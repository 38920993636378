import { Component } from '@angular/core';

@Component({
  selector: 'app-company-studio',
  templateUrl: './company-studio.component.html',
  styleUrls: ['./company-studio.component.scss']
})
export class CompanyStudioComponent {

}
