import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FORM_VALIDATION } from '../../constants/common';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {
  formValidation = FORM_VALIDATION

  constructor() { }

  /**
   * validateAlphabets() - method to validate if the control value contains only alphabetic characters or is empty.
   * If the field is empty or contains only whitespace, it returns a 'required' error.
   * @param control - The form control to validate.
   * @returns null if valid or a ValidationError object if invalid.
   */
  validateAlphabets(control: AbstractControl): ValidationErrors | null {
    const alphabetPattern = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
    const value = control.value;
    if (!value || value.trim() === '') {
      return { required: true };
    }
    if (value && (value !== value.trim())) {
      return alphabetPattern.test(value) ? null : { invalidSpace: true };
    }
    if (/\s{2,}/.test(value)) {
      return alphabetPattern.test(value) ? null : { multipleSpaces: true };
    }
      return alphabetPattern.test(value) ? null : { invalidAlphabets: true };
  }

  /**
   * validateAlphanumericAndSpecialCharacters() - method to validate if the control value contains alphanumeric and special characters or is empty.
   * @param control - The form control to validate.
   * @returns null if valid or a ValidationError object if invalid.
   */
  validateAlphanumericAndSpecialCharacters(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const regex = /^[a-zA-Z0-9@#?!&.,\-]+[\sa-zA-Z0-9@#?!&.,\-]*$/;
    if (value && !regex.test(value)) {
      return { invalidAlphanumericSpecial: true }; // Validation key for error message
    }
    return null;
  }

  // This pattern allows alphanumeric characters, spaces, and special characters.
  validateAlphanumericSpecialChars(control: AbstractControl): ValidationErrors | null {
    const pattern = /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};:'",.<>\/?\\|`~\s]*$/;    
    const value = control.value;
  
    // If the value is empty or just spaces, return the required error
    if (!value || value.trim() === '') {
      return { required: true };
    }
  
    return pattern.test(value) ? null : { invalidName: true };
  }

  validateAlphanumeric(control: AbstractControl): ValidationErrors | null {
    const pattern = /^[A-Za-z0-9]*$/; // Only allows alphanumeric characters (letters and digits)
    const alphaNumeric = FORM_VALIDATION.alphaNumericValidation;
    const value = control.value;
  
    // If the value is empty or contains only whitespace, return the 'required' error
    if (!value || value.trim() === '') {
      return { required: true };
    }
  
    // If the value does not match the alphanumeric pattern, return the 'invalidChars' error
    return pattern.test(value) ? null : { invalidChars: true };
  }

  /**
   * validateBusinessType() - method to validate if the field is alphanumeric 
   * @param control - the form control to valdiate
   * @returns - the validation message
   */
  validateBusinessType(control: AbstractControl): ValidationErrors | null {
    const pattern = /^[A-Za-z0-9\s]*$/; // Allows alphanumeric characters and spaces
    const value = control.value;
  
    // If the value is empty or contains only whitespace, return the 'required' error
    if (!value || value.trim() === '') {
      return { required: true };
    }
  
    // If the value does not match the alphanumeric pattern, return the 'invalidChars' error
    return pattern.test(value) ? null : { invalidChars: true };
  }

  validateNo(control: AbstractControl): ValidationErrors | null {
    const phonePattern = /^[0-9]*$/; // Only numbers allowed 
    const value = control.value;
  
    // if (!value || value.trim() === '') {
    //   return { required: true }; // Return required error if empty
    // }
  
    return phonePattern.test(value) ? null : { invalidno: true }; // If it contains anything other than numbers
  }

  /**
   * validateNoLoc() - validations for form controls
   * @param control - control name
   * @returns - an error message
   */
  validateNoLoc(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value === undefined) {
      return { required: true };
    }
    if (typeof value !== 'string') {
      return { invalidno: true };
    }
    if (!value || value.trim() === '' || (value.trim() === '$')) {
      return { required: true };
    }
    const cleanValue = value.replace(/[$,.]/g, ''); 
    const phonePattern = /^\d{1,14}$/; 
    
    if (cleanValue?.length > 14) {
      return { maxlength: true }; 
    }
    return phonePattern.test(cleanValue) ? null : { invalidno: true };
  }

  /**
   * validatePhoneNo() - method to validate phone numbers
   * @param control - the value entered
   * @returns - the appropriate error messages
   */
  validatePhoneNo(control: AbstractControl): ValidationErrors | null {
    const phonePattern = FORM_VALIDATION.phoneNovalidation;
    const value = control.value;
  
    if (value && !phonePattern.test(value)) {
      return { invalidPhone: true }; // Invalid phone number if it contains non-permitted characters
    }
    if (value?.length > 20) {
      return { maxLength: true };
    }
    if (/[a-zA-Z]/.test(value)) {
      return { invalidPhone: true }; // Return error if alphabetic characters are found
    } 
    return null;
  }

  /**
   * validateTrPhoneNo() - method to validate the phone numbers for trade references in customer credit application
   * @param control - the value entered
   * @returns - the appropriate error message if any
   */
  validateTrPhoneNo(control: AbstractControl): ValidationErrors | null {
    const phonePattern = FORM_VALIDATION.phoneNovalidation;
    const value = control.value;
    if (!value) {
        return { required: true }; // Required field validation
    }
    if (!phonePattern.test(value)) {
        return { invalidPhone: true }; // Invalid phone number if it contains non-permitted characters
    }
    if (value.length < 10) {
      return { minLength: true }; // Minimum length validation
    }
    if (value.length > 20) {
        return { maxLength: true };
    }
    if (/[a-zA-Z]/.test(value)) {
        return { invalidPhone: true }; // Return error if alphabetic characters are found
    }
    return null;
}

  /**
   * validateTrPhoneNo() - method to validate the phone numbers for trade references in customer credit application
   * @param control - the value entered
   * @returns - the appropriate error message if any
   */
  validateBankPhoneNo(control: AbstractControl): ValidationErrors | null {
    const phonePattern = FORM_VALIDATION.phoneNovalidation;
    const value = control.value;
    if (!phonePattern.test(value)) {
        return { invalidPhone: true }; // Invalid phone number if it contains non-permitted characters
    }
    if (value.length < 10) {
      return { minLength: true }; // Minimum length validation
    }
    if (value.length > 20) {
        return { maxLength: true };
    }
    if (/[a-zA-Z]/.test(value)) {
        return { invalidPhone: true }; // Return error if alphabetic characters are found
    }
    return null;
}


  validateFederalTaxTin(control: AbstractControl): ValidationErrors | null {
   // const tinPattern = /^[0-9-]+$/; // Only digits and hyphens are allowed
    const tinPattern = FORM_VALIDATION.tinValidation;
    
    const value = control.value;
  
    if (!value || value.trim() === '') {
      return { required: true }; // Return required error if empty
    }

    if (value.length > 15) {
      return { maxLength: true };
    }
    return tinPattern.test(value) ? null : { invalidTin: true };
    
  }

  validateCurrencyFormat(control: AbstractControl): ValidationErrors | null {
    const salePattern = /^\$(\d{1,3})(?:,(\d{3}))*(\.\d{2})?$/; // Dollar, numbers with commas, and optional decimal   
    const value = control.value;
  
    // If value is empty, return no error (you can change this if the field is required)
    if (!value || value.trim() === '') {
      return null;
    }
    // Test if the value matches the required pattern
    return salePattern.test(value) ? null : { invalidCurrency: true };
  }

  /**
   * validateEmail() - method to validate if the control value is a valid email format.
   * If the field is empty or contains only whitespace, it returns a 'required' error.
   * @param control - The form control to validate.
   * @returns null if valid or a ValidationError object if invalid.
   */
  validateEmail(control: AbstractControl): ValidationErrors | null {
   // const emailPattern = /^[^\s][a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}[^\s]$/;
    const emailPattern = FORM_VALIDATION.emailValidation;
    const trimmedValue = control.value;
    if (!trimmedValue || trimmedValue === '') {
      return { required: true };  // Empty or whitespace only input
    }
    if (trimmedValue && (trimmedValue !== trimmedValue.trim())) {
      return emailPattern.test(trimmedValue) ? null : { invalidSpace: true };
    }
    if (trimmedValue?.length > 80) {
      return { maxLength: true };
    }
    return emailPattern.test(trimmedValue) ? null : { invalidEmail: true };
  }

  /**
   * validateJobTitle() - method to validate if the control value is a valid job title format.
   * Allows alphanumeric characters, spaces, and specific special characters.
   * If the field is empty or contains only whitespace, it returns a 'required' error.
   * @param control - The form control to validate.
   * @returns null if valid or a ValidationError object if invalid.
   */
  validateJobTitle(control: AbstractControl): ValidationErrors | null {
    const jobTitlePattern = /^[A-Za-z0-9\s*()":{}|<>-]+$/;
    const trimmedValue = control.value?.trim();  
    if (trimmedValue === '') {
      return { required: true };  
    }
    return jobTitlePattern.test(trimmedValue) ? null : { invalidJobTitle: true };
  }

  /**
  * validateJobTitleAddUser() - method to validate if the control value is a valid job title format.
  * Allows alphanumeric characters, spaces, and specific special characters and it will not allow trailing,leading spaces.
  * If the field is empty or contains only whitespace, it returns a 'required' error.
  * @param control - The form control to validate.
  * @returns null if valid or a ValidationError object if invalid.
  */
  validateJobTitleAddUser(control: AbstractControl): ValidationErrors | null {
    const jobTitlePattern = /^[A-Za-z0-9]+(?:[\s*()":{}|<>-][A-Za-z0-9]+)*$/;
    const trimmedValue = control.value;  // Ensure null values are handled
    if (trimmedValue === '') {
      return { required: true };  // Empty or whitespace only input
    }
    if (trimmedValue && (trimmedValue !== trimmedValue.trim())) {
      return jobTitlePattern.test(trimmedValue) ? null : { invalidSpace: true };
    }
    if (/\s{2,}/.test(trimmedValue)) {
      return jobTitlePattern.test(trimmedValue) ? null : { multipleSpaces: true };
    }
    return jobTitlePattern.test(trimmedValue) ? null : { invalidJobTitle: true };
  }

  /**
   * validatePhone() - method to validate if the control value is a valid phone number format.
   * Allows numbers, dashes, plus, and parentheses.
   * If the field is empty or contains only whitespace, it returns a 'required' error.
   * @param control - The form control to validate.
   * @returns null if valid or a ValidationError object if invalid.
   */
  validatePhone(control: AbstractControl): ValidationErrors | null {
    const phonePattern = /^[+]*[0-9]*[()\d-]*$/;
    const value = control.value || '';
    const trimmedValue = value.trim();
    const minLength = 10;
    if (trimmedValue === '') {
      return { required: true };
    }

    const digitsOnly = trimmedValue.replace(/\D/g, '');
    if (digitsOnly.length < minLength) {
      return { minLength: true };
    }
    return phonePattern.test(trimmedValue) ? null : { invalidPhone: true };
  }


  /**
   * validateAtLeastOneSelected() - method to validate if at least one checkbox from a list of controls is selected.
   * Returns an error if none of the checkboxes are selected.
   * @param controls - An array of form controls representing checkboxes.
   * @returns null if at least one checkbox is selected, otherwise a ValidationError object.
   */
  validateAtLeastOneSelected(controls: AbstractControl[]): ValidationErrors | null {
    const isAnySelected = controls.some(control => control.value);
    return isAnySelected ? null : { atLeastOneRequired: true };
  }

  /**
   * phoneValidator() - This functions evaluated the phone  number field and throws erros according to that.
   * @returns - used to validate phone number in profile page;
   */
  phoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value || value.trim() === '') {
        return null;
      }
      const phonePattern = this.formValidation.phoneValidation;
      const minLengthValid = value.replace(/[\s()-]/g, '').length >= 10;
      const validPattern = phonePattern.test(value);

      if (!minLengthValid) {
        return { minLengthError: true };
      }

      if (!validPattern) {
        return { invalidPhone: true };
      }

      return null;
    };
  }


  /**
   * JobTitleValidator() - used to validate a job title
  @param control - The form control to validate.
 * @returns ValidationErrors | null - Returns an error object if validation fails, or null if it passes.
   */
  JobTitleValidator(control: AbstractControl): ValidationErrors | null {
    const trimmedValue = control.value?.trim();
  
    if (trimmedValue === '') {
      return { required: true };
    }
  
    const hasConsecutiveSpaces = /\s{2,}/.test(trimmedValue);
    const hasNonAlphanumericCharacters = /[^a-zA-Z0-9\s]/.test(trimmedValue);
  
    if (hasConsecutiveSpaces) {
      return { invalidSpace: true };
    } else if (hasNonAlphanumericCharacters) {
      return { invalidJobTitle: true };
    } else {
      return null;
    }
  }
  

  /**
 * streetValidator() - method to validate the street field.
 * Allows alphanumeric characters, special characters, and spaces.
 * Limits the input to a maximum of 85 characters.
 * If the field is empty, returns a 'required' error.
 * @param control - The form control to validate.
 * @returns null if valid or a ValidationError object if invalid.
 */
  streetValidator(control: AbstractControl): ValidationErrors | null {
    const value = control?.value?.trim() || '';
   // const streetPattern = /^[a-zA-Z0-9@#?!&.,\-()'"/:;+\s]{1,85}$/;
    const streetPattern = FORM_VALIDATION.streetValidation;

    if (!value) {
      return { required: true };
    }
    if (value.length > 85) {
      return { maxLength: true };
    }
    return streetPattern.test(value) ? null : { invalidStreet: true };
  }

 /**
 * streetValidator() - method to validate the street field.
 * Allows alphanumeric characters and spaces.
 * Limits the input to a maximum of 50 characters.
 * If the field is empty, returns a 'required' error.
 * @param control - The form control to validate.
 * @returns null if valid or a ValidationError object if invalid.
 */
  cityValidator(control: AbstractControl): ValidationErrors | null {
    const value = control?.value?.trim() || '';
   // const cityPattern = /^[a-zA-Z0-9\s]{1,50}$/;
    const cityPattern = FORM_VALIDATION.cityValidation;
  
    if (!value) {
      return { required: true };
    }
    if (value.length > 50) {
      return { maxLength: true };
    }
    return cityPattern.test(value) ? null : { invalidCity: true };
  }

  /**
   * zipCodeValidator() - Method to validate the ZIP code
   * @param control - The form control to validate
   * @returns null if valid or a ValidationError object if invalid.
   */
  zipCodeValidator(control: AbstractControl): ValidationErrors | null {
    const value = control?.value?.trim() || '';
    const zipCodePattern = /^[0-9]{1,12}$/;
    // const zipCodePattern = FORM_VALIDATION.zipCodeValidation;
  
    if (!value) {
      return { required: true };
    }
    if (value.length > 12) {
      return { maxLength: true };
    }
    return zipCodePattern.test(value) ? null : { invalidZipCode: true };
  }
 /**
   * dateValidator() - Method to validate the date field.
   * @param control - The form control to validate
   * @returns null if valid or a ValidationError object if invalid.
   */
  dateValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;

    const date = new Date(control.value);
    const year = date.getFullYear();
  
    if (year >= 1 && year <= 9999) {
      return null;
    } else {
      return { invalidDate: true };
    }
  }

  validateNoWithComma(control: AbstractControl): ValidationErrors | null {
    const phonePattern = /^[0-9,]*$/; // Only numbers and commas allowed
    const maxLength = 9;
    const value = control.value;
  
    if (value) {
      if (!phonePattern.test(value)) {
        return { invalidno: true };
      }
  
      // Remove commas for the length validation
      const numericValue = value.replace(/,/g, '');
      if (numericValue.length > maxLength) {
        return { maxlength: true };
      }
    }
  
    return null;
  }
  
}
