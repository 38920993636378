import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { REQUEST_AR, USER } from 'src/app/feature/studio-portal/constants/studio-portal.constant';
import { ProjectsCreditApplicationService } from 'src/app/feature/studio-portal/services/projects-credit-application.service';
import { ProjectsService } from 'src/app/feature/studio-portal/services/projects.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { PORTAL_TYPE } from 'src/app/shared/constants/common';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import * as _ from 'lodash';
import { AddProjectUserComponent } from 'src/app/shared/components/add-project-user/add-project-user.component';

@Component({
  selector: 'app-request-ar',
  templateUrl: './request-ar.component.html',
  styleUrls: ['./request-ar.component.scss']
})
export class RequestArComponent implements OnInit{
  requestArConstants = REQUEST_AR;
  public selectedDepartmentIds = [];
  public departmentDetails: any;
  public accountPayableUsers: any;
  public  userRoleDetails: any;
  public errorResponse: any;
  public displaySuccessMsg: any;
  user = USER;
  modalReference: NgbModalRef;
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @Input() public projectName: any;
  @Input() public companyName: any;
  @Input() public projectId: any;
  @Input() public projectCompanyId: any;
  form: FormGroup;
  sendLetterText: string = '';
  initialHtmlContent: string = '';
  updatedHtmlContent: string = '';
  isEdited: boolean = false; 

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private accountService: ProjectsCreditApplicationService,
    public projectsService: ProjectsService,
    public loaderService: LoaderService,
    private toastService: ToastService,) { }

    ngOnInit() {
      this.getDepartmentDetails();
      this.getAddProjectUserMetadata();
      this.initialHtmlContent = this.requestArConstants.sendRequestArTextboxName;
      this.sendLetterText = this.initialHtmlContent;
    }

    /**
   * initializeForm() - method to initialize the form group
   */
  initializeForm(): void {
    this.form = this.fb.group({
      requestArTextbox: [''],
      ...this.departmentDetails.reduce((acc, dept) => {
        acc[`department${dept.departmentId}`] = [false]; // default unchecked
        return acc;
      }, {}),
      ...this.accountPayableUsers.reduce((acc, user) => {
        acc[`caAccountPayableUser${user.userProfileId}`] = [false]; // default unchecked
        return acc;
      }, {})
    });
    this.form.addValidators([
      this.atLeastOneDepartmentSelected(),
      this.atLeastOneAccountPayableUserSelected()
    ]);
  }

 /**
  * atLeastOneDepartmentSelected() - Custom validator for at least one department selected
  */
    atLeastOneDepartmentSelected() : any {
      return (control: AbstractControl): { [key: string]: boolean } | null => {
        const selectedDepartments = this.departmentDetails.map(dept => control.get(`department${dept.departmentId}`).value);
        return selectedDepartments.includes(true) ? null : { 'atLeastOneDepartmentRequired': true };
      };
    }
  
    /**
    * atLeastOneAccountPayableUserSelected() - Custom validator for at least one account payable user selected
    */
    atLeastOneAccountPayableUserSelected() : any {
      return (control: AbstractControl): { [key: string]: boolean } | null => {
        const selectedUsers = this.accountPayableUsers.map(user => control.get(`caAccountPayableUser${user.userProfileId}`).value);
        return selectedUsers.includes(true) ? null : { 'atLeastOneAccountPayableUserRequired': true };
      };
    }

     /**
   * openAddUserModal() - method to open the add a user modal
   */
   openAddUserModal(): void {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddProjectUserComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true,
    });
    this.modalReference.componentInstance.modalTitle =
      this.requestArConstants?.addUserTitle;
    this.modalReference.componentInstance.projectName = this.projectName;
    this.modalReference.componentInstance.companyName = this.companyName;
    this.modalReference.componentInstance.departmentDetails =
      this.departmentDetails;
    this.modalReference.componentInstance.userRoleDetails =
      this.userRoleDetails;
    this.modalReference.componentInstance.portalType = PORTAL_TYPE.studioPortal;
    this.modalReference.componentInstance.projectId = this.projectId;
    this.modalReference.componentInstance.componentType =
      PORTAL_TYPE.studioTypeAdd;
    this.modalReference.componentInstance.projectCompanyId =
      this.projectCompanyId;
    this.modalReference.componentInstance.isAccountsPayableUser =
      PORTAL_TYPE.accountsPayableUser;
    this.closeModal();
    this.modalReference.result.then(
      (data) => {
        if (data) {
          if (data.status == this.requestArConstants.statusSuccess) {
            this.displaySuccessMsg = this.user.addUserSuccessMsg;
            this.showSuccess(this.successTpl);
          } else if (data.status != this.requestArConstants.statusSuccess && data != true){
            this.errorResponse = data;
            this.showDanger(this.dangerTpl);
          }
          else {
            this.errorResponse = data;
          }
        }
        // on close
      },
      (reason) => {
        // on dismiss
      }
    );
   }


   /**
   * getAccountPayableUsers() - to get the account payable users
   */
   getAccountPayableUsers(): void {
    this.loaderService.setLoadingState(true);
    this.accountService.getAccountPayableUsers(this.projectCompanyId, this.projectId).subscribe({
      next: (response: any) => {
        this.loaderService.setLoadingState(false);
        this.accountPayableUsers = response.accPayUsers;
        if (this.departmentDetails && this.accountPayableUsers) {
          this.initializeForm();
        }
      },
      error: (error: any) => {
        this.loaderService.setLoadingState(false);
        console.error(this.requestArConstants.accountPayable, error);
      },
    });
  }


  /**
  * getDepartmentDetails() - Method to fetch the department details
 */
  getDepartmentDetails(): void {
    this.accountService.fetchCAPageData().subscribe((response: any) => {
      this.departmentDetails = response?.departments;
      this.getAccountPayableUsers();
    });
  }

   /**
   * getAddProjectUserMetadata() - Method to fetch the metadata for add project user screen
   */
   getAddProjectUserMetadata(): void {
    this.projectsService.fetchMetaData().subscribe((response: any) => {
      this.userRoleDetails = response?.getProjectUserMetaDataDTO?.userRoleDetails;
    });
  }

    /**
   * onSubmit() - method to submit the Send Credit Application Packet.
   */
    onSubmit(): void {
      if (this.form.valid) {
        const selectedDepartmentIds = this.departmentDetails
          .filter(dept => this.form.value[`department${dept.departmentId}`])
          .map(dept => dept.departmentId);
        const selectedAccountPayableUserIds = this.accountPayableUsers
          .filter(user => this.form.value[`caAccountPayableUser${user.userProfileId}`])
          .map(user => user.userProfileId);
        const selectedAccountPayableContacts = this.accountPayableUsers
        .filter(user => selectedAccountPayableUserIds.includes(user.userProfileId))
        .map(user => ({
          accountPayableId: user.userProfileId,
          firstName: user.firstName,
          lastName: user.lastName,
          emailAddress: user.emailAddress
        }));
        const payload = {
          companyId: this.projectCompanyId,
          projectId: this.projectId,  //only the credit application with studio status approved can be submitted. 
          //projectId: 145,
          caPacketText: this.sendLetterText,
          arReqDepartmentIds: selectedDepartmentIds,
          caAccPayUsrIds: selectedAccountPayableUserIds,
          caLoaAccPayableContact: selectedAccountPayableContacts, 
          arText: 'AR request',
          portalType:'studio',
          projectName: this.projectName, 
        };
        this.loaderService.setLoadingState(true);
        this.accountService.sendToThirdPartyCustomer(payload).subscribe({
          next: (response: any) => {
            let message = { status: 'success', message: response.message };
            this.activeModal.close(message);
            this.loaderService.setLoadingState(false);
          },
          error: (error) => {
            let errorMessage = { status: 'error', message: error };
            this.activeModal.close(error);
            this.loaderService.setLoadingState(false);
          },
        });
      } else {
        console.error('Form is invalid:', this.form.errors);
      }
    }
  

  

  /**
   * onDepartmentCheckboxChange() - method to handle the event when a department checkbox is checked or unchecked.
   */
  onDepartmentCheckboxChange(event, dept): void {
    const checkbox = event.target as HTMLInputElement;
    const departmentId = Number(checkbox.value);
    switch (checkbox.checked) {
      case true:
        {
          if (departmentId == 1) {
            this.selectedDepartmentIds = [];
            _.forEach(this.departmentDetails, (dept) => {
              this.form
                .get(`department${dept.departmentId}`)
                .setValue(checkbox.checked);
            });
            this.selectedDepartmentIds.push(dept);
          } else {
            this.selectedDepartmentIds.push(dept);
            if (
              this.departmentDetails?.length - 1 ==
              this.selectedDepartmentIds?.length
            ) {
              this.selectedDepartmentIds = [];
              _.forEach(this.departmentDetails, (dept) => {
                this.form
                  .get(`department${dept.departmentId}`)
                  .setValue(checkbox.checked);
                dept.departmentId == 1
                  ? this.selectedDepartmentIds.push(dept)
                  : '';
              });
            }
          }
        }
        break;
      case false:
        {
          if (departmentId == 1) {
            this.selectedDepartmentIds = [];
            _.forEach(this.departmentDetails, (dept) => {
              this.form
                .get(`department${dept.departmentId}`)
                .setValue(checkbox.checked);
            });
          } else {
            if (this.selectedDepartmentIds.some(department => department.departmentId === 1)) {
              this.selectedDepartmentIds = this.departmentDetails;
              this.selectedDepartmentIds = _.filter(this.selectedDepartmentIds,
                (id) => id.departmentId !== departmentId && id.departmentId !== 1
              );
              _.forEach(this.departmentDetails, (dept) => {
                dept.departmentId == 1
                  ? this.form
                    .get(`department${dept.departmentId}`)
                    .setValue(checkbox.checked)
                  : '';
              });
              this.form.get(`department1`).setValue(checkbox.checked);
            } else {
              this.selectedDepartmentIds = _.filter(this.selectedDepartmentIds,
                (id) => id.departmentId !== departmentId
              );
            }
          }
        }
        break;
    }
    this.form.get('departmentsIds')?.setValue(this.selectedDepartmentIds);
    const allChecked = _.every(this.departmentDetails,
      (dept) => this.form.get(`department${dept.departmentId}`)?.value
    );
    this.form.get('custom-checkbox-all')?.setValue(allChecked);
  }

   /**
   * closeModal() - Method to close the modal
   */
   closeModal(): void {
    this.activeModal.close();
  }

   /**
   * showSuccess() - Displays a success toast notification with custom content and options.
   * @param successTpl - The template reference containing the toast's content.
   */
   showSuccess(successTpl: TemplateRef<any>): void {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: TemplateRef<any>): void {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
   * method to edit the htmlcontent
   * @param event 
   */
    onHtmlContentChange(event: Event): void {
      const content = (event.target as HTMLElement).innerHTML;
      if (content !== this.initialHtmlContent) {
        this.updatedHtmlContent = content; // Update the content if changed
        this.isEdited = true; // Mark that the user has edited the content
      }
      this.sendLetterText = this.isEdited ? this.updatedHtmlContent : this.initialHtmlContent;
    }
}



