// email-action.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL_API_PATHS } from 'src/assets/constants/apiPaths';
import { BaseApiService } from './shared/services/base-api/base-api.service';



@Injectable({
  providedIn: 'root'
})
export class EmailActionService {
  constructor(private http: HttpClient,private baseApiService: BaseApiService) {}

  downloadFullForm(data: any): Observable<any> {
    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json' as const
    };

    return this.baseApiService.post(GLOBAL_API_PATHS.downloadFullForm, data, options);
  }

  performRiskAction(requestBody: any): Observable<any> {
    return this.baseApiService.post(GLOBAL_API_PATHS.accept_decline_risk, requestBody);
}

}
