<div class="page-wrapper">
	<div class="container px-0">
		<div class="row">
			<div class="all-project-link-section" (click)="navigateToProjectDetails()">
				<img src="../../assets/images/keyboard_arrow_left.svg" alt="keyboard-arrow-left" />
				<p class="all-project-link-name mb-0">{{ projectName }}</p>
			</div>
			<div class="comman-title-block">
				<div class="comman-title-section">
					<h1>{{ viewAllUserConstants.projectUsers }}</h1>
					<button class="common-btn-primary btn" (click)="openAddUserModal()" *ngIf="isAddUserClickable">{{ viewAllUserConstants.addUser
                    }}</button>
				</div>
                <div class="comman-subtitle-section">
                    <h2>{{ viewAllUserConstants.company }}</h2>
                    <h2>{{ companyName }}</h2>
                </div>
				<div class="comman-filter-block">
					<div class="container">
						<div class="row">
							<div class="col-lg-9 col-12 px-0">
								<div class="comman-filter-title-section">
									<p>{{ viewAllUserConstants.filters }}</p>
								</div>
								<div class="comman-filter-section d-flex flex-wrap align-items-center gap-3">
									<!-- Multiselect Dropdowns -->
                                    <div class="id-filter">
                                        <app-ng-multiselect-dropdown [placeholder]="'ID #'" [settings]="defaultSettings"
										[type]="userTypeahead" [data]="usersId" [filterData]="userIdFilter" (onSelect)="selectedUserId($event)">
                                        </app-ng-multiselect-dropdown>
                                    </div>
									<app-ng-multiselect-dropdown [placeholder]="'User'" [settings]="defaultSettings"
									[type]="userTypeahead" [data]="users" [filterData]="userInfoFilter" (onSelect)="selectedUser($event)">
									</app-ng-multiselect-dropdown>
									<app-ng-multiselect-dropdown [placeholder]="'Status'" [settings]="defaultSettings"
									[data]="status" (onSelect)="selectedStatus($event)">
									</app-ng-multiselect-dropdown>
									<app-datepicker [placeholder]="placeholder" (dateRangeSelected)="onDateRangeSelected($event)"></app-datepicker>
									<app-ng-multiselect-dropdown [placeholder]="'Job Title'"
										[settings]="defaultSettings" [type]="userTypeahead" [data]="jobTitle" [filterData]="jobtitleFilter"
										(onSelect)="selectedJobTitle($event)">
									</app-ng-multiselect-dropdown>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="selected-filters-section">
				<!-- Combined Selected Filters -->
				<div *ngIf="isFiltersSelected" class="selected-filters">
                    <!-- Selected Users -->
					<div *ngFor="let userId of filteredUsersId" class="filter-tag">
						{{ userId?.id }}
						<button type="button" (click)="removeSelectedItem('id', userId)">×</button>
					</div>
					<!-- Selected Users -->
					<div *ngFor="let user of filteredUsers" class="filter-tag">
						{{ user?.firstname }}
						<button type="button" (click)="removeSelectedItem('firstname', user)">×</button>
					</div>
					<!-- Selected status -->
					<div *ngFor="let status of filteredStatus" class="filter-tag">
						{{ status?.text }}
						<button type="button" (click)="removeSelectedItem('status', status)">×</button>
					</div>
					<!-- Selected Last login date-->
					<div *ngIf="selectedFromDate && selectedToDate" class="filter-tag">
						{{ formatDateRange(selectedFromDate, selectedToDate) }}
						<button type="button" (click)="clearDateRange()">×</button>
					</div>
					<!-- Selected job title -->
					<div *ngFor="let jobTitle of filteredJobTitle" class="filter-tag">
						{{ jobTitle?.jobtitle }}
						<button type="button" (click)="removeSelectedItem('jobTitle', jobTitle)">×</button>
					</div>
					<!-- Clear All Filters Button -->
					<a class="text-link" (click)="clearAllFilters()">{{ viewAllUserConstants.clearFilters }}</a>
				</div>
			</div>
			<div class="common-grid-block">
				<div class="mt-4">
					<app-common-ag-grid style="width: 100%; height: 100%;" class="ag-theme-custom" [colDefs]="colDefs"
						[rowData]="filteredRowData" [colsForSmallScreen]="colsForSmallScreen"
						[colsForMediumScreen]="colsForMediumScreen" [colsForLargeScreen]="colsForLargeScreen"
						[colsForExtraLargeScreen]="colsForExtraLargeScreen" (cellClicked)="onCellClicked($event)">
					</app-common-ag-grid>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #successTpl>
	<div class="success">{{displaySuccessMsg}}</div>
</ng-template>

<ng-template #dangerTpl>
	<div class="danger">{{displayErrorMsg}}</div>
</ng-template>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>
