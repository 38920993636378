<!-- <div class="common-split-cell-section common-mobile-section-companies">
    <span class="common-split-cell-data-1" [ngClass]="getStatusClass(status)">{{ status }}</span>
    <div class="common-split-cell-data-2">
      <app-icons></app-icons>
    </div>
  </div> -->
  <div class="merged-icon-status">
    <div [ngClass]="getStatusClass(status)" (click)="redirectViewCompany()">{{ status }}</div>
    <div >
      <div class="icon-container">
        <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top" ngbTooltip="Add a Project"
          (click)="addProjectIcon()">
          <img src="/assets/images/disabled-icon.svg" alt="Add a Project" />
        </span>
        <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top" ngbTooltip="Add a User"
          (click)="addUserIcon()">
          <img src="/assets/images/add-user-icon.svg" alt="Add a User" />
        </span>
      
        <span  *ngIf="gridData.status == 'ACTIVE' && permissionsData['disableCompany'] && !showARCompaniesIcon" class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top"
          ngbTooltip="{{ gridData.status == 'DISABLED' ? 'Enable Company' : 'Disable Company' }}">
          <img src="/assets/images/disable-icon.svg"
            *ngIf="gridData.status == 'ACTIVE' && permissionsData['disableCompany']"
            alt="Disable Company" (click)="gridData?.activeProjects?.length === 0 ? openConfirmationModal('disable-company') : this.showDanger(this.restrictDisableCompanyTpl)" />
        </span>
        <span *ngIf="gridData.status == 'DISABLED' && permissionsData['disableCompany'] && !showARCompaniesIcon" class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top"
          ngbTooltip="{{ gridData.status == 'DISABLED' ? 'Enable Company' : 'Disable Company' }}">
          <img src="/assets/images/enable-icon.svg" *ngIf="gridData.status == 'DISABLED' && permissionsData['disableCompany']"
            alt="Enable Company" (click)="openConfirmationModal('enable-company')" />
        </span>
      </div>
    </div>
  </div>
  <ng-template #confirmationModalDisableCompany let-modal>
    <div class="modal-body">
      <h4 class="section-main-header">{{ modalTitle }}</h4>
      <div class="d-flex justify-content-end gap-2">
        <button type="button" [disabled]="this.disableCompany && gridData.activeProjects?.length !== 0" class="common-btn-secondary btn" (click)="handleAction()">
          {{ iconsRenderer.yes }}
        </button>
        <button type="button" class="common-btn-light btn" (click)="modal.close()">
          {{ iconsRenderer.no }}
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template #restrictDisableCompanyTpl>
    <div class="danger">
        <span>{{iconsRenderer.restrictDisableCompany}}</span>
    </div>
  </ng-template>