import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from '../../../shared/models/multiselect.model';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  title = 'angularaws';
  dropdownList : any[] = [];
  selectedItems : any[] = [];
  public dropdownSettings = {};
  filteredProject: any[] = [];
  filteredTypes: any[] = [];


  //multiselect code
  defaultSettings: IDropdownSettings = {
    idField: "id",
    textField: "text",
    enableCheckAll: true,
    allowSearchFilter: true,
    limitSelection: -1,
    clearSearchFilter: true,
    maxHeight: 197,
    itemsShowLimit: 999999999999,
    searchPlaceholderText: "Search",
    noDataAvailablePlaceholderText: "No data available",
    noFilteredDataAvailablePlaceholderText: "No filtered data available",
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
    allowRemoteDataSearch: false
  };

  projects = [
    { id: 1, text: 'Project Name 1' },
    { id: 2, text: 'Project Name 2' },
    { id: 3, text: 'Project Name 3' },
    { id: 4, text: 'Project Name 4' },
    { id: 5, text: 'Project Name 5' },
    { id: 6, text: 'Project Name 6' },
  ];
  types = [
    { id: 1, text: 'Feature' },
    { id: 2, text: 'Television' },
    { id: 3, text: 'Live Production/Rehearsal' },
    { id: 4, text: 'Commercial' },
    { id: 5, text: 'Still shot' },
    { id: 6, text: 'Special event' },
    { id: 6, text: 'Others' },
  ];

  ngOnInit() {
    this.dropdownList = [
      { item_id: 1, item_text: 'Project Name' },
      { item_id: 2, item_text: 'Project Name' },
      { item_id: 3, item_text: 'Project Name' },
      { item_id: 4, item_text: 'Project Name' },
      { item_id: 5, item_text: 'Project Name' }
    ];
  
  }
  onItemSelect(item: any) {
    console.warn('item');
  }
  onSelectAll(items: any) {
    console.warn('selectall');
  }


  selectedProject(selectedItems: any) {
    this.filteredProject = selectedItems
    
  }

  selectedType(selectedItems: any) {
    this.filteredTypes = selectedItems
    
  }
}
