import { Component, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-help-slideout',
  templateUrl: './help-slideout.component.html',
  styleUrls: ['./help-slideout.component.scss']
})
export class HelpSlideoutComponent{ title = 'studios-portal-app';
sidebarVisible = false;
noPaddingTop = false;
helpSliderVisible=false;

toggleSidebar() {
  this.sidebarVisible = !this.sidebarVisible;
}
toggleHelpSlider(){
  this.helpSliderVisible=!this.helpSliderVisible;
}
constructor(private renderer: Renderer2, private elRef: ElementRef) { }

ngOnInit(): void {}

sticky: boolean = false;
stickyPoint: number = 100;
@HostListener('window:scroll', ['$event'])
handleScroll() {
  const windowScroll = window.pageYOffset;
  this.sticky = windowScroll >= this.stickyPoint;
  this.noPaddingTop = windowScroll >=  this.stickyPoint;
}
ngAfterViewInit() {
  this.renderer.listen('document', 'click', (event: Event) => {
    const target = event.target as HTMLElement;
    if (this.helpSliderVisible && !this.elRef.nativeElement.contains(target)) {
      this.closeHelpSlider();
    }
  });
}

closeHelpSlider() {
  this.helpSliderVisible = false;
}}

