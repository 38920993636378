import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() overlayState: boolean = false;

  constructor() {}

  ngOnInit(): void { //this.loading = true;console.log("LOADER", this.loading)
  }
}
