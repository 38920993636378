import { Component, Inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { footer } from '../../constants/core.constant';
import { UserService } from '../../services/user-service/user-service.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  currentPortal: string;
  constants = footer;
  portalType: string
  userTypeId: any;
  userDetails: any;
  userRole: any;
  isAuthenticated: boolean = false;

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private router: Router, private userService: UserService) { }

  ngOnInit() {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.userTypeId = userProfile?.userTypeId;
    this.userDetails = userProfile;
    this.getUserInfo();
    this.setPortalType();
    this.isAuthenticated = !!userProfile?.userTypeId; // Set based on user profile presence
  }

  /**
   * redirectToDashboard() - Redirects the user to a specific dashboard based on portal
   */
  redirectToDashboard():void {
    switch (this.portalType) {
      case 'customer':
        this.router.navigate(['/feature/customer-dashboard/overview']);
        break;
      case 'wbso':
        this.router.navigate(['/feature/studio-dashboard/Mydashboard']);
        break;
      case 'ar':
        this.router.navigate(['/feature/ar-dashboard/overview']);
        break;
      default:
        console.log('Unknown portal type');
    }
  }
  /**
* navigateOnLogoClick() - Navigates the user based on their login status.
*/
  navigateOnLogoClick(): void {
    if (this.isAuthenticated) {
      this.redirectToDashboard();
    } else {
      this.router.navigate(['/homepage']);
    }
  }
/**
 * setPortalType() - Determines and sets the portal type based on the user type and roles.
 */
setPortalType():void{
  if (this?.userTypeId == 1) {
    this.portalType = 'customer';
  } else if (this?.userTypeId == 2) {
    if (Array.isArray(this.userDetails.roles) && this.userDetails.roles.includes('A/R')) {
      this.portalType = 'ar';
    } else {
      this.portalType = 'wbso';
    }
  }
}
  /**
  * getUserInfo() - used to get data from localstorage
  */
  getUserInfo(): void {
    this.userService.userInfo$.subscribe((userInfo) => {
      if (userInfo) {
        let userDetails = userInfo?.userProfileDTO;
        this.userTypeId = userDetails?.userTypeId;
        this.portalType = userDetails?.portalType[0];
        this.isAuthenticated = true; // Set authenticated if user info is present
      } else {
        this.isAuthenticated = false;}
    });
  }
}
