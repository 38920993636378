import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'src/app/shared/models/multiselect.model';
import {
  COMPANY,
  ERROR_MSG,
  NEW_PROJECT,
  PROJECT_DATES_DROPDOWN_LIST,
  PROJECT_DROPDOWN_SETTINGS,
  PROJECT_FILTER_TYPES,
  PROJECTS,
  PROJECTS_MODAL,
  SORTING_CONSTANTS,
  STUDIO_PROJECT_USERS_LIST_CONSTANTS,
} from '../../constants/studio-portal.constant';
import { BOOKMARK, INDEX, PAGES, SORT_ID } from '../../enum/studio-portal.enum';
import * as _ from 'lodash';
import { NgbDate, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { companyProjectIdTypeahead } from 'mockData/development/view-all-users';
import { AddProjectUserComponent } from 'src/app/shared/components/add-project-user/add-project-user.component';
import { AddProjectComponent } from './add-project/add-project.component';
import { ProjectsService } from '../../services/projects.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { PORTAL_TYPE } from 'src/app/shared/constants/common';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  isFiltersSelected: boolean = false;
  filteredCompany: any[] = [];
  filteredTypes: any[] = [];
  filteredStatus: any[] = [];
  filteredProjects: any[] = [];
  filteredData: any[] = [];
  selectedItems: any[] = [];
  paginatedData: any[] = [];
  filteredUsersId: any[] = [];
  modalReference: NgbModalRef;
  selectedFromDate: NgbDate = null;
  selectedToDate: NgbDate = null;
  userTypeahead: any = 'typeahead';
  usersId = companyProjectIdTypeahead;
  projectData: any[] = [];
  placeholder: string = PROJECTS.placeholder;
  filterTypes = PROJECT_FILTER_TYPES; // constants data for different filters
  company: any;
  types: any;
  status: any;
  projectIdFilter: any;
  userProfileId: number;
  modalText = PROJECTS_MODAL;
  datesList = PROJECT_DATES_DROPDOWN_LIST;
  sort = SORTING_CONSTANTS;
  project = PROJECTS;
  projectText = NEW_PROJECT;
  companyConst = COMPANY;
  requestResponse: any;
  departmentDetails: any;
  userRoleDetails: any;
  isBookmarkAdded: boolean;
  itemsPerPage: number = PAGES.ten; // Number of items to display per page
  currentPage: number; // Current page number
  totalItems: number = this.projectData?.length; // Total number of project items
  defaultSettings: IDropdownSettings = PROJECT_DROPDOWN_SETTINGS;
  sortId: number = SORT_ID.default_id_sort_function; //By default setting to 1 so that most recent is clicked
  userListConstants = STUDIO_PROJECT_USERS_LIST_CONSTANTS;
  projects: any;
  companyid: null;
  defaultSelectedCompany: any[];
  companyname:any;
  permissionsData: any;

  constructor(private router: Router, private route: ActivatedRoute, private modalService: NgbModal, private projectsService: ProjectsService, private toastService: ToastService, private loaderService: LoaderService) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.companyid = params['companyid'];
      this.companyname = params['companyName'];
    });
    this.getProfileId();
    this.getProjectList();
    this.getAddProjectUserMetadata();
  }

  /**
   * updatePaginatedData() - Method to update the data for the current page based on pagination settings
   * startIndex and endIndex are used to slice out the data to display it based on number of items per page selection
   */
  updatePaginatedData(): void {
    const startIndex = (this.currentPage - PAGES.one) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedData = _.slice(this.filteredData, startIndex, endIndex);
  }

  /**
   * onPageChange() - Method to handle page changes
   * @param page - The new page number to navigate to
   */
  onPageChange(page: number): void {
    this.currentPage = page;
    this.updatePaginatedData(); // Update the paginated data when the page changes
  }

  /**
   * onItemsPerPageChange() - Method to handle changes in the number of items per page
   * @param itemsPerPage - The new number of items to display per page
   */
  onItemsPerPageChange(itemsPerPage: number): void {
    this.itemsPerPage = itemsPerPage;
    this.currentPage = PAGES.one; // Reset to the first page when the items per page changes
    this.updatePaginatedData(); // Update the paginated data with the new items per page setting
  }

   /**
   * applyFilters() - Method to apply selected filters to the project data and update the paginated data
   */
  applyFilters(): void {
    let filteredData = this.projectData;
    if (this.filteredUsersId?.length > 0) {
      filteredData = _.filter(filteredData, (project) =>
        _.some(this.filteredUsersId, (x) => project.projectId === x.projectId
        )
      );
    }
    if (this.filteredProjects?.length) {
      filteredData = _.filter(filteredData, (project) =>
        _.some(this.filteredProjects, (x) =>
          _.includes(project.projectName, x.text)
        )
      );
    }
    if (this.filteredTypes?.length) {
      filteredData = _.filter(filteredData, (project) =>
        _.some(this.filteredTypes, (x) => project.projectType.projectType === x.text)
      );
    }
    if (this.filteredCompany?.length) {
      filteredData = _.filter(
        filteredData,
        (project) =>
          _.some(this.filteredCompany, (x) => project.company.companyName === x.text) 
      );
    }
    //Filter by selected date range
    if (this.selectedFromDate && this.selectedToDate) {
      const selectedFromDate = new Date(this.selectedFromDate.year, this.selectedFromDate.month - 1, this.selectedFromDate.day);
      const selectedToDate = new Date(this.selectedToDate.year, this.selectedToDate.month - 1, this.selectedToDate.day);
      // Filter projects that overlap with the selected date range
      filteredData = _.filter(filteredData, project => {
        const projectStartDate = new Date(project.projectStartDate);
        const projectEndDate = new Date(project.projectEndDate);
        return (projectStartDate <= selectedToDate && projectEndDate >= selectedFromDate);
      });
    }
    if (this.filteredStatus?.length) {
      filteredData = _.filter(
        filteredData,
        (project) =>
          _.some(this.filteredStatus, (x) => project.projectStatus.projectStatus === x.text),
      );
    }
    this.hasSelectedFilters();
    this.filteredData = filteredData;
    this.currentPage = PAGES.one;
    this.totalItems = this.filteredData?.length;
    this.updatePaginatedData();
  }

  hasSelectedFilters(): boolean {
    this.isFiltersSelected = this.filteredUsersId?.length > 0 || 
      this.filteredProjects?.length > 0 ||
      this.filteredTypes?.length > 0 ||
      this.filteredStatus?.length > 0 ||
      this.filteredCompany?.length > 0 ||
      (this.selectedFromDate !== null && this.selectedToDate !== null);
    return this.isFiltersSelected;
  }

  /**
   * Method to handle the selected date range emitted from the DatepickerComponent.
   * @param dateRange - The object containing fromDate and toDate.
   */
  onDateRangeSelected(dateRange: {
    fromDate: NgbDate | null;
    toDate: NgbDate | null;
  }): void {
    if (dateRange.fromDate && !dateRange.toDate) {
      this.selectedFromDate = dateRange.fromDate;
      this.selectedToDate = dateRange.fromDate;  // Treat single date as both 'from' and 'to'
    }
    else if (dateRange.fromDate && dateRange.toDate) {
      this.selectedFromDate = dateRange.fromDate;
      this.selectedToDate = dateRange.toDate;
    }
    this.applyFilters();
  }

  /**
   * clearDateRange() - method to clear the selected date range.
   */
  clearDateRange(): void {
    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.applyFilters();
  }

  /**
   * selectedType() - Method to handle selection of types from the dropdown
   * @param selectedItems - The selected items from the dropdown
   */
  selectedType(selectedItems: any): void {
    this.filteredTypes = selectedItems;
    this.applyFilters(); // Apply filters after selection
  }

  /**
  * selectedUser() - Method to update the selected user ID from the dropdown
  * @param selectedItems - The selected users
  */
  selectedUserId(selectedItems: any): void {
    this.filteredUsersId = selectedItems;
    this.applyFilters();
  }

   /**
   * selectedCompany() - Method to handle selection of companies from the dropdown
   * @param selectedItems - The selected items from the dropdown
   */
  selectedCompany(selectedItems: any): void {
    this.filteredCompany = selectedItems;
    this.applyFilters(); // Apply filters after selection
  }

  /**
   * selectedStatus() - Method to handle selection of status from the dropdown
   * @param selectedItems - The selected items from the dropdown
   */
  selectedStatus(selectedItems: any): void {
    this.filteredStatus = selectedItems;
    this.applyFilters(); // Apply filters after selection
  }

  /**
   * selectedProject() - Method to handle selection of projects from the dropdown
   * @param selectedItems - The selected items from the dropdown
   */
  selectedProject(selectedItems: any): void {
    this.filteredProjects = selectedItems;
    this.applyFilters(); // Apply filters after selection
  }

  /**
   * clearAllFilters() - Method to clear a specific filter
   */
  clearAllFilters(): void {
    //Clear Id
    this.filteredUsersId = [];
    this.selectedUserId(this.filteredUsersId);
    this.usersId = this.filteredUsersId;
    // Clear Projects
    this.filteredProjects = [];
    this.selectedProject(this.filteredProjects);
    this.projects = this.filteredProjects;
    // Clear Types
    this.filteredTypes = [];
    this.selectedType(this.filteredTypes);
    this.types = this.filteredTypes;
    // Clear Status
    this.filteredStatus = [];
    this.selectedStatus(this.filteredStatus);
    this.status = this.filteredStatus;
    this.filteredCompany = [];
    this.selectedCompany(this.filteredCompany);
    this.company = this.filteredCompany;
    this.selectedFromDate = null;
    this.selectedToDate = null;
    // Re-apply filters to reflect the cleared state
    this.applyFilters();
  }

  /**
   * removeSelectedItem() - Method to remove a specific item from a selected filter
   * @param filterType - The type of filter the item belongs to (project, type, department, status)
   * @param item - The item to be removed
   */
  removeSelectedItem(filterType: string, item: any): void {
    switch (filterType) {
      case this.filterTypes.projectId:
        {
          let userIdArray = [];
          this.filteredUsersId = this.filteredDataList(
            this.filteredUsersId, 
            item);
          userIdArray.push(item);
          this.usersId = userIdArray;
        }
        break;
      case this.filterTypes.project:
        {
          let projectArray = [];
          this.filteredProjects = this.filteredDataList(
            this.filteredProjects,
            item
          );
          projectArray.push(item);
          this.projects = projectArray;
        }
        break;
      case this.filterTypes.type:
        {
          let typeArray = [];
          this.filteredTypes = this.filteredDataList(this.filteredTypes, item);
          typeArray.push(item);
          this.types = typeArray;
        }
        break;
      case this.filterTypes.status:
        {
          let statusArray = [];
          this.filteredStatus = this.filteredDataList(
            this.filteredStatus,
            item
          );
          statusArray.push(item);
          this.status = statusArray;
        }
        break;
        case this.filterTypes.company:
          {
          let companiesArray = [];
          this.filteredCompany = this.filteredDataList(
            this.filteredCompany,
            item
          );
          companiesArray.push(item);
          this.company = companiesArray;
        }
        break;
    }
    this.applyFilters();
  }

  /**
   * filteredDataList() - Method to filter out the selected item from the array
   * @param arr - The array from which the item needs to be removed
   * @param item - The item to be removed
   * @returns The filtered array
   */
  filteredDataList(arr: any[], item: any): any {
    return _.filter(arr, (i) => i.id !== item.id);
  }

  /**
   * Formats the date range for display.
   * Format day and month to always be two digits
   * @param fromDate - The start date.
   * @param toDate - The end date.
   * @returns A string representing the formatted date range.
   */
  formatDateRange(fromDate: NgbDate, toDate: NgbDate): string {
    const formatToTwoDigits = (num: number) => num < PAGES.ten ? `${PAGES.zero}${num}` : `${num}`;
    const formattedFromDate = `${formatToTwoDigits(fromDate.month)}/${formatToTwoDigits(fromDate.day)}/${fromDate.year.toString()}`;
    const formattedToDate = `${formatToTwoDigits(toDate.month)}/${formatToTwoDigits(toDate.day)}/${toDate.year.toString()}`;
    // If both dates are the same, return just the single date
    if (fromDate.equals(toDate)) {
      return `${formattedFromDate}`;
    }
    else {
      return `${formattedFromDate} - ${formattedToDate}`;
    }
  }

   /**
   * sortByMostRecent() - Method to sort the projects list based on created date in descending order
   * *  @param id - an id to indicate the we are hitting sortByMostRecent() function for css to reflect
   */
   sortByMostRecent(id:number): void {
    this.sortId=id;
    this.projectData = _.orderBy(this.projectData, ['projectStartDate'], ['desc']);
    this.applyFilters();
  }

  /**
   * ascendingSort() - Method to sort the projects list in the alphabetical order from A to Z based on the project name
   *  @param id - an id to indicate the we are hitting ascendingSort() function for css to reflect
   */
  ascendingSort(id:number): void {
    this.sortId=id;
    this.projectData = _.orderBy(this.projectData, ['projectName'], ['asc']);
    this.applyFilters();
  }

  /**
   * openProjectDetails() - Method to navigate into project details page
   * @param projectId - id of the project to be navigated to
   */
  openProjectDetails(projectId: number, profileId: number): void {
    if (this.permissionsData.viewProject) {
      this.router.navigate(['/feature/studio-dashboard/project/project-details', profileId, projectId]);
    }
  }

  /**
   * onSelectChnage() -  Navigate based on the selected value
   * @param event - The event object containing details about the change event. 
   */
  onSelectChange(event: any, profileId: number, projectId: number, companyId: number, companyName: string, projectName: string): void {
    const selectedValue = event.target.value;
    switch (selectedValue) {
      case '1':
        this.router.navigate(['/feature/studio-dashboard/project/project-details/'+ `${profileId}`+ '/' + `${projectId}`]);
        break;
      case '2':
        this.router.navigate(['/feature/studio-dashboard/project/view-users'], {
          queryParams: {
            projectId: projectId,
            companyId: companyId,
            projectName: projectName,
            companyName: companyName
          },
        });
        break;
      case '3':
        this.router.navigate(['/feature/studio-dashboard/project/project-details/'+ `${profileId}`+ '/' + `${projectId}`]);
        break;
      case '4':
        this.router.navigate(['/feature/studio-dashboard/company'], {
          queryParams: {
            companyid: companyId,
          },
        });
        break;
      default:
        break;
    }
  }

  /**
   * openAddProjectModal() - method to open the add a project modal
   */
  openAddProjectModal(): void {
      window.scrollTo(0, 0);
      this.modalReference = this.modalService.open(AddProjectComponent, {
        windowClass: 'common-modal-xl',
        fullscreen: true
      });
      this.modalReference.componentInstance.modalTitle = this.projectText?.addUser;
      this.modalReference.componentInstance.editMode = false;
      this.modalReference.result.then(
        (result) => {
          if (result === 'refresh') {
            this.getProjectList();
          }
        },
        (dismissed) => {
          console.error(dismissed);
        }
      );
    }

  /**
   * getAddProjectUserMetadata() - Method to fetch the metadata for add project user screen
   */
  getAddProjectUserMetadata(): void {
    this.loaderService.setLoadingState(true);
    this.projectsService.fetchMetaData().subscribe((response: any) => {
      const metaData = response;
      this.departmentDetails = metaData?.getProjectUserMetaDataDTO?.departmentsDetails;
      this.userRoleDetails = metaData?.getProjectUserMetaDataDTO?.userRoleDetails;
      this.loaderService.setLoadingState(false);
    })
  }

  /**
  * openAddUserModal() - method to open the add a user modal
  */
  openAddUserModal(projectId: number, companyId: number, projectName: string): void {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddProjectUserComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true
    });
    this.modalReference.componentInstance.modalTitle = this.modalText.addUserTitle;
    this.modalReference.componentInstance.projectName = projectName;
    this.modalReference.componentInstance.departmentDetails = this.departmentDetails;
    this.modalReference.componentInstance.userRoleDetails = this.userRoleDetails;
    this.modalReference.componentInstance.portalType = PORTAL_TYPE.studioPortal;
    this.modalReference.componentInstance.projectId = projectId;
    this.modalReference.componentInstance.componentType = PORTAL_TYPE.studioTypeAdd;
    this.modalReference.componentInstance.projectCompanyId = companyId;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data.status == 'success') {
          this.requestResponse = data;
          this.showSuccess(this.successTpl);
        } else {
          this.requestResponse = data;
          this.showDanger(this.dangerTpl);
          console.error(data);
        }
      }
      // on close
    }, (reason) => {
      // on dismiss
    });
  }

  /**
   * getProfileId() - Method to get the profile id from the local
   */
  getProfileId(): void {
    const userInfo = JSON.parse(localStorage.getItem('user-profile'));
    this.permissionsData = { ...userInfo.roleAssigned };
    if(userInfo) {
      this.userProfileId = userInfo.userProfileId;
    } else {
      console.error(ERROR_MSG.userNotAvailable);
    }
  }

  /**
   *  getProjectList() - Method to fetch the projects data from API using projects service
   */
  getProjectList(): void {
    this.loaderService.setLoadingState(true);
    this.projectsService.getProjectsByUserProfileID(this.userProfileId)
    .pipe()
    .subscribe({
      next: (projects: any) => {
        this.projectData = projects?.projects?.projectList;      
        this.filterDataCreation(this.projectData);
        this.applyDefaultCompanySelected();
        this.applyFilters();
        this.sortByMostRecent(this.sortId);
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        console.error(ERROR_MSG.projectsList, error);
        this.requestResponse = error;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      }
    });
  }
   /**
   *  applyDefaultCompanySelected() - Method to apply default company selectcted filter
   */
   applyDefaultCompanySelected(): void {
    if(this.companyid != null){
      this.filteredCompany = [{ text: this.companyname}];  
      let value:any=[];
      this.company.forEach((item:any)=>{
        if(item.text == this.companyname)
          value.push(item)
      })
      if(value.length != 0){
        this.filteredCompany = [{ text: value[0].text }];
        this.defaultSelectedCompany=value;
      }else{
        this.filteredCompany = [{ text: this.companyname }];
      }
    }
  }
  /**
   *  filterDataCreation() - Method to create the project filters data and passing through the filter variables
   *  @param projects - All the available projects list
   */
  filterDataCreation(projects: any): void {
    let companyFilters = _.uniqBy(projects, 'company.companyName').map((project, index) => {
      return {
        id: project.projectId,
        text: project.company.companyName
      };
    });
    this.company = companyFilters.sort((a, b) => a.text.localeCompare(b.text));
    let projectFilters = _.uniqBy(projects, 'projectName').map((project, index) => {
      return {
        id: project.projectId,
        text: project.projectName
      };
    });
    this.projects = projectFilters.sort((a, b) => a.text.localeCompare(b.text));
    let typeFilters = _.uniqBy(projects, 'projectType.projectType').map((project, index) => {
      return {
        id: project.projectId,
        text: project.projectType.projectType
      };
    });
    this.types = typeFilters.sort((a, b) => a.text.localeCompare(b.text));
    let statusFilters = _.uniqBy(projects, 'projectStatus.projectStatus').map((project, index) => {
      return {
        id: project.projectId,
        text: project.projectStatus.projectStatus
      };
    });
    this.status = statusFilters.sort((a, b) => a.text.localeCompare(b.text));
    let projectIdFilter = _.uniqBy(projects, 'projectId').map((project, index) => {
      return {
        id: project.projectId,
        projectId: project.projectId
      };
    });
    this.projectIdFilter = projectIdFilter;
  }
  /**
*redirectViewCompany() - mthd used for redirect to view company page
*/
redirectViewCompany() : void {
  this.router.navigate(['/feature/studio-dashboard/company/companies']);
}

  /**
   * showSuccess() - Displays a success toast notification with custom content and options.
   * @param successTpl - The template reference containing the toast's content.
   */
  showSuccess(successTpl: TemplateRef<any>): void {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 5000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: any): void {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
  * addBookmark() - method to add the bookmark
  */
  addBookmark(data: any): void {
    const statusText = data?.projectStatus?.projectStatus === this.userListConstants.active ? this.userListConstants.statusActive : this.userListConstants.statusDeactive;
    const bookmarkPayload = {
      userProfileId: this.userProfileId,
      projectId: data?.projectId,
      status: statusText,
      projectBookmarked: BOOKMARK.one
    }
    this.loaderService.setLoadingState(true);
    this.projectsService.addBookmarkByUserAndProjectID(bookmarkPayload)
      .pipe()
      .subscribe({
        next: (response: any) => {
          this.requestResponse = response;
          this.getProjectList();
          this.isBookmarkAdded = true;
          this.loaderService.setLoadingState(false);
          this.showSuccess(this.successTpl);
        },
        error: (error) => {
          this.requestResponse = error;
          this.loaderService.setLoadingState(false);
          this.showDanger(this.dangerTpl);
          console.error(ERROR_MSG.bookmarkAddError, error);
        }
      })
  }

  /**
  * removeBookmark() - method to remove the bookmark
  */
  removeBookmark(data: any): void {
    const statusText = data?.projectStatus?.projectStatus === this.userListConstants.active ? this.userListConstants.statusActive : this.userListConstants.statusDeactive;
    const bookmarkPayload = {
      userProfileId: this.userProfileId,
      projectId: data?.projectId,
      status: statusText,
      projectBookmarked: BOOKMARK.zero
    }
    this.loaderService.setLoadingState(true);
    this.projectsService.removeBookmarkByUserAndProjectID(bookmarkPayload)
      .pipe()
      .subscribe({
        next: (response: any) => {
          this.requestResponse = response;
          this.getProjectList();
          this.isBookmarkAdded = false;
          this.loaderService.setLoadingState(false);
          this.showSuccess(this.successTpl);
        },
        error: (error) => {
          this.requestResponse = error;
          this.loaderService.setLoadingState(false);
          this.showDanger(this.dangerTpl);
          console.error(ERROR_MSG.bookmarkRemoveError, error);
        }
      })
  }
  getImageUrl(projectType: string): string {
    switch (projectType) {
      case 'Live':
        return 'assets/images/project-live.png';
        case 'Photoshoot':
          return 'assets/images/project-photoshoot.png';
      case 'Television':
        return 'assets/images/project-television.png';
      case 'Special Event':
        return 'assets/images/project-specialevent.png';
      case 'others':
        return 'assets/images/project-other.png';
      case 'Commercial':
        return 'assets/images/project-commercial.png';
      case 'Feature':
        return 'assets/images/project-feature.png';
        case 'Live Production/Rehearsal':
          return 'assets/images/project-live.png';
      default:
        return 'assets/images/project-image1.png';
    }
}
}