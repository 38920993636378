import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardFn } from '../core/services/auth-guard/auth-guard';
import { OktaAuthGuard } from '@okta/okta-angular';

const routes: Routes = [
  {
    path: 'customer-dashboard',
    loadChildren: () => import('./customer-portal/customer-portal.module').then(m => m.CustomerPortalModule),
    data: { roles: 'customer' },
    canActivate: [OktaAuthGuard, AuthGuardFn],
  },
  {
    path: 'studio-dashboard',
    loadChildren: () => import('./studio-portal/studio-portal.module').then(m => m.StudioPortalModule),
    data: { roles: 'studio' },
    canActivate: [OktaAuthGuard, AuthGuardFn],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeatureRoutingModule { }
