<div class="project-list-pagination-wrapper d-flex justify-content-between">
    <div class="nav-item-block d-flex">
        <p class="item-per-block">{{ paginationText.itemsperpage}}</p>
        <div class="btn-group">
            <div class="custom-dropdown">
                <select class="custom-select" (change)="onItemsPerPageChange($event)">
                  <option  class="dropdown-option"  *ngFor="let option of itemsPerPageOptions" [value]="option">
                    {{ option }}
                </option>
                </select>
              </div>
        </div>
    </div>
    <div class="page-navigation-block d-flex align-items-center gap-3">
        <div class="pagination-no" *ngIf="isLastPage && remainingItemsOnLastPage === 1">
            {{ totalItems }} {{ paginationText.of }} {{ totalItems }}
        </div>
        <div class="pagination-no" *ngIf="!isLastPage || remainingItemsOnLastPage !== 1">
            {{ (currentPage - 1) * itemsPerPage + 1 }} - {{ currentPage * itemsPerPage > totalItems ? totalItems :
            currentPage * itemsPerPage }} {{ paginationText.of }} {{ totalItems }}
        </div>
        <div class="pagenation-sect gap-3 d-flex">
            <img src="../../../../../assets/images/arrow-left.svg" alt="icon" (click)="prevPage()"
                [class.disabled]="currentPage === 1" />
            <img src="../../../../../assets/images/arrow-right.svg" alt="icon" (click)="nextPage()"
                [class.disabled]="currentPage * itemsPerPage >= totalItems" />
        </div>
    </div>
</div>