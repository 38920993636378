import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererParams } from 'ag-grid-community';
import * as bootstrap from 'bootstrap';
import { ACTION_MSG, COMPANY, ICONS_ACTION, ICONS_CASE, ICONS_RENDERED } from 'src/app/shared/constants/common';
import { AddNewProjectComponent } from '../../add-new-project/add-new-project.component';
import { AddCompanyUserComponent } from '../../add-company-user/add-company-user.component';
import { IconsService } from 'src/app/shared/services/icons/icons.service';
import { ToastService } from '../../toast.service';
import { rowData } from 'mockData/development/studio-users';
import { CompanyService } from 'src/app/feature/studio-portal/services/company.service';
import { AddProjectComponent } from 'src/app/feature/studio-portal/components/projects/add-project/add-project.component';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent {
  @ViewChild('confirmationModal', { static: false }) confirmationModal!: TemplateRef<any>;
  modalTitle: string = '';
  @Input() isUserDeactivated: boolean = false;
  // Constants and variables for icons rendering and company details
  iconsRenderer = ICONS_RENDERED;
  iconsAction = ICONS_ACTION;
  iconActionMsg = ACTION_MSG;
  iconCase = ICONS_CASE;
  company = COMPANY;
  currentRoute: string;
  showCompaniesIcon: any;
  showCompanyUserIcon: any;
  showCustomerProjectIcons: any;
  showCustomerCompanyIcons: any;
  gridData: any;
  showStudioPortalProjectUsersIcons: boolean = false;
  showStudioPortalUsersIcons: boolean = false;
  companyUserMetaData: any;
  addUserSuccess: any;
  addUserError: any;
  companyType: any;
  companyName: any;
  companyId: any;
  permissionsData: any;
  // Constructor to inject dependencies
  constructor(private router: Router, private modalService: NgbModal, private iconService: IconsService, private toastService: ToastService, private companyService: CompanyService) { }

  modalReference: NgbModalRef; // Reference to the modal opened
  disableCompany: boolean; // Flag to disable a company
  @Output() toastEvent = new EventEmitter(); // Event emitter for toast notifications
  /**
   * agInit() - Method to initialize ag-Grid cell renderer with parameters.
   * @param params - The params passed by ag-Grid to initialize the component.
   */
  agInit(params: ICellRendererParams): void {
    this.currentRoute = this.router.url; // Get the current route
    this.gridData = params.data;
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.permissionsData = { ...userProfile.roleAssigned };
    // Determine which icons to show based on the current route
    if (this.currentRoute == '/feature/studio-dashboard/company/companies') {
      this.companyId =  params.data.compid;
      this.companyName = params.data.companyName;
      this.companyType = params.data.companyType;

      this.companyUserMetaData = this.getCompanyUserMetaData(this.companyId); 
      this.showCompaniesIcon = !this.showCompaniesIcon;
    }
    else if (this.currentRoute.includes('/feature/studio-dashboard/company/company-user')) {
      this.showCompanyUserIcon = !this.showCompanyUserIcon;
    }
    else if (this.currentRoute.includes('/feature/customer-dashboard/company/companyuser')) {
      this.showCustomerCompanyIcons = true;
    }
    else if (this.currentRoute.includes('/feature/customer-dashboard/manage-users')) {
      this.showCustomerProjectIcons = true;
    }
    else if (this.currentRoute.includes('/feature/studio-dashboard/project/view-users')) {
      this.showStudioPortalProjectUsersIcons = true;
    }
    else if (this.currentRoute.includes('/feature/studio-dashboard/users')) {
      this.showStudioPortalUsersIcons = true;
    }
  }

  /**
   * refresh() - Method to refresh the component's content.
   * @param params - The params passed by ag-Grid.
   * @returns false - Currently, does not refresh the component.
   */
  refresh(params: ICellRendererParams): boolean {
    return false;
  }
  iconInfo: string;
  openConfirmationModal(action: string): void {
    switch (action) {
      case this.iconCase.freeze:
        this.modalTitle = this.iconActionMsg.freezeAccount;
        this.iconInfo = this.iconsAction.freezeAccount;
        break;
      case this.iconCase.deActivate:
        this.modalTitle = this.iconActionMsg.deActivate;
        this.iconInfo = this.iconsAction.deActivate;
        break;
      case this.iconCase.activate:
        this.modalTitle = this.iconActionMsg.activate;
        this.iconInfo = this.iconsAction.activate;
        break;
      case this.iconCase.remove:
        this.modalTitle = this.iconActionMsg.remove;
        this.iconInfo = this.iconsAction.remove;
        break;
      case this.iconCase.disableCompany:
        this.modalTitle = this.iconActionMsg.disableCompany;
        this.iconInfo = this.iconsAction.disableCompany;
        break;
      case this.iconCase.enableCompany:
        this.modalTitle = this.iconActionMsg.enableCompany;
        this.iconInfo = this.iconsAction.enableCompany;
      break;
    }

    const modalRef = this.modalService.open(this.confirmationModal, {
      windowClass: 'common-modal-center',
      centered: true,
      backdrop: 'static'
    });
  }

  handleAction(): void {
    switch (this.iconInfo) {
      case this.iconsAction.freezeAccount:
        this.freezeUser();
        break;
      case this.iconsAction.deActivate:
        this.deactivateUser();
        break;
      case this.iconsAction.activate:
        this.activateUser()
        break;
      case this.iconsAction.remove:
        this.removeUser()
        break;
      case this.iconsAction.disableCompany:
        this.confirmDisable()
      break;
      case this.iconsAction.enableCompany:
        this.confirmEnable()
      break;
    }
  }
  freezeUser() : void  {
    let data = {
      iconInfo: this.iconInfo,
      rowData: this.gridData
    };
    this.iconService.changeValue(data);
  }

  deactivateUser() : void  {
    this.isUserDeactivated = true;
    let data = {
      iconInfo: this.iconInfo,
      rowData: this.gridData,
      activeDeactieate: this.isUserDeactivated
    };
    this.iconService.changeValue(data);
    this.modalService.dismissAll();
  }

  activateUser() : void {
    this.isUserDeactivated = false
    let data = {
      iconInfo: this.iconInfo,
      rowData: this.gridData,
      activeDeactieate: this.isUserDeactivated
    };
    this.iconService.changeValue(data);
    this.modalService.dismissAll();
  }

  removeUser() {
    this.isUserDeactivated = true;
    let data = {
      iconInfo: this.iconInfo,
      rowData: this.gridData,
    };
    this.iconService.changeValue(data);
    this.modalService.dismissAll();
  }

   /**
   * confirmDisable() - Method to pass value to company screen when disable icon click
   *
   */
  confirmDisable() : void {
    this.disableCompany = true;
    let data = {
      iconInfo: this.iconInfo,
      rowData: this.gridData,
      disableCompany: this.disableCompany
    };
    this.iconService.changeValue(data);
    this.modalService.dismissAll();
  }
  /**
   * confirmEnable() - Method to pass value to company screen when enable icon click
   *
   */
  confirmEnable() : void {
    this.disableCompany = false;
    let data = {
      iconInfo: this.iconInfo,
      rowData: this.gridData,
      disableCompany: this.disableCompany
    };
    this.iconService.changeValue(data);
    this.modalService.dismissAll();
  }
  /**
   * confirmDelete() - Method to handle the confirmation of deletion.
   * Logs 'Deleted!!!' to the console upon confirmation.
   */
  confirmDelete(): void {
    this.iconService.changeValue(this.gridData);
    this.modalService.dismissAll();
  }


  /**
   * addProjectIcon() - Invokes the Add New Project modal.
   */
  addProjectIcon() : void {
    window.scrollTo(0,0);
    let title = this.company?.addnewproject;
    let componentType = this.company?.studioComponentType;
    let portalType = this.company?.studioPortalType;
    let  projectInfo = {};
    
      projectInfo = {
      company:{
        companyType:{
          companyType: this.companyType
        },
        companyName: this.companyName,
        companyId:this.companyId
      }
    }
    
   
    this.modalReference = this.modalService.open(AddProjectComponent, {windowClass: 'common-modal-xl',fullscreen: true
    });
    this.modalReference.componentInstance.modalTitle = title;
    this.modalReference.componentInstance.componentType = componentType;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.projectInfo = projectInfo;
  }

  /**
   * addUserIcon() - Invokes the Add User modal.
   */
  addUserIcon() : void  {
    window.scrollTo(0, 0); // Scroll to top of the page
    let title = this.company?.adduser; // Get the title for the modal
    let metaData = this.companyUserMetaData;
    let componentType = this.company?.studioComponentType;
    let portalType = this.company?.studioPortalType;
    let modalType = this.company?.modalAdd;
    this.modalReference = this.modalService.open(AddCompanyUserComponent,  {windowClass: 'common-modal-xl ',
      fullscreen: true
     });
     this.modalReference.componentInstance.title = title;
    this.modalReference.componentInstance.metaData = metaData;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.modalType = modalType;
    this.modalReference.componentInstance.componentType = componentType;
    this.modalReference.componentInstance.showSearchBarInput= false;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data.status == 'success') {
          this.addUserSuccess = data.message;
        } else {
          this.addUserError = data.message;
        }
      }
    });
  }
    
  /**
  *getCompanyUserMetaData() - getCompanyUserMetaData method gives list of project, department and other information that helps
  * to add the user in company.
  */

  getCompanyUserMetaData(companyId): void {
    this.companyService.getCompanyUsersMetadata(companyId).subscribe({
      next: (data: any) => {
        this.companyUserMetaData = data.companyUserMetaData;
      },
      error: (error: any) => {
      }
    })
  }
  }
