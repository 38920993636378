<div *ngIf="isAuthenticated">
    <app-menu [portalType]="portalType"></app-menu>
  </div>
  <div class="container-fluid page-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="page-title px-0">{{constants.maps}}</h1>
               
                <div class="d-flex align-items-center view-text ">
                    <div>
                            <p class="me-3 mb-0 change-weather-title-block">{{constants.you_are_viewing_the_directory_for}} <span class="change-weather-title">Burbank</span> <span class="change-weather-toggle"> <span>Change</span> <i class="bi bi-geo-alt-fill"></i>
                            </span></p>
                    </div>
                
            </div>
            </div>
            <div class="col-md-4 col-lg-4 d-none d-md-block">
                <ol class="handbook-list" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <li class="nav-link" id="main-lot-tab" role="tab" aria-controls="main-lot-content"
                        aria-selected="true" (click)="selectTab(constants.main_lot_tab) "  [ngClass]="{'show active': selectedTab === 'main-lot-tab'}">{{constants.main_lot}}
                    </li>
                    <li class="nav-link" id="ev-chargers" role="tab" aria-controls="ev-chargers-content"
                        aria-selected="false" (click)="selectTab(constants.ev_chargers_tab)" [ngClass]="{'show active': selectedTab === 'ev-chargers-tab'}">{{constants.ev_chargers}}</li>
                    <li class="nav-link" id="conference_rooms" role="tab" aria-controls="conference-rooms-content"
                        aria-selected="false" (click)="selectTab(constants.conference_rooms_tab)"  [ngClass]="{'show active': selectedTab === 'conference-rooms-tab'}">{{constants.conference_rooms}}
                    </li>
                    <li class="nav-link" id="ranch" role="tab" aria-controls="ranch-content" aria-selected="false"
                        (click)="selectTab(constants.ranch_tab)" [ngClass]="{'show active': selectedTab === 'ranch-tab'}">{{constants.ranch}} </li>
                </ol>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-12 d-block d-md-none mobile-select-block">
                <select class="form-select" id="mobileSelect" [(ngModel)]="selectedTab" (change)="onSelectChange($event)">
                  <option value="main-lot-tab">{{constants.main_lot}}
                  </option>
                  <option value="ev-chargers-tab">{{constants.ev_chargers}}
                  </option>
                  <option value="conference-rooms-tab">{{constants.conference_rooms}}
                  </option>
                  <option value="ranch-tab">{{constants.ranch}}
                  </option>
                </select>
              </div>
            <!-- Tab Content -->
            <div class="col-md-8 col-lg-8 col-sm-12">
                <div class="tab-content" id="v-pills-tabContent">
                    <!-- Main-lot -->
                    <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'main-lot-tab'}"
                        id="main-lot-tab" role="tabpanel" aria-labelledby="main-lot-tab">
                        <div class="page-details-section">
                            <h2 class="page-sub-heading">{{constants.main_lot}}</h2>
                            <ul class="maps-list">
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_1}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_2}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_3}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_4}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_5}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- EV-Chargers -->
                    <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'ev-chargers-tab'}"
                        id="ev-chargers-tab" role="tabpanel" aria-labelledby="ev-chargers-tab">
                        <div class="page-details-section">
                            <h2 class="page-sub-heading">{{constants.ev_chargers}}</h2>
                            <ul class="maps-list">
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_1}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_2}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_3}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_4}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_5}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- Conference-Rooms -->
                    <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'conference-rooms-tab'}"
                        id="conference-rooms-tab" role="tabpanel" aria-labelledby="conference-rooms-tab">
                        <div class="page-details-section">
                            <h2 class="page-sub-heading">{{constants.conference_rooms}}</h2>
                            <ul class="maps-list">
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_1}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_2}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_3}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_4}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_5}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- Ranch -->
                    <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'ranch-tab'}" id="ranch-tab"
                        role="tabpanel" aria-labelledby="ranch-tab">
                        <div class="page-details-section">
                            <h2 class="page-sub-heading">{{constants.ranch}}</h2>
                            <ul class="maps-list">
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_1}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_2}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_3}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_4}}</a>
                                </li>
                                <li>
                                    <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"
                                        alt="PDF icon">
                                    <a class="pdf-link">{{constants.map_5}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>