import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-review-cred-app',
  templateUrl: './review-cred-app.component.html',
  styleUrls: ['./review-cred-app.component.scss']
})
export class ReviewCredAppComponent {
  
  constructor(private modalService:NgbModal){}

  openDeleteConfirmationModal(content: TemplateRef<any>): void {
    this.modalService.open(content, { size: 'lg' , centered: true });
  }
  @ViewChild('fileInput') fileInput!: ElementRef;  // Reference to the file input element
  isFileOver = false;
  files: File[] = [];

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = false;

    if (event.dataTransfer?.files) {
      this.addFiles(event.dataTransfer.files);
    }
  }

  onFileSelect(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.addFiles(input.files);
    }
    this.resetFileInput();  // Reset input after handling the selection
  }

  addFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files[i]);
    }
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  // Reset the file input to allow re-uploading of the same files
  resetFileInput() {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }
}
