/**
 * File contains the TH Portal API Paths
 */

export const CUSTOMER_PORTAL_API_PATHS =
{
  /**User Microservices API */
  projects_list: 'customer-portal/projects',
  project_details: 'customer-portal/projects/advance-search/id',
  project_users: 'customer-portal/projects/users',
  project_user_search: 'customer-portal/projects/users/advance-search/id',
  project_users_metadata: 'customer-portal/projects/users/metadata',
  edit_project_description: 'customer-portal/projects/description',
  onTheLot: 'customer-portal/users/sessions/lots/metadata',
  activeProjects: 'customer-portal/projects/advance-search/status?',
  /**User Microservices API */
  company_user: 'customer-portal/companies/users',
  company_detail: 'customer-portal/companies',
  company_user_metadata: 'customer-portal/companies/users/metadata',
  updateOnTheLot: 'customer-portal/users/update',
  add_company_user: 'customer-portal/companies/users',
  add_project_user: 'customer-portal/projects/users',
  edit_project_user: 'customer-portal/projects/users',
  weather_forecast: 'customer-portal/weatherforecast',
  company_country: 'customer-portal/companies/metadata',
  company_user_search: 'customer-portal/companies/users/advanced-search/id',
  customer_company_update: 'customer-portal/companies',
  company_user_updatestatus: 'customer-portal/users/status',
  customer_update_company_user: 'customer-portal/companies/users',
  add_bookmark: 'customer-portal/spaceandresources/bookmark',
  remove_bookmark: 'customer-portal/spaceandresources/bookmark'

}

export const AR_PORTAL_API_PATHS = {

}

export const STUDIO_PORTAL_API_PATHS = {
  company_users: 'studio-ops/companies/users',
  company_metadata: 'studio-ops/companies/metadata',
  company_users_metadata: 'studio-ops/companies/users/metadata',
  project_users: 'studio-ops/projects/users',
  company_details_by_companyid: 'studio-ops/companies/advanced-search/id',
  company_add_update: 'studio-ops/companies',
  companies_list: 'studio-ops/companies',
  enable_company: 'studio-ops/companies/status/enable',
  disable_company: 'studio-ops/companies/status/disable',
  add_wb_user: 'studio-ops/users/wbso',
  add_customer_user: 'studio-ops/users/customer',
  edit_customer_user: 'studio-ops/users/customer',
  users_by_profile_id: 'studio-ops/companies/users/advanced-search/id',
  search_customer_user: 'studio-ops/companies/advance-search/all',
  add_wbso_user:'studio-ops/users/wbso',
  edit_wbso_user:'studio-ops/users/wbso',
  search_wbso_user:'studio-ops/wbsousers/advance-search/all',
  project_users_search: 'studio-ops/projects/users/advance-search/id',
  projects_project_list: 'studio-ops/projects',
  project_details_list: 'studio-ops/projects/advance-search/Id',
  project_details_add_bookmark: 'studio-ops/project/bookmark',
  project_details_remove_bookmark: 'studio-ops/project/bookmark',
  project_status_update: 'studio-ops/projects/status',
  Users_get: 'studio-ops/users/',
  project_company_list: 'studio-ops/projects/metadata',
  project_add_project: 'studio-ops/projects',
  project_update_project: 'studio-ops/projects',
  project_user_metadata: 'studio-ops/projects/users/metadata',
  deactivate_studio_company_user: 'studio-ops/company/users/update/status',
  activate_studio_company_user: 'studio-ops/company/users/update/status',
  add_project_user: 'studio-ops/projects/users',
  users_get_By_Id: 'studio-ops/companies/users/advanced-search/id?',
  search_wbso_user_id: 'studio-ops/users/advanced-search/wbso/id',
  search_customer_user_id: 'studio-ops/users/advanced-search/customers/id',
}

export const GLOBAL_API_PATHS = {
  auth_login: 'customer-portal/auth',
  user_profile: 'customer-portal/userprofiles',
  faq_Data: 'customer-portal/faq',
  faq_Post: 'customer-portal/faq',
  faq_edit: 'customer-portal/faq',
  faq_delete: 'customer-portal/faq',
  user_info: 'customer-portal/userprofile',
  user_profile_meta_data: 'customer-portal/userprofile/metadata',
  deactivate_user: 'customer-portal/users/status',
  activate_user: 'customer-portal/users/status',
  clear_cache: 'customer-portal/users/logout',
  deactivate_studio_project_user: 'studio-ops/users/status',
  activate_studio_project_user: 'studio-ops/users/status',
  remove_studio_project_user: 'studio-ops/projects/users/remove',
  remove_customer_project_user: 'customer-portal/projects/users/remove'
}
