import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-request',
  templateUrl: './customer-request.component.html',
  styleUrls: ['./customer-request.component.scss']
})
export class CustomerRequestComponent {
  @ViewChild('requestModal') requestModal: TemplateRef<any>;
  isNextEnabled: boolean = false;

  constructor(private modalService: NgbModal, private router: Router) {}

  openRequestModal(): void {
    this.modalService.open(this.requestModal, { fullscreen: true });
  }

  onRadioChange(event: Event): void {
    this.isNextEnabled = (event.target as HTMLInputElement).checked;
  }

  navigateToSalesforce(): void {
    this.modalService.dismissAll();
    this.router.navigate(['/feature/customer-dashboard/salesforce-path']);
  }
}
