<ng-template #dangerTpl>
	<div class="danger">Cras mattis consectetur purus sit amet fermentum.</div>
</ng-template>
<ng-template #successTpl>
	<div class="success">Cras mattis consectetur purus sit amet fermentum.</div>
</ng-template>
<ng-template #successActionTpl>
    <div class="total-action-content  d-flex flex-column align-items-start">
        <div class="inner-content">
            Cras mattis consectetur purus sit amet fermentum.
        </div>
        <div class="action-content">
           <span class="action-1">Action-1</span>
           <span class="action-2">Action-1</span>
        </div>
    </div>
    </ng-template>
    <ng-template #dangerActionTpl>
    <div class="total-action-content-danger d-flex flex-column align-items-start">
        <div class="inner-content-danger">
            Cras mattis consectetur purus sit amet fermentum.
        </div>
        <div class="action-content-danger">
           <span class="action-3">Action-1</span>
           <span class="action-4">Action-1</span>
        </div>
    </div>
    </ng-template>
    
    <button class="btn btn-danger" (click)="showDanger(dangerTpl)" ngbTooltip="Will disappear in 15s">Danger toast</button>
    
            <button class="btn btn-primary" (click)="showSuccess(successTpl)">hello</button>
            <button class="btn btn-primary" (click)="showSuccessAction(successActionTpl)">Success-action</button>

            <button class="btn btn-primary" (click)="showDangerAction(dangerActionTpl)">Danger-action</button>
    
        <app-toast aria-live="polite" aria-atomic="true"></app-toast>