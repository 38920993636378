import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/shared/services/base-api/base-api.service';
import { CUSTOMER_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';

@Injectable({
  providedIn: 'root'
})
export class ProjectUsersService {

  constructor(private baseApiService: BaseApiService) { }

  /**
  * getProjectUser() - Fetches project user details based on the provided project ID and company ID.
  * @param projectId - The ID of the project.
  * @param companyId - The ID of the company.
  * @returns {Observable<any>} - The API response containing the project user details.
  */
  getProjectUser(projectId: number, companyId: number): any {
    const url = `${CUSTOMER_PORTAL_API_PATHS.project_users}?projectId=${projectId}&companyId=${companyId}`;
    return this.baseApiService.get(url);
  }

  /**
 * getProjectUserById() - Fetches the details of a specific project user by company ID, project ID, and user profile ID.
 * @param companyId - The ID of the company.
 * @param projectId - The ID of the project.
 * @param userProfileId - The ID of the user's profile.
 * @returns {Observable<any>} - The API response containing the specific project user's details.
 */
  getProjectUserById(companyId, projectId, userProfileId) {
    let endpoint = `${CUSTOMER_PORTAL_API_PATHS.project_user_search}?companyId=${companyId}&projectId=${projectId}&userProfileId=${userProfileId}`;
    return this.baseApiService.get(endpoint);
  }

  /**
* fetchMetaData() - Method to fetch metadata related to project users.
* @returns - The API response containing the metadata.
*/
  fetchMetaData() {
    let endpoint = `${CUSTOMER_PORTAL_API_PATHS.project_users_metadata}`;
    return this.baseApiService.get(endpoint);
  }
}
