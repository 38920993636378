import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-review-step-one',
  templateUrl: './credit-review-step-one.component.html',
  styleUrls: ['./credit-review-step-one.component.scss']
})
export class CreditReviewStepOneComponent {

}
