<button data-bs-toggle="modal" data-bs-target="#content" (click)="openContentModal(content)">Approve</button>
<button data-bs-toggle="modal" data-bs-target="#contentdeny" (click)="openContentModalDeny(contentdeny)">Deny</button> 
<ng-template #content let-modal>
    <div class="page-wrapper-modal">
        <div class="page-header">
            <h2>Approve AR # Request</h2>
        </div>
        <div class="title-company-project">
            <div class="company">
              <span class="company-heading">Company:</span>
              <span class="company-content">A Brand New Production Company</span>
            </div>
            <div class="project mb-3">
              <span class="project-heading">Project:</span>
              <span class="project-content">The New Show</span>
            </div>
            <h5 class="mb-0">Select Reason:</h5>
          </div>
          <div class="container">
            <div class="internal-use-label mb-3">FOR INTERNAL USE ONLY</div>
          <div class="common-checkbox-section">
            <div class="row">
                <div class="col-md-12">
                    <div class="common-checkbox-button mb-2">
                      <input
                        type="checkbox"
                        id="custom-checkbox-0"
                        class="common-checkbox-input"
                      />
                      <label for="custom-checkbox-0" class="common-checkbox-label">
                        Lorem Ipsum
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="common-checkbox-button mb-2">
                      <input
                        type="checkbox"
                        id="custom-checkbox-1"
                        class="common-checkbox-input"
                      />
                      <label for="custom-checkbox-1" class="common-checkbox-label">
                        Lorem Ipsum
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="common-checkbox-button mb-2">
                      <input
                        type="checkbox"
                        id="custom-checkbox-1"
                        class="common-checkbox-input"
                      />
                      <label for="custom-checkbox-1" class="common-checkbox-label">
                        Other
                      </label>
                    </div>
                  </div>
            </div>
          </div>
        </div>
    </div>
    <div class="end-wrapper">
        <div class="button">
          <button class="common-btn-light btn">Cancel</button>
          <button class="common-btn  btn-approve">Approve</button>
        </div>
      </div>
</ng-template>
<ng-template #contentdeny let-modal>
    <div class="page-wrapper-modal">
        <div class="page-header">
            <h2>Deny AR # Request</h2>
        </div>
        <div class="title-company-project">
            <div class="company">
              <span class="company-heading">Company:</span>
              <span class="company-content">A Brand New Production Company</span>
            </div>
            <div class="project mb-3">
              <span class="project-heading">Project:</span>
              <span class="project-content">The New Show</span>
            </div>
            <h5 class="mb-0">Select Reason:</h5>
          </div>
          <div class="container">
            <div class="internal-use-label mb-3">FOR INTERNAL USE ONLY</div>
          <div class="common-checkbox-section">
            <div class="row">
                <div class="col-md-12">
                    <div class="common-checkbox-button mb-2">
                      <input
                        type="checkbox"
                        id="custom-checkbox-0"
                        class="common-checkbox-input"
                      />
                      <label for="custom-checkbox-0" class="common-checkbox-label">
                        Lorem Ipsum
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="common-checkbox-button mb-2">
                      <input
                        type="checkbox"
                        id="custom-checkbox-1"
                        class="common-checkbox-input"
                      />
                      <label for="custom-checkbox-1" class="common-checkbox-label">
                        Lorem Ipsum
                      </label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="common-checkbox-button mb-2">
                      <input
                        type="checkbox"
                        id="custom-checkbox-1"
                        class="common-checkbox-input"
                      />
                      <label for="custom-checkbox-1" class="common-checkbox-label">
                        Other
                      </label>
                    </div>
                  </div>
            </div>
          </div>
        </div>
    </div>
    <div class="end-wrapper">
        <div class="button">
          <button class="common-btn-light btn">Cancel</button>
          <button class="common-btn btn-deny">Deny</button>
        </div>
      </div>
</ng-template>
