<div class="bookmarked-project">
  <div class="comman-panel-block ">
    <div class="panel-heading">
      <h2>{{bookmarkConstant.bookmarkProjects}}</h2>
    </div>
  </div>
  <div class="panel-body scroll-vertical">
    <div class="bookmark-main-wrapper" *ngFor="let projects of bookmarkData">
      <div class="bookmark-project-wrapper">
        <div class="project-desc">
          <div class="row ">
            <div class="col-10 d-flex">
              <div class="project-image-block">
                <img src="{{projects?.imageUrl}}" onerror="this.src='../../../../../../assets/images/project-image1.png'"
                  alt="active-project-image" class="active-project-image img-fluid">
              </div>
              <div class="project-info-block">
                <h5>{{projects?.projectName}}</h5>
                <p class="season">{{projects?.seasonNumber}}</p>
                <p class="prod-name">{{projects?.company?.companyName}}</p>
              </div>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-end">
              <div class="project-year">
                <p>{{projects?.year}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bookmark-dropdown-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-6 col-sm-6 col-md-6 col-lg-6 px-0">
              <div class='custom-dropdown'>
                <select class="custom-select" (change)="onSelectChange($event, userProfileId, projects?.projectId, projects?.company?.companyId, projects?.company?.companyName, projects?.projectName)">
                  <option class="dropdown-option" value="" disabled selected hidden>{{bookmarkConstant.goTo}}</option>
                  <option class="dropdown-option" value={{bookmarkConstant.one}}>{{bookmarkConstant.projects}}</option>
                  <option class="dropdown-option" value={{bookmarkConstant.two}}>{{bookmarkConstant.users}}</option>
                  <option class="dropdown-option" value={{bookmarkConstant.three}}>{{bookmarkConstant.company}}</option>
                </select>
              </div>
            </div>
            <div class="col-2 col-sm-2 col-md-2 col-lg-2 d-flex align-items-center">
              <app-pop-up-cell-renderer [projectData]="[projects]" (successResponseMsg)="responseSuccessMessage($event)" (dangerResponseMsg)="responseErrMessage($event)"></app-pop-up-cell-renderer>
            </div>
            <div class="col-4 col-sm-4 col-md-4 col-lg-4 pe-0 bookmark-status">
              <span class="project-status-tag"
                [ngClass]="{'active-tag-green':projects?.projectStatus?.projectStatus==bookmarkConstant.active,'not-started-purple':projects?.projectStatus?.projectStatus==bookmarkConstant.notStarted,'closed-tag-black':projects?.projectStatus?.projectStatus==bookmarkConstant.closed}">{{projects?.projectStatus?.projectStatus}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #successTpl>
	<div class="success">{{requestResponse}}</div>
</ng-template>

<ng-template #dangerTpl>
	<div class="danger">{{requestResponse}}</div>
</ng-template>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>
