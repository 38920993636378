import { Component } from '@angular/core';

@Component({
  selector: 'app-credstatus-pending-risk',
  templateUrl: './credstatus-pending-risk.component.html',
  styleUrls: ['./credstatus-pending-risk.component.scss']
})
export class CredstatusPendingRiskComponent {

}
