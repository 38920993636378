
<div class="container-fluid">
  <div class="error-msg-block">
    <!-- Embed the Salesforce form directly in the page -->
    <iframe 
      src="https://d5j000008xeaseai-dev-ed.my.salesforce-sites.com/GreenLight" 
      height="500" 
      width="100%" 
      title="Salesforce Form" 
      frameborder="0">
    </iframe>
  </div>
</div>


<!-- <!DOCTYPE html>
<html lang="en">
<head>
<meta charset="utf-8" />
<meta name="viewport" content="width=device-width, initial-scale=1" />
</head>
<body>

    <div id="lightningout">Greenlight</div>

    <script src="https://d5j000008xeaseai-dev-ed.my.salesforce.com/lightning/lightning.out.js"></script>
<script>
  const accessToken = '00D5j000008xeAs!AQ0AQBUBbd84JkfBRQ15U8bizvPYLBthJDjUcWTPei439VAu982AuTboU5el1jHvx4WFkdujv.2KAIO7O2iC68vNqF6gtptY';
      $Lightning.use(
        'c:LightningOutDependencyApp', // name of the Lightning app
        function () {
          // Callback once framework and app loaded
          $Lightning.createComponent(
            'c:hotLockStageRequest', // top-level component of your app
            {}, // attributes to set on the component when created
            'lightningout', // the DOM location to insert the component
            function (cmp) {
              // callback when component is created and active on the page
            }
          );
        },
        'https://d5j000008xeaseai-dev-ed.lightning.force.com',
        accessToken
      );
</script>
</body>
</html> -->