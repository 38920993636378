import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  GridApi,
  GridOptions,
  ICellRendererParams,
} from 'ag-grid-community';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-studio-dashboard',
  templateUrl: './studio-dashboard.component.html',
  styleUrls: ['./studio-dashboard.component.scss']
})
export class StudioDashboardComponent {
  @ViewChild('requestGrid') requestGrid!: AgGridAngular;

  @ViewChild('creditGrid') creditGrid!: AgGridAngular;
  gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      filter: true
    },
  };

  rowDataRequest = [
    { date: '1/11/24', request: 'Publicity Clearance', projectName: 'Company Name', status: 'NEW REQUEST' },
    { date: '1/11/24 ', request: 'WBSO Pass Request', projectName: 'Company Name', status: 'IN PROGRESS' },
    { date: '1/11/24 ', request: 'Publicity Clearance', projectName: 'Company Name', status: 'RESOLVED' },
  ];
  colDefsRequest = [
    {
      headerName: '',
      field: 'date',
      wrapText: true,
      autoHeight: true,
      width: 100,
      hide: true,
      maxWidth: 110,
      cellClass: 'date-request'
    },
    {
      headerName: '',
      field: 'merged-field',
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const status = params.data.status;
        const statusClassRequest = this.getStatusClassRequest(status);
        const request = params.data.request;
        const date = params.data.date;
        const projectName = params.data.projectName;
        return `<div class="common-split-cell-section">
                   <div class="d-flex justify-content-between">   
        <div class="common-split-cell-data-1 mb-0">${request} </div>
          
        <div class="request-status-tag ${statusClassRequest} w-auto">${status}</div>
        </div>
                  <div class="common-split-cell-data-2 pt-0 pb-2" >${date}</div>
                    <div class="common-split-cell-data-2 pb-common">Project Name:${projectName}</div>
                </div>`;
      },
      minWidth: 150,
      flex: 2,

    },
    {
      headerName: '',
      field: 'request',
      cellRenderer: (params: ICellRendererParams) => {
        const request = params.data.request;
        const projectName = params.data.projectName;
        return `<div class="common-split-cell-section">
                  <div class="common-split-cell-data-1 common-cell-data-bold">${request}</div>
                  <div class="common-split-cell-data-2 ">Project Name : ${projectName}</div>
                </div>`;
      },
      minWidth: 150,

      flex: 2
    },
    {
      headerName: '',
      field: 'status',
      cellRenderer: this.statusCellRendererRequest.bind(this),
      cellClass: 'account-user-common-status-cell cell-right-align ',
      minWidth: 150,
      hide: true,
    },
  ];


  /**
   * statusCellRenderer() - Renders a cell with the appropriate status class and value.
   * @param params - Parameters passed by ag-Grid to the cell renderer. Includes data and context information.
   */
  statusCellRendererRequest(params: ICellRendererParams) {
    const statusClass = this.getStatusClassRequest(params.value);
    return `
       <div class="status-container move-right">
      <div class="request-status-tag  ${statusClass}">${params.value}</div>
      </div>
  `;
  }

  /**
   * getStatusClass() - Returns the appropriate class for each status value
   * @param status - The status string to get class for
   */
  getStatusClassRequest(status: string): string {
    switch (status) {
      case 'NEW REQUEST':
        return 'new account-user-common-status-cell cell-right-align';
      case 'IN PROGRESS':
        return 'inprogress-color account-user-common-status-cell';
      case 'CLOSED':
        return 'closed account-user-common-status-cell';
      case 'ASSIGNED':
        return 'assigned-color account-user-common-status-cell';
      case 'RESOLVED':
        return 'resolved-color account-user-common-status-cell';
      default:
        return '';
    }
  }


  //Credit Application Component
  rowDataCredit = [
    {
      date: '1/11/24 ',
      arNo: '123456',
      department: 'Department Name; Department Name; Department Name',
      lineofCredit: '$1,000,000,00',
      projectName: 'Company Name',
      status: 'NEW APPLICATION',

    },
    {
      date: '1/11/24 ',
      arNo: '----',
      department: 'Studio Operations; Transportation; Design Studio; Post Production Creative Services; Property; Production Catering,',
      lineofCredit: '$1,000,000,00',
      projectName: 'Company Name',
      status: 'UNDER REVIEW',
    }
  ];

  colDefsCredit = [
    {
      headerName: '',
      field: 'date',
      wrapText: true,
      autoHeight: true,
      minWidth: 100,
      cellClass: 'credit-date',
      cellStyle: { padding: '24px 10px 24px 24px' }
    },
    {
      headerName: '',
      field: 'arNo',
      wrapText: true,
      autoHeight: true,
      minWidth: 100,
      cellClass: 'credit-date',
      cellStyle: { padding: '24px 20px 0px 0px' },
      cellRenderer: (params: ICellRendererParams) => {
        const arNo = params.data.arNo;
        return `  
     <div class="common-split-cell-section">
     <div class="common-split-cell-section-1 common-cell-data-bold ">AR</div>
          <div class=" common-split-cell-section-1 common-cell-data-bold lh-1">${arNo}</div></div> `;
      }
    },

    {
      headerName: '',
      field: 'department',
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const department = params.data.department;
        const projectName = params.data.projectName;
        return `<div class="cred-dept-coloum">   <div title="${department}" class="credit-department-table-section common-cell-data-bold ">      
        ${department}</div>
        <div class="lh-base mb-2 ellipsis-email">Project Name : ${projectName}</div>
        </div>`;
      },
      minWidth: 235,
      cellStyle: { padding: '0px 10px 24px 0px' },
    },
    {
      headerName: '',
      field: 'merged-cell',
      wrapText: true,
      autoHeight: true,
      minWidth: 160,
      flex: 3,
      cellRenderer: (params: ICellRendererParams) => {
        const status = params.data.status;
        const statusClass = this.getStatusClass(status);

        const date = params.data.date;
        const arNo = params.data.arNo;
        const lineofCredit = params.data.lineofCredit;
        const department = params.data.department;
        const projectName = params.data.projectName;
        return `<div class="common-split-cell-section credit-app-sm-screen">
        <div class="d-flex justify-content-between">   
        <div class="common-split-cell-data-1 m-16">${arNo} </div>
          
        <div class="ar-status-tag ${statusClass} w-auto">${status}</div>
        </div>
      
                <div class="common-split-cell-data-2 m-16">${date}</div>
                 <div title="${department}" class="common-split-cell-data-2 m-16 ">
                 <span class="common-cell-data-bold w-auto">${department}</span><div>Project Name:${projectName}</div></div>
                 <div class="common-split-cell-data-2 m-16"><span class="common-cell-data-bold">Line of Credit:</span> ${lineofCredit}</span>
              </div>`;

      }, cellStyle: { padding: '24px 24px 24px 24px' }
    },
    {
      headerName: '',
      field: 'lineofCredit',
      wrapText: true,
      autoHeight: true,
      minWidth: 120,
      flex: 2,
      cellRenderer: (params: ICellRendererParams) => {
        const lineofCredit = params.data.lineofCredit;
        return `<div class="common-split-cell-section">      
   <div class="common-split-cell-section-1 common-cell-data-bold lh-1">Line of Credit:</div>
     <div class="common-split-cell-section-2 lh-2"> ${lineofCredit}</div>   
      </div>`;

      },cellStyle: { padding: '0px 10px 0px 10px' },
    },
    {
      headerName: '',
      field: 'status',
      cellRenderer: this.statusCellRenderer.bind(this),
      cellClass: 'account-user-common-status-cell cell-right-align',
      minWidth: 165,
      cellStyle: { padding: '0px 24px 14px 10px' },
    },
  ];
  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.value);
    return `
         <div class="">
        <div class="ar-status-tag ${statusClass} w-auto">${params.value}</div>
        </div>
    `;
  }

  /**
   * getStatusClass() - Returns the appropriate class for each status value
   * @param status - The status string to get class for
   */
  getStatusClass(status: string): string {
    switch (status) {
      case 'NEW APPLICATION':
        return 'ar-status-tag new-application account-user-common-status-cell';
      case 'UNDER REVIEW':
        return 'ar-status-tag  under-review account-user-common-status-cell';
      case 'REVISION':
        return 'ar-status-tag revision account-user-common-status-cell';
      case 'NEEDS APPROVAL':
        return 'ar-status-tag  assigned-color needs-approval account-user-common-status-cell';
      case 'APPROVED':
        return 'ar-status-tag  resolved-color approved account-user-common-status-cell';
      case 'DENIED':
        return 'ar-status-tag  resolved-color denied account-user-common-status-cell';
      case 'Start Application':
        return 'ar-status-tag  start-application account-user-common-status-cell start-app';
      default:
        return '';
    }
  }
  gridApi!: GridApi;
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.adjustColumns(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustColumns(event.target.innerWidth);
  }

  adjustColumns(screenWidth: number) {
    // Adjust for request grid
    const requestGridApi = this.requestGrid.api;
    if (requestGridApi) {
      const requestColumnIds = ['date', 'merged-field', 'request', 'status'];
      if (screenWidth <= 510) {
        requestGridApi.setColumnsVisible(requestColumnIds, false);
        requestGridApi.setColumnsVisible(['merged-field'], true);
      } else {
        requestGridApi.setColumnsVisible(requestColumnIds, true);
        requestGridApi.setColumnsVisible(['merged-field'], false);
      }
    }



    // Adjust for credit grid
    const creditGridApi = this.creditGrid.api;
    if (creditGridApi) {
      const creditColumnIds = ['date', 'arNo', 'department', 'status', 'lineofCredit'];
      if (screenWidth <= 378) {
        creditGridApi.setColumnsVisible(creditColumnIds, false);
        creditGridApi.setColumnsVisible(['merged-cell'], true);
      } else if (screenWidth <= 992) {
        creditGridApi.setColumnsVisible(creditColumnIds, false);
        creditGridApi.setColumnsVisible(['merged-cell'], true);
      } else {
        creditGridApi.setColumnsVisible(creditColumnIds, true);
        creditGridApi.setColumnsVisible(['merged-cell'], false);
      }
    }
  }

}