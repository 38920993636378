<div *ngIf = "permissionsData['requestWidget']" class="request-wrapper">
  <div class="container">
    <div class="row">
      <div class="comman-title-block">
        <div class="comman-title-section">
           <h1>Requests</h1>
          <button class="common-btn-primary btn">+ Add a request</button>
        </div>
    </div>
    <div class="comman-title-block">
      <div class="comman-filter-block">
          <div class="comman-filter-title-section mb-0 pb-0">
              <p>Filters</p>
          </div>
          <div class="comman-filter-section">
              <div class="btn-group dropdown-center">
                  <button class="btn custom-default-filter-1  " type="button">
                      Dates
                  </button>
                  <button type="button"
                      class="btn btn-sm custom-default-filter-toggle  dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <span class="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#">Action</a></li>
                      <li><a class="dropdown-item" href="#">Another action</a></li>
                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                      <li>
                          <hr class="dropdown-divider">
                      </li>
                      <li><a class="dropdown-item" href="#">Separated link</a></li>
                  </ul>
              </div>
              <div class="btn-group">
                  <button class="btn custom-default-filter-2 " type="button">
                     Request Types
                  </button>
                  <button type="button"
                      class="btn btn-sm custom-default-filter-toggle dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <span class="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#">Action</a></li>
                      <li><a class="dropdown-item" href="#">Another action</a></li>
                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                      <li>
                          <hr class="dropdown-divider">
                      </li>
                      <li><a class="dropdown-item" href="#">Separated link</a></li>
                  </ul>
              </div>
              <div class="btn-group">
                  <button class="custom-default-filter-3 " type="button">
                      Added By
                  </button>
                  <button type="button"
                      class="btn btn-sm custom-default-filter-toggle  dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <span class="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#">Action</a></li>
                      <li><a class="dropdown-item" href="#">Another action</a></li>
                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                      <li>
                          <hr class="dropdown-divider">
                      </li>
                      <li><a class="dropdown-item" href="#">Separated link</a></li>
                  </ul>
              </div>
          </div>
       
      </div>
  </div>  
    
    <div class="common-grid-section">
        <div class="common-grid-block">
          <div class="mt-4">
            <ag-grid-angular
              style="width: 100%; height: 100%;"
              class="ag-theme-custom my-grid"
              [gridOptions]="gridOptions"
              [columnDefs]="columnDefs"
              [rowData]="rowData"
              (gridReady)="onGridReady($event)"
              (paginationChanged)="updatePagination()">
            </ag-grid-angular>
          </div>
        </div>
      </div>  
      </div>    
    <div class="common-pagination-section">
        <div class="common-pagination-controls">
          <div class="items-per-page">
            <label for="page-size" class="per-page">Items per page:</label>
            <select id="page-size" (change)="onPageSizeChanged($event)">
              <option *ngFor="let size of pageSizeOptions" [value]="size" class="size-per-page">{{size}}</option>
            </select>
          </div>
          <div class="common-pagination-navigation-block ">
              <div class="pagination-no align-self-center">{{startRow}} - {{endRow}} of {{totalRows}}
                  <span class="paginator-arrow-icons align-self-center">
                     <a class="arrow-1" (click)="onBtPrevious()"> <img src="../../assets/images/arrow-left.svg" alt="icon" /></a>
                     <a class="arrow-1" (click)="onBtNext()"><img src="../../assets/images/arrow-right.svg" alt="icon" /></a> 
                  </span>
              </div>
          </div>
        </div>
  </div>
</div>
  </div>
