export enum PAGINATION_PAGES {
    one = 1
};

export enum ITEMS_PER_PAGE {
    ten = 10,
    twenty = 20,
    thirty = 30,
    fourty = 40,
    fifty = 50,
};

export enum COMMON_GRID_BREAKPOINTS {
    threeSeventyEight = 378,
    fiveHundredAndTen = 510,
    fiveHundredAndEleven = 511,
    nineNintyTwo = 992,
    fiveHundredThirtyEight=538
};

export enum TYPEAHEAD {
  debounceTime = 300,
};
export enum FORMAT_TIME {
    PM = 'PM',
    AM = 'AM',
    zero = 0,
    two = 2,
    twelve = 12,
    one = 1
};
export enum COMPANY_FILTER {
    two = 2,
    zero = 0,
    ten = 10,
    one = 1
};

export enum INDEX {
    zero = 0,
    one = 1,
    two = 2,
    three = 3,
    four = 4,
    minusTwo = -2,
    seven = 7
  }

  export enum DEACTIVATE{
    six=6,
    one=1
  }