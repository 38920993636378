<div class="all-projects-wrapper"><div class="container px-0">
  <div class="container px-0">
      <div class="row pb-0 pt-0 px-0 mx-0 all-project-title-block">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 mx-0">
              <div class="all-project-link-section">
                  <img src="../../assets/images/keyboard_arrow_left.svg" alt="keyboard-arrow-left" />
                  <p class="all-project-link-name mb-0">Projects</p>
              </div>
              <div class="all-project-title-section">
                  <p class="all-project-title-name mb-0">The Court- Season 1
                  </p>
                  <span class="mb-3" class="company-title"><span class="company-label">Company : </span>A Brand New Production Company</span>
              </div>
          </div>
          <div class="project-resource-wrapper wrapper-left col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
              <div class="all-project-status-widget-block">
                  <div class="card px-0">

                      <div class="row  align-items-center">
                          <div class="col-12 px-0">
                              <span class="project-status-tag active-tag-green">Active</span>
                          </div>
                          <div class="col-12 col-sm-4 col-md-3 col-lg-12 project-info-section">
                              <div class="project-info">Project Id #: <div class="project-value">123567</div></div>
                          </div>
                          <div class="col-12 col-sm-8 col-md-3 col-lg-12 project-info-section">
                            <div class="project-info">Type:
                                <div class="project-value">Television
                                    <span>
                                        <img src="../../assets/images/insert drivefile.svg" class=""
                                alt="insert drive" />
                            </span>
                        </div>
                    </div>
                        </div>
                          <div class="col-12  col-sm-6 col-md-4 col-lg-12  project-info-section">
                              <div class="project-info mb-0">Dates: <div class="project-value">1/29/24 - 2/24/24
                                <span (click)="openScheduledNote(date)"><img src="../../assets/images/insert drivefile.svg" class=""
                                    alt="insert drive"></span></div>
                                </div>
                          </div>
                          <div class="col-12 col-sm-4 col-md-2 col-lg-12 project-info-section">
                            <div class="project-info">Customer: <div class="project-value">3rd Party</div></div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-12 col-lg-12 project-info-section">
                            <div class="project-info">Primary Payment: <div class="project-value">A/R</div></div>
                        </div>

                          <div class="col-12 col-sm-4 col-md-3 col-lg-12 project-info-section ">
                              <div class="project-info mb-0 ">A/R #: <div class="project-value">123567</div></div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="card all-project-season-block">
                  <div class="container px-0 mx-0">
                      <div class="row mx-0">
                          <div class="all-project-season-image-section col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12">
                              <div class="image-wrapper">
                            <img src="../../assets/images/project-image2.png" class="all-project-season-image img-fluid"
                                  alt="project-image">
                              <img src="../../../../assets/images/image-edit-icon.svg.svg"
                                  class="all-project-season-edit-image" alt="edit-image-icon">
                          </div>
                        </div>
                          <div class="card-body col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12">
                              <div class="all-project-season-desc-section">
                                  <h6 class="all-project-season-subtitle">Description :</h6>
                                  <p class="all-project-season-desc ">Lorem, ipsum dolor sit amet
                                      consectetur
                                      adipisicing
                                      Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non
                                      magna.
                                      Vestibulum
                                      id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet
                                      rutrum
                                      faucibus
                                      dolor auctor.</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="mb-3"><a class="edit-project-info "> <img src="../../assets/images/action-bookmark.svg" class="edit-img"
                  alt="bookmark project"> Bookmark Project</a>
              </div>
              <div class="mb-3"><a class="edit-project-info" (click)="openProjectStatus(status)" > <img src="../../assets/images/edit-icon.svg" class="edit-img"
                alt="edit project status"> Edit Project Status</a>
            </div>
            <div class="mb-3"><a class="edit-project-info "  (click)="openEditProjectDetails(content)"> <img src="../../assets/images/edit-icon.svg" class="edit-img"
                alt="edit project-info"> Edit Project Information</a>
            </div>
          </div>
          <div class="project-resource-wrapper wrapper-right col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 ">
              <div class="comman-tabs-block">
                  <ul class="nav" role="tablist">
                      <li class="nav-item">
                          <a class="nav-link active" aria-current="page" data-bs-toggle="tab"
                              href="#credit-tabpanel-0" role="tab" aria-selected="true">Credit Applications</a>
                      </li>
                      <li class="nav-item">
                          <a class="nav-link" data-bs-toggle="tab" href="#project-users-tabpanel-1" role="tab"
                              aria-controls="fill-tabpanel-1">Project Users</a>
                      </li>
                      <li class="nav-item">
                          <a class="nav-link" data-bs-toggle="tab" href="#space-resource-tabpanel-2" role="tab"
                              aria-controls="fill-tabpanel-1">Spaces &
                              Resources</a>
                      </li>
                  </ul>
              </div>

              <div class="tab-content" id="tab-content">
                  <!---credit user tab-->
                  <div class="tab-pane  credit-app-tab active " id="credit-tabpanel-0" role="tabpane-0"
                      aria-labelledby="fill-tab-1">
                      <div class="container px-0 mx-0">
                          <div class="all-projects-spaces-resources-title">
                              <h2>Credit Applications(2)</h2>
                            
                          </div>
                          <!--  Make request -->
                          <div class="row all-projects-credit-AR-section">
                              <div class="col-12 all-projects-credit-app-subtitle">
                                 <div class="subtitle-block">
                                    <div class="ar-id">A/R # <span class="ar-id-no">84745</span>

                                    </div>
                                    <button class="common-btn-sent btn">
                                        <img class="common-btn-primary-icon" src="../../assets/images/send.svg"
                                            alt="add" />
                                        <span >Sent to customer</span>
                                    </button>
                                 </div>
                                
                              </div>
                              <div class="start-credit-app-btn">
                                <div class="attached-quotes">
                                  <span class="quotes-text text-link fb-600">Attach Quotes</span>
                                </div>
                            </div>
                              <div class="project-credit-app-costume-block ">
                                    <ul>
                                        <li class="project-credit-app-dept">Costumes</li>
                                    </ul>
                              </div>
                          </div>
                          <hr />
                          <div class="row all-projects-credit-office-section">
                              <div class="col-12 all-projects-credit-app-subtitle">
                                <div class="subtitle-block">
                                  <div class="ar-id">A/R # <span class="ar-id-no">04122</span></div>
                                  <div class="ar-status-tag under-review">Under Review</div>
                                  </div>
                              </div>
                              <div class="project-credit-app-credit-block">
                                  <button class="custom-credit-limit-btn common-primary-btn btn btn-block ">
                                      Credit Limit: <span class="credit-limit-amt"> $ 1,000,000,00</span>
                                  </button>
                                <div class="links-list">  <a class="text-link fb-600" href=""> View Quotes</a>
                                    <a class="text-link fb-600" href=""> Attach Quotes</a>
                                    <a class="text-link fb-600" href=""> View Application</a></div>
                              </div>
                              <div class="project-credit-app-costume-block">
                                <ul>
                                    <li class="project-credit-app-dept">
                                        <span>Studio Operations</span>
                                    </li>
                                    <li class="project-credit-app-dept">
                                       
                                        <span> Transportation</span>
                                    </li>
                                    <li class="project-credit-app-dept">
                                        <span>Design Studio</span>
                                    </li>
                                    <li class="project-credit-app-dept">
                                        <span>Post Production Creative Services</span>
                                    </li>
                                    <li class="project-credit-app-dept">
                                        <span>Property Production</span>
                                    </li>
                                    <li class="project-credit-app-dept">
                                        <span>Catering</span>
                                    </li>
                                </ul>
                                
                            </div>

                          </div>
                          <hr />
                      </div>
                      <div class="all-projects-credit-AR-section">
                        <div class="add-credit-add "><span class="text-link">+ Add a Credit Application</span></div>
                      </div>
                  </div>  

                  <!---Project user tab-->
                  <div class="tab-pane  project-user-tab" id="project-users-tabpanel-1" role="tabpane-3"
                      aria-labelledby="fill-tab-1">
                      <div class="container px-0 mx-0">
                          <div class="all-projects-spaces-resources-title ">
                              <h2>Project User</h2>
                              <div class="ms-1"><a class="text-link fb-600" href=""> View All</a> <button class="common-btn-primary  btn">
                                      <img class="common-btn-primary-icon" src="../../assets/images/add.svg"
                                          alt="add" />
                                      <span class="common-btn-primary-text-sm">Add a user</span></button></div>
                          </div>
                          <div class="row mx-0 px-0">
                              <div class="progress_container d-flex justify-content-center">
                                  <!-- First circular progress bar -->
                                  <div class="pass-status-widgets widget-blue">
                                      <div class="pass-status-widgets-values">11</div>
                                      <div class="pass-status-widgets-labels">All </div>
                                  </div>
                                  <div class="progress_container_arrow"><img alt="keyboard-right-arrow"
                                          src="../../../../assets/images/keyboard-arrow-right.svg" /></div>
                                  <!-- Second circular progress bar -->
                                  <div class="pass-status-widgets widget-green">
                                      <div class="pass-status-widgets-values">1</div>
                                      <div class="pass-status-widgets-labels">Not Verified </div>
                                  </div>
                                  <div class="progress_container_arrow"><img
                                          src="../../../../assets/images/keyboard-arrow-right.svg" /></div>
                                  <!-- Third circular progress bar -->
                                  <div class="pass-status-widgets widget-grey">
                                      <div class="pass-status-widgets-values">2</div>
                                      <div class="pass-status-widgets-labels">Inactive</div>
                                  </div>
                                  <div class="progress_container_arrow"><img
                                          src="../../../../assets/images/keyboard-arrow-right.svg" /></div>
                                  <div class="pass-status-widgets widget-darkgrey">
                                      <div class="pass-status-widgets-values">1</div>
                                      <div class="pass-status-widgets-labels">Admin </div>
                                  </div>
                              </div>
                              <hr />
                              <div class="project-user-section">
                                  <div class="project-users-title">Users: </div>
                                  <div class="project-users-list">
                                      Jodie Smith; Eryn Milne; Colette Alfaro; Fahran Blevins; Lexi Allan; Nuha
                                      Hodgson; Lin Ang; Maggie Bash; Kendell Turner; Risa Miyu; Daniel Rubin
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                    <!--space resource tab-->
                  <div class="tab-pane space-project-tab" id="space-resource-tabpanel-2" role="tabpane2"
                      aria-labelledby="fill-tab-0">
                      <div class="container px-0 mx-0">
                          <div class="all-projects-spaces-resources-title">
                              <h2 class="mb-0">My Spaces & Resources</h2>
                          </div>
                          <!-- STAGES -->
                          <div class="row  projects-spaces-resources-block">

                              <div class="col-12  projects-spaces-resources-subtitle ">STAGES </div>
                              <div class="col-lg-2 col-md-2 col-sm-4 col-4 project-spaces-resource-stage-name">
                                  <div>
                                      <p class="pb-0 mb-0">A Stage</p>
                                  </div>
                              </div>
                              <div class="col-lg-10 col-md-10 col-sm-8 col-8 project-spaces-resource-stages-block">
                                  <div class="col-12 ">
                                      <div class="col-6 leftalign">
                                          <div class="d-flex flex-revert">
                                              <div
                                                  class="d-flex justify-content-between flex-md-column flex-column flex-lg-row flex-md-row flex-sm-column flex-change">
                                                  <div class="bookmark-image-wrapper"><img class="bookmark-icon"
                                                          src="../../assets/images/active-bookmark.svg">Floorplan
                                                  </div>
                                                  <div class="bookmark-image-wrapper"><img class="bookmark-icon"
                                                          src="../../assets/images/bookmark.svg">Gantry Floor plan
                                                  </div>
                                                  <div class="bookmark-image-wrapper "><img class="bookmark-icon"
                                                          src="../../assets/images/bookmark.svg">Document 3</div>
                                              </div>
                                          </div>
                                      </div>

                                  </div>
                                  <div class="col-12 ">
                                      <div class="col-6 leftalign">
                                          <div class="d-flex flex-revert">
                                              <div
                                                  class="d-flex justify-content-between flex-md-column flex-column flex-lg-row flex-md-row flex-sm-column flex-change">
                                                  <div class="bookmark-image-wrapper"><img class="bookmark-icon"
                                                          src="../../assets/images/active-bookmark.svg">Floorplan
                                                  </div>
                                                  <div class="bookmark-image-wrapper"><img class="bookmark-icon"
                                                          src="../../assets/images/bookmark.svg">Gantry Floor plan
                                                  </div>
                                                  <div class="bookmark-image-wrapper "><img class="bookmark-icon"
                                                          src="../../assets/images/bookmark.svg">Document 3</div>
                                              </div>
                                          </div>
                                      </div>

                                  </div>
                              </div>
                              <hr />
                          </div>
                          <!-- Offices section-->
                          <div class="row projects-spaces-resources-block">
                              <div class="col-12 projects-spaces-resources-subtitle">Offices</div>
                              <div
                                  class="col-lg-2 col-md-2 col-sm-4 col-4 project-spaces-resource-stage-name flex-block">
                                  <div>
                                      <p class="pb-0 mb-0">A Stage</p>
                                  </div>
                                  <div>
                                      <p class="pb-0 mb-0">A Stage</p>
                                  </div>
                              </div>
                              <div class="col-lg-10 col-md-10 col-sm-8 col-8 project-spaces-resource-stages-block">
                                  <div class="col-12 ">
                                      <div class="col-6 leftalign">
                                          <div class="d-flex flex-revert">
                                              <div
                                                  class="d-flex justify-content-between flex-md-column flex-column flex-lg-row flex-md-row flex-sm-column flex-change">
                                                  <div class="bookmark-image-wrapper"><img class="bookmark-icon"
                                                          src="../../assets/images/active-bookmark.svg">Floorplan
                                                  </div>
                                                  <div class="bookmark-image-wrapper"><img class="bookmark-icon"
                                                          src="../../assets/images/bookmark.svg">Gantry Floor plan
                                                  </div>
                                                  <div class="bookmark-image-wrapper "><img class="bookmark-icon"
                                                          src="../../assets/images/bookmark.svg">Document 3</div>
                                              </div>
                                          </div>
                                      </div>

                                  </div>
                                  <div class="col-12 ">
                                      <div class="col-6 leftalign">
                                          <div class="d-flex flex-revert">
                                              <div
                                                  class="d-flex justify-content-between flex-md-column flex-column flex-lg-row flex-md-row flex-sm-column flex-change">
                                                  <div class="bookmark-image-wrapper"><img class="bookmark-icon"
                                                          src="../../assets/images/active-bookmark.svg">Floorplan
                                                  </div>
                                                  <div class="bookmark-image-wrapper"><img class="bookmark-icon"
                                                          src="../../assets/images/bookmark.svg">Gantry Floor plan
                                                  </div>
                                                  <div class="bookmark-image-wrapper "><img class="bookmark-icon"
                                                          src="../../assets/images/bookmark.svg">Document 3</div>
                                              </div>
                                          </div>
                                      </div>

                                  </div>
                                  <div class="col-12 mt-1">
                                      <div class="col-6 leftalign">
                                          <div class="d-flex flex-revert">
                                              <div
                                                  class="d-flex justify-content-between flex-md-column flex-column flex-lg-row flex-md-row flex-sm-column flex-change">
                                                  <div class="bookmark-image-wrapper"><img class="bookmark-icon"
                                                          src="../../assets/images/active-bookmark.svg">Floorplan
                                                  </div>
                                                  <div class="bookmark-image-wrapper"><img class="bookmark-icon"
                                                          src="../../assets/images/bookmark.svg">Gantry Floor plan
                                                  </div>
                                                  <div class="bookmark-image-wrapper "><img class="bookmark-icon"
                                                          src="../../assets/images/bookmark.svg">Document 3</div>
                                              </div>
                                          </div>
                                      </div>

                                  </div>
                              </div>
                              <hr />
                          </div>
                          <!-- Workshops -->
                          <div class="row  projects-spaces-resources-block">
                              <div class="col-12  projects-spaces-resources-subtitle ">Workshop</div>
                              <div class="col-lg-2 col-md-2 col-sm-4 col-4 project-spaces-resource-stage-name ">
                                  <div>
                                      <p class="pb-0 mb-0">A Stage</p>
                                  </div>
                              </div>
                              <div
                                  class="col-lg-10 col-md-10 col-sm-8 col-8 project-spaces-resource-stages-block px-0">
                                  <div class="col-12 ">
                                      <div class="col-6 leftalign">
                                          <div class="d-flex flex-revert">
                                              <div
                                                  class="d-flex justify-content-between flex-md-column flex-column flex-lg-row flex-md-row flex-sm-column flex-change">
                                                  <div class="bookmark-image-wrapper"><img class="bookmark-icon"
                                                          src="../../assets/images/active-bookmark.svg">Floorplan
                                                  </div>
                                                  <div class="bookmark-image-wrapper"><img class="bookmark-icon"
                                                          src="../../assets/images/bookmark.svg">Gantry Floor plan
                                                  </div>
                                                  <div class="bookmark-image-wrapper "><img class="bookmark-icon"
                                                          src="../../assets/images/bookmark.svg">Document 3</div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </div>
      </div>
  </div>
</div></div>

<!---Modals on opening left section icons-->

<div class="modal fade" id="additional-project-information" tabindex="-1" aria-labelledby="existingCompanyModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered common-modal-center">
    <div class="modal-content">

      <div class="modal-body">
        <h2 class="section-main-header">Search </h2>
        <div class="">
          <div class="mt-56 custom-floating-label">
            <label for="input1" class="form-label">Search</label>
            <input type="text" class="pe-4 form-control" placeholder="Search company name or company ID #"
              id="input1">
            <img class="search-icon" src="../../../../../../assets/images/search-icon.svg" alt="Search Icon">
          </div>

        </div>

        <div class="d-flex justify-content-end gap-2">
          <button type="btn" class="btn common-btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn common-btn-secondary ">Select</button>
        </div>
      </div>
    </div>
  </div>
  </div>
  <!---Added three modals on click of date Edit status and edit project information-->
<ng-template #content let-modal>
    <div class="modal-body">
        <h2 class="section-main-header">Additional Project Details </h2>
        <div>
            <label class="mb-3">TELEVISION Project Camera Setup:</label>
            <p>Multi Cam</p>
        </div>
        <div>
            <label class="mb-3">Programming schedule:</label>
            <p>Single Episode/Pilot </p>
        </div>
        <div class="d-flex justify-content-end gap-2">
            <button type="button" class="btn common-btn-secondary">Edit</button>
            <button type="button" class="btn common-btn-secondary" (click)="modal.close('Close click')">Ok</button>
        </div>
    </div>

</ng-template>
<ng-template #status let-modal>
    <div class="modal-body">
        <h2 class="section-main-header">Edit Project Details </h2>
        <form class="mx-3">
            <div class="row">
                <div class="col-md-12 mb-3  px-0 ">
                    <div class="common-radio-btn">
                        <input class="common-radio-input mb-0" type="radio" name="project" id="active" value="d/m/yyyy" />
                        <label class="common-radio-label" for="active">
                            <span class="project-status-tag  active-tag-green">Active</span>
                        </label>
                    </div>
                </div>
                <div class="col-md-12 mb-3  px-0 ">
                    <div class="common-radio-btn">
                        <input class="common-radio-input mb-0" type="radio" name="project" id="closed" value="d/m/yyyy" />
                        <label class="common-radio-label" for="closed">
                            <span class="project-status-tag  closed-tag-black">Closed</span>
                        </label>
                    </div>
                </div>
                <div class="col-md-12 mb-3 px-0 ">
                    <div class="common-radio-btn">
                        <input class="common-radio-input mb-0" type="radio" name="project" id="not" value="d/m/yyyy" />
                        <label class="common-radio-label" for="not">
                            <span class="project-status-tag  not-started-purple">Not Started</span>
                        </label>
                    </div>
                </div>
            </div>
        </form>
        <div class="d-flex justify-content-end gap-2">
          <button type="button" class="btn common-btn-secondary" >Edit</button>
          <button type="button" class="btn common-btn-secondary" (click)="modal.close('Close click')">Ok</button>
        </div>
      </div>

</ng-template>
<ng-template #date let-modal>
    <div class="modal-body">
        <h2 class="section-main-header">Schedule Notes</h2>
        <div>
          <p >Dates are not flexible</p>
        </div>
        <div class="d-flex justify-content-end gap-2">
          <button type="button" class="btn common-btn-light" >Edit</button>
          <button type="button" class="btn common-btn-secondary" (click)="modal.close('Close click')">Ok</button>
        </div>
      </div>
</ng-template>
