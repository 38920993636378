<button data-bs-toggle="modal" data-bs-target="#addUserCustPortal" class="btn btn-primary">File Upload button</button>
<div class="modal fade" id="addUserCustPortal" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content ">
            <div class="modal-body file-upload-modal-body justify-content-center align-items-center">
                <div class="cm-header-section">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <h1 class="modal-title">Attach Quotes</h1>
                <div class="container cm-main-container">
                    <div class="row">
                        <div class="file-drop-area mt-3" [ngClass]="{ 'file-over': isFileOver }"
                            (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
                            <img class="folder-icon" (click)="fileInput.click()"
                                src="../../../../../assets/images/folder-icon.svg" alt="folder icon">
                            <h3 class="mb-2 drag-n-drop">Drag and Drop your file here, or <a class="browse-file-link"
                                    (click)="fileInput.click()">browse</a></h3>
                            <input type="file" multiple class="d-none" (change)="onFileSelect($event)" #fileInput>
                        </div>
                        <!-- Display list of selected files -->
                        <ul *ngIf="files.length > 0" class="list-group mt-3 uploaded-files-section">
                            <li *ngFor="let file of files; let i = index" class="list-group-item px-0">
                                <div class="d-flex align-items-center">
                                    <div class="col-auto icon-col">
                                        <img src="../../../../../assets/images/pdf-icon.svg" alt="pdf icon"
                                            class="file-icon">
                                    </div>
                                    <div class="col ps-3 pe-2">
                                        <span [title]="file.name" class="file-name">{{ file.name }}</span>
                                        <ngb-progressbar class="file-progress-bar" type="success" textType="white"
                                            [value]="100" [showValue]="false"></ngb-progressbar>
                                    </div>
                                    <div class="col-auto close-icon-col px-0">
                                        <i class="bi bi-x remove-btn" (click)="removeFile(i)"></i>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="justify-content-end text-end px-0 my-4">
                            <div class="" *ngIf="files.length > 0">
                                <div class="custom-floating-label px-0">
                                    <label for="details1" class="form-label">Add a Comment</label>
                                    <textarea formControlName="details" class="form-control common-text-area"
                                        placeholder="Waiting for a few more quotes from PPCS and Sound Stages"
                                        id="details1" rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="text-end px-0">
                            <button type="button" class="btn common-btn-light me-2"
                                data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn common-btn-secondary"
                                [disabled]="!files || files.length === 0" data-bs-dismiss="modal">
                                Upload
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>