<button data-bs-toggle="modal" data-bs-target="#credApp">Pending Risk Status(48 hours review)</button>
<button data-bs-toggle="modal" data-bs-target="#credAppReviewExp">Pending Risk Status(After 48 hours risk review expires)</button>

<!-- common modal section-->
<!-- The Modal for Risk Pending (48 hours review) -->
<div class="modal fade" id="credApp" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="false">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
          <h5 class="modal-title" id="commonModalLabel">Credit Application</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
              class="cm-close-text">Close</span></button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
                <div class="modal-left-section me-4">
                    <h4 class="mt-4">Credit Application</h4>
                    <div class="mb-4 mt-3">
                        <div class="list-group list-group-flush mb-2">
                            <ul class="step-list">
                            <li>                           
                               <span class="step-text current-step"> Status</span>
                            </li>
                            <li>
                                <span class="step-text"> Form</span>
                            </li>
                            </ul>
                        </div>
                        <span class=" mb-1"><a class="text-link"><img src="../assets/images/pdf-blue-icon.svg" alt="" class="me-2">Download Packet</a></span>
                    </div>
                    <hr>
                    <h4>Company Info</h4>
                    <div class="company-meta-data">
                      <p class="title"><strong><span class="label-title">PROJECT</span></strong>: The new show <span></span></p>
                      <p class="title mb-0"><strong><span class="label-title">Comapny:</span></strong></p>
                      <p class="pb-0 pt-0 mb-0">A Brand New Production Company</p>
                      <p class="mb-0"><strong><span class="label-title">TYPE:</span></strong> Major Studio</p>
                      <p class="mb-0"><strong><span class="label-title">APPLICATION DATE :</span></strong> 1/1/24</p>
                      <div class="d-flex flex-column mt-2">
                        <span class=" mb-1"><a class="text-link"><img src="../assets/images/link-icon.svg" alt="" class="me-2">View Projects & Quotes</a></span>
                        <span class=" mb-1"><a class="text-link" ><img src="../assets/images/link-icon.svg" alt="" class="me-2">View Company</a></span>
                       </div>
                    </div>             
                  </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="container cm-main-container px-0 mx-0">
                <div class="common-modal-input-section grey-bg review-panel">
                    <div class="row mb-4 align-items-center">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 custom-status-text">
                            <h4 class="section-main-header cust-risk-pend-sec-header">Status</h4>
                          </div>
                          <div class="col-12 col-sm-4 col-md-4 col-lg-4 custom-status-button">
                            <div class="status-container">
                                <div class="ar-status-tag revision need-revision-width credit-status">Pending risk</div>
                            </div>
                           </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 custom-status-date">
                                <p class="mb-0"><strong><span class="title-content until-text">until</span></strong><span class="pend-risk-details until-text-red"> 1/1/24 3:25pm</span></p>
                            </div>
                    </div>
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-12">
                        <p class="mb-0"><strong><span class="title-content">Credit Limit:&nbsp;</span></strong><span class="pend-risk-details">$1,000,000.00</span></p>
                        <p class="mb-0"><strong><span class="title-content">Sent Risk Email on:&nbsp;</span></strong><span class="pend-risk-details">1/1/2</span>4&nbsp;<span class="text-link">View</span><span class="mb-0 title-content">&nbsp;&nbsp;&nbsp;<strong>Sent by:</strong></span><span class="pend-risk-details">&nbsp;Sophia Martinez</span></p>
                        <p class="mb-0"><strong><span class="title-content">Reasons:&nbsp;</span></strong><span class="pend-risk-details">No Bank Reference</span></p>
                    </div>
                  </div>
                  <hr class="my-5"><!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-12">
                        <p class="mb-0"><strong><span class="title-content">Waiting for answers from:</span></strong> </p>
                        <p class="mb-0"><span class="pend-risk-details">Studio Operations</span></p>
                    </div>
                  </div>
                  <hr class="my-5"><!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-12">
                        <p class="mb-0"><strong><span class="title-content">Departments Denying Risk</span></strong> </p>
                        <p class="mb-0"><span class="pend-risk-details">Property</span></p>
                    </div>
                  </div>
                  <hr class="my-5"><!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-12">
                        <p class="mb-0"><strong><span class="title-content">Departments Accepting Risk</span></strong></p>
                        <p class="mb-0"><span class="pend-risk-details">PPCS; Transportation; Design Studio</span></p>
                    </div>
                  </div>
                  <hr class="my-5"><!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-4 text-end">
                        <div class="btn-common-dropdown w-100">
                          <div ngbDropdown class="d-inline-block">
                            <button onclick="this.classList.toggle('open')" type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                              Actions…
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                              <button ngbDropdownItem><img src="../../../../../assets/images/approve.svg" alt="">Approve with Risk</button>
                              <button ngbDropdownItem><img src="../../../../../assets/images/deny.svg" alt=""> Deny</button>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>                  
                </div>
                <div class="grey-box">
                  <h2 class="section-main-header text-left">Comments(5)</h2>
                    <div class="">
                        <div class="row comment-list">
                            <div class="col-md-1"><span class="user-icon-text">AK</span></div>
                            <div class="col-11">
                                <p class="font-14 mb-0"><strong>Andrea Noj</strong></p>
                                <p class="font-14">xx/xx/xx xx:xxAM</p>
                                <p>Lorem Ipsum</p>
                            </div>
                        </div>
                        <div class="row comment-list">
                            <div class="col-md-1"><span class="user-icon-text">AK</span></div>
                            <div class="col-11">
                                <p class="font-14 mb-0"><strong>Sophia Martinez</strong></p>
                                <p class="font-14">xx/xx/xx xx:xxAM</p>
                                <p>Lorem Ipsum</p>
                            </div>
                        </div>
                        <div class="row comment-list no-border">
                            <div class="col-md-1"><span class="user-icon-text">AK</span></div>
                            <div class="col-11">
                                <p class="font-14 mb-0"><strong>Sophia Martinez</strong></p>
                                <p class="font-14">xx/xx/xx xx:xxAM</p>
                                <p>Lorem Ipsum</p>
                            </div>
                        </div>
                    </div>                  
                </div>
                <div class="grey-box risk-pending-comment">
                  <div class="row">
                      <div class="col-12">
                        <textarea name="" id="" class="form-control form-control-lg common-text-area">Type your comment…</textarea>
                      </div>
                  </div>
              </div>
                <div class="cm-btn-section">                        
                    <button type="button" class="btn common-btn-secondary me-2 mb-2">Add Comment</button>
                </div>             
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End The Modal for Risk Pending (48 hours review) -->

<!-- The Modal for Risk Pending (After 48 hours risk review expires) -->
<div class="modal fade" id="credAppReviewExp" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="false">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
          <h5 class="modal-title" id="commonModalLabel">Credit Application</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
              class="cm-close-text">Close</span></button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
                <div class="modal-left-section me-4">
                    <h4 class="mt-4">Credit Application</h4>
                    <div class="mb-4 mt-3">
                        <div class="list-group list-group-flush mb-2">
                            <ul class="step-list">
                            <li>                           
                               <span class="step-text current-step"> Status</span>
                            </li>
                            <li>
                                <span class="step-text"> Form</span>
                            </li>
                            </ul>
                        </div>
                        <span class=" mb-1"><a class="text-link"><img src="../assets/images/pdf-blue-icon.svg" alt="" class="me-2">Download Packet</a></span>
                    </div>
                    <hr>
                    <h4>Company Info</h4>
                    <div class="company-meta-data">
                      <p class="title"><strong><span class="label-title">PROJECT</span></strong>: The new show <span></span></p>
                      <p class="title mb-0"><strong><span class="label-title">Comapny:</span></strong></p>
                      <p class="pb-0 pt-0 mb-0">A Brand New Production Company</p>
                      <p class="mb-0"><strong><span class="label-title">TYPE:</span></strong> Major Studio</p>
                      <p class="mb-0"><strong><span class="label-title">APPLICATION DATE :</span></strong> 1/1/24</p>
                      <div class="d-flex flex-column mt-2">
                        <span class=" mb-1"><a class="text-link"><img src="../assets/images/link-icon.svg" alt="" class="me-2">View Projects & Quotes</a></span>
                        <span class=" mb-1"><a class="text-link" ><img src="../assets/images/link-icon.svg" alt="" class="me-2">View Company</a></span>
                       </div>
                    </div>             
                  </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="container cm-main-container px-0 mx-0">
                <div class="common-modal-input-section grey-bg review-panel">
                    <div class="row mb-4 align-items-center">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 custom-status-text">
                            <h4 class="section-main-header cust-risk-pend-sec-header">Status</h4>
                          </div>
                          <div class="col-12 col-sm-4 col-md-4 col-lg-4 custom-status-button">
                            <div class="status-container">
                                <div class="ar-status-tag need-approve need-revision-width credit-status">NEEDS APProval</div>
                            </div>
                           </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 custom-status-date">
                                <p class="mb-0"><strong><span class="title-content until-text">until</span></strong><span class="pend-risk-details until-text-red"> 1/1/24 3:25pm</span></p>
                            </div>
                    </div>
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-12">
                        <p class="mb-0"><strong><span class="title-content">Credit Limit:&nbsp;</span></strong><span class="pend-risk-details">$1,000,000.00</span></p>
                        <p class="mb-0"><strong><span class="title-content">Sent Risk Email on:&nbsp;</span></strong><span class="pend-risk-details">1/1/2</span>4&nbsp;<span class="text-link">View</span><span class="mb-0 title-content">&nbsp;&nbsp;&nbsp;<strong>Sent by:</strong></span><span class="pend-risk-details">&nbsp;Sophia Martinez</span></p>
                        <p class="mb-0"><strong><span class="title-content">Reasons:&nbsp;</span></strong><span class="pend-risk-details">No Bank Reference</span></p>
                    </div>
                  </div>
                  <hr class="my-5"><!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-12">
                        <p class="mb-0"><strong><span class="title-content">Didn't Answer:</span></strong> </p>
                        <p class="mb-0"><span class="pend-risk-details">---</span></p>
                    </div>
                  </div>
                  <hr class="my-5"><!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-12">
                        <p class="mb-0"><strong><span class="title-content">Departments Denying Risk</span></strong> </p>
                        <p class="mb-0"><span class="pend-risk-details">Property</span></p>
                    </div>
                  </div>
                  <hr class="my-5"><!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-12">
                        <p class="mb-0"><strong><span class="title-content">Departments Accepting Risk</span></strong></p>
                        <p class="mb-0"><span class="pend-risk-details">PPCS; Transportation; Design Studio</span></p>
                    </div>
                  </div>
                  <hr class="my-5"><!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-4 text-end">
                        <div class="btn-common-dropdown w-100">
                          <div ngbDropdown class="d-inline-block">
                            <button onclick="this.classList.toggle('open')" type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                              Actions…
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                              <button ngbDropdownItem><img src="../../../../../assets/images/approve.svg" alt="">Approve with Risk</button>
                              <button ngbDropdownItem><img src="../../../../../assets/images/deny.svg" alt=""> Deny</button>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>                  
                </div>
                <div class="grey-box">
                  <h2 class="section-main-header text-left">Comments(5)</h2>
                    <div class="">
                        <div class="row comment-list">
                            <div class="col-md-1"><span class="user-icon-text">AK</span></div>
                            <div class="col-11">
                                <p class="font-14 mb-0"><strong>Andrea Noj</strong></p>
                                <p class="font-14">xx/xx/xx xx:xxAM</p>
                                <p>Lorem Ipsum</p>
                            </div>
                        </div>
                        <div class="row comment-list">
                            <div class="col-md-1"><span class="user-icon-text">AK</span></div>
                            <div class="col-11">
                                <p class="font-14 mb-0"><strong>Sophia Martinez</strong></p>
                                <p class="font-14">xx/xx/xx xx:xxAM</p>
                                <p>Lorem Ipsum</p>
                            </div>
                        </div>
                        <div class="row comment-list no-border">
                            <div class="col-md-1"><span class="user-icon-text">AK</span></div>
                            <div class="col-11">
                                <p class="font-14 mb-0"><strong>Sophia Martinez</strong></p>
                                <p class="font-14">xx/xx/xx xx:xxAM</p>
                                <p>Lorem Ipsum</p>
                            </div>
                        </div>
                    </div>                  
                </div>
                <div class="grey-box risk-pending-comment">
                  <div class="row">
                      <div class="col-12">
                        <textarea name="" id="" class="form-control form-control-lg common-text-area">Type your comment…</textarea>
                      </div>
                  </div>
              </div>
                <div class="cm-btn-section">                        
                    <button type="button" class="btn common-btn-secondary me-2 mb-2">Add Comment</button>
                </div>             
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End The Modal for Risk Pending (After 48 hours risk review expires) -->
    <!---Added modals on click of Select Company-->
<ng-template #company let-modal>
  <div class="modal-body">
    <h2 class="section-main-header">Needs Revisions </h2>
    <p>COMPANY: A Brand New Company</p>
    <p>PROJECT: The new show</p>
    <div class="">
      <div class="mt-56 custom-floating-label">
        <label for="input1" class="form-label">Comments</label>
        <textarea name="comments" id="" rows="4"></textarea>
        <img class="search-icon" src="../../../../../../assets/images/search-icon.svg" alt="Search Icon">
      </div>
    </div>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="btn common-btn-secondary" (click)="modal.close('Close click')">Cancel</button>
      <button type="button" class="btn common-btn-secondary" >Select</button>
    </div>
  </div>
</ng-template>