import { Component } from '@angular/core';

@Component({
  selector: 'app-add-ar-user',
  templateUrl: './add-ar-user.component.html',
  styleUrls: ['./add-ar-user.component.scss']
})
export class AddArUserComponent {

}
