import { Component, TemplateRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { IDropdownSettings } from 'src/app/shared/models/multiselect.model';
import {
  COMPANY_USERS_REQUEST,
  DATEPICKER_CONSTANTS,
  ERROR_MESSAGES,
  MANAGE_USERS_CONSTANTS,
  MANAGE_USERS_TYPES,
  PROJECT_DROPDOWN_SETTINGS,
  PROJECT_USERS_LIST_CONSTANTS
} from '../../../constants/customer-portal.constant';
import {
  statusData
} from 'mockData/development/manage-all-users';
import { NgbDate, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { AddProjectUserComponent } from 'src/app/shared/components/add-project-user/add-project-user.component';
import { projectUsers } from 'mockData/development/project-users-list';
import { DatePipe } from '@angular/common';
import { INDEX, PAGES } from '../../../enum/customer-portal.enum';
import { companyJobTitleTypeahead, companyUserTypeahead } from 'mockData/development/company-filters-data';
import { ToastService } from 'src/app/feature/components/toast.service';
import { IconsComponent } from 'src/app/shared/components/ag-grid-icons/icons/icons.component';
import { ProjectUsersService } from '../../../services/project-users/project-users.service';
import { IconsService } from 'src/app/shared/services/icons/icons.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';

@Component({
  selector: 'app-manage-all-users',
  templateUrl: './manage-all-users.component.html',
  styleUrls: ['./manage-all-users.component.scss'],
  providers: [DatePipe]
})
export class ManageAllUsersComponent {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>; // Template for success toast
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>; // Template for danger toast
  @ViewChild('successTplActive', { static: false }) successTplActive!: TemplateRef<any>;
  @ViewChild('successTplRemove', { static: false }) successTplRemove!: TemplateRef<any>;
  isUserDeactivated: boolean = false;
  currentValue: any;
  message: string;
  companyId: any;
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  userFilterData;
  jobTitleFilterData;
  status = statusData;
  jobTitle = companyJobTitleTypeahead;
  filteredUsers: any[] = [];
  filteredStatus: any[] = [];
  filteredJobTitle: any[] = [];
  filteredRowData: any[] = [];
  filterTypes = MANAGE_USERS_TYPES;
  manageUserConstants = MANAGE_USERS_CONSTANTS;
  modalReference: NgbModalRef;
  projectName;
  projectId;
  filterLabel;
  tranferValue: any;
  projectUsers = projectUsers;
  isFiltersSelected: boolean = false;
  selectedLastLoginDate: NgbDate = null;
  userLabel = PROJECT_USERS_LIST_CONSTANTS;
  selectedFromDate: any = null;
  selectedToDate: any = null;
  userTypeahead: any = 'typeahead';
  user = companyUserTypeahead;
  datePickerPlaceholder = DATEPICKER_CONSTANTS;
  errorMessage = ERROR_MESSAGES;
  requestType = COMPANY_USERS_REQUEST;
  userId;
  userDetails;
  rowData = [];
  departmentDetails;
  userRoleDetails;
  defaultSelectedStatus;
  projectUserProfileId;
  projectUserId: any;
  selectedProjectId: any;
  userStatus: string;
  userLoggedIn: any;
  allUsers: any;
  permissionsData: any;

  defaultSettings: IDropdownSettings = PROJECT_DROPDOWN_SETTINGS;

  colsForSmallScreen = ['merged-field', 'icons'];
  colsForMediumScreen = ['merged-field', 'icons'];
  colsForLargeScreen = ['user', 'status', 'jobTitle', 'icons'];
  colsForExtraLargeScreen = ['user', 'status', 'date', 'jobTitle', 'access', 'sections', 'icons'];

  colDefs: ColDef[] = [
    {
      headerName: 'User',
      field: 'user',
      filter: 'agTextColumnFilter',
      filterParams: { alwaysShowFilter: true },
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const user = params.data.user;
        const email = params.data.email;
        let companyAdminInd = params.data.accountType;
        let requestTypeClass = '';
        if (companyAdminInd === 'Company admin') {
          requestTypeClass = 'company-admin';
          companyAdminInd = this.requestType.company;
        } else {
          companyAdminInd = '';
        }
        return `<span class="common-split-cell-section">
                    <span class="common-split-cell-data-1">${user}</span>
                    <span title="${email}" class="common-split-cell-data-2 ellipsis-email">${email}</span>
                    <span class="common-split-cell-data-3  ${requestTypeClass}">${companyAdminInd}</span>
                  </span>`;
      },
      minWidth: 200
    },
    {
      headerName: 'USER',
      field: 'merged-field',
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const user = params.data.user;
        const email = params.data.email;
        const status = params.data.status;
        const statusClass = this.getStatusClass(status);

        return `
          <span class="common-split-cell-section">
            <span class="common-split-cell-data-1">${user}</span>
            <span title="${email}" class="common-split-cell-data-2 ellipsis-email">${email}</span>
            <span class="common-split-cell-data-4">
              <span class="request-status-tag ${statusClass} mt-common-ag-grid-contant">${status}</span>
            </span>
          </span>
        `;
      },
      minWidth: 160,
      hide: true
    },
    {
      headerName: 'STATUS',
      field: 'status',
      cellRenderer: this.statusCellRenderer.bind(this),
      minWidth: 160,
    },
    {
      headerName: 'Last Login',
      filter: false,
      field: 'date',
      cellRenderer: (params: ICellRendererParams) => {
        const date = params.data.date;
        const time = params.data.time;

        if(!date) {
          return 'N/A';
        }

        return `<span class="common-split-cell-section">
                    <span class="common-split-cell-data-default-font common-split-cell-data-1">${date}</span>
                    <span class="common-split-cell-data-2">${time}</span>
                  </span>`;
      },
      minWidth: 120,
      flex: 1.8,
    },
    {
      field: 'jobTitle',
      filter: false,
      minWidth: 200,
      cellClass: 'common-cell-data-bold',
    },
    {
      headerName: ' Access to DPT.',
      filter: false,
      field: 'access',
      cellClass: 'user-project-acces-cell',
      minWidth: 170,
      flex: 1.2,
      valueFormatter: (params) => params.value?.toString(),
    },
    { field: 'sections', filter: false, minWidth: 150 },
    {
      field: 'icons',
      cellClass: 'project-user-icons',
      cellRenderer: IconsComponent,
      cellRendererParams(params: ICellRendererParams) {
        return {
          row: params.data
        }
      },
      headerName: '',
      filter: false,
      minWidth: 133,
      maxWidth: 140
    }
  ];
  userInfo = JSON.parse(localStorage.getItem('user-profile'));
  removeUserSuccess: any;

  constructor(private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private projectUsersService: ProjectUsersService,
    private toastService: ToastService,
    private iconService: IconsService,
    public datepipe: DatePipe,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.getProjectDetails();
    this.iconService.currentValue.subscribe(value => {
      this.currentValue = JSON.stringify(value); // Update the local value when it changes
      this.onValueChange(this.currentValue); // Trigger a method
    });
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.permissionsData = { ...userProfile.roleAssigned };
    this.companyId = userProfile.companyId;
    this.getProjectUserDetails();
    this.getAddProjectUserMetadata();
  }

  /**
    * getProjectUserDetails() - Method to retrieve project user details.
  */
  getProjectUserDetails(): void {
    this.loaderService.setLoadingState(true);
    this.projectUsersService.getProjectUser(this.projectId, this.userInfo.companyId).subscribe(
      (response) => {
        let userData = [];
        this.allUsers = response.projectUsers;
        for (let item of this.allUsers) {
          userData.push({
            id: item.userProfile.userProfileId,
            firstName: item.userProfile.firstName,
            lastName: item.userProfile.lastName,
            user: item.userProfile.firstName + ' ' + item.userProfile.lastName,
            email: item.userProfile.emailAddress ? item.userProfile.emailAddress : '',
            accountType: item.userProfile.companyAdminInd == 1 ? this.userLabel.companyAdmin : '',
            status: item.userProfile.statusActiveInd == "Y" ? this.userLabel.activeStatus : this.userLabel.deactivatedStatus,
            date: item.lastLoginDate,
            time: item.lastLoginTime,
            jobTitle: item.userProfile.jobTitle ? item.userProfile.jobTitle : '-',
            access: item.departmentNames == this.userLabel.allAccess ? this.userLabel.allAccess : item.departmentNames.split(",")?.length,
            sections: item.roleSections ? item.roleSections : '-',
            companyId: item.userProfile.companyId,
            projectId: item?.projectId,
            projectUserProfileId: item?.userProfile?.userProfileId,
          });
        }
        this.rowData = userData;
        this.initializeFiltersFromQueryParams();
        this.getFiltersData(this.rowData);
        this.applyFilters();
        this.loaderService.setLoadingState(false);
      },
      (error) => {
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
        console.error(this.errorMessage.projectUserErrorMessage, error);
      });
  }


  /**
  * getProjectDetails() - Method to retrieve project details from query parameters.
  */
  getProjectDetails() {
    this.route.queryParams?.subscribe(params => {
      this.projectName = params['projectName'];
      this.projectId = params['projectId'];
    });

    this.initializeFiltersFromQueryParams();
  }

  /**
  * navigateToProjectDetails() - Method to navigate to the project details page.
  */
  navigateToProjectDetails(): void {
    this.router.navigate(['feature/customer-dashboard/project-details', this.projectId]);
  }

  /**
  * onDateRangeSelected() - Method triggered when a date range is selected.
  * @param dateRange - The object containing the selected date range
  */
  onDateRangeSelected(dateRange: { fromDate: NgbDate | null; toDate: NgbDate | null }) {
    if (dateRange.fromDate && !dateRange.toDate) {
      this.selectedFromDate = dateRange.fromDate;
      this.selectedToDate = dateRange.fromDate;  // Treat single date as both 'from' and 'to'
    }
    else if (dateRange.fromDate && dateRange.toDate) {
      this.selectedFromDate = dateRange.fromDate;
      this.selectedToDate = dateRange.toDate;
    }
    this.applyFilters();
  }

  /**
   * formatDateRange() - method to format the date
   * @param fromDate - The start date.
   * @param toDate - The end date.
   * @returns A string representing the formatted date range.
 */
  formatDateRange(fromDate: NgbDate, toDate: NgbDate): string {
    const formatToTwoDigits = (num: number) => num < PAGES.ten ? `${PAGES.zero}${num}` : `${num}`;
    const formattedFromDate = `${formatToTwoDigits(fromDate.day)}/${formatToTwoDigits(fromDate.month)}/${fromDate.year.toString().slice(INDEX.minusTwo)}`;
    const formattedToDate = `${formatToTwoDigits(toDate.day)}/${formatToTwoDigits(toDate.month)}/${toDate.year.toString().slice(INDEX.minusTwo)}`;

    // If both dates are the same, return just the single date
    if (fromDate.equals(toDate)) {
      return `${formattedFromDate}`;
    }
    else {
      return `${formattedFromDate} - ${formattedToDate}`;
    }
  }


  /**
    * clearDateRange() - method to clear the selected date range.
  */
  clearDateRange() {
    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.applyFilters();
  }


  /**
    * selectedUser() - Method to update the selected users from the dropdown
    * @param selectedItems - The selected users
  */
  selectedUser(selectedItems: any) {
    this.filteredUsers = selectedItems;
    this.applyFilters();
  }

  /**
    * selectedStatus() - Method to update the selected statuses from the dropdown
    * @param selectedItems - The selected statuses
  */
  selectedStatus(selectedItems: any) {
    this.filteredStatus = selectedItems;
    this.applyFilters();
  }

  /**
    * selectedJobTitle() - Method to update the selected job titles from the dropdown
    * @param selectedItems - The selected job titles
  */
  selectedJobTitle(selectedItems: any) {
    this.filteredJobTitle = selectedItems;
    this.applyFilters();
  }

  /**
    * hasSelectedFilters() - Method to determine if any filters are currently selected.
    * @returns A boolean indicating whether any filters are selected.
  */
  hasSelectedFilters(): boolean {
    this.isFiltersSelected = this.filteredUsers?.length > 0 ||
      this.filteredStatus?.length > 0 ||
      this.filteredJobTitle?.length > 0 ||
      this.selectedFromDate !== null ||
      this.selectedToDate !== null
    return this.isFiltersSelected;
  }

  /**
    * statusCellRenderer() - Method to render the status field in the table
    * @param params - The cell parameters containing the status value
    * @returns The HTML content with the status and its CSS class
  */
  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.value);
    return `
        <div class="status-container">
          <div class="request-status-tag ${statusClass}">${params.value}</div>
        </div>
      `;
  }

  /**
    * getStatusClass() - Method to return the CSS class for a given status
    * @param status - The status value
    * @returns The CSS class as a string
  */
  getStatusClass(status: string): string {
    switch (status) {
      case 'ACTIVE':
        return 'user-status-tag active account-user-common-status-cell mt-common-ag-grid';
      case 'DEACTIVATED':
        return 'user-status-tag deactivated account-user-common-status-cell mt-common-ag-grid';
      default:
        return '';
    }
  }

  /**
   * clearAllFilters() - Method to clear all the selected filters
   */
  clearAllFilters(): void {
    // Clear Projects
    this.filteredUsers = [];
    this.selectedUser(this.filteredUsers);
    this.user = this.filteredUsers;

    // Clear Status
    this.filteredStatus = [];
    this.selectedStatus(this.filteredStatus);
    this.status = this.filteredStatus;

    //clear the selected dates
    this.selectedFromDate = null;
    this.selectedToDate = null;

    this.filteredJobTitle = [];
    this.selectedJobTitle(this.filteredJobTitle);
    this.jobTitle = this.filteredJobTitle;

    // Re-apply filters to reflect the cleared state
    this.applyFilters();
  }

  /**
   * removeSelectedItem() - Method to remove a specific item from a selected filter
   * @param filterType - The type of filter the item belongs to (user, status, jobTitle)
   * @param item - The item to be removed
   */
  removeSelectedItem(filterType: string, item: any): void {
    switch (filterType) {
      case this.filterTypes.user:
        {
          let userArray = [];
          this.filteredUsers = this.filteredDataList(this.filteredUsers, item);
          userArray.push(item);
          this.user = userArray;
        }
        break;
      case this.filterTypes.status:
        {
          let statusArray = [];
          this.filteredStatus = this.filteredDataList(
            this.filteredStatus,
            item
          );
          statusArray.push(item);
          this.status = statusArray;
        }
        break;
      case this.filterTypes.jobTitle:
        {
          let jobTitleArray = [];
          this.filteredJobTitle = this.filteredDataList(this.filteredJobTitle, item);
          jobTitleArray.push(item);
          this.jobTitle = jobTitleArray;
        }
        break;
    }
    this.applyFilters();
  }

  /**
   * filteredDataList() - Method to filter out the selected item from the array
   * @param arr - The array from which the item needs to be removed
   * @param item - The item to be removed
   * @returns The filtered array
   */
  filteredDataList(arr: any[], item: any): any[] {
    return arr.filter(selectedItem => selectedItem.id !== item.id);
  }

  /**
   * onCellClicked() - Method triggered when a cell is clicked in the ag-grid table.
   * Opens the edit user modal upon cell click.
   * @param evt - The event data containing the clicked row details.
  */
  onCellClicked(evt: any) {
    if (evt.column.colId !== 'icons' && this.permissionsData['manageProjectUser']) {
      const userId = evt.data.id;
      this.userId = userId;
        this.getUserDetailsById(this.userId);
    }
  }

  /**
   * getUserDetailsById() - Fetches user details by user ID and opens the edit user modal.
   * @param userId - The ID of the user whose details are to be fetched.
  */
  getUserDetailsById(userId: number): void {
    this.projectUsersService.getProjectUserById(this.userInfo.companyId, this.projectId, userId).subscribe(
      (data) => {
        this.userDetails = data;
        this.openEditUserModal(this.userDetails);
      },
      (error) => {
        console.error(this.errorMessage.userDetailsErrorMessage, error);
      }
    );
  }

  /**
   * getAddProjectUserMetadata() - Method to fetch the metadata for add project user screen
   */
  getAddProjectUserMetadata() {
    this.projectUsersService.fetchMetaData().subscribe((response: any) => {
      const metaData = response;
      this.departmentDetails = metaData.getProjectUserMetaDataDTO.departmentsDetails;
      this.userRoleDetails = metaData.getProjectUserMetaDataDTO.userRoleDetails;
    })
  }

  /**
   * onValueChange(val: any) - Method to handle changes in value.
   * This method processes the incoming value, determines the associated icon action,
   * and triggers the appropriate user action based on the parsed value.
   * 
   * @param val - The value representing the action to be taken, expected to be a JSON string.
   */
  onValueChange(val: any) {
    this.tranferValue = val;
    const parsedVal = JSON.parse(val);
    if(parsedVal != null){
      this.projectUserId = parsedVal.rowData.id;
      this.selectedProjectId = parsedVal.rowData.projectId;
      this.projectUserProfileId = parsedVal.rowData.projectUserProfileId;
      this.userStatus = parsedVal.rowData.status === 'ACTIVE' ? this.userLabel.statusDeactive : this.userLabel.statusActive;
    }
    if(this.userInfo != null){
      this.userLoggedIn = this.userInfo.userProfileId;
    }

    switch (parsedVal?.iconInfo) {
      case 'deactivate-account':
        this.deactivatingUser();
        break;
      case 'activate-account':
        this.activatingUser();
        break;
      case 'remove-account':
        this.removeUser();
        break;
      default:
        break;
    }
  }


  /**
  * openAddUserModal() - method to open the add a user modal
  */
  openAddUserModal() {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddProjectUserComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true
    });
    this.modalReference.componentInstance.modalTitle = this.manageUserConstants.addUserTitle;
    this.modalReference.componentInstance.departmentDetails = this.departmentDetails;
    this.modalReference.componentInstance.userRoleDetails = this.userRoleDetails;
    this.modalReference.componentInstance.portalType = MANAGE_USERS_TYPES.portalType;
    this.modalReference.componentInstance.modalType = MANAGE_USERS_TYPES.addModalType;
    this.modalReference.componentInstance.componentType = MANAGE_USERS_TYPES.componentType;
    this.modalReference.componentInstance.projectId = this.projectId;
    this.modalReference.componentInstance.projectName = this.projectName;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data.status == 'success') {
          this.getProjectUserDetails();
        }
      }
      // on close
    }, (reason) => {
      // on dismiss
    });
  }

  /**
   * openEditUserModal() - Method to open the modal for editing a user.
   * The modal is initialized with "Edit User" as the title and is displayed in full-screen.
  */
  openEditUserModal(userDetails: any) {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddProjectUserComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true
    });
    this.modalReference.componentInstance.modalTitle = this.manageUserConstants.EditUserTitle;
    this.modalReference.componentInstance.departmentDetails = this.departmentDetails;
    this.modalReference.componentInstance.userRoleDetails = this.userRoleDetails;
    this.modalReference.componentInstance.userDetails = userDetails;
    this.modalReference.componentInstance.portalType = MANAGE_USERS_TYPES.portalType;
    this.modalReference.componentInstance.modalType = MANAGE_USERS_TYPES.editModalType;
    this.modalReference.componentInstance.componentType = MANAGE_USERS_TYPES.componentType;
    this.modalReference.componentInstance.projectId = this.projectId;
    this.modalReference.componentInstance.projectName = this.projectName;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data.status == 'success') {
          this.getProjectUserDetails();
        }
      }
      // on close
    }, (reason) => {
      // on dismiss
    });
  }

  /**
   * initializeFiltersFromQueryParams() - Method to initialize filters based on query parameters.
   * The method fetches project-related query parameters (labelId, projectId, projectName) 
   * and applies filters based on the retrieved label (All, Active, Deactivated, Admin).
  */
  initializeFiltersFromQueryParams() {
    this.route.queryParams?.subscribe(params => {
      const LabelId = params['labelId'];
      this.projectId = params['projectId'];
      this.projectName = params['projectName'];

      const selectedLabel = this.projectUsers.find(label => label.id === +LabelId);
      this.filterLabel = selectedLabel?.label;

      const accountAdminUsers = _.filter(this.rowData, user => user.accountType == this.userLabel.companyAdmin);

      switch (this.filterLabel) {
        case this.userLabel.all:
          this.filteredUsers = [];
          this.filteredStatus = [];
          this.filteredJobTitle = [];
          this.applyFilters();
          break;
        case this.userLabel.active:
        case this.userLabel.deactivated:
          this.filteredStatus = [{ text: this.filterLabel.toUpperCase() }];
          this.selectedStatus(this.filteredStatus);
          this.applyFilters();
          break;
        case this.userLabel.admin:
          this.filteredUsers = accountAdminUsers.map(user => ({
            user: user.user,
            id: user.id
          }));
          this.selectedUser(this.filteredUsers);
          this.applyFilters();
          break;
      }
      this.applyDefaultStatus();
    });
  }

  /**
   *applyDefaultStatus() - Method to set default statu
  */
  applyDefaultStatus(): void {
    if (this.filterLabel === this.userLabel.active || this.filterLabel === this.userLabel.deactivated) {
      let defaultStatus = this.filterLabel;

      this.filteredStatus = [{ text: defaultStatus.toUpperCase() }];
      this.defaultSelectedStatus = this.filteredStatus;

      let value: any[] = [];
      this.status.forEach((item: any) => {
        if (item.text === defaultStatus.toUpperCase()) {
          value.push(item);
        }
      });
      this.defaultSelectedStatus = value;
      this.applyFilters();
    }
  }


  /**
   * applyFilters() - Method to apply filters and update the filteredRowData based on the selected filters.
  */
  applyFilters() {
    let filteredData = this.rowData;

    if (this.filteredUsers?.length) {
      filteredData = _.filter(filteredData, user =>
        _.some(this.filteredUsers, x => user.user == x.user)
      );
    }

    if (this.filteredStatus?.length > 0) {
      const selectedStatus = this.filteredStatus.map(status => status.text);
      filteredData = filteredData.filter((row) =>
        selectedStatus.includes(row.status)
      );
    }

    if (this.filteredJobTitle?.length) {
      filteredData = _.filter(filteredData, project =>
        _.some(this.filteredJobTitle, x => project.jobTitle == x.jobtitle)
      );
    }

    if (this.selectedFromDate && this.selectedToDate) {
      const selectedFromDate = new Date(this.selectedFromDate.year, this.selectedFromDate.month - 1, this.selectedFromDate.day);
      const selectedToDate = new Date(this.selectedToDate.year, this.selectedToDate.month - 1, this.selectedToDate.day);

      // Filter rows based on the last login date
      filteredData = filteredData.filter(row => {
        const lastLoginDate = new Date(row.date);
        return lastLoginDate >= selectedFromDate && lastLoginDate <= selectedToDate;
      });
    }

    this.filteredRowData = filteredData;
    this.hasSelectedFilters();
  }

  /**
   *  onToastEvent() - method to display the toast
   * @param - event to display success
   */
  onToastEvent(event: any): void {
    if (event === 'success')
      this.toastService.show('Success', {
        classname: 'custom-toast',
        delay: 3000,
      });
  }

  /**
   * deactivatingUser() - Method to handle user deactivation.
   * Calls the deactivateUser method and dismisses all active modals.
   */
  deactivatingUser(): void {
    this.deactivateUser();
    this.modalService.dismissAll();
  }

  /**
   * activateUser() - Method to handle user activation.
   * Calls the activatingUser method and dismisses all active modals.
   */
  activateUser() {
    this.activatingUser();
    this.modalService.dismissAll();
  }

  /**
   * deactivateUser() - Method to deactivate a user.
   * Calls the iconService to deactivate the user and handles the response.
   * Displays success message if deactivation is successful; logs a warning if not.
   */
  deactivateUser(): void {
    this.loaderService.setLoadingState(true);
    this.iconService.deactivateUser(this.projectUserId, this.userLoggedIn, this.userStatus).subscribe({
      next: (response: any) => {
        if (response) {
          this.showSuccess(this.successTpl);
          this.getProjectUserDetails();
          this.loaderService.setLoadingState(false);
        } else {
          console.warn('User could not be fetched.!');
        }
      },
      error: (error) => {
        console.error('Error fetching user :', error);
        this.loaderService.setLoadingState(false);
      },
    });
  }

  /**
   * activatingUser() - Method to activate a user.
   * Calls the iconService to activate the user and handles the response.
   * Displays success message if activation is successful; logs a warning if not.
   */
  activatingUser() {
    this.loaderService.setLoadingState(true);
    this.iconService.activateUser(this.projectUserId, this.userLoggedIn, this.userStatus).subscribe({
      next: (response: any) => {
        if (response) {
          this.showSuccessActi(this.successTplActive);
          this.getProjectUserDetails();
          this.loaderService.setLoadingState(false);
        } else {
          console.warn('User could not be fetched.!');
        }
      },
      error: (error) => {
        console.error('Error fetching user :', error);
        this.loaderService.setLoadingState(false);
      },
    });
  }

    /**
   * removeUser() - Method to remove a user.
   * Calls the iconService to remove the user and handles the response.
   */
    removeUser(): void {
      this.loaderService.setLoadingState(true);
      this.iconService.removeCustomerProjectUser(this.projectUserProfileId, this.selectedProjectId, this.userLoggedIn).subscribe({
        next: (response: any) => {
          if (response) {
            this.removeUserSuccess = response.message;
            this.getProjectUserDetails();
            this.modalService.dismissAll();
            this.loaderService.setLoadingState(false);
          }
        },
        error: (error) => {
          console.error('Error fetching user :', error);
          this.showDanger(this.dangerTpl);
          this.modalService.dismissAll();
          this.loaderService.setLoadingState(false);
        },
      });
    }

  /**
    * showRemoveIcon() - Displays a success toast notification with custom content and options.
    * @param successTplRemove - The template reference containing the toast's content.
    */
  showRemoveIcon(successTplRemove: TemplateRef<any>) {
    this.toastService.show(successTplRemove, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
    * showSuccess() - Displays a success toast notification with custom content and options.
    * @param successTpl - The template reference containing the toast's content.
    */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }
  showSuccessActi(successTplActive: TemplateRef<any>) {
    this.toastService.show(successTplActive, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message.
   * @param dangerTpl - The template reference for the danger message to display.
   */
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
   * getFiltersData() - method to get the data of the filters based on the row data
   * @param userData - the data of the users
   */
  getFiltersData(userData): void {
    this.userFilterData = userData.filter(x => x.email !== null)
      .map(({ id, email, firstName, lastName, user }) => ({
        id: id,
        email: email,
        firstname: firstName,
        lastname: lastName,
        user: user
      }));

    const uniqueJobTitles = Array.from(
      new Map(
        userData
          .filter(x => x.jobTitle !== null)
          .map(({ userProfileId, jobTitle }) => [jobTitle, { id: userProfileId, jobtitle: jobTitle }])
      ).values()
    );

    // returns the data for jobtitle typeahead value
    this.jobTitleFilterData = uniqueJobTitles.map((item, index) => (
      {
        id: index,
        jobtitle: item['jobtitle']
      }
    ));
  }
}
