import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserProfileResponse } from '../../models/profileModel';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private profileSubject = new BehaviorSubject<UserProfileResponse | null>(null);

  // Observable to subscribe to profile data
  profile$ = this.profileSubject.asObservable();

  constructor() {}

  // Method to update the profile data
  updateProfile(profile: UserProfileResponse): void {
    
    this.profileSubject.next(profile);
    
  }

}
