<div class="container cm-main-container px-0 mx-0">
  <form [formGroup]="form">
    <div class="common-modal-input-section grey-bg">
      <div formGroupName="lineOfCredit">
        <h2 class="section-main-header">Line of Credit<sup class="text-danger">*</sup></h2>
        <div class="mt-5">
          <!-- <form [formGroup]="form"> -->
          <div class="row">
            <div class="col-md-4 custom-floating-label">
              <label for="lnCreditReq" class="form-label">Line of credit requested</label>
              <input type="text" class="form-control" placeholder="Line of credit requested" id="lnCreditReq"
                formControlName="lineOfCreditRequested">
              <small class="text-danger"
                *ngIf="form.get('lineOfCredit.lineOfCreditRequested').invalid && (form.get('lineOfCredit.lineOfCreditRequested').touched || form.get('lineOfCredit.lineOfCreditRequested').dirty)">
                <div *ngIf="form.get('lineOfCredit.lineOfCreditRequested').errors['required']">{{
                  credFormOne.fieldRequired }}</div>
                <div *ngIf="form.get('lineOfCredit.lineOfCreditRequested').errors['invalidno']">{{
                  credFormOne.currencyInvalid }}</div>
              </small>
            </div>
            <div class="col-md-4 custom-floating-label">
              <label for="AntiJobTotal" class="form-label">Anticipated Job Total</label>
              <input type="text" class="form-control" placeholder="Anticipated Job Total" id="AntiJobTotal"
                formControlName="anticipatedJobTotal">
              <small class="text-danger"
                *ngIf="form.get('lineOfCredit.anticipatedJobTotal').invalid && (form.get('lineOfCredit.anticipatedJobTotal').touched || form.get('lineOfCredit.anticipatedJobTotal').dirty)">
                <div *ngIf="form.get('lineOfCredit.anticipatedJobTotal').errors['required']">{{
                  credFormOne.fieldRequired }}</div>
                <div *ngIf="form.get('lineOfCredit.anticipatedJobTotal').errors['invalidno']">{{
                  credFormOne.currencyInvalid }}</div>
              </small>
            </div>
            <div class="col-md-4 custom-floating-label">
              <label for="totalOfMonth" class="form-label">Total # of months</label>
              <input type="text" class="form-control" placeholder="Total # of months" id="totalOfMonth"
                formControlName="totalNoOfMonths">
              <small class="text-danger"
                *ngIf="form.get('lineOfCredit.totalNoOfMonths').invalid && (form.get('lineOfCredit.totalNoOfMonths').touched || form.get('lineOfCredit.totalNoOfMonths').dirty)">
                <div *ngIf="form.get('lineOfCredit.totalNoOfMonths').errors['required']">{{ credFormOne.fieldRequired }}
                </div>
                <div *ngIf="form.get('lineOfCredit.totalNoOfMonths').errors['invalidno']">{{
                  credFormOne.totalNoOfMonthsInvalid }}</div>
              </small>
            </div>
          </div>
        </div>
        <!-- </form> -->
      </div>
    </div>
    <div formGroupName="letterOfGuarantee" class="common-modal-input-section">
      <h2 class="section-main-header custom-cred-header">Letter of Credit / Guarantee</h2>
      <!-- <form> -->
      <div class="row">
        <div class="col-md-12 custom-floating-label my-4">
          <app-upload-file (filesUpdated)="onFilesUpdated($event)"
            (filesChanged)="onFileRemoved($event)"></app-upload-file>
        </div>
        <div class="col-md-12 custom-floating-label">
          <label for="LtofCreditComment" class="form-label"> Comments</label>
          <input type="text" class="form-control" placeholder="Comments" id="LtofCreditComment"
            formControlName="logComment">
        </div>
        <!-- Section to view the Uploaded Document from the API -->
        <div class="row pt-3" *ngIf="letterOfCreditPDFDocument">
          <div class="col-md-6"><span><img src="../assets/images/pdf-icon.svg" alt="">
              {{letterOfCreditPDFDocument?.name}}
            </span></div>
          <div class="col-md-6 text-end"><a (click)="viewLetterOfCreditPDFDocument()"
              class="text-link">{{credFormOneConstant?.view}}</a></div>
        </div>
      </div>
      <!-- </form> -->
    </div>

    <div class="common-modal-input-section">
      <div formGroupName="productionCompany">
        <h2 class="section-main-header custom-cred-header">Production Company<sup class="text-danger">*</sup></h2>
        <!-- <form [formGroup]="form"> -->
        <div class="row">
          <div class="col-md-12 mt-5">
            <p class="form-section-header mt-0 mb-4">Name:</p>
          </div>

          <div class="col-md-12 custom-floating-label input-icon">
            <label for="prodComName" class="form-label"> Name</label>
            <input type="text" class="form-control" placeholder="What is the production legal name?" id="prodComName"
              formControlName="name">
            <span class="help-icon me-1 clickable" (click)="toggleHelpSlider()">
            </span>
            <small class="text-danger"
              *ngIf="form.get('productionCompany.name').invalid && (form.get('productionCompany.name').touched || form.get('productionCompany.name').dirty)">
              <div *ngIf="form.get('productionCompany.name').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('productionCompany.name').errors['invalidName']">{{ credFormOne.nameInvalid }}
              </div>
            </small>
          </div>

          <div class="col-md-12 mt-5">
            <p class="form-section-header mt-0 mb-4">Address:</p>
          </div>
          <div class="col-md-12 custom-floating-label">
            <label for="prodComStreet" class="form-label"> Street</label>
            <input type="text" class="form-control" placeholder="Street" id="prodComStreet" formControlName="street">
            <small class="text-danger"
              *ngIf="form.get('productionCompany.street').invalid && (form.get('productionCompany.street').touched || form.get('productionCompany.street').dirty)">
              <div *ngIf="form.get('productionCompany.street').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('productionCompany.street').errors['maxLength']">{{ credFormOne.streetInvalid }}
              </div>
              <div *ngIf="form.get('productionCompany.street').errors['invalidName']">{{ credFormOne.streetInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="prodComCity" class="form-label"> City</label>
            <input type="text" class="form-control" placeholder="City" id="prodComCity" formControlName="city">
            <small class="text-danger"
              *ngIf="form.get('productionCompany.city').invalid && (form.get('productionCompany.city').touched || form.get('productionCompany.city').dirty)">
              <div *ngIf="form.get('productionCompany.city').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('productionCompany.city').errors['maxLength']">{{ credFormOne.cityInvalid }}</div>
              <div *ngIf="form.get('productionCompany.city').errors['invalidCity']">{{ credFormOne.cityInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5 custom-dropdown custom-floating-label">
                <label for="prodComState" class="form-label"> State</label>
                <select id="prodComState" class="custom-select" formControlName="state">
                  <option class="dropdown-option" value="">Select</option>
                  <option *ngFor="let state of states" [value]="state.stateId">{{ state.stateName }}</option>
                </select>
              </div>
              <div class="col-md-7 custom-floating-label">
                <label for="prodComZipcode" class="form-label"> ZIP Code</label>
                <input type="text" class="form-control" placeholder="ZIP Code" id="prodComZipcode"
                  formControlName="zipcode">
                <small class="text-danger"
                  *ngIf="form.get('productionCompany.zipcode').invalid && (form.get('productionCompany.zipcode').touched || form.get('productionCompany.zipcode').dirty)">
                  <div *ngIf="form.get('productionCompany.zipcode').errors['required']">{{ credFormOne.fieldRequired }}
                  </div>
                  <div *ngIf="form.get('productionCompany.zipcode').errors['maxLength']">{{ credFormOne.zipcodeInvalid
                    }}</div>
                  <div *ngIf="form.get('productionCompany.zipcode').errors['invalidZipCode']">{{
                    credFormOne.zipcodeInvalid
                    }}</div>
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="prodComPhone" class="form-label"> Phone</label>
            <input type="text" class="form-control" placeholder="Phone" id="prodComPhone" formControlName="phone">
            <small class="text-danger"
              *ngIf="form.get('productionCompany.phone').invalid && (form.get('productionCompany.phone').touched || form.get('productionCompany.phone').dirty)">
              <div *ngIf="form.get('productionCompany.phone').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('productionCompany.phone').errors['maxLength']">{{ credFormOne.PhoneInvalid }}
              </div>
              <div *ngIf="form.get('productionCompany.phone').errors['invalidPhone']">{{ credFormOne.PhoneInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-12">
            <label class="custom-checkbox" for="prodComBillingAddress">
              <input type="checkbox" id="prodComBillingAddress">
              <span class="checkmark"></span> Use for billing address
            </label>
          </div>
        </div>
      </div>
      <!-- </form> -->
    </div>

    <div class="common-modal-input-section">
      <div formGroupName="federalTax">
        <h2 class="section-main-header">Federal Tax ID #<sup class="text-danger">*</sup></h2>
        <!-- <form> -->
        <div class="row mt-5">
          <div class="col-md-6 custom-floating-label input-icon">
            <label for="federalTaxTIN" class="form-label"> TIN</label>
            <input type="text" class="form-control" placeholder="TIN" id="federalTaxTIN"
              formControlName="federalTaxTin">
            <small class="text-danger"
              *ngIf="form.get('federalTax.federalTaxTin').invalid && (form.get('federalTax.federalTaxTin').touched || form.get('federalTax.federalTaxTin').dirty)">
              <div *ngIf="form.get('federalTax.federalTaxTin').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('federalTax.federalTaxTin').errors['maxLength']">{{ credFormOne.tinInvalid }}</div>
              <div *ngIf="form.get('federalTax.federalTaxTin').errors['invalidTin']">{{ credFormOne.tinInvalid }}</div>
            </small>
            <span class="help-icon me-1 clickable" (click)="toggleHelpSlider()"></span>
          </div>
        </div>
      </div>
      <!-- </form> -->
    </div>

    <div class="common-modal-input-section">
      <div formGroupName="accountPayable">
        <h2 class="section-main-header">Account Payable Contact<sup class="text-danger">*</sup></h2>
        <!-- <form> -->
        <div class="row mt-5">
          <div class="col-md-6 custom-floating-label">
            <label for="accPayContFName" class="form-label"> First Name</label>
            <input type="text" class="form-control" placeholder="First Name" id="accPayContFName"
              formControlName="apFirstName">
            <small class="text-danger"
              *ngIf="form.get('accountPayable.apFirstName').invalid && (form.get('accountPayable.apFirstName').touched || form.get('accountPayable.apFirstName').dirty)">
              <div *ngIf="form.get('accountPayable.apFirstName').errors['required']">{{ credFormOne.fieldRequired }}
              </div>
              <div *ngIf="form.get('accountPayable.apFirstName').errors['invalidAlphabets']">{{
                credFormOne.firstNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="accPayContLName" class="form-label"> Last Name</label>
            <input type="text" class="form-control" placeholder="Last Name" id="accPayContLName"
              formControlName="apLastName">
            <small class="text-danger"
              *ngIf="form.get('accountPayable.apLastName').invalid && (form.get('accountPayable.apLastName').touched || form.get('accountPayable.apLastName').dirty)">
              <div *ngIf="form.get('accountPayable.apLastName').errors['required']">{{ credFormOne.fieldRequired }}
              </div>
              <div *ngIf="form.get('accountPayable.apLastName').errors['invalidAlphabets']">{{
                credFormOne.lastNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="accPayContEmail" class="form-label"> Email</label>
            <input type="text" class="form-control" placeholder="Email" id="accPayContEmail" formControlName="apEmail">
            <small class="text-danger"
              *ngIf="form.get('accountPayable.apEmail').invalid && (form.get('accountPayable.apEmail').touched || form.get('accountPayable.apEmail').dirty)">
              <div *ngIf="form.get('accountPayable.apEmail').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('accountPayable.apEmail').errors['maxLength']">{{ credFormOne.emailInvalid }}
              </div>
              <div *ngIf="form.get('accountPayable.apEmail').errors['invalidEmail']">{{ credFormOne.emailInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="accPayContPhone" class="form-label"> Phone</label>
            <input type="text" class="form-control" placeholder="Phone" id="accPayContPhone" formControlName="apPhone">
            <small class="text-danger"
              *ngIf="form.get('accountPayable.apPhone').invalid && (form.get('accountPayable.apPhone').touched || form.get('accountPayable.apPhone').dirty)">
              <div *ngIf="form.get('accountPayable.apPhone').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('accountPayable.apPhone').errors['maxLength']">{{ credFormOne.PhoneInvalid }}
              </div>
              <div *ngIf="form.get('accountPayable.apPhone').errors['invalidPhone']">{{ credFormOne.PhoneInvalid }}
              </div>
            </small>
          </div>

          <div class="col-md-12 custom-floating-label my-4">
            <span class="fw-semibold text-link">+ Add a Contact</span>
          </div>
        </div>
      </div>
      <!-- </form> -->
    </div>

    <div class="common-modal-input-section">
      <div formGroupName="companyProfile">
        <h2 class="section-main-header">Company Profile</h2>
        <!-- <form> -->
        <div class="row">
          <div class="col-md-12">
            <p class="form-section-header mt-0 mb-4">Select profile:</p>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
            <div class="common-radio-btn">
              <input class="common-radio-input mb-0" type="radio" name="cpProfile" formControlName="cpProfile"
                id="Corporation" value="Corporation" />
              <label class="common-radio-label" for="feature">
                Corporation
              </label>
            </div>
            <div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
            <div class="common-radio-btn">
              <input class="common-radio-input mb-0" type="radio" name="cpProfile" formControlName="cpProfile"
                id="Proprietorship" value="Proprietorship" />
              <label class="common-radio-label" for="tele">
                Proprietorship
              </label>
            </div>
            <div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
            <div class="common-radio-btn">
              <input class="common-radio-input mb-0" type="radio" name="cpProfile" id="tele" formControlName="cpProfile"
                value="Partnership" />
              <label class="common-radio-label" for="tele">
                Partnership
              </label>
            </div>
            <div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
            <div class="common-radio-btn">
              <input class="common-radio-input mb-0" type="radio" name="cpProfile" id="feature"
                formControlName="cpProfile" value="LLC" />
              <label class="common-radio-label" for="feature">
                LLC
              </label>
            </div>
            <div>
            </div>
          </div>
          <!-- <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
            <div class="common-radio-btn">
              <input class="common-radio-input mb-0" type="radio" name="project" formControlName="cpProfile" id="tele" value="d/m/yyyy" />
              <label class="common-radio-label" for="tele">
                Other
              </label>
            </div>
            <div>
            </div>
          </div> -->
          <!-- <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
            <div class="common-radio-btn">
              <input class="common-radio-input mb-0" type="radio" name="project" formControlName="cpProfile" id="specialEvent" value="Special Event" />
              <label class="common-radio-label" for="tele">
                Special Event
              </label>
            </div>
            <div>
            </div>
          </div> -->
          <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
            <div class="common-radio-btn">
              <input class="common-radio-input mb-0" type="radio" name="cpProfile" id="Other"
                formControlName="cpProfile" value="Other" />
              <label class="common-radio-label" for="tele">
                Other
              </label>
            </div>
          </div>
          <div class="col-md-12 px-0 mt-3">
            <p class="form-section-header mt-4 mb-4">Business info:</p>
          </div>
          <div class=" col-md-6 custom-floating-label mt-4" id="datepicker">
            <label for="comProfileDateStarted" class="form-label">Date Started</label>
            <input type="date" class="form-control d-block" placeholder="Date Started" formControlName="cpDateStarted"
              id="date" />
          </div>
          <div class="col-md-6 custom-floating-label mt-4">
            <label for="comProfileTypBusiness" class="form-label">Type of Business</label>
            <input type="text" class="form-control" placeholder="Type of Business" id="comProfileTypBusiness"
              formControlName="cpTypeOfBusiness">
            <small class="text-danger"
              *ngIf="form.get('companyProfile.cpTypeOfBusiness').invalid && (form.get('companyProfile.cpTypeOfBusiness').touched || form.get('companyProfile.cpTypeOfBusiness').dirty)">
              <!-- <div *ngIf="form.get('companyProfile.cpTypeOfBusiness').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('companyProfile.cpTypeOfBusiness').errors['invalidChars']">{{
                credFormOne.TypeOfBusinessInvalid }}</div>
            </small>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-6 custom-floating-label mt-4">
                    <label for="comProfiletotalAnnSales" class="form-label">Total Annual Sales</label>
                    <input type="text" class="form-control" placeholder="Total Annual Sales"
                      id="comProfiletotalAnnSales" formControlName="cpTotalAnnualsale">
                    <small class="text-danger"
                      *ngIf="form.get('companyProfile.cpTotalAnnualsale').invalid && (form.get('companyProfile.cpTotalAnnualsale').touched || form.get('companyProfile.cpTotalAnnualsale').dirty)">
                      <!-- <div *ngIf="form.get('companyProfile.cpTotalAnnualsale').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
                      <div *ngIf="form.get('companyProfile.cpTotalAnnualsale').errors['invalidno']">{{
                        credFormOne.currencyInvalid }}</div>
                    </small>
                  </div>
                  <div class="col-md-6 custom-floating-label mt-4">
                    <label for="comProfileNetworth" class="form-label">Networth</label>
                    <input type="text" class="form-control" placeholder="Networth" id="comProfileNetworth"
                      formControlName="cpNetworth">
                    <small class="text-danger"
                      *ngIf="form.get('companyProfile.cpNetworth').invalid && (form.get('companyProfile.cpNetworth').touched || form.get('companyProfile.cpNetworth').dirty)">
                      <!-- <div *ngIf="form.get('companyProfile.cpNetworth').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
                      <div *ngIf="form.get('companyProfile.cpNetworth').errors['invalidno']">{{
                        credFormOne.currencyInvalid }}</div>
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-md-3 custom-floating-label mt-4">
                <label for="comProfileNoofEmployees" class="form-label"># of Employees</label>
                <input type="text" class="form-control" placeholder="# of Employees" id="comProfileNoofEmployees"
                  formControlName="cpNumOfEmployees">
                <small class="text-danger"
                  *ngIf="form.get('companyProfile.cpNumOfEmployees').invalid && (form.get('companyProfile.cpNetworth').touched || form.get('companyProfile.cpNetworth').dirty)">
                  <!-- <div *ngIf="form.get('companyProfile.cpNumOfEmployees').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
                  <div *ngIf="form.get('companyProfile.cpNumOfEmployees').errors['invalidno']">{{
                    credFormOne.currencyInvalid }}</div>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </form> -->
    </div>

    <div class="common-modal-input-section">
      <div formGroupName="principalsAndPartners">
        <h2 class="section-main-header">Principals/Officers or Partners</h2>
        <!-- <form> -->
        <div class="row">
          <div class="col-md-6 custom-floating-label">
            <label for="principlePartnerName" class="form-label">Name</label>
            <input type="text" class="form-control" placeholder="Name" id="principlePartnerName"
              formControlName="ppName">
            <small class="text-danger"
              *ngIf="form.get('principalsAndPartners.ppName').invalid && (form.get('principalsAndPartners.ppName').touched || form.get('principalsAndPartners.ppName').dirty)">
              <!-- <div *ngIf="form.get('principalsAndPartners.ppName').errors['required']">{{ credFormOne.fieldRequired }}
              </div> -->
              <div *ngIf="form.get('principalsAndPartners.ppName').errors['invalidAlphabets']">{{
                credFormOne.nameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="principleTitle" class="form-label"> Title</label>
            <input type="text" class="form-control" placeholder="Title" id="principleTitle" formControlName="ppTitle">
            <small class="text-danger"
              *ngIf="form.get('principalsAndPartners.ppTitle').invalid && (form.get('principalsAndPartners.ppTitle').touched || form.get('principalsAndPartners.ppTitle').dirty)">
              <!-- <div *ngIf="form.get('principalsAndPartners.ppTitle').errors['required']">{{ credFormOne.fieldRequired }}
              </div> -->
              <div *ngIf="form.get('principalsAndPartners.ppTitle').errors['invalidName']">{{ credFormOne.titleInvalid
                }}</div>
            </small>
          </div>

          <div class="col-md-12 custom-floating-label my-4">
            <span class="fw-semibold text-link">+ Add Principal</span>
          </div>
        </div>
      </div>
      <!-- </form> -->
    </div>

    <div class="common-modal-input-section">
      <div formGroupName="bankingReference">
        <h2 class="section-main-header">Banking Reference</h2>
        <!-- <form> -->
        <div class="row mt-5">
          <div class="col-md-6 custom-floating-label">
            <label for="bankingRefBnkName" class="form-label">Bank Name</label>
            <input type="text" class="form-control" placeholder="Bank Name" id="bankingRefBnkName"
              formControlName="brbankName">
            <small class="text-danger"
              *ngIf="form.get('bankingReference.brbankName').invalid && (form.get('bankingReference.brbankName').touched || form.get('bankingReference.brbankName').dirty)">
              <!-- <div *ngIf="form.get('bankingReference.brbankName').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('bankingReference.brbankName').errors['invalidName']">{{ credFormOne.bankNameInvalid
                }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="bankingRefAccNum" class="form-label"> Account Number</label>
            <input type="text" class="form-control" placeholder="Account Number" id="bankingRefAccNum"
              formControlName="brAccountNumber">
            <small class="text-danger"
              *ngIf="form.get('bankingReference.brAccountNumber').invalid && (form.get('bankingReference.brAccountNumber').touched || form.get('bankingReference.brAccountNumber').dirty)">
              <!-- <div *ngIf="form.get('bankingReference.brAccountNumber').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('bankingReference.brAccountNumber').errors['invalidno']">{{ credFormOne.accNoInvalid
                }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="bankingRefContName" class="form-label"> Contact Name</label>
            <input type="text" class="form-control" placeholder="Contact Name" id="bankingRefContName"
              formControlName="brContactName">
            <small class="text-danger"
              *ngIf="form.get('bankingReference.brContactName').invalid && (form.get('bankingReference.brContactName').touched || form.get('bankingReference.brContactName').dirty)">
              <!-- <div *ngIf="form.get('bankingReference.brContactName').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('bankingReference.brContactName').errors['invalidAlphabets']">{{
                credFormOne.contactNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="bankingRefTitle" class="form-label"> Title</label>
            <input type="text" class="form-control" placeholder="Title" id="bankingRefTitle" formControlName="brTitle">
            <small class="text-danger"
              *ngIf="form.get('bankingReference.brTitle').invalid && (form.get('bankingReference.brTitle').touched || form.get('bankingReference.brTitle').dirty)">
              <!-- <div *ngIf="form.get('bankingReference.brTitle').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('bankingReference.brTitle').errors['invalidName']">{{ credFormOne.titleInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="bankingRefEmail" class="form-label"> Email</label>
            <input type="text" class="form-control" placeholder="Email" id="bankingRefEmail" formControlName="brEmail">
            <small class="text-danger"
              *ngIf="form.get('bankingReference.brEmail').invalid && (form.get('bankingReference.brEmail').touched || form.get('bankingReference.brEmail').dirty)">
              <!-- <div *ngIf="form.get('bankingReference.brEmail').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('bankingReference.brEmail').errors['maxLength']">{{ credFormOne.emailInvalid }}
              </div>
              <div *ngIf="form.get('bankingReference.brEmail').errors['invalidEmail']">{{ credFormOne.emailInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="bankingRefPhone" class="form-label"> Phone</label>
            <input type="text" class="form-control" placeholder="Phone" id="bankingRefPhone" formControlName="brPhone">
            <small class="text-danger"
              *ngIf="form.get('bankingReference.brPhone').invalid && (form.get('bankingReference.brPhone').touched || form.get('bankingReference.brPhone').dirty)">
              <!-- <div *ngIf="form.get('bankingReference.brPhone').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('bankingReference.brPhone').errors['maxLength']">{{ credFormOne.PhoneInvalid }}
              </div>
              <div *ngIf="form.get('bankingReference.brPhone').errors['invalidPhone']">{{ credFormOne.PhoneInvalid }}
              </div>
            </small>
          </div>
        </div>
      </div>
      <!-- </form> -->
    </div>

    <div class="common-modal-input-section">
      <div formGroupName="tradeReference1">
        <h2 class="section-main-header">Trade References<sup class="text-danger">*</sup></h2>
        <!-- <form> -->
        <div class="row mt-5">
          <div class="col-md-12">
            <p class="form-section-header mt-0 mb-4">Trade reference 1:</p>
          </div>
        </div>
        <div class="row justify-content-start">
          <div class="col-md-6 me-auto custom-floating-label">
            <label for="tradeRefCompName" class="form-label">Company Name</label>
            <input type="text" class="form-control" placeholder="Company Name" id="tradeRefCompName"
              formControlName="tr1CompanyName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference1.tr1CompanyName').invalid && (form.get('tradeReference1.tr1CompanyName').touched || form.get('tradeReference1.tr1CompanyName').dirty)">
              <div *ngIf="form.get('tradeReference1.tr1CompanyName').errors['required']">{{ credFormOne.fieldRequired }}
              </div>
              <div *ngIf="form.get('tradeReference1.tr1CompanyName').errors['invalidName']">{{
                credFormOne.companyNameInvalid }}</div>
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefContFName" class="form-label">Contact First Name</label>
            <input type="text" class="form-control" placeholder="Contact First Name" id="tradeRefContFName"
              formControlName="tr1ContactFirstName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference1.tr1ContactFirstName').invalid && (form.get('tradeReference1.tr1ContactFirstName').touched || form.get('tradeReference1.tr1ContactFirstName').dirty)">
              <div *ngIf="form.get('tradeReference1.tr1ContactFirstName').errors['required']">{{
                credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference1.tr1ContactFirstName').errors['invalidAlphabets']">{{
                credFormOne.firstNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefContLName" class="form-label"> Contact Last Name</label>
            <input type="text" class="form-control" placeholder="Contact Last Name" id="tradeRefContLName"
              formControlName="tr1ContactLastName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference1.tr1ContactLastName').invalid && (form.get('tradeReference1.tr1ContactLastName').touched || form.get('tradeReference1.tr1ContactLastName').dirty)">
              <div *ngIf="form.get('tradeReference1.tr1ContactLastName').errors['required']">{{
                credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference1.tr1ContactLastName').errors['invalidAlphabets']">{{
                credFormOne.lastNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefEmail" class="form-label"> Email</label>
            <input type="text" class="form-control" placeholder="Email" id="tradeRefEmail" formControlName="tr1Email">
            <small class="text-danger"
              *ngIf="form.get('tradeReference1.tr1Email').invalid && (form.get('tradeReference1.tr1Email').touched || form.get('tradeReference1.tr1Email').dirty)">
              <div *ngIf="form.get('tradeReference1.tr1Email').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference1.tr1Email').errors['maxLength']">{{ credFormOne.emailInvalid }}
              </div>
              <div *ngIf="form.get('tradeReference1.tr1Email').errors['invalidEmail']">{{ credFormOne.emailInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefPhone" class="form-label"> Phone</label>
            <input type="text" class="form-control" placeholder="Phone" id="tradeRefPhone" formControlName="tr1Phone">
            <small class="text-danger"
              *ngIf="form.get('tradeReference1.tr1Phone').invalid && (form.get('tradeReference1.tr1Phone').touched || form.get('tradeReference1.tr1Phone').dirty)">
              <div *ngIf="form.get('tradeReference1.tr1Phone').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference1.tr1Phone').errors['maxLength']">{{ credFormOne.PhoneInvalid }}
              </div>
              <div *ngIf="form.get('tradeReference1.tr1Phone').errors['invalidPhone']">{{ credFormOne.PhoneInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-12 custom-floating-label">
            <label for="tradeRefStreet" class="form-label"> Street</label>
            <input type="text" class="form-control" placeholder="Street" id="tradeRefStreet"
              formControlName="tr1Street">
            <small class="text-danger"
              *ngIf="form.get('tradeReference1.tr1Street').invalid && (form.get('tradeReference1.tr1Street').touched || form.get('tradeReference1.tr1Street').dirty)">
              <div *ngIf="form.get('tradeReference1.tr1Street').errors['required']">{{ credFormOne.fieldRequired }}
              </div>
              <div *ngIf="form.get('tradeReference1.tr1Street').errors['maxLength']">{{ credFormOne.streetInvalid }}
              </div>
              <div *ngIf="form.get('tradeReference1.tr1Street').errors['invalidStreet']">{{ credFormOne.streetInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="prodComCity" class="form-label"> City</label>
            <input type="text" placeholder="City" id="prodComCity" class="form-control" formControlName="tr1City">
            <small class="text-danger"
              *ngIf="form.get('tradeReference1.tr1City').invalid && (form.get('tradeReference1.tr1City').touched || form.get('tradeReference1.tr1City').dirty)">
              <div *ngIf="form.get('tradeReference1.tr1City').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference1.tr1City').errors['maxLength']">{{ credFormOne.cityInvalid }}</div>
              <div *ngIf="form.get('tradeReference1.tr1City').errors['invalidCity']">{{ credFormOne.cityInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5 custom-dropdown custom-floating-label">
                <label for="prodComState" class="form-label"> State</label>
                <select id="prodComState" class="custom-select" formControlName="tr1state">
                  <option class="dropdown-option" value="">Select</option>
                  <option *ngFor="let state of states" [value]="state.stateId">{{ state.stateName }}</option>
                </select>
              </div>
              <div class="col-md-7 custom-floating-label">
                <label for="prodComZipcode" class="form-label"> ZIP Code</label>
                <input type="text" placeholder="ZIP Code" id="prodComZipcode" class="form-control"
                  formControlName="tr1ZipCode">
                <small class="text-danger"
                  *ngIf="form.get('tradeReference1.tr1ZipCode').invalid && (form.get('tradeReference1.tr1ZipCode').touched || form.get('tradeReference1.tr1ZipCode').dirty)">
                  <div *ngIf="form.get('tradeReference1.tr1ZipCode').errors['required']">{{ credFormOne.fieldRequired }}
                  </div>
                  <div *ngIf="form.get('tradeReference1.tr1ZipCode').errors['maxLength']">{{
                    credFormOne.zipcodeInvalid}}</div>
                  <div *ngIf="form.get('tradeReference1.tr1ZipCode').errors['invalidZipCode']">{{
                    credFormOne.zipcodeInvalid}}</div>
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-12 custom-floating-label d-flex align-items-center my-4">
            <span class="fw-semibold me-3">Or</span>
            <label for="LtofCreditUploadDoc1" class="d-inline-block" *ngIf="!tr1fileName">
              <span class="fw-semibold text-link d-flex align-items-center">
                <span class="upload-icon me-1"></span> Upload Document</span>
              <input type="file" id="LtofCreditUploadDoc1" hidden="" class="form-control" (change)="onTrFileChange($event,1)" accept=".pdf">
            </label>
            <div class="row mx-0" *ngIf="tr1fileName">
              <div class="col-12 col-md-9 col-sm-9 col-lg-10 d-flex align-items-center gap-2 px-0">
                <div>
                  <img src="assets/images/pdf-icon.svg" alt="file icon" class="file-icon" />
                </div>
                <div>
                  <span [title]="tr1fileName" class="file-name">{{ tr1fileName }}</span>
                </div>
              </div>
              <div class="col-12 col-md-3 col-sm-3 col-lg-2 d-flex view-remove">
                <button class="btn btn-link fb-600" (click)="viewTradeRefferenceReport(tradeRefferenc1Data?.trDocumentUrl)">{{credFormOne.view}}</button>
                <button class="btn btn-link fb-600" (click)="removeTradeRefferenceReport($event,tradeRefferenc1Data,1)">{{credFormOne.remove}}</button>
              </div>
            </div>
          </div>
          <div *ngIf="tr1ErrorMessage" class="text-danger">
            <p>{{ tr1ErrorMessage }}</p>
          </div>
          <div class="col-md-12 py-3">
            <hr class="border-dark-subtle border-bottom" />
          </div>
        </div>
      </div>

      <div formGroupName="tradeReference2">
        <div class="row">
          <div class="col-md-12">

            <p class="form-section-header mt-0 mb-4">Trade reference 2:</p>
          </div>
        </div>
        <div class="row justify-content-start">
          <div class="col-md-6 me-auto custom-floating-label">
            <label for="tradeRefCompName" class="form-label">Company Name</label>
            <input type="text" class="form-control" placeholder="Company Name" id="tradeRefCompName"
              formControlName="tr2CompanyName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference2.tr2CompanyName').invalid && (form.get('tradeReference2.tr2CompanyName').touched || form.get('tradeReference2.tr2CompanyName').dirty)">
              <!-- <div *ngIf="form.get('tradeReference2.tr2CompanyName').errors['required']">{{ credFormOne.fieldRequired }}
              </div> -->
              <div *ngIf="form.get('tradeReference2.tr2CompanyName').errors['invalidName']">{{
                credFormOne.companyNameInvalid }}</div>
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefContFName" class="form-label">Contact First Name</label>
            <input type="text" class="form-control" placeholder="Contact First Name" id="tradeRefContFName"
              formControlName="tr2ContactFirstName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference2.tr2ContactFirstName').invalid && (form.get('tradeReference2.tr2ContactFirstName').touched || form.get('tradeReference2.tr2ContactFirstName').dirty)">
              <!-- <div *ngIf="form.get('tradeReference2.tr2ContactFirstName').errors['required']">{{
                credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('tradeReference2.tr2ContactFirstName').errors['invalidAlphabets']">{{
                credFormOne.firstNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefContLName" class="form-label"> Contact Last Name</label>
            <input type="text" class="form-control" placeholder="Contact Last Name" id="tradeRefContLName"
              formControlName="tr2ContactLastName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference2.tr2ContactLastName').invalid && (form.get('tradeReference2.tr2ContactLastName').touched || form.get('tradeReference2.tr2ContactLastName').dirty)">
              <!-- <div *ngIf="form.get('tradeReference2.tr2ContactLastName').errors['required']">{{
                credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('tradeReference2.tr2ContactLastName').errors['invalidAlphabets']">{{
                credFormOne.lastNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefEmail" class="form-label"> Email</label>
            <input type="text" class="form-control" placeholder="Email" id="tradeRefEmail" formControlName="tr2Email">
            <small class="text-danger"
              *ngIf="form.get('tradeReference2.tr2Email').invalid && (form.get('tradeReference2.tr2Email').touched || form.get('tradeReference2.tr2Email').dirty)">
              <!-- <div *ngIf="form.get('tradeReference2.tr2Email').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('tradeReference2.tr2Email').errors['maxLength']">{{ credFormOne.emailInvalid }}
              </div>
              <div *ngIf="form.get('tradeReference2.tr2Email').errors['invalidEmail']">{{ credFormOne.emailInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefPhone" class="form-label"> Phone</label>
            <input type="text" class="form-control" placeholder="Phone" id="tradeRefPhone2" formControlName="tr2Phone">
            <small class="text-danger"
              *ngIf="form.get('tradeReference2.tr2Phone').invalid && (form.get('tradeReference2.tr2Phone').touched || form.get('tradeReference2.tr2Phone').dirty)">
              <!-- <div *ngIf="form.get('tradeReference2.tr2Phone').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('tradeReference2.tr2Phone').errors['maxLength']">{{ credFormOne.PhoneInvalid }}</div>
              <div *ngIf="form.get('tradeReference2.tr2Phone').errors['invalidPhone']">{{ credFormOne.PhoneInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-12 custom-floating-label">
            <label for="tradeRefStreet" class="form-label"> Street</label>
            <input type="text" class="form-control" placeholder="Street" id="tradeRefStreet"
              formControlName="tr2Street">
            <small class="text-danger"
              *ngIf="form.get('tradeReference2.tr2Street').invalid && (form.get('tradeReference2.tr2Street').touched || form.get('tradeReference2.tr2Street').dirty)">
              <!-- <div *ngIf="form.get('tradeReference2.tr2Street').errors['required']">{{ credFormOne.fieldRequired }}
              </div> -->
              <div *ngIf="form.get('tradeReference2.tr2Street').errors['maxLength']">{{ credFormOne.streetInvalid }}
              </div>
              <div *ngIf="form.get('tradeReference2.tr2Street').errors['invalidStreet']">{{ credFormOne.streetInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="prodComCity" class="form-label"> City</label>
            <input type="text" placeholder="City" id="prodComCity" class="form-control" formControlName="tr2City">
            <small class="text-danger"
              *ngIf="form.get('tradeReference2.tr2City').invalid && (form.get('tradeReference2.tr2City').touched || form.get('tradeReference2.tr2City').dirty)">
              <!-- <div *ngIf="form.get('tradeReference2.tr2City').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('tradeReference2.tr2City').errors['maxLength']">{{ credFormOne.cityInvalid }}</div>
              <div *ngIf="form.get('tradeReference2.tr2City').errors['invalidCity']">{{ credFormOne.cityInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5 custom-dropdown custom-floating-label">
                <label for="prodComState" class="form-label"> State</label>
                <select id="prodComState" class="custom-select" formControlName="tr2state">
                  <option class="dropdown-option" value="">Select</option>
                  <option *ngFor="let state of states" [value]="state.stateId">{{ state.stateName }}</option>
                </select>
              </div>
              <div class="col-md-7 custom-floating-label">
                <label for="prodComZipcode" class="form-label"> ZIP Code</label>
                <input type="text" placeholder="ZIP Code" id="prodComZipcode" class="form-control"
                  formControlName="tr2ZipCode">
                <small class="text-danger"
                  *ngIf="form.get('tradeReference2.tr2ZipCode').invalid && (form.get('tradeReference2.tr2ZipCode').touched || form.get('tradeReference2.tr2ZipCode').dirty)">
                  <!-- <div *ngIf="form.get('tradeReference2.tr2ZipCode').errors['required']">{{ credFormOne.fieldRequired }}
                  </div> -->
                  <div *ngIf="form.get('tradeReference2.tr2ZipCode').errors['maxLength']">{{
                    credFormOne.zipcodeInvalid}}</div>
                  <div *ngIf="form.get('tradeReference2.tr2ZipCode').errors['invalidZipCode']">{{
                    credFormOne.zipcodeInvalid
                    }}</div>
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-12 custom-floating-label d-flex align-items-center my-4">
            <span class="fw-semibold me-3">Or</span>
            <label for="LtofCreditUploadDoc2" class="d-inline-block" *ngIf="!tr2fileName">
              <span class="fw-semibold text-link d-flex align-items-center">
                <span class="upload-icon me-1"></span> Upload Document</span>
              <input type="file" id="LtofCreditUploadDoc2" hidden="" class="form-control" (change)="onTrFileChange($event,2)" accept=".pdf">
            </label>
            <div class="row mx-0" *ngIf="tr2fileName">
              <div class="col-12 col-md-9 col-sm-9 col-lg-10 d-flex align-items-center gap-2 px-0">
                <div>
                  <img src="assets/images/pdf-icon.svg" alt="file icon" class="file-icon" />
                </div>
                <div>
                  <span [title]="tr2fileName" class="file-name">{{ tr2fileName }}</span>
                </div>
              </div>
              <div class="col-12 col-md-3 col-sm-3 col-lg-2 d-flex view-remove">
                <button class="btn btn-link fb-600" (click)="viewTradeRefferenceReport(tradeRefferenc2Data?.trDocumentUrl)">{{credFormOne.view}}</button>
                <button class="btn btn-link fb-600" (click)="removeTradeRefferenceReport($event,tradeRefferenc2Data,2)">{{credFormOne.remove}}</button>
              </div>
            </div>
          </div>
          <div *ngIf="tr2ErrorMessage" class="text-danger">
            <p>{{ tr2ErrorMessage }}</p>
          </div>
          <div class="col-md-12 py-3">
            <hr class="border-dark-subtle border-bottom" />
          </div>
        </div>
      </div>

      <div formGroupName="tradeReference3">
        <div class="row">
          <div class="col-md-12">
            <p class="form-section-header mt-0 mb-4">Trade reference 3:</p>
          </div>
        </div>
        <div class="row justify-content-start">
          <div class="col-md-6 me-auto custom-floating-label">
            <label for="tradeRefCompName" class="form-label">Company Name</label>
            <input type="text" class="form-control" placeholder="Company Name" id="tradeRefCompName"
              formControlName="tr3CompanyName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference3.tr3CompanyName').invalid && (form.get('tradeReference3.tr3CompanyName').touched || form.get('tradeReference3.tr3CompanyName').dirty)">
              <!-- <div *ngIf="form.get('tradeReference3.tr3CompanyName').errors['required']">{{ credFormOne.fieldRequired }}
              </div> -->
              <div *ngIf="form.get('tradeReference3.tr3CompanyName').errors['invalidName']">{{
                credFormOne.companyNameInvalid }}</div>
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefContFName" class="form-label">Contact First Name</label>
            <input type="text" class="form-control" placeholder="Contact First Name" id="tradeRefContFName"
              formControlName="tr3ContactFirstName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference3.tr3ContactFirstName').invalid && (form.get('tradeReference3.tr3ContactFirstName').touched || form.get('tradeReference3.tr3ContactFirstName').dirty)">
              <!-- <div *ngIf="form.get('tradeReference3.tr3ContactFirstName').errors['required']">{{
                credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('tradeReference3.tr3ContactFirstName').errors['invalidAlphabets']">{{
                credFormOne.firstNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefContLName" class="form-label"> Contact Last Name</label>
            <input type="text" class="form-control" placeholder="Contact Last Name" id="tradeRefContLName"
              formControlName="tr3ContactLastName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference3.tr3ContactLastName').invalid && (form.get('tradeReference3.tr3ContactLastName').touched || form.get('tradeReference3.tr3ContactLastName').dirty)">
              <!-- <div *ngIf="form.get('tradeReference3.tr3ContactLastName').errors['required']">{{
                credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('tradeReference3.tr3ContactLastName').errors['invalidAlphabets']">{{
                credFormOne.lastNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefEmail" class="form-label"> Email</label>
            <input type="text" class="form-control" placeholder="Email" id="tradeRefEmail" formControlName="tr3Email">
            <small class="text-danger"
              *ngIf="form.get('tradeReference3.tr3Email').invalid && (form.get('tradeReference3.tr3Email').touched || form.get('tradeReference3.tr3Email').dirty)">
              <!-- <div *ngIf="form.get('tradeReference3.tr3Email').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('tradeReference3.tr3Email').errors['maxLength']">{{ credFormOne.emailInvalid }}</div>
              <div *ngIf="form.get('tradeReference3.tr3Email').errors['invalidEmail']">{{ credFormOne.emailInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefPhone" class="form-label"> Phone</label>
            <input type="text" class="form-control" placeholder="Phone" id="tradeRefPhone" formControlName="tr3Phone">
            <small class="text-danger"
              *ngIf="form.get('tradeReference3.tr3Phone').invalid && (form.get('tradeReference3.tr3Phone').touched || form.get('tradeReference3.tr3Phone').dirty)">
              <!-- <div *ngIf="form.get('tradeReference3.tr3Phone').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('tradeReference3.tr3Phone').errors['maxLength']">{{ credFormOne.PhoneInvalid }}</div>
              <div *ngIf="form.get('tradeReference3.tr3Phone').errors['invalidPhone']">{{ credFormOne.PhoneInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-12 custom-floating-label">
            <label for="tradeRefStreet" class="form-label"> Street</label>
            <input type="text" class="form-control" placeholder="Street" id="tradeRefStreet"
              formControlName="tr3Street">
            <small class="text-danger"
              *ngIf="form.get('tradeReference3.tr3Street').invalid && (form.get('tradeReference3.tr3Street').touched || form.get('tradeReference3.tr3Street').dirty)">
              <!-- <div *ngIf="form.get('tradeReference3.tr3Street').errors['required']">{{ credFormOne.fieldRequired }}
              </div> -->
              <div *ngIf="form.get('tradeReference3.tr3Street').errors['maxLength']">{{ credFormOne.streetInvalid }}
              </div>
              <div *ngIf="form.get('tradeReference3.tr3Street').errors['invalidStreet']">{{ credFormOne.streetInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="prodComCity" class="form-label"> City</label>
            <input type="text" placeholder="City" id="prodComCity" class="form-control" formControlName="tr3City">
            <small class="text-danger"
              *ngIf="form.get('tradeReference3.tr3City').invalid && (form.get('tradeReference3.tr3City').touched || form.get('tradeReference3.tr3City').dirty)">
              <!-- <div *ngIf="form.get('tradeReference3.tr3City').errors['required']">{{ credFormOne.fieldRequired }}</div> -->
              <div *ngIf="form.get('tradeReference3.tr3City').errors['maxLength']">{{ credFormOne.cityInvalid }}</div>
              <div *ngIf="form.get('tradeReference3.tr3City').errors['invalidCity']">{{ credFormOne.cityInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5 custom-dropdown custom-floating-label">
                <label for="prodComState" class="form-label"> State</label>
                <select id="prodComState" class="custom-select" formControlName="tr3state">
                  <option class="dropdown-option" value="">Select</option>
                  <option *ngFor="let state of states" [value]="state.stateId">{{ state.stateName }}</option>
                </select>
              </div>
              <div class="col-md-7 custom-floating-label">
                <label for="prodComZipcode" class="form-label"> ZIP Code</label>
                <input type="text" placeholder="ZIP Code" id="prodComZipcode" class="form-control"
                  formControlName="tr3ZipCode">
                <small class="text-danger"
                  *ngIf="form.get('tradeReference3.tr3ZipCode').invalid && (form.get('tradeReference3.tr3ZipCode').touched || form.get('tradeReference3.tr3ZipCode').dirty)">
                  <!-- <div *ngIf="form.get('tradeReference3.tr3ZipCode').errors['required']">{{ credFormOne.fieldRequired }}
                  </div> -->
                  <div *ngIf="form.get('tradeReference3.tr3ZipCode').errors['maxLength']">{{ credFormOne.zipcodeInvalid
                    }}</div>
                  <div *ngIf="form.get('tradeReference3.tr3ZipCode').errors['invalidZipCode']">{{
                    credFormOne.zipcodeInvalid
                    }}</div>
                </small>
              </div>

            </div>
          </div>
          <div class="col-md-12 custom-floating-label d-flex align-items-center my-4">
            <span class="fw-semibold me-3">Or</span>
            <label for="LtofCreditUploadDoc3" class="d-inline-block" *ngIf="!tr3fileName">
              <span class="fw-semibold text-link d-flex align-items-center">
                <span class="upload-icon me-1"></span> Upload Document</span>
              <input type="file" id="LtofCreditUploadDoc3" hidden="" class="form-control" (change)="onTrFileChange($event,3)" accept=".pdf">
            </label>
            <div class="row mx-0" *ngIf="tr3fileName">
              <div class="col-12 col-md-9 col-sm-9 col-lg-10 d-flex align-items-center gap-2 px-0">
                <div>
                  <img src="assets/images/pdf-icon.svg" alt="file icon" class="file-icon" />
                </div>
                <div>
                  <span [title]="tr3fileName" class="file-name">{{ tr3fileName }}</span>
                </div>
              </div>
              <div class="col-12 col-md-3 col-sm-3 col-lg-2 d-flex view-remove">
                <button class="btn btn-link fb-600" (click)="viewTradeRefferenceReport(tradeRefferenc3Data?.trDocumentUrl)">{{credFormOne.view}}</button>
                <button class="btn btn-link fb-600" (click)="removeTradeRefferenceReport($event,tradeRefferenc3Data,3)">{{credFormOne.remove}}</button>
              </div>
            </div>
          </div>
          <div *ngIf="tr3ErrorMessage" class="text-danger">
            <p>{{ tr3ErrorMessage }}</p>
          </div>
        </div>
      </div>
      <!-- </form> -->
    </div>
    <div class="cm-btn-section"  *ngIf="!isRevision">
      <button type="button" class="btn common-btn-secondary mb-2" [disabled]="!form.dirty" 
        data-bs-dismiss="modal">{{credFormOne.certificate}}</button>
      <button type="button" class="btn common-btn-light ms-2 mb-2" [disabled]="!form.dirty" (click)="saveAndClose()" *ngIf="!isRevision"
        data-bs-dismiss="modal">{{credFormOne.SaveandClose}}</button>
    </div>
    <div class="cm-btn-section" *ngIf="isRevision">
      <button type="button" class="btn common-btn-light ms-2 mb-2" data-bs-dismiss="modal">{{credFormOne.cancel}}</button>
      <button type="button" class="btn common-btn-light ms-2 mb-2"
        data-bs-dismiss="modal">{{credFormOne.SaveandClose}}</button>
      <button type="button" class="btn common-btn-secondary mb-2 mr-2" (click)="postCreditApplication()"
        data-bs-dismiss="modal">{{credFormOne.review}}</button>
    </div>
  </form>
  <app-help-slideout [helpSliderVisible]="helpSliderVisible"></app-help-slideout>
</div>
<!-- </div> -->
<!---Added modals on click of Select Company-->
<ng-template #company let-modal>
  <div class="modal-body">
    <h2 class="section-main-header">Search </h2>
    <div class="">
      <div class="mt-56 custom-floating-label">
        <label for="input1" class="form-label">Search</label>
        <input type="text" class="pe-4 form-control" placeholder="Search company name or company ID #" id="input1">
        <img class="search-icon" src="../../../../../../assets/images/search-icon.svg" alt="Search Icon">
      </div>
    </div>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="btn common-btn-secondary" (click)="modal.close('Close click')">Cancel</button>
      <button type="button" class="btn common-btn-secondary">Select</button>
    </div>
  </div>
</ng-template>

<ng-template #successTpl>
  <div class="success">{{ toastMessages.creditApplication }}</div>
</ng-template>

<ng-template #successUploadFileTpl>
  <div class="success">{{ successMsg }}</div>
</ng-template>

<ng-template #dangerTpl>
  <div class="danger">{{ errorMsg }}</div>
</ng-template>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>