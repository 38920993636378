import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../base-api/base-api.service';
import { AR_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';

@Injectable({
  providedIn: 'root'
})
export class ViewCreditAppServiceService {

constructor(public baseApiService: BaseApiService) { }

/**
 * getDandBReport() - method to get the dandb report from api
 */
getCreditAppReport(url: any,section:any): Observable<any> {
  const endpoint = `${AR_PORTAL_API_PATHS.view_report}?fileUrl=${url}&section=${section}`;
  return this.baseApiService.get(endpoint);
}

}
