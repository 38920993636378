import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ERROR_MSG, NEW_PROJECT, NEW_PROJECT_COMPANY_TYPE, NEW_PROJECT_DETAILS, SEASONS, VALIDATION_MSG } from '../../../constants/studio-portal.constant';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ProjectsService } from '../../../services/projects.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { Router } from '@angular/router';
import { ADD_PROJECT } from '../../../enum/studio-portal.enum';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { ICONS_RENDERED } from 'src/app/shared/constants/common';
import * as _ from 'lodash';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
})
export class AddProjectComponent {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @ViewChild('alertTpl', { static: false }) alertTpl!: TemplateRef<any>;
  @ViewChild('WarningMsgModal', { static: false }) WarningMsgModal!: TemplateRef<any>;
  @Input() public modalTitle;
  @Input() public editMode;
  @Input() public projectId;
  @Input() public projectInfo;
  @Input() public componentType;
  projectText = NEW_PROJECT;
  projectDetailsText = NEW_PROJECT_DETAILS;
  projectCompanyTypeText = NEW_PROJECT_COMPANY_TYPE;
  message = VALIDATION_MSG;
  seasons = SEASONS;
  errorMsg = ERROR_MSG;
  iconsRenderer = ICONS_RENDERED;
  projectNumber = ADD_PROJECT;
  isEditMode: boolean;
  submitted: boolean;
  isCompany: boolean;
  projectForm: FormGroup;
  placeholder: string = this.projectText.placeholder;
  companyText: 'customer' = 'customer';
  portalType = 'studio';
  companyDetails: any;
  newCompanyData: any;
  searchBarInput: string = '';
  isCompanySearch: boolean;
  projectMetadata: any;
  projectCompanyDetails: any;
  projectCustomerTypes: any;
  projectPrimaryPaymentTypes: any;
  projectProgrammingScheduleDetails: any;
  projectProjectCameraDetails: any;
  projectProjectTypeDetails: any;
  addEditResponse: any;
  responseProjectId: any;
  isNavigateProject: boolean;
  isDisabled: boolean = true;
  projectFormInitialValues: any;
  buttonDisabled: boolean = true;

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, private fb: FormBuilder, private projectsService: ProjectsService, private toastService: ToastService, private router: Router, private loaderService: LoaderService) {}

  ngOnInit() {
    if(this.componentType == 'studio-company' && this.portalType == 'studio'){
      this.isCompany = true;
      this.companyDetails = this.projectInfo.company;
    }
    this.loadProjectMetadata();
    this.initForm();
    this.isEditMode = this.editMode ? this.editMode : false;
    if (this.isEditMode) {
      this.initEditForm();
    }
    this.projectFormInitialValues = this.projectForm.value;
    this.disableSaveButton();
  }

  /**
   * initEditForm() - Initialize the Edit project form and append the values from project details component
   */
  initEditForm(): void {
    if (this.projectInfo) {
      this.projectForm.patchValue(this.projectInfo?.projectDetails);
      this.companyDetails = this.projectInfo?.company;
      this.isCompany = true;
      if(this.projectForm?.controls['programSchedule']?.value !== 'Series/Multi Episodes'){
        this.projectForm.controls['programScheduleSeason'].setValue('');
      }
    }
  }

  /**
   * disableSaveButton() - method to disable the save button on load
   */
  disableSaveButton(): void {
    this.projectForm.valueChanges
    .subscribe((res) => {
      const statusFormCurrentValues = this.projectForm.value;
      const isValueChanged = this.formObjectChanges(this.projectFormInitialValues, statusFormCurrentValues);
      if (isValueChanged) {
        this.buttonDisabled = false;
      }
    });
  }

  /**
   * formObjectChanges() - method to check the difference between two form objects
   * @param obj1 previous form object
   * @param obj2 current form object
   * @returns boolean
   */
    formObjectChanges(obj1: object, obj2: object): boolean {
      return !_.isEqual(obj1, obj2);
    }

  /**
  * Added functions to open modal for select company using ng-bootstrap
  */
  openSelectCompany(company: TemplateRef<any>): void {
    this.modalService.open(company, { windowClass: 'common-modal-center',centered: true });
  }

  /**
   * Method for form group creation and validators for each form control
   */
  initForm(): void {
    this.projectForm = this.fb.group({
      projectName: ['', [Validators.required, Validators.maxLength(100)]],
      projectType: ['', [Validators.required]],
      projectTypeOther: ['', ],
      cameraSetup: ['', [Validators.required]],
      programSchedule: ['', [Validators.required]],
      programScheduleSeason: ['', ],
      seasonOther: ['', ],
      projectDesc: ['', ],
      projectStart: ['', [Validators.required, this.dateRangeValidator]],
      projectEnd: ['', [Validators.required, this.dateRangeValidator]],
      additionalNotes: ['', ],
      customerType: ['', [Validators.required]],
      primaryPayment: ['', [Validators.required]],
    },)
    
    this.projectForm.controls['programSchedule'].valueChanges.subscribe((value) => {
      if(this.projectForm?.controls['programSchedule']?.value !== 'Series/Multi Episodes'){
        this.projectForm.controls['programScheduleSeason'].setValue('');
      }
    });
  }
  
  /**
   * 
   */
  loadProjectMetadata(): void {
    this.loaderService.setLoadingState(true);
    this.projectsService.getProjectCompanyAndFormList()
    .pipe()
    .subscribe({
      next: (response: any) => {
        this.projectMetadata = response;
        this.projectCompanyDetails = response?.projectMetaData?.companyDetails;
        this.projectCustomerTypes = response?.projectMetaData?.customerTypes;
        this.projectPrimaryPaymentTypes = response?.projectMetaData?.primaryPaymentTypes;
        this.projectProgrammingScheduleDetails = response?.projectMetaData?.programmingScheduleDetails;
        this.projectProjectCameraDetails = response?.projectMetaData?.projectCameraDetails;
        this.projectProjectTypeDetails = response?.projectMetaData?.projectTypeDetails?.filter(item => item.orderNo !== 0)
        .filter((item, index, current) => 
          index === current.findIndex(t => t.orderNo === item.orderNo) // for value uniqueness
        );      
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        console.error(error);
        this.addEditResponse = error;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      }
    })
  }

  /**
   * Method to close the active modal window
   */
  closeModal(): void {
    this.activeModal.close();
  }

  /**
  * Method to close the active modal window
  * @param changes to pass the value to the modal
  */
  closeModalForChanges(changes: any): void {
      this.activeModal.close(changes);
  }

  /**
   * Method to select the company
   */
  companySelection(): void {
    if(this.isCompanySearch) {
      this.companyDetails = this.newCompanyData[0];
      this.isCompany = true;
      this.isCompanySearch = false;
      this.isDisabled = !this.isDisabled;
    }
  }

  /**
  * Added functions to open modal for change company
  */
  changeCompanySelection(company: TemplateRef<any>): void {
     this.modalService.open(company, { windowClass: 'common-modal-center',centered: true });
     if(this.isCompany) {
       this.searchBarInput = this.companyDetails;
     }
  }

  /**
  * Method to submit the form data to create the new project
  */
  addNewProject(): void {
    // validator addition based on condition for hidden fields
    if (this.projectForm.controls['projectType'].value === 'Other') {
      this.projectForm.controls['projectTypeOther'].addValidators([Validators.required]);
      this.projectForm.controls['projectTypeOther'].updateValueAndValidity();
    } else if (this.projectForm.controls['programSchedule'].value === 'Series/Multi Episodes') {
      this.projectForm.controls['programScheduleSeason'].addValidators([Validators.required]);
      this.projectForm.controls['programScheduleSeason'].updateValueAndValidity();
    } else if (this.projectForm.controls['programScheduleSeason'].value === 'Other') {
      this.projectForm.controls['seasonOther'].setValidators([Validators.required, Validators.max(99), Validators.maxLength(2)]);
      this.projectForm.controls['seasonOther'].updateValueAndValidity();
    }

    // validator remove based on condition for hidden fields
    if (this.projectForm.controls['projectType'].value !== 'Other') {
      this.projectForm?.controls['projectTypeOther']?.clearValidators();
      this.projectForm?.controls['projectTypeOther']?.updateValueAndValidity();
    } else if (this.projectForm.controls['programSchedule'].value !== 'Series/Multi Episodes') {
      this.projectForm?.controls['programScheduleSeason']?.clearValidators();
      this.projectForm?.controls['programScheduleSeason']?.updateValueAndValidity();
    } else if (this.projectForm.controls['programScheduleSeason'].value !== 'Other') {
      this.projectForm?.controls['seasonOther']?.clearValidators();
      this.projectForm?.controls['seasonOther']?.updateValueAndValidity();
    }

    // validator remove based on condition for camera and program section
    if (this.projectForm.controls['projectType'].value !== 'Television') {
      this.projectForm?.controls['cameraSetup']?.clearValidators();
      this.projectForm?.controls['cameraSetup']?.updateValueAndValidity();
      this.projectForm?.controls['programSchedule']?.clearValidators();
      this.projectForm?.controls['programSchedule']?.updateValueAndValidity();
      this.projectForm?.controls['programScheduleSeason']?.clearValidators();
      this.projectForm?.controls['programScheduleSeason']?.updateValueAndValidity();
    } else if (this.projectForm.controls['projectType'].value === 'Television') {
      this.projectForm?.controls['cameraSetup']?.addValidators([Validators.required]);
      this.projectForm?.controls['cameraSetup']?.updateValueAndValidity();
      this.projectForm?.controls['programSchedule']?.addValidators([Validators.required]);
      this.projectForm?.controls['programSchedule']?.updateValueAndValidity();
      }

    this.submitted = true;
    this.addEditResponse = '';
    const profileId =  this.projectsService.getProfileId();

    if(this.companyDetails && this.projectForm.valid) {
      const addPayload = this.getPayloadData(this.projectForm.value);
      this.loaderService.setLoadingState(true);
      this.projectsService.addNewProjectByUser(addPayload)
      .pipe()
      .subscribe({
        next: (response: any) => {
          this.buttonDisabled = true;
          this.addEditResponse = response;
          this.responseProjectId = response.projectId;
          this.showSuccess(this.successTpl);
          this.closeModalForChanges('refresh');
          this.loaderService.setLoadingState(false);
          if(this.isNavigateProject && this.responseProjectId) {
            this.router.navigate(['/feature/studio-dashboard/project/project-details', profileId, this.responseProjectId]);
            this.isNavigateProject = false;
            
          }
        },
        error: (error) => {
          this.addEditResponse = error;
          this.loaderService.setLoadingState(false);
          this.showDanger(this.dangerTpl);
          console.error(error);
        }
      })
    
    } else {
      this.addEditResponse = ERROR_MSG.formError;
      this.showDanger(this.alertTpl);
    }
  }

  /**
  * editProjectDetails() - Method to submit the form data to create the new project
  */
    editProjectDetails(): void {
      // validator addition based on condition for hidden fields
      if (this.projectForm.controls['projectType'].value === 'Other') {
        this.projectForm.controls['projectTypeOther'].addValidators([Validators.required]);
        this.projectForm.controls['projectTypeOther'].updateValueAndValidity();
      } else if (this.projectForm.controls['programSchedule'].value === 'Series/Multi Episodes') {
        this.projectForm.controls['programScheduleSeason'].addValidators([Validators.required]);
        this.projectForm.controls['programScheduleSeason'].updateValueAndValidity();
      } else if (this.projectForm.controls['programScheduleSeason'].value === 'Other') {
        this.projectForm.controls['seasonOther'].setValidators([Validators.required, Validators.max(99), Validators.maxLength(2)]);
        this.projectForm.controls['seasonOther'].updateValueAndValidity();
      }
  
      // validator remove based on condition for hidden fields
      if (this.projectForm.controls['projectType'].value !== 'Other') {
        this.projectForm?.controls['projectTypeOther']?.clearValidators();
        this.projectForm?.controls['projectTypeOther']?.updateValueAndValidity();
      } else if (this.projectForm.controls['programSchedule'].value !== 'Series/Multi Episodes') {
        this.projectForm?.controls['programScheduleSeason']?.clearValidators();
        this.projectForm?.controls['programScheduleSeason']?.updateValueAndValidity();
      } else if (this.projectForm.controls['programScheduleSeason'].value !== 'Other') {
        this.projectForm?.controls['seasonOther']?.clearValidators();
        this.projectForm?.controls['seasonOther']?.updateValueAndValidity();
      }
  
      // validator remove based on condition for camera and program section
      if (this.projectForm.controls['projectType'].value !== 'Television') {
        this.projectForm?.controls['cameraSetup']?.clearValidators();
        this.projectForm?.controls['cameraSetup']?.updateValueAndValidity();
        this.projectForm?.controls['programSchedule']?.clearValidators();
        this.projectForm?.controls['programSchedule']?.updateValueAndValidity();
        this.projectForm?.controls['programScheduleSeason']?.clearValidators();
        this.projectForm?.controls['programScheduleSeason']?.updateValueAndValidity();
      } else if (this.projectForm.controls['projectType'].value === 'Television') {
        this.projectForm?.controls['cameraSetup']?.addValidators([Validators.required]);
        this.projectForm?.controls['cameraSetup']?.updateValueAndValidity();
        this.projectForm?.controls['programSchedule']?.addValidators([Validators.required]);
        this.projectForm?.controls['programSchedule']?.updateValueAndValidity();
        }
  
      this.submitted = true;
      this.addEditResponse = '';
  
      if(this.companyDetails && this.projectForm.valid) {
        const editPayload = this.getPayloadData(this.projectForm.value);
        this.loaderService.setLoadingState(true);
        this.projectsService.updateExistingProjectByUser(editPayload)
        .pipe()
        .subscribe({
          next: (response: any) => {
            this.addEditResponse = response;
            this.showSuccess(this.successTpl);
            setTimeout(() => this.closeModalForChanges('refresh'), 3000);
            setTimeout(() => this.loaderService.setLoadingState(false), 3100);
          },
          error: (error) => {
            this.addEditResponse = error;
            this.loaderService.setLoadingState(false);
            this.showDanger(this.dangerTpl);
            console.error(error);
          }
        })
      
      } else {
        this.addEditResponse = ERROR_MSG.formError;
        this.showDanger(this.alertTpl);
      }
    }

 /**
 * getPayloadData() - Method to create the project payLoad data
 * @param formData - add/edit project form filled data
 * @returns - return the payload for project
 */
 getPayloadData(formData: any): any {
  const form = formData;
  let payloadData = {
    companyId: this.companyDetails?.companyId,
    projectTypeId: this.findIdByText(form.projectType, this.projectProjectTypeDetails, 'projectType', 'projectTypeId'),
    projectType: form.projectType === 'Other' ? form.projectTypeOther : '',
    customerTypeId: this.findIdByText(form.customerType, this.projectCustomerTypes, 'customerType', 'customerTypeId'),
    projectCameraId: this.findIdByText(form.cameraSetup, this.projectProjectCameraDetails, 'projectCameraSetup', 'projectCameraId'),
    programingScheduleId: this.findIdByText(form.programSchedule, this.projectProgrammingScheduleDetails, 'programingSchedule', 'programingScheduleId'),
    seasonNumber: form.programScheduleSeason === 'Other' ? form.seasonOther : form.programScheduleSeason,
    paymentTypeId: this.findIdByText(form.primaryPayment, this.projectPrimaryPaymentTypes, 'paymentType', 'paymentTypeId'),
    projectDesc: form.projectDesc,
    projectName: form.projectName,
    projectStartDate: form.projectStart,
    projectEndDate: form.projectEnd,
    additionalScheduleNotes: form.additionalNotes,
    loginUserId: this.projectsService.getProfileId(),
  } as any;

  const projectId = {
    projectId: this.projectId
  };

  if (this.isEditMode) { // On update fixes
    payloadData.projectId = projectId.projectId;
    payloadData.projectType = payloadData.projectTypeId === this.projectNumber.seven ? payloadData.projectType : '';
    return payloadData;
  } else if (payloadData.programingScheduleId === this.projectNumber.one) {
    const { seasonNumber, ...newPayloadData } = payloadData;
    return newPayloadData;
  } else {
    return payloadData;
  }
 }

 /**
  * onSearchCompleted() - method to get the searched content
  * @param $event - event for searched content from search component
  */ 
  onSearchCompleted($event: any): void {
    this.newCompanyData = $event;
    this.isCompanySearch = true;
    this.isDisabled = !this.isDisabled;
  }
  
  /**
  * checkSearchValue() - method to check the searched content
  */ 
  checkSearchValue($event: any): void {
    if(this.searchBarInput.trim() === ''){
      this.isDisabled = true;
    }
  }
 
  /**
   * findIdByText() - method to find the id from the given object
   * @param findText string that need to be searched in the object
   * @param arrList list of object
   * @param matchText property that need to be searched in the object
   * @param lookup id property that need to be searched in the object
   * @returns return the respective id
   */
  findIdByText(findText: string, arrList: any, matchText: any, lookup: any): number | undefined {
    const itemNumber = arrList.find(item => item[matchText] === findText);
    return itemNumber ? itemNumber[lookup] : '';
  }

  // removeValidatorsFn(controlName: any): void {
  //   this.projectForm.controls[controlName].removeValidators([Validators.required]);
  //   // Apply changes
  //   this.projectForm.controls[controlName].updateValueAndValidity();
  // }

  /**
   * showSuccess() - Displays a success toast notification with custom content and options.
   * @param successTpl - The template reference containing the toast's content.
   */
    showSuccess(successTpl: TemplateRef<any>): void {
      this.toastService.show(successTpl, {
        classname: 'custom-toast',
        delay: 3000,
      });
    }
    
    /**
     * showDanger() - Method to display a danger (error) toast message
     * @param dangerTpl - The template reference for the danger message to display
     */
    showDanger(dangerTpl: any): void {
      this.toastService.show(dangerTpl, {
        classname: 'bg-danger text-light',
        delay: 3000,
      });
    }

  /**
  * addNewProjectAndNavigate() - Method to submit the form data to create the new project and navigate to project page
  */
  addNewProjectAndNavigate(): void {
    this.isNavigateProject = true;
    this.addNewProject();
  }

  /**
   * navigateToCompany() - method to navigate the user to company page
   */
  navigateToCompany(): void {
    const url = this.router.createUrlTree(['/feature/studio-dashboard/company'], { queryParams: { companyid: this.companyDetails.companyId, } }).toString();
    window.open(url, '_blank');
  }

  /**
   * dateRangeValidator() - Method to validate the start date and end date range
   * @returns returns the invalidRange to the validators if invalid or null
   */
  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from = this.projectForm && this.projectForm.get("projectStart").value;
    const to = this.projectForm && this.projectForm.get("projectEnd").value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };

  /**
   * OpenWarningModal() - Method to close the modal on X 
   */
  OpenWarningModal(): void {
    if(this.projectForm.dirty){
      this.modalService.open(this.WarningMsgModal, {
        windowClass: 'common-modal-center',
        centered: true,
        backdrop: 'static'
      });
    }
    else{
      this.activeModal.close();
    }
  }
  

}