import { Component, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cred-from-department-modal',
  templateUrl: './cred-from-department-modal.component.html',
  styleUrls: ['./cred-from-department-modal.component.scss']
})
export class CredFromDepartmentModalComponent {
  constructor(private modalService:NgbModal){}

  openDeleteConfirmationModal(content: TemplateRef<any>): void {
    this.modalService.open(content, { size: 'lg' , centered: true });
  }
  openAddEditModal(addEditNewAcc: TemplateRef<any>): void {
    this.modalService.open(addEditNewAcc, { size: 'lg' , centered: true });
  }

  denyModal(denyCreditApp: TemplateRef<any>): void {
    this.modalService.open(denyCreditApp, { size: 'lg' , centered: true });
  }

}
