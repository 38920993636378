export const projectUsersList = [
    'Colette Alfaro',
    'Daniel Rubin',
    'Eryn Milne',
    'Jodie Smith',
    'Kendell Turner',
    'Lexi Allan',
    'Lin Ang',
    'Maggie Bash',
    'Nuha Hodgson',
    'Risa Miyu'
];

export const projectUsers = [
    {
        id: 1,
        usersCount: projectUsersList?.length,
        label: 'All'
    },
    {
        id: 2,
        usersCount: 1,
        label: 'Active'
    },
    {
        id: 3,
        usersCount: 2,
        label: 'Deactivated'
    },
    {
        id: 4,
        usersCount: 1,
        label: 'Admin'
    }
];
