import { Component, inject, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { ALL_PASSES } from 'src/app/feature/customer-portal/constants/customer-portal.constant';
import { INDEX } from 'src/app/feature/customer-portal/enum/customer-portal.enum';
import { PassesService } from 'src/app/feature/customer-portal/services/wbd-passes/passes.service';
// import * as XLSX from 'xlsx';

@Component({
  selector: 'app-view-passes',
  templateUrl: './view-passes.component.html',
  styleUrls: ['./view-passes.component.scss'],
})
export class ViewPassesComponent implements OnInit {
  @Input() passesData: any;
  filteredRowData: any[] = [];
  passes = ALL_PASSES;
  currentDate: string;
  currentDay: string;
  selectedTab: any;
  showCheckedInOnly: boolean = true;
  rowData: any;
  nextDays: any[] = [];
  todayDate: string;
  index = INDEX;
  filtered: any;
  data: any;
  selectedPassType: string;
  count: any;
  allPasses: any;
  driveOn: any;
  walkOn: any;

  constructor(public activeModal: NgbActiveModal, private passesservice: PassesService) {}

  ngOnInit(): void {
    const today = new Date();
    console.log(today);
    this.todayDate = this.formatDate(today);
    this.selectedTab = {
      date: this.todayDate,
      isToday: true,
    };
    this.nextDays = this.getNextDays(today);
    console.log(this.todayDate);
    this.filterData(this.todayDate);
    console.log(this.passesData);
    console.log(this.todayDate);
  }

  /**
   * getNextDays() - to get the next four days values
   * @param today - passin today to get next 4 days
   * @returns - next four days
   */
  getNextDays(today: Date): string[] {
    const daysOfWeek = this.passes.days;
    const nextDays = [];
    for (let i = this.index.zero; i < this.index.five; i++) {
      const nextDay = new Date(today);
      nextDay.setDate(today.getDate() + i);
      nextDays.push({
        day: daysOfWeek[nextDay.getDay()],
        date: this.formatDate(nextDay),
        isToday: i === this.index.zero,
      });
    }
    return nextDays;
  }

  /**
   * formatDate() - the date will be formatted
   * @param date - the date need to be formatted
   * @returns - a formatted data
   */
  formatDate(date: Date): string {
    const month = date.getMonth() + this.index.one;
    const day = date.getDate();
    return `${month}/${day}`;
  }

  convertToFullDate(date: string): string {
    const today = new Date();
    const [month, day] = date?.split('/')?.map((num) => parseInt(num));
    const year = today?.getFullYear();
    return `${year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')}`;
  }

  /**
   * closeModal() - Closes the modal.
   */
  closeModal(): void {
    this.activeModal.close();
  }

  /**
   * applyInitialFilter() - Initially filter the data to show only "CHECKED IN" passes
   */
  applyInitialFilter(data: any): void {
    console.log(data);
    this.showCheckedInOnly = true;
    this.filteredRowData = data
      .filter((pass) => pass.Status === this.passes.checkedInStatus)
      .map((pass) => {
        return {
          entry_type: pass.EntryType,
          name: pass.VisitorName,
          organization: pass.OrganizationName,
          active: pass.PassActive,
          expires: pass.PassExpiry,
          arrival: pass.PassArrival,
          departure: pass.PassDeparture,
          entry_point: pass.EntryPoint,
          building: pass.Building,
          status: pass.Status,
          time: pass.time,
        };
      });
    this.rowData = this.filteredRowData;
    console.log(this.rowData);
  }

  /**
   *toggleCheckedInStatus() -  Filter the data based on the toggle state
   */
  toggleCheckedInStatus(event: any): void {
    this.showCheckedInOnly = event.target.checked;
    console.log(this.showCheckedInOnly);
    if (this.showCheckedInOnly) {
      console.log(this.selectedTab);
      this.filterData(this.selectedTab.date);
    } else {
      console.log(this.selectedTab);
      this.filterData(this.selectedTab.date);
      console.log(this.selectedTab);
    }
  }

  /**
   * selectTab() - This method is used to set the currently selected tab.
   * @param tab - The identifier for the tab that is being selected. This could be a string
   */
  selectTab(tab: string) {
    this.selectedTab = tab;
    console.log(this.selectedTab);
    this.filterData(this.selectedTab.date);
    console.log(this.selectedTab.date);
  }


  filterData(selectedDate: string): void {
    console.log(selectedDate);
    const fullSelectedDate = this.convertToFullDate(selectedDate);

    // Filter the data based on the full date comparison
    this.filtered = this.passesData.filter((item) => {
      let itemDate = item.PassActive;
      console.log(fullSelectedDate);
      return itemDate === fullSelectedDate;
    });
    this.allPasses = this.filtered.length
    if (this.selectedPassType !== this.passes.all_passes) {
      this.count = this.filtered.filter((item) => item.EntryType === this.selectedPassType);
      if(this.selectedPassType == this.passes.walk_on){
        this.driveOn = this.count.length
      }
      if (this.selectedPassType == this.passes.walk_on){
        this.walkOn = this.count.length
      }
    }
    console.log(this.filtered);
    if (this.showCheckedInOnly) {
      this.data = this.filtered;
      this.applyInitialFilter(this.data);
    } else {
      this.data = this.filtered;
      this.getAllPasses(this.data);
    }
  }
  
  onDriveOnClick() {
    this.selectedPassType = this.passes.drive_on;
    this.filterData(this.selectedTab.date);  
  }
  
  onWalkOnClick() {
    this.selectedPassType = this.passes.walk_on;
    this.filterData(this.selectedTab.date); 
  }

  /**
   * onSelectChange() - This method is triggered when the user selects a new option from a dropdown/select element.
   * @param event - The event object triggered by the change in the select element.
   */
  onSelectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedTab = selectElement.value;
    console.log(this.selectedTab);
  }

  private modalService = inject(NgbModal);
  openSelectCompany(company: TemplateRef<any>) {
    this.modalService.open(company, {
      windowClass: 'common-modal-center',
      centered: true,
    });
  }

  /**
   * getAllPasses() - to get all data
   */
  getAllPasses(data: any) {
    console.log(data);
    const passesData = data.map((pass) => {
      
      return {
        entry_type: pass.EntryType,
        name: pass.VisitorName,
        organization: pass.OrganizationName,
        active: pass.PassActive,
        expires: pass.PassExpiry,
        arrival: pass.PassArrival,
        departure: pass.PassDeparture,
        entry_point: pass.EntryPoint,
        building: pass.Building,
        status: pass.Status,
        time: pass.time,
      };
    });
    this.rowData = passesData;
  }

  getPasses(): void {
    const body =
    {
      "Email": "shivanisandeep.patil@wbdcontractor.com",
      "TableName": "Pass",
      "Key": null,
      "ScreenName": "GreenLight",
      "SiteID": 1,
      "ObjectID": null,
      "ParentObjectField": null,
      "LimitNeeded": true
  }
  
    this.passesservice.getPasses(body).subscribe({
      next: (response: any) => {
        if (response) {
          this.data = response?.wbdPassApiResponse
          this.filterData(this.selectedTab.date)
          console.log(response)
        } else {
          console.warn("No response received");
        }
      },
      error: (error) => {
        console.error("An error occurred:", error);
      },
    });
  }

  /**
   * exportToExcel() - Export the data to Excel.
   * Depending on the showCheckedInOnly flag, export the filtered data or all data.
   */
  // exportToExcel() {
  //   const dataToExport = this.showCheckedInOnly
  //     ? this.filteredRowData
  //     : this.rowData;
  //   const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport);
  //   const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
  //   XLSX.writeFile(wb, 'passes.xlsx');
  // }

  colDefs: ColDef[] = [
    {
      headerName: 'ENTRY TYPE',
      field: 'entry_type',
      autoHeight: true,
      cellRenderer: this.entryTypeRenderer.bind(this),
      minWidth: 140,
      cellStyle: {
        paddingBottom: '1.5rem',
        paddingRight: '10px',
        paddingLeft: '24px',
        paddingTop: '24px',
      },
    },
    {
      headerName: 'NAME',
      field: 'name',
      minWidth: 120,
      headerClass: 'name',
      cellClass: 'common-row-padding common-cell-data-bold',
      // cellStyle:{paddingRight:'0px',paddingLeft:'10px',paddingBottom:'1.5rem'}
    },
    {
      headerName: 'ORG.',
      field: 'organization',
      filter: false,
      minWidth: 120,
      headerClass: 'name',
      cellClass: 'common-row-padding-new ',
      //  cellStyle:{paddingRight:'0px',paddingLeft:'10px'}
    },
    {
      headerName: 'ACTIVE',
      field: 'active',
      minWidth: 100,
      headerClass: 'name',
      cellClass: 'common-row-padding',
    },
    {
      headerName: 'EXPIRES',
      field: 'expires',
      minWidth: 110,
      headerClass: 'name',
      cellClass: 'common-row-padding',
    },
    {
      headerName: 'ARRIVAL',
      field: 'arrival',
      wrapText: true,
      autoHeight: true,
      minWidth: 110,
      cellClass: 'common-row-padding',
      headerClass: 'name',
      // cellStyle:{paddingRight:'0px',paddingLeft:'10px'}
    },
    {
      field: 'departure',
      headerName: 'DEPARTURE',
      minWidth: 100,
      cellClass: 'common-row-padding',
      headerClass: 'name',
      // cellStyle:{paddingRight:'0px',paddingLeft:'10px'}
    },
    {
      field: 'entry_point',
      headerName: 'ENTRY POINT',
      minWidth: 100,
      cellClass: 'common-row-padding',
      headerClass: 'custom-header-adjusting',
      // cellStyle:{paddingRight:'0px',paddingLeft:'10px'}
    },
    {
      headerName: 'BUILDING',
      field: 'building',
      minWidth: 100,
      cellClass: 'common-row-padding',
      headerClass: 'custom-header-adjusting',
      // cellStyle:{paddingRight:'0px',paddingLeft:'10px'}
    },
    {
      headerName: 'STATUS',
      field: 'status',
      minWidth: 150,
      cellClass: 'common-row-padding',
      headerClass: 'custom-header-adjusting',
      cellRenderer: this.statusCellRenderer.bind(this),
      // cellStyle:{paddingRight:'10px',paddingLeft:'10px'}
    },
    {
      headerName: '',
      field: 'time',
      minWidth: 90,
      cellStyle: { padding: '24px 24px 24px 0px' },
      // cellClass:'common-row-padding',
    },
    // {
    //   field:'merged-first-field',
    //   headerName:'',
    //   cellRenderer:this.mergedFieldRenderer.bind(this)

    // },
    {
      field: 'merged-second-field',
      headerName: '',
      cellClass: 'second-field',
      cellRenderer: this.thirdMergedFieldRenderer.bind(this),
      cellStyle: {
        padding: '0px',
      },
    },
  ];

  entryTypeRenderer(params: ICellRendererParams) {
    const entryType = params.value;
    const imageSrc =
      entryType === this.passes.walk_on
        ? 'assets/images/man.svg'
        : entryType === this.passes.drive_on
        ? 'assets/images/car.svg'
        : 'assets/images/default.svg';

    return `
      <span class="request-cell">
        <img src="${imageSrc}" alt="Entry Type Icon" class="entry-icon" />
        <span class="request-count">${entryType}</span>
      </span>`;
  }

  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.value);
    return `
         <div class="view-five-passes">
        <div class="request-status-tag ${statusClass}">${params.value}</div>
        </div>
    `;
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'CHECKED IN':
        return 'checked-in account-user-common-status-cell checked-in-width';
      case 'CHECKED OUT':
        return 'checked-out account-user-common-status-cell checked-out-width';
      default:
        return '';
    }
  }

  thirdMergedFieldRenderer(params: ICellRendererParams) {
    const entryType = params.data.entry_type;
    const name = params.data.name;
    const gate = params.data.entry_point;
    const building = params.data.building;
    const organization = params.data.organization;
    const status = params.data.status;
    const time = params.data.time;
    const statusClass = this.getStatusClass(status);

    const imageSrc =
      entryType === this.passes.walk_on
        ? 'assets/images/man.svg'
        : entryType === this.passes.drive_on
        ? 'assets/images/car.svg'
        : 'assets/images/default.svg';

    return `
      <div class="third-merged-field">
        <div class="first-row">
          <span class="entry-type-row">
            <img src="${imageSrc}" alt="Entry Type Icon" class="entry-icon" />
            <span class="entry-type-text">${entryType}</span>
          </span>
          <span class="status-time">
          <span class="request-status-tag ${statusClass}">${status}</span>
          <span class="time-text">${time}</span>
          </span>
        </div>
        
        <div class="second-row">
          <span class="name-text common-cell-data-bold">${name}</span>
          <span class="organization-text">${organization}</span>
        </div>
  
        <div class="third-row">
          <span class="gate-text">${gate}</span>
          <span >-</span>
          <span class="building-text">${building}</span>
        </div>
      </div>
    `;
  }

  colsForSmallScreen = ['merged-second-field'];
  colsForLargeScreen = ['merged-second-field'];
  colsForMediumScreen = ['merged-second-field'];
  colsForExtraLargeScreen = [
    'entry_type',
    'name',
    'organization',
    'active',
    'expires',
    'arrival',
    'departure',
    'entry_point',
    'building',
    'status',
    'time',
  ];
}