import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api/base-api.service';
import { CUSTOMER_PORTAL_API_PATHS, STUDIO_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';

@Injectable({
  providedIn: 'root'
})
export class CompanyUserServiceService {

  constructor(public baseApiService: BaseApiService) { }

   // addCompanyUser(user:any) {
  //   let endpoint = STUDIO_PORTAL_API_PATHS.add_company_user;
  //   return this.baseApiService.post(endpoint,user);
  // }

  /**
* getCompanyDetail - Invoke the data search for the company details for the logged in user.
* @param companyId - companyId input is expected to search the company details.
* @returns An Observable that emits a user information as response.
*/

  addCompanyUser(payload) {
    let endpoint = CUSTOMER_PORTAL_API_PATHS.add_company_user;
    return this.baseApiService.post(endpoint, payload);
  }

  updateCustCompanyDetail(payload) {
    let endpoint = CUSTOMER_PORTAL_API_PATHS.customer_company_update;
    return this.baseApiService.put(endpoint, payload);
  }

  updateCustCompanyUser(payload) {
    let endpoint = CUSTOMER_PORTAL_API_PATHS.customer_update_company_user;
    return this.baseApiService.put(endpoint, payload);
  }


}
