import { Component, inject, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-studio-pending-risk',
  templateUrl: './studio-pending-risk.component.html',
  styleUrls: ['./studio-pending-risk.component.scss']
})
export class StudioPendingRiskComponent {
  private modalService = inject(NgbModal);
  /**
   * Added functions to open modal for select company using ng-bootstrap
   */
  
}
