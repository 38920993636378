import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MERGED_CELL_ICONS } from '../../constants/common';

@Component({
  selector: 'app-merged-cell-icons',
  templateUrl: './merged-cell-icons.component.html',
  styleUrls: ['./merged-cell-icons.component.scss']
})
export class MergedCellIconsComponent implements ICellRendererAngularComp {
  @Input() status: string;
  
  statusContent= MERGED_CELL_ICONS;
  public params: any;

  /**
   * agInit() - Method to initialize the cell renderer with the provided parameters.
   * @param params - Parameters passed by ag-Grid for this cell renderer (cell data, grid details, etc.)
   */
  agInit(params: any): void {
    this.params = params;
    this.status = params.data.status; 
  }

  /**
   * refresh() - Method to refresh the cell content. Always returns false, meaning no refresh is needed.
   * @returns boolean - Always returns false, indicating no refresh is needed.
   */
  refresh(): boolean {
    return false;
  }

  /**
   * getStatusClass() - Method to get the CSS class based on the status value
   * @param status - The status of the cell (e.g., 'DISABLED', 'ACTIVE')
   * @returns string - The corresponding CSS class for the status
   */
  getStatusClass(status: string): string {
    switch (status) {
      case this.statusContent.disabled:
        return 'status-account-tag disabled';
      case this.statusContent.active:
        return 'user-status-tag active account-user-common-status-cell mt-common-ag-grid-new';
      default:
        return 'common-mb-status-tag';
    }
  }
}
