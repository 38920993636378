import { Component, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-final-credit-app-denied',
  templateUrl: './final-credit-app-denied.component.html',
  styleUrls: ['./final-credit-app-denied.component.scss']
})
export class FinalCreditAppDeniedComponent {
  constructor(private modalService: NgbModal) { }

  modalReference: NgbModalRef;
  openConfirmationModal (confirmationModal: TemplateRef<any>):void{
   
     this.modalService.open(confirmationModal, {
      windowClass: 'common-modal-center',
      centered: true,
      backdrop: 'static'
    });
  }

}
