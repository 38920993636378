/**
 * File contains the TH Portal API Paths
 */

export const CUSTOMER_PORTAL_API_PATHS =
{
  /**User Microservices API */
  projects_list: 'customer-portal/projects',
  project_details: 'customer-portal/projects/advance-search/id',
  project_users: 'customer-portal/projects/users',
  project_user_search: 'customer-portal/projects/users/advance-search/id',
  project_users_metadata: 'customer-portal/projects/users/metadata',
  edit_project_description: 'customer-portal/projects/description',
  onTheLot: 'customer-portal/users/sessions/lots/metadata',
  activeProjects: 'customer-portal/projects/advance-search/status?',
  /**User Microservices API */
  company_user: 'customer-portal/companies/users',
  company_detail: 'customer-portal/companies',
  company_user_metadata: 'customer-portal/companies/users/metadata',
  updateOnTheLot: 'customer-portal/users/update',
  add_company_user: 'customer-portal/companies/users',
  add_project_user: 'customer-portal/projects/users',
  edit_project_user: 'customer-portal/projects/users',
  weather_forecast: 'customer-portal/weatherforecast',
  company_country: 'customer-portal/companies/metadata',
  company_user_search: 'customer-portal/companies/users/advanced-search/id',
  customer_company_update: 'customer-portal/companies',
  company_user_updatestatus: 'customer-portal/users/status',
  customer_update_company_user: 'customer-portal/companies/users',
  add_bookmark: 'customer-portal/spaceandresources/bookmark',
  remove_bookmark: 'customer-portal/spaceandresources/bookmark',
  passes: 'customer-portal/wbdpass/multisitesmartsearch',
  customer_credit_application_metadata: 'ar-portal/customer/ca/pagedata',
  add_certificate_of_insurance: 'ar-portal/customer/ca/cert-of-insurance',
  edit_certificate_of_insurance: 'ar-portal/customer/ca/review/section/cert-of-insurance',
  save_credit_app_form: 'ar-portal/customer/ca/applicationform',
  update_credit_app_form: 'ar-portal/customer/ca/review/section/applicationform',
  start_credit_application: 'ar-portal/customer/ca',
  ca_edit_departments: 'ar-portal/customer/departments/',
  ca_terms_condition: "ar-portal/customer/ca/termandconditions",
  ca_revision_terms_condition: "ar-portal/customer/ca/review/section/termandconditions",
  credit_applications: 'ar-portal/customer/ca/advance-search/company?',
  submit_customer_credit_application: 'ar-portal/customer/ca/final-submission',
  upload_image: 'ar-portal/projects/image/upload',
  upload_trade_refference: 'ar-portal/customer/ca/tr/upload',
  upload_customer_coi: 'ar-portal/customer/ca/coi/upload',
  upload_log_file: 'ar-portal/customer/ca/log/upload',
  resetPhoto: 'ar-portal/projects/image/upload',
  ca_revision: 'ar-portal/customer/ca/revision?',
  startApplicationStep1: 'ar-portal/customer/ca/review/section/applicationform',
  add_credit_application: 'ar-portal/studio/ca/initiate',
  ca_version_check: 'ar-portal/customer/ca/version-check',
  lock_credit_application: 'ar-portal/ca/unlock',

  upload_PO_COI_fileUploadAWS: "ar-portal/ca/third-party/upload",
  save_PO_COI_file: "ar-portal/customer/ca/third-party/submit",
  get_ar_details: "ar-portal/ca/thirdparty/initiate",
  third_party_revision: 'ar-portal/customer/ca/third-party/revision',
  third_party_view_application:'ar-portal/customer/ca/third-party?',
  file_upload: 'ar-portal/presigned-url',
  customer_request: 'ar-portal/customer-studio/servicerequest/pagedata',

  // service request
 service_request: 'ar-portal/customer-studio/servicerequest',
 view_request: 'ar-portal/customer-studio/servicerequest/advance-search'
 

}

export const AR_PORTAL_API_PATHS = {
  search_credit_application: 'ar-portal/ca/review',
  review_summary_status: 'ar-portal/ca/advance-search/summary',
  review_form_view_page_metadata: 'ar-portal/ca/formpagedata',
  credit_application_dashboard: 'ar-portal/studio/ca/dashboard',
  credit_application_step2: 'ar-portal/ca/review/action',
  credit_application_review_actions: 'ar-portal/ca/review/action',
  save_dandb_report: 'ar-portal/dandbreport',
  view_report: 'ar-portal/document/view',
  credit_application_section_activity: 'ar-portal/review/activity',
  credit_application_send_for_revisions: 'ar-portal/revision',
  credit_application_pending_risk: 'ar-portal/ca/formpagedata',
  ar_upload_trade_reference: 'ar-portal/ca/tr/review/upload',
  uploadTradeReferanceRevisionEmail: 'ar-portal/ca/revision/tr/upload',
  uploadBankReferenceRevisionLetter: 'ar-portal/ca/bankreference/upload',
  credit_application_revision_trade_remove: 'ar-portal/ca/revision/tr/upload',
  credit_application_revision_bank_remove: 'ar-portal/ca/bankreference/upload',
  credit_application_review_trade_remove: 'ar-portal/ca/tr/review/upload',
  add_remove_acc_payable_loaandcod: 'ar-portal/ca/accpayuser',
  ar_get_acc_payable: 'ar-portal/advance-search/accpayable/users',
  ar_get_third_acc_payable:'ar-portal/advance-search/third-party/accpayable/users',
  ar_approve_reason: 'ar-portal/ca/formpagedata',
  ar_send_l2_approver: 'ar-portal/ca/action/sendforapproval',
  ar_credit_view_all_data: 'ar-portal/ca/advance-search/reviewed/id',
  ar_pending_risk: 'ar-portal/ca/advance-search/reviewed/id',
  ar_application_form_activity: 'ar-portal/review/activity',
  ar_pending_risk_add_comments: 'ar-portal/ca/comments',
  ar_denied_arnumber: 'ar-portal/update/ar',
  ar_action_approve_deny: 'ar-portal/ca/action/approvedeny',
  ar_pending_risk_view_email: 'ar-portal/ca/studio/department/risk-email',
  ar_pending_risk_approve_deney_action: 'ar-portal/ca/action/approvedeny',
  upload_cover_sheet: 'ar-portal/ca/coversheet/upload',
  credit_application_send_letter_of_approval: 'ar-portal/ca/letter-of-approval',
  loa_notification:'ar-portal/ca/letter-of-approval/notification',
  ar_credit_form_view_all_data:'ar-portal/ca/advance-search/app-form/id',
  ar_denied_send_cod_notification: 'ar-portal/ca/cod/notification',
  ar_credit_comment:'ar-portal/ca/advance-search/comments',
  ar_denied_cod_page_Data: 'ar-portal/ca/formpagedata',
  ar_credit_application_approvedeny: 'ar-portal/ca/action/approvedeny',
  ar_third_party_comm_house: 'ar-portal/ca/third-party/advance-search/formactivity',
  ar_third_party_document_summary: 'ar-portal/ca/third-party/advance-search/summary',
  ar_thirdparty_approve: 'ar-portal/ca/third-party/approvedeny',
  ar_third_party_status: 'ar-portal/ca/third-party/advance-search/reviewed/id',
  ar_third_party_departments: 'ar-portal/ca/third-party/departments',
  ar_third_party_summary: 'ar-portal/ca/third-party/review',
  ar_third_party_accpayuser: 'ar-portal/ca/third-party/accpayuser',
  ar_third_party_deny: 'ar-portal/ca/third-party/approvedeny',
  ar_thirdpart_send_loa: 'ar-portal/ca/third-party/letter-of-approval/notification',
  ar_third_party_comm_house_sendrev_email: 'ar-portal/ca/third-party/approvedeny',
  ar_third_party_send_letter_approval: 'ar-portal/ca/third-party/letter-of-approval',
ar_thirdparty_send_cod_notification:'ar-portal/ca/third-party/cod/notification',
  ar_creditapplication_reopen: 'ar-portal/ca/reopen',
  ar_third_party_download_packet: 'ar-portal/ca/thirdparty/download/packet',
  ar_thirdparty_comment:'ar-portal/ca/advance-search/third-party/comments',
  download_Packet: 'ar-portal/ca/download/packet',
  ca_form: 'ar-portal/ca/advance-search/app-form/id',
  ar_third_party_coversheet_upload: 'ar-portal/ca/third-party/coversheet/upload',
  ar_delegate_approver: 'ar-portal/pagedata',
  ar_delegate_data: 'ar-portal/managedelegate',
  ar_save_delegate_data: 'ar-portal/managedelegate'
}

export const STUDIO_PORTAL_API_PATHS = {
  company_users: 'studio-ops/companies/users',
  company_metadata: 'studio-ops/companies/metadata',
  company_users_metadata: 'studio-ops/companies/users/metadata',
  project_users: 'studio-ops/projects/users',
  company_details_by_companyid: 'studio-ops/companies/advanced-search/id',
  company_add_update: 'studio-ops/companies',
  companies_list: 'studio-ops/companies',
  enable_company: 'studio-ops/companies/status/enable',
  disable_company: 'studio-ops/companies/status/disable',
  add_wb_user: 'studio-ops/users/wbso',
  add_customer_user: 'studio-ops/users/customer',
  edit_customer_user: 'studio-ops/users/customer',
  users_by_profile_id: 'studio-ops/companies/users/advanced-search/id',
  search_customer_user: 'studio-ops/companies/advance-search/all',
  add_wbso_user: 'studio-ops/users/wbso',
  edit_wbso_user: 'studio-ops/users/wbso',
  search_wbso_user: 'studio-ops/wbsousers/advance-search/all',
  project_users_search: 'studio-ops/projects/users/advance-search/id',
  projects_project_list: 'studio-ops/projects',
  project_details_list: 'studio-ops/projects/advance-search/Id',
  project_details_add_bookmark: 'studio-ops/project/bookmark',
  project_details_remove_bookmark: 'studio-ops/project/bookmark',
  project_status_update: 'studio-ops/projects/status',
  Users_get: 'studio-ops/users/',
  project_company_list: 'studio-ops/projects/metadata',
  project_add_project: 'studio-ops/projects',
  project_update_project: 'studio-ops/projects',
  project_user_metadata: 'studio-ops/projects/users/metadata',
  deactivate_studio_company_user: 'studio-ops/company/users/update/status',
  activate_studio_company_user: 'studio-ops/company/users/update/status',
  add_project_user: 'studio-ops/projects/users',
  users_get_By_Id: 'studio-ops/companies/users/advanced-search/id?',
  search_wbso_user_id: 'studio-ops/users/advanced-search/wbso/id',
  search_customer_user_id: 'studio-ops/users/advanced-search/customers/id',
  account_payable_users: 'ar-portal/studio/company/payable/users?',
  dashboard_ca: 'ar-portal/studio/ca/dashboard',
  dashboard_bookmarked: 'studio-ops/projects/advance-search/status/bookmark',
  ca_send_to_customer: 'ar-portal/studio/ca/initiate',
  ar_third_party_send_to_customer: 'ar-portal/ca/thirdparty/initiate',
  ca_pagedata: 'ar-portal/studio/ca/pagedata',
  view_quote: 'ar-portal/studio/quotes?',
  upload_image: 'ar-portal/projects/image/upload',
  attachQuotes: 'ar-portal/studio/quotes',
  resetPhoto: 'ar-portal/projects/image/upload',
  add_Comments: 'ar-portal/ca/comments',
  edit_access_customer_user: 'studio-ops/users/customer/access-revert',
  risk_review_email: 'ar-portal/studio/ca/update/riskmail',
  departmentData: 'ar-portal/studio/ca/approvalflow/pagedata',
  selctedDept: 'ar-portal/departments',
  ca_form: 'ar-portal/ca/advance-search/app-form/id',
  ca_search_status: 'ar-portal/studio/ca/advance-search/id',
  pending_risk_email: 'ar-portal/studio/ca/department/risk-email',
  final_cred_view: 'ar-portal/document/view',
  approved_risk_email: 'ar-portal/studio/ca/advance-search/id',
  third_party_final_view: 'ar-portal/ca/third-party/advance-search/app-form/id',
  download_Packet: 'ar-portal/ca/download/packet',
  department_selction:'ar-portal/studio/ca/department/selection',
  get_comments:'ar-portal/ca/advance-search/comments',
  request_page_data: 'ar-portal/customer-studio/servicerequest/pagedata',
  service_request_search: 'ar-portal/customer-studio/servicerequest',
  service_request_searchById: 'ar-portal/customer-studio/servicerequest/advance-search/id',
  viewRequest_statusChange: 'ar-portal/studio/servicerequest',
  ca_third_party_search_status: 'ar-portal/ca/thirdparty/advance-search/id',
  ca_third_party_search_documents: 'ar-portal/ca/third-party/advance-search/app-form/id',
  third_party_download_Packet: 'ar-portal/ca/thirdparty/download/packet',
  sp_thirdparty_comment:'ar-portal/ca/advance-search/third-party/comments',
  sp_third_party_departments: 'ar-portal/ca/third-party/departments',
}

export const GLOBAL_API_PATHS = {
  auth_login: 'customer-portal/auth',
  user_profile: 'customer-portal/userprofiles',
  faq_Data: 'customer-portal/faq',
  faq_Post: 'customer-portal/faq',
  faq_edit: 'customer-portal/faq',
  faq_delete: 'customer-portal/faq',
  user_info: 'customer-portal/userprofile',
  user_profile_meta_data: 'customer-portal/userprofile/metadata',
  deactivate_user: 'customer-portal/users/status',
  activate_user: 'customer-portal/users/status',
  clear_cache: 'customer-portal/users/logout',
  deactivate_studio_project_user: 'studio-ops/users/status',
  activate_studio_project_user: 'studio-ops/users/status',
  remove_studio_project_user: 'studio-ops/projects/users/remove',
  remove_customer_project_user: 'customer-portal/projects/users/remove',
  downloadFullForm: 'ar-portal/studio/ca/riskemail/download',
  file_upload: 'ar-portal/presigned-url',
  accept_decline_risk:'ar-portal/studio/ca/riskemail',
}
