<div *ngIf = 'data'  class="comman-panel-block panel panel-default panel-passes-block">
  <div class="panel-heading">
    <h2>{{passesExpectedToday.passesExpectedToday}}</h2>
    <span (click) = "getPasses()" class="text-link refresh-link">{{passesExpectedToday.refresh}}<span><img alt="keyboard-right-arrow"
          src="../../../../../../assets/images/refresh-icon.svg" /></span></span>
  </div>
  <div class="checked-in-status">
    <div class="">
      <div class="checked-no">
        <span class="checked-text">{{passesExpectedToday.all}}</span>
        <span class="checked-value">{{allPasses}}</span>
      </div>
      <div (click)="onDriveOnClick()" class="checked-no">
        <span class="checked-text"><img alt="keyboard-right-arrow" class="align-img"
            src="../../../../../../assets/images/man.svg" /></span>
        <span class="checked-value">{{driveOn}}</span>
      </div>
      <div (click) = "onWalkOnClick()" class="checked-no">
        <span class="checked-text"><img alt="keyboard-right-arrow" class="align-img"
            src="../../../../../../assets/images/car.svg" /></span>
        <span class="checked-value">{{walkOn}}</span>
      </div>
    </div>
    <div>
      <div class="common-toggle-button">
        <label for="custom-toggle-1" class="common-toggle-label">
          {{passesExpectedToday.checkedIn}}
        </label>
        <input type="checkbox" id="custom-toggle-1" class="common-toggle-input" [checked]="showCheckedInOnly"
          (change)="toggleCheckedInStatus($event)" />
      </div>
    </div>
  </div>
  <!-- <div class="tablet-view-header">
    <div class="panel-footer-content ">
      <a class="text-link" href="">{{passesExpectedToday.viewAllPasses}} </a>
    </div>
  </div> -->
  <div class="panel-body table-responsive">
    <div class="container panel-passes-block-body">
      <ag-grid-angular #passesGrid class="ag-theme-custom no-header" [gridOptions]="gridOptions"
        [columnDefs]="colDefsPasses" [rowData]="rowDataPasses" (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
  <div class="panel-footer">
    <div class="panel-footer-content ">
      <a class="text-link" (click)="viewPasses()">{{passesExpectedToday.viewAllPasses}} </a>
    </div>
  </div>
</div>