import { Injectable } from '@angular/core';
import { CUSTOMER_PORTAL_API_PATHS, STUDIO_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';
import { BaseApiService } from '../base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectUserService {

  constructor(private baseApiService:BaseApiService) { }

  /**
  * projectUserSearch() - Method to fetch project user details by company ID, project ID, and user profile ID.
  * @param companyId - The ID of the company
  * @param projectId - The ID of the project
  * @param userProfileId - The ID of the user's profile
  * @returns - The API response containing project user details.
  */
  projectUserSearch(companyId,projectId,userProfileId) {
    let endpoint = `${STUDIO_PORTAL_API_PATHS.project_users_search}?companyId=${companyId}&projectId=${projectId}&userProfileId=${userProfileId}`;
    return this.baseApiService.get(endpoint);
  }

  /**
  * addCustomerProjectUser() - Method to add project user.
  * @param data - payload to be passed to the post call
  */
  addCustomerProjectUser(data) {
    let endpoint = `${CUSTOMER_PORTAL_API_PATHS.add_project_user}`;
    return this.baseApiService.post(endpoint, data);
  }

   /**
  * editCustomerProjectUser() - Method to add project user.
  * @param data - payload to be passed to the put call
  */
  editCustomerProjectUser(data) {
    let endpoint = `${CUSTOMER_PORTAL_API_PATHS.edit_project_user}`;
    return this.baseApiService.put(endpoint, data);
  }

  /**
  * addProjectUser() - Method to add project user.
  */
  addStudioProjectUser(payload) {
    let endpoint = STUDIO_PORTAL_API_PATHS.add_project_user;
    return this.baseApiService.post(endpoint, payload);
  }
  
  /**
  * updateStudioProjectUser() - Method to update project user.
  */
  
  updateStudioProjectUser(payload) {
    let endpoint = STUDIO_PORTAL_API_PATHS.add_project_user;
    return this.baseApiService.put(endpoint, payload);
  }
  
}
