export const ROW_DATA_CREDIT_APPLICATION = [
    {
      date: '1/11/24 ',
      arNo: '123456',
      department: 'Department Name; Department Name; Department Name',
      lineofCredit: '$1,000,000,00',
      projectName: 'Company Name',
      status: 'NEW APPLICATION',
  
    },
    {
      date: '1/11/24 ',
      arNo: '----',
      department: 'Studio Operations; Transportation; Design Studio; Post Production Creative Services; Property; Production Catering,',
      lineofCredit: '$1,000,000,00',
      projectName: 'Company Name',
      status: 'UNDER REVIEW',
    }
  ];

  export const ROW_DATA_REQUESTS = [
    { date: '1/11/24',
      request: 'Publicity Clearance',
      projectName: 'Company Name',
      status: 'NEW REQUEST'
     },
    { date: '1/11/24 ',
      request: 'WBSO Pass Request',
      projectName: 'Company Name', 
      status: 'IN PROGRESS' 
    },
    { date: '1/11/24 ',
      request: 'Publicity Clearance',
      projectName: 'Company Name',
      status: 'RESOLVED' 
    },
  ];

  export const BOOKMARKED_PROJECTS = [
    { title: 'The Court',
      season: 'Season 1',
      production: 'Sunshine Productions',
      year: '2022',
      status: 'Active',
      imgUrl: '../../../../../assets/images/project-image.png"'
    },
    { title: 'TV Show Name',
      season: 'Season 1',
      production: 'Sunshine Productions',
      year: '2022',
      status: 'Not Started',
      imgUrl: '../../../../../assets/images/project-image.png"'
    },
    { title: 'Feature Name',
      season: '',
      production: 'Prod Name',
      year: '2022',
      status: 'Closed',
      imgUrl: '../../../../../assets/images/project-image.png"'
    }
  ]
  