<div class="overview-wrapper">
  <div class="container overview-user-header-wrapper">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 ">
        <div class="user-greet-action-block">
          <div class="user-greeting-block">
            <h4 class="greeting-text">Hi, <span class="greet-user-name">{{first_name}}</span></h4>
          </div>
          <div class="user-actions-block">
            <div class="d-flex ">
              <button class="btn btn-dark custom-button">
                <img class="common-btn-primary-icon" src="../../assets/images/add.svg" alt="add" />
                <span> {{dashboard.request}}</span>
              </button>
              <button *ngIf="permissionsData['addUser']" class="btn btn-dark custom-button" (click)="addUser()">
                <img class="common-btn-primary-icon" src="../../assets/images/add.svg" alt="add" />
                <span> {{dashboard.user}}</span>
              </button>
              <button class="btn btn-dark custom-button">
                <img class="common-btn-primary-icon" src="../../assets/images/add.svg" alt="add" />
                <span>{{dashboard.credit}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 ">
        <app-weather-forecast></app-weather-forecast>
      </div>
    </div>
  </div>
</div>
<div class="container overview-user-requests-wrapper">
  <div class="row">
    <!-- Left section with 3 TABLES i.e.,ACTIVE PROJECTS, REQUEST, CREDIT-APPLICATION  -->
    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mt-0 left-column-top ">
      <div *ngIf="projectsList && projectsList?.length == 0" class="common-panel-block panel panel-default panel-no-project-block d-flex align-items-center">
        <p class="text-center">
          {{dashboard.inActive}}
          <a href="mailto:OpsClientServices@wbd.com">{{dashboard.administrator}}</a> {{dashboard.assistance}}
        </p>
      </div>
      <div *ngIf="projectsList && projectsList?.length > 0">
        <app-active-projects [resources]="groupedSpacesAndResources" *ngIf="[projectsList]?.length > 0"
                             [projectList]="projectsList"></app-active-projects>
      </div>
      <app-requests></app-requests>
      <app-credit-applications></app-credit-applications>
    </div>
    <!-- Widget Section-passes and on-the-lot -->
    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-0  right-column-top">
      <app-pass-expected-today></app-pass-expected-today>
      <app-on-the-lot *ngIf="response" [response]="response" (lotData)="updateOnTheLot($event)"></app-on-the-lot>
    </div>
    <!--<div *ngIf="projectsList && projectsList?.length == 0" class="common-panel-block panel panel-default panel-no-project-block">
      <p class="text-center">
        {{dashboard.inActive}}
        <a href="mailto:OpsClientServices@wbd.com">{{dashboard.administrator}}</a> {{dashboard.assistance}}
      </p>
    </div>-->
  </div>
</div>
<ng-template #successTpl>
  <div class="success">{{addUserSuccess}}</div>
</ng-template>

<ng-template #dangerTpl>
  <div class="danger">{{addUserError}}</div>
</ng-template>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>


