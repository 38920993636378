<div class="comman-panel-block panel panel-default panel-credit-block">
  <div class="panel-heading">
    <h2>{{creditApplicationConstants.creditApplication}}</h2>
  </div>
  <div class="panel-body">
    <div class="container panel-credit-block-body">
      <ag-grid-angular #creditGrid style="width: 100%; height: 100%;" class="ag-theme-custom no-header"
        [gridOptions]="gridOptions" [columnDefs]="colDefsCredit" [rowData]="rowDataCredit"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
  <div class="panel-footer" *ngIf="creditApplications?.length>0">
    <div class="panel-footer-content "><a class="text-link"
      routerLink="/feature/studio-dashboard/credit-app">{{creditApplicationConstants.viewAllCreditApplications}}</a>
    </div>
  </div>
</div>