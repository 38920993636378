import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

/**
 * A base service class for making HTTP requests.
 * Provides methods for GET, POST, PUT, and DELETE operations.
 * Centralizes common HTTP configurations and error handling.
 */
@Injectable({
  providedIn: 'root'  // This makes sure that the service is available throughout the application
})
export class BaseApiService {
  /**
   * The base URL for the API.
   * @readonly
   */
  private readonly API_BASE_URL: string = environment.apiGateWayBaseURL; // Set your base API URL here

  constructor(private http: HttpClient, @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { }
    
  
  ///**
  // * getHttpOptions() - Gets the standard HTTP options including headers.
  // * Sets 'Content-Type' to 'application/json' and includes the 'Authorization' header with a bearer token.
  // * @returns An object containing the HTTP options.
  // */
  //private getHttpOptions(): { headers: HttpHeaders } {
  //  const headers = new HttpHeaders({
  //    'Content-Type': 'application/json',     //Optional 
  //    'Authorization': `${this.getToken()}`, //Optional
  //    'Access-Control-Allow-Origin': '*',
  //    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
  //    "Access-Control-Allow-Headers": "Content-Type, x-requested-with"
  //  });

  //  return { headers };
  //}

  ///**
  // * getToken() - Retrieves the token from storage (e.g., localStorage).
  // * @returns The token string or an empty string if no token is found.
  // */
  //private getToken(): string {
  //  let token;
  //  if (localStorage.getItem('okta-token-storage')) {
  //    if (JSON.parse(localStorage.getItem('okta-token-storage')!).accessToken !== undefined) {
  //      if (JSON.parse(localStorage.getItem('okta-token-storage')!).accessToken.accessToken !== undefined) {
  //        if (JSON.parse(localStorage.getItem('okta-token-storage')!).accessToken.accessToken.length > 0) {
  //          !).accessToken.accessToken)
  //          token = JSON.parse(localStorage.getItem('okta-token-storage')!).accessToken.accessToken || '';
  //        }
  //      }
  //    }
  //  }
  //  return token;
  //}

  /**
   * get<T>() - Performs a GET request to the specified endpoint.
   * @param endpoint - The endpoint to which the GET request is sent.
   * @returns Observable<T> - An observable of the response type.
   */
  get<T>(endpoint: string): Observable<T> {
    return this.http.get<T>(`${this.API_BASE_URL}/${endpoint}`)
      .pipe(catchError(this.handleError) 
      );
  }

  /**
   * post<T>() - Performs a POST request to the specified endpoint with the provided data.
   * @param endpoint - The endpoint to which the POST request is sent.
   * @param data - The data to be sent in the request body.
   * @returns Observable<T> - An observable of the response type.
   */
  post<T>(endpoint: string, data: any, p0?: { headers: { 'Content-Type': string; }; }): Observable<T> {
    return this.http.post<T>(`${this.API_BASE_URL}/${endpoint}`, data)
      .pipe(catchError(this.handleError)
      );
  }

  /**
   * put<T>() - Performs a PUT request to the specified endpoint with the provided data.
   * @param endpoint - The endpoint to which the PUT request is sent.
   * @param data - The data to be sent in the request body.
   * @returns Observable<T> - An observable of the response type.
   */
  put<T>(endpoint: string, data: any): Observable<T> {
    return this.http.put<T>(`${this.API_BASE_URL}/${endpoint}`, data)
      .pipe(catchError(this.handleError)  
      );
  }

    /**
   * put<T>() - Performs a PUT request to the specified endpoint with the provided data.
   * @param endpoint - The endpoint to which the PUT request is sent.
   * @param data - The data to be sent in the request body.
   * @param fileData - the data of the updated file
   * @returns Observable<T> - An observable of the response type.
   */
  putFile<T>(endpoint: string, data: any, p0?: { headers: { 'Content-Type': string; }; }): Observable<T> {
    return this.http.put<T>(`${this.API_BASE_URL}/${endpoint}`, data)
      .pipe(catchError(this.handleError)  
      );
  }

  /**
   * delete<T>() - Performs a DELETE request to the specified endpoint.
   * @param endpoint - The endpoint to which the DELETE request is sent.
   * @returns Observable<T> - An observable of the response type.
   */
  delete<T>(endpoint: string): Observable<T> {
    return this.http.delete<T>(`${this.API_BASE_URL}/${endpoint}`)
      .pipe(catchError(this.handleError)  // Catch and handle errors
      );
  }

  /**
   * handleError() - Centralized method to handle HTTP errors.
   * @param error - The HTTP error response.
   * @returns Observable<never> - An observable that throws an error message.
   */
  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = '';  // Initialize an error message variable
    let errorLog = '';  // Initialize an backend error object variable
    let errorCode = error.status;

    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;  // Set the error message from the client-side error
    } else {
      // Server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;  // Set the error message from the server-side error
      if(error.error.errorMessages) {
        errorLog = `${error.error.errorMessages[0]?.errorMessage}`  // Set the error message if the error response has specified message from the server
      }
    }
    if (errorCode == 401) {
      window.location.href = '/homepage';
      localStorage.clear();
      localStorage.removeItem('access-token');
      localStorage.removeItem('okta-token-storage');
    }
    console.error(errorMessage);  // Log the error message to the console
    if(error.error.errorMessages) {
      return throwError(errorLog);   // Return an observable with the error message on case basis
    }
    return throwError(error.error.message); // Return an observable with the error message
  }
}
