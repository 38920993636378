<div class="overview-wrapper">
  <div class="container overview-user-header-wrapper">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 ">
        <div class="user-greet-action-block">
          <div class="user-greeting-block">
            <h4 class="greeting-text">Hi, <span class="greet-user-name">Seema</span></h4>
          </div>
          <div class="review-test">Here’s the projects activity your need to review:</div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 ">
      </div>
    </div>
  </div>
  <div class="container overview-user-requests-wrapper">
    <div class="row">
      <!-- Left section with 3 TABLES i.e.,REQUEST, CREDIT-APPLICATION  -->
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 order-2 order-lg-1 order-md-2 order-sm-2 mt-0S">
        <div class="comman-panel-block panel panel-default panel-credit-block">
          <div class="panel-heading">
            <h2>Credit Application</h2>
          </div>
          <div class="panel-body">
            <div class="container panel-credit-block-body">
              <ag-grid-angular #creditGrid style="width: 100%; height: 100%;" class="ag-theme-custom no-header"
                [gridOptions]="gridOptions" [columnDefs]="colDefsCredit" [rowData]="rowDataCredit"
                (gridReady)="onGridReady($event)">
              </ag-grid-angular>
            </div>
          </div>
          <div class="panel-footer">
            <div class="panel-footer-content "><a class="text-link" href="">View All Credit Applications</a>
            </div>
          </div>
        </div>
        <div class="comman-panel-block panel panel-default panel-request-block">
          <div class="panel-heading">
            <h2>Requests</h2>
          </div>
          <div class="panel-body ">
            <div class="container panel-request-block-body">
              <ag-grid-angular #requestGrid style="width: 100%; height: 100%;" class="ag-theme-custom no-header"
                [gridOptions]="gridOptions" [columnDefs]="colDefsRequest" [rowData]="rowDataRequest"
                (gridReady)="onGridReady($event)">
              </ag-grid-angular>
            </div>
          </div>
          <div class="panel-footer panel-footer-sm">
            <div class="panel-footer-content ">
              <a href="#" class="text-link"> View All Requests</a>
            </div>
          </div>
        </div>
      </div>
      <!-- Widget Bookmarked projects -->
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-0 order-1 order-lg-2 order-md-1 order-sm-1S">
        <div class="bookmarked-project">
          <div class="comman-panel-block ">
            <div class="panel-heading">
              <h2>Bookmarked Projects</h2>

            </div>
          </div>
          <div class="panel-body">
            <div class="bookmark-main-wrapper">
              <div class="bookmark-project-wrapper">
                <div class="project-desc">
                  <div class="row ">
                    <div class="col-10 d-flex">
                      <div class="project-image-block">
                        <img src="../../../../../assets/images/project-image1.png"
                          onerror="this.src='../../../../../../assets/images/project-image1.png'"
                          alt="active-project-image" class="active-project-image img-fluid">
                      </div>
                      <div class="project-info-block">
                        <h5>The Court</h5>
                        <p class="season">Season 1</p>
                        <p class="prod-name">Sunshine Productions</p>
                      </div>
                    </div>
                    <div class="col-2 d-flex align-items-center justify-content-end">
                      <div class="project-year">
                        <p>2023</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bookmark-dropdown-wrapper">
                <div class="container">
                  <div class="row">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 px-0">
                      <div class='custom-dropdown'>
                        <select class="custom-select">
                          <option class="dropdown-option" value="" disabled selected hidden>Go To…</option>
                          <option class="dropdown-option" value="1">Projects</option>
                          <option class="dropdown-option" value="2">Users</option>
                          <option class="dropdown-option" value="3">Spaces & Resources</option>
                          <option class="dropdown-option" value="3">Company</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2 col-lg-2 d-flex align-items-center">
                      <app-pop-up-cell-renderer></app-pop-up-cell-renderer>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 pe-0 bookmark-status">
                      <span class="project-status-tag active-tag-green">Active</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bookmark-main-wrapper">
              <div class="bookmark-project-wrapper">
                <div class="project-desc">
                  <div class="row">
                    <div class="col-10 d-flex">
                      <div class="project-image-block">
                        <img src="../../../../../assets/images/project-image.png"
                          onerror="this.src='../../../../../../assets/images/project-image1.png'"
                          alt="active-project-image" class="active-project-image img-fluid">
                      </div>
                      <div class="project-info-block">
                        <h5>The Court</h5>
                        <p class="season">Season 1</p>
                        <p class="prod-name">Sunshine Productions</p>
                      </div>
                    </div>
                    <div class="col-2 d-flex align-items-center justify-content-end">
                      <div class="project-year">
                        <p>2022</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bookmark-dropdown-wrapper">
                <div class="container">
                  <div class="row">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 px-0">
                      <div class='custom-dropdown'>
                        <select class="custom-select">
                          <option class="dropdown-option" value="" disabled selected hidden>Go To…</option>
                          <option class="dropdown-option" value="1">Projects</option>
                          <option class="dropdown-option" value="2">Users</option>
                          <option class="dropdown-option" value="3">Spaces & Resources</option>
                          <option class="dropdown-option" value="3">Company</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2 col-lg-2 d-flex align-items-center">
                      <app-pop-up-cell-renderer></app-pop-up-cell-renderer>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 pe-0 bookmark-status">
                      <span class="project-status-tag not-started-purple">not Started</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>