import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { NgbAccordionModule, NgbActiveModal, NgbDatepickerModule, NgbDropdownModule, NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { ViewDetailsCellRendererComponent } from './components/ag-grid-renderer/viewdetails-cell-renderer/viewdetails-cell-renderer.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { IconsComponent } from './components/ag-grid-icons/icons/icons.component';
import { CommonAgGridComponent } from './components/common-ag-grid/common-ag-grid.component';
import { AddWbUserComponent } from './components/add-wb-user/add-wb-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchComponent } from './components/search/search.component';
import { GoToDropdownComponent } from './components/go-to-dropdown/go-to-dropdown.component';
import { PopUpCellRendererComponent } from './pop-up-cell-renderer/pop-up-cell-renderer.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { MergedCellComponent } from './components/merged-cell/merged-cell.component';
import { MergedCellIconsComponent } from './components/merged-cell-icons/merged-cell-icons.component';
import { NgMultiSelectDropDown } from './components/ng-multiselect-dropdown/ng-multiselect-dropdown.module';
import { ToastComponent } from './components/toast/toast.component';
import { ToastService } from './components/toast.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    LoaderComponent,
    ViewDetailsCellRendererComponent,
    PaginationComponent,
    IconsComponent,
    CommonAgGridComponent,
    AddWbUserComponent,
    SearchComponent,
    GoToDropdownComponent,
    PopUpCellRendererComponent,
    DatepickerComponent,
    MergedCellComponent,
    MergedCellIconsComponent,
    ToastComponent
  ],
  imports: [
    CommonModule,
    NgbAccordionModule,
    NgbModule,
    AgGridModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    NgMultiSelectDropDown,
    NgbToastModule,
    NgbTooltipModule,
    NgbDropdownModule,

  ],
  providers:[ToastService],
  exports: [ LoaderComponent, PaginationComponent, CommonAgGridComponent, PopUpCellRendererComponent,DatepickerComponent,SearchComponent,ToastComponent]
})
export class SharedModule { }
