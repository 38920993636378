<div>
    <span>
    <img
          src="assets/images/image-edit-icon.svg.svg"
          class="eye"
          alt="image-dropdown"
          #trigger
          (click)="togglePopup()"
          data-action="toggle"
        />
    </span>
    <div #content *ngIf="isOpen" class="menu-container">
    <div class="menu-item" (click)="onUploadPhoto()">
    <span
            class="icon-hover"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Upload Photo"
    >
    <span class="img-content">Upload Photo</span>
    </span>
    </div>
    <div class="menu-item" (click)="onResetPhoto()">
    <span
            class="icon-hover"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Reset Photo"
    >
    <span class="img-content">Reset Photo</span>
    </span>
    </div>
    </div>
    </div>