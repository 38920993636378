import { HttpClient } from '@angular/common/http';
import { Component, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-set-lighting-form',
  templateUrl: './set-lighting-form.component.html',
  styleUrls: ['./set-lighting-form.component.scss']
})
export class SetLightingFormComponent {
  savedResponse: any;

  constructor(private renderer: Renderer2, private http: HttpClient) { }

  ngOnInit(): void {
    this.loadCognitoForm();
   
  }
  
  
  loadCognitoForm() {
    const script = this.renderer.createElement('script');
    script.src = 'https://www.cognitoforms.com/f/seamless.js';
    script.setAttribute('data-key', 'sGbqZu1iBkyf6kYvL5mSpA');
    script.setAttribute('data-form', '6');
    script.async = true;

   
    const container = document.getElementById('cognito-form-container');
    if (container) {
      this.renderer.appendChild(container, script);
      console.log('Cognito form script added to the container.');
    } else {
      console.error('Cognito form container not found');
    }

}
}
