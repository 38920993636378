<button data-bs-toggle="modal" data-bs-target="#reviewStep2">Review step 2</button>
<!-- common modal section-->
<div class="modal fade" id="reviewStep2" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="false">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
          <h5 class="modal-title" id="commonModalLabel">Review</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
              class="cm-close-text">Close</span></button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
              <div class="modal-left-section me-4">
                <h4 class="common-left-section-title mt-4">Company Info</h4>
                <div class="company-meta-data">
                  <p class="title mb-0"><strong class="common-title">PROJECT:</strong><span class="common-content">The new show</span></p>
                  <p class="title mb-0"><strong class="common-title">Comapny:</strong></p>
                  <p class="pb-2 pt-0 mb-0 commmon-content">A Brand New Production Company</p>
                  <p class="title mb-0"><strong class="common-title">TYPE:</strong><span class="common-content">Major Studio</span></p>
                  <p class="title mb-0"><strong class="common-title">APPLICATION DATE:</strong><span class="common-content">1/1/24</span></p>
                  <div class="d-flex flex-column mt-2">
                    <span class=" mb-1"><a class="text-link"><img src="../assets/images/link-icon.svg" alt=""
                          class="me-2">View Projects & Quotes</a></span>
                    <span class=" mb-1"><a class="text-link"><img src="../assets/images/link-icon.svg" alt=""
                          class="me-2">View Company</a></span>
                  </div>
                </div>
                <hr>
                <h4 class="common-left-section-title">Review</h4>
                <div class="mb-4 mt-3">
                  <div class="list-group list-group-flush mb-2">
                    <ul class="step-list">
                      <li>
                        <span class="step-icon"></span>
                        <span class="step-text current-step">Documents</span>
                      </li>
                      <li>
                        <span class="step-icon"></span>
                        <span class="step-text">Summary</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="container cm-main-container px-0 mx-0">

                <div class="common-modal-input-section grey-bg review-panel">
                  <div class="row mb-3 align-items-center">
                    <div class="col-md-7">
                      <h4 class="right-section-heading">Purchase Order</h4>
                    </div>
                    <div class="col-md-5">
                      <div class=" custom-dropdown w-100">
                        <select class="custom-select">
                          <option class="dropdown-option" value="" disabled selected hidden>Action</option>
                          <option class="dropdown-option" value="1"><img src="../assets/images/action-revision.svg"
                              alt="">Ask for Revision</option>
                          <option class="dropdown-option" value="2">Mark as Reviewed</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row pt-3">
                    <div class="col-md-6"><span class="d-flex gap-3 align-items-center"><img src="../assets/images/pdf-icon.svg" alt="">PO.pdf</span></div>
                    <div class="col-md-6 text-end"><a href="#" class="text-link">View</a></div>
                  </div>
                  <hr class="my-5">
                  <div class="row mb-3 align-items-center">
                    <div class="col-md-7">
                      <h4 class="right-section-heading">Certificate of Insurance</h4>
                    </div>
                    <div class="col-md-5">
                      <div class=" custom-dropdown w-100">
                        <select class="custom-select">
                          <option class="dropdown-option" value="" disabled selected hidden>Action</option>
                          <option class="dropdown-option" value="1"><img src="../assets/images/action-revision.svg"
                              alt="">Ask for Revision</option>
                          <option class="dropdown-option" value="2">Mark as Reviewed</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row pt-3">
                    <div class="col-md-6"><span class="d-flex gap-3 align-items-center"><img src="../assets/images/pdf-icon.svg" alt="">COI.pdf</span></div>
                    <div class="col-md-6 text-end"><a href="#" class="text-link">View</a></div>
                  </div>
                </div>

                <div class="grey-box">
                    <h2 class="section-main-header text-left common-heading">
                      Application Form Activity
                    </h2>
                    <div class="comment-section">
                      <div class="row comment-list">
                        <div class="commnet-icon-section col-md-1">
                          <span class="user-icon-text">AN</span>
                        </div>
                        <div class="common-comment-section col-11">
                          <p class="font-14 mb-0">
                            <strong class="common-content-bold"
                              >Andrea Noj</strong
                            >
                          </p>
                          <p class="common-small-content">xx/xx/xx xx:xxAM</p>
                          <p class="common-normal-content">
                            Reviewed Purchase Order
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cm-btn-section">
                    <button
                      type="button"
                      class="btn common-btn-secondary me-2 mb-2"
                    >
                    Next:Summary
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!---Added modals on click of Select Company-->
<ng-template #company let-modal>
  <div class="modal-body">
    <h2 class="section-main-header">Needs Revisions </h2>
    <p>COMPANY: A Brand New Company</p>
    <p>PROJECT: The new show</p>
    <div class="">
      <div class="mt-56 custom-floating-label">
        <label for="input1" class="form-label">Comments</label>
        <textarea name="comments" id="" rows="4"></textarea>
        <img class="search-icon" src="../../../../../../assets/images/search-icon.svg" alt="Search Icon">
      </div>
    </div>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="btn common-btn-secondary" (click)="modal.close('Close click')">Cancel</button>
      <button type="button" class="btn common-btn-secondary">Select</button>
    </div>
  </div>
</ng-template>