// src/app/core/core-routing.module.ts

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FaqComponent } from './components/header/faqs/faqs.component';
import { WbsoHandbookComponent } from './components/header/wbso-handbook/wbso-handbook.component';
import { WbsoHandbookPageComponent } from './components/header/wbso-handbook/wbso-handbook-page/wbso-handbook-page.component';
import { CoreDirectoryComponent } from './components/header/core-directory/core-directory.component';
import { MapsComponent } from './components/header/maps/maps.component';
import { MenuComponent } from './components/menu/menu.component';


const routes: Routes = [
      { path: 'faqs', component: FaqComponent },
      { path: 'wbso-handbook', component: WbsoHandbookComponent },
      { path: 'wbso-handbook-page', component: WbsoHandbookPageComponent },
      { path: 'core-directory', component: CoreDirectoryComponent },
      { path: 'maps', component: MapsComponent }
    ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
