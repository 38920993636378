import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CRED_FORM_THREE_CONSTANT,
  TERMS_AND_CONDITIONS,
} from 'src/app/feature/customer-portal/constants/customer-portal.constant';
import { FormValidationService } from 'src/app/shared/services/form-validations/form-validation.service';
import { CreditApplicationsService } from '../../../../services/credit-applications/credit-applications.service';
import {
  NgbDateStruct,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cred-app-step-three',
  templateUrl: './cred-app-step-three.component.html',
  styleUrls: ['./cred-app-step-three.component.scss'],
  providers: [DatePipe],
})
export class CredAppStepThreeComponent implements OnInit {
  @Input() customerStatus;
  @Input() revisionData: any;
  @Input() creditAppStatus: string;
  @Input() creditMetaData: any;
  @Input() startApplicationData: any;
  @Output() saveAndCloseEvent = new EventEmitter<void>();
  @Output() prevStepEvent = new EventEmitter<void>();
  @ViewChild('NgbdDatepicker') d: NgbDateStruct;
  form: FormGroup;
  credFormThree = CRED_FORM_THREE_CONSTANT;
  termsContent = TERMS_AND_CONDITIONS;
  model: NgbDateStruct;
  termsAndConditionsData;

  constructor(
    private fb: FormBuilder,
    private formValidationService: FormValidationService,
    private creditApplicationsService: CreditApplicationsService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.initializeStep3Form();
    this.checkCreditAppStatus();
    this.updateFormControls()
    this.termsAndConditionsData =
      this.startApplicationData?.creditApplicationList?.termAndCondition;
    // this.patchFormData(this.termsAndConditionsData);
    this.patchRevisions()
  }

    initializeStep3Form() {
    this.form = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          this.formValidationService.validateAlphabets,
        ],
      ],
      title: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          this.formValidationService.validateAlphanumericAndSpecialCharacters,
        ],
      ],
      signature: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          this.formValidationService.validateAlphabets,
        ],
      ],
      date: ['', [Validators.required]],
      convertedDate: [''],
    });
  }
 
  /**
   * updateFormControls() - to update the form controld based on review required
   */
  updateFormControls(): void {
    const data = this.revisionData?.caRevisionDetails?.termsAndConditionsDetails?.reviewRequired;
    if (this.customerStatus === this.credFormThree.needsRevision && data) {
      this.form.enable();
    } else {
      this.form.disable(); 
    }
  }

  /**
   * patchFormData() - patches the form with the data from termsAndConditionsData
   */
  patchFormData(data: any): void {
    this.form.patchValue({
      name: data?.name || '',
      title: data?.title || '',
      signature: data?.signature || '',
      date: this.convertStringToNgbDate(data?.date) || null, // Convert string date to NgbDateStruct
    });
  }

  /**
   * convertStringToNgbDate() - converts a date string to NgbDateStruct
   */
  convertStringToNgbDate(dateString: string): NgbDateStruct {
    if (!dateString) return null;

    const parts = dateString.split('/');
    return {
      year: parseInt(parts[2], 10), // Assuming the format is dd/MM/yyyy
      month: parseInt(parts[1], 10),
      day: parseInt(parts[0], 10),
    };
  }

  /**
   *onSubmit() - submitting the step3 form for the credit application
   */

  onSubmit(): any {
    if (this.creditAppStatus !== 'Needs Revisions') {
      let payLoadData = this.payLoadData();
      if (payLoadData) {
        this.creditApplicationsService
          .creditApplicationTermsandCon(payLoadData)
          .subscribe({
            next: (response: any) => {},
            error: (error) => {},
          });
      }
    } else {
      let revisionPayLoadData = this.revisionPayLoadData();
      if (revisionPayLoadData) {
        this.creditApplicationsService
          .creditApplicationRevisionTermsandCon(revisionPayLoadData)
          .subscribe({
            next: (response: any) => {},
            error: (error) => {},
          });
      }
    }
  }

  /**
   *payLoadData() - returns the payload based on condition to submit the form
   */
  payLoadData(): any {
    const formValue = this.form.getRawValue();
    let caSeqId = this.startApplicationData?.creditApplicationList?.caSeqId;
    let caNumber = this.startApplicationData?.creditApplicationList?.caNumber;
    let payload = {};
    if (this.creditMetaData.tcId == null) {
      payload = {
        tcVersionId: this.creditMetaData?.tcVersionId,
        caSeqId: caSeqId,
        caNumber: caNumber,
        tcId: null,
        name: formValue.name,
        title: formValue.title,
        signature: formValue.signature != '' ? formValue.signature : null,
        date: formValue.convertedDate,
      };
    } else {
      payload = {
        tcVersionId: this.creditMetaData?.tcVersionId,
        caSeqId: caSeqId,
        caNumber: caNumber,
        tcId: this.termsAndConditionsData?.tcId,
        name: formValue.name,
        title: formValue.title,
        signature: formValue.signature != '' ? formValue.signature : null,
        date: formValue.convertedDate,
      };
    }
    return payload;
  }

  /**
   *revisionPayLoadData() - returns the payload based on condition to submit the form for revision
   */
  revisionPayLoadData(): any {
    const formValue = this.form.getRawValue();
    let caSeqId = this.startApplicationData?.creditApplicationList?.caSeqId;
    let caNumber = this.startApplicationData?.creditApplicationList?.caNumber;
    let payload = {
      tcVersionId: this.creditMetaData?.tcVersionId,
      // dynamic data should be passed once the credit app api is done
      caSeqId: caSeqId,
      caNumber: caNumber,
      tcId: this.termsAndConditionsData?.tcId,
      name: formValue.name,
      title: formValue.title,
      signature: formValue.signature != '' ? formValue.signature : null,
      date: formValue.convertedDate,
    };

    return payload;
  }

  /**
   * checkCreditAppStatus() - method to disable the form based on credit application status
   */
  checkCreditAppStatus(): void {
    if (this.customerStatus === this.credFormThree.needsRevision) {
      this.disableFormControls();
    }
  }

  /**
   * disableFormControls() - method to disable the form controls
   */
  disableFormControls(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key)?.disable(); // Disable top-level controls
    });
  }

  /**
   * previousPage() - method to handle the event of going back to previous page
   */
  previousPage(): void {
    this.prevStepEvent.emit();
  }

  /**
   * onDateSelect() - method to convert the selected date for the payload
   */

  onDateSelect(date: any) {
    const formattedDate = this.datePipe.transform(
      new Date(date.year, date.month - 1, date.day),
      'yy-MM-dd'
    );
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0'); // Ensure 2 digits
    const minutes = String(now.getMinutes()).padStart(2, '0'); // Ensure 2 digits
    const seconds = String(now.getSeconds()).padStart(2, '0'); // Ensure 2 digits
    const finalDate = `${formattedDate} ${hours}:${minutes}:${seconds}`;
    this.form.get('convertedDate').setValue(finalDate);
  }

  /**
   * patchRevisions() - to patch values in form for step three
   */
  patchRevisions(): void {
    const data =
      this.revisionData?.caRevisionDetails?.termsAndConditionsDetails;
    this.form?.patchValue({
      name: data?.name,
      title: data?.title,
      signature: data?.signature,
      date: data?.data,
    });
  }
}
