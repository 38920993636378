<div class="comman-panel-block panel panel-default panel-request-block">
  <div class="panel-heading">
    <h2>{{requestConstants.requests}}</h2>
  </div>
  <div class="panel-body ">
    <div class="container panel-request-block-body">
      <ag-grid-angular #requestGrid style="width: 100%; height: 100%;" class="ag-theme-custom no-header"
        [gridOptions]="gridOptions" [columnDefs]="colDefsRequest" [rowData]="rowDataRequest"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
  <div class="panel-footer panel-footer-sm">
    <div class="panel-footer-content ">
      <a href="#" class="text-link">{{requestConstants.viewAllRequests}}</a>
    </div>
  </div>
</div>