<button data-bs-toggle="modal" data-bs-target="#credApp">Final Form</button>
<!-- common modal section-->
<div
  class="modal fade"
  id="credApp"
  tabindex="-1"
  aria-labelledby="commonModalLabel"
  aria-hidden="false"
>
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center">
        <div class="cm-header-section">
          <h5 class="modal-title mb-4" id="commonModalLabel">
            Credit Application
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="cm-close-text">Close</span>
          </button>
        </div>
        <div class="container px-0">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
              <div class="modal-left-section me-4">
                <div class="common-left-section-title">
                  <h4>Credit Application</h4>
                </div>
                <div class="mb-4 mt-3">
                  <div class="list-group list-group-flush mb-2">
                    <ul class="step-list mb-3">
                      <li>
                        <span class="step-icon"></span>
                        <span class="step-text current-step">Documents</span>
                      </li>
                      <li>
                        <span class="step-icon"></span>
                        <span class="step-text">Summary</span>
                      </li>
                    </ul>
                  </div>
                  <span class=""
                    ><a class="text-link"
                      ><img
                        src="../assets/images/pdf-blue-icon.svg"
                        alt=""
                        class="me-2"
                      />
                      Download Packet</a
                    ></span
                  >
                </div>
                <hr />
                <h4 class="common-left-section-title my-4">Company Info</h4>
                <div class="company-meta-data">
                  <p class="title mb-0">
                    <strong class="common-title">PROJECT:</strong
                    ><span class="common-content">The new show</span>
                  </p>
                  <p class="title mb-0 pb-2">
                    <strong class="common-title">Comapny:</strong>
                  </p>
                  <p class="pb-2 pt-0 mb-0 common-content">
                    A Brand New Production Company
                  </p>
                  <p class="title mb-0">
                    <strong class="common-title">TYPE:</strong
                    ><span class="common-content">Major Studio</span>
                  </p>
                  <p class="title mb-0">
                    <strong class="common-title">APPLICATION DATE</strong
                    ><span class="common-content">1/1/24</span>
                  </p>
                  <div class="d-flex flex-column mt-2">
                    <span class="mb-1"
                      ><a class="text-link"
                        ><img
                          src="../assets/images/link-icon.svg"
                          alt=""
                          class="me-2"
                        />
                        View Projects & Quotes</a
                      ></span
                    >
                    <span class="mb-1"
                      ><a class="text-link"
                        ><img
                          src="../assets/images/link-icon.svg"
                          alt=""
                          class="me-2"
                        />
                        View Company</a
                      ></span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="container cm-main-container px-0 mx-0">
                <div class="common-modal-input-section grey-bg review-panel">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <h4 class="right-section-heading">Line of Credit</h4>
                    </div>
                    <div class="col-md-8">
                      <div class="common-user-info">
                        <p class="title mb-0">
                          <strong class="common-title"
                            >Line of Credit requested:</strong
                          ><span class="common-content">$xxx,xxx,xxx.xx</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title"
                            >Anticipated Job Total:</strong
                          ><span class="common-content">$xxx,xxx,xxx.xx</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title"
                            >Total # of months:</strong
                          ><span class="common-content">2</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr class="my-4" />
                  <!-- divider -->

                  <div class="row mb-4">
                    <div class="col-md-4">
                      <h4 class="right-section-heading">
                        Letter of Credit/Guarantee
                      </h4>
                    </div>
                    <div class="col-md-8">
                      <div class="common-user-info">
                        <p class="title mb-0">
                          <strong class="common-title">Comments: </strong
                          ><span class="common-content">None</span>
                        </p>
                      </div>
                      <div class="row pt-3">
                        <div class="col-md-6">
                          <span
                            ><img src="../assets/images/pdf-icon.svg" alt="" />
                            signed.pdf</span
                          >
                        </div>
                        <div class="col-md-6 text-end">
                          <a href="#" class="text-link">View</a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr class="my-4" />
                  <!-- divider -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <h4 class="right-section-heading">Company</h4>
                    </div>
                    <div class="col-md-8">
                      <div class="common-user-info">
                        <p class="title mb-0">
                          <strong class="common-title">Name:</strong
                          ><span class="common-content"
                            >A Brand New Company</span
                          >
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Address:</strong
                          ><span class="common-content"
                            >2500 Main Street, Los Angeles, CA, 92783</span
                          >
                        </p>
                        <p class="title mb-2">
                          <strong class="common-title">Phone:</strong
                          ><span class="common-content">858556565</span>
                        </p>
                        <p class="title mb-0">
                          <input
                            type="checkbox"
                            name=""
                            class="common-content me-2"
                            checked
                            disabled
                          />
                          <label for="" class="common-content"
                            >Use for billing address</label
                          >
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr class="my-4" />
                  <!-- divider -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <h4 class="right-section-heading">Federal Tax ID #</h4>
                    </div>
                    <div class="col-md-8">
                      <div class="common-user-info">
                        <p class="title mb-0">
                          <strong class="common-title">TIN:</strong
                          ><span class="common-content">123456789</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr class="my-4" />
                  <!-- divider -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <h4 class="right-section-heading">
                        Accounts Payable Contact
                      </h4>
                    </div>
                    <div class="col-md-8">
                      <div class="common-user-info">
                        <p class="title mb-0">
                          <strong class="common-title">Name:</strong
                          ><span class="common-content"
                            >A Brand New Company</span
                          >
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Email:</strong
                          ><span class="common-content">name@domain.com</span>
                        </p>
                        <p class="title mb-2">
                          <strong class="common-title">Phone:</strong
                          ><span class="common-content">858556565</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr class="my-4" />
                  <!-- divider -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <h4 class="right-section-heading">Company Profile</h4>
                    </div>
                    <div class="col-md-8">
                      <div class="common-user-info">
                        <p class="title mb-0">
                          <strong class="common-title">Profile:</strong
                          ><span class="common-content">Corporate</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Date Started:</strong
                          ><span class="common-content">1/1/24</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Type of Business:</strong
                          ><span class="common-content">Corporation</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title"
                            >Total Annual Sales:</strong
                          ><span class="common-content">$xx.xx</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Net worth:</strong
                          ><span class="common-content">$xx.xx</span>
                        </p>
                        <p class="title mb-2">
                          <strong class="common-title"># of Employees:</strong
                          ><span class="common-content">0-25</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr class="my-4" />
                  <!-- divider -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <h4 class="right-section-heading">
                        Principals/Officers or Partners
                      </h4>
                    </div>
                    <div class="col-md-8">
                      <div class="common-user-info">
                        <p class="title mb-0">
                          <strong class="common-title">Name:</strong
                          ><span class="common-content"
                            >A Brand New Company</span
                          >
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Title:</strong
                          ><span class="common-content"
                            >2500 Main Street, Los Angeles, CA, 92783</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr class="my-4" />
                  <!-- divider -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <h4 class="right-section-heading">Banking References</h4>
                    </div>
                    <div class="col-md-8">
                      <div class="common-user-info">
                        <p class="title mb-0">
                          <strong class="common-title">Bank:</strong
                          ><span class="common-content"
                            >A Brand New Company</span
                          >
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Account Number:</strong
                          ><span class="common-content"
                            >1234 5678 9012 3456</span
                          >
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Name:</strong
                          ><span class="common-content">John Doe</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Title:</strong
                          ><span class="common-content"
                            >A Brand New Company</span
                          >
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Email:</strong
                          ><span class="common-content">name@domain.com</span>
                        </p>
                        <p class="title mb-2">
                          <strong class="common-title">Phone:</strong
                          ><span class="common-content">858556565</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr class="my-4" />
                  <!-- divider -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <h4 class="right-section-heading">Trade Reference 1</h4>
                    </div>
                    <div class="col-md-8">
                      <div class="common-user-info">
                        <p class="title mb-0">
                          <strong class="common-title">Company:</strong
                          ><span class="common-content">Name</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Contact:</strong
                          ><span class="common-content">Data</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Address:</strong
                          ><span class="common-content"
                            >2500 Main Street, Los Angeles, CA, 92783</span
                          >
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Email:</strong
                          ><span class="common-content">name@domain.com</span>
                        </p>
                        <p class="title mb-2">
                          <strong class="common-title">Phone:</strong
                          ><span class="common-content">858556565</span>
                        </p>
                      </div>
                      <div class="row pt-3">
                        <div class="col-md-6">
                          <span
                            ><img src="../assets/images/pdf-icon.svg" alt="" />
                            signed.pdf</span
                          >
                        </div>
                        <div class="col-md-6 text-end">
                          <a href="#" class="text-link">View</a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr class="my-4" />
                  <!-- divider -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <h4 class="right-section-heading">Trade Reference 2</h4>
                    </div>
                    <div class="col-md-8">
                      <div class="common-user-info">
                        <p class="title mb-0">
                          <strong class="common-title">Company:</strong
                          ><span class="common-content">Name</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Contact:</strong
                          ><span class="common-content">Data</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Address:</strong
                          ><span class="common-content"
                            >2500 Main Street, Los Angeles, CA, 92783</span
                          >
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Email:</strong
                          ><span class="common-content">name@domain.com</span>
                        </p>
                        <p class="title mb-2">
                          <strong class="common-title">Phone:</strong
                          ><span class="common-content">858556565</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr class="my-4" />
                  <!-- divider -->
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <h4 class="right-section-heading">Trade Reference 3</h4>
                    </div>
                    <div class="col-md-8">
                      <div class="common-user-info">
                        <p class="title mb-0">
                          <strong class="common-title">Company:</strong
                          ><span class="common-content">Name</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Contact:</strong
                          ><span class="common-content">Data</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Address:</strong
                          ><span class="common-content"
                            >2500 Main Street, Los Angeles, CA, 92783</span
                          >
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Email:</strong
                          ><span class="common-content">name@domain.com</span>
                        </p>
                        <p class="title mb-2">
                          <strong class="common-title">Phone:</strong
                          ><span class="common-content">858556565</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="button">
                    <button class="common-btn-secondary btn">
                      View Application Form Activity
                    </button>
                  </div>
                </div>

                <div class="common-modal-input-section grey-bg">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <h4 class="right-section-heading">Terms & Conditions</h4>
                    </div>
                    <div class="col-md-8">
                      <div class="common-user-info">
                        <p class="title mb-0">
                          <strong class="common-title">Name:</strong
                          ><span class="common-content">Catherine Decker</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Title:</strong
                          ><span class="common-content"
                            >Head of Accounting</span
                          >
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">signature:</strong
                          ><span class="common-content">Yes</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Date:</strong
                          ><span class="common-content">xx/xx/xxxx</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="button">
                    <button class="common-btn-secondary btn">
                      View T&C Activity
                    </button>
                  </div>
                </div>

                <div class="common-modal-input-section grey-bg">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <h4 class="right-section-heading">
                        Certificate of Insurance
                      </h4>
                    </div>
                    <div class="col-md-8">
                      <div class="common-user-info">
                        <p class="title mb-0">
                          <strong class="common-title">Producer:</strong
                          ><span class="common-content"
                            >XYZ Broker Company</span
                          >
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Insured:</strong
                          ><span class="common-content">John Doe</span>
                        </p>
                        <p class="title mb-0">
                          <strong class="common-title">Address:</strong
                          ><span class="common-content"
                            >2500 Main Street, Los Angeles, CA, 92783</span
                          >
                        </p>
                      </div>
                      <div class="row pt-3">
                        <div class="col-md-6">
                          <span
                            ><img src="../assets/images/pdf-icon.svg" alt="" />
                            signed.pdf</span
                          >
                        </div>
                        <div class="col-md-6 text-end">
                          <a href="#" class="text-link">View</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="button">
                    <button class="common-btn-secondary btn">
                      View COI Activity
                    </button>
                  </div>
                </div>

                <div class="common-modal-input-section grey-bg">
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <h4 class="right-section-heading">D&B Report</h4>
                    </div>
                    <div class="col-md-8">
                      <div class="row pt-3">
                        <div class="col-md-6">
                          <span
                            ><img src="../assets/images/pdf-icon.svg" alt="" />
                            signed.pdf</span
                          >
                        </div>
                        <div class="col-md-6 text-end">
                          <a href="#" class="text-link">View</a>
                        </div>
                      </div>
                      <div class="db-report-section row mt-3">
                        <span class="title col-4"
                          ><strong class="content-bold">Delliquency:</strong
                          ><span class="content-normal">88</span></span
                        >
                        <span class="title col-4"
                          ><strong class="content-bold">Failure Score:</strong
                          ><span class="content-normal">67</span></span
                        >
                        <span class="title col-4 px-0"
                          ><strong class="content-bold">PAYDEX® Score:</strong
                          ><span class="content-normal">72</span></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="button">
                    <button class="common-btn-secondary btn">
                      View D&B Activity
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!---Added modals on click of Select Company-->
<ng-template #company let-modal>
  <div class="modal-body">
    <h2 class="section-main-header">Search</h2>
    <div class="">
      <div class="mt-56 custom-floating-label">
        <label for="input1" class="form-label">Search</label>
        <input
          type="text"
          class="pe-4 form-control"
          placeholder="Search company name or company ID #"
          id="input1"
        />
        <img
          class="search-icon"
          src="../../../../../../assets/images/search-icon.svg"
          alt="Search Icon"
        />
      </div>
    </div>
    <div class="d-flex justify-content-end gap-2">
      <button
        type="button"
        class="btn common-btn-secondary"
        (click)="modal.close('Close click')"
      >
        Cancel
      </button>
      <button type="button" class="btn common-btn-secondary">Select</button>
    </div>
  </div>
</ng-template>
