import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor() { }

  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private creditApplicationLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public creditApplicationLoading$ = this.creditApplicationLoadingSubject.asObservable();

  private overlaySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public overlayState$ = this.overlaySubject.asObservable();

  setLoadingState(loading: boolean) {
    setTimeout(() => {
      this.loadingSubject.next(loading);
    });

  }

  // set the overlayState for control the visibility of a visual overlay means blocks the page's content and displays a loading spinner
  setOverlayState(overlayState: boolean): void{
    this.overlaySubject.next(overlayState);
  }

  // set loading state for credit application in dashboard of studio portal
  setCreditApplicationLoadingState(loading: boolean): void{
    this.creditApplicationLoadingSubject.next(loading);
  }
  
}
