import { Component, inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ADD_EDIT_COMPNAY, ADD_EDIT_COMPNAY_DROPDOWN, COMPANY_TYPES, REGEX_CONSTANTS } from '../../constants/common';
import { CompanyService } from 'src/app/feature/studio-portal/services/company.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { countries } from 'mockData/development/countries';
import { states } from 'mockData/development/states';
import { province } from 'mockData/development/province';
import { COMPANY, VALIDATION_MSG, MESSAGE, COMPANY_ADD_TYPE_ID, FED_TAX_VAL_POPUP, CUSTOM_ORDER } from 'src/app/feature/studio-portal/constants/studio-portal.constant';
import { CompanyRequest } from '../../models/company';
import { ToastService } from '../toast.service';
import { Router } from '@angular/router';
import { CompanyUserServiceService } from '../../services/company-user-service.service';
import { LoaderService } from '../../services/loader/loader.service';
import { FormValidationService } from '../../services/form-validations/form-validation.service';

@Component({
  selector: 'app-add-edit-company',
  templateUrl: './add-edit-company.component.html',
  styleUrls: ['./add-edit-company.component.scss']
})

export class AddEditCompanyComponent {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('existcompany', { static: false }) existcompany!: TemplateRef<any>;
  @ViewChild('dangerActionTpl', { static: false }) dangerActionTpl!: TemplateRef<any>;
  @ViewChild('existcompanyedit', { static: false }) existcompanyedit!: TemplateRef<any>;
  @ViewChild('dangerActionTplAPI', { static: false }) dangerActionTplAPI!: TemplateRef<any>;

  modalReference: NgbModalRef;

  private modalService1 = inject(NgbModal);
  response: any;
  addCompanyRequest: { companyProductionAddress: any; company: any; };
  hideBillingAddress: boolean = false;
  selectedCountry: string;
  submitted: boolean;
  selectedState: string;
  errMsg = VALIDATION_MSG
  companyAddressTypes: any;
  companyTypes: any;
  message = MESSAGE
  companyTypeAddress = COMPANY_ADD_TYPE_ID
  fedTaxValid = FED_TAX_VAL_POPUP
  initialValues: any;
  isFedTaxOverrite: boolean = false;
  fedTaxExist: boolean;
  isModalOpen: any;
  existingCompanies: any[] = [];
  newCompanyName: any;
  duplicateFedId: boolean;
  isStateVisible: any;
  customOrder: string[];
  selectedCountryCompany: string;
  selectedCountryBilling: any;
  statesCompany: any;
  statesBilling: any;
  addCompanyErrorMsgAPI: any;
  editCompanyErrorMsgAPI: any;

  constructor(public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private customerCompanyService: CompanyUserServiceService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private router: Router,
    private loaderService: LoaderService,
  ) { }

  @Input() public title;
  @Input() public portalType;
  @Input() public modalType;
  @Input() public countries;
  @Input() public companyData: any = {};
  @Input() public metaData: any = {};
  companyInfo = ADD_EDIT_COMPNAY;
  dropdownValue = ADD_EDIT_COMPNAY_DROPDOWN;
  companyForm: FormGroup;
  company = COMPANY;
  customOrderCountry = CUSTOM_ORDER;
  states = [];
  province = province;

  ngOnInit(): void {
    this.customOrder = this.customOrderCountry;

    if (this.portalType == 'studio' || this.portalType == 'customer') {

      this.countries = this.metaData?.countryDetails;
      this.sortCountries(this.countries);
      this.companyTypes = this.metaData?.companyTypeDetails;
      this.companyAddressTypes = this.metaData?.companyAddressTypeDetails;
      if (this.modalType == this.company?.modalAdd) {
        this.selectedCountryCompany = '2';
        this.selectedCountryBilling = '2';
        const selectedCountryCompany = this.countries.find(country => country.countryId == this.selectedCountryCompany);
        this.statesCompany = selectedCountryCompany ? selectedCountryCompany.states : [];
        const selectedCountryBilling = this.countries.find(country => country.countryId == this.selectedCountryBilling);
        this.statesBilling = selectedCountryBilling ? selectedCountryBilling.states : [];
      } else {

        if (this.companyData?.companyAddresses?.length != 0) {
          this.selectedCountryCompany = this.companyData?.companyAddresses[0]?.country?.countryId.toString();
          const selectedCountryCompany = this.countries.find(country => country.countryId == this.selectedCountryCompany);
          this.statesCompany = selectedCountryCompany ? selectedCountryCompany.states : [];
          this.selectedCountryBilling = this.companyData?.companyAddresses[1]?.country?.countryId.toString();
          const selectedCountryBilling = this.countries.find(country => country.countryId == this.selectedCountryBilling);
          this.statesBilling = selectedCountryBilling ? selectedCountryBilling.states : [];
        } else {
          this.selectedCountryCompany = '';
          this.selectedCountryBilling = '';

        }
      }

    }
   
    this.initCompanyForm();
    this.initialValues = this.companyForm?.value;
    if (this.companyForm) {
      const countryControl = this.companyForm.get('companyAddress.country');
      const zipCodeControl = this.companyForm.get('companyAddress.zipcode');
      const zipCodeBillingControl = this.companyForm.get('billingAddress.zipcode');

      // Ensure validators are updated based on the current country selection
      if (countryControl) {
        this.zipcodeValidationCompanyAddreess(); // Update validators based on current country
        this.zipcodeValidationBillingAddreess();
        // Optionally force validation
        zipCodeControl?.updateValueAndValidity();
        zipCodeBillingControl?.updateValueAndValidity();
      }
    }
  }

  /** zipcodeValidationCompanyAddreess - mthd to set and clear validations for the company address zipcode */
  zipcodeValidationCompanyAddreess() : void {
    if (this.companyForm) {
      if (this.selectedCountryCompany) {
        //For Company Address
        const zipCodeControl = this.companyForm.get('companyAddress.zipcode');

        // Clear existing validators
        zipCodeControl?.clearValidators();

        // Set validators based on selected country
        if (this.selectedCountryCompany === '2') {
          zipCodeControl?.setValidators([this.usZipCodeValidator()]);
        } else if (this.selectedCountryCompany === '1' || this.selectedCountryCompany === '3') {
          zipCodeControl?.setValidators([this.ukCanadaPostalCodeValidator()]);
        } else {
          zipCodeControl?.setValidators([this.generalPostalCodeValidator()]);
        }

        // Update the control state

        zipCodeControl?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      } else {

        this.companyForm.get('companyAddress.country')?.valueChanges.subscribe(selectedCountryCompany => {
          const zipCodeControl = this.companyForm.get('companyAddress.zipcode');
          // Clear existing validators
          zipCodeControl?.clearValidators();

          // Set validators based on selected country
          if (selectedCountryCompany === '2') {
            zipCodeControl?.setValidators([this.usZipCodeValidator()]);
          } else if (selectedCountryCompany === '1' || selectedCountryCompany === '3') {
            zipCodeControl?.setValidators([this.ukCanadaPostalCodeValidator()]);
          } else {
            zipCodeControl?.setValidators([this.generalPostalCodeValidator()]);
          }

          // Update the control state
          zipCodeControl?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });
      }
    }

  }

  /** zipcodeValidationBillingAddreess - mthd to set and clear validations for the billing address zipcode */
  zipcodeValidationBillingAddreess() : void {
    if (this.companyForm) {
      if (this.selectedCountryBilling) {
        //For Billing Address
        const zipCodeControlBilling = this.companyForm.get('billingAddress.zipcode');

        // Clear existing validators
        zipCodeControlBilling?.clearValidators();

        // Set validators based on selected country
        if (this.selectedCountryBilling === '2') {
          zipCodeControlBilling?.setValidators([this.usZipCodeValidator()]);
        } else if (this.selectedCountryBilling === '1' || this.selectedCountryBilling === '3') {
          zipCodeControlBilling?.setValidators([this.ukCanadaPostalCodeValidator()]);
        } else {
          zipCodeControlBilling?.setValidators([this.generalPostalCodeValidator()]);
        }

        // Update the control state
        zipCodeControlBilling?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      } else {

        this.companyForm.get('billingAddress.country')?.valueChanges.subscribe(selectedCountryBilling => {
          const zipCodeControlBilling = this.companyForm.get('billingAddress.zipcode');

          // Clear existing validators
          zipCodeControlBilling?.clearValidators();

          // Set validators based on selected country
          if (selectedCountryBilling === '2') {
            zipCodeControlBilling?.setValidators([this.usZipCodeValidator()]);
          } else if (selectedCountryBilling === '1' || selectedCountryBilling === '3') {
            zipCodeControlBilling?.setValidators([this.ukCanadaPostalCodeValidator()]);
          } else {
            zipCodeControlBilling?.setValidators([this.generalPostalCodeValidator()]);
          }

          // Update the control state
          zipCodeControlBilling?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });

      }
    }

  }

  /** usZipCodeValidator() - mthd to Validator for US Zip Code (5 numeric characters) */

  usZipCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value) {
        return { required: true };
      }
      const valid = REGEX_CONSTANTS.USZIPCODE.test(value);
      return valid ? null : { invalidZip: true };
    };
  }


  /** ukCanadaPostalCodeValidator() - mthd to Validator for UK and Canada Postal Code (Alphanumeric, max 12 characters) */

  ukCanadaPostalCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value) {
        return { required: true };
      }
      const valid = REGEX_CONSTANTS.UKCANPOSTAL.test(value);
      return valid ? null : { invalidPostal: true };
    };
  }
  /** generalPostalCodeValidator() - mthd to Validator for UK and Canada Postal Code (Alphanumeric, max 12 characters) */
  generalPostalCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value) {
        return { required: true };
      }
      const valid = REGEX_CONSTANTS.GENPOSTAL.test(value);
      return valid ? null : { invalidGeneralPostal: true };
    };
  }

  /** updateZipValidationForSubmit() - mthd to clear zipcode validations on submit*/
  updateZipValidationForSubmit() : void {
    if (this.companyForm) {
      const countryControl = this.companyForm.get('companyAddress.country');
      const zipCodeControl = this.companyForm.get('companyAddress.zipcode');

      const zipCodeBillingControl = this.companyForm.get('billingAddress.zipcode');
      zipCodeControl.clearValidators();
      zipCodeBillingControl.clearValidators();
      // Ensure validators are updated based on the current country selection
      if (countryControl && (zipCodeBillingControl || zipCodeControl)) {
        this.zipcodeValidationCompanyAddreess(); // Update validators based on current country
        this.zipcodeValidationBillingAddreess();
        // Optionally force validation

        zipCodeBillingControl?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        zipCodeBillingControl?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
    }
  }
  /** hasSpecificValueChanged - mthd to check any form values changed */

  hasSpecificValueChanged(controlName: string): boolean {
    const currentValue = this.companyForm.get(controlName)?.value;
    const initialValue = this.initialValues[controlName];
    return currentValue !== initialValue;
  }

  /** isFormModified() - mthd to enable save button if any changes happen in form */
  isFormModified(): boolean {
    return JSON.stringify(this.companyForm.value) !== JSON.stringify(this.initialValues);
  }

  /** sortCountries - mthd to sortCountries sort countries */
  sortCountries(countries: any[]) : void {
    const orderMap = new Map(this.customOrder.map((country, index) => [country, index]));

    countries.sort((a, b) => {
      const indexA = orderMap.get(a.countryName) !== undefined ? orderMap.get(a.countryName) : Infinity;
      const indexB = orderMap.get(b.countryName) !== undefined ? orderMap.get(b.countryName) : Infinity;

      return indexA - indexB || a.countryName.localeCompare(b.countryName);
    });
  }

  /** initCompanyForm - mthd to create initail form for add company */
  initCompanyForm(): void {
    this.companyForm = this.fb.group({
      company: this.fb.group({
        internalUse: ['', Validators.required],
        companyName: ['', [Validators.required]],

      }),
      companyAddress: this.fb.group({
        country: ['', Validators.required],
        street: ['', [Validators.required, Validators.maxLength(85)]],
        city: ['', [Validators.required, Validators.maxLength(50)]],
        state: ['', Validators.required],
        zipcode: ['', Validators.required],
        phone: ['', [Validators.required, Validators.maxLength(20), this.phoneNumberValidator()]],
        billingCheck: []
      }),
      billingAddress: this.fb.group({
        country: ['', Validators.required],
        street: ['', [Validators.required, Validators.maxLength(85)]],
        city: ['', [Validators.required, Validators.maxLength(50)]],
        state: ['', Validators.required],
        zipcode: ['', Validators.required],
        phone: ['', [Validators.required, Validators.maxLength(20), this.phoneNumberValidator()]],
      }),
      federal: this.fb.group({
        federalTaxId: ['', [Validators.required, Validators.maxLength(15), this.federalTaxValidator()]],
      }),
      contacts: this.fb.array([])
    });
    if (this.modalType === this.company?.modalEdit && this.companyData) {

      this.companyForm.get('company')?.patchValue({
        internalUse: this.companyData?.companyType.companyTypeId,
        companyName: this.companyData?.companyName,
      });

      this.companyForm.get('federal.federalTaxId')?.patchValue(this.companyData?.federalTax.federalTaxId);

      if (this.companyData?.companyAddresses.length > 0) {
        if (this.companyData?.companyAddresses.length != 0) {
          const address = this.companyData?.companyAddresses[0];
          this.companyForm.get('companyAddress')?.patchValue({
            country: this.companyData?.companyAddresses[0].country.countryId,
            street: this.companyData?.companyAddresses[0].street,
            city: this.companyData?.companyAddresses[0].city,
            state: (address.country.countryId === 2 || address.country.countryId === 3)
              ? address.state.stateId  // Use stateId for countries 2 and 3
              : address.state.stateName,
            zipcode: this.companyData?.companyAddresses[0].zip,
            phone: this.companyData?.companyAddresses[0].phone

          });
        }
      }
      
      if (this.companyData?.billinAddressSameInd != 1) {

        if (this.companyData?.companyAddresses.length != 0 && this.companyData?.companyAddresses[1] != undefined) {
          const address = this.companyData?.companyAddresses[1];

          this.companyForm.get('billingAddress')?.patchValue({
            country: this.companyData?.companyAddresses[1].country.countryId,
            street: this.companyData?.companyAddresses[1].street,
            city: this.companyData?.companyAddresses[1].city,
            state: (address.country.countryId === 2 || address.country.countryId === 3)
              ? this.companyData?.companyAddresses[1].state.stateId
              : this.companyData?.companyAddresses[1].state.stateName,
            zipcode: this.companyData?.companyAddresses[1].zip,
            phone: this.companyData?.companyAddresses[1].phone,

          });
        }

      } else {
        this.hideBillingAddress = !this.hideBillingAddress;
        this.companyForm.get('companyAddress')?.patchValue({
          billingCheck: this.companyData?.billinAddressSameInd == true ? 1 : 0,
        });
        this.updateBillingAddressValidators(); 
        this.updateZipValidationForSubmit();
      }

      this.companyData?.companyContactInfos.forEach(contact => {
        this.addContact();
        const contactGroup = this.contacts.at(this.contacts?.length - 1) as FormGroup;
        contactGroup.patchValue(contact);
      });
    
    } else {
      this.addContact();
    }
  }

  /** updateStateValidators - mthd to update validations for the state field in company address */

  updateStateValidators() : void {
    const stateControl = this.companyForm.get('companyAddress.state');

    if (this.isStateVisible) {
      stateControl.setValidators(Validators.required);
    } else {
      stateControl.clearValidators();
    }

    stateControl.updateValueAndValidity();
  }

  /** updateStateBillingValidators - mthd to update validations for the state field  in billing address*/

  updateStateBillingValidators() : void  {
    const stateControl = this.companyForm.get('billingAddress.state');

    if (this.isStateVisible) {
      stateControl.setValidators(Validators.required);
    } else {
      stateControl.clearValidators();
    }

    stateControl.updateValueAndValidity();
  }

  /** toggleStateVisibility - mthd to show hide state field*/

  toggleStateVisibility(isVisible: boolean) : void {
    this.isStateVisible = isVisible;
    this.updateStateValidators();
  }
  
  /** contacts - mthd to create contacts formArray */
  get contacts() : any {
    return this.companyForm.get('contacts') as FormArray;
  }

  /** addContact - mthd to create contacts group for dynamic contact */
  addContact(): void {
    const contactGroup = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(50), this.nameValidator()]],
      lastName: ['', [Validators.required, Validators.maxLength(50), this.nameValidator()]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(80)]],
      jobTitle: ['', [Validators.required, Validators.maxLength(50), this.jobTitleValidator]],
      phone: ['', [Validators.required, Validators.maxLength(20), this.phoneNumberValidator()]],
    })
    this.contacts.push(contactGroup);
  }

  /** removeContact - mthd to remove contacts group for dynamic contact */
  removeContact(index: number) :any {
    if (this.contacts?.length > 1) {
      this.contacts?.removeAt(index);
    }
  }

  /** phoneNumberValidator - mthd to add validations for phoneNumber */
  phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control?.value;
      const valid = REGEX_CONSTANTS.PHONE_NUMBER.test(value);
      return valid ? null : { 'invalidPhoneNumber': true };
    };
  }

  /** federalTaxValidator - mthd to add validations for federal */
  federalTaxValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control?.value;
      const valid = REGEX_CONSTANTS.FEDERAL_TAX_ID.test(value);
      return valid ? null : { 'invalidFederalTax': { value } };
    };
  }

  /** alphabeticValidator - mthd to add validations for alphabetic */
  alphabeticValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = REGEX_CONSTANTS.ALPHABETIC.test(control?.value);
      return valid ? null : { 'invalidAlphabetic': true };
    };
  }
  /** alphabeticValidator - mthd to add validations for alphabetic */
  nameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control?.value;
      const valid = REGEX_CONSTANTS.NAMES.test(value); // Only allows alphabets without spaces

      if (!value || value.trim() === '') {
        return { required: true };
      }

      return valid ? null : { 'invalidName': true };
    };
  }

  /** jobTitleValidator - mthd to add validations for jobTitle */
  jobTitleValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = REGEX_CONSTANTS.JOB_TITLE.test(control?.value);
      return valid ? null : { 'invalidJobTitle': true };
    };
  }

  /** alphanumericValidator - mthd to add validations for alphanumeric */
  alphanumericValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = REGEX_CONSTANTS.ALPHANUMERIC.test(control?.value);
      return valid ? null : { 'alphanumeric': true };
    };
  }

  /** numericValidator - mthd to add validations for numericValidator */
  numericValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = REGEX_CONSTANTS.NUMERIC.test(control?.value);
      return valid ? null : { 'numeric': true };
    };
  }

  /**
*closeModal() - action to close the active modal
*/
  closeModal() {
    this.activeModal.close();
  }

  /**
*openEditProjectDetails() - openEditProjectDetails used to invoke edit project component
*/

  openEditProjectDetails(content: TemplateRef<any>) {
    this.modalService1.open(content, { windowClass: 'common-modal-center', centered: true });
  }

  /**
*createRequestObject() - createRequestObject used for create request obj for the add company data
*/
  createRequestObject(): any {

    let userProfile = JSON.parse(localStorage.getItem('user-profile'));
    const formData = this.companyForm?.value;
    let stateCompanyId = parseInt(formData.companyAddress.state);
    let stateBillingId = parseInt(formData.billingAddress.state);
    let city = formData.companyAddress.city;
    let zip = formData.companyAddress.zipcode;
    let addressCompanyId;
    if (this.companyData.companyAddresses != undefined) {
      addressCompanyId = this.companyData.companyAddresses[0]?.companyAddressId
    } else {
      addressCompanyId = '';

    }
    const requestObject = {
      company: {
        companyName: formData.company.companyName,
        companyTypeId: parseInt(formData.company.internalUse)
      },
      companyProductionAddress: {
        countryId: parseInt(formData.companyAddress.country),
        street: formData.companyAddress.street,
        phone: formData.companyAddress.phone,
        companyAddressId: addressCompanyId,
        companyAddressTypeId: this.companyTypeAddress.productioncompanyAddressTypeId,

      },

      companyContactInfos: formData.contacts.map(contact => ({
        email: contact.email,
        firstName: contact.firstName,
        jobTitle: contact.jobTitle,
        lastName: contact.lastName,
        phone: contact.phone,
      })),
      billingAddressSameInd: formData.companyAddress.billingCheck == true ? 1 : 0
    };
    // customer portal company details edit
    const requestObjectCustomer = {
      billingAddressSameInd: formData.companyAddress.billingCheck == true ? 1 : 0,
      loginUserId: userProfile.userProfileId,
      companyProductionAddress: {
        companyAddressId: this.companyData.companyAddresses ? this.companyData.companyAddresses[0]?.companyAddressId : '',
        companyAddressTypeId: this.companyTypeAddress.productioncompanyAddressTypeId,
        street: formData.companyAddress.street,
        city: formData.companyAddress.city,
        zip: formData.companyAddress.zipcode,
        countryId: parseInt(formData.companyAddress.country),
        phone: formData.companyAddress.phone,
      },
      companyContactInfos: formData.contacts.map(contact => ({
        email: contact.email,
        firstName: contact.firstName,
        jobTitle: contact.jobTitle,
        lastName: contact.lastName,
        phone: contact.phone,
      })),
    };
    if (!this.hideBillingAddress) {
      let billingstateId = parseInt(formData.billingAddress.state);
      let billingcity = formData.billingAddress.city;
      let billingzip = formData.billingAddress.zipcode;
      requestObjectCustomer['companyBillingAddress'] = {
        countryId: parseInt(formData.billingAddress.country),
        street: formData.billingAddress.street,
        city: formData.billingAddress.city,
        //stateId: parseInt(formData.billingAddress.state),
        zip: formData.billingAddress.zipcode,
        phone: formData.billingAddress.phone,
        companyAddressTypeId: this.companyTypeAddress.billingcompanyAddressTypeId,
        companyAddressId: this.companyData.companyAddresses ? this.companyData.companyAddresses[1]?.companyAddressId : '',
      }
      if (stateBillingId !== null && !Number.isNaN(stateBillingId)) {
        requestObjectCustomer['companyBillingAddress']['stateId'] = billingstateId;
      } else {
        requestObjectCustomer['companyBillingAddress']['stateId'] = 1; //remove after api integration
      }
      if (city !== null) {
        requestObjectCustomer['companyBillingAddress']['city'] = billingcity;
      }
      if (zip !== null) {
        requestObjectCustomer['companyBillingAddress']['zip'] = billingzip;
      }
    } else {
      requestObjectCustomer['billingAddressSameInd'] = 1;
    }

    if (stateCompanyId !== null && !Number.isNaN(stateCompanyId)) {
      requestObjectCustomer['companyProductionAddress']['stateId'] = stateCompanyId;
    } else {
      requestObjectCustomer['companyProductionAddress']['stateId'] = 1; //remove after api integration
    }
    if (city !== null) {
      requestObjectCustomer['companyProductionAddress']['city'] = city;
    }
    if (zip !== null) {
      requestObjectCustomer['companyProductionAddress']['zip'] = zip;
    }
    if (this.modalType == this.company.modalType) {
      if (this.hasSpecificValueChanged('companyAddress') || this.hasSpecificValueChanged('billingAddress')) {
        requestObjectCustomer['isCompanyAddressModified'] = "true";
      }
      if (this.hasSpecificValueChanged('contacts')) {
        requestObjectCustomer['isCompanyContactInfoModified'] = "true";
      }
      requestObjectCustomer['companyId'] = this.companyData.companyId;
    }

    if (stateCompanyId !== null && !Number.isNaN(stateCompanyId)) {
      requestObject['companyProductionAddress']['stateId'] = stateCompanyId;
    } else {
      requestObject['companyProductionAddress']['stateId'] = 1; //remove after api integration
    }
    if (city !== null) {
      requestObject['companyProductionAddress']['city'] = city;
    }
    if (zip !== null) {
      requestObject['companyProductionAddress']['zip'] = zip;
    }
    if (this.modalType == this.company.modalType) {
      if (this.hasSpecificValueChanged('companyAddress') || this.hasSpecificValueChanged('billingAddress')) {
        requestObject['isCompanyAddressModified'] = "true";
      }
      if (this.hasSpecificValueChanged('contacts')) {
        requestObject['isCompanyContactInfoModified'] = "true";
      }
      requestObject['companyId'] = this.companyData.companyId;

      if (this.modalType === this.company?.modalEdit && !this.duplicateFedId) {
        requestObject['federalTax'] = {};
        requestObject['federalTax']['federalTaxId'] = formData.federal.federalTaxId;
        requestObject['federalTax']['federalId'] = parseInt(this.companyData?.federalTax?.federalId);
      } else {
        requestObject['federalTaxId'] = formData.federal.federalTaxId;
      }
    } else {
      requestObject['federalTaxId'] = formData.federal.federalTaxId;
    }

    requestObject['isfederalTaxIdExisted'] = this.isFedTaxOverrite ? "false" : "true";

    requestObject['loginUserId'] = userProfile.userProfileId;
    if (!this.hideBillingAddress) {
      let billingstateId = parseInt(formData.billingAddress.state);
      let billingcity = formData.billingAddress.city;
      let billingzip = formData.billingAddress.zipcode;
      let addressBillingId;
      if (this.companyData.companyAddresses != undefined) {
        addressBillingId = this.companyData.companyAddresses[1]?.companyAddressId
      } else {
        addressBillingId = '';

      }
      requestObject['companyBillingAddress'] = {
        countryId: parseInt(formData.billingAddress.country),
        street: formData.billingAddress.street,
        city: formData.billingAddress.city,
        zip: formData.billingAddress.zipcode,
        phone: formData.billingAddress.phone,
        companyAddressTypeId: this.companyTypeAddress.billingcompanyAddressTypeId,
        companyAddressId: addressBillingId,

      }
      if (stateBillingId !== null && !Number.isNaN(stateBillingId)) {
        requestObject['companyBillingAddress']['stateId'] = billingstateId;
      } else {
        requestObject['companyBillingAddress']['stateId'] = 1; //remove after api integration
      }
      if (city !== null) {
        requestObject['companyBillingAddress']['city'] = billingcity;
      }
      if (zip !== null) {
        requestObject['companyBillingAddress']['zip'] = billingzip;
      }
    } else {
      requestObject['billingAddressSameInd'] = 1;

    }

    if (this.portalType == 'studio') {
      return requestObject;

    } else {
      return requestObjectCustomer;
    }

  }

  /**
*addCompanyData() - addCompanyData used to call add company api
*/
  addCompanyData(): void {
    const requestObject = this.createRequestObject();
    this.updateZipValidationForSubmit();
    this.loaderService.setLoadingState(true);
    this.submitted = true;

    if (this.companyForm.valid) {
      this.companyService.addCompanyDetails(requestObject).subscribe(
        {
          next: (response) => {
            if (response?.success) {
              this.loaderService.setLoadingState(false);
              let message = { status: "success", message: response?.message }
              this.activeModal.close(message);
              this.modalService.dismissAll();
            } else {
              this.loaderService.setLoadingState(false);
              if (response?.message === this.message.duplicateFedId) {
                this.duplicateFedId = true;
                this.newCompanyName = this.companyForm.get('company').value.companyName || '';
                this.existingCompanies = response?.companyFederalTaxDTO?.companyList;
                this.openFedTaxModal();
              }
            }
          },
          error: (error) => {
            this.addCompanyErrorMsgAPI = error;
            this.showDanger(this.dangerActionTplAPI);
            this.loaderService.setLoadingState(false);

          }
        })
    }
  }

  /**
 *editCompanyData() - editCompanyData used to call add company api
 */
  editCompanyData(): void {
    const requestObject = this.createRequestObject();
    this.updateZipValidationForSubmit();
    this.loaderService.setLoadingState(true);

    if (this.modalType == this.company?.modalEdit) {
      if (this.companyForm.valid) {
        this.companyService.updateCompanyDetails(requestObject).subscribe(
          {
            next: (response) => {
              if (response?.success) {
                this.loaderService.setLoadingState(false);
                let message = { status: "success", message: response?.message }
                this.activeModal.close(message);
                this.modalService.dismissAll();
              } else {
                this.loaderService.setLoadingState(false);

                if (response?.message === this.message.duplicateFedId) {
                  this.duplicateFedId = true;
                  this.newCompanyName = this.companyForm.get('company').value.companyName || '';
                  this.existingCompanies = response?.companyFederalTaxDTO?.companyList;
                  this.openFedTaxEditModal();
                }
              }
            },
            error: (error) => {
              this.editCompanyErrorMsgAPI = error;
              this.showDanger(this.dangerActionTplAPI);
              this.loaderService.setLoadingState(false);

            }
          });
      }
    }
  }

  /**
*editCustCompanyData() - editCustCompanyData used to call update company details api
*/
  editCustCompanyData(): void {
    console.log("editCustCompanyData")
    const requestObject = this.createRequestObject();

    if (this.modalType == 'edit') {
      this.customerCompanyService.updateCustCompanyDetail(requestObject).subscribe(
        {
          next: (response: any) => {
            console.log("response")
            let message = { status: "success", message: response.message }
            this.activeModal.close(message);
          },
          error: (error) => {
            this.showDanger(this.dangerActionTpl);

          }
        });
    }
  }

/**
  * openFedTaxEditModal() - Method to open fed tax duplicate id popup
  */
  openFedTaxModal() : void {
    if (!this.isModalOpen) {
      this.modalReference = this.modalService.open(this.existcompany, {
        windowClass: 'common-modal-center',
        centered: true
      });

      this.isModalOpen = true;
      this.modalReference.result.then(
        (result) => {
          this.isModalOpen = false;
          this.activeModal.close();
        },
        (reason) => {
          this.isModalOpen = false;
        }
      );
    }
  }

/**
  * closeFedTaxModal() - Method to close fed tax duplicate id popup
  */
  closeFedTaxModal() : void {
    if (this.modalReference) {
      this.modalReference.close();
    }
  }
  
/**
  * openFedTaxEditModal() - Method to open fed tax duplicate id popup on edit
  */
  openFedTaxEditModal(): void {
    if (!this.isModalOpen) {
      this.modalReference = this.modalService.open(this.existcompanyedit, {
        windowClass: 'common-modal-center',
        centered: true
      });

      this.isModalOpen = true;
      this.modalReference.result.then(
        (result) => {
          this.isModalOpen = false;
          this.activeModal.close();
        },
        (reason) => {
          this.isModalOpen = false;
        }
      );
    }
  }

  /**
  *onBillingCheck() - onBillingCheck used to checkbox selection for billing add
  */
  onBillingCheck(event): void {
    this.hideBillingAddress = event.target.checked;
    this.updateBillingAddressValidators(); // Update validators when checkbox is toggled
  }

/**
  *onCountryCompAdressChange() - onCountryCompAdressChange used to update validation on country change for company address
  */
  onCountryCompAdressChange(event: Event) : void {

    const selectElement = event?.target as HTMLSelectElement;
    this.selectedCountryCompany = selectElement?.value;
    const selectedCountryCompany = this.countries.find(country => country.countryId == this.selectedCountryCompany);
    this.statesCompany = selectedCountryCompany ? selectedCountryCompany.states : [];
    this.companyForm.get('companyAddress.state').setValue('');
   
    //default set billing address country should be company address country
    this.companyForm.get('billingAddress.country').setValue(this.selectedCountryCompany);
    this.onCountryBillingAddressChange(event);
  
    this.updateStateValidators();
    this.updateZipValidationForSubmit();
    this.updateBillingAddressValidators();

  }

  /**
  *onCountryBillingAddressChange() - onCountryBillingAddressChange used to update validation on country change for billing address
  */
  onCountryBillingAddressChange(event: Event) : void {
    const selectElement = event?.target as HTMLSelectElement;
    this.selectedCountryBilling = selectElement?.value;
    const selectedCountryBilling = this.countries.find(country => country.countryId == this.selectedCountryBilling);
    this.statesBilling = selectedCountryBilling ? selectedCountryBilling.states : [];
    this.companyForm.get('billingAddress.state').setValue('');
    this.updateStateBillingValidators();
    this.updateZipValidationForSubmit();

  }

  /**
*updateBillingAddressValidators() - updateBillingAddressValidators used to clear validators on clik billing check
*/
  private updateBillingAddressValidators(): void {
    
    const billingAddressGroup = this.companyForm.get('billingAddress') as FormGroup;
    if (this.hideBillingAddress) {
      Object.keys(billingAddressGroup.controls).forEach(control => {
        billingAddressGroup.get(control)?.setValidators(null);
        billingAddressGroup.get(control)?.updateValueAndValidity({ emitEvent: false });
      });
    }
    billingAddressGroup.updateValueAndValidity();
  }

  

  /**
   * showSuccess() - Displays a success toast notification with custom content and options.
   * @param successTpl - The template reference containing the toast's content.
   */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.clear()
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 5000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: any) {
    this.toastService.clear()
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }
  /**
  * addNew() - Method to call add company Data with existing fed tax id
  */
  addNew(): void {
    if (this.modalType === this.company?.modalEdit) {
      this.isFedTaxOverrite = true;
      this.duplicateFedId = false;
      this.editCompanyData();
    } else {
      this.isFedTaxOverrite = true;
      this.addCompanyData();
    }
  }

  /**
  * goToExist() - Method to navigate existing company
  */
  goToExist(): void {
    let companyId;
    if(this.existingCompanies?.length > 0){
      companyId = this.existingCompanies[0].companyId;
    }
    this.modalService.dismissAll();
    this.router.navigate(['/feature/studio-dashboard/company'], {
      queryParams: { companyid:companyId }
    });    
  }
}
