<div *ngIf="isAuthenticated">
    <app-menu [portalType]="portalType"></app-menu>
</div>
<div class="container-fluid page-wrapper" *ngIf="permissionsData['viewFaq']">
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex justify-content-between align-items-center page-title">
                <h1 class="px-0">{{constants.faq}}</h1>
                <div *ngIf="portalType === 'studio' && permissionsData['editFaq']" class="common-toggle-button" (click)="onToggle()">
                    <input type="checkbox" id="custom-toggle-1" class="common-toggle-input" [(ngModel)]="isEditable">
                    <label for="custom-toggle-1" class="common-toggle-label">
                        {{ isEditable ? constants.edit_mode : constants.view_mode }}
                    </label>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 d-none d-md-block " *ngIf="mode !== constants.edit">
                <ol class="handbook-list" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <li class="nav-link" [ngClass]="{'show active': selectedTab === 'overview-tab'}" id="overview-tab"
                        role="tab" aria-controls="overview-content" aria-selected="true"
                        (click)="selectTab(constants.overview_tab)">
                        {{constants.overview}}
                    </li>
                    <li class="nav-link" id="account-tab" role="tab" aria-controls="account-content"
                        aria-selected="false" [ngClass]="{'show active': selectedTab === 'account-tab'}"
                        (click)="selectTab(constants.account_tab)">
                        {{constants.account}}
                    </li>
                    <li class="nav-link" id="projects-tab" role="tab" aria-controls="projects-content"
                        aria-selected="false" [ngClass]="{'show active': selectedTab === 'projects-tab'}"
                        (click)="selectTab(constants.your_projects_tab)">
                        {{constants.your_projects}}
                    </li>

                    <li class="nav-link" id="requests-tab" role="tab" aria-controls="requests-content"
                        aria-selected="false" [ngClass]="{'show active': selectedTab === 'requests-tab'}"
                        (click)="selectTab(constants.requests_tab)">
                        {{constants.requests}}
                    </li>
                    <li class="nav-link" [ngClass]="{'show active': selectedTab === 'users-tab'}" id="users-tab"
                        role="tab" aria-controls="users-content" aria-selected="false"
                        (click)="selectTab(constants.users_tab)">
                        {{constants.users}}
                    </li>
                    <li class="nav-link" [ngClass]="{'show active': selectedTab === 'contact-us-tab'}"
                        id="contact-us-tab" role="tab" aria-controls="contact-us-content" aria-selected="false"
                        (click)="selectTab(constants.contact_us_tab)">
                        {{constants.contact_us}}
                    </li>
                    <li class="nav-link" [ngClass]="{'show active': selectedTab === 'client-resources-tab'}"
                        id="client-resources-tab" role="tab" aria-controls="client-resources-content"
                        aria-selected="false" (click)="selectTab(constants.client_resource_tab)">
                        {{constants.client_resource}}
                    </li>
                </ol>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-12 d-block d-md-none mobile-select-block">
                <select class="form-select" id="mobileSelect" [(ngModel)]="selectedTab"
                    (change)="onSelectChange($event)">
                    <option value="overview-tab">
                        <span class="list-number">{{number.one}}{{constants.dot}}</span> {{constants.overview}}
                    </option>
                    <option value="account-tab">
                        <span class="list-number">{{number.two}}{{constants.dot}}</span> {{constants.account}}
                    </option>
                    <option value="projects-tab">
                        <span class="list-number">{{number.three}}{{constants.dot}}</span> {{constants.your_projects}}
                    </option>
                    <option value="requests-tab">
                        <span class="list-number">{{number.four}}{{constants.dot}}</span> {{constants.requests}}
                    </option>

                    <option value="users-tab">
                        <span class="list-number">{{number.five}}{{constants.dot}}</span> {{constants.users}}
                    </option>
                    <option value="contact-us-tab">
                        <span class="list-number">{{number.six}}{{constants.dot}}</span> {{constants.contact_us}}
                    </option>
                    <option value="client-resources-tab">
                        <span class="list-number">{{number.seven}}{{constants.dot}}</span> {{constants.client_resource}}
                    </option>
                </select>
            </div>
            <div class="col-md-8 col-lg-8 col-sm-12">
                <!-- Tab Content -->
                <div class="tab-content" id="v-pills-tabContent" *ngIf="mode == constants.view">
                    <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'overview-tab'}"
                        id="overview-tab" role="tabpanel" aria-labelledby="overview-tab">
                        <h1 class="tab-content-title">{{constants.overview}}</h1>
                        <div *ngIf="overview?.faq?.length>0 ">
                            <div class="page-details-section" *ngFor="let faq of overview?.faq">
                                <h2 class="page-sub-heading">{{faq.faqQuestions }}</h2>
                                <p class="para-text-light studios-para" [innerHTML]="convertTextToHtml(faq.faqAnswers)"><br>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'account-tab'}"
                        id="account-tab" role="tabpanel" aria-labelledby="account-tab">
                        <h1 class="tab-content-title">{{constants.account}}</h1>
                        <div *ngIf="account?.faq?.length>0 ">
                            <div class="page-details-section" *ngFor="let faq of account?.faq">
                                <h2 class="page-sub-heading">{{faq.faqQuestions }}</h2>
                                <p class="para-text-light studios-para" [innerHTML]="convertTextToHtml(faq.faqAnswers)"><br>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'projects-tab'}"
                        id="projects-tab" role="tabpanel" aria-labelledby="projects-tab">
                        <h1 class="tab-content-title">{{constants.your_projects}}</h1>
                        <div *ngIf="projects?.faq?.length>0 ">
                            <div class="page-details-section" *ngFor="let faq of projects?.faq">
                                <h2 class="page-sub-heading">{{faq.faqQuestions }}</h2>
                                <p class="para-text-light studios-para" [innerHTML]="convertTextToHtml(faq.faqAnswers)"><br>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'requests-tab'}"
                        id="requests-tab" role="tabpanel" aria-labelledby="requests-tab">
                        <h1 class="tab-content-title">{{constants.requests}}</h1>
                        <div *ngIf="requests?.faq?.length>0 ">
                            <div class="page-details-section" *ngFor="let faq of requests?.faq">
                                <h2 class="page-sub-heading">{{faq.faqQuestions }}</h2>
                                <p class="para-text-light studios-para" [innerHTML]="convertTextToHtml(faq.faqAnswers)"><br>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'users-tab'}" id="users-tab"
                        role="tabpanel" aria-labelledby="users-tab">
                        <h1 class="tab-content-title">{{constants.users}}</h1>
                        <div *ngIf="users?.faq?.length>0 ">
                            <div class="page-details-section" *ngFor="let faq of users?.faq">
                                <h2 class="page-sub-heading">{{faq.faqQuestions }}</h2>
                                <p class="para-text-light studios-para" [innerHTML]="convertTextToHtml(faq.faqAnswers)"><br>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'contact-us-tab'}"
                        id="contact-us-tab" role="tabpanel" aria-labelledby="contact-us-tab">
                        <h1 class="tab-content-title">{{constants.contact_us}}</h1>
                        <div *ngIf="contact_us?.faq?.length>0 ">
                            <div class="page-details-section" *ngFor="let faq of contact_us?.faq">
                                <h2 class="page-sub-heading">{{faq.faqQuestions }}</h2>
                                <p class="para-text-light studios-para" [innerHTML]="convertTextToHtml(faq.faqAnswers)"><br>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'client-resources-tab'}"
                        id="client-resources-tab" role="tabpanel" aria-labelledby="client-resources-tab">
                        <h1 class="tab-content-title">{{constants.client_resource}}</h1>
                        <div *ngIf="client_resource?.faq?.length>0 ">
                            <div class="page-details-section" *ngFor="let faq of client_resource?.faq">
                                <h2 class="page-sub-heading">{{faq.faqQuestions }}</h2>
                                <p class="para-text-light studios-para" [innerHTML]="convertTextToHtml(faq.faqAnswers)"><br>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="mode == constants.edit">
    <app-edit-faq [mode]="mode" [selectedTab]="selectedTab" (modeChange)="onModeChange($event)" (toastMessage)="showToast($event)" (unsavedChangesValue)="hasUnsavedChangesValue($event)"></app-edit-faq>
</div>

<ng-template #navigateAwayModal let-modal>
    <div class="modal-body mt-3">
      <p>You have unsaved changes. Navigating away will discard these changes. Are you sure you want to continue?</p>
      <div class="d-flex justify-content-end gap-2">
        <button type="button" class="common-btn-secondary btn" (click)="modal.close(true)">Yes</button>
        <button type="button" class="common-btn-light btn" (click)="modal.close(false)">No</button>
      </div>
    </div>
  </ng-template>

<ng-template #successTpl>
    <div class="success">{{ this.successMessage }}</div>
  </ng-template>
  
  <ng-template #dangerTpl>
    <div class="danger">{{ this.errorMessage }}</div>
  </ng-template>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>