export const overviewFaqData =[
    {
        subHeading:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        paragraph:'Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    },
    {
        subHeading:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        paragraph:'Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    }
];
export const accountFaqData =[
    {
        subHeading:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        paragraph:'Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    },
    {
        subHeading:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        paragraph:'Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    }
];
export const yourProjectsFaqData =[
    {
        subHeading:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        paragraph:'Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    },
    {
        subHeading:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        paragraph:'Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    }
];
export const requestsFaqData =[
    {
        subHeading:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        paragraph:'Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    },
    {
        subHeading:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        paragraph:'Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    }
];
export const usersFaqData =[
    {
        subHeading:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        paragraph:'Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    },
    {
        subHeading:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        paragraph:'Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    }
];
export const contactUsFaqData =[
    {
        subHeading:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        paragraph:'Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    },
    {
        subHeading:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        paragraph:'Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    }
];
export const clientResourceFaqData =[
    {
        subHeading:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        paragraph:'Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    },
    {
        subHeading:'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        paragraph:'Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    }
]