<div class="container-fluid page-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="page-title px-0">{{ profileInfo?.firstName + ' ' + profileInfo?.lastName }}</h1>
      </div>
      <div class="col-md-3 col-lg-3 d-none d-md-block">
        <ol class="handbook-list" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <li class="nav-link" id="profile-tab" role="tab" aria-controls="profile-content" aria-selected="true"
            (click)="selectTab('profile-tab')" [ngClass]="{'active-tab': selectedTab === user.profileTab}"
            (click)="selectTab('profile-tab')">
            <span class="list-number"></span> {{user.profile}}
          </li>
          <li class="nav-link" id="settings-tab" role="tab" aria-controls="settings-content" aria-selected="false"
            (click)="selectTab('settings-tab')" [ngClass]="{'active-tab': selectedTab === user.settingsTab}"
            (click)="selectTab('settings-tab')">
            <span class="list-number"></span> {{user.settings}}
          </li>
          <li class="nav-link" id="email-notifications-tab" role="tab" aria-controls="email-notifications-content"
            aria-selected="false" (click)="selectTab('email-notifications-tab')"
            [ngClass]="{'active-tab': selectedTab === user.emailNotificationstab}" aria-selected="false"
            (click)="selectTab('email-notifications-tab')">
            <span class="list-number"></span> {{user.emailNotifications}}
          </li>
        </ol>
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 d-block d-md-none mobile-select-block">
        <select class="form-select" id="mobileSelect" [(ngModel)]="selectedTab" (change)="onSelectChange($event)">
          <option [value]=user.profileTab>{{user.profile}}</option>
          <option [value]=user.settingsTab>{{user.settings}}</option>
          <option [value]=user.emailNotificationstab> {{user.emailNotifications}}</option>
        </select>
      </div>

      <div class="col-md-9 col-lg-9 col-sm-12">
        <div class="tab-content" id="v-pills-tabContent">
          <app-profile [profileInfo]="profileInfo" *ngIf='selectedTab === user.profileTab'
            (profileData)="save($event, 'profile-tab')"></app-profile>

          <app-settings *ngIf="selectedTab === user.settingsTab" [profileInfo]="profileInfo"
            [profileDetails]="profileDetails" (settingsData)="save($event, 'settings-tab')">
          </app-settings>

          <app-email-notifications [profileInfo]="profileInfo" [profileDetails]="profileDetails"
            *ngIf='selectedTab === user.emailNotificationstab'
            (emailData)="save($event, 'email-notifications-tab')"></app-email-notifications>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #successTpl>
  <div class="success">{{response.message}}</div>
</ng-template>

<ng-template #dangerTpl>
  <div class="danger">{{error}}</div>
</ng-template>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>