import { Component, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-approval-letter',
  templateUrl: './approval-letter.component.html',
  styleUrls: ['./approval-letter.component.scss']
})
export class ApprovalLetterComponent {
  constructor(private modalService:NgbModal){}

  openDeleteConfirmationModal(content: TemplateRef<any>): void {
    this.modalService.open(content, { size: 'lg' , centered: true });
  }
}
