import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  UserPreferences,
  UserProfileSearch,
} from 'src/app/shared/models/profileModel';
import * as _ from 'lodash';
import { HeaderService } from 'src/app/core/services/header/header.service';
import { SETTINGS, SETTINGS_DATA } from 'src/app/shared/constants/common';
import { INDEX } from 'src/app/shared/enum/shared.enum';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit{
  @Input() profileDetails: UserPreferences;
  @Input() profileInfo: UserProfileSearch;
  @Output() settingsData = new EventEmitter<any>();

  settingsForm: FormGroup;
  isFormChanged: boolean = false;
  settings = SETTINGS;
  metadata: any;
  timezones1: any[] = [];
  setting = SETTINGS_DATA;
  index = INDEX;
  initialFormValues: any;
  disableSaveButton: boolean = true;

  constructor(private fb: FormBuilder, private headerService: HeaderService) {
    this.settingsForm = this.fb.group({
      timezone: [this.settings.timeZoneDefault, Validators.required],
      dateFormat: [this.settings.dateFormat1, Validators.required],
      calendarFormat: [
        this.settings.calendarFormatDefault,
        Validators.required,
      ],
      timeFormat: [this.settings.timeFormat1, Validators.required],
      temperatureUnit: [this.settings.fahrenheitDefault, Validators.required],
    });
  }

  ngOnInit(): void {
    this.settingsForm.valueChanges.subscribe(() => {
      this.checkFormChanges();
    });
    this.getUserProfileMetaData();
    this.initialFormValues = this.settingsForm?.value;
  }


  /**
   *getUserProfileMetaData() -  Retrieves user profile metadata from the header service
   */
  getUserProfileMetaData(): void {
    this.headerService.getUserProfileMetaData().subscribe({
      next: (reponse: any) => {
        if (reponse) {
          this.metadata = reponse.userProfileMetaData;
          this.mapTimezoneSettings();
          this.mapMetadataToSettings();
        } else {
          console.warn('meta data could not be retrieved');
        }
      },
      error: (error) => {
        console.error('Error fetching user profile:', error);
      },
    });
  }

  /**
   * mapMetadataToSettings() - Maps the metadata to the settings object
   */
  mapMetadataToSettings(): void {
    if (this.metadata) {
      this.settings.startSunday =
        this.metadata.calenderFormatSettings[0].calenderFormat;
      this.settings.startMonday =
        this.metadata.calenderFormatSettings[1].calenderFormat;
      this.settings.dateFormat1 =
        this.metadata.dateFormatSettings[0].dateFormat;
      this.settings.dateFormat2 =
        this.metadata.dateFormatSettings[1].dateFormat;
      this.settings.fahrenheit = this.metadata.tempUnitSettings[0].tempUnit;
      this.settings.celsius = this.metadata.tempUnitSettings[1].tempUnit;
      this.settings.timeFormat1 =
        this.metadata.timeFormatSettings[0].timeFormat;
      this.settings.timeFormat2 =
        this.metadata.timeFormatSettings[1].timeFormat;
    }
  }

  /**
   * mapTimezoneSettings() - Maps the timezone settings from the metadata
   */
  mapTimezoneSettings(): void {
    this.timezones1 = _.map(this.metadata.timezoneSettings, (tz) => ({
      value: tz.value,
      label: tz.label,
      timezoneSettingId: tz.timezoneSettingId,
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['profileDetails']) {
      this.settingsForm.patchValue({
        timezone: this.profileDetails.timeZoneSetting.value,
        dateFormat: this.profileDetails.dateFormatSettings.dateFormat,
        calendarFormat:
          this.profileDetails.calenderFormatSettings.calenderFormat,
        timeFormat: this.profileDetails.timeFormatSettings.timeFormat,
        temperatureUnit: this.profileDetails.tempUnitSettings.tempUnit,
      });
    }
    this.initialFormValues = this.settingsForm?.value; // Update initial values on changes
    this.checkFormChanges();
  }

  isInitialState(): boolean {
    return JSON.stringify(this.settingsForm.value) === JSON.stringify(this.initialFormValues);
  }

  /**
   * checkFormChanges() - checks if form values has any changes
   */
  checkFormChanges(): void {
    this.isFormChanged = this.settingsForm.dirty;
    this.disableSaveButton = this.isInitialState(); // Update button state
    console.log('isFormChanged:', this.isFormChanged); // Debugging
    console.log('disableSaveButton:', this.disableSaveButton);
  }

  /**
   * onSaveSettings() - to perform actions after submitting the form with error handling
   */
  onSaveSettings(): void {
    if (this.settingsForm.valid && this.isFormChanged) {
      const selectedTimezoneValue = this.settingsForm.get('timezone')?.value;
      const selectedTimezone = _.find(this.timezones1, {
        value: selectedTimezoneValue,
      });
      const data = {
        userProfileId: this.profileInfo.userProfileId,
        firstName: this.profileInfo?.firstName,
        lastName: this.profileInfo?.lastName,
        email: this.profileInfo?.emailAddress,
        contactNo: this.profileInfo?.contactNo,
        jobTitle: this.profileInfo?.jobTitle,
        userPreferences: {
          emailCreditAppln: this.profileDetails.emailCreditAppln,
          emailCompany: this.profileDetails.emailCompany,
          tempUnitSettings: {
            tempUnitId:
              this.settingsForm.get('temperatureUnit')?.value ===
              this.setting.fahrenheit
                ? this.index.one
                : this.index.two,
          },
          timeFormatSettings: {
            timeFormatId:
              this.settingsForm.get('timeFormat')?.value === this.setting.time
                ? this.index.one
                : this.index.two,
          },
          timeZoneSetting: {
            timezoneSettingId: selectedTimezone.timezoneSettingId,
          },
          dateFormatSettings: {
            dateFormatId:
              this.settingsForm.get('dateFormat')?.value ===
              this.setting.dateFormat
                ? this.index.one
                : this.index.two,
          },
          calenderFormatSettings: {
            calenderFormatId:
              this.settingsForm.get('calendarFormat')?.value ===
              this.setting.calendarFormat
                ? this.index.one
                : this.index.two,
          },
        },
      };
      this.settingsData.emit(data);
       this.settingsForm.markAsPristine();
       this.initialFormValues = this.settingsForm?.value
       this.disableSaveButton = true;
    }
  }

  /**
   * hasError() - Checks if the specified form control has a specific error type and if the control has been touched or is dirty.
   * @param controlName - The name of the form control to check for errors.
   * @param errorType - The type of error to check for on the specified form control.
   * @returns - A boolean indicating whether the form control has the specified error type and has been touched or is dirty.
   */
  hasError(controlName: string, errorType: string): boolean {
    const control = this.settingsForm.get(controlName);
    return control?.hasError(errorType) && (control?.touched || control?.dirty);
  }
}
