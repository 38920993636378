import { Component, TemplateRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { projectUsers } from 'mockData/development/project-users-list';
import { IDropdownSettings } from 'src/app/shared/models/multiselect.model';
import { INDEX, PAGES } from '../../../enum/studio-portal.enum';
import { AddProjectUserComponent } from 'src/app/shared/components/add-project-user/add-project-user.component';
import { companyJobTitleTypeahead, companyUserIdTypeahead, companyUserTypeahead} from 'mockData/development/view-all-users';
import { PROJECT_DROPDOWN_SETTINGS, STUDIO_PROJECT_USERS_LIST_CONSTANTS, USER, VIEW_ALL_USERS_CONSTANTS, VIEW_ALL_USERS_TYPES } from '../../../constants/studio-portal.constant';
import { PORTAL_TYPE } from 'src/app/shared/constants/common';
import { ProjectsService } from '../../../services/projects.service';
import { IconsComponent } from 'src/app/shared/components/ag-grid-icons/icons/icons.component';
import { IconsService } from 'src/app/shared/services/icons/icons.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { Subject, take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-view-all-users',
  templateUrl: './view-all-users.component.html',
  styleUrls: ['./view-all-users.component.scss']
})
export class ViewAllUsersComponent {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  usersId = companyUserIdTypeahead;
  users = companyUserTypeahead;
  status: any;
  jobTitle = companyJobTitleTypeahead;
  userTypeahead: any = 'typeahead';
  filteredUsersId: any[] = [];
  filteredUsers: any[] = [];
  filteredStatus: any[] = [];
  filteredJobTitle: any[] = [];
  filteredRowData: any[] = [];
  filterTypes = VIEW_ALL_USERS_TYPES;
  viewAllUserConstants = VIEW_ALL_USERS_CONSTANTS;
  userLabel = STUDIO_PROJECT_USERS_LIST_CONSTANTS;
  defaultSettings: IDropdownSettings = PROJECT_DROPDOWN_SETTINGS;
  modalReference: NgbModalRef;
  placeholder: string = this.viewAllUserConstants.placeholder;
  projectUsers = projectUsers;
  isFiltersSelected: boolean = false;
  selectedLastLoginDate: NgbDate = null;
  selectedFromDate: any = null;
  selectedToDate: any = null;
  user = USER;
  isUserDeactivated: boolean = false;
  tranferValue: any;
  projectUserId: any
  userLoggedIn: any;
  selectedProjectId: any;
  projectUserProfileId: any;
  userStatus: any;
  userIdFilter: any;
  userInfoFilter: any;
  jobtitleFilter: any;
  displaySuccessMsg: any;
  displayErrorMsg: any;
  iconValue: any;
  allProjectusers;
  departmentDetails;
  userRoleDetails;
  userDetails;
  companyId;
  companyName;
  defaultSelectedStatus;
  projectName;
  projectId;
  projectCompanyId;
  filterLabel;
  private unsubscribe$ = new Subject<void>();
  isAddUserClickable:boolean = false;
  colsForSmallScreen = ['merged-field', 'icons'];
  colsForMediumScreen = ['merged-field', 'icons'];
  colsForLargeScreen = ['user', 'status', 'jobTitle', 'icons'];
  colsForExtraLargeScreen = ['id', 'user', 'status', 'date', 'jobTitle', 'access', 'sections', 'icons'];
  rowData =[];
  colDefs: ColDef[];
  userInfo = JSON.parse(localStorage.getItem('user-profile'));
 
  constructor(private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    public projectsService: ProjectsService,
    private toastService: ToastService,
    private iconService: IconsService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    const permissionsData = {...this.userInfo.roleAssigned };
    permissionsData.addProjectUser == true ? this.isAddUserClickable = true : this.isAddUserClickable = false; 
    this.getProjectDetails();
    this.iconService.currentValue.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.iconValue = value; // Update the local value when it changes
      if (!_.isNil(this.iconValue)) {
        this.onValueChange(value); // Trigger a method
      }
    });
    this.getProjectUserDetails();
    this.getAddProjectUserMetadata();
    this.getColumnData();
  }
  
  /**
    * getColumnData() - Method to retrieve project user columns.
  */

  getColumnData(): void{
  this.colDefs = [
    {
      headerName: 'id #',
      filter: true,
      field: 'id',
      maxWidth: 93,
    },
    {
      headerName: 'User',
      field: 'user',
      filter: 'agTextColumnFilter',
      filterParams: { alwaysShowFilter: true },
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const user = params.data.user;
        const email = params.data.email;
        const type = params.data.accountType;
        let emailClass=null;
        if(email===null || email===undefined){
          emailClass='view-user-email'
        }
        return `<span class="common-split-cell-section view-users-mb">
                    <span class="common-split-cell-data-1">${user}</span>
                    <span class="common-split-cell-data-2 ${emailClass}">${email}</span>
                    <span class="common-split-cell-data-3">${type}</span>
                  </span>`;
      },
      minWidth: 160,
    },
    {
      headerName: 'STATUS',
      field: 'status',
      cellRenderer: this.statusCellRenderer.bind(this),
      minWidth: 160,
    },
    {
      headerName: 'Last Login',
      filter: false,
      field: 'date',
      cellRenderer: (params: ICellRendererParams) => {
        const date = params.data.date;
        const time = params.data.time;
        return `<span class="common-split-cell-section">
                    <span class="common-split-cell-data-default-font common-split-cell-data-1">${date}</span>
                    <span class="common-split-cell-data-2">${time}</span>
                  </span>`;
      },
      minWidth: 130,
    },
    {
      field: 'jobTitle',
      filter: false,
      minWidth: 130,
      cellClass: 'common-cell-data-bold',
    },
    {
      headerName: ' Access to DPT.',
      filter: false,
      field: 'access',
      cellClass: 'user-project-acces-cell',
      minWidth: 165,
      valueFormatter: (params) => params.value?.toString(),
    },
    { field: 'sections', filter: false, minWidth: 150 },
    {
      headerName: 'USER',
      field: 'merged-field',
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const user = params.data.user;
        const email = params.data.email;
        const type = params.data.type;
        const status = params.data.status;
        const statusClass = this.getStatusClass(status);
        let requestTypeClass = '';
        if (type === 'system admin') {
          requestTypeClass = 'system-admin';
        } else if (type === 'account payable') {
          requestTypeClass = 'account-payable';
        } else if (type === 'company admin') {
          requestTypeClass = 'company-admin';
        } else if (type === '' || type===undefined) {
          requestTypeClass = 'no-data';
        }
        return `
          <span class="common-split-cell-section common-mobile-section-users">
            <span class="common-split-cell-data-1">${user}</span>
            <span title="${email}" class="common-split-cell-data-2 ellipsis-email mobile-screen-margin">${email}</span>
             <span class="common-studio-user-request-label ${requestTypeClass}">${type}</span>
            <span class="common-split-cell-mobile-data">
              <span class="request-status-tag ${statusClass} mt-common-ag-grid-contant">${status}</span>
            </span>
          </span>
        `;
      },
      minWidth: 155,
      hide: true,
    },
    {
      field: 'icons',
      cellRenderer:IconsComponent,
      cellClass:'project-user-icons',
      cellRendererParams(params: ICellRendererParams) {
        return {
          row: params.data
        }
      },
      headerName: '',
      filter: false,
      minWidth: 114,
    },
  ];
  }

  /**
    * getProjectUserDetails() - Method to retrieve project user details.
  */
  getProjectUserDetails(): void {
    this.loaderService.setLoadingState(true);
    this.projectsService.getProjectUser(this.projectId, this.projectCompanyId).subscribe(
      (data) => {
        this.loaderService.setLoadingState(false);
        let userData = [];
        this.allProjectusers = data?.projectUsers;
        for(let item of this.allProjectusers){
          userData.push({
            id: item.userProfile.userProfileId,
            user: item.userProfile.firstName+' '+item.userProfile.lastName,
            email: item.userProfile.emailAddress ? item.userProfile.emailAddress : '',
            accountType: item.userProfile.companyAdminInd==1 ? this.userLabel.companyAdmin : '',
            status: item.userProfile.statusActiveInd==this.userLabel.statusActive ? this.userLabel.activeStatus : this.userLabel.deactivatedStatus,
            date: item.lastLoginDate ? item.lastLoginDate : '',
            time: item.lastLoginTime ? item.lastLoginTime : '-',
            jobTitle: item.userProfile.jobTitle ? item.userProfile.jobTitle : '-',
            access: item.departmentNames == this.userLabel.allAccess ? this.userLabel.allAccess : item.departmentNames.split(",").length,
            sections: item.roleSections ? item.roleSections : '-',
            companyId: item.userProfile.companyId,
            projectId: item.projectId,
            projectUserProfileId: item.userProfile.userProfileId,
          });
        }
        this.rowData = userData;
        this.initializeFiltersFromQueryParams();
        this.filterDataCreation(userData);
        this.applyFilters();
        this.loaderService.setLoadingState(false);
      },
      (error) => {
        this.loaderService.setLoadingState(false);
        this.displayErrorMsg = error;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      });
  }

  /**
    * getProjectDetails() - Method to retrieve project details from query parameters.
  */
  getProjectDetails(): void {
    this.route.queryParams?.subscribe(params => {
      this.projectName = params['projectName'];
      this.projectId = params['projectId'];
      this.projectCompanyId = params['companyId'];
      this.companyName = params['companyName'];
    });
    this.initializeFiltersFromQueryParams();
  }

  /**
    * navigateToProjectDetails() - Method to navigate to the project details page.
  */
  navigateToProjectDetails(): void {
    const profileId =  this.projectsService.getProfileId();
    this.router.navigate(['feature/studio-dashboard/project/project-details', profileId, this.projectId]);
  }

  /**
    * onDateRangeSelected() - Method triggered when a date range is selected.
    * @param dateRange - The object containing the selected date range, specifically the fromDate.
  */
  onDateRangeSelected(dateRange: { fromDate: NgbDate | null; toDate: NgbDate | null }) {
    if (dateRange.fromDate && !dateRange.toDate) {
      this.selectedFromDate = dateRange.fromDate;
      this.selectedToDate = dateRange.fromDate;  // Treat single date as both 'from' and 'to'
    }
    else if (dateRange.fromDate && dateRange.toDate) {
      this.selectedFromDate = dateRange.fromDate;
      this.selectedToDate = dateRange.toDate;
    }
    this.applyFilters();
  }

  /**
   * formatDateRange() - method to format the date
   * @param fromDate - The start date.
   * @param toDate - The end date.
   * @returns A string representing the formatted date range.
 */
  formatDateRange(fromDate: NgbDate, toDate: NgbDate): string {
    const formatToTwoDigits = (num: number) => num < PAGES.ten ? `${PAGES.zero}${num}` : `${num}`;
    const formattedFromDate = `${formatToTwoDigits(fromDate.day)}/${formatToTwoDigits(fromDate.month)}/${fromDate.year.toString().slice(INDEX.minusTwo)}`;
    const formattedToDate = `${formatToTwoDigits(toDate.day)}/${formatToTwoDigits(toDate.month)}/${toDate.year.toString().slice(INDEX.minusTwo)}`;
    // If both dates are the same, return just the single date
    if (fromDate.equals(toDate)) {
      return `${formattedFromDate}`;
    }
    else {
      return `${formattedFromDate} - ${formattedToDate}`;
    }
  }


  /**
    * clearDateRange() - method to clear the selected date range.
  */
  clearDateRange(): void {
    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.applyFilters();
  }

  /**
    * selectedUser() - Method to update the selected user ID from the dropdown
    * @param selectedItems - The selected users
  */
  selectedUserId(selectedItems: any): void {
    this.filteredUsersId = selectedItems;
    this.applyFilters();
  }

  /**
    * selectedUser() - Method to update the selected users from the dropdown
    * @param selectedItems - The selected users
  */
  selectedUser(selectedItems: any): void {
    this.filteredUsers = selectedItems;
    this.applyFilters();
  }

  /**
    * selectedStatus() - Method to update the selected statuses from the dropdown
    * @param selectedItems - The selected statuses
  */
  selectedStatus(selectedItems: any): void {
    this.filteredStatus = selectedItems;
    this.applyFilters();
  }

  /**
    * selectedJobTitle() - Method to update the selected job titles from the dropdown
    * @param selectedItems - The selected job titles
  */
  selectedJobTitle(selectedItems: any): void {
    this.filteredJobTitle = selectedItems;
    this.applyFilters();
  }

  /**
    * hasSelectedFilters() - Method to determine if any filters are currently selected.
    * @returns A boolean indicating whether any filters are selected.
  */
  hasSelectedFilters(): boolean {
    this.isFiltersSelected = this.filteredUsersId?.length > 0 || 
      this.filteredUsers?.length > 0 ||
      this.filteredStatus?.length > 0 ||
      this.filteredJobTitle?.length > 0 ||
      this.selectedFromDate !== null ||
      this.selectedToDate !== null
    return this.isFiltersSelected;
  }

  /**
    * statusCellRenderer() - Method to render the status field in the table
    * @param params - The cell parameters containing the status value
    * @returns The HTML content with the status and its CSS class
  */
  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.value);
    return `
        <div class="status-container">
          <div class="request-status-tag ${statusClass}">${params.value}</div>
        </div>
      `;
  }

  /**
    * getStatusClass() - Method to return the CSS class for a given status
    * @param status - The status value
    * @returns The CSS class as a string
  */
  getStatusClass(status: string): string {
    switch (status) {
      case this.userLabel.activeStatus:
        return 'user-status-tag active account-user-common-status-cell mt-common-ag-grid';
      case this.userLabel.deactivatedStatus:
        return 'user-status-tag deactivated account-user-common-status-cell mt-common-ag-grid';
      default:
        return '';
    }
  }

  /**
   * clearAllFilters() - Method to clear all the selected filters
   */
  clearAllFilters(): void {
    //Clear Id
    this.filteredUsersId = [];
    this.selectedUserId(this.filteredUsersId);
    this.usersId = this.filteredUsersId;
    // Clear Projects
    this.filteredUsers = [];
    this.selectedUser(this.filteredUsers);
    this.users = this.filteredUsers;
    // Clear Status
    this.filteredStatus = [];
    this.selectedStatus(this.filteredStatus);
    this.status = this.filteredStatus;
    //clear the selected dates
    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.filteredJobTitle = [];
    this.selectedJobTitle(this.filteredJobTitle);
    this.jobTitle = this.filteredJobTitle;
    // Re-apply filters to reflect the cleared state
    this.applyFilters();
  }

  /**
   * removeSelectedItem() - Method to remove a specific item from a selected filter
   * @param filterType - The type of filter the item belongs to (user, status, jobTitle)
   * @param item - The item to be removed
   */
  removeSelectedItem(filterType: string, item: any): void {
    switch (filterType) {
      case this.filterTypes.userId:
        {
          let userIdArray = [];
          this.filteredUsersId = this.filteredDataList(this.filteredUsersId, item);
          userIdArray.push(item);
          this.usersId = userIdArray;
        }
        break;
      case this.filterTypes.user:
        {
          let userArray = [];
          this.filteredUsers = this.filteredDataList(this.filteredUsers, item);
          userArray.push(item);
          this.users = userArray;
        }
        break;
      case this.filterTypes.status:
        {
          let statusArray = [];
          this.filteredStatus = this.filteredDataList(
            this.filteredStatus,
            item
          );
          statusArray.push(item);
          this.status = statusArray;
        }
        break;
      case this.filterTypes.jobTitle:
        {
          let jobTitleArray = [];
          this.filteredJobTitle = this.filteredDataList(
            this.filteredJobTitle,
            item
          );
          jobTitleArray.push(item);
          this.jobTitle = jobTitleArray;
        }
        break;
    }
    this.applyFilters();
  }

  /**
   * filteredDataList() - Method to filter out the selected item from the array
   * @param arr - The array from which the item needs to be removed
   * @param item - The item to be removed
   * @returns The filtered array
   */
  filteredDataList(arr: any[], item: any): any[] {
    return arr.filter(selectedItem => selectedItem.id !== item.id);
  }

  /**
   * onCellClicked() - Method triggered when a cell is clicked in the ag-grid table.
   * Opens the edit user modal upon cell click.
   * @param evt - The event data containing the clicked row details.
  */
  onCellClicked(evt: any): void {
    if (evt.column.colId !== 'icons' && this.isAddUserClickable) {
      const userData = evt.data;
      this.getUserDetailsById(userData);
    }
  }

   /**
   * getUserDetailsById() - Fetches user details by user ID and opens the edit user modal.
  */
   getUserDetailsById(userData: any): void {
    this.loaderService.setLoadingState(true);
    this.projectsService.getProjectUserById(userData.companyId, userData.projectId, userData.id).subscribe(
      (data) => {
        this.loaderService.setLoadingState(false);
        this.userDetails = data;
        this.openEditUserModal(this.userDetails);
      },
      (error) => {
        this.loaderService.setLoadingState(false);
        this.displayErrorMsg = error;
        this.showDanger(this.dangerTpl);
      }
    );
  }

   /**
   * getAddProjectUserMetadata() - Method to fetch the metadata for add project user screen
   */
   getAddProjectUserMetadata(): void {
    this.projectsService.fetchMetaData().subscribe((response: any) => {
      const metaData = response;
      this.departmentDetails = metaData?.getProjectUserMetaDataDTO?.departmentsDetails;
      this.userRoleDetails = metaData?.getProjectUserMetaDataDTO?.userRoleDetails;
    })
  }

  /**
  * openAddUserModal() - method to open the add a user modal
  */
  openAddUserModal(): void {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddProjectUserComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true
    });
    this.modalReference.componentInstance.modalTitle = this.viewAllUserConstants?.addUserTitle;
    this.modalReference.componentInstance.projectName = this.projectName;
    this.modalReference.componentInstance.departmentDetails = this.departmentDetails;
    this.modalReference.componentInstance.userRoleDetails = this.userRoleDetails;
    this.modalReference.componentInstance.portalType = PORTAL_TYPE.studioPortal;
    this.modalReference.componentInstance.projectId = this.projectId;
    this.modalReference.componentInstance.componentType = PORTAL_TYPE.studioTypeAdd;
    this.modalReference.componentInstance.projectCompanyId = this.projectCompanyId;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data.status == this.userLabel.statusSuccess) {
          this.displaySuccessMsg = this.user.addUserSuccessMsg;
          this.showSuccess(this.successTpl);
          this.getProjectUserDetails();
        } else {
          this.displayErrorMsg = data;
          this.showDanger(this.dangerTpl);
        }
      }
      // on close
    }, (reason) => {
      // on dismiss
    });
  }

  /**
   * openEditUserModal() - Method to open the modal for editing a user.
   * The modal is initialized with "Edit User" as the title and is displayed in full-screen.
  */
  openEditUserModal(evt: any): void {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddProjectUserComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true
    });
    this.modalReference.componentInstance.modalTitle = this.viewAllUserConstants?.EditUserTitle;
    this.modalReference.componentInstance.projectName = this.projectName;
    this.modalReference.componentInstance.departmentDetails = this.departmentDetails;
    this.modalReference.componentInstance.userRoleDetails = this.userRoleDetails;
    this.modalReference.componentInstance.portalType = PORTAL_TYPE.studioPortal;
    this.modalReference.componentInstance.userDetails = evt;
    this.modalReference.componentInstance.projectId = this.projectId;
    this.modalReference.componentInstance.componentType = PORTAL_TYPE.studioTypeEdit;
    this.modalReference.componentInstance.projectCompanyId = this.projectCompanyId;
    this.modalReference.componentInstance.projectUserId = this.userDetails?.projectUser?.userProfile?.userProfileId;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data.status == this.userLabel.statusSuccess) {
          this.displaySuccessMsg = this.user.updateUserSuccessMsg;
          this.showSuccess(this.successTpl);
          this.getProjectUserDetails();
        } else {
          this.displayErrorMsg = data;
          this.showDanger(this.dangerTpl);
        }
      }
      // on close
    }, (reason) => {
      // on dismiss
    });
  }

  /**
   * initializeFiltersFromQueryParams() - Method to initialize filters based on query parameters.
   * The method fetches project-related query parameters (labelId, projectId, projectName) 
   * and applies filters based on the retrieved label (All, Active, Deactivated, Admin).
  */
  initializeFiltersFromQueryParams(): void {
    this.route.queryParams.subscribe(params => {
      const LabelId = params['labelId'];
      this.projectId = params['projectId'];
      this.projectCompanyId = params['companyId'];
      this.projectName = params['projectName'];
      this.companyName = params['companyName'];
      const selectedLabel = this.projectUsers?.find(label => label.id === +LabelId);
      this.filterLabel = selectedLabel?.label;
      const accountAdminUsers = _.filter(this.rowData, user => user.accountType == this.userLabel.companyAdmin);
      switch (this.filterLabel) {
        case this.userLabel.all:
          this.filteredUsers = [];
          this.filteredStatus = [];
          this.filteredJobTitle = [];
          this.applyFilters();
          break;
        case this.userLabel.active:
        case this.userLabel.deactivated:
          this.filteredStatus = [{ text: this.filterLabel.toUpperCase() }];
          this.selectedStatus(this.filteredStatus);
          this.applyFilters();
          break;
        case this.userLabel.admin:
          this.filteredUsers = accountAdminUsers.map(user => ({
            firstname: user.user,
            id: user.id
          }));
          this.selectedUser(this.filteredUsers);
          this.applyFilters();
          break;
      }
      this.applyDefaultStatus();
    });
  }

  /**
  * applyDefaultStatus() - Method to set default status
  */
   applyDefaultStatus(): void {
    if (this.filterLabel === this.userLabel.active || this.filterLabel === this.userLabel.deactivated) {
      let defaultStatus = this.filterLabel;
      this.filteredStatus = [{ text: defaultStatus.toUpperCase() }];
      this.defaultSelectedStatus = this.filteredStatus;
      let value: any[] = [];
      this.status?.forEach((item: any) => {
        if (item.text === defaultStatus.toUpperCase()) {
          value.push(item);
        }
      });
      this.defaultSelectedStatus = value;
      this.applyFilters();
    }
  }

  /**
   * applyFilters() - Method to apply filters and update the filteredRowData based on the selected filters.
  */
  applyFilters(): void {
    this.filteredRowData = this.rowData;
    if (this.filteredUsersId?.length > 0) {
      const selectedUsersId = this.filteredUsersId.map(users => users.id);
      this.filteredRowData = this.filteredRowData.filter((row) =>
        selectedUsersId.includes(row.id)
      );
    }
    if (this.filteredUsers?.length > 0) {
      const selectedUsers = this.filteredUsers.map(user => user.firstname);
      this.filteredRowData = this.filteredRowData.filter((row) =>
        selectedUsers.includes(row.user)
      );
    }
    if (this.filteredStatus?.length > 0) {
      const selectedStatus = this.filteredStatus.map(status => status.text);
      this.filteredRowData = this.filteredRowData.filter((row) =>
        selectedStatus.includes(row.status)
      );
    }
    if (this.filteredJobTitle?.length > 0) {
      const selectedJobTitles = this.filteredJobTitle.map(jobTitle => jobTitle.jobtitle);
      this.filteredRowData = this.filteredRowData.filter((row) =>
        selectedJobTitles.includes(row.jobTitle)
      );
    }
    if (this.selectedFromDate && this.selectedToDate) {
      const selectedFromDate = new Date(this.selectedFromDate.year, this.selectedFromDate.month - 1, this.selectedFromDate.day);
      const selectedToDate = new Date(this.selectedToDate.year, this.selectedToDate.month - 1, this.selectedToDate.day);
      // Filter rows based on the last login date
      this.filteredRowData = this.filteredRowData.filter((row) => {
        const lastLoginDate = new Date(row.date);
        return lastLoginDate >= selectedFromDate && lastLoginDate <= selectedToDate;
      });
    }
    this.hasSelectedFilters();
  }

  onToastEvent(event: any): void {
    if(event===this.userLabel.statusSuccess)
    this.toastService.show('ABCDe', {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * onValueChange(val: any) - Method to handle changes in value.
   * This method processes the incoming value, determines the associated icon action,
   * and triggers the appropriate user action based on the parsed value.
   * 
   * @param val - The value representing the action to be taken, expected to be a JSON string.
   */
  onValueChange(val: any): void  {
    this.tranferValue = val;
    const parsedVal = val;
    if(parsedVal != null){
      this.projectUserId = parsedVal.rowData.id;
      this.selectedProjectId = parsedVal.rowData.projectId;
      this.projectUserProfileId = parsedVal.rowData.projectUserProfileId;
      this.userStatus = parsedVal.rowData.status === this.userLabel.activeStatus ? this.userLabel.statusDeactive : this.userLabel.statusActive;
    }
    if(this.userInfo != null){
      this.userLoggedIn = this.userInfo.userProfileId;
    }
    if(parsedVal){
    switch (parsedVal.iconInfo) {
      case this.userLabel.deactivateAccount:
        this.deactivateUser();
        break;
      case this.userLabel.activateAccount:
        this.activateUser();
        break;
      case this.userLabel.removeAccount:
        this.removeUser();
        break;
      default:
        console.log('Unknown action: ' + parsedVal.iconInfo);
        break;
    }
   }
  }

  /**
   * deactivateUser() - Method to deactivate a user.
   * Calls the iconService to deactivate the user and handles the response.
   * Displays success message if deactivation is successful; logs a warning if not.
   */
  deactivateUser(): void {
    this.loaderService.setLoadingState(true);
    this.iconService.deactivateStudioProjectUser(this.projectUserId, this.userStatus, this.userLoggedIn).pipe(take(1)).subscribe({
      next: (response: any) => {
        if (response) {
          this.displaySuccessMsg = this.user.userDectivatedMsg;
          this.showSuccess(this.successTpl);
          this.loaderService.setLoadingState(false);
          this.iconValue = null;
          this.iconService.changeValue(null);
          this.modalService.dismissAll();
          this.getProjectUserDetails();
        }
      },
      error: (error) => {
        this.displayErrorMsg = error;
        this.showDanger(this.dangerTpl);
        this.loaderService.setLoadingState(false);
        this.iconValue = null;
        this.iconService.changeValue(null);
        this.modalService.dismissAll();
      },
    });
  }

  /**
   * activateUser() - Method to activate a user.
   * Calls the iconService to activate the user and handles the response.
   * Displays success message if activation is successful; logs a warning if not.
   */
  activateUser(): void {
    this.loaderService.setLoadingState(true);
    this.iconService.activateStudioProjectUser(this.projectUserId, this.userStatus, this.userLoggedIn).pipe(take(1)).subscribe({
      next: (response: any) => {
        if (response) {
          this.loaderService.setLoadingState(false);
          this.displaySuccessMsg = this.user.userActivatedMsg;
          this.showSuccess(this.successTpl);
          this.iconValue = null;
          this.iconService.changeValue(null);
          this.getProjectUserDetails();
          this.modalService.dismissAll();
        }
      },
      error: (error) => {
        this.loaderService.setLoadingState(false);
        this.displayErrorMsg = error;
        console.error('Error fetching user :', error);
        this.showDanger(this.dangerTpl);
        this.iconValue = null;
        this.iconService.changeValue(null);
        this.modalService.dismissAll();
      },
    });
  }

  /**
   * removeUser() - Method to remove a user.
   * Calls the iconService to remove the user and handles the response.
   */
  removeUser(): void {
    this.loaderService.setLoadingState(true);
    this.iconService.removeStudioProjectUser(this.projectUserProfileId, this.selectedProjectId, this.userLoggedIn).pipe(take(1)).subscribe({
      next: (response: any) => {
        if (response) {
          this.loaderService.setLoadingState(false);
          this.displaySuccessMsg = this.user.removeUserSuccessMsg;
          this.showSuccess(this.successTpl);
          this.iconValue = null;
          this.iconService.changeValue(null);
          this.getProjectUserDetails();
          this.modalService.dismissAll();
        }
      },
      error: (error) => {
        this.loaderService.setLoadingState(false);
        this.displayErrorMsg = error;
        this.iconService.changeValue(null);
        this.showDanger(this.dangerTpl);
        this.iconValue = null;
        this.iconService.changeValue(null);
        this.modalService.dismissAll();
      },
    });
  }

   
/**
 * showSuccess() - Method to display a success toast notification.
 * @param successTpl - The template reference for the success message
 */
showSuccess(successTpl: TemplateRef<any>): void {
  this.toastService.show(successTpl, {
    classname: 'custom-toast',
    delay: 3000,
  });
}


  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: any): void {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

    /**
   *  filterDataCreation() - Method to create the users filters data and passing through the filter variables
   *  @param userRecord - All the available user list
   */
    filterDataCreation(userRecord: any): void {
      let userIdFilter = _.uniqBy(userRecord, 'id').map((user, index) => {
        return {
          id: user.id,
        };
      });
      this.userIdFilter = userIdFilter;
      let userInfoFilter = _.uniqBy(userRecord, 'user').map((user, index) => { 
        return {
          id: user.id,
          firstname: user.user,
          email: user.email
        };
      });
      this.userInfoFilter = userInfoFilter.sort((a, b) => a.firstname.localeCompare(b.firstname));
      let statusFilters = _.uniqBy(userRecord, 'status').map((user, index) => {
        return {
          id: user.id,
          text: user.status
        };
      });
      this.status = statusFilters.sort((a, b) => a.text.localeCompare(b.text));
      let jobtitleFilter = _.uniqBy(userRecord, 'jobTitle').map((user, index) => {
        return {
          id: user.id,
          jobtitle: user.jobTitle
        };
      });
      this.jobtitleFilter = jobtitleFilter.sort((a, b) => a.jobtitle.localeCompare(b.jobtitle));
    }

    ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete(); // Complete the subject to avoid memory leaks
    }
}