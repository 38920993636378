import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-creditform1',
  templateUrl: './customer-creditform1.component.html',
  styleUrls: ['./customer-creditform1.component.scss']
})
export class CustomerCreditform1Component {

}
