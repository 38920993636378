

import { Injectable } from '@angular/core';
import { BaseApiService } from '../../../shared/services/base-api/base-api.service';
import {  STUDIO_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AddWbsoService {
  constructor(public baseApiService: BaseApiService) { }


  /**
 * addWbUser(user) - Sends a POST request to add a new WBSO user.
 * @param user - The user data to be sent in the request body.
 * @returns Observable<any> - An observable of the response.
 */

 addWbUser(user:any) {
    let endpoint = STUDIO_PORTAL_API_PATHS.add_wbso_user;
    return this.baseApiService.post(endpoint,user);
  }

  /**
 * updateWbUser(user) - Sends a PUT request to update an existing WBSO user.
 * @param user - The updated user data to be sent in the request body.
 * @returns Observable<any> - An observable of the response.
 */
  updateWbUser(user:any){
    let endpoint = STUDIO_PORTAL_API_PATHS.edit_wbso_user;
    return this.baseApiService.put(endpoint,user);

  }

  /**
 * searchWbUser() - Performs a GET request to search for WBSO users.
 * @returns Observable<any> - An observable of the user search results.
 */

  searchWbUSer(){
    let endpoint =STUDIO_PORTAL_API_PATHS.search_wbso_user;
    return this.baseApiService.get(endpoint);
  }
}
