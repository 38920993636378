// add-company-user.component.ts
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { COMPANY } from '../../../feature/customer-portal/constants/customer-portal.constant';
import { ADD_COMPANY_USER, SECTION_DATA, SECTION_DATA_VALUE, SAVE_CHANGES_WARNING } from './../../constants/common';
import { AddProjectUserComponent } from '../add-project-user/add-project-user.component';
import { SearchDataService } from '../../services/search.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BUTTON_TEXTS, LABEL_TEXTS } from 'src/app/feature/studio-portal/constants/studio-portal.constant';
import { CompanyService } from 'src/app/feature/studio-portal/services/company.service';
import { AddWbUserService } from '../../services/add-wb-user.service';
import { FormValidationService } from '../../services/form-validations/form-validation.service';
import { DEPARTMENT_DETAILS } from '../../../../../mockData/development/add-project-user-list';
import * as _ from 'lodash';
import { CompanyUserServiceService } from '../../services/company-user-service.service';
import { LoaderService } from '../../services/loader/loader.service';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-add-company-user',
  templateUrl: './add-company-user.component.html',
  styleUrls: ['./add-company-user.component.scss']
})
export class AddCompanyUserComponent implements OnInit {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @ViewChild('confirmationModal', { static: false }) confirmationModal!: TemplateRef<any>;

  @Input() public title: string = 'Add Customer User';
  @Input() public searchResults: any;
  @Input() showSearchBarInput: boolean = false;
  @Input() companyTypeaheadData;


  @Input() companyDataList
  // @Input() companies;
  placeholder: string = "Search company name or company ID #";
  showSearchBar: boolean = false;
  searchComplete: boolean = false;
  @Input() public isEditMode: boolean = false;
  @Input() public userDetails: any = {};
  companyUserData: any
  //@Input() public companyId: any = '';
  @Input() userType;

  // Dynamic content for the component
  @Input() public metaData: any = {};
  @Input() public portalType: string = '';
  @Input() public modalType: any = '';
  @Input() public componentType: any = '';
  activeProjectCount: number;
  closedProjectCount: number;
  activeProjectList: any[] = [];
  closedProjectList: any[] = [];
  activeProjectControls: any = [];
  closedProjectControls = [];
  saveText = BUTTON_TEXTS.save;
  addUserText = BUTTON_TEXTS.addUser;
  company = COMPANY;
  addCompanyUser = ADD_COMPANY_USER;
  saveChanges = SAVE_CHANGES_WARNING;
  modalReference: NgbModalRef;
  companyForm: FormGroup;
  selectDepartment = LABEL_TEXTS.selectDepartments;
  close = BUTTON_TEXTS.close;
  nextAddUser = BUTTON_TEXTS.nextAddUser
  companyId: any;
  userProfileId: any;
  projectdetails: any;
  departmentDetails: any;
  userRoleDetails: any;
  userTypeDetails: any;
  searchBarInput: string = null;
  searchDisabled: boolean = false;
  departmentData = DEPARTMENT_DETAILS;
  departmentControls = [];
  sectionData = SECTION_DATA_VALUE;
  editIcon: boolean = true;
  sectionInfo: any;
  response: any;
  selectedCompanyId: any;
  selectedCompanyName: any;
  permissionsData: any;
  isFormDisabled: boolean = false;
  @Output() emitError = new EventEmitter<any>();
  initialFormValues: any;


  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private searchService: SearchDataService,
    private companyService: CompanyService,
    private customerCompanyService: CompanyUserServiceService,
    private addWbUserService: AddWbUserService,
    private formValidationService: FormValidationService,
    private loaderService: LoaderService,
    private addaCustomerUser: AddWbUserService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.userProfileId = userProfile?.userProfileId;
    this.permissionsData = { ...userProfile.roleAssigned };

    this.initData();
    this.initializeForm();
    this.setupActiveProjectValidation();
    this.sectionInfo = this.sectionData.find(item => item?.portaltype === this.portalType)
    // Set showSearchBar based on searchService or input value
    this.showSearchBar = this.showSearchBarInput;
    // Show the form if in edit mode
    this.searchComplete = this.isEditMode;
    // If in edit mode, populate the form with user details
    if (this.isEditMode) {
      this.searchComplete = true;

      if (this.portalType == 'customer') {
        this.setEditStudioCompanyUserDetails(this.userDetails)
      } else if (this.portalType == 'studio' && this.componentType == 'studio-company' || this.componentType == 'customer') {
        if (this.userDetails != null) {

          this.setEditStudioCompanyUserDetails(this.userDetails)
        }
      }
    }
    this.initialFormValues = { ...this.companyForm.getRawValue() };
  }

  /**
  * isFormChanged() - method to return the true or false for the value changes in form
  */

  isFormChanged(): boolean {
    //return this.companyForm.dirty;
    return JSON.stringify(this.companyForm.getRawValue()) !== JSON.stringify(this.initialFormValues);
  }

  /**
  * initData() - method to initializ the metadata 
  */

  initData(): void {
    this.metaData = !_.isEmpty(this.metaData) ? this.metaData : {};
    this.companyId = this.metaData.companyId;
    this.projectdetails = this.metaData?.projectDetails;
    this.departmentDetails = this.metaData?.departmentDetails;
    this.userRoleDetails = this.metaData?.userRoleDetails;
    this.userTypeDetails = this.metaData?.userTypeDetails;
    this.projectdetails?.forEach(item => {
      item['departmentsIds'] = [];
      item['userRoleIds'] = [];

    });
    this.activeProjectList = _.filter(this.projectdetails, (project) => project.projectStatusId == 2);
    this.closedProjectList = _.filter(this.projectdetails, (project) => project.projectStatusId == 3);
    this.activeProjectCount = this.activeProjectList?.length;
    this.closedProjectCount = this.closedProjectList?.length;
  }

  /**
  * initializeForm() - method to initializ the form with controls for different form fields
  */

  initializeForm(): void {
    const isEditMode = this.modalType === 'edit';
    this.companyForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(50), this.formValidationService.validateAlphabets]],
      lastName: ['', [Validators.required, Validators.maxLength(50), this.formValidationService.validateAlphabets]],
      email: [{ value: '', disabled: isEditMode }, [Validators.required, Validators.maxLength(80), this.formValidationService.validateEmail]],
      jobTitle: ['', [Validators.required, Validators.maxLength(50), this.formValidationService.validateJobTitleAddUser]],
      makeUser: [false],
      accountPayable: [false],
      ...this.initializeActiveProject(),
      ...this.initializeClosedProject(),
    });
    this.companyForm.get('makeUser')?.valueChanges.subscribe(isChecked => {
      if (isChecked) {
        this.editIcon = false;
        this?.projectDeptAll(this.activeProjectList);
        this?.projectDeptAll(this.closedProjectList);
      }
      else {
        this.editIcon = true;
        this?.projectDeptAllUncheck(this.activeProjectList);
        this?.projectDeptAllUncheck(this.closedProjectList);
      }
      this.activeProjectControls.forEach(control => {
        if (isChecked) {
          control.setValue(isChecked);
          control.disable();
        }
        else {
          control.setValue(isChecked);
          control.enable();
        }
      });
      this.closedProjectControls.forEach(control => {
        if (isChecked) {
          control.setValue(isChecked);
          control.disable();
        }
        else {
          control.setValue(isChecked);
          control.enable();
        }
      });
    });
  }

  /**
  * projectDeptAll() - method to select all the project as per the functionality for listed project
  * @param projectList - sending an active and closed projectlsit to set the department value
  */

  projectDeptAll(projectList): void {
    _.forEach(projectList, (project) => {
      _.filter(this.departmentDetails, (dept) => dept.departmentId == 1).forEach(() => {
        project.departmentsIds = [];
        project.userRoleIds = [];
      })
    });
  }

  /**
  * projectDeptAllUncheck() - method to unselect all the project as per the functionality for listed project
  * @param projectList - sending an active and closed projectlsit to set the department value
  */

  projectDeptAllUncheck(projectList): void {
    _.forEach(projectList, (project) => {
      project.departmentsIds = [];
      project.userRoleIds = [];
    });
  }

  /**
 * toggleCheckbox() - method to toggle the checkbox based on project label clicked.
 */

  toggleCheckbox(projectId, state): void {
    if (state == 'active') {
      const control = this.companyForm.get(`activeproject${projectId}`);
      if (control) {
        const isChecked = control.value;
        this.companyForm.get(`activeproject${projectId}`)?.setValue(!isChecked);
      }
    } else {
      const control = this.companyForm.get(`closedproject${projectId}`);
      if (control) {
        const isChecked = control.value;
        this.companyForm.get(`closedproject${projectId}`)?.setValue(!isChecked);
      }
    }

  }

  /**
  * onCheckboxChange() - method to validate the department is slected or not in selected project list
  */

  onCheckboxChange(item, projectId): void {
    item.departmentsIds?.length == 0 ? this.companyForm.get(`activeproject${projectId}`)?.setErrors({ required: true }) : this.companyForm.get(`activeproject${projectId}`)?.setErrors(null);
    this.companyForm.get(`activeproject${projectId}`)?.value ? '' : this.companyForm.get(`activeproject${projectId}`)?.setErrors(null);
    item.departmentsIds?.length == 0 ? this.companyForm.get(`closedproject${projectId}`)?.setErrors({ required: true }) : this.companyForm.get(`closedproject${projectId}`)?.setErrors(null);
    this.companyForm.get(`closedproject${projectId}`)?.value ? '' : this.companyForm.get(`closedproject${projectId}`)?.setErrors(null);
  }

  /**
  * initializeActiveProject() - method to initializ the active project controls for the form.
  * Creates a form control for each department and adds it to the form.
  * @returns an object with form controls for each department.
  */

  initializeActiveProject(): {} {
    const activeProjectControls = {};
    this.activeProjectList?.forEach(project => {
      const control = this.fb.control(false);
      activeProjectControls[`activeproject${project.projectId}`] = control;
      this.activeProjectControls.push(control);

    });
    return activeProjectControls;
  }


  /**
 * initializeClosedProject() - method to initializ the closed project controls for the form.
 * Creates a form control for each department and adds it to the form.
 * @returns an object with form controls for each department.
 */

  initializeClosedProject(): {} {
    const closedProjectControls = {};
    this.closedProjectList?.forEach(project => {
      const control = this.fb.control(false);
      closedProjectControls[`closedproject${project.projectId}`] = control;
      this.closedProjectControls.push(control);
    });
    return closedProjectControls;
  }

  /**
  * setupActiveProjectValidation() - method to set up validation to ensure that at least one active project checkbox is selected.
  * Uses the FormValidationService to apply the custom validation.
  */
  setupActiveProjectValidation(): void {
    this.companyForm.setValidators(() => {
      const isActiveProjectValid = this.formValidationService.validateAtLeastOneSelected(this.activeProjectControls) === null;
      const isClosedProjectValid = this.formValidationService.validateAtLeastOneSelected(this.closedProjectControls) === null;
      return isActiveProjectValid || isClosedProjectValid ? null : { atLeastOneRequired: true };
    });
  }

  /**
   * onSearchCompleted(results) - Handle search completion event.
   * @param results - Array of search results.
   */
  onSearchCompleted(results: any[]): void {
    if (results.length > 0) {
      const selectedResult = results[0]; // This assumes the first result is the desired one
      this.selectedCompanyId = selectedResult.companyId;
      this.selectedCompanyName = selectedResult.companyName;
      this.searchDisabled = true;
    }
  }
  /**
   * proceedToForm() - Sets searchComplete to true if a company is selected,
   */
  proceedToForm(): void {
    if (this.selectedCompanyId) {
      this.searchComplete = true;
      this.getCompanyUserMetaData(this.selectedCompanyId);

    }
  }

  /**
  * resetSelection() - Resets the company selection and search input fields,
  */
  resetSelection(): void {
    this.selectedCompanyId = null;
    this.selectedCompanyName = null;
    this.searchBarInput = null;
    this.searchDisabled = false;

  }
  /**
     * closeModal() - Close the current modal.
     */
  closeModal(): void {
    if (this.isFormChanged()) {
      const modalRef = this.modalService.open(this.confirmationModal, {
        windowClass: 'common-modal-center',
        centered: true,
        backdrop: 'static',
      });
    } else {
      this.activeModal.close();
    }
  }

  /**
  * closeAllModal() - close all the active modal
  */

  closeAllModal() {
    this.modalService.dismissAll();
  }

  /**
   * saveForm() - Save form data and handle necessary actions.
   * This will log the form data to the console if the form is valid.
   */
  saveForm(): void {
    if (!this.isFormDisabled && !this.companyForm.invalid) {
      const userId = this.userDetails.id;
      const formValue = this.companyForm.getRawValue();
      formValue.fullName = `${formValue.firstName} ${formValue.lastName}`.trim();

      if (this.isEditMode) {
        if (this.portalType === 'customer') {
          let payload = this.editUserFormattedData();
          this.editCompanyCustomerUser(payload)

        } else if (this.portalType === 'studio') {
          let studioPayload = this.updateUserStudioFormattedData();
          this.editStudioUser(studioPayload);
        }
      } else {
        if (this.portalType === 'customer') {
          let payload = this.addUserFormattedData();
          this.addCompanyCustomerUser(payload);
        } else if (this.portalType === 'studio' && this.componentType === 'customer') {
          let studioPayload = this.addUCustomerUserFormattedData();
          this.addStudioUser(studioPayload);
        } else if (this.portalType === 'studio' && this.componentType === 'studio-company') {
          let studioPayload = this.addStudioCompanyUserFormattedData();
          this.addStudioUser(studioPayload);
        }
      }
    }
  }

  /**
  * formattedProjectList() - method to format the data for the payload specially for the project lsit
  * @param projectList - Array of active/closed project list.
  */

  formattedProjectList(projectList): any {
    return projectList.filter(item => item.departmentsIds?.length > 0)
      .map(item => ({
        projectId: item.projectId,
        departmentsIds: item.departmentsIds.map(dept => dept.departmentId),
        userRoleIds: item.userRoleIds.map(dept => dept.userRoleId),
        // need to change the status once the API is deployed properly in dev
        projectStatus: "A"
      }))
  }

  /**
  * addUserFormattedData() - method to format the data for the payload
  */

  addUserFormattedData(): any {
    const formValue = this.companyForm.getRawValue();
    let activeProjectData = this.formattedProjectList(this.activeProjectList)
    let closedProjectData = this.formattedProjectList(this.closedProjectList)
    let projectList = activeProjectData.concat(closedProjectData)
    if (!this.editIcon) {
      projectList = [];
    }
    let payload = {
      companyUserProfileRequest: {
        companyId: this.companyId,
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        emailAddress: formValue.email,
        companyAdminInd: formValue.makeUser ? 1 : 0,
        accountPayableUserInd: formValue.accountPayable ? 1 : 0,
        jobTitle: formValue.jobTitle
      },
      usersProjectAssignments: projectList,
      loginUserId: this.userProfileId
    }
    return payload;
  }

  /**
 * editformattedProjectList() - method to format the data for the payload specially for the project lsit
 */

  editformattedProjectList(projectList, selectedId): any {
    const filterIds = selectedId.map(Number);
    const filteredProjects = projectList.filter(project => filterIds.includes(project.projectId));
    return filteredProjects.filter(item => item.departmentsIds?.length > 0)
      .map(item => ({
        projectId: item.projectId,
        departmentsIds: item.departmentsIds.map(dept => dept.departmentId),
        userRoleIds: item.userRoleIds.map(dept => dept.userRoleId),
      }))
  }

  /**
  * editUserFormattedData() - method to format the data for the payload
  */

  editUserFormattedData(): any {
    const formValue = this.companyForm.getRawValue();
    const activeProjectIds = Object.keys(formValue)
      .filter(key => key.startsWith('activeproject') && formValue[key] === true)
      .map(key => key.replace('activeproject', ''));
    const closedProjectIds = Object.keys(formValue)
      .filter(key => key.startsWith('closedproject') && formValue[key] === true)
      .map(key => key.replace('closedproject', ''));
    let activeProjectData = this.editformattedProjectList(this.activeProjectList, activeProjectIds)
    let closedProjectData = this.editformattedProjectList(this.closedProjectList, closedProjectIds)
    let projectList = activeProjectData.concat(closedProjectData)
    if (!this.editIcon) {
      projectList = [];
    }
    let payload = {
      companyUserUpdateProfile: {
        companyId: this.companyId,
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        emailAddress: formValue.email,
        companyAdminInd: formValue.makeUser ? 1 : 0,
        accountPayableUserInd: formValue.accountPayable ? 1 : 0,
        jobTitle: formValue.jobTitle
      },
      usersProjectAssignments: projectList,
      loginUserId: this.userProfileId
    }
    return payload;
  }

  /**
  * addUserStudioFormattedData() - method to format the data for the payload
  */

  addUserStudioFormattedData(): any {
    const formValue = this.companyForm.getRawValue();
    let activeProjectData = this.formattedProjectList(this.activeProjectList)
    let closedProjectData = this.formattedProjectList(this.closedProjectList)
    let projectList = activeProjectData.concat(closedProjectData)

    let payload = {
      customerUserProfileRequest: {
        companyId: this.companyId,
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        emailAddress: formValue.email,
        companyAdminInd: formValue.makeUser ? 1 : 0,
        accountPayableUserInd: formValue.accountPayable ? 1 : 0,
        jobTitle: formValue.jobTitle
      },
      usersProjectAssignments: projectList,
      loginUserId: this.userProfileId
    }
    return payload;
  }

  /**
  * updateUserStudioFormattedData() - method to format the data for the payload
  */

  updateUserStudioFormattedData(): any {
    const formValue = this.companyForm.getRawValue();
    const activeProjectIds = Object.keys(formValue)
      .filter(key => key.startsWith('activeproject') && formValue[key] === true)
      .map(key => key.replace('activeproject', ''));
    const closedProjectIds = Object.keys(formValue)
      .filter(key => key.startsWith('closedproject') && formValue[key] === true)
      .map(key => key.replace('closedproject', ''));
    let activeProjectData = this.editformattedProjectList(this.activeProjectList, activeProjectIds)
    let closedProjectData = this.editformattedProjectList(this.closedProjectList, closedProjectIds)
    let projectList = activeProjectData.concat(closedProjectData)
    if (!this.editIcon) {
      projectList = [];
    }

    let payload = {
      userUpdateProfile: {
        companyId: this.companyId,
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        emailAddress: formValue.email,
        companyAdminInd: formValue.makeUser ? 1 : 0,
        accountPayableUserInd: formValue.accountPayable ? 1 : 0,
        jobTitle: formValue.jobTitle
      },
      usersProjectAssignments: projectList,
      loginUserId: this.userProfileId
    }
    return payload;
  }

  /**
  * addUCustomerUserFormattedData() - method to format the data for the payload
  */

  addUCustomerUserFormattedData(): any {
    const formValue = this.companyForm.getRawValue();
    let activeProjectData = this.formattedProjectList(this.activeProjectList)
    let closedProjectData = this.formattedProjectList(this.closedProjectList)
    let projectList = activeProjectData.concat(closedProjectData)

    let customerPayload = {
      customerUserProfileRequest: {
        companyId: this.companyId,
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        emailAddress: formValue.email,
        companyAdminInd: formValue.makeUser ? 1 : 0,
        accountPayableUserInd: formValue.accountPayable ? 1 : 0,
        jobTitle: formValue.jobTitle
      },
      usersProjectAssignments: projectList,
      loginUserId: this.userProfileId
    };

    return customerPayload;
  }

  /**
  * addStudioUser() - Save form data for adding the studio user and handle necessary actions.
  * @param customerPayload - final user json as per the functionality and passing it to the post API call.
  */

  addStudioUser(customerPayload): any {
    this.loaderService.setLoadingState(true);
    this.addaCustomerUser.addAUser(customerPayload).subscribe({
      next: (response: any) => {
        this.response = response
        let message = { status: "success", message: response?.message }
        this.activeModal.close(message);
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        let errorMessage = { status: "error", message: error }
        this.loaderService.setLoadingState(false);
        this.emitError.emit(errorMessage);

      }
    });
  }

  /**
  * addStudioCompanyUserFormattedData() - method to format the data for the payload for studio company
  */

  addStudioCompanyUserFormattedData(): any {
    const formValue = this.companyForm.getRawValue();
    let activeProjectData = this.formattedProjectList(this.activeProjectList)
    let closedProjectData = this.formattedProjectList(this.closedProjectList)
    let projectList = activeProjectData.concat(closedProjectData)

    let customerPayload = {
      customerUserProfileRequest: {
        companyId: this.companyId,
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        emailAddress: formValue.email,
        companyAdminInd: formValue.makeUser ? 1 : 0,
        accountPayableUserInd: formValue.accountPayable ? 1 : 0,
        jobTitle: formValue.jobTitle
      },
      usersProjectAssignments: projectList,
      loginUserId: this.userProfileId
    };
    return customerPayload;
  }

  /**
  * addCompanyCustomerUser() - Save form data for adding the customer poratal user and handle necessary actions.
  * @param customerPayload - final user json as per the functionality and passing it to the post API call.
  */

  addCompanyCustomerUser(customerPayload): any {
    this.loaderService.setLoadingState(true);
    this.customerCompanyService.addCompanyUser(customerPayload).subscribe({
      next: (response: any) => {
        let message = { status: "success", message: response?.message }
        this.activeModal.close(message);
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        let errorMessage = { status: "error", message: error }
        this.loaderService.setLoadingState(false);
        this.emitError.emit(errorMessage);
      }
    });
  }

  /**
  * editCompanyCustomerUser() - Edit customer portal user based on selected user.
  * @param customerPayload - final user json as per the functionality and passing it to the post API call.
  */

  editCompanyCustomerUser(customerPayload): any {
    this.loaderService.setLoadingState(true);
    this.customerCompanyService.updateCustCompanyUser(customerPayload).subscribe({
      next: (response: any) => {
        let message = { status: "success", message: response?.message }
        this.activeModal.close(message);
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        let errorMessage = { status: "error", message: error }
        this.loaderService.setLoadingState(false);
        this.emitError.emit(errorMessage);
      }
    });
  }

  /**
  * editUser() -  Edit studio portal wbso user based on selected user
  * @param user - final user json as per the functionality and passing it to the post API call.
  * @param userId - need to pass specific user id for the update process.
  */

  editUser(userId: string, user: any): any {
    this.addWbUserService.editUser(userId, user).subscribe({
      next: (response) => {
        this.activeModal.close(response);
      },
      error: (error) => {
        console.error('Error editing user:', error);
      }
    });
  }

  /**
  * editStudioUser() - Edit studio portal customer user based on selected user
  * @param payload - final user json as per the functionality and passing it to the post API call.
  */

  editStudioUser(payload): any {
    this.loaderService.setLoadingState(true);
    this.addaCustomerUser.editStudioUser(payload).subscribe({
      next: (response: any) => {
        let message = { status: "success", message: response.messsage }
        this.activeModal.close(message);
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        let errorMessage = { status: "error", message: error }
        this.loaderService.setLoadingState(false);
        this.emitError.emit(errorMessage);

      }
    });
  }

  /**
  * addProjectPermission() - Open AddProjectUserComponent modal for adding project permissions.
  */

  addProjectPermission(id, name, projectsInfo): any {
    let title = this.company?.projectuser;
    let projectId = id;
    let projectName = name;
    let projectDetails = {
      projectUser: {
        departmentList: projectsInfo.departmentsIds,
        rolesAndSectionsList: projectsInfo.userRoleIds
      }
    };
    this.modalReference = this.modalService.open(AddProjectUserComponent, {
      windowClass: 'common-modal-xl ',
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      fullscreen: true
    });
    this.modalReference.componentInstance.modalTitle = title;
    this.modalReference.componentInstance.portalType = this.portalType;
    this.modalReference.componentInstance.modalType = this.modalType;
    this.modalReference.componentInstance.componentType = this.componentType;
    this.modalReference.componentInstance.searchComplete = this.searchComplete;
    this.modalReference.componentInstance.departmentDetails = this.departmentDetails;
    this.modalReference.componentInstance.userRoleDetails = this.userRoleDetails;
    this.modalReference.componentInstance.projectId = projectId;
    this.modalReference.componentInstance.projectName = projectName;
    this.modalReference.componentInstance.studioType = this.userType;

    if (this.modalType == 'edit' || this.modalType == 'add') {
      this.modalReference.componentInstance.userDetails = projectDetails;
      this.modalReference.componentInstance.modalType = 'edit';
    }

    this.modalReference.result.then((data) => {
      if (data) {
        this.onCheckboxChange(data[0], projectId);
        data.forEach(newItem => {
          const activeprojectItem = this.activeProjectList.find(activeItem => activeItem.projectId === newItem.projectId);
          if (activeprojectItem) {
            Object.assign(activeprojectItem, newItem);
          }
          else {
            const closedProjectItem = this.closedProjectList.find(closedItem => closedItem.projectId === newItem.projectId);
            Object.assign(closedProjectItem, newItem);
          }
        });
      }
    }, (reason) => {
    });
  }

  /**
 * disableForm() - Disables all controls in the company form.
 * This method prevents any user interaction with the form fields,
 * effectively making the form read-only.
 */

  private disableForm(): void {
    this.companyForm.disable();
  }
  /**
   * enableForm() - Enables all controls in the company form.
   * This method allows user interaction with the form fields,
   * making it editable. Logs a message indicating the form is enabled.
   */

  private enableForm(): void {
    this.companyForm.enable();
    this.companyForm.get('email').disable();
  }
  /**
  * setEditStudioCompanyUserDetails() - Open AddProjectUserComponent modal for adding project permissions.
  * @param details - user details need to pass as a input to the form value.
  */

  setEditStudioCompanyUserDetails(details: any): any {
    if (details) {
      // Populate user details in the form
      this.companyForm.patchValue({
        firstName: details.firstName || '',
        lastName: details.lastName || '',
        email: details.emailAddress || '',
        jobTitle: details.jobTitle || '',
        makeUser: details.companyAdminInd === 1,
        accountPayable: details.accountPayableUserInd === 1,
      });

      let isChecked = this.companyForm.get('makeUser')?.value;
      const isActive = details.statusActiveInd === 'Y';
      this.isFormDisabled = !isActive;

      if (!isActive) {
        this.disableForm();
      } else {
        this.enableForm();
      }
      details.customerUserProjectAssignments?.forEach(assignment => {
          const projectId = assignment.project.projectId;
          const departments = assignment.departments || [];
          const roles = assignment.roles || [];

          if (this.portalType == 'studio' && this.modalType == 'edit' && this.componentType == 'customer') {
            if (assignment.project.isProjectSelected) {
              _.forEach(departments, department => {
                if (department.isDepartmentSelected) {
                  _.forEach(this.activeProjectList, project => {
                    if (project.projectId === projectId) {
                      project.departmentsIds.push(department);
                    }
                  });
                  this.closedProjectList.forEach(project => {
                    if (project.projectId === projectId) {
                      project.departmentsIds.push(department);
                    }
                  });
                }
              });
              _.forEach(roles, role => {
                if (role.isUserRoleSelected) {
                  _.forEach(this.activeProjectList, project => {
                    if (project.projectId === projectId) {
                      project.userRoleIds.push(role);
                    }
                  });
                  _.forEach(this.closedProjectList, project => {
                    if (project.projectId === projectId) {
                      project.userRoleIds.push(role);
                    }
                  });
                }
              });
              this.companyForm.get(`activeproject${assignment.project.projectId}`)?.setValue(true);
              this.companyForm.get(`closedproject${assignment.project.projectId}`)?.setValue(true);
              this.activeProjectControls.forEach(control => {
                if (isChecked) {
                  control.disable();
                }
                else {
                  control.enable();
                }
              });
              this.closedProjectControls.forEach(control => {
                if (isChecked) {
                  control.disable();
                }
                else {
                  control.enable();
                }
              });
            }
          }

        });
      

      details.companyUserProjectAssignments?.forEach(assignment => {
        const projectId = assignment.project.projectId;
        const departments = assignment.departments || [];
        const roles = assignment.roles || [];

        if (this.portalType == 'customer' && this.modalType == 'edit' && this.componentType == 'company') {
          if (assignment.project.isProjectSelected) {
            _.forEach(departments, department => {
              if (department.isDepartmentSelected) {
                _.forEach(this.activeProjectList, project => {
                  if (project.projectId === projectId) {
                    project.departmentsIds.push(department);
                  }
                });
                this.closedProjectList.forEach(project => {
                  if (project.projectId === projectId) {
                    project.departmentsIds.push(department);
                  }
                });
              }
            });
            _.forEach(roles, role => {
              if (role.isUserRoleSelected) {
                _.forEach(this.activeProjectList, project => {
                  if (project.projectId === projectId) {
                    project.userRoleIds.push(role);
                  }
                });
                _.forEach(this.closedProjectList, project => {
                  if (project.projectId === projectId) {
                    project.userRoleIds.push(role);
                  }
                });
              }
            });
            this.companyForm.get(`activeproject${assignment.project.projectId}`)?.setValue(true);
            this.companyForm.get(`closedproject${assignment.project.projectId}`)?.setValue(true);
            this.activeProjectControls.forEach(control => {
              if (isChecked) {
                control.disable();
              }
              else {
                control.enable();
              }
            });
            this.closedProjectControls.forEach(control => {
              if (isChecked) {
                control.disable();
              }
              else {
                control.enable();
              }
            });
          }
        } else if (this.portalType == 'studio' && this.modalType == 'edit' && this.componentType == 'studio-company') {
          _.forEach(departments, (department) => {
            _.forEach(this.activeProjectList, (project) => {
              if (project.projectId === projectId) {
                if (!_.includes(project.departmentsIds, department)) {
                  project.departmentsIds.push(department);
                }
              }
            });
            _.forEach(this.closedProjectList, (project) => {
              if (project.projectId === projectId) {
                if (!_.includes(project.departmentsIds, department)) {
                  project.departmentsIds.push(department);
                }
              }
            });
          });
          _.forEach(roles, (role) => {
            _.forEach(this.activeProjectList, (project) => {
              if (project.projectId === projectId) {
                if (!_.includes(project.userRoleIds, role)) {
                  project.userRoleIds.push(role);
                }
              }
            });
            _.forEach(this.closedProjectList, (project) => {
              if (project.projectId === projectId) {
                if (!_.includes(project.userRoleIds, role)) {
                  project.userRoleIds.push(role);
                }
              }
            });
          });
          this.companyForm.get(`activeproject${assignment.project.projectId}`)?.setValue(true);
          this.companyForm.get(`closedproject${assignment.project.projectId}`)?.setValue(true);
          this.activeProjectControls.forEach(control => {
            if (isChecked) {
              control.disable();
            }
            else {
              control.enable();
            }
          });
          this.closedProjectControls.forEach(control => {
            if (isChecked) {
              control.disable();
            }
            else {
              control.enable();
            }
          });
        }
      });
    }
  }

  /**
* getCompanyUserMetaData() - method to invoke the company list for adding the customer user in studio portal.
* @param companyId - pass the companyId to get the metadata.
*/

  getCompanyUserMetaData(companyId): any {
    this.companyService.getCompanyUsersMetadata(companyId).subscribe(
      (data: any) => {
        this.metaData = data.companyUserMetaData;
        this.initData();
        this.initializeForm();
        this.setupActiveProjectValidation();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  /**
  * showSuccess() - Displays a success toast notification with custom content and options.
  * @param successTpl - The template reference containing the toast's content.
  */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.clear()
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerActionTpl: any) {
    this.toastService.clear();
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }
}
