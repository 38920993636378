<div class="project-wrapper" *ngIf="permissionsData['accessProject']">
  <div class="container px-0">
    <div class="row">
      <div class="comman-title-block px-0">
        <div class="comman-title-section">
          <h1>{{ projectConstants.projects}}</h1>
        </div>
        <!-- Filters(dropdown) section-->
        <div class="comman-filter-block">
          <div class="container">
            <div class="row">
              <div class="col-lg-9 col-12 px-0">
                <div class="comman-filter-title-section">
                  <p>{{ projectConstants.filters }}:</p>
                </div>
                <div class="comman-filter-section">
                  <app-ng-multiselect-dropdown [placeholder]="'Projects'" [settings]="defaultSettings"
                    [data]="projectsFilterData" (onSelect)="selectedProject($event)"></app-ng-multiselect-dropdown>
                  <app-ng-multiselect-dropdown [placeholder]="'Types'" [settings]="defaultSettings"
                    [data]="typeFilterData" (onSelect)="selectedType($event)">
                  </app-ng-multiselect-dropdown>
                  <app-datepicker [placeholder]="datePickerPlaceholder.dates"
                    (dateRangeSelected)="onDateRangeSelected($event)"></app-datepicker>
                  <app-ng-multiselect-dropdown [placeholder]="'Status'" [settings]="defaultSettings"
                    [data]="statusFilterData" (onSelect)="selectedStatus($event)">
                  </app-ng-multiselect-dropdown>
                </div>
              </div>
              <div class="col-lg-3 col-12 px-0">
                <div class="comman-filter-title-section align-right-sort-text">
                  <p class="sort-text">{{ sortData.sort }}</p>
                </div>
                <div class="filter-sort-block d-flex align-right-sort-text">
                  <div class="most-recent-button btn-primary" [ngClass]="{'common-btn-secondary btn': sortId==1}"
                    (click)="sortByMostRecent(1)">{{
                    sortData.mostRecent
                    }}</div>
                  <div class="most-recent-button btn-primary" [ngClass]="{'common-btn-secondary btn': sortId==2}"
                    (click)="ascendingSort(2)">{{
                    sortData.aToz
                    }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Selected filters section -->
      <div class="selected-filters-section">
        <!-- Combined Selected Filters -->
        <div *ngIf="isFiltersSelected" class="selected-filters">
          <!-- Selected Projects -->
          <div *ngFor="let project of filteredProjects" class="filter-tag">
            {{ project.text }}
            <button type="button" (click)="removeSelectedItem('project', project)">×</button>
          </div>

          <!-- Selected Types -->
          <div *ngFor="let type of filteredTypes" class="filter-tag">
            {{ type.text }}
            <button type="button" (click)="removeSelectedItem('type', type)">×</button>
          </div>

          <!-- Selected Date Range -->
          <div *ngIf="selectedFromDate && selectedToDate" class="filter-tag">
            {{ formatDateRange(selectedFromDate, selectedToDate) }}
            <button type="button" (click)="clearDateRange()">×</button>
          </div>

          <!-- Selected Status -->
          <div *ngFor="let status of filteredStatus" class="filter-tag">
            {{ status.text }}
            <button type="button" (click)="removeSelectedItem('status', status)">×</button>
          </div>

          <!-- Clear All Filters Button -->
          <a class="text-link" (click)="clearAllFilters()">{{ projectConstants.clearFilters }}</a>
        </div>
      </div>
      <div *ngIf="paginatedData" class="project-list-wrapper ">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4" *ngFor="let data of paginatedData">
            <div class="project-card-block" (click)="openProjectDetails(data.projectId)">
              <div class="card">
                <img class="project-card-img img-fluid" 
                [src]="data.imageUrl == null ? getImageUrl(data.projectType) : data.imageUrl" 
                alt="project image"
                onerror="this.src='assets/images/project-image1.png'">
                <!-- <img class="project-card-img img-fluid" [src]="data.imageUrl" alt="blank-image"
                  onerror="this.src='../../../../../../assets/images/project-image1.png'"> -->
                <div class="card-body">
                  <h2 class="project-name">{{ data.projectName }}</h2>
                  <p class="project-movie-type">
                    {{ data.projectType }}<span *ngIf="data?.projectType === 'Television'"> - Season {{
                      data.seasonNumber }}</span>
                  </p>
                  <div class="project-date-status-block">
                    <p class="project-status"> {{ data.projectStartDate }} - {{
                      data.projectEndDate }}</p>
                    <span class="project-status-tag project-active-inactive"
                      [ngClass]="{'active-tag-green': data?.projectStatus?.projectStatus === 'Active', 'closed-tag-black': data?.projectStatus?.projectStatus === 'Closed', 'not-started-purple': data?.projectStatus?.projectStatus === 'Not Started'}">{{
                      data.projectStatus.projectStatus }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="paginatedData?.length === 0" class="no-projects-wrapper">
        <h6>{{ projectConstants.noProjects }}</h6>
      </div>

      <div *ngIf="paginatedData?.length !== 0">
        <app-pagination class="p-0" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems" [currentPage]="currentPage"
          (pageChange)="onPageChange($event)" (itemsPerPageChange)="onItemsPerPageChange($event)">
        </app-pagination>
      </div>
    </div>
  </div>
</div>

<ng-template #dangerTpl>
  <div class="danger">{{projectsResponse?.errorMessages}}</div>
</ng-template>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>