import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GO_TO_DROPDOWN, GO_TO_DROPDOWN_DEFAULT } from '../../constants/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-merged-cell',
  templateUrl: './merged-cell.component.html',
  styleUrls: ['./merged-cell.component.scss']
})
export class MergedCellComponent implements ICellRendererAngularComp {
  companyid: any;
  companyname: any;
  activeProjects: any;
  userInfo: any;
  go_to_dropdown = GO_TO_DROPDOWN;
  go_to_dropdown_default = GO_TO_DROPDOWN_DEFAULT;
  public params: any; // Variable to hold the parameters passed to the cell renderer
  constructor(private router: Router) {} 

  /**
   * agInit() - Method that initializes the cell renderer with the provided parameters
   * @param params - Parameters passed by ag-Grid for this cell renderer (cell data, grid details, etc.)
   */
  agInit(params: any): void {
    this.params = params; // Store the params in the component's instance for use in the template
    this.companyid = params?.data?.compid;
    this.companyname = params?.data?.companyName;
    this.activeProjects = params?.data?.activeProjects;
  }

  /**
   * refresh() - Method to refresh the cell content. Returns false, meaning no refresh is necessary.
   * This is typically used to determine whether the cell needs to be re-rendered when its data changes.
   * @returns boolean - Always returns false, indicating no refresh is needed.
   */
  refresh(): boolean {
    return false;
  }
   /*
  onChange()- Method to redirect to diff pages on change of the dropdown
  */
  onChange(event: any) : void {
    const selectedId = event.target.value;

    const selectedGoToOption = this.go_to_dropdown.find(option => option.description == selectedId);
    const selectedActiveProject = this.activeProjects.find(project => project.projectName == selectedId);

    if (selectedGoToOption && selectedGoToOption.description) {
      console.log('Selected from go_to_dropdown:', selectedGoToOption);
      switch (selectedGoToOption.description) {
        case 'Company':
          this.router.navigate(['/feature/studio-dashboard/company'],{
            queryParams: {
              companyid: this.companyid,
            }
          }); 
          break;
        case 'Projects':
          this.router.navigate(['/feature/studio-dashboard/project/project-list'],{
            queryParams: {
              companyid: this.companyid,
              companyName: this.companyname
            }
          });
          break;
        case 'Users':
          this.router.navigate(['/feature/studio-dashboard/company/company-user'],{
              queryParams: {
                companyid: this.companyid,
                companyName: this.companyname,
                page:'gotopage'
              }
            }
          );
          break;
        }
    } 
    else if (selectedActiveProject && selectedActiveProject.projectName) {
          this.userInfo = JSON.parse(localStorage.getItem('user-profile'));
          const userProfileId = this.userInfo.userProfileId;
          const projectId = selectedActiveProject.projectId;
          this.router.navigate(['/feature/studio-dashboard/project/project-details', userProfileId, projectId]);
    }
    else {
      console.log('No match found for selected value');
    }

    
  }

 /*
  redirectViewCompany()- Method to redirect to view company page on company name click
  */
  redirectViewCompany() : void {  
  this.router.navigate(['/feature/studio-dashboard/company'],{
    queryParams: {
      companyid: this.companyid,
    }
  });
  }
}
