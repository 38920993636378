<app-loader [loading]="loading" [overlayState]="overlayState"></app-loader>
<div *ngIf="showHeaderFooter">
  <app-header></app-header>
</div>
<router-outlet></router-outlet>
<!-- Use this tag to include the file upload component -->
<!-- <app-file-upload></app-file-upload> -->
<div *ngIf="showHeaderFooter">
  <app-footer></app-footer>
</div>
