import { Component, ViewChild, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import * as _ from 'lodash';
import { CREDIT_APP_TOAST_MESSAGES } from 'src/app/feature/customer-portal/constants/customer-portal.constant';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {

  @Output() filesChanged = new EventEmitter<File[]>();
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Output() filesUpdated = new EventEmitter<File[]>();
  @Input() allowOnlySingleFile: boolean = false;
  isFileOver = false;
  files: File[] = [];
  readonly MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB in bytes
  fileSizeErrorMessage: string;
  multipleFilesErrorMessage: string;
  pdfFileErrorMessage: string;
  

  /**
   * onDragOver() - Handles the drag over event to indicate a file drag action.
   * @param event - The drag event.
   */
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = true;
  }

  /**
   * onDragLeave() - Handles the drag leave event to reset drag action indicator.
   * @param event - The drag event.
   */
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = false;
  }

  /**
   * onDrop() - Handles the drop event to add the dragged files.
   * @param event - The drop event.
   */
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = false;
    if(this.allowOnlySingleFile){
      const files = event.dataTransfer?.files;
      this.fileSizeErrorMessage = null;
      this.multipleFilesErrorMessage = null;
      this.pdfFileErrorMessage = null;
      const selectedFile = files[0];
      if (files && files?.length > 1) {
        // If multiple files are dropped, show an error and prevent further action
        this.resetFileInput();
        this.multipleFilesErrorMessage = CREDIT_APP_TOAST_MESSAGES.dropOnlyOneFile;
        this.fileSizeErrorMessage = null;
        this.pdfFileErrorMessage = null;
        this.files = null;
        return;
      }
      if (selectedFile?.size > this.MAX_FILE_SIZE) {
        // If max file size is exceeds, show an error and prevent further action
        this.resetFileInput();
        this.fileSizeErrorMessage = CREDIT_APP_TOAST_MESSAGES.fileMoreThan100MBErrMsg;
        this.multipleFilesErrorMessage = null;
        this.pdfFileErrorMessage = null;
        this.files = null;
        return;
      }
      if (selectedFile?.type !== 'application/pdf') {
        // If other than pdf file is drop, show an error and prevent further action
        this.resetFileInput();
        this.pdfFileErrorMessage = CREDIT_APP_TOAST_MESSAGES.selectPdfFile;
        this.fileSizeErrorMessage = null;
        this.multipleFilesErrorMessage = null;
        this.files = null;
        return;
      }
      if (selectedFile) {
        this.fileSizeErrorMessage = null;
        this.multipleFilesErrorMessage = null;
        this.pdfFileErrorMessage = null;
        this.files = [selectedFile]; // Ensure only one file is stored
        this.emitFiles();
      }
     }
     else if (event.dataTransfer?.files) {
      this.addFiles(event.dataTransfer.files);
     }
  }

  /**
   * onFileSelect() - Handles the file input change event to add selected files.
   * @param event - The input event.
   */
  onFileSelect(event: Event) {
    const input = event.target as HTMLInputElement;
    this.fileSizeErrorMessage = null;
    this.multipleFilesErrorMessage = null;
    this.pdfFileErrorMessage = null;
    if (input?.files) {
     if(this.allowOnlySingleFile){
      const selectedFile = input?.files[0];
      if (selectedFile?.size > this.MAX_FILE_SIZE) {
        this.fileSizeErrorMessage = CREDIT_APP_TOAST_MESSAGES.fileMoreThan100MBErrMsg;
        this.multipleFilesErrorMessage = null;
        this.pdfFileErrorMessage = null;
        this.resetFileInput();
        return;
      }
      if (selectedFile) {
        this.fileSizeErrorMessage = null;
        this.multipleFilesErrorMessage = null;
        this.pdfFileErrorMessage = null;
        this.files = [selectedFile]; // Ensure only one file is stored
        this.emitFiles();
      }
     }
     else {
      this.fileSizeErrorMessage = null;
      this.multipleFilesErrorMessage = null;
      this.pdfFileErrorMessage = null;
      this.addFiles(input?.files);
     }
    }
    this.resetFileInput();
  }

  /**
   * addFiles() - Adds new files to the current list and ensures uniqueness by name.
   * @param files - The FileList containing the files to be added.
   */
  addFiles(files: FileList) {
    const newFiles = Array.from(files);
    this.files = _.uniqBy([...this.files, ...newFiles], 'name');
    this.emitFiles();
  }

  /**
   * emitFiles() - Emits the updated files list to the parent component.
   */
  emitFiles() {
    this.filesUpdated.emit(this.files);
  }

  /**
   * removeFile() - Removes a file from the current list and emits the removed file.
   * @param event - The click event.
   * @param index - The index of the file to be removed.
   */
  removeFile(event: Event, index: number) {
    event.preventDefault();
    const removedFile = this.files[index]; // Get the file to be removed
    this.files.splice(index, 1);
    this.filesChanged.emit([removedFile]); // Emit the removed file as an array
  }

  /**
   * resetFileInput() - Resets the file input element to allow re-selection of the same file.
   */
  resetFileInput() {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  /**
   * viewFile() - Opens the selected file in a new browser tab for viewing.
   * @param file - The file to be viewed.
   */
  viewFile(file: File): void {
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank'); // Open the file in a new tab
  }
}
