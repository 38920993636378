import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { rowData } from 'mockData/development/studio-users';
import { STUDIO_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';
import { BaseApiService } from 'src/app/shared/services/base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private baseApiService: BaseApiService) {}

  // Method to get user details from mock data and assign userType based on conditions
  getUserDetails(userId: string): Observable<{ userType: string, userDetails: any }> {
    const userDetail = rowData.find(user => user.id.toString() === userId);
    if (userDetail) {
      // Assign userType based on some condition
      const userType = (userId === '2') ? 'wbuser' : 'customeruser';

      // Return the details including userType and id
      return of({ 
        userType, 
        userDetails: { 
          ...userDetail, 
          id: userId, 
          userType 
        } 
      });
    }
    return of({ userType: 'unknown', userDetails: {} });
  }

  /**
   * getUsers() - it is used to get all users 
   * @returns - an observable with users List 
   */
  getUsers(){
    let endpoint = STUDIO_PORTAL_API_PATHS.Users_get
    return this.baseApiService.get(endpoint)
  }

  getUserById(userId: number): Observable<any> {
    const endpoint = `${STUDIO_PORTAL_API_PATHS.users_get_By_Id}userProfileId=${userId}`;
    return this.baseApiService.get(endpoint);
}
}
