<div class="mt-56 custom-floating-label common-search-input ">
  <label for="input1" class="form-label">Search</label>
  <input type="text" class="pe-4 form-control"  
       [(ngModel)]="searchTerm"
       (ngModelChange)="onInputChange($event)"
       [ngbTypeahead]="search"
       [inputFormatter]="formatter"
       [resultFormatter]="formatter"
       [resultTemplate]="rt"
       [editable]="true"    
       [disabled]="disabled"  
       [(ngModel)]="model"
       [placeholder]="placeholder"
       (selectItem)="selectedItem($event)">

  <img class="search-icon" src="../../../../../../assets/images/search-icon.svg" alt="Search Icon">
  <!-- Display error message if no results found -->
  <span *ngIf="invalidSearchMessage && searchTerm" class="text-danger">
    {{ invalidSearchMessage }}
  </span>
  <ng-template #rt let-r="result">
    <div class="user-details">
      <ul class="list-group">
        <li class="list-group-item">
          <span class="username">{{ r.companyName }}</span>
        </li>
        <li class="list-group-item">
          <span class="user-designation">{{ r.compid }} </span>
        </li>
      </ul>
    </div>
  </ng-template>
</div>

