import { Component, inject, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColDef, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-view-passes',
  templateUrl: './view-passes.component.html',
  styleUrls: ['./view-passes.component.scss']
})
export class ViewPassesComponent {
  selectedTab: string ;
  selectTab(tab: string) {
    this.selectedTab = tab;
  }
  onSelectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedTab = selectElement.value;
    
  }
  private modalService = inject(NgbModal);
  openSelectCompany(company: TemplateRef<any>) {
    this.modalService.open(company, { windowClass: 'common-modal-center',centered: true });
  }
  rowData:any[]=[
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Drive-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED OUT',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'},
    {entry_type:'Walk-On',name:'First/Last Name',organization:'Organization',active:'12/12/24',expires:'12/12/24',arrival:'10:10 AM',departure:'10:10 PM',entry_point:'Gate 3',building:'Building',status:'CHECKED IN',time:'10:11 AM'}
  ];
  colDefs: ColDef[] = [
    {
      headerName: 'ENTRY TYPE',
      field: 'entry_type',
      autoHeight: true,
      cellRenderer:this.entryTypeRenderer.bind(this),
      minWidth: 140,
      cellStyle:{paddingBottom:'1.5rem',paddingRight:'10px',paddingLeft:'24px',paddingTop:'24px'}
    },
    {
      headerName: 'NAME',
      field: 'name',
      minWidth: 120,
      headerClass:'name',
      cellClass:'common-row-padding common-cell-data-bold',
      // cellStyle:{paddingRight:'0px',paddingLeft:'10px',paddingBottom:'1.5rem'}
    },
    {
      headerName: 'ORG.',
      field: 'organization',
      filter: false,
       minWidth: 120,
       headerClass:'name',
       cellClass:'common-row-padding-new ',
      //  cellStyle:{paddingRight:'0px',paddingLeft:'10px'}
    },
    { headerName: 'ACTIVE', field: 'active', minWidth: 100 ,   headerClass:'name',       cellClass:'common-row-padding',},
    { headerName: 'EXPIRES', field: 'expires', minWidth: 110,   headerClass:'name',       cellClass:'common-row-padding', },
    {
      headerName: 'ARRIVAL',
      field: 'arrival',
      wrapText: true,
      autoHeight: true,
      minWidth: 110,
      cellClass:'common-row-padding',
      headerClass:'name',
      // cellStyle:{paddingRight:'0px',paddingLeft:'10px'}
    },
    {
      field:'departure',
      headerName:'DEPARTURE',
      minWidth:100,
      cellClass:'common-row-padding',
      headerClass:'name',
      // cellStyle:{paddingRight:'0px',paddingLeft:'10px'}
    },
    {
      field:'entry_point',
      headerName:'ENTRY POINT',
      minWidth:100,
      cellClass:'common-row-padding',
      headerClass:'custom-header-adjusting',
      // cellStyle:{paddingRight:'0px',paddingLeft:'10px'}
    },
    {
      headerName:'BUILDING',
      field:'building',
      minWidth:100,
      cellClass:'common-row-padding',
      headerClass:'custom-header-adjusting',
      // cellStyle:{paddingRight:'0px',paddingLeft:'10px'}
    },
    {
      headerName:'STATUS',
      field:'status',
      minWidth:150,
      cellClass:'common-row-padding',
      headerClass:'custom-header-adjusting',
      cellRenderer:this.statusCellRenderer.bind(this)
      // cellStyle:{paddingRight:'10px',paddingLeft:'10px'}
    },
    {
      headerName:'',
      field:'time',
      minWidth:90,
      cellStyle:{padding:'24px 24px 24px 0px'}
      // cellClass:'common-row-padding',
    },
    // {
    //   field:'merged-first-field',
    //   headerName:'',
    //   cellRenderer:this.mergedFieldRenderer.bind(this)

    // },
    {
      field:'merged-second-field',
      headerName:'',
      cellClass:'second-field',
      cellRenderer:this.thirdMergedFieldRenderer.bind(this),
      cellStyle:{
        padding:'0px'
      }
    }
  ];
  entryTypeRenderer(params: ICellRendererParams) {
    const entryType = params.value;
    const imageSrc = entryType === 'Walk-On' 
      ? 'assets/images/man.svg' 
      : entryType === 'Drive-On' 
      ? 'assets/images/car.svg' 
      : 'assets/images/default.svg'; 
  
    return `
      <span class="request-cell">
        <img src="${imageSrc}" alt="Entry Type Icon" class="entry-icon" />
        <span class="request-count">${entryType}</span>
      </span>`;
  }

  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.value);
    return `
         <div class="view-five-passes">
        <div class="request-status-tag ${statusClass}">${params.value}</div>
        </div>
    `;
  }
 
  getStatusClass(status: string): string {
    switch (status) {
      case 'CHECKED IN':
        return 'checked-in account-user-common-status-cell checked-in-width';
      case 'CHECKED OUT' :
        return 'checked-out account-user-common-status-cell checked-out-width'
      default:
        return '';
    }
  }
    
  thirdMergedFieldRenderer(params: ICellRendererParams) {
    const entryType = params.data.entry_type;
    const name = params.data.name;
    const gate = params.data.entry_point;
    const building = params.data.building;
    const organization = params.data.organization;
    const status = params.data.status;
    const time = params.data.time;
    const statusClass = this.getStatusClass(status);
  
    const imageSrc = entryType === 'Walk-On'
      ? 'assets/images/man.svg'
      : entryType === 'Drive-On'
      ? 'assets/images/car.svg'
      : 'assets/images/default.svg';
  
    return `
      <div class="third-merged-field">
        <div class="first-row">
          <span class="entry-type-row">
            <img src="${imageSrc}" alt="Entry Type Icon" class="entry-icon" />
            <span class="entry-type-text">${entryType}</span>
          </span>
          <span class="status-time">
          <span class="request-status-tag ${statusClass}">${status}</span>
          <span class="time-text">${time}</span>
          </span>
        </div>
        
        <div class="second-row">
          <span class="name-text common-cell-data-bold">${name}</span>
          <span class="organization-text">${organization}</span>
        </div>
  
        <div class="third-row">
          <span class="gate-text">${gate}</span>
          <span >-</span>
          <span class="building-text">${building}</span>
        </div>
      </div>
    `;
  }
  
  colsForSmallScreen = ['merged-second-field'];
  colsForLargeScreen = ['merged-second-field'];
  colsForMediumScreen = ['merged-second-field']
  colsForExtraLargeScreen = ['entry_type','name','organization','active','expires','arrival', 'departure', 'entry_point', 'building', 'status', 'time'];
}

