<div class="container">
    <div class="container cm-main-container px-0 mx-0">
        <div class="common-modal-input-section grey-bg review-panel">
            <h2 class="section-main-header">{{credFormFour.creditApplicationForm}}</h2>
            <div class="review-wrapper">
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.lineOfCredit}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                            <h5 class="title-content">{{credFormFour.lineOfCreditRequested}}</h5>
                            <p class="review-values">${{ lineOfCreditData?.locRequested}}</p>
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                            <h5 class="title-content">{{credFormFour.anticipatedJobTotal}}</h5>
                            <p class="review-values">${{ lineOfCreditData?.locJobTotal }}</p>
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                            <h5 class="title-content">{{credFormFour.totalNoOfMonths}}</h5>
                            <p class="review-values">{{ lineOfCreditData?.locNumOfMonths }}</p>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.letterOfCredit}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <p class="review-values">NA</p>
                            <p class="review-values">{{ letterOfGuaranteeData?.logComments }}</p>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.company}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <span class="review-values">A Brand New Prod Company</span><br />
                            <span class="review-values">2500 Main Street, Los Angeles, CA 92783</span><br />
                            <span class="review-values">(818)5678934</span><br />
                            <div class="col-md-12">
                                <label class="custom-checkbox" for="prodComBillingAddress">
                                    <input type="checkbox" id="prodComBillingAddress" checked disabled>
                                    <span class="checkmark"></span> Use for billing address
                                </label>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.federalTaxId}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <p class="review-values"> {{ caProdCompanyData[0]?.fedralTaxNum }} </p>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.accountPaybleContact}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <span class="review-values">{{ accountPayableContactData[0]?.firstName }}{{
                                accountPayableContactData[0]?.lastName }}</span><br />
                            <span class="review-values">{{ accountPayableContactData[0]?.emailAddress }}</span><br />
                            <span class="review-values">{{ accountPayableContactData[0]?.telephone }}</span><br />
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.companyProfile}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                            <h5 class="title-content">{{credFormFour.profile}}</h5>
                            <p class="review-values">{{ companyProfileData?.cqaCpType || "NA" }}</p>
                            <h5 class="title-content">{{credFormFour.totalAnnualSales || "NA" }}</h5>
                            <p class="review-values">{{ companyProfileData?.totalAnnualSales || "NA" }}</p>
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                            <h5 class="title-content">{{credFormFour.startedOn}}</h5>
                            <p class="review-values">{{ companyProfileData?.dateStarted || "NA" }}</p>
                            <h5 class="title-content">{{credFormFour.networth}}</h5>
                            <p class="review-values">${{ companyProfileData?.networth || "NA"}}</p>
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                            <h5 class="title-content">{{credFormFour.typeOfBusiness}}</h5>
                            <p class="review-values">{{ companyProfileData?.typeOfBusiness || "NA" }}</p>
                            <h5 class="title-content">{{credFormFour.noOfEmployees}}</h5>
                            <p class="review-values">{{ companyProfileData?.noOfEmployees || "NA" }}</p>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.principalOfficer}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <div class="profile-office-details" *ngFor="let data of prinipalOfficerData">
                                <span class="review-values">{{ data.partnerName || "NA" }}</span><br />
                                <span class="review-values">{{ data.partnerTitle || "NA" }}</span>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.bankingReference}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <span class="review-values">{{ bankingReferenceData?.bankName || "NA" }}</span><br />
                            <span class="review-values">{{ bankingReferenceData?.accountNumber || "NA" }}</span><br />
                            <span class="review-values">{{ bankingReferenceData?.contactFullName || "NA" }}</span><br />
                            <span class="review-values">{{ bankingReferenceData?.title || "NA" }}</span><br />
                            <span class="review-values">{{ bankingReferenceData?.emailAddress || "NA" }}</span><br />
                            <span class="review-values">{{ bankingReferenceData?.phoneNumber || "NA" }}</span><br />
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.tradeReferences}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <div class="trade-reference">
                                <h5 class="title-content">{{credFormFour.reference1}}</h5>
                                <span class="review-values">{{ tradeReferenceOneData?.trCompanyName || "NA"
                                    }}</span><br />
                                <span class="review-values">{{ tradeReferenceOneData?.trFirstName || "NA" }}{{
                                    tradeReferenceOneData?.trLastName }}</span><br />
                                <span class="review-values">{{ tradeReferenceOneData?.trPhone || "NA" }}</span><br />
                                <span class="review-values">{{ tradeReferenceOneData?.trEmail || "NA" }}</span><br />
                                <span class="review-values">{{ tradeReferenceOneData?.trStreet || "NA" }}, {{
                                    tradeReferenceOneData?.trCity || "NA" }}, {{ tradeReferenceOneData?.trStateId ||
                                    "NA" }}, {{ tradeReferenceOneData?.trZipCode || "NA" }} </span>
                            </div>
                            <div class="trade-reference">
                                <h5 class="title-content">{{credFormFour.reference1}}</h5>
                                <span class="review-values">{{ tradeReferenceTwoData?.trCompanyName || "NA"
                                    }}</span><br />
                                <span class="review-values">{{ tradeReferenceTwoData?.trFirstName || "NA" }}{{
                                    tradeReferenceTwoData?.trLastName }}</span><br />
                                <span class="review-values">{{ tradeReferenceTwoData?.trPhone || "NA" }}</span><br />
                                <span class="review-values">{{ tradeReferenceTwoData?.trEmail || "NA" }}</span><br />
                                <span class="review-values">{{ tradeReferenceTwoData?.trStreet || "NA" }}, {{
                                    tradeReferenceTwoData?.trCity || "NA" }}, {{ tradeReferenceTwoData?.trStateId ||
                                    "NA" }}, {{ tradeReferenceTwoData?.trZipCode || "NA" }} </span>
                            </div>
                            <div class="trade-reference">
                                <h5 class="title-content">{{credFormFour.reference1}}</h5>
                                <span class="review-values">{{ tradeReferenceThreeData?.trCompanyName || "NA"
                                    }}</span><br />
                                <span class="review-values">{{ tradeReferenceThreeData?.trFirstName || "NA" }}{{
                                    tradeReferenceThreeData?.trLastName }}</span><br />
                                <span class="review-values">{{ tradeReferenceThreeData?.trPhone || "NA" }}</span><br />
                                <span class="review-values">{{ tradeReferenceThreeData?.trEmail || "NA" }}</span><br />
                                <span class="review-values">{{ tradeReferenceThreeData?.trStreet || "NA" }}, {{
                                    tradeReferenceThreeData?.trCity || "NA" }}, {{ tradeReferenceThreeData?.trStateId ||
                                    "NA" }}, {{ tradeReferenceThreeData?.trZipCode || "NA" }} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="common-modal-input-section grey-bg review-panel">
            <h2 class="section-main-header">{{credFormFour.certificateOfInsurance}}</h2>
            <div class="review-wrapper">
                <div class="review-inner">
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <div class="insurance-info-details">
                                <h5 class="title-content">{{credFormFour.insuranceInformation}}</h5>
                                <span class="review-values">Donec ullamcorper nulla non metus auctor
                                    fringilla</span><br />
                            </div>
                            <div class="insurance-info-details">
                                <h5 class="title-content">{{credFormFour.form}}</h5>
                                <span class="review-values">COI-signed-pdf (137kb)</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="common-modal-input-section grey-bg review-panel">
            <h2 class="section-main-header">{{credFormFour.termsAndConditions}}</h2>
            <div class="review-wrapper">
                <div class="review-inner">
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <div class="terms-condtion">
                                <span class="review-values">Not Signed</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex gap-2 cust-sendar-action-btn">
            <div class="left-btn">
                <a class="text-link" (click)="previousPage()"><img class="back-img"
                        src="../../assets/images/blue-left-icon.svg" alt="Back" />back</a>
            </div>
            <div class="right-btn">
                <button type="button" class="btn common-btn-light"
                    (click)="saveAndClose()">{{credFormFour.SaveAndClose}}</button>
                <button type="button" class="btn common-btn-secondary"
                    (click)="submitCreditApplication()">{{credFormFour.submitCreditApplication}}</button>
            </div>
        </div>
    </div>
</div>
<!---Added modals on click of send revision-->
<ng-template #company let-modal>
    <div class="modal-body">
        <h2 class="section-main-header">Send Revisions to Customer (1)</h2>
        <p>COMPANY: A Brand New Company</p>
        <p>PROJECT: The new show</p>
        <div class="">
            <h5>Text Box Header:</h5>
            <p>Body copy</p>
        </div>
        <div class="d-flex justify-content-end gap-2">
            <button type="button" class="btn common-btn-secondary" (click)="modal.close('Close click')">Cancel</button>
            <button type="button" class="btn common-btn-secondary">Send Revision</button>
        </div>
    </div>
</ng-template>

<ng-template #successTpl>
    <div class="success">{{ toastMessages.reviewSuccess }}</div>
</ng-template>

<ng-template #dangerTpl>
    <div class="danger">{{ submissionError }}</div>
</ng-template>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>