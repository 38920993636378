import { Component, HostListener, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { ROW_DATA_REQUESTS } from 'mockData/development/studio-portal-dashboard';
import { STUDIO_DASHBOARD_REQUESTS } from 'src/app/feature/studio-portal/constants/studio-portal.constant';

@Component({
  selector: 'app-studio-dashboard-requests',
  templateUrl: './studio-dashboard-requests.component.html',
  styleUrls: ['./studio-dashboard-requests.component.scss']
})
export class StudioDashboardRequestsComponent {
  @ViewChild('requestGrid') requestGrid!: AgGridAngular;
  @HostListener('window:resize', ['$event'])
  rowDataRequest = ROW_DATA_REQUESTS;
  requestConstants = STUDIO_DASHBOARD_REQUESTS;
  colDefsRequest: ColDef[];
  gridApi!: GridApi;
  gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      filter: true
    },
  };

  constructor() { }

  ngOnInit() {
    this.getColumnData();
  }

  /**
   * getColumnData() - Method to retrieve credit application columns 
  */
  getColumnData(): void {
    this.colDefsRequest = [
      {
        headerName: '',
        field: 'date',
        wrapText: true,
        autoHeight: true,
        width: 100,
        hide: true,
        maxWidth: 110,
        cellClass: 'date-request'
      },
      {
        headerName: '',
        field: 'merged-field',
        wrapText: true,
        autoHeight: true,
        cellRenderer: (params: ICellRendererParams) => {
          const status = params.data.status;
          const statusClassRequest = this.getStatusClassRequest(status);
          const request = params.data.request;
          const date = params.data.date;
          const projectName = params.data.projectName;
          return `<div class="common-split-cell-section">
                   <div class="d-flex justify-content-between">   
        <div class="common-split-cell-data-1 mb-0">${request} </div>
          
        <div class="request-status-tag ${statusClassRequest} w-auto">${status}</div>
        </div>
                  <div class="common-split-cell-data-2 pt-0 pb-2" >${date}</div>
                    <div class="common-split-cell-data-2 pb-common">Project Name:${projectName}</div>
                </div>`;
        },
        minWidth: 150,
        flex: 2,

      },
      {
        headerName: '',
        field: 'request',
        cellRenderer: (params: ICellRendererParams) => {
          const request = params.data.request;
          const projectName = params.data.projectName;
          return `<div class="common-split-cell-section">
                  <div class="common-split-cell-data-1 common-cell-data-bold">${request}</div>
                  <div class="common-split-cell-data-2 ">Project Name : ${projectName}</div>
                </div>`;
        },
        minWidth: 150,

        flex: 2
      },
      {
        headerName: '',
        field: 'status',
        cellRenderer: this.statusCellRendererRequest.bind(this),
        cellClass: 'account-user-common-status-cell cell-right-align ',
        minWidth: 150,
        hide: true,
      },
    ];
  }

  /**
    * onGridReady() - Method to manage the grid of columns 
  */
  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.adjustColumns(window.innerWidth);
  }

  /**
   * onResize() - Method to manage resize the columns 
  */
  onResize(event: any): void {
    this.adjustColumns(event.target.innerWidth);
  }

  /**
   * statusCellRenderer() - Renders a cell with the appropriate status class and value.
   * @param params - Parameters passed by ag-Grid to the cell renderer. Includes data and context information.
  */
  statusCellRendererRequest(params: ICellRendererParams) {
    const statusClass = this.getStatusClassRequest(params.value);
    return `
       <div class="status-container move-right">
      <div class="request-status-tag  ${statusClass}">${params.value}</div>
      </div>
  `;
  }

  /**
   * getStatusClass() - Returns the appropriate class for each status value
   * @param status - The status string to get class for
  */
  getStatusClassRequest(status: string): string {
    switch (status) {
      case 'NEW REQUEST':
        return 'new account-user-common-status-cell cell-right-align';
      case 'IN PROGRESS':
        return 'inprogress-color account-user-common-status-cell';
      case 'CLOSED':
        return 'closed account-user-common-status-cell';
      case 'ASSIGNED':
        return 'assigned-color account-user-common-status-cell';
      case 'RESOLVED':
        return 'resolved-color account-user-common-status-cell';
      default:
        return '';
    }
  }

  /**
   * getColumnData() - Method to adjust the columns 
  */
  adjustColumns(screenWidth: number): void {
    const requestGridApi = this.requestGrid.api;
    if (requestGridApi) {
      const requestColumnIds = ['date', 'merged-field', 'request', 'status'];
      if (screenWidth <= 510) {
        requestGridApi.setColumnsVisible(requestColumnIds, false);
        requestGridApi.setColumnsVisible(['merged-field'], true);
      } else {
        requestGridApi.setColumnsVisible(requestColumnIds, true);
        requestGridApi.setColumnsVisible(['merged-field'], false);
      }
    }
  }

}
