import { Component } from '@angular/core';

@Component({
  selector: 'app-spects-stage',
  templateUrl: './spects-stage.component.html',
  styleUrls: ['./spects-stage.component.scss']
})
export class SpectsStageComponent {

}
