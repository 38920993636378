import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from 'src/app/shared/services/base-api/base-api.service';
import { CUSTOMER_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  constructor(private baseApiService:BaseApiService) { }

  /**
   * getWeatherByCoords() - method to get the weather forecast response
   * @param lat - latitudes of the location
   * @param lon - longitudes of the location
   * @returns - returns the get API
   */
  getWeatherByCoords(lat: number, lon: number): Observable<any> {
    const url = `${CUSTOMER_PORTAL_API_PATHS.weather_forecast}?latitude=${lat}&longitude=${lon}`;
    return this.baseApiService.get(url);
  }
}
