import { Component } from '@angular/core';

@Component({
  selector: 'app-studio-email-tire-one',
  templateUrl: './studio-email-tire-one.component.html',
  styleUrls: ['./studio-email-tire-one.component.scss']
})
export class StudioEmailTireOneComponent {
  selectedAction = { text: 'Actions…', img: '' };

  actions = [
    { text: 'Accept on Behalf of SVP', img: '../../../../../assets/images/orange-email-icon.svg' },
    { text: 'Send to SVP', img: '../../../../../assets/images/pending-icon.svg' },
    { text: 'Deny', img: '../../../../../assets/images/approved-email.svg' }
  ];

  updateButtonText(action: { text: string, img: string }) {
    this.selectedAction = action;
}
}
