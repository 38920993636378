export const rowData = [
  { id: 1, fullName: 'Dan Acorn', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '02/11/2024', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', company: 'Duis mollisest non commodo', },
  { id: 2, fllName: 'Lexi Allan', email: 'eryamille@sunshineproductions.com', requestType: "", status: 'DEACTIVATED', updatedDate: '01/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', company: 'Duis mollisest non commodo' },
  { id: 12348, fullName: 'John Smith', email: 'jsmith@sunshineproducions.com', requestType: "", status: 'DEACTIVATED', updatedDate: '01/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', company: 'WBSO' },
  { id: 4, fullName: 'Nuha Hodgson', email: 'colettealfaro@gmail.com', requestType: "system admin", status: 'ACTIVE', updatedDate: '01/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', company: 'WBSO' },
  { id: 5, fullName: 'Farhan Blevins', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '01/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', company: 'WBSO' },
  { id: 6, fullName: 'Lin ANg', email: 'colettealfaro@gmail.com', requestType: "", status: 'DEACTIVATED', updatedDate: '01/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', company: 'WBSO' },
  { id: 7, fullName: 'Erya Milnex`', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '01/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', company: 'Duis mollisest non commodo' },
  { id: 8, fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '01/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', company: 'Duis mollisest non commodo' },
  { id: 9, fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "account payable", status: 'ACTIVE', updatedDate: '01/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', company: 'Duis mollisest non commodo' },
  { id: 10, fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'DEACTIVATED', updatedDate: '01/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', company: 'Duis mollisest non commodo' },
  { id: 11, fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "company admin", status: 'ACTIVE', updatedDate: '01/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', company: 'WBSO' },
  { id: 12, fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '01/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', company: 'Duis mollisest non commodo' },
  { id: 13, fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '01/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', company: 'Duis mollisest non commodo' },
];

export const roles = [
  // { name: 'Department User', id: 11 },
  // { name: 'Department Head', id: 12 },
  // { name: 'CSR', id: 13 },
  // { name: 'A/R', id: 14 },
  // { name: 'System Admin', id: 15 },

]
interface roles {
  id: string; // or number, depending on your needs
  name: string;
  showArDetails: boolean;
  showDepartmentDetails: boolean;
}

export const userTypeData = [
  { id: 1, text: 'Accounts Payable' },
  { id: 2, text:  'All Users'},
  { id: 3, text:'Company Admin' },
  { id: 4, text: 'Customer'  },
  { id: 5, text: 'WBSO' }
];

  
 


