import { Component, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-credit-app',
  templateUrl: './credit-app.component.html',
  styleUrls: ['./credit-app.component.scss']
})
export class CreditAppComponent {
  constructor(private modalService:NgbModal){}

  openDeleteConfirmationModal(content: TemplateRef<any>): void {
    this.modalService.open(content, { size: 'lg' , centered: true });
  }
}
