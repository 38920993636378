import { Component } from '@angular/core';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import {
  companyJobTitleTypeahead,
  companyStatusData,
  companyUserTypeahead,
} from 'mockData/development/company-filters-data';
import { IDropdownSettings } from 'src/app/shared/models/multiselect.model';
import {
  COMPANY_FILTERS,
  DropdownSettings,
} from 'src/app/feature/studio-portal/constants/studio-portal.constant';
import { IconsComponent } from 'src/app/shared/components/ag-grid-icons/icons/icons.component';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-credit-grid',
  templateUrl: './credit-grid.component.html',
  styleUrls: ['./credit-grid.component.scss'],
})
export class CreditGridComponent {
  userFilterData: any[] = [];
  filteredProjects: any[] = [];
  filteredStatus: any[] = [];
  filteredUser: any[] = [];
  filteredJobtitle: any[] = [];
  filteredData: any[] = [];
  jobTitleFilterData: any[] = [];
  dropdown = DropdownSettings;
  defaultSettings: IDropdownSettings = {
    idField: this.dropdown.id,
    textField: this.dropdown.textField,
    enableCheckAll: true,
    allowSearchFilter: true,
    limitSelection: this.dropdown.limitSelection,
    clearSearchFilter: true,
    maxHeight: this.dropdown.maxHeight,
    itemsShowLimit: this.dropdown.itemsShowLimit,
    searchPlaceholderText: this.dropdown.searchPlaceholderText,
    noDataAvailablePlaceholderText: this.dropdown.noData,
    noFilteredDataAvailablePlaceholderText: this.dropdown.noFilteredData,
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
    allowRemoteDataSearch: false,
  };

  // Data for dropdown lists projects, types, departments, status and dayes
  activeprojects;
  status = companyStatusData;
  companyConstants = COMPANY_FILTERS;
  user = companyUserTypeahead;
  jobtitle = companyJobTitleTypeahead;
  userTypeahead: any = 'typeahead';
  rowData = [
    {
      date: '1/1/24',
      time: '10:00AM',
      companyName: 'Company Name',
      clientType: 'Client Type',
      projectName: 'Project Name',
      projectType: 'Project Type',
      ar: '123456',
      department: 'Department Name; Department Name; Department Name; +2',
      reviewTime: '6 days left',
      comments: '5',
      status: 'NEW APPLICATION',
    },
    {
      date: '1/1/24',
      time: '10:00AM',
      companyName: 'Company Name',
      clientType: 'Client Type',
      projectName: 'Project Name',
      projectType: 'Project Type',
      ar: '123456',
      department: 'Department Name; Department Name; Department Name; +2',
      reviewTime: '6 days left',
      comments: '5',
      status: 'DENIED',
    },
    {
      date: '1/1/24',
      time: '10:00AM',
      companyName: 'Company Name',
      clientType: 'Client Type',
      projectName: 'Project Name',
      projectType: 'Project Type',
      ar: '123456',
      department: 'Department Name; Department Name; Department Name; +2',
      reviewTime: '6 days left',
      comments: '6',
      status: 'NEEDS REVISIONS',
    },
    {
      date: '1/1/24',
      time: '10:00AM',
      companyName: 'Company Name',
      clientType: 'Client Type',
      projectName: 'Project Name',
      projectType: 'Project Type',
      ar: '123456',
      department: 'Department Name; Department Name; Department Name; +2',
      reviewTime: '6 days left',
      comments: '7',
      status: 'NEEDS APPROVAL',
    },
    {
      date: '1/1/24',
      time: '10:00AM',
      companyName: 'Company Name',
      clientType: 'Client Type',
      projectName: 'Project Name',
      projectType: 'Project Type',
      ar: '123456',
      department: 'Department Name; Department Name; Department Name; +2',
      reviewTime: '6 days left',
      comments: '1',
      status: 'APPROVED',
    },
    {
      date: '1/1/24',
      time: '10:00AM',
      companyName: 'Company Name',
      clientType: 'Client Type',
      projectName: 'Project Name',
      projectType: 'Project Type',
      ar: '123456',
      department: 'Department Name; Department Name; Department Name; +2',
      reviewTime: '6 days left',
      comments: '2',
      status: 'REVISIONS SUBMITTED',
    },
    {
      date: '1/1/24',
      time: '10:00AM',
      companyName: 'Company Name',
      clientType: 'Client Type',
      projectName: 'Project Name',
      projectType: 'Project Type',
      ar: '123456',
      department: 'Department Name; Department Name; Department Name; +2',
      reviewTime: '6 days left',
      comments: '3',
      status: 'PENDING RISK',
    },
    {
      date: '1/1/24',
      time: '10:00AM',
      companyName: 'Company Name',
      clientType: 'Client Type',
      projectName: 'Project Name',
      projectType: 'Project Type',
      ar: '123456',
      department: 'Department Name; Department Name; Department Name; +2',
      reviewTime: '6 days left',
      comments: '4',
      status: 'UNDER REVIEW',
    },
    {
      date: '1/1/24',
      time: '10:00AM',
      companyName: 'Company Name',
      clientType: 'Client Type',
      projectName: 'Project Name',
      projectType: 'Project Type',
      ar: '123456',
      department: 'Department Name; Department Name; Department Name; +2',
      reviewTime: '6 days left',
      comments: '6',
      status: 'APPROVED (RISK)',
    },
    {
      date: '1/1/24',
      time: '10:00AM',
      companyName: 'Company Name',
      clientType: 'Client Type',
      projectName: 'Project Name',
      projectType: 'Project Type',
      ar: '123456',
      department: 'Department Name; Department Name; Department Name; +2',
      reviewTime: '6 days left',
      comments: '9',
      status: 'APPROVED',
    },
  ];

  colDefs: ColDef[] = [
    {
      field: 'date',
      headerName: 'DATE',
      maxWidth: 110,
      cellRenderer: this.updatedDateTimeRenderer,
      cellStyle: { paddingRight: '0px' },
    },
    {
      field: 'company',
      headerName: 'COMPANY',
      minWidth: 165,
      maxWidth: 145,
      cellRenderer: this.companyClientRenderer,
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
    },
    {
      field: 'project',
      headerName: 'PROJECT',
      minWidth: 155,
      maxWidth: 125,
      cellRenderer: this.projectNameTypeRenderer,
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
    },
    {
      field: 'ar',
      headerName: 'AR #',
      minWidth: 110,
      maxWidth: 100,
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
      cellRenderer: this.uploadRenderer.bind(this),
    },
    {
      field: 'department',
      headerName: 'DEPARTMENTS',
      minWidth: 227,
      cellClass: 'credit-department inter-font',
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
      cellRenderer: this.departmentCellRenderer.bind(this),
    },
    {
      field: 'reviewTime',
      headerName: 'REVIEW TIME',
      minWidth: 80,
      cellClass: 'credit-review-time inter-font',
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
    },
    {
      field: 'comments',
      headerName: '',
      minWidth: 75,
      maxWidth: 70,
      cellRenderer: this.commentsRenderer.bind(this),
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
    },
    {
      field: 'status',
      headerName: 'STATUS',
      minWidth: 190,
      cellRenderer: this.statusCellRenderer.bind(this),
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
    },
    {
      field: 'email',
      headerName: '',
      minWidth: 60,
      cellRenderer: (params: ICellRendererParams) => {
        const rowIndex = params.node.rowIndex;

        let imageUrl = '';
        if (rowIndex === 0) {
          imageUrl = 'assets/images/email-sent.svg';
        } else if (rowIndex === 1) {
          imageUrl = 'assets/images/approved-email.svg';
        }
        return imageUrl
          ? `<span class="email-cell">
          <img src="${imageUrl}" alt="Email Icon" class="email-icon"/>
         </span>`
          : '';
      },
      cellStyle: { paddingLeft: '10px', paddingRight: '0px' },
    },
  ];
  updatedDateTimeRenderer(params: ICellRendererParams) {
    const date = params.data.date;
    const time = params.data.time;
    return `
    <span class="common-split-cell-section common-mobile-section">
    <span class="common-split-cell-data-1">${date}</span>
    <span class="common-split-cell-data-2">${time}</span>
    </span>`;
  }

  companyClientRenderer(params: ICellRendererParams) {
    const companyName = params.data.companyName;
    const clientType = params.data.clientType;
    return `<span class="common-split-cell-section common-mobile-section">
    <span class="common-split-cell-data-1">${companyName}</span>
    <span class="common-split-cell-data-2">${clientType}</span>
    </span>`;
  }
  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.data.status);
    return `
      <div class="status-container">
        <div class="ar-status-tag ${statusClass} credit-status">${params.data.status}</div>
      </div>
    `;
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'NEW APPLICATION':
        return 'new-application new-app-width';
      case 'APPROVED':
        return 'approved approved-wid';
      case 'DENIED':
        return 'denied';
      case 'NEEDS REVISIONS':
        return 'revision need-revision-width';
      case 'NEEDS APPROVAL':
        return 'need-approve need-revision-width';
      case 'REVISIONS SUBMITTED':
        return 'revision-submitted';
      case 'PENDING RISK':
        return 'revision';
      case 'UNDER REVIEW':
        return 'under-review';
      case 'APPROVED (RISK)':
        return 'approved';
      default:
        return '';
    }
  }

  projectNameTypeRenderer(params: ICellRendererParams) {
    const projectName = params.data.projectName;
    const projectType = params.data.projectType;
    return `<span class="common-split-cell-section common-mobile-section">
    <span class="common-split-cell-data-1">${projectName}</span>
    <span class="common-split-cell-data-2">${projectType}</span>
    </span>`;
  }

  commentsRenderer(params: ICellRendererParams) {
    const comments = params.value;
    return `
      <span class="comments-cell">
        <span class="comments-count">${comments}</span>
        <img src="assets/images/comment.svg" alt="Comment Icon" class="comments-icon"/>
      </span>`;
  }
  uploadRenderer(params: ICellRendererParams) {
    const ar = params.value;
    return `
      <span class="comments-cell">
        <img src="assets/images/upload-icon.svg" alt="Comment Icon" class="comments-icon"/>
                <span class="comments-count">${ar}</span>
      </span>`;
  }
  departmentCellRenderer(params: ICellRendererParams) {
    const departmentText = params.value || '';
    const formattedText = departmentText.replace(/\+\d+/g, (match) =>
      `<span style="color: blue; font-family: 'Inter-600'; font-size: 14px;">${match}</span>`
    );
    return `<span>${formattedText}</span>`;
  }

  colsForSmallScreen = ['date',
    'company',
    'project',
    'ar',
    'department',
    'reviewTime',
    'comments',
    'status',
    'email',];
  colsForMediumScreen = ['date',
    'company',
    'project',
    'ar',
    'department',
    'reviewTime',
    'comments',
    'status',
    'email',];
  colsForLargeScreen = ['date',
    'company',
    'project',
    'ar',
    'department',
    'reviewTime',
    'comments',
    'status',
    'email',];
  colsForExtraLargeScreen = [
    'date',
    'company',
    'project',
    'ar',
    'department',
    'reviewTime',
    'comments',
    'status',
    'email',
  ];
  selectedProject(onSelect: any) {
    this.filteredProjects = onSelect;
  }

  /**
   * selectedUser() - Method to handle selection of users from the dropdown
   * @param onSelect - The selected items from the dropdown
   */

  selectedUser(onSelect: any) {
    this.filteredUser = onSelect;
  }

  /**
   * selectedProject() - Method to handle selection of jobtitle from the dropdown
   * @param onSelect - The selected items from the dropdown
   */

  selectedJobtitle(onSelect: any) {
    this.filteredJobtitle = onSelect;
  }

  selectedStatus(selectedItems: any) {
    this.filteredStatus = selectedItems;
  }

  onDateRangeSelected(dateRange: { fromDate: NgbDate | null }) { }

showAlert = true;

  toggleAlert() {
    this.showAlert = !this.showAlert;
  }

  onCloseAlert() {
    this.showAlert = false;
  }}