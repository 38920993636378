<p>credit-acknowledgement-modal works!</p>
<button type="button" class="common-btn-light btn" 
(click)="openCreditAcknowledgeModal(content)">Click</button>
<!-- <ng-template #content let-modal>

  <div class="modal-body">
    <h4 class="section-main-header">Confirmation Header</h4>
    <div class="modal-body">
      <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
        </p>
        <ul>
          <li> By no means submitting a credit application guarantees the credit acceptance…</li>
          <li> …we will reach out to you for other options</li>
          <li>…by submitting, we have the right to check your credit</li>
        </ul>
    </div>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-light btn" (click)="modal.close()">Cancel</button>

      <button type="button" class="common-btn-secondary btn"
       >I acknowledge these conditions</button>
    </div>
  </div>
</ng-template> -->

<ng-template #content let-modal>

  <div class="modal-body">
    <h4 class="section-main-header">Confirmation Header</h4>

    <label>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</label>
    <div >
   
        <label class="mb-3"> <ul>
          <li> By no means submitting a credit application guarantees the credit acceptance…</li>
          <li> …we will reach out to you for other options</li>
          <li>…by submitting, we have the right to check your credit</li>
        </ul></label>
    </div>
    
    <div class="d-flex justify-content-end gap-3 flex-column-reverse flex-md-row">
      <button type="button" class="common-btn-light btn" (click)="modal.close()">Cancel</button>

      <button type="button" class="common-btn-secondary btn"
       >I acknowledge these conditions</button>
    </div>
  </div>
</ng-template>