 import { Component } from '@angular/core';
import { core_directory } from 'src/app/core/constants/core.constant';
import { NUMBER } from 'src/app/core/enum/core.enum';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';

@Component({
  selector: 'app-core-directory',
  templateUrl: './core-directory.component.html',
  styleUrls: ['./core-directory.component.scss']
})
export class CoreDirectoryComponent {
  constants = core_directory;
  number = NUMBER;
  selectedTab: string = this.constants.office_production_services_tab;
  portalType: string;
  isUserLoggedIn: boolean = false;
  isAuthenticated: boolean = false;


  constructor(private authService: AuthService) {}

  /**
   * selectTab() - method to update the currently selected tab.
   * @param tab - The identifier for the tab to be selected. This is typically a string that corresponds to one of the available tabs.
  */
  selectTab(tab: string) {
    this.selectedTab = tab;
  }
   /**
   * onSelectChange() - method to render different tabs after clicking on the button 
   * @param event - The change event object associated with the select element.
  */
  onSelectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedTab = selectElement.value;
  }

  ngOnInit(): void {
    this.checkAuthentication();
  }

  private async checkAuthentication(): Promise<void> {
    this.isAuthenticated = await this.authService.isAuthenticated();
    if (this.isAuthenticated) {
      const userProfileData = localStorage.getItem('user-profile');
      if (userProfileData) {
        const userProfile = JSON.parse(userProfileData);
        this.setPortalType(userProfile);
      }
    }
  }

  private setPortalType(userProfile: any): void {
    if (userProfile.userTypeId === 1) {
      this.portalType = "customer";
    } else if (userProfile.userTypeId === 2) {
      this.portalType = "studio";
    }
  }
}