<!-- welcome section starts -->
<app-loader [loading]="loading" [overlayState]="overlayState"></app-loader>
<div class="container-fluid" style="background: var(--surface-surface-secondary, #F5F5F5);">
  <div class="container homepage-top-section">
    <div class="row">
      <div class="col-md-4 d-flex order-2 order-sm-2 order-md-2 order-lg-1 justify-content-center align-items-center">
        <img src="../assets/images/water-tower-wb.png" alt="Welcome Image" class="img-fluid" style="height: auto;">
      </div>
      <div class="col-md-8 d-flex  order-1 order-sm-1 order-md-1 order-lg-2 justify-content-center align-items-center">
        <div class="welcome-note">
          <h1 class="homepage-greeting-msg">
            Welcome to Warner Bros.
            Studio Operations
          </h1>
          <p class="homepage-greeting-submsg">
            We are honored to host your production on our stages, backlot sets and/or
            offices at our <b class="bold-text">Burbank Main Lot & Ranch.</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- welcome section ends -->
<!-- center section starts -->
<div class="container">
  <div class="row" style="padding:112px 0 112px 0">
    <div class="col-lg-4 col-md-12 col-sm-12 site-resource-section">
      <div class="d-flex align-items-center site-resource-icon-wrapper">
        <img src="../assets/images/handbook.svg" alt="Handbook icon" class="me-3">
        <h2>Handbook</h2>
      </div>
      <div class="site-resource-more-info">
        <p class="site-resource-info-text">Operational procedures and policies for all users of the Studios.</p>
        <a routerLink="/core/wbso-handbook" class="text-link">Read the Handbook</a>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 col-sm-12 site-resource-section">
      <div class="d-flex align-items-center site-resource-icon-wrapper">
        <img src="../assets/images/maps.svg" alt="Handbook icon" class="me-3">
        <h2>Maps</h2>
      </div>
      <div class="site-resource-more-info">
        <p class="site-resource-info-text">Main Lot, Ranch, EV Chargers and Conference Rooms and more.</p>
        <a href="/core/maps" class="text-link">View All Maps</a>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 col-sm-12 site-resource-section">
      <div class="d-flex align-items-center site-resource-icon-wrapper">
        <img src="../assets/images/directory.svg" alt="Handbook icon" class="me-3">
        <h2>Directory</h2>
      </div>
      <div class="site-resource-more-info">
        <p class="site-resource-info-text">Emergency, Departments & Services contact and phone numbers.</p>
        <a href="/core/core-directory" class="text-link">Browse Directory</a>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid d-flex align-items-center" style="background-color:#F5F5F5">
  <div class="container" style="padding:112px 0 112px 0px">
    <div class="row">
      <div class="col-md-6 col-sm-12 col-xs-12 justify-content-center align-items-center site-resource-section">
        <div class="">
          <h2 class="page-subheading">Safety On The Set</h2>
        </div>
        <div class="location-title">
          <span>Burbank</span>
        </div>
        <div class="site-resource-more-info">
          <p class="site-resource-info-text">
            Includes information about COVID-19, our Safety Program, Safety reports,
            various illnesses, Safety Pass and
            Safety Bulletins.
          </p>
          <a href="https://safetyontheset.com/" class="text-link" target="_blank">Visit the Safety On The Set website </a>
          <img src="../assets/images/link-icon.svg" alt="Safety On the Set">
        </div>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 justify-content-center align-items-center site-resource-section">
        <div class="">
          <h2 class="page-subheading">Food Services</h2>
        </div>
        <div class="location-title">
          <span>Burbank</span>
        </div>
        <div class="site-resource-more-info">
          <p class="site-resource-info-text">Menus for the Coffee Cart, the Cafeteria, Road Runner & Avon Grill.</p>
          <a href="https://food.wb.com/" class="text-link" target="_blank">Visit the Food Services website; order online </a>
          <img src="../assets/images/link-icon.svg" alt="Food Service">
        </div>
      </div>
    </div>
  </div>
</div>
<!-- center section starts -->
<ng-template #successTpl>
  <div class="success">{{addUserSuccess}}</div>
</ng-template>

<ng-template #dangerTpl>
  <div class="danger">{{addUserError}}</div>
</ng-template>
