<div class="page-details-section">
  <div class="email-notifications-form">
    <h1 class="text-center">{{emailNotification.emailNotification}}</h1>
    <form [formGroup]="emailNotificationForm" (ngSubmit)="onSave()">
      <div>
        <div class="form-field justify-content-between mb-4">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <h6 class="field-label">{{emailNotification.crditApplication}}</h6>
              <p class="field-description">
                {{emailNotification.applicationAndStatus}}
              </p>
            </div>
            <div class="common-toggle-button">
              <input type="checkbox" id="custom-toggle-1" class="common-toggle-input" id="flexSwitchCheckChecked"
                formControlName="applicationStatus">
              <label for="custom-toggle-1" class="common-toggle-label">
              </label>
            </div>
          </div>
        </div>
        <div class="form-field justify-content-between">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <p class="field-label"> {{emailNotification.company}}</p>
              <p class="field-description">
                {{emailNotification.companyStatus}}
              </p>
            </div>
            <div class="common-toggle-button">
              <input type="checkbox" id="custom-toggle-1" class="common-toggle-input" id="flexSwitchCheckChecked"
                formControlName="creditApplication">
              <label for="custom-toggle-1" class="common-toggle-label">
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-40">
        <button type="submit" class="common-btn-secondary btn"
          [disabled]="!isFormChanged ||  disableSaveButton">{{emailNotification.save}}</button>
      </div>
    </form>
  </div>
</div>