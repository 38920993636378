import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ACTION_MSG, COMPANY, ICONS_ACTION, ICONS_CASE, ICONS_RENDERED, MERGED_CELL_ICONS } from '../../constants/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { IconsService } from '../../services/icons/icons.service';
import { ToastService } from '../toast.service';
import { CompanyService } from 'src/app/feature/studio-portal/services/company.service';
import { AddProjectComponent } from 'src/app/feature/studio-portal/components/projects/add-project/add-project.component';
import { AddCompanyUserComponent } from '../add-company-user/add-company-user.component';

@Component({
  selector: 'app-merged-cell-icons',
  templateUrl: './merged-cell-icons.component.html',
  styleUrls: ['./merged-cell-icons.component.scss']
})
export class MergedCellIconsComponent implements ICellRendererAngularComp {
  @Input() status: string;
  @ViewChild('restrictDisableCompanyTpl', { static: false }) restrictDisableCompanyTpl!: TemplateRef<any>; // Template for danger toast
  @ViewChild('confirmationModal', { static: false }) confirmationModal!: TemplateRef<any>;
  @ViewChild('confirmationModalDisableCompany', { static: false }) confirmationModalDisableCompany!: TemplateRef<any>;
  modalTitle: string = '';
  iconsRenderer = ICONS_RENDERED;
  iconsAction = ICONS_ACTION;
  iconActionMsg = ACTION_MSG;
  iconCase = ICONS_CASE;
  statusContent= MERGED_CELL_ICONS;
  public params: any;
  modalReference: NgbModalRef; // Reference to the modal opened
  disableCompany: boolean; // Flag to disable a company
  companyType: any;
  companyName: any;
  companyId: any;
  permissionsData: any;
  userLoggedIn: any;
  disableIcon: boolean = false;
  company = COMPANY;
  gridData: any;
  companyUserMetaData: any;
  addUserSuccess: any;
  addUserError: any;
  showCompaniesIcon: boolean;
  showARCompaniesIcon:boolean;
  currentRoute: string;
  constructor(private router: Router, private modalService: NgbModal, private iconService: IconsService, private toastService: ToastService, private companyService: CompanyService) { }

  /**
   * agInit() - Method to initialize the cell renderer with the provided parameters.
   * @param params - Parameters passed by ag-Grid for this cell renderer (cell data, grid details, etc.)
   */
  agInit(params: any): void {
    this.currentRoute = this.router.url; // Get the current route
    this.params = params;
    this.status = params.data.status; 
    this.gridData = params.data;
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.permissionsData = { ...userProfile.roleAssigned };
    this.companyId = params.data.compid;
    this.companyName = params.data.companyName;
    this.companyType = params.data.companyType;
    this.companyUserMetaData = this.getCompanyUserMetaData(this.companyId);
    if (this.currentRoute.includes('/feature/ar-dashboard/company/companies')) {
      this.showARCompaniesIcon = !this.showARCompaniesIcon;
    }
  }

  /**
   * refresh() - Method to refresh the cell content. Always returns false, meaning no refresh is needed.
   * @returns boolean - Always returns false, indicating no refresh is needed.
   */
  refresh(): boolean {
    return false;
  }

  /**
   * getStatusClass() - Method to get the CSS class based on the status value
   * @param status - The status of the cell (e.g., 'DISABLED', 'ACTIVE')
   * @returns string - The corresponding CSS class for the status
   */
  getStatusClass(status: string): string {
    switch (status) {
      case this.statusContent.disabled:
        return 'status-account-tag disabled';
      case this.statusContent.active:
        return 'user-status-tag active account-user-common-status-cell mt-common-ag-grid-new';
      default:
        return 'common-mb-status-tag';
    }
  }

   /**
   * addProjectIcon() - Invokes the Add New Project modal.
   */
   addProjectIcon(): void {
    window.scrollTo(0, 0);
    let title = this.company?.addnewproject;
    let componentType = this.company?.studioComponentType;
    let portalType = this.company?.studioPortalType;
    let projectInfo = {};

    projectInfo = {
      company: {
        companyType: {
          companyType: this.companyType
        },
        companyName: this.companyName,
        companyId: this.companyId
      }
    }


    this.modalReference = this.modalService.open(AddProjectComponent, {
      windowClass: 'common-modal-xl', fullscreen: true
    });
    this.modalReference.componentInstance.modalTitle = title;
    this.modalReference.componentInstance.componentType = componentType;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.projectInfo = projectInfo;
  }

  /**
   * addUserIcon() - Invokes the Add User modal.
   */
  addUserIcon(): void {
    window.scrollTo(0, 0); // Scroll to top of the page
    let title = this.company?.adduser; // Get the title for the modal
    let metaData = this.companyUserMetaData;
    let componentType = this.company?.studioComponentType;
    let portalType = this.company?.studioPortalType;
    let modalType = this.company?.modalAdd;
    let userDetails = {
      company : this.companyName
    }
    this.modalReference = this.modalService.open(AddCompanyUserComponent, {
      windowClass: 'common-modal-xl ',
      fullscreen: true
    });
    this.modalReference.componentInstance.title = title;
    this.modalReference.componentInstance.metaData = metaData;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.modalType = modalType;
    this.modalReference.componentInstance.componentType = componentType;
    this.modalReference.componentInstance.userDetails = userDetails;
    this.modalReference.componentInstance.showSearchBarInput = false;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data.status == 'success') {
          this.addUserSuccess = data.message;
        } else {
          this.addUserError = data.message;
        }
      }
    });
  }

  /**
  *getCompanyUserMetaData() - getCompanyUserMetaData method gives list of project, department and other information that helps
  * to add the user in company.
  */

  getCompanyUserMetaData(companyId): void {
    this.companyService.getCompanyUsersMetadata(companyId).subscribe({
      next: (data: any) => {
        this.companyUserMetaData = data.companyUserMetaData;
      },
      error: (error: any) => {
      }
    })
  }
  iconInfo: string;
  openConfirmationModal(action: string): void {
    switch (action) {
      case this.iconCase.disableCompany:
        this.modalTitle = this.iconActionMsg.disableCompany;
        this.iconInfo = this.iconsAction.disableCompany;
        this.disableCompany = true;
       
        break;
      case this.iconCase.enableCompany:
        this.modalTitle = this.iconActionMsg.enableCompany;
        this.iconInfo = this.iconsAction.enableCompany;
        break;
    }
   
    const modalRef = this.modalService.open(this.confirmationModal, {
      windowClass: 'common-modal-center',
      centered: true,
      backdrop: 'static',
    });
  
   
  }

  handleAction(): void {
    switch (this.iconInfo) {
      case this.iconsAction.disableCompany:
        this.confirmDisable()
        break;
      case this.iconsAction.enableCompany:
        this.confirmEnable()
        break;
    }
  }
  
  /**
  * confirmDisable() - Method to pass value to company screen when disable icon click
  *
  */
  confirmDisable(): void {
    this.disableCompany = true;
    let data = {
      iconInfo: this.iconInfo,
      rowData: this.gridData,
      disableCompany: this.disableCompany
    };
    this.iconService.changeValue(data);
    this.modalService.dismissAll();
  }
  /**
   * confirmEnable() - Method to pass value to company screen when enable icon click
   *
   */
  confirmEnable(): void {
    this.disableCompany = false;
    let data = {
      iconInfo: this.iconInfo,
      rowData: this.gridData,
      disableCompany: this.disableCompany
    };
    this.iconService.changeValue(data);
    this.modalService.dismissAll();
  }
  /*
  redirectViewCompany()- Method to redirect to view company page on company name click
  */
  redirectViewCompany() : void {  
    this.router.navigate(['/feature/studio-dashboard/company'],{
      queryParams: {
        companyid: this.companyId,
      }
    });
    }
  /**
    * showSuccess() - Displays a success toast notification with custom content and options.
    * @param successTpl - The template reference containing the toast's content.
    */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.clear()
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }
  /**
 * showDanger() - Method to display a danger (error) toast message.
 * @param dangerTpl - The template reference for the danger message to display.
 */
showDanger(dangerActionTpl: any) {
  this.toastService.clear()
  this.toastService.show(dangerActionTpl, {
    classname: 'bg-danger text-light',
    delay: 3000,
  });
}
}
