import { DomLayoutType } from "ag-grid-community";

export enum FILEUPLOAD {
  MAX_SIZE = 1024 * 1024,
}

export const MESSAGES = {
  successUpload: 'File successfully uploaded',
  errorUpload: 'File upload failed. Please try again.',
  invalidFileType: 'Invalid file type. Allowed types: ',
  fileSizeExceed: 'File size exceeds ',
};

export const FileUploadConfig = {
  DNB_REPORT_HEADER: 'D&B REPORT',
  SELECTED_FILE_LABEL: 'Selected file: ',
};

export const PAGINATION_TEXT = {
  itemsperpage: 'Items per page',
  of: 'of',
};

export const maintenaceConst = [
  {
    mainteneceMsg:
      'The WB Theatrical Portal will be down for maintenance starting Friday March 29th from 3:00PM PDT until Sunday March 31st 12:00PM PDT.',
  },
  {
    mainteneceMsg:
      'If you need booking assistance, please email wbportalhelp@warnerbros.com',
  },
];

export const ADD_COMPANY_USER = {
  firstname: 'First Name',
  lastname: 'Last Name',
  email: 'Email',
  jobtitle: 'Job Title',
  optional: 'Optional Settings',
  companyadmin: 'Make the user a company admin',
  accountpay: 'User is an Accounts Payable user',
  verfication:
    ' A verification email will be sent to the new user after approval from WBSO.',
  project: 'Projects',
  active: 'Active',
  close: 'Closed',
  adduser: 'Add User',
  edit: 'Edit',
  department: 'Departments',
  permissions: 'Permissions',
  projectview: 'Project (View)',
  projectname: 'The Court - Season 1',
  fieldRequired: 'This field is required',
  firstNameInvalid: 'First name is not valid',
  lastNameInvalid: 'Last name is not valid',
  emailInvalid: 'Please enter a valid email address',
  jobTitleInvalid: 'Job title is inavlid',
  makeuser: 'Make the user a company admin',
  accountpayable: ' User is an Accounts Payable user',
};

export const ADD_PROJECT_USER = {
  department: 'Department Access',
  access: 'Access',
  section: 'Sections & Permissions',
  project: 'Project',
  credit: 'Credit Application',
  manageuser: 'Manage Users',
  canadd:
    'Can add, remove users to this project & manage their access and permissions',
  firstname: 'First Name',
  lastname: 'Last Name',
  email: 'Email',
  jobtitle: 'Job Title',
  optional: 'Optional Settings',
  makeuser: 'Make the user a company admin',
  accountpayable: ' User is an Accounts Payable user',
  verification:
    'A verification email will be sent to the new user after approval from WBSO.',
  projectuser: 'Add a User To',
  close: 'Close',
  fieldRequired: 'This field is required',
  firstNameInvalid: 'First name is not valid',
  lastNameInvalid: 'Last name is not valid',
  emailInvalid: 'Please enter a valid email address',
  jobTitleInvalid: 'Job title is inavlid',
  phoneRequired:'This field is required',
  phoneInvalid: 'This is an invalid phone number',
  minLength: 'Min length is 10 characters',
  addUserSuccessMsg: 'Project user added successfully',
  addProjectUserSuccess: 'PROJECT USER ADDED SUCCESSFULLY',
  addUserErrorMsg: 'ERROR CREATING THE USER',
  updateUserSuccess: 'USER STATUS UPDATED SUCCESSFULLY',
  updateErrorMessage: 'ERROR IN UPDATING THE USER',
  projectUserStatus:'Y',
  userId: 'User ID #'
};

export const SECTION_DATA = [
  {
    "id": 1,
    "name": "Project",
    "roleSection": "Project",
    "permissions": ["Can View", "Can Manage"]
  },
  {
    "id": 2,
    "name": "Credit Applications",
    "roleSection": "Credit Application"
  },
  {
    "id": 3,
    "name": "Manage Users",
    "roleSection": "Manage User"
  }
]


export const SECTION_DATA_VALUE = [
  {
  portaltype: 'customer',
  dbvalue : {
  projectView : 'customer_project view only',
  projectViewMange :'customer_project view and manage',
  creditViewMange :'customer_credit application view and manage',
  userViewMange :'customer_manage users view and manage'
  },
  viewText: 
  {
  projectView : "Project (View)",
  projectViewMange : "Project (View and Manage)",
  creditViewMange : "Credit (View and Manage)",
  userViewMange : "User (View and Manage)"
  }
  },
  {
  portaltype: 'studio',
  dbvalue : {
  projectView : 'studio_project view only',
  projectViewMange :'studio_project view and manage',
  creditViewMange :'studio_credit application view and manage',
  userViewMange :'studio_manage users view and manage'
  },
  viewText: 
  {
  projectView : "Project (View)",
  projectViewMange : "Project (View and Manage)",
  creditViewMange : "Credit (View and Manage)",
  userViewMange : "User (View and Manage)"
  }
  },
]

export const COMMON_AG_GRID_CONSTANTS = {
  items: 'Items per page',
};

export const COMMON_GRID_OPTIONS = {
  pagination: true,
  paginationPageSize: 10,
  domLayout: 'autoHeight' as DomLayoutType,
  defaultColDef: {
    flex: 1,
    minWidth: 100,
    filter: false,
    autoHeight: true,
  },
};

export const ADD_WB_USER_TITLE = 'Add A WB User';
export const ADD_CUSTOMER_USER_TITLE = 'Add A Customer User';
export const ADD_USER_TITLE = 'Add User';
export const SEARCH_USER_TEXT = 'Search User';
export const PLACEHOLDER_TEXT = 'Search user name or email address';
export const SEARCH_PLACEHOLDER = 'Search'; // Add this line
 export const ADD_WB_SEARCH_SUCCESS='WBSO added Successfully';
 export const ADD_WB_SEARCH_ERROR='WBSO added Successfully';

export const AR_SECTION_TITLES = {
  permissions: 'Permissions',
  access: 'ACCESS',
  finalReview: 'Final Review',
  arNumber: 'A/R Number',
  finalReviewDescription: 'Approve or Deny Credit Application',
  arNumberDescription: 'Can add and edit the A/R number'
};




export const ADD_EDIT_COMPNAY = {
  companytype: "Company Type",
  internal: "For Internal use only",
  companyname: "Production Company Name",
  name: "Name",
  legalname: "The Production Legal Name",
  billingaddress: "Billing Address",
  companyaddress: "Production Company Address",
  contacts: "Contacts",
  country: "Country",
  street: "Street",
  city: "City",
  state: "State",
  zipcode: "Zip Code",
  billing: "Use for Billing Address",
  firstname: "First Name",
  lastname: "Last Name",
  email: "Email",
  jobtitle: "Job Title",
  phone: "Phone",
  federal: "Federal Tax ID #",
  federallabel: "TIN",
  addcontact: "Add a Contact",
  removecontact: "Remove Contact",
  addcompany: "Add Company",
  postalcode:"Postal Code",
  province:'Province',
  stateinput:'State/Province/Region',
  editcompany:'Edit A Company',
  gotopage: 'gotopage',
  savechanges: 'Save Changes',
  discardchanges: 'Discard Changes'
}

export const ADD_EDIT_COMPNAY_DROPDOWN = [
  { id: 1, description: 'Projects' },
  { id: 2, description: 'Users' },
  { id: 3, description: 'Spaces & Resources' },
  { id: 4, description: 'Company' },
];

export const POP_UP_CELL_RENDERED = {
  moreActions: 'MORE ACTIONS',
  deactivateUser: 'Deactivate User',
  freezeAccount: 'Freeze Account',
  removeUser: 'Remove User',
};

export const ICONS_RENDERED = {
  areYouSure: 'Are you sure you want to delete?',
  ok: 'Ok',
  cancel: 'Cancel'
};
export const GO_TO_DROPDOWN = [
  { id: 1, description: "Company" },
  { id: 2, description: "Projects" },
  { id: 3, description: "Users" }
];

export const GO_TO_DROPDOWN_DEFAULT = {
  goTo : 'Go To…',
  activeProjects: 'ACTIVE PROJECTS'
}

export const COMPANY_TYPES = [
  { id: 1, description: 'Warner Affiliate' },
  { id: 2, description: 'Major Studio' },
  { id: 3, description: '3rd Party' },
  { id: 4, description: '3rd Party Commercial House' }
];
export const COMPANY = {
  companyname: "Sunshine Productions",
  companyid: 'COMPANY ID #',
  projects: 'Projects',
  companyaddress: 'Company Address',
  contactinfo: 'Contact Information',
  companyuser: 'Company Users',
  creditstatus: 'Company Status',
  adduser: 'Add a User',
  viewall: 'View All',
  valid: 'Valid Until 12/12/24',
  current: 'Current',
  editcompany: 'Edit Company',
  addcompany: 'Add a Company',
  portaltype: 'customer',
  modalType: 'edit',
  addProject: 'Add a Project',
  internaluse: 'FOR INTERNAL USE ONLY',
  companytype: 'Company Type',
  enablecompany: 'Enable Company',
  disablecompany: 'Disable Company',
  addcustomeruser: 'Add a Customer User',
  addproject: 'Add a Project',
  allcompanies: 'All companies',
  addnewproject: 'Add A New Project',
  companytitle: 'Company',
  modalAdd: 'add',
  studioComponentType: 'studio-company',
  studioPortalType: 'studio'

};
export const ADD_COMPNAY_PROJECT = {
  companyname: "Sunshine Productions",
  companyid: 'COMPANY ID #',
  projects: 'Projects',
  companyaddress: 'Company Address',
  contactinfo: 'Contact Information',
  companyuser: 'Company Users',
  creditstatus: 'Company Status',
  adduser: 'Add a User',
  viewall: 'View All',
  valid: 'Valid Until 12/12/24',
  current: 'Current',
  editcompany: 'Edit Company',
  addcompany: 'Add a Company',
  portaltype: 'customer',
  modalType: 'edit',
  addProject: 'Add a Project',
  internaluse: 'FOR INTERNAL USE ONLY',
  companytype: 'Company Type',
  enablecompany: 'Enable Company',
  disablecompany: 'Disable Company',
  addcustomeruser: 'Add a Customer User',
  addproject: 'Add a Project',
  allcompanies: 'All companies',
  addnewproject: 'Add A New Project',
  companytitle: 'Company',
  companyTitle: 'Company',
  changeCompany: 'Change Company',
  openCompany: 'Open Company',
  selectCompany: 'Select Company',
  projectName: 'Project Name',
  projectCompanyName: 'Project Company Name',
  projectDetails: 'Project Details',
  projectType: 'Project Type',
  anticipatedDates: 'Anticipated Project Dates',
  internal: 'For Internal Use Only',
  primaryType: 'Primary Payment Type',
  addProjectBtn: 'Add Project',
  addGoProjectBtn: 'Add & Go To Project',
  customertype: 'Customer Type',
  primaryPaymentType: 'Primary Payment Type'
};
export const COUNTRIES = [
  { "id": 1, "name": "Afghanistan" },
  { "id": 2, "name": "Åland Islands" },
  { "id": 3, "name": "Albania" },
  { "id": 4, "name": "Algeria" },
  { "id": 5, "name": "American Samoa" },
  { "id": 6, "name": "Andorra" },
  { "id": 7, "name": "Angola" },
  { "id": 8, "name": "Anguilla" },
  { "id": 9, "name": "Antarctica" },
  { "id": 10, "name": "Antigua and Barbuda" },
  { "id": 11, "name": "Argentina" },
  { "id": 12, "name": "Armenia" },
  { "id": 13, "name": "Aruba" },
  { "id": 14, "name": "Australia" },
  { "id": 15, "name": "Austria" },
  { "id": 16, "name": "Azerbaijan" },
  { "id": 17, "name": "Bahamas" },
  { "id": 18, "name": "Bahrain" },
  { "id": 19, "name": "Bangladesh" },
  { "id": 20, "name": "Barbados" },
  { "id": 21, "name": "Belarus" },
  { "id": 22, "name": "Belgium" },
  { "id": 23, "name": "Belize" },
  { "id": 24, "name": "Benin" },
  { "id": 25, "name": "Bermuda" },
  { "id": 26, "name": "Bhutan" },
  { "id": 27, "name": "Bolivia" },
  { "id": 28, "name": "Bosnia and Herzegovina" },
  { "id": 29, "name": "Botswana" },
  { "id": 30, "name": "Bouvet Island" },
  { "id": 31, "name": "Brazil" },
  { "id": 32, "name": "British Indian Ocean Territory" },
  { "id": 33, "name": "Brunei Darussalam" },
  { "id": 34, "name": "Bulgaria" },
  { "id": 35, "name": "Burkina Faso" },
  { "id": 36, "name": "Burundi" },
  { "id": 37, "name": "Cambodia" },
  { "id": 38, "name": "Cameroon" },
  { "id": 39, "name": "Canada" },
  { "id": 40, "name": "Cape Verde" },
  { "id": 41, "name": "Cayman Islands" },
  { "id": 42, "name": "Central African Republic" },
  { "id": 43, "name": "Chad" },
  { "id": 44, "name": "Chile" },
  { "id": 45, "name": "China" },
  { "id": 46, "name": "Christmas Island" },
  { "id": 47, "name": "Cocos (Keeling) Islands" },
  { "id": 48, "name": "Colombia" },
  { "id": 49, "name": "Comoros" },
  { "id": 50, "name": "Congo" },
  { "id": 51, "name": "Congo, The Democratic Republic of The" },
  { "id": 52, "name": "Cook Islands" },
  { "id": 53, "name": "Costa Rica" },
  { "id": 54, "name": "Cote D'ivoire" },
  { "id": 55, "name": "Croatia" },
  { "id": 56, "name": "Cuba" },
  { "id": 57, "name": "Cyprus" },
  { "id": 58, "name": "Czech Republic" },
  { "id": 59, "name": "Denmark" },
  { "id": 60, "name": "Djibouti" },
  { "id": 61, "name": "Dominica" },
  { "id": 62, "name": "Dominican Republic" },
  { "id": 63, "name": "Ecuador" },
  { "id": 64, "name": "Egypt" },
  { "id": 65, "name": "El Salvador" },
  { "id": 66, "name": "Equatorial Guinea" },
  { "id": 67, "name": "Eritrea" },
  { "id": 68, "name": "Estonia" },
  { "id": 69, "name": "Ethiopia" },
  { "id": 70, "name": "Falkland Islands (Malvinas)" },
  { "id": 71, "name": "Faroe Islands" },
  { "id": 72, "name": "Fiji" }
]
export const REGEX_CONSTANTS = {
  PHONE_NUMBER: /^[0-9+\-() \s]*$/,
  FEDERAL_TAX_ID: /^[0-9\- ]*$/,
  ALPHABETIC: /^[A-Za-z\s]*$/,
  NAME_VALIDATION: /^[A-Za-z]+(\s[A-Za-z]+)*$/,
  JOB_TITLE: /^[a-zA-Z0-9\s\-_.&@!]+$/,
  ALPHANUMERIC: /^[a-zA-Z0-9]*$/,
  NUMERIC: /^[0-9]+$/,
  NAMES: /^[A-Za-z]+$/,
  USZIPCODE: /^[0-9]{1,5}$/,
  UKCANPOSTAL: /^[A-Za-z0-9\s]{1,12}$/,
  GENPOSTAL: /^[A-Za-z0-9\s]{1,12}$/, 
  SEARCH_COMPANY_NUMBER:  /^\d+$/
};

export const MERGED_CELL_ICONS = {
  disabled: 'DISABLED',
  active: 'ACTIVE'
}

export const MULTISELECT = {
  apply: 'Apply',
  close: 'Close',
  placeholder: 'Select',
  typeaheadplaceholder: 'Search'
};

export const USER = {
  userName: 'User Name',
  profile: 'Profile',
  settings: 'Settings',
  emailNotifications: 'Email Notifications',
  profileTab: 'profile-tab',
  settingsTab: 'settings-tab',
  emailNotificationstab: 'email-notifications-tab',
   successfullMessage: "User Profile has been successfully updated",
  errorMessage: 'Error in updating the profile',
  warn:'Profile data could not be retrieved.',
  error:'Error fetching user profile',
  updateError: 'Error updating profile',
  invalidField: 'This Field is invalid'
};

export const SETTINGS = {
  settings: 'Settings',
  timeZone: 'TIME ZONE',
  validatorRequired: 'This is required filed',
  dateFormat: 'DATE FORMAT',
  calendarFormat: 'CALENDAR FORMAT',
  startSunday: '',
  startMonday: '',
  timeFormat: 'TIME FORMAT',
  temperatureUnit: 'PREFERRED TEMPERATURE UNIT',
  fahrenheit: '',
  celsius: '',
  save: 'Save',
  dateFormat1: '',
  dateFormat2: '',
  timeFormat1: '',
  timeFormat2: '',
  timeZoneDefault: '',
  calendarFormatDefault: '',
  fahrenheitDefault: '',
};

export const SETTINGS_DATA = {
  fahrenheit: 'Fahrenheit',
  time: '12h',
  dateFormat: 'm/d/yyyy',
  calendarFormat: 'starts on Sunday',
}

export const PROJECT_ERROR_MSG = {
  errorProjectUser: 'Error fetching project user',
}

export const PORTAL_TYPE = {
  studioPortal: 'studio',
  studioTypeAdd: 'addProjectUser',
  studioTypeEdit: 'updatProjectUser',
}

export const WBCONSTANT_DATA = {
  wbsoAR: 10,
  deptmartmentUser: 6,
  department:7,
  statusActiveInd:'Y',
  loginUserId: 1,
}

export const SEARCH_DATA = {
  searchUser: 'Search User',
  search: 'Search',
}

export const ICONS_ACTION = {
  freezeAccount: 'freeze-account',
  deActivate: 'deactivate-account',
  activate: 'activate-account',
  remove: 'remove-account',
  disableCompany: 'disable-company',
  enableCompany: 'enable-company'
}

export const ACTION_MSG = {
  freezeAccount:'Are you sure you want to test the account?',
  deActivate: 'Are you sure you want to deactivate the user?',
  activate: 'Are you sure you want to activate the user?',
  remove: 'Are you sure you want to remove the user?',
  disableCompany: 'Are you sure you want to disable the company?',
  enableCompany: 'Are you sure you want to enable the company?'
}

export const ICONS_CASE = {
  freeze: 'freeze',
  deActivate: 'deactivate',
  activate: 'activate',
  remove: 'remove',
  disableCompany: 'disable-company',
  enableCompany: 'enable-company'
}

export const FORM_VALIDATION = {
  phoneValidation: /^(?!.*\s{2})(?!\s)[0-9\+\-\(\)\s]*[0-9](?<!\s)$/,
}

export const COMPANY_SEARCH_MESSAGES ={
  invalidCompanyId: 'Invalid Company ID#',
  invalidCompanyName: 'Invalid Company Name',
}
