<div class="project-wrapper">
	<div class="container">
		<div class="row">
			<div class="comman-title-block px-0">
				<div class="comman-title-section">
					<h1>Projects</h1>
				</div>
				<div class="comman-filter-block">
					<div class="container">
					  <div class="row">
						<div class="col-lg-9 col-12 px-0">
							<div class="comman-filter-title-section">
								<p>Filters</p>
							  </div>
							<div class="comman-filter-section d-flex flex-wrap align-items-center gap-3">
								<!-- Multiselect Dropdowns -->
								<!--<ng-multiselect-dropdown class="custom-multiselect" [placeholder]="'Projects'" [settings]="dropdownSettings"
													 [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)">
	</ng-multiselect-dropdown>
	<ng-multiselect-dropdown class="custom-multiselect" [placeholder]="'Types'" [settings]="dropdownSettings"
													 [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)">
	</ng-multiselect-dropdown>
	<ng-multiselect-dropdown class="custom-multiselect" [placeholder]="'Status'" [settings]="dropdownSettings"
													 [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)">
	</ng-multiselect-dropdown>-->

								<app-ng-multiselect-dropdown [placeholder]="'Projects'"
																						 [settings]="defaultSettings"
																						 [data]="projects"
																						 (onSelect)="selectedProject($event)"></app-ng-multiselect-dropdown>
								<app-ng-multiselect-dropdown [placeholder]="'Types'"
																						 [settings]="defaultSettings"
																						 [data]="types"
																						 (onSelect)="selectedType($event)"></app-ng-multiselect-dropdown>
							</div>
						</div>
						<div class="col-lg-3 col-12 px-0">
							<div class="comman-filter-title-section">
								<p>Sort:</p>
							  </div>
						  <div class="filter-sort-block d-flex">
							<button class="btn common-btn-secondary">Most Recent</button>
							<span class="sort">A - Z</span>
						  </div>
						</div>
					  </div>
					</div>
				</div>
			<div class="project-list-wrapper px-0">
				<div class="row">
					<div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
						<div class="project-card-block">
							<div class="card">
								<img class="project-card-img img-fluid" src="../../assets/images/project-image1.png"
									alt="blank-image">
								<div class="card-body">
									<h2 class="project-name">Project name</h2>
									<p class="project-movie-type">Season #</p>
									<div class="project-date-status-block">
										<p class=" project-status">1/1/24 - Present</p>
										<span class="project-status-tag  active-tag-green">Active</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
						<div class="project-card-block">
							<div class="card">
								<img class="project-card-img img-fluid" src="../../assets/images/project-image2.png"
									alt="blank-image">
								<div class="card-body">
									<h2 class="project-name">Project name</h2>
									<p class="project-movie-type">Season #</p>
									<div class="project-date-status-block ">
										<p class=" project-status">1/1/24 - Present</p> <span
											class="project-status-tag  closed-tag-black">Closed</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
						<div class="project-card-block">
							<div class="card">
								<img class="project-card-img img-fluid" src="../../assets/images/project-image2.png"
									alt="blank-image">
								<div class="card-body">
									<h2 class="project-name">Project name</h2>
									<p class="project-movie-type">Season #</p>
									<div class="project-date-status-block ">
										<p class=" project-status">1/1/24 - Present</p> <span
											class="project-status-tag  closed-tag-black">Closed</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
						<div class="project-card-block">
							<div class="card">
								<img class="project-card-img img-fluid" src="../../assets/images/project-image1.png"
									alt="blank-image">
								<div class="card-body">
									<h2 class="project-name">Project name</h2>
									<p class="project-movie-type">Season #</p>
									<div class="project-date-status-block ">
										<p class=" project-status">1/1/24 - Present</p> <span
											class="project-status-tag  active-tag-green">Active</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
						<div class="project-card-block">
							<div class="card">
								<img class="project-card-img img-fluid" src="../../assets/images/project-image1.png"
									alt="blank-image">
								<div class="card-body">
									<h2 class="project-name">Project name</h2>
									<p class="project-movie-type">Season #</p>
									<div class="project-date-status-block ">
										<p class=" project-status">1/1/24 - Present</p> <span
											class="project-status-tag  closed-tag-black">Closed</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
						<div class="project-card-block">
							<div class="card">
								<img class="project-card-img img-fluid" src="../../assets/images/project-image2.png"
									alt="blank-image">
								<div class="card-body">
									<h2 class="project-name">Project name</h2>
									<p class="project-movie-type">Season #</p>
									<div class="project-date-status-block ">
										<p class=" project-status">1/1/24 - Present</p> <span
											class="project-status-tag  active-tag-green">Active</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
						<div class="project-card-block">
							<div class="card">
								<img class="project-card-img img-fluid" src="../../assets/images/project-image1.png"
									alt="blank-image">
								<div class="card-body">
									<h2 class="project-name">Project name</h2>
									<p class="project-movie-type">Season #</p>
									<div class="project-date-status-block ">
										<p class=" project-status">1/1/24 - Present</p> <span
											class="project-status-tag  closed-tag-black">Closed</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4">
						<div class="project-card-block">
							<div class="card">
								<img class="project-card-img img-fluid" src="../../assets/images/project-image2.png"
									alt="blank-image">
								<div class="card-body">
									<h2 class="project-name">Project name</h2>
									<p class="project-movie-type">Season #</p>
									<div class="project-date-status-block ">
										<p class=" project-status">1/1/24 - Present</p> <span
											class="project-status-tag  closed-tag-black">Closed</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="common-pagination-section">
				<div class="common-pagination-controls">
				  <div class="items-per-page">
					<label for="page-size" class="per-page">Items per page:</label>
					<select id="page-size" >
					  <option >1</option>
					  <option >1</option>
					</select>
				  </div>
				  <div class="common-pagination-navigation-block ">
					  <div class="pagination-no align-self-center">1 - 2 of 5
						  <span class="paginator-arrow-icons align-self-center">
							 <a class="arrow-1"> <img src="../../assets/images/arrow-left.svg" alt="icon" /></a>
							 <a class="arrow-2"><img src="../../assets/images/arrow-right.svg" alt="icon" /></a>
						  </span>
					  </div>
				  </div>
				</div>
		  </div>
		</div>
	</div>
</div>
