export const projectDetails = {
    projectImg: "../../assets/images/project-image2.png",
    editImg: "../../../../assets/images/image-edit-icon.svg.svg"
}

export const projectData = [
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12341,
        projectType: 'Feature',
        projectName: 'Breaking Bad',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 1',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '1/1/24',
        projectEndDate: '12/12/24',
        status: 'CLOSED',
        createdDate: '02/03/01',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12345,
        projectType: 'Television',
        projectName: 'Stranger Things',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 2',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '1/1/13',
        projectEndDate: '12/12/26',
        status: 'CLOSED',
        createdDate: '03/04/01',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12348,
        projectType: 'Commercial',
        projectName: 'The Witcher',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 3',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '1/1/14',
        projectEndDate: '12/12/26',
        status: 'CLOSED',
        createdDate: '04/05/01',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12349,
        projectType: 'Still shot',
        projectName: 'Game of Thrones',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 4',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '1/1/15',
        projectEndDate: '12/12/26',
        status: 'CLOSED',
        createdDate: '05/06/01',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12351,
        projectType: 'Still shot',
        projectName: 'The Crown',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 5',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '1/1/16',
        projectEndDate: '12/12/26',
        status: 'ACTIVE',
        createdDate: '06/07/01',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12356,
        projectType: 'Commercial',
        projectName: 'The Mandalorian',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 6',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '1/1/17',
        projectEndDate: '12/12/26',
        status: 'CLOSED',
        createdDate: '07/08/01',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12367,
        projectType: 'Commercial',
        projectName: 'The Boys',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 7',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '2/1/17',
        projectEndDate: '12/12/26',
        status: 'CLOSED',
        createdDate: '08/09/01',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12368,
        projectType: 'Television',
        projectName: 'Westworld',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 8',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '3/1/17',
        projectEndDate: '12/12/26',
        status: 'CLOSED',
        createdDate: '09/10/01',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12369,
        projectType: 'Feature',
        projectName: 'Narcos',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 9',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '11/2/16',
        projectEndDate: '12/12/26',
        status: 'CLOSED',
        createdDate: '10/11/01',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12380,
        projectType: 'Feature',
        projectName: 'Black Mirror',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 10',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '23/5/10',
        projectEndDate: '12/12/26',
        status: 'ACTIVE',
        createdDate: '11/12/01',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12401,
        projectType: 'Television',
        projectName: 'The Umbrella Academy',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 11',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '9/11/06',
        projectEndDate: '12/12/26',
        status: 'CLOSED',
        createdDate: '12/01/02',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12402,
        projectType: 'Feature',
        projectName: 'House of Cards',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 12',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '15/7/19',
        projectEndDate: '12/12/26',
        status: 'ACTIVE',
        createdDate: '13/02/02',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12403,
        projectType: 'Feature',
        projectName: 'Mindhunter',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 13',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '22/01/98',
        projectEndDate: '12/12/26',
        status: 'ACTIVE',
        createdDate: '14/03/02',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12404,
        projectType: 'Feature',
        projectName: 'The Handmaid\'s Tale',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 14',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '26/05/95',
        projectEndDate: '12/12/26',
        status: 'CLOSED',
        createdDate: '15/04/02',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12405,
        projectType: 'Television',
        projectName: 'Better Call Saul',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 15',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '16/08/98',
        projectEndDate: '12/12/26',
        status: 'CLOSED',
        createdDate: '16/05/02',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12406,
        projectType: 'Feature',
        projectName: 'Sherlock',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 16',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '13/1/22',
        projectEndDate: '12/12/26',
        status: 'CLOSED',
        createdDate: '17/06/02',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12407,
        projectType: 'Feature',
        projectName: 'Fargo',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 17',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '23/1/24',
        projectEndDate: '12/12/26',
        status: 'ACTIVE',
        createdDate: '18/07/02',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12408,
        projectType: 'Feature',
        projectName: 'Peaky Blinders',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 18',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '30/1/24',
        projectEndDate: '12/12/26',
        status: 'ACTIVE',
        createdDate: '19/08/02',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12409,
        projectType: 'Feature',
        projectName: 'The Walking Dead',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 19',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '24/1/24',
        projectEndDate: '12/12/26',
        status: 'ACTIVE',
        createdDate: '20/09/02',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12410,
        projectType: 'Television',
        projectName: 'Ozark',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 20',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '19/9/24',
        projectEndDate: '12/12/26',
        status: 'ACTIVE',
        createdDate: '21/10/02',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12411,
        projectType: 'Feature',
        projectName: 'Succession',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 21',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '25/4/25',
        projectEndDate: '12/12/26',
        status: 'ACTIVE',
        createdDate: '22/11/02',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12412,
        projectType: 'Television',
        projectName: 'True Detective',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 22',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '1/2/18',
        projectEndDate: '15/7/23',
        status: 'CLOSED',
        createdDate: '23/12/02',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12413,
        projectType: 'Commercial',
        projectName: 'The Wire',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 23',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '5/3/19',
        projectEndDate: '22/8/26',
        status: 'ACTIVE',
        createdDate: '24/01/03',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12414,
        projectType: 'Television',
        projectName: 'Chernobyl',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 24',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '9/4/17',
        projectEndDate: '16/10/26',
        status: 'ACTIVE',
        createdDate: '25/02/03',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12415,
        projectType: 'Feature',
        projectName: 'The Queen\'s Gambit',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 25',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '15/5/18',
        projectEndDate: '20/11/26',
        status: 'ACTIVE',
        createdDate: '26/03/03',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12416,
        projectType: 'Feature',
        projectName: 'Breaking Bad',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 26',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '20/6/19',
        projectEndDate: '25/12/26',
        status: 'ACTIVE',
        createdDate: '27/04/03',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12417,
        projectType: 'Television',
        projectName: 'Stranger Things',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 27',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '25/7/20',
        projectEndDate: '29/12/26',
        status: 'ACTIVE',
        createdDate: '28/05/03',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12418,
        projectType: 'Commercial',
        projectName: 'The Witcher',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 28',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '30/8/21',
        projectEndDate: '3/1/27',
        status: 'ACTIVE',
        createdDate: '29/06/03',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12419,
        projectType: 'Television',
        projectName: 'Game of Thrones',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 29',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '4/9/22',
        projectEndDate: '8/1/27',
        status: 'ACTIVE',
        createdDate: '30/07/03',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12420,
        projectType: 'Still shot',
        projectName: 'The Crown',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 30',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '9/10/23',
        projectEndDate: '14/1/27',
        status: 'ACTIVE',
        createdDate: '31/08/03',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12421,
        projectType: 'Feature',
        projectName: 'The Mandalorian',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 31',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '14/11/24',
        projectEndDate: '19/1/27',
        status: 'ACTIVE',
        createdDate: '30/09/03',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12422,
        projectType: 'Commercial',
        projectName: 'The Boys',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 32',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '19/12/25',
        projectEndDate: '24/1/27',
        status: 'ACTIVE',
        createdDate: '29/10/03',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12423,
        projectType: 'Television',
        projectName: 'Westworld',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 33',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '24/1/26',
        projectEndDate: '29/1/27',
        status: 'ACTIVE',
        createdDate: '28/11/03',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12424,
        projectType: 'Feature',
        projectName: 'Narcos',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 34',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '29/2/27',
        projectEndDate: '5/2/28',
        status: 'ACTIVE',
        createdDate: '27/12/03',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12425,
        projectType: 'Feature',
        projectName: 'Black Mirror',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 35',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '4/3/27',
        projectEndDate: '10/2/28',
        status: 'ACTIVE',
        createdDate: '26/01/04',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12426,
        projectType: 'Television',
        projectName: 'The Umbrella Academy',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 36',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '9/4/27',
        projectEndDate: '14/3/28',
        status: 'ACTIVE',
        createdDate: '25/02/04',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12427,
        projectType: 'Television',
        projectName: 'House of Cards',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 37',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '14/5/28',
        projectEndDate: '19/4/29',
        status: 'ACTIVE',
        createdDate: '24/03/04',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12428,
        projectType: 'Feature',
        projectName: 'Mindhunter',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 38',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '19/6/29',
        projectEndDate: '24/5/30',
        status: 'ACTIVE',
        createdDate: '13/04/04',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12429,
        projectType: 'Television',
        projectName: 'The Handmaid\'s Tale',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 39',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '24/7/30',
        projectEndDate: '29/6/31',
        status: 'ACTIVE',
        createdDate: '12/05/04',
        arNumber: '123456'
    },
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 12430,
        projectType: 'Television',
        projectName: 'Better Call Saul',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 40',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '29/8/31',
        projectEndDate: '4/8/32',
        status: 'ACTIVE',
        createdDate: '17/06/04',
        arNumber: '123456'
    },
    {
        imgSrc: '../../../../../assets/images/project-image2.png',
        projectId: 12431,
        projectType: 'Commercial',
        projectName: 'Sherlock',
        customer: '3RD PARTY',
        primaryPay: 'A/R',
        season: 'Season 41',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '3/9/32',
        projectEndDate: '8/8/33',
        status: 'ACTIVE',
        createdDate: '03/07/04',
        arNumber: '123456'
    }
];