<div class="common-mobile-section-companies">
    <div class="common-split-cell-data-1 common-cell-data-bold ellipsis-email" (click)="redirectViewCompany()">{{ params.data.companyName }}</div>
      <div class="common-split-cell-data-2">
        <div class="custom-dropdown">
          <select class="custom-select" (change)="onChange($event)">
            <option value="" disabled selected hidden class="dropdown-option">{{go_to_dropdown_default.goTo}}</option>
            <option *ngFor="let option of go_to_dropdown"  [value]='option.description' class="dropdown-option">{{option.description}}</option>
            <ng-container *ngIf="activeProjects?.length != 0">
              <option value="" disabled class="dropdown-option">{{go_to_dropdown_default.activeProjects}}:</option>
              <option *ngFor="let item of activeProjects" [value]='item.projectName' class="dropdown-option">{{item.projectName}}</option>
            </ng-container>
          </select>
      </div>    
    </div>
</div>