import { Component } from '@angular/core';

@Component({
  selector: 'app-studio-final-cred-app-form',
  templateUrl: './studio-final-cred-app-form.component.html',
  styleUrls: ['./studio-final-cred-app-form.component.scss']
})
export class StudioFinalCredAppFormComponent {

}
