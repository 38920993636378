import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-handbook-main',
  templateUrl: './handbook-main.component.html',
  styleUrls: ['./handbook-main.component.scss']
})
export class HandbookMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
