import {
  Component,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ATTACH_QUOTES_CA } from 'mockData/development/studio-send-credit-application';
import { STUDIO_CREDIT_APPLICATION } from 'src/app/feature/studio-portal/constants/studio-portal.constant';
import { ICONS_RENDERED } from 'src/app/shared/constants/common';
import { ProjectsCreditApplicationService } from 'src/app/feature/studio-portal/services/projects-credit-application.service';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import _ from 'lodash';

@Component({
  selector: 'app-attach-quotes',
  templateUrl: './attach-quotes.component.html',
  styleUrls: ['./attach-quotes.component.scss'],
})
export class AttachQuotesComponent {
  @ViewChild('fileInput') fileInput!: ElementRef; // Reference to the file input element
  @ViewChild('WarningMsgModal', { static: false })
  WarningMsgModal!: TemplateRef<any>;
  @Input() departmentDetails;
  creditApplicationConstants = STUDIO_CREDIT_APPLICATION;
  iconsRenderer = ICONS_RENDERED;
  attachQuotes = ATTACH_QUOTES_CA;
  isFileOver = false;
  files: any[] = [];
  quotesForm: FormGroup;
  fileError = '';

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private http: HttpClient,
    private uploadQuoteService: ProjectsCreditApplicationService
  ) {}

  ngOnInit(): void {
    this.quotesForm = new FormGroup({
      quotesComment: new FormControl('', Validators.required),
      files: new FormArray([]),
    });
  }

  /**
   * filesFormArray() - Getter for accessing the 'files' FormArray within the quotesForm.
   */
  get filesFormArray(): FormArray {
    return this.quotesForm.get('files') as FormArray;
  }

  /**
   * onDragOver() - onDragOver method used to drag the mouse
   */
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = true;
  }

  /**
   * onDragLeave() - onDragLeave method used to leave the mouse
   */
  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = false;
  }

  /**
   * onDrop() - onDrop method used to drop the the files
   */
  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = false;
    if (event.dataTransfer?.files) {
      this.addFiles(event.dataTransfer.files);
    }
  }

  /**
   * onFileSelect() - onFileSelect method used to select the the file
   */
  onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.addFiles(input.files);
    }
    this.resetFileInput(); // Reset input after handling the selection
  }

  /**
   * addFiles() - addFiles method used to add the the file
   */
  addFiles(files: FileList): void {
    _.forEach(files, (file) => {
      this.files.push({
        file,
        departmentId: '',
        quotesValue: '',
        error: '',
      });
  
      const fileFormGroup = new FormGroup({
        departmentId: new FormControl('', Validators.required),
        quotesValue: new FormControl('', Validators.required),
      });
  
      this.filesFormArray.push(fileFormGroup);
    });
  }
  

  /**
   * removeFile() - Remove the file which was selected in browse
   */
  removeFile(file: any, index: number): void {
    this.files.splice(index, 1);
    this.filesFormArray.removeAt(index);
  }

  /**
   * resetFileInput() - Reset the file input to allow re-uploading of the same files
   */
  resetFileInput(): void {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  /**
   * openWarningModal() - Method to open the warning modal
   */
  openWarningModal(): void {
    this.modalService.open(this.WarningMsgModal, {
      windowClass: 'common-modal-center',
      centered: true,
      backdrop: 'static',
    });
  }

  /**
   * closeModal() - Method to close the warning modal
   */
  closeModal(): void {
    this.activeModal.close();
  }

  /**
   * viewFile() - Method to view the file
   */
  viewFile(file: any): void {
    const url = URL.createObjectURL(file.file);
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
    URL.revokeObjectURL(url);
  }

  /**
   * uploadFiles() - Method to upload files
   */
  uploadFiles(): void {
    if (this.quotesForm.invalid) {
      return;
    }
    const formData = new FormData();
    const caQuotes = [];
  
    _.forEach(this.files, (fileObj, index) => {
      const file = fileObj.file;
      const newFileName = `file_${index + 1}.${file.name.split('.').pop()}`;
      const newFile = new File([file], newFileName, { type: file.type });
      formData.append('file', newFile);
  
      caQuotes.push({
        fileSequenceNo: index + 1,
        fileName: newFileName,
        quotesValue: this.filesFormArray.at(index).get('quotesValue').value,
        departmentId: this.filesFormArray.at(index).get('departmentId').value,
      });
    });
  
    const data = {
      caSeqId: 61,
      caNumber: 61,
      caQuotes: caQuotes,
      quotesComment: this.quotesForm.get('quotesComment').value,
    };
  
    formData.append('data', JSON.stringify(data));
  
    this.uploadQuoteService.attachQuotes(formData).subscribe({
      next: (response) => {
        this.activeModal.close();
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
  
}
