<button data-bs-toggle="modal" data-bs-target="#reqArUploadModal">Request AR# (Upload a Purchase Order)</button>
<button data-bs-toggle="modal" data-bs-target="#crediARRequest">Request AR# (Select Department)</button>
<!-- For Request AR# (Upload a Purchase Order) -->
<!-- common modal section-->
<div class="modal fade" id="crediARRequest" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
    <div class="modal-dialog req-ar-modal">
        <div class="modal-content common-modal-xl">
            <div class="modal-body justify-content-center align-items-center">
                <form class="mx-3">
                    <h3 class="form-section-header cred-req-ar-margin mb-4 mt-0">
                        Request A/R #
                    </h3>
                    <h3 class="form-section-header custom-sub-header mt-0">SELECT DEPARTMENTS</h3>
                    <div class="row">
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 custom-floating-label">
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-1"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-1" class="common-checkbox-label">
                            Construction Services
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-2"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-2" class="common-checkbox-label">
                            Facilities Maintenance
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-3"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-3" class="common-checkbox-label">
                            Food Services
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-4"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-4" class="common-checkbox-label">
                            Motion Picture Imaging
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-5"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-5" class="common-checkbox-label">
                            Photo Lab
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-6"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-6" class="common-checkbox-label">
                            Special Events
                          </label>
                        </div>
                        <div class="common-checkbox-button ">
                          <input
                            type="checkbox"
                            id="custom-checkbox-7"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-7" class="common-checkbox-label">
                            Transportation
                          </label>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 custom-floating-label">
                        <div class="common-checkbox-button  mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-8"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-8" class="common-checkbox-label">
                            Costume
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-9"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-9" class="common-checkbox-label">
                            Fire & Security
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-10"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-10" class="common-checkbox-label">
                            Mail Services
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-11"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-11" class="common-checkbox-label">
                            Office & Production
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-12"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-12" class="common-checkbox-label">
                            Post Production Sound
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-13"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-13" class="common-checkbox-label">
                            Staff Shop
                          </label>
                        </div>
                        <div class="common-checkbox-button">
                          <input
                            type="checkbox"
                            id="custom-checkbox-14"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-14" class="common-checkbox-label">
                            Utilities Services
                          </label>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 custom-floating-label">
                        <div class="common-checkbox-button  mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-15"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-15" class="common-checkbox-label">
                            Design Studio
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-16"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-16" class="common-checkbox-label">
                            First Aid
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-17"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-17" class="common-checkbox-label">
                            Metal Shop
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-18"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-18" class="common-checkbox-label">
                            Parking
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-19"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-19" class="common-checkbox-label">
                            Property
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-20"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-20" class="common-checkbox-label">
                            Studio Operations
                          </label>
                        </div>
                      </div>
                    </div>
                </form>
                <div class="d-flex justify-content-end gap-2 cust-reqar-action-btn">
                    <button type="button" class="btn common-btn-light">Cancel</button>
                    <button type="button" class="btn common-btn-secondary">Next Upload PO & COI</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--common modal section ends-->
<!-- Request AR# (Upload a Purchase Order) -->

<!-- Request AR# (Select Department)  -->
<!-- common modal section-->
<div class="modal fade" id="reqArUploadModal" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
    <div class="modal-dialog reqarupload-modal">
        <div class="modal-content common-modal-xl">
            <div class="modal-body file-upload-modal-body justify-content-center align-items-center">
                <form class="mx-3">
                    <div class="upload-purchase-wrapper">
                        <h3 class="form-section-header cred-req-ar-margin mb-4 mt-0">
                            Request A/R #
                          </h3>
                          <h3 class="form-section-header custom-sub-header mt-0">Upoload a Purchase Order</h3>
                          <div class="cm-main-container">
                            <div class="row">
                              <div class="col-md-12 custom-floating-label my-4">
                                    <div class="file-drop-area mt-3" [ngClass]="{ 'file-over': isFileOver }"
                                        (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
                                        <img class="folder-icon" (click)="fileInput.click()"
                                            src="../../../../../assets/images/folder-icon.svg" alt="folder icon">
                                        <h3 class="mb-2 drag-n-drop">Drag and Drop your file here, or <a class="browse-file-link"
                                                (click)="fileInput.click()">browse</a></h3>
                                        <input type="file" multiple class="d-none" (change)="onFileSelect($event)" #fileInput>
                                    </div>
                                    <!-- Display list of selected files -->
                                    <ul *ngIf="files.length > 0" class="list-group mt-3 uploaded-files-section">
                                        <li *ngFor="let file of files; let i = index" class="list-group-item px-0">
                                            <div class="d-flex align-items-center">
                                                <div class="col-auto icon-col">
                                                    <img src="../../../../../assets/images/pdf-icon.svg" alt="pdf icon"
                                                        class="file-icon">
                                                </div>
                                                <div class="col ps-3 pe-2">
                                                    <span [title]="file.name" class="file-name">{{ file.name }}</span>
                                                    <!--<ngb-progressbar class="file-progress-bar" type="success" textType="white"
                                                        [value]="100" [showValue]="false"></ngb-progressbar>-->
                                                </div>
                                                <div class="col-auto close-icon-col px-0">
                                                    <i class="bi bi-x remove-btn" (click)="removeFile(i)"></i>
                                                    <i class="bi bi-eye remove-btn" (click)="removeFile(i)"></i>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="justify-content-end text-end px-0 my-4">
                                        <div class="" *ngIf="files.length > 0">
                                            <div class="custom-floating-label px-0">
                                                <label for="details1" class="form-label">Add a Comment</label>
                                                <textarea formControlName="details" class="form-control common-text-area"
                                                    placeholder="Waiting for a few more quotes from PPCS and Sound Stages"
                                                    id="details1" rows="3"></textarea>
                                            </div>
                                        </div>
                                    </div>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="upload-cert-insur-wrapper">
                          <h3 class="form-section-header custom-sub-header mt-0">Upload a certificate of Insurance:</h3>
                          <div class="cm-main-container">
                            <div class="row">
                              <div class="col-md-12 custom-floating-label my-4">
                                    <div class="file-drop-area mt-3" [ngClass]="{ 'file-over': isFileOver }"
                                        (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
                                        <img class="folder-icon" (click)="fileInput.click()"
                                            src="../../../../../assets/images/folder-icon.svg" alt="folder icon">
                                        <h3 class="mb-2 drag-n-drop">Drag and Drop your file here, or <a class="browse-file-link"
                                                (click)="fileInput.click()">browse</a></h3>
                                        <input type="file" multiple class="d-none" (change)="onFileSelect($event)" #fileInput>
                                    </div>
                                    <!-- Display list of selected files -->
                                    <ul *ngIf="files.length > 0" class="list-group mt-3 uploaded-files-section">
                                        <li *ngFor="let file of files; let i = index" class="list-group-item px-0">
                                            <div class="d-flex align-items-center">
                                                <div class="col-auto icon-col">
                                                    <img src="../../../../../assets/images/pdf-icon.svg" alt="pdf icon"
                                                        class="file-icon">
                                                </div>
                                                <div class="col ps-3 pe-2">
                                                    <span [title]="file.name" class="file-name">{{ file.name }}</span>
                                                   <!--<ngb-progressbar class="file-progress-bar" type="success" textType="white"
                                                        [value]="100" [showValue]="false"></ngb-progressbar>-->
                                                </div>
                                                <div class="col-auto close-icon-col px-0">
                                                    <i class="bi bi-x remove-btn" (click)="removeFile(i)"></i>
                                                    <i class="bi bi-eye remove-btn" (click)="removeFile(i)"></i>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="justify-content-end text-end px-0 my-4">
                                        <div class="" *ngIf="files.length > 0">
                                            <div class="custom-floating-label px-0">
                                                <label for="details1" class="form-label">Add a Comment</label>
                                                <textarea formControlName="details" class="form-control common-text-area"
                                                    placeholder="Waiting for a few more quotes from PPCS and Sound Stages"
                                                    id="details1" rows="3"></textarea>
                                            </div>
                                        </div>
                                    </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="d-flex gap-2 cust-sendar-action-btn">
                    <div class="left-btn">
                        <a class="text-link"><img class="back-img" src="../../assets/images/blue-left-icon.svg" alt="Back" /> back </a>
                    </div>  
                    <div class="right-btn">
                        <button type="button" class="btn common-btn-light">Cancel</button>
                        <button type="button" class="btn common-btn-secondary">Send A/R # Request</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--common modal section ends-->
<!-- Request AR# (Select Department) -->
