import { Component, HostListener } from '@angular/core';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-account-user',
  templateUrl: './account-user.component.html',
  styleUrls: ['./account-user.component.scss']
})
export class AccountUserComponent {
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  public dropdownSettings: IDropdownSettings = {};
  ngOnInit() {
    this.dropdownList = [
      { item_id: 1, item_text: 'Project Name' },
      { item_id: 2, item_text: 'Project Name' },
      { item_id: 3, item_text: 'Project Name' },
      { item_id: 4, item_text: 'Project Name' },
      { item_id: 5, item_text: 'Project Name' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      showSelectedItemsAtTop: false
    };
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }


  gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'autoHeight',
    defaultColDef: {
      flex: 1,
      sortable:true,
      filter:true
    },
    rowHeight: 80 ,
  };

  columnDefs: ColDef[] = [
    {
      headerName: 'USER',
      field: 'user',
      autoHeight:true,
      cellRenderer:(params: ICellRendererParams) => {
        const fullName = params.data.fullName;
        const email = params.data.email;
        const user =  params.data.requestType;
        return `<span class="common-split-cell-section">
                  <span class="common-split-cell-data-1">${fullName}</span>
                  <span class="common-split-cell-data-2">${email}</span>
                  <span class="common-split-cell-data-3">${user}</span>
                </span>`;
      },
      minWidth: 277,
      flex:2.7
    },
    {
      headerName: 'STATUS',
      field: 'status',
      cellRenderer: this.statusCellRenderer.bind(this),
      minWidth: 135,
      flex: 1.5,
    },
    { 
      headerName: 'LAST LOGIN', 
      field: 'activity',
      cellRenderer: (params: ICellRendererParams) => {
        const date = params.data.updatedDate;
        const additionalTime = params.data.additionalTime;
        return `<span class="common-split-cell-section">
                  <span class="common-split-cell-data-default-font common-split-cell-data-1">last:${date}</span>
                  <span class="common-split-cell-data-2">${additionalTime}</span>
                </span>`;
      },
      minWidth:180,flex:1.2,
    },
    { headerName: 'JOB TITLE', field: 'jobTitle', flex: 1.7, cellClass:'common-cell-data-bold',minWidth:175 },
    { headerName: 'ACTIVE PROJECTS', field: 'activeProjects', cellClass:'common-cell-data-mt account-user-active-project-cell',minWidth:150 ,flex:2},
    {field:"icons",
      headerName:"",
      cellRenderer: () => {
        return `<div class="icon-container"> 
          <span class="icon-hover">
            <img src="/assets/images/account-freezed-icon.svg"/>
          </span>
          <span class="icon-hover">
            <img src="/assets/images/user-deactivate-icon.svg"/>
          </span>
          <span class="icon-hover">
            <img src="/assets/images/user-remove-icon.svg"/>
          </span>
        </div>`;
      },
     filter:false,
      minWidth:120,}
  ];
  rowData = [
    { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court',},
    { fullName: 'Colette Alfaro', email: 'eryamille@sunshineproductions.com',requestType:"", status: 'DEACTIVATED', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
    { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"", status: 'DEACTIVATED', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
    { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
    { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
    { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"", status: 'DEACTIVATED', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
    { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
    { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
    { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"account admin", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
    { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"", status: 'DEACTIVATED', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
    { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
    { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
    { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  ];
  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.value);
    return `
      <div class="status-container">
        <div class="request-status-tag ${statusClass}">${params.value}</div>
      </div>
    `;
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'ACTIVE':
        return 'user-status-tag active account-user-common-status-cell mt-common-ag-grid';
      case 'DEACTIVATED':
        return 'user-status-tag deactivated account-user-common-status-cell mt-common-ag-grid';
      default:
        return '';
    }
  }
  gridApi!: GridApi;
  pageSizeOptions: number[] = [10, 20, 50];
  startRow: number = 1;
  endRow: number = 10;
  totalRows: number = this.rowData.length;
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.updatePagination();
  }
  onPageSizeChanged(event: any) {
    const value = event.target.value;
    this.gridApi.paginationSetPageSize(Number(value));
    this.updatePagination();
  }
  onBtNext() {
    this.gridApi.paginationGoToNextPage();
    this.updatePagination();
  }
  onBtPrevious() {
    this.gridApi.paginationGoToPreviousPage();
    this.updatePagination();
  }
  updatePagination() {
    if (this.gridApi) {
      const currentPage = this.gridApi.paginationGetCurrentPage();
      const pageSize = this.gridApi.paginationGetPageSize();
      this.startRow = currentPage * pageSize + 1;
      this.endRow = Math.min((currentPage + 1) * pageSize, this.totalRows);
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustColumns(event.target.innerWidth);
  }
  adjustColumns(screenWidth: number) {
    if (this.gridApi) {
      const allColumnIds: string[] = [];
      this.gridApi.getAllGridColumns().forEach((column) => {
        allColumnIds.push(column.getColId());
      });

      if (screenWidth <= 375) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['user', 'status','icons'], true);
      } else if (screenWidth > 375 && screenWidth <= 510) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['user', 'status','icons'], true);
      } else if (screenWidth > 510 && screenWidth <= 992) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['user', 'status', 'activity', 'jobTitle','icons'], true);
      } else {
        this.gridApi.setColumnsVisible(allColumnIds, true);
      }
    }
  }
}
