<div>
  <button
    class="btn btn-primary"
    (click)="openDeleteConfirmationModal(content)"
  >
    hello
  </button>
</div>
<ng-template #content let-modal>
  <div class="page-wrapper-modal">
    <div class="page-header">
      <h2 class="mb-0">Approval Required</h2>
    </div>
    <div class="title-company-project">
      <div class="company">
        <span class="company-heading">Company:</span>
        <span class="company-content">A Brand New Production Company</span>
      </div>
      <div class="project">
        <span class="project-heading">Project:</span>
        <span class="project-content">The New Show - Pilot</span>
      </div>
    </div>
    <div class="section-text-area mt-0">
      <textarea class="text-area" id="credit-text" name="credit-text">
Hi,
A/R received the necessary credit paperwork from the customer and the credit check process has been completed.
Below is the summary evaluation.</textarea
      >
    </div>
    <div class="refrences">
      <div class="bank-reference">
        <h3 class="bank mb-0">Bank Reference:</h3>
        <p class="content mb-0">N/A</p>
      </div>
      <div class="trade-reference">
        <h3 class="trade mb-0">Trade References:</h3>
        <p class="content mb-0">
          Two Trades responded; Highest amount billed is $26,000.00
        </p>
      </div>
      <div class="db">
        <h3 class="mb-0">D&B:</h3>
        <div class="content">
          <div class="all-contents">
            <span>-</span><span>Deliquency Score:</span
            ><span class="content-number">88</span>
          </div>
          <div class="all-contents">
            <span>-</span><span>Failure Score:</span
            ><span class="content-number">67</span>
          </div>
          <div class="all-contents">
            <span>-</span><span>PAYDEX® Score:</span
            ><span class="content-number">73</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="end-wrapper">
    <div class="button">
      <button class="common-btn-light btn">Cancel</button>
      <button class="common-btn-secondary btn">Send For Approval</button>
    </div>
  </div>
</ng-template>
