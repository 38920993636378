import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { userProfileList } from '../../../../../mockData/development/user-profile';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private http: HttpClient) { }

  data = userProfileList;

  /**
  *getUsers() - method to get the signed in user information
  */

  getUsers(): Observable<any> {
    return of(this.data)
  }

}
