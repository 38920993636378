import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/shared/services/base-api/base-api.service';
import { CUSTOMER_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';

@Injectable({
  providedIn: 'root'
})
export class CreditApplicationsService {

  constructor(private baseApiService: BaseApiService) { }

  /**
   * getProjectUser() - method to fetch project user details based on the provided project ID and company ID.
   * @param projectId - The ID of the project.
   * @param companyId - The ID of the company.
   * @returns {Observable<any>} - The API response containing the project user details.
  */
  getCreditApplicationMetaData(): any {
    const url = `${CUSTOMER_PORTAL_API_PATHS.customer_credit_application_metadata}`;
    return this.baseApiService.get(url);
  }

  /**
   * saveCreditAppForm() - method to update the data
   * @param data - the data to be sent in request body
   * @returns - an observable of the response
   */
  saveCreditAppForm(data: any) {
    const url = `${CUSTOMER_PORTAL_API_PATHS.save_credit_app_form}`;
    return this.baseApiService.put(url, data);
  }
  /**
  * updateCreditAppForm() - method to call the update request
  * @param data - the data to be sent in request body
  * @returns - an  observable of the response
 */
  updateCreditAppForm(data: any) {
    const url = `${CUSTOMER_PORTAL_API_PATHS.update_credit_app_form}`;
    return this.baseApiService.post(url, data);
  }
  /**
   * addCertificateOfInsurance() - method to send a post request
   * @param data - the data to be sent in request body
   * @returns - an observable of the response
   */
  addCertificateOfInsurance(data: any) {
    const url = `${CUSTOMER_PORTAL_API_PATHS.add_certificate_of_insurance}`;
    return this.baseApiService.post(url, data);
  }

  /**
   * updateCertificateOfInsurance() - method to call the update request
   * @param data - the data to be sent in request body
   * @returns - an  observable of the response
  */
  updateCertificateOfInsurance(data: any) {
    const url = `${CUSTOMER_PORTAL_API_PATHS.edit_certificate_of_insurance}`;
    return this.baseApiService.post(url, data);
  }

  /**
   * certificateOfInsuranceFileUpload() - methos to upload the certificate of insurance file
   * @param fileData - file to be uploaded
   * @param data - the payload to be passed
   * @returns - an observable of the response
   */
  certificateOfInsuranceFileUpload(data: any) {
    const url = `${CUSTOMER_PORTAL_API_PATHS.upload_customer_coi}`;
    return this.baseApiService.put(url, data);
  }

  /**
  * startCreditApplication() - method to get the data to start the credit application
  * @param sequenceId - getting the sequence id as input to start the credit application
  * @returns - an observable of the response
  */
  startCreditApplication(sequenceId: any, creditNumber?: any) {
    let url;
    if (creditNumber) {
      url = CUSTOMER_PORTAL_API_PATHS.start_credit_application + '?' + 'caSeqId=' + sequenceId + '&' + 'caNumber=' + creditNumber;
    } else {
      url = CUSTOMER_PORTAL_API_PATHS.start_credit_application + '?' + 'caSeqId=' + sequenceId;
    }
    return this.baseApiService.get(url);
  }

  /**
   * editDepartments() - method to update the data to of ca departments 
  */
  editDepartments(data: any) {
    const url = `${CUSTOMER_PORTAL_API_PATHS.ca_edit_departments}`;
    return this.baseApiService.put(url, data);
  }

  /**
 * creditApplicationTermsandCon() - method to post the data on the credit application step 3.
 * @param data - data for the step3 terms and condition.
 * @returns - an observable of the response
 */
  creditApplicationTermsandCon(data: any) {
    const url = CUSTOMER_PORTAL_API_PATHS.ca_terms_condition;
    return this.baseApiService.post(url, data);
  }

  /**
* creditApplicationRevisionTermsandCon() - method to update the data fro revision the credit application step 3.
* @param data - data for the step3 terms and condition revision.
* @returns - an observable of the response
*/
  creditApplicationRevisionTermsandCon(data: any) {
    const url = CUSTOMER_PORTAL_API_PATHS.ca_revision_terms_condition;
    return this.baseApiService.put(url, data);
  }

  /**
   * submitCreditApplication() - method to submit the credit application step 4
   * @param - data for step4 review 
   * returns - an observable of the response
   */
  submitCreditApplication(data: any) {
    const url = CUSTOMER_PORTAL_API_PATHS.submit_customer_credit_application;
    return this.baseApiService.post(url, data);
  }

/**
* uploadTradeRefference() - method to to upload the Trade Refference.
* @param data - data for the Trade Refference.
* @returns - an observable of the response
*/
uploadTradeRefference(data: any) {
  const url = CUSTOMER_PORTAL_API_PATHS.upload_trade_refference;
  return this.baseApiService.put(url, data);
}

  /**
   * uploadLogFile() - method to upload the letter of guarantee
   * @param data - data for the letter of guarantee
   * @returns - an observable of the response
   */
  uploadLogFile(data: any) {
    const endpoint = CUSTOMER_PORTAL_API_PATHS.upload_log_file;
    return this.baseApiService.put(endpoint, data);
  }

  /**
   * removeCertificateOfInsuranceFile() - method to remove the certificate of insurance file
   * @param data - data for the coi file remove api
   * @returns - an observable of the response
   */
  removeCertificateOfInsuranceFile(data: any) {
    const url = `${CUSTOMER_PORTAL_API_PATHS.upload_customer_coi}`;
    return this.baseApiService.put(url, data);
  }

  /**
   * 
   * @param caSeqId - the seqid need to be passed as params
   * @param caNumber - the caNumber need to be passed as params
   * @returns - an observable with revision form data
   */
     getRevisionForm(caSeqId:any, caNumber:any){
      const endpoint = `${CUSTOMER_PORTAL_API_PATHS.ca_revision}caSeqId=${caSeqId}&caNumber=${caNumber}`;
      return this.baseApiService.get(endpoint)
    }

    /**
     * postCreditApplication() - to post the credit application form step 1 
     * @param data - the payload that needs to be send
     * @returns - an observable that gives success response
     */
    postCreditApplication(data: any){
      const endpoint = CUSTOMER_PORTAL_API_PATHS.startApplicationStep1;
      return this.baseApiService.post(endpoint,data)
    }
}
