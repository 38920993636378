import { Component, HostListener } from '@angular/core';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent {
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  public dropdownSettings: IDropdownSettings = {};

  ngOnInit() {
    this.dropdownList = [
      { item_id: 1, item_text: 'Project Name' },
      { item_id: 2, item_text: 'Project Name' },
      { item_id: 3, item_text: 'Project Name' },
      { item_id: 4, item_text: 'Project Name' },
      { item_id: 5, item_text: 'Project Name' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      showSelectedItemsAtTop: false
    };
  }

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'autoHeight',
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      filter: true,
      autoHeight: true
    },
  };

  rowData = [
    {id:12345, companyName: 'Dan Acorn', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'',comapnyName:'Dan Acorn',ids:'12345'},
    { id:12345,companyName: 'Lexi Allan', status: '',  projects: '#', active: '#',users:'#' ,goToPage:'',comapnyName:'Lexi Allan',ids:'12345'},
    { id:12345,companyName: 'Maggie Bash', status: '',  projects: '#', active: '#',users:'#' ,goToPage:'',comapnyName:'Maggie Bash',ids:'12345'},
    {id:12345, companyName: 'Nuha Hodgson', status: 'DISABLED',  projects: '#', active: '#',users:'#' ,goToPage:'',comapnyName:'Nuha Hodgson',ids:'12345'},
    {id:12345, companyName: 'Farhan Blevins', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'' ,comapnyName:'Farhan Blevins',ids:'12345'},
    {id:12345, companyName: 'Lin ANg', status: '',  projects: '#', active: '#',users:'#' ,goToPage:'',comapnyName:'Lin ANg',ids:'12345'},
    { id:12345,companyName: 'Erya Milnex', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'' ,comapnyName:'Erya Milnex',ids:'12345'},
    {id:12345, companyName: 'Colette Alfaro', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'' ,comapnyName:'Colette Alfaro',ids:''},
    {id:12345, companyName: 'Colette Alfaro', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'' ,comapnyName:'Colette Alfaro',ids:'12345'},
    {id:12345, companyName: 'Colette Alfaro', status: '',  projects: '#', active: '#',users:'#' ,goToPage:'',comapnyName:'Colette Alfaro',ids:'12345'},
    { id:12345,companyName: 'Colette Alfaro', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'',comapnyName:'Colette Alfaro',ids:'12345' },
    { id:12345,companyName: 'Colette Alfaro', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'' ,comapnyName:'Colette Alfaro',ids:'12345'},
    { id:12345,companyName: 'Colette Alfaro', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'',comapnyName:'Colette Alfaro',ids:'12345' },
  ];

  colDefs: ColDef[] = [
    { headerName: 'ID#', field: 'id', cellClass: 'account-user-active-project-cell', minWidth: 93, flex: 1 },
    {
      headerName: 'USER',
      field: 'user',
      cellRenderer:(params: ICellRendererParams) => {
        const comapnyName = params.data.comapnyName;
        const ids = params.data.ids;
        return `<span class="common-split-cell-section">
                  <span class="common-split-cell-data-1">${comapnyName}</span>
                  <span class="common-split-cell-data-2">${ids}</span>
                </span>`;
      },
      minWidth: 150,
      flex:1.3,
      hide:true
    },
    { headerName: 'COMPANY NAME', field: 'companyName', filter: 'agTextColumnFilter', filterParams: { alwaysShowFilter: true }, wrapText: true, minWidth: 150, flex: 2.4, cellClass: 'common-cell-data-bold' },
    { headerName: 'STATUS', field: 'status', cellRenderer: this.statusCellRenderer.bind(this), minWidth: 200, flex: 2 },
    { headerName: 'PROJECTS', field: 'projects', filter: false, flex: 1.8 },
    { headerName: 'ACTIVE', field: 'active', cellClass: 'account-user-active-project-cell', flex: 1.8 },
    { headerName: 'USERS', field: 'users', cellClass: 'account-user-active-project-cell', flex: 1, filter: false },
    {
      headerName: 'GO TO PAGE',
      field: 'goToPage',
      cellClass: 'account-user-active-project-cell',
      filter: false,
      minWidth: 180,
      flex: 1.8,
      cellRenderer: this.goToPageRenderer
    },
    {
      field: 'icons',
      cellRenderer: this.iconsRenderer,
      headerName: '',
      filter: false,
      minWidth: 150,
    },

  ];

  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.value);
    return `
    <div class="request-status-tag ${statusClass}">${params.value}</div>
  `;
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'DISABLED':
        return 'status-account-tag disabled';
      case 'DEACTIVATED':
        return 'user-status-tag deactivated account-user-common-status-cell';
      default:
        return '';
    }
  }

  goToPageRenderer(params: ICellRendererParams) {
    return `
      <div class="custom-dropdown">
        <select class="custom-select">
          <option value="" disabled selected hidden class="dropdown-option">Go To…</option>
          <option value="1" class="dropdown-option">Projects</option>
          <option value="2" class="dropdown-option">Users</option>
          <option value="3" class="dropdown-option">Spaces & Resources</option>
          <option value="4" class="dropdown-option">Company</option>
        </select>
      </div>`;
  }

  iconsRenderer() {
    return `
      <div class="icon-container">
        <span class="icon-hover"><img src="/assets/images/add-folder.svg"/></span>
        <span class="icon-hover"><img src="/assets/images/add-user-icon.svg"/></span>
        <span class="icon-hover"><img src="/assets/images/disabled-icon.svg"/></span>
      </div>`;
  }

  gridApi!: GridApi;
  pageSizeOptions: number[] = [10, 20, 50];
  startRow: number = 1;
  endRow: number = 10;
  totalRows: number = this.rowData.length;

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.updatePagination();
  }

  onPageSizeChanged(event: any) {
    const value = event.target.value;
    this.gridApi.paginationSetPageSize(Number(value));
    this.updatePagination();
  }

  onBtNext() {
    this.gridApi.paginationGoToNextPage();
    this.updatePagination();
  }

  onBtPrevious() {
    this.gridApi.paginationGoToPreviousPage();
    this.updatePagination();
  }

  updatePagination() {
    if (this.gridApi) {
      const currentPage = this.gridApi.paginationGetCurrentPage();
      const pageSize = this.gridApi.paginationGetPageSize();
      this.startRow = currentPage * pageSize + 1;
      this.endRow = Math.min((currentPage + 1) * pageSize, this.totalRows);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustColumns(event.target.innerWidth);
  }

  adjustColumns(screenWidth: number) {
    if (this.gridApi) {
      const allColumnIds: string[] = [];
      this.gridApi.getAllGridColumns().forEach((column) => {
        allColumnIds.push(column.getColId());
      });

      if (screenWidth <= 375) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['user', 'status', 'icons'], true);
      } else if (screenWidth > 375 && screenWidth <= 510) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['user', 'status', 'icons'], true);
      } else if (screenWidth > 511 && screenWidth <= 992) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['user', 'status', 'goToPage', 'icons'], true);
      } else {
        this.gridApi.setColumnsVisible(allColumnIds, true);
        this.gridApi.setColumnsVisible(['user'], false);
      }
    }
  }
}
