<div class="page-details-section">
  <div class="profile-container">
    <h2 class="profile-heading mb-0">{{profile.myProfile}}</h2>
    <form [formGroup]="profileForm" (ngSubmit)="onSave()">
      <div class="col-md-12 custom-floating-label">
        <label for="firstName" class="form-label form-control-label">{{profile.firstName}}</label>
        <input type="text" placeholder="First Name" id="firstName" class="form-control form-inner-content" formControlName="firstName"
          maxlength="50" />
          <small class="text-danger"
          *ngIf="profileForm.get('firstName').invalid && (profileForm.get('firstName').touched || profileForm.get('firstName').dirty)">
          <div *ngIf="profileForm.get('firstName').errors['required']">{{ validations.fieldRequired }}</div>
          <div *ngIf="profileForm.get('firstName').errors['invalidAlphabets']">{{user.invalidField}}</div>          
        </small>
      </div>

      <div class="col-md-12 custom-floating-label">
        <label for="lastName" class="form-label form-control-label">{{profile.lastName}} </label>
        <input type="text" placeholder="Last Name" id="lastName" class="form-control form-inner-content" formControlName="lastName"
          maxlength="50" />
          <small class="text-danger"
          *ngIf="profileForm.get('lastName').invalid && (profileForm.get('lastName').touched || profileForm.get('lastName').dirty)">
          <div *ngIf="profileForm.get('lastName').errors['required']">{{ validations.fieldRequired }}</div>
          <div *ngIf="profileForm.get('lastName').errors['invalidAlphabets']">{{user.invalidField}}</div>
        </small>
      </div>

      <div class="col-md-12 custom-floating-label">
        <label for="email" class="form-label form-control-label">{{profile.email}}</label>
        <input type="email" placeholder="Email" id="email" class="form-control form-inner-content" formControlName="email"
          maxlength="80" />
          <small class="text-danger"
          *ngIf="profileForm.get('email').invalid && (profileForm.get('email').touched || profileForm.get('email').dirty)">
          <div *ngIf="profileForm.get('email').errors['required']">{{ validations.fieldRequired }}</div>
          <div *ngIf="profileForm.get('email').errors['invalidEmail']">{{ validations.emailInvalid }}</div>
        </small>
      </div>

      <div class="col-md-12 custom-floating-label">
        <label for="Phone" class="form-label form-control-label">{{profile.phone}}</label>
        <input type="text" placeholder="Phone" id="Phone" class="form-control form-inner-content" formControlName="phone" maxlength="20"
          minlength="10" />
        <img src="/assets/images/question-mark.svg" data-bs-toggle="tooltip" class="phone-question-mark" alt="Enter Phone" ngbTooltip="Please enter the country code. Phone number format example for US: +1 (000) 000-0000 ; UK: +44 0000 000 0000"/>
        <div class="error-message-container">
            <small class="text-danger"
            *ngIf="profileForm.get('phone').invalid && (profileForm.get('phone').touched || profileForm.get('phone').dirty)">
            <div *ngIf="profileForm.get('phone').errors['required']">{{validations.phoneRequired}}</div>
            <div *ngIf="profileForm.get('phone').errors['invalidPhone']">{{validations.phoneInvalid}}</div>
            <div *ngIf="profileForm.get('phone').errors['minLengthError']">{{validations.minLength}}</div>
          </small>
          <span class="tooltip-icon" [title]="tooltipMessage"></span>
        </div>
      </div>

      <div class="col-md-12 custom-floating-label">
        <label for="jobTitle" class="form-label form-control-label">{{profile.jobTitle}}</label>
        <input type="text" placeholder="Job Title" id="jobTitle" class="form-control form-inner-content" formControlName="jobTitle"
          maxlength="50" />
          <small class="text-danger"
          *ngIf="profileForm.get('jobTitle').invalid && (profileForm.get('jobTitle').touched || profileForm.get('jobTitle').dirty)">
          <div *ngIf="profileForm.get('jobTitle').errors['required']">{{ validations.fieldRequired }}</div>
          <div *ngIf="profileForm.get('jobTitle').errors['invalidJobTitle']">{{ validations.jobTitleInvalid }}</div>
        </small>
      </div>

      <div class="d-flex gap-3">
        <button type="submit" class="common-btn-secondary btn" [disabled]="!profileForm.valid || disableSaveButton" >{{profile.save}}</button>
        <button type="button" class="common-btn-light btn" [disabled] = "!isFormChanged || disableSaveButton"
          (click)="openDeleteConfirmationModal(content)">{{profile.discardChanges}}</button>
      </div>
    </form>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-body">
    <h4 class="section-main-header">{{profile.discardChangesMessage}}</h4>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-secondary btn"
        (click)="navigateToDashboard(modal)">{{profile.yes}}</button>
      <button type="button" class="common-btn-light btn" (click)="modal.close()">{{profile.no}}</button>
    </div>
  </div>
</ng-template>

<ng-template #dangerTpl>
  <div class="danger">{{user.errorMessage}}</div>
</ng-template>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>