import { Component } from '@angular/core';

@Component({
  selector: 'app-add-customer-user',
  templateUrl: './add-customer-user.component.html',
  styleUrls: ['./add-customer-user.component.scss']
})
export class AddCustomerUserComponent {

}
