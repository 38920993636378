import { Component } from '@angular/core';

@Component({
  selector: 'app-cred-app-form-four',
  templateUrl: './cred-app-form-four.component.html',
  styleUrls: ['./cred-app-form-four.component.scss']
})
export class CredAppFormFourComponent {

}
