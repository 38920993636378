import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import tippy, { hideAll } from 'tippy.js';
 
@Component({
  selector: 'app-image-dropdown',
  templateUrl: './image-dropdown.component.html',
  styleUrls: ['./image-dropdown.component.scss']
})
export class ImageDropdownComponent {
  @Output() uploadPhoto = new EventEmitter<void>(); // Event to notify the parent to handle file upload
  @Output() resetPhoto = new EventEmitter<void>(); // Event to notify the parent to reset photo
  @Input() fileInput: HTMLInputElement; // Accept the file input reference from the parent
 
  isOpen: boolean = false;
  private tippyInstance;
 
  @ViewChild('content') container;
  @ViewChild('trigger') button;
 
  constructor(private changeDetector: ChangeDetectorRef) {}
 
  ngAfterViewInit(): void {
    this.tippyInstance = tippy(this.button.nativeElement, {
      trigger: 'manual',
      placement: 'right',
      arrow: false,
      interactive: true,
      appendTo: document.body,
      hideOnClick: false,
      onShow: (instance) => {
        hideAll({ exclude: instance });
      },
      onClickOutside: (instance) => {
        this.isOpen = false;
        instance.hide();
      },
    });
    this.tippyInstance.disable();
  }
 
  togglePopup() {
    this.isOpen = !this.isOpen;
    this.changeDetector.detectChanges();
 
    if (this.isOpen) {
      this.configureTippyInstance();
    } else {
      this.tippyInstance.hide();
    }
  }
 
  configureTippyInstance() {
    this.tippyInstance.enable();
    this.tippyInstance.setContent(this.container.nativeElement);
    this.tippyInstance.show();
  }
 
  onUploadPhoto(): void {
    this.uploadPhoto.emit(); // Notify the parent component
    this.isOpen=false;
  }
 
  onResetPhoto(): void {
    this.resetPhoto.emit(); // Notify the parent component
    this.isOpen=false;
  }
}