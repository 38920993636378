<div class="container-fluid page-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-between align-items-center page-title">
        <h1 class="px-0">FAQs</h1>
        <div class="common-toggle-button">
          <input type="checkbox" id="custom-toggle-1" class="common-toggle-input" [(ngModel)]="isEditable">
          <label for="custom-toggle-1" class="common-toggle-label">
            {{ isEditable ? 'Edit Mode' : 'View Mode' }}
          </label>
        </div>
      </div>

      <!-- Sidebar Tabs (for desktop) -->
      <div class="col-md-4 col-lg-4 d-none d-md-block">
        <ol class="handbook-list" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <li class="nav-link" id="overview-tab" role="tab" aria-controls="overview-content" aria-selected="true" (click)="selectTab('overview-tab')">
            <span class="list-number">1.</span> Overview
          </li>
          <li class="nav-link" id="details-tab" role="tab" aria-controls="details-content" (click)="selectTab('details-tab')">
            <span class="list-number">2.</span> Details
          </li>
          <li class="nav-link" id="account-tab" role="tab" aria-controls="account-content" (click)="selectTab('account-tab')">
            <span class="list-number">3.</span> Account
          </li>
          <li class="nav-link" id="projects-tab" role="tab" aria-controls="projects-content" (click)="selectTab('projects-tab')">
            <span class="list-number">4.</span> Projects 
          </li>
          <li class="nav-link" id="requests-tab" role="tab" aria-controls="requests-content" (click)="selectTab('requests-tab')">
            <span class="list-number">5.</span> Requests
          </li>
          <li class="nav-link" id="users-tab" role="tab" aria-controls="users-content" (click)="selectTab('users-tab')">
            <span class="list-number">6.</span> Users
          </li>
          <li class="nav-link" id="contact-us-tab" role="tab" aria-controls="contact-us-content" (click)="selectTab('contact-us-tab')">
            <span class="list-number">7.</span> Contact Us
          </li>
        </ol>
      </div>

      <!-- Mobile Dropdown (for mobile) -->
      <div class="col-md-4 col-lg-4 col-sm-12 d-block d-md-none mobile-select-block">
        <select class="form-select" id="mobileSelect" [(ngModel)]="selectedTab" (change)="onSelectChange($event)">
          <option value="overview-tab">1. Overview</option>
          <option value="details-tab">2. Details</option>
          <option value="account-tab">3. Account</option>
          <option value="projects-tab">4. Projects</option>
          <option value="requests-tab">5. Requests</option>
          <option value="users-tab">6. Users</option>
          <option value="contact-us-tab">7. Contact Us</option>
        </select>
      </div>

      <!-- Tab Contents -->
      <div class="col-md-8 col-lg-8 col-sm-12">
        <div class="tab-content" id="v-pills-tabContent">

          <!-- Overview Tab -->
          <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'overview-tab'}" id="overview-tab" role="tabpanel">
            <form [formGroup]="formGroups['overview']">
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="tab-content-title">Overview</h1>
                <div *ngIf="isEditable">
                  <button (click)="addOverviewSection()" type="button" class="common-btn-primary btn mb-3">+ Add FAQ</button>
                </div>
              </div>
              <div class="page-details-section" *ngFor="let section of getOverviewSections().controls; let i = index" [formGroupName]="i">
                <div class="d-flex justify-content-end">
                  <i (click)="openDeleteConfirmationModal(content, 'overview', i)" class="bi bi-trash"></i>
                </div>
                <div class="custom-floating-label">
                  <label for="title1" class="form-label">Title</label>
                  <input formControlName="title" class="form-control" placeholder="Enter title" id="title1" />
                </div>
                <div class="custom-floating-label">
                  <label for="details1" class="form-label">Details</label>
                  <textarea formControlName="details" class="form-control common-text-area" placeholder="Enter details" id="details1" rows="5"></textarea>
                </div>
              </div>
              <div class="page-details-section" *ngIf="!isEditable">
                <div *ngFor="let section of getOverviewSections().controls; let i = index" [formGroupName]="i" class="card">
                  <h2 class="page-sub-heading">{{ section.get('title').value }}</h2>
                  <p class="para-text-light">{{ section.get('details').value }}</p>
                </div>
              </div>
              <div *ngIf="isEditable" class="d-flex justify-content-end gap-3">
                <button (click)="saveChanges('overview')" type="button" class="common-btn-secondary btn">Save Changes</button>
                <button (click)="discardChanges('overview')" type="button" class="common-btn-light btn">Discard</button>
              </div>
            </form>
          </div>

          <!-- Details Tab -->
          <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'details-tab'}" id="details-tab" role="tabpanel">
            <form [formGroup]="formGroups['details']">
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="tab-content-title">Details</h1>
                <div *ngIf="isEditable">
                  <button (click)="addDetailsSection()" type="button" class="common-btn-primary btn mb-3">+ Add Detail</button>
                </div>
              </div>
              <div class="page-details-section" *ngFor="let section of getDetailsSections().controls; let i = index" [formGroupName]="i">
                <div class="d-flex justify-content-end">
                  <i (click)="openDeleteConfirmationModal(content, 'details', i)" class="bi bi-trash"></i>
                </div>
                <div class="custom-floating-label">
                  <label for="summary1" class="form-label">Summary</label>
                  <input formControlName="summary" class="form-control" placeholder="Enter summary" id="summary1" />
                </div>
                <div class="custom-floating-label">
                  <label for="description1" class="form-label">Description</label>
                  <textarea formControlName="description" class="form-control common-text-area" placeholder="Enter description" id="description1" rows="5"></textarea>
                </div>
              </div>
              <div class="page-details-section" *ngIf="!isEditable">
                <div *ngFor="let section of getOverviewSections().controls; let i = index" [formGroupName]="i" class="card">
                  <h2 class="page-sub-heading">{{ section.get('title').value }}</h2>
                  <p class="para-text-light">{{ section.get('details').value }}</p>
                </div>
              </div>
              <div *ngIf="isEditable" class="d-flex justify-content-end gap-3">
                <button (click)="saveChanges('details')" type="button" class="common-btn-secondary btn">Save Changes</button>
                <button (click)="discardChanges('details')" type="button" class="common-btn-light btn">Discard</button>
              </div>
            </form>
          </div>

          <!-- Account Tab -->
          <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'account-tab'}" id="account-tab" role="tabpanel">
            <form [formGroup]="formGroups['account']">
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="tab-content-title">Account</h1>
                <div *ngIf="isEditable">
                  <button (click)="addAccountSection()" type="button" class="common-btn-primary btn mb-3">+ Add Account Info</button>
                </div>
              </div>
              <div class="page-details-section" *ngFor="let section of getAccountSections().controls; let i = index" [formGroupName]="i">
                <div class="d-flex justify-content-end">
                  <i (click)="openDeleteConfirmationModal(content, 'account', i)" class="bi bi-trash"></i>
                </div>
                <div class="custom-floating-label">
                  <label for="accountTitle1" class="form-label">Account Info Title</label>
                  <input formControlName="accountTitle" class="form-control" placeholder="Enter account title" id="accountTitle1" />
                </div>
                <div class="custom-floating-label">
                  <label for="accountDetails1" class="form-label">Account Info Details</label>
                  <textarea formControlName="accountDetails" class="form-control common-text-area" placeholder="Enter account details" id="accountDetails1" rows="5"></textarea>
                </div>
              </div>
              <div class="page-details-section" *ngIf="!isEditable">
                <div *ngFor="let section of getOverviewSections().controls; let i = index" [formGroupName]="i" class="card">
                  <h2 class="page-sub-heading">{{ section.get('title').value }}</h2>
                  <p class="para-text-light">{{ section.get('details').value }}</p>
                </div>
              </div>
              <div *ngIf="isEditable" class="d-flex justify-content-end gap-3">
                <button (click)="saveChanges('account')" type="button" class="common-btn-secondary btn">Save Changes</button>
                <button (click)="discardChanges('account')" type="button" class="common-btn-light btn">Discard</button>
              </div>
            </form>
          </div>

          <!-- Projects Tab -->
          <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'projects-tab'}" id="projects-tab" role="tabpanel">
            <form [formGroup]="formGroups['projects']">
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="tab-content-title">Projects</h1>
                <div *ngIf="isEditable">
                  <button (click)="addProjectsSection()" type="button" class="common-btn-primary btn mb-3">+ Add Project</button>
                </div>
              </div>
              <div class="page-details-section" *ngFor="let section of getProjectsSections().controls; let i = index" [formGroupName]="i">
                <div class="d-flex justify-content-end">
                  <i (click)="openDeleteConfirmationModal(content, 'projects', i)" class="bi bi-trash"></i>
                </div>
                <div class="custom-floating-label">
                  <label for="projectTitle1" class="form-label">Project Title</label>
                  <input formControlName="projectTitle" class="form-control" placeholder="Enter project title" id="projectTitle1" />
                </div>
                <div class="custom-floating-label">
                  <label for="projectDetails1" class="form-label">Project Details</label>
                  <textarea formControlName="projectDetails" class="form-control common-text-area" placeholder="Enter project details" id="projectDetails1" rows="5"></textarea>
                </div>
              </div>
              <div class="page-details-section" *ngIf="!isEditable">
                <div *ngFor="let section of getOverviewSections().controls; let i = index" [formGroupName]="i" class="card">
                  <h2 class="page-sub-heading">{{ section.get('title').value }}</h2>
                  <p class="para-text-light">{{ section.get('details').value }}</p>
                </div>
              </div>
              <div *ngIf="isEditable" class="d-flex justify-content-end gap-3">
                <button (click)="saveChanges('projects')" type="button" class="common-btn-secondary btn">Save Changes</button>
                <button (click)="discardChanges('projects')" type="button" class="common-btn-light btn">Discard</button>
              </div>
            </form>
          </div>

          <!-- Requests Tab -->
          <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'requests-tab'}" id="requests-tab" role="tabpanel">
            <form [formGroup]="formGroups['requests']">
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="tab-content-title">Requests</h1>
                <div *ngIf="isEditable">
                  <button (click)="addRequestsSection()" type="button" class="common-btn-primary btn mb-3">+ Add Request</button>
                </div>
              </div>
              <div class="page-details-section" *ngFor="let section of getRequestsSections().controls; let i = index" [formGroupName]="i">
                <div class="d-flex justify-content-end">
                  <i (click)="openDeleteConfirmationModal(content, 'requests', i)" class="bi bi-trash"></i>
                </div>
                <div class="custom-floating-label">
                  <label for="requestTitle1" class="form-label">Request Title</label>
                  <input formControlName="requestTitle" class="form-control" placeholder="Enter request title" id="requestTitle1" />
                </div>
                <div class="custom-floating-label">
                  <label for="requestDetails1" class="form-label">Request Details</label>
                  <textarea formControlName="requestDetails" class="form-control common-text-area" placeholder="Enter request details" id="requestDetails1" rows="5"></textarea>
                </div>
              </div>
              <div class="page-details-section" *ngIf="!isEditable">
                <div *ngFor="let section of getOverviewSections().controls; let i = index" [formGroupName]="i" class="card">
                  <h2 class="page-sub-heading">{{ section.get('title').value }}</h2>
                  <p class="para-text-light">{{ section.get('details').value }}</p>
                </div>
              </div>
              <div *ngIf="isEditable" class="d-flex justify-content-end gap-3">
                <button (click)="saveChanges('requests')" type="button" class="common-btn-secondary btn">Save Changes</button>
                <button (click)="discardChanges('requests')" type="button" class="common-btn-light btn">Discard</button>
              </div>
            </form>
          </div>

          <!-- Users Tab -->
          <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'users-tab'}" id="users-tab" role="tabpanel">
            <form [formGroup]="formGroups['users']">
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="tab-content-title">Users</h1>
                <div *ngIf="isEditable">
                  <button (click)="addUsersSection()" type="button" class="common-btn-primary btn mb-3">+ Add User</button>
                </div>
              </div>
              <div class="page-details-section" *ngFor="let section of getUsersSections().controls; let i = index" [formGroupName]="i">
                <div class="d-flex justify-content-end">
                  <i (click)="openDeleteConfirmationModal(content, 'users', i)" class="bi bi-trash"></i>
                </div>
                <div class="custom-floating-label">
                  <label for="userName1" class="form-label">User Name</label>
                  <input formControlName="userName" class="form-control" placeholder="Enter user name" id="userName1" />
                </div>
                <div class="custom-floating-label">
                  <label for="userEmail1" class="form-label">User Email</label>
                  <input formControlName="userEmail" class="form-control" placeholder="Enter user email" id="userEmail1" />
                </div>
              </div>
              <div class="page-details-section" *ngIf="!isEditable">
                <div *ngFor="let section of getOverviewSections().controls; let i = index" [formGroupName]="i" class="card">
                  <h2 class="page-sub-heading">{{ section.get('title').value }}</h2>
                  <p class="para-text-light">{{ section.get('details').value }}</p>
                </div>
              </div>
              <div *ngIf="isEditable" class="d-flex justify-content-end gap-3">
                <button (click)="saveChanges('users')" type="button" class="common-btn-secondary btn">Save Changes</button>
                <button (click)="discardChanges('users')" type="button" class="common-btn-light btn">Discard</button>
              </div>
            </form>
          </div>

          <!-- Contact Us Tab -->
          <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'contact-us-tab'}" id="contact-us-tab" role="tabpanel">
            <form [formGroup]="formGroups['contactUs']">
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="tab-content-title">Contact Us</h1>
                <div *ngIf="isEditable">
                  <button (click)="addContactUsSection()" type="button" class="common-btn-primary btn mb-3">+ Add Contact Info</button>
                </div>
              </div>
              <div class="page-details-section" *ngFor="let section of getContactUsSections().controls; let i = index" [formGroupName]="i">
                <div class="d-flex justify-content-end">
                  <i (click)="openDeleteConfirmationModal(content, 'contactUs', i)" class="bi bi-trash"></i>
                </div>
                <div class="custom-floating-label">
                  <label for="contactTitle1" class="form-label">Contact Info Title</label>
                  <input formControlName="contactTitle" class="form-control" placeholder="Enter contact title" id="contactTitle1" />
                </div>
                <div class="custom-floating-label">
                  <label for="contactDetails1" class="form-label">Contact Info Details</label>
                  <textarea formControlName="contactDetails" class="form-control common-text-area" placeholder="Enter contact details" id="contactDetails1" rows="5"></textarea>
                </div>
              </div>
              <div class="page-details-section" *ngIf="!isEditable">
                <div *ngFor="let section of getOverviewSections().controls; let i = index" [formGroupName]="i" class="card">
                  <h2 class="page-sub-heading">{{ section.get('title').value }}</h2>
                  <p class="para-text-light">{{ section.get('details').value }}</p>
                </div>
              </div>
              <div *ngIf="isEditable" class="d-flex justify-content-end gap-3">
                <button (click)="saveChanges('contactUs')" type="button" class="common-btn-secondary btn">Save Changes</button>
                <button (click)="discardChanges('contactUs')" type="button" class="common-btn-light btn">Discard</button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-body">
    <h4 class="section-main-header">Are you sure you want to delete?</h4>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-secondary btn" (click)="confirmDelete()">Ok</button>
      <button type="button" class="common-btn-light btn" (click)="modal.close()">Cancel</button>
    </div>
  </div>
</ng-template>
 