import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/shared/services/base-api/base-api.service';
import { CUSTOMER_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';

@Injectable({
  providedIn: 'root'
})
export class PassesService {

  constructor( private baseApiService: BaseApiService ) { }

  getPasses(data: any) {
    const endpoint = `${CUSTOMER_PORTAL_API_PATHS.passes}`;
    return this.baseApiService.post(endpoint,data);
  }
}
