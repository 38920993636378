// email-action.component.ts
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailActionService } from '../email-actio.service';
import { EMAIL_ACTIONS, MESSAGES, RISK_ACTIONS } from 'src/assets/constants/globalContants';
import { ToastService } from '../shared/components/toast.service';

@Component({
  selector: 'app-email-action',
  templateUrl: './email-action.component.html',
  styleUrls: ['./email-action.component.scss']
})
export class EmailActionComponent implements OnInit {
  errorMessage: string = '';
  successMessage: string = '';
  loading: boolean = false;
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private emailActionService: EmailActionService,
    private router: Router,
    private toastService: ToastService

  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let token = params['token'] ? decodeURIComponent(params['token']) : null;
      const actionType = params['actionType'];
      if (token) {
        token = token.replace(/ /g, '+');
      }
      if (token && actionType) {
        this.handleAction(token, actionType);
      } else {
        this.showMessage(MESSAGES.NO_TOKEN, false);
      }
    });
  }
  /**
   * handleAction = the action based on the actionType.
   * @param token - The token extracted from the URL.
   * @param actionType - The type of action to perform.
   */

  private handleAction(token: string, actionType: string): void {

    switch (actionType.toLowerCase()) {
      case EMAIL_ACTIONS.DOWNLOAD:
        this.handleEmailAction(token, 'DownloadFullForm');
        break;
      case EMAIL_ACTIONS.ACCEPT_RISK:
        this.performRiskAction(token, RISK_ACTIONS.ACCEPT);
        break;
      case EMAIL_ACTIONS.DECLINE_RISK:
        this.performRiskAction(token, RISK_ACTIONS.DECLINE);
        break;
      default:
        this.showMessage(MESSAGES.INVALID_ACTION, false);
    }
  }

  /**
   * performRiskAction = Performs a risk action by sending a request to the service.
   * @param token - The token to be sent in the request.
   * @param actionType - The type of risk action to perform.
   */
  private performRiskAction(token: string, actionType: string): void {
    const requestBody = {
      token: token,
      actionType: actionType
    };

    this.emailActionService.performRiskAction(requestBody).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.successMessage = actionType === RISK_ACTIONS.ACCEPT
            ? MESSAGES.ACCEPT_SUCCESS
            : MESSAGES.DECLINE_SUCCESS;
        } else {
          this.successMessage = response;
        }
        this.showSuccess(this.successTpl);
      },

      error: (response) => {
        this.errorMessage = response
        
        this.showDanger(this.dangerTpl);

      }
    });
  }
  /**
  * handleEmailAction = Handles email actions such as downloading a file.
  * @param token - The token to be sent in the request.
  * @param actionType - The type of email action to perform.
  */
  private handleEmailAction(token: string, actionType: string): void {
    this.loading = true;
    const requestBody = {
      token: token,
      actionType: actionType
    };

    this.emailActionService.downloadFullForm(requestBody).subscribe({
      next: (response: any) => {
        this.loading = false;
        const message = actionType === 'downloadfullform'
        if (response && response.result && response.result.body) {
          const contentDisposition = response.result.headers['Content-Disposition'][0];
          const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
          const fileName = fileNameMatch ? fileNameMatch[1] : 'downloaded-file.pdf';
          if (actionType.toLowerCase() === 'downloadfullform') {
            this.downloadBase64File(response.result.body, fileName);
          }
        } else {
          this.successMessage = MESSAGES.DOWNLOAD_SUCCESS
          this.showSuccess(this.successTpl)
        }
      },
      error: (response) => {
        this.loading = false;
        this.errorMessage = response
        this.showDanger(this.dangerTpl)

      }
    });
  }
  /**
 * downloadBase64File = Downloads a file from a base64 encoded string.
 * @param base64Data - The base64 encoded data of the file.
 * @param fileName - The name of the file to be downloaded.
 */
  private downloadBase64File(base64Data: string, fileName: string): void {
    try {
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      this.showMessage('File downloaded successfully', true);
      setTimeout(() => {
      }, 5000); // 5 seconds delay
    } catch (error) {
      this.showMessage(`Error downloading file: ${error}`, false);
    }
  }

  /**
 * showMessage - Displays a message to the user.
 * @param message - The message to display.
 * @param isSuccess - Whether the message is a success message or an error message.
 */
  private showMessage(message: string, isSuccess: boolean) {
    if (isSuccess) {
      this.successMessage = message;
      this.errorMessage = '';
    } else {
      this.errorMessage = message;
      this.successMessage = '';
    }

    setTimeout(() => {
      this.successMessage = '';
      this.errorMessage = '';
    }, 5000);
  }

  /**
 * showSuccess() - Displays a success toast notification with custom content and options.
 * @param successTpl - The template reference containing the toast's content.
*/
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.clear()
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Displays a danger (error) toast message.
   * This method is called when there is an error in adding the user.
   * @param dangerTpl - The template reference for the danger message to display.
  */
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }


}
