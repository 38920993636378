
<div class="modal-body justify-content-center align-items-center">
  <div class="cm-header-section">
    <h5 class="modal-title" id="commonModalLabel">{{title}}</h5>
    <button type="button" class="btn-close" (click)="OpenWarningModal()">
      <span class="cm-close-text">Close</span>
    </button>
  </div>
  <!-- Add Company Section -->
  <div class="container cm-main-container" >
    <form [formGroup]="companyForm" (ngSubmit)="addCompanyData()">
    <div class="common-modal-input-section custom-internal-use-bg-shadow grey-bg" *ngIf="portalType !== 'customer'" >
      <h2 class="section-main-header">{{companyInfo.companytype}}</h2>
      <span *ngIf="modalType == 'edit'" class="company-type">{{company.companyid}}  <span class="compant-type-id">{{companyData.companyId}}</span></span>

      <div class="mt-5">
        <p class="text-color-red">{{companyInfo.internal}}</p>
        <div formGroupName="company">
          <div class="row">
            <div class="col-md-5 custom-dropdown">
              <select class="custom-select" formControlName="internalUse">
                <option class="dropdown-option" value="" disabled selected hidden>Select</option>
                <option class="dropdown-option" [value]="value.companyTypeId" *ngFor="let value of companyTypes">{{value.companyType}}</option>
              </select>
              <div *ngIf="submitted && companyForm.get('company.internalUse').invalid">
                <p class="text-danger">{{errMsg.required}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Company Details Section -->
    <div class="common-modal-input-section prod-comp-name" *ngIf="portalType !== 'customer'">
      <h2 class="section-main-header">{{companyInfo.companyname}}</h2>
      <div formGroupName="company">
        <div class="row">
          <div class="col-md-12 custom-floating-label">
            <label for="companyName" class="form-label">{{ companyInfo.name }}</label>
            <input 
              type="text" 
              class="form-control" 
              formControlName="companyName" 
              placeholder="Name" 
              (blur)="companyForm.get('company.companyName')?.markAsTouched()"
            >
            <div *ngIf="(submitted || companyForm.get('company.companyName')?.touched) && companyForm.get('company.companyName')?.invalid">
              <p *ngIf="companyForm.get('company.companyName').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="!companyForm.get('company.companyName').errors?.['required']" class="text-danger">{{ errMsg.companyName }}</p>              
              <p *ngIf="!companyForm.get('company.companyName').errors?.['required'] && !companyForm.get('company.companyName').errors?.['maxlength']" class="text-danger">{{ errMsg.companyName }}</p>
            </div>
            <span>{{ companyInfo.legalname }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Company Address Section -->
    <div class="common-modal-input-section company-address-block">
      <h2 class="section-main-header">{{companyInfo.companyaddress}}</h2>
      <div formGroupName="companyAddress">
        <div class="row">
          <div class="col-md-4 mb-5 custom-dropdown custom-floating-label">
            <label for="inputCountry" class="form-label">{{ companyInfo.country }}</label>
            <select class="custom-select" formControlName="country" (change)="onCountryCompAdressChange($event)">
              <option class="dropdown-option" value="" disabled selected hidden>Select Country</option>
              <option class="dropdown-option" [value]="value.countryId" *ngFor="let value of countries">{{ value.countryName }}</option>
            </select>
            <div *ngIf="(submitted || companyForm.get('companyAddress.country')?.touched) && companyForm.get('companyAddress.country')?.invalid">
              <p class="text-danger">{{ errMsg.required }}</p>
            </div>
          </div>
          <div class="col-md-12 custom-floating-label">
            <label for="inputStreet" class="form-label">{{ companyInfo.street }}</label>
            <input type="text" class="form-control" formControlName="street" placeholder="Street" id="inputStreet" (blur)="companyForm.get('companyAddress.street')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('companyAddress.street')?.touched) && companyForm.get('companyAddress.street')?.invalid">
              <p class="text-danger">{{ errMsg.required }}</p>
            </div>
          </div>
          <div class="col-md-5 custom-floating-label" *ngIf="selectedCountryCompany === '2' || selectedCountryCompany === '3'">
            <label for="inputCity" class="form-label">{{ companyInfo.city }}</label>
            <input type="text" class="form-control" formControlName="city" placeholder="City" id="inputCity" (blur)="companyForm.get('companyAddress.city')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('companyAddress.city')?.touched) && companyForm.get('companyAddress.city')?.invalid">
              <p class="text-danger">{{ errMsg.required }}</p>
            </div>
          </div>
          <div class="col-md-5 custom-floating-label" *ngIf="selectedCountryCompany === '1' || (selectedCountryCompany !== '2' && selectedCountryCompany !== '3')">
            <label for="inputCityTown" class="form-label">{{ companyInfo.city }}</label>
            <input type="text" class="form-control" formControlName="city" placeholder="City/Town" id="inputCityTown" maxlength="15" (blur)="companyForm.get('companyAddress.city')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('companyAddress.city')?.touched) && companyForm.get('companyAddress.city')?.invalid">
              <p class="text-danger">{{ errMsg.required }}</p>
            </div>
          </div>
      
          <!-- Conditional fields based on country selection -->
          <!-- United States -->
          <div class="col-md-3 custom-dropdown custom-floating-label" *ngIf="selectedCountryCompany === '2' || selectedCountryCompany === ''">
            <label for="inputState" class="form-label">{{ companyInfo.state }}</label>
            <select class="custom-select" formControlName="state">
              <option class="dropdown-option" value="" disabled selected hidden>Select State</option>
              <option class="dropdown-option" [value]="value.stateId" *ngFor="let value of statesCompany">{{ value.stateName }}</option>
            </select>
            <div *ngIf="(submitted || companyForm.get('companyAddress.state')?.touched) && companyForm.get('companyAddress.state')?.invalid">
              <p class="text-danger">{{ errMsg.required }}</p>
            </div>
          </div>
          <div class="col-md-3 custom-floating-label" *ngIf="selectedCountryCompany === '2' || selectedCountryCompany === ''">
            <label for="inputZipcode" class="form-label">{{ companyInfo.zipcode }}</label>
            <input type="text" class="form-control" formControlName="zipcode" placeholder="Zip Code" maxlength="5" id="inputZipcode" (blur)="companyForm.get('companyAddress.zipcode')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('companyAddress.zipcode')?.touched) && companyForm.get('companyAddress.zipcode')?.invalid">
              <p *ngIf="companyForm.get('companyAddress.zipcode').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="!companyForm.get('companyAddress.zipcode').errors?.['required'] && companyForm.get('companyAddress.zipcode').errors?.['invalidZip']" class="text-danger">{{ errMsg.postalcode }}</p>
            </div>
          </div>
          
      
          <!-- Additional country-specific fields -->
          <div class="col-md-3 custom-floating-label" *ngIf="selectedCountryCompany === '1'">
            <label for="inputPostalCode" class="form-label">{{ companyInfo.postalcode }}</label>
            <input type="text" class="form-control" formControlName="zipcode" placeholder="Postal Code" id="inputPostalCode" maxlength="12" (blur)="companyForm.get('companyAddress.zipcode')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('companyAddress.zipcode')?.touched) && companyForm.get('companyAddress.zipcode')?.invalid">
              <p *ngIf="companyForm.get('companyAddress.zipcode').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="!companyForm.get('companyAddress.zipcode').errors?.['required'] && companyForm.get('companyAddress.zipcode').errors?.['invalidPostal']" class="text-danger">{{ errMsg.postalcode }}</p>
              <p *ngIf="!companyForm.get('companyAddress.zipcode').errors?.['required'] && companyForm.get('companyAddress.zipcode').errors?.['maxlength']" class="text-danger">{{ errMsg.postalcode }}</p>
            </div>
          </div>
      
          <!-- Canada -->
          <div class="col-md-4 custom-dropdown custom-floating-label" *ngIf="selectedCountryCompany === '3'">
            <label for="inputProvince" class="form-label">{{ companyInfo.province }}</label>
            <select class="custom-select" formControlName="state">
              <option class="dropdown-option" value="" disabled selected hidden>Select Province</option>
              <option class="dropdown-option" [value]="value.stateId" *ngFor="let value of statesCompany">{{ value.stateName }}</option>
            </select>
            <div *ngIf="(submitted || companyForm.get('companyAddress.state')?.touched) && companyForm.get('companyAddress.state')?.invalid">
              <p class="text-danger">{{ errMsg.required }}</p>
            </div>
          </div>
          <div class="col-md-3 custom-floating-label" *ngIf="selectedCountryCompany === '3'">
            <label for="inputPostalCodeCanada" class="form-label">{{ companyInfo.postalcode }}</label>
            <input type="text" class="form-control" formControlName="zipcode" placeholder="Postal Code" id="inputPostalCodeCanada" maxlength="12" (blur)="companyForm.get('companyAddress.zipcode')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('companyAddress.zipcode')?.touched) && companyForm.get('companyAddress.zipcode')?.invalid">
              <p *ngIf="companyForm.get('companyAddress.zipcode').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="!companyForm.get('companyAddress.zipcode').errors?.['required'] && companyForm.get('companyAddress.zipcode').errors?.['invalidPostal']" class="text-danger">{{ errMsg.postalcode }}</p>
            </div>
          </div>
      
          <!-- Other countries -->
          <div class="col-md-3 custom-floating-label" *ngIf="selectedCountryCompany !== '1' && selectedCountryCompany !== '2' && selectedCountryCompany !== '3' && selectedCountryCompany !== ''">
            <label for="inputOtherState" class="form-label">State/Province/Region</label>
            <input type="text" class="form-control" formControlName="state" placeholder="State/Province/Region" id="inputOtherState" (blur)="companyForm.get('companyAddress.state')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('companyAddress.state')?.touched) && companyForm.get('companyAddress.state')?.invalid">
              <p *ngIf="companyForm.get('companyAddress.state').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
            </div>
          </div>
          <div class="col-md-3 custom-floating-label" *ngIf="selectedCountryCompany !== '1' && selectedCountryCompany !== '2' && selectedCountryCompany !== '3' && selectedCountryCompany !== ''">
            <label for="inputOtherPostalCode" class="form-label">{{ companyInfo.postalcode }}</label>
            <input type="text" class="form-control" formControlName="zipcode" placeholder="ZIP/Postal Code" id="inputOtherPostalCode" maxlength="12" (blur)="companyForm.get('companyAddress.zipcode')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('companyAddress.zipcode')?.touched) && companyForm.get('companyAddress.zipcode')?.invalid">
              <p *ngIf="companyForm.get('companyAddress.zipcode').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="!companyForm.get('companyAddress.zipcode').errors?.['required'] && companyForm.get('companyAddress.zipcode').errors?.['invalidGeneralPostal']" class="text-danger">{{ errMsg.postalcode }}</p>
            </div>
          </div>
          
          <div class="col-md-5 custom-floating-label last-label-margin">
            <label for="inputPhone" class="form-label">{{ companyInfo.phone }}</label>
            <input type="text" class="form-control" formControlName="phone" placeholder="Phone" id="inputPhone" maxlength="20" (blur)="companyForm.get('companyAddress.phone')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('companyAddress.phone')?.touched) && companyForm.get('companyAddress.phone')?.invalid">
              <p *ngIf="companyForm.get('companyAddress.phone').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="!companyForm.get('companyAddress.phone').errors?.['required'] && companyForm.get('companyAddress.phone').errors?.['invalidPhoneNumber']" class="text-danger">{{ errMsg.phone }}</p>
            </div>
          </div>
          
          <div>
            <label class="custom-checkbox">
              <input type="checkbox" formControlName="billingCheck" value="" (change)="onBillingCheck($event)">
              <span class="checkmark"></span>
              {{ companyInfo.billing }}
            </label>
          </div>
        </div>
      </div>
  </div>
    <!-- Add Company Billing Address Section -->

    <div class="common-modal-input-section company-billing-address" *ngIf="!hideBillingAddress">
      <h2 class="section-main-header">{{companyInfo.billingaddress}}</h2>
      <div formGroupName="billingAddress">
        <div class="row">
          <div class="col-md-4 mb-5 custom-dropdown custom-floating-label">
            <label for="country" class="form-label">{{ companyInfo.country }}</label>
            <select class="custom-select" formControlName="country" (change)="onCountryBillingAddressChange($event)">
              <option class="dropdown-option" value="" disabled selected hidden>Select Country</option>
              <option class="dropdown-option" [value]="value.countryId" *ngFor="let value of countries">{{ value.countryName }}</option>
            </select>
            <div *ngIf="(submitted || companyForm.get('billingAddress.country')?.touched) && companyForm.get('billingAddress.country')?.invalid">
              <p class="text-danger">{{ errMsg.required }}</p>
            </div>
          </div>
      
          <div class="col-md-12 custom-floating-label">
            <label for="street" class="form-label">{{ companyInfo.street }}</label>
            <input type="text" class="form-control" formControlName="street" placeholder="Street" id="street" (blur)="companyForm.get('billingAddress.street')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('billingAddress.street')?.touched) && companyForm.get('billingAddress.street')?.invalid">
              <p class="text-danger">{{ errMsg.required }}</p>
            </div>
          </div>
      
          <div class="col-md-5 custom-floating-label" *ngIf="selectedCountryBilling === '2' || selectedCountryBilling === '3'">
            <label for="city" class="form-label">{{ companyInfo.city }}</label>
            <input type="text" class="form-control" formControlName="city" placeholder="City" id="city" (blur)="companyForm.get('billingAddress.city')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('billingAddress.city')?.touched) && companyForm.get('billingAddress.city')?.invalid">
              <p class="text-danger">{{ errMsg.required }}</p>
            </div>
          </div>
      
          <div class="col-md-5 custom-floating-label" *ngIf="selectedCountryBilling === '1' || (selectedCountryBilling !== '2' && selectedCountryBilling !== '3')">
            <label for="cityTown" class="form-label">{{ companyInfo.city }}</label>
            <input type="text" class="form-control" formControlName="city" placeholder="City/Town" id="cityTown" maxlength="15" (blur)="companyForm.get('billingAddress.city')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('billingAddress.city')?.touched) && companyForm.get('billingAddress.city')?.invalid">
              <p class="text-danger">{{ errMsg.required }}</p>
            </div>
          </div>
      
          <!-- United States -->
          <div class="col-md-3 custom-dropdown custom-floating-label" *ngIf="selectedCountryBilling === '2' || selectedCountryBilling == ''">
            <label for="state" class="form-label">{{ companyInfo.state }}</label>
            <select class="custom-select" formControlName="state">
              <option class="dropdown-option" value="" disabled selected hidden>Select State</option>
              <option class="dropdown-option" [value]="value.stateId" *ngFor="let value of statesBilling">{{ value.stateName }}</option>
            </select>
            <div *ngIf="(submitted || companyForm.get('billingAddress.state')?.touched) && companyForm.get('billingAddress.state')?.invalid">
              <p class="text-danger">{{ errMsg.required }}</p>
            </div>
          </div>
       
          <div class="col-md-3 custom-floating-label" *ngIf="selectedCountryBilling === '2' || selectedCountryBilling == ''">
            <label for="zipcode" class="form-label">{{ companyInfo.zipcode }}</label>
            <input type="text" class="form-control" formControlName="zipcode"  maxLength="5" placeholder="Zip Code" id="zipcode" (blur)="companyForm.get('billingAddress.zipcode')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('billingAddress.zipcode')?.touched) && companyForm.get('billingAddress.zipcode')?.invalid">
              <p *ngIf="companyForm.get('billingAddress.zipcode').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="!companyForm.get('billingAddress.zipcode').errors?.['required'] && (companyForm.get('billingAddress.zipcode').errors?.['invalidZip'] || companyForm.get('billingAddress.zipcode').errors?.['maxlength'])" class="text-danger">{{ errMsg.postalcode }}</p>
            </div>
            
          </div>
      
          <!-- United Kingdom -->
          <div class="col-md-3 custom-floating-label" *ngIf="selectedCountryBilling === '1'">
            <label for="postalcodeUK" class="form-label">{{ companyInfo.postalcode }}</label>
            <input type="text" class="form-control" formControlName="zipcode" placeholder="Postal Code" id="postalcodeUK" maxlength="12" (blur)="companyForm.get('billingAddress.zipcode')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('billingAddress.zipcode')?.touched) && companyForm.get('billingAddress.zipcode')?.invalid">
              <p *ngIf="companyForm.get('billingAddress.zipcode').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="!companyForm.get('billingAddress.zipcode').errors?.['required'] && (companyForm.get('billingAddress.zipcode').errors?.['invalidPostal'] || companyForm.get('billingAddress.zipcode').errors?.['maxlength'])" class="text-danger">{{ errMsg.postalcode }}</p>
            </div>
          </div>
      
          <!-- Canada -->
          <div class="col-md-4 custom-dropdown custom-floating-label" *ngIf="selectedCountryBilling === '3'">
            <label for="province" class="form-label">{{ companyInfo.province }}</label>
            <select class="custom-select" formControlName="state">
              <option class="dropdown-option" value="" disabled selected hidden>Select Province</option>
              <option class="dropdown-option" [value]="value.stateId" *ngFor="let value of statesBilling">{{ value.stateName }}</option>
            </select>
            <div *ngIf="(submitted || companyForm.get('billingAddress.state')?.touched) && companyForm.get('billingAddress.state')?.invalid">
              <p class="text-danger">{{ errMsg.required }}</p>
            </div>
          </div>
      
          <div class="col-md-3 custom-floating-label" *ngIf="selectedCountryBilling === '3'">
            <label for="postalcodeCanada" class="form-label">{{ companyInfo.postalcode }}</label>
            <input type="text" class="form-control" formControlName="zipcode" placeholder="Postal Code" id="postalcodeCanada" maxlength="12" (blur)="companyForm.get('billingAddress.zipcode')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('billingAddress.zipcode')?.touched) && companyForm.get('billingAddress.zipcode')?.invalid">
              <p *ngIf="companyForm.get('billingAddress.zipcode').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="!companyForm.get('billingAddress.zipcode').errors?.['required'] && (companyForm.get('billingAddress.zipcode').errors?.['invalidPostal'] || companyForm.get('billingAddress.zipcode').errors?.['maxlength'])" class="text-danger">{{ errMsg.postalcode }}</p>
            </div>
          </div>
      
          <!-- Other countries -->
          <div class="col-md-3 custom-floating-label" *ngIf="selectedCountryBilling !== '1' && selectedCountryBilling !== '2' && selectedCountryBilling !== '3' && selectedCountryBilling !== ''">
            <label for="otherState" class="form-label">State/Province/Region</label>
            <input type="text" class="form-control" formControlName="state" placeholder="State/Province/Region" id="otherState" (blur)="companyForm.get('billingAddress.state')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('billingAddress.state')?.touched) && companyForm.get('billingAddress.state')?.invalid">
              <p *ngIf="companyForm.get('billingAddress.state').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
            </div>
          </div>
      
          <div class="col-md-3 custom-floating-label" *ngIf="selectedCountryBilling !== '1' && selectedCountryBilling !== '2' && selectedCountryBilling !== '3' && selectedCountryBilling !== ''">
            <label for="otherPostalCode" class="form-label">{{ companyInfo.postalcode }}</label>
            <input type="text" class="form-control" formControlName="zipcode" placeholder="ZIP/Postal Code" id="otherPostalCode" maxlength="12" (blur)="companyForm.get('billingAddress.zipcode')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('billingAddress.zipcode')?.touched) && companyForm.get('billingAddress.zipcode')?.invalid">
              <p *ngIf="companyForm.get('billingAddress.zipcode').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="!companyForm.get('billingAddress.zipcode').errors?.['required'] && (companyForm.get('billingAddress.zipcode').errors?.['invalidGeneralPostal'] || companyForm.get('billingAddress.zipcode').errors?.['maxlength'])" class="text-danger">{{ errMsg.postalcode }}</p>
            </div>
          </div>
      
          <div class="col-md-5 custom-floating-label">
            <label for="phone" class="form-label">{{ companyInfo.phone }}</label>
            <input type="text" class="form-control" formControlName="phone" placeholder="Phone" id="phone" maxlength="20" (blur)="companyForm.get('billingAddress.phone')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('billingAddress.phone')?.touched) && companyForm.get('billingAddress.phone')?.invalid">
              <p *ngIf="companyForm.get('billingAddress.phone').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="!companyForm.get('billingAddress.phone').errors?.['required'] && companyForm.get('billingAddress.phone').errors?.['invalidPhoneNumber']" class="text-danger">{{ errMsg.phone }}</p>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <!-- Add company Federeal Tax Section -->

    <div class="common-modal-input-section company-fed-tax" *ngIf="portalType !== 'customer'">
      <h2 class="section-main-header">{{companyInfo.federal}}</h2>
      <div formGroupName="federal">
        <div class="row">
          <div class="col-md-12 custom-floating-label">
            <label for="federalTaxId" class="form-label">{{ companyInfo.federallabel }}</label>
            <input type="text" formControlName="federalTaxId" class="form-control" placeholder="TIN" id="federalTaxId" maxlength="15" (blur)="companyForm.get('federal.federalTaxId')?.markAsTouched()">
            <div *ngIf="(submitted || companyForm.get('federal.federalTaxId')?.touched) && companyForm.get('federal.federalTaxId')?.invalid">
              <p *ngIf="companyForm.get('federal.federalTaxId').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="!companyForm.get('federal.federalTaxId').errors?.['required'] && (companyForm.get('federal.federalTaxId').errors?.['invalidFederalTax'] || companyForm.get('federal.federalTaxId').errors?.['maxlength'])" class="text-danger">{{ errMsg.fedTaxId }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Company Contacts Section -->

    <div class="common-modal-input-section">
      <h2 class="section-main-header">{{companyInfo.contacts}}</h2>
      <div formArrayName="contacts" *ngFor="let contact of contacts.controls; let i = index">
        <div [formGroupName]="i" class="row">
          <div class="col-md-6 custom-floating-label">
            <label for="firstName{{i}}" class="form-label">{{companyInfo.firstname}}</label>
            <input type="text" formControlName="firstName" class="form-control" placeholder="First Name" id="firstName{{i}}" (blur)="contact.get('firstName')?.markAsTouched()"  (input)="contact.get('firstName')?.updateValueAndValidity()">
            <div *ngIf="(submitted || contact.get('firstName')?.invalid) && contact.get('firstName')?.touched || contact.get('firstName')?.dirty">
              <p *ngIf="contact.get('firstName')?.errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="contact.get('firstName')?.errors?.['invalidAlphabets'] && !contact.get('firstName')?.errors?.['required']" class="text-danger">First Name is not valid</p>
              <p *ngIf="contact.get('firstName')?.errors?.['multipleSpaces'] " class="text-danger">{{addCompanyUser.twospaceerror}}</p>
              <p *ngIf="contact.get('firstName')?.errors?.['invalidSpace'] " class="text-danger">{{ addCompanyUser.spaceerror }}</p>
            </div>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="lastName{{i}}" class="form-label">{{companyInfo.lastname}}</label>
            <input type="text" formControlName="lastName" class="form-control" placeholder="Last Name" id="lastName{{i}}" (blur)="contact.get('lastName')?.markAsTouched()" (input)="contact.get('lastName')?.updateValueAndValidity()">
            <div *ngIf="(submitted || contact.get('lastName')?.invalid) && (contact.get('lastName')?.touched || contact.get('lastName')?.dirty)">
              <p *ngIf="contact.get('lastName').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="contact.get('lastName').errors?.['invalidName'] && !contact.get('lastName')?.errors?.['required']" class="text-danger">Last Name is not valid</p>
              <p *ngIf="contact.get('lastName')?.errors?.['multipleSpaces'] " class="text-danger">{{addCompanyUser.twospaceerror}}</p>
              <p *ngIf="contact.get('lastName')?.errors?.['invalidSpace'] " class="text-danger">{{ addCompanyUser.spaceerror }}</p>
            </div>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="email{{i}}" class="form-label">{{companyInfo.email}}</label>
            <input type="email" formControlName="email" class="form-control" placeholder="Email" id="email{{i}}" (blur)="contact.get('email')?.markAsTouched()">
            <div *ngIf="(submitted || contact.get('email')?.touched) && contact.get('email')?.invalid">
              <p *ngIf="contact.get('email').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="contact.get('email').errors?.['email']" class="text-danger">{{ errMsg.email }}</p>
            </div>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="jobTitle{{i}}" class="form-label">{{companyInfo.jobtitle}}</label>
            <input type="text" formControlName="jobTitle" class="form-control" placeholder="Job Title" id="jobTitle{{i}}" (blur)="contact.get('jobTitle')?.markAsTouched()">
            <div *ngIf="(submitted || contact.get('jobTitle')?.touched) && contact.get('jobTitle')?.invalid">
              <p class="text-danger">{{errMsg.required}}</p>
            </div>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="phone{{i}}" class="form-label">{{companyInfo.phone}}</label>
            <input type="text" formControlName="phone" class="form-control" placeholder="Phone" id="phone{{i}}" maxlength="20" (blur)="contact.get('phone')?.markAsTouched()">
            <div *ngIf="(submitted || contact.get('phone')?.touched) && contact.get('phone')?.invalid">
              <p *ngIf="contact.get('phone').errors?.['required']" class="text-danger">{{ errMsg.required }}</p>
              <p *ngIf="!contact.get('phone').errors?.['required'] && contact.get('phone').errors?.['invalidPhoneNumber']" class="text-danger">{{ errMsg.phone }}</p>
            </div>
          </div>
          <div class="col-md-6 text-end">
            <p class="remove-contact" 
               (click)="removeContact(i)" 
               [ngClass]="{
                 'remove-contact-disabled': contacts?.length <= 1,
                 'remove-contact-blue': contacts?.length >= 2
               }">
              {{ companyInfo.removecontact }}
            </p>
          </div>
        </div>
      </div>
      
      <span class="add-contact text-link" (click)="addContact()">+ {{companyInfo.addcontact}}</span>
      <div *ngIf="submitted && contacts?.length === 0">
        <p class="text-danger">{{errMsg.contact}}</p>
      </div>
  </div>
  
    <div class="cm-btn-section" *ngIf="modalType !== 'edit'">
      <button type="button" (click)="addCompanyData()" [disabled]="!companyForm.valid" class="btn common-btn-light me-2" >
        {{companyInfo.addcompany}}
      </button>
    </div>
    <div class="cm-btn-section" *ngIf="modalType == 'edit'">  
      <button type="button" class="btn common-btn-light me-2 mb-2"
      data-bs-dismiss="modal" (click)="closeModal()">{{companyInfo.discardchanges}}</button>
      <button type="button" class="btn common-btn-secondary me-2 mb-2"
        data-bs-dismiss="modal" [disabled]="!companyForm.valid || !isFormModified()" (click)="portalType == 'studio' ? editCompanyData() : editCustCompanyData()" >{{companyInfo.savechanges}}</button>
      </div>

     </form>
  </div>
</div>

<!--common modal section ends-->
<!-- s -->
<ng-template #existcompany let-modal>
  <div class="modal-body">
    <label>{{fedTaxValid.existingMsg}}:</label>
    <div *ngIf="existingCompanies?.length != 0">
   
        <label class="mb-3">Exisiting:</label>
        <ng-container *ngFor="let company of existingCompanies">
          <p class="mb-0 title-bold">{{company.companyName}}</p>
          <p>ID: {{company.companyId}}</p>
        </ng-container>
    </div>
    <div>
        <label>New Company :</label>
        <p class="title-bold">{{newCompanyName}}</p>
    </div>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-secondary btn" (click)="addNew()">{{fedTaxValid.addBtn}}</button>
      <button *ngIf="existingCompanies?.length > 1" type="button" class="btn common-btn-light me-2 mb-2"
        data-bs-dismiss="modal" (click)="closeFedTaxModal()">{{fedTaxValid.cancel}}</button>
      <button *ngIf="existingCompanies?.length == 1" type="button" class="common-btn-light btn" (click)="goToExist()">{{fedTaxValid.goToExistingBtn}}</button>

    </div>
  </div>
</ng-template>
<!-- s -->
<ng-template #existcompanyedit let-modal>
  <div class="modal-body">
    <label>{{fedTaxValid.existingEditMsg}}:</label>
    <div *ngIf="existingCompanies?.length != 0">
   
        <label class="mb-3">Exisiting:</label>
        <ng-container *ngFor="let company of existingCompanies">
          <p class="mb-0 title-bold">{{company.companyName}}</p>
          <p>ID: {{company.companyId}}</p>
        </ng-container>
    </div>
    <div>
        <label>New Company :</label>
        <p class="title-bold">{{newCompanyName}}</p>
    </div>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-secondary btn" (click)="addNew()">{{fedTaxValid.saveChanges}}</button>
      <button *ngIf="existingCompanies?.length > 1" type="button" class="btn common-btn-light me-2 mb-2"
        data-bs-dismiss="modal" (click)="closeFedTaxModal()">{{fedTaxValid.cancel}}</button>
      <button *ngIf="existingCompanies?.length == 1" type="button" class="common-btn-light btn" (click)="goToExist()">{{fedTaxValid.goToExistingBtn}}</button>    </div>
  </div>
</ng-template>
<ng-template #successTpl>
	<div class="success">
    {{ modalType !== 'edit' ? message.addCompanySuccessMessage : message.editCompanySuccessMessage }}
  </div>
</ng-template>

<ng-template #dangerActionTpl>
<div class="total-action-content-danger d-flex flex-column align-items-start">
    <div class="inner-content-danger">
      {{ modalType !== 'edit' ? message.addCompanyErrorMessage : message.editCompanyErrorMessage }}
    </div>
</div>
</ng-template>
<ng-template #dangerActionTplAPI>
  <div class="total-action-content-danger d-flex flex-column align-items-start">
      <div class="inner-content-danger">
        {{ modalType !== 'edit' ? addCompanyErrorMsgAPI : editCompanyErrorMsgAPI }}
      </div>
  </div>
  </ng-template>
  <ng-template #WarningMsgModal let-modal>
    <div class="modal-body">
      <h4 class="section-main-header">{{ confirmClosePopup.confirmCompanyCloseMsg }}</h4>
      <div class="d-flex justify-content-end gap-2">
        <button type="button" class="common-btn-secondary btn" (click)="closeModal();modal.close()">
          {{ confirmClosePopup.yesBtn }}
        </button>
        <button type="button" class="common-btn-light btn" (click)="modal.close()">
          {{ confirmClosePopup.noBtn }}
        </button>
      </div>
    </div>
    </ng-template>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>
