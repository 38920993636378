<!-- Help Slider -->
<div [ngClass]="{'show': helpSliderVisible}" [class.noPaddingTop]="noPaddingTop" class="sidebar helpsidebar bg-light">
  <div [ngClass]="{'show': helpSliderVisible}" class="help-sidebar-section">
    <div class="">
      <div class="d-flex justify-content-end mb-3"><span class="close-btn clickable"
          (click)="closeHelpSlider()">{{helpContent.close}} </span></div>
    </div>
    <div class="helper-content-section">
      <h3>{{ helpContent.title }}</h3>
      <p>{{ helpContent.content }}</p>
    </div>
    <div class="helper-content-section">
      <h3>{{ helpContent.title }}</h3>
      <p>{{ helpContent.content }}</p>
    </div>
    <div class="helper-content-section">
      <h3>{{ helpContent.help }}</h3>
      <p>{{helpContent.faqs}} <span class="blue-text clickable"><a
            (click)="navigateToFaqs()">{{helpContent.linkText}}</a></span></p>
    </div>
  </div>
</div>