import { Component } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-studio-projects',
  templateUrl: './studio-projects.component.html',
  styleUrls: ['./studio-projects.component.scss']
})
export class StudioProjectsComponent {
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  public dropdownSettings: IDropdownSettings = {};
  ngOnInit() {
    this.dropdownList = [
      { item_id: 1, item_text: 'Project Name' },
      { item_id: 2, item_text: 'Project Name' },
      { item_id: 3, item_text: 'Project Name' },
      { item_id: 4, item_text: 'Project Name' },
      { item_id: 5, item_text: 'Project Name' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      showSelectedItemsAtTop: false
    };
  }
  onItemSelect(item: any) {
    console.warn(item);
  }
  onSelectAll(items: any) {
    console.warn(items);
  }
}
