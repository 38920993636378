// email-action.component.ts
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailActionService } from '../email-actio.service';

@Component({
  selector: 'app-email-action',
  templateUrl: './email-action.component.html',
  styleUrls: ['./email-action.component.scss']
})
export class EmailActionComponent implements OnInit {
  errorMessage: string = '';
  successMessage: string = '';
  loading: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private emailActionService: EmailActionService,
    private router: Router, // Inject Router

  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let token = params['token'] ? decodeURIComponent(params['token']) : null;
      const actionType = params['actionType'];

      if (token) {
        token = token.replace(/ /g, '+');
      }
      if (token && actionType) {
        this.redirectToBackend(token, actionType);
        this.handleAction(token, actionType);
      } else {
        this.showMessage('No token or actionType found in URL', false);
      }
    });
  }

  private handleAction(token: string, actionType: string): void {
    switch (actionType.toLowerCase()) {
      case 'downloadfullform':
        this.handleEmailAction(token, actionType);
        break;
      case 'accept_risk':
        this.performRiskAction(token, 'ACCEPT_RISK');
        break;
      case 'decline_risk':
        this.performRiskAction(token, 'DECLINE_RISK');
        break;
      default:
        this.showMessage('Invalid action type', false);
    }
  }


  private performRiskAction(token: string, actionType: string): void {
    const requestBody = {
      token: token,
      actionType: actionType
    };

    this.emailActionService.performRiskAction(requestBody).subscribe({
      next: (response: any) => {
        if (response.success) {
          const message = actionType === 'ACCEPT_RISK' ? 'Risk accepted successfully' : 'Risk declined successfully';
          this.showMessage(message, true);
        } else {
          this.showMessage('Failed to perform action', false);
        }
      },
      error: (error) => {
        this.showMessage(`Error: ${error.message}`, false);
      }
    });
  }
 
  private handleEmailAction(token: string, actionType: string): void {
    this.loading = true;
    const requestBody = {
      token: token,
      actionType: actionType
    };

    this.emailActionService.downloadFullForm(requestBody).subscribe({
      next: (response: any) => {
        this.loading = false;
        if (response && response.result && response.result.body) {
          const contentDisposition = response.result.headers['Content-Disposition'][0];
          const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
          const fileName = fileNameMatch ? fileNameMatch[1] : 'downloaded-file.pdf';

          if (actionType.toLowerCase() === 'downloadfullform') {
            this.downloadBase64File(response.result.body, fileName);
          }
        } else {
          console.error('No base64 data found in response');
          this.showMessage('Error: No base64 data found in response', false);
        }
      },
      error: (error) => {
        this.loading = false;
        this.showMessage(`Error: ${error.message}`, false);
      }
    });
  }
private downloadBase64File(base64Data: string, fileName: string): void {
    try {
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      this.showMessage('File downloaded successfully', true);
      setTimeout(() => {
        this.router.navigate(['/page-not-found']); // Adjust the route as needed
      }, 5000); // 5 seconds delay

    } catch (error) {
      console.error('Error downloading file:', error);
      this.showMessage(`Error downloading file: ${error}`, false);
    }
  }

  private redirectToBackend(token: string, actionType: string): void {
    try {
      const backendUrl = `https://greenlight-dev.wbd.com/rismailtier2?token=${encodeURIComponent(token)}&actionType=${encodeURIComponent(actionType)}`;
      window.location.href = backendUrl; // Redirect to the backend URL
    } catch (error) {
      console.error(`Error in redirect: ${error}`);
      this.showMessage(`Error during redirect: ${error}`, false);
    }
  }
  
  private showMessage(message: string, isSuccess: boolean) {
    if (isSuccess) {
      this.successMessage = message;
      this.errorMessage = '';
    } else {
      this.errorMessage = message;
      this.successMessage = '';
    }

    setTimeout(() => {
      this.successMessage = '';
      this.errorMessage = '';
    }, 5000);
  }

  private isValidBase64(str: string): boolean {

    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }
}
