<div>
    <button
      class="btn btn-primary"
      (click)="openDeleteConfirmationModal(content)"
    >
      hello
    </button>
  </div>
  <ng-template #content let-modal>
    <div class="page-wrapper-modal">
      <div class="page-header">
        <h2 class="mb-0">Send Letter of Approval</h2>
      </div>
      <div class="title-company-project">
        <div class="company">
          <span class="company-heading">Company:</span>
          <span class="company-content">A Brand New Production Company</span>
        </div>
        <div class="project">
          <span class="project-heading">Project:</span>
          <span class="project-content">The New Show</span>
        </div>
      </div>
      <div class="refrences">
          <div class="db">
            <h3 class="mb-0">Accounts payable contact(s):</h3>
            <div class="content">
              <div class="all-contents">
                <span>-</span><span>Jane Doe (jdoe@domain.com)</span>
              </div>
              <div class="action-add-edit d-flex align-items-center">
                  <a>
                  <img src="assets/images/edit-icon.svg">
                  <span class="text-link ms-1">Add/Edit New</span>
              </a>
              </div>
            </div>
          </div>
        </div>
      <div class="section-text-area">
        <textarea class="text-area" id="credit-text" name="credit-text">
Dear Accounts Payable,

Having reviewed your application for credit, Warner Bros. Studio Operations (WBSO) is pleased to advise you that we have established an account for you. WBSO would like to take this opportunity to welcome you as a customer and acquaint you with our practices. Please read the letter in attachments.
Please contact WBSFAR@warnerbros.com if there are any questions.

Thanks,
WBSO A/R</textarea
        >
      </div>
      <div class="w-100">
        <app-upload-file></app-upload-file>
      </div>
    </div>
    <div class="end-wrapper">
      <div class="button">
        <button class="common-btn-light btn">Cancel</button>
        <button class="common-btn-secondary btn">Send to Customer</button>
      </div>
    </div>
  </ng-template>
  