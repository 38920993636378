import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { header } from '../../constants/core.constant';
import { OktaAuth } from '@okta/okta-auth-js';
import { FormControl, FormGroup } from '@angular/forms';
import { OKTA_AUTH } from '@okta/okta-angular';
import { Router } from '@angular/router';
import { ToastService } from '../../../shared/components/toast.service';
import { HeaderService } from '../../services/header/header.service';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../services/user-service/user-service.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constants = header;
  title = 'studios-portal-app';
  sidebarVisible = false;
  noPaddingTop = false;
  userTypeId: number;
  currentPortal: any;
  portalType: string;
  addUserSuccess: any;
  addUserError: any;
  clearCache: any;
  s3BucketUrl: string = environment.s3BucketUrl; // Set your base API URL here
  logoPath: string;
  userDetails: any;
  isLoggedIn:boolean = false;
  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;

  }
  constructor(
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private router: Router,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private toastService: ToastService,
    private headerService: HeaderService,
    private userService: UserService
  ) {
  }
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;

  ngOnInit(): void {
    this._oktaAuth.authStateManager.subscribe((authState) => {
      this.isLoggedIn = authState.isAuthenticated;
    });
    this._oktaAuth.isAuthenticated().then((authenticated) => {
      this.isLoggedIn = authenticated;
    });
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.userTypeId = userProfile?.userTypeId;
    if (this?.userTypeId == 1) {
      this.portalType = 'customer';
      this.isLoggedIn = true;
    } else if (this?.userTypeId == 2) {
      this.portalType = 'studio';
      this.isLoggedIn = true;
    }

    this.logoPath = `${this.s3BucketUrl}/logos/wb-logo-white.svg`;
    this.getUserInfo();
  }
  ngAfterViewInit() {
    this.renderer.listen('document', 'click', (event: Event) => {
      const target = event.target as HTMLElement;
      if (this.sidebarVisible && !this.elRef.nativeElement.contains(target)) {
        this.toggleSidebar();
      }
    });
  }

  sticky: boolean = false;
  stickyPoint: number = 100;
  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    this.sticky = windowScroll >= this.stickyPoint;
    this.noPaddingTop = windowScroll >= this.stickyPoint;
  }

  async oktaAuthentication() {
    await this._oktaAuth
      .signInWithRedirect()
      .then((_) => this.router.navigate(['/homepage']))
      .catch((Error) => {
        console.log(Error);
      });
  }

  async logout() {
    await this.headerService.clearCache().subscribe({
      next: (data: any) => {
        this.clearCache = data.message;
      },
      error: (error: any) => { },
    });
    await this._oktaAuth.signOut();
    localStorage.clear();
    this._oktaAuth.tokenManager.clear();
    localStorage.removeItem('access-token');
    localStorage.removeItem('okta-token-storage');
  }

  loginUser(): void {
    this.oktaAuthentication();
  }

  /**
   * getUserInfo() - used to get data from localstorage
   */
  getUserInfo(): void {
    this.userService.userInfo$.subscribe((userInfo) => {
      if (userInfo) {
        this.userDetails = userInfo?.userProfileDTO;
        this.userTypeId = this.userDetails?.userTypeId;
        this.portalType = this.userDetails?.portalType[0];
        this.isLoggedIn = true;
      }
    });
  }

  /**
   * redirectToDasboard() - the user able to redirect to specific dashboard based on portal
   */
  redirectToDashboard(): void {
    switch (this.portalType) {
      case 'customer':
        this.router.navigate(['/feature/customer-dashboard/overview']);
        break;
      case 'studio':
        this.router.navigate(['/feature/studio-dashboard/users']);
        break;
      case 'ar':
        this.router.navigate(['/feature/ar-dashboard/overview']);
        break;
      default:
        console.log('Unknown portal type');
    }
  }

  /**
* navigateOnLogoClick() - Navigates the user based on their login status.
*/
  navigateOnLogoClick(): void {
    if (this.isLoggedIn) {
      this.redirectToDashboard();
    } else {
      this.router.navigate(['/homepage']); // Adjust this path to your actual homepage route
    }
  }

  /**
   * navigateToCompany() - it will naviagate to company based on typeId
   */
  navigateToCompany(): void {
    switch (this.userTypeId) {
      case 1:
        this.router.navigate(['/feature/customer-dashboard/company']);
        break;
      case 2:
        this.router.navigate(['/feature/studio-dashboard/company/companies']);
        break;
      case 3:
        this.router.navigate(['/feature/ar-dashboard/company']);
        break;
    }
    this.sidebarVisible = !this.sidebarVisible;
  }

  /**
   * navigateToProjects() - it will navigate to projects based on typeId
   */
  navigateToProjects(): void {
    switch (this.userTypeId) {
      case 1:
        this.router.navigate(['/feature/customer-dashboard/projects-list']);
        break;
      case 2:
        this.router.navigate([
          '/feature/studio-dashboard/project/project-list',
        ]);
        break;
    }
    this.sidebarVisible = !this.sidebarVisible;
  }

  /**
   * navigateToUsers() - it will navigate to users page based on typeId
   */
  navigateToUsers() {
    switch (this.userTypeId) {
      case 1:
        this.router.navigate(['/feature/customer-dashboard/users']);
        break;
      case 2:
        this.router.navigate(['/feature/studio-dashboard/users']);
        break;
    }
    this.sidebarVisible = !this.sidebarVisible;
  }

  /**
   * navigateToDashboard() - it will navigate to dashboard according to typeId
   */
  navigateToDashboard(): void {
    switch (this.userTypeId) {
      case 1:
        this.router.navigate(['/feature/customer-dashboard/overview']);
        break;
      case 2:
        this.router.navigate(['/feature/studio-dashboard/users']);
        break;
      case 3:
        this.router.navigate(['/feature/ar-dashboard/overview']);
        break;
    }
    this.sidebarVisible = !this.sidebarVisible;
  }

  /**
   * showSuccess() - Displays a success toast notification with custom content and options.
   * @param successTpl - The template reference containing the toast's content.
   */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 10000,
    });
  }

  /**
   * showDanger() - Displays a danger (error) toast message.
   * This method is called when there is an error in adding the user.
   * @param dangerTpl - The template reference for the danger message to display.
   */
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 10000,
    });
  }
}