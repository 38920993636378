import { Injectable } from '@angular/core';
import { GLOBAL_API_PATHS } from '../../../../assets/constants/apiPaths';
import { BaseApiService } from '../../../shared/services/base-api/base-api.service';
import { HttpHeaders } from '@angular/common/http';
import { end } from '@popperjs/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(public baseApiService: BaseApiService) { }

/**
* getuserprofile - Invoke the data search for the logged in user profile.
* @param userId - userId input is expected to search the user profile.
* @returns An Observable that emits a user information as response.
*/

getuserprofile(userId: Number) {
  let endpoint = `${GLOBAL_API_PATHS.user_profile}?userProfileId=${userId}`;
  return this.baseApiService.get(endpoint);
}

/**
 * updateuserprofile() - to update user profile
 * @param data - the data which needs to send to backend 
 * @returns an observable with seccessfull reponse 
 */
updateuserprofile(data: any){
  let endpoint = GLOBAL_API_PATHS.user_profile;
  return this.baseApiService.put(endpoint, data )
}

/**
 * getUserProfileMetaData() - to retrieve the meta data
 * @returns - an observable with meta data
 */
getUserProfileMetaData(){
  let endpoint = GLOBAL_API_PATHS.user_profile_meta_data
  return this.baseApiService.get(endpoint)
}


  /**
* getuserinfo - Invoke the data search for the logged in user profile.
* @param email - user mail input is expected to search the user profile.
* @returns An Observable that emits a user information as response.
*/

  getuserinfo(email) {
    let endpoint = GLOBAL_API_PATHS.user_info + '?' + 'emailId=' + email;
    return this.baseApiService.get(endpoint);
  }

  /**
* getauth - Invoke the first api in the application to get the API authentication.
* @returns An Observable that emits a user information as response.
*/

  getauth() {
    let endpoint = GLOBAL_API_PATHS.auth_login;
    return this.baseApiService.get(endpoint);
  }

  /**
* clearCache - Invoke the clear cache opertaion in backend to clear the token validation.
* @returns An Observable that emits a success message as response.
*/

  clearCache() {
    let endpoint = GLOBAL_API_PATHS.clear_cache;
    return this.baseApiService.put(endpoint, '');
  }
}
