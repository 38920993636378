import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor() { }

  toasts: any[] = []; // Array to hold active toast messages

  /**
   * show() - Method to add a new toast to the array of toasts
   * @param textOrTpl - Can be a string message or a TemplateRef for custom toast content
   * @param options - Additional options for the toast such as classname or delay
   */
  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    // Add the new toast message to the toasts array
    this.toasts.push({ textOrTpl, ...options });
  }

  /**
   * remove() - Method to remove a specific toast from the array
   * @param toast - The toast object to remove
   */
  remove(toast: any) {
    // Filter out the specific toast from the toasts array
    this.toasts = this.toasts.filter(t => t !== toast);
  }

    /**
   * clear() - Clears all active toasts, ensuring no toasts are currently displayed.
   */
    clear() {
      this.toasts = [];
    }
}
