import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appClickableLinks]'
})
export class ClickableLinksDirective {

  constructor() { }

  @HostListener('click', ['$event'])

  // Method to check the clicked email content has any links and navigate to them in a new tab
  public onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    
    if (target.tagName === 'A') {
      event.preventDefault();
      const href = (target as HTMLAnchorElement).href;

      // Condition added to check whether it's a mailto link or weblink
      if (href.startsWith('mailto:')) {
        window.location.href = href;
      } else {
        window.open(href, '_blank');
      }
    }
  }

}