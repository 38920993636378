<div>
  <button
    class="btn btn-primary"
    (click)="openDeleteConfirmationModal(content)"
  >
    hello
  </button>
</div>

<ng-template #content let-modal>
  <div class="page-wrapper-modal">
    <div class="page-header">
      <h2 class="mb-0">Upload Coversheet</h2>
    </div>
    <div class="w-100">
      <app-upload-file></app-upload-file>
    </div>
  </div>
  <div class="end-wrapper">
    <div class="button">
      <button class="common-btn-light btn">Cancel</button>
      <button class="common-btn-secondary btn">Save</button>
    </div>
  </div>
</ng-template>