import { Component } from '@angular/core';
import { LoaderService } from '../../shared/services/loader/loader.service';

@Component({
  selector: 'app-studio-portal',
  templateUrl: './studio-portal.component.html',
  styleUrls: ['./studio-portal.component.scss']
})
export class StudioPortalComponent {
  loading: boolean = false;
  overlayState: boolean = false;
  portalType : string;
  constructor(private loaderService: LoaderService) { }
  ngOnInit() {
    this.loaderService.loading$.subscribe((loading) => {
      this.loading = loading;
    });
    this.loaderService.overlayState$.subscribe((applying) => {
      this.overlayState = applying;
    });
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    if (userProfile?.userTypeId == 1) {
      this.portalType = "customer";
    }
    else if (userProfile?.userTypeId == 2) {
      this.portalType = "studio";
    }
  }
}
