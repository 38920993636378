<p>studio-email-tire-one works!</p>
<button data-bs-toggle="modal" data-bs-target="#credApp">Risk Email Tier 1</button>
<!-- common modal section-->
<div class="modal fade" id="credApp" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="false">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
          <h5 class="modal-title" id="commonModalLabel">Credit Application</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
              class="cm-close-text">Close</span></button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
              <div class="company-meta-data">
                <p class="title mb-0">
                  <strong class="common-title">PROJECT : </strong
                  ><span class="common-content">The new show</span>
                </p>
                <p class="title mb-0 pb-2">
                  <strong class="common-title">Comapny : </strong>
                </p>
                <p class="common-content pb-2 pt-0 mb-0">
                  A Brand New Production Company
                </p>
                <p class="title mb-0">
                  <strong class="common-title">TYPE : </strong
                  ><span class="common-content">Major Studio</span>
                </p>
                <p class="title mb-0">
                  <strong class="common-title">APPLICATION DATE : &nbsp;</strong
                  ><span class="common-content">1/1/24</span>
                </p>
                <div class="d-flex flex-column mt-2">
                  <span class=" mb-1"><a class=""><img src="../assets/images/link-icon.svg" alt="" class="me-2 "><span class="text-link">View Projects & Quotes</span></a></span>
                  <span class=" mb-1"><a class="" ><img src="../assets/images/link-icon.svg" alt="" class="me-2 "><span class="text-link">View Company</span></a></span>

                </div>
              </div>            
            
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="container cm-main-container px-0 mx-0">

                <div class="common-modal-input-section grey-bg">
                  <h2 class="section-main-header">Accept Risk before <span class="text-red"> /1/24 3:25pm</span></h2>
                  <div class="mt-4">
                    <p>Hi All,</p>
 
                    <p class="title-content">A/R received a credit request for a new account from the following customer: <strong>Brand New Prod Company.</strong></p>
                    
                    <p class="title-content">The customer has not met the WBSO Credit criteria and they are not associated with a major studio or on our <a class="text-link" href="#"><strong>approved guarantors list.</strong> </a></p>
                    
                    <p class="title-content">Please note that the customer has not been notified that a line of credit will not be granted. However, A/R wanted to verify with the departments would like to accept the risk for this customer for all your respective departments and expense any amount deemed uncollectible from each group.</p>
                     
                    <p class="title-content"><strong>respond with your approval along with your SVP approval if the department would like to accept the risk and the credit limit for the customer.</strong> If A/R does not receive the approval from the departments, A/R will send the COD notification to the customer within 24 hours from sending the Customer Risk Approval - Financial Responsibility email.</p>
                  </div>
                  
                </div>

                <div class="common-modal-input-section grey-bg">
                    <h2 class="section-main-header">Departments Selected to be Used by Customer</h2>
                    <div class="mt-4">
                      <p class="mb-0 title-content">- Studio Operations</p>
                      <p class="mb-0 title-content">- PPCS</p>
                      <p class="mb-0 title-content">- Transportation</p>
                      <p class="mb-0 title-content">- Design Studio</p>
                      <p class="mb-0 title-content">- Property</p>
                      
                    </div>
                    
                  </div>

                  <div class="common-modal-input-section grey-bg">
                    <h2 class="section-main-header">Credit Line Requested</h2>
                    <div class="mt-4">
                      <p class="title-content">$1,000,000.00</p> 
                      
                    </div>                    
                  </div>

                  <div class="common-modal-input-section grey-bg">
                    <h2 class="section-main-header">Summary Evaluation</h2>
                    <div class="mt-4">
                      <h4  class="summary-sub-heading">Bank Reference:</h4>
                      <p class="ps-4 title-content">N/A</p>

                      <h4 class="summary-sub-heading">Trade References:</h4>
                      <p class="ps-4 title-content">Two Trades responded; Highest amount billed is $26,000.00</p>
                      <h4 class="summary-sub-heading">D&B : </h4>.
                      <p class="ps-4 mb-0 title-content">- Deliquency Score: <strong>88</strong></p>
                      <p class="ps-4 mb-0 title-content">- Failure Score: <strong>67</strong></p>
                      <p class="ps-4 mb-0 title-content">- PAYDEX® Score: <strong>22</strong></p>                      
                    </div>                    
                  </div>

                             
                  <div class="grey-box">
                    <div class="container">
                        <div class="text-end">
                            
                              <div class="btn-common-dropdown w-100">
                                <div ngbDropdown class="d-inline-block">
                                <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                                <img *ngIf="selectedAction.img" [src]="selectedAction.img" alt="" style="margin-right: 5px;">
                                      {{ selectedAction.text }}
                                </button>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                <button *ngFor="let action of actions" ngbDropdownItem (click)="updateButtonText(action)">
                                <img [src]="action.img" alt=""> {{ action.text }}
                                </button>
                                </div>
                                </div>
                                </div>
                        </div>
                    </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
 
  </div>

</div>
  