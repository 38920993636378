<div>
  <button
    class="btn btn-primary"
    (click)="openDeleteConfirmationModal(content)"
  >
    hello
  </button>
</div>
<ng-template #content let-modal>
  <div class="page-wrapper-modal">
    <div class="page-header">
      <h2 class="mb-0">Approval Delegation</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-3 ps-0">
          <div class="custom-floating-label">
            <label for="input1" class="form-label form-label-for-select">
              Appprove Delegate
            </label>

            <div class="custom-dropdown w-100">
              <select class="custom-select">
                <option
                  class="dropdown-option"
                  value=""
                  disabled
                  selected
                  hidden
                >
                  Select
                </option>
                <option class="dropdown-option" value="type1">Sophia Martinez</option>
                <option class="dropdown-option" value="type2">Andrea Noj</option>
                <option class="dropdown-option" value="type3">Alejandro Aguilar</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="custom-floating-label">
            <label for="input1" class="form-label form-label-for-select">
              Appprove Delegate
            </label>

            <div class="custom-dropdown w-100">
              <select class="custom-select">
                <option
                  class="dropdown-option"
                  value=""
                  disabled
                  selected
                  hidden
                >
                  Select
                </option>
                <option class="dropdown-option" value="type1">Type 1</option>
                <option class="dropdown-option" value="type2">Type 2</option>
                <option class="dropdown-option" value="type3">Type 3</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="custom-floating-label" id="datepicker ">
            <label for="input1" class="form-label"
              >Approve Delegate From</label
            >
            <input
              type="date"
              class="form-control d-block"
              placeholder="Start Date"
              id="date"
            />
          </div>
        </div>
        <div class="col-3 pe-0">
          <div class="custom-floating-label" id="datepicker ">
            <label for="input1" class="form-label"
              >Approve Delegate From</label
            >
            <input
              type="date"
              class="form-control d-block"
              placeholder="Start Date"
              id="date"
            />
          </div>
        </div>
      </div>
      <div class="adding-deligate">
        <span class="text-link">+ <span>Add Delegate</span></span>
      </div>
    </div>
    <div class="delegations mb-3">
      <h4 class="mb-0">Scheduled Delegations</h4>
      <div class="del">
        <div class="container">
      <div class="delegation row">
        <div class="col-5 ps-0">
          <span class="scheduled-delegations">
            <span class="delegation-name"
              >From<strong>Sophia Martinez</strong>to<strong
                >Andrea Noj</strong
              ></span
            >
            </span>
        </div>
        <div class="col-5 px-0">
          <span class="delegation-time"
          >Effective Date&nbsp;: 12/10/2024 to 12/12/2024</span
        >
        </div>
        <div class="col-2 pe-0">
          <span class="remove-contact remove-contact-blue"> Remove</span>
        </div>
      </div>
      <div class="delegation row">
        <div class="col-5 ps-0">
          <span class="scheduled-delegations">
            <span class="delegation-name"
              >From<strong>Sophia Martinez</strong>to<strong
                >Andrea Noj</strong
              ></span
            >
            </span>
        </div>
        <div class="col-5 px-0">
          <span class="delegation-time"
          >Effective Date&nbsp;: 12/10/2024 to 12/12/2024</span
        >
        </div>
        <div class="col-2 pe-0">
          <span class="remove-contact remove-contact-blue"> Remove</span>
        </div>
      </div>
    </div>
    </div>
    </div>
    <div class="delegations">
      <h4 class="mb-0">PAST DELEGATIONS</h4>
      <div class="del">
        <div class="container">
      <div class="delegation row">
        <div class="col-5 ps-0">
          <span class="scheduled-delegations">
            <span class="delegation-name"
              >From<strong>Sophia Martinez</strong>to<strong
                >Andrea Noj</strong
              ></span
            >
            </span>
        </div>
        <div class="col-5 px-0">
          <span class="delegation-time"
          >Effective Date&nbsp;: 12/10/2024 to 12/12/2024</span
        >
        </div>
        <div class="col-2 pe-0">
          <span class="remove-contact remove-contact-blue"> Remove</span>
        </div>
      </div>
      <div class="delegation row">
        <div class="col-5 ps-0">
          <span class="scheduled-delegations">
            <span class="delegation-name"
              >From<strong>Sophia Martinez</strong>to<strong
                >Andrea Noj</strong
              ></span
            >
            </span>
        </div>
        <div class="col-5 px-0">
          <span class="delegation-time"
          >Effective Date&nbsp;: 12/10/2024 to 12/12/2024</span
        >
        </div>
        <div class="col-2 pe-0">
          <span class="remove-contact remove-contact-blue"> Remove</span>
        </div>
      </div>
    </div>
    </div>
    </div>
    <div class="buttons">
      <button class="common-btn-light btn">Cancel</button>
      <button class="common-btn-secondary btn">Save</button>
    </div>
  </div>
</ng-template>

<div>
  <button
    class="btn btn-primary"
    (click)="openDeleteConfirmationModalOne(content1)"
  >
    Burbank form
  </button>
</div>

<ng-template #content1 let-modal>
  <div class="page-wrapper-modal">
    <div class="header-cognito">
    <img class="wbd-logo" src="assets/images/wbd-logo-cognito.png" alt="wbd-logo">
    <div class="header-content">
      <h1>Burbank Main Lot Facilities Service Request</h1>
      <p>Create a Main Lot Facilities Service Request</p>
    </div>
  </div>
  <div class="terms-conditions-cognito">
    <p>Please use this form to report Burbank Main Lot Facilities Maintenance issues for your building, office or workspace. Select the issue you are reporting from the “Request Type” drop down field. Confirm your location (building number) is accurate in the “Building” field. Please include your office/room number in the “Additional Information” section, you may also include notes and related information in this field. If your issue does not appear in the drop down selections, please reach out to Studio Operations for assistance at 818-954-6777.</p>
    <p>You will receive an email confirmation containing ticket details and who to contact, should you need to update your request. Additional comments made after the initial ticket is submitted, will not be received.   </p>
    <p>Note: Please do not use this form for URGENT issues, such as a water leak, power outage, or other facility related critical matters.  Instead call 818-954-1700, to report any URGENT utility related matters.</p>
  </div>
  <div class="input-text-data">
    <div class="container px-0">
      <div class="row mb-3">
        <div class="col-6">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Requestor</label>
            <input type="text" class="form-control" placeholder="" id="requestor" />
          </div>
        </div>
        <div class="col-6">
          <div class="custom-floating-label" id="datepicker ">
            <label for="input1" class="form-label"
              >Date</label
            >
            <input
              type="date"
              class="form-control d-block"
              placeholder=""
              id="date"
            />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Production Company</label>
            <input type="text" class="form-control" placeholder="" id="pcompany" />
          </div>
        </div>
        <div class="col-6">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Production Name</label>
            <input type="text" class="form-control" placeholder="" id="pname" />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Building</label>
            <input type="text" class="form-control" placeholder="" id="building" />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Location Details</label>
            <input type="text" class="form-control" placeholder="" id="ldetails" />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Request Category</label>
            <input type="text" class="form-control" placeholder="" id="rcategory" />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Request Type</label>
            <input type="text" class="form-control" placeholder="" id="rtype" />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Request Details</label>
            <input type="text" class="form-control" placeholder="" id="rdetails" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="button">
    <button class="common-btn-secondary btn">Submit</button>
  </div>
  </div>
</ng-template>

<div>
  <button
    class="btn btn-primary"
    (click)="openDeleteConfirmationModalTwo(content2)"
  >
    Hot Lock Request Form
  </button>
</div>


<ng-template #content2 let-modal>
  <div class="page-wrapper-modal">
    <div class="header-cognito">
    <img class="wbd-logo" src="assets/images/wbd-logo-cognito.png" alt="wbd-logo">
    <div class="header-content">
      <h1>Greenlight-Hot Lock Stage Request</h1>
    </div>
  </div>

  <div class="input-text-data">
    <div class="container px-0">
      <div class="row mb-3">
        <div class="col-4">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Production Company</label>
            <input type="text" class="form-control" placeholder="" id="pcompany" />
          </div>
        </div>
        <div class="col-4">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Show Name</label>
            <input type="text" class="form-control" placeholder="" id="sname" />
          </div>
        </div>
        <div class="col-4">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Requestor</label>
            <input type="text" class="form-control" placeholder="" id="requestor" />
          </div>
        </div>

      </div>
      <div class="row mb-3">
        <div class="col-5">
          <div class="custom-floating-label" id="datepicker ">
            <label for="input1" class="form-label"
              >Date</label
            >
            <input
              type="date"
              class="form-control d-block"
              placeholder=""
              id="date"
            />
          </div>
        </div>
        <div class="col-7">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Stages(s)</label>
            <input type="text" class="form-control" placeholder="" id="stages" />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4">
          <div class="custom-floating-label" id="datepicker ">
            <label for="input1" class="form-label"
              >Start Date</label
            >
            <input
              type="date"
              class="form-control d-block"
              placeholder=""
              id="date"
            />
          </div>
        </div>
                <div class="col-4">
          <div class="custom-floating-label" id="datepicker ">
            <label for="input1" class="form-label"
              >Release Date</label
            >
            <input
              type="date"
              class="form-control d-block"
              placeholder=""
              id="date"
            />
          </div>
        </div>
        <div class="col-4">
          <div class="custom-floating-label" id="datepicker ">
            <label for="input1" class="form-label"
              >Revised Date</label
            >
            <input
              type="date"
              class="form-control d-block"
              placeholder=""
              id="date"
            />
          </div>
        </div>
        


      </div>
      <div class="row mb-3">
        <div class="col-4">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">UPM</label>
            <input type="text" class="form-control" placeholder="" id="upm" />
          </div>
        </div>
        <div class="col-4">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Cell</label>
            <input type="text" class="form-control" placeholder="" id="cell" />
          </div>
        </div>
        <div class="col-4">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Home</label>
            <input type="text" class="form-control" placeholder="" id="home" />
          </div>
        </div>

      </div>
  </div>
  </div>
  <div class="heading-content">
    <h2 class="mb-3">
			PERSONS AUTHORIZED TO OPEN STAGE  (Alphabetize by Last Name)
			</h2>
    <div class="container">
      <div class="row mb-3">
        <div class="col-3 ps-0">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Name</label>
            <input type="text" class="form-control" placeholder="" id="name" />
          </div>
        </div>

        <div class="col-3">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Title</label>
            <input type="text" class="form-control" placeholder="" id="title" />
          </div>
        </div>
        <div class="col-3">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Cell</label>
            <input type="text" class="form-control" placeholder="" id="cell" />
          </div>
        </div>
        <div class="col-3 pe-0">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Home</label>
            <input type="text" class="form-control" placeholder="" id="home" />
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="heading-content">
    <h2 class="mb-3">
			After Hours Emergency Contact : ***Required***
			</h2>
    <div class="container">
      <div class="row mb-3">
        <div class="col-3 ps-0">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Name</label>
            <input type="text" class="form-control" placeholder="" id="name" />
          </div>
        </div>

        <div class="col-3">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Title</label>
            <input type="text" class="form-control" placeholder="" id="title" />
          </div>
        </div>
        <div class="col-3">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Cell</label>
            <input type="text" class="form-control" placeholder="" id="cell" />
          </div>
        </div>
        <div class="col-3 pe-0">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Home</label>
            <input type="text" class="form-control" placeholder="" id="home" />
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="input-text-data">
    <div class="container px-0">
      <div class="row mb-3">
        <div class="col-6">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Print Name</label>
            <input type="text" class="form-control" placeholder="" id="pname" />
          </div>
        </div>
        <div class="col-6">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Title</label>
            <input type="text" class="form-control" placeholder="" id="title" />
          </div>
        </div>

      </div></div>
      </div>
  </div>
</ng-template>

<div>
  <button
    class="btn btn-primary"
    (click)="openDeleteConfirmationModalThree(content3)"
  >
    Credit Card Authorization
  </button>
</div>

<ng-template #content3 let-modal>
  <div class="page-wrapper-modal">
    <div class="header-cognito">
    <img class="wbd-logo" src="assets/images/wbd-logo-cognito.png" alt="wbd-logo">
    <div class="header-content">
      <h1>[DEV]Greenlight-Credit Card Authorization</h1>
    </div>
  </div>

  <div class="input-text-data">
    <div class="container px-0">
      <div class="row">
        <div class="col-12">
          <p class="letter-foramt mb-0">
            <strong>4000 Warner Blvd, Building 153 Lower Level</strong>
            <br>
            <strong>Burbank, California 91522</strong>
            <br>
            <strong>Phone: (818) 954-1297 Option 1</strong>
            <br>
            <strong>wbcostumesnewaccounts@warnerbros.com</strong>
          </p>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12">
          <div class="costume-heading">
            <div>
          <h3>Warner Bros. Studio Operations – Costume</h3>
        </div>
        <div>
          <h3>Department (“WBSO Costumes”)</h3>
        </div>
        </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Cardholder Name:</label>
            <input type="text" class="form-control" placeholder="" id="pcompany" />
          </div>
        </div>
        <div class="col-4">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label"></label>
            <input type="text" class="form-control" placeholder="" id="sname" />
          </div>
        </div>
        <div class="col-4">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Production Company</label>
            <input type="text" class="form-control" placeholder="" id="requestor" />
          </div>
        </div>

      </div>
      <div class="row mb-3">
        <div class="col-8">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Email Address</label>
            <input type="text" class="form-control" placeholder="" id="email" />
          </div>
        </div>
        <div class="col-4">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Job/Show Name</label>
            <input type="text" class="form-control" placeholder="" id="jshowname" />
          </div>
        </div>

      </div>
      <div class="row mb-3">
        <div class="col-12">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Requestor</label>
            <input type="text" class="form-control" placeholder="" id="requestor" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p>
            <strong>1.</strong>
            We, the undersigned, shall be responsible for the return of all rented items (the “Articles”) in the same condition as received, reasonable wear and tear excepted.
          </p>
          <p>
            <strong>2.</strong>
            We, the undersigned, shall be responsible for any approval, rental and extended rental charges (collectively, “rental charges”) and any other charges associated with loss and damage, cleaning, returns, restocking and/or labor (collectively, “additional charges").
          </p>
          <p>
            <strong>3.</strong>
            We, the undersigned, acknowledge that WBSO's liability, if any, for damages arising out of any breach, errors, omissions, interruptions, delays or defects in any of the Articles or services provided by WBSO Costumes shall in no event exceed an amount equal to the amount charged pursuant to this Credit Card Authorization.
          </p>
          <p>
            <strong>4.</strong>
            We, the undersigned, will authorize our credit card processor to pay any charges initiated from WBSO Costumes related to the job. For our convenience, we, the undersigned, acknowledge that we may elect to securely store credit card information with WBSO Costume’s third-party credit card processor for current and future transactions with WBSO Costumes. The undersigned also acknowledges that charges may be recurring, and in such event, this authorization shall remain in full force and effect, unless we revoke such authorization through WBSO Costume’s third-party credit card processor.
          </p>
          <p>
            <strong>5.</strong>
            We, the undersigned, acknowledge that this authorization allows WBSO Costumes to charge our credit card an initial deposit and any additional deposits to cover rental charges and additional charges, unless we have made other acceptable payment arrangements with WBSO Costumes and such payment arrangements are approved by the WBSO Accounts Receivable Department.
          </p>
        </div>
      </div>
      <div class="row mb-2 mt-2">
        <div class="col-6">
          <div class="custom-floating-label" id="datepicker ">
            <label for="input1" class="form-label"
              >Dated:</label
            >
            <input
              type="date"
              class="form-control d-block"
              placeholder=""
              id="date"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="custom-floating-label" id="datepicker ">
            <label for="input1" class="form-label"
              >Dated:</label
            >
            <input
              type="date"
              class="form-control d-block"
              placeholder=""
              id="date"
            />
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-12">
          <p>The representatives from our company listed below (“Authorized Users”) are authorized to charge Articles from WBSO Costumes for payment. Only Authorized Users may pull Articles from WBSO Costumes. A receipt for each transaction will be provided to the cardholder of record. Please indicate which, if any, authorized users should be sent invoice copies. </p>
        </div>
      </div>
  </div>
  </div>
  <div class="heading-content">
    <h2 class="mb-3">
			Untitled
			</h2>
    <div class="container">
      <div class="row mb-3">
        <div class="col-4 ps-0">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Name</label>
            <input type="text" class="form-control" placeholder="" id="name" />
          </div>
        </div>

        <div class="col-4">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Email</label>
            <input type="text" class="form-control" placeholder="" id="email" />
          </div>
        </div>
        <div class="col-4 pe-0">
          <div class="custom-floating-label">
            <label for="prodComPhone" class="form-label">Send Invoice Copy?</label>
            <input type="text" class="form-control" placeholder="" id="sendinvoicecopy" />
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</ng-template>
