<div class="page-wrapper">
  <div class="container px-0 ">
    <div class="row comman-title-block">
      <div class="comman-title-section col-12 ">
        <h1 class="mb-0" *ngIf="!companyError || permissionsData['accessCompany']">{{company_view_info?.companyName}}</h1>
        <h1 class="mb-0" *ngIf="companyError">{{company.companyerror}}</h1>
      </div>
    </div>
    <div class="row account-project-block">
      <div class="col-6 project-section-company">
        <h5 class="mb-0">
          {{company.companyid}}
        </h5>
        <p *ngIf="" class="mb-0">{{company_view_info?.companyId}}</p>
      </div>
      <div class="col-6 project-section">
        <h5 *ngIf="permissionsData['accessCompany']" class="mb-0">{{company.projects}} ({{company_view_info?.projectCount}})</h5>
        <span *ngIf="permissionsData['accessCompany']" class="text-link view-sub-text mb-0" (click)="navigateToProjectDetails()">{{company.viewall}}</span>
      </div>
    </div>
    <div class="row address-block" *ngIf="company_view_info.federalTax?.federalTaxId && permissionsData['editCompany']">
      <div class="col-lg-4 col-sm-12 company-address">
        <h3 class="company-heading">
          {{ company.federalTaxId }}
        </h3>
        <p class="company-content">
          {{ company_view_info.federalTax.federalTaxId }}
        </p>
      </div>
    </div>
        <div class="row address-block" *ngIf="company_view_info.companyAddresses?.length > 1">
      <ng-container *ngIf="permissionsData['accessCompany']">
        <ng-container *ngFor="let address of company_view_info?.companyAddresses">
          <div class="col-lg-4 col-sm-12 company-address">
            <ng-container *ngIf="address.companyAddressType.companyAddressTypeId === 1">
              <h3 class="company-heading">{{company.companyaddress}}</h3>
            </ng-container>
            <ng-container *ngIf="address.companyAddressType.companyAddressTypeId !== 1">
              <h3 class="company-heading">{{company.billingaddress}}</h3>
            </ng-container>
            <p class="company-content">
              {{address.street}}, {{address.city}}, {{address.state.stateName}}, {{address.country.countryCode}}, {{address.zip}}
              <br />{{address.phone}}
            </p>
          </div>
        </ng-container>
      </ng-container>
</div>
    <div class="row contact-info-block-1" *ngIf="company_view_info.companyAddresses?.length == 1">
      <ng-container *ngIf="permissionsData['accessCompany']">
        <div class="col-12 d-flex justify-content-between align-items-center pe-0 ps-0 mb-4">
          <h3 class="title-contact mb-0">{{company.companyaddress}}</h3>
        </div>
        <div class="col-12 ps-0 pe-0">
          <p class="contact-inner" *ngFor="let address of company_view_info?.companyAddresses">
            {{address.street}}, {{address.city}}, {{address.state.stateName}}, {{address.country.countryCode}}, {{address.zip}}
            <br />{{address.phone}}
          </p>
        </div>
      </ng-container>
    </div>
    <div *ngIf="permissionsData['accessCompany']"class="row contact-info-block-2">
      <div class="px-0">
        <div class="additional-contact-information-section-1">
          <div class="col-12 d-flex justify-content-between align-items-center pe-0 ps-0">
            <p class="title-contact mb-0">{{company.contactinfo}}</p>
          </div>
        </div>
        <div class="row additional-contact-information-section-2 mx-0 ">
          <div class="col-lg-4 col-sm-12 px-0 pt-2" *ngFor="let info of company_view_info?.companyContactInfos">
            <p class="content-bold mb-0">{{info.firstName}} {{info.lastName}}</p><strong class="content-bold">{{info.jobTitle}} </strong>
            <p class="content-normal mb-0">{{info.email}}</p><p class="content-normal mb-0">{{info.phone}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row account-user-block align-items-center">
      <div class="account-user col-12 d-flex justify-content-between ps-0 pe-0 ">
        <h3 *ngIf="permissionsData['accessCompany']" class="title-contact mb-0">{{company.companyuser}} ({{company_view_info?.userCount}})</h3>
        <div class="view-div d-flex align-items-center">
          <p *ngIf="permissionsData['viewCompanyUser']" routerLink="companyuser" class="text-link view-sub-text mb-0 me-3">{{company.viewall}}</p>
          <button *ngIf="permissionsData['addCustomerUser']" class="btn btn-dark custom-button" (click)="addUser()">+ {{company.adduser}}</button>
        </div>
      </div>
    </div>
    <div class="row account-project-block">
      <div class="qccount-section-company-block col-sm-12 col-md-12 col-lg-6 mb-4" *ngIf="company_view_info?.companyType?.companyTypeId == 4">
        <h5 class="mb-0 title-contact">{{company.creditstatus}}</h5>
        <div class="view-div d-flex align-items-center" *ngIf="company_view_info?.companyCreditStatus != null">
          <p class="credit-sub-text mb-0 me-3" *ngIf="company_view_info?.companyCreditStatus.creditStatus === 'Current'">{{company.validtill}} {{company_view_info?.validUntil}}</p>
          <p class="mb-0 credit-status-tag"
             [ngClass]="{'current': company_view_info?.companyCreditStatus.creditStatus === 'Current', 'needs-approval' : company_view_info?.companyCreditStatus.creditStatus === 'Needs Approval' }">
            {{company_view_info?.companyCreditStatus.creditStatus}}
          </p>
        </div>
        <div class="view-div d-flex align-items-center" *ngIf="company_view_info?.companyCreditStatus == null">
          <p class="credit-sub-text mb-0 me-3">{{company.notavailable}}</p>
        </div>
      </div>
    </div>
    <div class="row last-section">
      <div *ngIf="permissionsData['editCompany']" class="last-section-edit">
        <img src="assets/images/edit.svg" alt="Edit Icon" class="icon">
        <p class="mb-0" (click)="editCompany()">{{company.editcompany}}</p>
      </div>
    </div>
  </div>
  <ng-template #successTpl>
    <div class="success">{{addUserSuccess}}</div>
  </ng-template>
  
  <ng-template #dangerTpl>
    <div class="danger">{{addUserError}}</div>
  </ng-template>
  <app-toast aria-live="polite" aria-atomic="true"></app-toast>

