import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddCompanyUserComponent } from '../../../../shared/components/add-company-user/add-company-user.component';
import { COMPANY, DASHBOARD } from '../../constants/customer-portal.constant';
import { OnTheLotService } from '../../services/onTheLot/on-the-lot.service';
import { ActiveProjectsService } from '../../services/active-projects/active-projects.service';
import {
  Project,
  ProjectResponse,
  ResourceSpace,
} from 'src/app/shared/models/active-projects';
import * as _ from 'lodash';
import { CompanyService } from '../../services/company/company.service';
import { ToastService } from '../../../../shared/components/toast.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  spacesAndResources: ResourceSpace[] = [];
  groupedSpacesAndResources: any[] = [];
  projectDetails!: ProjectResponse;
  projectsList: Project[] = [];
  first_name: string;

  userProfileId: any;
  response: any;
  modalReference: NgbModalRef;
  projectStatusId = 2;

  dashboard = DASHBOARD;
  company = COMPANY;
  userInfo = JSON.parse(localStorage.getItem('user-profile'));
  userProjectsByStatus: any;
  addUserSuccess: any;
  addUserError: any;
  companyUserMetaData: any;
  companyId: any;
  permissionsData: any;
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  constructor(
    private modalService: NgbModal,
    private onTheLotService: OnTheLotService,
    private activeProjectsService: ActiveProjectsService,
    private companyService: CompanyService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.permissionsData = { ...userProfile.roleAssigned };
    this.companyId = userProfile.companyId;
    this.getOnTheLot();
    this.getActiveProjects();
    this.getCompanyUserMetaData();
    this.userProfileId = this.userInfo.userProfileId;
    this.first_name = this.userInfo.firstName;
  }

  /**
  *addUser() - invoke the add user modal popup
  */

  addUser(): void {
    let title = this.company?.adduser;
    let metaData = this.companyUserMetaData;
    let portalType = this.company?.portaltype;
    let modalType = this.company?.modalAdd;
    let componentType = this.company?.componenttype;
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddCompanyUserComponent, {
      windowClass: 'common-modal-xl ',
      fullscreen: true
    });
    this.modalReference.componentInstance.title = title;
    this.modalReference.componentInstance.metaData = metaData;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.modalType = modalType;
    this.modalReference.componentInstance.componentType = componentType;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data.status == this.company.success) {
          this.addUserSuccess = data?.message;
          this.showSuccess(this.successTpl);
        } else {
          this.addUserError = data?.message;
          this.showDanger(this.dangerTpl);
        }
      }
    });
  }

  /**
*getCompanyUserMetaData() - getCompanyUserMetaData method gives list of project, department and other information that helps
* to add the user in company.
*/

  getCompanyUserMetaData(): void {
    this.companyService.getCompanyUserMetaData(this.companyId).subscribe({
      next: (data: any) => {
        this.companyUserMetaData = data?.companyUserMetaData;
      },
      error: (error: any) => {
        console.error(error);
      }
    })
  }

  /**
* showSuccess() - Displays a success toast notification with custom content and options.
* @param successTpl - The template reference containing the toast's content.
*/
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }
  /**
     * showDanger() - Displays a danger (error) toast message.
     * This method is called when there is an error in adding the user.
     * @param dangerTpl - The template reference for the danger message to display.
     */
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
   * getOnTheLot() - used to retrieve metadata for on-the-lot in dashboard
   */
  getOnTheLot() {
    this.onTheLotService.getOnTheLotData().subscribe({
      next: (response: any) => {
        if (response) {
          this.response = response;
        } else {
          console.warn(this.dashboard.lotError);
        }
      },
    });
  }

  /**
   * getActivePRojects() - used to get active projects based on the logged in user
   */
  getActiveProjects(): void {
    const userProfileId = this.userInfo.userProfileId;
    this.activeProjectsService
      .getActiveProjects(userProfileId, this.projectStatusId)
      .subscribe({
        next: (response: ProjectResponse | null) => {
          if (response) {
            this.projectDetails = response;
            console.log(this.projectDetails);
            this.projectsList = response.userProjectsByStatus.projectList;
  
            // Clear existing spaces and resources before populating
            this.spacesAndResources = []; // Ensure this is defined in your class
  
            // Process each project to gather space and resources
            this.projectsList.forEach((project) => {
              // Ensure spaceAndResources exists and is an array
              if (project.spaceAndResources && project.spaceAndResources.length > 0) {
                project.spaceAndResources.forEach((resource) => {
                  this.spacesAndResources.push(resource);
                });
              }
            });
  
            // Group the spaces and resources after populating
            this.groupSpacesAndResources();
          } else {
            console.warn(this.dashboard.error);
          }
        },
      });
  }
  

  /**
   * groupSpacesAndResources() - used to group resource values based on resources type
   */
  groupSpacesAndResources(): void {
    const grouped = _.groupBy(
      this.spacesAndResources,
      (resource) => `${resource.resourceType}-${resource.stageNumber || 'N/A'}`
    );
    this.groupedSpacesAndResources = Object.keys(grouped).map((key) => {
      const resources = grouped[key];
      return {
        resourceType: resources[0].resourceType,
        stageNumber: resources[0].stageNumber,
        resourceValues: resources.map((r) => r.resourceValue),
      };
    });
  }

  /**
 * groupSpacesAndResources() - used to group space and resource values based on resource type and stage number
 */
// groupSpacesAndResources(): void {
//   // Initialize the grouped structure
//   this.groupedSpacesAndResources = [];

//   // Iterate over each project in the projectList
//   this.userProjectsByStatus.projectList.forEach(project => {
//     const grouped = _.groupBy(
//       project.spaceAndResources,
//       (resource) => `${resource.resourceType}-${resource.stageNo || 'N/A'}`
//     );

//     // Map the grouped resources to the desired structure
//     const groupedResources = Object.keys(grouped).map((key) => {
//       const resources = grouped[key];
//       return {
//         resourceType: resources[0].resourceType,
//         stageNumber: resources[0].stageNo,
//         resourceValues: resources.map((r) => r.resourceValue),
//         bookmarkedResources: resources.map(r => r.bookmarkResources)
//       };
//     });

//     // Push the grouped resources into the final structure
//     this.groupedSpacesAndResources.push({
//       projectId: project.projectId,
//       projectName: project.projectName,
//       groupedResources: groupedResources,
//     });
//     console.log(this.groupedSpacesAndResources)
//   });
// }


  

  /**
   * updateOnTheLot() - its is for updating the country id
   * @param index - the country id that need to be updated
   */
  updateOnTheLot(index: number): void {
    const data = {
      onTheLotCountryId: index,
    };
    this.onTheLotService.updateOnTheLot(data, this.userProfileId).subscribe({
      next: (response) => {
        console.log(this.dashboard.Updatsuccessful, response);
      },
      error: (error) => {
        console.error(this.dashboard.lotError, error);
      },
    });
  }
}
