import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { COMPANY_DATA } from 'mockData/development/company-list';
import { debounceTime, distinctUntilChanged, map, Observable, Subject, takeUntil } from 'rxjs';
import { COMPANY_SEARCH_MESSAGES, MESSAGES, REGEX_CONSTANTS, SEARCH_PLACEHOLDER } from 'src/app/shared/constants/common';
import { COMPANY_FILTER } from 'src/app/shared/enum/shared.enum';
import { SearchDataService } from 'src/app/shared/services/search.service';
import { CompanyService } from '../../../services/company.service';

@Component({
  selector: 'app-search-company',
  templateUrl: './search-company.component.html',
  styleUrls: ['./search-company.component.scss']
})
export class SearchCompanyComponent {
  public model: any;
 
  companyList:any = [];
  @Input() placeholder: string = SEARCH_PLACEHOLDER; 
  @Input() disabled: boolean = false;// New input for disabled state
  @Input() searchTerm: string = '';  // Bindable searchTerm property
  @Output() searchTermChange: EventEmitter<string> = new EventEmitter<string>();  // Emit when searchTerm changes
  @Input() parameter: any;
  @Output() searchComplete = new EventEmitter<any>();
  @Output() inputCleared: EventEmitter<string> = new EventEmitter<string>();

  noResultsFound: boolean;
  invalidSearchMessage: string;
  @Input() companiesSubject!: Subject<any[]>;
  companies: any[] = [];
  private unsubscribe$ = new Subject<void>();
  searchRegx = REGEX_CONSTANTS;
  message = COMPANY_SEARCH_MESSAGES;
  constructor() {}

  ngOnInit() {
    this.companiesSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((companies: any[]) => {
        this.companies = companies;
          this.companyList = this.companies.map(company => {
          if (company.compid) {
            return {
              compid: company.compid.toString(),
              companyName: company.companyName,
            };
          } else {
            return null; 
          }
        }).filter(company => company !== null); 
      });
  }

/**ngOnDestroy - unsubscribe all subjects on ngDestroy*/

ngOnDestroy() {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
}
  
/**search - search method used for the typehead list */
search = (text$: Observable<any>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => {
      this.invalidSearchMessage = null; // Reset the error message

      if (term) {
        const isNumber = this.searchRegx.SEARCH_COMPANY_NUMBER.test(term);
        const results = this.companyList.filter(company =>
          company.compid.includes(term) || company.companyName.toLowerCase().includes(term.toLowerCase())
        );

        // Set error message based on search criteria
        if (isNumber && results?.length === 0) {
          this.invalidSearchMessage = this.message.invalidCompanyId;
        } else if (!isNumber && results?.length === 0) {
          this.invalidSearchMessage = this.message.invalidCompanyName;
        }

        return results.slice(0, 10);
      }

      return []; // Return empty if no term
    })
  );


  /**formatter - formatter method used to show company name and company id*/
  formatter = (x: {companyName: string,compid: string}) => `${x.companyName} - ${x.compid}`;

  /**selectedItem - selectedItem method called on select change*/
  selectedItem(event: any): void {
    const selectedItem = event?.item;
    this.searchComplete.emit([selectedItem]);
  }

  /**onInputChange - onInputChange method called on input change*/
  onInputChange(newValue: any): any {
    this.searchTerm = newValue || '';  // Fallback to empty string if undefined or null
    if (newValue === '') {
      // Perform an action when input is cleared
      this.selectedItem = null;
      this.searchTerm = null;
      this.searchComplete.emit(null);
    }else{
        this.searchComplete.emit([this.searchTerm]);
        this.searchTermChange.emit(this.searchTerm);
    }
  }

}
