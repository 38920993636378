import { Component } from '@angular/core';

@Component({
  selector: 'app-salesforce-form',
  templateUrl: './salesforce-form.component.html',
  styleUrls: ['./salesforce-form.component.scss']
})
export class SalesforceFormComponent {

}
