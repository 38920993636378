<div class="container cm-main-container px-0 mx-0">
    <div class="common-modal-input-section grey-bg">
        <div [innerHTML]="termsContent" class="term-condition-desc">
        </div>
    </div>

    <div class="common-modal-input-section terms-input-sec mt-5">
        <form [formGroup]="form">
            <div class="row">
                <div class="col-md-6 custom-floating-label">
                    <label for="termCondiName" class="form-label"> Name</label>
                    <input type="text" class="form-control" placeholder="Name" id="termCondiName"
                        formControlName="name">
                    <small class="text-danger"
                        *ngIf="form.get('name').invalid && (form.get('name').touched || form.get('name').dirty)">
                        <div *ngIf="form.get('name').errors['required']">{{credFormThree.fieldRequired }}</div>
                        <div *ngIf="form.get('name').errors['invalidAlphabets']">{{credFormThree.nameInvalid }}
                        </div>
                    </small>
                </div>
                <div class="col-md-6 custom-floating-label">
                    <label for="termCondiTitle" class="form-label"> Title</label>
                    <input type="text" class="form-control" placeholder="Title" id="termCondiTitle"
                        formControlName="title">
                    <small class="text-danger"
                        *ngIf="form.get('title').invalid && (form.get('title').touched || form.get('title').dirty)">
                        <div *ngIf="form.get('title').errors['required']">{{credFormThree.fieldRequired }}</div>
                        <div *ngIf="form.get('title').errors['invalidAlphanumericSpecial']">
                            {{credFormThree.titleInvalid }}</div>
                    </small>
                </div>
                <div class="col-md-6 custom-floating-label">
                    <label for="termCondiSignature" class="form-label"> Signature</label>
                    <input type="text" class="form-control" placeholder="Signature" id="termCondiSignature"
                        formControlName="signature">
                    <small class="text-danger"
                        *ngIf="form.get('signature').invalid && (form.get('signature').touched || form.get('signature').dirty)">
                        <div *ngIf="form.get('signature').errors['required']">{{credFormThree.fieldRequired }}</div>
                        <div *ngIf="form.get('signature').errors['invalidAlphabets']">
                            {{credFormThree.signatureInvalid }}</div>
                    </small>
                </div>
                <div class="col-md-4 custom-floating-label">
                  <label for="termCondiDate" class="form-label"> Date</label>
                      <input class="form-control"
                             id="termCondiDate"
                             placeholder="mm-dd-yy"
                             name="dp"
                             formControlName="date"
                             ngbDatepicker
                             #d="ngbDatepicker" (click)="d.toggle()"
                             (ngModelChange)="onDateSelect($event)"/>
                </div>
            </div>
        </form>
    </div>
    <div class="cm-btn-section d-flex align-items-center">
        <div class="left-btn me-auto">
            <a class="text-link" (click)="previousPage()"><img class="back-img" src="../../assets/images/blue-left-icon.svg" alt="Back" />back
            </a>
        </div>
        <button type="button" class="btn common-btn-secondary" data-bs-dismiss="modal" (click)="onSubmit()" [disabled]="!form.valid">Next: Review Application</button>
        <button type="button" class="btn common-btn-light ms-2" data-bs-dismiss="modal" (click)="onSubmit()" [disabled]="!form.valid">Save & Close</button>
    </div>
    <!---Added modals on click of Select Company-->
    <ng-template #company let-modal>
        <div class="modal-body">
            <h2 class="section-main-header">Search </h2>
            <div class="">
                <div class="mt-56 custom-floating-label">
                    <label for="input1" class="form-label">Search</label>
                    <input type="text" class="pe-4 form-control" placeholder="Search company name or company ID #"
                        id="input1">
                    <img class="search-icon" src="../../../../../../assets/images/search-icon.svg" alt="Search Icon">
                </div>
            </div>
            <div class="d-flex justify-content-end gap-2">
                <button type="button" class="btn common-btn-secondary"
                    (click)="modal.close('Close click')">Cancel</button>
                <button type="button" class="btn common-btn-secondary">Select</button>
            </div>
        </div>
    </ng-template>
