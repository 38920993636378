import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { INDEX } from '../../enum/shared.enum';
import { SettingsService } from '../../services/settings/settings.service';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit {
  @Input() placeholder: string;
  @ViewChild('dpToDate', { static: false }) dpToDate!: ElementRef;

  fromDateInput: NgbDate | null = null;
  toDateInput: NgbDate | null = null;

  @Output() dateRangeSelected = new EventEmitter<{
    fromDate: NgbDate | null;
    toDate: NgbDate | null;
  }>();
  @Output() dateRangeCancelled = new EventEmitter<void>();

  hasSelectedDate: boolean = false;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = this.fromDateInput;
  toDate: NgbDate | null = this.toDateInput;
  currentDay: NgbDate;
  calenderFormat: number;
  formatId: any;
  index = INDEX;
  profileSubscription: any;
  profile: any;
  user_profile: any;

  /**
   * Constructor initializing the component with calendar and date formatter services.
   * @param calendar - Service for calendar operations.
   * @param formatter - Service for date parsing and formatting.
   */
  constructor(
    private calendar: NgbCalendar,
    private formatter: NgbDateParserFormatter,
    private profileService: SettingsService
  ) {
    this.currentDay = this.calendar.getToday();
  }
  ngOnInit(): void {
    this.user_profile = JSON.parse(localStorage.getItem('user-profile'));
    this.getCalendarFormat();
    if (this.formatId === this.index.one) {
      this.calenderFormat = this.index.seven;
    } else {
      this.calenderFormat = this.index.one;
    }
  }
  /*e
   *getCalendarFormat() - to get the calendar format id based on change in user profilr
   */
  getCalendarFormat(): void{
    const updatedData = JSON.parse(localStorage.getItem('updated-profile'));
    if (updatedData) {
      this.formatId =
        updatedData?.userPreferences?.calenderFormatSettings?.calenderFormatId;
    } else {
      this.formatId =
        this.user_profile?.userPreferences?.calenderFormatSettings?.calenderFormatId;
  }
}

  /**
   * ngOnChanges() - Updates internal state with the new input values.
   * @param changes - Object containing information about the changed properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['fromDateInput']) {
      this.fromDate = changes['fromDateInput'].currentValue;
    }
    if (changes['toDateInput']) {
      this.toDate = changes['toDateInput'].currentValue;
    }
  }

  /**
   * clearInputField() - Clears the value of the date input field.
   */
  clearInputField() {
    if (this.dpToDate) {
      this.dpToDate.nativeElement.value = '';
    }
  }

  /**
   * onDateSelection() - Handles the date selection logic.
   * Updates the start and end dates based on the selected date.
   * @param date - The newly selected date.
   */
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.toDate = null;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.hasSelectedDate = !!this.fromDate || !!this.toDate;
    this.clearInputField();
  }

  /**
   * openDatepicker() - Toggles the visibility of the datepicker.
   * @param datepicker - The NgbDatepicker instance to be toggled.
   */
  openDatepicker(datepicker: any) {
    datepicker.toggle();
  }

  /**
   * isHovered() _ Checks if a date is being hovered over in the date range picker.
   * @param date - The date to check.
   * @returns `true` if the date is being hovered, otherwise `false`.
   */
  isHovered(date: NgbDate): boolean {
    return (
      this.fromDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  /**
   * isInside() - Checks if a date is within the selected date range.
   * @param date - The date to check.
   * @returns `true` if the date is within the range, otherwise `false`.
   */
  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  /**
   * isRange() - Determines if a date is part of the selected date range or is being hovered over.
   * @param date - The date to check.
   * @returns `true` if the date is part of the range or hovered, otherwise `false`.
   */
  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  /**
   * validateInput() - Validates and parses a date input string.
   * @param currentValue - The current date value to return if the input is invalid.
   * @param input - The input date string to parse and validate.
   * @returns The parsed `NgbDate` if valid, otherwise the `currentValue`.
   */
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  /**
   * isToday() - Checks if a date is today's date.
   * @param date - The date to check against today's date.
   * @returns `true` if the date is today, otherwise `false`.
   */
  isToday(date: NgbDate) {
    return date.equals(this.currentDay);
  }

  /**
   * onApply() - Emits the selected date range and closes the datepicker.
   * Resets the component state after selection.
   * @param datepicker - The NgbDatepicker instance used to close the datepicker.
   */
  onApply(datepicker: any) {
    this.dateRangeSelected.emit({
      fromDate: this.fromDate,
      toDate: this.toDate,
    });
    datepicker.close();
    this.resetDates();
  }

  /**
   * onCancel() - Emits a cancellation event and closes the datepicker.
   * Resets the component state after cancellation.
   * @param datepicker - The NgbDatepicker instance used to close the datepicker.
   */
  onCancel(datepicker: any) {
    this.dateRangeCancelled.emit();
    datepicker.close();
    this.resetDates();
  }

  /**
   * resetDates() - Resets the date range selection to its initial state.
   * Clears any selected dates and updates the component's state.
   */
  resetDates() {
    this.fromDate = null;
    this.toDate = null;
    this.hasSelectedDate = false;
  }
}
