import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-review-summary',
  templateUrl: './credit-review-summary.component.html',
  styleUrls: ['./credit-review-summary.component.scss']
})
export class CreditReviewSummaryComponent {
  selectedAction = { text: 'Actions…', img: '' };

  actions = [
    { text: 'Send Revisions Email', img: '../../../../../assets/images/orange-email-icon.svg' },
    { text: 'Send For Approval', img: '../../../../../assets/images/pending-icon.svg' },
    { text: 'Approve', img: '../../../../../assets/images/approve.svg' },
    { text: 'Deny', img: '../../../../../assets/images/approved-email.svg' }
  ];

  updateButtonText(action: { text: string, img: string }) {
    this.selectedAction = action;
}
}