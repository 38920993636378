<div class="modal-dialog modal-lg my-0">  
  <div class="modal-content ">  
   <div class="modal-body file-upload-modal-body justify-content-center align-items-center">  
    <h1 class="modal-title">{{creditApplicationConstants.attachQuotes}}</h1>  
    <div class="container cm-main-container">  
      <div class="row">  
       <div class="file-drop-area mt-3" [ngClass]="{ 'file-over': isFileOver }"  
        (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">  
        <img class="folder-icon" (click)="fileInput.click()"  
          src="../../../../../assets/images/folder-icon.svg" alt="folder icon">  
        <h3 class="mb-2 drag-n-drop">{{creditApplicationConstants.dragAndDrop}} <a  
           class="browse-file-link"  
           (click)="fileInput.click()">{{creditApplicationConstants.browse}}</a></h3>  
        <input type="file" multiple class="d-none" (change)="onFileSelect($event)" #fileInput>  
       </div>  
       <!-- Display list of selected files -->  
       <ul *ngIf="files.length > 0" class="list-group mt-3 uploaded-files-section">  
        <li *ngFor="let file of files; let i = index" class="list-group-item px-0">  
          <div class="d-flex align-items-center">  
           <div class="col-auto icon-col">  
            <img src="../../../../../assets/images/pdf-icon.svg" alt="pdf icon"  
              class="file-icon">  
           </div>  
           <div class="col ps-3 pe-2">  
            <span [title]="file.file.name" class="file-name">{{ file.file.name }}</span>  
            <!--<ngb-progressbar class="file-progress-bar" type="success" textType="white"  
              [value]="100" [showValue]="false"></ngb-progressbar>-->  
           </div>  
           <div class="col-auto close-icon-col px-0">  
            <i class="bi bi-x remove-btn" (click)="removeFile(file, i)"></i>  
           </div>  
           <div *ngIf="file.error" class="text-danger">{{ file.error }}</div>  
          </div>  
        </li>  
       </ul>  
       <div *ngIf="fileError" class="text-danger">{{ fileError }}</div>  
       <div class="justify-content-end text-end px-0 my-4">  
        <form [formGroup]="quotesForm">  
          <div formArrayName="files">  
           <div *ngFor="let file of files; let i = index" [formGroupName]="i">  
            <div class="row row-pdf-section">  
              <div class="col-md-12 col-lg-12 col-sm-6 col-12 custom-floating-label div-report-pdf">  
               <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg"  
                alt="PDF icon"><span class="pdf-report-name">{{ file.file.name }}</span>  
              </div>  
            </div>  
            <div class="row row-pdf-section" *ngIf="!file.error">  
              <div class="col-md-6 col-lg-6 col-sm-6 col-12 custom-floating-label div-report-pdf">  
               <div class="row upload-input-texts">  
                <div class="col-md-6 col-lg-6 col-sm-6 col-6">  
                  <div class="custom-dropdown w-100">  
                   <select formControlName="departmentId" class="custom-select">  
                    <option class="dropdown-option" value="" disabled selected hidden>  
                      {{ attachQuotes.setLighting }}  
                    </option>  
                    <option class="dropdown-option" *ngFor="let department of departmentDetails"  
                      [value]="department.departmentId">  
                      {{ department.departmentName }}  
                    </option>  
                   </select>  
                   <div *ngIf="filesFormArray.at(i).get('departmentId').invalid && (filesFormArray.at(i).get('departmentId').dirty || filesFormArray.at(i).get('departmentId').touched)" class="text-danger">  
                    Department is required.  
                   </div>  
                  </div>  
                </div>  
                <div class="col-md-6 col-lg-6 col-sm-6 col-6">  
                  <input type="number" formControlName="quotesValue"  
                   class="upload-text-cont form-control" id="exampleFormControlInput1"  
                   placeholder="Enter quotes value" />  
                   <div *ngIf="filesFormArray.at(i).get('quotesValue').invalid && (filesFormArray.at(i).get('quotesValue').dirty || filesFormArray.at(i).get('quotesValue').touched)" class="text-danger">  
                    Quotes value is required.  
                   </div>  
                </div>  
               </div>  
              </div>  
              <div class="col-md-6 col-lg-6 col-sm-6 col-12 custom-floating-label div-action-links">  
               <div class="inner-div-action-links">  
                <a class="text-link fb-600" (click)="removeFile(file, i)">{{ creditApplicationConstants.remove }}</a>  
                <a class="text-link fb-600" (click)="viewFile(file)">{{ creditApplicationConstants.view }}</a>  
               </div>  
              </div>  
            </div>  
           </div>  
          </div>  
          <!-- Add Comment -->  
          <div class="" *ngIf="files.length > 0">  
           <hr class="my-5">  
           <div class="custom-floating-label px-0">  
            <label for="details1" class="form-label">{{ creditApplicationConstants.addComment }}</label>  
            <textarea formControlName="quotesComment" class="form-control common-text-area"  
              placeholder="Waiting for a few more quotes from PPCS and Sound Stages" id="details1"  
              rows="3"></textarea>  
              <div *ngIf="quotesForm.get('quotesComment').invalid && (quotesForm.get('quotesComment').dirty || quotesForm.get('quotesComment').touched)" class="text-danger">  
               Comment is required.  
              </div>  
           </div>  
          </div>  
        </form>  
       </div>  
       <div class="text-end px-0">  
        <button type="button" class="btn common-btn-light me-2" data-bs-dismiss="modal"  
          (click)="openWarningModal()">  
          {{ creditApplicationConstants.cancel }}  
        </button>  
        <button type="button" class="btn common-btn-secondary" [disabled]="!files || files.length === 0 || quotesForm.invalid"  
          (click)="uploadFiles()">  
          {{ creditApplicationConstants.upload }}  
        </button>  
       </div>  
      </div>  
    </div>  
   </div>  
  </div>  
</div>  
  
<ng-template #WarningMsgModal let-modal>  
  <div class="modal-body">  
   <h4 class="section-main-header">{{ creditApplicationConstants.attachQuotesCancelMsg }}</h4>  
   <div class="d-flex justify-content-end gap-2">  
    <button type="button" class="common-btn-secondary btn" (click)="closeModal();modal.close()">  
      {{ iconsRenderer.ok }}  
    </button>  
    <button type="button" class="common-btn-light btn" (click)="modal.close()">  
      {{ iconsRenderer.cancel }}  
    </button>  
   </div>  
  </div>  
</ng-template>  
<app-toast aria-live="polite" aria-atomic="true"></app-toast>