import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor() { }

  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private overlaySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public overlayState$ = this.overlaySubject.asObservable();

  setLoadingState(loading: boolean) {
    setTimeout(() => {
      this.loadingSubject.next(loading);
    });

  }

  setOverlayState(overlayState: boolean) {
    this.overlaySubject.next(overlayState);
  }
  
}
