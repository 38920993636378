import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, finalize, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { LoaderService } from '../../shared/services/loader/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private serviceCount = 0;

  constructor(private loaderService: LoaderService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const currentUrl = (this.router.url).split('/');
    if (req.url.endsWith('/alerts') || !['/statements', '/payments', '/tv-details', '/tv-advance-search', '/group-sales', '/worldwide-rights-tv-title'].includes(`${'/'}${currentUrl[1]}`)) {
      return next.handle(req); // Do not intercept
    }

    this.serviceCount++;
    this.loaderService.setLoadingState(true);
    return next.handle(req).pipe(
      catchError((error) => {
        // Handle errors if needed
        return throwError(error);
      }),
      finalize(() => {
        this.serviceCount--;
        if (this.serviceCount === 0) {
          this.loaderService.setLoadingState(false);
        }
      })
    );
  }
}
