import { Component , TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-edit-ar',
  templateUrl: './add-edit-ar.component.html',
  styleUrls: ['./add-edit-ar.component.scss']
})
export class AddEditArComponent {
  @ViewChild('confirmationModal', { static: false }) confirmationModal!: TemplateRef<any>;

  constructor(private modalService: NgbModal){}
  openConfirmationModal(): void {
    this.modalService.open(this.confirmationModal, {
      windowClass: 'common-modal-center',
      centered: true,
      backdrop: 'static'
    });
  }

  handleAction(): void {
    // Add your confirmation modal action logic here
    console.warn('Action confirmed');
  }
}
