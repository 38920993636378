import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public router: Router,
    private _oktaStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth  ) { }

  userTypeId: number;
  /**
  *isAuthenticated() - method to check if the user is signed in or not
  */

  isAuthenticated() {
    return this._oktaAuth.isAuthenticated();
  }
  
  getUserRole(): any {
    // Return the role of the authenticated user
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.userTypeId = userProfile.userTypeId;
    switch (this.userTypeId) {
      case 1:
        return "customer";
      case 2:
        return "studio";
      case 3:
        return "ar";
      default:
        return ""
    }
  }

}
