export enum PASSES_NUMBER {
  allPassesCount = 36,
  driveOnCount = 15,
  walkOnCount = 21,
}

export enum COMPANY_NUMBER {
  companyid = 12345,
  projectcount = 2,
  companyusercount = 74
}

export enum PAGES {
  zero = 0,
  one = 1,
  ten = 10
}

export enum ACTIVE_PROJECTS_CONSTANTS {
  arNumber = 123456,
  dates = 1/29/24 - 2/24/24,
  projectId = 123456
}

export enum SORT_ID {
  default_id_sort_function=1
}

export enum INDEX {
  one = 1,
  two = 2,
  three = 3,
  minusTwo = -2,
  hundred = 100,
  weather_value = 273.15,
  nine = 9,
  thirtyTwo = 32,
  five = 5,
  zero = 0,
}

export enum WEATHER_LOCATION {
  burbankLatitude = 34.18,
  burbankLongitude = -118.30,
  leavesdenLatitude = 51.69,
  leavesdenLongitude = -0.41,
}