import { Component, TemplateRef } from '@angular/core';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-approval-delegation',
  templateUrl: './approval-delegation.component.html',
  styleUrls: ['./approval-delegation.component.scss']
})
export class ApprovalDelegationComponent {
  constructor(private modalService:NgbModal){}

  openDeleteConfirmationModal(content: TemplateRef<any>): void {
    this.modalService.open(content, { size: 'lg' , centered: true });
  }
     onDateRangeSelected(dateRange: { fromDate: NgbDate | null }) { }

     openDeleteConfirmationModalOne(content1: TemplateRef<any>): void {
      this.modalService.open(content1, { size: 'lg' , centered: true });
    }

    openDeleteConfirmationModalTwo(content2: TemplateRef<any>): void {
      this.modalService.open(content2, { size: 'lg' , centered: true });
    }

    openDeleteConfirmationModalThree(content3: TemplateRef<any>): void {
      this.modalService.open(content3, { size: 'lg' , centered: true });
    }
}
