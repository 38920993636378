import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { CRED_FORM_FOUR_CONSTANT, CREDIT_APP_TOAST_MESSAGES } from 'src/app/feature/customer-portal/constants/customer-portal.constant';
import { CreditApplicationsService } from 'src/app/feature/customer-portal/services/credit-applications/credit-applications.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';

@Component({
  selector: 'app-cred-app-step-four',
  templateUrl: './cred-app-step-four.component.html',
  styleUrls: ['./cred-app-step-four.component.scss']
})
export class CredAppStepFourComponent {
  @Input() startApplicationData: any;
  @Input() customerStatus: any;
  @Output() saveAndCloseEvent = new EventEmitter<void>();
  @Output() prevStepEvent = new EventEmitter<void>();
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  credFormFour = CRED_FORM_FOUR_CONSTANT;
  toastMessages = CREDIT_APP_TOAST_MESSAGES;
  submissionError; string;
  lineOfCreditData;
  letterOfGuaranteeData;
  caProdCompanyData;
  accountPayableContactData;
  companyProfileData;
  prinipalOfficerData;
  bankingReferenceData;
  tradeReferenceOneData;
  tradeReferenceTwoData
  tradeReferenceThreeData
  revisionData: any;

  constructor(
    private loaderService: LoaderService,
    private creditApplicationService: CreditApplicationsService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.patchDetailsOnCustomerStatus() 
  }

  /**
   * patchDetailsOnCustomerStatus() - to patch the details based on customer status for revisions and non-revision screen
   */
  patchDetailsOnCustomerStatus(): void{
    const data = this.revisionData?.caRevisionDetails?.applicationFormDetails;
    if(this.customerStatus !== this.credFormFour.customerStatus){
      this.lineOfCreditData = this.startApplicationData?.creditApplicationList?.lineOfCredit;
      this.letterOfGuaranteeData =  this.startApplicationData?.creditApplicationList?.letterOfGuarantee;
      this.caProdCompanyData = this.startApplicationData?.creditApplicationList?.caProdCompany;
      this.accountPayableContactData = this.startApplicationData?.creditApplicationList?.accountPayableContact;
      this.companyProfileData = this.startApplicationData?.creditApplicationList?.caCompanyProfile;
      this.prinipalOfficerData = this.startApplicationData?.creditApplicationList?.partners;
      this.bankingReferenceData = this.startApplicationData?.creditApplicationList?.bankingReferences;
      this.tradeReferenceOneData = this.startApplicationData?.creditApplicationList?.tradeReferences1;
      this.tradeReferenceTwoData = this.startApplicationData?.creditApplicationList?.tradeReferences2;
      this.tradeReferenceThreeData = this.startApplicationData?.creditApplicationList?.tradeReferences3;
    }
    else{
      this.lineOfCreditData = data?.lineOfCreditDetails;
      this.letterOfGuaranteeData =  data?.letterOfGuaranteeDetails;
      this.caProdCompanyData = data?.productionCompanyAddressDetails;
      this.accountPayableContactData = data?.accountsPayableContactsDetails;
      this.companyProfileData = data?.caCompanyProfileDetails;
      this.prinipalOfficerData = data?.partnersDetails;
      this.bankingReferenceData = data?.bankingReferencesDetails;
      this.tradeReferenceOneData = data?.tradeReference1Details
      this.tradeReferenceTwoData =  data?.tradeReference2Details
      this.tradeReferenceThreeData =  data?.tradeReference3Details
    }

  }

  /**
   * saveAndClose() - method to save the credit application and move the page to previously opened page prior to credit application
   */
  saveAndClose(): void {
    this.submitCreditApplication();
    this.saveAndCloseEvent.emit();
  }

  /**
   * submitCreditApplication() - method to submit the credit application
   */
  submitCreditApplication(): void {
    this.loaderService.setLoadingState(true);
    let payloadData = this.payloadData();

    this.creditApplicationService.addCertificateOfInsurance(payloadData).subscribe({
      next: (response: any) => {
        this.loaderService.setLoadingState(false);
        this.showSuccess(this.successTpl);
      },
      error: (error) => {
        console.error('Error submitting Certificate of Insurance:', error);
        this.submissionError = error;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
   * payloadData() - method to return the payload data to be sent to the api
   * @returns - the payload data
   */
  payloadData(): any {
    let caSeqId = this.startApplicationData?.caSeqId;
    let caNumber = this.startApplicationData?.caNumber;

    let payload = {
      caSeqId: caSeqId,
      caNumber: caNumber,
      isCASubmitted: true
    }

    return payload;
  }

   /**
   * previousPage() - method to handle the event of going back to previous page
   */
   previousPage(): void {
    this.prevStepEvent.emit();
  }

   /**
   * getRevisionForm() - the get the revision form data
   * @param caSeqId - the caseq need to be passed
   * @param caNumber - the caNumber need to be passed
   */
   getRevisionForm(): void {
    this.loaderService.setLoadingState(true);
    this.creditApplicationService.getRevisionForm(61, 61).subscribe({
      next: (response) => {
        this.revisionData = response
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        console.error(error);
        this.loaderService.setLoadingState(false);
      },
    });
  }

  /**
   * showSuccess() - Method to display a success toast notification.
   * @param successTpl - The template reference for the success message
   */
  showSuccess(successTpl: TemplateRef<any>): void {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: any): void {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }
}
