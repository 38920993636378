<div>
  <button
    class="btn btn-primary"
    (click)="openDeleteConfirmationModal(content)"
  >
    hello
  </button>
</div>
<ng-template #content let-modal>
  <div class="page-wrapper-modal">
    <div class="page-header">
    </div>
    <div class="refrence-letter-section">
      <div class="container cm-main-container">
          <div class="row">
            <h2 class="checkbox-heading mb-3">Add Department</h2>
           
            <div class="col-md-6 col-lg-4 col-sm-6 col-12 custom-floating-label">
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-1"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-1" class="common-checkbox-label">
                  Construction Services
                </label>
              </div>
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-2"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-2" class="common-checkbox-label">
                  Facilities Maintenance
                </label>
              </div>
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-3"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-3" class="common-checkbox-label">
                  Food Services
                </label>
              </div>
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-4"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-4" class="common-checkbox-label">
                  Motion Picture Imaging
                </label>
              </div>
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-5"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-5" class="common-checkbox-label">
                  Photo Lab
                </label>
              </div>
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-6"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-6" class="common-checkbox-label">
                  Special Events
                </label>
              </div>
              <div class="common-checkbox-button">
                <input
                  type="checkbox"
                  id="custom-checkbox-7"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-7" class="common-checkbox-label">
                  Transportation
                </label>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-6 col-12 custom-floating-label">
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-8"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-8" class="common-checkbox-label">
                  Costume
                </label>
              </div>
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-9"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-9" class="common-checkbox-label">
                  Fire & Security
                </label>
              </div>
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-10"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-10" class="common-checkbox-label">
                  Mail Services
                </label>
              </div>
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-11"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-11" class="common-checkbox-label">
                  Office & Production
                </label>
              </div>
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-12"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-12" class="common-checkbox-label">
                  Post Production Sound
                </label>
              </div>
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-13"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-13" class="common-checkbox-label">
                  Staff Shop
                </label>
              </div>
              <div class="common-checkbox-button">
                <input
                  type="checkbox"
                  id="custom-checkbox-14"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-14" class="common-checkbox-label">
                  Utilities Services
                </label>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-6 col-12 custom-floating-label">
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-15"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-15" class="common-checkbox-label">
                  Design Studio
                </label>
              </div>
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-16"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-16" class="common-checkbox-label">
                  First Aid
                </label>
              </div>
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-17"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-17" class="common-checkbox-label">
                  Metal Shop
                </label>
              </div>
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-18"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-18" class="common-checkbox-label">
                  Parking
                </label>
              </div>
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-19"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-19" class="common-checkbox-label">
                  Property
                </label>
              </div>
              <div class="common-checkbox-button mb-4">
                <input
                  type="checkbox"
                  id="custom-checkbox-20"
                  class="common-checkbox-input"
                />
                <label for="custom-checkbox-20" class="common-checkbox-label">
                  Studio Operations
                </label>
              </div>
            </div>
          </div>
    </div>
  </div>
  <div class="save-btn-wrapper">
    <div class="d-flex flex-row align-items-center g-3">
      <button class="common-btn-light btn">Cancel</button>
      <button class="common-btn-secondary btn save-btn">Save</button>
    </div>
  </div>
  </div>
</ng-template>

   <!---Added modals on click of Add/Edit New Account-->
   <div>
    <button
      class="btn btn-primary"
      (click)="openAddEditModal(addEditNewAcc)"
    >
      Add/Edit
    </button>
  </div>
   
  <div>
    <button
      class="btn btn-primary"
      (click)="denyModal(denyCreditApp)"
    >
     Deny/Credit
    </button>
  </div>
   <ng-template #addEditNewAcc let-modal>
    <div class="modal-body p-5">
      <div class="page-header">
          <h4 class="mb-3">Add/Edit New Account Payable Contact</h4>
      </div>    
      <div class="row divider-line mb-3">
        <div class="col-md-4 mb-3 custom-floating-label">
              <label for="input1" class="form-label">Name</label>
              <input type="text" disabled class="pe-4 form-control" placeholder="Name" value="Jon Doe"  id="input1">        
           
        </div>
        <div class="col-md-5 mb-3 custom-floating-label">
              <label for="input1" class="form-label">Email</label>
              <input type="text" disabled class="pe-4 form-control" placeholder="Email" value="Jond@domain.com"  id="input1">        
        </div>
        <div class="col-md-3"></div>
      </div>
      
      <div class="row">
          <div class="col-md-3 mb-3 custom-floating-label">
              <label for="input1" class="form-label">Name</label>
            
                <input type="text" class="pe-4 form-control" placeholder="Name"  id="input3">        
              </div>

              <div class="col-md-3 mb-3 custom-floating-label">
                <label for="input1" class="form-label">Name</label>
              
                  <input type="text" class="pe-4 form-control" placeholder="Name"  id="input3">        
                </div>
  

              
        
          <div class="col-md-3 mb-3 custom-floating-label">
            <label for="input1" class="form-label">Email</label>
     
                <input type="text" class="pe-4 form-control" placeholder="Email"  id="input4">        
              </div>
       
          <div class="col-md-3 d-flex align-items-center"><a href="#" class="remove-contact-btn"> Remove Contact</a></div>
        </div>
        <div class="">
          <a href="#" class="text-link add-contact-btn">+ Add Contact</a>
        </div>    
    </div>
    <div class="end-wrapper p-4">
      <div class="button gap-3 justify-content-start">
          <button type="button" class="common-btn-link btn btn-link"><img src="assets/images/blue-left-icon.svg"> back</button>
          <button type="button" class="common-btn-light btn" (click)="modal.close('Close click')">Cancel</button>      
      </div>
    </div>
  </ng-template>
  
   <!---Added modals on click of Deny Credit Application-->
   <ng-template #denyCreditApp let-modal>
      <div class="modal-body p-5">
        <div class="page-header">
            <h4 class="mb-2 heading">Deny Credit Application</h4>
        </div>    
        <p class="sub-heading">
          COMPANY: A Brand New Prod Company <br>
          PROJECT: The New Show
        </p> 
        <h5 class="heading mb-1">Select Reason:</h5>
        <div class="reason-list-box">
          <h6 class="mb-3">FOR INTERNAL USE ONLY</h6>
          <div class="common-checkbox-button mb-3">
            <input type="checkbox" id="custom-checkbox-1" class="common-checkbox-input" />
            <label for="custom-checkbox-1" class="common-checkbox-label">
              Invalid Trade Reference / Not in the Entertainment Business
            </label>
          </div>
          
          <div class="common-checkbox-button mb-3">
            <input type="checkbox" id="custom-checkbox-2" class="common-checkbox-input" />
            <label for="custom-checkbox-2" class="common-checkbox-label">
              No D&B
            </label>
          </div>
          
          <div class="common-checkbox-button mb-3">
            <input type="checkbox" id="custom-checkbox-3" class="common-checkbox-input" />
            <label for="custom-checkbox-3" class="common-checkbox-label">
              No Bank Reference
            </label>
          </div>
          
          <div class="common-checkbox-button mb-3">
            <input type="checkbox" id="custom-checkbox-4" class="common-checkbox-input" />
            <label for="custom-checkbox-4" class="common-checkbox-label">
              No Bank or Trade Responses
            </label>
          </div>
          
          <div class="common-checkbox-button mb-3">
            <input type="checkbox" id="custom-checkbox-5" class="common-checkbox-input" />
            <label for="custom-checkbox-5" class="common-checkbox-label">
              No Favorable Trades
            </label>
          </div>
          
          <div class="common-checkbox-button mb-3">
            <input type="checkbox" id="custom-checkbox-6" class="common-checkbox-input" />
            <label for="custom-checkbox-6" class="common-checkbox-label">
              Production Company Less 6 Months in business
            </label>
          </div>
          
          <div class="common-checkbox-button mb-3">
            <input type="checkbox" id="custom-checkbox-7" class="common-checkbox-input" />
            <label for="custom-checkbox-7" class="common-checkbox-label">
              Customer Did Not Want A/R Account
            </label>
          </div>
          
          <div class="common-checkbox-button mb-3">
            <input type="checkbox" id="custom-checkbox-8" class="common-checkbox-input" />
            <label for="custom-checkbox-8" class="common-checkbox-label">
              Exclude Until Customer Complete Information
            </label>
          </div>
          
          <div class="common-checkbox-button">
            <input type="checkbox" id="custom-checkbox-9" class="common-checkbox-input" />
            <label for="custom-checkbox-9" class="common-checkbox-label">
              Other
            </label>
          </div>
          
         
        </div>
      </div>
      <div class="end-wrapper ps-5 pe-5 py-4">
        <div class="button button-group  gap-3 ">          
            <button type="button" class="common-btn-light btn mb-2" (click)="modal.close('Close click')">Cancel</button>      
            <div class=" text-end">
              <button type="button" class="common-btn-secondary btn" (click)="modal.close('Close click')">Send Risk Email</button>
              <button type="button" class="btn-danger btn ms-3" (click)="modal.close('Close click')">Deny</button>      
            </div>
        </div>
      </div>
    </ng-template> 