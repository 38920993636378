<p>studio-pending-risk works!</p>
<button data-bs-toggle="modal" data-bs-target="#credApp">Credit Application</button>
<!-- common modal section-->
<div class="modal fade" id="credApp" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="false">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
          <h5 class="modal-title" id="commonModalLabel">Credit Application</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
              class="cm-close-text">Close</span></button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
              <div class="modal-left-section me-4">
                <h4 class="common-left-section-title mt-4">Credit Application</h4>
                <div class="mb-4 mt-3">
                    <div class="list-group list-group-flush mb-2">
                        <ul class="step-list">
                        <li>                           
                           <span class="step-text current-step"> Status</span>
                        </li>
                        <li>
                            <span class="step-text"> Form</span>
                        </li>
                        </ul>
                    </div>
                    <span class=" mb-1"><a ><img src="../assets/images/pdf-blue-icon.svg" alt="" class="me-2"><span class="text-link">Download Packet</span></a></span>
                </div>
                <hr>
                <h4 class="common-left-section-title ">Company Info</h4>
                <div class="company-meta-data">
                  <p class="title mb-0">
                    <strong class="common-title">PROJECT:</strong
                    ><span class="common-content">The new show</span>
                  </p>
                  <p class="title mb-0 pb-2">
                    <strong class="common-title">Comapny:</strong>
                  </p>
                  <p class="common-content pb-2 pt-0 mb-0">
                    A Brand New Production Company
                  </p>
                  <p class="title mb-0">
                    <strong class="common-title">TYPE:</strong
                    ><span class="common-content">Major Studio</span>
                  </p>
                  <p class="title mb-0">
                    <strong class="common-title">APPLICATION DATE:</strong
                    ><span class="common-content">1/1/24</span>
                  </p>
                  <div class="d-flex flex-column mt-2">
                    <span class=" mb-1"><a class=""><img src="../assets/images/link-icon.svg" alt="" class="me-2 "><span class="text-link">View Projects & Quotes</span></a></span>
                    <span class=" mb-1"><a class="" ><img src="../assets/images/link-icon.svg" alt="" class="me-2 "><span class="text-link">View Company</span></a></span>

                  </div>
                </div>            
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="container cm-main-container px-0 mx-0">

                <div class="common-modal-input-section grey-bg review-panel">
                  <div class="row  align-items-center">
                    <div class="col-12 col-sm-4 col-md-4 col-lg-4 custom-status-text">
                        <h4 class="section-main-header cust-risk-pend-sec-header">Status</h4>
                      </div>
                      <div class="col-12 col-sm-4 col-md-4 col-lg-4 custom-status-button">
                        <div class="status-container">
                            <div class="ar-status-tag revision need-revision-width ">Pending Risk</div>
                        </div>
                       </div>
                     
                </div>
                <div class="row mb-4 align-items-center">
                  <div class="col-md-12">
                    <p class="mb-1"><strong class="title-content">Credit Limit : </strong> $1,000,000.00</p>
                    <p class="mb-1"><strong class="title-content">Sent Risk Email on : &nbsp; </strong> <span class="pend-risk-details"> 10/19/2024</span><a href="#" class="mx-2 text-decoration-none"><span class="text-link">View</span></a> 
                        <span class=""><strong class="title-content">Sent by :&nbsp; </strong><span class="mb-0 title-content"> Sophia Martinez</span> </span>
                    </p>
                    <p class=""><strong class="title-content">Reasons : &nbsp;</strong><span class="title-content"> No Bank Reference</span></p>
                    <div class="mb-5">
                        <button class="btn common-btn-secondary me-3" >Review Risk Email</button>
                        <strong class="title-content">(Submit your approval before  1/1/24 3:25pm)</strong>
                    </div>
                  </div>
                  </div>
                  <hr>
                  <p><strong class="title-content">Departments : </strong></p>
                  <p><span class="pend-risk-details">Studio Operations; PPCS; Transportation; Design Studio; Property</span></p>
                </div>

                <div class="grey-box mb-0">
                  <h2 class="section-main-header text-left common-heading">
                    Comments(5)
                  </h2>
                  <div class="comment-section">
                    <div class="row comment-list">
                      <div class="commnet-icon-section col-md-1">
                        <span class="user-icon-text">AK</span>
                      </div>
                      <div class="common-comment-section col-11">
                        <p class="font-14 mb-0">
                          <strong class="common-content-bold"
                            >Andrew Noel</strong
                          >
                        </p>
                        <p class="common-small-content">xx/xx/xx xx:xxAM</p>
                        <p class="common-normal-content">
                          Sent email to Trade Reference 1
                        </p>
                      </div>
                    </div>
                    <div class="row comment-list">
                      <div class="commnet-icon-section col-md-1">
                        <span class="user-icon-text">AK</span>
                      </div>
                      <div class="common-comment-section col-11">
                        <p class="font-14 mb-0">
                          <strong class="common-content-bold"
                            >Andrew Noel</strong
                          >
                        </p>
                        <p class="common-small-content">xx/xx/xx xx:xxAM</p>
                        <p class="common-normal-content">
                          Asked revisions for Banking References:<br />
                          <strong class="common-content-extra-bold"
                            >“Could you please let us know the contact’s full
                            name?”</strong
                          >
                        </p>
                      </div>
                    </div>
                    <div class="row comment-list no-border">
                      <div class="commnet-icon-section col-md-1">
                        <span class="user-icon-text">AK</span>
                      </div>
                      <div class="common-comment-section col-11">
                        <p class="font-14 mb-0">
                          <strong class="common-content-bold"
                            >Andrew Noel</strong
                          >
                        </p>
                        <p class="common-small-content">xx/xx/xx xx:xxAM</p>
                        <p class="common-normal-content">Lorem Ipsum</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grey-box mt-1">
                    <div class="row text-area-block">
                        <div class="col-12">
                          <textarea name="" id="" class="form-control form-control-lg common-text-area">Type your comment…</textarea>
                        </div>
                    </div>
                  </div>
                  <div class="cm-btn-section">                        
                      <button type="button" class="btn common-btn-secondary me-2 mb-2">Add Comment</button>
                  </div>   
                
                

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
    