<div class="container-fluid page-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="page-title px-0">User Name</h1>
      </div>
      <div class="col-md-3 col-lg-3 d-none d-md-block">
        <ol
          class="handbook-list"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <li
            class="nav-link"
            id="profile-tab"
            role="tab"
            aria-controls="profile-content"
            aria-selected="true"
            (click)="selectTab('profile-tab')"
          >
            <span class="list-number">1.</span> Profile
          </li>
          <li
            class="nav-link"
            id="settings-tab"
            role="tab"
            aria-controls="settings-content"
            aria-selected="false"
            (click)="selectTab('settings-tab')"
          >
            <span class="list-number">2.</span> Settings
          </li>
          <li
            class="nav-link"
            id="email-notifications-tab"
            role="tab"
            aria-controls="email-notifications-content"
            aria-selected="false"
            (click)="selectTab('email-notifications-tab')"
          >
            <span class="list-number">3.</span> Email Notifications
          </li>
        </ol>
      </div>
      <div
        class="col-md-4 col-lg-4 col-sm-12 d-block d-md-none mobile-select-block"
      >
        <select
          class="form-select"
          id="mobileSelect"
          [(ngModel)]="selectedTab"
          (change)="onSelectChange($event)"
        >
          <option value="profile-tab">
            <span class="list-number">1.</span> Profile
          </option>
          <option value="settings-tab">
            <span class="list-number">2.</span> Settings
          </option>
          <option value="email-notifications-tab">
            <span class="list-number">3.</span> Email Notifications
          </option>
        </select>
      </div>
      <div class="col-md-9 col-lg-9 col-sm-12">
        <!-- Tab Content -->
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade"
            [ngClass]="{ 'show active': selectedTab === 'profile-tab' }"
            id="profile-tab"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div class="page-details-section">
              <div class="profile-container">
                <h2 class="profile-heading mb-0">My Profile</h2>
                <form [formGroup]="profileForm" (ngSubmit)="onSave()">
                  <div class="col-md-12 custom-floating-label">
                    <label for="input1" class="form-label">First Name</label>
                    <input
                      type="text"
                      placeholder="Placeholder"
                      id="input1"
                      class="form-control"
                      formControlName="input1"
                    />
                  </div>
                  <div>
                    <label for="timezone" class="commmon-header"
                      >Time Zone:</label
                    >
                    <select
                      id="timezone"
                      formControlName="timezone"
                      class="form-select custom-select"
                    >
                      <option *ngFor="let zone of timeZones" [value]="zone">
                        {{ zone }}
                      </option>
                    </select>
                  </div>
 
                  <div>
                    <label class="commmon-header">Date Format:</label>
                    <div class="radio-group d-flex">
                      <div class="common-radio-btn me-3">
                        <input
                          class="common-radio-input mb-0"
                          type="radio"
                          name="dateFormat"
                          id="mdy"
                          formControlName="dateFormat"
                          value="m/d/yyyy"
                        />
                        <label class="common-radio-label" for="mdy">
                          m/d/yyyy
                        </label>
                      </div>
                      <div class="common-radio-btn">
                        <input
                          class="common-radio-input mb-0"
                          type="radio"
                          name="dateFormat"
                          id="dmy"
                          formControlName="dateFormat"
                          value="d/m/yyyy"
                        />
                        <label class="common-radio-label" for="dmy">
                          d/m/yyyy
                        </label>
                      </div>
                    </div>
                  </div>
 
                  <div class="form-field justify-content-between">
                    <div class="d-flex justify-content-between mb-0 gap-4">
                      <div class="d-flex flex-column mb-0">
                        <h3 class="field-label">Header</h3>
                        <p class="field-description">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Maecenas faucibus mollis interdum.
                        </p>
                      </div>
                      <div class="form-check form-switch mb-0">
                        <input
                          class="form-check-input switch common-tog"
                          type="checkbox"
                          role="switch"
                          checked
                          formControlName="notifications"
                        />
                      </div>
                    </div>
                  </div>
 
                  <div class="d-flex gap-3">
                    <button type="submit" class="common-btn-secondary btn">Save</button>
                    <button type="button" class="common-btn-light btn" (click)="onDiscard()">
                      Discard Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
 
          <div
            class="tab-pane fade"
            [ngClass]="{ 'show active': selectedTab === 'settings-tab' }"
            id="settings-tab"
            role="tabpanel"
            aria-labelledby="settings-tab"
          >
            <div class="page-details-section">
              <div class="settings-container">
                <h2 class="settings-heading">Settings</h2>
                <form [formGroup]="settingsForm" (ngSubmit)="onSaveSettings()">
                  <div>
                    <label for="timezone" class="form-label">Time Zone:</label>
                    <select
                      id="timezone"
                      class="form-control custom-select"
                      formControlName="timezone"
                    >
                      <option *ngFor="let zone of timeZones" [value]="zone">
                        {{ zone }}
                      </option>
                    </select>
                  </div>
 
                  <div>
                    <label class="commmon-header">Date Format:</label>
                    <div class="radio-group d-flex">
                      <div class="common-radio-btn me-3">
                        <input
                          class="common-radio-input mb-0"
                          type="radio"
                          name="dateFormat"
                          id="mdy"
                          formControlName="dateFormat"
                          value="m/d/yyyy"
                        />
                        <label class="common-radio-label mb-0" for="mdy">
                          m/d/yyyy
                        </label>
                      </div>
                      <div class="common-radio-btn">
                        <input
                          class="common-radio-input mb-0"
                          type="radio"
                          name="dateFormat"
                          id="dmy"
                          formControlName="dateFormat"
                          value="d/m/yyyy"
                        />
                        <label class="common-radio-label mb-0" for="dmy">
                          d/m/yyyy
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label class="commmon-header">Calendar Format:</label>
                    <div class="radio-group d-flex">
                      <div class="common-radio-btn">
                        <input
                          class="common-radio-input mb-0"
                          type="radio"
                          name="calendarFormat"
                          id="sunday"
                          formControlName="calendarFormat"
                          value="sunday"
                        />
                        <label class="mb-0 common-font" for="mdy">
                          Starts on Sunday
                        </label>
                      </div>
                      <div class="common-radio-btn">
                        <input
                          class="common-radio-input mb-0"
                          type="radio"
                          name="calendarFormat"
                          id="monday"
                          formControlName="calendarFormat"
                          value="monday"
                        />
                        <label class="common-radio-label mb-0 common-font" for="dmy">
                          Starts on Monday
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label class="commmon-header">Time Format:</label>
                    <div class="radio-group d-flex">
                      <div class="common-radio-btn me-3">
                        <input
                          class="common-radio-input mb-0"
                          type="radio"
                          name="timeFormat"
                          id="12h"
                          formControlName="timeFormat"
                          value="12h"
                        />
                        <label class="common-radio-label mb-0" for="12h">
                          12h
                        </label>
                      </div>
                      <div class="common-radio-btn">
                        <input
                          class="common-radio-input mb-0"
                          type="radio"
                          name="timeFormat"
                          id="24h"
                          formControlName="timeFormat"
                          value="24h"
                        />
                        <label class="common-radio-label mb-0" for="24h">
                          24h
                        </label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label class="commmon-header"
                      >Preferred Temperature Unit:</label
                    >
                    <div class="radio-group d-flex">
                      <div class="common-radio-btn me-3">
                        <input
                          class="common-radio-input mb-0"
                          type="radio"
                          name="temperatureUnit"
                          id="fahrenheit"
                          formControlName="temperatureUnit"
                          value="fahrenheit"
                        />
                        <label
                          class="common-radio-label mb-0"
                          for="fahrenheit"
                        >
                          Fahrenheit
                        </label>
                      </div>
                      <div class="common-radio-btn">
                        <input
                          class="common-radio-input mb-0"
                          type="radio"
                          name="temperatureUnit"
                          id="celsius"
                          formControlName="temperatureUnit"
                          value="celsius"
                        />
                        <label
                          class="common-radio-label mb-0"
                          for="celsius"
                        >
                          Celsius
                        </label>
                      </div>
                    </div>
                  </div>
                  <div >
                    <button type="submit" class="common-btn-secondary btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
 
          <div
            class="tab-pane fade"
            [ngClass]="{
              'show active': selectedTab === 'email-notifications-tab'
            }"
            id="email-notifications-tab"
            role="tabpanel"
            aria-labelledby="email-notifications-tab"
          >
            <div class="page-details-section">
              <div class="email-notifications-form">
                <h1 class="text-center">Email Notifications</h1>
                <div class="form-field justify-content-between mb-4">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column">
                      <h6 class="field-label">Credit Applications</h6>
                      <p class="field-description">
                        Credit Applications & their statuses
                      </p>
                    </div>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input switch"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked
                      />
                    </div>
                  </div>
                </div>
                <div class="form-field justify-content-between">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column">
                      <p class="field-label">Credit Applications</p>
                      <p class="field-description">
                        Credit Applications & their statuses
                      </p>
                    </div>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input switch"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div >
                <button type="submit" class="common-btn-secondary btn">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>