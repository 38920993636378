<div class="container-fluid banner-landing">
    <img src="/assets/images/banner-image.png" alt="">
</div>
<div class="container">
    <div class="heading">
        <h3>WBSO Stage-1</h3>
        <button class="btn btn-primary"><i class="bi bi-file-pdf"></i>Download Pdf</button>
    </div>
    <hr>
    <div class="container">
        <h2>Measurement</h2>
        <div class="measurement-item">
            <span>Stage Height</span>
            <div class="measurement-line"></div>
            <span>35'</span>
        </div>
        <div class="measurement-item">
            <span>Stage ft.</span>
            <div class="measurement-line"></div>
            <span>10,791 sq. ft.</span>
        </div>
        <div class="measurement-item">
            <span>Dimensions</span>
            <div class="measurement-line"></div>
            <span>109' x 99'</span>
        </div>
        <div class="measurement-item">
            <span>Buildable Area</span>
            <div class="measurement-line"></div>
            <span>8,203 sq. ft.</span>
        </div>
        <div class="measurement-item">
            <span>Elephant Door (East)</span>
            <div class="measurement-line"></div>
            <span>20'W x 23'H</span>
        </div>
    
        <hr>
    
        <h2>Utilities</h2>
        <div class="measurement-item">
            <span>Air Conditioning</span>
            <div class="measurement-line"></div>
            <span>central</span>
        </div>
        <div class="measurement-item">
            <span>Heat</span>
            <div class="measurement-line"></div>
            <span>Yes</span>
        </div>
        <div class="measurement-item">
            <span>Power</span>
            <div class="measurement-line"></div>
            <span>4800 amps/3 phase</span>
        </div>
        <div class="measurement-item">
            <span>Internet</span>
            <div class="measurement-line"></div>
            <span>Internet and fiber available</span>
        </div>
    
        <hr>
    
        <h2>Support Space</h2>
        <div class="measurement-item">
            <span>Int. Dressing Rooms w/ Restrooms</span>
            <div class="measurement-line"></div>
            <span>None</span>
        </div>
        <div class="measurement-item">
            <span>Gold Rooms</span>
            <div class="measurement-line"></div>
            <span>None</span>
        </div>
        <div class="measurement-item">
            <span>Sound Booth</span>
            <div class="measurement-line"></div>
            <span>None</span>
        </div>
        <div class="measurement-item">
            <span>Dimmer Room Upper Catwalks</span>
            <div class="measurement-line"></div>
            <span>None</span>
        </div>
    </div>
        <hr>
        <h2>Weight Restrictions - Stage Floor</h2>
    <div class="measurement-item">
        <span>MultiLayer Sub Floor w/ Cresform Surface</span>
        <div class="measurement-line"></div>
        <span>High density particle board atop (2) layers plywood atop raised compartment – rests atop 2x6 to 2x9” joists, supported by 6x6 to 8x8” wood girders, supported by 6x6 to 8x8” wood posts (6’ on center) on pressure-treated sleeper planks or concrete blocks.</span>
    </div>
    <div class="measurement-item">
        <span>Temporary Load Capacity</span>
        <div class="measurement-line"></div>
        <span>150 PSF</span>
    </div>
    <div class="measurement-item">
        <span>Max Gross Vehicle Weight (GVW)</span>
        <div class="measurement-line"></div>
        <span>Buildable Floor Area - 10,000 lbs. (2,500 per wheel)</span>
    </div>
    <div class="measurement-item">
        <span>Interior Perimeter/Access Hatch (GVW)</span>
        <div class="measurement-line"></div>
        <span>Max - 10,000 lbs. (2,500 per wheel)</span>
    </div>
    <div class="measurement-item">
        <span>Truck Loading Zone (GVW)</span>
        <div class="measurement-line"></div>
        <span>14,000 lbs (3,500 per wheel)</span>
    </div>
    <hr>

    <h2>Weight Restrictions - Overhead Loading</h2>
    <div class="measurement-item">
        <span>Load Limit Permanent Beams (perpendicular to truss)</span>
        <div class="measurement-line"></div>
        <span>6’x8’</span>
    </div>
    <div class="measurement-item">
        <span>500 lbs. single load / 300 lbs. for two loads</span>
        <div class="measurement-line"></div>
        <span></span>
    </div>
    <div class="measurement-item">
        <span>Load Limit "Green Sticks"</span>
        <div class="measurement-line"></div>
        <span>100 lbs.</span>
    </div>
    <div class="measurement-item">
        <span>Load only at nodes of roof truss or permanent beams.</span>
        <div class="measurement-line"></div>
        <span>DO NOT apply weight to center of truss or walkway planks.</span>
    </div>
    <div class="last-para">
        <div class="last-para-heading">
            <strong>Check connection of permanent beam to truss prior to load application.</strong>
        </div>
        <div >

        <ul>
            <li>Please contact OPS at 818-954-6777 and Safety & Environmental Affairs at 818-954-2890 to discuss any weight load questions.</li>
            <li>Engineering approval required for any unusual load requirements on roof trusses or permanent beams.</li>
            <li class="last-para-content-3">Fall Protection is mandatory whenever working beyond the catwalk or guardrail in the “ozone”.</li>
        </ul>
    </div>
    </div>
    </div> 






