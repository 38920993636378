import { Component, TemplateRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { projectDetails } from 'mockData/development/project-details';
import { AddProjectUserComponent } from 'src/app/shared/components/add-project-user/add-project-user.component';
import { ADD_USER_CONSTANTS, CREDIT_APPLICATION_CONSTANTS, EDIT_DESCRIPTION_CONSTANTS, ERROR_MSG, NEW_PROJECT, NEW_PROJECT_DETAILS, PROJECT_DETAILS_CONSTANTS, PROJECT_DETAILS_MODAL_CONTENT, PROJECT_DETAILS_SPACES, PROJECT_USERS_COUNT, SEASONS, STUDIO_PROJECT_USERS_LIST_CONSTANTS, VALIDATION_MSG } from '../../../constants/studio-portal.constant';
import { ProjectsService } from '../../../services/projects.service';
import { BOOKMARK, INDEX, PROJECT_DETAILS } from '../../../enum/studio-portal.enum';
import { ToastService } from 'src/app/shared/components/toast.service';
import { AddProjectComponent } from '../add-project/add-project.component';
import { PORTAL_TYPE } from 'src/app/shared/constants/common';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  projectUsersData;
  spacesAndResourcesData;
  projectUsersCount: any[] = [];
  projectDetails = projectDetails;
  spacesAndResources = PROJECT_DETAILS_SPACES;
  projectUsersConstants = PROJECT_USERS_COUNT;
  creditApplicationConstants = CREDIT_APPLICATION_CONSTANTS;
  userListConstants = STUDIO_PROJECT_USERS_LIST_CONSTANTS;
  stagesData: any[] = [];
  officesData: any[] = [];
  workshopsData: any[] = [];
  projectDetailsConstants = PROJECT_DETAILS_CONSTANTS;
  editConstants = EDIT_DESCRIPTION_CONSTANTS;
  addUserConstants = ADD_USER_CONSTANTS;
  projectModalContent = PROJECT_DETAILS_MODAL_CONTENT;
  projectDetailsEnum = PROJECT_DETAILS;
  projectText = NEW_PROJECT;
  message = VALIDATION_MSG;
  errorMsg = ERROR_MSG;
  projectDetailsText = NEW_PROJECT_DETAILS;
  seasons = SEASONS;
  projectData: any = {};
  modalReference: NgbModalRef;
  title = this.projectDetailsConstants.addUser;
  editForm: FormGroup;
  editStatusForm: FormGroup;
  additionalForm: FormGroup;
  notesForm: FormGroup;
  isEditAdditional: boolean
  isEditNotes: boolean;
  submitted: boolean;
  projectMetadata: any;
  projectProgrammingScheduleDetails: any;
  projectProjectCameraDetails: any;
  isBookmarkAdded: boolean;
  userProfileId: number;
  requestResponse: any;
  departmentDetails: any;
  userRoleDetails: any;
  permissionsData: any;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private projectsService: ProjectsService,
    private toastService: ToastService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.permissionsData = { ...userProfile.roleAssigned };
    this.getProjectDetails();
    this.getAddProjectUserMetadata();
    this.editForm = this.formBuilder.group({
      description: ['', Validators.required]
    });
    this.editStatusForm = this.formBuilder.group({
      projectStatus: ['', Validators.required]
    });
    this.notesForm = this.formBuilder.group({
      additionalNotes: ['']
    });
    this.additionalForm = this.formBuilder.group({
      cameraSetup: ['', [Validators.required]],
      programSchedule: ['', [Validators.required]],
      programScheduleSeason: ['', ],
      seasonOther: ['', ],
    },)
  }

  /**
  * triggerFileUpload() - Programmatically triggers a click event to open the file upload dialog.
  * @param fileInput - The file input element to trigger the click event on.
  */
  triggerFileUpload(fileInput: HTMLInputElement): void {
    fileInput.click();
  }

  /**
   * onFileSelected() - Handles the file selection event when a file is chosen from the file upload dialog.
   * @param event - The event triggered when the user selects a file.
   */
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files && input?.files?.length > 0) {
      const file = input.files[0];
    }
  }

  /**
   * navigateToViewAllUsers() - Navigates to the 'View All Users' screen, passing the current project's ID and name as query parameters.
   */
  navigateToViewAllUsers(): void {
    this.router.navigate(['feature/studio-dashboard/project/view-users'], {
      queryParams: {
        projectId: this.projectData.projectId,
        projectName: this.projectData.projectName,
        companyId: this.projectData.company.companyId,
        companyName: this.projectData.company.companyName,
      }
    });
  }

  /**
   * openViewAllUsers() - Navigates to the 'View All Users' screen with additional filter parameters, passing the current project and selected user label.
   * @param user - The user object containing the selected user's ID to filter by.
   */
  openViewAllUsers(user): void {
    const filterParams = {
      projectId: this.projectData?.projectId,
      projectName: this.projectData?.projectName,
      labelId: user?.id,
      companyId: this.projectData?.company?.companyId,
      companyName: this.projectData?.company?.companyName,
    };
    this.router.navigate(['feature/studio-dashboard/project/view-users'], {
      queryParams: filterParams
    });
  }

  /**
  * sortProjectUsersList() - Method to sort the list of users in alphabetical order.
  */
  sortProjectUsersList(): void {
    this.projectUsersData = this.projectUsersData.sort((a, b) =>
      a.firstName.localeCompare(b.firstName)
    );
  }

  /**
  * getSortedUsersList() - Method to get the sorted list of users with full names.
  */
  getSortedUsersList(): string[] {
    return _.map(this.projectUsersData, user => `${user.firstName} ${user.lastName}`);
  }

  /**
   * openEditProjectInfoModal() - method to open the edit project information modal
   */
  openEditProjectInfoModal(): void {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddProjectComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true,
    });
    this.modalReference.componentInstance.modalTitle = this.projectModalContent?.editProject;
    this.modalReference.componentInstance.editMode = true;
    this.modalReference.componentInstance.projectInfo = this.getProjectInfoData();
    this.modalReference.componentInstance.projectId = this.projectData?.projectId;
    this.modalReference.result.then(
      (result) => {
        if (result === 'refresh') {
          this.getProjectDetails();
        }
      },
      (dismissed) => {
        console.error(dismissed);
      }
    );
  }

/**
 * getProjectInfoData() - Method to create the project Info data
 * @returns - return the project info data for Edit Project component
 */
 getProjectInfoData(): any {
  const projectData = this.projectData;
  const projectInfoData = {
    company: projectData?.company,
    projectDetails: {
      projectName: projectData?.projectName,
      projectType: projectData?.projectTypeDetails?.projectTypeId <= this.projectDetailsEnum.six ? projectData?.projectTypeDetails?.projectType : 'Other',
      projectTypeOther: projectData?.projectTypeDetails?.projectTypeId >= this.projectDetailsEnum.seven ? projectData?.projectTypeDetails?.projectType : '',
      cameraSetup: projectData?.projectCameraSetup?.projectCameraSetup,
      programSchedule: projectData?.programmingSchedule?.programingSchedule,
      programScheduleSeason: projectData?.seasonNumber <= this.projectDetailsEnum.ten ? projectData?.seasonNumber : projectData?.seasonNumber > this.projectDetailsEnum.ten ? 'Other' : '',
      seasonOther: projectData?.seasonNumber > this.projectDetailsEnum.ten ? projectData?.seasonNumber : '',
      projectDesc: projectData?.projectDesc,
      projectStart: this.setDateFormat(projectData?.projectStartDate),
      projectEnd: this.setDateFormat(projectData?.projectEndDate),
      additionalNotes: projectData?.additionalScheduleNotes,
      customerType: projectData?.customerType?.customerType,
      primaryPayment: projectData?.primaryPaymentType?.paymentType,
    }
  }
  return projectInfoData;
 }

/**
 * setDateFormat() - Method to format the date for project form 
 * @param date - data date as an input value
 * @returns  - returns the formatted the date as year, month, day
 */
  setDateFormat(date: any): any {
    if (date) {
      const dateObject = new Date(date);
      let month = '' + (dateObject.getMonth() + this.projectDetailsEnum.one);
      let day = '' + dateObject.getDate();
      const year = dateObject.getFullYear();
      if (month?.length < this.projectDetailsEnum.two) month = '0' + month;
      if (day?.length < this.projectDetailsEnum.two) day = '0' + day;
      return [year, month, day].join('-');
    } else {
      return '';
    }
  }

  /**
   * getAddProjectUserMetadata() - Method to fetch the metadata for add project user screen
   */
  getAddProjectUserMetadata(): void {
    this.projectsService.fetchMetaData().subscribe((response: any) => {
      const metaData = response;
      this.departmentDetails = metaData?.getProjectUserMetaDataDTO?.departmentsDetails;
      this.userRoleDetails = metaData?.getProjectUserMetaDataDTO?.userRoleDetails;
    })
  }

  /**
  * openAddUserModal() - method to open the add a user modal
  */
  openAddUserModal(projectId: number, companyId: number): void {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddProjectUserComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true
    });
    this.modalReference.componentInstance.modalTitle = this.title;
    this.modalReference.componentInstance.projectName = this.projectData?.projectName;
    this.modalReference.componentInstance.departmentDetails = this.departmentDetails;
    this.modalReference.componentInstance.userRoleDetails = this.userRoleDetails;
    this.modalReference.componentInstance.portalType = PORTAL_TYPE.studioPortal;
    this.modalReference.componentInstance.projectId = projectId;
    this.modalReference.componentInstance.componentType = PORTAL_TYPE.studioTypeAdd;
    this.modalReference.componentInstance.projectCompanyId = companyId;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data.status == this.userListConstants.statusSuccess) {
          this.requestResponse = data;
          this.showSuccess(this.successTpl);
          this.getProjectDetailsByProjectId(this.userProfileId, this.projectData?.projectId);
        } else {
          this.requestResponse = data;
          this.showDanger(this.dangerTpl);
          console.error(data);
        }
      }
      // on close
    }, (reason) => {
      // on dismiss
    });
  }

  /**
   * getProjectDetails() - method to get the details of the project based on project id
   */
  getProjectDetails(): void {
    const projectId = parseInt(this.route.snapshot.paramMap?.get('projectId'));
    const profileId = parseInt(this.route.snapshot.paramMap?.get('profileId'));
    if (profileId && projectId) {
      this.userProfileId = profileId;
      this.getProjectDetailsByProjectId(profileId, projectId);
    }
  }

  /**
   * getProjectDetailsByProjectId() - Method to get the project details from the API call
   * @param projectId - project Id returns from the project page
   */
  getProjectDetailsByProjectId(profileId: number, projectId: number, ): void {
    this.loaderService.setLoadingState(true);
    this.projectsService.getProjectDetailsByUserAndProjectID(profileId, projectId)
    .pipe()
    .subscribe({
      next: (response: any) => {
        this.projectData = response?.projectDetails;
        this.projectUsersData = response?.projectDetails?.userProfiles;
        this.spacesAndResourcesData = response?.projectDetails?.spaceAndResourceses;
        this.isBookmarkAdded = response?.projectDetails?.projectBookmarked === BOOKMARK.one ? true : false;
        this.getSpacesAndResourcesData();
        this.projectUsersCount = this.generateProjectUsersCount(this.projectUsersData);
        this.sortProjectUsersList();
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        console.error(ERROR_MSG.projectDetails, error);
        this.requestResponse = error;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      }
    })
  }

  /**
   * addBookmark() - method to add the bookmark
   */
  addBookmark(): void {
    const statusText = this.projectData?.projectStatus?.projectStatus === this.userListConstants.active ? this.userListConstants.statusActive : this.userListConstants.statusDeactive;
    const bookmarkPayload = {
      userProfileId: this.userProfileId,
      projectId: this.projectData?.projectId,
      status: statusText,
      projectBookmarked: BOOKMARK.one
    }
    this.loaderService.setLoadingState(true);
    this.projectsService.addBookmarkByUserAndProjectID(bookmarkPayload)
    .pipe()
    .subscribe({
      next: (response: any) => {
        this.requestResponse = response;
        this.isBookmarkAdded = true;
        this.loaderService.setLoadingState(false);
        this.showSuccess(this.successTpl);
      },
      error: (error) => {
        this.requestResponse = error;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      }
    })
  }

  /**
   * removeBookmark() - method to remove the bookmark
   */
  removeBookmark(): void {
    const statusText = this.projectData?.projectStatus?.projectStatus === this.userListConstants.active ? this.userListConstants.statusActive : this.userListConstants.statusDeactive;
    const bookmarkPayload = {
      userProfileId: this.userProfileId,
      projectId: this.projectData?.projectId,
      status: statusText,
      projectBookmarked: BOOKMARK.zero
    }
    this.loaderService.setLoadingState(true);
    this.projectsService.removeBookmarkByUserAndProjectID(bookmarkPayload)
    .pipe()
    .subscribe({
      next: (response: any) => {
        this.requestResponse = response;
        this.isBookmarkAdded = false;
        this.loaderService.setLoadingState(false);
        this.showSuccess(this.successTpl);
      },
      error: (error) => {
        this.requestResponse = error;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
        console.error(ERROR_MSG.bookmarkRemoveError, error);
      }
    })
  }

  /**
   * openAdditionalProjectDetailsModal() - method to open the additional project details modal
   * @param additionalProjectDetailsModal - template reference for additional project details modal
   */
  openAdditionalProjectDetailsModal(additionalProjectDetailsModal: TemplateRef<any>): void {
    this.modalService.open(additionalProjectDetailsModal, {
      windowClass: 'common-modal-center', 
      centered: true 
    });
    this.loadAdditionalModalMetadata();
  }

  /**
   * openScheduledNoteModal() - method to open the schedule note modal
   * @param scheduledNoteModal - template reference for schedule note modal
   */
  openScheduledNoteModal(scheduledNoteModal: TemplateRef<any>): void {
    this.modalService.open(scheduledNoteModal, { 
      windowClass: 'common-modal-center', 
      centered: true 
    });
  }

  /**
   * openEditProjectStatusModal() - method to open the edit project status modal
   * @param editProjectStatusModal - template reference for edit project status modal
   */
  openEditProjectStatusModal(editProjectStatusModal: TemplateRef<any>): void {
    this.modalService.open(editProjectStatusModal, {
       windowClass: 'common-modal-center', 
       centered: true 
    });

    this.editStatusForm.patchValue({
      projectStatus: this.projectData?.projectStatus?.projectStatus
    });
  }

  /**
   * editProjectStatus() - Method to update the project status
   */
  editProjectStatus(): void {
    const statusId = this.editStatusForm.controls['projectStatus'].value === this.editConstants.notStarted ? INDEX.one : this.editStatusForm.controls['projectStatus'].value === this.editConstants.active ? INDEX.two : INDEX.three;
    const statuskPayload = {
      projectId: this.projectData?.projectId,
      loginUserId: this.userProfileId,
      projectStatusId: statusId,
    }
    this.projectsService.updateProjectStatusByUserAndProjectID(statuskPayload)
    .pipe()
    .subscribe({
      next: (response: any) => {
        this.requestResponse = response;
        this.getProjectDetailsByProjectId(this.userProfileId, this.projectData?.projectId);
        this.showSuccess(this.successTpl);
      },
      error: (error) => {
        this.requestResponse = error;
        this.showDanger(this.dangerTpl);
        console.error(ERROR_MSG.statusUpdateError, error);
      }
    })
  }

  /**
  * getSpacesAndResourcesData() - Method to filter and transform the spaces and resources data
  * Filters the spaces and resources data based on resource type ('Stage', 'Office', 'Workshop').
  * Transforms the filtered data into a structured format for display.
  */
  getSpacesAndResourcesData(): void {
    this.stagesData = this.transformData(this.spacesAndResourcesData.filter(item => item.resourceType === 'Stage'));
    this.officesData = this.transformData(this.spacesAndResourcesData.filter(item => item.resourceType === 'Office'));
    this.workshopsData = this.transformData(this.spacesAndResourcesData.filter(item => item.resourceType === 'Workshop'));
  }
  
  /**
  * transformData() - Method to transform raw resources data into a structured format
  * Loops through the resources array and organizes data into groups based on the `stageNo`.
  * Each group contains a list of documents, and bookmarks are flagged.
  * @param resources - Array of raw resource data to be transformed.
  * @returns Transformed array of resources organized by stages and documents.
  */
  transformData(resources: any[]): any[] {
    const result = [];
    resources.forEach(resource => {
      const existingStage = result.find(item => item.stageName === resource.stageNo);
      const document = {
        id: resource.resourceSpaceId,
        name: resource.resourceValue,
        bookmarked: resource.selectedInd === 1
      };
      if (existingStage) {
        existingStage.documentGroups[0].documents.push(document);
      } else {
        result.push({
          stageName: resource.stageNo,
          documentGroups: [
            {
              documents: [document]
            }
          ]
        });
      }
    });
    return result;
  }
    
  /**
  * generateProjectUsersCount() - Method to generate the count of users based on specific categories
  * Generates the total count of users, active users, deactivated users, and admins.
  * Returns an array of user count objects for each category.
  * @param projectUsersData - Array of user profile data.
  * @returns Array of user count objects for different categories (All, Active, Deactivated, Admin).
  */
  generateProjectUsersCount(projectUsersData: any[]) {
    return [
      {
        id: 1,
        usersCount: projectUsersData?.length,
        label: 'All'
      },
      {
        id: 2,
        usersCount: projectUsersData.filter(user => user.statusActiveInd === 'Y')?.length,
        label: 'Active'
      },
      {
        id: 3,
        usersCount: projectUsersData.filter(user => user.statusActiveInd === 'N')?.length,
        label: 'Deactivated'
      },
      {
        id: 4,
        usersCount: projectUsersData.filter(user => user.companyAdminInd === 1)?.length,
        label: 'Admin'
      }
    ];
  }

  /**
   * showSuccess() - Displays a success toast notification with custom content and options.
   * @param successTpl - The template reference containing the toast's content.
   */
     showSuccess(successTpl: TemplateRef<any>): void {
      this.toastService.show(successTpl, {
        classname: 'custom-toast',
        delay: 3000,
      });
    }
  
    /**
     * showDanger() - Method to display a danger (error) toast message
     * @param dangerTpl - The template reference for the danger message to display
     */
    showDanger(dangerTpl: any): void {
      this.toastService.show(dangerTpl, {
        classname: 'bg-danger text-light',
        delay: 3000,
      });
    }

  /**
   * Method to close the active modal window
   */
  closeModal(): void {
    this.activeModal.close();
  }

  /**
  * loadAdditionalModalMetadata() - Method to load the metadata for Additional project modal
  */
  loadAdditionalModalMetadata(): void {
    this.loaderService.setLoadingState(true);
    this.projectsService.getProjectCompanyAndFormList()
    .pipe()
    .subscribe({
      next: (response: any) => {
        this.projectMetadata = response;
        this.projectProgrammingScheduleDetails = response?.projectMetaData?.programmingScheduleDetails;
        this.projectProjectCameraDetails = response?.projectMetaData?.projectCameraDetails;
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        console.error(error);
        this.requestResponse = error;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      }
    })
  }

  /**
 * getPayloadData() - Method to create the project payLoad data
 * @param formData - add/edit project form filled data
 * @param formName - form name
 * @returns - return the payload for project
 */
 getPayloadData(formData: any, formName: string): any {
  const form = formData;
  const projectData = this.projectData;
  let payloadData = {
    companyId: projectData?.company?.companyId,
    projectTypeId: projectData?.projectTypeDetails?.projectTypeId,
    projectType: projectData?.projectTypeDetails?.projectTypeId === this.userListConstants.seven ? projectData?.projectTypeDetails?.projectType : '',
    customerTypeId: projectData?.customerType?.customerTypeId,
    projectCameraId: projectData?.projectCameraSetup?.projectCameraId,
    programingScheduleId: projectData?.programmingSchedule?.programingScheduleId,
    seasonNumber: projectData?.seasonNumber,
    paymentTypeId: projectData?.primaryPaymentType?.paymentTypeId,
    projectDesc: projectData?.projectDesc,
    projectName: projectData?.projectName,
    projectStartDate: this.setDateFormat(projectData?.projectStartDate),
    projectEndDate: this.setDateFormat(projectData?.projectEndDate),
    additionalScheduleNotes: form.additionalNotes,
    loginUserId: this.projectsService.getProfileId(),
    projectId: projectData?.projectId
  } as any;
  if (formName == 'camera') {
    payloadData.projectTypeId = this.projectDetailsEnum?.two;
    payloadData.projectType = this.projectDetailsText?.television;
    payloadData.projectCameraId = this.findIdByText(form.cameraSetup, this.projectProjectCameraDetails, 'projectCameraSetup', 'projectCameraId');
    payloadData.programingScheduleId = this.findIdByText(form.programSchedule, this.projectProgrammingScheduleDetails, 'programingSchedule', 'programingScheduleId');
    payloadData.seasonNumber = form.programScheduleSeason === 'Other' ? form.seasonOther : form.programScheduleSeason;
    return payloadData;
  }
  return payloadData;
 }

   /**
   * editNotesModal() - Method to edit the schedule notes project details modal
   */
   editNotesModal(): void {
    this.isEditNotes = true;
    this.notesForm.patchValue({
      additionalNotes: this.projectData?.additionalScheduleNotes
    });
  }

  /**
  * resetNotesModal() - Method to edit the schedule notes project details modal
  */
  resetNotesModal(): void {
    this.isEditNotes = false;
  }
  
  /**
  * editCameraAndProgramming() - Method to edit the additional project details modal
  */
  saveScheduleNotes(): void {
    this.submitted = true;
    this.requestResponse = '';
    if(this.notesForm.valid) {
      const editPayload = this.getPayloadData(this.notesForm.value, 'notes');
      this.loaderService.setLoadingState(true);
      this.projectsService.updateExistingProjectByUser(editPayload)
      .pipe()
      .subscribe({
        next: (response: any) => {
          this.requestResponse = response;
          this.getProjectDetailsByProjectId(this.userProfileId, this.projectData?.projectId);
          this.loaderService.setLoadingState(false);
          this.showSuccess(this.successTpl);
          this.isEditNotes = false;
        },
        error: (error) => {
          this.requestResponse = error;
          this.loaderService.setLoadingState(false);
          this.showDanger(this.dangerTpl);
          this.isEditNotes = false;
          console.error(error);
        }
      })
    } else {
      this.requestResponse = ERROR_MSG.formError;
      this.showDanger(this.dangerTpl);
    }   
  }

    /**
   * editAdditionalModal() - Method to edit the additional project details modal
   */
    editAdditionalModal(): void {
      this.isEditAdditional = true;
      const projectData = this.getProjectInfoData();
      this.additionalForm.patchValue({
        cameraSetup: projectData.projectDetails?.cameraSetup ? projectData.projectDetails?.cameraSetup : '',
        programSchedule: projectData.projectDetails?.programSchedule ? projectData.projectDetails?.programSchedule : '',
        programScheduleSeason: projectData.projectDetails?.programScheduleSeason ? projectData.projectDetails?.programScheduleSeason : '',
        seasonOther: projectData.projectDetails?.seasonOther ? projectData.projectDetails?.seasonOther : '',
      });
    }
  
    /**
     * resetAdditionalModal() - Method to cancel the additional project details modal
     */
    resetAdditionalModal(): void {
      this.isEditAdditional = !this.isEditAdditional;
    }
  
    /**
     * editCameraAndProgramming() - Method to edit the additional project details modal
     */
    editCameraAndProgramming(): void {
      // validator addition based on condition for hidden fields
      if (this.additionalForm.controls['programSchedule'].value === 'Series/Multi Episodes') {
        this.additionalForm.controls['programScheduleSeason'].addValidators([Validators.required]);
        this.additionalForm.controls['programScheduleSeason'].updateValueAndValidity();
        if (this.additionalForm.controls['programScheduleSeason'].value === 'Other') {
          this.additionalForm.controls['seasonOther'].setValidators([Validators.required, Validators.max(99), Validators.maxLength(2)]);
          this.additionalForm.controls['seasonOther'].updateValueAndValidity();
        }
      }
      // validator remove based on condition for hidden fields
      if (this.additionalForm.controls['programSchedule'].value !== 'Series/Multi Episodes') {
        this.additionalForm?.controls['programScheduleSeason']?.clearValidators();
        this.additionalForm?.controls['programScheduleSeason']?.updateValueAndValidity();
        if (this.additionalForm.controls['programScheduleSeason'].value !== 'Other') {
          this.additionalForm?.controls['seasonOther']?.clearValidators();
          this.additionalForm?.controls['seasonOther']?.updateValueAndValidity();
        }
      }
      this.submitted = true;
      this.requestResponse = '';
      if(this.additionalForm.valid) {
        const editPayload = this.getPayloadData(this.additionalForm.value, 'camera');
        this.loaderService.setLoadingState(true);
        this.projectsService.updateExistingProjectByUser(editPayload)
        .pipe()
        .subscribe({
          next: (response: any) => {
            this.requestResponse = response;
            this.getProjectDetailsByProjectId(this.userProfileId, this.projectData?.projectId);
            setTimeout(() => {
              this.modalService.dismissAll();
              this.loaderService.setLoadingState(false);
              this.isEditAdditional = !this.isEditAdditional;
              this.showSuccess(this.successTpl);
            }, 2000);
          },
          error: (error) => {
            this.requestResponse = error;
            this.loaderService.setLoadingState(false);
            this.showDanger(this.dangerTpl);
            console.error(error);
          }
        })
      } else {
        this.requestResponse = ERROR_MSG.formError.message;
        this.showDanger(this.dangerTpl);
      }
    }

  /**
   * findIdByText() - method to find the id from the given object
   * @param findText string that need to be searched in the object
   * @param arrList list of object
   * @param matchText property that need to be searched in the object
   * @param lookup id property that need to be searched in the object
   * @returns return the respective id
   */
  findIdByText(findText: string, arrList: any, matchText: any, lookup: any): number | undefined {
    const itemNumber = arrList.find(item => item[matchText] === findText);
    return itemNumber ? itemNumber[lookup] : '';
  }

}