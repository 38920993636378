<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form">
    <div class="page-wrapper">
        <div class="page-header">
            <h2>{{ requestArConstants.heading }}</h2>
        </div>
        <div class="title-company-project">
            <div class="company">
                <span class="company-heading">{{ requestArConstants.company }}</span>
                <span class="company-content">{{ companyName }}</span>
            </div>
            <div class="project">
                <span class="project-heading">{{ requestArConstants.project }}</span>
                <span class="project-content">{{ projectName}}</span>
            </div>
        </div>
        <div class="section-text-area max-height-textarea" [innerHtml]="this.requestArConstants.sendRequestArTextboxName"
            id="credit-text" name="credit-text" (input)="onHtmlContentChange($event)" contenteditable="true" appClickableLinks></div>
        <div class="common-checkbox-section">
            <div class="row">
                <h2 class="checkbox-heading">{{ requestArConstants.departments }}</h2>

                <div class="col-md-4 col-lg-4 col-sm-6 col-6 mb-3"
                    [ngClass]="{'col-lg-12 col-md-12 col-sm-12 col-12': first}"
                    *ngFor="let dept of departmentDetails; let first = first">
                    <div class="common-checkbox-button">
                        <input type="checkbox" [id]="'custom-checkbox-' + dept.departmentId"
                            class="common-checkbox-input" formControlName="department{{dept.departmentId}}"
                            [value]="dept.departmentId" (change)="onDepartmentCheckboxChange($event,dept)" />
                        <label [for]="'i-custom-checkbox-' + dept.departmentId" class="common-checkbox-label">
                            {{ dept.departmentName }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <p class="line"></p>
        <div class="account-payable-contact">
            <h4 class="account-payable-heading">{{ requestArConstants.send }}</h4>

            <div *ngFor="let user of accountPayableUsers" class="col-md-12">
                <div class="common-checkbox-button mb-4">
                    <input type="checkbox" id="custom-checkbox-50" class="common-checkbox-input"
                        formControlName="caAccountPayableUser{{user.userProfileId}}" [value]="user.userProfileId" />
                    <label for="i-custom-checkbox-50" class="common-checkbox-label ellipses-email text-label"
                        title="SEND TO ACCOUNTS PAYABLE CONTACTS">
                        {{ user.firstName }} {{ user.lastName }} ({{ user.emailAddress }})
                    </label>
                </div>
            </div>

        </div>

    </div>


    <div class="end-wrapper">
        <div class="account-payable-user">
            <img src="assets/images/add-user.svg" alt="add-user" />
            <span class="user-content text-link" (click)="openAddUserModal()">{{ requestArConstants.link }}</span>
        </div>
        <div class="button">
            <button class="common-btn-light btn" (click)="closeModal()">{{ requestArConstants.cancelBtn }}</button>
            <button class="common-btn-secondary btn" [disabled]="form.invalid || !sendLetterText">{{ requestArConstants.sendToCustomerBtn }}</button>
        </div>
    </div>
</form>

<ng-template #successTpl>
    <div class="success">{{displaySuccessMsg}}</div>
</ng-template>

<ng-template #dangerTpl>
    <div class="danger">{{errorResponse}}</div>
</ng-template>
