<div class="page-wrapper" *ngIf="!isLoading && companyData">
    <div class="container px-0 ">
      <div class="top-nav-company d-flex align-items-center mb-4">
        <img src="assets/images/keyboard_arrow_left.svg" alt="Company Icon" class="icon">
        <p class="all-companies mb-0" (click)="viewAllCompanies()">{{company.allcompanies}}</p>
      </div>
      <!-- Company Section -->

      <div class="row mx-0">
        <div class="page-header col-12 px-3">
          <h1 class="mb-0" *ngIf="!companyError">{{companyData.companyName}}</h1>
          <h1 class="mb-0" *ngIf="companyError">{{company.companyerror}}</h1>
        </div>    
      </div>
     <!-- Company Project Section -->
     <div class="row account-project-block">
      <div class="col-12 col-md-6 col-lg-6 col-sm-6 account-section">
        <h5 class="mb-0 title-contact">{{company.companyid}}</h5>
        <p class="mb-0">{{companyData.companyId}}</p>
      </div>
      <div class="col-12 col-md-6 col-lg-6 col-sm-6 project-section">
        <h5 class="mb-0 title-contact">{{company.projects}} ({{companyData.projectCount}})</h5>
        <div class="view-div d-flex align-items-center">
          <p (click)="viewAllProjects(companyData.companyId,companyData.companyName)" class="text-link view-sub-text mb-0 me-3">{{company.viewall}}</p>
          <button class="btn btn-dark custom-button" (click)="addProject()">+ {{company.addProject}}</button>
        </div>   
      </div>
    </div>
      <!-- <div class="row account-project-block">
        <div class="col-md-6 col-lg-6 col-sm-6 col-12 account-section">
          <h5 class="mb-0 title-contact">{{company.companyid}}</h5>
          <p class="mb-0">{{companyData.companyId}}</p>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-6 col-12 project-section">
          <h5 class="mb-0 title-contact">{{company.projects}} ({{companyData.projectCount}})</h5>
          <div class="view-div d-flex align-items-center">
            <p (click)="viewAllProjects(companyData.companyId,companyData.companyName)" class="text-link view-sub-text mb-0 me-3">{{company.viewall}}</p>
            <button class="btn btn-dark custom-button" (click)="addProject()">+ {{company.addProject}}</button>
          </div>   
        </div>
      </div> -->
       <!-- Federal Section -->
      <div class="row address-block" *ngIf="companyData.federalTax.federalTaxId">
        <div class="col-lg-4 col-sm-12 company-address">
          <h3 class="company-heading">
            {{company.federalTaxId}}
          </h3>
          <p class="company-content">
            {{companyData.federalTax.federalTaxId}}
          </p>
        </div>
      </div>
       <!-- Company Address Section -->
       <div class="row address-block" *ngIf="companyData.companyAddresses?.length > 1">
        <ng-container *ngFor="let address of companyData.companyAddresses">
          <!-- Check for Production Address first -->
          <ng-container *ngIf="address.companyAddressType.companyAddressTypeId === 1">
            <div class="col-lg-4 col-sm-12 company-address">
              <h3 class="company-heading">
                {{ company.companyaddress }}
              </h3>
              <p class="company-content">
                {{ address.street }}, {{ address.city }}, {{ address.state.stateName }}, {{ address.country.countryCode }}, {{ address.zip }} 
              </p>
            </div>
          </ng-container>
        </ng-container>
      
        <ng-container *ngFor="let address of companyData.companyAddresses">
          <!-- Render other addresses -->
          <ng-container *ngIf="address.companyAddressType.companyAddressTypeId !== 1">
            <div class="col-lg-4 col-sm-12 company-address">
              <h3 class="company-heading">
                {{ company.billingaddress}}
              </h3>
              <p class="company-content">
                {{ address.street }}, {{ address.city }}, {{ address.state.stateName }}, {{ address.country.countryCode }}, {{ address.zip }} 
              </p>
            </div>
          </ng-container>
        </ng-container>
      </div>
      
      <div class="row contact-info-block-1" *ngIf="companyData.companyAddresses?.length == 1">
        <div class="col-12 d-flex justify-content-between align-items-center pe-0 ps-0 mb-4">
          <h3 class="title-contact mb-0">{{company.companyaddress}}</h3>
        </div>
        <div class="col-12 ps-0 pe-0">
          <p class="contact-inner" *ngFor="let address of companyData.companyAddresses">
            {{address.street}}, {{address.city}}, {{address.state.stateName}}, {{address.country.countryCode}}, {{address.zip}}
          </p>
        </div>
      </div>
       <!-- Company Contact Section -->

      <div class="row contact-info-block-2">
        <div class=" px-0">
            <div class="additional-contact-information-section-1">
                <div class="col-12 d-flex justify-content-between align-items-center pe-0 ps-0">
                  <p class="title-contact mb-0">{{company.contactinfo}}</p>
                </div>
              </div>
          <div class="row additional-contact-information-section-2 mx-0 ">
            <div class="col-lg-4 col-sm-12 px-0 pt-4"  *ngFor="let info of companyData.companyContactInfos">
              <strong class="content-bold pb-1">{{info.firstName}} {{info.lastName}}</strong><p class="content-bold mb-0 pb-2"> {{info.jobTitle}}</p>
              <p class="content-normal mb-0 pb-1">{{info.email}}</p><p class="content-normal mb-0">{{info.phone}}</p>
            </div>
          </div>
        </div> 
      </div>
      <!-- Company User Section -->

      <div class="row account-user-block align-items-center">
        <div class="account-user col-12 d-flex justify-content-between ps-0 pe-0 ">
          <h3 class="title-contact mb-0">{{company.companyuser}}  ({{companyData.userCount}})</h3>
          <div class="view-div d-flex align-items-center">
            <p class="text-link view-sub-text mb-0 me-3" (click)="redirectCompanyUser(companyData.companyId,companyData.companyName)">{{company.viewall}}</p>
            <button class="btn btn-dark custom-button" (click)="addUser()">+ {{company.adduser}}</button>
          </div>
        </div>
      </div>
      <div class="row account-project-block" >
        <div class="account-section  col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6" *ngIf="companyData.companyType.companyTypeId == 4">
          <h5 class="mb-0 title-contact">{{company.creditstatus}}</h5>
          <div class="view-div d-flex align-items-center" >
            <p class="credit-sub-text mb-0 me-3" *ngIf="companyData?.validUntil">
              {{ company.valid }} {{ formattedDate ? (formattedDate | date: 'MM/dd/yyyy') : '--' }}
             </p>
            <p class="mb-0 credit-status-tag"  [ngClass]="{'current': companyData?.companyCreditStatus.creditStatus === 'Current', 'needs-approval' : companyData?.companyCreditStatus.creditStatus === 'Needs Renewal' }" *ngIf="companyData.companyCreditStatus">{{companyData?.companyCreditStatus.creditStatus}}</p>
            <p class="credit-sub-text mb-0 me-3" *ngIf="!companyData?.companyCreditStatus || companyData?.companyCreditStatus.creditStatus === 'Not Available'">{{company.notavailable}}</p>
          </div>
        </div>
        <div class="company-type col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6">
            <div class="d-flex justify-content-between">
              <div>
                <p class="mb-0 additional-text">{{company.internaluse}}</p>
                <h5 class="mb-0 title-contact">{{company.companytype}}</h5>
              </div>
              <div class="align-self-end">
                <p class=" mb-0 additional-text-1">{{companyData.companyType.companyType}}</p>
              </div>
            </div>
        </div>
        </div>
        <!-- Company Link Section -->

        <div class="last-section">
            <div class="last-section-edit clickable">
              <img src="assets/images/edit.svg" alt="Edit Icon" class="icon">
              <p class="mb-0" (click)="editCompany()">{{company.editcompany}}</p>
            </div>
            <div class="last-section-disable">
              <img src="assets/images/disable.svg"  class="icon clickable" *ngIf="companyData?.statusActiveInd == 'Y' && permissionsData['disableCompany']"  alt="Disable Icon" >
              <p class="mb-0 clickable"  *ngIf="companyData?.statusActiveInd == 'Y' && permissionsData['disableCompany']" (click)="openConfirmModal(confirmdisable)"><span *ngIf="companyData.statusActiveInd == 'Y' && permissionsData['disableCompany']">{{company.disablecompany}}</span></p>
              <img src="assets/images/enable-blue.svg"  class="icon clickable" *ngIf="companyData?.statusActiveInd == 'N' && permissionsData['disableCompany']"  alt="Enable Icon">
              <p class="mb-0 clickable"   *ngIf="companyData?.statusActiveInd == 'N' && permissionsData['disableCompany']" (click)="openConfirmModal(confirmenable)">{{company.enablecompany}}</p>
            </div>
          </div>
      </div>
    </div>
    <!-- Company Enable Popup Section -->

    <ng-template #confirmdisable let-modal>
      <div class="modal-body">
        <h4 class="section-main-header">{{title}}</h4>
        <div>
          <p>{{confirm.disablecompany}}</p>
        </div>
        <div class="d-flex justify-content-end gap-2">
          <button type="button" class="common-btn-secondary btn" (click)="confirmDisable()">{{confirm.yes}}</button>
          <button type="button" class="common-btn-light btn" (click)="modal.close()">{{confirm.no}}</button>
        </div>
      </div>
    </ng-template>
    <ng-template #confirmenable let-modal>
      <div class="modal-body">
        <h4 class="section-main-header">{{title}}</h4>
        <div>
          <p >{{confirm.enablecompany}}</p>
        </div>
        <div class="d-flex justify-content-end gap-2">
          <button type="button" class="common-btn-secondary btn" (click)="confirmEnable()">{{confirm.yes}}</button>
          <button type="button" class="common-btn-light btn" (click)="modal.close()">{{confirm.no}}</button>
        </div>
      </div>
    </ng-template>
    <ng-template #successTpl>
      <div class="success">
          <span *ngIf="addUserSuccess">{{addUserSuccess}} </span>
      </div>
    </ng-template>
    <ng-template #successEnableCompanyTpl>
      <div class="success">
          <span *ngIf="this.enableCompany">{{confirm.successEnable}} </span>
      </div>
    </ng-template>
    <ng-template #successDisableCompanyTpl>
      <div class="success">
        <span *ngIf="this.disableCompany">{{confirm.successDisable}} </span>
      </div>
    </ng-template>
    
    <ng-template #successTplCompany>
      <div class="success">
          <span *ngIf="addCompanySuccess">{{addCompanySuccess}} </span>
      </div>
    </ng-template>
    <ng-template #dangerActionTplCompany>
      <div class="success">
          <span *ngIf="addCompanyError">{{addCompanyError}} </span>
      </div>
    </ng-template>
    <ng-template #dangerActionTpl>
      <div class="total-action-content-danger d-flex flex-column align-items-start">
          <div class="inner-content-danger">
            <span *ngIf="addUserError">{{addUserError}} </span>
          </div>
      </div>
      </ng-template>
      <ng-template #dangerEnableCompanyTpl>
        <div class="total-action-content-danger d-flex flex-column align-items-start">
            <div class="inner-content-danger">
              <span>{{confirm.errorEnable}} </span>
            </div>
        </div>
        </ng-template>
        <ng-template #dangerDisableCompanyTpl>
          <div class="total-action-content-danger d-flex flex-column align-items-start">
              <div class="inner-content-danger">
                <span>{{confirm.errorDisable}} </span>
              </div>
          </div>
          </ng-template>
        <ng-template #dangerTpl>
          <div class="danger">
            <span *ngIf="addUserError">{{addUserError}}</span>
          </div>
        </ng-template>
    <app-toast aria-live="polite" aria-atomic="true"></app-toast>
    
    <app-toast aria-live="polite" aria-atomic="true"></app-toast>
