import { Component, inject, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-studio-add-new-project',
  templateUrl: './studio-add-new-project.component.html',
  styleUrls: ['./studio-add-new-project.component.scss']
})
export class StudioAddNewProjectComponent {
  private modalService = inject(NgbModal);
  /**
 * Added functions to open modal for select company using ng-bootstrap
 */
  openSelectCompany(company: TemplateRef<any>) {
    this.modalService.open(company, { windowClass: 'common-modal-center',centered: true });
  }
}
