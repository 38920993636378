export const devOktaConfig=
 {
    pkce: true,
    clientId: '0oa26avnmsikvoiQC0h8',
    issuer: 'https://wmbiz.oktapreview.com',
    postLogoutRedirectUri: 'https://greenlight-dev.wbd.com',
    authorizeUrl: 'https://wmbiz.oktapreview.com/oauth2/v1/authorize',
    userinfoUrl: 'https://wmbiz.oktapreview.com/oauth2/v1/userinfo',
    redirectUri: 'https://greenlight-dev.wbd.com/implicit/callback',
    scopes: ['openid', 'profile', 'email']
  }