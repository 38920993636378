<div>
  <span>
    <img
      src="assets/images/three-dots.svg"
      class="eye"
      alt="..."
      #trigger
      (click)="togglePopup()"
      data-action="toggle"
    />
  </span>
  <div #content *ngIf="isOpen" class="menu-container">
    <span class="more-action">{{popUpDropdown.moreActions}}</span>
    <div class="menu-item">
      <span
        class="icon-hover"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Deactivate User"
      >
        <img
          src="assets/images/person-add.svg"
          alt="Deactivate User"
        /><span class="img-content">Add a User</span>
      </span>
    </div>
    <div class="menu-item">
      <span class="icon-hover">
        <img
          src="assets/images/house-icon.svg"
          alt="Freeze Account"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Freeze Account"
        /> <span class="img-content">Send Credit Application</span>
      </span>
    </div>
    <div class="menu-item">
      <span
        class="icon-hover"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Remove User"
      >
        <img src="assets/images/bookmark.svg" alt="Remove User" />
        <span class="img-content">Remove Bookmark</span>
      </span>
    </div>
  </div>
</div>
