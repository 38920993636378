<div class="container-fluid page-wrapper">
	<div class="container">
		<div class="row">
			<div class="col-12">
                <h1 class="page-title px-0 mb-0">Directory</h1>
                <div class="d-flex align-items-center view-text ">
                    <div>
                        <p class="me-3 mb-0">You are viewing the Directory for</p>
                    </div>
                    <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="assets/images/location-icon.svg" alt="Icon" class="dropdown-icon">Burbank
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li><a class="dropdown-item" href="#">Action 1</a></li>
                            <li><a class="dropdown-item" href="#">Action 2</a></li>
                            <li><a class="dropdown-item" href="#">Action 3</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 d-none d-md-block">
                <ol class="handbook-list" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <li
                    class="nav-link"
                    id="office-production-services-tab"
                    role="tab"
                    aria-controls="office-production-services-content"
                    aria-selected="true"
                    (click)="selectTab('office-production-services-tab')"
                  >
                    <span class="list-number">1.</span> OFFICE & PRODUCTION SERVICES (NEW)
                  </li>
                  <li
                    class="nav-link"
                    id="safety-environmental-affairs-tab"
                    role="tab"
                    aria-controls="safety-environmental-affairs-content"
                    aria-selected="false"
                    (click)="selectTab('safety-environmental-affairs-tab')"
                  >
                    <span class="list-number">2.</span> SAFETY & ENVIRONMENTAL AFFAIRS
                  </li>
                  <li
                    class="nav-link"
                    id="studio-protection-group-tab"
                    role="tab"
                    aria-controls="studio-protection-group-content"
                    aria-selected="false"
                    (click)="selectTab('studio-protection-group-tab')"
                  >
                    <span class="list-number">3.</span> STUDIO PROTECTION GROUP
                  </li>
                  <li
                    class="nav-link"
                    id="construction-services-tab"
                    role="tab"
                    aria-controls="construction-services-content"
                    aria-selected="false"
                    (click)="selectTab('construction-services-tab')"
                  >
                    <span class="list-number">4.</span> CONSTRUCTION SERVICES
                  </li>
                  <li
                    class="nav-link"
                    id="costume-tab"
                    role="tab"
                    aria-controls="costume-content"
                    aria-selected="false"
                    (click)="selectTab('costume-tab')"
                  >
                    <span class="list-number">5.</span> COSTUME
                  </li>
                  <li
                    class="nav-link"
                    id="design-studio-tab"
                    role="tab"
                    aria-controls="design-studio-content"
                    aria-selected="false"
                    (click)="selectTab('design-studio-tab')"
                  >
                    <span class="list-number">6.</span> DESIGN STUDIO
                  </li>
                  <li
                    class="nav-link"
                    id="post-production-creative-services-tab"
                    role="tab"
                    aria-controls="post-production-creative-services-content"
                    aria-selected="false"
                    (click)="selectTab('post-production-creative-services-tab')"
                  >
                    <span class="list-number">7.</span> POST PRODUCTION CREATIVE SERVICES
                  </li>
                  <li
                    class="nav-link"
                    id="production-sound-video-tab"
                    role="tab"
                    aria-controls="production-sound-video-content"
                    aria-selected="false"
                    (click)="selectTab('production-sound-video-tab')"
                  >
                    <span class="list-number">8.</span> PRODUCTION SOUND & VIDEO
                  </li>
                  <li
                    class="nav-link"
                    id="property-drapery-floor-covering-tab"
                    role="tab"
                    aria-controls="property-drapery-floor-covering-content"
                    aria-selected="false"
                    (click)="selectTab('property-drapery-floor-covering-tab')"
                  >
                    <span class="list-number">9.</span> PROPERTY | DRAPERY | FLOOR COVERING
                  </li>
                  <li
                    class="nav-link"
                    id="food-services-special-events-tab"
                    role="tab"
                    aria-controls="food-services-special-events-content"
                    aria-selected="false"
                    (click)="selectTab('food-services-special-events-tab')"
                  >
                    <span class="list-number">10.</span> FOOD SERVICES | SPECIAL EVENTS
                  </li>
                  <li
                    class="nav-link"
                    id="grip-tab"
                    role="tab"
                    aria-controls="grip-content"
                    aria-selected="false"
                    (click)="selectTab('grip-tab')"
                  >
                    <span class="list-number">11.</span> GRIP
                  </li>
                  <li
                    class="nav-link"
                    id="set-lighting-tab"
                    role="tab"
                    aria-controls="set-lighting-content"
                    aria-selected="false"
                    (click)="selectTab('set-lighting-tab')"
                  >
                    <span class="list-number">12.</span> SET LIGHTING
                  </li>
                  <li
                    class="nav-link"
                    id="special-effects-tab"
                    role="tab"
                    aria-controls="special-effects-content"
                    aria-selected="false"
                    (click)="selectTab('special-effects-tab')"
                  >
                    <span class="list-number">13.</span> SPECIAL EFFECTS
                  </li>
                  <li
                    class="nav-link"
                    id="transportation-tab"
                    role="tab"
                    aria-controls="transportation-content"
                    aria-selected="false"
                    (click)="selectTab('transportation-tab')"
                  >
                    <span class="list-number">14.</span> TRANSPORTATION
                  </li>
                  <li
                    class="nav-link"
                    id="labor-tab"
                    role="tab"
                    aria-controls="labor-content"
                    aria-selected="false"
                    (click)="selectTab('labor-tab')"
                  >
                    <span class="list-number">15.</span> LABOR
                  </li>
                  <li
                    class="nav-link"
                    id="utilities-tab"
                    role="tab"
                    aria-controls="utilities-content"
                    aria-selected="false"
                    (click)="selectTab('utilities-tab')"
                  >
                    <span class="list-number">16.</span> UTILITIES
                  </li>
                  <li
                    class="nav-link"
                    id="studio-tours-tab"
                    role="tab"
                    aria-controls="studio-tours-content"
                    aria-selected="false"
                    (click)="selectTab('studio-tours-tab')"
                  >
                    <span class="list-number">17.</span> STUDIO TOURS
                  </li>
                  <li
                    class="nav-link"
                    id="additional-services-tab"
                    role="tab"
                    aria-controls="additional-services-content"
                    aria-selected="false"
                    (click)="selectTab('additional-services-tab')"
                  >
                    <span class="list-number">18.</span> ADDITIONAL ON-LOT CLIENT & EMPLOYEE SERVICES
                  </li>
                </ol>
              </div>
              
              <div class="col-md-4 col-lg-4 col-sm-12 d-block d-md-none mobile-select-block">
                <select class="form-select" id="mobileSelect" [(ngModel)]="selectedTab" (change)="onSelectChange($event)">
                  <option value="office-production-services-tab">
                    <span class="list-number">1.</span> OFFICE & PRODUCTION SERVICES (NEW)
                  </option>
                  <option value="safety-environmental-affairs-tab">
                    <span class="list-number">2.</span> SAFETY & ENVIRONMENTAL AFFAIRS
                  </option>
                  <option value="studio-protection-group-tab">
                    <span class="list-number">3.</span> STUDIO PROTECTION GROUP
                  </option>
                  <option value="construction-services-tab">
                    <span class="list-number">4.</span> CONSTRUCTION SERVICES
                  </option>
                  <option value="costume-tab">
                    <span class="list-number">5.</span> COSTUME
                  </option>
                  <option value="design-studio-tab">
                    <span class="list-number">6.</span> DESIGN STUDIO
                  </option>
                  <option value="post-production-creative-services-tab">
                    <span class="list-number">7.</span> POST PRODUCTION CREATIVE SERVICES
                  </option>
                  <option value="production-sound-video-tab">
                    <span class="list-number">8.</span> PRODUCTION SOUND & VIDEO
                  </option>
                  <option value="property-drapery-floor-covering-tab">
                    <span class="list-number">9.</span> PROPERTY | DRAPERY | FLOOR COVERING
                  </option>
                  <option value="food-services-special-events-tab">
                    <span class="list-number">10.</span> FOOD SERVICES | SPECIAL EVENTS
                  </option>
                  <option value="grip-tab">
                    <span class="list-number">11.</span> GRIP
                  </option>
                  <option value="set-lighting-tab">
                    <span class="list-number">12.</span> SET LIGHTING
                  </option>
                  <option value="special-effects-tab">
                    <span class="list-number">13.</span> SPECIAL EFFECTS
                  </option>
                  <option value="transportation-tab">
                    <span class="list-number">14.</span> TRANSPORTATION
                  </option>
                  <option value="labor-tab">
                    <span class="list-number">15.</span> LABOR
                  </option>
                  <option value="utilities-tab">
                    <span class="list-number">16.</span> UTILITIES
                  </option>
                  <option value="studio-tours-tab">
                    <span class="list-number">17.</span> STUDIO TOURS
                  </option>
                  <option value="additional-services-tab">
                    <span class="list-number">18.</span> ADDITIONAL ON-LOT CLIENT & EMPLOYEE SERVICES
                  </option>
                </select>
              </div>
              
              <div class="col-md-8 col-lg-8 col-sm-12">
                <!-- Tab Content -->
                <div class="tab-content" id="v-pills-tabContent">
                  <!-- OFFICE & PRODUCTION SERVICES (NEW) -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-services-tab'}"
                    id="office-production-services-tab" role="tabpanel" aria-labelledby="office-production-services-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">OFFICE & PRODUCTION SERVICES (NEW)</h2>
                        <div class="dispatch-list-section">
                            <h3 class="list-title">General Office:</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>OPS Call Center and Copier Services/Canon Help Desk</li>
                                <li>Phone: <span class="contact-details">818-954-6777</span></li>
                                <li>Email: <span class="contact-details">wbsocallcenter@warnerbros.com</span></li>
                            </ul>
                        </div>
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Sound Stage & Exterior Set Inquiries (Main Lot & Ranch) – New Productions Only</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2577</span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbstagesandsets.com" target="_blank">www.wbstagesandsets.com</a></span></li>
                            </ul>
                        </div>
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Parking Services</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-7275 (4-PARK)</span></li>
                                <li>Email: <span class="contact-details">chris.owens@warnerbros.com</span> and <span class="contact-details">gregg.pawlik@warnerbros.com</span></li>
                            </ul>
                        </div>
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Publicity Clearances (Justin Delaney)</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-6337</span></li>
                                <li>Email: <span class="contact-details">justin.delaney@warnerbros.com</span></li>
                            </ul>
                        </div>
                    </div>  
                  </div>
              
                  <!-- SAFETY & ENVIRONMENTAL AFFAIRS -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environmental-affairs-tab'}"
                    id="safety-environmental-affairs-tab" role="tabpanel" aria-labelledby="safety-environmental-affairs-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">SAFETY & ENVIRONMENTAL AFFAIRS</h2>
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Anonymous Safety Hotline</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2800</span></li>
                            </ul>
                        </div>
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Toll-Free Hotline</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">877-566-8001</span></li>
                            </ul>
                        </div>
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Food Safety Hotline</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2350</span></li>
                            </ul>
                        </div>
                    </div>
                    
                  </div>
              
                  <!-- STUDIO PROTECTION GROUP -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-group-tab'}"
                    id="studio-protection-group-tab" role="tabpanel" aria-labelledby="studio-protection-group-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">STUDIO PROTECTION GROUP</h2>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Dispatch</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1248</span></li>
                                <li>Email: <span class="contact-details">WBSFDispatch@warnerbros.com</span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Watch Commander – Security</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-4838</span></li>
                                <li>Email: <span class="contact-details">WBSFWatchCommander@warnerbros.com</span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Card Access</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-5222</span></li>
                                <li>Email: <span class="contact-details">WBSFCardAccess@warnerbros.com</span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Scheduling</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1294</span></li>
                                <li>Email: <span class="contact-details">WBSFSecurityScheduling@warnerbros.com</span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Burbank Fire Department – On-Site Captain – Ryan Weber</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Mobile: <span class="contact-details">818-531-5509</span></li>
                                <li>Email: <span class="contact-details">rweber@burbankca.gov</span></li>
                                <li>On-Site: Tuesday-Friday weekly, aside from holidays; available otherwise by mobile or email.</li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Warner Bros. Fire Station 18</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1261</span></li>
                                <li>Email: <span class="contact-details">WBFireStation18@warnerbros.com</span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">First Aid – Main Lot</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1201</span></li>
                                <li>Email: <span class="contact-details">MainLotFirstAid@warnerbros.com</span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">First Aid – Ranch</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-977-5251</span></li>
                                <li>Email: <span class="contact-details">RanchFirstAid@warnerbros.com</span></li>
                            </ul>
                        </div>
                    </div>
                    
                  </div>
              
                  <!-- CONSTRUCTION SERVICES -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'construction-services-tab'}"
                    id="construction-services-tab" role="tabpanel" aria-labelledby="construction-services-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">CONSTRUCTION SERVICES</h2>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Gene Owens & Bruce Jepsen</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1260</span></li>
                                <li>Email: <span class="contact-details">WBSOConstSvc@warnerbros.com</span></li>
                            </ul>
                        </div>
                    </div>
                    
                  </div>
              
                  <!-- COSTUME -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'costume-tab'}"
                    id="costume-tab" role="tabpanel" aria-labelledby="costume-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">COSTUME</h2>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Rentals</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1297</span></li>
                                <li>Email: <span class="contact-details">wbsfcostumedesk@warnerbros.com</span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbcostumedept.com" target="_blank">www.wbcostumedept.com</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Administration</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-5693</span></li>
                                <li>Email: <span class="contact-details">wbcostumesnewaccounts@warnerbros.com</span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Alterations</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2017</span></li>
                            </ul>
                        </div>
                    </div>
                    
                  </div>
              
                  <!-- DESIGN STUDIO -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'design-studio-tab'}"
                    id="design-studio-tab" role="tabpanel" aria-labelledby="design-studio-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">DESIGN STUDIO</h2>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">New Projects (Pam Lord)</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-7118</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:pam.lord@warnerbros.com">pam.lord@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.warnerbrosdesignstudio.com" target="_blank">www.warnerbrosdesignstudio.com</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Metal Shop</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-7118</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:wbds@warnerbros.com">wbds@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="https://www.warnerbrosdesignstudio.com/shops/metal-shop/" target="_blank">https://www.warnerbrosdesignstudio.com/shops/metal-shop/</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Paint Shop</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1817</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:wbsfpaint@warnerbros.com">wbsfpaint@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="https://www.warnerbrosdesignstudio.com/shops/paint-shop/" target="_blank">https://www.warnerbrosdesignstudio.com/shops/paint-shop/</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Photo Lab</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-7118</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:photolab@warnerbros.com">photolab@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="https://www.warnerbrosdesignstudio.com/shops/photo-lab/" target="_blank">https://www.warnerbrosdesignstudio.com/shops/photo-lab/</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Sign & Scenic Art</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1815</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:wbsigns@warnerbros.com">wbsigns@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="https://www.warnerbrosdesignstudio.com/shops/sign-scenic-art/" target="_blank">https://www.warnerbrosdesignstudio.com/shops/sign-scenic-art/</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Staff Shop (Moldings)</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2269</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:wbds@warnerbros.com">wbds@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="https://www.warnerbrosdesignstudio.com/shops/staff-shop/" target="_blank">https://www.warnerbrosdesignstudio.com/shops/staff-shop/</a></span></li>
                            </ul>
                        </div>
                    </div>
                    

                    
                  </div>
              
                  <!-- POST PRODUCTION CREATIVE SERVICES -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'post-production-creative-services-tab'}"
                    id="post-production-creative-services-tab" role="tabpanel" aria-labelledby="post-production-creative-services-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">POST PRODUCTION CREATIVE SERVICES</h2>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">General Info</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2515</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:wbsfpostproduction@warnerbros.com">wbsfpostproduction@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbppcs.com" target="_blank">www.wbppcs.com</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Motion Picture Imaging</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2464</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:mpi-clientservicesreception@warnerbros.onmicrosoft.com">mpi-clientservicesreception@warnerbros.onmicrosoft.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbppcs.com/burbank-picture" target="_blank">www.wbppcs.com/burbank-picture</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Projection / Screening Rooms (Betty Soto)</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2144</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:betty.soto@warnerbros.com">betty.soto@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbppcs.com/burbank-screening-rooms" target="_blank">www.wbppcs.com/burbank-screening-rooms</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Scoring Stage (Jamie Olvera)</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-6800</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:jamie.olvera@warnerbros.com">jamie.olvera@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbppcs.com/facilities/scoring" target="_blank">www.wbppcs.com/facilities/scoring</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">WB Game Audio (Emma Emrich)</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2467</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:emma.emrich@warnerbros.com">emma.emrich@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbgameaudio.com" target="_blank">www.wbgameaudio.com</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">2 Pop Music Editorial (Irene Montero)</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2318</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:irene.montero@warnerbros.com">irene.montero@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.2popmusic.com" target="_blank">www.2popmusic.com</a></span></li>
                            </ul>
                        </div>
                    </div>
                  </div>
              
                  <!-- PRODUCTION SOUND & VIDEO -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'production-sound-video-tab'}"
                    id="production-sound-video-tab" role="tabpanel" aria-labelledby="production-sound-video-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">PRODUCTION SOUND & VIDEO</h2>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">General Info</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2511</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:wbsfproductionsound@warnerbros.com">wbsfproductionsound@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbsoundandvideo.com" target="_blank">www.wbsoundandvideo.com</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Rentals</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-4202</span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Wireless Frequency Review (Ara Mkhitaryan)</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2736</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:Ara.Mkhitaryan@warnerbros.com">Ara.Mkhitaryan@warnerbros.com</a></span></li>
                            </ul>
                        </div>
                    </div>
                  </div>
              
                  <!-- PROPERTY | DRAPERY | FLOOR COVERING -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'property-drapery-floor-covering-tab'}"
                    id="property-drapery-floor-covering-tab" role="tabpanel" aria-labelledby="property-drapery-floor-covering-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">PROPERTY | DRAPERY | FLOOR COVERING</h2>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">General Rentals</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2181</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:wbsfproperty@warnerbros.com">wbsfproperty@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbpropertydept.com" target="_blank">www.wbpropertydept.com</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">The Collection</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-7405</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:wbsfproperty@warnerbros.com">wbsfproperty@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbpropertydept.com" target="_blank">www.wbpropertydept.com</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Hand Props</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-3547</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:wbsfproperty@warnerbros.com">wbsfproperty@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbpropertydept.com" target="_blank">www.wbpropertydept.com</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Hardware</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1335</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:wbsfproperty@warnerbros.com">wbsfproperty@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbpropertydept.com" target="_blank">www.wbpropertydept.com</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Lighting Fixtures</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1888</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:wbsfproperty@warnerbros.com">wbsfproperty@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbpropertydept.com" target="_blank">www.wbpropertydept.com</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Drapery</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-4426</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:wbsfdrapery@warnerbros.com">wbsfdrapery@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbpropertydept.com" target="_blank">www.wbpropertydept.com</a></span></li>
                            </ul>
                        </div>
                    </div>
                    
                  </div>
              
                  <!-- FOOD SERVICES | SPECIAL EVENTS -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'food-services-special-events-tab'}"
                    id="food-services-special-events-tab" role="tabpanel" aria-labelledby="food-services-special-events-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">FOOD SERVICES | SPECIAL EVENTS</h2>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Food Services – General Info</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-4203</span></li>
                                <li>Website: <span class="contact-details"><a href="http://food.wb.com" target="_blank">food.wb.com</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Commissary Fine Dining Room – Reservations</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-4220</span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Master Chefs Production Catering</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1267</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:ProductionCatering@warnerbros.com">ProductionCatering@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://food.wb.com" target="_blank">food.wb.com</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Road Runner – Delivery Service for Craft Service & Meals</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-4202</span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Special Events</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2652</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:wbspecialevents@warnerbros.com">wbspecialevents@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbspecialevents.com" target="_blank">www.wbspecialevents.com</a></span></li>
                            </ul>
                        </div>
                    </div>
                    
                  </div>
              
                  <!-- GRIP -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'grip-tab'}"
                    id="grip-tab" role="tabpanel" aria-labelledby="grip-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">GRIP</h2>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">General Info</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1590</span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbgripdept.com" target="_blank">www.wbgripdept.com</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Equipment Rentals – Pacific Ave.</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1863</span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbgripdept.com" target="_blank">www.wbgripdept.com</a></span></li>
                            </ul>
                        </div>
                    
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Maintenance</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1929</span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbgripdept.com" target="_blank">www.wbgripdept.com</a></span></li>
                            </ul>
                        </div>
                    
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Scene Dock</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1387</span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbgripdept.com" target="_blank">www.wbgripdept.com</a></span></li>
                            </ul>
                        </div>
                    
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Canvas Room</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2671</span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbgripdept.com" target="_blank">www.wbgripdept.com</a></span></li>
                            </ul>
                        </div>
                    </div>
                  </div>
              
                  <!-- SET LIGHTING -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'set-lighting-tab'}"
                    id="set-lighting-tab" role="tabpanel" aria-labelledby="set-lighting-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">SET LIGHTING</h2>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">General Info</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2575</span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbsetlighting.com" target="_blank">www.wbsetlighting.com</a></span></li>
                            </ul>
                        </div>
                    
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Equipment Rentals – Pacific Ave.</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-4806</span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbsetlighting.com" target="_blank">www.wbsetlighting.com</a></span></li>
                            </ul>
                        </div>
                    
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Repair Shop – Pacific Ave.</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2322</span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbsetlighting.com" target="_blank">www.wbsetlighting.com</a></span></li>
                            </ul>
                        </div>
                    </div>
                  </div>
              
                  <!-- SPECIAL EFFECTS -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'special-effects-tab'}"
                    id="special-effects-tab" role="tabpanel" aria-labelledby="special-effects-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">SPECIAL EFFECTS</h2>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Services & Permit Requests (Tyler Matson)</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-1365</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:tyler.matson@warnerbros.com">tyler.matson@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbspecialeffects.com" target="_blank">www.wbspecialeffects.com</a></span></li>
                            </ul>
                        </div>
                    </div>
                  </div>
              
                  <!-- TRANSPORTATION -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'transportation-tab'}"
                    id="transportation-tab" role="tabpanel" aria-labelledby="transportation-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">TRANSPORTATION</h2>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Dispatch & Equipment Rentals</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-4106</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:wbsftransportation@warnerbros.com">wbsftransportation@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbsftransportation.com" target="_blank">www.wbsftransportation.com</a></span></li>
                            </ul>
                        </div>
                    </div>
                  </div>
              
                  <!-- LABOR -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'labor-tab'}"
                    id="labor-tab" role="tabpanel" aria-labelledby="labor-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">LABOR</h2>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Pam Marrow, Labor (Studio Utilities): Trash Bins, Non-Set Cleaning</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2009</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:pam.marrow@warnerbros.com">pam.marrow@warnerbros.com</a></span></li>
                            </ul>
                        </div>
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Daniel Fregoso, Labor (Craft Service): Set & Stage Cleaning, Supply Rentals</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2900</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:daniel.fregoso@warnerbros.com">daniel.fregoso@warnerbros.com</a></span></li>
                            </ul>
                        </div>
                    </div>
                  </div>
              
                  <!-- UTILITIES -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'utilities-tab'}"
                    id="utilities-tab" role="tabpanel" aria-labelledby="utilities-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">UTILITIES</h2>
                        
                        <div class="dispatch-list-section">
                            <ul class="dispatch-list bullet-list mt-4">
                                <li>Air Conditioning: <span class="contact-details">818-954-1700</span></li>
                                <li>Electrical: <span class="contact-details">818-954-1700</span></li>
                                <li>Plumbing: <span class="contact-details">818-954-1700</span></li>
                            </ul>
                        </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-tours-tab'}"
                    id="studio-tours-tab" role="tabpanel" aria-labelledby="studio-tours-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">STUDIO TOURS</h2>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">WB Studio Tour Hollywood</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-977-8687</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:studio.tour@warnerbros.com">studio.tour@warnerbros.com</a></span></li>
                                <li>Website: <span class="contact-details"><a href="http://www.wbstudiotour.com" target="_blank">www.wbstudiotour.com</a></span></li>
                            </ul>
                        </div>
                    </div>
                    
                  </div>
              
                  <!-- ADDITIONAL ON-LOT CLIENT & EMPLOYEE SERVICES -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'additional-services-tab'}"
                    id="additional-services-tab" role="tabpanel" aria-labelledby="additional-services-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">ADDITIONAL ON-LOT CLIENT & EMPLOYEE SERVICES</h2>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">DirecTV – Lindsey Richman</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">310-490-5068</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:lindsey@gettvdirect.com">lindsey@gettvdirect.com</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">E-Car – Electric Cart Vendor</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-764-4500</span></li>
                                <li>Email: <span class="contact-details"><a href="mailto:ecargolfcarts@gmail.com">ecargolfcarts@gmail.com</a></span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">First Entertainment Credit Union – Warner Bros. Branch</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">888-800-3328</span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Fitness Center</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-4242</span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Hair Salon</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2951</span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Mothers Rooms – Info</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-6777</span></li>
                            </ul>
                        </div>
                        
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Victor’s Shoe Shine & Repair</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-445-0881</span></li>
                            </ul>
                        </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              

            </div>
            </div>
</div>