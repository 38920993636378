// menu.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { STUDIO_MENU_LABELS, CUSTOMER_MENU_LABELS, AR_MENU_LABELS } from '../../constants/core.constant';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() sticky: boolean = false;
  @Input() portalType: any;
  userTypeId: number;
  permissionsData: any;
  studiolabels = STUDIO_MENU_LABELS;
  customerlabels = CUSTOMER_MENU_LABELS;
  arlabels = AR_MENU_LABELS;
  userRole: any;

  constructor() { }

  ngOnInit() {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.permissionsData = { ...userProfile.roleAssigned };
    this.userTypeId = userProfile?.userTypeId;
    this.userRole = userProfile?.roles;
    
    if (this.userTypeId === 1) {
      this.portalType = 'customer';
    } else if (this.userTypeId === 2) {
      if (Array.isArray(this.userRole) && this.userRole.includes('A/R')) {
        this.portalType = 'ar';
      } else {
        this.portalType = 'studio';
      }
    }
  }
}