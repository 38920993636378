import { Component } from '@angular/core';

@Component({
  selector: 'app-studio-add-company',
  templateUrl: './studio-add-company.component.html',
  styleUrls: ['./studio-add-company.component.scss']
})
export class StudioAddCompanyComponent {

}
