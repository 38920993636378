import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { NgMultiSelectDropDown } from 'src/app/shared/components/ng-multiselect-dropdown/ng-multiselect-dropdown.module';
import { StudioPortalRoutingModule } from './studio-portal-routing.module';
import { UsersComponent } from './components/users/users.component';
import { StudioPortalComponent } from './studio-portal.component';
// import { CommonAgGridComponent } from 'src/app/shared/components/common-ag-grid/common-ag-grid.component';
import { CompanyProjectComponent } from './components/company/company-project/company-project.component';
// import { FeatureModule } from '../feature.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ViewCompanyComponent } from './components/company/view-company/view-company.component';
// // import { AddNewProjectComponent } from './components/company/add-new-project/add-new-project.component';
import { CompaniesComponent } from './components/company/companies/companies.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ProjectsComponent } from './components/projects/projects.component';
import { ViewAllUsersComponent } from './components/projects/view-all-users/view-all-users.component';
import { CoreModule } from 'src/app/core/core.module';
import { ProjectDetailsComponent } from './components/projects/project-details/project-details.component';
import { NgbActiveModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';import { SearchCompanyComponent } from './components/company/search-company/search-company.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddProjectComponent } from './components/projects/add-project/add-project.component';


@NgModule({
    declarations: [
     UsersComponent,
      StudioPortalComponent,
      CompanyProjectComponent,
      ViewCompanyComponent,
      // AddNewProjectComponent,
      CompaniesComponent,
      ProjectsComponent,
      ViewAllUsersComponent,
      ProjectDetailsComponent,
      SearchCompanyComponent,
      AddProjectComponent
    ],
    imports: [
      CommonModule,
      AgGridModule,
      ReactiveFormsModule,
      FormsModule,
      StudioPortalRoutingModule,
      NgMultiSelectDropDown,
      SharedModule,
      NgMultiSelectDropDownModule,
      CoreModule,
      NgbTooltipModule,
      NgbModule,
    ],
    exports: [
      UsersComponent,
      StudioPortalComponent,
      CompaniesComponent

    ],
    providers: [
      NgbActiveModal,
    ]
  })
  export class StudioPortalModule { }
  
  
