export const userProfileList = 
{
  "email": "dineshwaran.sivakumar@wbdcontractor.com",
  "role": "customer",
  "first_name": "Dineshwaran",
  "last_name": "Sivakumar",
  "user_type": "DOM",
  "licenseeRole": null,
  "bofaAccountId": null,
  "buyingCircuits": [],
  "owningCircuits": [],
  "payingCircuits": [],
  "bookingType": "classics",
  "country": "",
  "avatarUrl": "https://theatrical-stage-assets.warnerbros.com/avatar/WBD_HorizontalLogo_OnLight_RGB.svg",
  "accessToken": "",
  "rightsCountry": null,
  "roleAssigned": {
    "faq": true,
    "faqEdit": false,
    "statements": false,
    "payment": false,
    "viewBookingRequest": false,
    "bookingsByWeek": false,
    "holdovers": false,
    "holdoverRequests": false,
    "theaters": false,
    "theaterRequests": false,
    "worldWideRights": true,
    "userAdministration": false,
    "userRequests": false,
    "bookingRequestMgmt": false,
    "masterData": false,
    "circuit": false,
    "release": false,
    "competitiveTitles": false,
    "formats": false,
    "home": false,
    "login": false,
    "search": false,
    "tax": false,
    "filmRentalTax": false,
    "exhibComment": false,
    "mediaTypeDimention": false,
    "aspectRatio": false,
    "resolution": false,
    "soundSystem": false,
    "seatTypes": false,
    "seats": false,
    "amenity": false,
    "projectionType": false,
    "comments": false,
    "submitBookingRequest": false,
    "holdoverDashboardCard": false,
    "bookingsDashboardCard": false,
    "statementsDashboardCard": false,
    "dashboardCard": false,
    "distribution-plan": false
  }
}

