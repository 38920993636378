import { Component } from '@angular/core';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {
  constructor(public toastService: ToastService) {}
  /**
   * showSuccess() - Method to display a success toast message
   * @param successTpl - The template reference for the success message to display
   * Options:
   *  - classname: Custom CSS class for styling the toast
   *  - delay: Time in milliseconds before the toast disappears automatically
   */
  showSuccess(successTpl: any) {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showSuccessAction() - Method to display a success toast with a longer delay
   * @param successActionTpl - The template reference for the success message with an action button
   * Options:
   *  - classname: Custom CSS class for styling the toast
   *  - delay: Long delay for action toast, set to 3,000,000 milliseconds (50 minutes)
   */
  showSuccessAction(successActionTpl: any) {
    this.toastService.show(successActionTpl, {
      classname: 'success-action',
      delay: 3000
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   * Options:
   *  - classname: Custom CSS class for danger styling (red background, light text)
   *  - delay: Time in milliseconds before the toast disappears automatically
   */
  showDanger(dangerTpl: any) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
   * showDangerAction() - Method to display a danger toast with an action and long delay
   * @param dangerActionTpl - The template reference for the danger message with an action button
   * Options:
   *  - classname: Custom CSS class for styling the danger action toast
   *  - delay: Long delay for action toast, set to 30,000 milliseconds (30 seconds)
   */
  showDangerAction(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger-action text-light',
      delay: 3000
    });
  }
}
