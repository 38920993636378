<button data-bs-toggle="modal" data-bs-target="#addUserCustPortal">
    Add A New Project
  </button>
  <!-- common modal section -->
  <div
    class="modal fade"
    id="addUserCustPortal"
    tabindex="-1"
    aria-labelledby="commonModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content common-modal-xl">
        <div class="modal-body justify-content-center align-items-center">
          <div class="cm-header-section">
            <h5 class="modal-title mb-3" id="commonModalLabel">WBD Pass</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span class="cm-close-text">Close</span>
            </button>
          </div>
  
          <div class="sub-heading-block">
            <span class="first-sub-heading">View or Create passes on</span>
            <span class="second-sub-heading"
              >pass.wbd.com<img src="assets/images/link-icon.svg" alt="link-icon"
            /></span>
          </div>
  
          <div
            class="project-resource-wrapper wrapper-right col-12 col-sm-12 col-md-12"
          >
            <div class="tabs-rhs">
              <div class="comman-tabs-block ml-1 d-none d-md-block">
                <ul class="nav" role="tablist">
                  <li class="nav-item">
                    <a
                      class="tab-font nav-link active"
                      data-bs-toggle="tab"
                      href="#today-tab"
                      role="tab"
                      aria-selected="true"
                    >
                      <span class="day">Today</span>
                      <span class="date">8/22</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#friday-tab"
                      role="tab"
                    >
                      <span class="day">Fri</span>
                      <span class="date">8/23</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#saturday-tab"
                      role="tab"
                    >
                      <span class="day">Sat</span>
                      <span class="date">8/24</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#sunday-tab"
                      role="tab"
                    >
                      <span class="day">Sun</span>
                      <span class="date">8/25</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#monday-tab"
                      role="tab"
                    >
                      <span class="day">Mon</span>
                      <span class="date">8/26</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-12 col-sm-8 d-md-none">
                <div class="custom-dropdown w-100">
                  <select
                    class="custom-select"
                    id="mobileSelect"
                    [(ngModel)]="selectedTab"
                    (change)="onSelectChange($event)"
                  >
                    <option value="today-tab">Today</option>
                    <option value="friday-tab">Fri</option>
                    <option value="saturday-tab">Sat</option>
                    <option value="sunday-tab">Sun</option>
                    <option value="monday-tab">Mon</option>
                  </select>
                </div>
              </div>
              <div class="export-refrest-rhs">
                <span class="export clickable"
                  >Export <img src="assets/images/export.svg" alt="upload-icon"
                /></span>
                <span class="refresh clickable"
                  >Refresh
                  <img src="assets/images/refresh.svg" alt="refresh-icon"
                /></span>
              </div>
            </div>
            <div class="check-in-block">
              <div class="passes-block">
                <div class="all-passes">
                  <span>All Passes</span>
                  <span class="checked-status">36</span>
                </div>
                <div class="mobile-walk-drive">
                  <div class="drive-on">
                    <img src="assets/images/car.svg" alt="drive-on" />
                    <span>15 Drive-On</span>
                  </div>
                  <div class="walk-on">
                    <img src="assets/images/man.svg" alt="walk-on" />
                    <span>21 Walk-On</span>
                  </div>
                </div>
              </div>
              <div class="checked-in-status-block">
                <span class="checked-status">Checked In only</span>
                <div class="form-check form-switch mb-0">
                  <input
                    class="form-check-input switch common-tog"
                    type="checkbox"
                    role="switch"
                    checked
                  />
                </div>
              </div>
            </div>
            <div class="tab-content" id="tab-content">
              <div
                class="tab-pane fade show active"
                id="today-tab"
                role="tabpanel"
              >
                <div class="common-grid-section">
                  <div class="common-grid-block">
                    <div class="mt-3">
                      <app-common-ag-grid
                        class="ag-theme-custom"
                        [colDefs]="colDefs"
                        [rowData]="rowData"
                        [colsForSmallScreen]="colsForSmallScreen"
                        [colsForMediumScreen]="colsForMediumScreen"
                        [colsForLargeScreen]="colsForLargeScreen"
                        [colsForExtraLargeScreen]="colsForExtraLargeScreen"
                        [editType]="'fullRow'"
                        [suppressClickEdit]="true"
                        [animateRows]="true"
                      >
                      </app-common-ag-grid>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="friday-tab" role="tabpanel">
                Content for Friday
              </div>
              <div class="tab-pane fade" id="saturday-tab" role="tabpanel">
                <h2>Content for Saturday</h2>
              </div>
              <div class="tab-pane fade" id="sunday-tab" role="tabpanel">
                <h1>Content for Sunday</h1>
              </div>
              <div class="tab-pane fade" id="monday-tab" role="tabpanel">
                <h1>Content for Monday</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  