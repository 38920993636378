<div class="container" style="margin-bottom:100px;margin-top:50px">
	<button data-bs-toggle="modal" data-bs-target="#commonCredModal" class="btn common-btn-secondary">Open Credit
		Application</button>
</div>
<div class="modal fade" id="commonCredModal" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-fullscreen common-modal-xl-container">
		<div class="modal-content common-modal-xl">
			<div class="modal-body justify-content-center align-items-center">
				<div class="cm-header-section">
					<h5 class="modal-title" id="commonModalLabel">Credit Application Form</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
							class="cm-close-text">Close</span></button>
					<p class="cm-subtitle mb-5">Edit or Add any missing information before sending your request to WB Studio Facilities.</p>
				</div>
				<div class="container cm-main-container-lg cm-main-container">
					<div class="row">

					
					<div class="col-3">
						<div class="">
							<h3 class="section-main-header">Credit applicaton</h3>
						</div>
					</div>
					<div class="col-9">
						<div class="common-modal-input-section">
							<h2 class="section-main-header">Section header</h2>
							<form>
								<h3 class="form-section-header">Group Name</h3>
								<div class="row">
									<div class="col-md-6 custom-floating-label">
										<label for="input1" class="form-label">label</label>
										<input type="text" class="form-control" placeholder="Placeholder" value="Amet Lorem" id="input1">
									</div>
									<div class="col-md-6 custom-floating-label">
										<label for="input2" class="form-label">label</label>
										<input type="text" class="form-control" placeholder="Placeholder" value="Amet Lorem" id="input2">
									</div>
								</div>
								<div class="row">
									<div class="col-md-6 custom-floating-label">
										<label for="input3" class="form-label">label</label>
										<input type="text" class="form-control" placeholder="Placeholder" value="Amet Lorem" id="input3">
									</div>
									<div class="col-md-3 custom-floating-label">
										<label for="input4" class="form-label">label</label>
										<input type="text" class="form-control" placeholder="Placeholder" value="Amet Lorem" id="input4">
									</div>
								</div>
								<div class="col-md-4">
									<label class="custom-checkbox">
										<input type="checkbox">
										<span class="checkmark"></span>
										Option
									</label>
								</div>
							</form>
						</div>
						<div class="common-modal-input-section">
							<h2 class="section-main-header">Section header</h2>
							<form>
								<h3 class="form-section-header">Group Name</h3>
								<div class="row">
									<div class="col-md-6 custom-floating-label">
										<label for="input1" class="form-label">label</label>
										<input type="text" class="form-control" placeholder="Placeholder" value="Amet Lorem" id="input1">
									</div>
									<div class="col-md-6 custom-floating-label">
										<label for="input2" class="form-label">label</label>
										<input type="text" class="form-control" placeholder="Placeholder" value="Amet Lorem" id="input2">
									</div>
								</div>
								<div class="row">
									<div class="col-md-6 custom-floating-label">
										<label for="input3" class="form-label">label</label>
										<input type="text" class="form-control" placeholder="Placeholder" value="Amet Lorem" id="input3">
									</div>
									<div class="col-md-3 custom-floating-label">
										<label for="input4" class="form-label">label</label>
										<input type="text" class="form-control" placeholder="Placeholder" value="Amet Lorem" id="input4">
									</div>
								</div>
								<div class="col-md-4">
									<label class="custom-checkbox">
										<input type="checkbox">
										<span class="checkmark"></span>
										Option
									</label>
								</div>
							</form>
						</div>
					
						
						<div class="d-flex justify-content-between">
							<button type="button" class="btn common-btn-light" data-bs-dismiss="modal">Cancel</button>
							<button type="button" class="btn common-btn-secondary">Submit Revisions</button>
					</div>
					</div>
					
				</div>
			</div>
		</div>
		</div>
	</div>
</div>