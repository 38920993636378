import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-handbook-page',
  templateUrl: './handbook-page.component.html',
  styleUrls: ['./handbook-page.component.scss']
})
export class HandbookPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  selectedTab: string = 'studio-protection-0-tab';

  selectTab(tab: string) {
    this.selectedTab = tab;
  }

  onSelectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedTab = selectElement.value;
  }
}
