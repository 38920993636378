<div class="overview-wrapper">
  <div class="container overview-user-header-wrapper">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 ">
        <div class="user-greet-action-block">
          <div class="user-greeting-block">
            <h4 class="greeting-text">Hi, <span class="greet-user-name">{{first_name}}</span></h4>
          </div>
          <div class="review-test">Here’s the projects activity your need to review:</div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 ">
      </div>
    </div>
  </div>
  <div class="container overview-user-requests-wrapper">
    <div class="row">
      <!-- Left section with 3 TABLES i.e.,REQUEST, CREDIT-APPLICATION  -->
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 order-2 order-lg-1 order-md-2 order-sm-2 mt-0S">
        <app-studio-dashboard-credit-application></app-studio-dashboard-credit-application>
        <app-studio-dashboard-requests></app-studio-dashboard-requests>
      </div>
      <!-- Widget Bookmarked projects -->
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-0 order-1 order-lg-2 order-md-1 order-sm-1S">
        <app-studio-dashboard-bookmarked-projects></app-studio-dashboard-bookmarked-projects>
      </div>
    </div>