import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FaqComponent } from './components/header/faqs/faqs.component';
import { WbsoHandbookPageComponent } from './components/header/wbso-handbook/wbso-handbook-page/wbso-handbook-page.component';
import { WbsoHandbookComponent } from './components/header/wbso-handbook/wbso-handbook.component';
import { CoreRoutingModule } from './core-routing-module';
import { MenuComponent } from './components/menu/menu.component';
import { LoginComponent } from './components/login/login.component';
import { EditFaqComponent } from './components/header/faqs/edit-faq/edit-faq.component';
import { CoreDirectoryComponent } from './components/header/core-directory/core-directory.component';
import { MapsComponent } from './components/header/maps/maps.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    NavbarComponent,
    FaqComponent,
    MenuComponent,
    WbsoHandbookComponent,
    WbsoHandbookPageComponent,
    EditFaqComponent,
    CoreDirectoryComponent,
    LoginComponent,
    MapsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CoreRoutingModule,
    SharedModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    NavbarComponent,
    MenuComponent
  ]
})
export class CoreModule { }
