import { Component } from '@angular/core';

@Component({
  selector: 'app-add-user-cust-portal',
  templateUrl: './add-user-cust-portal.component.html',
  styleUrls: ['./add-user-cust-portal.component.scss']
})
export class AddUserCustPortalComponent {

}
