import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/shared/services/base-api/base-api.service';
import { GLOBAL_API_PATHS } from 'src/assets/constants/apiPaths';

@Injectable({
  providedIn: 'root'
})
export class FaqServiceService {

  constructor(private baseApiService: BaseApiService) { }

  getFaqdata() {
    let endpoint = GLOBAL_API_PATHS.faq_Data;
    return this.baseApiService.get(endpoint);
  }

  addFaqData(data) {
    let endpoint = GLOBAL_API_PATHS.faq_Post;
    return this.baseApiService.post(endpoint, data);
  }

  updateFaqData(data) {
    const endpoint = `${GLOBAL_API_PATHS.faq_edit}`;
    return this.baseApiService.put(endpoint, data);
  }

  deleteFaqData(data) {
    const endpoint = `${GLOBAL_API_PATHS.faq_delete}?faqId=${data}`;
    return this.baseApiService.delete(endpoint);
  }

}
