<div class="request-wrapper">
  <div class="container">
    <div class="row">
      <div class="comman-title-block">
        <div class="comman-title-section">
          <h1>Requests</h1>
          <!-- Button to open the modal -->
          <button class="common-btn-primary btn" (click)="openRequestModal()">+Make a Request</button>
        </div>
      </div>
      <!-- Other content -->
    </div>
  </div>

  <!-- Modal Template -->
  <ng-template #requestModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add a Request</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="requestType" id="hotLockStage" (change)="onRadioChange($event)">
          <label class="form-check-label" for="hotLockStage">
            Hot Lock Stage Request
          </label>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="!isNextEnabled" (click)="navigateToSalesforce()">Next</button>
    </div>
  </ng-template>
</div>
