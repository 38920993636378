export const statusData = [
    { id: 1, text: 'ACTIVE' },
    { id: 3, text: 'DEACTIVATED' }
];

export const userTypeData = [
    { id: 1, text: 'All Customers' },
    { id: 2, text: 'Company admin' },
    { id: 3, text: 'account payable' }
];

