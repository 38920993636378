import { Component } from '@angular/core';
import { ERROR } from 'src/assets/constants/globalContants';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  errorHeader=ERROR.error_header;
  errorMessage=ERROR.error_message;
  errorBtnTitle=ERROR.error_btn_title;
  portalType: string;

  ngOnInit(): void {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    if (userProfile.portalType.includes("customer")) {
      this.portalType = "customer";
    }
    else if (userProfile.portalType.includes("studio")) {
      this.portalType =  "studio";
    }
  }

}
