<div *ngIf = "permissionsData['weatherWidget']" class="common-forecast-widget">
    <div class="card">
      <div class="card-body">
        <div class="row list-inline common-forecast-widget-list">
          <div class="col-6 col-sm-6 col-md-2 col-lg-6 px-0 cb-width-col-3">
            <div class="weather-info">
              <p class="weather-city">{{ isBurbank ? 'Burbank' : 'Leavesden' }}</p>
              <p class="weather-type">{{ weatherData.weatherCondition }}</p>
              <p class="weather-type">{{ weatherConstants.rain }} {{ weatherData.pop }} %</p>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-1 col-lg-6 px-0 cb-width-col-2">
            <div class="weather-temp">
              {{ weatherData.currentTemp }}°
              <p class="weather-sub-temp">{{ weatherData.maxTemp }}°/{{ weatherData.minTemp }}°</p>
            </div>
          </div>
          <div class="weather-forecast-block col-12 col-sm-12 col-md-4 col-lg-12 cb-width-col-2">
            <p class="weather-predication" (click)="toggleCity()">
              <span class="ml-2">{{ weatherConstants.change }} </span><i class="bi bi-geo-alt-fill"></i>
            </p>
            <p class="weather-forecast" (click)="naviagteToWeatherpage()">{{ weatherConstants.forecast }}
              <span><img src="../../assets/images/link-icon.svg" alt="Arrow Icon" class="arrow-icon"></span>
            </p>
          </div>
          <div class="col-12 col-sm-12 col-md-5 col-lg-12 px-0 cb-width-col-5">
            <img class="img-fluid weather-icon" [src]="weatherData.image" alt="weather-icon" onerror="this.src='assets/images/project-image1.png'">
          </div>
        </div>
      </div>
    </div>
  </div>
  