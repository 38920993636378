<button data-bs-toggle="modal" data-bs-target="#addUserCustPortal">Add A New Project</button>
<!-- common modal section-->
<div class="modal fade" id="addUserCustPortal" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
          <div class="cm-header-title text-center mb-5">
            <h5 class="modal-title mb-2" id="commonModalLabel">Credit Application Form</h5>
            <p class="modal-title-desc">Please ensure the credit application in its entirety.</p>
          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
              class="cm-close-text">Close</span></button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
              <div class="modal-left-section me-4">
                <h4 class="mt-4">Credit Application</h4>
                <div class="company-meta-data">
                  <p class="title"><strong><span class="label-title">PROJECT</span></strong>: The new show <span></span></p>
                  <p class="title mb-0"><strong><span class="label-title">Departments:</span></strong></p>
                  <p class="pb-0 pt-0 mb-0">Studio Operations; Transportation; Design Studio; Post Production; Creative Services; Property; Production Catering</p>
                  <div class="d-flex flex-column mt-2">
                    <span class=" mb-1"><a class="text-link">Edit departments <img src="../assets/images/edit-icon.svg" alt="Edit Department"></a></span>
                  </div>
                </div>             
                <div class="mb-4 mt-3">
                  <div class="list-group list-group-flush mb-2">
                    <ul class="step-list">
                      <li>
                        <span class="step-icon step-check-grey"></span>
                        <span class="step-text current-step"> Application Form</span>
                      </li>
                      <li>
                        <span class="step-icon"></span>
                        <span class="step-text"> Certification of Insurance</span>
                      </li>
                      <li>
                        <span class="step-icon"></span>
                        <span class="step-text">Terms & Conditions</span>
                      </li>
                      <li>
                        <span class="step-icon"></span>
                        <span class="step-text">Review Application</span>
                      </li>
                    </ul>
                  </div>
                  <p class="required-text">(<span class="star-red">*</span>)Required</p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="alert revision-alert" role="alert">
                <div class="container">
                  <div class="row">
                    <div class="wbd-alert-content">
                      Revisions needed:
                  </div>
                  </div>
                  <div class="row">
                    <div class="wbd-alert-content-list">
                      <ul>
                        <li><span>Banking References</span>&nbsp;
                          <span (click)=" scroll(bankingReference)" class="div-anch"><a class="wbd-action text-link">Scroll to section</a></span>                   
                        </li>
                        <li> <span>Trading References</span>&nbsp;<span (click)="scroll(tradeReference)" class="div-anch"><a
                              class="wbd-action text-link">Scroll to section</a></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container cm-main-container px-0 mx-0">
                <div class="common-modal-input-section grey-bg">
                  <h2 class="section-main-header">Line of Credit<sup class="text-danger">*</sup></h2>
                  <div class="mt-5">
                    <form>
                      <div class="row">
                        <div class="col-md-4 custom-floating-label">
                          <label for="lnCreditReq" class="form-label">Line of credit requested</label>
                          <input type="text" class="form-control" placeholder="Line of credit requested" id="lnCreditReq">
                        </div>
                        <div class="col-md-4 custom-floating-label">
                          <label for="AntiJobTotal" class="form-label">Anticipated Job Total</label>
                          <input type="text" class="form-control" placeholder="Anticipated Job Total" id="AntiJobTotal">
                        </div>
                        <div class="col-md-4 custom-floating-label">
                          <label for="totalOfMonth" class="form-label">Total # of months</label>
                          <input type="text" class="form-control" placeholder="Total # of months" id="totalOfMonth">
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="common-modal-input-section">
                  <h2 class="section-main-header custom-cred-header">Letter of Credit / Guarantee</h2>
                  <form>
                    <div class="row">
                      <div class="col-md-12 custom-floating-label my-4">
                            <div class="file-drop-area mt-3" [ngClass]="{ 'file-over': isFileOver }"
                                (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
                                <img class="folder-icon" (click)="fileInput.click()"
                                    src="../../../../../assets/images/folder-icon.svg" alt="folder icon">
                                <h3 class="mb-2 drag-n-drop">Drag and Drop your file here, or <a class="browse-file-link"
                                        (click)="fileInput.click()">browse</a></h3>
                                <input type="file" multiple class="d-none" (change)="onFileSelect($event)" #fileInput>
                            </div>
                            <!-- Display list of selected files -->
                            <ul *ngIf="files.length > 0" class="list-group mt-3 uploaded-files-section">
                                <li *ngFor="let file of files; let i = index" class="list-group-item px-0">
                                    <div class="d-flex align-items-center">
                                        <div class="col-auto icon-col">
                                            <img src="../../../../../assets/images/pdf-icon.svg" alt="pdf icon"
                                                class="file-icon">
                                        </div>
                                        <div class="col ps-3 pe-2">
                                            <span [title]="file.name" class="file-name">{{ file.name }}</span>
                                           <!-- <ngb-progressbar class="file-progress-bar" type="success" textType="white"
                                                [value]="100" [showValue]="false"></ngb-progressbar>-->
                                        </div>
                                        <div class="col-auto close-icon-col px-0">
                                            <i class="bi bi-x remove-btn" (click)="removeFile(i)"></i>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="justify-content-end text-end px-0 my-4">
                                <div class="" *ngIf="files.length > 0">
                                    <div class="custom-floating-label px-0">
                                        <label for="details1" class="form-label">Add a Comment</label>
                                        <textarea formControlName="details" class="form-control common-text-area"
                                            placeholder="Waiting for a few more quotes from PPCS and Sound Stages"
                                            id="details1" rows="3"></textarea>
                                    </div>
                                </div>
                            </div>
                      </div>
                      <div class="col-md-12 custom-floating-label">
                        <label for="LtofCreditComment" class="form-label"> Comments</label>
                        <input type="text" class="form-control" placeholder="Comments" id="LtofCreditComment">
                      </div>
                    </div>
                  </form>
                </div>

                <div class="common-modal-input-section">
                  <h2 class="section-main-header custom-cred-header">Production Company<sup class="text-danger">*</sup></h2>
                  <form>
                    <div class="row">
                      <div class="col-md-12 mt-5">
                        <p class="form-section-header mt-0 mb-4">Name:</p>
                      </div>
                      <div class="col-md-12 custom-floating-label input-icon">
                        <label for="prodComName" class="form-label"> Names</label>
                        <input type="text" class="form-control" placeholder="What is the production legal name?" id="prodComName">
                        <span class="help-icon me-1" (click)="toggleSidebar()"></span>
                      </div>

                      <div class="col-md-12 mt-5">
                        <p class="form-section-header mt-0 mb-4">Address:</p>
                      </div>
                      <div class="col-md-12 custom-floating-label">
                        <label for="prodComStreet" class="form-label"> Street</label>
                        <input type="text" class="form-control" placeholder="Street" id="prodComStreet">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="prodComCity" class="form-label"> City</label>
                        <input type="text" class="form-control" placeholder="City" id="prodComCity">
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-5 custom-dropdown custom-floating-label">
                            <label for="prodComState" class="form-label"> State</label>
                            <select class="custom-select" id="prodComState">
                              <option class="dropdown-option" value="" disabled selected hidden>Select</option>
                              <option class="dropdown-option" value="1">Projects</option>
                              <option class="dropdown-option" value="2">Users</option>
                              <option class="dropdown-option" value="3">Spaces & Resources</option>
                              <option class="dropdown-option" value="3">Company</option>
                            </select>  
                          </div>
                          <div class="col-md-7 custom-floating-label">
                            <label for="prodComZipcode" class="form-label"> ZIP Code</label>
                            <input type="text" class="form-control" placeholder="ZIP Code" id="prodComZipcode">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="prodComPhone" class="form-label"> Phone</label>
                        <input type="text" class="form-control" placeholder="Phone" id="prodComPhone">
                      </div>
                      <div class="col-md-12">
                        <label class="custom-checkbox" for="prodComBillingAddress">
                          <input type="checkbox" id="prodComBillingAddress">
                          <span class="checkmark"></span> Use for billing address
                        </label>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="common-modal-input-section">
                  <h2 class="section-main-header">Federal Tax ID #<sup class="text-danger">*</sup></h2>
                  <form>
                    <div class="row mt-5">
                      <div class="col-md-6 custom-floating-label input-icon">
                        <label for="federalTaxTIN" class="form-label"> TIN</label>
                        <input type="text" class="form-control" placeholder="TIN" id="federalTaxTIN">
                        <span class="help-icon me-1"></span>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="common-modal-input-section">
                  <h2 class="section-main-header">Accounts Payable Contact<sup class="text-danger">*</sup></h2>
                  <form>
                    <div class="row mt-5">
                      <div class="col-md-6 custom-floating-label">
                        <label for="accPayContFName" class="form-label"> First Name</label>
                        <input type="text" class="form-control" placeholder="First Name" id="accPayContFName">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="accPayContLName" class="form-label"> Last Name</label>
                        <input type="text" class="form-control" placeholder="Last Name" id="accPayContLName">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="accPayContEmail" class="form-label"> Email</label>
                        <input type="text" class="form-control" placeholder="Email" id="accPayContEmail">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="accPayContPhone" class="form-label"> Phone</label>
                        <input type="text" class="form-control" placeholder="Phone" id="accPayContPhone">
                      </div>

                      <div class="col-md-12 custom-floating-label my-4">
                        <span class="fw-semibold text-link">+ Add a Contact</span>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="common-modal-input-section">
                  <h2 class="section-main-header">Company Profile</h2>
                  <form>
                    <div class="row">
                      <div class="col-md-12">
                        <p class="form-section-header mt-0 mb-4">Select profile:</p>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="feature"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="feature">
                            Corporation
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="tele"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="tele">
                            Proprietorship
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="tele"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="tele">
                            Partnership
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="feature"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="feature">
                            LLC
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="tele"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="tele">
                            Other
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="tele"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="tele">
                            Special Event
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="tele"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="tele">
                            Other
                          </label>
                        </div>
                      </div>
                      <div class="col-md-12 px-0 mt-3">
                        <p class="form-section-header mt-4 mb-4">Business info:</p>
                      </div>
                      <div class="col-md-6 custom-floating-label input-icon mt-4">
                        <label for="comProfileDateStarted" class="form-label">Date Started</label>
                        <input type="text" class="form-control" placeholder="Date Started" id="comProfileDateStarted">
                        <span class="calender-icon"></span>
                      </div>
                      <div class="col-md-6 custom-floating-label mt-4">
                        <label for="comProfileTypBusiness" class="form-label">Type of Business</label>
                        <input type="text" class="form-control" placeholder="Type of Business" id="comProfileTypBusiness">
                      </div>
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-9">
                            <div class="row">
                              <div class="col-md-6 custom-floating-label mt-4">
                                <label for="comProfiletotalAnnSales" class="form-label">Total Annual Sales</label>
                                <input type="text" class="form-control" placeholder="Total Annual Sales" id="comProfiletotalAnnSales">
                              </div>
                              <div class="col-md-6 custom-floating-label mt-4">
                                <label for="comProfileNetworth" class="form-label">Networth</label>
                                <input type="text" class="form-control" placeholder="Networth" id="comProfileNetworth">
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3 custom-floating-label mt-4">
                            <label for="comProfileNoofEmployees" class="form-label"># of Employees</label>
                            <input type="text" class="form-control" placeholder="# of Employees" id="comProfileNoofEmployees">
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="common-modal-input-section">
                  <h2 class="section-main-header">Principals/Officers or Partners</h2>
                  <form>
                    <div class="row">
                      <div class="col-md-6 custom-floating-label">
                        <label for="principlePartnerName" class="form-label">Name</label>
                        <input type="text" class="form-control" placeholder="Name" id="principlePartnerName">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="principleTitle" class="form-label"> Title</label>
                        <input type="text" class="form-control" placeholder="Title" id="principleTitle">
                      </div>

                      <div class="col-md-12 custom-floating-label my-4">
                        <span class="fw-semibold text-link">+  Add Principal</span>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="common-modal-input-section" #bankingReference>
                  <div class="alert revision-alert p-4" role="alert">
                    <div class="container">
                      <div class="row">
                        <div class="wbd-alert-desc">
                          “Could you please let us know the contact’s full name?”
                      </div>
                      </div>
                    
                    </div>
                  </div>
                  <h2 class="section-main-header">Banking Reference</h2>
                  <form>
                    <div class="row mt-5">
                      <div class="col-md-6 custom-floating-label">
                        <label for="bankingRefBnkName" class="form-label">Bank Name</label>
                        <input type="text" class="form-control" placeholder="Bank Name" id="bankingRefBnkName">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="bankingRefAccNum" class="form-label"> Account Number</label>
                        <input type="text" class="form-control" placeholder="Account Number" id="bankingRefAccNum">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="bankingRefContName" class="form-label"> Contact Name</label>
                        <input type="text" class="form-control" placeholder="Contact Name" id="bankingRefContName">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="bankingRefTitle" class="form-label"> Title</label>
                        <input type="text" class="form-control" placeholder="Title" id="bankingRefTitle">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="bankingRefEmail" class="form-label"> Email</label>
                        <input type="text" class="form-control" placeholder="Email" id="bankingRefEmail">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="bankingRefPhone" class="form-label"> Phone</label>
                        <input type="text" class="form-control" placeholder="Phone" id="bankingRefPhone">
                      </div>
                    </div>
                  </form>
                </div>

                <div class="common-modal-input-section" #tradeReference>
                  <h2 class="section-main-header">Trade References<sup class="text-danger">*</sup></h2>
                  <form>
                    <div class="row mt-5">
                      <div class="col-md-12">
                        <p class="form-section-header mt-0 mb-4">Trade reference 1:</p>
                      </div>
                    </div>
                    <div class="row justify-content-start">
                      <div class="col-md-6 me-auto custom-floating-label">
                        <label for="tradeRefCompName" class="form-label">Company Name</label>
                        <input type="text" class="form-control" placeholder="Company Name" id="tradeRefCompName">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 custom-floating-label">
                        <label for="tradeRefContFName" class="form-label">Contact First Name</label>
                        <input type="text" class="form-control" placeholder="Contact First Name" id="tradeRefContFName">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="tradeRefContLName" class="form-label"> Contact Last Name</label>
                        <input type="text" class="form-control" placeholder="Contact Last Name" id="tradeRefContLName">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="tradeRefEmail" class="form-label"> Email</label>
                        <input type="text" class="form-control" placeholder="Email" id="tradeRefEmail">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="tradeRefPhone" class="form-label"> Phone</label>
                        <input type="text" class="form-control" placeholder="Phone" id="tradeRefPhone">
                      </div>
                      <div class="col-md-12 custom-floating-label">
                        <label for="tradeRefStreet" class="form-label"> Street</label>
                        <input type="text" class="form-control" placeholder="Street" id="tradeRefStreet">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="prodComCity" class="form-label"> City</label>
                        <input type="text" placeholder="City" id="prodComCity" class="form-control">
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-5 custom-dropdown custom-floating-label">
                            <label for="prodComState" class="form-label"> State</label>
                            <select id="prodComState" class="custom-select">
                              <option value="" disabled="" selected="" hidden="" class="dropdown-option" ng-reflect-value="">Select</option>
                              <option value="1" class="dropdown-option" ng-reflect-value="1">Projects</option>
                              <option value="2" class="dropdown-option" ng-reflect-value="2">Users</option>
                              <option value="3" class="dropdown-option" ng-reflect-value="3">Spaces &amp; Resources</option>
                              <option value="3" class="dropdown-option" ng-reflect-value="3">Company</option>
                            </select>
                          </div>
                          <div class="col-md-7 custom-floating-label">
                            <label for="prodComZipcode" class="form-label"> ZIP Code</label>
                            <input type="text" placeholder="ZIP Code" id="prodComZipcode" class="form-control">
                          </div>
                          
                        </div>
                      </div>
                      <div class="col-md-12 custom-floating-label d-flex align-items-center my-4">
                        <span class="fw-semibold me-3">Or</span>
                        <label for="LtofCreditUploadDoc" class="d-inline-block">
                          <span class="fw-semibold text-link d-flex align-items-center">
                            <span class="upload-icon me-1"></span> Upload Document</span>
                          <input type="file" id="LtofCreditUploadDoc" hidden="" class="form-control">
                        </label>
                      </div>
                      <div class="col-md-12 py-3">
                        <hr class="border-dark-subtle border-bottom" />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <p class="form-section-header mt-0 mb-4">Trade reference 2:</p>
                      </div>
                    </div>
                    <div class="row justify-content-start">
                      <div class="col-md-6 me-auto custom-floating-label">
                        <label for="tradeRefCompName" class="form-label">Company Name</label>
                        <input type="text" class="form-control" placeholder="Company Name" id="tradeRefCompName">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 custom-floating-label">
                        <label for="tradeRefContFName" class="form-label">Contact First Name</label>
                        <input type="text" class="form-control" placeholder="Contact First Name" id="tradeRefContFName">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="tradeRefContLName" class="form-label"> Contact Last Name</label>
                        <input type="text" class="form-control" placeholder="Contact Last Name" id="tradeRefContLName">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="tradeRefEmail" class="form-label"> Email</label>
                        <input type="text" class="form-control" placeholder="Email" id="tradeRefEmail">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="tradeRefPhone" class="form-label"> Phone</label>
                        <input type="text" class="form-control" placeholder="Phone" id="tradeRefPhone">
                      </div>
                      <div class="col-md-12 custom-floating-label">
                        <label for="tradeRefStreet" class="form-label"> Street</label>
                        <input type="text" class="form-control" placeholder="Street" id="tradeRefStreet">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="prodComCity" class="form-label"> City</label>
                        <input type="text" placeholder="City" id="prodComCity" class="form-control">
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-5 custom-dropdown custom-floating-label">
                            <label for="prodComState" class="form-label"> State</label>
                            <select id="prodComState" class="custom-select">
                              <option value="" disabled="" selected="" hidden="" class="dropdown-option" ng-reflect-value="">Select</option>
                              <option value="1" class="dropdown-option" ng-reflect-value="1">Projects</option>
                              <option value="2" class="dropdown-option" ng-reflect-value="2">Users</option>
                              <option value="3" class="dropdown-option" ng-reflect-value="3">Spaces &amp; Resources</option>
                              <option value="3" class="dropdown-option" ng-reflect-value="3">Company</option>
                            </select>
                          </div>
                          <div class="col-md-7 custom-floating-label">
                            <label for="prodComZipcode" class="form-label"> ZIP Code</label>
                            <input type="text" placeholder="ZIP Code" id="prodComZipcode" class="form-control">
                          </div>
                          
                        </div>
                      </div>
                      <div class="col-md-12 custom-floating-label d-flex align-items-center my-4">
                        <span class="fw-semibold me-3">Or</span>
                        <label for="LtofCreditUploadDoc" class="d-inline-block">
                          <span class="fw-semibold text-link d-flex align-items-center">
                            <span class="upload-icon me-1"></span> Upload Document</span>
                          <input type="file" id="LtofCreditUploadDoc" hidden="" class="form-control">
                        </label>
                      </div>
                      <div class="col-md-12 py-3">
                        <hr class="border-dark-subtle border-bottom" />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <p class="form-section-header mt-0 mb-4">Trade reference 3:</p>
                      </div>
                    </div>
                    <div class="row justify-content-start">
                      <div class="col-md-6 me-auto custom-floating-label">
                        <label for="tradeRefCompName" class="form-label">Company Name</label>
                        <input type="text" class="form-control" placeholder="Company Name" id="tradeRefCompName">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 custom-floating-label">
                        <label for="tradeRefContFName" class="form-label">Contact First Name</label>
                        <input type="text" class="form-control" placeholder="Contact First Name" id="tradeRefContFName">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="tradeRefContLName" class="form-label"> Contact Last Name</label>
                        <input type="text" class="form-control" placeholder="Contact Last Name" id="tradeRefContLName">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="tradeRefEmail" class="form-label"> Email</label>
                        <input type="text" class="form-control" placeholder="Email" id="tradeRefEmail">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="tradeRefPhone" class="form-label"> Phone</label>
                        <input type="text" class="form-control" placeholder="Phone" id="tradeRefPhone">
                      </div>
                      <div class="col-md-12 custom-floating-label">
                        <label for="tradeRefStreet" class="form-label"> Street</label>
                        <input type="text" class="form-control" placeholder="Street" id="tradeRefStreet">
                      </div>
                      <div class="col-md-6 custom-floating-label">
                        <label for="prodComCity" class="form-label"> City</label>
                        <input type="text" placeholder="City" id="prodComCity" class="form-control">
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-5 custom-dropdown custom-floating-label">
                            <label for="prodComState" class="form-label"> State</label>
                            <select id="prodComState" class="custom-select">
                              <option value="" disabled="" selected="" hidden="" class="dropdown-option" ng-reflect-value="">Select</option>
                              <option value="1" class="dropdown-option" ng-reflect-value="1">Projects</option>
                              <option value="2" class="dropdown-option" ng-reflect-value="2">Users</option>
                              <option value="3" class="dropdown-option" ng-reflect-value="3">Spaces &amp; Resources</option>
                              <option value="3" class="dropdown-option" ng-reflect-value="3">Company</option>
                            </select>
                          </div>
                          <div class="col-md-7 custom-floating-label">
                            <label for="prodComZipcode" class="form-label"> ZIP Code</label>
                            <input type="text" placeholder="ZIP Code" id="prodComZipcode" class="form-control">
                          </div>
                          
                        </div>
                      </div>
                      <div class="col-md-12 custom-floating-label d-flex align-items-center my-4">
                        <span class="fw-semibold me-3">Or</span>
                        <label for="LtofCreditUploadDoc" class="d-inline-block">
                          <span class="fw-semibold text-link d-flex align-items-center">
                            <span class="upload-icon me-1"></span> Upload Document</span>
                          <input type="file" id="LtofCreditUploadDoc" hidden="" class="form-control">
                        </label>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="cm-btn-section">
                  <button type="button" class="btn common-btn-secondary mb-2" data-bs-dismiss="modal">Next: Certificate of Insurance</button>
                  <button type="button" class="btn common-btn-light ms-2 mb-2" data-bs-dismiss="modal">Save & Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div [ngClass]="{'show': helpSliderVisible}" [class.noPaddingTop]="noPaddingTop" class="sidebar helpsidebar bg-light">
    <div [ngClass]="{'show': helpSliderVisible}" class="help-sidebar-section">
      <div class="">
        <div class="d-flex justify-content-end mb-3"><span class="close-btn">close Help > </span></div>
      </div>
      <div class="helper-content-section">
        <h3>Title:</h3>
        <p>Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean lacinia bibendum nulla sed consectetur. Vestibulum id ligula porta felis euismod semper.</p>
      </div>
      <div class="helper-content-section">
        <h3>Title:</h3>
        <p>Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean lacinia bibendum nulla sed consectetur. Vestibulum id ligula porta felis euismod semper.</p>
      </div>
      <div class="helper-content-section">
        <h3>Need More Help?</h3>
        <p>Read our <span class="blue-text">FAQs </span></p>
      </div>
    </div>
  
    <!-- Overlay to detect clicks outside -->
    <div *ngIf="helpSliderVisible" class="overlay" (click)="closeHelpSlider()"></div>
  </div>
</div>


