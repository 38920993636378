import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent {
  @ViewChild('requestGrid') requestGrid!: AgGridAngular;
  @ViewChild('passesGrid') passesGrid!: AgGridAngular;
  @ViewChild('creditGrid') creditGrid!: AgGridAngular;
  gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      filter: true
    },
  };
  rowDataRequest = [{ date: "1/11/24", request: "Request Name", status: "NEW" },
  { date: "1/11/24 ", request: "Request Name", status: "IN PROGRESS" },
  { date: "1/11/24 ", request: "Request Name", status: "ASSIGNED" },
  { date: "1/11/24 ", request: "Request Name", status: "CLOSED" },
  { date: "1/11/24 ", request: "Request Name", status: "RESOLVED" },

  ];
  colDefsRequest: ColDef[] = [
    {
      headerName: '',
      field: 'date',
      wrapText: true,
      autoHeight: true,
      minWidth: 150,
      hide: true
    },
    {
      headerName: '',
      field: 'merged-field',
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const request = params.data.request;
        const date = params.data.date;
        return `<span class="common-split-cell-section">
                  <span class="common-split-cell-data-1">${request}</span>
                  <span class="common-split-cell-data-2">${date}</span>
                </span>`;
      },
      minWidth: 150, flex: 2
    },
    { headerName: '', field: "request", minWidth: 150, flex: 2, cellClass: 'common-cell-data-bold', hide: true },
    {
      headerName: '',
      field: 'status',
      cellClass: (params: any) => {
        switch (params.value) {
          case 'NEW': return 'request-status-tag  new account-user-common-status-cell cell-right-align';
          case 'IN PROGRESS': return 'request-status-tag  inprogress-color account-user-common-status-cell';
          case 'CLOSED': return 'request-status-tag  closed account-user-common-status-cell';
          case 'ASSIGNED': return 'request-status-tag  assigned-color account-user-common-status-cell';
          case 'RESOLVED': return 'request-status-tag  resolved-color  account-user-common-status-cell';
          default: return '';
        }
      }, minWidth: 150, hide: true
    },
  ];
  rowDataPasses = [
    { date: "1/11/24", username: "First/Last Name", walkon: "Walk-On", gate: "Gate-3" },
    { date: "1/11/24 ", username: "First/Last Name", walkon: "Walk-On", gate: "Gate-3" },
    { date: "1/11/24 ", username: "First/Last Name", walkon: "Walk-On", gate: "Gate-3" },
    { date: "1/11/24 ", username: "First/Last Name", walkon: "Walk-On", gate: "Gate-3" },
    { date: "1/11/24 ", username: "First/Last Name", walkon: "Walk-On", gate: "Gate-3" },
    { date: "1/11/24 ", username: "First/Last Name", walkon: "Walk-On", gate: "Gate-3" },
    { date: "1/11/24 ", username: "First/Last Name", walkon: "Walk-On", gate: "Gate-3" },
    { date: "1/11/24 ", username: "First/Last Name", walkon: "Walk-On", gate: "Gate-3" },
    { date: "1/11/24 ", username: "First/Last Name", walkon: "Walk-On", gate: "Gate-3" },
    { date: "1/11/24 ", username: "First/Last Name", walkon: "Walk-On", gate: "Gate-3" }
  ];

  colDefsPasses: ColDef[] = [
    {
      headerName: '',
      field: 'date',
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const date = params.data.date;
        return `<span class="common-split-cell-section">
                  <span class="common-split-cell-data-default-font"><span class="passes-table-arrival-time-hide">Arrival Time: </span>${date}</span>
                </span>`;
      },
      minWidth: 230,
    },
    {
      headerName: '',
      field: 'username',
      wrapText: true,
      autoHeight: true,
      minWidth: 270, flex: 1.5,
      cellRenderer: (params: ICellRendererParams) => {
        const username = params.data.username;
        return `<span class="common-split-cell-section">
                  <span class="common-split-cell-data-1">${username}</span>
                </span>`;
      },
    },
    {
      headerName: '', field: "walkon", minWidth: 120,
      cellRenderer: (params: ICellRendererParams) => {
        const walkon = params.data.walkon;
        const imageUrl = '../../assets/images/man.svg';
        return `<span class="common-split-cell-section">
                    <span class="d-flex justify-content-center">                
                    <img src="${imageUrl}" alt="man-icon" class="man-icon-img"/>${walkon}</span>
                  </span>`;
      },
    },
    { headerName: '', field: "gate", minWidth: 60 },
  ];

  rowDataCredit = [
    { date: "1/11/24", arNo: "123456", department: "Department Name,", status: "NEW" },
    { date: "1/11/24 ", arNo: "123456", department: "Department Name,", status: "UNDER REVIEW" },

  ];

  colDefsCredit: ColDef[] = [
    {
      headerName: '',
      field: 'date',
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const date = params.data.date;
        return `<span class="common-split-cell-section ">
                  <span class="common-split-cell-data-1">${date}</span>
                </span>`;
      },

      minWidth: 110,
    },
    {
      headerName: '',
      field: 'arNo',
      wrapText: true,
      autoHeight: true,
      minWidth: 100
    },
    {
      headerName: '',
      field: 'merged-field',
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const date = params.data.date;
        const arNo = params.data.arNo;
        return `<span class="common-split-cell-section">
                  <span class="common-split-cell-data-1">${arNo}</span>
                  <span class="common-split-cell-data-2">${date}</span>
                </span>`;
      },
      minWidth: 120
    },
    {
      headerName: '',
      field: 'department',
      wrapText: true,
      autoHeight: true,
      minWidth: 150, flex: 3,
      cellRenderer: (params: ICellRendererParams) => {
        const department = params.data.department;
        return `   <span class="credit-department-table-section ">       
        <span >${department}${department}${department}</span>       
              
           
        </span>`;
      },
    },
    {
      headerName: '',
      field: 'status',
      cellClass: (params: any) => {
        switch (params.value) {
          case 'NEW': return 'ar-status-tag new-application account-user-common-status-cell';
          case 'UNDER REVIEW': return 'ar-status-tag  under-review account-user-common-status-cell';
          case 'REVISION': return 'ar-status-tag revision account-user-common-status-cell';
          case 'NEEDS APPROVAL': return 'ar-status-tag  assigned-color needs-approval account-user-common-status-cell';
          case 'APPROVED': return 'ar-status-tag  resolved-color approved account-user-common-status-cell';
          case 'DENIED': return 'ar-status-tag  resolved-color denied account-user-common-status-cell';
          default: return '';
        }
      }, minWidth: 150
    },
  ];
  gridApi!: GridApi;

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.adjustColumns(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustColumns(event.target.innerWidth);
  }

  adjustColumns(screenWidth: number) {
    // Adjust for request grid
    const requestGridApi = this.requestGrid.api;
    if (requestGridApi) {
      const requestColumnIds = ['date', 'merged-field', 'request', 'status'];
      if (screenWidth <= 378) {
        requestGridApi.setColumnsVisible(requestColumnIds, false);
        requestGridApi.setColumnsVisible(['merged-field', 'status'], true);
      } else {
        requestGridApi.setColumnsVisible(requestColumnIds, true);
        requestGridApi.setColumnsVisible(['merged-field'], false);
      }
    }

    // Adjust for passes grid
    const passesGridApi = this.passesGrid.api;
    if (passesGridApi) {
      const passesColumnIds = ['date', 'username', 'walkon', 'gate'];
      if (screenWidth <= 378) {
        passesGridApi.setColumnsVisible(passesColumnIds, false);
        passesGridApi.setColumnsVisible(['date', 'username', 'walkon'], true);
      }
      else if (screenWidth <= 992) {
        passesGridApi.setColumnsVisible(passesColumnIds, false);
        passesGridApi.setColumnsVisible(['date', 'username', 'walkon'], true);
      } else {
        passesGridApi.setColumnsVisible(passesColumnIds, true);
      }
    }

    // Adjust for credit grid
    const creditGridApi = this.creditGrid.api;
    if (creditGridApi) {
      const creditColumnIds = ['date', 'arNo', 'department', 'status'];
      if (screenWidth <= 378) {
        creditGridApi.setColumnsVisible(creditColumnIds, false);
        creditGridApi.setColumnsVisible(['merged-field', 'department', 'status'], true);
      } else if (screenWidth <= 992) {
        creditGridApi.setColumnsVisible(creditColumnIds, false);
        creditGridApi.setColumnsVisible(['merged-field', 'department', 'status'], true);
      } else {
        creditGridApi.setColumnsVisible(creditColumnIds, true);
        creditGridApi.setColumnsVisible(['merged-field'], false);
      }
    }
  }
}

