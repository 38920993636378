<button data-bs-toggle="modal" data-bs-target="#credApp">Final Form</button>
<!-- common modal section-->
<div class="modal fade" id="credApp" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="false">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center">
        <div class="cm-header-section">
          <h5 class="modal-title" id="commonModalLabel">Credit Application</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
              class="cm-close-text">Close</span></button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
              <div class="modal-left-section me-4">
                <h4 class="common-left-section-title mt-4">Credit Application</h4>
                <div class="mb-4 mt-3">
                    <div class="list-group list-group-flush mb-2">
                        <ul class="step-list">
                        <li>                           
                           <span class="step-text current-step"> Status</span>
                        </li>
                        <li>
                            <span class="step-text"> Form</span>
                        </li>
                        </ul>
                    </div>
                    <span class=" mb-1"><a ><img src="../assets/images/pdf-blue-icon.svg" alt="" class="me-2"><span class="text-link">Download Packet</span></a></span>
                </div>
                <hr>
                <h4 class="common-left-section-title ">Company Info</h4>
                <div class="company-meta-data">
                  <p class="title mb-0">
                    <strong class="common-title">PROJECT:</strong
                    ><span class="common-content">The new show</span>
                  </p>
                  <p class="title mb-0 pb-2">
                    <strong class="common-title">Comapny:</strong>
                  </p>
                  <p class="common-content pb-2 pt-0 mb-0">
                    A Brand New Production Company
                  </p>
                  <p class="title mb-0">
                    <strong class="common-title">TYPE:</strong
                    ><span class="common-content">Major Studio</span>
                  </p>
                  <p class="title mb-0">
                    <strong class="common-title">APPLICATION DATE:</strong
                    ><span class="common-content">1/1/24</span>
                  </p>
                  <div class="d-flex flex-column mt-2">
                    <span class=" mb-1"><a class=""><img src="../assets/images/link-icon.svg" alt="" class="me-2 "><span class="text-link">View Projects & Quotes</span></a></span>
                    <span class=" mb-1"><a class="" ><img src="../assets/images/link-icon.svg" alt="" class="me-2 "><span class="text-link">View Company</span></a></span>

                  </div>
                </div>            
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="container cm-main-container px-0 mx-0">

                <div class="common-modal-input-section grey-bg review-panel">
                    <div class="row mb-4">
                      <div class="col-md-4"><h4 class="right-section-heading">Line of Credit</h4>     </div>
                      <div class="col-md-8">
                        <div class="common-user-info">
                            <p class="title mb-0"><strong class="common-title">Line of Credit requested:</strong> <span class="common-content">$xxx,xxx,xxx.xx</span></p>
                            <p class="title mb-0"><strong class="common-title">Anticipated Job Total:</strong><span class="common-content">$xxx,xxx,xxx.xx</span> </p>
                            <p class="title mb-0"><strong class="common-title">Total # of months:</strong> <span class="common-content">2</span></p>
                        </div>
                      </div>
                    </div>                  
                    
                    
                    <hr class="my-5"><!-- divider -->
  
                    <div class="row mb-4">
                      <div class="col-md-4"><h4 class="right-section-heading">Letter of Credit/Guarantee</h4></div>
                      <div class="col-md-8">
                          <div class="common-user-info">
                            <p class="mb-0 title"><strong class="common-title">Comments : &nbsp;</strong><span class="common-content">None</span></p>                    
                          </div>
                          <div class="row pt-3">
                            <div class="col-md-6"><span><img  src="../assets/images/pdf-icon.svg" alt=""> signed.pdf</span></div>
                            <div  class="col-md-6 text-end"><a href="#" class="text-decoration-none" ><span class="text-link">View</span></a></div>
                        </div> 
                      </div>
                    </div>                  
                    
                    <hr class="my-5"><!-- divider -->
                    <div class="row mb-4">
                      <div class="col-md-4"><h4 class="right-section-heading">Company</h4>     </div>
                      <div class="col-md-8">
                        <div class="common-user-info">
                            <p class="mb-0 title" ><strong class="common-title">Name : &nbsp;</strong> <span class="common-content">A Brand New Company</span></p>
                            <p class="mb-0 title"><strong  class="common-title">Address :&nbsp;</strong> <span class="common-content">2500 Main Street, Los Angeles, CA, 92783</span> </p>
                            <p class="mb-2 title"><strong  class="common-title">Phone :&nbsp;</strong> <span class="common-content"> 858556565</span></p>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-1"
                            class="common-checkbox-input"
                            disabled checked
                          />
                          <label for="custom-checkbox-1" class="common-checkbox-label">
                            Use for billing address
                          </label>
                        </div>
                          </div>
                      </div>
                    </div>                  
                    
                    <hr class="my-5"><!-- divider -->
                    <div class="row mb-4">
                      <div class="col-md-4"><h4 class="right-section-heading">Federal Tax ID #</h4>     </div>
                      <div class="col-md-8">
                        <div class="common-user-info">
                            <p class="mb-0 title"><strong class="common-title">TIN: &nbsp;</strong> <span class="common-content">123456789</span></p>                    
                          </div>
                      </div>
                    </div>                  
                    
                    <hr class="my-5"><!-- divider -->
                    <div class="row mb-4">
                      <div class="col-md-4"><h4 class="right-section-heading">Accounts Payable Contact</h4>     </div>
                      <div class="col-md-8">
                        <div class="common-user-info">
                            <p class="mb-0 title"><strong class="common-title">Name:&nbsp;</strong><span class="common-content">A Brand New Company</span> </p>
                            <p class="mb-0 title"><strong class="common-title">Email:&nbsp;</strong> <span class="common-content">name@domain.com</span></p>
                            <p class="mb-2 title"><strong class="common-title">Phone:&nbsp;</strong><span class="common-content">858556565</span></p>                    
                          </div>
                      </div>
                    </div>                  
                    
                    <hr class="my-5"><!-- divider -->
                    <div class="row mb-4">
                      <div class="col-md-4"><h4 class="right-section-heading">Company Profile</h4>     </div>
                      <div class="col-md-8">
                        <div class="common-user-info">
                            <p class="mb-0 title"><strong class="common-title">Profile:</strong><span class="common-content">Corporate</span> /p>
                            <p class="mb-0 title"><strong class="common-title">Date Started:</strong> <span class="common-content">1/1/24</span></p>
                            <p class="mb-2 title"><strong class="common-title">Type of Business:</strong><span class="common-content">Corporation</span> </p>
                            <p class="mb-0 title"><strong class="common-title">Total Annual Sales:</strong><span class="common-content">$xx.xx</span> </p>
                            <p class="mb-0 title"><strong class="common-title">Net worth:</strong><span class="common-content">$xx.xx</span> </p>
                            <p class="mb-2 title"><strong class="common-title"># of Employees:</strong><span class="common-content">0-25</span></p>
                          </div>
                      </div>
                    </div>                  
                    
                    <hr class="my-5"><!-- divider -->
                    <div class="row mb-4">
                      <div class="col-md-4"><h4 class="right-section-heading">Principals/Officers or Partners</h4>     </div>
                      <div class="col-md-8">
                        <div class="common-user-info">
                            <p class="mb-0 title"><strong class="common-title">Name :</strong><span class="common-content">A Brand New Company</span> </p>
                            <p class="mb-0 title"><strong class="common-title">Title :</strong><span class="common-content">2500 Main Street, Los Angeles, CA, 92783</span> </p>                   
                          </div>
                      </div>
                    </div>                  
                    
                    <hr class="my-5"><!-- divider -->
                    <div class="row mb-4">
                      <div class="col-md-4"><h4 class="right-section-heading">Banking References</h4>     </div>
                      <div class="col-md-8">
                        <div class="common-user-info">
                            <p class="mb-0 title"><strong class="common-title">Bank :</strong><span class="common-content">A Brand New Company</span> </p>
                            <p class="mb-0 title"><strong class="common-title">Account Number :</strong><span class="common-content">1234 5678 9012 3456</span> </p>
                            <p class="mb-2 title"><strong class="common-title">Name :</strong><span class="common-content">John Doe</span> </p>
                            <p class="mb-0 title"><strong class="common-title">Title :</strong> <span class="common-content">A Brand New Company</span></p>
                            <p class="mb- title"><strong class="common-title">Email :</strong><span class="common-content">name@domain.com</span> </p>
                            <p class="mb-2 title"><strong class="common-title">Phone :</strong> <span class="common-content">858556565</span></p>                    
                          </div>
                      </div>
                    </div>                  
                    
                    <hr class="my-5"><!-- divider -->
                    <div class="row mb-4">
                      <div class="col-md-4"><h4 class="right-section-heading">Trade Reference 1</h4>     </div>
                      <div class="col-md-8">
                          <div class="common-user-info">
                            <p class="mb-0 title"><strong class="common-title">Company:</strong><span class="common-content">Name</span></p>
                            <p class="mb-0 title"><strong class="common-title">Contact:</strong><span class="common-content"> Data</span></p>
                            <p class="mb-0 title"><strong class="common-title">Address:</strong><span class="common-content">  2500 Main Street, Los Angeles, CA, 92783</span></p>
                            <p class="mb-0 title"><strong class="common-title">Email:</strong><span class="common-content"> name@domain.com</span></p>
                            <p class="mb-2 title"><strong class="common-title">Phone:</strong><span class="common-content">858556565</span></p>                            
                          </div>
                          <div class="row pt-3">
                            <div class="col-md-6"><span><img  src="../assets/images/pdf-icon.svg" alt=""> signed.pdf</span></div>
                            <div  class="col-md-6 text-end"><a href="#" class="text-decoration-none"><span  class="text-link">View</span></a></div>
                        </div> 
                      </div>
                    </div>                  
                    
                    <hr class="my-5"><!-- divider -->
                    <div class="row mb-4">
                      <div class="col-md-4"><h4 class="right-section-heading">Trade Reference 2</h4>     </div>
                      <div class="col-md-8">
                        <div class="common-user-info">
                            <p class="mb-0 title"><strong class="common-title">Company:</strong><span class="common-content">Name</span> </p>
                            <p class="mb-0 title"><strong class="common-title">Contact:</strong><span class="common-content"> Data</span></p>
                            <p class="mb-0 title"><strong class="common-title">Address:</strong> <span class="common-content">2500 Main Street, Los Angeles, CA, 92783</span></p>
                            <p class="mb-0 title"><strong class="common-title">Email:</strong> <span class="common-content">name@domain.com</span></p>
                            <p class="mb-2 title"><strong class="common-title">Phone:</strong><span class="common-content">858556565</span> </p>                            
                          </div>
                      </div>
                    </div>                  
                    
                    <hr class="my-5"><!-- divider -->
                    <div class="row mb-4">
                      <div class="col-md-4"><h4 class="right-section-heading">Trade Reference 3</h4>     </div>
                      <div class="col-md-8">
                        <div class="common-user-info">
                            <p class="mb-0 title"><strong  class="common-title">Company:</strong><span class="common-content">Name</span> </p>
                            <p class="mb-0 title"><strong  class="common-title">Contact:</strong><span class="common-content"> Data</span></p>
                            <p class="mb-0 title"><strong  class="common-title">Address:</strong> <span class="common-content">2500 Main Street, Los Angeles, CA, 92783</span></p>
                            <p class="mb-0 title"><strong  class="common-title">Email:</strong> <span class="common-content">name@domain.com</span></p>
                            <p class="mb-2 title"><strong class="common-title">Phone:</strong><span class="common-content">858556565</span> </p>                            
                          </div>    
                      </div>
                    </div>                  
                   
                  </div>

                  <div class="common-modal-input-section grey-bg">
                    <div class="row mb-4">
                        <div class="col-md-4"><h4 class="right-section-heading">Terms & Conditions</h4>     </div>
                        <div class="col-md-8">
                          <div class="common-user-info">
                              <p class="mb-0 title"><strong class="common-title">Name:</strong><span class="common-content">Catherine Decker</span> </p>
                              <p class="mb-0 title"><strong class="common-title">Title:</strong><span class="common-content">Head of Accounting</span> </p>
                              <p class="mb-0 title"><strong class="common-title">signature:</strong><span class="common-content">Yes</span> </p>
                                <p class="mb-0 title"><strong class="common-title">Date:</strong><span class="common-content">xx/xx/xxxx</span></p>                              
                            </div>    
                        </div>
                      </div>                   
                  </div>

                  <div class="common-modal-input-section grey-bg">
                    <div class="row mb-4">
                        <div class="col-md-4"><h4 class="right-section-heading">Certificate of Insurance</h4>     </div>
                        <div class="col-md-8">
                          <div class="common-user-info">
                              <p class="mb-0 title"><strong class="common-title">Producer:</strong><span class="common-content">XYZ Broker Company</span> </p>
                              <p class="mb-0 title"><strong class="common-title">Insured:</strong> <span class="common-content">John Doe</span></p>
                              <p class="mb-0 title"><strong class="common-title">Address:</strong> <span class="common-content">2500 Main Street, Los Angeles, CA, 92783</span></p>                              
                            </div> 
                            <div class="row pt-3">
                                <div class="col-md-6"><span><img  src="../assets/images/pdf-icon.svg" alt=""> signed.pdf</span></div>
                                <div  class="col-md-6 text-end"><a href="#"class="text-decoration-none"><span class="text-link">View</span></a></div>
                            </div>   
                        </div>
                      </div>                    
                  </div>

                  <div class="common-modal-input-section grey-bg">
                    <div class="row mb-4">
                        <div class="col-md-4"><h4 class="right-section-heading">D&B Report</h4>     </div>
                        <div class="col-md-8">
                            <div class="row pt-3">
                                <div class="col-md-6"><span><img  src="../assets/images/pdf-icon.svg" alt=""> signed.pdf</span></div>
                                <div  class="col-md-6 text-end"><a href="#" class="text-decoration-none"><span class="text-link">View</span></a></div>
                            </div> 
                            <div class="row mt-3">
                                <span class="col-4 title"><strong  class="title">Delliquency</strong>:<span class="common-content">88</span> </span>
                                <span class="col-4 title"><strong  class="title">Failure Score</strong>:<span class="common-content">67</span> </span>
                                <span class="col-4 px-0 title"><strong  class="title">PAYDEX® Score:</strong>:<span class="common-content">72</span> </span>
                            </div>    
                        </div>
                      </div>                    
                  </div>
                
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
   