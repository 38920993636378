import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FORM_VALIDATION } from '../../constants/common';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {
  formValidation= FORM_VALIDATION 

  constructor() { }

  /**
   * validateAlphabets() - method to validate if the control value contains only alphabetic characters or is empty.
   * If the field is empty or contains only whitespace, it returns a 'required' error.
   * @param control - The form control to validate.
   * @returns null if valid or a ValidationError object if invalid.
   */
  validateAlphabets(control: AbstractControl): ValidationErrors | null {
    const alphabetPattern = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
    const value = control.value;
    if (!value || value.trim() === '') {
      return { required: true };
    }
    return alphabetPattern.test(value) ? null : { invalidAlphabets: true };
  }

  /**
   * validateEmail() - method to validate if the control value is a valid email format.
   * If the field is empty or contains only whitespace, it returns a 'required' error.
   * @param control - The form control to validate.
   * @returns null if valid or a ValidationError object if invalid.
   */
  validateEmail(control: AbstractControl): ValidationErrors | null {
    const emailPattern = /^[^\s][a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}[^\s]$/;
    const trimmedValue = control.value;
    if (trimmedValue === '') {
      return { required: true };  // Empty or whitespace only input
    }
    return emailPattern.test(trimmedValue) ? null : { invalidEmail: true };
  }

  /**
   * validateJobTitle() - method to validate if the control value is a valid job title format.
   * Allows alphanumeric characters, spaces, and specific special characters.
   * If the field is empty or contains only whitespace, it returns a 'required' error.
   * @param control - The form control to validate.
   * @returns null if valid or a ValidationError object if invalid.
   */
  validateJobTitle(control: AbstractControl): ValidationErrors | null {
    const jobTitlePattern = /^[A-Za-z0-9\s*()":{}|<>-]+$/;
    const trimmedValue = control.value?.trim();  // Ensure null values are handled
    if (trimmedValue === '') {
      return { required: true };  // Empty or whitespace only input
    }
    return jobTitlePattern.test(trimmedValue) ? null : { invalidJobTitle: true };
  }

  /**
  * validateJobTitleAddUser() - method to validate if the control value is a valid job title format.
  * Allows alphanumeric characters, spaces, and specific special characters and it will not allow trailing,leading spaces.
  * If the field is empty or contains only whitespace, it returns a 'required' error.
  * @param control - The form control to validate.
  * @returns null if valid or a ValidationError object if invalid.
  */
  validateJobTitleAddUser(control: AbstractControl): ValidationErrors | null {
    const jobTitlePattern = /^[A-Za-z0-9]+(?:[\s*()":{}|<>-][A-Za-z0-9]+)*$/;
    const trimmedValue = control.value;  // Ensure null values are handled
    if (trimmedValue === '') {
      return { required: true };  // Empty or whitespace only input
    }
    return jobTitlePattern.test(trimmedValue) ? null : { invalidJobTitle: true };
  }

  /**
   * validatePhone() - method to validate if the control value is a valid phone number format.
   * Allows numbers, dashes, plus, and parentheses.
   * If the field is empty or contains only whitespace, it returns a 'required' error.
   * @param control - The form control to validate.
   * @returns null if valid or a ValidationError object if invalid.
   */
  validatePhone(control: AbstractControl): ValidationErrors | null {
   const phonePattern = /^[+]*[0-9]*[()\d-]*$/;
    const value = control.value || '';
    const trimmedValue = value.trim();
    const minLength = 10;
    if (trimmedValue === '') {
      return { required: true };
    }

    const digitsOnly = trimmedValue.replace(/\D/g, '');
    if (digitsOnly.length < minLength) {
      return { minLength: true };
    }
    return phonePattern.test(trimmedValue) ? null : { invalidPhone: true };
  }
  
  
  /**
   * validateAtLeastOneSelected() - method to validate if at least one checkbox from a list of controls is selected.
   * Returns an error if none of the checkboxes are selected.
   * @param controls - An array of form controls representing checkboxes.
   * @returns null if at least one checkbox is selected, otherwise a ValidationError object.
   */
  validateAtLeastOneSelected(controls: AbstractControl[]): ValidationErrors | null {
    const isAnySelected = controls.some(control => control.value);
    return isAnySelected ? null : { atLeastOneRequired: true };
  }

  /**
   * phoneValidator() - This functions evaluated the phone  number field and throws erros according to that.
   * @returns - used to validate phone number in profile page;
   */
  phoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return { required: true }; // Return required error if no value
      }
  
      const phonePattern = this.formValidation.phoneValidation;
      const minLengthValid = value.replace(/[\s()-]/g, '').length >= 10;
      const validPattern = phonePattern.test(value);
  
      if (!minLengthValid) {
        return { minLengthError: true }; 
      }
  
      if (!validPattern) {
        return { invalidPhone: true }; 
      }
  
      return null;
    };
  }


  /**
   * JobTitleValidator() - used to validate a job title
  @param control - The form control to validate.
 * @returns ValidationErrors | null - Returns an error object if validation fails, or null if it passes.
   */
  JobTitleValidator(control: AbstractControl): ValidationErrors | null {
  const trimmedValue = control.value?.trim();  

  if (trimmedValue === '') {
    return { required: true };  
  }

  const hasConsecutiveSpaces = /\s{2,}/.test(trimmedValue);
  
  return hasConsecutiveSpaces ? { invalidJobTitle: true } : null;
}
Ex
}
