
<div *ngIf="isAuthenticated">
  <app-menu [portalType]="portalType"></app-menu>
</div>
<div class="container-fluid page-wrapper">
    <div class="container">
      <div class="row">
        <div class="px-4">
          <h1 class="page-title">{{constants.handbook}}</h1>
          <div class="d-flex align-items-center view-text pb-0 mb-4 mt-3">
            <div>
              <p class="me-3 mb-0 change-weather-title-block">You are viewing the Handbook for <span
                  class="change-weather-title">{{ isBurbank ? 'Burbank' : 'Leavesden' }}</span> <span class="change-weather-toggle"  (click)="toggleCity()"> <span>Change</span> <i
                    class="bi bi-geo-alt-fill"></i>
                </span></p>
            </div>
        
          </div>
          <p class="para-text mt-4">{{constants.paragraph_text}}</p>
        </div>
        <ng-container *ngIf="isBurbank">
          <div routerLink="/core/wbso-handbook-page" [queryParams]="{ tab: 'office-production-tab' }"
          class="card-wrapper col-md-6 col-sm-6 mb-md-3 mb-sm-3 mb-3 col-lg-6 col-xl-3 justify-contents-center d-flex align-items-center">
          <div class="card">
            <div class="card-body">
              <h1 class="card-title mb-3">{{number.one}}</h1>
              <p class="card-text" >{{constants.office_production_services}}</p>
            </div>
          </div>
        </div>
        <div routerLink="/core/wbso-handbook-page" [queryParams]="{ tab: 'purchase-billing-tab' }"
          class="card-wrapper col-md-6 col-sm-6 mb-md-3 mb-sm-3 mb-3 col-lg-6 col-xl-3 justify-contents-center d-flex align-items-center">
          <div class="card">
            <div class="card-body">
              <h1 class="card-title mb-3">{{number.two}}</h1>
              <p class="card-text">{{constants.purchasing_and_billing}}</p>
            </div>
          </div>
        </div>
        <div [routerLink]="['/core/wbso-handbook-page']" [queryParams]="{ tab: 'studio-protection-tab' }"
          class="card-wrapper col-md-6 col-sm-6 mb-md-3 mb-sm-3 mb-3 col-lg-6 col-xl-3 justify-contents-center d-flex align-items-center">
          <div class="card">
            <div class="card-body">
              <h1 class="card-title mb-3">{{number.three}}</h1>
              <p class="card-text">{{constants.studio_protection_group}}</p>
            </div>
          </div>
        </div>
        <div routerLink="/core/wbso-handbook-page"  [queryParams]="{ tab: 'safety-environment-tab' }"
          class="card-wrapper col-md-6 col-sm-6 mb-md-3 mb-sm-3 mb-3 col-lg-6 col-xl-3 justify-contents-center d-flex align-items-center">
          <div class="card">
            <div class="card-body">
              <h1 class="card-title mb-3">{{number.four}}</h1>
              <p class="card-text">{{constants.safety_and_enivironmental_affairs}}</p>
            </div>
          </div>
        </div>
        </ng-container>
        <ng-container *ngIf="!isBurbank">
          <div class="px-4 mb-4 ms-4 mt-3">
            <a class="me-3 mb-0 text-decoration-none" href=".../../../../../../../assets/docs/Leavesden-Client-Operations-Handbook-V35.pdf" target="_blank"><span class="text-link leavesden-link">Leavesden-Client-Operation-Handbook-V35.PDF</span></a>
          </div>
        </ng-container>
        
      </div>
    </div>
  </div>
