<div class="user-project-wrapper">
	<div class="container px-0">
		<div class="row">
			<div class="comman-title-block">
				<div class="comman-title-section">
					<h1>Company</h1>
					<button class="common-btn-primary btn">+ Company</button>
				</div>
				<div class="comman-filter-block">
					<div class="container">
						<div class="row">
							<div class="col-lg-9 col-12 px-0">
								<div class="comman-filter-title-section">
									<p>Filters</p>
								</div>
								<div class="comman-filter-section d-flex flex-wrap align-items-center gap-3">
									<!-- Multiselect Dropdowns -->
									<ng-multiselect-dropdown class="custom-multiselect" [placeholder]="'Status'"
										[settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
										(onSelect)="onItemSelect($event)">
									</ng-multiselect-dropdown>
                                    <div class="common-model-xl">
                                        <div class="model-body">
                                            <div class="mb-0  custom-searchbar">
                                                <!-- <label for="input1" class="form-label">Search</label> -->
                                                <input type="text" class="pe-4 form-control" placeholder="Search company name or company ID #"
                                                    id="input1">
                                                <img class="search-icon" src="../../../../../../assets/images/search-icon.svg" alt="Search Icon">
                                            </div>
                                        </div>
                                    </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="common-grid-block">
				<div class="mt-4">
					<ag-grid-angular  class="ag-theme-custom" [gridOptions]="gridOptions"
						[columnDefs]="colDefs" [rowData]="rowData" (gridReady)="onGridReady($event)"
						(paginationChanged)="updatePagination()">
					</ag-grid-angular>
				</div>
			</div>
		</div>
		<div class="common-pagination-section">
			<div class="common-pagination-controls">
				<div class="items-per-page">
					<label for="page-size" class="per-page">Items per page:</label>
					<select id="page-size" (change)="onPageSizeChanged($event)">
						<option *ngFor="let size of pageSizeOptions" [value]="size" class="size-per-page">{{size}}</option>
					</select>
				</div>
				<div class="common-pagination-navigation-block ">
					<div class="pagination-no align-self-center">{{startRow}} - {{endRow}} of {{totalRows}}
						<span class="paginator-arrow-icons align-self-center">
							<a class="arrow-1" (click)="onBtPrevious()"> <img src="../../assets/images/arrow-left.svg"
									alt="icon" /></a>
							<a class="arrow-2" (click)="onBtNext()"><img src="../../assets/images/arrow-right.svg" alt="icon" /></a>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>