import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { PAGINATION_TEXT } from '../../constants/common';
import { ITEMS_PER_PAGE, PAGINATION_PAGES } from '../../enum/shared.enum';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() totalItems: number; // The total number of items to paginate
  @Input() itemsPerPage: number; // The number of items to display per page
  @Input() currentPage: number; // The current page number
  @Output() pageChange = new EventEmitter<number>(); // Event emitter for page change
  @Output() itemsPerPageChange = new EventEmitter<number>(); // Event emitter for items per page change

  itemsPerPageOptions: number[] = [ITEMS_PER_PAGE.ten, ITEMS_PER_PAGE.twenty, ITEMS_PER_PAGE.thirty, ITEMS_PER_PAGE.fourty]; // Array of options for items per page
  totalPageCount: number; // The total number of pages
  paginationText = PAGINATION_TEXT; // constants for pagination

  ngOnInit(): void {
    this.getTotalPageCount();
  }

  /**
   * getTotalPageCount() - Method to calculate the total number of pages based on total items and items per page
   */
  getTotalPageCount(): void {
    this.totalPageCount = Math.ceil(this.totalItems / this.itemsPerPage);
  }

  /**
   * nextPage() - Method to navigate to the next page
   */
  nextPage(): void {
    if (this.currentPage * this.itemsPerPage < this.totalItems) {
      this.currentPage++;
      this.pageChange.emit(this.currentPage);
    }
  }

  /**
   * prevPage() - Method to navigate to the previous page
   */
  prevPage(): void {
    if (this.currentPage > PAGINATION_PAGES.one) {
      this.currentPage--;
      this.pageChange.emit(this.currentPage);
    }
  }
  /**
   * onItemsPerPageChange() - Method to handle changes in the number of items per page
   * @param option The new number of items to display per page
   */
  onItemsPerPageChange(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.itemsPerPage = +selectedValue; // Convert to number
    this.itemsPerPageChange.emit(this.itemsPerPage);
    this.currentPage = PAGINATION_PAGES.one; // Reset to page 1
    this.getTotalPageCount();
    this.pageChange.emit(this.currentPage);
  }
  
  /**
   * isLastPage - Getter to determine if the current page is the last page
   * returns a boolean indicating if the current page is the last page
   */
  get isLastPage() {
    return this.currentPage * this.itemsPerPage >= this.totalItems;
  }

  /**
   * remainingItemsOnLastPage - Getter to calculate the number of remaining items on the last page
   * returns the number of remaining items on the last page
   */
  get remainingItemsOnLastPage() {
    return this.totalItems - (this.currentPage - PAGINATION_PAGES.one) * this.itemsPerPage;
  }
}
