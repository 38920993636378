<div class="container-fluid handbook-wrapper">
	<div class="container">
		<div class="row">
			<div>
				<h1 class="page-title">Hi, <span class="user-name">Laura</span></h1>
			</div>
			<div class="select-account-wrapper">
				<p class="mb-0">Select an account</p>
			</div>
			<div class="col-md-12 justify-content-center align-items-center d-flex">
				<div class="col-md-8 col-12">
					<div class="select-account-dropdown">
						<select class="form-select" aria-label="Default select example">
							<option selected>Open this select menu</option>
							<option value="1">One</option>
							<option value="2">Two</option>
							<option value="3">Three</option>
						</select>
						<button class="btn common-btn-secondary">Go</button>
					</div>
					<p class="normal-text">Not Laura?</p>
					<a href="" class="text-link">Sign In with a different email.</a>
				</div>
			</div>
		</div>
	</div>
</div>