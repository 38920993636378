import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'We encountered an error. Please try again later.';

        if (error.status === 500) {
            
          // Replace the error message
          error = new HttpErrorResponse({
            error: { message: errorMessage },
            headers: error.headers,
            status: error.status,
            statusText: error.statusText,
            url: error.url!,
          });
        }

        return throwError(() => error);
      })
    );
  }
}