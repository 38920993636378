<button data-bs-toggle="modal" data-bs-target="#reviewStep4">Review step 4</button>
<!-- common modal section-->
<div class="modal fade" id="reviewStep4" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="false">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
          <h5 class="modal-title" id="commonModalLabel">Review</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
              class="cm-close-text">Close</span></button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
              <div class="modal-left-section me-4">
                <h4 class="common-left-section-title mt-4">Company Info</h4>
                <div class="company-meta-data">
                  <p class="title mb-0"><strong class="common-title">PROJECT:</strong><span class="common-content">The new show</span></p>
                  <p class="title mb-0 pb-2"><strong class="common-title">Comapny:</strong></p>
                  <p class="common-content pb-2 pt-0 mb-0">A Brand New Production Company</p>
                  <p class="title mb-0"><strong class="common-title">TYPE:</strong><span class="common-content">Major Studio</span></p>
                  <p class="title mb-0"><strong class="common-title">APPLICATION DATE:</strong><span class="common-content">1/1/24</span></p>
                  <div class="d-flex flex-column mt-2">
                    <span class=" mb-1"><a class="text-link"><img src="../assets/images/link-icon.svg" alt=""
                          class="me-2">View Projects & Quotes</a></span>
                    <span class=" mb-1"><a class="text-link"><img src="../assets/images/link-icon.svg" alt=""
                          class="me-2">View Company</a></span>
                  </div>
                </div>
                <hr>
                <h4 class="common-left-section-title">Review</h4>
                <div class="mb-4 mt-3">
                  <div class="list-group list-group-flush mb-2">
                    <ul class="step-list">
                      <li>
                        <span class="step-icon step-check"></span>
                        <span class="step-text"> Application Form</span>
                      </li>
                      <li>
                        <span class="step-icon step-check "></span>
                        <span class="step-text"> Certification of Insurance</span>
                      </li>
                      <li>
                        <span class="step-icon step-check"></span>
                        <span class="step-text">Terms & Conditions</span>
                      </li>
                      <li>
                        <span class="step-icon"></span>
                        <span class="step-text current-step">D&B Report</span>
                      </li>
                      <li>
                        <span class="step-icon"></span>
                        <span class="step-text">Summary</span>
                      </li>
                    </ul>
                  </div>
                  <span class=""><a class="text-link"><img src="../assets/images/pdf-blue-icon.svg" class="me-2"
                        alt="">Download Packet</a></span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="container cm-main-container px-0 mx-0">

                <div class="common-modal-input-section grey-bg review-panel">
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-7">
                      <h4 class="right-section-heading">D&B Report</h4>
                    </div>
                    <div class="col-md-5">
                      <div class=" custom-dropdown w-100">
                        <select class="custom-select">
                          <option class="dropdown-option" value="" disabled selected hidden>Action</option>
                          <option class="dropdown-option" value="1"><img src="../assets/images/action-revision.svg"
                              alt="">Upload</option>
                          <option class="dropdown-option" value="2">Not Found</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div  class="common-user-info">
                    <p class="title mb-0"><strong class="common-title">Name:</strong><span class="common-content">John Doe</span></p>
                    <p class="title mb-0"><strong class="common-title">Title:</strong><span class="common-content">Head of Accounting</span></p>
                    <p class="title mb-0"><strong class="common-title">Signature:</strong><span class="common-content">Yes</span></p>
                    <p class="title mb-0"><strong class="common-title">Date:</strong><span class="common-content">xx/xx/xxxx</span></p>
                  </div>
                  <div class="row pt-3">
                    <div class="col-md-6"><span><img src="../assets/images/pdf-icon.svg" alt=""> signed.pdf</span></div>
                    <div class="col-md-6 text-end"><a href="#" class="text-link">View</a></div>
                  </div>

                </div>

                <div class="grey-box">
                  <h2 class="common-heading mb-4">D&B Report Activity</h2>
                  <div class="inner-content">
                    <p>No Activity</p>
                  </div>
                </div>
                <div class="row next-back-btns-section">
                  <div class="col-6"><a href="#" class="back-link text-link">
                      < back</a>
                  </div>
                  <div class="col-6 text-end">
                    <button type="button" class="btn common-btn-secondary">Next:D&B Report</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!---Added modals on click of Select Company-->
<ng-template #company let-modal>
  <div class="modal-body">
    <h2 class="section-main-header">Needs Revisions </h2>
    <p>COMPANY: A Brand New Company</p>
    <p>PROJECT: The new show</p>
    <div class="">
      <div class="mt-56 custom-floating-label">
        <label for="input1" class="form-label">Comments</label>
        <textarea name="comments" id="" rows="4"></textarea>
        <img class="search-icon" src="../../../../../../assets/images/search-icon.svg" alt="Search Icon">
      </div>
    </div>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="btn common-btn-secondary" (click)="modal.close('Close click')">Cancel</button>
      <button type="button" class="btn common-btn-secondary">Select</button>
    </div>
  </div>
</ng-template>