import { Component, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-approve-deny-popup',
  templateUrl: './approve-deny-popup.component.html',
  styleUrls: ['./approve-deny-popup.component.scss']
})
export class ApproveDenyPopupComponent {
  modalReference!: NgbModalRef;
  constructor(private modalService: NgbModal,){}
  openContentModal(content: TemplateRef<any>) {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(content, {
      windowClass: 'common-modal-center',
      size: 'lg',
      centered: true,
    });
  }
  openContentModalDeny(contentdeny: TemplateRef<any>) {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(contentdeny, {
      windowClass: 'common-modal-center',
      size: 'lg',
      centered: true,
    });
  }
}
