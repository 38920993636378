export const userRoleAccessCustCustomerUser: any = {
  //functional area
  "overview": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  "request": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  "project": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  "companyManage": ["customer_company admin"],
  "companyView": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  "clientResource": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  //overview dashboard
  "requestWidget": ["customer_project view only", "customer_project view and manage","customer_company admin"],
  "weatherWidget": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  "activeProject": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  "wbdPass": [],
  "creditappWidget": ["customer_credit application view and manage","customer_manage users view and manage","customer_company admin"],
  "makeRequest": ["customer_company admin"],
  "createPass": [],
  "dashboardAdduser": ["customer_manage users view and manage", "customer_company admin"],
  "startCredit": ["customer_credit application view and manage","customer_company admin"],
  //project
  "addNewProject": [],
  "viewProject": ["customer_project view only", "customer_project view and manage","customer_company admin"],
  "editProject": ["customer_project view and manage","customer_company admin"],
  "mySpace": ["customer_project view only", "customer_project view and manage","customer_company admin"],
  "viewAr": ["customer_project view only", "customer_project view and manage","customer_company admin"],
  "applyCredit": ["customer_credit application view and manage","customer_company admin"],
  "viewCreditProgress": ["customer_credit application view and manage","customer_company admin"],
  "viewCreditDetails": ["customer_credit application view and manage","customer_company admin"],
  "viewProjectUser": ["customer_project view only", "customer_project view and manage","customer_company admin"],
  "addProjectUser": ["customer_manage users view and manage","customer_company admin"],
  "manageProjectUser": ["customer_manage users view and manage", "customer_company admin"],
  "removeProjectUser": ["customer_manage users view and manage", "customer_company admin"],
  "forcePasswordReset": ["customer_manage users view and manage", "customer_company admin"],
  "deactivatUser": ["customer_manage users view and manage", "customer_company admin"],
  //manage comapny & portal user
  "editCompanyInfo": ["customer_company admin"],
  "companyDeletion": ["customer_company admin"],
  "managePortalUser": ["customer_manage users view and manage", "customer_company admin"],
  //manage portal user
  "addUser": ["customer_manage users view and manage", "customer_company admin"],
  "editUser": ["customer_manage users view and manage", "customer_company admin"],
  "removeUser": ["customer_manage users view and manage", "customer_company admin"],
  "portalForcePasswordReset": ["customer_manage users view and manage", "customer_company admin"],
  "makeCompanyAdmin": ["customer_company admin"],
  //portal user profile
  "manageProfile": ["customer_project view only", "customer_project view and manage","customer_company admin"],
  "managePortal": ["customer_project view only", "customer_project view and manage","customer_company admin"],
  "manageEmail": ["customer_project view only", "customer_project view and manage","customer_company admin"],
  //manage customer user
  "manageCustomerUser": ["customer_company admin"],
}

export const userRoleAccessCustStudioUser: any = {
  //functional area
  "overview": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
  "request": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
  "project": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
  "companyManage": ["studio_company admin"],
  "companyView": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
  "clientResource": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
  //overview dashboard
  "requestWidget": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
  "weatherWidget": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
  "activeProject": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
  "wbdPass": [],
  "creditappWidget": ["studio_credit application view and manage", "studio_manage users view and manage", "studio_company admin"],
  "makeRequest": ["studio_company admin"],
  "createPass": [],
  "dashboardAdduser": ["studio_manage users view and manage", "studio_company admin"],
  "startCredit": ["studio_credit application view and manage", "studio_company admin"],
  //project
  "addNewProject": [],
  "viewProject": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
  "editProject": ["studio_project view and manage", "studio_company admin"],
  "mySpace": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
  "viewAr": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
  "applyCredit": ["studio_credit application view and manage", "studio_company admin"],
  "viewCreditProgress": ["studio_credit application view and manage", "studio_company admin"],
  "viewCreditDetails": ["studio_credit application view and manage", "studio_company admin"],
  "viewProjectUser": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
  "addProjectUser": ["studio_manage users view and manage", "studio_company admin"],
  "manageProjectUser": ["studio_manage users view and manage", "studio_company admin"],
  "removeProjectUser": ["studio_manage users view and manage", "studio_company admin"],
  "forcePasswordReset": ["studio_manage users view and manage", "studio_company admin"],
  "deactivatUser": ["studio_manage users view and manage", "studio_company admin"],
  //manage comapny & portal user
  "editCompanyInfo": ["studio_company admin"],
  "companyDeletion": ["studio_company admin"],
  "managePortalUser": ["studio_manage users view and manage", "studio_company admin"],
  //manage portal user
  "addUser": ["studio_manage users view and manage", "studio_company admin"],
  "editUser": ["studio_manage users view and manage", "studio_company admin"],
  "removeUser": ["studio_manage users view and manage", "studio_company admin"],
  "portalForcePasswordReset": ["studio_manage users view and manage", "studio_company admin"],
  "makeCompanyAdmin": ["studio_company admin"],
  //portal user profile
  "manageProfile": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
  "managePortal": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
  "manageEmail": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
  //manage customer user
  "manageCustomerUser": ["studio_company admin"],
}

export const userRoleAccessStudioWBSOUser: any = {
  //functional area
  "myDashboard": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "company": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "projects": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "users": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "creditApp": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "clientResource": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  //overview dashboard
  "creditAppWidget": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "bookmarkProjectWidget": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "requestWidget": ["Department User", "Department Head", "CSR", "System Admin"],
  //company
  "viewCompanyDirectory": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "addCompany": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "editCompnay": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "disableCompany": ["System Admin"],
  "createCreditAppPacket": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "addCompanyUser": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "viewCompany": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "viewUsers": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  //project
  "addNewProject": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "viewProject": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "editProject": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "uploadQuote": ["CSR", "System Admin"],
  "viewQuote": ["CSR", "System Admin", "A/R"],
  "sendCreditAppPacket": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "addProjectUser": ["CSR", "System Admin", "A/R"],
  "creditAppTab": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "mySpace": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "userTab": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  //user management
  "addCustomerUser": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "editCustomerUser": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "removeCustomerUser": ["CSR", "System Admin"],
  "makeCompanyAdmin": ["CSR", "System Admin"],
  "addWBSOUser": ["System Admin","CSR"],
  "editWBSOUser": ["System Admin","CSR"],
  "portalForcePasswordReset": ["CSR", "System Admin"],
  "deactivateUser": ["CSR", "System Admin"],
  //credit application
  "viewCreditApp": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "editCreditAppPacket": ["System Admin", "A/R"],
  "assignRiskApprover": ["System Admin", "A/R"],
  "approveCreditAppLevel1": ["Department Head"],
  "approveCreditAppLevel2": ["Department Head"],
  "approveDenyCreditApp": ["A/R", "Level 1 Approver", "Level 2 Approver"],
  "reviewSubmittedCreditApp": ["A/R"],
  "adjustCreditLimit": ["A/R"],
  "addAR": ["A/R", "Level 1 Approver", "Level 2 Approver"],
  "downloadCreditPacket": ["A/R"],
}


