<div class="container-fluid centered-form-container">
	<div class="top-right-text">
		<p>Already have a company?  <a href="" class="text-link">Sign In</a></p>
	</div>
	<div class="top-left-text">
		<a href="" class="text-link">
			< Back</a>
	</div>
	<div class="row justify-content-center align-items-center w-100">
		<div class="logo text-center">
			<img src="../../../../../assets/images/shield-64.svg" alt="">
			<h1 class="logo-text">Studios Portal</h1>
		</div>
		<div class="col-md-5 centered-form">
			<form>
				<h1 class="acc-act-title">Reset Password</h1>
				<div class=" custom-floating-label">
					<input type="password" class="form-control" id="exampleFormControlInput1" placeholder="Create Password" />
					<label for="exampleFormControlInput1" class="form-label">Create Password</label>
				</div>
				<div class="custom-floating-label">
					<input type="password" class="form-control" id="exampleFormControlInput2" placeholder="Confirm Password" />
					<label for="exampleFormControlInput2" class="form-label">Confirm Password</label>
				</div>
			</form>
			<div class="text-end">
				<button type="button" class="btn btn-primary common-btn-secondary">Save New Password</button>
			</div>
		</div>
	</div>
	<div class="bottom-center-text">
		<p>Privacy Policy | Terms of Use</p>
		<p>Copyright ©2024 Warner Bros Entertainement Inc.</p>
	</div>
</div>