<button data-bs-toggle="modal" data-bs-target="#arPortalModal">AR Team Modal</button>
<button data-bs-toggle="modal" data-bs-target="#creditAppDept">Credit Application Department</button>
<button data-bs-toggle="modal" data-bs-target="#sendRevToCustomers">Send Revisions to Customers</button>
<!-- For Credit Application Department Modal -->
<!-- common modal section-->
<div class="modal fade" id="creditAppDept" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
    <div class="modal-dialog custom-dept-modal">
        <div class="modal-content common-modal-xl">
            <div class="modal-body justify-content-center align-items-center">
                <form class="mx-3">
                    <h3 class="form-section-header custom-margin mb-4 mt-0">
                        Credit Application Departments
                      </h3>
                    <div class="row">
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 custom-floating-label">
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-1"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-1" class="common-checkbox-label">
                            Construction Services
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-2"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-2" class="common-checkbox-label">
                            Facilities Maintenance
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-3"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-3" class="common-checkbox-label">
                            Food Services
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-4"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-4" class="common-checkbox-label">
                            Motion Picture Imaging
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-5"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-5" class="common-checkbox-label">
                            Photo Lab
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-6"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-6" class="common-checkbox-label">
                            Special Events
                          </label>
                        </div>
                        <div class="common-checkbox-button ">
                          <input
                            type="checkbox"
                            id="custom-checkbox-7"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-7" class="common-checkbox-label">
                            Transportation
                          </label>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 custom-floating-label">
                        <div class="common-checkbox-button  mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-8"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-8" class="common-checkbox-label">
                            Costume
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-9"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-9" class="common-checkbox-label">
                            Fire & Security
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-10"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-10" class="common-checkbox-label">
                            Mail Services
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-11"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-11" class="common-checkbox-label">
                            Office & Production
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-12"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-12" class="common-checkbox-label">
                            Post Production Sound
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-13"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-13" class="common-checkbox-label">
                            Staff Shop
                          </label>
                        </div>
                        <div class="common-checkbox-button">
                          <input
                            type="checkbox"
                            id="custom-checkbox-14"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-14" class="common-checkbox-label">
                            Utilities Services
                          </label>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 custom-floating-label">
                        <div class="common-checkbox-button  mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-15"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-15" class="common-checkbox-label">
                            Design Studio
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-16"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-16" class="common-checkbox-label">
                            First Aid
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-17"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-17" class="common-checkbox-label">
                            Metal Shop
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-18"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-18" class="common-checkbox-label">
                            Parking
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-19"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-19" class="common-checkbox-label">
                            Property
                          </label>
                        </div>
                        <div class="common-checkbox-button mb-4">
                          <input
                            type="checkbox"
                            id="custom-checkbox-20"
                            class="common-checkbox-input"
                          />
                          <label for="custom-checkbox-20" class="common-checkbox-label">
                            Studio Operations
                          </label>
                        </div>
                      </div>
                    </div>
                </form>
                <div class="d-flex justify-content-end gap-2 cust-credapp-action-btn">
                    <button type="button" class="btn common-btn-light">Cancel</button>
                    <button type="button" class="btn common-btn-secondary">Start Application</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--common modal section ends-->

<!-- For A/R DB Report Modal  -->
<!-- common modal section-->
<div class="modal fade" id="arPortalModal" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
    <div class="modal-dialog custom-ardb-modal">
        <div class="modal-content common-modal-xl">
            <div class="modal-body justify-content-center align-items-center">
                <form class="mx-3">
                    <h3 class="form-section-header custom-margin mb-4 mt-0">
                        Upload D&B Report
                      </h3>
                    <div class="row row-pdf-section">
                      <div class="col-md-6 col-lg-6 col-sm-6 col-12 custom-floating-label div-report-pdf">
                        <img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon"><span class="pdf-report-name">report.pdf</span>
                      </div>
                      <div class="col-md-6 col-lg-6 col-sm-6 col-12 custom-floating-label div-action-links">
                        <a class="text-link fb-600">Remove</a><a class="text-link fb-600">View</a>
                      </div>
                    </div>
                    <div class="row row-score-section">
                        <div class="col-md-4 col-lg-4 col-sm-4 col-12 custom-floating-label">
                            <label for="exampleFormControlInput1" class="cust-form-label">Deliquency Score:</label>
                            <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="88"/>
                        </div>
                        <div class="col-md-4 col-lg-4 col-sm-4 col-12 custom-floating-label">
                            <label for="exampleFormControlInput1" class="cust-form-label">Failure Score:</label><input type="text" class="form-control" id="exampleFormControlInput1" placeholder="67"/>
                        </div>
                        <div class="col-md-4 col-lg-4 col-sm-4 col-12 custom-floating-label">
                            <label for="exampleFormControlInput1" class="cust-form-label">PAYDEX® Score:</label><input type="text" class="form-control" id="exampleFormControlInput1" placeholder="73"/>
                        </div>
                    </div>
                </form>
                <div class="d-flex justify-content-end gap-2 cust-arteam-action-btn">
                    <button type="button" class="btn common-btn-light">Cancel</button>
                    <button type="button" class="btn common-btn-secondary">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--common modal section ends-->
<!-- For A/R DB Report Modal  -->
<!-- common modal section-->
<div class="modal fade" id="sendRevToCustomers" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
  <div class="modal-dialog send-revision-modal">
      <div class="modal-content common-modal-xl">
          <div class="modal-body justify-content-center align-items-center">
              <form class="mx-3">
                  <h3 class="form-section-header custom-margin mb-4 mt-0">
                    Send Revisions to Customer (1)
                  </h3>
                  <div class="row row-company-section">
                      <div class="col-md-12 col-lg-12 col-sm-12 col-12 custom-floating-label">
                          <label for="exampleFormControlInput1" class="send-revison-label">COMPANY:</label><span class="send-revison-details">A Brand New Prod Company</span>
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-12 custom-floating-label">
                        <label for="exampleFormControlInput1" class="send-revison-label">PROJECT:</label><span class="send-revison-details">The New Show</span>
                    </div>
                  </div>
                  <div class="row row-header-section">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-12 custom-floating-label">
                        <label for="exampleFormControlInput1" class="send-header-label">Text Box Header:</label>
                        <p class="send-body-details">Body copy</p>
                    </div>
                </div>
              </form>
          </div>
          <div class="d-flex gap-2 cust-sendrev-action-btn">
            <button type="button" class="btn common-btn-light">Cancel</button>
            <button type="button" class="btn common-btn-secondary">Send Revisions</button>
        </div>
      </div>
  </div>
</div>
<!--common modal section ends-->
