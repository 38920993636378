<div class="comman-panel-block common-on-lot">
  <div class="panel-heading">
    <h2>On The Lot</h2>
  </div>
  <div class="panel-body">
    <div class="container common-on-lot-body">
      <div class="comman-tabs-block">
        <ul class="nav" role="tablist">
          <li *ngFor="let country of countries; let i = index" class="nav-item">
            <a class="nav-link" [class.active]="i === selectedTabIndex - 1" (click)="selectTab(i)" role="tab"
              aria-current="page" data-bs-toggle="tab" href="#space-resource-tabpanel-{{ i }}"
              aria-selected="i === selectedTabIndex - 1">
              {{ country.onTheLotCountryName }}
            </a>
          </li>
        </ul>
      </div>
   
      <!-- Content Section - Display based on selected tab -->
      <div class="row mt-3" *ngIf="selectedTabIndex === 1">
        <div class="col-12 mb-3">
          <img src="../../../../../assets/images/on-the-lot-image.png" alt="on-the-lot-image" class="img-fluid">
        </div>
        <!-- Burbank Tab Content -->
        <div class="col-12">
          <div class="food-delivery-text">
            <a class="text-link" href="https://food.wb.com/#one" target="_blank">{{onTheLot.cafetria}}</a>
            <span class="food-delivery-value">{{onTheLot.menu}}</span>
          </div>
        </div>
        <div class="col-12">
          <div class="food-delivery-text">
            <a class="text-link" href="https://food.wb.com/#two" target="_blank">{{onTheLot.fine}}</a>
            <span class="food-delivery-value">{{onTheLot.party}}</span>
          </div>
        </div>
        <div class="col-12">
          <div class="food-delivery-text">
            <a class="text-link" href="https://food.wb.com/#three" target="_blank">{{onTheLot.grill}}</a>
            <span class="food-delivery-value">{{onTheLot.orderOnline}}</span>
          </div>
        </div>
        <div class="col-12">
          <div class="food-delivery-text">
            <a class="text-link" href="https://food.wb.com/#four" target="_blank">{{onTheLot.roadRunner}}</a>
            <span class="food-delivery-value">{{onTheLot.orderOnlineMenu}}</span>
          </div>
        </div>
      </div>

      <div class="row mt-3" *ngIf="selectedTabIndex === 2">
        <div class="col-12 mb-3">
          <img src="../../../../../assets/images/leavesden.png" alt="on-the-lot-image" class="img-fluid">
        </div>
        <!-- Leavesden Tab Content -->
        <div class="col-12">
          <div class="food-delivery-text">
            <a class="text-link" href="https://www.wbsl.com/studios/about/tenants-on-site/aramark/" target="_blank">{{onTheLot.aramark}}</a>
            <span class="food-delivery-value">{{onTheLot.foodServices}}</span>
          </div>
        </div>

        <div class="col-12">
          <div class="food-delivery-text">
            <a class="text-link" href="https://www.wbsl.com/studios/about/tenants-on-site/featuremedical/" target="_blank">{{onTheLot.feature}}</a>
            <span class="food-delivery-value">{{onTheLot.medical}}</span>
          </div>
        </div>

        <div class="col-12">
          <div class="food-delivery-text">
            <a class="text-link" href="https://www.wbsl.com/studios/about/tenants-on-site/msl/" target="_blank">{{onTheLot.msl}}</a>
            <span class="food-delivery-value">{{onTheLot.safety}}</span>
          </div>
        </div>

        <div class="col-12">
          <div class="food-delivery-text">
            <a class="text-link" href="https://www.wbsl.com/studios/about/tenants-on-site/solas-mind/" target="_blank">{{onTheLot.solas}}</a>
            <span class="food-delivery-value">{{onTheLot.mentalHealth}}</span>
          </div>
        </div>

        <div class="col-12">
          <div class="food-delivery-text">
            <a class="text-link" href="https://www.wbsl.com/studios/about/tenants-on-site/the-wonderworks/" target="_blank">{{onTheLot.wonderWorks}}</a>
            <span class="food-delivery-value">{{onTheLot.childcare}}</span>
          </div>
        </div>

        <div class="col-12">
          <div class="food-delivery-text">
            <a class="text-link" href="https://www.wbsl.com/studios/about/tenants-on-site/zone-10/" target="_blank">{{onTheLot.zone10}}</a>
            <span class="food-delivery-value">{{onTheLot.fitnessWellbeing}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
