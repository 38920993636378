import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-review-step-three',
  templateUrl: './credit-review-step-three.component.html',
  styleUrls: ['./credit-review-step-three.component.scss']
})
export class CreditReviewStepThreeComponent {

}
