<!-- footer section starts -->
<footer class="bg-light footer">
    <div class="container footer-section">
        <div class="row">
            <!-- Logo Section -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                <div class="logo-wrapper" (click)="navigateOnLogoClick()">
                    <h3 class="text-primary">
                        <img  src="../assets/images/wb-logo-black.svg"
                            class="img-fluid wb-logo">
                        <span class="footer-logo">{{constants.studios_portal}}</span>
                    </h3>
                </div>
            </div>
            <!-- Title and Submenu Sections -->
            <div *ngIf="portalType === 'customer' " class="col-6  col-md-6 col-sm-6 col-lg-2">

                <h5 class="footer-heading">{{constants.portal}}</h5>

                <ul class="list-unstyled footer-subheading-list">

                    <li><a class="text-link"
                            routerLink="/feature/customer-dashboard/overview">{{constants.overview}}</a></li>
                    <li><a class="text-link"
                            routerLink="/feature/customer-dashboard/projects-list">{{constants.projects}}</a></li>
                    <li><a class="text-link"
                            routerLink="/feature/customer-dashboard/requests">{{constants.requests}}</a></li>
                    <li><a class="text-link" routerLink="/feature/customer-dashboard/company">{{constants.company}}</a>
                    </li>
                </ul>
            </div>
            <div *ngIf="portalType === 'wbso' " class="col-6  col-md-6 col-sm-6 col-lg-2">
                <h5 class="footer-heading">{{constants.portal}}</h5>
                <ul class="list-unstyled footer-subheading-list">
                    <li><a class="text-link"
                            routerLink="/feature/studio-dashboard/overview">{{constants.myDashboard}}</a></li>
                    <li><a class="text-link"
                            routerLink="/feature/studio-dashboard/project/project-list">{{constants.projects}}</a></li>
                    <li><a class="text-link" routerLink="/feature/studio-dashboard/users">{{constants.users}}</a></li>
                    <li><a class="text-link"
                            routerLink="/feature/studio-dashboard/company/companies">{{constants.companies}}</a></li>
                    <li><a class="text-link"
                            routerLink="/feature/studio-dashboard/company/creditAplication">{{constants.creditApplications}}</a>
                    </li>
                    <li><a class="text-link" routerLink="/feature/studio-dashboard/requests">{{constants.requests}}</a>
                    </li>


                </ul>
            </div>
            <div *ngIf="portalType === 'ar' " class="col-6  col-md-6 col-sm-6 col-lg-2">
                <h5 class="footer-heading">{{constants.portal}}</h5>
                <ul class="list-unstyled footer-subheading-list">
                    <li><a class="text-link"
                            routerLink="/feature/ar-dashboard/overview">{{constants.creditApplications}}</a></li>
                    <li><a class="text-link"
                            routerLink="/feature/ar-dashboard/project/project-list">{{constants.projects}}</a></li>
                    <li><a class="text-link" routerLink="/feature/ar-dashboard/users">{{constants.users}}</a></li>
                    <li><a class="text-link"
                            routerLink="/feature/ar-dashboard/company/companies">{{constants.companies}}</a></li>
                </ul>
            </div>
            <div class="col-6 col-md-6 col-sm-6 col-lg-2">
                <h5 class="footer-heading">{{constants.client_resources}}</h5>
                <ul class="list-unstyled footer-subheading-list">
                    <li><a class="text-link" routerLink="/core/wbso-handbook">{{constants.handbook}}</a></li>
                    <li><a class="text-link" routerLink="/core/maps">Maps</a></li>
                    <li><a class="text-link" routerLink="/core/core-directory">{{constants.directory}}</a></li>
                    <li *ngIf="isAuthenticated"><a class="text-link" routerLink="/core/faqs">{{constants.faqs}}</a></li>
                </ul>
            </div>
            <!-- <div class="col-6 col-md-2 col-sm-6">
                <h5 class="footer-heading">Important Links:</h5>
                <ul class="list-unstyled footer-subheading-list mb-0">
                    <li><a class="text-link" href="#">Food Services</a> <img src="../assets/images/link-icon.svg"
                            alt="Food services link"></li>
                    <li><a class="text-link" href="#">Safety On The Sets</a><img src="../assets/images/link-icon.svg"
                            alt="Safety on the sets link"></li>
                </ul>
            </div> -->
            <div class="col-6 col-md-6 col-sm-6 col-lg-2">
                <h5 class="footer-heading">{{constants.wb_burbank}} <a class="text-link"
                        href="https://studiooperations.warnerbros.com/" target="_blank"><img
                            src="../assets/images/link-icon.svg" alt="WB Burbank link"></a></h5>
                <ul class="list-unstyled footer-subheading-list mb-0">
                    <li>
                        <p class="" href="#">4000 Warner Blvd.
                            Burbank, CA 91522</p>
                    </li>
                    <li class="align-prop"><a class="text-link" href="#">+1 (810) 954-2577</a></li>
                    <li><a class="text-link" href="https://food.wb.com/"
                            target="_blank">{{constants.food_services}}</a><img src="../assets/images/link-icon.svg"
                            alt="Food Service"></li>
                    <li><a class="text-link" href="https://safetyontheset.com/"
                            target="_blank">{{constants.safety_on_the_set}}</a><img src="../assets/images/link-icon.svg"
                            alt="Safety On the Set"></li>
                </ul>
            </div>
            <div class="col-6 col-md-6 col-sm-6 col-lg-2">
                <h5 class="footer-heading">{{constants.wb_leavesden}} <a class="text-link" href="https://www.wbsl.com/"
                        target="_blank"><img src="../assets/images/link-icon.svg" alt="WB Leavesden link"></a></h5>
                <ul class="list-unstyled footer-subheading-list mb-0">
                    <li>
                        <p class="" href="#">
                            Warner Drive,
                            Leavesden,
                            Hertfordshire,<br />
                            WD25 7LP</p>
                    </li>
                    <li><a class="text-link" href="#">+44 (0) 20 3427 7777</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid footer-sub-section">
        <div class="row">
            <div class="col-lg-6 ">
                <p>{{constants.para_text}}<br />
                    {{constants.paragraph_text}}</p>
            </div>
            <div class="col-lg-6 col-md-12 d-flex justify-content-lg-end justify-content-start align-items-center">
                <span class="me-3"><a href="https://www.wbdprivacy.com/policycenter/b2b/"
                        target="_blank">{{constants.privacy_policy}}</a></span>
                <span><a href="https://policies.warnerbros.com/terms/en-us/html/terms_en-us_1.1.0.html"
                        target="_blank">{{constants.terms_conditions}}</a></span>
            </div>
        </div>
    </div>
</footer>
<!-- footer section ends -->