import { Component } from '@angular/core';

@Component({
  selector: 'app-review-document',
  templateUrl: './review-document.component.html',
  styleUrls: ['./review-document.component.scss']
})
export class ReviewDocumentComponent {

}
