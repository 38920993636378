export const activeProjectData = 
    {
        imgSrc: '../../assets/images/project-image1.png',
        projectId: 1,
        projectType: 'Feature',
        projectName: 'Breaking Bad',
        season: 'Season 1',
        projectDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
        projectStartDate: '1/1/12',
        projectEndDate: '12/12/26',
        status: 'CLOSED',
        createdDate: '02/03/01',
        arNumber: 123456
    }
