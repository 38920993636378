import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectComponent } from './multiselect.component';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { ListFilterPipe } from '../../pipes/list-filter.pipe';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [MultiSelectComponent, ClickOutsideDirective, ListFilterPipe],
  providers: [ListFilterPipe],
  exports: [MultiSelectComponent]
})

export class NgMultiSelectDropDown {
  static forRoot(): ModuleWithProviders<NgMultiSelectDropDown> {
      return {
        ngModule: NgMultiSelectDropDown
      };
    }
}
