<div>
  <button
    class="btn btn-primary"
    (click)="openDeleteConfirmationModal(content)"
  >
    hello
  </button>
</div>
<ng-template #content let-modal>
  <div class="page-wrapper-modal">
    <div class="page-header">
      <h2 class="mb-0">Trade Reference</h2>
    </div>
    <div class="title-company-project">
      <div class="company">
        <span class="company-heading">Company:</span>
        <span class="company-content">A Brand New Production Company</span>
      </div>
      <div class="project">
        <span class="project-heading">Project:</span>
        <span class="project-content">The New Show - Pilot</span>
      </div>
      <div class="name">
        <span class="name-heading">Name:</span>
        <span class="name-content">Payroll Bureau</span>
      </div>
    </div>
    <div class="section-text-area">
      <textarea class="text-area" id="credit-text" name="credit-text">
Dear Vendor,
Attached is a letter from Warner Bros. requesting trades from your customer.
We appreciate your help in this matter.
Please contact WBSFAR@warnerbros.com if there are any questions.
Thanks,
WBSO A/R</textarea
      >
    </div>
    <div class="refrence-letter-section">
      <h3 class="mb-0">Trade Reference Letter:</h3>
      <div class="container cm-main-container">
        <div class="row">
          <div
            class="file-drop-area"
            [ngClass]="{ 'file-over': isFileOver }"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
            (drop)="onDrop($event)"
          >
            <img
              class="folder-icon"
              (click)="fileInput.click()"
              src="../../../../../assets/images/folder-icon.svg"
              alt="folder icon"
            />
            <h3 class="mb-2 drag-n-drop">
              Drag and Drop your file here, or
              <a class="browse-file-link" (click)="fileInput.click()">browse</a>
            </h3>
            <input
              type="file"
              multiple
              class="d-none"
              (change)="onFileSelect($event)"
              #fileInput
            />
          </div>
          <!-- Display list of selected files -->
          <ul
            *ngIf="files.length > 0"
            class="list-group mt-3 uploaded-files-section"
          >
            <li
              *ngFor="let file of files; let i = index"
              class="list-group-item px-0"
            >
              <div class="d-flex align-items-center">
                <div class="col-auto icon-col">
                  <img
                    src="../../../../../assets/images/pdf-icon.svg"
                    alt="pdf icon"
                    class="file-icon"
                  />
                </div>
                <div class="col ps-3 pe-2">
                  <span [title]="file.name" class="file-name">{{
                    file.name
                  }}</span>
                  <!--<ngb-progressbar
                    class="file-progress-bar"
                    type="success"
                    textType="white"
                    [value]="100"
                    [showValue]="false"
                  ></ngb-progressbar>-->
                </div>
                <div class="col-auto close-icon-col px-0">
                  <i class="bi bi-x remove-btn" (click)="removeFile(i)"></i>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="end-wrapper">
    <div class="button">
      <button class="common-btn-light btn">Cancel</button>
      <button class="common-btn-secondary btn">Send Email</button>
    </div>
  </div>
</ng-template>
