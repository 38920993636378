<div>
    <button class="btn btn-primary" (click)="openDeleteConfirmationModal(content)">
      Approve credit application with risk
    </button>
    <button class="btn btn-primary" (click)="openDeleteConfirmationModal1(content1)">
        Approve credit application with risk(Checked)
      </button>
  </div>
  <ng-template #content let-modal>
    <div class="page-wrapper-modal">
      <div class="page-header">
        <h2 class="mb-0">Approve Credit Application with Risk</h2>
      </div>
      <div class="title-company-project">
        <div class="company">
          <span class="company-heading">Company:</span>
          <span class="company-content">A Brand New Prod Company</span>
        </div>
        <div class="project">
          <span class="project-heading">Project:</span>
          <span class="project-content">The New Show</span>
        </div>
      </div>
      <div class="section-text-area">
        <h3 class="section-text">Credit Limit:</h3>
        <div class="credit-input-sec custom-floating-label">
            <label for="lnCreditReq" class="form-label">Credit Limit</label>
            <input type="text" class="form-control" placeholder="$1,000,000.00" id="lnCreditReq" disabled>
            <a class="text-link fb-600">Edit</a>
        </div>
        <h3 class="section-text">Select Reason:</h3>
        <div class="credit-checkbox-sec">
            <p class="sel-reason">FOR INTERNAL USE ONLY</p>
            <div class="common-checkbox-button mb-4">
                <input type="checkbox" id="custom-checkbox-1" class="common-checkbox-input"/>
                <label for="custom-checkbox-1" class="common-checkbox-label dep-check">
                    Departments are accepting risk: <strong> Operations; PPCS; Property; Transportation; Design Studio</strong>
                </label>
            </div>
            <div class="common-checkbox-button mb-4">
                <input type="checkbox" id="custom-checkbox-1" class="common-checkbox-input"/>
                <label for="custom-checkbox-1" class="common-checkbox-label dep-check">
                    Other
                </label>
            </div>
        </div>
      </div>
    </div>
    <div class="end-wrapper">
      <div class="button">
        <button class="common-btn-light btn">Cancel</button>
        <button class="common-btn-secondary btn">Approve</button>
      </div>
    </div>
  </ng-template>


  <ng-template #content1 let-modal>
    <div class="page-wrapper-modal">
      <div class="page-header">
        <h2 class="mb-0">Approve Credit Application with Risk</h2>
      </div>
      <div class="title-company-project">
        <div class="company">
          <span class="company-heading">Company:</span>
          <span class="company-content">A Brand New Prod Company</span>
        </div>
        <div class="project">
          <span class="project-heading">Project:</span>
          <span class="project-content">The New Show</span>
        </div>
      </div>
      <div class="section-text-area">
        <h3 class="section-text">Credit Limit:</h3>
        <div class="credit-input-sec custom-floating-label">
            <label for="lnCreditReq" class="form-label">Credit Limit</label>
            <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="$1,000,000.00" disabled/>
            <a class="text-link fb-600">Edit</a>
        </div>
        <h3 class="section-text">Select Reason:</h3>
        <div class="credit-checkbox-sec custom-floating-label">
            <p class="sel-reason">FOR INTERNAL USE ONLY</p>
            <div class="common-checkbox-button mb-4">
                <input type="checkbox" id="custom-checkbox-1" class="common-checkbox-input" checked/>
                <label for="custom-checkbox-1" class="common-checkbox-label dep-check">
                    Departments are accepting risk: <strong> Operations; PPCS; Property; Transportation; Design Studio</strong>
                </label>
            </div>
            <div class="common-checkbox-button mb-4">
                <input type="checkbox" id="custom-checkbox-1" class="common-checkbox-input" checked/>
                <label for="custom-checkbox-1" class="common-checkbox-label dep-check">
                    Other
                </label>
            </div>
            <div class="col-md-4 custom-floating-label">
                <label for="lnCreditReq" class="form-label">Explain reason</label>
                <input type="text" class="form-control" placeholder="Explain reason" id="lnCreditReq">
            </div>
        </div>
      </div>
    </div>
    <div class="end-wrapper">
      <div class="button">
        <button class="common-btn-light btn">Cancel</button>
        <button class="common-btn-secondary btn">Approve</button>
      </div>
    </div>
  </ng-template>