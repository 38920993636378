import { Component, inject, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-attach-quotes',
  templateUrl: './view-attach-quotes.component.html',
  styleUrls: ['./view-attach-quotes.component.scss']
})
export class ViewAttachQuotesComponent {
  private modalService = inject(NgbModal);
  openFullviewCostume(costume: TemplateRef<any>) {
    this.modalService.open(costume, { windowClass: 'common-modal-xl', fullscreen: true });
  }
  openFullview(burbank: TemplateRef<any>) {
    this.modalService.open(burbank, { windowClass: 'common-modal-xl', fullscreen: true });
  }
  openFullviewHotlock(hotlock: TemplateRef<any>) {
    this.modalService.open(hotlock, { windowClass: 'common-modal-xl', fullscreen: true });
  }
  selectedAction = { text: 'Actions…', img: '' };

  actions = [
    { text: 'Send Revisions Email', img: '../../../../../assets/images/orange-email-icon.svg' },
    { text: 'Send For Approval', img: '../../../../../assets/images/pending-icon.svg' },
    { text: 'Approve', img: '../../../../../assets/images/approve.svg' },
    { text: 'Deny', img: '../../../../../assets/images/approved-email.svg' }
  ];

  updateButtonText(action: { text: string, img: string }) {
    this.selectedAction = action;
}
}

