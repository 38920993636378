<div class="container-fluid page-wrapper">
	<div class="container">
		<div class="row">
			<div class="col-12 page-title">
			<h1 class=" px-0">Maps</h1>
			<div class="d-flex align-items-center view-text ">
				<div>
						<p class="me-3 mb-0 change-weather-title-block">You are viewing the Maps for <span class="change-weather-title">Burbank</span> <span class="change-weather-toggle"> <span>Change</span> <i class="bi bi-geo-alt-fill"></i>
						</span></p>
				</div>
			
		</div></div>
	
			<div class="col-md-4">
				<ol class="handbook-list">
					<li class="active">
						<span class="list-number">1.</span> Main Lot
					</li>
					<li><span class="list-number">2.</span> Ranch </li>
					<li><span class="list-number">3.</span> EV Chargers</li>
					<li><span class="list-number">4.</span> Conference Rooms</li>
				</ol>
			</div>
			<div class="col-md-8">
				<h1 class="page-title no-border-bottom">3.0 Studio Protection Group (SPG)</h1>
				<div class="page-details-section">
					<h2 class="page-sub-heading">Main Lot</h2>
					<ul class="maps-list">
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 1</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 2</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 3</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 4</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 5</a>
						</li>
					</ul>
				</div>
				<div class="page-details-section">
					<h2 class="page-sub-heading">Ranch</h2>
					<ul class="maps-list">
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 1</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 2</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 3</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 4</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 5</a>
						</li>
					</ul>
				</div>
				<div class="page-details-section">
					<h2 class="page-sub-heading">EV Chargers</h2>
					<ul class="maps-list">
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 1</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 2</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 3</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 4</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 5</a>
						</li>
					</ul>
				</div>
				<div class="page-details-section">
					<h2 class="page-sub-heading">Conference Rooms</h2>
					<ul class="maps-list">
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 1</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 2</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 3</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 4</a>
						</li>
						<li>
							<img class="pdf-icon" src="../../../../../assets/images/pdf-icon.svg" alt="PDF icon">
							<a class="pdf-link">Map 5</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>