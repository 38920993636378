import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { INDEX } from '../../enum/shared.enum';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CustomAdapter extends NgbDateAdapter<string> {
  userProfile: any;
  preferredDateFormat: any;
 
constructor(){
  super(); 
  this.changeDateFormat()
}

/**
 * changeDateFormat() - to chnage the date format and comvert according to user preference
 */
  changeDateFormat(): void{
    this.userProfile = JSON.parse(localStorage.getItem('user-profile'));
    const updatedData = JSON.parse(localStorage.getItem("updated-profile"))
    if(updatedData){
      this.preferredDateFormat = updatedData?.userPreferences?.dateFormatSettings?.dateFormatId;
    }
    else{
      this.preferredDateFormat= this.userProfile.userPreferences.dateFormatSettings.dateFormatId;
  }
}

/**
 * fromModel() - to get the date form model and to pass to model
 */
  readonly DELIMITER = '-';
  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = _.split(value, this.DELIMITER);
      if (this.preferredDateFormat === INDEX.one) {
        return {
          month: parseInt(date[0], 10),
          day: parseInt(date[1], 10),
          year: parseInt(date[2], 10),
        };
      }  if (this.preferredDateFormat === INDEX.two) {
        
        return {
          day: parseInt(date[0], 10),
          month: parseInt(date[1], 10),
          year: parseInt(date[2], 10),
        };
      }
    }
    return null;
  }

  /**
   * toModel() - to pass the date to model
   * @param date - the date to pass the model
   * @returns - returns the date which understand by date picker
   */
  toModel(date: NgbDateStruct | null): string | null {
    if (date) {
      if (this.preferredDateFormat === 1) {
        return `${date.month}${this.DELIMITER}${date.day}${this.DELIMITER}${date.year}`;
      } else if (this.preferredDateFormat === 2) {
        return `${date.day}${this.DELIMITER}${date.month}${this.DELIMITER}${date.year}`;
      }
    }
    return null;
  }
}
