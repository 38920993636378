<button data-bs-toggle="modal" data-bs-target="#reviewStep4">Credit Application Review</button>
<!-- common modal section-->
<div class="modal fade" id="reviewStep4" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="false">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center align-items-center">

        <div class="cm-header-section">
            <h5 class="modal-title" id="commonModalLabel">Review Application</h5>
            <p class="cm-subtitle mb-5">Edit or Add any missing information before sending your request to WB Studio Facilities.</p>
             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
              class="cm-close-text">Close</span></button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
              <div class="modal-left-section me-4">
                <h4 class="mt-4">Credit Application</h4>
                <div class="company-meta-data">
                  <p class="title"><strong><span class="label-title">PROJECT</span></strong>: The new show <span></span></p>
                  <p class="title mb-0"><strong><span class="label-title">Departments:</span></strong></p>
                  <p class="pb-0 pt-0 mb-0">Studio Operations; Transportation; Design Studio; Post Production; Creative Services; Property; Production Catering</p>
                </div>             
                <div class="mb-4 mt-3">
                  <div class="list-group list-group-flush mb-2">
                    <ul class="step-list">
                      <li>
                        <span class="step-icon step-check"></span>
                        <span class="step-text"> Application Form</span>
                      </li>
                      <li>
                        <span class="step-icon step-check"></span>
                        <span class="step-text"> Certification of Insurance</span>
                      </li>
                      <li>
                        <span class="step-icon step-check"></span>
                        <span class="step-text">Terms & Conditions</span>
                      </li>
                      <li>
                        <span class="step-icon"></span>
                        <span class="step-text current-step">Review Application</span>
                      </li>
                    </ul>
                  </div>
                  <p class="required-text">(<span class="star-red">*</span>)Required</p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="container cm-main-container px-0 mx-0">
                <div class="container cm-main-container px-0 mx-0">
                    <div class="common-modal-input-section grey-bg review-panel">
                      <h2 class="section-main-header">Credit Application Form</h2>
                      <div class="review-wrapper">
                        <div class="review-inner">
                            <h3 class="form-section-header">Line of credit:</h3>
                            <div class="row mb-4 align-items-center custom-padding">
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                                  <h5 class="title-content">Line of Credit requested:</h5>
                                  <p class="review-values">$1,000,000.00</p>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                                    <h5 class="title-content">Anticipated Job Total:</h5>
                                    <p class="review-values">$750,000.00</p>
                                  </div>
                                  <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                                    <h5 class="title-content">Total # of months:</h5>
                                    <p class="review-values">2</p>
                                  </div>
                            </div>
                            <hr>
                        </div>
                        <div class="review-inner">
                            <h3 class="form-section-header">LETTER OF CREDIT/GUARANTEE:</h3>
                            <div class="row mb-4 align-items-center custom-padding">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                                  <p class="review-values">NA</p>
                                  <p class="review-values">No comments</p>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div class="review-inner">
                            <h3 class="form-section-header">COMPANY:</h3>
                            <div class="row mb-4 align-items-center custom-padding">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                                  <span class="review-values">A Brand New Prod Company</span><br/>
                                  <span class="review-values">2500 Main Street, Los Angeles, CA 92783</span><br/>
                                  <span class="review-values">(818)5678934</span><br/>
                                  <div class="col-md-12">
                                    <label class="custom-checkbox" for="prodComBillingAddress">
                                      <input type="checkbox" id="prodComBillingAddress" checked>
                                      <span class="checkmark"></span> Use for billing address
                                    </label>
                                  </div>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div class="review-inner">
                            <h3 class="form-section-header">Federal tax ID #:</h3>
                            <div class="row mb-4 align-items-center custom-padding">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                                  <p class="review-values">123456</p>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div class="review-inner">
                            <h3 class="form-section-header">ACCOUNTS PAYABLE CONTACT:</h3>
                            <div class="row mb-4 align-items-center custom-padding">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                                  <span class="review-values">Catherine Decker</span><br/>
                                  <span class="review-values">catherine.decker@abrandnewprodcommpany.com</span><br/>
                                  <span class="review-values">(818) 878-9876</span><br/>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div class="review-inner">
                            <h3 class="form-section-header">COMPANY PROFILE:</h3>
                            <div class="row mb-4 align-items-center custom-padding">
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                                  <h5 class="title-content">Profile:</h5>
                                  <p class="review-values">Corporation</p>
                                  <h5 class="title-content">Total Annual Sales:</h5>
                                  <p class="review-values">Corporation</p>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                                    <h5 class="title-content">Started on:</h5>
                                    <p class="review-values">10/20/2016</p>
                                    <h5 class="title-content">Networth:</h5>
                                    <p class="review-values">$400,000.00</p>
                                  </div>
                                  <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                                    <h5 class="title-content">Type of Business:</h5>
                                    <p class="review-values">Production Company</p>
                                    <h5 class="title-content"># of Employees:</h5>
                                    <p class="review-values">27</p>
                                  </div>
                            </div>
                            <hr>
                        </div>
                        <div class="review-inner">
                            <h3 class="form-section-header">Principal officer:</h3>
                            <div class="row mb-4 align-items-center custom-padding">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                                 <div class="profile-office-details">
                                    <span class="review-values">John Doe</span><br/>
                                    <span class="review-values">Title</span>
                                 </div>
                                 <div class="profile-office-details">
                                    <span class="review-values">John Doe</span><br/>
                                    <span class="review-values">Title</span>
                                 </div>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div class="review-inner">
                            <h3 class="form-section-header">Banking reference:</h3>
                            <div class="row mb-4 align-items-center custom-padding">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                                  <span class="review-values">Wells Fargo</span><br/>
                                  <span class="review-values">1234 5678 9012 3456</span><br/>
                                  <span class="review-values">Jane Doe</span><br/>
                                  <span class="review-values">Premier Banker</span><br/>
                                  <span class="review-values">jane.doe@wellsfargo.com</span><br/>
                                  <span class="review-values">(818) 123-4567</span><br/>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div class="review-inner">
                            <h3 class="form-section-header">TRADE referenceS:</h3>
                            <div class="row mb-4 align-items-center custom-padding">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                                 <div class="trade-reference">
                                    <h5 class="title-content">Reference 1:</h5>
                                    <span class="review-values">Payroll Bureau</span><br/>
                                    <span class="review-values">John Doe</span><br/>
                                    <span class="review-values">(818) 123-4567</span><br/>
                                    <span class="review-values">jdoe@domain.com</span><br/>
                                    <span class="review-values">2500 Main Street, Los Angeles, CA 92783</span>
                                 </div>
                                 <div class="trade-reference">
                                    <h5 class="title-content">Reference 2:</h5>
                                    <span class="review-values">Company Name</span><br/>
                                    <span class="review-values">John Doe</span><br/>
                                    <span class="review-values">(818) 123-4567</span><br/>
                                    <span class="review-values">jdoe@domain.com</span><br/>
                                    <span class="review-values">2500 Main Street, Los Angeles, CA 92783</span>
                                 </div>
                                 <div class="trade-reference">
                                    <h5 class="title-content">Reference 3:</h5>
                                    <span class="review-values">Company Name</span><br/>
                                    <span class="review-values">John Doe</span><br/>
                                    <span class="review-values">(818) 123-4567</span><br/>
                                    <span class="review-values">jdoe@domain.com</span><br/>
                                    <span class="review-values">2500 Main Street, Los Angeles, CA 92783</span>
                                 </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="common-modal-input-section grey-bg review-panel">
                    <h2 class="section-main-header">Certificate of Insurance</h2>
                    <div class="review-wrapper">
                      <div class="review-inner">
                          <div class="row mb-4 align-items-center custom-padding">
                              <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                               <div class="insurance-info-details">
                                  <h5 class="title-content">Insurance Information:</h5>
                                  <span class="review-values">Donec ullamcorper nulla non metus auctor fringilla</span><br/>
                               </div>
                               <div class="insurance-info-details">
                                  <h5 class="title-content">Form</h5>
                                  <span class="review-values">COI-signed-pdf (137kb)</span>
                               </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="common-modal-input-section grey-bg review-panel">
                    <h2 class="section-main-header">Terms & Conditions</h2>
                    <div class="review-wrapper">
                      <div class="review-inner">
                          <div class="row mb-4 align-items-center custom-padding">
                              <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                               <div class="terms-condtion">
                                  <span class="review-values">Not Signed</span>
                               </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
            <div class="row" style="background:#E0E0E0;">
              <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
                <p>&nbsp;</p>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
                <div class="container cm-main-container px-0 mx-0">
                    <div class="d-flex gap-2 cust-sendar-action-btn">
                        <div class="left-btn">
                            <a class="text-link"><img class="back-img" src="../../assets/images/blue-left-icon.svg" alt="Back" />back</a>
                        </div>  
                        <div class="right-btn">
                            <button type="button" class="btn common-btn-light">Save & Close</button>
                            <button type="button" class="btn common-btn-secondary">Submit Credit Application</button>
                        </div>
                    </div>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</div>
<!---Added modals on click of send revision-->
<ng-template #company let-modal>
  <div class="modal-body">
    <h2 class="section-main-header">Send Revisions to Customer (1)</h2>
    <p>COMPANY: A Brand New Company</p>
    <p>PROJECT: The new show</p>
    <div class="">
      <h5>Text Box Header:</h5>
      <p>Body copy</p>
    </div>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="btn common-btn-secondary" (click)="modal.close('Close click')">Cancel</button>
      <button type="button" class="btn common-btn-secondary">Send Revision</button>
    </div>
  </div>
</ng-template>