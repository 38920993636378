import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api/base-api.service';
import {  STUDIO_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AddWbUserService {

  constructor(public baseApiService: BaseApiService) { }

  /**
   * addWbUser() - to add a user as wbso user
   * @param user - the data which need to be added
   * @returns - an observable with a successfull message
   */
 addWbUser(user:any) {
    let endpoint = STUDIO_PORTAL_API_PATHS.add_wb_user;
    return this.baseApiService.post(endpoint,user);
  }

  /**
   * addAUser - to add a user as customer user
   * @param user - the data which need to be added
   * @returns - an observable with a successfull message
   */
  addAUser(user: any): Observable<any> {
    let endpoint = STUDIO_PORTAL_API_PATHS.add_customer_user;
    return this.baseApiService.post(endpoint,user);
  }
  editUser(userId: string, user: any): Observable<any> {
    const endpoint = `${STUDIO_PORTAL_API_PATHS.edit_customer_user}/${userId}`;
  return this.baseApiService.put(endpoint, userId)
  }

  editStudioUser(user: any){
    const endpoint = `${STUDIO_PORTAL_API_PATHS.edit_customer_user}`;
    return this.baseApiService.put(endpoint, user)
  }
}


  

