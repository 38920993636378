<div>
  <div
    class="file-drop-area"
    [ngClass]="{ 'file-over': isFileOver }"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
  >
    <img
      class="folder-icon"
      (click)="fileInput.click()"
      src="../../../../../assets/images/folder-icon.svg"
      alt="folder icon"
    />
    <h3 class="mb-2 drag-n-drop">
      Drag and Drop your file here, or
      <a class="browse-file-link" (click)="fileInput.click()">browse</a>
    </h3>
    <input
      type="file"
      [multiple]="!allowOnlySingleFile"
      class="d-none"
      (change)="onFileSelect($event)"
      #fileInput
      accept=".pdf"
    />
  </div>

  <!-- Display list of uploaded files -->
  <ul class="list-group mt-3 uploaded-files-section" *ngIf="files?.length > 0">
    <li *ngFor="let file of files; let i = index" class="list-group-item px-0">
      <div class="">
        <div class="row mx-0">
          <div
            class="col-12 col-md-9 col-sm-9 col-lg-10 d-flex align-items-center gap-2 px-0"
          >
            <div>
              <!-- Display generic file icon -->
              <img
                src="assets/images/pdf-icon.svg"
                alt="file icon"
                class="file-icon"
              />
            </div>
            <div>
              <span [title]="file.name" class="file-name">{{ file.name }}</span>
            </div>
          </div>
          <div class="col-12 col-md-3 col-sm-3 col-lg-2 view-remove">
            <button class="btn btn-link fb-600" (click)="viewFile(file)">
              View
            </button>
            <button class="btn btn-link fb-600" (click)="removeFile($event, i)">
              Remove
            </button>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <div *ngIf="fileSizeErrorMessage" class="alert alert-danger mt-2">
    {{ fileSizeErrorMessage }}
  </div>
  <div *ngIf="multipleFilesErrorMessage" class="alert alert-danger mt-2">
    {{ multipleFilesErrorMessage }}
  </div>
  <div *ngIf="pdfFileErrorMessage" class="alert alert-danger mt-2">
    {{ pdfFileErrorMessage }}
  </div>
</div>
