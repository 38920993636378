import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-upload-coversheet',
  templateUrl: './upload-coversheet.component.html',
  styleUrls: ['./upload-coversheet.component.scss']
})
export class UploadCoversheetComponent {
  constructor(private modalService: NgbModal) {}

  openDeleteConfirmationModal(content: TemplateRef<any>): void {
    this.modalService.open(content, { size: 'lg', centered: true });
  }
}
