import { Component, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-deny-risk-email',
  templateUrl: './deny-risk-email.component.html',
  styleUrls: ['./deny-risk-email.component.scss']
})
export class DenyRiskEmailComponent {
  constructor(private modalService:NgbModal){}

  openDeleteConfirmationModal(content: TemplateRef<any>): void {
    this.modalService.open(content, { size: 'lg' , centered: true });
  }
}
