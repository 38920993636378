import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomepageComponent } from './components/homepage/homepage.component';
import { HandbookMainComponent } from './components/handbook-main/handbook-main.component';
import { HandbookPageComponent } from './components/handbook-page/handbook-page.component';
import { MapsDirectoryProtocolsComponent } from './components/maps-directory-protocols/maps-directory-protocols.component';
import { AccountActivationComponent } from './components/account-activation/account-activation.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AccountCancelledComponent } from './components/account-cancelled/account-cancelled.component';
import { OverviewComponent } from './components/overview/overview.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { RouterModule } from '@angular/router';
import { AccountUserComponent } from './components/account-user/account-user.component';
import { AgGridModule } from 'ag-grid-angular';
import { CommonModalComponent } from './components/common-modal/common-modal.component';
import { UserProjectComponent } from './components/user-project/user-project.component';
import { ProjectDetailComponent } from './components/project-detail/project-detail.component';
import { SpectsStageComponent } from './components/spects-stage/spects-stage.component';
import { CustomerPortalModule } from './customer-portal/customer-portal.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AccountPageComponent } from './components/account-page/account-page.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { AddCustomerUserComponent } from './components/studio-portal/user/add-customer-user/add-customer-user.component';
import { RequestsComponent } from './components/requests/requests.component';
import { NgMultiSelectDropDown } from '../shared/components/ng-multiselect-dropdown/ng-multiselect-dropdown.module';
import { StudioProjectsComponent } from './components/studio-portal/studio-projects/studio-projects.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AddWbUserComponent } from './components/studio-portal/user/add-wb-user/add-wb-user.component';
import { DirectoryComponent } from './components/directory/directory.component';
import { StudioProjectDetailComponent } from './components/studio-portal/studio-project-detail/studio-project-detail.component';
import { UsersComponent } from './components/studio-portal/users/users.component';
import { AddEditCompanyComponent } from './components/studio-portal/add-edit-company/add-edit-company.component';
import { StudioCompanyUserComponent } from './components/studio-portal/studio-company-user/studio-company-user.component';
import { CompanyStudioComponent } from './components/studio-portal/company-studio/company-studio.component';
import { AddEditUserComponent } from './components/studio-portal/add-edit-user/add-edit-user.component';
import { ProjectUserComponent } from './components/studio-portal/project-user/project-user.component';
import { StudioAddCompanyComponent } from './components/studio-portal/studio-add-company/studio-add-company.component';
import { CompaniesComponent } from './components/studio-portal/companies/companies.component';
import { NgbDropdownModule, NgbProgressbarModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { AddArUserComponent } from './components/studio-portal/user/add-ar-user/add-ar-user.component';
import { StudioAddNewProjectComponent } from './components/studio-portal/studio-add-new-project/studio-add-new-project.component';
import { FeatureRoutingModule } from './feature-routing.module';
import { StudioPortalModule } from './studio-portal/studio-portal.module';
import { FaqEditComponent } from './components/faq-edit/faq-edit.component';
import { SharedModule } from '../shared/shared.module';

import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { ToastComponent } from './components/toast/toast.component';
import { ToastService } from './components/toast.service';
import { CreditAppComponent } from './components/studio-portal/credit-app/credit-app.component';
import { CreditGridComponent } from './components/ar-portal/credit-grid/credit-grid.component';
import { CreditAcknowledgementModalComponent } from './components/studio-portal/credit-acknowledgement-modal/credit-acknowledgement-modal.component';
import { HelpSlideoutComponent } from './components/help-slideout/help-slideout.component';
import { SetLightingFormComponent } from './components/set-lighting-form/set-lighting-form.component';
import { StudioCreditAppComponent } from './components/studio-credit-app/studio-credit-app.component';
import { ReviewCreditAppComponent } from './components/ar-portal/review-credit-app/review-credit-app.component';
import { ViewPassesComponent } from './components/view-passes/view-passes.component';
import { CustomerCreditform1Component } from './components/customer-creditform1/customer-creditform1.component';
import { StudioDashboardComponent } from './components/studio-portal/studio-dashboard/studio-dashboard.component';
import { FileUploadComponent } from './components/studio-portal/file-upload/file-upload.component';
import { CredAppFormFourComponent } from './components/cred-app-form-four/cred-app-form-four.component';
import { ReviewCredAppComponent } from './components/ar-portal/review-cred-app/review-cred-app.component';
import { ReviewCredAppLastComponent } from './components/ar-portal/review-cred-app-last/review-cred-app-last.component';
import { CreditReviewStepOneComponent } from './components/ar-portal/credit-review-step-one/credit-review-step-one.component';
import { CreditReviewStepTwoComponent } from './components/ar-portal/credit-review-step-two/credit-review-step-two.component';
import { CreditReviewStepThreeComponent } from './components/ar-portal/credit-review-step-three/credit-review-step-three.component';
import { CreditReviewStepFourComponent } from './components/ar-portal/credit-review-step-four/credit-review-step-four.component';
import { CreditReviewSummaryComponent } from './components/ar-portal/credit-review-summary/credit-review-summary.component';
import { CustomerRequestArformsComponent } from './components/customer-request-arforms/customer-request-arforms.component';
import { CredAppFormOneComponent } from './components/cred-app-form-one/cred-app-form-one.component';
import { CredAppFormTwoComponent } from './components/cred-app-form-two/cred-app-form-two.component';
import { CredAppFormThreeComponent } from './components/cred-app-form-three/cred-app-form-three.component';
import { CredstatusPendingRiskComponent } from './components/ar-portal/credstatus-pending-risk/credstatus-pending-risk.component';
import { CredAppStudioComponent } from './components/studio-portal/cred-app-studio/cred-app-studio.component';
import { ViewAttachQuotesComponent } from './components/studio-portal/view-attach-quotes/view-attach-quotes.component';
import { ReviewDocumentComponent } from './components/ar-portal/review-document/review-document.component';
import { CredFromDepartmentModalComponent } from './components/cred-from-department-modal/cred-from-department-modal.component';
import { RiskEmailComponent } from './components/studio-portal/risk-email/risk-email.component';
import { FinalCreditAppDeniedComponent } from './components/ar-portal/final-credit-app-denied/final-credit-app-denied.component';
import { ApproveDenyPopupComponent } from './components/ar-portal/approve-deny-popup/approve-deny-popup.component';
import { CodInfoComponent } from './components/ar-portal/cod-info/cod-info.component';
import { ApprovalLetterComponent } from './components/ar-portal/approval-letter/approval-letter.component';
import { UploadCoversheetComponent } from './components/ar-portal/upload-coversheet/upload-coversheet.component';
import { AddEditArComponent } from './components/ar-portal/add-edit-ar/add-edit-ar.component';
import { ApproveCredAppWithriskComponent } from './components/ar-portal/approve-cred-app-withrisk/approve-cred-app-withrisk.component';
import { FinalCredFormComponent } from './components/ar-portal/final-cred-form/final-cred-form.component';
import { StudioPendingRiskComponent } from './components/studio-portal/studio-pending-risk/studio-pending-risk.component';
import { StudioEmailTireOneComponent } from './components/studio-portal/studio-email-tire-one/studio-email-tire-one.component';
import { StudioFinalCredAppFormComponent } from './components/studio-portal/studio-final-cred-app-form/studio-final-cred-app-form.component';
import { CoreModule } from '../core/core.module';
import { DeniedCodWithRiskComponent } from './components/ar-portal/denied-cod-with-risk/denied-cod-with-risk.component';
import { ApproveCredReviewAppComponent } from './components/ar-portal/approve-cred-review-app/approve-cred-review-app.component';
import { L2ApproverComponent } from './components/ar-portal/l2-approver/l2-approver.component';
import { DenyRiskEmailComponent } from './components/ar-portal/deny-risk-email/deny-risk-email.component';
import { ThirdPartyCommHouseComponent } from './components/ar-portal/third-party-comm-house/third-party-comm-house.component';


@NgModule({
  declarations: [
    HomepageComponent,
    HandbookMainComponent,
    HandbookPageComponent,
    MapsDirectoryProtocolsComponent,
    AccountActivationComponent,
    ResetPasswordComponent,
    SignInComponent,
    AccountCancelledComponent,
    OverviewComponent,
    ProjectsComponent,
    UserProjectComponent,
    AccountUserComponent,
    CommonModalComponent,
    ProjectDetailComponent,
    SpectsStageComponent,
    AccountPageComponent,
    FaqsComponent,
    AddCustomerUserComponent,
    RequestsComponent,
    StudioProjectsComponent,
    ProfileComponent,
    AddWbUserComponent,
    DirectoryComponent,
    StudioProjectDetailComponent,
    UsersComponent,
    AddEditCompanyComponent,
    StudioCompanyUserComponent,
    CompanyStudioComponent,
    AddEditUserComponent,
    ProjectUserComponent,
    AddArUserComponent,
    StudioAddNewProjectComponent,
    FaqEditComponent,
    CompaniesComponent,
    CompaniesComponent,
    SnackbarComponent,
    ToastComponent,
    CreditAppComponent,
    CreditGridComponent,
    CreditAcknowledgementModalComponent,
    HelpSlideoutComponent,
    SetLightingFormComponent,
    StudioCreditAppComponent,
    ReviewCreditAppComponent,
    ViewPassesComponent,
    CustomerCreditform1Component,
    StudioDashboardComponent,
    FileUploadComponent,
    CredAppFormFourComponent,
    ReviewCredAppComponent,
    ReviewCredAppLastComponent,
    CreditReviewStepOneComponent,
    CreditReviewStepTwoComponent,
    CreditReviewStepThreeComponent,
    CreditReviewStepFourComponent,
    CreditReviewSummaryComponent,
    CustomerRequestArformsComponent,
    CredAppFormOneComponent,
    CredAppFormTwoComponent,
    CredAppFormThreeComponent,
    CredstatusPendingRiskComponent,
    CredAppStudioComponent,
    ViewAttachQuotesComponent,
    ReviewDocumentComponent,
    CredFromDepartmentModalComponent,
    RiskEmailComponent,
    FinalCreditAppDeniedComponent,
    ApproveDenyPopupComponent,
    CodInfoComponent,
    ApprovalLetterComponent,
    UploadCoversheetComponent,
    AddEditArComponent,
    ThirdPartyCommHouseComponent,
    ApproveCredAppWithriskComponent,
    FinalCredFormComponent,
    StudioPendingRiskComponent,
    StudioEmailTireOneComponent,
    StudioFinalCredAppFormComponent,
    DeniedCodWithRiskComponent,
    ApproveCredReviewAppComponent,
    L2ApproverComponent,
    DenyRiskEmailComponent,
  ],
  imports: [CommonModule, FormsModule, RouterModule,StudioPortalModule, CustomerPortalModule, SharedModule,NgMultiSelectDropDownModule.forRoot(), AgGridModule,
    NgMultiSelectDropDown,ReactiveFormsModule,FeatureRoutingModule,NgbToastModule,  NgbDropdownModule, NgbProgressbarModule,CoreModule],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ],
  exports: [ToastComponent,CompaniesComponent],
  providers:[ToastService]

})
export class FeatureModule {}
