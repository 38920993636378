<div class="all-projects-wrapper">
    <div class="container">
        <div class="container px-0">
            <div class="row pb-0 pt-0 px-0 mx-0 all-project-title-block">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 mx-0">
                    <div class="all-project-link-section" routerLink="/feature/studio-dashboard/project/project-list">
                        <img src="../../assets/images/keyboard_arrow_left.svg" alt="keyboard-arrow-left" />
                        <p class="all-project-link-name mb-0">{{ projectDetailsConstants.allProjects }}</p>
                    </div>
                    <div class="all-project-title-section">
                        <p class="all-project-title-name">{{ projectData?.projectName }}
                        </p>
                        <span class="mb-3" class="company-title">
                            <span
                                class="company-label">{{projectDetailsConstants.company}}</span>
                            <span class="company-content">{{projectData?.company?.companyName}}</span>
                        </span>
                    </div>
                </div>
                <div class="project-resource-wrapper wrapper-left col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <div class="all-project-status-widget-block">
                        <div class="card px-0">
                            <div class="row">
                                <div class="col-12 px-0">
                                    <span class="project-status-tag"
                                        [ngClass]="{'active-tag-green': projectData?.projectStatus?.projectStatus === 'Active', 'closed-tag-black': projectData?.projectStatus?.projectStatus === 'Closed', 'not-started-purple': projectData?.projectStatus?.projectStatus === 'Not Started'}">{{
                                        projectData?.projectStatus?.projectStatus }}</span>
                                </div>
                                <div class="col-12 col-sm-3 col-md-3 col-lg-12 project-info-section">
                                    <div class="project-info">
                                        <div class="project-info-inner">
                                            <span>{{ projectDetailsConstants.projectId }}</span><span
                                                class="project-value">{{projectData?.projectId }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-8 col-md-4 col-lg-12 project-info-section">
                                    <div class="project-info">
                                        <div class="project-info-inner project-type-value">
                                        <span>{{ projectDetailsConstants.type }}</span>
                                            <span class="project-value" title="{{ projectData?.projectTypeDetails?.projectType }}">{{ projectData?.projectTypeDetails?.projectType }}</span>
                                            <span *ngIf="projectData?.projectTypeDetails?.projectType == 'Television'" (click)="openAdditionalProjectDetailsModal(additionalProjectDetailsModal)" class="clickable">
                                                <img src="../../assets/images/insert drivefile.svg" class=""
                                                    alt="insert drive" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12  col-sm-5 col-md-5 col-lg-12  project-info-section">
                                    <div class="project-info mb-0">
                                        <div class="project-info-inner d-flex align-items-center gap-2">
                                            <span>{{ projectDetailsConstants.dates }}</span>
                                            <span class="padding-left project-value">{{
                                                projectData?.projectStartDate }} -
                                                {{ projectData?.projectEndDate }}</span>
                                            <span (click)="openScheduledNoteModal(scheduledNoteModal)"
                                                class="clickable date-icon-m-left"><img src="../../assets/images/insert drivefile.svg"
                                                    class="" alt="insert drive"></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-4 col-md-4 col-lg-12 project-info-section">
                                    <div class="project-info">
                                        <div class="project-info-inner">
                                            <span>{{ projectDetailsConstants.customer }}</span><span
                                                class="project-value">{{projectData?.customerType?.customerType}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4 col-lg-12 col-lg-12 project-info-section">
                                    <div class="project-info">
                                        <div class="project-info-inner">
                                            <span>{{ projectDetailsConstants.primaryPayment }}</span><span
                                                class="project-value">{{projectData?.primaryPaymentType?.paymentType}}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-4 col-md-3 col-lg-12 project-info-section ">
                                    <div class="project-info mb-0 border-0">
                                        <div class="project-info-inner">
                                            <span>{{ projectDetailsConstants.ar }}</span>
                                            <span class="project-value">{{displayListOfArNumber}}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="card all-project-season-block">
                        <div class="container px-0 mx-0">
                            <div class="row mx-0">
                                <div
                                    class="all-project-season-image-section col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12">
                                    <div class="image-wrapper">
                                        <img
                                            [src]="projectData?.imageUrl"
                                            class="all-project-season-image img-fluid"
                                            alt="project-image"
                                            onerror="this.src='../../assets/images/project-image1.png'"
                                          />
                                        <app-image-dropdown
                                            class="image-dropdown"
                                            [fileInput]="fileInput"
                                            (uploadPhoto)="triggerFileUpload(fileInput)"
                                            (resetPhoto)="handleResetPhoto()"
                                        ></app-image-dropdown>
                                         
                                          <!-- Hidden file input -->
                                        <input
                                            type="file"
                                            #fileInput
                                            accept="image/*"
                                            style="display: none;"
                                            (change)="onFileSelected($event)"
                                          />
                                        </div>

                                </div>
                                <div class="card-body col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12">
                                    <div class="all-project-season-desc-section">
                                        <h6 class="all-project-season-subtitle">{{ projectDetailsConstants.description
                                            }} :
                                        </h6>
                                        <p class="all-project-season-desc ">{{ projectData?.projectDesc }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3" *ngIf="!isBookmarkAdded"><a class="text-link edit-project-info clickable"
                            (click)="addBookmark()"> <img src="../../assets/images/action-bookmark.svg" class="edit-img"
                                alt="bookmark project"> {{editConstants.bookmarkProject}}</a>
                    </div>
                    <div class="mb-3" *ngIf="isBookmarkAdded"><a class="text-link edit-project-info clickable"
                            (click)="removeBookmark()"> <img src="../../assets/images/active-bookmark.svg"
                                class="edit-img" alt="bookmark project"> {{editConstants.removeBookmark}}</a>
                    </div>
                    <div class="mb-3"><a class="text-link edit-project-info clickable"
                            *ngIf="permissionsData['editProject']"
                            (click)="openEditProjectStatusModal(editProjectStatusModal)"> <img
                                src="../../assets/images/edit-icon.svg" class="edit-img" alt="edit project status">
                            {{editConstants.editProjectStatus}}</a>
                    </div>

                    <div class="mb-4"><a (click)="openEditProjectInfoModal()"
                            class="text-link edit-project-info clickable"> <img src="../../assets/images/edit-icon.svg"
                                class="" alt="edit project-info"> {{editConstants.editProjectInformation}}</a>
                    </div>
                </div>
                <div class="project-resource-wrapper wrapper-right col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 ">
                    <div class="comman-tabs-block">
                        <ul class="nav" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#credit-tabpanel-1" role="tab"
                                    aria-controls="fill-tabpanel-1" aria-disabled="true">{{
                                    creditApplicationConstants.creditApplications }}
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="permissionsData['userTab']">
                                <a class="nav-link " data-bs-toggle="tab" href="#project-users-tabpanel-2" role="tab"
                                    aria-controls="fill-tabpanel-1">{{ projectUsersConstants.projectUsers }}</a>
                            </li>
                            <li class="nav-item" *ngIf="permissionsData['mySpace']">
                                <a class="nav-link" aria-current="page" data-bs-toggle="tab"
                                    href="#space-resource-tabpanel-0" role="tab" aria-selected="true">{{
                                    spacesAndResources.spaces }}</a>
                            </li>
                        </ul>
                    </div>
                    <!--space resource tab-->
                    <div class="tab-content" id="tab-content">

                        <div class="tab-pane" id="space-resource-tabpanel-0" role="tabpanel"
                            aria-labelledby="fill-tab-0">
                            <div class="space-project-tab">
                                <div class="container px-0 mx-0">
                                    <div class="all-projects-spaces-resources-title">
                                        <h2 class="mb-0">{{ spacesAndResources.mySpaces }}</h2>
                                    </div>
                                    <!-- STAGES -->
                                    <div class="row  projects-spaces-resources-block">
                                        <div class="col-12  projects-spaces-resources-subtitle ">{{
                                            spacesAndResources.stages }}</div>
                                        <!-- Loop over each stage -->
                                        <div *ngFor="let stage of stagesData"
                                            class="row projects-spaces-resources-block p-0">
                                            <div
                                                class="col-lg-2 col-md-2 col-sm-4 col-4 project-spaces-resource-stage-name">
                                                <div>
                                                    <p class="pb-0 mb-0">{{ stage.stageName }}</p>
                                                </div>
                                            </div>
                                            <div
                                                class="col-lg-10 col-md-10 col-sm-8 col-8 project-spaces-resource-stages-block">
                                                <!-- Loop over each group of documents -->
                                                <div class="col-12">
                                                    <div *ngFor="let group of
                                                 stage.documentGroups" class="col-6 leftalign">
                                                        <!-- Loop over each document -->
                                                        <div class="d-flex flex-revert">
                                                            <div
                                                                class="d-flex justify-content-between flex-md-column flex-column flex-lg-row flex-md-row flex-sm-column flex-change">
                                                                <div *ngFor="let document of group.documents"
                                                                    class="bookmark-image-wrapper">
                                                                    <!-- <img class="bookmark-icon"
                                                                    [src]="document.bookmarked ? '../../assets/images/active-bookmark.svg' : '../../assets/images/bookmark.svg'"> -->
                                                                    {{ document.name }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    <!-- Offices section-->
                                    <div class="row projects-spaces-resources-block">
                                        <div class="col-12 projects-spaces-resources-subtitle">{{
                                            spacesAndResources.offices
                                            }}</div>
                                        <div *ngFor="let office of officesData"
                                            class="row projects-spaces-resources-block p-0">
                                            <div
                                                class="col-lg-2 col-md-2 col-sm-4 col-4 project-spaces-resource-stage-name">
                                                <div>
                                                    <p class="pb-0 mb-0">{{ office.stageName }}</p>
                                                </div>
                                            </div>
                                            <div
                                                class="col-lg-10 col-md-10 col-sm-8 col-8 project-spaces-resource-stages-block">
                                                <!-- Loop over each group of documents -->
                                                <div class="col-12">
                                                    <div *ngFor="let group of office.documentGroups"
                                                        class="col-6 leftalign">
                                                        <!-- Loop over each document -->
                                                        <div class="d-flex flex-revert">
                                                            <div
                                                                class="d-flex justify-content-between flex-md-column flex-column flex-lg-row flex-md-row flex-sm-column flex-change">
                                                                <div *ngFor="let document of group.documents"
                                                                    class="bookmark-image-wrapper">
                                                                    <!-- <img class="bookmark-icon"
                                                                    [src]="document.bookmarked ? '../../assets/images/active-bookmark.svg' : '../../assets/images/bookmark.svg'"> -->
                                                                    {{ document.name }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    <!-- Workshops -->
                                    <div class="row projects-spaces-resources-block">
                                        <div class="col-12  projects-spaces-resources-subtitle ">{{
                                            spacesAndResources.workshops }}</div>
                                    </div>
                                    <div *ngFor="let workshop of workshopsData"
                                        class="row projects-spaces-resources-block py-0">
                                        <div
                                            class="col-lg-2 col-md-2 col-sm-4 col-4 project-spaces-resource-stage-name">
                                            <div>
                                                <p class="pb-0 mb-0">{{ workshop.stageName }}</p>
                                            </div>
                                        </div>
                                        <div
                                            class="col-lg-10 col-md-10 col-sm-8 col-8 project-spaces-resource-stages-block">
                                            <!-- Loop over each group of documents -->
                                            <div class="col-12">
                                                <div *ngFor="let group of workshop.documentGroups"
                                                    class="col-6 leftalign">
                                                    <!-- Loop over each document -->
                                                    <div class="d-flex flex-revert">
                                                        <div
                                                            class="d-flex justify-content-between flex-md-column flex-column flex-lg-row flex-md-row flex-sm-column flex-change">
                                                            <div *ngFor="let document of group.documents"
                                                                class="bookmark-image-wrapper">
                                                                <!-- <img class="bookmark-icon"
                                                                [src]="document.bookmarked ? '../../assets/images/active-bookmark.svg' : '../../assets/images/bookmark.svg'"> -->
                                                                {{ document.name }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!---credit Application tab-->
                        <div class="tab-pane credit-app-tab active" id="credit-tabpanel-1" role="tabpane-l"
                            aria-labelledby="fill-tab-1">
                            <div class="container px-0 mx-0">
                                <div class="all-projects-spaces-resources-title">
                                    <h2>{{creditApplication?.creditApplication}}
                                       <span *ngIf="creditApplicationData"> ({{creditApplicationData?.length}})</span></h2>
                                </div>
                                <div class="row all-projects-credit-AR-section" *ngFor="let item of creditApplicationData; let last = last">
                                    <div class="col-12 all-projects-credit-app-subtitle">
                                        <div class="subtitle-block">
                                            <div class="ar-id">{{creditApplication?.arName}} # <span
                                                    class="ar-id-no">{{item?.arName ? item?.arName : '---'}}</span></div>
                                            <button class="common-btn-sent btn">
                                                <img class="common-btn-primary-icon" src="../../assets/images/send.svg"
                                                    alt="add" />
                                                <span>{{creditApplication?.sentToCustomer}}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="start-credit-app-btn">
                                        <div class="attached-quotes">
                                            <a class="quotes-text text-link fb-600" *ngIf="item?.caNumber"
                                                (click)="openViewQuotesModal(item?.caSeqId,item?.caNumber)">{{creditApplication?.viewQuotes}}</a>
                                            &nbsp;
                                            <a class="quotes-text text-link fb-600"
                                                (click)="openAttachQuotesModal()">{{creditApplication?.attachQuotes}}</a>
                                        </div>
                                    </div>
                                    <div class="project-credit-app-costume-block ">
                                        <ul *ngFor="let deptData of item?.caDepartments">
                                            <li class="project-credit-app-dept">{{deptData?.departments?.departmentName}}</li>
                                        </ul>
                                    </div>
                                    <hr  *ngIf="!last">
                                </div>
                                <hr class="custom-hr"/>
                            </div>
                            <div class="all-projects-credit-AR-section">
                                <div class="add-credit-add " (click)="openSendCreditApplicationModal()"><span
                                        class="text-link">+ {{creditApplication?.addCreditApplication}}</span></div>
                            </div>
                        </div>

                        <!---Project user tab-->
                        <div class="tab-pane project-user-tab" id="project-users-tabpanel-2" role="tabpane-2"
                            aria-labelledby="fill-tab-1">
                            <div class="container px-0 mx-0">
                                <div class="all-projects-spaces-resources-title ">
                                    <h2>{{ projectUsersConstants.projectUsers }}</h2>
                                    <div>
                                        <a class="text-link fb-600"
                                            routerLink="/feature/studio-dashboard/project/view-users"
                                            (click)="navigateToViewAllUsers()">{{
                                            projectUsersConstants.viewAll }}</a>
                                        <button class="common-btn-primary btn custom-add-btn"
                                            *ngIf="permissionsData['addCustomerUser']"
                                            (click)="openAddUserModal(projectData?.projectId, projectData?.company?.companyId)">
                                            <img class="common-btn-primary-icon" src="../../assets/images/add.svg"
                                                alt="add" />
                                            <span class="common-btn-primary-text-sm">{{ projectUsersConstants.addUser
                                                }}</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="row mx-0 px-0">
                                    <div class="progress_container d-flex justify-content-center">
                                        <!-- circular progress bar -->
                                        <div class="pass-status-widgets-container d-flex">
                                            <ng-container *ngFor="let user of projectUsersCount; let i = index">
                                                <!-- Each widget -->
                                                <div class="pass-status-widgets" [ngClass]="{
                                                  'widget-blue': user.label === userListConstants.all,
                                                  'widget-green': user.label === userListConstants.active,
                                                  'widget-grey': user.label === userListConstants.deactivated,
                                                  'widget-darkgrey': user.label === userListConstants.admin
                                                }" (click)="openViewAllUsers(user)">
                                                    <div class="pass-status-widgets-values">{{ user.usersCount }}</div>
                                                    <div class="pass-status-widgets-labels">{{ user.label }}</div>
                                                </div>
                                                <!-- Conditionally render the arrow only if not the last widget -->
                                                <div *ngIf="i < projectUsersCount?.length - 1"
                                                    class="progress_container_arrow">
                                                    <img alt="keyboard-right-arrow"
                                                        src="../../../../assets/images/keyboard-arrow-right.svg" />
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="project-user-section">
                                        <div class="project-users-title">{{ projectUsersConstants.users }}:</div>
                                        <div class="project-users-list">
                                            <span *ngFor="let user of getSortedUsersList(); let i = index">
                                                {{ user }}<span *ngIf="i < projectUsersData?.length - 1">;</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #addNewProjectModal let-modal>
    <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
            <h5 class="modal-title mb-3" id="commonModalLabel">{{ editConstants.addNewProject }}</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span class="cm-close-text">{{ editConstants.close }}</span>
            </button>
        </div>
        <div class="container cm-main-container">
        </div>
    </div>
</ng-template>

<!---Modals on opening left section icons-->

<div class="modal fade" id="additional-project-information" tabindex="-1" aria-labelledby="existingCompanyModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered common-modal-center">
        <div class="modal-content">
            <div class="modal-body">
                <h2 class="section-main-header">Search </h2>
                <div class="">
                    <div class="mt-56 custom-floating-label">
                        <label for="input1" class="form-label">Search</label>
                        <input type="text" class="pe-4 form-control" placeholder="Search company name or company ID #"
                            id="input2">
                        <img class="search-icon" src="../../../../../../assets/images/search-icon.svg"
                            alt="Search Icon">
                    </div>
                </div>
                <div class="d-flex justify-content-end gap-2">
                    <button type="btn" class="btn common-btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn common-btn-secondary ">Select</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!---Added three modals on click of date Edit status and edit project information-->
<ng-template #additionalProjectDetailsModal let-modal>
    <div class="modal-body">
        <h2 class="section-main-header">{{projectModalContent.additionalProjectDetails}} </h2>
        <ng-container *ngIf="!isEditAdditional">
            <div>
                <label class="mb-3">{{projectModalContent.televisionProjectCameraSetup}}</label>
                <p>{{projectData?.projectCameraSetup?.projectCameraSetup}}</p>
                <p class="text-center" *ngIf="!projectData?.projectCameraSetup?.projectCameraSetup">
                    {{projectModalContent.noData}}</p>
            </div>
            <div>
                <label class="mb-3">{{projectModalContent.programmingSchedule}}</label>
                <p>{{projectData?.programmingSchedule?.programingSchedule}}</p>
                <p class="text-center" *ngIf="!projectData?.programmingSchedule?.programingSchedule">
                    {{projectModalContent.noData}}</p>
            </div>
            <div class="d-flex justify-content-end gap-2">
                <button type="button" class="btn common-btn-secondary"
                    (click)="editAdditionalModal()">{{editConstants.modalEdit}}</button>
                <button type="button" class="btn common-btn-secondary"
                    (click)="modal.close('Close click')">{{editConstants.modalOk}}</button>
            </div>
        </ng-container>
        <ng-container *ngIf="isEditAdditional">
            <form [formGroup]="additionalForm">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                            <div class="container cm-main-container px-0 mx-0">
                                <div class="common-modal-input-section">
                                    <!-- <form> -->
                                    <div class="row">
                                        <div>
                                            <label
                                                class="mb-3">{{projectModalContent.televisionProjectCameraSetup}}</label>
                                        </div>
                                        <ng-container *ngFor="let camera of projectProjectCameraDetails">
                                            <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mb-4">
                                                <div class="common-radio-btn">
                                                    <input class="common-radio-input mb-0" type="radio"
                                                        name="cameraSetup" [id]="camera.projectCameraSetup"
                                                        [value]="camera.projectCameraSetup"
                                                        formControlName="cameraSetup" />
                                                    <label class="common-radio-label" [for]="camera.projectCameraSetup">
                                                        {{camera.projectCameraSetup}}
                                                    </label>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <div *ngIf="submitted && additionalForm.get('cameraSetup').invalid"
                                            class="ml-0">
                                            <p class="text-danger">{{message.required}}</p>
                                        </div>
                                        <div>
                                            <label class="mt-3 mb-3">{{projectModalContent.programmingSchedule}}</label>
                                        </div>
                                        <ng-container *ngFor="let program of projectProgrammingScheduleDetails">
                                            <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0">
                                                <div class="common-radio-btn">
                                                    <input class="common-radio-input mb-0" type="radio"
                                                        name="programSchedule" [id]="program.programingSchedule"
                                                        [value]="program.programingSchedule"
                                                        formControlName="programSchedule" />
                                                    <label class="common-radio-label"
                                                        [for]="program.programingSchedule">
                                                        {{program.programingSchedule}}
                                                    </label>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0">
                                            <div class="custom-dropdown disabled px-0 w-100">
                                                <select class="custom-select disabled"
                                                    formControlName="programScheduleSeason"
                                                    [ngClass]="{ 'no-pointer-events': additionalForm?.controls['programSchedule'].value !== 'Series/Multi Episodes' ,'disabled':additionalForm?.controls['programSchedule'].value !== 'Series/Multi Episodes'}">
                                                    <option class="dropdown-option" value="" disabled selected hidden>
                                                        {{projectDetailsText.season}}</option>
                                                    <option class="dropdown-option"
                                                        [disabled]="additionalForm?.controls['programSchedule'].value !== 'Series/Multi Episodes'"
                                                        *ngFor="let option of seasons" [value]="option.season">
                                                        {{option.season}}</option>
                                                </select>
                                            </div>
                                            <ng-container
                                                *ngIf="additionalForm?.controls['programSchedule'].value == 'Series/Multi Episodes' && additionalForm?.controls['programScheduleSeason'].value == 'Other'">
                                                <div class="col-md-5 custom-floating-label mt-4 w-100">
                                                    <label for="input1"
                                                        class="form-label">{{projectDetailsText.seasonNumber}}</label>
                                                    <input type="text" class="form-control" placeholder="Season Number"
                                                        formControlName="seasonOther" id="input1">
                                                    <div
                                                        *ngIf="submitted && additionalForm?.controls['programScheduleSeason'].value == 'Other' && additionalForm.get('seasonOther').hasError('required')">
                                                        <p class="text-danger">{{message.required}}</p>
                                                    </div>
                                                    <div
                                                        *ngIf="!additionalForm.get('seasonOther').hasError('required') && additionalForm.get('seasonOther')?.hasError('pattern') || !additionalForm.get('seasonOther').hasError('required') && additionalForm.get('seasonOther')?.hasError('range')">
                                                        <p class="text-danger">{{errorMsg.numbersAllowed}}</p>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div
                                                *ngIf="submitted && additionalForm?.controls['programSchedule'].value == 'Series/Multi Episodes' && additionalForm.get('programScheduleSeason').invalid">
                                                <p class="text-danger">{{message.required}}</p>
                                            </div>
                                        </div>
                                        <div *ngIf="submitted && additionalForm.get('programSchedule').invalid"
                                            class="ml-0">
                                            <p class="text-danger">{{message.required}}</p>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end gap-2 mt-4">
                                        <button type="button" class="btn common-btn-light" data-bs-dismiss="modal"
                                            (click)="resetAdditionalModal();modal.close('Close click')">{{projectText.cancel}}</button>
                                        <button type="button" class="btn common-btn-secondary" data-bs-dismiss="modal"
                                            (click)="editCameraAndProgramming()"
                                            [disabled]="additionalDisabled">{{projectText.saveChanges}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
</ng-template>
<ng-template #editProjectStatusModal let-modal>
    <div class="modal-body">
        <h2 class="section-main-header">{{projectModalContent.editProjectStatus}}</h2>
        <form class="mx-3" [formGroup]="editStatusForm">
            <div class="row">
                <div class="col-md-12 mb-3 px-0 ">
                    <div class="common-radio-btn">
                        <input class="common-radio-input mb-0" type="radio" formControlName="projectStatus"
                            name="projectStatus" id="not" [value]="editConstants.notStarted" />
                        <label class="common-radio-label" for="not">
                            <span class="project-status-tag not-started-purple">{{editConstants.notStarted}}</span>
                        </label>
                    </div>
                </div>
                <div class="col-md-12 mb-3  px-0 ">
                    <div class="common-radio-btn">
                        <input class="common-radio-input mb-0" type="radio" formControlName="projectStatus"
                            name="projectStatus" id="active" [value]="editConstants.active" />
                        <label class="common-radio-label" for="active">
                            <span class="project-status-tag  active-tag-green">{{editConstants.active}}</span>
                        </label>
                    </div>
                </div>
                <div class="col-md-12 mb-3  px-0 ">
                    <div class="common-radio-btn">
                        <input class="common-radio-input mb-0" type="radio" formControlName="projectStatus"
                            name="projectStatus" id="closed" [value]="editConstants.closed" />
                        <label class="common-radio-label" for="closed">
                            <span class="project-status-tag  closed-tag-black">{{editConstants.closed}}</span>
                        </label>
                    </div>
                </div>
            </div>
        </form>
        <div class="d-flex justify-content-end gap-2">
            <button type="button" class="btn common-btn-light"
                (click)="modal.close('Close click')">{{editConstants.cancel}}</button>
            <button type="button" class="btn common-btn-secondary"
                (click)="editProjectStatus();modal.close('Close click')"
                [disabled]="statusDisabled">{{editConstants.saveStatus}}</button>
        </div>
    </div>
</ng-template>
<ng-template #scheduledNoteModal let-modal>
    <div class="modal-body">
        <h2 class="section-main-header">{{projectModalContent.scheduleNotes}}</h2>
        <div *ngIf="!isEditNotes">
            <p>{{projectData?.additionalScheduleNotes}}</p>
        </div>
        <ng-container *ngIf="isEditNotes">
            <form [formGroup]="notesForm">
                <div class="col-md-12">
                    <div class=" custom-floating-label mt-3">
                        <label for="input1" class="form-label">{{projectDetailsText.scdeduledNotes}}</label>
                        <textarea class="form-control form-control-lg common-text-area" id="project Description"
                            rows="4" formControlName="additionalNotes"></textarea>
                        <div *ngIf="submitted && notesForm.get('additionalNotes').invalid">
                            <p class="text-danger">{{message.required}}</p>
                        </div>
                    </div>
                </div>
            </form>
        </ng-container>
        <div class="d-flex justify-content-end gap-2" *ngIf="!isEditNotes">
            <button type="button" class="btn common-btn-light"
                (click)="editNotesModal()">{{editConstants.modalEdit}}</button>
            <button type="button" class="btn common-btn-secondary"
                (click)="modal.close('Close click')">{{editConstants.modalOk}}</button>
        </div>
        <div class="d-flex justify-content-end gap-2" *ngIf="isEditNotes">
            <button type="button" class="btn common-btn-light"
                (click)="resetNotesModal();modal.close('Close click')">{{editConstants.cancel}}</button>
            <button type="button" class="btn common-btn-secondary"
                (click)="saveScheduleNotes();modal.close('Close click')">{{projectText.saveChanges}}</button>
        </div>
    </div>
</ng-template>
<ng-template #successTpl>
    <div class="success">{{requestResponse?.message}}</div>
</ng-template>
<ng-template #successMsgTpl>
    <div class="success">{{successResponse}}</div>
</ng-template>
<ng-template #dangerTpl>
    <div class="danger">{{requestResponse}}</div>
</ng-template>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>