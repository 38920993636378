import { Component, OnInit } from '@angular/core';
import { OktaAuthStateService } from '@okta/okta-angular';
import { AuthState } from '@okta/okta-auth-js';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from './shared/services/loader/loader.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showHeaderFooter = true;
  public isAuthenticated$!: Observable<boolean>;
  fromDate: NgbDate;
  toDate: NgbDate;
  loading: boolean = false;
  overlayState: boolean = false;
  errorMessage: string = '';
  successMessage: string = '';

  constructor(
    private router: Router,
    private _oktaStateService: OktaAuthStateService,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      window.scrollTo(0, 0);
    });
  }

  ngOnInit() {


    // this.route.queryParams.subscribe(params => {
    //   const token = 'AQICAHj52qDuKo9X8PL8ENnTFx0fZP1Y7CjgI5lu9+ArjJL1dQE9OLaXPwITTw5ECjHAIOHfAAAAjDCBiQYJKoZIhvcNAQcGoHwwegIBADB1BgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDBL+WrxazDLp+Y5LnQIBEIBIdR0nh7FzuQaIbaO89s4y/d9XUaa2dWIG9qEQHkkjjfD4IwEzsEaIcoEPWdPqVjXrnquBhXAHbx4bJz5DogOL7JOINtX5QBJB';
    //   const actionType = 'DownloadFullForm'; // Try both parameter name
      // const token = params['token'];
      // const actionType = params['actionType'];
      // let token = this.route.snapshot.queryParams['token'];
      // let action = this.route.snapshot.queryParams['action'];
    

    //   if (token && actionType) {
    //     this.showMessage(`Received parameters - Token: ${token.substring(0, 10)}... ActionType: ${actionType}`, true);
    //     this.handleEmailAction(token, actionType);
    //   } else {
    //     this.showMessage('No token or actionType found in URL', false);
    //   }
    //   return ;

    // });

    // Regular authentication and routing logic
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeaderFooter = !event.urlAfterRedirects.startsWith('/acc');
      }
    });

    this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState),
      map((authState: AuthState) => authState.isAuthenticated ?? false)
    );

    this.loaderService.loading$.subscribe((loading) => {
      this.loading = loading;
    });

    this.loaderService.overlayState$.subscribe((applying) => {
      this.overlayState = applying;
    });
  }

  // private handleEmailAction(token: string, actionType: string): void {
  //   const requestBody = {
  //     token: token,
  //     actionType: actionType
  //   };

  //       const headers = new HttpHeaders({
  //         'Content-Type': 'application/json',
  //       });
    
    
  //   

  //   // Changed responseType to 'json' instead of 'blob'
  //   this.http.post('https://greenlight-api-dev.wbd.com/ar-portal/studio/ca/riskemail/download', requestBody,{headers})
  //     .subscribe({
  //       next: (response: any) => {
  //         

  //         if (
  //           actionType.toLowerCase() === 'downloadfullform') {
  //           this.downloadBase64File(response.base64Data,
  //             response.fileName || 'full-form.pdf');
  //         }
  //         this.redirectToBackend(token, actionType);
  //       },
  //       error: (error) => {
  //         console.error('Error performing action:', error);
  //         this.showMessage(`Error: ${error.message}`, false);
  //       }
  //     });
  // }


  // private downloadBase64File(base64Data: string, fileName: string): void {
  //   try {
  //     // Remove data URI prefix if present
  //     const base64Content = base64Data.replace(/^data:.*,/, '');

  //     // Convert base64 to blob
  //     const byteCharacters = atob(base64Content);
  //     const byteNumbers = new Array(byteCharacters.length);

  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }

  //     const byteArray = new Uint8Array(byteNumbers);
  //     const blob = new Blob([byteArray], { type: 'application/pdf' });

  //     // Create download link
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = fileName;
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);

  //     this.showMessage('File downloaded successfully', true);
  //   } catch (error) {
  //     console.error('Error downloading file:', error);
  //     this.showMessage(`Error downloading file: ${error}`, false);
  //   }
  // }

  // // Helper function to validate base64 string
  // private isValidBase64(str: string): boolean {
  //   try {
  //     return btoa(atob(str)) === str;
  //   } catch (err) {
  //     return false;
  //   }
  // }

  // private redirectToBackend(token: string, actionType: string): void {
  //   try {
  //     const backendUrl = `https://greenlight-dev.wbd.com/riskmailtier2?token=${encodeURIComponent(token)}&actionType=${encodeURIComponent(actionType)}`;
  //     alert(`Redirecting to: ${backendUrl}`); // Debugging alert
  //     setTimeout(() => {
  //       window.location.href = backendUrl;
  //     }, 2000);
  //   } catch (error) {
  //     alert(`Error in redirect: ${error}`); // Debugging alert
  //   }
  // }

  // private showMessage(message: string, isSuccess: boolean) {
  //   if (isSuccess) {
  //     this.successMessage = message;
  //     this.errorMessage = '';
  //   } else {
  //     this.errorMessage = message;
  //     this.successMessage = '';
  //   }
  //   setTimeout(() => {
  //     this.successMessage = '';
  //     this.errorMessage = '';
  //   }, 5000);
  // }


}
