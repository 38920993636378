import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-merged-cell',
  templateUrl: './merged-cell.component.html',
  styleUrls: ['./merged-cell.component.scss']
})
export class MergedCellComponent implements ICellRendererAngularComp {
  public params: any; // Variable to hold the parameters passed to the cell renderer

  /**
   * agInit() - Method that initializes the cell renderer with the provided parameters
   * @param params - Parameters passed by ag-Grid for this cell renderer (cell data, grid details, etc.)
   */
  agInit(params: any): void {
    this.params = params; // Store the params in the component's instance for use in the template
  }

  /**
   * refresh() - Method to refresh the cell content. Returns false, meaning no refresh is necessary.
   * This is typically used to determine whether the cell needs to be re-rendered when its data changes.
   * @returns boolean - Always returns false, indicating no refresh is needed.
   */
  refresh(): boolean {
    return false;
  }
}
