import { Component, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-credit-acknowledgement-modal',
  templateUrl: './credit-acknowledgement-modal.component.html',
  styleUrls: ['./credit-acknowledgement-modal.component.scss']
})
export class CreditAcknowledgementModalComponent {

  constructor(
    private modalService: NgbModal,){}
    openCreditAcknowledgeModal(content: TemplateRef<any>): void {
    this.modalService.open(content, {
      windowClass: 'common-modal-center',
      centered: true,
    });
  }
}
