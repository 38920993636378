<div class="user-project-wrapper">
	<div class="container px-0">
		<div class="row">
			<div class="comman-title-block">
				<div class="comman-title-section">
					<h1>Project Users</h1>
					<button class="common-btn-primary btn">+ Add a User</button>
				</div>
				<div class="comman-filter-block">
					<div class="container">
						<div class="row">
							<div class="col-lg-9 col-12 px-0">
								<div class="comman-filter-title-section">
									<p>Filters</p>
								</div>
								<div class="comman-filter-section d-flex flex-wrap align-items-center gap-3">
									
									<ng-multiselect-dropdown class="custom-multiselect" [placeholder]="'Projects'"
										[settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
										(onSelect)="onItemSelect($event)">
									</ng-multiselect-dropdown>
									<ng-multiselect-dropdown class="custom-multiselect" [placeholder]="'Types'"
										[settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
										(onSelect)="onItemSelect($event)">
									</ng-multiselect-dropdown>
									<ng-multiselect-dropdown class="custom-multiselect" [placeholder]="'Status'"
										[settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
										(onSelect)="onItemSelect($event)">
									</ng-multiselect-dropdown>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="common-grid-block">
				<div class="mt-4">
					<app-common-ag-grid  class="ag-theme-custom" 
						[colDefs]="colDefs"
						 [rowData]="rowData"
						 [colsForSmallScreen]="colsForSmallScreen"
						 [colsForMediumScreen]="colsForMediumScreen"
						 [colsForLargeScreen]="colsForLargeScreen"
						 [colsForExtraLargeScreen]="colsForExtraLargeScreen"
						 >
					</app-common-ag-grid>
				</div>
			</div>
		</div>
	</div>
</div>