export const DEPARTMENT_DETAILS = [
  { id: 2, description: "Construction Services" },
  { id: 3, description: "Costume" },
  { id: 4, description: "Design Studio" },
  { id: 5, description: "Facilities Maintenance" },
  { id: 6, description: "Fire & Security" },
  { id: 7, description: "First Aid"},
  { id: 8, description: "Food Services" },
  { id: 9, description: "Mail Services" },
  { id: 10, description: "Metal Shop" },
  { id: 11, description: "Motion Picture Imaging" },
  { id: 12, description: "Office & Production" },
  { id: 13, description: "Parking" },
  { id: 14, description: "Photo Lab" },
  { id: 15, description: "Post Production Sound" },
  { id: 16, description: "Property" },
  { id: 17, description: "Special Events" },
  { id: 18, description: "Staff Shop" },
  { id: 19, description: "Studio Operations" },
  { id: 20, description: "Transportation" },
  { id: 21, description: "Utilities Services" },
]

export const ACCORDION_DATA = [
  { id: 1, title: "Accordion Item #1", detials: "Placeholder content for this accordion." },
  { id: 2, title: "Accordion Item #2", details: "Placeholder content for this accordion."},

]
