<div
  class="file-drop-area"
  [ngClass]="{ 'file-over': isFileOver, 'disabled': isUploadDisabled }"
  [attr.disabled]="disabled ? true : null"
  (dragover)="disabled || onDragOver($event)"
  (dragleave)="disabled || onDragLeave($event)"
  (drop)="disabled || onDrop($event)"
>
  <img
    class="folder-icon"
    (click)="disabled || fileInput.click()"
    src="../../../../../assets/images/folder-icon.svg"
    alt="folder icon"
  />
  <h3 class="mb-2 drag-n-drop">
    Drag and Drop your file here, or
    <a
      class="browse-file-link"
      (click)="disabled || fileInput.click()"
      [class.disabled-link]="disabled"
    >
      browse
    </a>
  </h3>
  <input
    type="file"
    [multiple]="!allowOnlySingleFile"
    class="d-none"
    (change)="disabled || onFileSelect($event)"
    #fileInput
    [attr.disabled]="disabled ? true : null"
    accept=".pdf"
  />
</div>

<!-- Additional styles for file list and error messages (no changes needed here) -->
<ul
  class="list-group mt-3 uploaded-files-section"
  *ngIf="files?.length > 0 && fileUploadSuccess"
>
  <li *ngFor="let file of files; let i = index" class="list-group-item px-0">
    <div>
      <div class="row mx-0">
        <div
          class="col-12 col-md-9 col-sm-9 col-lg-10 d-flex align-items-center gap-2 px-0"
        >
          <div>
            <!-- Display generic file icon -->
            <img
              src="assets/images/pdf-icon.svg"
              alt="file icon"
              class="file-icon"
            />
          </div>
          <div>
            <span [title]="file.name" class="file-name">{{ file.name }}</span>
          </div>
        </div>
        <div class="col-12 col-md-3 col-sm-3 col-lg-2 view-remove">
          <!-- View span remains active -->
          <a
            class="text-link"
            (click)="viewFile(file)"
            [class.disabled-link]="false"
          >
            View
          </a>
          <!-- Remove a is disabled when in disabled mode -->
          <a
            class="text-link"
            (click)="disabled || removeFile($event, i)"
            [attr.disabled]="disabled ? true : null"
            [class.disabled-link]="disabled"
          >
            Remove
          </a>
        </div>
      </div>
    </div>
  </li>
</ul>

<div *ngIf="!disabled && fileSizeErrorMessage" class="alert alert-danger mt-2">
  {{ fileSizeErrorMessage }}
</div>
<div *ngIf="!disabled && multipleFilesErrorMessage" class="alert alert-danger mt-2">
  {{ multipleFilesErrorMessage }}
</div>
<div *ngIf="!disabled && pdfFileErrorMessage" class="alert alert-danger mt-2">
  {{ pdfFileErrorMessage }}
</div>
