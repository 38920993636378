import { FormControl } from '@angular/forms';
import { connect } from 'rxjs';

export const CREDIT_APPLICATION = {
  creditApplication: 'Credit Applications',
  viewCredit: 'View Credit Application',
};
export const NOCREDITAPP = {
  creditappnodata: 'There are no credit applications.',
}
export const PROJECTS = {
  projects: 'Projects',
  project: 'Project',
  filters: 'FILTERS:',
  clearFilters: 'Clear Filters',
  id: 'ID #',
  users: 'Users',
  spacesResources: 'Spaces And Resources',
  company: 'Company',
  noProjects: 'No Projects To Display',
  goTo: 'Go To…',
  placeholder: 'Dates',
  season: 'Season #',
  noProjectsCompany: 'There are no projects associated with this company'
}

export const ca_form_portaltype = {
  portalType1: 'ar',
  portalType2: 'studio'

}

export const USER_FILTER_TYPES = {
  ID: 'id',
  Users: 'user',
  JobTitle: 'jobtitle',
  userType: 'userType',
  Status: 'status',
  LastLogin: 'lastlogin',
  Company: 'comapny',
}
export const ALL_STATUSES = [
  { id: 1, text: 'NEW' },
  { id: 2, text: 'ASSIGNED' },
  { id: 3, text: 'CLOSED' },
  { id: 4, text: 'RESOLVED' },
  { id: 5, text: 'IN PROGRESS' },
  { id: 6, text: 'SUBMITTED' }
];

export const statusData = [
  { id: 1, text: 'Active' },
  { id: 3, text: 'Deactivated' }
];


export const USER_DROPDOWN_SETTINGS = {
  idField: "id",
  textField: "text",
  maxHeight: 250,
  noDataAvailablePlaceholderText: "No data available",
  noFilteredDataAvailablePlaceholderText: "No filtered data available",
  closeDropDownOnSelection: false,
  defaultOpen: false
}

export const PROJECT_FILTER_TYPES = {
  projectId: 'projectId',
  projects: 'projects',
  types: 'types',
  departments: 'departments',
  companies: 'companies',
  status: 'status',
  project: 'project',
  type: 'type',
  department: 'department',
  company: 'company'
}

export const PROJECT_DATES_DROPDOWN_LIST = {
  action: 'Action',
  otherAction: 'Another action',
  something: 'Something else here',
  separatedlink: 'Separated link',
  toggle: 'Toggle Dropdown',
};

export const PASSES_EXPECTED_TODAY = {
  passesExpectedToday: 'Passes Expected Today',
  all: 'All',
  passes: 'Passes',
  driveOn: 'Drive On',
  walkOn: 'Walk On',
  nextHour: 'Next Hour',
  today: 'Today',
  checkedIn: 'Checked In',
  viewAllPasses: 'View All Passes',
};

export const REQUEST = {
  request: 'Requests',
  viewAllRequest: 'View All Requests',
  SEARCH_PLACEHOLDER: "Search company name or company ID #"
};

export const MENU = {
  projects: 'Projects',
  request: 'Requests',
  company: 'Company',
};

export const DASHBOARD = {
  request: 'Request',
  user: 'User',
  credit: 'Credit Application',
  pass: 'Pass',
};

export const BUTTON_TEXTS = {
  addWbUser: '+ Add a WB user',
  addCustomerUser: '+ Add a Customer User',
  clearAllFilters: 'Clear All Filters',
  filters: 'Filters:',
  save: 'Save',
  addUser: 'Add User',
  nextAddUser: 'Next: Add User',
  close: 'Close',
  reset: 'Reset',
  users: 'Users'

};

// messages.constants.ts

export const ERROR_MESSAGE = {
  userDeactivated: 'This user is deactivated and cannot be edited',
  // Add other messages as needed
};


export const LABEL_TEXTS = {
  itemsPerPage: 'Items per page:',
  selectDepartments: 'Select department(s):',

};


export const ACTIVE_PROJECTS = {
  activeProjects: 'Active Projects & Spaces',
  viewBookMarks: 'View Bookmarks & Project',
  myStages: 'My Stages',
  myOffices: 'My Offices',
  viewProject: 'View Project',
  viewAll: 'View All Project'
};

export const PROJECT_DETAILS_SPACES = {
  mySpaces: 'My Spaces & Resources',
  spaces: 'Spaces & Resources',
  stages: '1001',
  offices: '1002',
  workshops: '1003',
  description: 'DESCRIPTION'
};

export const PROJECT_USERS_COUNT = {
  projectUsers: 'Project Users',
  viewAll: 'View All',
  addUser: 'Add a user',
  users: 'USERS'
};

export const CREDIT_APPLICATION_CONSTANTS = {
  creditApplications: 'Credit Applications',
  startApplication: 'Start a Credit Application',
  allDepartments: 'All Departments'
};

export const EMAIL_NOTIFICATION = {
  emailNotification: 'Email Notification',
  crditApplication: 'Credit Application',
  applicationAndStatus: 'Credit Applications & their statuses',
  save: 'Save',
};

export const PROFILE = {
  myProfile: 'My Profile',
  firstName: 'First Name',
  timeZone: 'Time Zone',
  dateFormat: 'Date Format:',
  header: 'Header',
  save: 'Save',
  validatorRequired: 'First Name is required.',
  validatorTimeZone: 'Time Zone is Required',
  spaceValidator: 'First Name should not contain numbers or spaces.',
  discardChanges: 'Discard Changes',
  headerDescription:
    ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus mollis interdum.',
  dateFormat1: 'm/d/yyyy',
  dateFormat2: 'd/m/yyyy',
};

export const SETTINGS = {
  settings: 'Settings',
  timeZone: 'Time Zone:',
  validatorRequired: 'Time Zone is required',
  dateFormat: 'Date Format',
  calendarFormat: 'Calendar Format',
  startSunday: 'Starts on Sunday',
  startMonday: 'starts on monday',
  timeFormat: 'Time Format:',
  temperatureUnit: 'Preferred Temperature Unit:',
  fahrenheit: 'Fahrenheit',
  celsius: 'Celsius',
  save: 'Save',
  dateFormat1: 'm/d/yyyy',
  dateFormat2: 'd/m/yyyy',
  timeFormat1: '12h',
  timeFormat2: '24h',
  timeZoneDefault: '',
  calendarFormatDefault: 'sunday',
  fahrenheitDefault: 'fahrenheit',
};

export const PROJECTS_USER_ROLE = {
  companyAdmin: 'customer_company admin',
  projectViewOnly: 'customer_project view only', 
  projectViewAndManage: 'customer_project view and manage', 
  manageUsersViewAndManage: 'customer_manage users view and manage',
  creditApplicationViewAndManage: 'customer_credit application view and manage'
}

export const TIMEZONES: string[] = ['UTC', 'GMT', 'EST', 'PST', 'CET'];

export const USER = {
  userName: 'User Name',
  profile: 'Profile',
  settings: 'Settings',
  emailNotifications: 'Email Notifications',
  profileTab: 'profile-tab',
  settingsTab: 'settings-tab',
  emailNotificationstab: 'email-notifications-tab',
  userActivatedMsg: 'User Status Updated Successfully',
  userDectivatedMsg: 'User Status Updated Successfully ',
  addUserSuccessMsg: 'Project User Added Successfully',
  updateUserSuccessMsg: 'Project User Updated Successfully',
  removeUserSuccessMsg: 'Project User Removed Successfully'
};

export const NO_NUMBERS_OR_SPACES_REGEX = /^(?!.*[\d\s]).*$/;
export function noNumbersOrSpaces(control: FormControl) {
  const value = control.value;
  if (value && !NO_NUMBERS_OR_SPACES_REGEX.test(value)) {
    return { invalidName: true };
  }
  return null;
}

export function hasError(controlName: string, errorType: string): boolean {
  const control = this.settingsForm.get(controlName);
  return control?.hasError(errorType) && (control?.touched || control?.dirty);
}

// app.constants.ts
export const RISK_CONSTANTS = {
  modalTitle: 'Credit Application',
  statusLabel: 'Status',
  closelabel: 'Close',
  formLabel: 'Form',
  documentLabel: 'Document',
  projectLabel: 'PROJECT',
  companyLabel: 'Company',
  typeLabel: 'TYPE',
  applicationDateLabel: 'APPLICATION DATE',
  downloadPacketText: 'Download Packet',
  viewProjectsText: 'View Project & Quotes',
  viewCompanyText: 'View Company',
  creditLimitLabel: 'Credit Limit',
  sentRiskEmailLabel: 'Sent Risk Email on',
  sentByLabel: 'Sent by',
  reasonsLabel: 'Reasons',
  departmentsLabel: 'Departments',
  commentsLabel: 'Comments',
  addCommentButtonText: 'Add Comment',
  tabs: {
    status: 'status',
    form: 'form'
  },
  viewOnlyProjectsText: 'View Project',

};

export const CREDIT_APP_CONSTANTS = {
  alldepartment: 'All Departments',
  userNotAllowedmsg: 'Credit application pending review by other departments.Please try again later.',
  allowedStatuses: {
    APPROVED_RISK: 'APPROVED (RISK)',
    APPROVED: 'APPROVED',
    DENIED: 'DENIED',
    PENDING_RISK: 'PENDING RISK',
    NEEDS_APPROVAL: 'NEEDS APPROVAL'

  },
  message: 'The current application is still under A/R review.',
  okButton: 'OK',
  title: 'Credit Application with Risk',
  project: 'Project: ',
  company: 'Company: ',
  subtitle: 'Your account is associated with multiple departments.',
  bodytitle: 'Please select which department view you want to use to open the credit application and review the risk:',
  cancel: 'Cancel',
  select: 'Select',
  

};


export const STATUS_CLASSES = {
  'NEW APPLICATION': 'new-application need-app-width',
  'APPROVED': 'approved',
  'DENIED': 'denied',
  'NEEDS REVISIONS': 'revision need-revision-width',
  'NEEDS APPROVAL': 'need-approve need-revision-width',
  'REVISIONS SUBMITTED': 'new-application need-revision-width',
  'PENDING RISK': 'revision',
  'UNDER REVIEW': 'under-review need-revision-width',
  'APPROVED (RISK)': 'approved-risk need-revision-width',
};
// credit-app-details.constants.ts
export const CREDIT_APP_DETAILS_CONSTANTS = {
  titles: {
    status: 'Status',
    comments: 'Comments',
    departments: 'Departments:',
    selectedDepartments: 'selected Departments:',
    ar: 'A/R',
    codDepartments: 'Cash On Demand Departments',
    allDepartments:'All Departments'

  },
  labels: {
    creditLimit: 'Credit Limit',
    sentRiskEmail: 'Sent Risk Email on',
    sentBy: 'Sent by',
    reasons: 'Reasons',
    typeComment: 'Type your comment…',
    copy: 'Copy',
    approvedon: 'Approved on',
    approvedby: 'Approved by',
    deniedon: 'Denied on',
    deniedby: 'Denied by',
    loasenton: 'Letter of Approval Sent on',
    loasentby: 'Sent by',
    codnotify: 'COD Notification Sent on'
  },
  contents: {
    loa: 'Letter of Approval not sent yet',
    cod: 'COD Notification not sent yet'
  },
  buttons: {
    view: 'View',
    reviewRiskEmail: 'Review Risk Email',
    addDepartment: 'Add Department',
    addComment: 'Add Comment'
  }
};

export const CREDIT_APP_FORM_CONSTANTS = {
  sections: {
    lineOfCredit: {
      title: 'Line of Credit',
      labels: {
        requested: 'Line of Credit requested',
        jobTotal: 'Anticipated Job Total',
        months: 'Total # of months'
      }
    },
    letterOfCredit: {
      title: 'Letter of Credit/',
      gurantee:'Guarantee',
      labels: {
        comments: 'Comments'
      }
    },
    company: {
      title: 'Company',
      labels: {
        name: 'Name',
        address: 'Address',
        phone: 'Phone',
        billingAddress: 'Use for billing address'
      },
      prodcompanyaddress: 'Production Company Address',
      billingaddress: 'Billing Address'
    },
    federalTaxId: {
      title: 'Federal Tax ID #',
      labels: {
        tin: 'TIN'
      }
    },
    accountPayable: {
      title: 'Accounts Payable Contact',
      labels: {
        name: 'Name',
        email: 'Email',
        phone: 'Phone'
      }
    },
    companyProfile: {
      title: 'Company Profile',
      labels: {
        profile: 'Profile',
        dateStarted: 'Date Started',
        businessType: 'Type of Business',
        annualSales: 'Total Annual Sales',
        netWorth: 'Net worth',
        employees: '# of Employees'
      }
    },
    principals: {
      title: 'Principals/Officers or Partners',
      labels: {
        name: 'Name',
        title: 'Title'
      }
    },
    bankingReferences: {
      title: 'Banking References',
      labels: {
        bank: 'Bank',
        accountNumber: 'Account Number',
        name: 'Name',
        title: 'Title',
        email: 'Email',
        phone: 'Phone'
      }
    },
    tradeReference: {
      title: 'Trade Reference',
      labels: {
        company: 'Company',
        contact: 'Contact',
        address: 'Address',
        email: 'Email',
        phone: 'Phone'
      }
    },
    termsConditions: {
      title: 'Terms & Conditions',
      labels: {
        name: 'Name',
        title: 'Title',
        signature: 'Signature',
        date: 'Date',
        yes: 'Yes',
        no: 'No'
      }
    },
    certificateOfInsurance: {
      title: 'Certificate of',
      insurance:'Insurance',
      labels: {
        producer: 'Producer',
        insured: 'Insured',
        address: 'Address'
      }
    },
    dbReport: {
      title: 'D&B Report',
      labels: {
        delinquency: 'Delinquency Score',
        failureScore: 'Failure Score',
        paydexScore: 'PAYDEX® Score',
        na: 'N/A'
      }
    }
  },
  common: {
    view: 'View',
    none: 'None'
  }
};

export const COMPANY = {
  companyname: "Sunshine Productions",
  companyid: 'Company ID #',
  projects: 'Projects',
  companyaddress: 'Production Company Address',
  billingaddress: 'Billing Address',
  contactinfo: 'Contact Information',
  companyuser: 'Company Users',
  creditstatus: 'Credit Status',
  adduser: 'Add a User',
  viewall: 'View All',
  valid: 'Valid Until',
  current: 'Current',
  editcompany: 'Edit Company',
  addcompany: 'Add a Company',
  portaltype: 'studio',
  modalType: 'edit',
  addProject: 'Add a Project',
  internaluse: 'FOR INTERNAL USE ONLY',
  companytype: 'Company Type',
  enablecompany: 'Enable Company',
  disablecompany: 'Disable Company',
  addcustomeruser: 'Add a Customer User',
  addproject: 'Add a Project',
  allcompanies: 'All Companies',
  addnewproject: 'Add A New Project',
  companytitle: 'Companies',
  companybtn: 'Company',
  enable: 'enable',
  disable: 'disable',
  notavailable: 'Not Available',
  modalEdit: 'edit',
  modalAdd: 'add',
  componentType: 'studio-company',
  companyerror: 'Company does not exist',
  editCustomerUser: 'Edit A Customer User',
  companyUserType: 'companyuser',
  federalTaxId: 'Federal Tax ID #',
  noUsers: 'No Users To Display',
  four: '4'
};

export const COMPANY_USERS = {
  companyusers: 'Company Users',
  user: 'User',
  filter: 'Filters',
  adduser: 'Add a User',
};

export const PROJECT_USERS_LIST_CONSTANTS = {
  all: 'All',
  notVerified: 'Not Verified',
  inactive: 'Inactive',
  admin: 'Admin'
}

export const DropdownSettings = {
  id: "id",
  textField: "text",
  limitSelection: -1,
  maxHeight: 197,
  itemsShowLimit: 999999999999,
  searchPlaceholderText: "Search",
  noData: "No data available",
  noFilteredData: "No filtered data available",
}

export const PROJECT_DROPDOWN_SETTINGS = {
  idField: "id",
  textField: "text",
  maxHeight: 250,
  noDataAvailablePlaceholderText: "No data available",
  noFilteredDataAvailablePlaceholderText: "No filtered data available",
  closeDropDownOnSelection: false,
  defaultOpen: false
}

export const SORTING_CONSTANTS = {
  sort: "Sort:",
  mostRecent: "Most Recent",
  aToz: "A - Z"
}
export const GETSTATUS_CLASS = {
  active: 'ACTIVE',
  deactivated: 'DEACTIVATED',
  disabled: 'DISABLED'
}
export const COLS = {
  companies: [
    {
      colsForLargeScreen: ['user', 'status', 'goToPage', 'icons'],
      colsForSmallScreen: ['bella', 'iconsStatus'],
      colsForMediumScreen: ['bella', 'iconsStatus'],
      colsForExtraLargeScreen: ['compid', 'companyName', 'status', 'projects', 'active', 'users', 'phone', 'goToPage', 'icons']
    }
  ],
  company_user: [
    {
      colsForSmallScreen: ['user-status', 'icons'],
      colsForMediumScreen: ['user-status', 'icons'],
      colsForLargeScreen: ['user', 'status', 'activity', 'jobTitle', 'icons'],
      colsForExtraLargeScreen: ['userid', 'user', 'status', 'jobTitle', 'activity', 'phone', 'activeProjects', 'icons']
    }
  ],
}
export const COL_SMALL_SCREEN = ['user', 'status', 'icons']
export const COL_MEDIUM_SCREEN = ['user', 'status', 'icons']
export const COL_LARGE_SCREEN = ['user', 'status', 'activity', 'jobTitle', 'icons']
export const COL_XL_SCREEN = ['id', 'user', 'status', 'activity', 'jobTitle', 'accessdept', 'sections', 'icons']

export const COMPANY_FILTERS = {
  projects: 'Projects',
  filters: 'FILTERS',
  clearFilters: 'Clear Filters'
}
export const COMPANY_USERS_FILTER_TYPES = {
  activeprojects: 'activeprojects',
  status: 'status',
  user: 'user',
  jobtitle: 'jobtitle',
  id: 'id',
  userType: 'userType',
}
export const GO_TO_DROPDOWN = [
  {
    'id': 1,
    'name': "User"
  }
]
export const PROJECT = {
  projects: 'Projects',

}
export const PROJECT_LIST = {
  companyname: "Company Name",
  id: 'ID #',
  projects: 'Projects',
  projectname: 'Project Name',
  companyaddress: 'Company Address',
  contactinfo: 'Contact Information',
  companyuser: 'Company Users',
  creditstatus: 'Company Status',
  adduser: 'Add a User',
  viewall: 'View All',
  valid: 'Valid Until 12/12/24',
  current: 'Current',
  editcompany: 'Edit Company',
  addcompany: 'Add a Company',
  portaltype: 'customer',
  modalType: 'edit',
  addProject: 'Add a Project',
  internaluse: 'FOR INTERNAL USE ONLY',
  companytype: 'Company Type',
  enablecompany: 'Enable Company',
  disablecompany: 'Disable Company',
  addcustomeruser: 'Add a Customer User',
  addproject: 'Add a Project',
  allcompanies: 'All companies',
  addnewproject: 'Add A New Project',
  companytitle: 'Company'
};

export const VIEW_ALL_USERS_TYPES = {
  userId: 'id',
  users: 'users',
  userType: 'userType',
  status: 'status',
  jobTitles: 'jobTitles',
  user: 'firstname',
  jobTitle: 'jobTitle'
}

export const VIEW_ALL_USERS_CONSTANTS = {
  addUser: '+ Add a User',
  filters: 'FILTERS:',
  company: 'COMPANY:',
  companyText: 'A Brand New Production Company',
  clearFilters: 'Clear Filters',
  allProjects: 'All Projects',
  projectUsers: 'Project Users',
  addUserTitle: 'Add A User',
  EditUserTitle: 'Edit User',
  placeholder: 'Last Login',
  errorMessage: 'Error fetching users:',
  allUsers: 'All Users'
}

export const STUDIO_PROJECT_USERS_LIST_CONSTANTS = {
  all: 'All',
  active: 'Active',
  deactivated: 'Deactivated',
  admin: 'Admin',
  companyAdmin: 'Company admin',
  accountPayableUser: 'accounts payable',
  activeStatus: 'ACTIVE',
  deactivatedStatus: 'DEACTIVATED',
  statusActive: 'Y',
  statusDeactive: 'N',
  allAccess: 'ALL',
  statusSuccess: 'success',
  deactivateAccount: 'deactivate-account',
  activateAccount: 'activate-account',
  removeAccount: 'remove-account',
  seven: '7',
  companyAdminText: 'Company Admin',
  accountPayableText: 'Accounts Payable',
  allDepartments: 'All Departments',
}

export const COMPANY_CREDIT_STATUS = [
  { 'id': 1, 'credit_status': 'Current' },
  { 'id': 2, 'credit_status': 'Not Available' },
  { 'id': 3, 'credit_status': 'Needs Renewal' },
]

export const VALIDATION_MSG = {
  required: 'This field is required',
  postalcode: 'Please enter a valid ZIP/Postal Code',
  phone: 'Please enter a valid Phone Number',
  firstname: 'First Name is not valid',
  lastname: 'Last Name is not valid',
  email: 'Please enter a valid email address',
  contact: 'At least one contact is required.',
  companyName: 'Company Name is not valid',
  maxCharCompanyName: 'Company name cannot exceed 50 characters.',
  characterLimitHundred: 'Name must be within 100 characters',
  fedTaxId: 'Please enter a valid Federal Tax ID #',
  jobtitle: 'Job Title is not valid',
  streetInvalid: 'Street is not valid',
  cityInvalid: 'City is not valid',
  duplicateemail: 'This email address already exists, please enter a different email address.',
  fieldInvalid: 'Please correct the indicated fields. Some values are invalid or missing.',
  spaceerror: 'Please remove any extra spaces before or after the text.',
  twospaceerror: 'Please remove extra spaces between words.',
  invalidPhone: 'Invalid phone number',
  phoneMinLength: 'Min length is 10 characters',
  phoneMaxLength: 'Max length exceeded'
}
export const CONFIRM_COMP_POPUP = {
  yes: 'Yes',
  no: 'No',
  enablecompany: 'Are you sure you want to enable this company?',
  disablecompany: 'Are you sure you want to disable this company?',
  successEnable: 'Company Enabled Successfully',
  successDisable: 'Company Disabled Successfully',
  errorEnable: 'Company Enabled Error',
  errorDisable: 'Company Disabled Error'
}
export const PROJECT_DETAILS_CONSTANTS = {
  allProjects: 'All Projects',
  projectId: 'PROJECT ID #:',
  ar: 'A/R #:',
  dates: 'DATES:',
  description: 'DESCRIPTION',
  editDescription: 'Edit project description',
  addUser: 'Add A User',
  company: 'COMPANY:',
  companyText: 'A Brand New Production Company',
  type: 'TYPE:',
  customer: 'CUSTOMER:',
  primaryPayment: 'PRIMARY PAYMENT:',
  imageError: 'Error during upload',
  projectImage: 'project',
  section: 'section',
  projectIdImage: 'projectId',
  file: 'file',
  myDashboard: 'My Dashboard',
  allCompanies: 'All Companies',
  project: 'project',
  maxSize: ['image/jpeg', 'image/png']
};

export const ADD_USER_CONSTANTS = {
  addUser: 'Add A User',
  editUser: 'Edit User',
  close: 'Close',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  jobTitle: 'Job Title',
  optionalSettings: 'Optional Settings',
  departmentAccess: 'Department Access',
  sectionAndPermissions: 'SECTION AND PERMISSIONs',
  access: 'ACCESS',
  projects: 'PROJECTS',
  serviceRequest: 'Service Request',
  creditApplication: 'CREDIT APPLICATION',
  manageUsers: 'MANAGE USERS'
};

export const EDIT_DESCRIPTION_CONSTANTS = {
  editProject: 'Edit Project',
  description: 'Project Description',
  save: 'Save Changes',
  discard: 'Discard Changes',
  close: 'Close',
  bookmarkProject: 'Bookmark Project',
  removeBookmark: 'Remove Bookmark',
  editProjectStatus: 'Edit Project Status',
  editProjectInformation: 'Edit Project Information',
  notStarted: 'Not Started',
  active: 'Active',
  closed: 'Closed',
  modalEdit: 'Edit',
  modalOk: 'OK',
  addNewProject: 'Add A New Project',
  saveStatus: 'Save Status',
  cancel: 'Cancel'
};

export const PROJECT_DETAILS_MODAL_CONTENT = {
  scheduleNotes: 'Schedule Notes',
  datesAreNotFlexible: 'Dates are not flexible',
  editProjectStatus: 'Edit Project Status',
  additionalProjectDetails: 'Additional Project Details',
  televisionProjectCameraSetup: 'TELEVISION PROJECT CAMERA SETUP:',
  multiCam: 'Multi Cam',
  programmingSchedule: 'PROGRAMMING SCHEDULE:',
  singleEpisodePilot: 'Single Episode/Pilot',
  editProject: 'Edit A Project',
  noData: 'No Data Available'
}

export const MESSAGE = {
  addCompanySuccessMessage: "Company Added Successfully",
  editCompanySuccessMessage: "Company Updated Successfully",
  addCompanyErrorMessage: "Error while adding company",
  editCompanyErrorMessage: "Error while updating company",
  enableCompanySuccessMessage: "Company Enabled Successfully",
  disableCompanySuccessMessage: "Company Disabled Successfully",
  enableCompanyErrorMessage: "Error while enabling company",
  disableCompanyErrorMessage: "Error while disabling company",
  errorCompanies: 'Error Retriving Company',
  companyUserDeactivated: 'Company User Deactivated Successfully',
  companyUserActivated: 'Company User Activated Successfully',
  errorCompanyUser: 'Error Retriving Company Users',
  duplicateFedId: 'Duplicate Federal Tax Id',
  companyUserAddSuccess: 'Company User Added Successfully',
  companyUserEditSuccess: 'Company User Updated Successfully',
  fileSizeExceedFiveMB: 'File size exceeds 5 MB',
  imageTypeSupportedMessage: 'The allowed file types are PNG, JPG/JPEG'
}


export const PROJECTS_MODAL = {
  addUserTitle: 'Add A User',
}
export const COMPANY_ADD_TYPE_ID = {
  productioncompanyAddressTypeId: 1,
  billingcompanyAddressTypeId: 2,
}

export const NEW_PROJECT = {
  addUser: 'Add A New Project',
  editUser: 'Edit A New Project',
  close: 'Close',
  company: 'Company',
  type: 'TYPE:',
  companyText: 'COMPANY:',
  companyName: 'A Brand New Production Company',
  changeCompany: 'Change Company',
  openCompany: 'Open Company',
  selectCompany: 'Select Company',
  projectName: 'Project Name',
  addProject: 'Add Project',
  addAndGo: 'Add & Go to Project',
  search: 'Search',
  cancel: 'Cancel',
  select: 'Select',
  placeholder: 'Search company name or company ID #',
  searchLabel: 'Company',
  saveChanges: 'Save Changes',
  ar: 'A/R'
}

export const NEW_PROJECT_DETAILS = {
  projectDetails: 'Project Details',
  projectType: 'Project Type',
  feature: 'Feature',
  television: 'Television',
  liveProduction: 'Live Production/Rehearsal',
  commercial: 'Commercial',
  stillShoot: 'Still Shoot',
  specialEvent: 'Special Event',
  other: 'Other',
  type: 'Type',
  seasonNumber: 'Season Number',
  cameraSetup: 'TELEVISION PROJECT CAMERA SETUP:',
  singleCam: 'Single Cam',
  multiCam: 'Multi Cam',
  variety: 'Variety',
  programmingSchedule: 'Programming schedule:',
  singleEpisode: 'Single Episode/Pilot',
  series: 'Series/Multi Episodes',
  season: 'Season #',
  season1: '1',
  season2: '2',
  season3: '3',
  season4: '4',
  projectDescription: 'Project Description:',
  anticipatedProjectDates: 'Anticipated Project Dates:',
  startDate: 'Start Date',
  endDate: 'End Date',
  scdeduledNotes: 'Schedule Notes',
  additionalscdeduledNotes: 'Additional Schedule Notes?',
  errorMessage: 'End date cannot be before start date.',
  requiredDate: 'Date is required.',
  invalidDate: 'Invalid date',
  consoleError:'Invalid date string:',
  consoleError1: 'Date string does not have the expected format:',
  invalidDateFormat: 'Invalid preferred unit',
}

export const NEW_PROJECT_COMPANY_TYPE = {
  companyType: 'Company Type',
  forInternal: 'For Internal use only',
  customerType: 'Customer Type',
  select: 'Select',
  projects: 'Projects',
  users: 'Users',
  spaces: 'Spaces & Resources',
  company: 'Company',
  primaryPaymentType: 'Primary Payment Type',
  current: 'Current',
}

export const ERROR_MSG = {
  projectDetails: 'Error fetching Project Specific details list:',
  projectsList: 'Error fetching Projects List:',
  userNotAvailable: 'User Project ID not available',
  bookmarkAddError: 'Error creating the new bookmark',
  bookmarkRemoveError: 'Error removing the bookmark',
  statusUpdateError: 'Error Updating the project status',
  maxAllowedValue: 'Maximum allowed value is 99.',
  numbersAllowed: 'Allowed values between 1 and 99',
  companyTypeError: 'Company Type cannot be changed due to an approved credit application.',
  zeroNotAllowed: 'Value cannot be 0 or start with leading zeros (00, 0000)',
  formError: { message: 'Please correct the indicated fields. Some values are invalid or missing.' }
}

export const SEASONS = [
  { id: 1, season: 1 },
  { id: 2, season: 2 },
  { id: 3, season: 3 },
  { id: 4, season: 4 },
  { id: 5, season: 5 },
  { id: 6, season: 6 },
  { id: 7, season: 7 },
  { id: 8, season: 8 },
  { id: 9, season: 9 },
  { id: 10, season: 10 },
  { id: 11, season: 'Other' },
];

export const FED_TAX_VAL_POPUP = {
  existingMsg: 'We found an existing company with a similar name/federal tax ID',
  existingEditMsg: 'We found an existing company with a similar name/ identical Federal Tax ID:',
  addBtn: 'Add New',
  saveChanges: 'Save Changes',
  goToExistingBtn: 'Cancel and Go To Existing',
  cancel: 'Cancel'
}
export const ICON_ACTION_NAMES = {
  freez: 'freeze-account',
  deActivate: 'deactivate-account',
  activate: 'activate-account',
  disableCompany: 'disable-company',
  enableCompany: 'enable-company'

}
export const CUSTOM_ORDER = ['United States', 'United Kingdom', 'Canada'];

export const COL_DEF_FIELDS = {
  icons: 'icons',
  gotopage: 'goToPage',
  mergedUser: 'bella',
  mergedStatus: 'iconsStatus'
}

export const DEFAULT_STATUS = {
  active: 'active',
  activeCapital: 'ACTIVE',
  activeCamel: 'Active',
  activeId: 1,
  disabledId: 2,
  disabled: 'disabled',
  disabledCapital: 'DISABLED',
  disabledCamel: 'Disabled'

}


export const RESPONSE_STATUS = {
  success: 'success'
}
export const DATEPICKER_CONSTANTS = {
  lastLogin: "Last Login",
  dates: "Dates"
}

export const USERS_TYPES = {
  addModalType: 'add',
  editModalType: 'edit',
  componentType: 'viewAll'
}

export const STUDIO_DASHBOARD_CREDIT_APPLICATION = {
  creditApplication: 'Credit Applications',
  viewAllCreditApplications: 'View All Credit Applications',
  allDepartments: 'All Departments'
}

export const STUDIO_DASHBOARD_REQUESTS = {
  requests: 'Requests',
  viewAllRequests: 'View All Requests'
}

export const STUDIO_DASHBOARD_BOOKMARK_PROJECTS = {
  bookmarkProjects: 'Bookmarked Projects',
  goTo: 'Go To…',
  projects: 'Project',
  users: 'Users',
  company: 'Company',
  active: 'Active',
  notStarted: 'Not Started',
  closed: 'Closed',
  one: '1',
  two: '2',
  three: '3'
}
export const DEFAULT_STATUSES = [
  { id: 2, text: "Active", orderNo: 2 },
  { id: 3, text: "Closed", orderNo: 3 },
  { id: 1, text: "Not Started", orderNo: 1 }
];

export const DEFAULT_PROJECT_TYPES = [
  "Feature",
  "Television",
  "Live Production/Rehearsal",
  "Commercial",
  "Still Shoot",
  "Special Event",
  "Other"
];


export const STUDIO_CREDIT_APPLICATION = {
  sendCreditApplication: 'Send Credit Application Packet',
  company: 'Company',
  project: 'Project',
  departments: 'DEPARTMENTS',
  sendToAccountPayable: 'SEND TO ACCOUNTS PAYABLE CONTACTS',
  addAnAccountPayable: 'Add an Accounts Payable User',
  cancel: 'Cancel',
  close: 'Close',
  sendToCustomer: 'Send to Customer',
  arName: 'A/R',
  attachQuotes: 'Attach Quotes',
  creditApplication: 'Credit Applications',
  sentToCustomer: 'Sent to Customer',
  statusSuccess: 'success',
  addUserTitle: 'Add A User',
  addCreditApplication: 'Add a Credit Application',
  addRequestAr: 'Request A/R #',
  thirdPartyCommercialHouse: '3rd Party Commercial House',
  current: 'Current',
  accountPayable: 'Error fetching account payable users:',
  successSentCreditApplication: 'The Credit Application has been sent',
  viewQuotes: 'View Quotes',
  viewQuote: 'View Quote',
  quotesForCreditApp: 'Quotes For Credit Application',
  waitingQuotesMsg: 'Waiting for a few more quotes from PPCS and Sound Stages',
  attachQuotesCancelMsg: 'Are you sure you want to close without uploading?',
  quotesUploadSuccessfully: 'Quotes have been uploaded',
  dragAndDrop: 'Drag and Drop your file here, or',
  browse: 'browse',
  addComment: 'ADD A COMMENT',
  upload: 'Upload',
  remove: 'Remove',
  view: 'View',
  successAttachQuotes: 'Quotes have been uploaded',
  sendCALetterText: `<p>Thank you for your interest in doing business with Warner Bros. Studio Operations.</p><p>Please ensure the credit application is completed in its entirety. Critical information required is as follows: </p><p><ul>  <li>Customer name, show name, billing address and customer Accounts Payable email address</li>  <li>Company Federal Tax ID (FEIN Number)</li> <li>Experian account number, banking and trade references in the entertainment industry With a valid fax or email address or attach a company credit reference sheet with the information.<ul><li>Unacceptable Trade References: hotels, professional services, payroll services, car rentals, Federal Express, Office Depot, etc.</li></ul>
</li><li>Signed application by an authorized agent of the bank, dated, print name and title. </li>
<li>Certificate of Insurance </li>  </ul>  </p><p><b><i>The application will not be processed without the customer information requested above and signed by the authorized bank agent.</i></b></p><p><b><i>Please do not send any credit card information with the WBSO credit packet. WBSO A/R does not accept credit card payments nor handles C.O.D. accounts.</i></b> </p><p>If you have any questions regarding the account process please contact us at <a href="mailto:WBSFAR@wbd.com">WBSFAR@wbd.com</a></p><p>Thank you again for your interest in Warner Bros. Studio Operations. We look forward to being of service to you.</p>`,
  portaltype: 'A/R',
  uploadFrom: 'Upload from',
  at: 'at',
  by: 'By',
  chooseDepartment: 'Choose Department',
  valueError: 'Exceeded maximum value of $999,999,999.',
  requiredError: 'Quotes value is required.',
  department: 'Department is required.'
}
export const STUDIO_CREDIT_APPLICATION_STATUS = [
  { id: 1, text: 'Approved' },
  { id: 2, text: 'Approved (Risk)' },
  { id: 3, text: 'Denied' },
  { id: 4, text: 'New Application' },
  { id: 5, text: 'Needs Approval' },
  { id: 6, text: 'Needs Revisions' },
  { id: 7, text: 'Pending Risk' },
  { id: 8, text: 'Revisions Submitted' },
  { id: 9, text: 'Sent To Customer' },
  { id: 10, text: 'Under Review' },
]
export const STUDIO_STATUS = {
  approved: 'APPROVED',
  approvedRisk: 'APPROVED (RISK)',
  denied: 'DENIED',
  needsApproval: 'NEEDS APPROVAL',
  needsRevisions: 'NEEDS REVISIONS',
  newApplication: 'NEW APPLICATION',
  pendingRisk: 'PENDING RISK',
  revisionsSubmitted: 'REVISIONS SUBMITTED',
  sendToCustomer: 'SEND REVISIONS',
  sentToCustomer: 'SENT TO CUSTOMER',
  underReview: 'UNDER REVIEW',
}

export const NOTIFICATION_NOT_SENT = ['Approved', 'Approved (Risk)', 'Denied'];
export const PATTERN = {
  number: '^[0-9]+$'
}

export const ADD_DEPARTMENT_ARRAY = [
  { departmentId: 1, departmentName: 'Construction Services' },
  { departmentId: 2, departmentName: 'Facilities Maintenance' },
  { departmentId: 3, departmentName: ' Food Services' },
  { departmentId: 4, departmentName: 'Motion Picture Imaging' },
  { departmentId: 5, departmentName: ' Photo Lab' },
  { departmentId: 6, departmentName: 'Special Events' },
  { departmentId: 7, departmentName: 'Transportation' },
  { departmentId: 8, departmentName: 'Costume' },
  { departmentId: 9, departmentName: ' Fire & Security' },
  { departmentId: 10, departmentName: 'Mail Services' },
  { departmentId: 11, departmentName: 'Office & Production' },
  { departmentId: 12, departmentName: ' Post Production Sound' },
  { departmentId: 13, departmentName: ' Staff Shop' },
  { departmentId: 14, departmentName: ' Utilities Services' },
  { departmentId: 15, departmentName: 'Design Studio' },
  { departmentId: 16, departmentName: 'First Aid' },
  { departmentId: 17, departmentName: ' Metal Shop' },
  { departmentId: 18, departmentName: ' Parking' },
  { departmentId: 19, departmentName: ' Property' },
  { departmentId: 20, departmentName: ' Studio Operations' },

]
export const RISK_EMAIL_CONSTANTS = {
  title: 'Risk Email',
  bankreferance: 'Bank Reference:',
  tradereferance: 'Trade References:',
  dandb: 'D&B:',
  deliquencyscore: 'Deliquency Score:',
  failurescore: 'Failure Score:',
  paydexscore: 'PAYDEX® Score:',
  emailbody1: ' The customer has not met the WBSO Credit criteria and they are not associated with a major studio or on our approved guarantors list.',
  emailbody2: ' Please note that the customer has not been notified that a line of credit will not be granted. However, A/R wanted to verify with the departments would like to accept the risk for this customer for all your respective departments and expense any amount deemed uncollectible from each group.',
  emailbody3: ' Please with your approval along with your SVP approval if the department would like to accept the risk and the credit limit fo the customer. If A/R does not receive the approval from the departments, A/R will send the COD notification to the customer within 24 hours from sending the Customer Risk Approval - Financial Responsibility email.',
  close: 'Close'
}

export const REQUEST_AR = {
  heading: 'Request Documents for new A/R',
  company: 'Company:',
  name: 'A Brand New Production Company',
  project: 'Project:',
  projectName: 'The New Show - Pilot',
  text: 'Nullam id dolor id nibh ultricies vehicula ut id elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor.',
  departments: 'DEPARTMENTS',
  send: 'SEND TO ACCOUNTS PAYABLE CONTACTS:',
  link: 'Add an Accounts Payable User',
  cancelBtn: 'Cancel',
  statusSuccess: 'success',
  sendToCustomerBtn: 'Send to Customer',
  accountPayable: 'Error fetching account payable users:',
  addUserTitle: 'Add A User',
  successThirdpartyArApplication: 'Request Documents for new A/R # has been sent',
  sendRequestArTextboxName: '<p>Thank you for your interest in doing business with Warner Bros. Studio Operations.</p><p>Please ensure that the Purchase Order form and the Certificate of Insurance is uploaded to request a new AR #.</p><p>If you have any questions regarding the account process please contact us at <a href="mailto:WBSFAR@wbd.com">WBSFAR@wbd.com</a>.</p><p>Thank you again for your interest in Warner Bros. Studio Operations. We look forward to being of service to you.</p>',
  cancel: 'Cancel',
  button: 'Send to Customer',
}

export const RISK_EMAIL_TIER1_CONSTANTS = {
  title: 'Department Risk',
  project: 'PROJECT :',
  company: 'Comapny :',
  type: 'TYPE :',
  appdate: 'APPLICATION DATE :',
  close:'Close',
  view:'View Credit Application',
  download:'Download Packet',
  acceptriskbefore: 'Accept Risk before',
  hiall: 'Hi All,',
  content1: 'A/R received a credit request for a new account from the following customer:',
  content2: 'The customer has not met the WBSO Credit criteria and they are not associated with a major studio or on our ',
  content3: 'Please note that the customer has not been notified that a line of credit will not be granted. However, A/R wanted to verify with the departments would like to accept the risk for this customer for all your respective departments and expense any amount deemed uncollectible from each group.',
  content4: 'Please respond with your approval along with your SVP approval if the department would like to accept the risk and the credit limit for the customer.',
  list: 'approved guarantors list.',
  content5: 'If A/R does not receive the approval from the departments, A/R will send the COD notification to the customer within 24 hours from sending the Customer Risk Approval - Financial Responsibility email.',
  departmentsselected: 'Departments Selected to be Used by Customer',
  creditlinerequested: 'Credit Line Requested',
  summaryevaluation: 'Summary Evaluation',
  bankreference: 'Bank Reference:',
  tradereferences: 'Trade References:',
  db: 'D&B:',
  deliquencyscore: '- Deliquency Score:',
  failurescore: '- Failure Score:',
  paydexscore: '- PAYDEX® Score:',
  allDepartment:'All Department',
  yourDepartment:'YOUR DEPARTMENT',
  allDepartments: 'All Departments'


};

export const RISK_EMAIL_TIER1_Text_AFTER_ACTION = {
  denied: "Denied",
  acceptedOnBehalfOfSVP: "Accepted On Behalf Of SVP",
  sentToSVP: "Sent To SVP",
  depHeadAccepted: "Accepted",
  depHeadDenied: "Denied",
}

export const RISK_EMAIL_TIER1_IMG_AFTER_ACTION = {
  denied: "../../../../../assets/images/deny-cross.svg",
  acceptedOnBehalfOfSVP: "../../../../../assets/images/approve.svg",
  sentToSVP: "../../../../../assets/images/send-email.svg",
  depHeadAccepted: "../../../../../assets/images/approve.svg",
  depHeadDenied: "../../../../../assets/images/deny-cross.svg",
}

export const TIER1_DEPTSELECT = {
  title: 'Credit Application with Risk',
  project: 'Project: ',
  company: 'Company: ',
  subtitle: 'Your account is associated with multiple departments.',
  bodytitle1: 'Please select which department view you want to use to open the',
  bodytitle2: 'credit application and review the risk:',
  cancel: 'Cancel',
  select: 'Select'


}
//Success message for the API
export const STUDIO_SUCCESS_APIMESSAGE = {
  //dashboard
  removeBookmark: 'Remove Bookmark Successfully',
  editProjectStatus: 'Project Status Updated Successfully',
  bookmarkProjectStatus: 'Bookmark Status Updated Successfully',
  removeBookamarkText: { message: 'Remove Bookmark Successfully' },
  editProjectStatusInfo: 'Project Updated Successfully',
  projectCreated: { message: 'Project created successfully' },

  //users

  //project

  //company

  //addcomment //packet
  errormsgpacket: 'Download failed. Please try again.',
  successmsgpacket: 'Document downloaded successfully.',
  sucessmsgdepartment: 'Department added successfully.',
  errorwithoutinput: 'Please enter a comment before submitting.',
  errorwithmethod: 'There was an error while adding your comment. Please try again.',
  erroradding: 'The department you are trying to add already exists. Please add a different department.'
}
// help.constants.ts
export const HELP_CONTENT = {
  title: 'List of acceptable guarantors:',
  list: {
    content: 'Abominable Pictures Inc.,Amblin Partners,Awesomeness,Blumhouse Productions LLC.($10k per show),DreamWorks,Electus LCC,FremantleMedia,Funny or Die($10k per show),IMG(WME-IMG),Lakeshore Entertainment,Legendary Pictures,Marvel,Lionsgate,MRC,Netflix,Starz Entertainment,Tristar Production Inc,STX Entertainment'
  },
  close: 'Close Help >',

};

export const CREDIT_APP_FILTER_TYPES = {
  company: 'company',
  projects: 'projects',
  department: 'department',
  status: 'status',
}

export const CONFIRM_CLOSE_POPUP = {
  confirmCompanyCloseMsg: 'Are you sure you want to discard changes without saving?',
  confirmEditCompanyCloseMsg: 'Are you sure you want to discard changes without saving?',
  yesBtn: 'Yes',
  noBtn: 'No',
  confirmDiscardCompanyCloseMsg: 'Are you sure you want to discard changes without saving?'
}

export const DASHBOARD_TEXT = {
  hi: 'Hi,',
  message: 'Here are the project activities you need to review'
}
export const STUDIO_THIRDPARTY_COMMHOUSE_CONSTANT = {
  creditApplicationTitle: 'Credit Application',
  creditApplicationLeftTitle: 'Credit Application',
  status: 'Status',
  documents: 'Documents',
  downloadPacket: 'Document Packet',
  companyInfo: 'Company Info',
  projectTitle: 'PROJECT:',
  project: 'The new show',
  companyTitle: 'Comapny:',
  company: 'A Brand New Production Company',
  typeTitle: 'TYPE:',
  type: 'Major Studio',
  applicationDateTitle: 'APPLICATION DATE:',
  applicationDate: '1/1/24',
  viewProjQuote: 'View Project & Quotes',
  viewCompany: 'View Company',
  purchaseOrder: 'Purchase Order',
  poPdf: 'PO.pdf',
  view: 'View',
  certificateOfInsurance: 'Certificate of Insurance',
  coiPdf: 'COI.pdf',
  viewDocumentActivity: 'View Documents Activity',
  creditApplicationFormActivityTitle: 'Credit Application Form Activity',
  creditApplicationFormActivity: [
    { userIconText: 'AN', commentBy: 'Andrea Noj', timeStamp: 'xx/xx/xx xx:xxAM', activity: 'Reviewed Certificate of Insurance' },
    { userIconText: 'AN', commentBy: 'Andrea Noj', timeStamp: 'xx/xx/xx xx:xxAM', activity: 'Reviewed Certificate of Insurance' }
  ]
};

export const projectTypeData = [
  { id: 1, text: 'Feature' },
  { id: 2, text: 'Television' },
  { id: 3, text: 'Live Production/Rehearsal' },
  { id: 4, text: 'Commercial' },
  { id: 5, text: 'Still Shoot' },
  { id: 6, text: 'Special Event' },
  { id: 7, text: 'Other' },
];

export const PROJECT_TYPE = {
  feature: 'Feature',
  television: 'Television',
  liveProduction: 'Live Production/Rehearsal',
  commercial: 'Commercial',
  stillShoot: 'Still Shoot',
  specialEvent: 'Special Event',
  other: 'Other',
}

export const SP_CREDITAPP_DASHBOARD = {
  filters: 'FILTERS:',
  alertMessage: 'There are updates to the credit application(s) that need your review.',
  viewAll: 'View All',
  refresh: 'Refresh',
  newApplication: 'in New Application',
  revisionSubmitted: 'in Revisions Submitted',
  pendingApprobal: 'in Pending Approval.',
  pendingRisk: 'in Pending Risk.',
  popUp: 'Please use a wider screen to display the Credit Application',
  ok: 'OK',
  reload: 'reload',
  alldepartment: 'All Departments'
}
export const SP_CREDITAPP_STATUS = {
  newApplication: 'NEW APPLICATION',
  approved: 'APPROVED',
  denied: 'DENIED',
  needsRevisions: 'NEEDS REVISIONS',
  needsApproval: 'NEEDS APPROVAL',
  revisionsSubmitted: 'REVISIONS SUBMITTED',
  pendingRisk: 'PENDING RISK',
  underReview: 'UNDER REVIEW',
  approvedRisk: 'APPROVED (RISK)',
  sendToCustomer: 'SEND TO CUSTOMER',
  sentToCustomer: 'SENT TO CUSTOMER',
  startApplication: 'Start Application',
  finishApplication: 'Finish Application'
}
export const ADD_DEPARTMENT = {
  title: 'Add Departments',
  cancel: 'Cancel',
  save: 'Save',
  close: 'Close'
}
export const NOROWSMESSAGE = {
  noCompaniesData: 'No Companies To Display',
  inValiddata: 'Projects is not available for this company'
}
export const REQUEST_CONSTANTS = {
  makeArequest: '+ Make a Request',
  filter: 'FILTERS:',
  addARequest: 'Add A Request',
  next: 'Next',
  requests: 'Requests',
  clearFilters: 'clear filters',
  close: 'Close',
  projects: 'Projects',
  selectProject:'Select Project',
 savingOrUnsavingText: 'Are you sure you want to close without saving?',
 yes: 'Yes',
 no: 'No',
 assigned: 'ASSIGNED',
 closed: 'CLOSED',
 inProgress: 'IN PROGRESS',
 resolved: 'RESOLVED',
 newRequest: 'NEW REQUEST',
 submitted: 'SUBMITTED',
 cancelled: 'CANCELLED',
 new: 'NEW',
 noRequestData:'No Requests To Display'
}
export const REGEX = {
  TIME_12H_FORMAT: /[0-9:]+|[APM]+/g
};
export const VIEW_REQUEST = {
   title: 'Request',
   formName:'Form Request Name',
   
   
}
export const TITLE_CASE_REGEX = /\b\w/g;
export const COSTUME_FORM = {
  costumeFormName : 'Costumes – Credit Card Authorization',
  costumeTitle: 'Greenlight-Credit Card Authorization',
  costumeAddress1: '4000 Warner Blvd, Building 153 Lower Level',
  costumeAddress2: 'Burbank, California 91522',
  costumePhone: 'Phone: (818) 954-1297 Option 1',
  costumeMail: 'wbcostumesnewaccounts@warnerbros.com',
  costumeHeading1: 'Warner Bros. Studio Operations – Costume',
  costumeHeading2: 'Department (“WBSO Costumes”)',
  costumeCardHolderName: 'Cardholder Name:',
  costumeLastName: 'Last Name:',
  costumeProduction: 'Production Company',
  costumeEmailAddress: 'Email Address',
  costumeJobName:'Job/Show Name',
  costumeRequestor: 'Requestor',
  costumeInstruction1: 'We, the undersigned, shall be responsible for the return of all rented items (the “Articles”) in the same condition as received, reasonable wear and tear excepted.',
  costumeInstruction2: 'We, the undersigned, shall be responsible for any approval, rental and extended rental charges (collectively, “rental charges”) and any other charges associated with loss and damage, cleaning, returns, restocking and/or labor (collectively, “additional charges").',
  costumeInstruction3: 'We, the undersigned, acknowledge that WBSO s liability, if any, for damages arising out of any breach, errors, omissions, interruptions, delays or defects in any of the Articles or services provided by WBSO Costumes shall in no event exceed an amount equal to the amount charged pursuant to this Credit Card Authorization.',
  costumeInstruction4: 'We, the undersigned, will authorize our credit card processor to pay any charges initiated from WBSO Costumes related to the job. For our convenience, we, the undersigned, acknowledge that we may elect to securely store credit card information with WBSO Costume’s third-party credit card processor for current and future transactions with WBSO Costumes. The undersigned also acknowledges that charges may be recurring, and in such event, this authorization shall remain in full force and effect, unless we revoke such authorization through WBSO Costume’s third-party credit card processor.',
  costumeInstruction5: 'We, the undersigned, acknowledge that this authorization allows WBSO Costumes to charge our credit card an initial deposit and any additional deposits to cover rental charges and additional charges, unless we have made other acceptable payment arrangements with WBSO Costumes and such payment arrangements are approved by the WBSO Accounts Receivable Department.',
  costumeSignature: 'Signature',
  costumeUpdatedDate: 'Date',
  costumeParagraph: 'The representatives from our company listed below (“Authorized Users”) are authorized to charge Articles from WBSO Costumes for payment. Only Authorized Users may pull Articles from WBSO Costumes. A receipt for each transaction will be provided to the cardholder of record. Please indicate which, if any, authorized users should be sent invoice copies.',
  costumeAuthorizedHeading: 'Authorized Users',
  costumeAuthName: 'Name',
  costumeAuthEmail: 'Email',
  costumeAuthInvoice: 'Send Invoice Copy?'
}
export const HOTLOCK_FORM = {
  hotlockFormName: 'Hot Lock Stage Request',
  hotlockTitle: 'Greenlight-Hot Lock Stage Request',
  hotlockProductionName: 'Production Company',
  hotlockJobName:'Show Name',
  hotlockRequestor:'Requestor',
  hotlockStage:'Stages(s)',
  hotlockUpdatedDate:'Date',
  hotlockStartdate: 'Start Date',
  hotlockReleaseDate: 'Release Date',
  hotlockRevisedDate: 'Revised Date',
  hotlockUPM:'UPM',
  hotlockCell:'Cell',
  hotlockHome:'Home',
  hotlockAuthName: 'Name',
  hotlockAuthTitle:'PERSONS AUTHORIZED TO OPEN STAGE  (Alphabetize by Last Name)',
  hotlockPerAuthTitle: 'Title',
  hotlockAuthCell: 'Cell',
  hotlockAuthHome: 'Home',
  hotlockEmergeencyTitle: 'After Hours Emergency Contact : ***Required***',
  hotlockemergencyName: 'Name',
  hotlockemergencyTitle: 'Title',
  hotlockemergencyCell: 'Cell',
  hotlockemergencyHome: 'Home',
  hotlockemergencyPrintHome:'Print Name',
  hotlockSignature: 'Signature',
  hotlockemeTitle: 'Title',
  hotlockemergencyHeader: 'After Hours Emergency Contact : ***Required***'
}

export const BURBANK_FORM = {
  burBankFormName: 'Burbank Main Lot Facilities Request',
  burBankTitle: 'Burbank Main Lot Facilities Service Request',
  burBankSubTitle: 'Create a Main Lot Facilities Service Request',
  burbankParagraph1: 'Please use this form to report Burbank Main Lot Facilities Maintenance issues for your building, office or workspace. Select the issue you are reporting from the “Request Type” drop down field. Confirm your location (building number) is accurate in the “Building” field. Please include your office/room number in the “Additional Information” section, you may also include notes and related information in this field. If your issue does not appear in the drop down selections, please reach out to Studio Operations for assistance at 818-954-6777.',
  burBankParagraph2: 'You will receive an email confirmation containing ticket details and who to contact, should you need to update your request. Additional comments made after the initial ticket is submitted, will not be received.',
  burBankParagraph3: 'Note: Please do not use this form for URGENT issues, such as a water leak, power outage, or other facility related critical matters.  Instead call 818-954-1700, to report any URGENT utility related matters.',
  burBankRequestor: 'Requestor',
  burBankUpdatedDate: 'Date',
  burBankProduction: 'Production Company',
  burBankProductionName: 'Production Name',
  burBankBuilding: 'Building',
  burBankLocationDetails: 'Location Details',
  burBankRequestCategory: 'Request Category',
  burBankRequestType: 'Request Type',
  burBankRequestDetails: 'Request Details',
  burBankAttachments: 'Attachments'
}