import { Component, OnInit, Inject, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { HeaderService } from '../../../core/services/header/header.service';
import * as _ from 'lodash';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { forkJoin } from 'rxjs';
import { LoaderService } from '../../../shared/services/loader/loader.service';
import { UserService } from 'src/app/core/services/user-service/user-service.service';
import { userRoleAccessCustCustomerUser, userRoleAccessCustStudioUser, userRoleAccessStudioWBSOUser } from '../../../../assets/constants/userRole';
import { ToastService } from '../../../shared/components/toast.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  userInfoEmitter = new EventEmitter<any>();
  isLoggedIn: any;
  loading: boolean = false;
  overlayState: boolean = false;
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  addUserError: any;
  addUserSuccess: any;
  clearCache: any;
  userClaims: any;
  isToastrTokenTimeoutDisplayed: any;
  sessionTimeoutWarning: any;
  sessionTimeout: any;

  constructor(private headerService: HeaderService, private router: Router,private userService: UserService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private _oktaStateService: OktaAuthStateService, private loaderService: LoaderService,
    private toastService: ToastService  ) {
    this.isAuthenticated();
    this.populateUsernameAndQuickSearch();
  }

  ngOnInit(): void {
    this.loaderService.loading$.subscribe((loading) => {
      this.loading = loading;
    });
    this.loaderService.overlayState$.subscribe((applying) => {
      this.overlayState = applying;
    });
    this.checkAuthentication();
    const user_okta = localStorage.getItem('okta-token-storage');
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
     if (!_.isNil(user_okta) && _.isNil(userProfile)) {
      this.getAuthenticate();
    }
  }

/**
*checkAuthentication() - checkAuthentication method is to clear the okta token completely from localstorage after user in logged out.
*/

  async checkAuthentication() {
    if (!this.isLoggedIn) {
      this._oktaAuth.tokenManager.clear();
    } 
  }

/**
*isAuthenticated() - isAuthenticated is to check the user is logged in or not in the application.
*/

  async isAuthenticated() {
    this.isLoggedIn = await this._oktaAuth.isAuthenticated();
  }

/**
*getAuthenticate() - getAuthenticate method authenticate the first api in the application.
*/

  async getAuthenticate() {
    this.loaderService.setLoadingState(true);
    await this.headerService.getauth().subscribe(
      {
        next: (value: any) => {

           const userProfile = JSON.parse(localStorage.getItem('user-profile'));
          if (_.isNil(userProfile)) {
            this.getuserInfo();
          }
          return value
        },
          error: (error: any) => {
            this.loaderService.setLoadingState(false)
            this.addUserError = error;
            this.showDanger(this.dangerTpl);
            setTimeout(() => {
              this.logout();
            }, 3000);
          }
      }
    )
  }

/**
*getuserInfo() - getuserInfo method gives the logged in user profile information.
*/

  async getuserInfo() {
    const user_okta = (localStorage.getItem('okta-token-storage'));
    if (user_okta) {
      const userEmail = JSON.parse(user_okta).idToken.claims.email;
      await this.headerService.getuserinfo(userEmail).subscribe((data: any) => {
        let roleAssigned: any = [];
        let permissionsData: any = {};
        roleAssigned = data.userProfileDTO.roles;
        let portalType = data.userProfileDTO.portalType;
        if (data.userProfileDTO.userTypeId == 1) {
          let type = 'customer';
          if (portalType.some(item => item.includes(type))) {
            //logic written for customer portal customer user 
            Object.keys(userRoleAccessCustCustomerUser).forEach((menuItem: any) => {
              permissionsData[menuItem] = userRoleAccessCustCustomerUser[menuItem]?.some((role: any) => {
                return roleAssigned.includes(role)
              })
            })
          } else {
            //logic written for customer portal studio user 
            Object.keys(userRoleAccessCustStudioUser).forEach((menuItem: any) => {
              permissionsData[menuItem] = userRoleAccessCustStudioUser[menuItem]?.some((role: any) => {
                return roleAssigned.includes(role)
              })
            })
          }
          
        } else if (data.userProfileDTO.userTypeId == 2) {
            //logic written for studio portal wbso user 
          Object.keys(userRoleAccessStudioWBSOUser).forEach((menuItem: any) => {
            permissionsData[menuItem] = userRoleAccessStudioWBSOUser[menuItem]?.some((role: any) => {
              return roleAssigned.includes(role)
            })
          })
        }
        
        data.userProfileDTO.roleAssigned = permissionsData;
        localStorage.setItem('user-profile', JSON.stringify(data.userProfileDTO));
        const userProfile = JSON.parse(localStorage.getItem('user-profile'));
        this.userService.setUserInfo(data);
        switch (userProfile?.userTypeId) {
          case 1:
            this.router.navigate(['/feature/customer-dashboard/overview']);
            break;
          case 2:
            this.router.navigate(['/feature/studio-dashboard/users']);
            break;
          case 3:
            this.router.navigate(['/feature/ar-dashboard/overview']);
            break;
          default:
            this.router.navigate(['/homepage'])
        }
        if (localStorage.getItem('okta-token-storage')) {
          if (
            JSON.parse(localStorage.getItem('okta-token-storage')!).accessToken !==
            undefined
          ) {
            if (
              JSON.parse(localStorage.getItem('okta-token-storage')!).accessToken
                .accessToken !== undefined
            ) {
              if (
                JSON.parse(localStorage.getItem('okta-token-storage')!).accessToken
                  .accessToken.length > 0
              ) {
                this.populateUsernameAndQuickSearch();
              }
            }
          }
        }
        this.loaderService.setLoadingState(false)
      },
        (error) => {
        this.loaderService.setLoadingState(false)
          console.error(error);
        }
      )
    }
  }

/**
*getSecondsUntilAccessTokenExpires() - invoking the timer for the session for the logged in user.
*/

  getSecondsUntilAccessTokenExpires(): any {
    let expiresAt;
    let numberOfSecondsUntilAccessTokenExpires;
    if (localStorage.getItem('okta-token-storage')) {
      if (
        JSON.parse(localStorage.getItem('okta-token-storage')!).accessToken !==
        undefined
      ) {
        if (
          JSON.parse(localStorage.getItem('okta-token-storage')!).accessToken
            .expiresAt !== undefined
        ) {
          expiresAt = JSON.parse(localStorage.getItem('okta-token-storage')!)
            .accessToken.expiresAt;
        }
      }
    }
    const now = new Date();
    const currentEpochTime = Math.round(now.getTime() / 1000);
    const buffer = 60;
    if (expiresAt && expiresAt > 0) {
      numberOfSecondsUntilAccessTokenExpires =
        expiresAt - currentEpochTime - buffer;
    } else {
      numberOfSecondsUntilAccessTokenExpires = 0;
    }
    return numberOfSecondsUntilAccessTokenExpires;
  }

/**
*populateUsernameAndQuickSearch() - invoking the session timeout process once the user logged in.
*/

  async populateUsernameAndQuickSearch(): Promise<any> {
    this.userClaims = await this._oktaAuth.getUser();
    if (this.userClaims) {
      if (localStorage.getItem('isToastrTokenTimeoutDisplayed')) {
        this.isToastrTokenTimeoutDisplayed = JSON.parse(
          localStorage.getItem('isToastrTokenTimeoutDisplayed')!
        );
      } else {
        this.isToastrTokenTimeoutDisplayed = false;
      }
      if (this.isToastrTokenTimeoutDisplayed !== true) {
        this.createSessionTimeoutWarning();
      }
      this.createSessionTimeout();
    }
  }

/**
*createSessionTimeout() - creating hte session start time from this method
*/

  createSessionTimeout(): any {
    const secondsToExpiration = this.getSecondsUntilAccessTokenExpires();
    this.clearSessionTimeout();
    this.sessionTimeout = setTimeout(() => {
      this.clearSessionTimeout();
      localStorage.setItem('isSessionExpired', 'true');
      this.addUserSuccess =
        'Your secure session is expired. Please login again.';
      this.showSuccess(this.successTpl);
      this.logout();
    }, secondsToExpiration * 1000);
  }

/**
*createSessionTimeoutWarning() - method will invoke 5min before the auto logout functionality.
*/

  createSessionTimeoutWarning(): any {
    const secondsToExpiration = this.getSecondsUntilAccessTokenExpires();
    const secondsBeforeExpirationToWarn = 300;
    this.clearSessionTimeoutWarning();
    this.sessionTimeoutWarning = setTimeout(() => {
      this.clearSessionTimeoutWarning();
      this.addUserError =
        'Session will expire in 5 minutes. Please complete items in progress to avoid losing unsaved changes.';
      this.showDanger(this.dangerTpl);
      localStorage.setItem('isToastrTokenTimeoutDisplayed', 'true');
    }, secondsToExpiration * 1000 - secondsBeforeExpirationToWarn * 1000);
  }

/**
*clearSessionTimeoutWarning() - method will trigger the warning message.
*/
  clearSessionTimeoutWarning(): any {
    clearTimeout(this.sessionTimeoutWarning);
  }

/**
*clearSessionTimeout() - method will trigger the logged warning message.
*/

  clearSessionTimeout(): any {
    clearTimeout(this.sessionTimeout);
  }

  /**
* showSuccess() - Displays a success toast notification with custom content and options.
* @param successTpl - The template reference containing the toast's content.
*/
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }
  /**
     * showDanger() - Displays a danger (error) toast message.
     * This method is called when there is an error in adding the user.
     * @param dangerTpl - The template reference for the danger message to display.
     */
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  async logout() {
    await this.headerService.clearCache().subscribe({
      next: (data: any) => {
        this.clearCache = data.message;
      },
      error: (error: any) => { },
    });
    await this._oktaAuth.signOut();
    localStorage.clear();
    this._oktaAuth.tokenManager.clear();
    localStorage.removeItem('access-token');
    localStorage.removeItem('okta-token-storage');
  }

}
