<div class="icon-container" *ngIf="!showCompaniesIcon && !showCompanyUserIcon && !showARCompaniesIcon && !showARCompanyUserIcon && !showCustomerProjectIcons && !showStudioPortalProjectUsersIcons && !showStudioPortalUsersIcons && !showCustomerCompanyIcons">
  <span class="icon-hover">
    <img src="/assets/images/account-freezed-icon.svg" alt="Freeze Account" data-bs-toggle="tooltip"
      data-bs-placement="top" ngbTooltip="Freeze Account" (click)="openConfirmationModal('content')" />
  </span>
  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top">
    <img src="/assets/images/user-deactivate-icon.svg" alt="Deactivate User" ngbTooltip="Deactivate User"
      (click)="openConfirmationModal('content')" />
  </span>
  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top">
    <img src="/assets/images/user-remove-icon.svg" alt="Remove User" ngbTooltip="Remove User" />
  </span>
</div>

<div class="icon-container" *ngIf="showCompaniesIcon">
  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top" ngbTooltip="Add a Project"
    (click)="addProjectIcon()">
    <img src="/assets/images/disabled-icon.svg" alt="Add a Project" />
  </span>
  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top" ngbTooltip="Add a User"
    (click)="addUserIcon()">
    <img src="/assets/images/add-user-icon.svg" alt="Add a User" />
  </span>

  <span  *ngIf="gridData.status == 'ACTIVE' && permissionsData['disableCompany']" class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top"
    ngbTooltip="{{ gridData.status == 'DISABLED' ? 'Enable Company' : 'Disable Company' }}">
    <img src="/assets/images/disable-icon.svg"
      *ngIf="gridData.status == 'ACTIVE' && permissionsData['disableCompany']"
      alt="Disable Company" (click)="gridData?.activeProjects?.length === 0 ? openConfirmationModal('disable-company') : this.showDanger(this.restrictDisableCompanyTpl)" />
  </span>
  <span *ngIf="gridData.status == 'DISABLED' && permissionsData['disableCompany']" class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top"
    ngbTooltip="{{ gridData.status == 'DISABLED' ? 'Enable Company' : 'Disable Company' }}">
    <img src="/assets/images/enable-icon.svg" *ngIf="gridData.status == 'DISABLED' && permissionsData['disableCompany']"
      alt="Enable Company" (click)="openConfirmationModal('enable-company')" />
  </span>
</div>
<div class="icon-container" *ngIf="showARCompaniesIcon">
  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top" 
    ngbTooltip="Add a Project" (click)="addProjectIcon()">
    <img src="/assets/images/disabled-icon.svg" alt="Add a Project" />
  </span>
  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top"  ngbTooltip="Add a User"
    (click)="addUserIcon()">
    <img src="/assets/images/add-user-icon.svg" alt="Add a User" />
  </span>
</div>
<div class="icon-container" *ngIf="showCompanyUserIcon">

  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top"
    *ngIf="gridData.status == 'ACTIVE' && permissionsData['deactivateUser']">
    <img src="/assets/images/user-deactivate-icon.svg" alt="Deactivate User" ngbTooltip="Deactivate User"
      (click)="openConfirmationModal('deactivate')" />
  </span>

  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top"
    *ngIf="gridData.status == 'DEACTIVATED' && permissionsData['deactivateUser']">
    <img src="/assets/images/activated.icon.svg" alt="Activate User" ngbTooltip="Activate User"
      (click)="openConfirmationModal('activate')" />
  </span>


</div>
<div class="icon-container" *ngIf="showARCompanyUserIcon">
  
  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top" *ngIf="gridData.status == 'ACTIVE' && permissionsData['deactivateUser']"
    >
    <img src="/assets/images/user-deactivate-icon.svg" alt="Deactivate User" ngbTooltip="Deactivate User"
      (click)="openConfirmationModal('deactivate')" />
  </span>

  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top" *ngIf="gridData.status == 'DEACTIVATED' && permissionsData['deactivateUser']"
    >
    <img src="/assets/images/activated.icon.svg" alt="Activate User" ngbTooltip="Activate User"
      (click)="openConfirmationModal('activate')" />
  </span>


</div>
<div class="icon-container" *ngIf="showCustomerCompanyIcons">
  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top"
    *ngIf="gridData.statusActiveInd == 'active'">
    <img *ngIf="disableIcon" src="/assets/images/user-deactivate-icon.svg" alt="Deactivate User" ngbTooltip="Deactivate User"
      (click)="openConfirmationModal('deactivate')" />
  </span>
  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top"
    *ngIf="gridData.statusActiveInd == 'deactivated'">
    <img src="/assets/images/activated.icon.svg" alt="Activate User" ngbTooltip="Activate User"
      (click)="openConfirmationModal('activate')" />
  </span>
</div>

<div class="icon-container" *ngIf="showCustomerProjectIcons">
  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top"
    *ngIf="gridData.status == 'ACTIVE' && permissionsData['deactivatUser']">
    <img src="/assets/images/user-deactivate-icon.svg" alt="Deactivate User" ngbTooltip="Deactivate User"
      (click)="openConfirmationModal('deactivate')" />
  </span>

  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top"
    *ngIf="gridData.status == 'DEACTIVATED' && permissionsData['deactivatUser']">
    <img src="/assets/images/activated.icon.svg" alt="Activate User" ngbTooltip="Activate User"
      (click)="openConfirmationModal('activate')" />
  </span>

  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top"
    *ngIf="permissionsData['removeProjectUser']">
    <img src="/assets/images/user-remove-icon.svg" alt="Remove User" ngbTooltip="Remove User"
      (click)="openConfirmationModal('remove')" />
  </span>
</div>

<div class="icon-container" *ngIf="showStudioPortalProjectUsersIcons">

  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top" *ngIf="gridData.status == 'ACTIVE' && permissionsData['deactivateUser']">
    <img src="/assets/images/user-deactivate-icon.svg" alt="Deactivate User" ngbTooltip="Deactivate User"
      (click)="openConfirmationModal('deactivate')" />
  </span>

  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top" *ngIf="gridData.status == 'DEACTIVATED' && permissionsData['deactivateUser']">
    <img src="/assets/images/activated.icon.svg" alt="Activate User" ngbTooltip="Reactivate User"
      (click)="openConfirmationModal('activate')" />
  </span>

  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top" title="Remove User" *ngIf="gridData.accountType !== 'Company admin' && permissionsData['removeCustomerUser']">
    <img src="/assets/images/user-remove-icon.svg" alt="Remove User" ngbTooltip="Remove User"
      (click)="openConfirmationModal('remove')" />
  </span>
</div>

<div class="icon-container" *ngIf="showStudioPortalUsersIcons">
  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top"
    *ngIf="gridData.status == 'ACTIVE' && permissionsData['deactivateUser'] ">
    <img  *ngIf="disableIcon" src="/assets/images/user-deactivate-icon.svg" alt="Deactivate User" ngbTooltip="Deactivate User"
      (click)="openConfirmationModal('deactivate')" 
  />
  </span>

  <span class="icon-hover" data-bs-toggle="tooltip" data-bs-placement="top"
    *ngIf="gridData.status == 'DEACTIVATED'&& permissionsData['deactivateUser']">
    <img src="/assets/images/activated.icon.svg" alt="Activate User" ngbTooltip="Activate User"
      (click)="openConfirmationModal('activate')" />
  </span>
</div>

<ng-template #confirmationModal let-modal>
  <div class="modal-body">
    <h4 class="section-main-header">{{ modalTitle }}</h4>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-secondary btn" (click)="handleAction()">
        {{ iconsRenderer.ok }}
      </button>
      <button type="button" class="common-btn-light btn" (click)="modal.close()">
        {{ iconsRenderer.cancel }}
      </button>
    </div>
  </div>
</ng-template>
<ng-template #confirmationModalDisableCompany let-modal>
  <div class="modal-body">
    <h4 class="section-main-header">{{ modalTitle }}</h4>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-secondary btn" (click)="handleAction()">
        {{ iconsRenderer.yes }}
      </button>
      <button type="button" class="common-btn-light btn" (click)="modal.close()">
        {{ iconsRenderer.no }}
      </button>
    </div>
  </div>
</ng-template>
<ng-template #restrictDisableCompanyTpl>
  <div class="danger">
      <span>{{iconsRenderer.restrictDisableCompany}}</span>
  </div>
</ng-template>
