import { Component } from '@angular/core';
import { WBSO } from 'src/app/core/constants/core.constant';
import { NUMBER } from 'src/app/core/enum/core.enum';
import { CompanyService } from 'src/app/feature/studio-portal/services/company.service';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';

@Component({
  selector: 'app-wbso-handbook',
  templateUrl: './wbso-handbook.component.html',
  styleUrls: ['./wbso-handbook.component.scss']
})
export class WbsoHandbookComponent {
  constants = WBSO;
  number = NUMBER;
  portalType: string;
  isAuthenticated: boolean = false;
  onTheLotCountryName: any;

  constructor(private authService: AuthService,private companyService: CompanyService) {}


  ngOnInit(): void {
    this.checkAuthentication();
  }

  private async checkAuthentication(): Promise<void> {
    this.isAuthenticated = await this.authService.isAuthenticated();
    if (this.isAuthenticated) {
      const userProfileData = localStorage.getItem('user-profile');
      if (userProfileData) {
        const userProfile = JSON.parse(userProfileData);
        this.setPortalType(userProfile);
        this.onTheLotCountryName = userProfile?.userPreferences?.onTheLotCountry?.onTheLotCountryName ?  userProfile.userPreferences?.onTheLotCountry?.onTheLotCountryName : '';

      }
    }
  }

  private setPortalType(userProfile: any): void {
    if (userProfile.userTypeId === 1) {
      this.portalType = "customer";
    } else if (userProfile.userTypeId === 2) {
      this.portalType = "studio";
    }
  }
}
