import { Component, OnInit } from '@angular/core';
import { WEATHER_FORECAST_CONSTANTS } from '../../../constants/customer-portal.constant';
import { WeatherService } from '../../../services/weather.service';
import { INDEX, WEATHER_LOCATION } from '../../../enum/customer-portal.enum';
import { SettingsService } from 'src/app/shared/services/settings/settings.service';

@Component({
  selector: 'app-weather-forecast',
  templateUrl: './weather-forecast.component.html',
  styleUrls: ['./weather-forecast.component.scss']
})
export class WeatherForecastComponent implements OnInit {
  weatherData: any = {};
  weatherConstants = WEATHER_FORECAST_CONSTANTS;
  weatherLatitudes = WEATHER_LOCATION;
  index = INDEX;

  burbankCoords = { lat: this.weatherLatitudes.burbankLatitude, lon: this.weatherLatitudes.burbankLongitude };
  leavesdenCoords = { lat: this.weatherLatitudes.leavesdenLatitude, lon: this.weatherLatitudes.leavesdenLongitude };


  isBurbank = true;
  profileSubscription: any;
  profile: any;
  preferentTemperatureId: number;
  userProfile:any;
  permissionsData: any;

  constructor(private weatherService: WeatherService, private profileService: SettingsService) { }

  ngOnInit() {
    this.userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.permissionsData = { ...this.userProfile.roleAssigned };
    this.changeTemperatureUnit()
    this.getWeatherData();
  }

  /**
   * changeTemperatureUnit() - to get the temperature unit id based on change in user-profile
   */
  changeTemperatureUnit(): void{
    const updatedData = JSON.parse(localStorage.getItem("updated-profile"))
    if(updatedData){
      this.preferentTemperatureId = updatedData?.userPreferences?.tempUnitSettings?.tempUnitId;
    }
    else{
      this.preferentTemperatureId= this.userProfile.userPreferences.tempUnitSettings.tempUnitId;
      console.log(this.preferentTemperatureId)
  }
}

  /**
   * getWeatherData() - method to call the weather API
   */
  getWeatherData(): void{
    const coords = this.isBurbank ? this.burbankCoords : this.leavesdenCoords;
    this.weatherService.getWeatherByCoords(coords.lat, coords.lon).subscribe(data => {
      const weatherInfo = data.list[0];
      const kelvinToCelsius = (kelvin: number) => kelvin - this.index.weather_value;
      const kelvinToFahrenheit = (kelvin: number) => (kelvin - this.index.weather_value) * this.index.nine / this.index.five + this.index.thirtyTwo;

      const currentTemp = this.preferentTemperatureId === this.index.one
        ? kelvinToFahrenheit(weatherInfo.main.temp).toFixed(0)  
        : kelvinToCelsius(weatherInfo.main.temp).toFixed(0); 

      const maxTemp = this.preferentTemperatureId === this.index.one
        ? kelvinToFahrenheit(weatherInfo.main.temp_max).toFixed(0)  
        : kelvinToCelsius(weatherInfo.main.temp_max).toFixed(0);  

      const minTemp = this.preferentTemperatureId === this.index.one
        ? kelvinToFahrenheit(weatherInfo.main.temp_min).toFixed(0)  
        : kelvinToCelsius(weatherInfo.main.temp_min).toFixed(0);

        this.weatherData = {
          currentTemp,
          maxTemp,
          minTemp,
          weatherCondition: weatherInfo.weather[0].description,
          pop: (weatherInfo.pop * this.index.hundred),
          image: weatherInfo.weather[0].image
        };
      });
    }

  /**
   * toggleCity() - method to toggle between the cities
   */
  toggleCity(): void {
    this.isBurbank = !this.isBurbank;
    this.getWeatherData();
  }

  /**
   * naviagteToWeatherpage() - method to navigate to weather.com page
   */
  naviagteToWeatherpage(): void {
    const coords = this.isBurbank ? this.burbankCoords : this.leavesdenCoords;
    const weatherUrl = `${WEATHER_FORECAST_CONSTANTS.weatherUrl}/${coords.lat},${coords.lon}`;
    window.open(weatherUrl, '_blank');
  }
}
