import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseApiService } from './base-api/base-api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL_API_PATHS, STUDIO_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';
 
@Injectable({
  providedIn: 'root'
})
export class SearchDataService {
  private searchResultSource = new BehaviorSubject<string[]>([]);
  currentSearchResult = this.searchResultSource.asObservable();
  public searchshow :boolean = false;
 
  constructor(public baseApiService: BaseApiService,private http:HttpClient){}
 
  changeSearchResult(result: string[]) {
    this.searchResultSource.next(result);
  }
  searchUser(query: string, userType: 'customer' | 'wbuser', endpoint: string): Observable<any[]> {
    return this.baseApiService.get(endpoint);
  }
}
 