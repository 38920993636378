<div>
    <button
      class="btn btn-primary"
      (click)="openDeleteConfirmationModal(content)"
    >
      hello
    </button>
  </div>
  <ng-template #content let-modal>
    <div class="page-wrapper">
      <div class="page-header">
        <h2>Send Credit Application Packet</h2>
      </div>
      <div class="title-company-project">
        <div class="company">
          <span class="company-heading">Company:</span>
          <span class="company-content">A Brand New Production Company</span>
        </div>
        <div class="project">
          <span class="project-heading">Project:</span>
          <span class="project-content">The New Show - Pilot</span>
        </div>
      </div>
      <textarea class="text-area" id="credit-text" name="credit-text">Nullam id dolor id nibh ultricies vehicula ut id elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor.</textarea
      >
      <div class="common-checkbox-section">
        <div class="row">
          <h2 class="checkbox-heading">DEPARTMENTS</h2>
          <div class="col-md-12">
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-0"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-0" class="common-checkbox-label">
                All
              </label>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-sm-6 col-12 custom-floating-label">
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-1"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-1" class="common-checkbox-label">
                Construction Services
              </label>
            </div>
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-2"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-2" class="common-checkbox-label">
                Facilities Maintenance
              </label>
            </div>
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-3"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-3" class="common-checkbox-label">
                Food Services
              </label>
            </div>
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-4"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-4" class="common-checkbox-label">
                Motion Picture Imaging
              </label>
            </div>
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-5"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-5" class="common-checkbox-label">
                Photo Lab
              </label>
            </div>
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-6"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-6" class="common-checkbox-label">
                Special Events
              </label>
            </div>
            <div class="common-checkbox-button">
              <input
                type="checkbox"
                id="custom-checkbox-7"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-7" class="common-checkbox-label">
                Transportation
              </label>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-sm-6 col-12 custom-floating-label">
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-8"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-8" class="common-checkbox-label">
                Costume
              </label>
            </div>
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-9"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-9" class="common-checkbox-label">
                Fire & Security
              </label>
            </div>
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-10"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-10" class="common-checkbox-label">
                Mail Services
              </label>
            </div>
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-11"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-11" class="common-checkbox-label">
                Office & Production
              </label>
            </div>
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-12"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-12" class="common-checkbox-label">
                Post Production Sound
              </label>
            </div>
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-13"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-13" class="common-checkbox-label">
                Staff Shop
              </label>
            </div>
            <div class="common-checkbox-button">
              <input
                type="checkbox"
                id="custom-checkbox-14"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-14" class="common-checkbox-label">
                Utilities Services
              </label>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-sm-6 col-12 custom-floating-label">
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-15"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-15" class="common-checkbox-label">
                Design Studio
              </label>
            </div>
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-16"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-16" class="common-checkbox-label">
                First Aid
              </label>
            </div>
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-17"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-17" class="common-checkbox-label">
                Metal Shop
              </label>
            </div>
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-18"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-18" class="common-checkbox-label">
                Parking
              </label>
            </div>
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-19"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-19" class="common-checkbox-label">
                Property
              </label>
            </div>
            <div class="common-checkbox-button mb-4">
              <input
                type="checkbox"
                id="custom-checkbox-20"
                class="common-checkbox-input"
              />
              <label for="custom-checkbox-20" class="common-checkbox-label">
                Studio Operations
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="account-payable-contact">
        <h4 class="account-payable-heading">SEND TO ACCOUNTS PAYABLE CONTACTS:</h4>
        <div class="col-md-12">
          <div class="common-checkbox-button mb-4">
            <input
              type="checkbox"
              id="custom-checkbox-50"
              class="common-checkbox-input"
            />
            <label for="custom-checkbox-50" class="common-checkbox-label ellipses-email" title="Catherine Decker (catherinedecker@abrandnewproductioncompany.com)">
              Catherine Decker (catherinedecker@abrandnewproductioncompany.com)
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="end-wrapper">
      <div class="account-payable-user">
        <img src="assets/images/add-user.svg" alt="add-user" />
        <span class="user-content">Add an Accounts Payable User</span>
      </div>
      <div class="button">
        <button class="common-btn-light btn">Cancel</button>
        <button class="common-btn-secondary btn">Send to Customer</button>
      </div>
    </div>
  </ng-template>
  