import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyService } from 'src/app/feature/studio-portal/services/company.service';


@Injectable({
  providedIn: 'root'
})
export class TypeaheadService {

  constructor(private http: HttpClient,private companyService:CompanyService) { }

  private apiUrlUser = 'assets/typeahead.json'; // Path to your local JSON file
  private apiUrlUserId = 'assets/userid.json'; // Path to your local JSON file
  private apiUrlProjectId = 'assets/projectid.json'; // Path to your local JSON file
  private apiUrljobTtile = 'assets/jobTitle.json'; // Path to your local JSON file
  private apiUrlCompany = 'assets/companyTypeahead.json'; // Path to your local JSON file
  /**
  * searchUser - Invoke the data search for the jobtitle.
  * @param term - term input is expected to search the user using name and email.
  * @returns An Observable that emits a mock response.
  */

  searchUser(term: string): Observable<any[]> {
    let result;
    if (term) {
      result = this.http.get<any[]>(this.apiUrlUser).pipe(
        map(data => data.filter(item => item.firstname.toLowerCase().includes(term.toLowerCase()) ||
          item.email.toLowerCase().includes(term.toLowerCase())
        ))
      );
    }
    else {
      result = of([]);
    }
    return result;
  }

  /**
   * searchJobtitle - Invoke the data search for the jobtitle.
   * @param term - term input is expected to search the jobtitle .
   * @returns An Observable that emits a mock response.
   */

  searchJobtitle(term: string): Observable<any[]> {
    let result;
    if (term) {
      result = this.http.get<any[]>(this.apiUrljobTtile).pipe(
        map(data => data.filter(item => item.jobtitle.toLowerCase().includes(term.toLowerCase())
        ))
      );
    }
    else {
      result = of([]);
    }
    return result;
  }
  searchCompanyOrID(term: string): Observable<any[]> {
    let result;
    if (term) {
      result = this.http.get<any[]>(this.apiUrlCompany).pipe(
        map(data => data.filter(item =>  item.id.toString().includes(term) || 
        item.companyName.toLowerCase().includes(term.toLowerCase())
        ))
      );
    }
    else {
      result = of([]);
    }
    return result;
  }
  /**
   * searchUserId - Invoke the data search for the userId.
   * @param term - term input is expected to search the userId.
   * @returns An Observable that emits a mock response.
   */

    searchUserId(term: string): Observable<any[]> {
      let result;
      if (term) {
        result = this.http.get<any[]>(this.apiUrlUserId).pipe(
          map(data => data.filter(item => item.userId.includes(term)
          ))
        );
      }
      else {
        result = of([]);
      }
      return result;
    }

  /**
   * searchProjectId - Invoke the data search for the projectId.
   * @param term - term input is expected to search the projectId.
   * @returns An Observable that emits a mock response.
   */

    searchProjectId(term: string): Observable<any[]> {
      let result;
      if (term) {
        result = this.http.get<any[]>(this.apiUrlProjectId).pipe(
          map(data => data.filter(item => item.projectId.includes(term)
          ))
        );
      }
      else {
        result = of([]);
      }
      return result;
    }
}
