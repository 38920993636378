<div class="page-wrapper">
	<div class="container px-0">
		<div class="row">
			<div class="comman-title-block">
				<div class="text-blue mb-3 clickable" *ngIf="!page">
					<img src="../../assets/images/keyboard_arrow_left.svg" alt="keyboard-arrow-left" />
					<p class="back-link mb-0" (click)="redirectViewCompany()">
					 {{company_name}}
					</p>
				  </div>
				  <div *ngIf="page == 'gotopage'" class="text-blue mb-3 clickable">
					<img src="../../assets/images/keyboard_arrow_left.svg" alt="keyboard-arrow-left" />
					<p class="back-link mb-0" (click)="viewAllCompanies()">
					{{company.allcompanies}}
				  </div>
				<div class="comman-title-section">
					<h1>{{company_users.companyusers}}</h1>
					<button class="btn btn-dark custom-button"  (click)="addUser()">+ {{company_users.adduser}}</button>
				</div>
				<!-- Filter Section -->
				<div class="comman-filter-block">
					<div class="container">
						<div class="row">
							<div class="col-lg-12 col-12 px-0">
								<div class="comman-filter-title-section">
									<p>{{company_users.filter}}:</p>
								</div>
								<div class="comman-filter-section d-flex flex-wrap align-items-center gap-3">
									<!-- Multiselect Dropdowns -->
									 
								<app-ng-multiselect-dropdown [placeholder]="'ID #'" *ngIf="company_id"
										[settings]="defaultSettings"
										[type]="userIdTypeahead"
										[data]="userId"
										[searchtextplaceholder]="'Type a ID'"
										(onSelect)="selectedId($event)"
										[filterData] ="userIdFilterData"
										>
									</app-ng-multiselect-dropdown>

								<app-ng-multiselect-dropdown [placeholder]="'User'"
										[settings]="defaultSettings"
										[type]="userTypeahead"
										[data]="user"
										[searchtextplaceholder]="'Type a name or email address'"
										(onSelect)="selectedUser($event)"
										[filterData] ="userFilterData">
									</app-ng-multiselect-dropdown>

								<app-ng-multiselect-dropdown [placeholder]="'Status'"
																[settings]="defaultSettings"
																[data]="status"
																(onSelect)="selectedStatus($event)"
																[(ngModel)]="defaultSelectedStatus">
								</app-ng-multiselect-dropdown>
								<app-datepicker [placeholder]="datePickerPlaceholder.lastLogin"
									(dateRangeSelected)="onDateRangeSelected($event)">
								</app-datepicker>
								<app-ng-multiselect-dropdown [placeholder]="'Job Title'"
									[settings]="defaultSettings"
									[type]="userTypeahead"
									[data]="jobtitle"
									[searchtextplaceholder]="'Type a job title'"
									(onSelect)="selectedJobtitle($event)"
									[filterData] ="jobTitleFilterData">
								</app-ng-multiselect-dropdown>
								<app-ng-multiselect-dropdown 
									[placeholder]="'Active Projects'"
									[settings]="defaultSettings"
									[data]="activeprojects"
									(onSelect)="selectedProject($event)"
									>
								</app-ng-multiselect-dropdown>
								</div>
							</div>
						</div>
					</div>
				</div>
				  <!-- Selected filters section -->
				  <div class="selected-filters-section">
					<div *ngIf="isFiltersSelected" class="selected-filters">
					  <div *ngFor="let activeprojects of filteredProjects" class="filter-tag">
						{{ activeprojects.text }}
						<button type="button" (click)="removeSelectedItem('activeprojects', activeprojects)">×</button>
					  </div>
					  <div *ngFor="let status of filteredStatus" class="filter-tag">
						{{ status.text }}
						<button type="button" (click)="removeSelectedItem('status', status)">×</button>
					  </div>
					  <div *ngFor="let user of filteredUser" class="filter-tag">
						{{ user.firstname }}
						<button type="button" (click)="removeSelectedItem('user', user)">×</button>
					  </div>
					  
					<!-- Selected Last login date-->
					<div *ngIf="selectedFromDate && selectedToDate" class="filter-tag">
						{{ formatDateRange(selectedFromDate, selectedToDate) }}
						<button type="button" (click)="clearDateRange()">×</button>
					</div>
					  <div *ngFor="let job of filteredJobtitle" class="filter-tag">
						{{ job.jobtitle }}
						<button type="button" (click)="removeSelectedItem('jobtitle', job)">×</button>
					  </div>
					  <div *ngFor="let item of filteredId" class="filter-tag">
						{{ item.id}}
						<button type="button" (click)="removeSelectedItem('id', item)">×</button>
					  </div>
					<!-- Clear All Filters Button -->
					<a class="text-link" (click)="clearAllFilters()">{{ companyConstants.clearFilters }}</a>
				  </div>
			</div>
			<!-- Ag-grid Table Section -->
			<div *ngIf="rowData?.length != 0" class="common-grid-block">
				<div class="mt-4">
                    <app-common-ag-grid  class="ag-theme-custom" 
						[colDefs]="colDefs"
						 [rowData]="rowData"
						 [colsForSmallScreen]="colsForSmallScreen"
						 [colsForMediumScreen]="colsForMediumScreen"
						 [colsForLargeScreen]="colsForLargeScreen"
						 [colsForExtraLargeScreen]="colsForExtraLargeScreen"
						 (cellClicked)="onCellClicked($event)">
					</app-common-ag-grid>
				</div>
			</div>

			<div *ngIf="rowData?.length == 0 || filteredData?.length == 0" class="no-users-wrapper">
				<h6>{{company.noUsers}}</h6>
			</div>
		</div>
	</div>
</div>
<ng-template #successTpl>
	<div class="success">
		<span *ngIf="!addUserSuccess">{{message.companyUserDeactivated}}</span>
		<span *ngIf="addUserSuccess">{{addUserSuccess}}</span>
	</div>
  </ng-template>
  <ng-template #successEditUserTpl>
	<div class="success">
		<span *ngIf="editUserSuccess">{{editUserSuccess}}</span>
	</div>
  </ng-template>
  <ng-template #dangerEditUserTpl>
	<div class="danger">
		<span *ngIf="editUserError">{{editUserError}}</span>
	</div>
  </ng-template>
  <ng-template #successTplActive>
	<div class="success">{{message.companyUserActivated}}</div>
  </ng-template>
  
  <ng-template #dangerTpl>
	
	<div class="danger">
		<span *ngIf="!addUserError">{{message.errorCompanyUser}}</span>
		<span *ngIf="addUserError">{{addUserError}}</span>
	</div>
  </ng-template>
  
  <app-toast aria-live="polite" aria-atomic="true"></app-toast><ng-template #successTpl>