import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { HomepageComponent } from './feature/components/homepage/homepage.component';
import { HandbookMainComponent } from './feature/components/handbook-main/handbook-main.component';
import { HandbookPageComponent } from './feature/components/handbook-page/handbook-page.component';
import { MapsDirectoryProtocolsComponent } from './feature/components/maps-directory-protocols/maps-directory-protocols.component';
import { OverviewComponent } from './feature/components/overview/overview.component';
import { ProjectsComponent } from './feature/components/projects/projects.component';
import { AccountActivationComponent } from './feature/components/account-activation/account-activation.component';
import { ResetPasswordComponent } from './feature/components/reset-password/reset-password.component';
import { AccountCancelledComponent } from './feature/components/account-cancelled/account-cancelled.component';
import { SignInComponent } from './feature/components/sign-in/sign-in.component';
import { AccountUserComponent } from './feature/components/account-user/account-user.component';
import { CommonModalComponent } from './feature/components/common-modal/common-modal.component';
import { ProjectDetailComponent } from './feature/components/project-detail/project-detail.component';
import { SpectsStageComponent } from './feature/components/spects-stage/spects-stage.component';
import { AccountPageComponent } from './feature/components/account-page/account-page.component';
import { AddCustomerUserComponent } from './feature/components/studio-portal/user/add-customer-user/add-customer-user.component';
import { UserProjectComponent } from './feature/components/user-project/user-project.component';
import { RequestsComponent } from './feature/components/requests/requests.component';
import { FaqsComponent } from './feature/components/faqs/faqs.component';
import { AddUserCustPortalComponent } from './feature/components/add-user-cust-portal/add-user-cust-portal.component';
import { StudioProjectsComponent } from './feature/components/studio-portal/studio-projects/studio-projects.component';
import { ProfileComponent } from './feature/components/profile/profile.component';
import { AddWbUserComponent } from './feature/components/studio-portal/user/add-wb-user/add-wb-user.component';
import { DirectoryComponent } from './feature/components/directory/directory.component';
import { StudioProjectDetailComponent } from './feature/components/studio-portal/studio-project-detail/studio-project-detail.component';
import { UsersComponent } from './feature/components/studio-portal/users/users.component';
import { AddEditCompanyComponent } from './feature/components/studio-portal/add-edit-company/add-edit-company.component';
import { StudioCompanyUserComponent } from './feature/components/studio-portal/studio-company-user/studio-company-user.component';
import { CompanyStudioComponent } from './feature/components/studio-portal/company-studio/company-studio.component';
import { AddEditUserComponent } from './feature/components/studio-portal/add-edit-user/add-edit-user.component';
import { ProjectUserComponent } from './feature/components/studio-portal/project-user/project-user.component';
import { WbsoHandbookComponent } from './core/components/header/wbso-handbook/wbso-handbook.component';
import { WbsoHandbookPageComponent } from './core/components/header/wbso-handbook/wbso-handbook-page/wbso-handbook-page.component';
import { StudioAddCompanyComponent } from './feature/components/studio-portal/studio-add-company/studio-add-company.component';
import { CompaniesComponent } from './feature/components/studio-portal/companies/companies.component';
import { AddArUserComponent } from './feature/components/studio-portal/user/add-ar-user/add-ar-user.component';
import { StudioAddNewProjectComponent } from './feature/components/studio-portal/studio-add-new-project/studio-add-new-project.component';
import { FaqEditComponent } from './feature/components/faq-edit/faq-edit.component';
import { SalesforceFormComponent } from './feature/components/salesforce-form/salesforce-form.component';
import { AuthGuardFn } from './core/services/auth-guard/auth-guard';
import { ErrorComponent } from './errors/error/error.component';
import { SnackbarComponent } from './feature/components/snackbar/snackbar.component';
import { CreditAppComponent } from './feature/components/studio-portal/credit-app/credit-app.component';
import { CreditGridComponent } from './feature/components/ar-portal/credit-grid/credit-grid.component';
import { CreditAcknowledgementModalComponent } from './feature/components/studio-portal/credit-acknowledgement-modal/credit-acknowledgement-modal.component';
import { HelpSlideoutComponent } from './feature/components/help-slideout/help-slideout.component';
import { SetLightingFormComponent } from './feature/components/set-lighting-form/set-lighting-form.component';
import { StudioCreditAppComponent } from './feature/components/studio-credit-app/studio-credit-app.component';
import { ReviewCreditAppComponent } from './feature/components/ar-portal/review-credit-app/review-credit-app.component';
import { ViewPassesComponent } from './feature/components/view-passes/view-passes.component';
import { CustomerCreditform1Component } from './feature/components/customer-creditform1/customer-creditform1.component';
import { StudioDashboardComponent } from './feature/components/studio-portal/studio-dashboard/studio-dashboard.component';
import { FileUploadComponent } from './feature/components/studio-portal/file-upload/file-upload.component';
import { CredAppFormFourComponent } from './feature/components/cred-app-form-four/cred-app-form-four.component';
import { ReviewCredAppComponent } from './feature/components/ar-portal/review-cred-app/review-cred-app.component';
import { ReviewCredAppLastComponent } from './feature/components/ar-portal/review-cred-app-last/review-cred-app-last.component';
import { CreditReviewStepOneComponent } from './feature/components/ar-portal/credit-review-step-one/credit-review-step-one.component';
import { CreditReviewStepTwoComponent } from './feature/components/ar-portal/credit-review-step-two/credit-review-step-two.component';
import { CreditReviewStepThreeComponent } from './feature/components/ar-portal/credit-review-step-three/credit-review-step-three.component';
import { CreditReviewStepFourComponent } from './feature/components/ar-portal/credit-review-step-four/credit-review-step-four.component';
import { CreditReviewSummaryComponent } from './feature/components/ar-portal/credit-review-summary/credit-review-summary.component';
import { CustomerRequestArformsComponent } from './feature/components/customer-request-arforms/customer-request-arforms.component';
import { CredAppFormOneComponent } from './feature/components/cred-app-form-one/cred-app-form-one.component';
import { CredAppFormTwoComponent } from './feature/components/cred-app-form-two/cred-app-form-two.component';
import { CredAppFormThreeComponent } from './feature/components/cred-app-form-three/cred-app-form-three.component';
import { CredstatusPendingRiskComponent } from './feature/components/ar-portal/credstatus-pending-risk/credstatus-pending-risk.component';
import { CredAppStudioComponent } from './feature/components/studio-portal/cred-app-studio/cred-app-studio.component';
import { ViewAttachQuotesComponent } from './feature/components/studio-portal/view-attach-quotes/view-attach-quotes.component';
import { ReviewDocumentComponent } from './feature/components/ar-portal/review-document/review-document.component';
import { CredFromDepartmentModalComponent } from './feature/components/cred-from-department-modal/cred-from-department-modal.component';
import { RiskEmailComponent } from './feature/components/studio-portal/risk-email/risk-email.component';
import { FinalCreditAppDeniedComponent } from './feature/components/ar-portal/final-credit-app-denied/final-credit-app-denied.component';
import { UploadFileComponent } from './shared/components/upload-file/upload-file.component';
import { CodInfoComponent } from './feature/components/ar-portal/cod-info/cod-info.component';
import { ApprovalLetterComponent } from './feature/components/ar-portal/approval-letter/approval-letter.component';
import { UploadCoversheetComponent } from './feature/components/ar-portal/upload-coversheet/upload-coversheet.component';
import { AddEditArComponent } from './feature/components/ar-portal/add-edit-ar/add-edit-ar.component';
import { ApproveCredAppWithriskComponent } from './feature/components/ar-portal/approve-cred-app-withrisk/approve-cred-app-withrisk.component';
import { FinalCredFormComponent } from './feature/components/ar-portal/final-cred-form/final-cred-form.component';
import { StudioPendingRiskComponent } from './feature/components/studio-portal/studio-pending-risk/studio-pending-risk.component';
import { StudioEmailTireOneComponent } from './feature/components/studio-portal/studio-email-tire-one/studio-email-tire-one.component';
import { StudioFinalCredAppFormComponent } from './feature/components/studio-portal/studio-final-cred-app-form/studio-final-cred-app-form.component';
import { ApproveDenyPopupComponent } from './feature/components/ar-portal/approve-deny-popup/approve-deny-popup.component';

import { DeniedCodWithRiskComponent } from './feature/components/ar-portal/denied-cod-with-risk/denied-cod-with-risk.component';

import { ApproveCredReviewAppComponent } from './feature/components/ar-portal/approve-cred-review-app/approve-cred-review-app.component';

import { L2ApproverComponent } from './feature/components/ar-portal/l2-approver/l2-approver.component';
import { DenyRiskEmailComponent } from './feature/components/ar-portal/deny-risk-email/deny-risk-email.component';

import { ThirdPartyCommHouseComponent } from './feature/components/ar-portal/third-party-comm-house/third-party-comm-house.component';
import { ApprovalDelegationComponent } from './feature/components/ar-portal/approval-delegation/approval-delegation.component';
import { EmailActionComponent } from './email-action/email-action.component';


const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'homepage', component: HomepageComponent },
  { path: 'handbook-main', component: HandbookMainComponent },
  { path: 'handbook-page', component: HandbookPageComponent },
  { path: 'maps-directory-protocols', component: MapsDirectoryProtocolsComponent },
  { path: 'account-activation', component: AccountActivationComponent },
  { path: 'account-reset-password', component: ResetPasswordComponent },
  { path: 'user-account-cancelled', component: AccountCancelledComponent },
  { path: 'sign-in', component: SignInComponent },
  { path: 'overview', component: OverviewComponent },
  { path: 'projects', component: ProjectsComponent },
  {path:'account-user',component:AccountUserComponent},
  { path: 'common-modal', component: CommonModalComponent },
  { path: 'project-detail', component: ProjectDetailComponent },
  { path: 'specs-stage', component: SpectsStageComponent },
  { path: 'implicit/callback', component: OktaCallbackComponent },
  { path: 'wbso-handbook', component: WbsoHandbookComponent },
  { path: 'wbso-handbook-page', component: WbsoHandbookPageComponent },
  {path:'salesforce-path', component:SalesforceFormComponent},
  {path:'credit-ack', component:CreditAcknowledgementModalComponent},
  {path: 'setlight', component:SetLightingFormComponent},
  {path:'riskmailtier2', component: EmailActionComponent},
  {path:'studio-dashboard', component:StudioDashboardComponent},
  {
    path: 'feature',
    loadChildren: () => import('./feature/feature.module').then(m => m.FeatureModule),
    canActivate: [OktaAuthGuard, AuthGuardFn],
  },
  {
    path: 'core',
    loadChildren: () => import('./core/core.module').then(m => m.CoreModule)
  },

  { path: 'account-page', component: AccountPageComponent },
  { path: 'user', component: ProjectsComponent },
  { path: 'user-project', component: UserProjectComponent },
  {path:'faqs',component:FaqsComponent},
  { path: 'add-user', component: AddCustomerUserComponent  },
  { path: 'requests', component: RequestsComponent },
  { path: 'add-user-cust-portal', component: AddUserCustPortalComponent },
  { path: 'add-user-cust-portal', component: AddUserCustPortalComponent },
  { path: 'studio-projects', component:StudioProjectsComponent },
  {path:'profile',component:ProfileComponent},
  { path: 'add-wb-user', component: AddWbUserComponent },
  {path:'directory',component:DirectoryComponent},
  {path:'user-company',component:StudioCompanyUserComponent},
  { path: 'studio-project-detail', component: StudioProjectDetailComponent },
  {path:'users',component:UsersComponent},
  {path:'company-studio',component:CompanyStudioComponent},
  {path:'add-edit-user',component:AddEditUserComponent},
  {path:'project-users',component:ProjectUserComponent},
  {path:'add-edit-company',component:AddEditCompanyComponent},
  {path:'studio-add-company',component:StudioAddCompanyComponent},
  {path:'companies',component:CompaniesComponent},
  { path: 'add-ar-user', component: AddArUserComponent },
  {path:'studio-add-new-project',component:StudioAddNewProjectComponent},
  {path:'faqs-edit',component:FaqEditComponent},
  {path:'page-not-found',component:ErrorComponent},
  {path:'snackbar',component:SnackbarComponent},
  {path:'credit-app',component:CreditAppComponent},
  {path:'credit-grid',component:CreditGridComponent},
  {path:'help-slide',component:HelpSlideoutComponent},
  {path:'customer-credit-grid',component:StudioCreditAppComponent},
  {path:'review-credit-app',component:ReviewCreditAppComponent},
  {path:'view-passes',component:ViewPassesComponent},
  {path:'customer-creditform1',component:CustomerCreditform1Component},
  {path:'file-upload',component:FileUploadComponent},
  {path:'cred-app-form-four', component:CredAppFormFourComponent},
  {path:'review-cred-app-2',component:ReviewCredAppComponent},
  {path:'review-cred-app-3',component:ReviewCredAppLastComponent},
  {path:'credit-review-step-one', component:CreditReviewStepOneComponent},
  {path:'credit-review-step-two', component:CreditReviewStepTwoComponent},
  {path:'credit-review-step-three', component:CreditReviewStepThreeComponent},
  {path:'credit-review-step-four', component:CreditReviewStepFourComponent},
  {path:'credit-review-summary', component:CreditReviewSummaryComponent},
  {path:'cust-req-arform', component:CustomerRequestArformsComponent}, 
  {path:'cred-app-form-one', component:CredAppFormOneComponent},
  {path:'cred-app-form-two', component:CredAppFormTwoComponent},
  {path:'cred-app-form-three', component:CredAppFormThreeComponent},
  {path:'cred-status-pending-risk', component:CredstatusPendingRiskComponent},
  {path:'cred-app-studio',component:CredAppStudioComponent},
  {path:'view-quotes', component:ViewAttachQuotesComponent},
  {path:'review-document',component:ReviewDocumentComponent},
  {path:'cred-form-dept', component:CredFromDepartmentModalComponent},
  {path:'risk-email',component:RiskEmailComponent},
  {path:'credit-denied',component:FinalCreditAppDeniedComponent},
  {path:'upload-file',component:UploadFileComponent},
  {path:'approve-deny-popup',component:ApproveDenyPopupComponent},
  {path:'cod-info',component:CodInfoComponent},
  {path:'approval-letter',component:ApprovalLetterComponent},
  {path:'upload-cover-sheet',component:UploadCoversheetComponent},
  {path:'add-edit-ar',component:AddEditArComponent},
  {path:'third-part-comm-house',component:ThirdPartyCommHouseComponent},
  {path:'approve-credit-app',component:ApproveCredAppWithriskComponent},
  {path:'final-cred-app',component:FinalCredFormComponent},
  {path:'studio-pending-risk',component:StudioPendingRiskComponent},
  {path:'RiskEmailTier',component:StudioEmailTireOneComponent},
  {path:'studio-final-cred-form',component:StudioFinalCredAppFormComponent},

  {path:'denied-with-risk',component:DeniedCodWithRiskComponent},


  {path:'approve-review-cred-app',component:ApproveCredReviewAppComponent},

  {path:'l2-approver',component:L2ApproverComponent},
  {path:'deny-risk-email',component:DenyRiskEmailComponent},
  {path:'approval-deligation',component:ApprovalDelegationComponent},
  { path: '**', redirectTo: '/page-not-found', pathMatch: 'full'},
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
