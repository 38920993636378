import { Component, TemplateRef } from '@angular/core';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-toast',
  template: `
  <ngb-toast
    *ngFor="let toast of toastService.toasts"
    [autohide]="true"
    [delay]="toast.delay || 3000"
    (hidden)="toastService.remove(toast)">
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>
    <ng-template #text>{{ toast.textOrTpl }}</ng-template>
  </ngb-toast>
  `,
  host: { 'class': 'toast-container position-fixed top-0 end-0 p-3', 'style': 'z-index: 2200' }
})
export class ToastComponent {
  constructor(public toastService: ToastService) {}

  /**
   * isTemplate() - Method to check if the toast content is a TemplateRef
   * @param toast - The toast object to check for TemplateRef content
   * @returns - Returns true if the toast content is a TemplateRef, otherwise false
   */
  isTemplate(toast: { textOrTpl: any; }) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
