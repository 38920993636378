import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { footer } from '../../constants/core.constant';
import { UserService } from '../../services/user-service/user-service.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentPortal: string;
  constants = footer;
  portalType: string = '';
  userTypeId: any;
  userDetails: any;
  userRole: any;
  logoPath: string;
  isAuthenticated: boolean = false;
  loading: boolean = true;

  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private router: Router,
    private userService: UserService
  ) {
    
  }

  
  

  ngOnInit(): void {
    this.getUserInfo();
    this.setLogoPath();
  }

  
  private setLogoPath() {
    const currentEnvironment = environment.environment;

    switch (currentEnvironment) {
      case 'dev':
        this.logoPath = 'https://greenlight-dev.wbd.com/assets/images/wb-logo-black.svg';
        break;
      case 'qa':
        this.logoPath = 'https://greenlight-qa.wbd.com/assets/images/wb-logo-black.svg';
        break;
      default:
        this.logoPath = 'https://greenlight-dev.wbd.com/assets/images/wb-logo-black.svg';
        break;
    }
  }
  /**
   * Redirects the user to a specific dashboard based on portal type
   */
  redirectToDashboard(): void {
    switch (this.portalType) {
      case 'customer':
        this.router.navigate(['/feature/customer-dashboard/overview']);
        break;
      case 'wbso':
        this.router.navigate(['/feature/studio-dashboard/Mydashboard']);
        break;
      case 'ar':
        this.router.navigate(['/feature/ar-dashboard/overview']);
        break;
      default:
        console.log('Unknown portal type');
    }
  }

  /**
   * Navigates the user based on their login status
   */
  navigateOnLogoClick(): void {
    if (this.isAuthenticated) {
      this.redirectToDashboard();
    } else {
      this.router.navigate(['/homepage']);
    }
  }

  /**
   * Sets the portal type based on user type and roles
   */
  setPortalType(): void {
    if (this.userTypeId == 1) {
      this.portalType = 'customer';
    } else if (this.userTypeId == 2) {
      if (Array.isArray(this.userDetails.roles) && this.userDetails.roles.includes('A/R')) {
        this.portalType = 'ar';
      } else {
        this.portalType = 'wbso';
      }
    }
  }

  /**
   * Fetches user info and updates component state
   */
  getUserInfo(): void {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    if (userProfile) {
      this.userTypeId = userProfile.userTypeId;
      this.userDetails = userProfile;
      this.setPortalType();
      this.isAuthenticated = true;
    }
    this.userService.userInfo$.subscribe((userInfo) => {
      if (userInfo) {
        let userDetails = userInfo?.userProfileDTO;
        this.userTypeId = userDetails?.userTypeId;
        this.userDetails = userDetails;
        this.setPortalType();
        this.isAuthenticated = true;
      }
      this.loading = false;
    });
  }
}
