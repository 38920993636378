export const AR_FILTERS = {
  filters: 'FILTERS:',
  clearFilters: 'Clear Filters'
}

export const companyTypeahead = [
  { id: 1, text: 'companiesTypeahead' },
];

export const projectsTypeahead = [
  { id: 1, text: 'activeProjectsTypeahead' },
];

export const departmentsTypeahead = [
  { id: 1, text: 'departmentsTypeahead' },
];

export const AR_DASHBOARD = {
  greetings: 'Hi,',
  filters: 'FILTERS:',
  toggleText: 'Status letter/notification not sent'
}

export const FILTER_TYPES = {
  companies: 'company',
  projects: 'project',
  departments: 'department',
  status: 'status',
}

export const AR_STATUS = {
  newApplication: 'NEW APPLICATION',
  approved: 'APPROVED',
  denied: 'DENIED',
  needsRevisions: 'NEEDS REVISIONS',
  needsApproval: 'NEEDS APPROVAL',
  revisionsSubmitted: 'REVISIONS SUBMITTED',
  pendingRisk: 'PENDING RISK',
  underReview: 'UNDER REVIEW',
  approvedRisk: 'APPROVED (RISK)',
  sendToCustomer: 'SEND TO CUSTOMER',
  sentToCustomer: 'SENT TO CUSTOMER'
}

export const NOTIFICATION_NOT_SENT = ['APPROVED', 'APPROVED (RISK)', 'DENIED'];

export const DROPDOWNSETTINGS = {
  id: "id",
  textField: "text",
  limitSelection: -1,
  maxHeight: 197,
  itemsShowLimit: 999999999999,
  searchPlaceholderText: "Search",
  noData: "No data available",
  noFilteredData: "No filtered data available",
}

export const VALIDATION_MSG = {
  required:'This field is required',
  numbersAllowed: 'Allowed values between 1 and 100',
  maxCharacters: 'Maximum 1024 characters allowed.',
}

export const ERROR_MSG = {
  formError: {message: 'Please fill all the required fields'}
}

export const AR_REVIEW = {
  reviewTitle: 'Review',
  companyInfo: 'Company Info',
  project: 'PROJECT:',
  company: 'Company:',
  type: 'TYPE:',
  applicationDate: 'APPLICATION DATE:',
  departments: 'DEPARTMENTS:',
  viewProjectQuotes: 'View Projects & Quotes',
  viewCompany: 'View Company',
  applicationForm: 'Application Form',
  certificationOfInsurance: 'Certification of Insurance',
  termsConditions: 'Terms & Conditions',
  dbReport: 'D&B Report',
  summary: 'Summary',
  downloadPacket: 'Download Packet',
  lineOfCredit: 'Line of Credit',
  lineofCreditRequested: 'Line of Credit requested:',
  anticipatedJobTotal: 'Anticipated Job Total:',
  totalofMonths: 'Total # of months:',
  letterofCreditGuarantee: 'Letter of Credit/Guarantee',
  comments: 'Comments:',
  companyText: 'Company',
  name: 'Name:',
  address: 'Address:',
  phone: 'Phone:',
  useForBillingAddress: 'Use for billing address',
  federalTaxID: 'Federal Tax ID #',
  tin: 'TIN:',
  accountPayableContact: 'Account Payable Contact',
  email: 'Email:',
  companyProfile: 'Company Profile',
  profile: 'Profile:',
  dateStarted: 'Date Started:',
  typeofBusiness: 'Type of Business:',
  totalAnnualSales: 'Total Annual Sales:',
  netWorth: 'Net worth:',
  noOfEmployees: '# of Employees:',
  principalsOfficersOrPartners: 'Principals/Officers or Partners',
  title: 'Title:',
  bankingReferences: 'Banking References',
  bank: 'Bank:',
  accountNumber: 'Account Number:',
  tradeReference1: 'Trade Reference 1',
  contact: 'Contact:',
  uploadDocument: 'Upload Document',
  tradeReference2: 'Trade Reference 2',
  tradeReference3: 'Trade Reference 3',
  commentsText: 'Comments',
  certificateOfInsurance: 'Certificate of Insurance',
  producer: 'Producer:',
  insured: 'Insured:',
  view: 'View',
  remove: 'Remove',
  certificateOfInsuranceActivity: 'Certificate of Insurance Activity',
  termsAndConditionsActivity: 'Terms & Conditions Activity',
  NoActivity: 'No Activity',
  signature: 'Signature:',
  date: 'Date:',
  nextCertificateofInsurance: 'Next: Certificate of Insurance',
  nextTermsAndConditions: 'Next: Terms & Conditions',
  nextdbReport: 'Next: D&B Report',
  nextSummary: 'Next: Summary',
  uploadDBReport: 'Upload D&B Report',
  deliquencyScore: 'Deliquency Score:',
  failureScore: 'Failure Score:',
  paydexScore: 'PAYDEX® Score:',
  modalError:'Modal was dismissed or error occurred:',
  applicationFormActivity: 'Application Form Activity',
  dbReportActivity: 'D&B Report Activity'
}

export const AR_REVIEW_FORMTWO = {
  certificateOfInsurance: 'Certificate of Insurance',
  producer: 'Producer:',
  insured: 'Insured:',
  address: 'Address:',
  view: 'View',
  certificateOfInsuranceActivity: 'Certificate of Insurance Activity',
  NoActivity: 'No Activity',
}

export const AR_MODALTEXT = {
  close: 'Close',
  cancel: 'Cancel',
  save: 'Save',
  nextCertificateofInsurance: 'Next: Certificate of Insurance',
  nextTermsAndConditions: 'Next: Terms & Conditions',
  nextSummary: 'Next: Summary'
}

export const AR_REVIEW_FORMSTATUS = [
  { text: "Ask for Revisions", img: "../../../../../assets/images/warning.svg" },
  { text: "Mark as Reviewed", img: "../../../../../assets/images/status-check.svg" },
  { text: "Send Email", img: "../../../../../assets/images/send-email.svg" },
  { text: "Won’t Use", img: "../../../../../assets/images/w-use.svg" },
  { text: "Upload", img: "../../../../../assets/images/upload-icon.svg" },
  { text: "Not Found", img: "../../../../../assets/images/w-use.svg" },
  { text: "Send Revisions Email", img: "../../../../../assets/images/orange-email-icon.svg" },
  { text: "Send For Approval", img: "../../../../../assets/images/pending-icon.svg" },
  { text: "Approve", img: "../../../../../assets/images/approve.svg" },
  { text: "Deny", img: "../../../../../assets/images/approved-email.svg" }
];

export const AR_REVIEW_FORMTYPE = {
  lineOfCredit: 'Line of Credit',
  letterOfCredit: 'Letter of Credit/Guarantee',
  company: 'Company',
  federalTaxId: 'Federal Tax ID #',
  accountPayableContact: 'Account Payable Contact',
  companyProfile: 'Company Profile',
  officersOrPartners: 'Principals/Officers or Partners',
  bankingReferences: 'Banking References',
  tradeReference1: 'Trade Reference 1',
  tradeReference2: 'Trade Reference 2',
  tradeReference3: 'Trade Reference 3',
  certificateOfInsurance: 'Certificate of Insurance',
  termsAndConditions: 'Terms & Conditions',
  dAndBReport: 'D&B Report',
  arReviewSummary: 'AR Reviewer',
  arReviewSummaryL1: 'AR L1',
  arReviewSummaryL2: 'AR L2',
}

export const SELECTED_ACTION = {
  actionValues: 'Actions…', img: ''
}

export const REVISION_MODALTYPE = {
  revisions: 'Revisions',
  bankingReference: 'Banking Reference',
  tradeReference: 'Trade Reference',
}

export const AR_TOAST_MESSAGES = {
  dandbFileUploadSuccess: 'D&B Report saved sucessfully',
}

export const ARREVISION = 
  {
     needsRevisions: 'Needs Revisions',
     company: 'Company' ,
     project: 'Project',
     text:'Could you please let us know the contact’s full name?',
     sectionName:'Section Name',
     name:'Name',
     bankReference:'Bank Reference',
     bankReferenceLetter:'Bank Reference Letter',
     textBankReferance:'Dear Vendor, Attached is a letter from Warner Bros. requesting references from your customer. We appreciate your help in this matter.Please contact WBSFAR@warnerbros.com if there are any questions.Thanks,WBSO A/R',
     tradeReference:'Trade Reference',
     tradeReferenceLetter:'Trade Reference Letter',
     cancel:'Cancel',
     sendEmail:'Send Email',
     saveComment:'Save Comment',
     message:'This field is required.',
     errorMessage:'Maximum 1024 characters allowed.',
     textError:'Only alphanumeric and special characters are allowed.',
     browse:'browse',
   };

   export const FORMVALIDATOR = {
    ALPHANUMERIC: /^[a-zA-Z0-9\s!@#$%^&*()_+={}\[\]:;"'<>,.?\/`~\\|-]*$/
   };

   export const PATTERN = {
    number: '^[0-9]+$'
  }

  export const AR_REVIEW_SUMMARY = {
    summary: 'Summary',
    lineOfCredit: 'Line of Credit',
    lineofCreditGurantee: 'Line of Credit/Guarantee',
    company: 'Company',
    accountPayableContact: 'Account Payable Contact',
    companyProfile: 'Company Profile',
    principalOfficers: 'Principal Officers',
    bankingReference: 'Banking Reference',
    tradeReferences: 'Trade References',
    certificateOfInsurance: 'Certificate of Insurance',
    termsAndConditions: 'Terms & Conditions',
    dbReport: 'D&B Report',
    summaryEvaluation: 'Summary Evaluation',
    bankReference: 'Bank Reference:',
    tradeReference: 'Trade References:',
    db: 'D&B:',
    deliquencyScore: 'Deliquency Score:',
    failureScore: 'Failure Score:',
    paydexScore: 'PAYDEX® Score:',
    sendRevisionsToCustomer: 'Send Revisions to Customer',
    modalCompany: 'COMPANY:',
    modalProject: 'PROJECT:',
    cancel: 'Cancel',
    sendRevision: 'Send Revision',
    approvalActivity: 'Approval Activity',
    sent: 'sent'
  }
  
  export const MESSAGES = {
    successUpload: 'File successfully uploaded',
    errorUpload: 'File upload failed. Please try again.',
    invalidFileType: 'Invalid file type. Allowed types: ',
    fileSizeExceed: 'File size exceeds ',
    bankfileRemoved: 'File removed successfully',
    bankFileRemovingFailed: 'Failed to remove the file from the server',
    tradefileRemoved: 'File removed successfully',
    tradeFileRemovingFailed: 'Failed to remove the file from the server'
  };

  export const FILE_UPLOAD = {
    dragText: 'Drag and Drop your file here, or',
    browse: 'browse',
    addComment: 'Add a Comment',
    cancel: 'Cancel',
    upload: 'Upload'
  };
  export const COL_DEF_FIELDS = {
    icons : 'icons',
    gotopage : 'goToPage',
    mergedUser: 'bella',
    mergedStatus: 'iconStatus'
  }
  export const COLS = {
    companies:[
      {
        colsForLargeScreen :['user', 'status','goToPage','icons'],
        colsForSmallScreen:[ 'bella', 'iconsStatus'],
        colsForMediumScreen:[ 'bella', 'iconsStatus'],
        colsForExtraLargeScreen:['compid','companyName', 'status', 'projects','active','users','goToPage','icons']
      }
    ],
    company_user:[
      {
        colsForSmallScreen :['user-status','icons'],
        colsForMediumScreen:['user-status','icons'],
        colsForLargeScreen:['user', 'status', 'activity','jobTitle','icons'],
        colsForExtraLargeScreen:['userid', 'user', 'status', 'jobTitle','activity','activeProjects','icons']
      }
    ],
  }
  export const COMPANY = {
    companyname: "Sunshine Productions",
    companyid: 'Company ID #',
    projects: 'Projects',
    companyaddress: 'Production Company Address',
    billingaddress: 'Billing Address',
    contactinfo: 'Contact Information',
    companyuser: 'Company Users',
    creditstatus: 'Credit Status',
    adduser: 'Add a User',
    viewall: 'View All',
    valid: 'Valid Until',
    current: 'Current',
    editcompany: 'Edit Company',
    addcompany: 'Add a Company',
    portaltype: 'studio',
    modalType: 'edit',
    addProject:'Add a Project',
    internaluse:'FOR INTERNAL USE ONLY',
    companytype:'Company Type',
    enablecompany:'Enable Company',
    disablecompany:'Disable Company',
    addcustomeruser: 'Add a Customer User',
    addproject:'Add a Project',
    allcompanies:'All Companies',
    addnewproject:'Add A New Project',
    companytitle:'Companies',
    companybtn:'Company',
    enable:'enable',
    disable:'disable',
    notavailable: 'Not Available',
    modalEdit: 'edit',
    modalAdd: 'add',
    componentType: 'studio-company',
    companyerror: 'Company does not exist',
    editCustomerUser: 'Edit A Customer User',
    companyUserType: 'companyuser',
    federalTaxId: 'Federal Tax ID #',
    noUsers: 'No Users To Display'
  };
  export const CUSTOM_ORDER = ['United States', 'United Kingdom', 'Canada'];
  
  export const DEFAULT_STATUS = {
    active : 'active',
    activeCapital : 'ACTIVE',
    activeId : 1
  }
  export const DATEPICKER_CONSTANTS = {
    lastLogin: "Last Login",
    dates: "Dates"
  }
  export const COMPANY_FILTERS = {
    projects: 'Projects',
    filters: 'FILTERS',
    clearFilters: 'Clear Filters'
  }
  export const COMPANY_USERS_FILTER_TYPES = {
    activeprojects: 'activeprojects',
    status: 'status',
    user:'user',
    jobtitle:'jobtitle',
    id:'id'
  }
  export const COMPANY_USERS = {
    companyusers: 'Company Users',
    user: 'User',
    filter: 'Filters',
    adduser: 'Add a User',
  };
  export const DropdownSettings = {
    id: "id",
    textField: "text",
    limitSelection: -1,
    maxHeight: 197,
    itemsShowLimit: 999999999999,
    searchPlaceholderText: "Search",
    noData: "No data available",
    noFilteredData: "No filtered data available",
  }
  export const RESPONSE_STATUS = {
    success : 'success'
  }
  export const GETSTATUS_CLASS = {
    active: 'ACTIVE',
    deactivated: 'DEACTIVATED',
    disabled:'DISABLED'
  }
  export const GO_TO_DROPDOWN=[
    {
      'id':1,
      'name':"User"
    }
  ]
  export const ICON_ACTION_NAMES = {
    freez: 'freeze-account',
    deActivate: 'deactivate-account',
    activate: 'activate-account',
    disableCompany: 'disable-company',
    enableCompany: 'enable-company'
  
  }
  export const MESSAGE = {
    addCompanySuccessMessage: "Company Added Successfully",
    editCompanySuccessMessage: "Company Updated Successfully",
    addCompanyErrorMessage: "Error while adding company",
    editCompanyErrorMessage:"Error while updating company",
    enableCompanySuccessMessage: "Company Enabled Successfully",
    disableCompanySuccessMessage: "Company Disabled Successfully",
    enableCompanyErrorMessage: "Error while enabling company",
    disableCompanyErrorMessage:"Error while disabling company",
    errorCompanies: 'Error Retriving Company',
    companyUserDeactivated: 'Company User Deactivated Successfully',
    companyUserActivated: 'Company User Activated Successfully',
    errorCompanyUser: 'Error Retriving Company Users',
    duplicateFedId: 'Duplicate Federal Tax Id',
    companyUserAddSuccess:'Company User Added Successfully',
    companyUserEditSuccess: 'Company User Updated Successfully',
    stepThreeSuccess: 'Action Saved Successfully'
  }

  export const COMPANY_CREDIT_STATUS=[
    {'id':1,'credit_status':'Current'},
    {'id':2,'credit_status':'Not Available'},
    {'id':3,'credit_status':'Needs Renewal'},
  ]
  export const CONFIRM_COMP_POPUP={
    yes:'Yes',
    no:'No',
    enablecompany:'Do you want to enable this company?',
    disablecompany:'Do you want to disable this company?',
    successEnable: 'Company Enabled Successfully',
    successDisable: 'Company Disabled Successfully',
    errorEnable: 'Company Enabled Error',
    errorDisable: 'Company Disabled Error'
  }

  export const CREDIT_APP_REPORT_SECTION ={
    dnbSection: 'dnb',
    locSection: 'loc',
    coiSection: 'coi',
    trSection: 'traderef1',
    bankReference: 'banking',
    trSection2: 'traderef2',
    trSection3: 'traderef2',
    bankSection: 'banking'
  }

  export const AR_REVIEW_APPLICATION_FORM_ACTIVITY = {
    caSectionName: 'Application_Form_Activity'
  }

  export const AR_REVIEW_ACTION_STATUS = {
    needsRevisions: 'Needs Revisions'
  }

  export const CREDIT_APP_TOAST_MESSAGES = {
    fileMoreThan100MBErrMsg: 'File size exceeds 100 MB limit',
  }

  export const CRED_FORM_ONE_CONSTANT = {
    view: 'View',
    remove: 'Remove',
  }

  //Success message for the API
  export const ARPORTAL_SUCCESS_APIMESSAGE = {
    //credit app
    tradeReferenceSuccess: 'Trade Reference Uploaded Successfully',
  }
