<!-- <div class="common-split-cell-section common-mobile-section-companies">
    <span class="common-split-cell-data-1" [ngClass]="getStatusClass(status)">{{ status }}</span>
    <div class="common-split-cell-data-2">
      <app-icons></app-icons>
    </div>
  </div> -->
  <div class="merged-icon-status">
    <div [ngClass]="getStatusClass(status)">{{ status }}</div>
    <div >
      <app-icons></app-icons>
    </div>
  </div>
  