export interface IDropdownSettings {
  idField?: any;
  textField: any;
  enableCheckAll?: any;
  allowSearchFilter?: any;
  clearSearchFilter?: any;
  maxHeight?: number;
  itemsShowLimit?: any;
  limitSelection?: any;
  searchPlaceholderText?: any;
  noDataAvailablePlaceholderText?: any;
  noFilteredDataAvailablePlaceholderText?: any;
  closeDropDownOnSelection?: any;
  showSelectedItemsAtTop?: any;
  defaultOpen: any;
  allowRemoteDataSearch?: any;
}

export class ListItem {
  id!: String | number;
  text!: String | number;
  isDisabled?: boolean;

  public constructor(source: any) {
    if (typeof source === 'string' || typeof source === 'number') {
      this.id = this.text = source;
    }
    if (typeof source === 'object') {
      this.id = source.id;
      this.text = source.text;
    }
  }
}
