import { Component, inject, TemplateRef, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-studio-project-detail',
  templateUrl: './studio-project-detail.component.html',
  styleUrls: ['./studio-project-detail.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class StudioProjectDetailComponent {
private modalService = inject(NgbModal);

/**
 * Added functions to open modal using ng-bootstrap
 */
openEditProjectDetails(content: TemplateRef<any>) {
  this.modalService.open(content, { windowClass: 'common-modal-center',centered: true });
}
openProjectStatus(status: TemplateRef<any>) {
  this.modalService.open(status, { windowClass: 'common-modal-center',centered: true });
}
openScheduledNote(date: TemplateRef<any>) {
  this.modalService.open(date, { windowClass: 'common-modal-center',centered: true });
}
}
