import { Component } from '@angular/core';
import * as _ from 'lodash';
import {
  DATEPICKER_CONSTANTS,
  PROJECT_DATES_DROPDOWN_LIST,
  PROJECT_DROPDOWN_SETTINGS,
  PROJECT_FILTER_TYPES,
  PROJECTS,
  SORTING_CONSTANTS
} from '../../constants/customer-portal.constant';
import { IDropdownSettings } from 'src/app/shared/models/multiselect.model';
import { INDEX, PAGES, SORT_ID } from '../../enum/customer-portal.enum';
import { Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ProjectsListService } from '../../services/projects-list/projects-list.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent {

  projectsList: any[] = [];
  projectsResponse;
  projectConstants = PROJECTS; // constants data for projects page
  filterTypes = PROJECT_FILTER_TYPES; // constants data for different filters
  public dropdownSettings = {}; // Settings for the dropdown filters

  // Arrays to hold the selected filters for projects, types, and statuses
  filteredProjects: any[] = [];
  filteredTypes: any[] = [];
  filteredStatus: any[] = [];
  filteredData: any[] = [];

  itemsPerPage: number = PAGES.ten; // Number of items to display per page
  currentPage: number; // Current page number
  totalItems: number = this.projectsList?.length; // Total number of project items
  paginatedData: any[] = [];
  isFiltersSelected: boolean = false;
  sortId: number = SORT_ID.default_id_sort_function;//By default setting to 1 so that most recent is clicked
  datesList = PROJECT_DATES_DROPDOWN_LIST;
  projectsFilterData;
  statusFilterData;
  typeFilterData;
  startDate;
  endDate;

  sortData = SORTING_CONSTANTS;
  selectedFromDate: any = null;
  selectedToDate: any = null;
  defaultSettings: IDropdownSettings = PROJECT_DROPDOWN_SETTINGS;
  datePickerPlaceholder = DATEPICKER_CONSTANTS;
  permissionsData: any;

  constructor(
    private router: Router,
    private projectsService: ProjectsListService,
    private toastService: ToastService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.permissionsData = { ...userProfile.roleAssigned };
    this.getProjectsList();
    this.updatePaginatedData();
  }
  /**
   * getProjectsList() - Method to retrieve project data, apply filters, and sort by the most recent projects
   * Fetches the list of user projects and processes the data.
   * Filters for project names, statuses, and types are created and sorted alphabetically.
   * Filters are applied, and sorting is done by the most recent projects.
   */
  getProjectsList(): void {
    this.loaderService.setLoadingState(true);
    let userProfileId = JSON.parse(localStorage.getItem('user-profile'));
    this.projectsService.getProjectsData(userProfileId.userProfileId).subscribe({
      next: (response: any) => {
        if (response) {
          const res = response;
          this.projectsResponse = res;
          this.projectsList = res.userProjects.projects.map(project => {
            this.startDate = project.projectStartDate;
            this.endDate = project.projectEndDate;

            return {
              ...project,
              projectStartDate: this.startDate,
              projectEndDate: this.endDate,
              projectType: project.projectTypeDetails.projectType
            };
          });

          this.getFiltersData();
          this.applyFilters();
          this.sortByMostRecent(this.sortId);
          this.loaderService.setLoadingState(false);
        } else {
          console.warn('Profile data could not be retrieved.');
        }
      },
      error: (error) => {
        console.error('Error fetching user profile:', error);
        this.loaderService.setLoadingState(false);
      },
    });
  }
  /**
 * Parses a date string into a Date object.
 * @param dateString - The date string to parse.
 * @returns A Date object.
 */
  parseDate(dateString: string): NgbDate {
    const date = new Date(dateString);
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  /**
  * Formats a NgbDate object into 'dd/mm/yy' format.
  * @param ngbDate - The NgbDate object to format.
  * @returns A string representing the formatted date.
  */
  formatDate(ngbDate: NgbDate): string {
    const formatToTwoDigits = (num: number) => (num < 10 ? '0' + num : num);
    return `${formatToTwoDigits(ngbDate.day)}/${formatToTwoDigits(ngbDate.month)}/${ngbDate.year.toString().slice(-2)}`;
  }

  /**
   * getFiltersData() - method to get the data for the filters based on the list of projects
   */
  getFiltersData(): void {
    let projectsFilters = _.uniqBy(this.projectsList, 'projectName').map((project, index) => {
      return {
        id: project.projectId,
        text: project.projectName
      };
    });
    this.projectsFilterData = projectsFilters.sort((a, b) => a.text.localeCompare(b.text));

    let statusFilters = _.uniqBy(this.projectsList, 'projectStatus.projectStatus').map((project, index) => {
      return {
        id: project.projectStatus.projectStatusId,
        text: project.projectStatus.projectStatus
      };
    });
    this.statusFilterData = statusFilters.sort((a, b) => a.text.localeCompare(b.text));

    let typeFilters = _.uniqBy(this.projectsList, 'projectTypeDetails.projectType').map((project, index) => {
      return {
        id: project.projectTypeDetails.projectTypeId,
        text: project.projectTypeDetails.projectType
      };
    });
    this.typeFilterData = typeFilters.sort((a, b) => a.text.localeCompare(b.text));
  }

  /**
 * showDanger() - Method to display a danger (error) toast message
 * @param dangerTpl - The template reference for the danger message to display
 */
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
   * updatePaginatedData() - Method to update the data for the current page based on pagination settings
   * startIndex and endIndex are used to slice out the data to display it based on number of items per page selection
   */
  updatePaginatedData(): void {
    const startIndex = (this.currentPage - PAGES.one) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedData = _.slice(this.filteredData, startIndex, endIndex);
  }

  /**
   * selectedProject() - Method to handle selection of projects from the dropdown
   * @param selectedItems - The selected items from the dropdown
   */
  selectedProject(selectedItems: any) {
    this.filteredProjects = selectedItems;
    this.applyFilters(); // Apply filters after selection
  }

  /**
   * selectedType() - Method to handle selection of types from the dropdown
   * @param selectedItems - The selected items from the dropdown
   */
  selectedType(selectedItems: any) {
    this.filteredTypes = selectedItems;
    this.applyFilters(); // Apply filters after selection
  }

  /**
 * Converts an NgbDate object into a string formatted as 'DD/MM/YY'.
 * @param ngbDate - An object of NgbDate containing day, month, and year.
 * @returns A formatted date string in 'DD/MM/YY' format.
 */
  convertNgbDateToString(ngbDate: NgbDate): string {
    const day = ngbDate.day < 10 ? '0' + ngbDate.day : ngbDate.day;
    const month = ngbDate.month < 10 ? '0' + ngbDate.month : ngbDate.month;
    return `${day}/${month}/${ngbDate.year.toString().slice(-2)}`;
  }

  /**
 * onDateRangeSelected() - Method to handle the selected date range emitted from the DatepickerComponent.
 * If only one date is selected, it uses the same date for both fromDate and toDate.
 * @param dateRange - The object containing fromDate and toDate.
 */
  onDateRangeSelected(dateRange: { fromDate: NgbDate | null; toDate: NgbDate | null }) {
    if (dateRange.fromDate && !dateRange.toDate) {
      this.selectedFromDate = dateRange.fromDate;
      this.selectedToDate = dateRange.fromDate;  // Treat single date as both 'from' and 'to'
    }
    else if (dateRange.fromDate && dateRange.toDate) {
      this.selectedFromDate = dateRange.fromDate;
      this.selectedToDate = dateRange.toDate;
    }
    this.applyFilters();
  }

  /**
   * selectedStatus() - Method to handle selection of status from the dropdown
   * @param selectedItems - The selected items from the dropdown
   */
  selectedStatus(selectedItems: any) {
    this.filteredStatus = selectedItems;
    this.applyFilters(); // Apply filters after selection
  }

  /**
   * hasSelectedFilters() - Method to check if any filters are selected.
   * @returns boolean - True if any filters are selected, otherwise false.
   */
  hasSelectedFilters(): boolean {
    this.isFiltersSelected = this.filteredProjects?.length > 0 ||
      this.filteredTypes?.length > 0 ||
      this.filteredStatus?.length > 0 ||
      (this.selectedFromDate !== null && this.selectedToDate !== null);
    return this.isFiltersSelected;
  }

  /**
   * clearAllFilters() - Method to clear a specific filter
   */
  clearAllFilters(): void {
    // Clear Projects
    this.filteredProjects = [];
    this.selectedProject(this.filteredProjects);
    this.projectsFilterData = this.filteredProjects;

    // Clear Types
    this.filteredTypes = [];
    this.selectedType(this.filteredTypes);
    this.typeFilterData = this.filteredTypes;

    // Clear Status
    this.filteredStatus = [];
    this.selectedStatus(this.filteredStatus);
    this.statusFilterData = this.filteredStatus;

    this.selectedFromDate = null;
    this.selectedToDate = null;

    // Re-apply filters to reflect the cleared state
    this.applyFilters();
  }

  /**
   * removeSelectedItem() - Method to remove a specific item from a selected filter
   * @param filterType - The type of filter the item belongs to (project, type, department, status)
   * @param item - The item to be removed
   */
  removeSelectedItem(filterType: string, item: any): void {
    switch (filterType) {
      case this.filterTypes.project: {
        let projectArray = [];
        this.filteredProjects = this.filteredDataList(this.filteredProjects, item);
        projectArray.push(item);
        this.projectsFilterData = projectArray;
      }
        break;
      case this.filterTypes.type: {
        let typeArray = [];
        this.filteredTypes = this.filteredDataList(this.filteredTypes, item);
        typeArray.push(item);
        this.typeFilterData = typeArray;
      }
        break;
      case this.filterTypes.status: {
        let statusArray = [];
        this.filteredStatus = this.filteredDataList(this.filteredStatus, item);
        statusArray.push(item);
        this.statusFilterData = statusArray;
      }
        break;
    }
    this.applyFilters();
  }

  /**
   * filteredDataList() - Method to filter out the selected item from the array
   * @param arr - The array from which the item needs to be removed
   * @param item - The item to be removed
   * @returns The filtered array
   */
  filteredDataList(arr: any[], item: any): any {
    return _.filter(arr, i => i.id !== item.id);
  }

  /**
  * clearDateRange() - method to clear the selected date range.
  */
  clearDateRange() {
    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.applyFilters();
  }

  /**
 * Formats the date range for display.
 * If the fromDate and toDate are the same, display a single date.
 * @param fromDate - The start date.
 * @param toDate - The end date.
 * @returns A string representing the formatted date or date range.
 */
  formatDateRange(fromDate: NgbDate, toDate: NgbDate): string {
    const formatToTwoDigits = (num: number) => num < PAGES.ten ? `${PAGES.zero}${num}` : `${num}`;
    const formattedFromDate = `${formatToTwoDigits(fromDate.day)}/${formatToTwoDigits(fromDate.month)}/${fromDate.year.toString().slice(INDEX.minusTwo)}`;
    const formattedToDate = `${formatToTwoDigits(toDate.day)}/${formatToTwoDigits(toDate.month)}/${toDate.year.toString().slice(INDEX.minusTwo)}`;

    // If both dates are the same, return just the single date
    if (fromDate.equals(toDate)) {
      return `${formattedFromDate}`;
    }
    else {
      return `${formattedFromDate} - ${formattedToDate}`;
    }
  }

  /**
   * applyFilters() - Method to apply selected filters to the project data and update the paginated data
   */
  applyFilters() {
    let filteredData = this.projectsList;
    if (this.filteredProjects?.length) {
      filteredData = _.filter(filteredData, project =>
        _.some(this.filteredProjects, x => _.includes(project.projectName, x.text))
      );
    }

    if (this.filteredTypes?.length) {
      const typeIds = this.filteredTypes.map(type => type.id);
      filteredData = _.filter(filteredData, project =>
        _.includes(typeIds, project.projectTypeDetails.projectTypeId)
      );
    }

    if (this.selectedFromDate && this.selectedToDate) {
      const selectedFromDate = new Date(this.selectedFromDate.year, this.selectedFromDate.month - 1, this.selectedFromDate.day);
      const selectedToDate = new Date(this.selectedToDate.year, this.selectedToDate.month - 1, this.selectedToDate.day);

      // Filter projects that overlap with the selected date range
      filteredData = _.filter(filteredData, project => {
        const projectStartDate = new Date(project.projectStartDate.year, project.projectStartDate.month - 1, project.projectStartDate.day);
        const projectEndDate = new Date(project.projectEndDate.year, project.projectEndDate.month - 1, project.projectEndDate.day);

        return (projectStartDate <= selectedToDate && projectEndDate >= selectedFromDate);
      });
    }

    if (this.filteredStatus?.length) {
      const statusIds = this.filteredStatus.map(status => status.id);
      filteredData = _.filter(filteredData, project =>
        _.includes(statusIds, project.projectStatus.projectStatusId)
      );
    }

    this.hasSelectedFilters();
    this.filteredData = filteredData;
    this.currentPage = PAGES.one;
    this.totalItems = this.filteredData?.length;
    this.updatePaginatedData();
  }

  /**
   * onPageChange() - Method to handle page changes
   * @param page - The new page number to navigate to
   */
  onPageChange(page: number): void {
    this.currentPage = page;
    this.updatePaginatedData(); // Update the paginated data when the page changes
  }

  /**
   * onItemsPerPageChange() - Method to handle changes in the number of items per page
   * @param itemsPerPage - The new number of items to display per page
   */
  onItemsPerPageChange(itemsPerPage: number): void {
    this.itemsPerPage = itemsPerPage;
    this.currentPage = PAGES.one; // Reset to the first page when the items per page changes
    this.updatePaginatedData(); // Update the paginated data with the new items per page setting
  }

  /**
   * sortByMostRecent() - Method to sort the projects list based on created date in descending order
   * *  @param id - an id to indicate the we are hitting sortByMostRecent() function for css to reflect
   */
  sortByMostRecent(id: number): void {
    this.sortId = id;
    this.projectsList = _.orderBy(this.projectsList, ['createdDate'], ['desc']);
    this.applyFilters();
  }

  /**
   * ascendingSort() - Method to sort the projects list in the alphabetical order from A to Z based on the project name
   *  @param id - an id to indicate the we are hitting ascendingSort() function for css to reflect
   */
  ascendingSort(id: number): void {
    this.sortId = id;
    this.projectsList = _.orderBy(this.projectsList, ['projectName'], ['asc']);
    this.applyFilters();
  }

  /**
   * openProjectDetails()- Method to navigate to project details page
   * @param projectId - id of the project to be navigated to
   */
  openProjectDetails(projectId: number) {
    this.router.navigate(['/feature/customer-dashboard/project-details', projectId]);
  }

  getImageUrl(projectType: string): string {
    switch (projectType) {
      case 'Live':
        return 'assets/images/project-live.png';
        case 'Photoshoot':
          return 'assets/images/project-photoshoot.png';
      case 'Television':
        return 'assets/images/project-television.png';
      case 'Special Event':
        return 'assets/images/project-specialevent.png';
      case 'others':
        return 'assets/images/project-other.png';
      case 'Commercial':
        return 'assets/images/project-commercial.png';
      case 'Feature':
        return 'assets/images/project-feature.png';
        case 'Live Production/Rehearsal':
          return 'assets/images/project-live.png';
      default:
        return 'assets/images/project-image1.png';
    }
  }
}
