import { DomLayoutType } from "ag-grid-community";

export enum FILEUPLOAD {
  MAX_SIZE = 1024 * 1024,
}

export const MESSAGES = {
  successUpload: 'File successfully uploaded',
  errorUpload: 'File upload failed. Please try again.',
  invalidFileType: 'Invalid file type. Allowed types: ',
  fileSizeExceed: 'File size exceeds ',
  invalidUser: 'Invalid user. Please try again.',
  invalidCompany: 'Invalid company. Please try again.'
};

export const FileUploadConfig = {
  DNB_REPORT_HEADER: 'D&B REPORT',
  AR_REQ_FILE: 'Request A/R #',
  SELECTED_FILE_LABEL: 'Selected file: ',
};

export const PAGINATION_TEXT = {
  itemsperpage: 'Items per page',
  of: 'of',
};
// help.constants.ts
export const HELP_CONTENT = {
  defaultList :{
  title: 'Title:',
  content: 'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean lacinia bibendum nulla sed consectetur. Vestibulum id ligula porta felis euismod semper.',
  help: 'Need More Help?',
  faqs: 'Read our ',
  linkText: 'FAQs',
  },
  list: {
    title2: 'List of acceptable guarantors:',
    content: 'Abominable Pictures Inc.,Amblin Partners,Awesomeness,Blumhouse Productions LLC.($10k per show),DreamWorks,Electus LCC,FremantleMedia,Funny or Die($10k per show),IMG(WME-IMG),Lakeshore Entertainment,Legendary Pictures,Marvel,Lionsgate,MRC,Netflix,Starz Entertainment,Tristar Production Inc,STX Entertainment'
  },
  close: 'Close Help >',
};


export const maintenaceConst = [
  {
    mainteneceMsg:
      'The WB Theatrical Portal will be down for maintenance starting Friday March 29th from 3:00PM PDT until Sunday March 31st 12:00PM PDT.',
  },
  {
    mainteneceMsg:
      'If you need booking assistance, please email wbportalhelp@warnerbros.com',
  },
];

export const ADD_COMPANY_USER = {
  firstname: 'First Name',
  lastname: 'Last Name',
  email: 'Email',
  jobtitle: 'Job Title',
  optional: 'Optional Settings',
  companyadmin: 'Make the user a company admin',
  accountpay: 'User is an Accounts Payable user',
  verfication:
    ' A verification email will be sent to the new user after approval from WBSO.',
  project: 'Projects',
  active: 'Active',
  close: 'Closed',
  notstarted: 'Not Started',
  adduser: 'Add User',
  edit: 'Edit',
  department: 'Departments',
  permissions: 'Permissions',
  projectview: 'Project (View)',
  projectname: 'The Court - Season 1',
  fieldRequired: 'This field is required',
  firstNameInvalid: 'First Name is not valid',
  lastNameInvalid: 'Last Name is not valid',
  emailInvalid: 'Please enter a valid email address',
  jobTitleInvalid: 'Job title is invalid',
  makeuser: 'Make the user a company admin',
  accountpayable: ' User is an Accounts Payable user',
  spaceerror: 'Please remove any extra spaces before or after the text.',
  twospaceerror: 'Please remove extra spaces between words.',
  noactiveproject: 'There are no active projects associated with this company.',
  noclosedproject: 'There are no closed projects associated with this company.',
  maxLengthExceeded: 'Max length exceeded',
  minLengthErr: 'Min length is 10 characters',
  invalidPhoneNo: 'Invalid phone number',
  activeproject: 'activeproject',
  closedproject: 'closedproject',
  notstartedproject: 'notstartedproject',
  departmentsIds: 'departmentsIds',
  userRoleIds: 'userRoleIds',
  selectCompany: 'Please select a Company'
};

export const ADD_PROJECT_USER = {
  department: 'Departments Access',
  access: 'Access',
  section: 'Sections & Permissions',
  project: 'Project',
  credit: 'Credit Application',
  manageuser: 'Manage Users',
  canadd:
    'Can add, remove users to this project & manage their access and permissions',
  firstname: 'First Name',
  lastname: 'Last Name',
  email: 'Email',
  jobtitle: 'Job Title',
  optional: 'Optional Settings',
  makeuser: 'Make the user a company admin',
  accountpayable: ' User is an Accounts Payable user',
  verification:
    'A verification email will be sent to the new user after approval from WBSO.',
  projectuser: 'Add a User To',
  close: 'Close',
  fieldRequired: 'This field is required',
  firstNameInvalid: 'First name is not valid',
  lastNameInvalid: 'Last name is not valid',
  emailInvalid: 'Please enter a valid email address',
  jobTitleInvalid: 'Job title is invalid',
  phoneRequired: 'This field is required',
  phoneInvalid: 'This is an invalid phone number',
  minLength: 'Min length is 10 characters',
  addUserSuccessMsg: 'Project user added successfully',
  addProjectUserSuccess: 'PROJECT USER ADDED SUCCESSFULLY',
  addUserErrorMsg: 'ERROR CREATING THE USER',
  updateUserSuccess: 'User Updated Successfully',
  updateErrorMessage: 'ERROR IN UPDATING THE USER',
  projectUserStatus: 'Y',
  userId: 'User ID #',
  phoneInvalidNo: 'Invalid phone number',
};

export const SECTION_DATA = [
  {
    "id": 1,
    "name": "Project",
    "roleSection": "Project",
    "permissions": ["Can View", "Can Manage"]
  },
  {
    "id": 2,
    "name": "Credit Applications",
    "roleSection": "Credit Application"
  },
  {
    "id": 3,
    "name": "Manage Users",
    "roleSection": "Manage User"
  }
]


export const SECTION_DATA_VALUE = [
  {
    portaltype: 'customer',
    dbvalue: {
      projectView: 'customer_project view only',
      projectViewMange: 'customer_project view and manage',
      creditViewMange: 'customer_credit application view and manage',
      userViewMange: 'customer_manage users view and manage'
    },
    viewText:
    {
      projectView: "Project (View)",
      projectViewMange: "Project (View and Manage)",
      creditViewMange: "Credit (View and Manage)",
      userViewMange: "User (View and Manage)"
    }
  },
  {
    portaltype: 'studio',
    dbvalue: {
      projectView: 'customer_project view only',
      projectViewMange: 'customer_project view and manage',
      creditViewMange: 'customer_credit application view and manage',
      userViewMange: 'customer_manage users view and manage',
    },
    viewText:
    {
      projectView: "Project (View)",
      projectViewMange: "Project (View and Manage)",
      creditViewMange: "Credit (View and Manage)",
      userViewMange: "User (View and Manage)"
    }
  },
]

export const COMMON_AG_GRID_CONSTANTS = {
  items: 'Items per page',
};

export const COMMON_GRID_OPTIONS = {
  pagination: true,
  paginationPageSize: 10,
  domLayout: 'autoHeight' as DomLayoutType,
  defaultColDef: {
    flex: 1,
    minWidth: 100,
    filter: false,
    autoHeight: true,
  },
};

export const ADD_WB_USER_TITLE = 'Add A WB User';
export const ADD_CUSTOMER_USER_TITLE = 'Add A Customer User';
export const ADD_USER_TITLE = 'Add User';
export const SEARCH_USER_TEXT = 'Search User';
export const PLACEHOLDER_TEXT = 'Search user name or email address';
export const SEARCH_PLACEHOLDER = 'Search'; // Add this line
export const ADD_WB_SEARCH_SUCCESS = 'WBSO added Successfully';
export const ADD_WB_SEARCH_ERROR = 'WBSO added Successfully';

export const AR_SECTION_TITLES = {
  permissions: 'Permissions',
  access: 'ACCESS',
  finalReview: 'Final Review',
  arNumber: 'A/R Number',
  finalReviewDescription: 'Approve or Deny Credit Application',
  arNumberDescription: 'Can add and edit the A/R number'
};

export const REVISION = {
  revisionsNeeded: "Revisions needed:",
  uploadPurchaseOrder: "Upload a Purchase Order",
  uploadCertificateOfInsurance :"Upload a certificate of Insurance",
  purchaseOrder : "Purchase Order",
  certificateOfInsurance: "Certificate Of Insurance",
  needsRevision : "NEEDS REVISIONS",
  docUploadSuccess : "Document Uploaded Successfully",
  docRemoveSuccess: "Document Removed Successfully"

}


export const ADD_EDIT_COMPNAY = {
  companytype: "Company Type",
  internal: "For Internal use only",
  companyname: "Production Company Name",
  name: "Name",
  legalname: "The Production Legal Name",
  billingaddress: "Billing Address",
  companyaddress: "Production Company Address",
  contacts: "Contacts",
  country: "Country",
  street: "Street",
  city: "City",
  cities:"City/Town",
  state: "State",
  zipcode: "Zip Code",
  billing: "Use for Billing Address",
  firstname: "First Name",
  lastname: "Last Name",
  email: "Email",
  jobtitle: "Job Title",
  phone: "Phone",
  federal: "Federal Tax ID #",
  federallabel: "TIN",
  addcontact: "Add a Contact",
  removecontact: "Remove Contact",
  addcompany: "Add Company",
  postalcode: "Postal Code",
  province: 'Province',
  stateinput: 'State/Province/Region',
  editcompany: 'Edit A Company',
  gotopage: 'gotopage',
  savechanges: 'Save Changes',
  discardchanges: 'Discard Changes',
  zippostalcode: 'ZIP/Postal Code'
}

export const ADD_EDIT_COMPNAY_DROPDOWN = [
  { id: 1, description: 'Projects' },
  { id: 2, description: 'Users' },
  { id: 3, description: 'Spaces & Resources' },
  { id: 4, description: 'Company' },
];

export const POP_UP_CELL_RENDERED = {
  moreActions: 'MORE ACTIONS',
  deactivateUser: 'Deactivate User',
  freezeAccount: 'Freeze Account',
  removeUser: 'Remove User',
};

export const ICONS_RENDERED = {
  areYouSure: 'Are you sure you want to delete?',
  closeProjectCreationPage: 'Are you sure you want to close the project creation page without saving?',
  closeUserCreationPage: 'Are you sure you want to close the user creation page without saving?',
  ok: 'OK',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  restrictDisableCompany: 'Company can’t be disabled because there are active projects associated with this company.',
  restrictUserCompany: 'There are no active projects associated with this company.',
  editcloseProjectCreationPage: 'Are you sure you want to discard changes without saving?',
};
export const GO_TO_DROPDOWN = [
  { id: 1, description: "Company" },
  { id: 2, description: "Projects" },
  { id: 3, description: "Users" }
];

export const GO_TO_DROPDOWN_DEFAULT = {
  goTo: 'Go To…',
  activeProjects: 'ACTIVE PROJECTS'
}

export const COMPANY_TYPES = [
  { id: 1, description: 'Warner Affiliate' },
  { id: 2, description: 'Major Studio' },
  { id: 3, description: '3rd Party' },
  { id: 4, description: '3rd Party Commercial House' }
];
export const COMPANY = {
  companyname: "Sunshine Productions",
  companyid: 'COMPANY ID #',
  projects: 'Projects',
  companyaddress: 'Company Address',
  contactinfo: 'Contact Information',
  companyuser: 'Company Users',
  creditstatus: 'Company Status',
  adduser: 'Add a User',
  viewall: 'View All',
  valid: 'Valid Until 12/12/24',
  current: 'Current',
  editcompany: 'Edit Company',
  addcompany: 'Add a Company',
  portaltype: 'customer',
  modalType: 'edit',
  addProject: 'Add a Project',
  internaluse: 'FOR INTERNAL USE ONLY',
  companytype: 'Company Type',
  enablecompany: 'Enable Company',
  disablecompany: 'Disable Company',
  addcustomeruser: 'Add a Customer User',
  addproject: 'Add a Project',
  allcompanies: 'All companies',
  addnewproject: 'Add A New Project',
  companytitle: 'Company',
  modalAdd: 'add',
  studioComponentType: 'studio-company',
  studioPortalType: 'studio'

};
export const ADD_COMPNAY_PROJECT = {
  companyname: "Sunshine Productions",
  companyid: 'COMPANY ID #',
  projects: 'Projects',
  companyaddress: 'Company Address',
  contactinfo: 'Contact Information',
  companyuser: 'Company Users',
  creditstatus: 'Company Status',
  adduser: 'Add a User',
  viewall: 'View All',
  valid: 'Valid Until 12/12/24',
  current: 'Current',
  editcompany: 'Edit Company',
  addcompany: 'Add a Company',
  portaltype: 'customer',
  modalType: 'edit',
  addProject: 'Add a Project',
  internaluse: 'FOR INTERNAL USE ONLY',
  companytype: 'Company Type',
  enablecompany: 'Enable Company',
  disablecompany: 'Disable Company',
  addcustomeruser: 'Add a Customer User',
  addproject: 'Add a Project',
  allcompanies: 'All companies',
  addnewproject: 'Add A New Project',
  companytitle: 'Company',
  companyTitle: 'Company',
  changeCompany: 'Change Company',
  openCompany: 'Open Company',
  selectCompany: 'Select Company',
  projectName: 'Project Name',
  projectCompanyName: 'Project Company Name',
  projectDetails: 'Project Details',
  projectType: 'Project Type',
  anticipatedDates: 'Anticipated Project Dates',
  internal: 'For Internal Use Only',
  primaryType: 'Primary Payment Type',
  addProjectBtn: 'Add Project',
  addGoProjectBtn: 'Add & Go To Project',
  customertype: 'Customer Type',
  primaryPaymentType: 'Primary Payment Type'
};
export const COUNTRIES = [
  { "id": 1, "name": "Afghanistan" },
  { "id": 2, "name": "Åland Islands" },
  { "id": 3, "name": "Albania" },
  { "id": 4, "name": "Algeria" },
  { "id": 5, "name": "American Samoa" },
  { "id": 6, "name": "Andorra" },
  { "id": 7, "name": "Angola" },
  { "id": 8, "name": "Anguilla" },
  { "id": 9, "name": "Antarctica" },
  { "id": 10, "name": "Antigua and Barbuda" },
  { "id": 11, "name": "Argentina" },
  { "id": 12, "name": "Armenia" },
  { "id": 13, "name": "Aruba" },
  { "id": 14, "name": "Australia" },
  { "id": 15, "name": "Austria" },
  { "id": 16, "name": "Azerbaijan" },
  { "id": 17, "name": "Bahamas" },
  { "id": 18, "name": "Bahrain" },
  { "id": 19, "name": "Bangladesh" },
  { "id": 20, "name": "Barbados" },
  { "id": 21, "name": "Belarus" },
  { "id": 22, "name": "Belgium" },
  { "id": 23, "name": "Belize" },
  { "id": 24, "name": "Benin" },
  { "id": 25, "name": "Bermuda" },
  { "id": 26, "name": "Bhutan" },
  { "id": 27, "name": "Bolivia" },
  { "id": 28, "name": "Bosnia and Herzegovina" },
  { "id": 29, "name": "Botswana" },
  { "id": 30, "name": "Bouvet Island" },
  { "id": 31, "name": "Brazil" },
  { "id": 32, "name": "British Indian Ocean Territory" },
  { "id": 33, "name": "Brunei Darussalam" },
  { "id": 34, "name": "Bulgaria" },
  { "id": 35, "name": "Burkina Faso" },
  { "id": 36, "name": "Burundi" },
  { "id": 37, "name": "Cambodia" },
  { "id": 38, "name": "Cameroon" },
  { "id": 39, "name": "Canada" },
  { "id": 40, "name": "Cape Verde" },
  { "id": 41, "name": "Cayman Islands" },
  { "id": 42, "name": "Central African Republic" },
  { "id": 43, "name": "Chad" },
  { "id": 44, "name": "Chile" },
  { "id": 45, "name": "China" },
  { "id": 46, "name": "Christmas Island" },
  { "id": 47, "name": "Cocos (Keeling) Islands" },
  { "id": 48, "name": "Colombia" },
  { "id": 49, "name": "Comoros" },
  { "id": 50, "name": "Congo" },
  { "id": 51, "name": "Congo, The Democratic Republic of The" },
  { "id": 52, "name": "Cook Islands" },
  { "id": 53, "name": "Costa Rica" },
  { "id": 54, "name": "Cote D'ivoire" },
  { "id": 55, "name": "Croatia" },
  { "id": 56, "name": "Cuba" },
  { "id": 57, "name": "Cyprus" },
  { "id": 58, "name": "Czech Republic" },
  { "id": 59, "name": "Denmark" },
  { "id": 60, "name": "Djibouti" },
  { "id": 61, "name": "Dominica" },
  { "id": 62, "name": "Dominican Republic" },
  { "id": 63, "name": "Ecuador" },
  { "id": 64, "name": "Egypt" },
  { "id": 65, "name": "El Salvador" },
  { "id": 66, "name": "Equatorial Guinea" },
  { "id": 67, "name": "Eritrea" },
  { "id": 68, "name": "Estonia" },
  { "id": 69, "name": "Ethiopia" },
  { "id": 70, "name": "Falkland Islands (Malvinas)" },
  { "id": 71, "name": "Faroe Islands" },
  { "id": 72, "name": "Fiji" }
]
export const REGEX_CONSTANTS = {
  PHONE_NUMBER: /^[0-9+\-() \s]*$/,
  FEDERAL_TAX_ID: /^[0-9\- ]*$/,
  ALPHABETIC: /^[A-Za-z\s]*$/,
  NAME_VALIDATION: /^[A-Za-z]+(\s[A-Za-z]+)*$/,
  JOB_TITLE: /^[a-zA-Z0-9\s\-_.&@!]+$/,
  ALPHANUMERIC: /^[a-zA-Z0-9]*$/,
  NUMERIC: /^[0-9]+$/,
  NAMES: /^[A-Za-z]+(?: [A-Za-z]+)*$/,
  USZIPCODE: /^[0-9]{1,5}$/,
  UKCANPOSTAL: /^[A-Za-z0-9\s]{1,12}$/,
  GENPOSTAL: /^[A-Za-z0-9\s]{1,12}$/,
  SEARCH_COMPANY_NUMBER: /^\d+$/
};

export const MERGED_CELL_ICONS = {
  disabled: 'DISABLED',
  active: 'ACTIVE'
}

export const MULTISELECT = {
  apply: 'Apply',
  close: 'Close',
  placeholder: 'Select',
  typeaheadplaceholder: 'Search'
};

export const USER = {
  userName: 'User Name',
  profile: 'Profile',
  settings: 'Settings',
  emailNotifications: 'Email Notifications',
  profileTab: 'profile-tab',
  settingsTab: 'settings-tab',
  emailNotificationstab: 'email-notifications-tab',
  successfullMessage: "User Profile has been successfully updated",
  errorMessage: 'Error in updating the profile',
  warn: 'Profile data could not be retrieved.',
  error: 'Error fetching user profile',
  updateError: 'Error updating profile',
  invalidField: 'This Field is invalid',
  warningMessage: 'Are you sure you want proceed without saving changes?',
  yes: 'Yes',
  no: 'No'
};

export const SETTINGS = {
  settings: 'Settings',
  timeZone: 'TIME ZONE',
  validatorRequired: 'This is required filed',
  dateFormat: 'DATE FORMAT',
  calendarFormat: 'CALENDAR FORMAT',
  startSunday: '',
  startMonday: '',
  timeFormat: 'TIME FORMAT',
  temperatureUnit: 'PREFERRED TEMPERATURE UNIT',
  fahrenheit: '',
  celsius: '',
  save: 'Save',
  dateFormat1: '',
  dateFormat2: '',
  timeFormat1: '',
  timeFormat2: '',
  timeZoneDefault: '',
  calendarFormatDefault: '',
  fahrenheitDefault: '',
};

export const SETTINGS_DATA = {
  fahrenheit: 'Fahrenheit',
  time: '12h',
  dateFormat: 'm/d/yyyy',
  calendarFormat: 'starts on Sunday',
}

export const PROJECT_ERROR_MSG = {
  errorProjectUser: 'Error fetching project user',
}

export const PORTAL_TYPE = {
  studioPortal: 'studio',
  studioTypeAdd: 'addProjectUser',
  studioTypeEdit: 'updatProjectUser',
  accountsPayableUser: 'accountsPayableUser',
}

export const WBCONSTANT_DATA = {
  wbsoAR: 10,
  deptmartmentUser: 6,
  department: 7,
  statusActiveInd: 'Y',
  loginUserId: 1,
}

export const SEARCH_DATA = {
  searchUser: 'Search User',
  search: 'Search',
}

export const ICONS_ACTION = {
  freezeAccount: 'freeze-account',
  deActivate: 'deactivate-account',
  activate: 'activate-account',
  remove: 'remove-account',
  disableCompany: 'disable-company',
  enableCompany: 'enable-company'
}

export const ACTION_MSG = {
  freezeAccount: 'Are you sure you want to test the account?',
  deActivate: 'Are you sure you want to deactivate the user?',
  activate: 'Are you sure you want to reactivate the user?',
  remove: 'Are you sure you want to remove the user?',
  disableCompany: 'Are you sure you want to disable this company?',
  enableCompany: 'Are you sure you want to enable this company?'
}

export const ICONS_CASE = {
  freeze: 'freeze',
  deActivate: 'deactivate',
  activate: 'activate',
  remove: 'remove',
  disableCompany: 'disable-company',
  enableCompany: 'enable-company'
}

export const FORM_VALIDATION = {
  phoneValidation: /^(?!.*\s{2})(?!\s)[0-9\+\-\(\)\s]*[0-9](?<!\s)$/,
  cityValidation: /^[a-zA-Z0-9\s]{1,50}$/,
  streetValidation: /^[a-zA-Z0-9@#?!&.,\-()'"/:;+\s]{1,85}$/,
  zipCodeValidation: /^[0-9]{1,12}$/,
  phoneNovalidation: /^[0-9\+\-\(\)\s]*$/,
  emailValidation: /^[^\s][a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}[^\s]$/,
  tinValidation: /^[0-9-\s]+$/,
  alphaNumericValidation: /^[A-Za-z0-9]*$/,
  projectName: /^[A-Za-z0-9\s!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~]*$/,
  streetValidationSpecialChar:/^[a-zA-Z0-9@#?!&.,\-()'"/:;+\s_]{1,85}$/,
  emailValidationCompany:/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

}

export const COMPANY_SEARCH_MESSAGES = {
  invalidCompanyId: 'Invalid Company ID#',
  invalidCompanyName: 'Invalid Company Name',
  search: 'Search'
}

export const SAVE_CHANGES_WARNING = {
  message: 'Are you sure you want to close the user creation page without saving?',
  message1:'Are you sure you want to discard changes made without saving?',
  yes: 'Yes',
  no: 'No'
}

export const APPROVAL_LETTER_FORMAT = {
  htmldata: '<p>Dear Accounts Payable,</p><p>Having reviewed your application for credit, Warner Bros. Studio Operations (WBSO) is pleased to advise you that we have established an account for you. WBSO would like to take this opportunity to welcome you as a customer and acquaint you with our practices. Please read the letter in attachments.</p><p>Please contact <a href="mailto:WBSFAR@warnerbros.com">WBSFAR@warnerbros.com</a> if there are any questions.<br/>Thanks,<br/>WBSO A/R</p>'
}
export const SEND_APPROVAL_LETTER_DATA = {
  title: 'Send Letter of Approval',
  company: 'Company',
  companyName: 'A Brand New Production Company',
  project: 'Project',
  projectName: 'The New Show',
  accountPayable: 'Accounts Payable contact(s)',
  accountPayableEmail: 'Jane Doe (jdoe@domain.com)',
  addeditbtn: 'Add/Edit New',
  cancelbtn: 'Cancel',
  sendtocustomerbtn: 'Send to Customer'
}
export const COD_NOTIFICATION = {
  codTitle: 'Send COD Information',
  codCompany: 'Company',
  codCompanyName: 'A Brand New Production Company',
  codProject: 'Project',
  codProjectName: 'The New Show',
  accountPayableContact: 'Accounts Payable Contact(s)',
  accountPayableEmail: 'Jane Doe (jkdoe@domain.com)',
  addeditbtn: 'Add/Edit New',
  cancelbtn: 'Cancel',
  sendcustomerbtn: 'Send To Customer',
  addeditTitle: 'Add/Edit New Accounts Payable Contact',
  payableName: 'Name',
  payableNameValue: 'Jon Doe',
  payableEmail: 'Email',
  payableEmailValue: 'Jond@domain.com',
  newPayableName: ' ',
  newPayableNameValue: ' ',
  newPayableEmail: ' ',
  newPayableEmailValue: '',
  nameRequired: 'Name is required.',
  nameMinLength: 'Name must be at least 3 characters long.',
  nameMaxLength: 'Name must not exceed 100 characters.',
  lnameRequired: 'Last name is required.',
  lnameMinLength: 'Last name must be at least 1 characters long.',
  lnameMaxLength: 'Last ame must not exceed 100 characters.',
  emailRequired: 'Email is required.',
  invalidEmail: 'Email format is invalid.',
  removebtn: 'Remove Contact',
  addContactbtn: ' Add a Contact',
  no:'No',
  yes:'Yes',
  unsavedChangesWarning:'Are you sure you want to discard changes without saving?',
  backbtn:'Back',
  saveBtn:'Save Changes',
  cancelBtn: 'Cancel',
  departmentsToContact: 'Departments to contact are: ',
  departmentName: 'departmentName',
  errormessage:'Duplicate email address. Please enter a different email',
  errorCheck:'Name must contain only alphabets',
  errorDelete: "We encountered an error. Please try again later.",
  errorNameMessage:'Name must contain only alphabets and length should be in between 1 to 50 characters.',
  newContactEmail: 'newContactEmail',
  statusValid: 'VALID',
  statusInvalid: 'INVALID',
  newContactName: 'newContactName',
  newContactLname: 'newContactLname',
};
export const COD_EMAIL_FORMAT = {
  htmlData: '<p>Dear Customer,</p><p>Thank you for your interest in doing business with WBSO. Based on email below A/R Team is under the impression that a cash account is needed therefore to process your credit card request please work directly with the department. WBSO is no longer accepting any credit card information via email</p><p>Departments to contact are: </p><p>Below are the links to our Costume, Property and other WBSO Departments which will provide the contact email and phone number for setting up a cash account.</p><p>Costumes: <a target="_blank" href="https://studiofacilities.warnerbros.com/costumes/">https://studiofacilities.warnerbros.com/costumes/ </a><br>Property: <a target="_blank" href="https://property.warnerbros.com">https://property.warnerbros.com</a><br>All Other WBSO Departments: <a target="_blank" href="https://studiofacilities.warnerbros.com/department-contacts/">https://studiofacilities.warnerbros.com/department-contacts/</a></p><p>If you have any questions, please let us know.</p><p>_______</p><p>WBSO A/R<br>WB Burbank</p>',
  replaceContent: '<p>Departments to contact are: </p>'
}

export const FINAL_CRED_FORM={
  title:'Line of Credit',
  lineofcredreq:'Line of Credit requested',
  lineofcredreqamt:'$xxx,xxx,xxx.xx',
  anticipatejobtotle:'Anticipated Job Total',
  anticipatejobtotleamt:'$xxx,xxx,xxx.xx',
  totalmonths:'Total # of months',
  totalnomonths:'2',
  letterofcredit:'Letter of Credit/',
  gurantee:'Guarantee',
  comment:'Comments',
  comments:'none',
  pdf:'signed.pdf',
  view:'View',
  company:'Company',
  name:'Name',
  companyName:'A Brand New Company',
  address:'Address',
  addressdetails:'2500 Main Street,Los Angeles, CA,92783',
  phone:'Phone',
  phoneno:'858556565',
  useofbill:'Use for billing address',
  federaltaxid:'Federal Tax ID #',
  tin:'TIN',
  tinno:'123456789',
  accountpayablecontact:'Accounts Payable Contact',
  accname:'Name',
  accnameValue:'A Brand New Company',
  accemail:'Email',
  accemailvalue:'name@domain.com',
  accphonetitle:'Phone',
  accphone:'858556565',
  companyprofile:'Company Profile',
  profile:'Profile',
  corparate:'Corporate',
  datestarted:'Date Started',
  date:'1/1/24',
  typeofbusiness:'Type of Business',
  typeofbusinessname:'Corporation',
  totalannual:'Total Annual Sales',
  totalannualamt:'$xx.xx',
  networth:'Net Worth',
  networthamt:'$xx,xx',
  ofemployee:'# of Employees',
  ofemployeeno:'0-25',
  principal:'Principals/Officers or Partners',
  principalname:'Name',
  principalValue:'John Doe',
  ofemployeetitle:'Title',
  ofemployeetitlevalue:'Job title',
  bankingref:'Banking References',
  bank:'Bank',
  bankname:'A Brand New Company',
  accntnotitle:'Account Number',
  accntno:'1234 5678 9012 3456',
  accntnametitle:'Name',
  accntnameValue:'John Doe',
  accntcomptitle:'Title',
  accntcompname:'A Brand New Comapny',
  accntcompemail:'Email',
  accntcompemailvalue:'name@domain.com',
  accntcompPhone:'Phone',
  accntcompPhoneno:'858556565',
  tradereferencetitle:'Trade Reference 1',
  tradereferencecompany:'Company',
  tradereferencename:'Name',
  tradereferencecontact:'Contact Name',
  tradereferencedate:'Data',
  tradereferenceaddress:'Address',
  tradereferenceaddressvalue:'2500 Main Street,Los angeles,CA 92783',
  tradereferenceemailtitle:'Email',
  tradereferenceemailValue:'name@domain.com',
  tradereferencephone:'Phone',
  tradereferencephoneno:'858556565',
  tradereferencepdf:'signed.pdf',
  tradereferenceview:'View',
  tradereferencetitle2:'Trade Reference 2',
  tradereferencecompany2:'Company',
  tradereferencename2:'Name',
  tradereferenceContact2:'Contact Name',
  tradereferencedate2:'Data',
  tradereferenceaddress2:'Address',
  tradereferenceaddressvalue2:'2500 Main Street,Los angeles,CA 92783',
  tradereferenceemailtitle2:'Email',
  tradereferenceemailValue2:'name@domain.com',
  tradereferencephone2:'Phone',
  tradereferencephoneno2:'858556565',
  tradereferencetitle3:'Trade Reference 3',
  tradereferencecompany3:'Company',
  tradereferencename3:'Name',
  tradereferenceContact3:'Contact Name',
  tradereferencedate3:'Data',
  tradereferenceaddress3:'Address',
  tradereferenceaddressvalue3:'2500 Main Street,Los angeles,CA 92783',
  tradereferenceemailtitle3:'Email',
  tradereferenceemailValue3:'name@domain.com',
  tradereferencephone3:'Phone',
  tradereferencephoneno3:'858556565',
  viewapplication:'View Application Form Activity',
  tc:'Terms & Conditions',
  tcname:'Name',
  tcnamevalue:'Catherine Dacker',
  tctitle:'Title',
  tctitlevalue:'Head of Accounting',
  tcsign:'Signature',
  tcsigned:'Yes',
  tcDate:'Date',
  tcDateValue:'xx/xx/xxx',
  tcViewcbtn:'View T&C Activity',
  certificate:'Certificate of',
  insurance:'Insurance',
  producer:'Producer',
  producername:'XYZ Broker Company',
  producerinsurance:'Insurance',
  producerinsurancename:'John Doe',
  produceraddress:'Address',
  produceraddressValue:'2500 Main Street,Los Angeles, CA, 92783',
  producerdocpdf:'signed.pdf',
  producerdocview:'View',
  viewcoiactivity:'View COI Activity',
  dandb:'D&B Report',
  dandbdoc:'signed.pdf',
  dandbview:'View',
  delliquency:'Deliquency Score',
  delliquencyno:'88',
  failurescore:'Failure Score',
  failurescoreno:'67',
  paydex:'PAYDEX® Score',
  paydexno:'72',
  viewdandbactivity:'View D&B Activity',
  search:'Search',
  cancel:'cancel',
  select:'Select',
  yes: 'Yes',
  no: 'No',
  prodCompanyAddress: 'Production Company Address',
  billingAddress: 'Billing Address',
  sameAddress: 'Same as Company Address'
}
 export const CRED_APPLICATION_FORM_ACTIVITY={
  title:'Credit Application Form Activity',
  namelogo:'AN',
  name:'Andrea Noj',
  commentdate:'xx/xx/xx xx:xxAM',
  comment:'Reviewed Certificate of Insurance',
  namelogo1:'AN',
  name1:'Andrea Noj',
  commentdate1:'xx/xx/xx xx:xxAM',
  comment1:'Reviewed Purchase Order',
  close:'Close'
 }
 export const  TERMS_CONDITION_ACTIVITY={
  activityTitle:'Terms & Conditions Activity',
  activityData:'No Activity',
  closeBtn:'Close'
 }
 export const DANDB_REPORT_ACTIVITY={
 activityTitle:'D&B Report Activity',
 activityData:'No Activity',
  closeBtn:'Close'
 }
 export const CERTIFICATE_OF_INSURANCE_ACTIVITY={
  activityTitle:'Certificate of Insurance Activity',
  activityData:'No Activity',
  closeBtn:'Close'
 }
 export const THIRD_PARTY_PORTALTYPE={
 Ar:'ar',
 STUDIO:'studio'
 }

 export const STUDIO_WBSO_USER_ROLE={
  wbso:'wbso',
  departmentUser:'Department User',
  departmentHead:'Department Head'
  }

 export const WBSO_AR_NUMBER={
  wbsoARNumber:'wbso_ar number',
}

export const ADD_USER = {
  activeproject : 'activeproject'
}
  

  export const THIRD_PARTY_COMPANY_TYPE = {
    thirdPartyCommercialHouse: '3rd Party Commercial House'
  }

export const EMAIL_TEMPLATE_ROUTE = {
  customer_portal: 'customer-dashboard/project-details'
}

export const SIDEBAR_SALES = {
  active: 'Active',
  project: 'Project: ',
  user: 'User: ',
  company: 'Company: ',
  projectId: 'Project Id: ',
}
