<div class="page-wrapper">
  <div class="container px-0">
    <div class="row">
      <div class="comman-title-block">
        <div class="comman-title-section">
          <h1>Users</h1>
          <div class="button-group">
            <button *ngIf="permissionsData['addWBSOUser']" class="common-btn-primary btn btn-right xs-mobile-margin"
              (click)="addWbUser()">{{ addWbUserText
              }}</button>
            <button class="common-btn-primary btn btn-right xs-mobile-margin" (click)="addAUser()">{{
              addCustomerUserText }}</button>
          </div>
        </div>
        <div class="comman-filter-block">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-12 px-0">
                <div class="comman-filter-title-section">
                  <p>{{filters}}</p>
                </div>
                <div class="comman-filter-section d-flex flex-wrap align-items-center gap-3">

                  <!-- Multiselect Dropdowns -->
                  <app-ng-multiselect-dropdown [placeholder]="'ID #'" [settings]="defaultSettings"
                    [searchtextplaceholder]="'Type an ID#'" [type]="userTypeahead" [data]="usersId"
                    [filterData]="idFilterData" (onSelect)="selectedId($event)">
                  </app-ng-multiselect-dropdown>

                  <app-ng-multiselect-dropdown [placeholder]="'User'" [settings]="defaultSettings" [data]="users"
                    [searchtextplaceholder]="'Type a name or email address..#'" [type]="userTypeahead"
                    [filterData]="userFilterData" (onSelect)="selectedUser($event)">
                  </app-ng-multiselect-dropdown>

                  <app-ng-multiselect-dropdown [placeholder]="'User Type'" [settings]="defaultSettings"
										[data]="studioUserType" (onSelect)="selectedUserType($event)">
									</app-ng-multiselect-dropdown>

                  <app-ng-multiselect-dropdown [placeholder]="'Status'" [settings]="defaultSettings" [data]="status"
                    (onSelect)="selectedStatus($event)">
                  </app-ng-multiselect-dropdown>

                  <app-datepicker [placeholder]="placeholder" (dateRangeSelected)="onDateRangeSelected($event)">
                  </app-datepicker>

                  <app-ng-multiselect-dropdown [placeholder]="'Job Title'" [settings]="defaultSettings"
                    [searchtextplaceholder]="'Type a job title'" [data]="jobTitleTypeAhead" [type]="userTypeahead"
                    [filterData]="jobTitleFilterData" (onSelect)="selectedJobTitle($event)">
                  </app-ng-multiselect-dropdown>

                  <app-ng-multiselect-dropdown [placeholder]="'Companies'" [settings]="defaultSettings"
                    [data]="activecompanies" (onSelect)="selectedCompanies($event)">
                  </app-ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Selected filters section -->
        <div *ngIf="isFiltersSelected" class="selected-filters-section">
          <div class="selected-filters">
            <div *ngFor="let id of filteredId" class="filter-tag">
              {{ id.id }}
              <button type="button" (click)="removeSelectedItem('ID', id)">×</button>
            </div>
            <div *ngFor="let user of filteredUsers" class="filter-tag">
              {{ user.firstname }}
              <button type="button" (click)="removeSelectedItem('user', user)">×</button>
            </div>
            <div *ngFor="let userType of filteredUserType" class="filter-tag">
						  {{ userType?.text }}
						  <button type="button" (click)="removeSelectedItem('userType', userType)">×</button>
					  </div>
            <div *ngFor="let status of filteredStatus" class="filter-tag">
              {{ status.text }}
              <button type="button" (click)="removeSelectedItem('status', status)">×</button>
            </div>
            <div *ngFor="let job of filteredJobTitle" class="filter-tag">
              {{ job.jobtitle }}
              <button type="button" (click)="removeSelectedItem('jobTitle', job)">×</button>
            </div>
            <div *ngFor="let company of filteredCompanies" class="filter-tag">
              {{ company.text }}
              <button type="button" (click)="removeSelectedItem('companies', company)">×</button>
            </div>
            <div *ngIf="selectedFromDate && selectedToDate" class="filter-tag">
              {{ formatDateRange(selectedFromDate, selectedToDate) }}
              <button type="button" (click)="clearDateRange()">×</button>
            </div>
            <!-- Clear All Filters Button -->
            <a class="text-link" (click)="clearAllFilters()">{{ clearAllFiltersText }}</a>
          </div>
        </div>
        <div class="common-grid-block">
          <div class="mt-4">
            <app-common-ag-grid class="ag-theme-custom" [colDefs]="colDefs" [rowData]="filteredRowData"
              (cellClicked)="onCellClicked($event)" [colsForSmallScreen]="colsForSmallScreen"
              [colsForMediumScreen]="colsForMediumScreen" [colsForLargeScreen]="colsForLargeScreen"
              [colsForExtraLargeScreen]="colsForExtraLargeScreen">
            </app-common-ag-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #successTpl>
  <div class="success">{{addUserSuccess}}</div>
</ng-template>

<ng-template #dangerTpl>
  <div class="danger">{{addUserError}}</div>
</ng-template>

<ng-template #successTplActiveDeactive>
  <div class="success">{{successActivateDeactivateMsg}}</div>
</ng-template>

<ng-template #dangerTplActiveDeactive>
  <div class="danger">{{errorActivateDeactivateMsg}}</div>
</ng-template>

<ng-template #dangerTplDangerDeactivedUser>
  <div class="danger">{{ message }}</div>
</ng-template>


<app-toast aria-live="polite" aria-atomic="true"></app-toast>