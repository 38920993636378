import { FormControl } from '@angular/forms';

export const CREDIT_APPLICATION = {
  creditApplication: 'Credit Applications',
  viewCredit: 'View All Credit Applications',
};

export const PROJECTS = {
  projects: 'Projects',
  filters: 'FILTERS',
  clearFilters: 'Clear Filters',
  noProjects: 'No Projects To Display'
}
export const NOCREDITAPP = {
  creditappnodata: 'There are no credit applications.',
}
export const PROJECT_FILTER_TYPES = {
  projects: 'projects',
  types: 'types',
  departments: 'departments',
  status: 'status',
  project: 'project',
  type: 'type',
  department: 'department'
}

export const VIEW_APP_AR_USER = {
 requestAR: 'Request A/R #',
 uploadCOI: 'Upload a certificate of Insurance',
 uploadPO: ' Upload a Purchase Order',
 view: 'View',
 back: 'Back'
}

export const MANAGE_USERS_TYPES = {
  users: 'users',
  userType: 'userType',
  status: 'status',
  jobTitles: 'jobTitles',
  user: 'user',
  jobTitle: 'jobTitle',
  portalType: 'customer',
  addModalType: 'add',
  editModalType: 'edit',
  componentType: 'manage'
}
export const CREDIT_APPLICATION_FILTERS = {
  companies: 'company',
  projects: 'project',
  departments: 'department',
  status: 'status',
}

export const departmentsTypeahead = [
  { id: 1, text: 'departmentsTypeahead' },
];

export const projectsTypeahead = [
  { id: 1, text: 'activeProjectsTypeahead' },
];

export const PROJECTS_USER_ROLE = {
  companyAdmin: 'customer_company admin',
  projectViewOnly: 'customer_project view only', 
  projectViewAndManage: 'customer_project view and manage', 
  manageUsersViewAndManage: 'customer_manage users view and manage',
  creditApplicationViewAndManage: 'customer_credit application view and manage'
}

export const MANAGE_USERS_CONSTANTS = {
  addUser: '+ Add a User',
  filters: 'FILTERS',
  clearFilters: 'Clear Filters',
  allProjects: 'All Projects',
  projectUsers: 'Project Users',
  addUserTitle: 'Add A User',
  EditUserTitle: 'Edit A User',
  allCustomers: 'All Customers',
  imageSize: ['image/jpeg', 'image/png'],
  project: 'project'
}
export const TOASTER_MSG_MANAGE_USERS = {
  userActivated: 'User Activated Successfully',
  userDeactivated: 'User Deactivated Successfully',
  errorRetrivingUser: 'Error retriving user',

}

export const PROJECT_DATES_DROPDOWN_LIST = {
  action: 'Action',
  otherAction: 'Another action',
  something: 'Something else here',
  separatedlink: 'Separated link',
  toggle: 'Toggle Dropdown',
};
export const REQUEST = {
  request: 'Requests',
  viewAllRequest: 'View All Requests',
};
export const ALL_STATUSES = [
  { id: 61, text: 'ACTIVE' },
  { id: 31, text: 'CLOSED' },
  { id: 101, text: 'NOT STARTED' },
  { id: 41, text: 'NEW' },
  { id: 51, text: 'ASSIGNED' },
  { id: 71, text: 'RESOLVED' },
  { id: 81, text: 'IN PROGRESS' },
  { id: 91, text: 'SUBMITTED' }
];
export const MENU = {
  projects: 'Projects',
  request: 'Requests',
  company: 'Company',
  overview: 'Overview'

};

export const ERROR_MESSAGES = {
  projectUserErrorMessage: 'Error fetching project user',
  userDetailsErrorMessage: 'Error fetching user details',
}

export const DASHBOARD = {
  request: 'Request',
  user: 'User',
  credit: 'Credit Application',
  error: 'error getting the project',
  Updatsuccessful: 'country id updated successfully',
  lotError: 'Error in updating the country id',
  inActive: 'This company is not associated with any active projects. Please contact your',
  administrator: 'administrator',
  assistance: 'for assistance.',
  creditError: 'Error fetching credit application:',
  creditErrorAdd: 'Profile data could not be retrieved.',
  startApplication: 'Start Application',
  finishApplication:'Finish Application',
  current: 'CURRENT',
  creditApplication: 'Credit Application',
  projectSelect: 'Please select a project you would like to submit a credit application for:',
  newApplication: 'Add a New Credit Application',
  cancel: 'Cancel',
  continue: 'Continue',
  existingRecord: 'You have existing credit application(s) in draft or initiated status from Studio Operations for the following departments. Please choose one to complete or create a new application.',
  thirdPartyCommercialHouse: '3rd Party Commercial House',
  currentStatus: 'Current'
};

export const ACTIVE_PROJECTS = {
  projectName: 'Project Id',
  viewProject: 'View Project',
  viewProjectAndBookmark: 'View Bookmarks & Project',
  dates: 'Dates',
  aStage: "A Stage",
  officeSuite: 'Office Suit',
  floorPlanSuit: 'Floor Plan Suit',
  groundFloorPlan: 'Ground Floor Plan',
  technicalSpec: 'Technical Spec',
  floorPlan: 'FloorPlan',
  ar: 'A/R'
};

export const PROJECT_DETAILS_SPACES = {
  mySpaces: 'My Spaces & Resources',
  spaces: 'Spaces & Resources',
  stages: 'STAGES',
  offices: 'OFFICES',
  workshops: 'WORKSHOP',
  maps: "Maps",
  viewMaps: 'View All Maps'

};

export const PROJECT_DETAILS_CONSTANTS = {
  allProjects: 'Projects',
  projectId: 'PROJECT ID # ',
  ar: 'A/R # ',
  dates: 'DATES: ',
  description: 'DESCRIPTION',
  editDescription: 'Edit Project Description',
  addUser: 'Add A User',
  projectDetailsErrorMessage: 'Error in fetching the project details',
  editDescriptionErrorMessage: 'Error in updating description',
  bookmarkSuccessMessage: 'Bookmark Status Updated Successfully',
  bookmarkErrorMessage: 'Error in upating bookmark status',
  imageError: 'Error during upload',
  projectImage: 'project',
  section: 'section',
  projectIdImage: 'projectId',
  file: 'file',
  addCreditApplication: 'Add a Credit Application',
  editSuccess: 'Project description updated successfully',
  addCreditAppARreq: 'Request A/R #',
};

export const PROJECT_DETAILS_CONSTANT_LATEST = {
 ar: 'A/R #',
 startApplication: 'Start Application',
 finishApplication: 'Finish Application',
 creditLimit: 'Credit Limit',
 viewApplication: 'View Application',
 thirdPartyViewApplication: 'Third Party View Application',
}

export const ACKNOWLEDGE_CONSTANTS = {
  acknowledgeLabel: 'Acknowledgment Required',
  acknowledgeConditionOne: 'Customer acknowledges and agrees that its submission of this Credit Application Form in no way guarantees the approval of any credit to Customer, and that Customer has no right to procure any services, materials or other items from Warner Bros. Studio Operations departments unless and until a Net Term Account is approved.',
  acknowledgeConditionTwo: 'Customer acknowledges and agrees that its submission of this Credit Application Form authorizes WBSO to investigate Customer’s credit information and history and contact the listed references for information that might be useful in the determination of whether a Net Term Account will be given to Customer.',
  cancelButton: 'Cancel',
  acknowledgeConditionsBtn: 'I acknowledge these conditions'
}

export const EDIT_DESCRIPTION_CONSTANTS = {
  editProject: 'Edit Project',
  description: 'Project Description',
  save: 'Save Changes',
  discard: 'Discard Changes',
  close: 'Close'
};

export const ADD_USER_CONSTANTS = {
  addUser: 'Add A User',
  editUser: 'Edit User',
  close: 'Close',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  jobTitle: 'Job Title',
  optionalSettings: 'Optional Settings',
  departmentAccess: 'Department Access',
  sectionAndPermissions: 'SECTION AND PERMISSIONs',
  access: 'ACCESS',
  projects: 'PROJECTS',
  serviceRequest: 'Service Request',
  creditApplication: 'CREDIT APPLICATION',
  manageUsers: 'MANAGE USERS'
};

export const PROJECT_USERS_COUNT = {
  projectUsers: 'Project Users',
  viewAll: 'View All',
  addUser: 'Add a user',
  users: 'USERS',
};

export const CREDIT_APPLICATION_CONSTANTS = {
  creditApplications: 'Credit Applications',
  startApplication: 'Start a Credit Application',
  creditApplicationForm: 'Credit Application Form',
  credAppSubtext: 'Please ensure the credit application is completed in its entirety.',
  certificateOfInsurance: 'Certificate of Insurance',
  coiSubtextOne: 'This certificate is issued as a matter of information only and confers no rights upon the certificate holder.',
  coiSubTextTwo: 'This certificate does not amend, extend or alter the coverage afforded by the policies below.',
  termsAndConditions: 'Terms & Conditions',
  tncSubText: 'Warner Bros. Studio Facilities',
  reviewApplication: 'Review Application',
  reviewSubtext: 'Edit or Add any missing information before sending your request to WB Studio Facilities.',
  close: 'Close',
  needsRevision: 'NEEDS REVISIONS',
  revisionsSubmitted: 'REVISIONS SUBMITTED',
  startApplicationStatus: 'Start Application',
  finishApplication: 'Finish Application',
  allDepartments: 'All Departments',
  all: 'All',
  creditApplication: 'Credit Application',
  project: 'PROJECT',
  departments: 'Departments',
  editDepartments: 'Edit departments',
  applicationForm: 'Application Form',
  certificationInsurance: 'Certification of Insurance',
  termsConditions: 'Terms & Conditions',
  reviewApplications: 'Review Application',
  required: 'Required',
  creditApplicationLocked: 'Another user (email) is currently working on the application. Please check back later.',
  yes: 'Y'
};

export const CRED_THIRD_PARTY = {
  creditApplication: 'Credit Application',
  project :'PROJECT',
  company: 'Company',
  type: 'TYPE',
  applicationDate: 'APPLICATION DATE',
  departments: 'Departments',
  viewProjectQuotes: 'View Project & Quotes',
  viewCompany: 'View Company',
  customer:'Customer'
}

export const EMAIL_NOTIFICATION = {
  emailNotification: 'Email Notifications',
  crditApplication: 'Credit Applications',
  applicationAndStatus: 'Credit Applications & their statuses',
  company: 'Company',
  companyStatus: 'New users added to a project, company updates',
  save: 'Save',
};

export const PROFILE = {
  myProfile: 'My Profile',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  jobTitle: 'Job Title',
  save: 'Save',
  validatorRequiredFname: 'First Name is required.',
  validatorRequiredLname: 'last Name is required.',
  validatorRequiredEmail: 'email Name is required.',
  validatorRequiredPhone: 'phone Name is required.',
  validatorRequiredJobTitle: 'job Title is Required',
  spaceValidator: 'First Name should not contain numbers or spaces.',
  specialCharacterValidator: 'can only contain numbers, "()", "-", and "+".',
  maxLengthValidator: 'Phone number must be up to 15 characters.',
  lNameLengthValidator: 'Last name exceeded the length',
  FNameLengthValidator: 'first Name exceeded the length',
  jobTitleLengthValidator: 'Job title exceeds the length limit of 50 characters.',
  discardChanges: 'Discard Changes',
  yes: 'Yes',
  no: 'No',
  discardChangesMessage: 'Are you sure you want to discard changes without saving?',
  tooltip: "Please enter the country code. Phone number format example for US: +1 (000) 000-0000; UK: +44 0000 000 0000",
  spaceerror: 'Please remove any extra spaces before or after the text.',
  twospaceerror: 'Please remove extra spaces between words.',

};
export const PHONE_PATTERN = /^[0-9()+\-]*$/;
export const NAME_PATTERN = /^[A-Za-z\s]*$/;




export const COMPANY = {
  companyid: 'Company ID #',
  projects: 'Projects',
  companyaddress: 'Production Company Address',
  billingaddress: 'Billing Address',
  contactinfo: 'Contact Information',
  companyuser: 'Company Users',
  creditstatus: 'Credit Status',
  projectuser: 'Add a User To',
  adduser: 'Add a User',
  viewall: 'View All',
  current: 'Current',
  editcompany: 'Edit Company',
  addcompany: 'Add a Company',
  portaltype: 'customer',
  modalEdit: 'edit',
  modalAdd: 'add',
  notavailable: 'Not Available',
  companyerror: 'Company does not exist',
  componenttype: 'company',
  success: 'success',
  validtill: 'Valid until',
  federalTaxId: 'Federal Tax ID #',

};

export const COMPANY_USERS = {
  companyusers: 'Company Users',
  user: 'User',
  filter: 'Filters',
  adduser: 'Add a User',
  edituser: 'Edit A Customer User',
  portaltype: 'customer',
  modalEdit: 'edit',
  modalAdd: 'add',
  company: 'Company',
  componenttype: 'company',
  success: 'success',
  userType: 'companyuser'
};

export const COMPANY_USERS_REQUEST = {
  system: 'system admin',
  account: 'account payable',
  company: 'company admin',
};

export const GETSTATUS_CLASS = {
  active: 'ACTIVE',
  deactivated: 'DEACTIVATED',
};

export const COMPANY_USERS_FILTER_TYPES = {
  activeprojects: 'activeprojects',
  status: 'status',
  user: 'user',
  jobtitle: 'jobtitle',
  userType: 'userType'
}
export const COMPANY_FILTERS = {
  projects: 'Projects',
  filters: 'FILTERS',
  clearFilters: 'Clear Filters'
}

export const CREDIT_APP_FILTER_TYPES = {
  company: 'company',
  projects: 'projects',
  department: 'department',
  status: 'status',
}

export const PROJECT_USERS_LIST_CONSTANTS = {
  all: 'All',
  active: 'Active',
  deactivated: 'Deactivated',
  admin: 'Admin',
  companyAdmin: 'Company Admin',
  accountPayableUser: 'Accounts Payable',
  activeStatus: 'ACTIVE',
  deactivatedStatus: 'DEACTIVATED',
  statusActive: 'Y',
  statusDeactive: 'N',
  allAccess: 'ALL',
  companyAdminIndicator: 'Company Admin',
  AccountPayableIndicator: 'Accounts Payable'
}

export const ADD_PROJECT_USER_CONSTANTS = {
  manage: 'Can Manage',
  view: 'Can View',
  all: 'All Departments'
}

export const DropdownSettings = {
  id: "id",
  textField: "text",
  limitSelection: -1,
  maxHeight: 197,
  itemsShowLimit: 999999999999,
  searchPlaceholderText: "Search",
  noData: "No data available",
  noFilteredData: "No filtered data available",
}

export const PROJECT_DROPDOWN_SETTINGS = {
  idField: "id",
  textField: "text",
  maxHeight: 250,
  noDataAvailablePlaceholderText: "No data available",
  noFilteredDataAvailablePlaceholderText: "No filtered data available",
  closeDropDownOnSelection: false,
  defaultOpen: false
}

export const SORTING_CONSTANTS = {
  sort: "Sort:",
  mostRecent: "Most Recent",
  aToz: "A - Z"
}


export const WEATHER_FORECAST_CONSTANTS = {
  rain: "Chance of Rain:",
  change: "Change",
  forecast: "10 Day Forecast",
  weatherUrl: "https://weather.com/weather/today/l"
}

export const DATEPICKER_CONSTANTS = {
  lastLogin: "Last Login",
  dates: "Dates"
}
export const DEFAULT_STATUSES = [
  { id: 2, text: 'Active' },
  { id: 3, text: 'Closed' },
  { id: 1, text: 'Not Started' }
];

export const DEFAULT_PROJECT_TYPES = [
  { id: 1, text: 'Feature' },
  { id: 2, text: 'Television' },
  { id: 3, text: 'Live Production/Rehearsal' },
  { id: 4, text: 'Commercial' },
  { id: 5, text: 'Still Shoot' },
  { id: 6, text: 'Special Event' },
  { id: 7, text: 'Other'}
];


export const onTheLot = {
  cafetria: 'Commissary Cafeteria',
  menu: ' - Menu & Order Online',
  fine: 'Fine Dining',
  party: ' - Dining & Private Party menus',
  grill: 'Avon Grill',
  orderOnline: ' - Menu & Order Online',
  roadRunner: 'Road Runner Delivery',
  orderOnlineMenu: ' - Menu & Order Online',
  aramark: 'Aramark',
  foodServices: ' - Food Services',
  feature: 'Feature Medical',
  medical: ' - Medical care',
  safety: ' - Safety Services',
  mentalHealth: ' - Mental Health & Wellbeing support',
  solas: 'Solas Mind',
  wonderWorks: 'The Wonderworks',
  childcare: ' - Childcare',
  zone10: 'Zone 10',
  fitnessWellbeing: ' - Fitness & Wellbeing',
  msl: 'MSL'
}



export const ALL_PASSES = {
  wbdPass: 'WBD Pass',
  close: 'Close',
  createPass: 'View or Create passes on',
  link: 'pass.wbd.com',
  refresh: 'Refresh',
  allPasses: 'All Passes',
  driveOn: 'Drive-On',
  walkOn: 'Walk-On',
  checkedIn: 'Checked In only',
  days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  today: 'today-tab',
  checkedInStatus: 'Checked In',
  export: 'Export',
  error: 'error',
  noReponse: 'No response received',
  all_passes: 'All Passes',
  drive_on: 'Drive-On',
  walk_on: 'Walk-On',
  warn: 'No response received',
  checkedOut: 'CHECKED OUT',
  checkedInTime: 'Checked In',
   checkedOutTime: 'Checked Out'
}

export const CERTIFICATE_OF_INSURANCE = {
  title: 'Insurance Information',
  namesLabel: 'NAMES:',
  insuranceBrokerName: "Your insurance broker's name",
  producerLabel: 'Producer',
  insuredLabel: 'Insured',
  addressLabel: 'Address:',
  streetLabel: 'Street',
  cityLabel: 'City',
  stateLabel: 'State',
  zipCodeLabel: 'ZIP Code',
  uploadForm: 'Upload your Form',
  dragAndDrop: 'Drag and Drop your file here, or',
  browse: 'browse',
  addComment: 'Add a Comment',
  comments: 'Comments',
  back: 'Back',
  termsAndConditionsBtn: 'Next: Terms & Conditions',
  saveAndCloseBtn: 'Save & Close',
  sample: 'View Sample',
  needsRevision: 'NEEDS REVISIONS',
  startApplication: 'Start Application',
  finishApplication: 'Finish Application',
  thisfieldIsRequired: 'This field is required',
  producerExceedCharacters: 'Producer name should not exceed 50 characters',
  yourFullName: 'Your full name',
  state: 'State',
  insuredShouldNotExceedCharacters: 'Insured name should not exceed 50 characters',
  streetShouldNotExceedCharacters: 'Street name should not exceed 85 characters.',
  streetNameIsInvalid: 'Street name is invalid.',
  cityMustNotExceedCharacters: 'City must not exceed 50 characters.',
  cityCanOnlyContainLettersNumbersAndSpaces: ' City can only contain letters, numbers, and spaces.',
  zipCodeShouldNotExceedCharacters: 'ZIP Code should not exceed 12 characters.',
  zipCodecanOnlyContainNumericCharacters: 'Please enter a valid ZIP Code.'
}

export const CREDIT_APP_TOAST_MESSAGES = {
  insuranceSuccess: 'Certificate Of Insurance Saved Successfully',
  creditApplication: 'Application Form Saved Successfully',
  reviewSuccess: 'Credit Application Submitted Successfully',
  uploadSuccess: 'Certificate Of Insurance Uploaded Successfully',
  removeSuccess: 'Certificate Of Insurance Removed Successfully',
  tradeReferenceSuccess: 'Trade Reference Uploaded Successfully',
  tradeReferenceRemoveSuccess: 'Trade Reference Removed Successfully',
  fileMoreThan100MBErrMsg: 'File size exceeds 25MB limit',
  fileMoreThan5MBErrMsg: 'File size exceeds 25MB limit',
  dropOnlyOneFile: 'You can drop only one file',
  selectPdfFile: 'Please select a PDF file',
  duplicateFileName: 'File could not be uploaded due to duplicate file name',
  moreThan20Files: 'You cannot upload more than 20 files in a single upload',
  logUploadSuccess: 'Letter of Credit/Guarantee Uploaded Successfully',
  logDeleteSuccess: 'Letter of Credit/Guarantee Removed Successfully',
  maxFilesExceededError: 'You can only upload a maximum of 10 files.',
  revisionApplication: 'Application Form Revisions Saved Successfully',
  formError:'Please correct the indicated fields. Some values are invalid or missing.'
}
export const CRED_APP_STEP_ONE = {
  revisionNeeded: 'Revisions needed:',
  scrolltosection: 'Scroll to section',
  lineOfCredit: 'Line of Credit',
  lineOfCreditRequested: 'Line Of Credit Requested',
   maxLengthExceeded: 'Max length exceeded',
   phoneMinLength: 'Min length is 10 characters',
   anticipatedJobTotal: 'Anticipated Job Total',
   totalOfMonths: 'Total # of months',
   letterofCreditGuarantee: 'Letter of Credit / Guarantee',
   comments: 'Comments',
   productionCompany: 'Production Company',
   name: 'Name',
   address: 'Address',
   street: 'Street',
   city: 'City',
   state: 'State',
   zipCode: ' ZIP Code',
   phone: 'Phone',
   useForBillingAddress: 'Use for billing address',
   billingAddress: 'Billing Address',
   federalTaxID: 'Federal Tax ID #',
   tin: ' TIN',
   accountsPayableContact: 'Accounts Payable Contact',
   firstName: 'First Name',
   lastName: 'Last Name',
   email: 'Email',
   removeContact: 'Remove Contact',
   addContact: '+ Add a Contact',
   companyProfile: 'Company Profile',
   selectProfile: 'Select profile',
   corporation:  'Corporation',
   proprietorship: 'Proprietorship',
   partnership: 'Partnership',
   llc: 'LLC',
   other: 'Other',
   businessInfo: 'Business info',
   dateStarted: 'Date Started',
   typeOfBusiness: 'Type of Business',
   totalAnnualSales: 'Total Annual Sales',
   networth: 'Networth',
   ofEmployees: '# of Employees',
   principalsOfficersorPartners: 'Principals/Officers or Partners',
   title: 'Title',
   removePartner: 'Remove Partner',
   addPrincipal: '+ Add Principal',
   bankingReference: 'Banking Reference',
   bankName: 'Bank Name',
   accountNumber: 'Account Number',
   contactName: 'Contact Name',
   tradeReferences: 'Trade References',
   tradeReferenceOne: 'Trade reference 1',
   companyName: 'Company Name',
   contactFirstName: 'Contact First Name',
   contactLastName: 'Contact Last Name',
   uploadDocument: 'Upload Document',
   or: 'Or',
   tradeReferenceTwo: 'Trade reference 2',
   tradeReferenceThree: 'Trade reference 3',
   requiredMessage: 'Date is required.',
   invalidDate: 'Invalid date.'


}

export const ADD_APPLICATION_CONSTANTS = {
  submitSuccess: 'Credit Application Created Successfully',
  title: 'SELECT DEPARTMENTS'
}
export const CRED_FORM_ONE_CONSTANT = {
  fieldRequired: 'This field is required',
  nameInvalid: 'Name is not valid',
  streetInvalid: 'Street is not valid',
  cityInvalid: 'City is not valid',
  PhoneInvalid: 'Invalid phone number',
  zipcodeInvalid: 'Please enter a valid ZIP Code',
  tinInvalid: 'Please enter a valid Federal Tax ID #',
  firstNameInvalid: 'First Name is not valid',
  lastNameInvalid: 'Last Name is not valid',
  emailInvalid: 'Please enter a valid email address',
  TypeOfBusinessInvalid: 'Type Of Business is not valid',
  dateInvalid: 'Please enter a valid date',
  currencyInvalid: 'Currency Format is not valid',
  formatInvalid: '# of Employees is not valid',
  titleInvalid: 'Title is not valid',
  bankNameInvalid: 'Bank Name is not valid',
  accNoInvalid: 'Please enter a valid account number',
  contactNameInvalid: 'Contact Name is not valid',
  companyNameInvalid: 'Company Name is not valid',
  totalNoOfMonthsInvalid: 'Total Month is not valid',
  view: 'View',
  remove: 'Remove',
  section: 'letterofguarantee',
  close: 'close',
  SaveandClose: 'Save & Close',
  review: 'Next: Certificate Of Insurance',
  certificate: 'Next: Certificate of Insurance',
  cancel: 'Cancel',
  spaceerror: 'Please remove any extra spaces before or after the text.',
  twospaceerror: 'Please remove extra spaces between words.',
  formError: 'Please correct the indicated fields. Some values are invalid or missing.'
}

export const CRED_FORM_THREE_CONSTANT = {
  fieldRequired: 'This field is required',
  nameInvalid: 'Name is not valid',
  titleInvalid: 'Title is not valid',
  signatureInvalid: 'Signature is not valid',
  needsRevision: 'NEEDS REVISIONS',
  revisionsSubmitted: 'REVISIONS SUBMITTED',
  startApplication: 'Start Application',
  finishApplication: 'Finish Application',
  termsAndConditionsSuccess: 'Terms and Conditions Saved Successfully',
  name:'Name',
  maxLengthExceeded:'Max length exceeded',
  title: 'Title',
  signature: 'Signature',
  date: 'Date',
  back: 'Back',
  nextReview: 'Next: Review Application',
  saveClose: 'Save & Close',
  formError: 'Please correct the indicated fields. Some values are invalid or missing.',
  invalidDate: 'Date is required.',
  invalid: 'Invalid date.',
}

export const TERMS_AND_CONDITIONS = `
  <ul class="term-condition-list">
    <li class="mb-3"><b>TERMS:</b> Net 30 days from date of invoice. Customer agrees to make payments in full to Warner Bros. Studio Operations (“WBSO”), a division of WB Studio Enterprises Inc., for all amounts due according to WBSO invoice(s). Should Customer default in any such payment(s), WBSO shall have the right, without notice to Customer, to declare all invoice amounts immediately due and payable. In the event WBSO should commence any action or otherwise seek to enforce these terms and conditions against Customer, Customer agrees to pay reasonable collection and attorney(s) fees, costs and other related expenses incurred by WBSO, whether or not an action is filed. This agreement is not transferable or assignable by Customer without the prior written consent of WBSO. Any sums payable to WBSO shall be paid within the terms stated above or shall bear interest from the due date, at the then prime rate or the rate at which WBSO is then able to borrow funds, whichever is greater. This Application may be used for any and all WBSO departments regardless of those specifically identified above. Venue for all disputes shall be in the County of Los Angeles.</li>

    <li class="mb-3">Payments are to be mailed to: WBSO - P.O. Box 847250 - Los Angeles, CA 90084-7250</li>
    <li class="mb-3">The WBSO terms and condition of sales, shall govern all transactions between WBSO and Customer, including any additional terms and conditions that may be provided by any WBSO department.</li>
    <li class="mb-3">WBSO reserves the right to decline credit to Customer at WBSO's sole discretion, and, in the event credit is extended to Customer, WBSO reserves the right to change or revoke Customer's credit line on the basis of changes in WBSO's credit policies or Customer's financial condition and/or payment record.</li>
    <li class="mb-3">Customer hereby requests WBSO to consider this Application for the purpose of extending credit and authorizes (i) the references listed on this Application to provide all information requested in conjunction with this Application; and (ii) WBSO to investigate Customer's credit information and history as WBSO may deem necessary.</li>
    <li class="mb-3">The undersigned hereby certifies that he or she is duly authorized to sign this Application on behalf of Customer, that the information given in this Application is true and correct to the best of his or her knowledge and that the Customer hereby agrees to the foregoing terms and conditions.</li>
  </ul>
`;

export const CRED_FORM_FOUR_CONSTANT = {
  creditApplicationForm: 'Credit Application Form',
  lineOfCreditRequested: 'Line of Credit requested:',
  anticipatedJobTotal: 'Anticipated Job Total:',
  totalNoOfMonths: 'Total # of months:',
  lineOfCredit: 'Line of credit:',
  letterOfCredit: 'LETTER OF CREDIT/GUARANTEE:',
  company: 'COMPANY:',
  federalTaxId: 'Federal tax ID #:',
  accountPaybleContact: 'ACCOUNTS PAYABLE CONTACT:',
  companyProfile: 'COMPANY PROFILE:',
  profile: 'Profile:',
  totalAnnualSales: 'Total Annual Sales:',
  startedOn: 'Started on:',
  networth: 'Networth:',
  typeOfBusiness: 'Type of Business:',
  noOfEmployees: '# of Employees:',
  reference1: 'Reference 1:',
  reference2: 'Reference 2:',
  reference3: 'Reference 3:',
  principalOfficer: 'Principal officer:',
  bankingReference: 'Banking reference:',
  tradeReferences: 'Trade references:',
  certificateOfInsurance: 'Certificate of Insurance',
  insuranceInformation: 'Insurance Information:',
  form: 'Form',
  termsAndConditions: 'Terms & Conditions',
  saveAndClose: 'Save & Close',
  submitCreditApplication: 'Submit Credit Application',
  submitRevisions: 'Submit Revisions',
  needsRevisions: 'NEEDS REVISIONS',
  revisionsSubmitted: 'REVISIONS SUBMITTED',
  billingAddress: 'BILLING ADDRESS:',
}
export const CRED_STEP_FOUR = {
  na: 'N/A',
  UseForBillingAddress: 'Use for billing address',
  signed: 'Signed',
  notSigned: 'Not Signed',
  back: 'Back',
  thankYou: 'Thank you for your submission.',
  text: 'We will look over your credit application and get back to you within 5-7 business days.',
  creditApplicationSavedSuccessfully: 'Credit Application Saved Successfully',
}

export const PASSES_EXPECTED_TODAY = {
  passesExpectedToday: 'Today’s Passes',
  all: 'All',
  passes: 'Passes',
  driveOn: 'Drive On',
  walkOn: 'Walk On',
  nextHour: 'Next Hour',
  today: 'Today',
  checkedIn: 'Checked In',
  viewAllPasses: 'View 5 Day Passes',
  technicalSpec: 'TechnicalSpec',
  floorPlan: 'Floor Plan',
  refresh: 'Refresh',
  checkedInStatus: 'Checked In',
  all_passes: 'All Passes',
  drive_on: 'Drive-On',
  walk_on: 'Walk-On',
  error: 'No response received'
};
export const CRED_DEPARTMENT_CONSTANT = {
  credDepartmentFormTitle: 'Credit Application Departments',
  cancel: 'Cancel',
  saveChanges: 'Save Changes',
  deptEditError: 'Error While Updating Credit Application Departments',
  deptEditSuccess: 'Credit Application Departments Updated Successfully'
};
export const REQUEST_AR = {
  request: 'Request A/R #',
  department: 'Select Departments',
  cancel: 'Cancel',
  upload: 'Next Upload PO & COI',

};
export const REQUEST_FILE_AR = {
  request: 'Request A/R #',
  upload: 'Upload a Purchase Order',
  certificate : 'Upload a certificate of Insurance:',
  back: 'Back',
  cancel: 'Cancel',
  button: 'Next Upload PO & COI',

}

export const CUSTOMER_CREDIT_APPLICATION_STATUS = 
[
  { id: 1, text: 'Approved' },
  { id: 2, text: 'Application Submitted' },
  { id: 3, text: 'Cash On Demand' },
  { id: 4, text: 'Finish Application' },
  { id: 5, text: 'Needs Revisions' },
  { id: 6, text: 'Revisions Submitted' },
  { id: 7, text: 'Start Application' },
  { id: 8, text: 'Under Review' }
]

export const CRED_STEP_ONE_CONSTANT = {
  view: 'View',
  needsRevision: 'NEEDS REVISIONS',
  revisionsSubmitted: 'REVISIONS SUBMITTED',
  startApplication: 'Start Application',
  finishApplication: 'Finish Application',
  enable: 'enable',
  disable: 'disable'
}

export const CREDIT_APP_REPORT_SECTION = {
  dnbSection: 'dnb',
  locSection: 'loc',
  coiSection: 'coi',
  trSection: 'traderef1'
}

//Success message for the API
export const CUSTOMER_SUCCESS_APIMESSAGE = {
  //dashboard

  //project

  //company

}

export const VIEW_ALL_CREDIT_APPLICATIONS = {
  hii: 'Hi',
  filter: 'FILTER :',
  clear:'Clear All',
  Creditapplications: 'Credit Applications',
  alldepartment: 'All Departments'
}

export const THIRD_PARTY_REVISION={
  title:'Request A/R #',
  revision:'Revisions needed:',
  uploadHeading1:'Upload a Purchase Order',
  uploadHeading2:'Upload a certificate of Insurance',
  view:'view',
  back:'Back',
  cancel:'Cancel',
  send:'Send A/R # Request'
}

export const CONFIRM_CLOSE_POPUP = {
  confirmCreditAppCloseMsg: 'Are you sure you want to close the application without saving?',
  yesBtn: 'Yes',
  noBtn: 'No'
}
export const MESSAGE = {
  fileSizeExceedFiveMB: 'File size exceeds 5 MB',
  imageTypeSupportedMessage: 'The allowed file types are PNG, JPG/JPEG'
}
export const REQUEST_CONSTANTS_CP = {
  makeArequest: '+ Make a Request',
  filter: 'FILTERS:',
  addARequest: 'Add A Request',
  next: 'Next',
  requests: 'Requests',
  clearFilters: 'clear filters',
  close: 'Close',
  projects: 'Projects',
  selectProject:'Select Project',
  closeWithoutSavingText: 'Are you sure you want to close without saving?',
  yes: 'Yes',
  no: 'No',
  newRequest: 'NEW REQUEST',
  resolved: 'RESOLVED',
  inProgress: 'IN PROGRESS',
  closed: 'CLOSED',
  assigned: 'ASSIGNED',
  submitted: 'SUBMITTED',
  cancelled: 'CANCELLED',
  new: 'NEW',
  request: 'Request',
  burbankHeading: 'Burbank Main Lot Facilities Service Request',
  burBankPara: 'Create a Main Lot Facilities Service Request',
  burBankParaOne: 'Please use this form to report Burbank Main Lot Facilities Maintenance issues for your building, office or workspace. Select the issue you are reporting from the “Request Type” drop down field. Confirm your location (building number) is accurate in the “Building” field. Please include your office/room number in the “Additional Information” section, you may also include notes and related information in this field. If your issue does not appear in the drop down selections, please reach out to Studio Operations for assistance at 818-954-6777.',
  burBankParaTwo: 'You will receive an email confirmation containing ticket details and who to contact, should you need to update your request. Additional comments made after the initial ticket is submitted, will not be received.',
  burBankParaThree: 'Note: Please do not use this form for URGENT issues, such as a water leak, power outage, or other facility related critical matters.  Instead call 818-954-1700, to report any URGENT utility related matters.',
  requestor: 'Requestor',
  date: 'Date',
  productionCompany: 'Production Company',
  productionName: 'Production Name',
  building: 'Building',
  locationDetails: 'Location Details',
  requestCatagory: 'Request Category',
  requestType: 'Request Type',
  requestDetails: 'Request Details',
  attachments: 'Attachments',
  closeModalText: 'Close',
  hotLockStageRequest: 'Hot Lock Stage Request',
  costumesCreditCardAuthorization: 'Costumes – Credit Card Authorization',
  authorizedUsers: 'Authorized Users',
  afterHoursEmergencyContact: 'After Hours Emergency Contact : ***Required***',
  signature: 'Signature',
  dated: 'Dated:',
  emailAddress: 'Email Address',
  jobShowName: 'Job/Show Name',
  cardholderName: 'Cardholder Name:',
  firstName: 'First Name',
  lastName: 'Last Name',
  termsAndConditions: 'Terms and Conditions',
  responsibilities: 'Responsibilities',
  returnItems: 'We, the undersigned, shall be responsible for the return of all rented items (the “Articles”) in the same condition as received, reasonable wear and tear excepted.',
  rentalCharges: 'We, the undersigned, shall be responsible for any approval, rental and extended rental charges (collectively, “rental charges”) and any other charges associated with loss and damage, cleaning, returns, restocking and/or labor (collectively, “additional charges").',
  liabilityAcknowledgment: 'We, the undersigned, acknowledge that WBSO\'s liability, if any, for damages arising out of any breach, errors, omissions, interruptions, delays or defects in any of the Articles or services provided by WBSO Costumes shall in no event exceed an amount equal to the amount charged pursuant to this Credit Card Authorization.',
  authorization: 'We, the undersigned, will authorize our credit card processor to pay any charges initiated from WBSO Costumes related to the job. For our convenience, we, the undersigned, acknowledge that we may elect to securely store credit card information with WBSO Costume’s third-party credit card processor for current and future transactions with WBSO Costumes. The undersigned also acknowledges that charges may be recurring, and in such event, this authorization shall remain in full force and effect, unless we revoke such authorization through WBSO Costume’s third-party credit card processor.',
  initialDeposit: 'We, the undersigned, acknowledge that this authorization allows WBSO Costumes to charge our credit card an initial deposit and any additional deposits to cover rental charges and additional charges, unless we have made other acceptable payment arrangements with WBSO Costumes and such payment arrangements are approved by the WBSO Accounts Receivable Department.',
  authorizedUsersList: 'Authorized Users List',
  emergencyContact: 'Emergency Contact',
  contactName: 'Contact Name',
  contactTitle: 'Contact Title',
  contactCell: 'Contact Cell',
  contactHome: 'Contact Home',
  signatureRequired: 'Signature Required',
  printName: 'Print Name',
  title: 'Title',
  hotLockStage: 'Hot Lock Stage',
  stageDetails: 'Stage Details',
  startDate: 'Start Date',
  releaseDate: 'Release Date',
  revisedDate: 'Revised Date',
  upm: 'UPM',
  cell: 'Cell',
  home: 'Home',
  responsibilitiesAcknowledgment: 'Responsibilities Acknowledgment',
  returnCondition: 'Return Condition',
  rentalAgreement: 'Rental Agreement',
  authorizedUsersNote: 'Only Authorized Users may pull Articles from WBSO Costumes. A receipt for each transaction will be provided to the cardholder of record.',
  invoiceCopy: 'Send Invoice Copy?',
  authorizedUserName: 'Authorized User Name',
  authorizedUserEmail: 'Authorized User Email',
  sendInvoice: 'Send Invoice?',
  additionalInformation: 'Additional Information',
  attachmentsNote: 'Please attach any relevant documents or files.',
  name: 'Name',
  email: 'Email',
  greenlight: 'Greenlight-Hot Lock Stage Request',
  showname: 'Show Name',
  emergency: 'After Hours Emergency Contact : ***Required***',
  personAut: 'PERSONS AUTHORIZED TO OPEN STAGE  (Alphabetize by Last Name)',
  stages: 'Stages(s)',
  longPara: 'The representatives from our company listed below (“Authorized Users”) are authorized to charge Articles from WBSO Costumes for payment. Only Authorized Users may pull Articles from WBSO Costumes. A receipt for each transaction will be provided to the cardholder of record. Please indicate which, if any, authorized users should be sent invoice copies.',
  greenLightCrdit: 'Greenlight-Credit Card Authorization',
  credOne: '4000 Warner Blvd, Building 153 Lower Level',
  burOne: 'Burbank, California 91522',
  phoneOne: 'Phone: (818) 954-1297 Option 1',
  wbOne: 'wbcostumesnewaccounts@warnerbros.com',
  wOne: 'Warner Bros. Studio Operations – Costume',
  depOne: 'Department (“WBSO Costumes”)',
  creditOne: 'Costumes – Credit Card Authorization',
  hotLock: 'Hot Lock Stage Request',
  burbank: 'Burbank Main Lot Facilities Request',
  error: 'Selected Form type does not exist in database',
  noRequestData:'No Requests To Display'
};

export const TITLE_CASE_REGEX = /\b\w/g;
export const DATE_PICKER_REGEX = /\//g;
