import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
export const timeZones: string[] = [
  'UTC',
  'GMT',
  'EST',
  'PST',
  'CET',
];
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})


export class ProfileComponent implements OnInit {
  selectedTab: string = 'profile-tab';
  profileForm: FormGroup;
  settingsForm: FormGroup;
  form: FormGroup;
  timeZones: string[] = timeZones;
 
  constructor(private fb: FormBuilder) {}
 
  ngOnInit(): void {
    // Initialize the profile form
    this.profileForm = new FormGroup({
      input1: new FormControl(null, {
        validators: [Validators.required]
      }),
      timezone: new FormControl(null, {
        validators: [Validators.required]
      }),
      dateFormat: new FormControl('m/d/yyyy', {
        validators: [Validators.required]
      }),
      notifications: new FormControl(true, {
        validators: [Validators.required]
      })
    });
   
    this.settingsForm = this.fb.group({
      timezone: ['', Validators.required],
      dateFormat: ['m/d/yyyy', Validators.required],
      calendarFormat: ['sunday', Validators.required],
      timeFormat: ['12h', Validators.required],
      temperatureUnit: ['fahrenheit', Validators.required],
    });
 
    this.form = this.fb.group({
      creditApplications: [false],
      company: [false],
    });
  }
 
  selectTab(tab: string): void {
    this.selectedTab = tab;
  }
 
  onSelectChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedTab = selectElement.value;
  }
 
  onSave(): void {
    if (this.profileForm.valid) {
      // If block
    }
  }
 
  onDiscard(): void {
    this.profileForm.reset();
  }
 
  onSaveSettings(): void {
    if (this.settingsForm.valid) {
      const settingsData = this.settingsForm.value;
      
    } else {
      console.warn('Form is invalid');
    }
  }
}

