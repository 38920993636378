export const province=[
    {"id": 1, "name": "Alberta"},
    {"id": 2, "name": "British Columbia"},
    {"id": 3, "name": "Manitoba"},
    {"id": 4, "name": "New Brunswick"},
    {"id": 5, "name": "Newfoundland and Labrador"},
    {"id": 6, "name": "Nova Scotia"},
    {"id": 7, "name": "Northwest Territories"},
    {"id": 8, "name": "Nunavut"},
    {"id": 9, "name": "Ontario"},
    {"id": 10, "name": "Prince Edward Island"},
    {"id": 11, "name": "Quebec"},
    {"id": 12, "name": "Saskatchewan"},
    {"id": 13, "name": "Yukon"}
]
