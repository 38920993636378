<button data-bs-toggle="modal" data-bs-target="#addUser">Open add customer user modal</button>
<!-- common modal section-->
<div class="modal fade" id="addUser" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-fullscreen">
		<div class="modal-content common-modal-xl">
			<div class="modal-body justify-content-center align-items-center">
				<div class="cm-header-section">
					<h5 class="modal-title" id="commonModalLabel">Add A Customer User</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
							class="cm-close-text">Close</span></button>
							<a type="link" class="back-link text-link" aria-label="Close"><span
								class="cm-close-text">< Back</span></a>
					<p class="cm-subtitle mb-5">Chuck Lorre Productions</p>
				</div>
				<div class="container cm-main-container">
					<div class="common-modal-input-section">
						<h2 class="section-main-header">User Projects & Permissions</h2>
						<div class="">
							<div class="mt-56 custom-floating-label">
								<label for="input1" class="form-label">Search</label>
								<input type="text" class="pe-4 form-control" placeholder="Search company name or company ID #" id="input1">
								<img class="search-icon" src="../../../../../../assets/images/search-icon.svg" alt="Search Icon">
							</div>
						</div>
					</div>
					<div class="mb-40 cm-btn-section">
						<button type="button" class="btn common-btn-secondary me-2" data-bs-dismiss="modal">Next: Add User</button>
					</div>
					<div class="common-modal-input-section">
						<!-- <h2 class="section-main-header">Section header</h2> -->
						<form>
							<!-- <h3 class="form-section-header">Group Name</h3> -->
							<div class="row">
								<div class="col-md-6 custom-floating-label">
									<label for="input1" class="form-label">First Name</label>
									<input type="text" class="form-control" placeholder="First Name" id="input1">
								</div>
								<div class="col-md-6 custom-floating-label">
									<label for="input2" class="form-label">Last Name</label>
									<input type="text" class="form-control" placeholder="Last Name" id="input2">
								</div>
							</div>
							<div class="row">
								<div class="col-md-6 custom-floating-label">
									<label for="input3" class="form-label">Email</label>
									<input required type="email" class="form-control" placeholder="Email" id="input3">
								</div>
								<div class="col-md-3 custom-floating-label">
									<label for="input4" class="form-label">Job Title</label>
									<input type="text" class="form-control" placeholder="Job Title" id="input4">
								</div>
							</div>
							<div class="col-md-8">
								<label class="custom-checkbox">
									<input type="checkbox">
									<span class="checkmark"></span>
									Make the user an account admin
								</label>
							</div>
						</form>
					</div>
					<div class="common-modal-input-section">
						<h2 class="section-main-header">User Projects & Permissions</h2>
						<div class="comman-tabs-block mt-5">
							<ul class="nav" role="tablist">
								<li class="nav-item">
									<a class="nav-link active" aria-current="page" data-bs-toggle="tab" href="#active" role="tab"
										aria-selected="true">Active <span>(3)</span></a>
								</li>
								<li class="nav-item">
									<a class="nav-link" data-bs-toggle="tab" href="#closed" role="tab"
										aria-controls="fill-tabpanel-1">Closed <span>(43)</span></a>
								</li>
							</ul>
							<div class="tab-content" id="tab-content">
								<div class="tab-pane space-project-tab active" id="active" role="tabpanel" aria-labelledby="fill-tab-0">
									<div class="common-checkbox-img-list">
										<ul class="list-group">
											<li class="list-group-item">
												<label class="custom-checkbox">
													<input type="checkbox">
													<span class="checkmark"></span>
												</label>
												<img src="../../../../../../assets/images/project-image.png" alt="">
												<span class="season-name">Mom - Season 8</span>
											</li>
											<li class="list-group-item">
												<label class="custom-checkbox">
													<input type="checkbox">
													<span class="checkmark"></span>
												</label>
												<img src="../../../../../../assets/images/wb-logo-black.svg" alt="">
												<span class="season-name">Yound Sheldon - Season 4</span>
											</li>
											<li class="list-group-item">
												<label class="custom-checkbox">
													<input type="checkbox">
													<span class="checkmark"></span>
												</label>
												<img src="../../../../../../assets/images/logo-i.png" alt="">
												<span class="season-name">Mom - Season 8</span>
											</li>
										</ul>
									</div>
								</div>
								<div class="tab-pane space-project-tab" id="closed" role="tabpanel" aria-labelledby="fill-tab-0">
									<div class="common-checkbox-img-list">
										<ul class="list-group">
											<li class="list-group-item">
												<label class="custom-checkbox">
													<input type="checkbox">
													<span class="checkmark"></span>
												</label>
												<img src="../../../../../../assets/images/logo-i.png" alt="">
												<span class="season-name">Mom - Season 8</span>
											</li>
											<li class="list-group-item">
												<label class="custom-checkbox">
													<input type="checkbox">
													<span class="checkmark"></span>
												</label>
												<img src="../../../../../../assets/images/wb-logo-black.svg" alt="">
												<span class="season-name">Yound Sheldon - Season 4</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="cm-btn-section">
						<button type="button" class="btn common-btn-light me-2" data-bs-dismiss="modal">Save</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--common modal section ends-->