export const companyFilterData = [
  { id: 1, text: 'The Court' },
  { id: 2, text: 'GOT' },
  { id: 3, text: 'Barbie' },
  { id: 4, text: 'Stranger Things' },
  { id: 5, text: 'The Witcher' },
];


export const companyStatusData = [
  { id: 1, text: 'Active' },
  { id: 2, text: 'Deactivated' }
];

export const companyUserTypeData = [
  { id: 1, text: 'Accounts Payable' },
  { id: 2, text:  'All Customers'},
  { id: 3, text: 'Company Admin'}
];

export const companyUserTypeahead = [
  { id: 1, text: 'userTypeahead' },
];


export const companyJobTitleTypeahead = [
  { id: 1, text: 'jobtitleTypeahead' },
];
export const companyTypeahead = [
  { id: 1, text: 'companyTypeahead' },
];
export const companiesStatusData = [
  { id: 1, text: 'Active' },
  { id: 2, text: 'Disabled' }
];
export const companyId= [];
export const activeProjectsTypeahead = [
  { id: 1, text: 'activeProjectsTypeahead' },
];
export const userIdTypeahead = [
  { id: 1, text: 'userIdTypeahead' },
];
