import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/shared/services/base-api/base-api.service';
import { CUSTOMER_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';

@Injectable({
  providedIn: 'root'
})
export class ProjectsListService {

  constructor(public baseApiService: BaseApiService) { }

  getProjectsData(userId: Number) {
    let endpoint = `${CUSTOMER_PORTAL_API_PATHS.projects_list}?userProfileId=${userId}`;
    return this.baseApiService.get(endpoint);
  }

  getProjectDataById(projectId: Number, userId: Number) {
    let endpoint = `${CUSTOMER_PORTAL_API_PATHS.project_details}?projectId=${projectId}&userProfileId=${userId}`;
    return this.baseApiService.get(endpoint);
  }

  updateProjectDescription(data: any) {
    let endpoint = `${CUSTOMER_PORTAL_API_PATHS.edit_project_description}`;
    return this.baseApiService.put(endpoint, data);
  }

  /**
   * bookmarkSpacesAndResources() - method to add the bookmark using payload data
   * @param bookmarkPayload - payload includes profileid, projectid, status and projectBookmarked flag
   * @returns - returns the status of the added bookmark status
   */
  addBookmarkSpacesAndResources(data: any) {
    const endpoint = `${CUSTOMER_PORTAL_API_PATHS.add_bookmark}`;
    return this.baseApiService.post(endpoint, data);
  }

  /**
* bookmarkSpacesAndResources() - method to add the bookmark using payload data
* @param bookmarkPayload - payload includes profileid, projectid, status and projectBookmarked flag
* @returns - returns the status of the added bookmark status
*/
  removeBookmarkSpacesAndResources(data: any) {
    const endpoint = `${CUSTOMER_PORTAL_API_PATHS.remove_bookmark}`;
    return this.baseApiService.post(endpoint, data);
  }

  /**
   * addImage() - to upload image to database
   * @param data - the data that need to be send to api
   * @returns - an observable with a success message 
   */
  addImage( data:any){
    const endpoint = `${CUSTOMER_PORTAL_API_PATHS.upload_image}`;
    return this.baseApiService.put(endpoint, data);
  }
  resetImage(data:any){
    const endpoint =`${CUSTOMER_PORTAL_API_PATHS.resetPhoto}`;
    return this.baseApiService.put(endpoint,data);
  }
}
