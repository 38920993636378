<div *ngIf = "permissionsData['creditappWidget']" class="comman-panel-block panel panel-default panel-credit-block">
    <div class="panel-heading">
        <h2>{{creditApplication.creditApplication}}</h2>
    </div>
    <div class="panel-body">
        <div class="container panel-credit-block-body">
            <ag-grid-angular #creditGrid class="ag-theme-custom no-header" [gridOptions]="gridOptions"
                [columnDefs]="colDefsCredit" [rowData]="rowDataCredit" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
    <div class="panel-footer">
        <div class="panel-footer-content "><a class="text-link" href="">{{creditApplication.viewCredit}}</a>
        </div>
    </div>
</div>