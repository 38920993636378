    <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form">
      <div class="page-wrapper">
        <div class="page-header">
          <h2>{{creditApplicationConstants.sendCreditApplication}}</h2>
        </div>
        <div class="title-company-project">
          <div class="company">
            <span class="company-heading">{{creditApplicationConstants.company}}:</span>
            <span class="company-content">{{companyName}}</span>
          </div>
          <div class="project">
            <span class="project-heading">{{creditApplicationConstants.project}}:</span>
            <span class="project-content">{{projectName}}</span>
          </div>
        </div>

        <div class="section-text-area max-height-textarea" [innerHtml]="creditApplicationConstants.sendCALetterText" id="credit-text" name="credit-text" formControlName="caTextbox" (input)="onHtmlContentChange($event)" contenteditable="true" appClickableLinks>
      
        </div>
        <div class="common-checkbox-section">
          <div class="row">
          <h3 class="checkbox-heading">{{ creditApplicationConstants.departments }}</h3>
          
            <div class="col-md-4 col-lg-4 col-sm-6 col-6 mb-3"
              [ngClass]="{'col-lg-12 col-md-12 col-sm-12 col-12': first}"
              *ngFor="let dept of departmentDetails; let first = first">
              <div class="common-checkbox-button">
                <input type="checkbox" [id]="'custom-checkbox-' + dept.departmentId" class="common-checkbox-input"
                  formControlName="department{{dept.departmentId}}" [value]="dept.departmentId"
                  (change)="onDepartmentCheckboxChange($event,dept)" />
                <label [for]="'checkbox-' + dept.departmentId" class="common-checkbox-label">
                  {{ dept.departmentName }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="account-payable-contact">
          <h4 class="account-payable-heading">{{creditApplicationConstants.sendToAccountPayable}}:</h4>
          <div *ngFor = "let user of accountPayableUsers" class="col-md-12">
            <div class="common-checkbox-button mb-4">
              <input type="checkbox" [id]="'custom-checkbox-' + user.userProfileId" class="common-checkbox-input" formControlName="caAccountPayableUser{{user.userProfileId}}" [value]="user.userProfileId"/>
              <label [for]="'checkbox-' + user.userProfileId" class="common-checkbox-label ellipses-email text-label"
                title="SEND TO ACCOUNTS PAYABLE CONTACTS">
                {{ user.firstName }} {{ user.lastName }} ({{ user.emailAddress }})
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="end-wrapper">
        <div class="account-payable-user">
          <img src="assets/images/add-user.svg" alt="add-user" />
          <span class="text-link" (click)="openAddUserModal()">{{creditApplicationConstants.addAnAccountPayable}}</span>
                        
        </div>
        <div class="button">
          <button class="common-btn-light btn" (click)="closeModal()">{{creditApplicationConstants.cancel}}</button>
          <button class="common-btn-secondary btn"
            [disabled]="form.invalid">{{creditApplicationConstants.sendToCustomer}}</button>
        </div>
      </div>
    </form>

    <ng-template #successTpl>
      <div class="success">{{displaySuccessMsg}}</div>
    </ng-template>
    
    <ng-template #dangerTpl>
      <div class="danger">{{errorResponse}}</div>
    </ng-template>
    
    <app-toast aria-live="polite" aria-atomic="true"></app-toast>
    