<button data-bs-toggle="modal" data-bs-target="#credApp">Review step 1</button>
<!-- common modal section-->
<div
  class="modal fade"
  id="credApp"
  tabindex="-1"
  aria-labelledby="commonModalLabel"
  aria-hidden="false"
>
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
          <h5 class="modal-title" id="commonModalLabel">Review</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="cm-close-text">Close</span>
          </button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
              <div class="modal-left-section me-4">
                <h4 class="common-left-section-title mt-4">Company Info</h4>
                <div class="company-meta-data">
                  <p class="title mb-0">
                    <strong class="common-title">PROJECT:</strong
                    ><span class="common-content">The new show</span>
                  </p>
                  <p class="title mb-0 pb-2">
                    <strong class="common-title">Comapny:</strong>
                  </p>
                  <p class="common-content pb-2 pt-0 mb-0">
                    A Brand New Production Company
                  </p>
                  <p class="title mb-0">
                    <strong class="common-title">TYPE:</strong
                    ><span class="common-content">Major Studio</span>
                  </p>
                  <p class="title mb-0">
                    <strong class="common-title">APPLICATION DATE:</strong
                    ><span class="common-content">1/1/24</span>
                  </p>
                  <div class="d-flex flex-column mt-2">
                    <span class="mb-1"
                      ><a class="text-link"
                        ><img
                          src="../assets/images/link-icon.svg"
                          alt=""
                          class="me-2"
                        />View Projects & Quotes</a
                      ></span
                    >
                    <span class="mb-1"
                      ><a class="text-link"
                        ><img
                          src="../assets/images/link-icon.svg"
                          alt=""
                          class="me-2"
                        />View Company</a
                      ></span
                    >
                  </div>
                </div>
                <hr />
                <h4 class="common-left-section-title">Review</h4>
                <div class="mb-4 mt-3">
                  <div class="list-group list-group-flush mb-2">
                    <ul class="step-list">
                      <li>
                        <span class="step-icon step-uncheck"></span>
                        <span class="step-text current-step">
                          Application Form</span
                        >
                      </li>
                      <li>
                        <span class="step-icon step-uncheck"></span>
                        <span class="step-text">
                          Certification of Insurance</span
                        >
                      </li>
                      <li>
                        <span class="step-icon step-uncheck"></span>
                        <span class="step-text">Terms & Conditions</span>
                      </li>
                      <li>
                        <span class="step-icon step-uncheck"></span>
                        <span class="step-text">D&B Report</span>
                      </li>
                      <li>
                        <span class="step-icon step-uncheck"></span>
                        <span class="step-text">Summary</span>
                      </li>
                    </ul>
                  </div>
                  <span class=""
                    ><a class="text-link"
                      ><img
                        src="../assets/images/pdf-blue-icon.svg"
                        class="me-2"
                        alt=""
                      />Download Packet</a
                    ></span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="container cm-main-container px-0 mx-0">
                <div class="common-modal-input-section grey-bg review-panel">
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-7">
                      <h4 class="right-section-heading">Line of Credit</h4>
                    </div>
                    <div class="col-md-5">
                      <div class="custom-dropdown w-100">
                        <select class="custom-select">
                          <option
                            class="dropdown-option"
                            value=""
                            disabled
                            selected
                            hidden
                          >
                            Action
                          </option>
                          <option class="dropdown-option" value="1">
                            <img
                              src="../assets/images/action-revision.svg"
                              alt=""
                            />Ask for Revision
                          </option>
                          <option class="dropdown-option" value="2">
                            Mark as Reviewed
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="common-user-info">
                    <p class="title mb-0">
                      <strong class="common-title"
                        >Anticipated Job Total:</strong
                      ><span class="common-content">$xxx,xxx,xxx.xx</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Total # of months:</strong
                      ><span class="common-content">2</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title"
                        >Line of Credit requested:</strong
                      ><span class="common-content">$xxx,xxx,xxx.xx</span>
                    </p>
                  </div>
                  <hr class="my-5" />
                  <!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-7">
                      <h4 class="right-section-heading">Line of Credit</h4>
                    </div>
                    <div class="col-md-5">
                      <div class="custom-dropdown w-100">
                        <select class="custom-select">
                          <option
                            class="dropdown-option"
                            value=""
                            disabled
                            selected
                            hidden
                          >
                            Action
                          </option>
                          <option class="dropdown-option" value="1">
                            <img
                              src="../assets/images/action-revision.svg"
                              alt=""
                            />Ask for Revision
                          </option>
                          <option class="dropdown-option" value="2">
                            Mark as Reviewed
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="common-user-info">
                    <p class="title mb-0">
                      <strong class="common-title"
                        >Line of Credit requested:</strong
                      ><span class="common-content">$xxx,xxx,xxx.xx</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title"
                        >Anticipated Job Total:</strong
                      ><span class="common-content">$xxx,xxx,xxx.xx</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Total # of months:</strong
                      ><span class="common-content">2</span>
                    </p>
                  </div>
                  <hr class="my-5" />
                  <!-- divider -->

                  <div class="row mb-4 align-items-center">
                    <div class="col-md-7">
                      <h4 class="right-section-heading">
                        Letter of Credit/Guarantee
                      </h4>
                    </div>
                    <div class="col-md-5">
                      <div class="custom-dropdown w-100">
                        <select class="custom-select">
                          <option
                            class="dropdown-option"
                            value=""
                            disabled
                            selected
                            hidden
                          >
                            Action
                          </option>
                          <option class="dropdown-option" value="1">
                            <img
                              src="../assets/images/action-revision.svg"
                              alt=""
                            />Ask for Revision
                          </option>
                          <option class="dropdown-option" value="2">
                            Mark as Reviewed
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="common-user-info">
                    <p class="title mb-0">
                      <strong class="common-title">Comments:</strong
                      ><span class="common-content">None</span>
                    </p>
                  </div>
                  <hr class="my-5" />
                  <!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-7">
                      <h4 class="right-section-heading">Company</h4>
                    </div>
                    <div class="col-md-5">
                      <div class="custom-dropdown w-100">
                        <select class="custom-select">
                          <option
                            class="dropdown-option"
                            value=""
                            disabled
                            selected
                            hidden
                          >
                            Action
                          </option>
                          <option class="dropdown-option" value="1">
                            <img
                              src="../assets/images/action-revision.svg"
                              alt=""
                            />Ask for Revision
                          </option>
                          <option class="dropdown-option" value="2">
                            Mark as Reviewed
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="common-user-info">
                    <p class="title mb-0">
                      <strong class="common-title">Name:</strong
                      ><span class="common-content">A Brand New Company</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Address:</strong
                      ><span class="common-content"
                        >2500 Main Street, Los Angeles, CA, 92783</span
                      >
                    </p>
                    <p class="title mb-2">
                      <strong class="common-title">Phone:</strong
                      ><span class="common-content">858556565</span>
                    </p>
                    <p>
                      <input type="checkbox" name="" />
                      <label for="">Use for billing address</label>
                    </p>
                  </div>
                  <hr class="my-5" />
                  <!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-7">
                      <h4 class="right-section-heading">Federal Tax ID #</h4>
                    </div>
                    <div class="col-md-5">
                      <div class="custom-dropdown w-100">
                        <select class="custom-select">
                          <option
                            class="dropdown-option"
                            value=""
                            disabled
                            selected
                            hidden
                          >
                            Action
                          </option>
                          <option class="dropdown-option" value="1">
                            <img
                              src="../assets/images/action-revision.svg"
                              alt=""
                            />Ask for Revision
                          </option>
                          <option class="dropdown-option" value="2">
                            Mark as Reviewed
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="common-user-info">
                    <p class="title mb-0">
                      <strong class="common-title">TIN:</strong
                      ><span class="common-content">123456789</span>
                    </p>
                  </div>
                  <hr class="my-5" />
                  <!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-7">
                      <h4 class="right-section-heading">
                        Account Payable Contact
                      </h4>
                    </div>
                    <div class="col-md-5">
                      <div class="custom-dropdown w-100">
                        <select class="custom-select">
                          <option
                            class="dropdown-option"
                            value=""
                            disabled
                            selected
                            hidden
                          >
                            Action
                          </option>
                          <option class="dropdown-option" value="1">
                            <img
                              src="../assets/images/action-revision.svg"
                              alt=""
                            />Ask for Revision
                          </option>
                          <option class="dropdown-option" value="2">
                            Mark as Reviewed
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="common-user-info">
                    <p class="title mb-0">
                      <strong class="common-title">Name:</strong
                      ><span class="common-content">A Brand New Company</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Email:</strong
                      ><span class="common-content">name@domain.com</span>
                    </p>
                    <p class="title mb-2">
                      <strong class="common-title">Phone:</strong
                      ><span class="common-content">858556565</span>
                    </p>
                  </div>
                  <hr class="my-5" />
                  <!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-7">
                      <h4 class="right-section-heading">Company Profile</h4>
                    </div>
                    <div class="col-md-5">
                      <div class="custom-dropdown w-100">
                        <select class="custom-select">
                          <option
                            class="dropdown-option"
                            value=""
                            disabled
                            selected
                            hidden
                          >
                            Action
                          </option>
                          <option class="dropdown-option" value="1">
                            <img
                              src="../assets/images/action-revision.svg"
                              alt=""
                            />Ask for Revision
                          </option>
                          <option class="dropdown-option" value="2">
                            Mark as Reviewed
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="common-user-info">
                    <p class="title mb-0">
                      <strong class="common-title">Profile:</strong
                      ><span class="common-content">Corporate</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Date Started:</strong
                      ><span class="common-content">1/1/24</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Type of Business:</strong
                      ><span class="common-content">Corporation</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Total Annual Sales:</strong
                      ><span class="common-content">$xx.xx</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Net worth:</strong
                      ><span class="common-content">$xx.xx</span>
                    </p>
                    <p class="title mb-2">
                      <strong class="common-title"># of Employees:</strong
                      ><span class="common-content">0-25</span>
                    </p>
                  </div>
                  <hr class="my-5" />
                  <!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-7">
                      <h4 class="right-section-heading">
                        Principals/Officers or Partners
                      </h4>
                    </div>
                    <div class="col-md-5">
                      <div class="custom-dropdown w-100">
                        <select class="custom-select">
                          <option
                            class="dropdown-option"
                            value=""
                            disabled
                            selected
                            hidden
                          >
                            Action
                          </option>
                          <option class="dropdown-option" value="1">
                            <img
                              src="../assets/images/action-revision.svg"
                              alt=""
                            />Ask for Revision
                          </option>
                          <option class="dropdown-option" value="2">
                            Mark as Reviewed
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="common-user-info">
                    <p class="title mb-0">
                      <strong class="common-title">Name:</strong
                      ><span class="common-content">A Brand New Company</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Title:</strong
                      ><span class="common-content"
                        >2500 Main Street, Los Angeles, CA, 92783</span
                      >
                    </p>
                  </div>
                  <hr class="my-5" />
                  <!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-7">
                      <h4 class="right-section-heading">Banking References</h4>
                    </div>
                    <div class="col-md-5">
                      <div class="custom-dropdown w-100">
                        <select class="custom-select">
                          <option
                            class="dropdown-option"
                            value=""
                            disabled
                            selected
                            hidden
                          >
                            Action
                          </option>
                          <option class="dropdown-option" value="1">
                            <img
                              src="../assets/images/action-revision.svg"
                              alt=""
                            />Ask for Revision
                          </option>
                          <option class="dropdown-option" value="2">
                            Send Email
                          </option>
                          <option class="dropdown-option" value="2">
                            Won't Use
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="common-user-info">
                    <p class="title mb-0">
                      <strong class="common-title">Bank:</strong
                      ><span class="common-content">A Brand New Company</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Account Number:</strong
                      ><span class="common-content">1234 5678 9012 3456</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Name:</strong
                      ><span class="common-content">John Doe</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Title:</strong
                      ><span class="common-content">A Brand New Company</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Email:</strong
                      ><span class="common-content">name@domain.com</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Phone:</strong
                      ><span class="common-content">858556565</span>
                    </p>
                  </div>
                  <hr class="my-5" />
                  <!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-7">
                      <h4 class="right-section-heading">Trade Reference 1</h4>
                    </div>
                    <div class="col-md-5">
                      <div class="custom-dropdown w-100">
                        <select class="custom-select">
                          <option
                            class="dropdown-option"
                            value=""
                            disabled
                            selected
                            hidden
                          >
                            Action
                          </option>
                          <option class="dropdown-option" value="1">
                            <img
                              src="../assets/images/action-revision.svg"
                              alt=""
                            />Ask for Revision
                          </option>
                          <option class="dropdown-option" value="2">
                            Mark as Reviewed
                          </option>
                          <option class="dropdown-option" value="2">
                            Send Email
                          </option>
                          <option class="dropdown-option" value="2">
                            Won't Use
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="common-user-info">
                    <p class="title mb-0">
                      <strong class="common-title">Company:</strong
                      ><span class="common-content">Name</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Contact:</strong
                      ><span class="common-content">Data</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Address:</strong
                      ><span class="common-content"
                        >2500 Main Street, Los Angeles, CA, 92783</span
                      >
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Email:</strong
                      ><span class="common-content">name@domain.com</span>
                    </p>
                    <p class="title mb-2">
                      <strong class="common-title">Phone:</strong
                      ><span class="common-content">858556565</span>
                    </p>
                    <p>
                      <a href=""
                        ><img
                          src="../assets/images/upload.svg"
                          class="text-link"
                          width="20"
                          height="20"
                          alt=""
                        />Upload Document</a
                      >
                    </p>
                  </div>
                  <hr class="my-5" />
                  <!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-7">
                      <h4 class="right-section-heading">Trade Reference 2</h4>
                    </div>
                    <div class="col-md-5">
                      <div class="custom-dropdown w-100">
                        <select class="custom-select">
                          <option
                            class="dropdown-option"
                            value=""
                            disabled
                            selected
                            hidden
                          >
                            Action
                          </option>
                          <option class="dropdown-option" value="1">
                            <img
                              src="../assets/images/action-revision.svg"
                              alt=""
                            />Ask for Revision
                          </option>
                          <option class="dropdown-option" value="2">
                            Mark as Reviewed
                          </option>
                          <option class="dropdown-option" value="2">
                            Send Email
                          </option>
                          <option class="dropdown-option" value="2">
                            Won't Use
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="common-user-info">
                    <p class="title mb-0">
                      <strong class="common-title">Company:</strong
                      ><span class="common-content">Name</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Contact:</strong
                      ><span class="common-content">Data</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Address:</strong
                      ><span class="common-content"
                        >2500 Main Street, Los Angeles, CA, 92783</span
                      >
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Email:</strong
                      ><span class="common-content">name@domain.com</span>
                    </p>
                    <p class="title mb-2">
                      <strong class="common-title">Phone:</strong
                      ><span class="common-content">858556565</span>
                    </p>
                    <p>
                      <a href=""
                        ><img
                          src="../assets/images/upload.svg"
                          class="text-link"
                          width="20"
                          height="20"
                          alt=""
                        />Upload Document</a
                      >
                    </p>
                  </div>
                  <hr class="my-5" />
                  <!-- divider -->
                  <div class="row mb-4 align-items-center">
                    <div class="col-md-7">
                      <h4 class="right-section-heading">Trade Reference 3</h4>
                    </div>
                    <div class="col-md-5">
                      <div class="custom-dropdown w-100">
                        <select class="custom-select">
                          <option
                            class="dropdown-option"
                            value=""
                            disabled
                            selected
                            hidden
                          >
                            Action
                          </option>
                          <option class="dropdown-option" value="1">
                            <img
                              src="../assets/images/action-revision.svg"
                              width="20"
                              alt=""
                            />Ask for Revision
                          </option>
                          <option class="dropdown-option" value="2">
                            &#x29E9; Mark as Reviewed
                          </option>
                          <option class="dropdown-option" value="2">
                            Send Email
                          </option>
                          <option class="dropdown-option" value="2">
                            Won't Use
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="common-user-info">
                    <p class="title mb-0">
                      <strong class="common-title">Company:</strong
                      ><span class="common-content">Name</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Contact:</strong
                      ><span class="common-content">Data</span>
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Address:</strong
                      ><span class="common-content"
                        >2500 Main Street, Los Angeles, CA, 92783</span
                      >
                    </p>
                    <p class="title mb-0">
                      <strong class="common-title">Email:</strong
                      ><span class="common-content">name@domain.com</span>
                    </p>
                    <p class="title mb-2">
                      <strong class="common-title">Phone:</strong
                      ><span class="common-content">858556565</span>
                    </p>
                    <p>
                      <a href=""
                        ><img
                          src="../assets/images/upload.svg"
                          class="text-link"
                          width="20"
                          height="20"
                          alt=""
                        />Upload Document</a
                      >
                    </p>
                  </div>
                </div>
                <div class="grey-box">
                  <h2 class="section-main-header text-left common-heading">
                    Comments(5)
                  </h2>
                  <div class="comment-section">
                    <div class="row comment-list">
                      <div class="commnet-icon-section col-md-1">
                        <span class="user-icon-text">AK</span>
                      </div>
                      <div class="common-comment-section col-11">
                        <p class="font-14 mb-0">
                          <strong class="common-content-bold"
                            >Andrew Noel</strong
                          >
                        </p>
                        <p class="common-small-content">xx/xx/xx xx:xxAM</p>
                        <p class="common-normal-content">
                          Sent email to Trade Reference 1
                        </p>
                      </div>
                    </div>
                    <div class="row comment-list">
                      <div class="commnet-icon-section col-md-1">
                        <span class="user-icon-text">AK</span>
                      </div>
                      <div class="common-comment-section col-11">
                        <p class="font-14 mb-0">
                          <strong class="common-content-bold"
                            >Andrew Noel</strong
                          >
                        </p>
                        <p class="common-small-content">xx/xx/xx xx:xxAM</p>
                        <p class="common-normal-content">
                          Asked revisions for Banking References:<br />
                          <strong class="common-content-extra-bold"
                            >“Could you please let us know the contact’s full
                            name?”</strong
                          >
                        </p>
                      </div>
                    </div>
                    <div class="row comment-list no-border">
                      <div class="commnet-icon-section col-md-1">
                        <span class="user-icon-text">AK</span>
                      </div>
                      <div class="common-comment-section col-11">
                        <p class="font-14 mb-0">
                          <strong class="common-content-bold"
                            >Andrew Noel</strong
                          >
                        </p>
                        <p class="common-small-content">xx/xx/xx xx:xxAM</p>
                        <p class="common-normal-content">Lorem Ipsum</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cm-btn-section">
                  <button
                    type="button"
                    class="btn common-btn-secondary me-2 mb-2"
                  >
                    Next:Certificate of Insurance
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!---Added modals on click of Select Company-->
<ng-template #company let-modal>
  <div class="modal-body">
    <h2 class="section-main-header">Needs Revisions</h2>
    <p>COMPANY: A Brand New Company</p>
    <p>PROJECT: The new show</p>
    <div class="">
      <div class="mt-56 custom-floating-label">
        <label for="input1" class="form-label">Comments</label>
        <textarea name="comments" id="" rows="4"></textarea>
        <img
          class="search-icon"
          src="../../../../../../assets/images/search-icon.svg"
          alt="Search Icon"
        />
      </div>
    </div>
    <div class="d-flex justify-content-end gap-2">
      <button
        type="button"
        class="btn common-btn-secondary"
        (click)="modal.close('Close click')"
      >
        Cancel
      </button>
      <button type="button" class="btn common-btn-secondary">Select</button>
    </div>
  </div>
</ng-template>
