import { ChangeDetectorRef, Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { accountFaqData, clientResourceFaqData, contactUsFaqData, overviewFaqData, requestsFaqData, usersFaqData, yourProjectsFaqData } from 'mockData/development/faq';
import { FAQ } from 'src/app/core/constants/core.constant';
import * as _ from 'lodash';
import { NUMBER } from 'src/app/core/enum/core.enum';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FaqServiceService } from 'src/app/core/services/faq-service.service';
import { FaqCategory, FaqResponse, FaqSearchResponse } from 'src/app/shared/models/faqModel';
import { ToastService } from 'src/app/shared/components/toast.service';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqComponent implements OnInit {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @ViewChild('navigateAwayModal') navigateAwayModal!: TemplateRef<any>;

  private modalService = inject(NgbModal);
  isEditable: boolean = false;
  formGroups: { [key: string]: FormGroup } = {};
  mode: any = 'View';
  sectionToDelete: { tab: string, index: number } | null = null;
  constants = FAQ;
  number = NUMBER;
  overviewFaqData = overviewFaqData;
  accountFaqData = accountFaqData;
  yourProjectsFaqData = yourProjectsFaqData;
  requestsFaqData = requestsFaqData;
  usersFaqData = usersFaqData;
  contactUsFaqData = contactUsFaqData;
  clientResourceFaqData = clientResourceFaqData;
  faq: any[] = [];
  getFaqdata: any;
  isAuthenticated: boolean = false;
  selectedTab: string = this.constants.overview_tab;
  faqCategories: any;
  overview: FaqCategory;
  account: FaqCategory;
  your_project: FaqCategory;
  request: FaqCategory;
  users: FaqCategory;
  contact_us: FaqCategory;
  client_resource: any;
  your_projects: FaqCategory;
  requests: FaqCategory;
  projects: FaqCategory;
  portalType: string;
  successMessage: string;
  errorMessage: string;
  hasUnsavedChanges: boolean = false;
  permissionsData: any;

  constructor(private FaqServiceService: FaqServiceService,
    private cdf: ChangeDetectorRef,
    private authService: AuthService,
    private toastService: ToastService) {
  }

  ngOnInit() {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.permissionsData = { ...userProfile.roleAssigned };

    this.getFaqData();
    this.checkAuthentication();
  }

  private async checkAuthentication(): Promise<void> {
    this.isAuthenticated = await this.authService.isAuthenticated();
    if (this.isAuthenticated) {
      const userProfileData = localStorage.getItem('user-profile');
      if (userProfileData) {
        const userProfile = JSON.parse(userProfileData);
        this.setPortalType(userProfile);
      }
    }
  }

  private setPortalType(userProfile: any): void {
    if (userProfile.userTypeId === 1) {
      this.portalType = "customer";
    } else if (userProfile.userTypeId === 2) {
      this.portalType = "studio";
    }
  }


  /**
   * selectTab() - method to update the currently selected tab.
   * @param tab - The identifier for the tab to be selected. This is typically a string that corresponds to one of the available tabs.
  */
  selectTab(tab: string) {
    this.selectedTab = tab;
  }

  /**
   * onSelectChange() - method to render different tabs after clicking on the button 
   * @param event - The change event object associated with the select element.
  */
  onSelectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedTab = selectElement.value;
  }

  /**
 * onToggle() - Toggles the mode between 'Edit' and 'View'.
 * When the form is in edit mode, the mode is set to 'Edit'.
 * When the form is in view mode, the mode is set to 'View'.
 * This method is typically used to switch the user interface 
 * between editable and non-editable states.
 */
  onToggle() {
    this.mode = this.isEditable ? 'View' : 'Edit';
    this.getFaqData();
  }

  /**
   * getFaqData() - method to fetch the faq details
   */
  getFaqData(): void {
    this.FaqServiceService.getFaqdata().subscribe({
      next: (response: any) => {
        if (response) {
          this.faq = response?.faqSearch;

          // Initialize categories
          const categories = {
            overview: null,
            account: null,
            projects: null,
            requests: null,
            users: null,
            contact_us: null,
            client_resource: null
          };

          // Populate categories based on faqCategoryId
          this.faq.forEach(category => {
            const uniqueFaqs = _.uniqBy(category.faq, item => `${item.faqQuestions}&&${item.faqAnswers}`);
            switch (category.faqCategoryId) {
              case 1:
                categories.overview = {
                  ...category,
                  faq: uniqueFaqs
                };
                break;
              case 2:
                categories.account = {
                  ...category,
                  faq: uniqueFaqs
                };
                break;
              case 3:
                categories.projects = {
                  ...category,
                  faq: uniqueFaqs
                };
                break;
              case 4:
                categories.requests = {
                  ...category,
                  faq: uniqueFaqs
                };
                break;
              case 5:
                categories.users = {
                  ...category,
                  faq: uniqueFaqs
                };
                break;
              case 6:
                categories.contact_us = {
                  ...category,
                  faq: uniqueFaqs
                };
                break;
              case 7:
                categories.client_resource = {
                  ...category,
                  faq: uniqueFaqs
                };
                break;
              default:
                break;
            }
          });

          // Assign the categories to the component properties
          this.overview = categories.overview;
          this.account = categories.account;
          this.projects = categories.projects;
          this.requests = categories.requests;
          this.users = categories.users;
          this.contact_us = categories.contact_us;
          this.client_resource = categories.client_resource;
        } else {
          console.warn('FAQ data could not be retrieved.');
        }
      },
      error: (error) => {
        console.error('Error fetching FAQ details:', error);
      },
    });
  }

  /**
   * onModeChange() - Method to get the value of mode change from child
   * @param newMode - new mode change value from parent
   */
  onModeChange(newMode: string) {
    this.mode = newMode;
    if (this.mode == 'View') {
      this.isEditable = false;
    }
    else {
      this.isEditable = true;
    }
    this.getFaqData();
  }

  /**
   * showToast() - Method to display the toast message
   * @param event - Toast data from the parent component
   */
  showToast(event: { type: string; message: string }): void {
    const { type, message } = event;
    const template = type === 'success' ? this.successTpl : this.dangerTpl;
    this.successMessage = message;
    this.errorMessage = message;
    this.toastService.show(template, {
      classname: type === 'success' ? 'custom-toast' : 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
   * hasUnsavedChangesValue - method to get the unsaved changes value
   * @param event - value of unsaved changes
   */
  hasUnsavedChangesValue(event) {
    this.hasUnsavedChanges = event;
  }
}
