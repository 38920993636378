import { Component, Input, OnInit } from '@angular/core';
import { STUDIO_MENU_LABELS, CUSTOMER_MENU_LABELS } from '../../constants/core.constant';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() sticky: boolean = false;
  @Input() portalType: any;
  finalresult: { label: string; link: string; id: string; }[];
  userTypeId: number;

  constructor(private router: Router) { }
  /**
   * ngOnInit() - Lifecycle hook that is called after data-bound properties are initialized.
   * Calls the getLinks() method to initialize the menu links.
   */
  ngOnInit() {

    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.userTypeId = userProfile?.userTypeId;
    if (this?.userTypeId == 1) {
      this.portalType = 'customer';
    } else if (this?.userTypeId == 2) {
      this.portalType = 'studio';
    }
    this.getLinks();
  }
  /**
   * getLinks() - Method to generate menu links based on portalType.
   * Populates the finalresult array with appropriate menu items.
   */
  getLinks() {
    if (this.portalType === 'customer') {
      this.finalresult = [
        { label: CUSTOMER_MENU_LABELS.overview, link: '/feature/customer-dashboard/overview', id: 'overview' },
        { label: CUSTOMER_MENU_LABELS.projects, link: '/feature/customer-dashboard/projects-list', id: 'projects' },
        { label: CUSTOMER_MENU_LABELS.requests, link: '/feature/customer-dashboard/requests', id: 'requests' },
        { label: CUSTOMER_MENU_LABELS.company, link: '/feature/customer-dashboard/company', id: 'company' }
      ];
    } else {
      this.finalresult = [
        { label: STUDIO_MENU_LABELS.myDashboard, link: '/feature/studio-dashboard/Mydashboard', id: 'Mydashboard' },
        { label: STUDIO_MENU_LABELS.projects, link: '/feature/studio-dashboard/project/project-list', id: 'projects' },
        { label: STUDIO_MENU_LABELS.users, link: '/feature/studio-dashboard/users', id: 'users' },
        { label: STUDIO_MENU_LABELS.companies, link: '/feature/studio-dashboard/company/companies', id: 'companies' }
      ];
    }
  }
}
