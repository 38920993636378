 import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { core_directory, directoryTabConfig } from 'src/app/core/constants/core.constant';
import { NUMBER } from 'src/app/core/enum/core.enum';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { CompanyService } from 'src/app/feature/studio-portal/services/company.service';

@Component({
  selector: 'app-core-directory',
  templateUrl: './core-directory.component.html',
  styleUrls: ['./core-directory.component.scss']
})
export class CoreDirectoryComponent {
  constants = core_directory;
  number = NUMBER;
  selectedTab: string = this.constants.office_production_services_tab;
  portalType: string;
  isUserLoggedIn: boolean = false;
  isAuthenticated: boolean = false;
  directoryContent: any;
  directoryConfig = directoryTabConfig;


  constructor(private authService: AuthService,private companyService: CompanyService,private sanitizer: DomSanitizer) {}

  /**
   * selectTab() - method to update the currently selected tab.
   * @param tab - The identifier for the tab to be selected. This is typically a string that corresponds to one of the available tabs.
  */
  selectTab(tab: string) {
    window.scrollTo(0,0);
    this.selectedTab = tab;
    this.directoryApiProd(this.selectedTab);

  }
   /**
   * onSelectChange() - method to render different tabs after clicking on the button 
   * @param event - The change event object associated with the select element.
  */
  onSelectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedTab = selectElement.value;

  }

  ngOnInit(): void {
    this.checkAuthentication();
    this.directoryApiProd(this.selectedTab);

  }

  private async checkAuthentication(): Promise<void> {
    this.isAuthenticated = await this.authService.isAuthenticated();
    if (this.isAuthenticated) {
      const userProfileData = localStorage.getItem('user-profile');
      if (userProfileData) {
        const userProfile = JSON.parse(userProfileData);
        this.setPortalType(userProfile);
      }
    }
  }

  private setPortalType(userProfile: any): void {
    if (userProfile.userTypeId === 1) {
      this.portalType = "customer";
    } else if (userProfile.userTypeId === 2) {
      this.portalType = "studio";
    }
  }

/**
   * directoryApiProd() - method to call the directory url.
  */
  directoryApiProd(selectTab: string): void {
    const url = 'https://client.wb.com/directory/';
    
    this.companyService.getHtmlContent(url).subscribe(
      (content) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
  
        const tabConfig = this.directoryConfig;
  
        const selectedTabConfig = tabConfig[selectTab];
        if (selectedTabConfig) {
          const headingElement = Array.from(doc.body.querySelectorAll('.elementor-toggle-title'))
            .find(title => title.textContent?.trim() === selectedTabConfig.heading);
  
          if (headingElement) {
            const toggleItem = headingElement.closest('.elementor-toggle-item');
            if (toggleItem) {
              const contentDiv = toggleItem.querySelector('.elementor-tab-content');
              if (contentDiv) {
                let contentHtml = contentDiv.innerHTML
                .replace(/<div>&nbsp;<\/div>/g, '') // Remove unwanted divs
                .replace(/<br\s*\/?>/g, ''); // Remove <br> tags
              
                contentHtml = contentHtml.replace(/Phone:\s*(\d{3}-\d{3}-\d{4}|\d{10})/g, (match, p1) => {
                  return `Phone: <a class="not-clickable pb-0" href="tel:${p1}">${p1}</a>`;
                });

                // Add text-link class to email anchor tags
                contentHtml = contentHtml.replace(/<a href="mailto:([^"]+)">([^<]+)<\/a>/g, (match, email, text) => {
                  return `<a href="mailto:${email}" class="text-link">${text}</a>`;
                });

                // Add text-link class to website anchor tags
                contentHtml = contentHtml.replace(/<a href="([^"]+)" target="_blank" rel="noopener">([^<]+)<\/a>/g, (match, url, text) => {
                  return `<a href="${url}" target="_blank" rel="noopener" class="text-link">${text}</a>`;
                });

              // Add mt-3 class to all <ul class="organized"> elements
              contentHtml = contentHtml.replace(
                /<ul class="organized">/g, 
                '<ul class="organized mt-3">'
              );
                const finalContentHtml = `
                <h2 class="elementor-heading-title elementor-size-default">${selectedTabConfig.headingFormat}</h2>
                ${contentHtml}
              `;
                this.directoryContent = this.sanitizer.bypassSecurityTrustHtml(finalContentHtml);
                console.log('Sanitized Map Content:', this.directoryContent);
              }
            } 
          } 
        } 
      },
      (error) => {
        console.error('Error fetching HTML content:', error);
      }
    );
  }
}