import { FormControl } from '@angular/forms';

export const CREDIT_APPLICATION = {
  creditApplication: 'Credit Applications',
  viewCredit: 'View All Credit Applications',
};

export const PROJECTS = {
  projects: 'Projects',
  filters: 'FILTERS',
  clearFilters: 'Clear Filters',
  noProjects: 'No Projects To Display'
}

export const PROJECT_FILTER_TYPES = {
  projects: 'projects',
  types: 'types',
  departments: 'departments',
  status: 'status',
  project: 'project',
  type: 'type',
  department: 'department'
}

export const MANAGE_USERS_TYPES = {
  users: 'users',
  status: 'status',
  jobTitles: 'jobTitles',
  user: 'user',
  jobTitle: 'jobTitle',
  portalType: 'customer',
  addModalType: 'add',
  editModalType: 'edit',
  componentType: 'manage'
}

export const MANAGE_USERS_CONSTANTS = {
  addUser: '+ Add a User',
  filters: 'FILTERS',
  clearFilters: 'Clear Filters',
  allProjects: 'All Projects',
  projectUsers: 'Project Users',
  addUserTitle: 'Add A User',
  EditUserTitle: 'Edit User'
}

export const PROJECT_DATES_DROPDOWN_LIST = {
  action: 'Action',
  otherAction: 'Another action',
  something: 'Something else here',
  separatedlink: 'Separated link',
  toggle: 'Toggle Dropdown',
};

export const PASSES_EXPECTED_TODAY = {
  passesExpectedToday: 'Today’s Passes',
  all: 'All',
  passes: 'Passes',
  driveOn: 'Drive On',
  walkOn: 'Walk On',
  nextHour: 'Next Hour',
  today: 'Today',
  checkedIn: 'Checked In',
  viewAllPasses: 'View 5 Day Passes',
  technicalSpec: 'TechnicalSpec',
  floorPlan: 'Floor Plan',
  refresh: 'Refresh',
};

export const REQUEST = {
  request: 'Requests',
  viewAllRequest: 'View All Requests',
};

export const MENU = {
  projects: 'Projects',
  request: 'Requests',
  company: 'Company',
  overview: 'Overview'

};

export const ERROR_MESSAGES = {
  projectUserErrorMessage: 'Error fetching project user',
  userDetailsErrorMessage: 'Error fetching user details',
}

export const DASHBOARD = {
  request: 'Request',
  user: 'User',
  credit: 'Credit Application',
  error:'error getting the project',
  Updatsuccessful: 'country id updated successfully',
  lotError: 'Error in updating the country id',
  inActive: 'This company is not associated with any projects. Please Contact your',
  administrator: 'Administrator',
  assistance: 'for assistance.'
  
};

export const ACTIVE_PROJECTS = {
  projectName: 'Project Id',
  viewProject: 'View Project',
  viewProjectAndBookmark: 'View Bookmarks & Project',
  dates: 'Dates',
  aStage: "A Stage",
  officeSuite: 'Office Suit',
  floorPlanSuit: 'Floor Plan Suit',
  groundFloorPlan: 'Ground Floor Plan',
  technicalSpec: 'Technical Spec',
  floorPlan: 'FloorPlan',
  ar: 'A/R'
};

export const PROJECT_DETAILS_SPACES = {
  mySpaces: 'My Spaces & Resources',
  spaces: 'Spaces & Resources',
  stages: 'STAGES',
  offices: 'OFFICES',
  workshops: 'WORKSHOP',
  maps: "Maps",
  viewMaps: 'View All Maps'

};

export const PROJECT_DETAILS_CONSTANTS = {
  allProjects: 'Projects',
  projectId: 'PROJECT ID # ',
  ar: 'A/R # ',
  dates: 'DATES : ',
  description: 'DESCRIPTION',
  editDescription: 'Edit Project Description',
  addUser: 'Add A User',
  projectDetailsErrorMessage: 'Error in fetching the project details',
  editDescriptionErrorMessage: 'Error in updating description',
  bookmarkErrorMessage: 'Error in upating bookmark status'
};

export const EDIT_DESCRIPTION_CONSTANTS = {
  editProject: 'Edit Project',
  description: 'Project Description',
  save: 'Save Changes',
  discard: 'Discard Changes',
  close: 'Close'
};

export const ADD_USER_CONSTANTS = {
  addUser: 'Add A User',
  editUser: 'Edit User',
  close: 'Close',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  jobTitle: 'Job Title',
  optionalSettings: 'Optional Settings',
  departmentAccess: 'Department Access',
  sectionAndPermissions: 'SECTION AND PERMISSIONs',
  access: 'ACCESS',
  projects: 'PROJECTS',
  serviceRequest: 'Service Request',
  creditApplication: 'CREDIT APPLICATION',
  manageUsers: 'MANAGE USERS'
};

export const PROJECT_USERS_COUNT = {
  projectUsers: 'Project Users',
  viewAll: 'View All',
  addUser: 'Add a user',
  users: 'USERS',
};

export const CREDIT_APPLICATION_CONSTANTS = {
  creditApplications: 'Credit Applications',
  startApplication: 'Start a Credit Application'
};

export const EMAIL_NOTIFICATION = {
  emailNotification: 'Email Notifications',
  crditApplication: 'Credit Applications',
  applicationAndStatus: 'Credit Applications & their statuses',
  company: 'Company',
  companyStatus: 'New users added to a project, company updates',
  save: 'Save',
};

export const PROFILE = {
  myProfile: 'My Profile',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  jobTitle: 'Job Title',
  save: 'Save',
  validatorRequiredFname: 'First Name is required.',
  validatorRequiredLname: 'last Name is required.',
  validatorRequiredEmail: 'email Name is required.',
  validatorRequiredPhone: 'phone Name is required.',
  validatorRequiredJobTitle: 'job Title is Required',
  spaceValidator: 'First Name should not contain numbers or spaces.',
  specialCharacterValidator: 'can only contain numbers, "()", "-", and "+".',
  maxLengthValidator: 'Phone number must be up to 15 characters.',
  lNameLengthValidator: 'Last name exceeded the length',
  FNameLengthValidator: 'first Name exceeded the length',
  jobTitleLengthValidator: 'Job title exceeds the length limit of 50 characters.',
  discardChanges: 'Discard Changes',
  yes: 'Yes',
  no: 'No',
  discardChangesMessage:'Are you sure you want to discard changes?',
  tooltip:"Please enter the country code. Phone number format example for US: +1 (000) 000-0000; UK: +44 0000 000 0000"

};
export const PHONE_PATTERN = /^[0-9()+\-]*$/;
export const NAME_PATTERN = /^[A-Za-z\s]*$/;




export const COMPANY = {
  companyid: 'Company ID #',
  projects: 'Projects',
  companyaddress: 'Production Company Address',
  billingaddress: 'Billing Address',
  contactinfo: 'Contact Information',
  companyuser: 'Company Users',
  creditstatus: 'Credit Status',
  projectuser: 'Add a User To',
  adduser: 'Add a User',
  viewall: 'View All',
  current: 'Current',
  editcompany: 'Edit Company',
  addcompany: 'Add a Company',
  portaltype: 'customer',
  modalEdit: 'edit',
  modalAdd: 'add',
  notavailable: 'Not Available',
  companyerror: 'Company does not exist',
  componenttype: 'company',
  success: 'success',
  validtill: 'Valid until',
};

export const COMPANY_USERS = {
  companyusers: 'Company User',
  user: 'User',
  filter: 'Filters',
  adduser: 'Add a User',
  edituser: 'Edit A Customer User',
  portaltype: 'customer',
  modalEdit: 'edit',
  modalAdd: 'add',
  company: 'Company',
  componenttype: 'company',
  success: 'success',
  userType: 'companyuser'
};

export const COMPANY_USERS_REQUEST = {
  system: 'system admin',
  account: 'account payable',
  company: 'company admin',
};

export const GETSTATUS_CLASS = {
  active: 'ACTIVE',
  deactivated: 'DEACTIVATED',
};

export const COMPANY_USERS_FILTER_TYPES = {
  activeprojects: 'activeprojects',
  status: 'status',
  user: 'user',
  jobtitle: 'jobtitle',
}
export const COMPANY_FILTERS = {
  projects: 'Projects',
  filters: 'FILTERS',
  clearFilters: 'Clear Filters'
}


export const PROJECT_USERS_LIST_CONSTANTS = {
  all: 'All',
  active: 'Active',
  deactivated: 'Deactivated',
  admin: 'Admin',
  companyAdmin: 'Company admin',
  activeStatus: 'ACTIVE',
  deactivatedStatus: 'DEACTIVATED',
  statusActive: 'Y',
  statusDeactive: 'N',
  allAccess: 'ALL'
}

export const ADD_PROJECT_USER_CONSTANTS = {
  manage: 'Can Manage',
  view: 'Can View',
  all: 'All'
}

export const DropdownSettings = {
  id: "id",
  textField: "text",
  limitSelection: -1,
  maxHeight: 197,
  itemsShowLimit: 999999999999,
  searchPlaceholderText: "Search",
  noData: "No data available",
  noFilteredData: "No filtered data available",
}

export const PROJECT_DROPDOWN_SETTINGS = {
  idField: "id",
  textField: "text",
  maxHeight: 250,
  noDataAvailablePlaceholderText: "No data available",
  noFilteredDataAvailablePlaceholderText: "No filtered data available",
  closeDropDownOnSelection: false,
  defaultOpen: false
}

export const SORTING_CONSTANTS = {
  sort: "Sort:",
  mostRecent: "Most Recent",
  aToz: "A - Z"
}


export const WEATHER_FORECAST_CONSTANTS = {
  rain: "Chance of Rain:",
  change: "Change",
  forecast: "10 Day Forecast",
  weatherUrl: "https://weather.com/weather/today/l"
}

export const DATEPICKER_CONSTANTS = {
  lastLogin: "Last Login",
  dates: "Dates"
}

export const onTheLot = {
  cafetria: 'Commissary Cafeteria',
  menu: '- Menu & Order Online',
  fine: 'Fine Dining',
  party: '- Dining & Private Party menus',
  grill: 'Avon Grill',
  orderOnline: '- Menu & Order Online',
  roadRunner: 'Road Runner Delivery',
  orderOnlineMenu: '- Menu & Order Online',
  aramark: 'Aramark',
  foodServices: '- Food Services',
  feature: 'Feature Medical',
  medical: '- Medical care',
  safety: '- Safety Services',
  mentalHealth: '- Mental Health & Wellbeing support',
  solas: 'Solas Mind',
  wonderWorks: 'The Wonderworks',
  childcare: '- Childcare',
  zone10: 'Zone 10',
  fitnessWellbeing: '- Fitness & Wellbeing',
  msl: 'MSL'
}
