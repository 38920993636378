<div class="container-fluid page-wrapper">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<h1 class="page-title px-0">FAQs</h1>
			</div>
            <div class="col-md-4 col-lg-4 d-none d-md-block ">
				<ol class="handbook-list" id="v-pills-tab" role="tablist" aria-orientation="vertical">
					<li
					  class="nav-link"
					  id="overview-tab"
					  role="tab"
					  aria-controls="overview-content"
					  aria-selected="true"
					  (click)="selectTab('overview-tab')"
					>
					  <span class="list-number">1.</span> Overview
					</li>
				  <li
				  class="nav-link"
				  id="account-tab"
				  role="tab"
				  aria-controls="account-content"
				  aria-selected="false"
				  (click)="selectTab('account-tab')"
				>
				  <span class="list-number">2.</span> Account
				</li>
				  <li
				  class="nav-link"
				  id="projects-tab"
				  role="tab"
				  aria-controls="projects-content"
				  aria-selected="false"
				  (click)="selectTab('projects-tab')"
				>
				  <span class="list-number">3.</span> Your Projects
				</li>

				  <li
				  class="nav-link"
				  id="requests-tab"
				  role="tab"
				  aria-controls="requests-content"
				  aria-selected="false"
				  (click)="selectTab('requests-tab')"
				>
				  <span class="list-number">4.</span> Requests
				</li>
                <li
                class="nav-link"
                id="users-tab"
                role="tab"
                aria-controls="users-content"
                aria-selected="false"
                (click)="selectTab('users-tab')"
              >
                <span class="list-number">5.</span> Users
              </li>
              <li
              class="nav-link"
              id="contact-us-tab"
              role="tab"
              aria-controls="contact-us-content"
              aria-selected="false"
              (click)="selectTab('contact-us-tab')"
            >
              <span class="list-number">6.</span> Contact Us
            </li>
            <li
            class="nav-link"
            id="client-resources-tab"
            role="tab"
            aria-controls="client-resources-content"
            aria-selected="false"
            (click)="selectTab('client-resources-tab')"
          >
            <span class="list-number">7.</span> Client Resources
          </li>
				</ol>
			</div>
            <div class="col-md-4 col-lg-4 col-sm-12 d-block d-md-none mobile-select-block">
				<select class="form-select" id="mobileSelect" [(ngModel)]="selectedTab" (change)="onSelectChange($event)">
					<option value="overview-tab">
						<span class="list-number">1.</span> Overview
					</option>
					<option value="account-tab">
						<span class="list-number">2.</span> Account
					</option>
					<option value="projects-tab">
						<span class="list-number">3.</span> Your Projects
					</option>
					<option value="requests-tab">
						<span class="list-number">4.</span> Requests
					</option>
				
                <option value="users-tab">
                    <span class="list-number">5.</span> Users
                </option>
            <option value="contact-us-tab">
                <span class="list-number">6.</span> Contact Us
            </option>
        <option value="client-resources-tab">
            <span class="list-number">7.</span> Client Resources
        </option>
    </select>
			</div>
            <div class="col-md-8 col-lg-8 col-sm-12">
				<!-- Tab Content -->
				<div class="tab-content" id="v-pills-tabContent">
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'overview-tab'}"
						id="overview-tab" role="tabpanel" aria-labelledby="overview-tab">
                        <h1 class="tab-content-title">Overview</h1>
                        <div class="page-details-section">
                            <h2 class="page-sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h2>
                            <p class="para-text-light studios-para">Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.<br>
                            </p>
					</div>
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h2>
                        <p class="para-text-light studios-para">Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.<br>
                        </p>
                </div>
                    </div>

					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'account-tab'}"
						id="account-tab" role="tabpanel" aria-labelledby="account-tab">
                        <h1 class="tab-content-title">Account</h1>
                        <div class="page-details-section">
                            <h2 class="page-sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h2>
                            <p class="para-text-light studios-para">Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.<br>
                            </p>
					</div>
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h2>
                        <p class="para-text-light studios-para">Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.<br>
                        </p>
                </div>
					</div>

					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'projects-tab'}"
						id="projects-tab" role="tabpanel" aria-labelledby="projects-tab">
                        <h1 class="tab-content-title">Your Projects</h1>
                        <div class="page-details-section">
                            <h2 class="page-sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h2>
                            <p class="para-text-light studios-para">Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.<br>
                            </p>
					</div>
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h2>
                        <p class="para-text-light studios-para">Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.<br>
                        </p>
                </div>
					</div>

					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'requests-tab'}"
						id="requests-tab" role="tabpanel" aria-labelledby="requests-tab">
                        <h1 class="tab-content-title">Requests</h1>
                        <div class="page-details-section">
                            <h2 class="page-sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h2>
                            <p class="para-text-light studios-para">Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.<br>
                            </p>
					</div>
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h2>
                        <p class="para-text-light studios-para">Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.<br>
                        </p>
                </div>
					</div>
                    
                    <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'users-tab'}"
						id="users-tab" role="tabpanel" aria-labelledby="users-tab">
                        <h1 class="tab-content-title">Users</h1>
                        <div class="page-details-section">
                            <h2 class="page-sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h2>
                            <p class="para-text-light studios-para">Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.<br>
                            </p>
					</div>
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h2>
                        <p class="para-text-light studios-para">Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.<br>
                        </p>
                </div>
					</div>

					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'contact-us-tab'}"
						id="contact-us-tab" role="tabpanel" aria-labelledby="contact-us-tab">
                        <h1 class="tab-content-title">Contact Us</h1>
                        <div class="page-details-section">
                            <h2 class="page-sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h2>
                            <p class="para-text-light studios-para">Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.<br>
                            </p>
					</div>
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h2>
                        <p class="para-text-light studios-para">Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.<br>
                        </p>
                </div>
					</div>
                    
                    <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'client-resources-tab'}"
						id="client-resources-tab" role="tabpanel" aria-labelledby="client-resources-tab">
                        <h1 class="tab-content-title">Client Resources</h1>
                        <div class="page-details-section">
                            <h2 class="page-sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h2>
                            <p class="para-text-light studios-para">Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.<br>
                            </p>
					</div>
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</h2>
                        <p class="para-text-light studios-para">Sed posuere consectetur est at lobortis. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.<br>
                        </p>
                </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
