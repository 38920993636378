<div class="page-wrapper">
  <div class="container px-0">
    <div class="row">
      <div class="comman-title-block">
        <div class="comman-title-section">
          <h1>Hi, Vignesh</h1>
        </div>
        <div class="comman-filter-block">
          <div class="comman-filter-title-section mb-0">
            <p>FILTER :</p>
          </div>
          <div class="dual-filter-section">
            <div
              class="comman-filter-section d-flex flex-wrap align-items-center gap-3"
            >
              <app-ng-multiselect-dropdown
                [placeholder]="'Companies'"
                [settings]="defaultSettings"
                [data]="status"
                (onSelect)="selectedStatus($event)"
              >
              </app-ng-multiselect-dropdown>
              <app-datepicker
                [placeholder]="'Projects'"
                (dateRangeSelected)="onDateRangeSelected($event)"
              ></app-datepicker>
              <app-ng-multiselect-dropdown
                [placeholder]="'Departments'"
                [settings]="defaultSettings"
                [type]="userTypeahead"
                [data]="jobtitle"
                [filterData]="jobTitleFilterData"
                [searchtextplaceholder]="'Type a job title'"
                (onSelect)="selectedJobtitle($event)"
              >
              </app-ng-multiselect-dropdown>
              <app-ng-multiselect-dropdown
                [placeholder]="'Status'"
                [settings]="defaultSettings"
                [data]="activeprojects"
                (onSelect)="selectedProject($event)"
              >
              </app-ng-multiselect-dropdown>
            </div>
            <div class="notification-coloum">
              <img
                src="assets/images/approved-email.svg"
                alt="approved-email"
              />
              <span class="notification-status"
                >Status letter/notification not sent</span
              >
              <div class="form-check form-switch mb-0">
                <input
                  class="form-check-input switch common-tog"
                  type="checkbox"
                  role="switch"
                  checked
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="common-grid-section">
        <div class="common-grid-block">
          <div class="mt-4">
            <app-common-ag-grid
              class="ag-theme-custom"
              [colDefs]="colDefs"
              [rowData]="rowData"
              [colsForSmallScreen]="colsForSmallScreen"
              [colsForMediumScreen]="colsForMediumScreen"
              [colsForLargeScreen]="colsForLargeScreen"
              [colsForExtraLargeScreen]="colsForExtraLargeScreen"
              [editType]="'fullRow'"
              [suppressClickEdit]="true"
              [animateRows]="true"
            >
            </app-common-ag-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
