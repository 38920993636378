import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/shared/services/base-api/base-api.service';
import { CUSTOMER_PORTAL_API_PATHS, GLOBAL_API_PATHS } from 'src/assets/constants/apiPaths';

@Injectable({
  providedIn: 'root'
})
export class OnTheLotService {

  constructor(private baseApiService: BaseApiService) { }

/**
 * getOnTheLotData() - to retrive widget data
 * @returns an observable for giving widget data
 */
  getOnTheLotData(){
    let endpoint = CUSTOMER_PORTAL_API_PATHS.onTheLot;
    return this.baseApiService.get(endpoint)

  }

  /**
   * updateOnTheLot() - used to update country id
   * @param data - the data to be passed to update
   * @returns an observable with a successfull message
   */
  updateOnTheLot(data: { onTheLotCountryId: number }, userId: number) {
    let endpoint = `${CUSTOMER_PORTAL_API_PATHS.updateOnTheLot}?userProfileId=${userId}`;
    return this.baseApiService.put(endpoint, data);
  }
  






}
