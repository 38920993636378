<!-- The Commented HTML Code for to view Multiple Projects in Dashboard -->
<div *ngIf="_projectList?.length == 1 && permissionsData['activeProject']" class="common-active-project-widget">
    <div *ngFor="let project of _projectList" class="card">
        <div class="card-body">
            <div class="active-project-title-block single-project-view">
                <div class="container px-0">
                    <div class="row mx-0">
                        <div class=" col-6 col-sm-6 col-md-4 col-lg-4 mx-0 px-0">
                            <div class="active-project-image-wrapper">
                                <div><img [src]="_projectList[0].imageUrl"  onerror="this.src='../../../../../../assets/images/project-image1.png'" alt="active-project-image"
                                        class="active-project-image img-fluid">
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 px-0">
                            <div class="active-project-title">{{project?.projectName}}</div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 px-0">
                            <div class="active-projectinfo-wrapper">
                                <div class="active-projects-details">
                                    <p>{{activeProjects.projectName}} #</p>
                                    <p>{{project?.projectId}}</p>
                                </div>
                                <div class="active-projects-details project-date-block">
                                    <p>{{activeProjects.dates}}:</p>
                                    <p>{{formatDate(project?.projectStartDate)}} - {{formatDate(project?.projectEndDate)}}</p>
                                </div>
                                <div class="active-projects-details project-ar-block">
                                    <p>{{activeProjects.ar}} #</p>
                                    <p>{{project?.projectId}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf = "permissionsData['activeProject']" class="stage-office-wrapper">
                <div *ngFor="let resource of resources" class="my-stage-block">
                    <div class="card-subtitle">

                        <div class="stage-label">
                            <div class="bookmark-icon-wrapper">
                                <span><img class="bookmark-icon" src="../../assets/images/active-bookmark.svg"
                                        alt="bookmark"></span>
                                <div class="card-subtitle-info">
                                    <!-- <-- {{activeProjects.aStage}}  -->
                                    {{ resource?.resourceType }} :
                                </div>

                            </div>
                        </div>
                        <div class=" stage-details">
                            <div class="card-subtitle-datalist">
                                <p *ngFor="let resourceValue of resource?.resourceValues" class="card-text">
                                    <span>{{resourceValue}}</span>
                                    <img class="pdf-icon" alt="pdf-icon"
                                        src="../../../../assets/images/pdf-icon-active.svg" />
                                </p>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div class="card-footer common-active-project-footer single-project-footer">
                <a class="card-link text-link"
                    (click)="navigateToProjectDetails(project?.projectId)">{{activeProjects.viewProject}}</a>
            </div>
        </div>
    </div>
</div>

<!-- multiple project -->
<div *ngIf="_projectList.length > 1 &&  permissionsData['activeProject']" class="common-active-project-widget">
    <div class="card card-scroll">
        <div class="card-body">
            <div class="active-project-title-block" *ngFor="let project of _projectList">
                <div class="container px-0">
                    <div class="row mx-0">
                        <div class=" col-6 col-sm-6 col-md-4 col-lg-4 mx-0 px-0">
                            <div class="active-project-image-wrapper">
                                <div><img [src]="project?.imageUrl"
                                        onerror="this.src='../../../../../../assets/images/project-image1.png'"
                                        alt="Project image" class="active-project-image img-fluid">
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 px-0">
                            <div class="active-project-title">{{project?.projectName}}</div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 px-0">
                            <div class="active-projectinfo-wrapper">
                                <div class="active-projects-details">
                                    <p>{{activeProjects.projectName}} #</p>
                                    <p>{{project?.projectId}}</p>
                                </div>
                                <div class="active-projects-details project-date-block">
                                    <p>{{activeProjects.dates}}:</p>
                                    <p>{{formatDate(project?.projectStartDate) }}-{{ formatDate(project.projectEndDate) }}</p>
                                </div>
                                <div class="active-projects-details project-ar-block">
                                    <p>{{activeProjects.ar}} #</p>
                                    <p>{{project?.projectId}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="custom-bookmark-text common-active-project-footer">
                            <a class="card-link text-link"
                                (click)="navigateToProjectDetails(project?.projectId)">{{activeProjects.viewProjectAndBookmark}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>