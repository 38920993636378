<ul class="navbar-nav ms-auto d-md-flex align-items-center">
  <li class="nav-item">
    <ng-container *ngIf="(isAuthenticated$ | async) === false;else signin">
      <button class="btn btn-primary common-btn-secondary" (click)="loginUser()">
        {{logininfo.signin}}
      </button>
    </ng-container>
  </li>
</ul>
<ul class="navbar-nav d-none d-md-flex">
  <ng-template #signin>
    <li class="nav-item dropdown">
      <a class="nav-link nav-client-resource user-name" *ngIf="name$ | async as name" id="profileDropdown" role="button"
        data-bs-toggle="dropdown" aria-expanded="false">
        <span class="user-name-logged">{{name}}</span><span class="span-image-wrapper"><img class="user-img"
            src="../assets/images/user-icon.svg" /></span>
        <span class="header_dropdown-multiselect__caret"></span>
      </a>
      <ul class="dropdown-menu my-profile client-res-dropdown" aria-labelledby="profileDropdown">
        <li><a class="dropdown-item" (click)="redirectToProfile()">{{logininfo.myprofile}}</a></li>
        <li><a class="dropdown-item" (click)="logout()">{{logininfo.logout}}</a></li>

      </ul>
    </li>
  </ng-template>
</ul>