import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ACTION_MSG,
  ADD_PROJECT_USER,
  ICONS_ACTION,
  ICONS_CASE,
  ICONS_RENDERED,
  PORTAL_TYPE,
  PROJECT_ERROR_MSG,
  SECTION_DATA,
  SECTION_DATA_VALUE,
  SAVE_CHANGES_WARNING
} from '../../constants/common';
import { FormValidationService } from '../../services/form-validations/form-validation.service';
import { ProjectUserService } from '../../services/project-user/project-user.service';
import { ADD_PROJECT_USER_CONSTANTS, MANAGE_USERS_CONSTANTS } from 'src/app/feature/customer-portal/constants/customer-portal.constant';
import * as _ from 'lodash';
import { ProjectsService } from 'src/app/feature/studio-portal/services/projects.service';
import { LoaderService } from '../../services/loader/loader.service';
import { ToastService } from '../toast.service';
import { IconsService } from '../../services/icons/icons.service';
import { STUDIO_PROJECT_USERS_LIST_CONSTANTS } from 'src/app/feature/studio-portal/constants/studio-portal.constant';

@Component({
  selector: 'app-add-project-user',
  templateUrl: './add-project-user.component.html',
  styleUrls: ['./add-project-user.component.scss'],
})
export class AddProjectUserComponent implements OnInit {
  @ViewChild('addUserErrorMessage', { static: false })
  addUserErrorMessage!: TemplateRef<any>;
  @ViewChild('addUserSuccessMessage', { static: false })
  addUserSuccessMessage!: TemplateRef<any>;
  @ViewChild('EditUserErrorMessage', { static: false })
  EditUserErrorMessage!: TemplateRef<any>;
  @ViewChild('EditUserSuccessMessage', { static: false })
  EditUserSuccessMessage!: TemplateRef<any>;
  @ViewChild('confirmationModal', { static: false }) confirmationModal!: TemplateRef<any>;
  @ViewChild('savechangeconfirmationModal', { static: false }) savechangeconfirmationModal!: TemplateRef<any>;
  @ViewChild('WarningMsgModal', { static: false }) WarningMsgModal!: TemplateRef<any>;

  @Input() public modalTitle: string;
  @Input() public portalType: string;
  @Input() public modalType: string;
  @Input() userDetails;
  @Input() public departmentDetails: [];
  @Input() public userRoleDetails: [];
  @Input() public projectId: any;
  @Input() public projectName: any;
  @Input() public componentType: any;
  @Input() public projectCompanyId: any;
  @Input() public companyName: any;
  @Input() public projectUserId: any;
  @Input() public isAccountsPayableUser: any;

  form: FormGroup;
  addProjectUser = ADD_PROJECT_USER;
  departmentData: any;
  sectionData: any;
  filteredSectionData: any;
  filteredProjectSectionData: any;
  departmentControls = [];
  setUserRoleId = [];
  permissionConstants = ADD_PROJECT_USER_CONSTANTS;
  companyId;
  userProfileId;
  setPermissionValue;
  userAddErrorMessage;

  @Input() public userEvent;
  userInfo = JSON.parse(localStorage.getItem('user-profile'));
  sectionVal: any = SECTION_DATA_VALUE;
  saveChanges = SAVE_CHANGES_WARNING;
  iconsRenderer = ICONS_RENDERED;
  iconCase = ICONS_CASE;
  iconActionMsg = ACTION_MSG;
  iconsAction = ICONS_ACTION;
  userLabel = STUDIO_PROJECT_USERS_LIST_CONSTANTS;
  iconInfo: string;
  setDepartmentId = [];
  setRollId = [];
  setRoleId = [];
  selectedDepartmentIds = [];
  selecteduserRoleIds = [];
  defaultPermissionValue = [];
  userStatus: any;
  fetchProjectUserDetails: any;
  isUserCompanyAdmin: boolean = false;
  permissionsData: any;
  iconModalTitle: string;
  isFormValid: boolean = false;
  initialFormValues: any;
  initialValues: any;
  isDropdownDisabled: boolean;
  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private formValidationService: FormValidationService,
    private projectUserService: ProjectUserService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private iconService: IconsService
  ) { }

  ngOnInit() {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.permissionsData = { ...userProfile.roleAssigned };
    this.companyId = userProfile.companyId;
    this.userProfileId = userProfile.userProfileId;
    this.projectName = this.projectName;
    this.departmentData = this.departmentDetails;
    this.sectionData = this.userRoleDetails;
    this.fetchProjectUserDetails = this.userDetails?.projectUser?.userProfile;
    this.userStatus = this.fetchProjectUserDetails?.statusActiveInd == 'N' ? this.userLabel.statusActive : this.userLabel.statusDeactive;

    this.sectionVal = this.sectionVal.find(
      (item) => item.portaltype === this.portalType
    );
    if (this.portalType == 'customer') {
      this.componentType != 'manage'
        ? this.initializeCustomerPortalForm()
        : this.initializeForm();
    }
    if (this.portalType == 'studio') {
      if (this.componentType == 'customer' || this.componentType == 'studio-company') {
        this.initializeCustomerPortalForm();
      } else if (this.componentType == 'addProjectUser') {
        this.initializeForm();
      } else {
        this.initializeForm();
      }
    }

    this.setupDepartmentValidation();

    if (this.userDetails) {
      if (this.portalType == 'customer' || this.portalType === 'studio') {
        if ((this.modalType == 'edit' && this.componentType == 'manage') || (this.componentType == 'addProjectUser' || this.componentType == 'updatProjectUser') || (this.componentType == 'company')) {
          this.form.get('makeUser')?.setValue(this.userDetails.projectUser?.userProfile?.companyAdminInd === 1);
          this.onMakeUserCheckboxChange({ target: { checked: this.form.get('makeUser')?.value } });
        }
      }
    }

    this.initialValues = JSON.parse(JSON.stringify(this.form?.getRawValue()));
    this.form.valueChanges.subscribe(() => {
      this.isFormValid = this.form?.valid;
      this.isInitialState();
    });

    this.disableDeaactivatedUser();
  }

  /**
   * disableDeaactivatedUser() - method to disable the form if the user is deactivated
   */
  disableDeaactivatedUser(): void {
    if (this.componentType === 'manage' && this.modalType === 'edit' && this.portalType === 'customer' || this.componentType === 'updatProjectUser' && this.modalType === 'edit' && this.portalType === 'studio') {
      if (this.fetchProjectUserDetails?.statusActiveInd === 'N') {
        this.isDropdownDisabled = true;
        this.form.disable();
        this.filteredSectionData.forEach(section => {
          this.form.get(`section${section.userRoleId}`).disable();
          this.form.get('permission').disable();
        });
      }
    }
  }

  /**
   * isInitialState() - Method to handle disable functionality on save button 
  */
  isInitialState() {
    return JSON.stringify(this.form?.getRawValue()) == JSON.stringify(this.initialValues)
  }

  /**
   * OpenWarningModal() - Method to close the modal on X 
   */
  OpenWarningModal(): void {
    if (this.form.dirty) {
      this.modalService.open(this.WarningMsgModal, {
        windowClass: 'common-modal-center',
        centered: true,
        backdrop: 'static'
      });
    }
    else {
      this.activeModal.close(true);
    }
  }


  /**
  * isFormChanged() - method to return the true or false for the value changes in form
  */

  isFormChanged(): boolean {
    //return this.companyForm.dirty;
    return JSON.stringify(this.form.getRawValue()) !== JSON.stringify(this.initialFormValues);
  }

  /**
   * initializeForm() - method to initializ the form with controls for different form fields
   */
  initializeForm(): void {
    const isEditMode = this.modalType === 'edit';

    this.form = this.fb.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          this.formValidationService.validateAlphabets,
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          this.formValidationService.validateAlphabets,
        ],
      ],
      email: [
        { value: '', disabled: isEditMode },
        [
          Validators.required,
          Validators.maxLength(80),
          this.formValidationService.validateEmail,
        ],
      ],
      jobTitle: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          this.formValidationService.validateJobTitle,
        ],
      ],
      contactNo: ['', [this.formValidationService.validateOptionalPhoneNo]],

      makeUser: [false],
      accountPayable: [false],
      allDepartment: [false],
      ...this.initializeDepartments(),
      ...this.initializeSectionsWithPermissions(),
      departmentsIds: [],
      userRoleIds: [],
    });

    this.form.get('makeUser').disable();
    this.form.get('accountPayable').disable();
    if (this.isAccountsPayableUser) {
      this.form.get('accountPayable')?.setValue(true);
      this.form.get('accountPayable').disable();
    }
    if (this.userDetails) {
      this.patchUserDetails();
    }
    this.initialFormValues = { ...this.form.getRawValue() };
  }

  /**
   * patchUserDetails() - method to get user details for editing existing user information.
   */
  patchUserDetails(): void {
    if (this.userDetails) {
      this.setUserRoleId = [];
      const projectUser = this.userDetails?.projectUser;
      const userProfile = projectUser?.userProfile;
      const departmentList = projectUser?.departmentList;
      const rolesAndSectionsList = projectUser?.rolesAndSectionsList;
      rolesAndSectionsList.length == 0 ? this.setPermissionValue = 'Can Manage' : null;

      this.form.patchValue({
        firstName: userProfile?.firstName || '',
        lastName: userProfile?.lastName || '',
        email: userProfile?.emailAddress || '',
        jobTitle: userProfile?.jobTitle || '',
        contactNo: userProfile?.contactNo || null,
        accountPayable: userProfile?.accountPayableUserInd,
        makeUser: userProfile?.companyAdminInd,
      });

      if (
        departmentList &&
        departmentList[0]?.departmentName === this.permissionConstants.all
      ) {
        this.selectedDepartmentIds = [];
        this.form.controls['allDepartment'].setValue(true);
        if (this.portalType == 'studio' || this.portalType == 'customer' && (this.componentType == 'company' && this.componentType !== 'manage')) {
          this.departmentData.forEach((dept) => {
            if (dept.departmentId == 1) {
              this.selectedDepartmentIds.push(dept);
            }
            this.form.controls[`department${dept.departmentId}`].setValue(true);
          });
        } else {
          this.departmentData.forEach((dept) => {
            this.form.controls[`department${dept.departmentId}`].setValue(true);
            this.selectedDepartmentIds.push(dept);
          });
        }
      } else {
        this.selectedDepartmentIds = [];
        departmentList?.forEach((dept) => {
          if (this.form.controls[`department${dept.departmentId}`]) {
            this.form.controls[`department${dept.departmentId}`].setValue(true);
            this.selectedDepartmentIds.push(dept);
          }
        });
      }
      this.form.get('departmentsIds')?.setValue(this.selectedDepartmentIds);
      rolesAndSectionsList?.forEach((roleSection) => {
        if (this.portalType == 'wbso' || this.portalType == 'customer' && (this.componentType !== 'company' && this.componentType !== 'manage')) {
          const section = this.sectionData.find(
            (sec) => sec.roleSection === roleSection.roleSection
          );
          if (section) {
            this.form.controls[`section${section.userRoleId}`].setValue(true);
            this.setUserRoleId.push(section);
          }
        }
        if ((this.portalType == 'studio' || this.portalType == 'customer') && (this.componentType == 'company' || this.modalType == 'edit' || this.componentType == 'updatProjectUser')) {
          const section = this.sectionData.find(
            (sec) => sec.userRoleId === roleSection.userRoleId
          );
          if (section) {
            this.form.controls[`section${section.userRoleId}`]?.setValue(true);
            if (section.role == this.sectionVal.dbvalue.projectView || section.role == this.sectionVal.dbvalue.projectViewMange) {
                this.defaultPermissionValue = [];
                section.role == this.sectionVal.dbvalue.projectView ? this.form.get('permission').setValue('Can View') : this.form.get('permission').setValue('Can Manage');
                this.setPermissionValue = section.role == this.sectionVal.dbvalue.projectViewMange ? 'Can Manage' : 'Can View';
              this.defaultPermissionValue.push(section);
              }
              else {
                this.setUserRoleId.push(section);
              }
          }
        }

      });
      this.form.get('userRoleIds')?.setValue(this.setUserRoleId);
    }
  }

  /**
   * initializeForm() - method to initializ the form with controls for different form fields
   */
  initializeCustomerPortalForm(): void {
    this.form = this.fb.group({
      ...this.initializeDepartments(),
      ...this.initializeSectionsWithPermissions(),
      departmentsIds: [],
      userRoleIds: [],
      allDepartment: [false],
    });
    if (this.userDetails) {
      this.patchUserDetails();

    }
    this.initialFormValues = { ...this.form.getRawValue() };
  }

  /**
   * initializeDepartments() - method to initializ the department controls for the form.
   * Creates a form control for each department and adds it to the form.
   * @returns an object with form controls for each department.
   */
  initializeDepartments(): {} {
    const departmentControls = {};
    this.departmentData.forEach((dept) => {
      const control = this.fb.control(false);
      departmentControls[`department${dept.departmentId}`] = control;
      this.departmentControls.push(control);
    });
    return departmentControls;
  }

  /**
   * setupDepartmentValidation() - method to set up validation to ensure that at least one department checkbox is selected.
   * Uses the FormValidationService to apply the custom validation.
   */
  setupDepartmentValidation(): void {
    this.form.setValidators(() =>
      this.formValidationService.validateAtLeastOneSelected(
        this.departmentControls
      )
    );
  }

  /**
   * initializeSectionsWithPermissions() - method to initializ the form controls for sections and permissions.
   * Each section is initialized with a 'Can View' permission by default.
   * @returns an object with form controls for each section and its permissions.
   */
  initializeSectionsWithPermissions(): {} {
    const sectionControls = {};
    this.filteredProjectSectionData = _.filter(this.sectionData, [
      'roleSection',
      'Project',
    ]);
    this.selecteduserRoleIds = _.filter(this.filteredProjectSectionData, [
      'role',
      this.sectionVal.dbvalue.projectView,
    ]).map((item) => item.userRoleId);
    this.defaultPermissionValue = _.filter(this.filteredProjectSectionData, [
      'role',
      this.sectionVal.dbvalue.projectView,
    ]);
    this.filteredProjectSectionData = this.filteredProjectSectionData.map(
      (item) => ({
        ...item,
        roleDisplay:
          item.role == this.sectionVal.dbvalue.projectView
            ? 'Can View'
            : item.role == this.sectionVal.dbvalue.projectViewMange
              ? 'Can Manage'
              : '',
        roleDisplayDesc:
          item.role == this.sectionVal.dbvalue.projectView
            ? 'The user can view project information & booked spaces and resources limited to their department.'
            : item.role == this.sectionVal.dbvalue.projectViewMange
              ? 'The user can view project information & booked spaces, resources and add create service requests limited to their departments.'
              : '',
      })
    );
    this.filteredSectionData = _.uniqBy(this.sectionData, 'roleSection');
    this.filteredSectionData.forEach((section) => {
      if (section.roleSection === 'Project') {
        sectionControls[`section${section.userRoleId}`] = [
          { value: true, disabled: true },
          Validators.requiredTrue,
        ];
      } else {
        sectionControls[`section${section.userRoleId}`] = [false];
      }
      sectionControls[`permission`] = 'Can View';
      sectionControls[`permissionId`] = this.selecteduserRoleIds;
    });
    return sectionControls;
  }

  /**
   * onSubmit() - method to submit the form if it is valid.
   * Logs the form value to the console.
   */
  onSubmit(): void {
    if (this.form.valid) {
      if (this.portalType == 'studio') {
        if (this.componentType == 'customer' || this.componentType == 'studio-company') {
          let payload = this.payloadData();
          this.activeModal.close(payload);
        } else if (this.componentType == 'addProjectUser') {
          let payload = this.addProjectUserFormattedData();
          this.addStudioPortalProjectUser(payload);
        } else if (this.componentType == 'updatProjectUser') {
          let payload = this.updateProjectUserFormattedData();
          this.updateStudioPortalProjectUser(payload);
        }
      }

      if (this.portalType == 'customer') {
        if (this.componentType == 'manage' && this.modalType == 'add') {
          let payload = this.addCustomerProjectUserFormattedData();
          this.addCustomerProjectUser(payload);
        }
        else if (this.componentType == 'manage' && this.modalType == 'edit') {
          let payload = this.addCustomerProjectUserFormattedData();
          this.editCustomerProjectUser(payload);
        }
        else if (this.componentType == 'company') {
          const payload = this.payloadData();
          this.activeModal.close(payload);
        }
      }
    } else {
      console.error('Form is invalid:', this.form.errors);
    }
  }

  addProjectUserFormattedData() { 
    const formValue = this.form.getRawValue(); 
    if (formValue.makeUser) {
      this.setDepartmentId = [];
      this.setRollId = [];
    } else {
      let departmentIds = this.departmentData
        .filter((dept) => this.form.get(`department${dept.departmentId}`).value)
        .map((dept) => dept.departmentId);
      departmentIds.includes(1) ? this.setDepartmentId = [1] : this.setDepartmentId = departmentIds;
      let roleIds = this.form.get(`userRoleIds`).value;
      let roleIdArr = [];
      if (roleIds) {
        roleIdArr = roleIds.map((role) => role.userRoleId);
        roleIdArr.push(formValue.permissionId);
      }
      else {
        roleIdArr.push(formValue.permissionId);
      }
      this.setRoleId = roleIdArr;
    }
    let payload = {
      projectUserProfile: {
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        emailAddress: formValue.email,
        jobTitle: formValue.jobTitle,
        companyId: this.projectCompanyId,
        companyName: this.companyName,
        contactNumber:formValue.contactNo || null,
        companyAdminInd: formValue.makeUser ? 1 : 0,
        accountPayableUserInd: formValue.accountPayable ? 1 : 0,
      },
      usersProjectAssignments: {
        projectId: this.projectId,
        projectName: this.projectName,
        departmentsIds: this.setDepartmentId,
        userRoleIds: this.setRoleId,
        status: this.addProjectUser.projectUserStatus,
      },
      loginUserId: 1,
    };
    return payload;
  }

  addStudioPortalProjectUser(payload) {
    this.loaderService.setLoadingState(true);
    this.projectUserService.addStudioProjectUser(payload).subscribe({
      next: (response: any) => {
        let message = { status: 'success', message: response.message };
        this.activeModal.close(message);
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        let errorMessage = { status: 'error', message: error };
        this.loaderService.setLoadingState(false);
        this.activeModal.close(error);
      },
    });
  }

  updateProjectUserFormattedData() {
    const formValue = this.form.getRawValue();
    if (formValue.makeUser) {
      this.setDepartmentId = [];
      this.setRollId = [];
    } else {
      let departmentIds = this.departmentData
        .filter((dept) => this.form.get(`department${dept.departmentId}`).value)
        .map((dept) => dept.departmentId);
      departmentIds.includes(1) ? this.setDepartmentId = [1] : this.setDepartmentId = departmentIds;
      let roleIds = this.form.get(`userRoleIds`).value;
      let roleIdArr = [];
      if (roleIds) {
        roleIdArr = roleIds.map((role) => role.userRoleId);
        roleIdArr.push(formValue.permissionId);
      }
      else {
        roleIdArr.push(formValue.permissionId);
      }
      this.setRoleId = roleIdArr;
    }
    let payload = {
      projectUserProfile: {
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        emailAddress: formValue.email,
        jobTitle: formValue.jobTitle,
        contactNumber:formValue.contactNo || null,
        companyId: this.projectCompanyId,
        companyName: this.companyName,
        
        companyAdminInd: formValue.makeUser ? 1 : 0,
        accountPayableUserInd: formValue.accountPayable ? 1 : 0,
      },
      usersProjectAssignments: {
        projectId: this.projectId,
        projectName: this.projectName,
        departmentsIds: this.setDepartmentId,
        userRoleIds: this.setRoleId,
      },
      loginUserId: this.userProfileId,
    };
    return payload;
  }

  updateStudioPortalProjectUser(payload) {
    this.loaderService.setLoadingState(true);
    this.projectUserService.updateStudioProjectUser(payload).subscribe({
      next: (response: any) => {
        let message = { status: 'success', message: response.message };
        this.activeModal.close(message);
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        let errorMessage = { status: 'error', message: error };
        this.loaderService.setLoadingState(false);
        this.activeModal.close(error);
      },
    });
  }

  addCustomerProjectUserFormattedData() {
    const formValue = this.form.getRawValue();
    if (formValue.makeUser) {
      this.setDepartmentId = [];
      this.setRoleId = [];
    } else {
      let departmentIds = this.departmentData
        .filter((dept) => this.form.get(`department${dept.departmentId}`).value)
        .map((dept) => dept.departmentId);
      departmentIds.includes(1) ? this.setDepartmentId = [1] : this.setDepartmentId = departmentIds;

      let roleIds = this.form.get(`userRoleIds`).value;
      let roleIdArr = [];
      if (roleIds) {
        roleIdArr = roleIds.map((role) => role.userRoleId);
        roleIdArr.push(formValue.permissionId);
      }
      else {
        roleIdArr.push(formValue.permissionId);
      }
      this.setRoleId = roleIdArr;
    }

    let payload = {
      projectUserProfile: {
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        emailAddress: formValue.email,
        jobTitle: formValue.jobTitle,
        contactNumber:formValue.contactNo || null,
        companyId: this.userInfo.companyId,
        companyName: this.companyName,
        companyAdminInd: formValue.makeUser ? 1 : 0,
        accountPayableUserInd: formValue.accountPayable ? 1 : 0,
      },
      usersProjectAssignments: {
        projectId: this.projectId,
        projectName: this.projectName,
        departmentsIds: this.setDepartmentId,
        userRoleIds: this.setRoleId,
        status: this.addProjectUser.projectUserStatus,
      },
      loginUserId: this.userProfileId,
    };
    return payload;
  }

  addCustomerProjectUser(payload: any) {
    if (
      !this.userDetails &&
      this.portalType == 'customer' &&
      this.componentType == 'manage' &&
      this.modalType == 'add'
    ) {
      this.loaderService.setLoadingState(true);
      this.projectUserService.addCustomerProjectUser(payload).subscribe({
        next: (response: any) => {
          let message = { status: 'success', message: response.message };
          this.activeModal.close(message);
          this.loaderService.setLoadingState(false);
          this.showSuccess(this.addUserSuccessMessage);
        },
        error: (error) => {
          let errorMessage = { status: 'error', message: error };
          this.userAddErrorMessage = errorMessage?.message;
          this.activeModal.close(errorMessage);
          this.loaderService.setLoadingState(false);
          this.showDanger(this.addUserErrorMessage);
        },
      });
    }
  }

  editCustomerProjectUser(payload) {
    if (this.userDetails && this.portalType == 'customer' &&
      this.componentType == 'manage' &&
      this.modalType == 'edit') {
      this.projectUserService.editCustomerProjectUser(payload).subscribe(
        (response: any) => {
          let message = { status: 'success', message: response.message };
          this.activeModal.close(message);
          this.loaderService.setLoadingState(false);
          this.showSuccess(this.EditUserSuccessMessage);
        },
        (error) => {
          let errorMessage = { status: 'error', message: error };
          this.activeModal.close(errorMessage);
          this.loaderService.setLoadingState(false);
          this.showDanger(this.EditUserErrorMessage);
        }
      )
    }
  }

  payloadData() {
    this.filteredSectionData.forEach((section) => {
      let value = this.form.get(`section${section.userRoleId}`).value;
      if (value) {
        if (section.roleSection == 'Project') {
          const section = this.sectionData.find(
            (item) => item.userRoleId === this.form.get(`permissionId`).value
          );
        } else {
          this.selecteduserRoleIds.push(section);
        }
      }
    });
    this.form.get('userRoleIds')?.setValue(this.selecteduserRoleIds);

    let data;
    if (
      this.portalType === 'customer' &&
      (this.modalType === 'add' || this.modalType === 'edit') &&
      this.componentType === 'manage'
    ) {
      return (data = {
        projectUserProfile: {
          firstName: this.form.get('firstName')?.value,
          lastName: this.form.get('lastName')?.value,
          emailAddress: this.form.get('email')?.value,
          jobTitle: this.form.get('jobTitle')?.value,
          companyId: this.companyId,
          companyName: this.companyName,
          companyAdminInd: this.form.get('makeUser')?.value ? 0 : 1,
          accountPayableUserInd: this.form.get('accountPayable')?.value ? 1 : 0,
        },
        usersProjectAssignments: {
          projectId: this.projectId,
          departmentsIds: this.form.get(`departmentsIds`)?.value,
          userRoleIds: this.form.get(`departmentsIds`)?.value,
          status: 'Y',
        },
        loginUserId: this.userProfileId,
      });
    } else {
      // Default payload structure for other cases
      return (data = [
        {
          projectId: this.projectId,
          departmentsIds: this.form.get(`departmentsIds`).value,
          userRoleIds: this.defaultPermissionValue.concat(this.setUserRoleId),
        },
      ]);
    }
  }

  /**
   * closeModal() - method to close the modal when called.
   */
  closeModal(): void {
    this.activeModal.close(true);
  }

  /**
 * closeAllModal() - close all the active modal
 */

  closeAllModal(modalRef: any) {
    modalRef.close();
    this.activeModal.close(true);
  }

  /**
   * selectPermission() -  method to update the permission for a specific section when a new permission is selected.
   * @param sectionId - The ID of the section whose permission is being changed.
   * @param permission - The new permission to be set.
   */
  selectPermission(sectionId: number, permission: string, section: any) {
    this.selecteduserRoleIds = [];
    this.defaultPermissionValue = [];
    this.form.get(`permission`)?.setValue(permission);
    this.form.get(`permissionId`)?.setValue(sectionId);
    this.selecteduserRoleIds.push(sectionId);
    this.defaultPermissionValue.push(section);
  
    // Mark the form as dirty
    this.form.markAsDirty();
  }
  onPermissionsChange(event, section) {
    const checkbox = event.target as HTMLInputElement;
    const userRoleId = section.userRoleId;
    if (checkbox.checked) {
      this.setUserRoleId.push(section);
      this.setUserRoleId = this.groupByUserRoleId(this.setUserRoleId);
      this.form.get('userRoleIds').setValue(this.setUserRoleId);
    } else {
      this.setUserRoleId = this.setUserRoleId.filter(
        (id) => id.userRoleId !== userRoleId
      );
      this.form.get('userRoleIds').setValue(this.setUserRoleId);
    }
  }

   /**
   * groupByUserRoleId() - Group the data by userRoleId.
  */
   groupByUserRoleId(rolesArray: any[]): any[] {
    const grouped: { [key: number]: any[] } = {};
    rolesArray.forEach((role) => {
      if (!grouped[role.userRoleId]) {
        grouped[role.userRoleId] = [];
      }
      grouped[role.userRoleId].push(role);
    });
    return Object.values(grouped).flat();
  } 
  /**
   * onDepartmentCheckboxChange() - method to handle the event when a department checkbox is checked or unchecked.
   */
  onDepartmentCheckboxChange(event, dept): void {
    const checkbox = event.target as HTMLInputElement;
    const departmentId = Number(checkbox.value);
    switch (checkbox.checked) {
      case true:
        {
          if (departmentId == 1) {
            this.selectedDepartmentIds = [];
            this.departmentData.forEach((dept) => {
              this.form
                .get(`department${dept.departmentId}`)
                .setValue(checkbox.checked);
            });
            this.selectedDepartmentIds.push(dept);
          } else {
            this.selectedDepartmentIds.push(dept);
            if (
              this.departmentData.length-1 == this.selectedDepartmentIds.length
            ) {
              this.selectedDepartmentIds = [];
              this.departmentData.forEach((dept) => {
                this.form
                  .get(`department${dept.departmentId}`)
                  .setValue(checkbox.checked);
                dept.departmentId == 1
                  ? this.selectedDepartmentIds.push(dept)
                  : '';
              });
            }
          }
        }
        break;
      case false:
        {
          if (departmentId == 1) {
            this.selectedDepartmentIds = [];
            this.departmentData.forEach((dept) => {
              this.form
                .get(`department${dept.departmentId}`)
                .setValue(checkbox.checked);
            });
          } else {
            if (this.selectedDepartmentIds.some(department => department.departmentId === 1)) {
              this.selectedDepartmentIds = this.departmentData;
              this.selectedDepartmentIds = this.selectedDepartmentIds.filter(
                (id) => id.departmentId !== departmentId && id.departmentId !== 1
              );
              this.departmentData.forEach((dept) => {
                dept.departmentId == 1 ? this.form.get(`department${dept.departmentId}`).setValue(checkbox.checked) : '';
              });
              this.form.get(`department1`).setValue(checkbox.checked);

            } else {
              this.selectedDepartmentIds = this.selectedDepartmentIds.filter(
                (id) => id.departmentId !== departmentId
              );
            }
          }
        }
        break;
    }
    this.form.get('departmentsIds')?.setValue(this.selectedDepartmentIds);
    const allChecked = this.departmentData.every(
      (dept) => this.form.get(`department${dept.departmentId}`)?.value
    );
    this.form.get('custom-checkbox-all')?.setValue(allChecked);
  }

  /**
   * onMakeUserCheckboxChange() - method to handle the change event of a user checkbox, updating the department and section data.
   * @param event - The checkbox change event that triggers the update.
   */
  onMakeUserCheckboxChange(event): void {
    if (((this.modalType == 'add' || this.modalType == 'edit') && this.componentType == 'manage') || (this.componentType == 'addProjectUser' || this.componentType == 'updatProjectUser')) {
      this.toggleDropdown(event);
    }
    if (this.portalType !== 'studio' || (this.portalType === 'studio' && (this.componentType == 'addProjectUser' || this.componentType == 'updatProjectUser'))) {
      const isChecked = event.target.checked;
      this.departmentData.forEach((dept) => {
        if (isChecked) {
          this.departmentData.forEach((dept) => {
            this.form.get(`department${dept.departmentId}`).setValue(true);
          });
          this.form.get(`department${dept.departmentId}`).disable();
        } else {
          this.form.get(`department${dept.departmentId}`).enable();
        }
      });

      this.filteredSectionData.forEach((section) => {
        if (isChecked) {
          this.form.get(`section${section.userRoleId}`).setValue(true);
          this.form.get(`section${section.userRoleId}`).disable();
          this.form.get('permission').setValue('Can Manage');
        } else {
          if ((this.componentType === 'updatProjectUser' || this.componentType == 'manage') || (this.componentType == 'company' && this.userDetails?.rolesAndSectionsList?.length > 1)) {
            this.form.get(`section${section.userRoleId}`).enable();
          }
          if ((this.componentType === 'updatProjectUser' || this.componentType == 'manage') || (this.componentType == 'company' && this.userDetails.rolesAndSectionsList.length > 1)) {
            this.form.get('permission')?.setValue(this.setPermissionValue);
          }
          else {
            this.form.get('permission').setValue('Can View');
          }
        }
      });

      if (!isChecked) {
        this.departmentData.forEach((dept) => {
          if (this.componentType === 'updatProjectUser' || this.modalType == 'edit') {
            this.form.get('department${dept.departmentId}')?.setValue(this.selectedDepartmentIds);
          }
          else {
            this.form.get(`department${dept.departmentId}`).setValue(false);
          }
        });
        this.filteredSectionData.forEach((section) => {
          if (section.roleSection === 'Project') {
            this.form.get(`section${section.userRoleId}`).setValue(true);
            this.form.get(`section${section.userRoleId}`).disable();
          }
          else {
            if (this.componentType === 'updatProjectUser' || this.modalType == 'edit') {
              this.form.get('section${section.userRoleId}')?.setValue(this.setUserRoleId);
            }
            else {
              this.form.get(`section${section.userRoleId}`).setValue(false);
            }
          }
        });
      }
    }
  }

  /**
   * toggleDropdown() - method to toggle the dropdown state between enable and disable
   * @param evt - checked or unchecked event.
   */
  toggleDropdown(evt: any) {
    const formValue = this.form.getRawValue();
    if (formValue.makeUser) {
      this.isUserCompanyAdmin = true;
    }
    else {
      this.isUserCompanyAdmin = false;
    }
  }

  /**
* restrictNonNumericInput() - method to retrict the alphabets and other symbols..
*/
  restrictNonNumericInput(event: KeyboardEvent): void {
    const allowedKeys = [
      'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Enter', 'Escape', 'SelectAll', ' '
    ];
    if (allowedKeys.includes(event.key)) {
      return;
    }
    if (/[0-9]/.test(event.key)) {
      return;
    }
    if (event.shiftKey && ['+', '-', '(', ')'].includes(event.key)) {
      return;
    }
    if (event.ctrlKey && ['a', 'c', 'v', 'x'].includes(event.key.toLowerCase())) {
      return;
    }
    const isAlphabet = /^[a-zA-Z]$/.test(event.key);
    if (isAlphabet) {
      event.preventDefault();
    }
    const isOtherSymbol = /[^0-9()+\-]/.test(event.key);
    if (isOtherSymbol) {
      event.preventDefault();
    }
  }

  /**
   * showSuccess() - Method to display a success toast notification.
   * @param successTpl - The template reference for the success message
   */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: any) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  openConfirmationModal(action: string): void {
    switch (action) {
      case this.iconCase.deActivate:
        this.iconModalTitle = this.iconActionMsg.deActivate;
        this.iconInfo = this.iconsAction.deActivate;
        break;
      case this.iconCase.activate:
        this.iconModalTitle = this.iconActionMsg.activate;
        this.iconInfo = this.iconsAction.activate;
        break;
      case this.iconCase.remove:
        this.iconModalTitle = this.iconActionMsg.remove;
        this.iconInfo = this.iconsAction.remove;
        break;
    }

    const modalRef = this.modalService.open(this.confirmationModal, {
      windowClass: 'common-modal-center',
      centered: true,
      backdrop: 'static'
    });
  }

  handleAction(): void {
    switch (this.iconInfo) {
      case this.iconsAction.deActivate:
        this.activateDeactivateUser();
        break;
      case this.iconsAction.activate:
        this.activateDeactivateUser()
        break;
      case this.iconsAction.remove:
        this.removeUser()
        break;
    }
  }

  activateDeactivateUser(): void {
    this.loaderService.setLoadingState(true);
    this.iconService.deactivateStudioProjectUser(this.projectUserId, this.userStatus, this.userInfo?.userProfileId).subscribe({
      next: (response: any) => {
        if (response) {
          let message = { status: 'success', message: response.message };
          this.loaderService.setLoadingState(false);
          this.activeModal.close(message);
          this.iconService.changeValue(null);
          this.modalService.dismissAll();
        }
      },
      error: (error) => {
        console.error('Error fetching user :', error);
        this.loaderService.setLoadingState(false);
        this.activeModal.close(error);
        this.iconService.changeValue(null);
        this.modalService.dismissAll();
      },
    });
  }

  removeUser(): void {
    // if (this.projectUserId === this.fetchProjectUserDetails?.userProfileId) {
    //   this.userAddErrorMessage = "User cannot be removed as they are associated with only one project.";
    //   this.showDanger(this.addUserErrorMessage);
    //   this.iconService.changeValue(null);
    //       this.modalService.dismissAll();
    //   return;
    // }
    this.loaderService.setLoadingState(true);
    this.iconService.removeStudioProjectUser(this.fetchProjectUserDetails?.userProfileId, this.projectId, this.userInfo?.userProfileId).subscribe({
      next: (response: any) => {
        if (response) {
          let message = { status: 'success', message: response.message };
          this.iconService.changeValue(null);
          this.loaderService.setLoadingState(false);
          this.activeModal.close(message);
          this.iconService.changeValue(null);
          this.modalService.dismissAll();
        }
      },
      error: (error) => {
        console.error('Error fetching user :', error);
        this.loaderService.setLoadingState(false);
        this.activeModal.close(error);
        this.iconService.changeValue(null);
        this.iconService.changeValue(null);
        this.modalService.dismissAll();
      },
    });
  }

}
