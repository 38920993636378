<button data-bs-toggle="modal" data-bs-target="#reviewStep4">Review Summary</button>
<!-- common modal section-->
<div class="modal fade" id="reviewStep4" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="false">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
          <h5 class="modal-title" id="commonModalLabel">Review</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
              class="cm-close-text">Close</span></button>
        </div>
        <div class="container px-0">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
              <div class="modal-left-section me-4">
                <h4 class="common-left-section-title mt-4">Company Info</h4>
                <div class="company-meta-data">
                  <p class="title mb-0"><strong class="common-title">PROJECT:</strong><span class="common-content">The new show</span></p>
                  <p class="title mb-0 pb-2"><strong class="common-title">Comapny:</strong></p>
                  <p class="common-content pb-2 pt-0 mb-0">A Brand New Production Company</p>
                  <p class="title mb-0"><strong class="common-title">TYPE:</strong><span class="common-content">Major Studio</span></p>
                  <p class="title mb-0"><strong class="common-title">APPLICATION DATE:</strong><span class="common-content">1/1/24</span></p>
                  <div class="d-flex flex-column mt-2">
                    <span class=" mb-1"><a class="text-link"><img src="../assets/images/link-icon.svg" alt=""
                          class="me-2">View Projects & Quotes</a></span>
                    <span class=" mb-1"><a class="text-link"><img src="../assets/images/link-icon.svg" alt=""
                          class="me-2">View Company</a></span>
                  </div>
                </div>
                <hr>
                <h4 class="common-left-section-title">Review</h4>
                <div class="mb-4 mt-3">
                  <div class="list-group list-group-flush mb-2">
                    <ul class="step-list">
                      <li>
                        <span class="step-icon step-check"></span>
                        <span class="step-text"> Application Form</span>
                      </li>
                      <li>
                        <span class="step-icon step-check "></span>
                        <span class="step-text"> Certification of Insurance</span>
                      </li>
                      <li>
                        <span class="step-icon step-check"></span>
                        <span class="step-text">Terms & Conditions</span>
                      </li>
                      <li>
                        <span class="step-icon step-check"></span>
                        <span class="step-text">D&B Report</span>
                      </li>
                      <li>
                        <span class="step-icon step-check-grey"></span>
                        <span class="step-text current-step">Summary</span>
                      </li>
                    </ul>
                  </div>
                  <span class=""><a class="text-link"><img src="../assets/images/pdf-blue-icon.svg" class="me-2"
                        alt="">Download Packet</a></span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="container cm-main-container px-0 mx-0">

                <div class="common-modal-input-section grey-bg review-panel summary-step">
                  <h2 class="section-main-header">Summary</h2>
                  <div class="row summary-details">
                    <div class="col-md-7">
                      <h5 class="summary-heading">Line of credit</h5>
                    </div>
                    <div class="col-md-5 text-end"><span class="reviewed-status">REVIEWED</span> </div>
                  </div>

                  <div class="row summary-details">
                    <div class="col-md-7">
                      <h5 class="summary-heading">Line of Credit/Guarantee</h5>
                    </div>
                    <div class="col-md-5 text-end"><span class="reviewed-status">REVIEWED</span> </div>
                  </div>

                  <div class="row summary-details">
                    <div class="col-md-7">
                      <h5 class="summary-heading">Company</h5>
                    </div>
                    <div class="col-md-5 text-end"><span class="reviewed-status">REVIEWED</span> </div>
                  </div>

                  <div class="row summary-details">
                    <div class="col-md-7">
                      <h5 class="summary-heading">Accounts Payable Contact</h5>
                    </div>
                    <div class="col-md-5 text-end"><span class="reviewed-status">REVIEWED</span> </div>
                  </div>

                  <div class="row summary-details">
                    <div class="col-md-7">
                      <h5 class="summary-heading">Company Profile</h5>
                    </div>
                    <div class="col-md-5 text-end"><span class="reviewed-status">REVIEWED</span> </div>
                  </div>

                  <div class="row summary-details">
                    <div class="col-md-7">
                      <h5 class="summary-heading">Principal Officers</h5>
                    </div>
                    <div class="col-md-5 text-end"><span class="reviewed-status">REVIEWED</span> </div>
                  </div>

                  <div class="row summary-details">
                    <div class="col-md-7">
                      <h5 class="summary-heading">Banking Reference</h5>
                    </div>
                    <div class="col-md-5 text-end"><span class="reviewed-status">REVIEWED</span> </div>
                  </div>

                  <div class="row summary-details">
                    <div class="col-md-7">
                      <h5 class="summary-heading">Trade References</h5>
                    </div>
                    <div class="col-md-5 text-end"><span class="reviewed-status">REVIEWED</span> </div>
                  </div>
                  <hr class="my-5"> <!--divider-->
                  <div class="row summary-details">
                    <div class="col-md-7">
                      <h5 class="summary-heading">Certificate of Insurance</h5>
                    </div>
                    <div class="col-md-5 text-end"><span class="reviewed-status">REVIEWED</span> </div>
                  </div>
                  <hr class="my-5"> <!--divider-->
                  <div class="row summary-details">
                    <div class="col-md-7">
                      <h5 class="summary-heading">Terms & Conditions</h5>
                    </div>
                    <div class="col-md-5 text-end"><span class="reviewed-status">REVIEWED</span> </div>
                  </div>
                  <hr class="my-5"> <!--divider-->
                  <div class="row summary-details">
                    <div class="col-md-7">
                      <h5 class="summary-heading">D&B Report</h5>
                    </div>
                    <div class="col-md-5 text-end"><span class="reviewed-status">REVIEWED</span> </div>
                  </div>
                </div>
                <div class="grey-box">
                  <h2 class="common-heading mb-4">Summary Evalution</h2>
                  <div class="refrence-section">
                    <h5 class="common-reference mb-3">Bank Reference:</h5>
                    <div class="custom-floating-label ms-4 mb-4">
                      <label for="text1" class="form-label">Bank Reference</label>
                      <textarea name="" id="" class="form-control form-control-lg common-text-area">N/A</textarea>
                    </div>
                    <h5 class="common-reference mb-3">Trade Reference:</h5>
                    <div class="custom-floating-label ms-4 mb-4">
                      <label for="text1" class="form-label">Trade Reference</label>
                      <textarea name="" id=""
                        class="form-control form-control-lg common-text-area">2 Trade responded <br>Highest amount billed is $26,000.00</textarea>
                    </div>
                    <h5 class="common-reference">D&B</h5>
                    <div class="db-section ms-4">
                      <p class="left-section m-0">- Deliquency Score: <strong class="right-section">88</strong></p>
                      <p class="left-section m-0">- Failure Score: <strong class="right-section">67</strong></p>
                      <p class="left-sectionm-0">- PAYDEX® Score: <strong class="right-section">77</strong></p>
                    </div>
                  </div>

                </div>
                <div class="row next-back-btns-section">
                  <div class="col-8"><a href="#" class="back-link text-link">
                      < back</a>
                  </div>
                  <div class="col-4 text-end">
                  
                    <div class="btn-common-dropdown w-100">
                      <div ngbDropdown class="d-inline-block">
                      <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                      <img *ngIf="selectedAction.img" [src]="selectedAction.img" alt="" style="margin-right: 5px;">
                            {{ selectedAction.text }}
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <button *ngFor="let action of actions" ngbDropdownItem (click)="updateButtonText(action)">
                      <img [src]="action.img" alt=""> {{ action.text }}
                      </button>
                      </div>
                      </div>
                      </div>
                     
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!---Added modals on click of send revision-->
<ng-template #company let-modal>
  <div class="modal-body">
    <h2 class="section-main-header">Send Revisions to Customer (1)</h2>
    <p>COMPANY: A Brand New Company</p>
    <p>PROJECT: The new show</p>
    <div class="">
      <h5>Text Box Header:</h5>
      <p>Body copy</p>
    </div>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="btn common-btn-secondary" (click)="modal.close('Close click')">Cancel</button>
      <button type="button" class="btn common-btn-secondary">Send Revision</button>
    </div>
  </div>
</ng-template>