import { Component, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-approve-cred-app-withrisk',
  templateUrl: './approve-cred-app-withrisk.component.html',
  styleUrls: ['./approve-cred-app-withrisk.component.scss']
})
export class ApproveCredAppWithriskComponent {
  constructor(private modalService:NgbModal){}
  openDeleteConfirmationModal(content: TemplateRef<any>): void {
    this.modalService.open(content, { size: 'lg' , centered: true });
  }
  openDeleteConfirmationModal1(content1: TemplateRef<any>): void {
    this.modalService.open(content1, { size: 'lg' , centered: true });
  }
}
