<div class="comman-panel-block panel panel-default panel-request-block">
    <div class="panel-heading">
        <h2>{{Requests.request}}</h2>
    </div>
    <div class="panel-body ">
        <div class="container panel-request-block-body">
            <ag-grid-angular #requestGrid class="ag-theme-custom no-header" [gridOptions]="gridOptions"
                [columnDefs]="colDefsRequest" [rowData]="rowData" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
    <div class="panel-footer panel-footer-sm">
        <div class="panel-footer-content ">
            <a href="#" class="text-link"> {{Requests.viewAllRequest}}</a>
        </div>
    </div>
</div>