import { Component } from '@angular/core';

@Component({
  selector: 'app-final-cred-form',
  templateUrl: './final-cred-form.component.html',
  styleUrls: ['./final-cred-form.component.scss']
})
export class FinalCredFormComponent {

}
