import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-review-step-four',
  templateUrl: './credit-review-step-four.component.html',
  styleUrls: ['./credit-review-step-four.component.scss']
})
export class CreditReviewStepFourComponent {

}
