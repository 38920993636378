import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './components/dashboard/dashboard.component';
// import { MenuComponent } from '../../core/components/menu/menu.component';
import { RequestsComponent } from './components/dashboard/requests/requests.component';
import { ActiveProjectsComponent } from './components/dashboard/active-projects/active-projects.component'
import { WeatherForecastComponent } from './components/dashboard/weather-forecast/weather-forecast.component';
import { PassExpectedTodayComponent } from './components/dashboard/pass-expected-today/pass-expected-today.component';
import { CreditApplicationsComponent } from './components/dashboard/credit-applications/credit-applications.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectDetailsComponent } from './components/projects/project-details/project-details.component';
import { CustomerPortalComponent } from './customer-portal.component';
import { CustomerPortalRoutingModule } from './customer-portal-routing.module';
import { AgGridModule } from 'ag-grid-angular';
import { CompanyComponent } from './components/company/company.component';
import { NgMultiSelectDropDown } from '../../shared/components/ng-multiselect-dropdown/ng-multiselect-dropdown.module';
import { UserComponent } from '../../shared/components/user/user.component';
import { ProfileComponent } from '../../shared/components/user/profile/profile.component';
import { SettingsComponent } from '../../shared/components/user/settings/settings.component';
import { EmailNotificationsComponent } from '../../shared/components/user/email-notifications/email-notifications.component';
import { CompanyUserComponent } from './components/company/company-user/company-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageAllUsersComponent } from './components/projects/manage-all-users/manage-all-users.component';
import { AddCompanyUserComponent } from '../../shared/components/add-company-user/add-company-user.component';
import { AddProjectUserComponent } from '../../shared/components/add-project-user/add-project-user.component';
import { AddEditCompanyComponent } from '../../shared/components/add-edit-company/add-edit-company.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { OnTheLotComponent } from './components/dashboard/on-the-lot/on-the-lot.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    DashboardComponent,
    RequestsComponent,
    ActiveProjectsComponent,
    WeatherForecastComponent,
    PassExpectedTodayComponent,
    CreditApplicationsComponent,
    ProjectsComponent,
    ProjectDetailsComponent,
    CustomerPortalComponent,
    CompanyComponent,
    UserComponent,
    ProfileComponent,
    SettingsComponent,
    EmailNotificationsComponent,
    ManageAllUsersComponent,
    CompanyUserComponent,
    AddCompanyUserComponent,
    AddProjectUserComponent,
    AddEditCompanyComponent,
    OnTheLotComponent
  ],
  imports: [
    CommonModule,
    CustomerPortalRoutingModule,
    AgGridModule,
    NgMultiSelectDropDown,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    CoreModule,
    NgbDropdownModule,
    NgbTooltipModule
  ],

  exports: [
    DashboardComponent,
    RequestsComponent,
    ActiveProjectsComponent,
    PassExpectedTodayComponent,
    CreditApplicationsComponent,
  ],
})
export class CustomerPortalModule { }

