import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-salesforcepath-form',
  templateUrl: './salesforcepath-form.component.html',
  styleUrls: ['./salesforcepath-form.component.scss']
})
export class SalesforcePathFormComponent {

  sanitizedUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
    const salesforceUrl = 'https://d5j000008xeaseai-dev-ed.my.salesforce-sites.com/GreenLight';
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(salesforceUrl);
  }

}
