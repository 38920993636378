import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { maps, mapTabConfig } from 'src/app/core/constants/core.constant';
import { NUMBER } from 'src/app/core/enum/core.enum';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { CompanyService } from 'src/app/feature/studio-portal/services/company.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent {
  constants = maps;
  number = NUMBER;
  portalType: string;
  selectedTab: string = this.constants.main_lot_tab;
  isAuthenticated: boolean = false;
  mapContent: any;
  mapConfig = mapTabConfig;
  isChangeLocationHide = false;



  constructor(private authService: AuthService,private companyService: CompanyService,private sanitizer: DomSanitizer,private loaderService:LoaderService) {}

  /**
    * selectTab() - method to update the currently selected tab.
    * @param tab - The identifier for the tab to be selected. This is typically a string that corresponds to one of the available tabs.
   */
  selectTab(tab: string) {
    window.scrollTo(0,0);
    this.selectedTab = tab;

    this.mapApiProd(this.selectedTab);
  }
  /**
   * onSelectChange() - method to render different tabs after clicking on the button 
   * @param event - The change event object associated with the select element.
  */
  onSelectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedTab = selectElement.value;
    this.mapApiProd(this.selectedTab);
  }


  ngOnInit(): void {
    this.checkAuthentication();
    this.mapApiProd(this.selectedTab);
  }

  private async checkAuthentication(): Promise<void> {
    this.isAuthenticated = await this.authService.isAuthenticated();
    if (this.isAuthenticated) {
      const userProfileData = localStorage.getItem('user-profile');
      if (userProfileData) {
        const userProfile = JSON.parse(userProfileData);
        this.setPortalType(userProfile);
      }
    }
  }

  private setPortalType(userProfile: any): void {
    if (userProfile.userTypeId === 1) {
      this.portalType = "customer";
    } else if (userProfile.userTypeId === 2) {
      this.portalType = "studio";
    }
  }
  
  /**
   * mapApiProd() - method to call map url
   * * @param selectTab - The identifier for the tab to be selected. This is typically a string that corresponds to one of the available tabs.
  */
 mapApiProd(selectTab: string): void {
    const url = 'https://client.wb.com/maps/';

    this.companyService.getHtmlContent(url).subscribe(
      (content) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');

        const tabConfig = this.mapConfig;
  
        const selectedTabConfig = tabConfig[selectTab];
        let contentHtml = '';
  
        if (selectedTabConfig) {
          const headingElement = Array.from(doc.body.querySelectorAll('h2'))
            .find(h2 => h2.textContent?.trim() === selectedTabConfig.heading);
  
          if (headingElement) {
            const iconListDiv = Array.from(doc.body.querySelectorAll('.elementor-icon-list-items'))
              .find(list => {
                const linkText = list.querySelector('span.elementor-icon-list-text');
                return linkText && linkText.textContent?.trim() === selectedTabConfig.linkText;
              });
  
            if (iconListDiv) {
              // Modify the span elements to include the 'text-link' class
              const links = iconListDiv.querySelectorAll('span.elementor-icon-list-text');
              links.forEach(link => {
                link.classList.add('text-link-maps'); // Add the text-link class
              });
              contentHtml += iconListDiv.outerHTML; 
            } 
          } 
        } 
  
        const camelCaseHeading = selectedTabConfig.heading
          .toLowerCase() 
          .replace(/\b\w/g, (match) => match.toUpperCase()); 
          const finalContentHtml = `<h2 class="elementor-heading-title elementor-size-default pb-0">${selectedTabConfig.headingFormat}</h2>${contentHtml}`;
  
        this.mapContent = this.sanitizer.bypassSecurityTrustHtml(finalContentHtml);
      },
      (error) => {
        console.error('Error fetching HTML content:', error);
      }
    );
}
}
