import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyUserComponent } from './components/company/company-user/company-user.component';
import { CompanyComponent } from './components/company/company.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { CustomerPortalComponent } from './customer-portal.component';
import { ProjectDetailsComponent } from './components/projects/project-details/project-details.component';
import { ManageAllUsersComponent } from './components/projects/manage-all-users/manage-all-users.component';
import { UserComponent } from '../../shared/components/user/user.component';
import { UserProjectComponent } from '../components/user-project/user-project.component';
import { CredAppFormOneComponent } from './components/projects/project-details/cred-app-step-one/cred-app-step-one.component';
import { CertificateOfInsuranceComponent } from './components/projects/project-details/certificate-of-insurance/certificate-of-insurance.component';
import { SalesforcePathFormComponent } from './components/customer-request/salesforce-form/salesforcepath-form.component';
import { CustomerRequestComponent } from './components/customer-request/customer-request.component';


const routes: Routes = [
  {
    path: '', component: CustomerPortalComponent,
    children: [
      { path: 'overview', component: DashboardComponent },
      { path: 'projects-list', component: ProjectsComponent },
      { path: 'requests', component: CustomerRequestComponent },
      { path: 'salesforce-path', component: SalesforcePathFormComponent },

      { path: 'project-details/:projectId', component: ProjectDetailsComponent },
      { path: 'cred1', component: CredAppFormOneComponent },
      { path: 'step2', component: CertificateOfInsuranceComponent },
      {
        path: 'company',
        children: [
          {
            path: '',
            component: CompanyComponent
          },
          {
            path: 'companyuser',
            component: CompanyUserComponent
          },
        ]
      },
      { path: 'user-dropdown', component: UserComponent },
      { path: 'manage-users', component: ManageAllUsersComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerPortalRoutingModule { }
