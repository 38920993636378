<!-- <button data-bs-toggle="modal" data-bs-target="#addUserCustPortal">
    Add A New Project
  </button> -->
<!-- common modal section -->
<!-- <div class="modal fade" id="addUserCustPortal" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true"> -->
<div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
        <div class="modal-body justify-content-center align-items-center">
            <div class="cm-header-section">
                <h5 class="modal-title mb-3" id="commonModalLabel">{{passes.wbdPass}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeModal()">
                    <span class="cm-close-text">{{passes.close}}</span>
                </button>
            </div>

            <div class="sub-heading-block">
                <span class="first-sub-heading">{{passes.createPass}}</span>
                <span class="second-sub-heading">
                    <a href="http://pass.wbd.com" target="_blank">{{passes.link}} </a>
                    <img src="assets/images/link-icon.svg" alt="link-icon" />
                </span>
            </div>

            <div class="project-resource-wrapper wrapper-right col-12 col-sm-12 col-md-12">
                <div class="tabs-rhs">
                    <div class="comman-tabs-block ml-1 d-none d-md-block">
                        <ul class="nav" role="tablist">
                            <li *ngFor="let day of nextDays; let i = index" class="nav-item">
                                <a class="tab-font nav-link" [class.active]="i ===0" (click)="selectTab(day)"
                                    data-bs-toggle="tab" [href]="'#' + day.day + '-tab'" role="tab"
                                    [attr.aria-selected]="selectedTab === (day.day + '-tab')">
                                    <span class="day">{{ day.isToday ? 'Today' : day.day }}</span>
                                    <span class="date">{{ day.isToday ? todayDate : day.date }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="export-refrest-rhs">
                        <span class="export clickable" (click)="exportToExcel()">{{passes.export}} <img
                                src="assets/images/export.svg" alt="upload-icon" /></span>
                        <span (click)="getPasses()" class="refresh clickable">{{passes.refresh}}
                            <img src="assets/images/refresh.svg" alt="refresh-icon" /></span>
                    </div> -->
                </div>
                <div class="check-in-block">
                    <div class="passes-block">
                        <div class="all-passes">
                            <span>{{passes.allPasses}}</span>
                            <span class="checked-status">{{allPasses}}</span>
                        </div>
                        <div class="mobile-walk-drive">
                            <div class="drive-on">
                                <img src="assets/images/car.svg" alt="drive-on" />
                                <span (click)="onDriveOnClick()">{{driveOn}} {{passes.driveOn}}</span>
                            </div>
                            <div class="walk-on">
                                <img src="assets/images/man.svg" alt="walk-on" />
                                <span (click)="onWalkOnClick()">{{walkOn}} {{passes.walkOn}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="checked-in-status-block">
                        <span class="checked-status">{{passes.checkedIn}}</span>
                        <div class="form-check form-switch mb-0">
                            <input class="form-check-input switch common-tog" type="checkbox" role="switch"
                                [checked]="showCheckedInOnly" (change)="toggleCheckedInStatus($event)" />
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="tab-content">
                    <div class="tab-pane fade show active" id="today-tab" role="tabpanel">
                        <div class="common-grid-section">
                            <div class="common-grid-block">
                                <div class="mt-3">
                                    <app-common-ag-grid class="ag-theme-custom" [colDefs]="colDefs" [rowData]="rowData"
                                        [colsForSmallScreen]="colsForSmallScreen"
                                        [colsForMediumScreen]="colsForMediumScreen"
                                        [colsForLargeScreen]="colsForLargeScreen"
                                        [colsForExtraLargeScreen]="colsForExtraLargeScreen" [editType]="'fullRow'"
                                        [suppressClickEdit]="true" [animateRows]="true">
                                    </app-common-ag-grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>