import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/shared/services/base-api/base-api.service';
import { STUDIO_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';

@Injectable({
  providedIn: 'root'
})
export class UsersServiceService {

  constructor(public baseApiService: BaseApiService) { }

  getWbsoMetaDeta(){
    let endpoint = STUDIO_PORTAL_API_PATHS.search_wbso_user
    return this.baseApiService.get(endpoint)
  }

  getCustomerMetaDeta(){
    let endpoint = STUDIO_PORTAL_API_PATHS.search_customer_user
    return this.baseApiService.get(endpoint)

  }


  searchWbsoUser(userId) {
    let endpoint = STUDIO_PORTAL_API_PATHS.search_wbso_user_id + '?' + 'userProfileId=' + userId;
    return this.baseApiService.get(endpoint)
  }

  searchCustomerUser(userId) {
    let endpoint = STUDIO_PORTAL_API_PATHS.search_customer_user_id + '?' + 'selectedUserId=' + userId;
    return this.baseApiService.get(endpoint)
  }

}
