<app-menu [portalType]="portalType"></app-menu>
<div class="container-fluid page-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="page-title px-0 mb-0">{{ constants.handbook }}</h1>
                <div class="d-flex align-items-center view-text">
                    <div>
                        <p class="me-3 mb-0 change-weather-title-block">You are viewing the Handbook for <span
                                class="change-weather-title">{{ onTheLotCountryName }}</span> <span class="change-weather-toggle" *ngIf="isChangeLocationHide"> <span>Change</span> <i
                                    class="bi bi-geo-alt-fill"></i>
                            </span></p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 d-none d-md-block">
                <ol class="handbook-list" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <li class="nav-link" id="office-production-tab" role="tab" (click)="selectTab('office-production-tab')">
                        <span class="list-number">{{ number.one }}{{ constants.dot }}</span> {{ constants.office_and_production_services }}
                    </li>
                    <div class="collapse" [ngClass]="{ 'show': isFirstCollapsed }" 
                         [attr.aria-expanded]="isFirstCollapsed ? 'true' : 'false'">
                        <ul class="handbook-sub-list scrollable-container">
                            <li *ngFor="let listItem of handbookTab" class="nav-link" role="tab" 
                                [attr.aria-controls]="listItem" 
                                [attr.aria-selected]="selectedTab === listItem"  
                                (click)="selectTab(listItem, true)"
                                [ngClass]="{ 'active': selectedTab === listItem }">
                                {{ listItem }}
                            </li>
                        </ul>
                    </div>
                
                    <li class="nav-link" id="purchase-billing-tab" role="tab" (click)="selectTab('purchase-billing-tab')">
                        <span class="list-number">{{ number.two }}{{ constants.dot }}</span> {{ constants.purchasing_and_billing }}
                    </li>
                    <div class="collapse" [ngClass]="{ 'show': isSecondCollapsed }" 
                         [attr.aria-expanded]="isSecondCollapsed ? 'true' : 'false'">
                        <ul class="handbook-sub-list scrollable-container">
                            <li *ngFor="let listItem of handbookTab1" class="nav-link" role="tab" 
                                [attr.aria-controls]="listItem" 
                                [attr.aria-selected]="selectedTab === listItem" 
                                (click)="selectTab(listItem, true)"
                                [ngClass]="{ 'active': selectedTab === listItem }">
                                {{ listItem }}
                            </li>
                        </ul>
                    </div>
                
                    <li class="nav-link" id="studio-protection-tab" role="tab" (click)="selectTab('studio-protection-tab')">
                        <span class="list-number">{{ number.three }}{{ constants.dot }}</span> {{ constants.studio_protection_group_emergency_response }}
                    </li>
                    <div class="collapse" [ngClass]="{ 'show': isThirdCollapsed }" 
                         [attr.aria-expanded]="isThirdCollapsed ? 'true' : 'false'">
                        <ul class="handbook-sub-list scrollable-container">
                            <li *ngFor="let listItem of handbookTab2" class="nav-link" role="tab" 
                                [attr.aria-controls]="listItem"  
                                [attr.aria-selected]="selectedTab === listItem"  
                                (click)="selectTab(listItem, true)"
                                [ngClass]="{ 'active': selectedTab === listItem }">
                                {{ listItem }}
                            </li>
                        </ul>
                    </div>

                    <li class="nav-link" id="safety-environment-tab" role="tab" (click)="selectTab(constants.safety_environment_tab)">
                        <span class="list-number">{{ number.four }}{{ constants.dot }}</span> {{ constants.safety_and_environmental_affairs }}
                    </li>
                    <div class="collapse" [ngClass]="{ 'show': isFourCollapsed }" 
                         [attr.aria-expanded]="isFourCollapsed ? 'true' : 'false'">
                        <ul class="handbook-sub-list scrollable-container">
                            <li class="nav-link" role="tab" 
                                [attr.aria-controls]="constants.safety_environment_tab"  
                                [attr.aria-selected]="selectedTab === constants.safety_environment_tab"  
                                (click)="selectTab(constants.safety_environment_tab, true)"
                                [ngClass]="{ 'active': selectedTab === constants.safety_environment_tab }">
                                <span class="list-number">{{ number.four_point_zero }}</span>{{constants.hyphen}} {{ constants.safety_and_environmental_affairs }}
                            </li>
                        </ul>
                    </div>
                </ol>
            </div>

            <div class="col-md-4 col-lg-4 col-sm-12 d-block d-md-none mobile-select-block">
                <select class="form-select" id="mobileSelect" [(ngModel)]="selectedTab" (change)="onSelectChange($event)">
                    <ng-container *ngFor="let listContent of handbookTab">
                        <option [value]="listContent">{{ listContent }}</option>
                    </ng-container>
                    <ng-container *ngFor="let listContent of handbookTab1">
                        <option [value]="listContent">{{ listContent }}</option>
                    </ng-container>
                    <ng-container *ngFor="let listContent of handbookTab2">
                        <option [value]="listContent">{{ listContent }}</option>
                    </ng-container>
                    <ng-container *ngIf="selectedTab === constants.safety_environment_tab">
                        <option [value]="constants.safety_environment_tab">
                            <span class="list-number">{{ number.four }}{{ constants.dot }}</span> {{constants.hyphen}} {{ constants.safety_and_environmental_affairs }}
                        </option>
                    </ng-container>  
                </select>
            </div>

            <div class="col-md-8 col-lg-8 col-sm-12">
                <!-- Tab Content -->
                <div class="tab-content" id="v-pills-tabContent">
                    <!-- Office Production Tab -->
                    <div class="tab-pane fade" [ngClass]="{ 'show active': selectedTab === '1.0 – Overview' }" id="office-production-tab" role="tabpanel" aria-labelledby="office-production-tab">
                    </div>
                    <!-- Sub-tabs for Office Production -->
                    <ng-container *ngFor="let listContent of handbookTab">
                        <div class="tab-pane fade" *ngIf="handbookContent" [ngClass]="{ 'show active': selectedTab === listContent }" id="{{ listContent }}" role="tabpanel">
                            <div class="page-details-section" [innerHtml]="handbookContent"></div>
                        </div>
                    </ng-container>

                    <!-- Purchase Billing Tab -->
                    <div class="tab-pane fade" [ngClass]="{ 'show active': selectedTab === '2.0 – Accounts Receivable Numbers (AR Number)' }" id="purchase-billing-tab" role="tabpanel" aria-labelledby="purchase-billing-tab">
                    </div>

                    <!-- Sub-tabs for Purchase Billing -->
                    <ng-container *ngFor="let listContent of handbookTab1">
                        <div class="tab-pane fade" *ngIf="handbookContent1" [ngClass]="{ 'show active': selectedTab === listContent }" id="{{ listContent }}" role="tabpanel">
                            <div class="page-details-section" [innerHtml]="handbookContent1"></div>
                        </div>
                    </ng-container>

                    <!-- Studio Protection Tab -->
                    <div class="tab-pane fade" [ngClass]="{ 'show active': selectedTab === '3.0 – Studio Protection Group (SPG)' }" id="studio-protection-tab" role="tabpanel" aria-labelledby="studio-protection-tab">
                    </div>

                    <!-- Sub-tabs for Studio Protection -->
                    <ng-container *ngFor="let listContent of handbookTab2">
                        <div class="tab-pane fade" *ngIf="handbookContent2" [ngClass]="{ 'show active': selectedTab === listContent }" id="{{ listContent }}" role="tabpanel">
                            <div class="page-details-section" [innerHtml]="handbookContent2"></div>
                        </div>
                    </ng-container>

                    <!-- Safety and Environment Tab -->
                    <div class="tab-pane fade" [ngClass]="{ 'show active': selectedTab === constants.safety_environment_tab }" id="safety-environment-tab" role="tabpanel" aria-labelledby="safety-environment-tab">
                        <div class="page-details-section">
                            <h3 class="four-tab">{{ number.four_point_zero }}  {{constants.hyphen}} {{ constants.safety_and_environmental_affairs }}</h3>
                            <p class="para-text-light">{{constants.visit_link}}</p> 
							<p><a href="https://safetyontheset.com/" target="_blank">{{constants.safety_on_the_set}}</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>