import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  first_name: string;
  
  ngOnInit() {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.first_name = userProfile.firstName;
  }
}