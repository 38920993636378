<div class="modal-body justify-content-center align-items-center">
    <div class="cm-header-section">
        <div *ngIf="currentStep === 1" class="cm-header-title text-center mb-5">
            <h5 class="modal-title mb-2" id="commonModalLabel">{{ credAppConstants.creditApplicationForm }}</h5>
            <p class="modal-title-desc">{{ credAppConstants.credAppSubtext }}</p>
        </div>
        <div *ngIf="currentStep === 2" class="cm-header-title text-center mb-5">
            <h5 class="modal-title mb-2" id="commonModalLabel">{{ credAppConstants.certificateOfInsurance }}</h5>
            <p class="modal-title-desc">{{ credAppConstants.coiSubtextOne }}<br>
                {{ credAppConstants.coiSubTextTwo }}</p>
        </div>
        <div *ngIf="currentStep === 3" class="cm-header-title text-center mb-5">
            <h5 class="modal-title mb-2" id="commonModalLabel">{{ credAppConstants.termsAndConditions }}</h5>
            <p class="modal-title-desc">{{ credAppConstants.tncSubText }}</p>
        </div>
        <div *ngIf="currentStep === 4" class="cm-header-title text-center mb-5">
            <h5 class="modal-title" id="commonModalLabel">{{ credAppConstants.reviewApplication }}</h5>
            <p class="cm-subtitle mb-5">{{ credAppConstants.reviewSubtext }}</p>
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"><span
                class="cm-close-text">{{ credAppConstants.close }}</span></button>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
                <div class="modal-left-section me-4">
                    <h4 class="common-left-section-title">Credit Application</h4>
                    <div class="company-meta-data">
                        <p class="title mb-0"><strong class="common-title">PROJECT:</strong>
                            <span class="common-content">{{ projectName }}</span>
                        </p>
                        <div id="departments-container">
                            <p class="title mb-0"><strong class="common-title">Departments:</strong></p>
                            <p class="common-content">
                                <span *ngFor="let department of departmentsData; let last = last">
                                    {{ department.departments.departmentName }}<span *ngIf="!last">; </span>
                                </span>
                            </p>
                        </div>
                        <div class="d-flex flex-column mt-2">
                            <span class=" mb-1" (click)="openEditDepartmentsModal(editDepartmentsModal)"><a
                                    class="text-link">Edit departments <img src="../assets/images/edit-icon.svg"
                                        alt="Edit Department"></a></span>
                        </div>
                    </div>
                    <div class="mb-4 mt-3">
                        <div class="list-group list-group-flush mb-2">
                            <ul class="step-list">
                                <li (click)="selectStep(1)">
                                    <span class="step-icon"
                                        [ngClass]="{
                                            'step-uncheck': startApplicationData?.creditApplicationList?.applicationFormStatus?.caFormStatus?.status === 'Not Started',
                                            'step-check-grey': startApplicationData?.creditApplicationList?.applicationFormStatus?.caFormStatus?.status === 'Pending',
                                            'step-check': startApplicationData?.creditApplicationList?.applicationFormStatus?.caFormStatus?.status === 'Completed'}"></span>
                                    <span class="step-text" [ngClass]="{'current-step': currentStep === 1}"> Application
                                        Form</span>
                                </li>
                                <li (click)="selectStep(2)">
                                    <span class="step-icon"
                                        [ngClass]="{
                                            'step-uncheck': startApplicationData?.creditApplicationList?.certificateOfInsurance?.caFormStatus?.status === 'Not Started',
                                            'step-check-grey': startApplicationData?.creditApplicationList?.certificateOfInsurance?.caFormStatus?.status === 'Pending',
                                            'step-check': startApplicationData?.creditApplicationList?.certificateOfInsurance?.caFormStatus?.status === 'Completed'}"></span>
                                    <span class="step-text" [ngClass]="{'current-step': currentStep === 2}">
                                        Certification of Insurance</span>
                                </li>
                                <li (click)="selectStep(3)">
                                    <span class="step-icon"
                                        [ngClass]="{
                                            'step-uncheck': startApplicationData?.creditApplicationList?.termAndCondition?.caFormStatus?.status === 'Not Started',
                                            'step-check-grey': startApplicationData?.creditApplicationList?.termAndCondition?.caFormStatus?.status === 'Pending',
                                            'step-check': startApplicationData?.creditApplicationList?.termAndCondition?.caFormStatus?.status === 'Completed'}"></span>
                                    <span class="step-text" [ngClass]="{'current-step': currentStep === 3}">Terms &
                                        Conditions</span>
                                </li>
                                <li (click)="selectStep(4)">
                                    <span class="step-icon"
                                        [ngClass]="{
                                            'step-uncheck': startApplicationData?.creditApplicationList?.reviewApplicationStatusDetails?.formSatusDetails?.status === 'Not Started',
                                            'step-check-grey': startApplicationData?.creditApplicationList?.reviewApplicationStatusDetails?.formSatusDetails?.status === 'Pending',
                                            'step-check': startApplicationData?.creditApplicationList?.reviewApplicationStatusDetails?.formSatusDetails?.status === 'Completed'}"></span>
                                    <span class="step-text" [ngClass]="{'current-step': currentStep === 4}">Review
                                        Application</span>
                                </li>
                            </ul>
                        </div>
                        <p class="required-text">(<span class="star-red">*</span>)Required</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
                <ng-container *ngIf="currentStep === 1">
                    <app-cred-app-step-one [states]="states" [projectId]="projectId" [revisionData] = "revisionData"  [customerStatus] = "customerStatus"
                        [startApplicationData]="startApplicationData" [caSeqId]="caSeqId" [caNumber]="caNumber"
                        (nextStepEvent)="nextStep()" (stepCompleted)="onStepCompleted(currentStep)"
                        (saveAndCloseEvent)="closeModal()" [creditAppStatus]="creditAppStatus"></app-cred-app-step-one>
                </ng-container>
                <ng-container *ngIf="currentStep === 2">
                    <app-certificate-of-insurance [states]="states" [startApplicationData]="startApplicationData" [revisionData] = "revisionData"  [customerStatus] = "customerStatus"
                        (nextStepEvent)="nextStep()" (prevStepEvent)="previousStep()" (saveAndCloseEvent)="closeModal()"
                        (stepCompleted)="onStepCompleted(currentStep)" [caSeqId]="caSeqId" [caNumber]="caNumber"
                        [creditAppStatus]="creditAppStatus"></app-certificate-of-insurance>
                </ng-container>
                <ng-container *ngIf="currentStep === 3">
                    <app-cred-app-step-three [creditMetaData]="creditMetaData" [customerStatus] = "customerStatus"
                        [startApplicationData]="startApplicationData" [creditAppStatus]="creditAppStatus" [revisionData] = "revisionData"
                        (nextStepEvent)="nextStep()" (prevStepEvent)="previousStep()"></app-cred-app-step-three>
                </ng-container>
                <ng-container *ngIf="currentStep === 4">
                    <app-cred-app-step-four [startApplicationData]="startApplicationData" [customerStatus] = "customerStatus" 
                        (prevStepEvent)="previousStep()" (saveAndCloseEvent)="closeModal()"></app-cred-app-step-four>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<ng-template #editDepartmentsModal let-modal>
    <div class="page-wrapper-modal">
        <div class="refrence-letter-section">
            <div class="container cm-main-container">
                <div class="row">
                    <h3 class="checkbox-heading mb-4">{{credFormDepartmentConstant.credDepartmentFormTitle}}</h3>
                    <div class="col-md-6 col-lg-4 col-sm-6 col-12 mb-4" *ngFor="let department of departments">
                        <div class="common-checkbox-button">
                            <input type="checkbox" [id]="'custom-checkbox-' + department.departmentId"
                                class="common-checkbox-input" [value]="department.departmentId"
                                (change)="toggleDepartment(department.departmentId, $event)"
                                [checked]="isSelected(department.departmentId)" />
                            <label [for]="'custom-checkbox-' + department.departmentId" class="common-checkbox-label">
                                {{ department.departmentName }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="save-btn-wrapper">
            <div class="d-flex flex-row align-items-center g-3">
                <button class="common-btn-light btn" (click)="cancel()">{{ credFormDepartmentConstant.cancel }}</button>
                <button class="common-btn-secondary btn save-btn" (click)="saveChanges()">{{
                    credFormDepartmentConstant.saveChanges }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #successTpl>
    <div class="success">{{ credFormDepartmentConstant.deptEditSuccess }}</div>
</ng-template>

<ng-template #dangerTpl>
    <div class="danger">{{ deptEditError }}</div>
</ng-template>