
      <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
          <h5 class="modal-title" id="commonModalLabel">{{modalTitle}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="OpenWarningModal()"
            aria-label="Close"><span class="cm-close-text">{{projectText.close}}</span></button>
        </div>
        <form [formGroup]="projectForm">
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-3">
                <div class="modal-left-section">
                  <div class="company-title">{{projectText.company}}</div>
                  <ng-container *ngIf="isCompany">
                    <div class="company-meta-data">
                      <p class="title mb-0 mt-2">{{projectText.type}} <span class="subtitle">{{companyDetails?.companyType?.companyType}}</span></p>
                      <p class="title mb-0">{{projectText.companyText}}</p>
                      <p class="pb-0 pt-0">{{companyDetails?.companyName}}</p>
                      <div class="d-flex flex-column">
                        <span class=" mb-2"><a class="text-link"
                            (click)="changeCompanySelection(company)">{{projectText.changeCompany}}</a></span>
                        <span class=" mb-2"><a (click)="navigateToCompany()" ><span class="text-link">{{projectText.openCompany}}</span>&nbsp;<img src="../../assets/images/link-icon.svg"
                          class="" alt="open company"></a></span>
                      </div>
                    </div>
                  </ng-container>
                  <button *ngIf="!isCompany" class="btn common-btn-secondary"
                    (click)="openSelectCompany(company)">{{projectText.selectCompany}}</button>
                    <div *ngIf="submitted && !isCompany">
                      <p class="text-danger">{{message.required}}</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-9">
                <div class="container cm-main-container  mx-0">

                  <div class="common-modal-input-section">
                    <h2 class="section-main-header">{{projectText.projectName}}</h2>
                    <div >
                      <!-- <form> -->
                      <div class="row">
                        <div class="col-md-12 custom-floating-label">
                          <label for="input1" class="form-label">{{projectText.projectName}}&nbsp;
                            <span class="hash-tricks">*</span>&nbsp;</label>
                          <input type="text" class="form-control" placeholder="Project Name" id="input1"
                            formControlName="projectName">
                            <div *ngIf="submitted && projectForm.get('projectName').hasError('required')">
                              <p class="text-danger">{{message.required}}</p>
                            </div>
                            <div *ngIf="projectForm.get('projectName').hasError('maxlength')">
                              <p class="text-danger">{{message.characterLimitHundred}}</p>
                            </div>
              
                        </div>

                      </div>

                      <!-- </form> -->
                    </div>
                  </div>

                  <div class="common-modal-input-section">
                    <h2 class="section-main-header">{{projectDetailsText.projectDetails}}</h2>
                    <!-- <form> -->
                      <div class="row">
                        <div class="col-md-12 ">
                          <p class="form-section-header mt-0">{{projectDetailsText.projectType}}&nbsp;
                            <span class="hash-tricks">*</span>&nbsp;</p>
                        </div>

                        <ng-container *ngFor="let details of projectProjectTypeDetails">
                          <div class="col-md-4 col-lg-4 col-sm-6 col-6  mt-2">
                            <div class="common-radio-btn ">
                             <div class="radio-wrapper">
                              <input class="common-radio-input mb-0 " type="radio" name="projectType" [id]="details.projectType"
                                [value]="details.projectType" formControlName="projectType" />
                                </div>
                              <label class="common-radio-label">
                                {{details.projectType}}
                              </label>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="projectForm?.controls['projectType'].value == 'Other'">
                        <div class="col-lg-12 mx-1 custom-floating-label mt-4 mb-0 ">
                          <label for="input1" class="form-label">{{projectDetailsText.type}}</label>
                          <input type="text" class="form-control custom-type" placeholder="What is your project type?"
                            formControlName="projectTypeOther" id="input1">
                          <div *ngIf="submitted && projectForm?.controls['projectType'].value == 'Other' && projectForm.get('projectTypeOther').invalid">
                            <p class="text-danger">{{message.required}}</p>
                          </div>
                        </div>
                        </ng-container>
                        <div *ngIf="submitted && projectForm.get('projectType').invalid" class="ml-0">
                          <p class="text-danger">{{message.required}}</p>
                        </div>
                        <ng-container *ngIf="projectForm?.controls['projectType'].value == 'Television'">
                          <div class="col-md-12 mt-4 ">
                            <p class="form-section-header mt-0 ">{{projectDetailsText.cameraSetup}}</p>
                          </div>


                          <ng-container *ngFor="let camera of projectProjectCameraDetails">
                            <div class="col-md-4 col-lg-4 col-sm-6 col-6 mt-3">
                              <div class="common-radio-btn">
                                <input class="common-radio-input mb-0" type="radio" name="cameraSetup" [id]="camera.projectCameraSetup"
                                  [value]="camera.projectCameraSetup" formControlName="cameraSetup" />
                                <label class="common-radio-label" [for]="camera.projectCameraSetup">
                                  {{camera.projectCameraSetup}}
                                </label>
                              </div>
                            </div>
                          </ng-container>
                          <div *ngIf="submitted && projectForm?.controls['projectType'].value == 'Television' && projectForm.get('cameraSetup').invalid" class="ml-0">
                            <p class="text-danger">{{message.required}}</p>
                          </div>
                          <div class="col-md-12 mt-5">
                            <p class="form-section-header mt-0 ">{{projectDetailsText.programmingSchedule}}</p>
                          </div>
                          <ng-container *ngFor="let program of projectProgrammingScheduleDetails">
                            <div class="col-md-4 col-lg-4 col-sm-6 col-6 mt-3">
                              <div class="common-radio-btn">
                                <input class="common-radio-input mb-0" type="radio" name="programSchedule" [id]="program.programingSchedule"
                                  [value]="program.programingSchedule" formControlName="programSchedule" />
                                <label class="common-radio-label" [for]="program.programingSchedule">
                                  {{program.programingSchedule}}
                                </label>
                              </div>
                            </div>
                          </ng-container>

                          <div class="col-md-4 col-lg-4 col-sm-6 col-6 ">
                            <div class="custom-dropdown disabled w-100">
                              <select class="custom-select disabled" formControlName="programScheduleSeason" [ngClass]="{ 'no-pointer-events': projectForm?.controls['programSchedule'].value !== 'Series/Multi Episodes' ,'disabled':projectForm?.controls['programSchedule'].value !== 'Series/Multi Episodes'}">
                                <option class="dropdown-option" value="" disabled selected hidden>
                                  {{projectDetailsText.season}}</option>
                                <option class="dropdown-option" [disabled]="projectForm?.controls['programSchedule'].value !== 'Series/Multi Episodes'" *ngFor="let option of seasons" [value]="option.season">
                                  {{option.season}}</option>
                              </select>
                            </div>

                            <ng-container *ngIf="projectForm?.controls['programSchedule'].value == 'Series/Multi Episodes' && projectForm?.controls['programScheduleSeason'].value == 'Other'">
                              <div class="col-md-5 custom-floating-label mt-4 w-100">
                                <label for="input1" class="form-label">{{projectDetailsText.seasonNumber}}</label>
                                <input type="number" min="1" max="99" class="form-control" placeholder="Season Number"
                                  formControlName="seasonOther" id="input1">
                                <div *ngIf="submitted && projectForm?.controls['programScheduleSeason'].value == 'Other' && projectForm.get('seasonOther').hasError('required')">
                                  <p class="text-danger">{{message.required}}</p>
                                </div>
                                <div *ngIf="projectForm.get('seasonOther').hasError('max')">
                                  <p class="text-danger">{{errorMsg.maxAllowedValue}}</p>
                                </div>
                              </div>
                              </ng-container>
      
                            <div *ngIf="submitted && projectForm?.controls['programSchedule'].value == 'Series/Multi Episodes' && projectForm.get('programScheduleSeason').invalid">
                              <p class="text-danger">{{message.required}}</p>
                            </div>
                          </div>
                          <div *ngIf="submitted && projectForm?.controls['projectType'].value == 'Television' && projectForm.get('programSchedule').invalid" class="ml-0">
                            <p class="text-danger">{{message.required}}</p>
                          </div>

                          
                        </ng-container>
                        <div class="col-md-12 mt-3 ">
                          <p class="form-section-header mt-0">{{projectDetailsText.projectDescription}}</p>
                        </div>
                        <div class="col-md-12 ">
                          <div class=" custom-floating-label mt-3">
                            <label for="input1" class="form-label">{{projectDetailsText.projectDescription}}</label>

                            <textarea class="form-control form-control-lg common-text-area" id="project Description"
                              formControlName="projectDesc" rows="4"></textarea>
                              <div *ngIf="submitted && projectForm.get('projectDesc').invalid">
                                <p class="text-danger">{{message.required}}</p>
                              </div>
                          </div>
                        </div>
                      </div>
                    <!-- </form> -->
                  </div>
                  <div class="common-modal-input-section">
                    <h2 class="section-main-header">{{projectDetailsText.anticipatedProjectDates}}</h2>
                    <!-- <form> -->
                      <div class="row">
                        <div class=" col-md-6 custom-floating-label" id="datepicker ">
                          <label for="input1" class="form-label">{{projectDetailsText.startDate}}&nbsp;
                            <span class="hash-tricks">*</span>&nbsp;</label>
                          <input type="date" class="form-control d-block" placeholder="Start Date" id="date"
                            formControlName="projectStart" min="1800-01-01" max="2500-12-31"/>
                            <div *ngIf="submitted && projectForm.get('projectStart').invalid">
                              <p class="text-danger">{{message.required}}</p>
                            </div>
                        </div>
                        <div class=" col-md-6 custom-floating-label" id="datepicker">
                          <label for="input1" class="form-label">{{projectDetailsText.endDate}}&nbsp;
                            <span class="hash-tricks">*</span>&nbsp;</label>
                          <input type="date" class="form-control d-block" placeholder="Start Date" id="date"
                            formControlName="projectEnd" min="1800-01-01" max="2500-12-31"/>
                          <div *ngIf="submitted && projectForm.get('projectEnd').hasError('required')">
                            <p class="text-danger">{{message.required}}</p>
                          </div>
                          <div *ngIf="projectForm.get('projectEnd').hasError('invalidRange')">
                            <p class="text-danger">End date cannot be before start date.</p>
                          </div>
                        </div>
                        <div class="col-md-12 ">
                          <p class="form-section-header mt-0">{{projectDetailsText.additionalscdeduledNotes}}</p>
                        </div>
                        <div class="col-md-12  ">
                          <div class=" custom-floating-label mt-3">
                            <label for="input1" class="form-label">{{projectDetailsText.scdeduledNotes}}</label>
                            <textarea class="form-control form-control-lg common-text-area" id="project Description"
                              rows="4" formControlName="additionalNotes"></textarea>
                              <div *ngIf="submitted && projectForm.get('additionalNotes').invalid">
                                <p class="text-danger">{{message.required}}</p>
                              </div>
                          </div>
                        </div>
                      </div>
                    <!-- </form> -->
                  </div>


                  <div class="common-modal-input-section custom-internal-use-bg-shadow grey-background">
                    <!-- <h2 class="section-main-header">{{projectCompanyTypeText.companyType}}</h2> -->


                    <p class="text-color-red">{{projectCompanyTypeText.forInternal}}</p>
                    <!-- <form> -->
                      <div class="row">
                        <div class="col-md-6 custom-floating-label">
                          <label for="input1"
                            class="form-label form-label-for-select">{{projectCompanyTypeText.customerType}}&nbsp;
                            <span class="hash-tricks">*</span>&nbsp;</label>

                          <div class=" custom-dropdown w-100">
                            <select class="custom-select" formControlName="customerType">
                              <option class="dropdown-option" value="" disabled selected hidden>
                                {{projectCompanyTypeText.select}}</option>
                              <option class="dropdown-option" *ngFor="let customer of projectCustomerTypes" [value]="customer.customerType">
                                {{customer.customerType}}</option>
                            </select>
                            <div *ngIf="submitted && projectForm.get('customerType').invalid">
                              <p class="text-danger">{{message.required}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 custom-floating-label">
                          <label for="input1"
                            class="form-label form-label-for-select">{{projectCompanyTypeText.primaryPaymentType}}&nbsp;
                            <span class="hash-tricks">*</span>&nbsp;</label>
                          <div class=" custom-dropdown w-100">
                            <select class="custom-select" formControlName="primaryPayment">
                              <option class="dropdown-option" value="" disabled selected hidden>
                                {{projectCompanyTypeText.select}}</option>
                              <option class="dropdown-option" *ngFor="let payment of projectPrimaryPaymentTypes" [value]="payment.paymentType">
                                {{payment.paymentType}}</option>
                            </select>
                            <div *ngIf="submitted && projectForm.get('primaryPayment').invalid">
                              <p class="text-danger">{{message.required}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    <!-- </form> -->

                  </div>

                  <div class="cm-btn-section" *ngIf="!isEditMode">
                    <button type="button" class="btn common-btn-secondary me-2 mb-2"
                      data-bs-dismiss="modal" (click)="addNewProject()">{{projectText.addProject}}</button>
                    <button type="button" class="btn common-btn-secondary me-2 mb-2"
                      data-bs-dismiss="modal" (click)="addNewProjectAndNavigate()">{{projectText.addAndGo}}</button>
                  </div>

                  <div class="cm-btn-section" *ngIf="isEditMode">
                    <button type="button" class="btn common-btn-light me-2 mb-2"
                      data-bs-dismiss="modal" (click)="closeModal()">{{projectText.cancel}}</button>
                    <button type="button" class="btn common-btn-secondary me-2 mb-2"
                      data-bs-dismiss="modal" (click)="editProjectDetails()" [disabled]="buttonDisabled">{{projectText.saveChanges}}</button>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </form>
      </div>
  
<!---Added modals on click of Select Company-->
<ng-template #company let-modal>
  <div class="modal-body">
    <app-search [placeholder]="placeholder" [(searchTerm)]="searchBarInput" [userType]="companyText" [usersList]="projectCompanyDetails" [portalType]="portalType" (searchComplete)="onSearchCompleted($event)" (input)="checkSearchValue($event)"></app-search>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="btn common-btn-light"
        (click)="modal.close('Close click')">{{projectText.cancel}}</button>
      <button type="button" class="btn common-btn-secondary" [disabled]="isDisabled"
        (click)="modal.close('Close click'); companySelection()">{{projectText.select}}</button>
    </div>
  </div>
</ng-template>

<ng-template #successTpl>
  <div class="success">{{addEditResponse}}</div>
</ng-template>

<ng-template #dangerTpl>
  <div class="danger" *ngIf="addEditResponse?.errorMessages?.errorMessage">{{addEditResponse?.errorMessages?.errorMessage}}</div>
  <div class="danger" *ngIf="addEditResponse">{{addEditResponse}}</div>
</ng-template>

<ng-template #alertTpl>
  <div class="danger">{{addEditResponse?.message}}</div>
</ng-template>

<ng-template #WarningMsgModal let-modal>
  <div class="modal-body">
    <h4 class="section-main-header">{{ iconsRenderer.closeProjectCreationPage }}</h4>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-secondary btn" (click)="closeModal();modal.close()">
        {{ iconsRenderer.ok }}
      </button>
      <button type="button" class="common-btn-light btn" (click)="modal.close()">
        {{ iconsRenderer.cancel }}
      </button>
    </div>
  </div>
 </ng-template>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>