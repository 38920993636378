import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userInfoSubject = new BehaviorSubject<any>(null);
  userInfo$ = this.userInfoSubject.asObservable();

  /**
   * setUserInfo() - This is used to set userDetails to use in login component for redirection
   * @param userInfo - user details that we are setting in homepage component for userDetails
   */
  setUserInfo(userInfo: any) {
    this.userInfoSubject.next(userInfo);
  }
}
