import { Component, Inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { footer } from '../../constants/core.constant';
import { UserService } from '../../services/user-service/user-service.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  currentPortal: string;
  constants = footer;
  portalType: string;
  userTypeId: any;
  isLoggedIn: boolean = false;

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private router: Router, private userService: UserService) { }

  ngOnInit() {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    if (userProfile?.userTypeId == 1) {
      this.portalType = "customer";
    }
    else if (userProfile?.userTypeId == 2) {
      this.portalType = "studio";
    }
    this.getUserInfo()
    this.checkAuthentication();
  }

  /**
 * checkAuthentication() - Checks if the user is currently authenticated.
 */
  private async checkAuthentication() {
    try {
      this.isLoggedIn = await this.oktaAuth.isAuthenticated();
    } catch (error) {
      console.error('Error checking authentication status:', error);
    }
  }

  /**
   * redirectToDashboard() - Redirects the user to a specific dashboard based on portal
   */
  redirectToDashboard() {
    switch (this.portalType) {
      case 'customer':
        this.router.navigate(['/feature/customer-dashboard/overview']);
        break;
      case 'studio':
        this.router.navigate(['/feature/studio-dashboard/users']);
        break;
      case 'ar':
        this.router.navigate(['/feature/dashboard']);
        break;
      default:
        console.log('Unknown portal');
    }
  }

  /**
 * navigateOnLogoClick() - Navigates the user based on their login status.
 */
  navigateOnLogoClick(): void {
    if (this.isLoggedIn) {
      this.redirectToDashboard();
    } else {
      this.router.navigate(['/homepage']); // Adjust this path to your actual homepage route
    }
  }


  /**
  * getUserInfo() - used to get data from localstorage
  */
  getUserInfo(): void {
    this.userService.userInfo$.subscribe((userInfo) => {
      if (userInfo) {
        let userDetails = userInfo?.userProfileDTO;
        this.userTypeId = userDetails?.userTypeId;
        this.portalType = userDetails?.portalType[0];
      }
    });
  }
}
