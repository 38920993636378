<div class="page-details-section">
  <div class="settings-container">
    <h2 class="settings-heading">{{settings.settings}}</h2>
    <form [formGroup]="settingsForm" (ngSubmit)="onSaveSettings()">
      <div class="custom-dropdown">
        <label for="timezone" class="form-label">{{settings.timeZone}}</label>
        <select id="timezone" class="form-control custom-select" formControlName="timezone">
          <option *ngFor="let zone of timezones1" [value]="zone.value">
            {{ zone.label }}
          </option>
        </select>
        <small *ngIf="hasError('timezone', 'required')"
          class="text-danger error-handeling">{{settings.validatorRequired}}</small>
      </div>

      <div>
        <label class="commmon-header">{{settings.dateFormat}}</label>
        <div class="radio-group d-flex">
          <div class="common-radio-btn">
            <input class="common-radio-input mb-0" type="radio" name="dateFormat" id="mdy" formControlName="dateFormat"
              value="m/d/yyyy" />
            <label class="common-radio-label mb-0" for="mdy">
              {{settings.dateFormat1}}
            </label>
          </div>
          <div class="common-radio-btn">
            <input class="common-radio-input mb-0" type="radio" name="dateFormat" id="dmy" formControlName="dateFormat"
              value="d/m/yyyy" />
            <label class="common-radio-label mb-0" for="dmy">
              {{settings.dateFormat2}}
            </label>
          </div>
        </div>
      </div>
      <div>
        <label class="commmon-header">{{settings.calendarFormat}}</label>
        <div class="radio-group d-flex">
          <div class="common-radio-btn">
            <input class="common-radio-input mb-0" type="radio" name="calendarFormat" id="starts on Sunday"
              formControlName="calendarFormat" value="starts on Sunday" />
            <label class="mb-0 common-font" for="starts on Sunday">
              {{settings.startSunday}}
            </label>
          </div>
          <div class="common-radio-btn">
            <input class="common-radio-input mb-0" type="radio" name="calendarFormat" id="starts on Monday"
              formControlName="calendarFormat" value="starts on Monday" />
            <label class="common-radio-label mb-0 common-font" for="starts on Monday">
              {{settings.startMonday}}
            </label>
          </div>
        </div>
      </div>
      <div>
        <label class="commmon-header">{{settings.timeFormat}}</label>
        <div class="radio-group d-flex">
          <div class="common-radio-btn me-3">
            <input class="common-radio-input mb-0" type="radio" name="timeFormat" id="12h" formControlName="timeFormat"
              value="12h" />
            <label class="common-radio-label mb-0" for="12h">
              {{settings.timeFormat1}}
            </label>
          </div>
          <div class="common-radio-btn">
            <input class="common-radio-input mb-0" type="radio" name="timeFormat" id="24h" formControlName="timeFormat"
              value="24h" />
            <label class="common-radio-label mb-0" for="24h">
              {{settings.timeFormat2}}
            </label>
          </div>
        </div>
      </div>
      <div>
        <label class="commmon-header">{{settings.temperatureUnit}}</label>
        <div class="radio-group d-flex">
          <div class="common-radio-btn me-3">
            <input class="common-radio-input mb-0" type="radio" name="temperatureUnit" id="Fahrenheit"
              formControlName="temperatureUnit" value="Fahrenheit" />
            <label class="common-radio-label mb-0" for="Fahrenheit">
              {{settings.fahrenheit}}
            </label>
          </div>
          <div class="common-radio-btn">
            <input class="common-radio-input mb-0" type="radio" name="temperatureUnit" id="Celsius"
              formControlName="temperatureUnit" value="Celsius" />
            <label class="common-radio-label mb-0" for="Celsius">
              {{settings.celsius}}
            </label>
          </div>
        </div>
      </div>
      <div>
        <button type="submit" class="common-btn-secondary btn" [disabled]="!isFormChanged || disableSaveButton">{{settings.save}}</button>
      </div>
    </form>
  </div>
</div>
