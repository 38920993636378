import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMAIL_NOTIFICATION } from '../../../../feature/customer-portal/constants/customer-portal.constant';
import {
  UserPreferences,
  UserProfileSearch,
} from 'src/app/shared/models/profileModel';
import { INDEX } from 'src/app/shared/enum/shared.enum';
@Component({
  selector: 'app-email-notifications',
  templateUrl: './email-notifications.component.html',
  styleUrls: ['./email-notifications.component.scss'],
})
export class EmailNotificationsComponent implements OnInit {
  @Input() profileDetails: UserPreferences;
  @Input() profileInfo: UserProfileSearch;
  @Output() emailData = new EventEmitter<any>();
  disableSaveButton: boolean = true;

  emailNotificationForm: FormGroup;
  form: FormGroup;
  isFormChanged: boolean = false;
  emailNotification = EMAIL_NOTIFICATION;
  index = INDEX
  initialFormValues: any;

  constructor(private fb: FormBuilder) {
    this.emailNotificationForm = this.fb.group({
      applicationStatus: [true, Validators.required],
      creditApplication: [true, Validators.required],
    });
  }

  ngOnInit(): void {
    this.emailNotificationForm.valueChanges.subscribe(() => {
      this.checkFormChanges();
    });
    this.initialFormValues = this.emailNotificationForm?.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['profileDetails']) {
      this.emailNotificationForm.controls['applicationStatus'].setValue(
        this.profileDetails.emailCreditAppln == this.index.one
      );
      this.emailNotificationForm.controls['creditApplication'].setValue(
        this.profileDetails.emailCompany == this.index.one
      );
    }
    this.initialFormValues = this.emailNotificationForm.value;
    this.checkFormChanges();
  }

  isInitialState(): boolean {
    return JSON.stringify(this.emailNotificationForm.value) === JSON.stringify(this.initialFormValues);
  }

  /**
   * checkFormChanges() - checks if form values has any changes
   */
  checkFormChanges(): void {
    this.isFormChanged = this.emailNotificationForm.dirty;
    this.emailNotificationForm.valid
    this.disableSaveButton = this.isInitialState(); // Update button state
    console.log('isFormChanged:', this.isFormChanged); // Debugging
    console.log('disableSaveButton:', this.disableSaveButton); // Debugging
  }

  /**
   * onSave - To perform certain operations after submitting the form
   */
  onSave(): void {
    if (this.emailNotificationForm.valid && this.isFormChanged) {
      const data = {
        userProfileId: this.profileInfo.userProfileId,
        firstName: this.profileInfo?.firstName,
        lastName: this.profileInfo?.lastName,
        email: this.profileInfo?.emailAddress,
        contactNo: this.profileInfo?.contactNo,
        jobTitle: this.profileInfo?.jobTitle,
        userPreferences: {
          emailCreditAppln:
            this.emailNotificationForm.get('applicationStatus')?.value == true
              ? this.index.one
              : this.index.zero,
          emailCompany:
            this.emailNotificationForm.get('creditApplication')?.value == true
              ? this.index.one
              : this.index.zero,
        },
      };
      this.emailData.emit(data);
      this.emailNotificationForm.markAsPristine();
      this.initialFormValues = this.emailNotificationForm.value
      this.disableSaveButton = true;

    }
    this.emailNotificationForm.invalid
  }
}
