import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VIEW_QUOTES_CA } from 'mockData/development/studio-send-credit-application';
import { STUDIO_CREDIT_APPLICATION } from 'src/app/feature/studio-portal/constants/studio-portal.constant';
import { ProjectsCreditApplicationService } from 'src/app/feature/studio-portal/services/projects-credit-application.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';

@Component({
  selector: 'app-view-quotes',
  templateUrl: './view-quotes.component.html',
  styleUrls: ['./view-quotes.component.scss']
})
export class ViewQuotesComponent implements OnInit{
  creditApplicationConstants = STUDIO_CREDIT_APPLICATION;
  viewQuotesCAConstants = VIEW_QUOTES_CA;
  caQuotesGroupDetails: any[] = [];
  caQuotes: any[] = [];
  @Input() caSeqId;
  @Input() caNumber;

  constructor(public activeModal: NgbActiveModal,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private accountService: ProjectsCreditApplicationService
  ){}
    
  ngOnInit(){

    this.getViewQuoteDetails()
  }

  /**
   * closeModal() - Method to close the modal
   */
  closeModal(): void {
    this.activeModal.close();
  }

  /**
   * getViewQuoteDetails() - Method to get the view quote data.
   */

  getViewQuoteDetails(): void {
    this.loaderService.setLoadingState(true);
    this.accountService.getViewQuoteData(this.caSeqId, this.caNumber).subscribe((response: any) => {
      this.loaderService.setLoadingState(false);
      if (response.success) {
        this.caQuotesGroupDetails = response.caQuotesSearchResponseDetails.caQuotesGroupDetails;
        this.caQuotes = response.caQuotesSearchResponseDetails.caQuotesGroupDetails.caQuotes;
      } 
      else {
        console.error('Error fetching quote data');
      }

      error: (error) => {
        this.loaderService.setLoadingState(false);
        console.error('Error fetching quote data');
      }
      
    });
  }
}
