import { Component } from '@angular/core';

@Component({
  selector: 'app-add-wb-user',
  templateUrl: './add-wb-user.component.html',
  styleUrls: ['./add-wb-user.component.scss']
})
export class AddWbUserComponent {

}
