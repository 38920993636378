<div>
    <button
      class="btn btn-primary"
      (click)="openDeleteConfirmationModal(content)"
    >
      hello
    </button>
  </div>
  <ng-template #content let-modal>
    <div class="page-wrapper-modal">
      <div class="page-header">
        <h2 class="mb-0">Department Risk</h2>
      </div>
      <div class="title-company-project">
        <div class="company">
          <span class="company-heading">Company:</span>
          <span class="company-content">A Brand New Production Company</span>
        </div>
        <div class="project">
          <span class="project-heading">Project:</span>
          <span class="project-content">The New Show - Pilot</span>
        </div>
      </div>
      <div class="refrences">
        <div class="bank-reference">
            <h3 class="bank mb-0">Credit Line requested:</h3>
            <p class="content mb-0">$1,000,000.00</p>
          </div>
        <div class="db">
            <h3 class="mb-0">Departments selected to be used by customer:</h3>
            <div class="content">
              <div class="all-contents">
                <span>-</span><span>Department Name</span
                >
              </div>
              <div class="all-contents">
                <span>-</span><span>Department Name</span
                >
              </div>
              <div class="all-contents">
                <span>-</span><span>Department Name</span
                >
              </div>
              <div class="all-contents">
                <span>-</span><span>Department Name</span
                >
              </div>
              <div class="all-contents">
                <span>-</span><span>Department Name</span
                >
              </div>
            </div>
          </div>
          <div class="bank-reference">
            <h3 class="bank mb-0">Bank Reference:</h3>
            <p class="content mb-0">N/A</p>
          </div>
        <div class="trade-reference">
          <h3 class="trade mb-0">Trade References:</h3>
          <p class="content mb-0">
            Two Trades responded; Highest amount billed is $26,000.00
          </p>
        </div>
        <div class="db">
          <h3 class="mb-0">D&B:</h3>
          <div class="content">
            <div class="all-contents">
              <span>-</span><span>Deliquency Score:</span
              ><span class="content-number">88</span>
            </div>
            <div class="all-contents">
              <span>-</span><span>Failure Score:</span
              ><span class="content-number">67</span>
            </div>
            <div class="all-contents">
              <span>-</span><span>PAYDEX® Score:</span
              ><span class="content-number">73</span>
            </div>
          </div>
        </div>
      </div>
      <div class="section-text-area mt-0">
        <textarea class="text-area" id="credit-text" name="credit-text">
The customer has not met the WBSO Credit criteria and they are not associated with a major studio or on our approved guarantors list. 

Please note that the customer has not been notified that a line of credit will not be granted. However, A/R wanted to verify with the departments would like to accept the risk for this customer for all your respective departments and expense any amount deemed uncollectible from each group.

Please with your approval along with your SVP approval if the department would like to accept the risk and the credit limit for the customer. If A/R does not receive the approval from the departments, A/R will send the COD notification to the customer within 24 hours from sending the Customer Risk Approval - Financial Responsibility email.</textarea
        >
      </div>
    </div>
    <div class="end-wrapper">
      <div class="button">
        <div class="lhs-buttons">
       <span class="d-flex align-items-center gap-3"><img src="assets/images/blue-left-icon.svg"><span class="fb-600">back</span></span>
        <button class="common-btn-light btn">Cancel</button>
    </div>
        <button class="common-btn-secondary btn">Send to 5 Departments</button>
      </div>
    </div>
  </ng-template>
  