import { Component, HostListener, OnInit } from '@angular/core';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit{
    permissionsData: any;
    
    ngOnInit(): void {
      const userProfile = JSON.parse(localStorage.getItem('user-profile'));
      this.permissionsData = { ...userProfile.roleAssigned };
  }
    title = 'project-streamline';
    gridOptions: GridOptions = {
      pagination: true,
      paginationPageSize: 10,
      domLayout: 'autoHeight',
      defaultColDef: {
        flex: 1,
        sortable: true,
        filter:true
      },
      rowHeight: 80
    };
    columnDefs: ColDef[] = [
      { headerName: 'UPDATED', field: 'updated', cellRenderer: this.updatedRenderer, cellClass: 'common-split-cell-section' ,minWidth:120,flex:1.3},
      { headerName: 'REQUEST TYPE', 
        field: 'requestType', 
        flex: 2.5, 
        cellRenderer: (params: ICellRendererParams) => {
          const requestType = params.data.requestType;
        
          return `<div>
                    ${requestType}
                  </div>`;
        },
        cellClass: 'common-cell-data-word-break' ,minWidth:150
      },
      { headerName: 'STATUS', field: 'status', cellClass: this.statusCellClass,minWidth:180 ,flex:2},
      { headerName: 'ADDED BY', field: 'addedBy', cellRenderer: this.addedByRenderer, cellClass: 'common-split-cell-section',minWidth:120,flex:1.5 },
      { headerName: 'ADDED ON', field: 'addedOn', cellRenderer: this.addedOnRenderer, cellClass: 'common-split-cell-section',minWidth:120,flex:1.4 },
      { headerName: 'ASSIGNED TO', field: 'assignedTo', flex: 2, cellClass: 'common-cell-data-mt account-user-active-project-cell',minWidth:180 },
      { headerName: 'COMMENTS', field: 'comments', cellClass: 'common-cell-data-mt account-user-active-project-cell request-comment-cell-end',minWidth:120,flex:1.5 },
    ];
    rowData = [
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Lock Stage Request Stage RequestStage Request', status: 'NEW Request', addedBy: 'First/Last Name', jobTitle: 'Job Title 1', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' },
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Hot Lock Stage Request', status: 'ASSIGNED', addedBy: 'First/Last Name', jobTitle: 'Job Title 2', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' },
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Hot Lock Stage Request', status: 'NEW Request', addedBy: 'First/Last Name', jobTitle: 'Job Title 3', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' },
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Hot Lock Stage Request', status: 'IN PROGRESS', addedBy: 'First/Last Name', jobTitle: 'Job Title 4', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' },
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Hot Lock Stage Request', status: 'CLOSED', addedBy: 'First/Last Name', jobTitle: 'Job Title 5', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' },
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Hot Lock Stage Request', status: 'CLOSED', addedBy: 'First/Last Name', jobTitle: 'Job Title 6', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' },
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Hot Lock Stage Request', status: 'RESOLVED', addedBy: 'First/Last Name', jobTitle: 'Job Title 7', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' },
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Hot Lock Stage Request', status: 'RESOLVED', addedBy: 'First/Last Name', jobTitle: 'Job Title 8', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' },
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Hot Lock Stage Request', status: 'RESOLVED', addedBy: 'First/Last Name', jobTitle: 'Job Title 9', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' },
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Hot Lock Stage Request', status: 'IN PROGRESS', addedBy: 'First/Last Name', jobTitle: 'Job Title 10', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' },
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Hot Lock Stage Request', status: 'NEW Request', addedBy: 'First/Last Name', jobTitle: 'Job Title 11', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' },
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Hot Lock Stage Request', status: 'NEW Request', addedBy: 'First/Last Name', jobTitle: 'Job Title 12', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' },
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Hot Lock Stage Request', status: 'NEW Request', addedBy: 'First/Last Name', jobTitle: 'Job Title 13', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' },
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Hot Lock Stage Request', status: 'NEW Request', addedBy: 'First/Last Name', jobTitle: 'Job Title 14', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' },
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Hot Lock Stage Request', status: 'NEW Request', addedBy: 'First/Last Name', jobTitle: 'Job Title 15', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' },
      { updatedDate: '1/1/24', updatedTime: '10:00AM', requestType: 'Hot Lock Stage Request', status: 'NEW Request', addedBy: 'First/Last Name', jobTitle: 'Job Title 16', addedOnTime: '10:00AM', additionalTime: '2:00PM', assignedTo: 'Department Name', comments: '#' }
    ];
    gridApi!: GridApi;
    pageSizeOptions: number[] = [10, 20, 50];
    startRow: number = 1;
    endRow: number = 10;
    totalRows: number = this.rowData.length;
  
    onGridReady(params: any) {
      this.gridApi = params.api;
      this.updatePagination();
      this.adjustColumns(window.innerWidth);
    }
    onPageSizeChanged(event: any) {
      const value = event.target.value;
      this.gridApi.paginationSetPageSize(Number(value));
      this.updatePagination();
    }
    onBtNext() {
      this.gridApi.paginationGoToNextPage();
      this.updatePagination();
    }
    onBtPrevious() {
      this.gridApi.paginationGoToPreviousPage();
      this.updatePagination();
    }
    updatePagination() {
      if (this.gridApi) {
        const currentPage = this.gridApi.paginationGetCurrentPage();
        const pageSize = this.gridApi.paginationGetPageSize();
        this.startRow = currentPage * pageSize + 1;
        this.endRow = Math.min((currentPage + 1) * pageSize, this.totalRows);
      }
    }
    updatedRenderer(params: ICellRendererParams) {
      const date = params.data.updatedDate;
      const time = params.data.updatedTime;
      return `
      <span class="common-split-cell-section">
      <span class="common-split-cell-data-1">${date}</span>
      <span class="common-split-cell-data-2">${time}</span>
      </span>`;
    }
    statusCellClass(params: any) {
      switch (params.value) {
        case 'NEW Request': return 'request-status-tag new account-user-common-status-cell';
        case 'IN PROGRESS': return 'request-status-tag inprogress-color account-user-common-status-cell';
        case 'CLOSED': return 'request-status-tag closed account-user-common-status-cell';
        case 'ASSIGNED': return 'request-status-tag assigned-color account-user-common-status-cell';
        case 'RESOLVED': return 'request-status-tag resolved-color account-user-common-status-cell';
        default: return '';
      }
    }
    addedByRenderer(params: ICellRendererParams) {
      const addedBy = params.data.addedBy;
      const jobTitle = params.data.jobTitle;
      return `<span class="common-split-cell-section">
      <span class="common-split-cell-data-1">${addedBy}</span>
      <span class="common-split-cell-data-2">${jobTitle}</span>
      </span>`;
    }
    addedOnRenderer(params: ICellRendererParams) {
      const time = params.data.addedOnTime;
      const additionalTime = params.data.additionalTime;
      return `<span class="common-split-cell-section">
      <span class="common-split-cell-data-1">${time}</span>
      <span class="common-split-cell-data-2">${additionalTime}</span>
      </span>`;
    }
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
      this.adjustColumns(event.target.innerWidth);
    }
  
    adjustColumns(screenWidth: number) {
      if (this.gridApi) {
        const allColumnIds: string[] = [];
        this.gridApi.getAllGridColumns().forEach((column) => {
          allColumnIds.push(column.getColId());
        });
        if (screenWidth <= 375) {
          this.gridApi.setColumnsVisible(allColumnIds, false);
          this.gridApi.setColumnsVisible(['updated', 'requestType','status'], true);
        } else if (screenWidth > 375 && screenWidth <= 510) {
          this.gridApi.setColumnsVisible(allColumnIds, false);
          this.gridApi.setColumnsVisible(['updated', 'requestType', 'status', 'comments'], true);
        } else if (screenWidth > 510 && screenWidth <= 992) {
          this.gridApi.setColumnsVisible(allColumnIds, false);
          this.gridApi.setColumnsVisible(['updated', 'requestType', 'status', 'assignedTo', 'comments'], true);
        } else {
          this.gridApi.setColumnsVisible(allColumnIds, true);
        }
      }
    }
  }
  