<div>
  <button
    class="btn btn-primary"
    (click)="openDeleteConfirmationModal(content)"
  >
    hello
  </button>
</div>
<ng-template #content let-modal>
  <div class="page-wrapper-modal">
    <div class="page-header">
      <h2 class="mb-0">Send COD Information</h2>
    </div>
    <div class="title-company-project">
      <div class="company">
        <span class="company-heading">Company:</span>
        <span class="company-content">A Brand New Production Company</span>
      </div>
      <div class="project">
        <span class="project-heading">Project:</span>
        <span class="project-content">The New Show</span>
      </div>
    </div>
    <div class="refrences">
        <div class="db">
          <h3 class="mb-0">Accounts payable contact(s):</h3>
          <div class="content">
            <div class="all-contents">
              <span>-</span><span>Jane Doe (jdoe@domain.com)</span>
            </div>
            <div class="action-add-edit d-flex align-items-center">
                <a>
                <img src="assets/images/edit-icon.svg">
                <span class="text-link ms-1">Add/Edit New</span>
            </a>
            </div>
          </div>
        </div>
      </div>
    <div class="section-text-area">
      <textarea class="text-area" id="credit-text" name="credit-text">
Dear Customer,

Thank you for your interest in doing business with WBSO. Based on email below A/R Team is under the impression that a cash account is needed therefore to process your credit card request please work directly with the department. WBSO is no longer accepting any credit card information via email.
Departments to contact are: Exterior Sets; Grip Department; Post Production Creative Services; Set Lighting; Sound Stages
Below are the links to our Costume, Property and other WBSO Departments which will provide the contact email and phone number for setting up a cash account.
Costumes: https://studiofacilities.warnerbros.com/costume/
Property: https://property.warnerbros.com
All Other WBSO Departments: https://studiofacilities.warnerbros.com/department-contacts/
If you have any questions, please let us know.

Thanks,
WBSO A/R</textarea
      >
    </div>
  </div>
  <div class="end-wrapper">
    <div class="button">
      <button class="common-btn-light btn">Cancel</button>
      <button class="common-btn-secondary btn">Send to Customer</button>
    </div>
  </div>
</ng-template>
