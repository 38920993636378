import { FormControl } from '@angular/forms';

export const CREDIT_APPLICATION = {
  creditApplication: 'Credit Applications',
  viewCredit: 'View Credit Application',
};

export const PROJECTS = {
  projects: 'Projects',
  project: 'Project',
  filters: 'FILTERS:',
  clearFilters: 'Clear Filters',
  id: 'ID #',
  users: 'Users',
  spacesResources: 'Spaces And Resources',
  company: 'Company',
  noProjects: 'no projects to display',
  goTo: 'Go To…',
  placeholder: 'Dates',
  season: 'Season #',
  noProjectsCompany: 'There are no projects associated with this company'
}

export const USER_FILTER_TYPES = {
  ID: 'id',
  Users: 'user',
  JobTitle: 'jobtitle',
  Status: 'status',
  LastLogin: 'lastlogin',
  Company: 'comapny',
}


export const statusData = [
  { id: 1, text: 'ACTIVE' },
  { id: 3, text: 'DEACTIVATED' }
];


export const USER_DROPDOWN_SETTINGS = {
  idField: "id",
  textField: "text",
  maxHeight: 250,
  noDataAvailablePlaceholderText: "No data available",
  noFilteredDataAvailablePlaceholderText: "No filtered data available",
  closeDropDownOnSelection: false,
  defaultOpen: false
}

export const PROJECT_FILTER_TYPES = {
  projectId: 'projectId',
  projects: 'projects',
  types: 'types',
  departments: 'departments',
  companies: 'companies',
  status: 'status',
  project: 'project',
  type: 'type',
  department: 'department',
  company: 'company'
}

export const PROJECT_DATES_DROPDOWN_LIST = {
  action: 'Action',
  otherAction: 'Another action',
  something: 'Something else here',
  separatedlink: 'Separated link',
  toggle: 'Toggle Dropdown',
};

export const PASSES_EXPECTED_TODAY = {
  passesExpectedToday: 'Passes Expected Today',
  all: 'All',
  passes: 'Passes',
  driveOn: 'Drive On',
  walkOn: 'Walk On',
  nextHour: 'Next Hour',
  today: 'Today',
  checkedIn: 'Checked In',
  viewAllPasses: 'View All Passes',
};

export const REQUEST = {
  request: 'Requests',
  viewAllRequest: 'View All Requests',
};

export const MENU = {
  projects: 'Projects',
  request: 'Requests',
  company: 'Company',
};

export const DASHBOARD = {
  request: 'Request',
  user: 'User',
  credit: 'Credit Application',
  pass: 'Pass',
};

export const BUTTON_TEXTS = {
  addWbUser: '+ Add a WB user',
  addCustomerUser: '+ Add a Customer User',
  clearAllFilters: 'Clear All Filters',
  filters: 'Filters:',
  save: 'Save',
  addUser: 'Add User',
  nextAddUser: 'Next:Add User',
  close : 'Close',
  reset :'Reset'

};

// messages.constants.ts

export const ERROR_MESSAGE = {
  userDeactivated: 'This user is deactivated and cannot be edited',
  // Add other messages as needed
};


export const LABEL_TEXTS = {
  itemsPerPage: 'Items per page:',
  selectDepartments: 'Select department(s):',

};


export const ACTIVE_PROJECTS = {
  activeProjects: 'Active Projects & Spaces',
  viewBookMarks: 'View Bookmarks & Project',
  myStages: 'My Stages',
  myOffices: 'My Offices',
  viewProject: 'View Project',
  viewAll: 'View All Project'
};

export const PROJECT_DETAILS_SPACES = {
  mySpaces: 'My Spaces & Resources',
  spaces: 'Spaces & Resources',
  stages: 'STAGES',
  offices: 'OFFICES',
  workshops: 'WORKSHOP',
  description: 'DESCRIPTION'
};

export const PROJECT_USERS_COUNT = {
  projectUsers: 'Project Users',
  viewAll: 'View All',
  addUser: 'Add a user',
  users: 'USERS'
};

export const CREDIT_APPLICATION_CONSTANTS = {
  creditApplications: 'Credit Applications',
  startApplication: 'Start a Credit Application'
};

export const EMAIL_NOTIFICATION = {
  emailNotification: 'Email Notification',
  crditApplication: 'Credit Application',
  applicationAndStatus: 'Credit Applications & their statuses',
  save: 'Save',
};

export const PROFILE = {
  myProfile: 'My Profile',
  firstName: 'First Name',
  timeZone: 'Time Zone',
  dateFormat: 'Date Format:',
  header: 'Header',
  save: 'Save',
  validatorRequired: 'First Name is required.',
  validatorTimeZone: 'Time Zone is Required',
  spaceValidator: 'First Name should not contain numbers or spaces.',
  discardChanges: 'Discard Changes',
  headerDescription:
    ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus mollis interdum.',
  dateFormat1: 'm/d/yyyy',
  dateFormat2: 'd/m/yyyy',
};

export const SETTINGS = {
  settings: 'Settings',
  timeZone: 'Time Zone:',
  validatorRequired: 'Time Zone is required',
  dateFormat: 'Date Format',
  calendarFormat: 'Calendar Format',
  startSunday: 'Starts on Sunday',
  startMonday: 'starts on monday',
  timeFormat: 'Time Format:',
  temperatureUnit: 'Preferred Temperature Unit:',
  fahrenheit: 'Fahrenheit',
  celsius: 'Celsius',
  save: 'Save',
  dateFormat1: 'm/d/yyyy',
  dateFormat2: 'd/m/yyyy',
  timeFormat1: '12h',
  timeFormat2: '24h',
  timeZoneDefault: '',
  calendarFormatDefault: 'sunday',
  fahrenheitDefault: 'fahrenheit',
};

export const TIMEZONES: string[] = ['UTC', 'GMT', 'EST', 'PST', 'CET'];

export const USER = {
  userName: 'User Name',
  profile: 'Profile',
  settings: 'Settings',
  emailNotifications: 'Email Notifications',
  profileTab: 'profile-tab',
  settingsTab: 'settings-tab',
  emailNotificationstab: 'email-notifications-tab',
  userActivatedMsg: 'USER ACTIVATED SUCCESSFULLY',
  userDectivatedMsg: 'USER DEACTIVATED SUCCESSFULLY',
  addUserSuccessMsg: 'PROJECT USER ADDED SUCCESSFULLY',
  updateUserSuccessMsg: 'PROJECT USER UPDATED SUCCESSFULLY',
  removeUserSuccessMsg: 'PROJECT USER REMOVED SUCCESSFULLY'
};

export const NO_NUMBERS_OR_SPACES_REGEX = /^(?!.*[\d\s]).*$/;
export function noNumbersOrSpaces(control: FormControl) {
  const value = control.value;
  if (value && !NO_NUMBERS_OR_SPACES_REGEX.test(value)) {
    return { invalidName: true };
  }
  return null;
}

export function hasError(controlName: string, errorType: string): boolean {
  const control = this.settingsForm.get(controlName);
  return control?.hasError(errorType) && (control?.touched || control?.dirty);
}

export const COMPANY = {
  companyname: "Sunshine Productions",
  companyid: 'Company ID #',
  projects: 'Projects',
  companyaddress: 'Production Company Address',
  billingaddress: 'Billing Address',
  contactinfo: 'Contact Information',
  companyuser: 'Company Users',
  creditstatus: 'Credit Status',
  adduser: 'Add a User',
  viewall: 'View All',
  valid: 'Valid Until',
  current: 'Current',
  editcompany: 'Edit Company',
  addcompany: 'Add a Company',
  portaltype: 'studio',
  modalType: 'edit',
  addProject:'Add a Project',
  internaluse:'FOR INTERNAL USE ONLY',
  companytype:'Company Type',
  enablecompany:'Enable Company',
  disablecompany:'Disable Company',
  addcustomeruser: 'Add a Customer User',
  addproject:'Add a Project',
  allcompanies:'All Companies',
  addnewproject:'Add A New Project',
  companytitle:'Companies',
  companybtn:'Company',
  enable:'enable',
  disable:'disable',
  notavailable: 'Not Available',
  modalEdit: 'edit',
  modalAdd: 'add',
  componentType: 'studio-company',
  companyerror: 'Company does not exist',
  editCustomerUser: 'Edit A Customer User',
  companyUserType: 'companyuser',
  federalTaxId: 'Federal Tax ID #'
};

export const COMPANY_USERS = {
  companyusers: 'Company Users',
  user: 'User',
  filter: 'Filters',
  adduser: 'Add a User',
};

export const PROJECT_USERS_LIST_CONSTANTS = {
  all: 'All',
  notVerified: 'Not Verified',
  inactive: 'Inactive',
  admin: 'Admin'
}

export const DropdownSettings = {
  id: "id",
  textField: "text",
  limitSelection: -1,
  maxHeight: 197,
  itemsShowLimit: 999999999999,
  searchPlaceholderText: "Search",
  noData: "No data available",
  noFilteredData: "No filtered data available",
}

export const PROJECT_DROPDOWN_SETTINGS = {
  idField: "id",
  textField: "text",
  maxHeight: 250,
  noDataAvailablePlaceholderText: "No data available",
  noFilteredDataAvailablePlaceholderText: "No filtered data available",
  closeDropDownOnSelection: false,
  defaultOpen: false
}

export const SORTING_CONSTANTS = {
  sort: "Sort:",
  mostRecent: "Most Recent",
  aToz: "A - Z"
}
export const GETSTATUS_CLASS = {
  active: 'ACTIVE',
  deactivated: 'DEACTIVATED',
  disabled:'DISABLED'
}
export const COLS = {
  companies:[
    {
      colsForLargeScreen :['user', 'status','goToPage','icons'],
      colsForSmallScreen:[ 'bella', 'iconsStatus'],
      colsForMediumScreen:[ 'bella', 'iconsStatus'],
      colsForExtraLargeScreen:['compid','companyName', 'status', 'projects','active','users','goToPage','icons']
    }
  ],
  company_user:[
    {
      colsForSmallScreen :['user-status','icons'],
      colsForMediumScreen:['user-status','icons'],
      colsForLargeScreen:['user', 'status', 'activity','jobTitle','icons'],
      colsForExtraLargeScreen:['userid', 'user', 'status', 'jobTitle','activity','activeProjects','icons']
    }
  ],
}
export const COL_SMALL_SCREEN =['user', 'status','icons']
export const COL_MEDIUM_SCREEN =['user', 'status','icons']
export const COL_LARGE_SCREEN =['user', 'status', 'activity', 'jobTitle','icons']
export const COL_XL_SCREEN =['id', 'user', 'status', 'activity', 'jobTitle','accessdept','sections','icons']

export const COMPANY_FILTERS = {
  projects: 'Projects',
  filters: 'FILTERS',
  clearFilters: 'Clear Filters'
}
export const COMPANY_USERS_FILTER_TYPES = {
  activeprojects: 'activeprojects',
  status: 'status',
  user:'user',
  jobtitle:'jobtitle',
  id:'id'
}
export const GO_TO_DROPDOWN=[
  {
    'id':1,
    'name':"User"
  }
]
export const PROJECT={
  projects:'Projects',

}
export const PROJECT_LIST = {
  companyname: "Company Name",
  id: 'ID #',
  projects: 'Projects',
  projectname: 'Project Name',
  companyaddress: 'Company Address',
  contactinfo: 'Contact Information',
  companyuser: 'Company Users',
  creditstatus: 'Company Status',
  adduser: 'Add a User',
  viewall: 'View All',
  valid: 'Valid Until 12/12/24',
  current: 'Current',
  editcompany: 'Edit Company',
  addcompany: 'Add a Company',
  portaltype: 'customer',
  modalType: 'edit',
  addProject:'Add a Project',
  internaluse:'FOR INTERNAL USE ONLY',
  companytype:'Company Type',
  enablecompany:'Enable Company',
  disablecompany:'Disable Company',
  addcustomeruser: 'Add a Customer User',
  addproject:'Add a Project',
  allcompanies:'All companies',
  addnewproject:'Add A New Project',
  companytitle:'Company'
};

export const VIEW_ALL_USERS_TYPES = {
  userId: 'id',
  users: 'users',
  status: 'status',
  jobTitles: 'jobTitles',
  user: 'firstname',
  jobTitle: 'jobTitle'
}

export const VIEW_ALL_USERS_CONSTANTS = {
  addUser: '+ Add a User',
  filters: 'FILTERS:',
  company: 'COMPANY:',
  companyText: 'A Brand New Production Company',
  clearFilters: 'Clear Filters',
  allProjects: 'All Projects',
  projectUsers: 'Project Users',
  addUserTitle: 'Add A User',
  EditUserTitle: 'Edit User',
  placeholder: 'Last Login',
  errorMessage: 'Error fetching users:'
}

export const STUDIO_PROJECT_USERS_LIST_CONSTANTS = {
  all: 'All',
  active: 'Active',
  deactivated: 'Deactivated',
  admin: 'Admin',
  companyAdmin: 'Company admin',
  activeStatus: 'ACTIVE',
  deactivatedStatus: 'DEACTIVATED',
  statusActive: 'Y',
  statusDeactive: 'N',
  allAccess: 'ALL',
  statusSuccess: 'success',
  deactivateAccount: 'deactivate-account',
  activateAccount: 'activate-account',
  removeAccount: 'remove-account',
  seven: '7',
}

export const COMPANY_CREDIT_STATUS=[
  {'id':1,'credit_status':'Current'},
  {'id':2,'credit_status':'Not Available'},
  {'id':3,'credit_status':'Needs Renewal'},
]

export const VALIDATION_MSG={
  required:'This field is required',
  postalcode:'Please enter a valid ZIP/Postal Code',
  phone:'Please enter a valid Phone Number',
  firstname:'First Name is not valid',
  lastname:'Last Name is not valid',
  email:'Please enter a valid email address',
  contact:'At least one contact is required.',
  companyName:'Company Name is not valid',
  characterLimitHundred: 'Name must be within 100 characters',
  fedTaxId:'Please enter a valid Federal Tax ID #'
}
export const CONFIRM_COMP_POPUP={
  yes:'Yes',
  no:'No',
  enablecompany:'Do you want to enable this company?',
  disablecompany:'Do you want to disable this company?',
  successEnable: 'Company Enabled Successfully',
  successDisable: 'Company Disabled Successfully',
  errorEnable: 'Company Enabled Error',
  errorDisable: 'Company Disabled Error'
}
export const PROJECT_DETAILS_CONSTANTS = {
  allProjects: 'All Projects',
  projectId: 'PROJECT ID #:',
  ar: 'A/R #:',
  dates: 'DATES:',
  description: 'DESCRIPTION',
  editDescription: 'Edit project description',
  addUser: 'Add A User',
  company: 'COMPANY:',
  companyText: 'A Brand New Production Company',
  type: 'TYPE:',
  customer: 'CUSTOMER:',
  primaryPayment: 'PRIMARY PAYMENT:'
};

export const ADD_USER_CONSTANTS = {
  addUser: 'Add A User',
  editUser: 'Edit User',
  close: 'Close',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  jobTitle: 'Job Title',
  optionalSettings: 'Optional Settings',
  departmentAccess: 'Department Access',
  sectionAndPermissions: 'SECTION AND PERMISSIONs',
  access: 'ACCESS',
  projects: 'PROJECTS',
  serviceRequest: 'Service Request',
  creditApplication: 'CREDIT APPLICATION',
  manageUsers: 'MANAGE USERS'
};

export const EDIT_DESCRIPTION_CONSTANTS = {
  editProject: 'Edit Project',
  description: 'Project Description',
  save: 'Save Changes',
  discard: 'Discard Changes',
  close: 'Close',
  bookmarkProject: 'Bookmark Project',
  removeBookmark: 'Remove Bookmark',
  editProjectStatus: 'Edit Project Status',
  editProjectInformation: 'Edit Project Information',
  notStarted: 'Not Started',
  active: 'Active',
  closed: 'Closed',
  modalEdit: 'Edit',
  modalOk: 'Ok',
  addNewProject: 'Add A New Project',
  saveStatus: 'Save Status',
  cancel: 'Cancel'
};

export const PROJECT_DETAILS_MODAL_CONTENT = {
  scheduleNotes: 'Schedule Notes',
  datesAreNotFlexible: 'Dates are not flexible',
  editProjectStatus: 'Edit Project Status',
  additionalProjectDetails: 'Additional Project Details',
  televisionProjectCameraSetup: 'TELEVISION PROJECT CAMERA SETUP:',
  multiCam: 'Multi Cam',
  programmingSchedule: 'PROGRAMMING SCHEDULE:',
  singleEpisodePilot: 'Single Episode/Pilot',
  editProject: 'Edit A Project',
  noData: 'No Data Available'
}

export const MESSAGE = {
  addCompanySuccessMessage: "Company Added Successfully",
  editCompanySuccessMessage: "Company Updated Successfully",
  addCompanyErrorMessage: "Error while adding company",
  editCompanyErrorMessage:"Error while updating company",
  enableCompanySuccessMessage: "Company Enabled Successfully",
  disableCompanySuccessMessage: "Company Disabled Successfully",
  enableCompanyErrorMessage: "Error while enabling company",
  disableCompanyErrorMessage:"Error while disabling company",
  errorCompanies: 'Error Retriving Company',
  companyUserDeactivated: 'Company User Deactivated Successfully',
  companyUserActivated: 'Company User Activated Successfully',
  errorCompanyUser: 'Error Retriving Company Users',
  duplicateFedId: 'Duplicate Federal Tax Id',
  companyUserAddSuccess:'Company User Added Successfully',
  companyUserEditSuccess: 'Company User Updated Successfully'
}


export const PROJECTS_MODAL = {
  addUserTitle: 'Add A User',
}
export const COMPANY_ADD_TYPE_ID = {
  productioncompanyAddressTypeId: 1,
  billingcompanyAddressTypeId: 2,
}

export const NEW_PROJECT = {
  addUser: 'Add A New Project',
  editUser: 'Edit A New Project',
  close: 'Close',
  company: 'Company',
  type: 'TYPE:',
  companyText: 'COMPANY:',
  companyName: 'A Brand New Production Company',
  changeCompany: 'Change Company',
  openCompany: 'Open Company',
  selectCompany: 'Select Company',
  projectName: 'Project Name',
  addProject: 'Add Project',
  addAndGo: 'Add and go to Project',
  search: 'Search',
  cancel: 'Cancel',
  select: 'Select',
  placeholder: 'Search company name or company ID #',
  searchLabel: 'Company',
  saveChanges: 'Save Changes'
}

export const NEW_PROJECT_DETAILS = {
  projectDetails: 'Project Details',
  projectType: 'Project Type',
  feature: 'Feature',
  television: 'Television',
  liveProduction: 'Live Production/Rehearsal',
  commercial: 'Commercial',
  stillShoot: 'Still Shoot',
  specialEvent: 'Special Event',
  other: 'Other',
  type: 'Type',
  seasonNumber: 'Season Number',
  cameraSetup: 'TELEVISION PROJECT CAMERA SETUP:',
  singleCam: 'Single Cam',
  multiCam: 'Multi Cam',
  variety: 'Variety',
  programmingSchedule: 'Programming schedule:',
  singleEpisode: 'Single Episode/Pilot',
  series: 'Series/Multi Episodes',
  season: 'Season #',
  season1: '1',
  season2: '2',
  season3: '3',
  season4: '4',
  projectDescription: 'Project Description:',
  anticipatedProjectDates: 'Anticipated Project Dates:',
  startDate: 'Start Date',
  endDate: 'End Date',
  scdeduledNotes: 'Schedule Notes',
  additionalscdeduledNotes: 'Additional Schedule Notes?'
}

export const NEW_PROJECT_COMPANY_TYPE = {
  companyType: 'Company Type',
  forInternal: 'For Internal use only',
  customerType: 'Customer Type',
  select: 'Select',
  projects: 'Projects',
  users: 'Users',
  spaces: 'Spaces & Resources',
  company: 'Company',
  primaryPaymentType: 'Primary Payment Type',

}

export const ERROR_MSG = {
  projectDetails: 'Error fetching Project Specific details list:',
  projectsList: 'Error fetching Projects List:',
  userNotAvailable: 'User Project ID not available',
  bookmarkAddError: 'Error creating the new bookmark',
  bookmarkRemoveError: 'Error removing the bookmark',
  statusUpdateError: 'Error Updating the project status',
  maxAllowedValue: 'Maximum allowed value is 99.',
  formError: {message: 'Please fill all the required fields'}
}

export const SEASONS = [
  {id: 1, season: 1},
  {id: 2, season: 2},
  {id: 3, season: 3},
  {id: 4, season: 4},
  {id: 5, season: 5},
  {id: 6, season: 6},
  {id: 7, season: 7},
  {id: 8, season: 8},
  {id: 9, season: 9},
  {id: 10, season: 10},
  {id: 11, season: 'Other'},
];

export const FED_TAX_VAL_POPUP = {
  existingMsg: 'We found an existing company with a similar name/federal tax ID',
  existingEditMsg: 'We found an existing company with a similar name/ identical Federal Tax ID:',
  addBtn: 'Add New',
  saveChanges: 'Save Changes',
  goToExistingBtn: 'Cancel and Go To Existing',
  cancel: 'Cancel'
}
export const ICON_ACTION_NAMES = {
  freez: 'freeze-account',
  deActivate: 'deactivate-account',
  activate: 'activate-account',
  disableCompany: 'disable-company',
  enableCompany: 'enable-company'

}
export const CUSTOM_ORDER = ['United States', 'United Kingdom', 'Canada'];

export const COL_DEF_FIELDS = {
  icons : 'icons',
  gotopage : 'goToPage'
}

export const DEFAULT_STATUS = {
  active : 'active',
  activeCapital : 'ACTIVE'
}


export const RESPONSE_STATUS = {
  success : 'success'
}