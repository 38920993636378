import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-review-summary',
  templateUrl: './credit-review-summary.component.html',
  styleUrls: ['./credit-review-summary.component.scss']
})
export class CreditReviewSummaryComponent {

}
