import { Component, HostListener } from '@angular/core';
import {
  ColDef,
  GridApi,
  GridOptions,
  ICellRendererParams,
} from 'ag-grid-community';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UserService } from 'src/app/feature/studio-portal/services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  public dropdownSettings: IDropdownSettings = {};

  ngOnInit() {
    this.dropdownList = [
      { item_id: 1, item_text: 'Project Name' },
      { item_id: 2, item_text: 'Project Name' },
      { item_id: 3, item_text: 'Project Name' },
      { item_id: 4, item_text: 'Project Name' },
      { item_id: 5, item_text: 'Project Name' },
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      showSelectedItemsAtTop: false,
    };
  }

  constructor(private userService: UserService) {}

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowHeight:60,
    // domLayout: 'autoHeight',
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      filter: true,
    },
  };

  rowData = [
    {
      id: 12345,
      fullName: 'Dan Acorn',
      email: 'colettealfaro@gmail.com',
      requestType: '',
      status: 'ACTIVE',
      updatedDate: '1/11/24',
      additionalTime: '10:00AM',
      jobTitle: 'Costume Assistant',
      company: 'Duis mollisest non commodo',
    },
    {
      id: 12345,
      fullName: 'Lexi Allan',
      email: 'eryamille@sunshineproductions.com',
      requestType: '',
      status: 'DEACTIVATED',
      updatedDate: '1/11/24',
      additionalTime: '10:00AM',
      jobTitle: 'Costume Assistant',
      company: 'Duis mollisest non commodo',
    },
    {
      id: 12345,
      fullName: 'Maggie Bash',
      email: 'colettealfaro@gmail.com',
      requestType: '',
      status: 'DEACTIVATED',
      updatedDate: '1/11/24',
      additionalTime: '10:00AM',
      jobTitle: 'Costume Assistant',
      company: 'WBSO',
    },
    {
      id: 12345,
      fullName: 'Nuha Hodgson',
      email: 'colettealfaro@gmail.com',
      requestType: 'system admin',
      status: 'ACTIVE',
      updatedDate: '1/11/24',
      additionalTime: '10:00AM',
      jobTitle: 'Costume Assistant',
      company: 'WBSO',
    },
    {
      id: 12345,
      fullName: 'Farhan Blevins',
      email: 'colettealfaro@gmail.com',
      requestType: '',
      status: 'ACTIVE',
      updatedDate: '1/11/24',
      additionalTime: '10:00AM',
      jobTitle: 'Costume Assistant',
      company: 'WBSO',
    },
    {
      id: 12345,
      fullName: 'Lin ANg',
      email: 'colettealfaro@gmail.com',
      requestType: '',
      status: 'DEACTIVATED',
      updatedDate: '1/11/24',
      additionalTime: '10:00AM',
      jobTitle: 'Costume Assistant',
      company: 'WBSO',
    },
    {
      id: 12345,
      fullName: 'Erya Milnex`',
      email: 'colettealfaro@gmail.com',
      requestType: '',
      status: 'ACTIVE',
      updatedDate: '1/11/24',
      additionalTime: '10:00AM',
      jobTitle: 'Costume Assistant',
      company: 'Duis mollisest non commodo',
    },
    {
      id: 12345,
      fullName: 'Colette Alfaro',
      email: 'colettealfaro@gmail.com',
      requestType: '',
      status: 'ACTIVE',
      updatedDate: '1/11/24',
      additionalTime: '10:00AM',
      jobTitle: 'Costume Assistant',
      company: 'Duis mollisest non commodo',
    },
    {
      id: 12345,
      fullName: 'Colette Alfaro',
      email: 'colettealfaro@gmail.com',
      requestType: 'account payable',
      status: 'ACTIVE',
      updatedDate: '1/11/24',
      additionalTime: '10:00AM',
      jobTitle: 'Costume Assistant',
      company: 'Duis mollisest non commodo',
    },
    {
      id: 12345,
      fullName: 'Colette Alfaro',
      email: 'colettealfaro@gmail.com',
      requestType: '',
      status: 'DEACTIVATED',
      updatedDate: '1/11/24',
      additionalTime: '10:00AM',
      jobTitle: 'Costume Assistant',
      company: 'Duis mollisest non commodo',
    },
    {
      id: 12345,
      fullName: 'Colette Alfaro',
      email: 'colettealfaro@gmail.com',
      requestType: 'company admin',
      status: 'ACTIVE',
      updatedDate: '1/11/24',
      additionalTime: '10:00AM',
      jobTitle: 'Costume Assistant',
      company: 'WBSO',
    },
    {
      id: 12345,
      fullName: 'Colette Alfaro',
      email: 'colettealfaro@gmail.com',
      requestType: '',
      status: 'ACTIVE',
      updatedDate: '1/11/24',
      additionalTime: '10:00AM',
      jobTitle: 'Costume Assistant',
      company: 'Duis mollisest non commodo',
    },
    {
      id: 12345,
      fullName: 'Colette Alfaro',
      email: 'colettealfaro@gmail.com',
      requestType: '',
      status: 'ACTIVE',
      updatedDate: '1/11/24',
      additionalTime: '10:00AM',
      jobTitle: 'Costume Assistant',
      company: 'Duis mollisest non commodo',
    },
  ];

  colDefs: ColDef[] = [
    {
      headerName: 'ID#',
      field: 'id',
      cellClass: 'common-cell-data-bold',
      minWidth: 53,
      flex: 1.4,
    },
    {
      headerName: 'User',
      field: 'user',
      filter: 'agTextColumnFilter',
      filterParams: { alwaysShowFilter: true },
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const fullName = params.data.fullName;
        const email = params.data.email;
        const requestType = params.data.requestType;

        let requestTypeClass = '';
        if (requestType === 'system admin') {
          requestTypeClass = 'system-admin';
        } else if (requestType === 'account payable') {
          requestTypeClass = 'account-payable';
        } else if (requestType === 'company admin') {
          requestTypeClass = 'company-admin';
        }

        return `
          <span class="common-split-cell-section">
            <span class="common-split-cell-data-1">${fullName}</span>
            <span class="common-split-cell-data-2">${email}</span>
            <span class="common-studio-user-request-label ${requestTypeClass}">${requestType}</span>
          </span>
        `;
      },
      minWidth: 256,
      flex: 4.2,
    },
    {
      headerName: 'USER',
      field: 'merged-field',
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const fullName = params.data.fullName;
        const email = params.data.email;
        const requestType = params.data.requestType;
        const status = params.data.status;
        const statusClass = this.getStatusClass(status);

        let requestTypeClass = '';
        if (requestType === 'system admin') {
          requestTypeClass = 'system-admin';
        } else if (requestType === 'account payable') {
          requestTypeClass = 'account-payable';
        } else if (requestType === 'company admin') {
          requestTypeClass = 'company-admin';
        }

        return `
          <span class="common-split-cell-section">
            <span class="common-split-cell-data-1">${fullName}</span>
            <span title="${email}" class="common-split-cell-data-2 ellipsis-email">${email}</span>
             <span class="common-studio-user-request-label ${requestTypeClass}">${requestType}</span>
            <span class="common-split-cell-data-4">
              <span class="request-status-tag ${statusClass} mt-common-ag-grid-contant">${status}</span>
            </span>
          </span>
        `;
      },
      minWidth: 160,
      hide: true,
    },
    {
      headerName: 'STATUS',
      field: 'status',
      cellRenderer: this.statusCellRenderer.bind(this),
      minWidth: 135,
      flex: 1.8,
    },
    {
      headerName: 'Last Login',
      field: 'activity',
      filter: false,
      cellRenderer: (params: ICellRendererParams) => {
        const date = params.data.updatedDate;
        const additionalTime = params.data.additionalTime;
        return `<span class="common-split-cell-section">
                  <span class="common-split-cell-data-default-font common-split-cell-data-1">last:${date}</span>
                  <span class="common-split-cell-data-2">${additionalTime}</span>
                </span>`;
      },
      minWidth: 138,
      flex: 2,
    },
    {
      headerName: 'JOB TITLE',
      field: 'jobTitle',
      flex: 2.2,
      cellClass: 'common-cell-data-bold',
      minWidth: 200,
    },
    {
      headerName: 'COMPANY',
      field: 'company',
      cellClass: 'common-cell-data-mt account-user-active-project-cell',
      minWidth: 68,
      flex: 2.5,
    },
    {
      field: 'icons',
      cellRenderer: () => {
        return `
          <div class="icon-container">
            <span class="icon-hover" title="Forget Password">
              <img src="/assets/images/account-freezed-icon.svg" alt="Forget Password Icon"/>
            </span>
            <span class="icon-hover" title="Deactivate">
              <img src="/assets/images/user-deactivate-icon.svg" alt="Deactivate Icon"/>
            </span>
            <span class="icon-hover" title="Remove">
              <img src="/assets/images/user-remove-icon.svg" alt="Remove Icon"/>
            </span>
          </div>`;
      },
      headerName: '',
      filter: false,
      minWidth: 110,
    },
  ];

  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.value);
    return `
      <div class="status-container">
        <div class="request-status-tag ${statusClass}">${params.value}</div>
      </div>
    `;
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'ACTIVE':
        return 'user-status-tag active account-user-common-status-cell mt-common-ag-grid';
      case 'DEACTIVATED':
        return 'user-status-tag deactivated account-user-common-status-cell mt-common-ag-grid';
      default:
        return '';
    }
  }

  gridApi!: GridApi;
  pageSizeOptions: number[] = [10, 20, 50];
  startRow: number = 1;
  endRow: number = 10;
  totalRows: number = this.rowData.length;

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.updatePagination();
  }

  onPageSizeChanged(event: any) {
    const value = event.target.value;
    this.gridApi.paginationSetPageSize(Number(value));
    this.updatePagination();
  }

  onBtNext() {
    this.gridApi.paginationGoToNextPage();
    this.updatePagination();
  }

  onBtPrevious() {
    this.gridApi.paginationGoToPreviousPage();
    this.updatePagination();
  }

  updatePagination() {
    if (this.gridApi) {
      const currentPage = this.gridApi.paginationGetCurrentPage();
      const pageSize = this.gridApi.paginationGetPageSize();
      this.startRow = currentPage * pageSize + 1;
      this.endRow = Math.min((currentPage + 1) * pageSize, this.totalRows);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustColumns(event.target.innerWidth);
  }

  adjustColumns(screenWidth: number) {
    if (this.gridApi) {
      const allColumnIds: string[] = [];
      this.gridApi.getAllGridColumns().forEach((column) => {
        allColumnIds.push(column.getColId());
      });

      if (screenWidth <= 375) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['user', 'status', 'icons'], true);
      } else if (screenWidth > 375 && screenWidth <= 510) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['user', 'status', 'icons'], true);
      } else if (screenWidth > 511 && screenWidth <= 992) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(
          ['user', 'status', 'activity', 'jobTitle', 'icons'],
          true
        );
      } else {
        this.gridApi.setColumnsVisible(allColumnIds, true);
      }
    }
  }
}
