import { Component, ViewChild, ElementRef, AfterViewInit, TemplateRef } from '@angular/core';
import {
  COMPANY
} from '../../constants/customer-portal.constant';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddCompanyUserComponent } from '../../../../shared/components/add-company-user/add-company-user.component';
import { AddEditCompanyComponent } from '../../../../shared/components/add-edit-company/add-edit-company.component';
import { Router } from '@angular/router';
import { CompanyService } from '../../services/company/company.service';
import { LoaderService } from '../../../../shared/services/loader/loader.service';
import { ToastService } from 'src/app/shared/components/toast.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent {

  constructor(private modalService: NgbModal, private router: Router,
    private companyService: CompanyService, private loaderService: LoaderService,
    private toastService: ToastService
     ) { }

  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  modalReference: NgbModalRef;
  company = COMPANY;
  company_view_info: any = {};
  companyId: any;
  companyError: boolean = false;
  companyUserMetaData: any;
  countryMetadata: any;
  addUserSuccess: any;
  addUserError: any;
  permissionsData: any;

  ngOnInit(): void {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.permissionsData = { ...userProfile.roleAssigned };
    this.companyId = userProfile.companyId;
    this.getCompany();
    this.getCompanyUserMetaData();
    this.getCountryMetaData();
  }


/**
* getCompany() - getCompany method gives the logged in user allocated company information.
*/

  getCompany(): void {
    this.loaderService.setLoadingState(true);
    this.companyService.getCompanyDetail(this.companyId).subscribe({
      next: (data: any) => {
        this.company_view_info = data?.company;
        if (this.company_view_info.companyAddresses?.length > 1) {
          this.company_view_info.companyAddresses = this.company_view_info.companyAddresses.map(address =>
            address.companyAddressType.companyAddressTypeId === 1 ?
              this.company_view_info.companyAddresses.find(a => a.companyAddressType.companyAddressTypeId === 1) :
              address.companyAddressType.companyAddressTypeId === 2 ?
                this.company_view_info.companyAddresses.find(a => a.companyAddressType.companyAddressTypeId === 2) :
                address
          );
        }
        setTimeout(() => {
          this.loaderService.setLoadingState(false)
        }, 1000);

      },
      error: (error: any) => {
        this.companyError = true;
        this.addUserError = error;
        this.showDanger(this.dangerTpl);
        console.log(error);
        setTimeout(() => {
          this.loaderService.setLoadingState(false)
        }, 1000);
      }
    })
  }


/**
*addUser() - invoke the add user modal popup
*/
 
  addUser(): void {
    let title = this.company?.adduser;
    let metaData = this.companyUserMetaData;
    let portalType = this.company?.portaltype;
    let modalType = this.company?.modalAdd;
    let componentType = this.company?.componenttype;
    window.scrollTo(0,0);
    this.modalReference = this.modalService.open(AddCompanyUserComponent, {windowClass: 'common-modal-xl ',
     fullscreen: true
    });
    this.modalReference.componentInstance.title = title;
    this.modalReference.componentInstance.metaData = metaData;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.modalType = modalType;
    this.modalReference.componentInstance.componentType = componentType;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data.status == this.company.success) {
          this.addUserSuccess = data?.message;
          this.showSuccess(this.successTpl);
        } else {
          this.addUserError = data?.message;
          this.showDanger(this.dangerTpl);
        }
      }
    });
  }

    /**
  * showSuccess() - Displays a success toast notification with custom content and options.
  * @param successTpl - The template reference containing the toast's content.
  */
    showSuccess(successTpl: TemplateRef<any>) {
      this.toastService.show(successTpl, {
        classname: 'custom-toast',
        delay: 3000,
      });
    }
    /**
       * showDanger() - Displays a danger (error) toast message.
       * This method is called when there is an error in adding the user.
       * @param dangerTpl - The template reference for the danger message to display.
       */
    showDanger(dangerActionTpl: any) {
      this.toastService.show(dangerActionTpl, {
        classname: 'bg-danger text-light',
        delay: 3000,
      });
    }

/**
* navigateToProjectDetails() - Method to navigate to the project list page.
*/

  navigateToProjectDetails(): void {
    this.router.navigate(['feature/customer-dashboard/projects-list']);
  }

  /**
*getCompanyUserMetaData() - getCompanyUserMetaData method gives list of project, department and other information that helps
 * to add the user in company.
*/

  getCompanyUserMetaData(): void {
    this.companyService.getCompanyUserMetaData(this.companyId).subscribe({
      next: (data: any) => {
        this.companyUserMetaData = data?.companyUserMetaData;
      },
      error: (error: any) => {
        console.error(error);
      }
    })
  }

  /**
*getCompanyUserMetaData() - getCompanyUserMetaData method gives list of project, department and other information that helps
* to add the user in company.
*/

  getCountryMetaData(): void {
    this.companyService.getCountryMetaData(this.companyId).subscribe({
      next: (data: any) => {
        this.countryMetadata = data?.companyMetaData;
      },
      error: (error: any) => {
        console.error(error);
      }
    })
  }

/**
*editCompany() - invoke the edit modal to edit the company information.
*/

  editCompany(): void {
    let title = this.company?.editcompany;
    let portalType = this.company?.portaltype;
    let modalType = this.company?.modalEdit;
    window.scrollTo(0,0);
    this.modalReference = this.modalService.open(AddEditCompanyComponent, {
      windowClass: 'common-modal-xl ',fullscreen: true  
 
    });
    this.modalReference.componentInstance.title = title;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.modalType = modalType;
    this.modalReference.componentInstance.companyData = this.company_view_info;
    this.modalReference.componentInstance.metaData = this.countryMetadata;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data.status == this.company.success) {
          this.addUserSuccess = data?.message;
          this.showSuccess(this.successTpl);
          this.getCompany();
        } else {
          this.addUserError = data?.message;
          this.showDanger(this.dangerTpl);
        }
      }
    });

  }

}
