import { Injectable } from '@angular/core';
import { GLOBAL_API_PATHS, STUDIO_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';
import { BaseApiService } from '../base-api/base-api.service';
import { BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  constructor(private baseApiService: BaseApiService) { }
  private valueSource = new BehaviorSubject<any>(null);
  currentValue = this.valueSource.asObservable();

  changeValue(value: any) {
    this.valueSource.next(value);
  }

  deactivateUser(userProfileId, loggedInId, status) {
    let endpoint = `${GLOBAL_API_PATHS.deactivate_user}?userProfileId=${userProfileId}&loggedInId=${loggedInId}&status=${status}`;
    return this.baseApiService.put(endpoint, userProfileId);
  }

  activateUser(userProfileId, loggedInId, status) {
    let endpoint = `${GLOBAL_API_PATHS.activate_user}?userProfileId=${userProfileId}&loggedInId=${loggedInId}&status=${status}`;
    return this.baseApiService.put(endpoint, userProfileId);
  }

  /**
    * deactivateStudioProjectUser() - Method to call Deactivate the user by userId, status and loggedInId.
    * @returns - The API response containing the user is deactivate or not.
  */
  deactivateStudioProjectUser(userId, status, loggedInId) {
    let endpoint = `${GLOBAL_API_PATHS.deactivate_studio_project_user}?userId=${userId}&status=${status}&userLoggedInId=${loggedInId}`;
    return this.baseApiService.put(endpoint, userId);
  }

  /**
    * activateStudioProjectUser() - Method to call Activate the user by userId, status and loggedInId.
    * @returns - The API response containing the user is activate or not.
  */
  activateStudioProjectUser(userId, status, loggedInId) {
    let endpoint = `${GLOBAL_API_PATHS.activate_studio_project_user}?userId=${userId}&status=${status}&userLoggedInId=${loggedInId}`;
    return this.baseApiService.put(endpoint, userId);
  }

  /** deactivateCompanyUser - deactivateCompanyUser used to call deactivate company user api*/
  deactivateCompanyUser(userProfileId, loggedInId, status){
    let endpoint = `${STUDIO_PORTAL_API_PATHS.deactivate_studio_company_user}?userProfileId=${userProfileId}&userLoggedInId=${loggedInId}&status=${status}`;
    return this.baseApiService.put(endpoint, userProfileId);
  }

  /** activateCompanyUser - activateCompanyUser used to call activate company user api*/
  activateCompanyUser(userProfileId, loggedInId, status) {
    let endpoint = `${STUDIO_PORTAL_API_PATHS.activate_studio_company_user}?userProfileId=${userProfileId}&userLoggedInId=${loggedInId}&status=${status}`;
    return this.baseApiService.put(endpoint, userProfileId);
  }

  /** enableCompany - enableCompany used to call enable company api*/
  enableCompany(companyId) {
    let endpoint = `${STUDIO_PORTAL_API_PATHS.enable_company}?companyId=${companyId}`;
    return this.baseApiService.put(endpoint,companyId);
  
  }
  
  /** disableCompany - disableCompany used to call disable company api*/
  disableCompany(companyId) {
    let endpoint = `${STUDIO_PORTAL_API_PATHS.disable_company}?companyId=${companyId}`;
    return this.baseApiService.put(endpoint,companyId);
  
  }
  
  /** removeStudioProjectUser - used to remove project user*/
  removeStudioProjectUser(userProfileId, projectId, loggedInId) {
    let endpoint = `${GLOBAL_API_PATHS.remove_studio_project_user}?userProfileId=${userProfileId}&projectId=${projectId}&loggedInUserId=${loggedInId}`;
    return this.baseApiService.put(endpoint, userProfileId);
  }

   /** 
    * removeCustomerProjectUser() - method to remove project user
    * @param userProfileId - the user profile id
    * @param projectId - the project id
    * @param loggedInId - the logged in user id
    **/
   removeCustomerProjectUser(userProfileId, projectId, loggedInId) {
    let endpoint = `${GLOBAL_API_PATHS.remove_customer_project_user}?userProfileId=${userProfileId}&projectId=${projectId}&loggedInUserId=${loggedInId}`;
    return this.baseApiService.put(endpoint, userProfileId);
  }
}
