<div class="overview-wrapper">
	<div class="container overview-user-header-wrapper">
		<div class="row">
			<div class="user-greet-action-block">
				<div class="user-greeting-block">
					<h4 class="greeting-text">Hi, <span class="greet-user-name">Jodie</span></h4>
				</div>
				<div class="user-actions-block">
					<div class="d-flex ">
						<button class="common-btn-primary  btn">
							<img class="common-btn-primary-icon" src="../../assets/images/add.svg" alt="add" />
							<span> Request</span>
						</button>
						<button class="common-btn-primary  btn">
							<img class="common-btn-primary-icon" src="../../assets/images/add.svg" alt="add" />
							<span> User</span>
						</button>
						<button class="common-btn-primary  btn">
							<img class="common-btn-primary-icon" src="../../assets/images/add.svg" alt="add" />
							<span>Credit Application</span>
						</button>
						<button class="common-btn-primary  btn">
							<img class="common-btn-primary-icon" src="../../assets/images/add.svg" alt="add" />
							<span>Pass</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container overview-user-requests-wrapper">
		<div class="row">
			<!-- Left section with 3 TABLES -->
			<div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mt-4 order-2 order-lg-1 order-md-2 order-sm-2">
				<div class="common-panel-block panel panel-default panel-no-project-block d-none">
					<div class="panel-heading">
					</div>
					<div class="panel-body no-project-section d-flex align-items-center justify-content-center">
						<p class="text-center">
							Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
							Contact your <span class="text-link">administrator</span> to be added to a project.
						</p>
					</div>
				</div>
				<div class="comman-panel-block panel panel-default panel-request-block">
					<div class="panel-heading">
						<h2>Requests</h2>
					</div>
					<div class="panel-body ">
						<div class="container panel-request-block-body">
						<ag-grid-angular #requestGrid style="width: 100%; height: 100%;"
								class="ag-theme-custom no-header" [gridOptions]="gridOptions"
								[columnDefs]="colDefsRequest" [rowData]="rowDataRequest"
								(gridReady)="onGridReady($event)">
							</ag-grid-angular>
						</div>
					</div>
					<div class="panel-footer panel-footer-sm">
						<div class="panel-footer-content ">
							<a href="#" class="text-link"> View All Requests</a>
						</div>
					</div>
				</div>
				<div class="comman-panel-block panel panel-default panel-passes-block">
					<div class="panel-heading">
						<h2>Passes Expected Today</h2>
					</div>
					<div class="progress_container d-flex justify-content-center">
						<!-- First circular progress bar -->
						<div class="pass-status-widgets widget-grey">
							<div class="pass-status-widgets-values">36</div>
							<div class="pass-status-widgets-labels">All <span
									class="pass-status-widgets-labels-sm">Passes</span>
							</div>
						</div>
						<div class="progress_container_arrow"><img alt="keyboard-right-arrow"
								src="../../assets/images/keyboard-arrow-right.svg" /></div>
						<!-- Second circular progress bar -->
						<div class="pass-status-widgets widget-darkblue">
							<div class="pass-status-widgets-values">15</div>
							<div class="pass-status-widgets-labels"><img alt="keyboard-right-arrow"
									src="../../assets/images/car.svg" /><span
									class="pass-status-widgets-labels-sm">Drive On</span>
							</div>
						</div>
						<div class="progress_container_arrow"><img src="../../assets/images/keyboard-arrow-right.svg" />
						</div>
						<!-- Third circular progress bar -->
						<div class="pass-status-widgets widget-darkblue">
							<div class="pass-status-widgets-values">21</div>
							<div class="pass-status-widgets-labels"><img alt="man-icon"
									src="../../assets/images/man.svg" /><span class="pass-status-widgets-labels-sm">Walk
									On</span></div>
						</div>
					</div>
					<div class="comman-tabs-block">
						<ul class="nav" role="tablist">
							<li class="nav-item">
								<a class="nav-link active" aria-current="page" data-bs-toggle="tab"
									href="#space-resource-tabpanel-0" role="tab" aria-selected="true">Next Hour</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" data-bs-toggle="tab" href="#credit-tabpanel-1" role="tab"
									aria-controls="fill-tabpanel-1">Today</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" data-bs-toggle="tab" href="#project-users-tabpanel-2" role="tab"
									aria-controls="fill-tabpanel-1">Checked In</a>
							</li>
						</ul>
					</div>
					<div class="panel-body table-responsive">
							<div class="container panel-passes-block-body">
								<ag-grid-angular #passesGrid style="width: 100%; height: 100%;"
									class="ag-theme-custom no-header" [gridOptions]="gridOptions"
									[columnDefs]="colDefsPasses" [rowData]="rowDataPasses"
									(gridReady)="onGridReady($event)">
								</ag-grid-angular>
							</div>
					</div>
					<div class="panel-footer">
						<div class="panel-footer-content ">
							<a class="text-link" href=""> View All Passes <img src="../../assets/images/link-icon.svg"
									alt="link" /></a>
						</div>
					</div>
				</div>
				<div class="comman-panel-block panel panel-default panel-credit-block">
					<div class="panel-heading">
						<h2>Credit Applications</h2>
					</div>
					<div class="panel-body">
						<div class="container panel-credit-block-body">
							<ag-grid-angular #creditGrid style="width: 100%; height: 100%;"
								class="ag-theme-custom no-header" [gridOptions]="gridOptions"
								[columnDefs]="colDefsCredit" [rowData]="rowDataCredit"
								(gridReady)="onGridReady($event)">
							</ag-grid-angular>
						</div>
					</div>
					<div class="panel-footer">
						<div class="panel-footer-content "><a class="text-link" href="">View Credit Application </a>
						</div>
					</div>
				</div>
			</div>
			<!--Widgets-->
			<div class=" col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-4 order-1 order-lg-2 order-md-1 order-sm-1">
				<div class="common-forecast-widget">
					<div class="card">
						<div class="card-header">
							Thursday, January 1
						</div>
						<div class="card-body">
							<ul class="list-inline common-forecast-widget-list">
								<li class="list-inline-item">
									<div class="weather-icon-block">
										<img class="imag-fluid" src="../../assets/images/weather-icon.svg"
											alt="weather-icon" class="weather-icon">
									</div>
								</li>
								<li class="list-inline-item list-middle-item ">
									<div class="weather-info">
										<p class="weather-city">Burbank, CA <span class="comman-location-btn">
												<button class="btn btn-primary rounded-button">
													<i class="bi bi-geo-alt"></i>
													<span class="ml-2">change</span>
												</button>
											</span> </p>
										<p class="weather-type">Partly Cloudy</p>
										<p class="weather-predication">Chance of Rain: 0%</p>
									</div>
								</li>
								<li class="list-inline-item">
									<div class="weather-temp">
										68°
										<p class="weather-sub-temp">70º/82º</p>
									</div>
								</li>
							</ul>
						</div>
						<div class="card-footer">
							<div class="view-forcast-block">
								<a class="view-forecast-link text-link" href="">View Forecast <img
										src="../../assets/images/link-icon.svg" alt="Arrow Icon" class="arrow-icon"></a>
							</div>
						</div>
					</div>
				</div>
				<div class="common-active-project-widget">
					<div class="card">
						<div class="card-header">
							<div class="card-header-title">Active Projects & Spaces</div>
						</div>
						<div class="card-body">
							<div class="active-project-title-block">
								<div class="active-project-image-wrapper">
									<div><img src="../../assets/images/project-image.png" alt="Project-image"
											class="active-project-image">
									</div>
									<div class="active-project-title">The Court-Season 1</div>
								</div>
								<div class="active-projectinfo-wrapper  mt-3">

									<div class="container px-0 mx-0">
										<div class="row g-3 px-0 mx-0">
											<div class="col-5 col-sm-3 col-md-3 border-end active-projects-details ">
												<p>Project ID:</p>
												<p>123456</p>
											</div>
											<div
												class="col-12 col-sm-6 col-md-6 border-end active-projects-details project-date-block">
												<p>Dates:</p>
												<p>1/29/24 - 2/24/24</p>
											</div>
											<div
												class="col-4  col-sm-3 col-md-3 active-projects-details project-ar-block">
												<p>A/R #</p>
												<p>123456</p>
											</div>
										</div>
										<div class="view-bookmark-link-section">
											<a class="text-link" href="">View Bookmarks & Project </a>
										</div>

									</div>
								</div>
								<div class="stage-office-wrapper">
									<div class="my-stage-block">
										<div class="card-subtitle">
											<div class="bookmark-icon-wrapper">
												<img class="bookmark-icon" src="../../assets/images/active-bookmark.svg"
													alt="bookmark">
											</div>
											<p class="pb-0 mb-0">My Stages:</p>
										</div>
										<div class="card-subtitle-info">A-Stage:</div>
										<div class="card-subtitle-datalist">
											<p class="card-text">Floor Plan.<img class="pdf-icon" alt="pdf-icon"
													src="../../../../assets/images/pdf-icon-active.svg" /></p>
											<p class="card-text">Technical Spec.<img class="pdf-icon" alt="pdf-icon"
													src="../../../../assets/images/pdf-icon-active.svg" /></p>
										</div>
									</div>
									<div class="my-office-block">
										<div class="card-subtitle">
											<div class="bookmark-icon-wrapper"><img class="bookmark-icon"
													alt="bookmark-icon" src="../../assets/images/active-bookmark.svg">
											</div>
											<p class="pb-0 mb-0">My Offices:</p>
										</div>
										<div class="card-subtitle-info">Office suite 3s Building 3.</div>
										<div>
											<div class="card-subtitle-datalist mb-4">
												<p class="card-text">Floor Plan Suit.<img class="pdf-icon"
														alt="pdf-icon"
														src="../../../../assets/images/pdf-icon-active.svg" /></p>

												<p class="card-text">Ground Floor Floorplan.<img class="pdf-icon"
														alt="pdf-icon"
														src="../../../../assets/images/pdf-icon-active.svg" /></p>
											</div>
										</div>
									</div>
								</div>
								<div class="card-footer common-active-project-footer">
									<a href="#" class="card-link text-link">View Project</a>
									<span class="card-link-divider"></span>
									<a href="#" class="card-link text-link">View All Project</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>