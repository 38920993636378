import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { onTheLot } from '../../../constants/customer-portal.constant';

@Component({
  selector: 'app-on-the-lot',
  templateUrl: './on-the-lot.component.html',
  styleUrls: ['./on-the-lot.component.scss'],
})
export class OnTheLotComponent implements OnInit{
  @Output() lotData = new EventEmitter<any>();
  private _response: any;
  selectedTabIndex: number = 0;
  userInfo = JSON.parse(localStorage.getItem('user-profile'));
  onTheLotCountryId;
  onTheLot = onTheLot;
  userProfile: any;
  permissionsData: any;

  @Input()
  set response(value: any) {
    this._response = value;
    console.log('Countries Data set:', this._response);
  }

  ngOnInit() {
    this.userProfile = JSON.parse(localStorage.getItem('user-profile'))
    this.permissionsData = { ...this.userProfile.roleAssigned };
    this.getTabIndex();
  }

  /**
   * getTabIndex() - to get the tab index from either local storage or from usersession
   */
  getTabIndex() {
    let index = localStorage.getItem('selectedTabIndexValue');
    if (index) {
      this.selectedTabIndex = +index;
    } else {
      this.onTheLotCountryId = this.userInfo.userPreferences?.onTheLotCountry?.onTheLotCountryId;
      if (this.onTheLotCountryId) {
        this.selectedTabIndex = this.onTheLotCountryId;
      }
    }
  }

  get countries(): any[] {
    return this._response ? this._response.countries : [];
  }

  /**
   * selectTab() - it sends the data to update the value
   * @param index - value of the use selected index
   */
  selectTab(index: number) {
    this.selectedTabIndex = index + 1;
    localStorage.setItem(
      'selectedTabIndexValue',
      this.selectedTabIndex.toString()
    );
    console.log(this.selectedTabIndex);
    this.lotData.emit(this.selectedTabIndex);
  }
}
