import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { HeaderService } from '../../../core/services/header/header.service';
import { UserProfileResponse } from 'src/app/shared/models/profileModel';
import { ProfileComponent } from './profile/profile.component';
import { USER } from '../../constants/common';
import { INDEX } from '../../enum/shared.enum';
import { ToastService } from '../toast.service';
import { SettingsService } from '../../services/settings/settings.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @ViewChild(ProfileComponent, { static: false })
  profileComponent!: ProfileComponent;

  selectedTab: string = USER.profileTab;
  user = USER;
  index = INDEX;
  profileInfo: any;
  profileDetails: any;
  metadata: any;
  response: any
  error: any;

  constructor(
    private headerService: HeaderService,
    private toastService: ToastService,
    private profileService: SettingsService 
  ) {}

  /**
   * selectTab() - Switches the currently selected tab to the specified tab.
   * @param tab - The identifier for the tab to switch to. This should match one of the predefined tab values used in the component
   */
  selectTab(tab: string): void {
    this.selectedTab = tab;
  }

  /**
   * onSelectChange() - Handles the change event for a select element and updates the selected tab.
   * @param event - The change event from the select element.
   */
  onSelectChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedTab = selectElement.value;
  }

  ngOnInit() {
    this.getProfile();
  }

  /**
   * getProfile() - Invoke the API call for the user profile.
   */
  getProfile(): void {
    let userProfileId = JSON.parse(localStorage.getItem('user-profile'));
    this.headerService
      .getuserprofile(userProfileId.userProfileId)
      .subscribe({
        next: (response: UserProfileResponse | null) => {
          if (response) {
            this.profileInfo = response.userProfileSearch;
            this.profileDetails = response.userProfileSearch.userPreferences;
            this.profileService.updateProfile(response);
          } else {
            console.warn(this.user.warn);
          }
        },
        error: (error) => {
          this.error=error
          console.error(this.user.error, error);
          this.showDanger(this.dangerTpl);
        },
      });
  }

  ngOndestroy() {
    this.profileInfo.unsubscribe();
  }

  /**
   * save() - Saves the user profile data to the server
   * @param data the user profile data to be saved
   * @param tab the tab that triggered the save action
   */
  save(data: any, tab: string): void {
    const observer = {
      next: (reponse:any) => {
        this.response= reponse
        this.showSuccess(this.successTpl);
        this.getProfile();
        this.markFormAsPristine(tab);
      },
      error: (error: any) => {
        this.error = error
        console.error(this.user.updateError, error);
        this.showDanger(this.dangerTpl);
      },
    };

    switch (tab) {
      case 'profile-tab':
      case 'settings-tab':
      case 'email-notifications-tab':
        this.headerService.updateuserprofile(data).subscribe(observer);
        break;
    }
  }

  /**
   * showSuccess() - Displays a success toast notification with custom content and options.
   * @param successTpl - The template reference containing the toast's content.
   */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.clear()
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerActionTpl: any) {
    this.toastService.clear();
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
   * markFormAsPristine() - Marks the specified form as pristine and untouched based on the provided tab name.
   * @param tab - The name of the tab whose form should be marked as pristine. Currently only supports 'profile-tab'.
   */
  markFormAsPristine(tab: string): void {
    switch (tab) {
      case 'profile-tab':
        this.profileComponent.profileForm.markAsPristine();
        this.profileComponent.profileForm.markAsUntouched();
        break;
    }
  }
}
