import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditFaqComponent } from '../../components/header/faqs/edit-faq/edit-faq.component';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<EditFaqComponent> {
  constructor(private modalService: NgbModal) {}

  canDeactivate(
    component: EditFaqComponent
  ): boolean | Promise<boolean> {

    if (component.hasUnsavedChanges) {
      return new Promise((resolve) => {
        const modalRef = this.modalService.open(component.navigateAwayModal, {
          windowClass: 'common-modal-center',
          centered: true,
          backdrop: 'static'
        });

        modalRef.result.then(
          (result) => {
            resolve(result);
          },
          () => {
            resolve(false);
          }
        );
      });
    }
    return true;
  }
}