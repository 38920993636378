import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/shared/services/base-api/base-api.service';
import { CUSTOMER_PORTAL_API_PATHS, GLOBAL_API_PATHS } from 'src/assets/constants/apiPaths';

@Injectable({
  providedIn: 'root'
})
export class ActiveProjectsService {

  constructor(private baseApiService: BaseApiService) { }

  getActiveProjects(userProfileId: Number,projectStatusId: Number) {
    const endpoint = `${CUSTOMER_PORTAL_API_PATHS.activeProjects}userProfileId=${userProfileId}&projectStatusId=${projectStatusId}`;
    return this.baseApiService.get(endpoint);
  }
}
