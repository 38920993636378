<button class="btn btn-primary" (click)="openConfirmationModal()">addd-edid-ar</button>

<ng-template #confirmationModal let-modal>
  <div class="page-wrapper-modal">
    <div class="page-header">
      <h2 class="mb-0">A/R Number</h2>
    </div>
    <div class="container">
      <div class="row justify-content-center text-center gap-2">
        <div class="col-2 custom-box">0</div>
        <div class="col-2 custom-box">0</div>
        <div class="col-2 custom-box">0</div>
        <div class="col-2 custom-box">0</div>
        <div class="col-2 custom-box">0</div>
        <div class="col-2 custom-box">0</div>
        
      </div>
    </div>
    <div class="d-flex align-self-end gap-2 mt-3">
      <button type="button" class="common-btn-light btn" (click)="modal.close()">
        Cancel
      </button>
      <button type="button" class="common-btn-secondary btn" (click)="handleAction()">
        Save
      </button>
    </div>
  </div>
</ng-template>
