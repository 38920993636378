<button data-bs-toggle="modal" data-bs-target="#addUserCustPortal">Open add user customer portal modal</button>
<!-- common modal section-->
<div class="modal fade" id="addUserCustPortal" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
<div class="modal-dialog modal-fullscreen">
  <div class="modal-content common-modal-xl">
    <div class="modal-body justify-content-center align-items-center">
      <div class="cm-header-section">
        <h5 class="modal-title" id="commonModalLabel">Add A User</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span class="cm-close-text">Close</span>
        </button>
      </div>
      <div class="container cm-main-container">
        <div class="common-modal-input-section">
          <div class="row">
            <div class="col-md-6 custom-floating-label">
              <label for="input1" class="form-label">First Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="First Name"
                id="input1"
              />
            </div>
            <div class="col-md-6 custom-floating-label">
              <label for="input2" class="form-label">Last Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Last Name"
                id="input2"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 custom-floating-label">
              <label for="input3" class="form-label">Email</label>
              <input
                required
                type="email"
                class="form-control"
                placeholder="Email"
                id="input3"
              />
            </div>
            <div class="col-md-6 custom-floating-label mb-0">
              <label for="input4" class="form-label">Job Title</label>
              <input
                type="text"
                class="form-control"
                placeholder="Job Title"
                id="input4"
              />
            </div>
          </div>
          <div class="row">
            <h2 class="form-section-header custom-margin mb-3 mt-4">
              Optional Settings
            </h2>
            <div class="col-md-6">
                <div class="common-checkbox-button">
                    <input
                      type="checkbox"
                      id="custom-checkbox-30"
                      class="common-checkbox-input"
                    />
                    <label for="custom-checkbox-30" class="common-checkbox-label">
                        Make the user a company admin
                    </label>
                  </div> 
            </div>

            <div class="col-md-6">
                <div class="common-checkbox-button">
                    <input
                      type="checkbox"
                      id="custom-checkbox-31"
                      class="common-checkbox-input"
                    />
                    <label for="custom-checkbox-31" class="common-checkbox-label">
                        User is an Accounts Payable user
                    </label>
                  </div> 
            </div>
          </div>
        </div>
        <div class="common-modal-input-section">
            <div class="row">
                <h2 class="form-section-header custom-margin mb-4 mt-0">
                    DEPARTMENT ACCESS
                  </h2>
                  <div class="col-md-12">
                    <div class="common-checkbox-button mb-4">
                    <input
                      type="checkbox"
                      id="custom-checkbox-0"
                      class="common-checkbox-input"
                    />
                    <label for="custom-checkbox-0" class="common-checkbox-label">
                      All
                    </label>
                  </div> 
                  </div>
              <div class="col-md-4 col-lg-4 col-sm-6 col-6 custom-floating-label">
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-1"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-1" class="common-checkbox-label">
                    Construction Services
                  </label>
                </div>
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-2"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-2" class="common-checkbox-label">
                    Facilities Maintenance
                  </label>
                </div>
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-3"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-3" class="common-checkbox-label">
                    Food Services
                  </label>
                </div>
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-4"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-4" class="common-checkbox-label">
                    Motion Picture Imaging
                  </label>
                </div>
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-5"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-5" class="common-checkbox-label">
                    Photo Lab
                  </label>
                </div>
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-6"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-6" class="common-checkbox-label">
                    Special Events
                  </label>
                </div>
                <div class="common-checkbox-button ">
                  <input
                    type="checkbox"
                    id="custom-checkbox-7"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-7" class="common-checkbox-label">
                    Transportation
                  </label>
                </div>
              </div>
              <div class="col-md-4 col-lg-4 col-sm-6 col-6 custom-floating-label">
                <div class="common-checkbox-button  mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-8"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-8" class="common-checkbox-label">
                    Costume
                  </label>
                </div>
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-9"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-9" class="common-checkbox-label">
                    Fire & Security
                  </label>
                </div>
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-10"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-10" class="common-checkbox-label">
                    Mail Services
                  </label>
                </div>
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-11"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-11" class="common-checkbox-label">
                    Office & Production
                  </label>
                </div>
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-12"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-12" class="common-checkbox-label">
                    Post Production Sound
                  </label>
                </div>
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-13"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-13" class="common-checkbox-label">
                    Staff Shop
                  </label>
                </div>
                <div class="common-checkbox-button">
                  <input
                    type="checkbox"
                    id="custom-checkbox-14"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-14" class="common-checkbox-label">
                    Utilities Services
                  </label>
                </div>
              </div>
              <div class="col-md-4 col-lg-4 col-sm-6 col-6 custom-floating-label">
                <div class="common-checkbox-button  mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-15"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-15" class="common-checkbox-label">
                    Design Studio
                  </label>
                </div>
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-16"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-16" class="common-checkbox-label">
                    First Aid
                  </label>
                </div>
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-17"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-17" class="common-checkbox-label">
                    Metal Shop
                  </label>
                </div>
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-18"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-18" class="common-checkbox-label">
                    Parking
                  </label>
                </div>
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-19"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-19" class="common-checkbox-label">
                    Property
                  </label>
                </div>
                <div class="common-checkbox-button mb-4">
                  <input
                    type="checkbox"
                    id="custom-checkbox-20"
                    class="common-checkbox-input"
                  />
                  <label for="custom-checkbox-20" class="common-checkbox-label">
                    Studio Operations
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="common-modal-input-section">
            <div class="row">
                <div class="d-flex justify-content-between">
                    <h2 class="form-section-header custom-margin mb-4 mt-0">
                    SECTION & PERMISSION
                  </h2>
                  <h2 class="form-section-header custom-margin mb-4 mt-0">
                    ACCESS
                  </h2>
                </div>
                <form action="">
                    <div class="main-acc-wrapper mb-4">
                      <div class="d-flex justify-content-between align-items-center">
                        <h3 class="acc-sec-header"> Projects </h3>
                        <div class="common-toggle-button">
                            <input type="checkbox" id="custom-toggle-4" class="common-toggle-input" checked>
                            <label for="custom-toggle-4" class="common-toggle-label">
                            </label>
                          </div>
                      </div>
                      <div class="col-12 col-sm-8 col-md-6 col-lg-4">
                        <!-- <div class="accordion accordion-flush" id="accordionFlushExample">
                          <div class="accordion-item">
                            <h2 class="accordion-header">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                <img src="../../../../assets/images/eye.svg" class="eye" alt="eye icon">Can View
                              </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse"
                              data-bs-parent="#accordionFlushExample">
                              <div class="accordion-body p-0">Placeholder content for this accordion.</div>
                            </div>
                          </div>
                        </div> -->
                        <div ngbDropdown class="custom-user-permission-dropdown">
                          <button type="button" class="btn btn-outline-primary custom-dropdown-btn" id="dropdownForm1" ngbDropdownToggle>
                            <p><img src="../../../../assets/images/eye.svg" class="eye" alt="eye icon"> Can View</p> 
                          </button>
                          <div ngbDropdownMenu aria-labelledby="dropdownForm1" class="custom-dropdown-menu" >
                            <form class="px-4 py-3 custom-dropdown-form" ngbDropdownItem>
                              <img src="assets/images/tick-icon.svg" class="tick" alt="tick icon"><p class="dropdown-title"> Can View <img src="../../../../assets/images/eye.svg" class="eye" alt="eye icon"></p> 
                              <p class="dropdown-description">The User can view project information & booked spaces and resources limited to their department</p>
                            </form>
                            <div class="dropdown-divider"></div>
                            <form class="px-4 py-3 custom-dropdown-form" ngbDropdownItem>
                              <img src="assets/images/tick-icon.svg" class="tick" alt="tick icon"><p class="dropdown-title">Can Manage <img src="assets/images/edit1-icon.svg" class="eye" alt="eye icon"></p>
                              <p class="dropdown-description">The User can view project information & booked spaces and resources limited to their department</p>
                            </form>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    <div class="main-acc-wrapper mb-4">
                        <div class="d-flex justify-content-between align-items-center">
                          <h3 class="acc-sec-header">Service Request</h3>
                          <div class="common-toggle-button">
                              <input type="checkbox" id="custom-toggle-4" class="common-toggle-input" checked>
                              <label for="custom-toggle-4" class="common-toggle-label">
                              </label>
                            </div>
                        </div>
                      </div>
                      <div class="main-acc-wrapper mb-4">
                        <div class="d-flex justify-content-between align-items-center">
                          <h3 class="acc-sec-header">Credit Application</h3>
                          <div class="common-toggle-button">
                              <input type="checkbox" id="custom-toggle-4" class="common-toggle-input" checked>
                              <label for="custom-toggle-4" class="common-toggle-label">
                              </label>
                            </div>
                        </div>
                      </div>
                      <div class="main-acc-wrapper">
                        <div class="d-flex justify-content-between align-items-center">
                          <h3 class="acc-sec-header">Manage Users</h3>
                          <div class="common-toggle-button">
                              <input type="checkbox" id="custom-toggle-4" class="common-toggle-input" checked>
                              <label for="custom-toggle-4" class="common-toggle-label">
                              </label>
                            </div>
                        </div>
                      </div>
                  </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>