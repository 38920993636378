<div class="modal-body justify-content-center align-items-center">
  <div class="cm-header-section">
    <h5 class="modal-title" id="commonModalLabel">{{title}}</h5>
    <button type="button" class="btn-close" (click)="closeModal()">
      <span class="cm-close-text">{{ close }}</span>
    </button>
    <p class="cm-subtitle mb-2">{{selectedCompanyName ? selectedCompanyName : userDetails?.company}}
      <span *ngIf="(portalType === 'studio' && componentType === 'customer') && 
      (selectedCompanyId || isEditMode) && 
      !showSearchBar && 
      userDetails?.statusActiveInd === 'Y'" class="edit-icon" (click)="editCompanySelection()">
        <img src="../../assets/images/edit-icon.svg" alt="Edit" style="cursor: pointer; margin-left: 10px;" />
      </span>
    </p>
  </div>
  <form [formGroup]="companyForm" (ngSubmit)="saveForm()">
    <div class="container cm-main-container">
      <div class="common-modal-input-section" *ngIf="showSearchBar && !searchComplete">
        <h2 class="section-main-header">Select the user company</h2>
        <app-search [placeholder]="placeholder" [userType]="userType" [usersList]="companyDataList"
          [disabled]="searchDisabled" [portalType]="portalType" [(searchTerm)]="searchBarInput"
          (searchComplete)="onSearchCompleted($event)">
        </app-search>
        <div class="d-flex justify-content-between" *ngIf="isEditMode || (selectedCompanyName && selectedCompanyId)">
          <div class="company-details">
            <ul class="list-group">
              <li class="list-group-item">
                <span class="company-name font-weight-bold">{{ selectedCompanyName }}</span>
              </li>
              <li class="list-group-item">
                <span class="company-id">{{ selectedCompanyId }}</span>
              </li>
            </ul>
          </div>
          <span (click)="resetSelection()" class="reset-link">Reset</span>
        </div>
      </div>
      <div class="mb-40 cm-btn-section" *ngIf="showSearchBar && !searchComplete">
        <button type="button" class="btn common-btn-secondary me-2" data-bs-dismiss="modal"
          (click)="proceedToForm()">{{nextAddUser}}</button>
      </div>


      <div class="common-modal-input-section" *ngIf="searchComplete || !showSearchBar">
        <div class="row">
          <div class="col-md-6 custom-floating-label">
            <label for="input1" class="form-label">{{addCompanyUser.firstname}}</label>
            <input type="text" class="form-control" placeholder="First Name" id="firstName" formControlName="firstName">
            <small class="text-danger"
              *ngIf="companyForm.get('firstName').invalid && (companyForm.get('firstName').touched || companyForm.get('firstName').dirty)">
              <span *ngIf="companyForm.get('firstName').errors['required']">{{ addCompanyUser.fieldRequired }}</span>
              <span *ngIf="companyForm.get('firstName').errors['invalidAlphabets']">{{ addCompanyUser.firstNameInvalid
                }}</span>
              <span *ngIf="companyForm.get('firstName').errors['multipleSpaces']">{{ addCompanyUser.twospaceerror
                }}</span>
              <span *ngIf="companyForm.get('firstName').errors['invalidSpace']">{{ addCompanyUser.spaceerror }}</span>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="input2" class="form-label">{{addCompanyUser.lastname}}</label>
            <input type="text" class="form-control" placeholder="Last Name" id="lastName" formControlName="lastName">
            <small class="text-danger"
              *ngIf="companyForm.get('lastName').invalid && (companyForm.get('lastName').touched || companyForm.get('lastName').dirty)">
              <span *ngIf="companyForm.get('lastName').errors['required']">{{ addCompanyUser.fieldRequired }}</span>
              <span *ngIf="companyForm.get('lastName').errors['invalidAlphabets']">{{ addCompanyUser.lastNameInvalid
                }}</span>
              <span *ngIf="companyForm.get('lastName').errors['multipleSpaces']">{{ addCompanyUser.twospaceerror
                }}</span>
              <span *ngIf="companyForm.get('lastName').errors['invalidSpace']">{{ addCompanyUser.spaceerror }}</span>
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 custom-floating-label">
            <label for="input3" class="form-label">{{addCompanyUser.email}}</label>
            <input required type="text" class="form-control" placeholder="Email" id="email" formControlName="email">
            <small class="text-danger"
              *ngIf="companyForm.get('email').invalid && (companyForm.get('email').touched || companyForm.get('email').dirty)">
              <span *ngIf="companyForm.get('email').errors['required']">{{ addCompanyUser.fieldRequired }}</span>
              <span *ngIf="companyForm.get('email').errors['invalidEmail']">{{ addCompanyUser.emailInvalid }}</span>
              <span *ngIf="companyForm.get('email').errors['invalidSpace']">{{ addCompanyUser.spaceerror }}</span>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="input4" class="form-label">{{addCompanyUser.jobtitle}}</label>
            <input type="text" class="form-control" placeholder="Job Title" id="jobTitle" formControlName="jobTitle">
            <small class="text-danger"
              *ngIf="companyForm.get('jobTitle').invalid && (companyForm.get('jobTitle').touched || companyForm.get('jobTitle').dirty)">
              <span *ngIf="companyForm.get('jobTitle').errors['required']">{{ addCompanyUser.fieldRequired }}</span>
              <span *ngIf="companyForm.get('jobTitle').errors['invalidJobTitle']">{{ addCompanyUser.jobTitleInvalid
                }}</span>
              <span *ngIf="companyForm.get('jobTitle').errors['multipleSpaces']">{{ addCompanyUser.twospaceerror
                }}</span>
              <span *ngIf="companyForm.get('jobTitle').errors['invalidSpace']">{{ addCompanyUser.spaceerror }}</span>
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 custom-floating-label mb-0">
            <label for="input4" class="form-label">Phone (optional)</label>
            <input type="text" class="form-control" placeholder="Phone No" id="Phone" maxlength="20"
              formControlName="contactNumber" (keydown)="restrictNonNumericInput($event)">
          </div>
        </div>
        <div class="row">
          <h2 class="form-section-header custom-margin">{{addCompanyUser.optional}}</h2>
          <div class="col-md-6 mb-2" *ngIf="permissionsData['makeCompanyAdmin'] ">
            <div class="common-checkbox-button">
              <input type="checkbox" id="companyAdmin" class="common-checkbox-input" formControlName="makeUser" />
              <label for="companyAdmin" class="common-checkbox-label">
                {{addCompanyUser.makeuser}}
              </label>
            </div>
          </div>
          <div class="col-md-6 mb-2">
            <div class="common-checkbox-button">
              <input type="checkbox" id="accountPayable" class="common-checkbox-input"
                formControlName="accountPayable" />
              <label for="accountPayable" class="common-checkbox-label">
                {{addCompanyUser.accountpayable}}
              </label>
            </div>
          </div>

        </div>
      </div>
      <div class="common-modal-input-section" *ngIf="searchComplete || !showSearchBar">
        <h2 class="section-main-header">
          {{addCompanyUser.project}}
        </h2>
        <div class="comman-tabs-block mt-5">
          <ul class="nav" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="project-tab" data-bs-toggle="tab" data-bs-target="#activeProjects"
                type="button" role="tab" aria-controls="activeProjects" aria-selected="true">
                {{addCompanyUser.active}} <span>({{activeProjectCount}})</span>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="closed-project-tab" data-bs-toggle="tab" data-bs-target="#closedProjects"
                type="button" role="tab" aria-controls="closedProjects" aria-selected="false">
                {{addCompanyUser.close}} <span>({{closedProjectCount}})</span>
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="activeProjects" role="tabpanel" aria-labelledby="project-tab">
              <div class="common-checkbox-img-list" *ngIf="activeProjectList.length > 0">
                <ul class="list-group">
                  <li class="list-group-item" *ngFor="let activeProject of activeProjectList">
                    <div class="d-flex flex-column flex-fill">
                      <div class="common-checkbox-button">
                        <input type="checkbox" [id]="'custom-checkbox-' + activeProject.projectId"
                          class="common-checkbox-input" formControlName="activeproject{{activeProject.projectId}}"
                          [value]="activeProject.projectId"
                          (change)="onCheckboxChange(activeProject, activeProject.projectId)" />
                        <label for="custom-checkbox-30" class="common-checkbox-label"
                          (click)="toggleCheckbox(activeProject.projectId,'active')">
                          <img src="{{activeProject.imageUrl}}"
                            onerror="this.src='../../../../../../assets/images/projects-small-img.svg'" alt=""
                            class="active-project-image img-fluid">
                          <span class="season-name">{{activeProject.projectName}}</span>
                        </label>
                      </div>
                      <div *ngIf="editIcon && companyForm.get('activeproject' + activeProject.projectId)?.value">
                        <div *ngIf="activeProject.departmentsIds.length == 0"
                          class="alert alert-danger common-alert-danger" role="alert">
                          <p>{{selectDepartment}}</p>
                        </div>
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-10">
                              <div class="dept-permission-info">
                                <div>
                                  <span>{{addCompanyUser.department}}:</span>
                                  <span *ngFor="let dept of activeProject.departmentsIds;let last = last;">
                                    {{dept.departmentName | titlecase}}<span *ngIf="!last">,</span>
                                  </span>
                                </div>
                                <div>
                                  <span>{{addCompanyUser.permissions}}:</span>
                                  <span *ngFor="let permission of activeProject.userRoleIds;let last = last;">
                                    <span *ngIf="permission.role == sectionInfo.dbvalue.projectView">
                                      {{sectionInfo.viewText.projectView}}
                                    </span>
                                    <span *ngIf="permission.role == sectionInfo.dbvalue.projectViewMange">
                                      {{sectionInfo.viewText.projectViewMange}}
                                    </span>
                                    <span *ngIf="permission.role == sectionInfo.dbvalue.creditViewMange">
                                      {{sectionInfo.viewText.creditViewMange}}
                                    </span>
                                    <span *ngIf="permission.role == sectionInfo.dbvalue.userViewMange">
                                      {{sectionInfo.viewText.userViewMange}}
                                    </span>
                                    <span *ngIf="!last">,</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 text-end" *ngIf="!isFormDisabled">
                              <div class="edit-icon">
                                <a class="text-link"
                                  (click)="addProjectPermission(activeProject.projectId, activeProject.projectName,activeProject)"
                                  [class.disabled]="isFormDisabled" tabindex="{{isFormDisabled ? -1 : 0}}">
                                  {{addCompanyUser.edit}}
                                </a>
                                <span><img src="../../../../assets/images/edit-icon.svg" alt="edit icon"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </li>
                </ul>
              </div>
              <div class="common-checkbox-img-list d-flex justify-content-center" *ngIf="activeProjectList.length == 0">
                <span>{{ addCompanyUser.noactiveproject }}</span>
              </div>
            </div>

            <div class="tab-pane fade" id="closedProjects" role="tabpanel" aria-labelledby="closed-project-tab">
              <div class="common-checkbox-img-list" *ngIf="closedProjectList.length > 0">
                <ul class="list-group">
                  <li class="list-group-item" *ngFor="let closedProject of closedProjectList">
                    <div class="d-flex flex-column flex-fill">
                      <div class="common-checkbox-button">
                        <input type="checkbox" [id]="'custom-checkbox-' + closedProject.projectId"
                          class="common-checkbox-input" formControlName="closedproject{{closedProject.projectId}}"
                          [value]="closedProject.projectId"
                          (change)="onCheckboxChange(closedProject, closedProject.projectId)" />
                        <label for="custom-checkbox-30" class="common-checkbox-label"
                          (click)="toggleCheckbox(closedProject.projectId,'closed')">

                          <img src="{{closedProject.imageUrl}}"
                            onerror="this.src='../../../../../../assets/images/projects-small-img.svg'" alt=""
                            class="active-project-image img-fluid">
                          <span class="season-name">{{closedProject.projectName}}</span>
                        </label>
                      </div>
                      <div *ngIf="editIcon && companyForm.get('closedproject' + closedProject.projectId)?.value">
                        <div *ngIf="closedProject?.departmentsIds?.length == 0"
                          class="alert alert-danger common-alert-danger" role="alert">
                          <p>{{selectDepartment}}</p>
                        </div>
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-10">
                              <div class="dept-permission-info">
                                <div>
                                  <span>{{addCompanyUser.department}}:</span>
                                  <span *ngFor="let dept of closedProject.departmentsIds;let last = last;">
                                    {{dept.departmentName | titlecase}}<span *ngIf="!last">,</span>
                                  </span>
                                </div>
                                <div>
                                  <span>{{addCompanyUser.permissions}}:</span>
                                  <span *ngFor="let permission of closedProject.userRoleIds;let last = last;">
                                    <span *ngIf="permission.role == sectionInfo.dbvalue.projectView">
                                      {{sectionInfo.viewText.projectView}}
                                    </span>
                                    <span *ngIf="permission.role == sectionInfo.dbvalue.projectViewMange">
                                      {{sectionInfo.viewText.projectViewMange}}
                                    </span>
                                    <span *ngIf="permission.role == sectionInfo.dbvalue.creditViewMange">
                                      {{sectionInfo.viewText.creditViewMange}}
                                    </span>
                                    <span *ngIf="permission.role == sectionInfo.dbvalue.userViewMange">
                                      {{sectionInfo.viewText.userViewMange}}
                                    </span>
                                    <span *ngIf="!last">,</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 text-end" *ngIf="!isFormDisabled">
                              <div class="edit-icon">
                                <a class="text-link"
                                  (click)="addProjectPermission(closedProject.projectId, closedProject.projectName,closedProject)"
                                  [class.disabled]="isFormDisabled" tabindex="{{isFormDisabled ? -1 : 0}}">
                                  <!-- Making it non-focusable if disabled -->
                                  {{addCompanyUser.edit}}
                                </a>
                                <span><img src="../../../../assets/images/edit-icon.svg" alt="edit icon"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="common-checkbox-img-list d-flex justify-content-center" *ngIf="closedProjectList.length == 0">
                <span class="no-project-msg">{{ addCompanyUser.noclosedproject }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cm-btn-section d-flex align-items-center justify-content-between"
        *ngIf="searchComplete || !showSearchBar">
        <div class="d-flex gap-2 align-items-center">
          <img class="back-img" src="../../assets/images/blue-left-icon.svg" alt="Back" /><a class="text-link lh-unset"
            (click)="goBackToSearch()">back </a>
        </div>

        <button type="submit" class="btn common-btn-secondary"
          [disabled]="(companyForm.invalid || isFormDisabled || isInitialState()) && companyChange">
          {{ isEditMode ? saveText : addUserText }}
        </button>
      </div>

    </div>
  </form>
</div>
<ng-template #confirmationModal let-modal>
  <div class="modal-body">
    <h4 class="section-main-header">
      {{ isEditMode ? saveChanges.message1 : saveChanges.message }}
    </h4>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-secondary btn" (click)="closeAllModal()">
        {{saveChanges.yes}}
      </button>
      <button type="button" class="common-btn-light btn" (click)="modal.close()">
        {{saveChanges.no}}
      </button>
    </div>
  </div>
</ng-template>