import { Component } from '@angular/core';
import { maps } from 'src/app/core/constants/core.constant';
import { NUMBER } from 'src/app/core/enum/core.enum';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent {
  constants = maps;
  number = NUMBER;
  portalType: string;
  selectedTab: string = this.constants.main_lot_tab;
  isAuthenticated: boolean = false;


  constructor(private authService: AuthService) {}

  /**
    * selectTab() - method to update the currently selected tab.
    * @param tab - The identifier for the tab to be selected. This is typically a string that corresponds to one of the available tabs.
   */
  selectTab(tab: string) {
    this.selectedTab = tab;
  }
  /**
   * onSelectChange() - method to render different tabs after clicking on the button 
   * @param event - The change event object associated with the select element.
  */
  onSelectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
  }


  ngOnInit(): void {
    this.checkAuthentication();
  }

  private async checkAuthentication(): Promise<void> {
    this.isAuthenticated = await this.authService.isAuthenticated();
    if (this.isAuthenticated) {
      const userProfileData = localStorage.getItem('user-profile');
      if (userProfileData) {
        const userProfile = JSON.parse(userProfileData);
        this.setPortalType(userProfile);
      }
    }
  }

  private setPortalType(userProfile: any): void {
    if (userProfile.userTypeId === 1) {
      this.portalType = "customer";
    } else if (userProfile.userTypeId === 2) {
      this.portalType = "studio";
    }
  }
}
