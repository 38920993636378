import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgGridModule } from 'ag-grid-angular';
import { environment } from 'src/environments/environment';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { FileUploadComponent } from './shared/components/file-upload/file-upload.component';
import { FeatureModule } from './feature/feature.module';
import { CoreModule } from './core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './errors/error/error.component';
import { TokenInterceptors } from './core/interceptors/token-interceptors';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';

const oktaAuth = new OktaAuth(environment.oktaConfig);
@NgModule({
  declarations: [
    AppComponent,
    MaintenanceComponent,
    FileUploadComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    OktaAuthModule,
    AppRoutingModule, 
    CoreModule,
    FeatureModule,
    HttpClientModule,
    AgGridModule,
    NgbModule,
    CommonModule
  ],
  providers: [{ provide: OKTA_CONFIG, useValue: { oktaAuth } },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptors, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },   ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
