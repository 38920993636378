import { FormControl } from '@angular/forms';
import { connect } from 'rxjs';

export const CREDIT_APPLICATION = {
  creditApplication: 'Credit Applications',
  viewCredit: 'View Credit Application',
};

export const PROJECTS = {
  projects: 'Projects',
  project: 'Project',
  filters: 'FILTERS:',
  clearFilters: 'Clear Filters',
  id: 'ID #',
  users: 'Users',
  spacesResources: 'Spaces And Resources',
  company: 'Company',
  noProjects: 'No Projects To Display',
  goTo: 'Go To…',
  placeholder: 'Dates',
  season: 'Season #',
  noProjectsCompany: 'There are no projects associated with this company'
}

export const ca_form_portaltype = {
  portalType1 : 'ar',
  portalType2:'studio'

}

export const USER_FILTER_TYPES = {
  ID: 'id',
  Users: 'user',
  JobTitle: 'jobtitle',
  userType: 'userType',
  Status: 'status',
  LastLogin: 'lastlogin',
  Company: 'comapny',
}


export const statusData = [
  { id: 1, text: 'Active' },
  { id: 3, text: 'Deactivated' }
];


export const USER_DROPDOWN_SETTINGS = {
  idField: "id",
  textField: "text",
  maxHeight: 250,
  noDataAvailablePlaceholderText: "No data available",
  noFilteredDataAvailablePlaceholderText: "No filtered data available",
  closeDropDownOnSelection: false,
  defaultOpen: false
}

export const PROJECT_FILTER_TYPES = {
  projectId: 'projectId',
  projects: 'projects',
  types: 'types',
  departments: 'departments',
  companies: 'companies',
  status: 'status',
  project: 'project',
  type: 'type',
  department: 'department',
  company: 'company'
}

export const PROJECT_DATES_DROPDOWN_LIST = {
  action: 'Action',
  otherAction: 'Another action',
  something: 'Something else here',
  separatedlink: 'Separated link',
  toggle: 'Toggle Dropdown',
};

export const PASSES_EXPECTED_TODAY = {
  passesExpectedToday: 'Passes Expected Today',
  all: 'All',
  passes: 'Passes',
  driveOn: 'Drive On',
  walkOn: 'Walk On',
  nextHour: 'Next Hour',
  today: 'Today',
  checkedIn: 'Checked In',
  viewAllPasses: 'View All Passes',
};

export const REQUEST = {
  request: 'Requests',
  viewAllRequest: 'View All Requests',
};

export const MENU = {
  projects: 'Projects',
  request: 'Requests',
  company: 'Company',
};

export const DASHBOARD = {
  request: 'Request',
  user: 'User',
  credit: 'Credit Application',
  pass: 'Pass',
};

export const BUTTON_TEXTS = {
  addWbUser: '+ Add a WB user',
  addCustomerUser: '+ Add a Customer User',
  clearAllFilters: 'Clear All Filters',
  filters: 'Filters:',
  save: 'Save',
  addUser: 'Add User',
  nextAddUser: 'Next: Add User',
  close : 'Close',
  reset :'Reset',
  users: 'Users'

};

// messages.constants.ts

export const ERROR_MESSAGE = {
  userDeactivated: 'This user is deactivated and cannot be edited',
  // Add other messages as needed
};


export const LABEL_TEXTS = {
  itemsPerPage: 'Items per page:',
  selectDepartments: 'Select department(s):',

};


export const ACTIVE_PROJECTS = {
  activeProjects: 'Active Projects & Spaces',
  viewBookMarks: 'View Bookmarks & Project',
  myStages: 'My Stages',
  myOffices: 'My Offices',
  viewProject: 'View Project',
  viewAll: 'View All Project'
};

export const PROJECT_DETAILS_SPACES = {
  mySpaces: 'My Spaces & Resources',
  spaces: 'Spaces & Resources',
  stages: 'STAGES',
  offices: 'OFFICES',
  workshops: 'WORKSHOP',
  description: 'DESCRIPTION'
};

export const PROJECT_USERS_COUNT = {
  projectUsers: 'Project Users',
  viewAll: 'View All',
  addUser: 'Add a user',
  users: 'USERS'
};

export const CREDIT_APPLICATION_CONSTANTS = {
  creditApplications: 'Credit Applications',
  startApplication: 'Start a Credit Application',
  allDepartments: 'All Departments'
};

export const EMAIL_NOTIFICATION = {
  emailNotification: 'Email Notification',
  crditApplication: 'Credit Application',
  applicationAndStatus: 'Credit Applications & their statuses',
  save: 'Save',
};

export const PROFILE = {
  myProfile: 'My Profile',
  firstName: 'First Name',
  timeZone: 'Time Zone',
  dateFormat: 'Date Format:',
  header: 'Header',
  save: 'Save',
  validatorRequired: 'First Name is required.',
  validatorTimeZone: 'Time Zone is Required',
  spaceValidator: 'First Name should not contain numbers or spaces.',
  discardChanges: 'Discard Changes',
  headerDescription:
    ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus mollis interdum.',
  dateFormat1: 'm/d/yyyy',
  dateFormat2: 'd/m/yyyy',
};

export const SETTINGS = {
  settings: 'Settings',
  timeZone: 'Time Zone:',
  validatorRequired: 'Time Zone is required',
  dateFormat: 'Date Format',
  calendarFormat: 'Calendar Format',
  startSunday: 'Starts on Sunday',
  startMonday: 'starts on monday',
  timeFormat: 'Time Format:',
  temperatureUnit: 'Preferred Temperature Unit:',
  fahrenheit: 'Fahrenheit',
  celsius: 'Celsius',
  save: 'Save',
  dateFormat1: 'm/d/yyyy',
  dateFormat2: 'd/m/yyyy',
  timeFormat1: '12h',
  timeFormat2: '24h',
  timeZoneDefault: '',
  calendarFormatDefault: 'sunday',
  fahrenheitDefault: 'fahrenheit',
};

export const TIMEZONES: string[] = ['UTC', 'GMT', 'EST', 'PST', 'CET'];

export const USER = {
  userName: 'User Name',
  profile: 'Profile',
  settings: 'Settings',
  emailNotifications: 'Email Notifications',
  profileTab: 'profile-tab',
  settingsTab: 'settings-tab',
  emailNotificationstab: 'email-notifications-tab',
  userActivatedMsg: 'User Status Updated Successfully',
  userDectivatedMsg: 'User Status Updated Successfully ',
  addUserSuccessMsg: 'Project User Added Successfully',
  updateUserSuccessMsg: 'Project User Updated Successfully',
  removeUserSuccessMsg: 'Project User Removed Successfully'
};

export const NO_NUMBERS_OR_SPACES_REGEX = /^(?!.*[\d\s]).*$/;
export function noNumbersOrSpaces(control: FormControl) {
  const value = control.value;
  if (value && !NO_NUMBERS_OR_SPACES_REGEX.test(value)) {
    return { invalidName: true };
  }
  return null;
}

export function hasError(controlName: string, errorType: string): boolean {
  const control = this.settingsForm.get(controlName);
  return control?.hasError(errorType) && (control?.touched || control?.dirty);
}

// app.constants.ts
export const RISK_CONSTANTS = {
  modalTitle: 'Credit Application',
  statusLabel: 'Status',
  closelabel: 'Close',
  formLabel: 'Form',
  documentLabel:'Document',
  projectLabel: 'PROJECT',
  companyLabel: 'Company',
  typeLabel: 'TYPE',
  applicationDateLabel: 'APPLICATION DATE',
  downloadPacketText: 'Download Packet',
  viewProjectsText: 'View Projects & Quotes',
  viewCompanyText: 'View Company',
  creditLimitLabel: 'Credit Limit',
  sentRiskEmailLabel: 'Sent Risk Email on',
  sentByLabel: 'Sent by',
  reasonsLabel: 'Reasons',
  departmentsLabel: 'Departments',
  commentsLabel: 'Comments',
  addCommentButtonText: 'Add Comment',
  tabs: {
    status: 'status',
    form: 'form'
  }

};

export const CREDIT_APP_CONSTANTS = {
  alldepartment: 'All Departments',
  allowedStatuses: {
    APPROVED_RISK: 'APPROVED (RISK)',
    APPROVED: 'APPROVED',
    DENIED: 'DENIED',
    PENDING_RISK: 'PENDING RISK',
    NEEDS_APPROVAL: 'NEEDS APPROVAL'

  },
  message: 'The current application is still under A/R review.',
  okButton: 'OK',
  title:'Credit Application with Risk',
  project:'Project: ',
  company:'Company: ',
  subtitle:'Your account is associated with multiple departments.',
  bodytitle:'Please select which department view you want to use to open the credit application and review the risk:',
  cancel:'Cancel',
  select:'Select'

};


export const STATUS_CLASSES = {
  'NEW APPLICATION': 'new-application need-app-width',
  'APPROVED': 'approved need-revision-width',
  'DENIED': 'denied',
  'NEEDS REVISIONS': 'revision need-revision-width',
  'NEEDS APPROVAL': 'need-approve need-revision-width',
  'REVISIONS SUBMITTED': 'new-application need-revision-width',
  'PENDING RISK': 'revision need-revision-width',
  'UNDER REVIEW': 'under-review need-revision-width',
  'APPROVED (RISK)': 'approved-risk need-revision-width',
};
// credit-app-details.constants.ts
export const CREDIT_APP_DETAILS_CONSTANTS = {
  titles: {
    status: 'Status',
    comments: 'Comments',
    departments: 'Departments:',
    selectedDepartments: 'selected Departments:'

  },
  labels: {
    creditLimit: 'Credit Limit',
    sentRiskEmail: 'Sent Risk Email on',
    sentBy: 'Sent by',
    reasons: 'Reasons',
    typeComment: 'Type your comment…'
  },
  buttons: {
    view: 'View',
    reviewRiskEmail: 'Review Risk Email',
    addDepartment: 'Add Department',
    addComment: 'Add Comment'
  }
};

export const CREDIT_APP_FORM_CONSTANTS = {
  sections: {
    lineOfCredit: {
      title: 'Line of Credit',
      labels: {
        requested: 'Line of Credit requested',
        jobTotal: 'Anticipated Job Total',
        months: 'Total # of months'
      }
    },
    letterOfCredit: {
      title: 'Letter of Credit/Guarantee',
      labels: {
        comments: 'Comments'
      }
    },
    company: {
      title: 'Company',
      labels: {
        name: 'Name',
        address: 'Address',
        phone: 'Phone',
        billingAddress: 'Use for billing address'
      },
      prodcompanyaddress: 'Production Company Address',
      billingaddress: 'Billing Address'
    },
    federalTaxId: {
      title: 'Federal Tax ID #',
      labels: {
        tin: 'TIN'
      }
    },
    accountPayable: {
      title: 'Account Payable Contact',
      labels: {
        name: 'Name',
        email: 'Email',
        phone: 'Phone'
      }
    },
    companyProfile: {
      title: 'Company Profile',
      labels: {
        profile: 'Profile',
        dateStarted: 'Date Started',
        businessType: 'Type of Business',
        annualSales: 'Total Annual Sales',
        netWorth: 'Net worth',
        employees: '# of Employees'
      }
    },
    principals: {
      title: 'Principals/Officers or Partners',
      labels: {
        name: 'Name',
        title: 'Title'
      }
    },
    bankingReferences: {
      title: 'Banking References',
      labels: {
        bank: 'Bank',
        accountNumber: 'Account Number',
        name: 'Name',
        title: 'Title',
        email: 'Email',
        phone: 'Phone'
      }
    },
    tradeReference: {
      title: 'Trade Reference',
      labels: {
        company: 'Company',
        contact: 'Contact',
        address: 'Address',
        email: 'Email',
        phone: 'Phone'
      }
    },
    termsConditions: {
      title: 'Terms & Conditions',
      labels: {
        name: 'Name',
        title: 'Title',
        signature: 'signature',
        date: 'Date'
      }
    },
    certificateOfInsurance: {
      title: 'Certificate of Insurance',
      labels: {
        producer: 'Producer',
        insured: 'Insured',
        address: 'Address'
      }
    },
    dbReport: {
      title: 'D&B Report',
      labels: {
        delinquency: 'Delinquency',
        failureScore: 'Failure Score',
        paydexScore: 'PAYDEX® Score'
      }
    }
  },
  common: {
    view: 'View',
    none: 'None'
  }
};

export const COMPANY = {
  companyname: "Sunshine Productions",
  companyid: 'Company ID #',
  projects: 'Projects',
  companyaddress: 'Production Company Address',
  billingaddress: 'Billing Address',
  contactinfo: 'Contact Information',
  companyuser: 'Company Users',
  creditstatus: 'Credit Status',
  adduser: 'Add a User',
  viewall: 'View All',
  valid: 'Valid Until',
  current: 'Current',
  editcompany: 'Edit Company',
  addcompany: 'Add a Company',
  portaltype: 'studio',
  modalType: 'edit',
  addProject: 'Add a Project',
  internaluse: 'FOR INTERNAL USE ONLY',
  companytype: 'Company Type',
  enablecompany: 'Enable Company',
  disablecompany: 'Disable Company',
  addcustomeruser: 'Add a Customer User',
  addproject: 'Add a Project',
  allcompanies: 'All Companies',
  addnewproject: 'Add A New Project',
  companytitle: 'Companies',
  companybtn: 'Company',
  enable: 'enable',
  disable: 'disable',
  notavailable: 'Not Available',
  modalEdit: 'edit',
  modalAdd: 'add',
  componentType: 'studio-company',
  companyerror: 'Company does not exist',
  editCustomerUser: 'Edit A Customer User',
  companyUserType: 'companyuser',
  federalTaxId: 'Federal Tax ID #',
  noUsers: 'No Users To Display'
};

export const COMPANY_USERS = {
  companyusers: 'Company Users',
  user: 'User',
  filter: 'Filters',
  adduser: 'Add a User',
};

export const PROJECT_USERS_LIST_CONSTANTS = {
  all: 'All',
  notVerified: 'Not Verified',
  inactive: 'Inactive',
  admin: 'Admin'
}

export const DropdownSettings = {
  id: "id",
  textField: "text",
  limitSelection: -1,
  maxHeight: 197,
  itemsShowLimit: 999999999999,
  searchPlaceholderText: "Search",
  noData: "No data available",
  noFilteredData: "No filtered data available",
}

export const PROJECT_DROPDOWN_SETTINGS = {
  idField: "id",
  textField: "text",
  maxHeight: 250,
  noDataAvailablePlaceholderText: "No data available",
  noFilteredDataAvailablePlaceholderText: "No filtered data available",
  closeDropDownOnSelection: false,
  defaultOpen: false
}

export const SORTING_CONSTANTS = {
  sort: "Sort:",
  mostRecent: "Most Recent",
  aToz: "A - Z"
}
export const GETSTATUS_CLASS = {
  active: 'ACTIVE',
  deactivated: 'DEACTIVATED',
  disabled: 'DISABLED'
}
export const COLS = {
  companies: [
    {
      colsForLargeScreen: ['user', 'status', 'goToPage', 'icons'],
      colsForSmallScreen: ['bella', 'iconsStatus'],
      colsForMediumScreen: ['bella', 'iconsStatus'],
      colsForExtraLargeScreen: ['compid', 'companyName', 'status', 'projects', 'active', 'users', 'phone', 'goToPage', 'icons']
    }
  ],
  company_user: [
    {
      colsForSmallScreen: ['user-status', 'icons'],
      colsForMediumScreen: ['user-status', 'icons'],
      colsForLargeScreen: ['user', 'status', 'activity', 'jobTitle', 'icons'],
      colsForExtraLargeScreen: ['userid', 'user', 'status', 'jobTitle', 'activity','phone', 'activeProjects', 'icons']
    }
  ],
}
export const COL_SMALL_SCREEN = ['user', 'status', 'icons']
export const COL_MEDIUM_SCREEN = ['user', 'status', 'icons']
export const COL_LARGE_SCREEN = ['user', 'status', 'activity', 'jobTitle', 'icons']
export const COL_XL_SCREEN = ['id', 'user', 'status', 'activity', 'jobTitle', 'accessdept', 'sections', 'icons']

export const COMPANY_FILTERS = {
  projects: 'Projects',
  filters: 'FILTERS',
  clearFilters: 'Clear Filters'
}
export const COMPANY_USERS_FILTER_TYPES = {
  activeprojects: 'activeprojects',
  status: 'status',
  user:'user',
  jobtitle:'jobtitle',
  id: 'id',
  userType: 'userType',
}
export const GO_TO_DROPDOWN = [
  {
    'id': 1,
    'name': "User"
  }
]
export const PROJECT = {
  projects: 'Projects',

}
export const PROJECT_LIST = {
  companyname: "Company Name",
  id: 'ID #',
  projects: 'Projects',
  projectname: 'Project Name',
  companyaddress: 'Company Address',
  contactinfo: 'Contact Information',
  companyuser: 'Company Users',
  creditstatus: 'Company Status',
  adduser: 'Add a User',
  viewall: 'View All',
  valid: 'Valid Until 12/12/24',
  current: 'Current',
  editcompany: 'Edit Company',
  addcompany: 'Add a Company',
  portaltype: 'customer',
  modalType: 'edit',
  addProject: 'Add a Project',
  internaluse: 'FOR INTERNAL USE ONLY',
  companytype: 'Company Type',
  enablecompany: 'Enable Company',
  disablecompany: 'Disable Company',
  addcustomeruser: 'Add a Customer User',
  addproject: 'Add a Project',
  allcompanies: 'All companies',
  addnewproject: 'Add A New Project',
  companytitle: 'Company'
};

export const VIEW_ALL_USERS_TYPES = {
  userId: 'id',
  users: 'users',
  userType: 'userType',
  status: 'status',
  jobTitles: 'jobTitles',
  user: 'firstname',
  jobTitle: 'jobTitle'
}

export const VIEW_ALL_USERS_CONSTANTS = {
  addUser: '+ Add a User',
  filters: 'FILTERS:',
  company: 'COMPANY:',
  companyText: 'A Brand New Production Company',
  clearFilters: 'Clear Filters',
  allProjects: 'All Projects',
  projectUsers: 'Project Users',
  addUserTitle: 'Add A User',
  EditUserTitle: 'Edit User',
  placeholder: 'Last Login',
  errorMessage: 'Error fetching users:',
  allUsers: 'All Users'
}

export const STUDIO_PROJECT_USERS_LIST_CONSTANTS = {
  all: 'All',
  active: 'Active',
  deactivated: 'Deactivated',
  admin: 'Admin',
  companyAdmin: 'Company admin',
  accountPayableUser: 'accounts payable',
  activeStatus: 'ACTIVE',
  deactivatedStatus: 'DEACTIVATED',
  statusActive: 'Y',
  statusDeactive: 'N',
  allAccess: 'ALL',
  statusSuccess: 'success',
  deactivateAccount: 'deactivate-account',
  activateAccount: 'activate-account',
  removeAccount: 'remove-account',
  seven: '7',
}

export const COMPANY_CREDIT_STATUS = [
  { 'id': 1, 'credit_status': 'Current' },
  { 'id': 2, 'credit_status': 'Not Available' },
  { 'id': 3, 'credit_status': 'Needs Renewal' },
]

export const VALIDATION_MSG = {
  required: 'This field is required',
  postalcode: 'Please enter a valid ZIP/Postal Code',
  phone: 'Please enter a valid Phone Number',
  firstname: 'First Name is not valid',
  lastname: 'Last Name is not valid',
  email: 'Please enter a valid email address',
  contact: 'At least one contact is required.',
  companyName: 'Company Name is not valid',
  maxCharCompanyName: 'Company name cannot exceed 50 characters.',
  characterLimitHundred: 'Name must be within 100 characters',
  fedTaxId: 'Please enter a valid Federal Tax ID #',
  jobtitle:'Job Title is not valid',
  streetInvalid: 'Street is not valid',
  cityInvalid: 'City is not valid',
  duplicateemail: 'This email address already exists, please enter a different email address.'
}
export const CONFIRM_COMP_POPUP = {
  yes: 'Yes',
  no: 'No',
  enablecompany:'Are you sure you want to enable this company?',
  disablecompany: 'Are you sure you want to disable this company?',
  successEnable: 'Company Enabled Successfully',
  successDisable: 'Company Disabled Successfully',
  errorEnable: 'Company Enabled Error',
  errorDisable: 'Company Disabled Error'
}
export const PROJECT_DETAILS_CONSTANTS = {
  allProjects: 'All Projects',
  projectId: 'PROJECT ID #:',
  ar: 'A/R #:',
  dates: 'DATES:',
  description: 'DESCRIPTION',
  editDescription: 'Edit project description',
  addUser: 'Add A User',
  company: 'COMPANY:',
  companyText: 'A Brand New Production Company',
  type: 'TYPE:',
  customer: 'CUSTOMER:',
  primaryPayment: 'PRIMARY PAYMENT:',
  imageError: 'Error during upload',
  projectImage: 'project',
  section: 'section',
  projectIdImage: 'projectId',
  file: 'file',
  myDashboard: 'My Dashboard',
  allCompanies: 'All Companies',
  project: 'project',
  maxSize: ['image/jpeg', 'image/png']
};

export const ADD_USER_CONSTANTS = {
  addUser: 'Add A User',
  editUser: 'Edit User',
  close: 'Close',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  jobTitle: 'Job Title',
  optionalSettings: 'Optional Settings',
  departmentAccess: 'Department Access',
  sectionAndPermissions: 'SECTION AND PERMISSIONs',
  access: 'ACCESS',
  projects: 'PROJECTS',
  serviceRequest: 'Service Request',
  creditApplication: 'CREDIT APPLICATION',
  manageUsers: 'MANAGE USERS'
};

export const EDIT_DESCRIPTION_CONSTANTS = {
  editProject: 'Edit Project',
  description: 'Project Description',
  save: 'Save Changes',
  discard: 'Discard Changes',
  close: 'Close',
  bookmarkProject: 'Bookmark Project',
  removeBookmark: 'Remove Bookmark',
  editProjectStatus: 'Edit Project Status',
  editProjectInformation: 'Edit Project Information',
  notStarted: 'Not Started',
  active: 'Active',
  closed: 'Closed',
  modalEdit: 'Edit',
  modalOk: 'OK',
  addNewProject: 'Add A New Project',
  saveStatus: 'Save Status',
  cancel: 'Cancel'
};

export const PROJECT_DETAILS_MODAL_CONTENT = {
  scheduleNotes: 'Schedule Notes',
  datesAreNotFlexible: 'Dates are not flexible',
  editProjectStatus: 'Edit Project Status',
  additionalProjectDetails: 'Additional Project Details',
  televisionProjectCameraSetup: 'TELEVISION PROJECT CAMERA SETUP:',
  multiCam: 'Multi Cam',
  programmingSchedule: 'PROGRAMMING SCHEDULE:',
  singleEpisodePilot: 'Single Episode/Pilot',
  editProject: 'Edit A Project',
  noData: 'No Data Available'
}

export const MESSAGE = {
  addCompanySuccessMessage: "Company Added Successfully",
  editCompanySuccessMessage: "Company Updated Successfully",
  addCompanyErrorMessage: "Error while adding company",
  editCompanyErrorMessage: "Error while updating company",
  enableCompanySuccessMessage: "Company Enabled Successfully",
  disableCompanySuccessMessage: "Company Disabled Successfully",
  enableCompanyErrorMessage: "Error while enabling company",
  disableCompanyErrorMessage: "Error while disabling company",
  errorCompanies: 'Error Retriving Company',
  companyUserDeactivated: 'Company User Deactivated Successfully',
  companyUserActivated: 'Company User Activated Successfully',
  errorCompanyUser: 'Error Retriving Company Users',
  duplicateFedId: 'Duplicate Federal Tax Id',
  companyUserAddSuccess: 'Company User Added Successfully',
  companyUserEditSuccess: 'Company User Updated Successfully',
  fileSizeExceedFiveMB: 'File size exceeds 5 MB',
  imageTypeSupportedMessage: 'The allowed file types are PNG, JPG/JPEG'
}


export const PROJECTS_MODAL = {
  addUserTitle: 'Add A User',
}
export const COMPANY_ADD_TYPE_ID = {
  productioncompanyAddressTypeId: 1,
  billingcompanyAddressTypeId: 2,
}

export const NEW_PROJECT = {
  addUser: 'Add A New Project',
  editUser: 'Edit A New Project',
  close: 'Close',
  company: 'Company',
  type: 'TYPE:',
  companyText: 'COMPANY:',
  companyName: 'A Brand New Production Company',
  changeCompany: 'Change Company',
  openCompany: 'Open Company',
  selectCompany: 'Select Company',
  projectName: 'Project Name',
  addProject: 'Add Project',
  addAndGo: 'Add & Go to Project',
  search: 'Search',
  cancel: 'Cancel',
  select: 'Select',
  placeholder: 'Search company name or company ID #',
  searchLabel: 'Company',
  saveChanges: 'Save Changes'
}

export const NEW_PROJECT_DETAILS = {
  projectDetails: 'Project Details',
  projectType: 'Project Type',
  feature: 'Feature',
  television: 'Television',
  liveProduction: 'Live Production/Rehearsal',
  commercial: 'Commercial',
  stillShoot: 'Still Shoot',
  specialEvent: 'Special Event',
  other: 'Other',
  type: 'Type',
  seasonNumber: 'Season Number',
  cameraSetup: 'TELEVISION PROJECT CAMERA SETUP:',
  singleCam: 'Single Cam',
  multiCam: 'Multi Cam',
  variety: 'Variety',
  programmingSchedule: 'Programming schedule:',
  singleEpisode: 'Single Episode/Pilot',
  series: 'Series/Multi Episodes',
  season: 'Season #',
  season1: '1',
  season2: '2',
  season3: '3',
  season4: '4',
  projectDescription: 'Project Description:',
  anticipatedProjectDates: 'Anticipated Project Dates:',
  startDate: 'Start Date',
  endDate: 'End Date',
  scdeduledNotes: 'Schedule Notes',
  additionalscdeduledNotes: 'Additional Schedule Notes?'
}

export const NEW_PROJECT_COMPANY_TYPE = {
  companyType: 'Company Type',
  forInternal: 'For Internal use only',
  customerType: 'Customer Type',
  select: 'Select',
  projects: 'Projects',
  users: 'Users',
  spaces: 'Spaces & Resources',
  company: 'Company',
  primaryPaymentType: 'Primary Payment Type',

}

export const ERROR_MSG = {
  projectDetails: 'Error fetching Project Specific details list:',
  projectsList: 'Error fetching Projects List:',
  userNotAvailable: 'User Project ID not available',
  bookmarkAddError: 'Error creating the new bookmark',
  bookmarkRemoveError: 'Error removing the bookmark',
  statusUpdateError: 'Error Updating the project status',
  maxAllowedValue: 'Maximum allowed value is 99.',
  numbersAllowed: 'Allowed values between 1 and 99',
  formError: { message: 'Please fill all the required fields' }
}

export const SEASONS = [
  { id: 1, season: 1 },
  { id: 2, season: 2 },
  { id: 3, season: 3 },
  { id: 4, season: 4 },
  { id: 5, season: 5 },
  { id: 6, season: 6 },
  { id: 7, season: 7 },
  { id: 8, season: 8 },
  { id: 9, season: 9 },
  { id: 10, season: 10 },
  { id: 11, season: 'Other' },
];

export const FED_TAX_VAL_POPUP = {
  existingMsg: 'We found an existing company with a similar name/federal tax ID',
  existingEditMsg: 'We found an existing company with a similar name/ identical Federal Tax ID:',
  addBtn: 'Add New',
  saveChanges: 'Save Changes',
  goToExistingBtn: 'Cancel and Go To Existing',
  cancel: 'Cancel'
}
export const ICON_ACTION_NAMES = {
  freez: 'freeze-account',
  deActivate: 'deactivate-account',
  activate: 'activate-account',
  disableCompany: 'disable-company',
  enableCompany: 'enable-company'

}
export const CUSTOM_ORDER = ['United States', 'United Kingdom', 'Canada'];

export const COL_DEF_FIELDS = {
  icons: 'icons',
  gotopage: 'goToPage',
  mergedUser: 'bella',
  mergedStatus: 'iconsStatus'
}

export const DEFAULT_STATUS = {
  active : 'active',
  activeCapital : 'ACTIVE',
  activeCamel : 'Active',
  activeId : 1,
  disabledId:2,
  disabled :'disabled',
  disabledCapital : 'DISABLED',
  disabledCamel : 'Disabled'

}


export const RESPONSE_STATUS = {
  success: 'success'
}
export const DATEPICKER_CONSTANTS = {
  lastLogin: "Last Login",
  dates: "Dates"
}

export const USERS_TYPES = {
  addModalType: 'add',
  editModalType: 'edit',
  componentType: 'viewAll'
}

export const STUDIO_DASHBOARD_CREDIT_APPLICATION = {
  creditApplication: 'Credit Applications',
  viewAllCreditApplications: 'View All Credit Applications',
  allDepartments: 'All Departments'
}

export const STUDIO_DASHBOARD_REQUESTS = {
  requests: 'Requests',
  viewAllRequests: 'View All Requests'
}

export const STUDIO_DASHBOARD_BOOKMARK_PROJECTS = {
  bookmarkProjects: 'Bookmarked Projects',
  goTo: 'Go To…',
  projects: 'Projects',
  users: 'Users',
  company: 'Company',
  active: 'Active',
  notStarted: 'Not Started',
  closed: 'Closed',
  one: '1',
  two: '2',
  three: '3'
}

export const STUDIO_CREDIT_APPLICATION = {
  sendCreditApplication: 'Send Credit Application Packet',
  company: 'Company',
  project: 'Project',
  departments: 'DEPARTMENTS',
  sendToAccountPayable: 'SEND TO ACCOUNT PAYABLE CONTACTS',
  addAnAccountPayable: 'Add an Accounts Payable User',
  cancel: 'Cancel',
  close: 'Close',
  sendToCustomer: 'Send to Customer',
  arName: 'A/R',
  attachQuotes: 'Attach Quotes',
  creditApplication: 'Credit Applications',
  sentToCustomer: 'Sent to Customer',
  statusSuccess: 'success',
  addUserTitle: 'Add A User',
  addCreditApplication: 'Add a Credit Application',
  addRequestAr: 'Request A/R #',
  thirdPartyCommercialHouse: '3rd Party Commercial House',
  current: 'Current',
  accountPayable: 'Error fetching account payable users:',
  successSentCreditApplication: 'The Credit Application has been sent',
  viewQuotes: 'View Quotes',
  viewQuote: 'View Quote',
  quotesForCreditApp: 'Quotes For Credit Application',
  waitingQuotesMsg: 'Waiting for a few more quotes from PPCS and Sound Stages',
  attachQuotesCancelMsg: 'Are you sure you want to close without uploading?',
  quotesUploadSuccessfully: 'Quotes have been uploaded',
  dragAndDrop: 'Drag and Drop your file here, or',
  browse: 'browse',
  addComment: 'ADD A COMMENT',
  upload: 'Upload',
  remove: 'Remove',
  view: 'View',
  successAttachQuotes: 'Quotes have been uploaded',
  sendCreditApplicationDataTextboxName: `Thank you for your interest in doing business with Warner Bros. Studio Operations. 
                                
Please ensure the credit application is completed in its entirety. Critical information required is as follows: 
                                        
Customer name, show name, billing address and customer Accounts Payable email 
address 
• Company Federal Tax ID (FEIN Number)  
• Experian account number, banking and trade references in the entertainment industry 
 With a valid fax or email address or attach a company credit reference sheet with the information. 
          - Unacceptable Trade References: hotels, professional services, payroll services, car rentals, Federal Express, Office Depot, etc. 
• Signed application by an authorized agent of the bank, dated, print name and title. 
• Certificate of Insurance  
                                        
The application will not be processed without the customer information requested above and signed by the authorized bank agent. 
                                       
Please do not send any credit card information with the WBSO credit packet. WBSO A/R does not accept credit card payments nor handles C.O.D. accounts. 
                                       
If you have any questions regarding the account process please contact us at WBSFAR@wbd.com 

                                       
Thank you again for your interest in Warner Bros. Studio Operations. We look forward to being of service to you.`,
  portaltype: 'A/R',
  uploadFrom: 'Upload from',
  at: 'at',
  by: 'By',
  chooseDepartment: 'Choose Department',
  valueError: 'Exceeded maximum value of $999,999,999.',
  requiredError: 'Quotes value is required.'
}
export const STUDIO_CREDIT_APPLICATION_STATUS = [
  { id: 1, text: 'Approved' },
  { id: 2, text: 'Approved (Risk)' },
  { id: 3, text: 'Denied' },
  { id: 4, text: 'New Application' },
  { id: 5, text: 'Needs Approval' },
  { id: 6, text: 'Needs Revisions' },
  { id: 7, text: 'Pending Risk' },
  { id: 8, text: 'Revisions Submitted' },
  { id: 9, text: 'Sent To Customer' },
  { id: 10, text: 'Under Review' },
]
export const STUDIO_STATUS = {
  approved: 'APPROVED',
  approvedRisk: 'APPROVED (RISK)',
  denied: 'DENIED',
  needsApproval: 'NEEDS APPROVAL',
  needsRevisions: 'NEEDS REVISIONS',
  newApplication: 'NEW APPLICATION',
  pendingRisk: 'PENDING RISK',
  revisionsSubmitted: 'REVISIONS SUBMITTED',
  sendToCustomer: 'SEND REVISIONS',
  sentToCustomer: 'SENT TO CUSTOMER',
  underReview: 'UNDER REVIEW',
}

export const NOTIFICATION_NOT_SENT = ['Approved', 'Approved (Risk)', 'Denied'];
export const PATTERN = {
  number: '^[0-9]+$'
}

export const ADD_DEPARTMENT_ARRAY = [
  { departmentId: 1, departmentName: 'Construction Services' },
  { departmentId: 2, departmentName: 'Facilities Maintenance' },
  { departmentId: 3, departmentName: ' Food Services' },
  { departmentId: 4, departmentName: 'Motion Picture Imaging' },
  { departmentId: 5, departmentName: ' Photo Lab' },
  { departmentId: 6, departmentName: 'Special Events' },
  { departmentId: 7, departmentName: 'Transportation' },
  { departmentId: 8, departmentName: 'Costume' },
  { departmentId: 9, departmentName: ' Fire & Security' },
  { departmentId: 10, departmentName: 'Mail Services' },
  { departmentId: 11, departmentName: 'Office & Production' },
  { departmentId: 12, departmentName: ' Post Production Sound' },
  { departmentId: 13, departmentName: ' Staff Shop' },
  { departmentId: 14, departmentName: ' Utilities Services' },
  { departmentId: 15, departmentName: 'Design Studio' },
  { departmentId: 16, departmentName: 'First Aid' },
  { departmentId: 17, departmentName: ' Metal Shop' },
  { departmentId: 18, departmentName: ' Parking' },
  { departmentId: 19, departmentName: ' Property' },
  { departmentId: 20, departmentName: ' Studio Operations' },

]
export const RISK_EMAIL_CONSTANTS = {
  title: 'Risk Email',
  bankreferance: 'Bank Reference:',
  tradereferance: 'Trade References:',
  dandb: 'D&B:',
  deliquencyscore: 'Deliquency Score:',
  failurescore: 'Failure Score:',
  paydexscore: 'PAYDEX® Score:',
  emailbody1: ' The customer has not met the WBSO Credit criteria and they are not associated with a major studio or on our approved guarantors list.',
  emailbody2: ' Please note that the customer has not been notified that a line of credit will not be granted. However, A/R wanted to verify with the departments would like to accept the risk for this customer for all your respective departments and expense any amount deemed uncollectible from each group.',
  emailbody3: ' Please with your approval along with your SVP approval if the department would like to accept the risk and the credit limit fo the customer. If A/R does not receive the approval from the departments, A/R will send the COD notification to the customer within 24 hours from sending the Customer Risk Approval - Financial Responsibility email.'
}

export const REQUEST_AR = {
  heading: 'Request Documents for new A/R',
  company: 'Company:',
  name: 'A Brand New Production Company',
  project: 'Project:',
  projectName: 'The New Show - Pilot',
  text: 'Nullam id dolor id nibh ultricies vehicula ut id elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor.',
  departments: 'DEPARTMENTS',
  send: 'SEND TO ACCOUNT PAYABLE CONTACTS:',
  link: 'Add an Accounts Payable User',
  cancelBtn: 'Cancel',
  statusSuccess: 'success',
  sendToCustomerBtn: 'Send to Customer',
  accountPayable: 'Error fetching account payable users:',
  addUserTitle: 'Add A User',
  successThirdpartyArApplication: 'Request Documents for new A/R # has been sent',
  sendRequestArTextboxName: 'Nullam id dolor id nibh ultricies vehicula ut id elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor',
  cancel: 'Cancel',
  button: 'Send to Customer',
}

export const RISK_EMAIL_TIER1_CONSTANTS = {
  title: 'Department Risk',
  project: 'PROJECT :',
  company: 'Comapny :',
  type: 'TYPE :',
  appdate: 'APPLICATION DATE :',
  acceptriskbefore: 'Accept Risk before',
  hiall: 'Hi All,',
  content1: 'A/R received a credit request for a new account from the following customer:',
  content2: 'The customer has not met the WBSO Credit criteria and they are not associated with a major studio or on our ',
  content3: 'Please note that the customer has not been notified that a line of credit will not be granted. However, A/R wanted to verify with the departments would like to accept the risk for this customer for all your respective departments and expense any amount deemed uncollectible from each group.',
  content4: 'Please respond with your approval along with your SVP approval if the department would like to accept the risk and the credit limit for the customer.',
  list: 'approved guarantors list.',
  content5: 'If A/R does not receive the approval from the departments, A/R will send the COD notification to the customer within 24 hours from sending the Customer Risk Approval - Financial Responsibility email.',
  departmentsselected: 'Departments Selected to be Used by Customer',
  creditlinerequested: 'Credit Line Requested',
  summaryevaluation: 'Summary Evaluation',
  bankreference: 'Bank Reference:',
  tradereferences: 'Trade References:',
  db: 'D&B:',
  deliquencyscore: '- Deliquency Score:',
  failurescore: '- Failure Score:',
  paydexscore: '- PAYDEX® Score:',


};

export const TIER1_DEPTSELECT={
  title:'Credit Application with Risk',
  project:'Project: ',
  company:'Company: ',
  subtitle:'Your account is associated with multiple departments.',
  bodytitle1:'Please select which department view you want to use to open the',
  bodytitle2:'credit application and review the risk:',
  cancel:'Cancel',
  select:'Select'


}
//Success message for the API
export const STUDIO_SUCCESS_APIMESSAGE = {
  //dashboard
  removeBookmark: 'Remove Bookmark Successfully',
  editProjectStatus: 'Project Status Updated Successfully',
  bookmarkProjectStatus: 'Bookmark Status Updated Successfully',
  removeBookamarkText: {message:'Remove Bookmark Successfully'},
  editProjectStatusInfo: 'Project Updated Successfully',
  
  //users

  //project

  //company

  //addcomment //packet
  errormsgpacket:'Download failed. Please try again.',
  successmsgpacket:'Document downloaded successfully.',
  sucessmsgdepartment:'Department added successfully.',
  errorwithoutinput: 'Please enter a comment before submitting.',
  errorwithmethod: 'There was an error while adding your comment. Please try again.',
  erroradding:'The department you are trying to add already exists. Please add a different department.'
}
// help.constants.ts
export const HELP_CONTENT = {
  title: 'List of acceptable guarantors:',
  list: {
    content: 'Abominable Pictures Inc.,Amblin Partners,Awesomeness,Blumhouse Productions LLC.($10k per show),DreamWorks,Electus LCC,FremantleMedia,Funny or Die($10k per show),IMG(WME-IMG),Lakeshore Entertainment,Legendary Pictures,Marvel,Lionsgate,MRC,Netflix,Starz Entertainment,Tristar Production Inc,STX Entertainment'
  },
  close: 'Close Help >',

};

export const CREDIT_APP_FILTER_TYPES = {
  company: 'company',
  projects: 'projects',
  department: 'department',
  status: 'status',
}

export const CONFIRM_CLOSE_POPUP = {
  confirmCompanyCloseMsg: 'Are you sure you want to close the company creation page without saving?',
  yesBtn: 'Yes',
  noBtn: 'No',
  confirmDiscardCompanyCloseMsg: 'Are you sure you want to discard changes?'
}

export const DASHBOARD_TEXT = {
  hi: 'Hi,',
  message: 'Here are the project activities you need to review'
}
export const STUDIO_THIRDPARTY_COMMHOUSE_CONSTANT = {
  creditApplicationTitle: 'Credit Application',
  creditApplicationLeftTitle: 'Credit Application',
  status: 'Status',
  documents: 'Documents',
  downloadPacket: 'Document Packet',
  companyInfo: 'Company Info',
  projectTitle: 'PROJECT:',
  project: 'The new show',
  companyTitle: 'Comapny:',
  company: 'A Brand New Production Company',
  typeTitle: 'TYPE:',
  type: 'Major Studio',
  applicationDateTitle: 'APPLICATION DATE:',
  applicationDate: '1/1/24',
  viewProjQuote: 'View Projects & Quotes',
  viewCompany: 'View Company',
  purchaseOrder: 'Purchase Order',
  poPdf: 'PO.pdf',
  view: 'View',
  certificateOfInsurance: 'Certificate of Insurance',
  coiPdf: 'COI.pdf',
  viewDocumentActivity: 'View Documents Activity',
  creditApplicationFormActivityTitle: 'Credit Application Form Activity',
  creditApplicationFormActivity: [
    {userIconText: 'AN', commentBy: 'Andrea Noj', timeStamp: 'xx/xx/xx xx:xxAM', activity: 'Reviewed Certificate of Insurance'},
    {userIconText: 'AN', commentBy: 'Andrea Noj', timeStamp: 'xx/xx/xx xx:xxAM', activity: 'Reviewed Certificate of Insurance'}
  ]
};

export const projectTypeData = [
  { id: 1, text: 'Feature' },
  { id: 2, text: 'Television' },
  { id: 3, text: 'Live Production/Rehearsal' },
  { id: 4, text: 'Commercial' },
  { id: 5, text: 'Still Shoot' },
  { id: 6, text: 'Special Event' },
  { id: 7, text: 'Other' },
];

export const PROJECT_TYPE = {
  feature: 'Feature',
  television: 'Television',
  liveProduction: 'Live Production/Rehearsal',
  commercial: 'Commercial',
  stillShoot: 'Still Shoot',
  specialEvent: 'Special Event',
  other: 'Other',
}

export const SP_CREDITAPP_DASHBOARD = {
  filters: 'FILTERS:',
  alertMessage: 'There are updates to the credit application(s) that need your review.',
  viewAll: 'View All',
  refresh: 'Refresh',
  newApplication: 'in New Application',
  revisionSubmitted: 'in Revisions Submitted',
  pendingApprobal: 'in Pending Approval.',
  popUp: 'Please use a wider screen to display the Credit Application',
  ok: 'OK',
  reload: 'reload',
  alldepartment: 'All Departments'
}
export const SP_CREDITAPP_STATUS = {
  newApplication: 'NEW APPLICATION',
  approved: 'APPROVED',
  denied: 'DENIED',
  needsRevisions: 'NEEDS REVISIONS',
  needsApproval: 'NEEDS APPROVAL',
  revisionsSubmitted: 'REVISIONS SUBMITTED',
  pendingRisk: 'PENDING RISK',
  underReview: 'UNDER REVIEW',
  approvedRisk: 'APPROVED (RISK)',
  sendToCustomer: 'SEND TO CUSTOMER',
  sentToCustomer: 'SENT TO CUSTOMER',
  startApplication: 'Start Application',
  finishApplication: 'Finish Application'
}

