<nav [class.sticky]="sticky" class="user-navbar navbar navbar-expand-lg navbar-light bg-light sticky-top d-none d-lg-flex">
  <div class="container">
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav mx-auto" id="navbarNav">
        <li class="nav-item" *ngFor="let link of finalresult">
          <a class="nav-link" routerLink="{{link.link}}" routerLinkActive="active" id="{{link.id}}">
            {{link.label}}
          </a>
        </li>
      </ul>
    </div>
  </div> 
</nav>
