import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CRED_FORM_ONE_CONSTANT, CRED_STEP_ONE_CONSTANT, CREDIT_APP_REPORT_SECTION, CREDIT_APP_TOAST_MESSAGES } from 'src/app/feature/customer-portal/constants/customer-portal.constant';
import { FormValidationService } from 'src/app/shared/services/form-validations/form-validation.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { CreditApplicationsService } from 'src/app/feature/customer-portal/services/credit-applications/credit-applications.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { NgbActiveModal, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ViewCreditAppServiceService } from 'src/app/shared/services/view-credit-app-service/view-credit-app-service.service';
import * as _ from 'lodash';
import { INDEX } from 'src/app/feature/customer-portal/enum/customer-portal.enum';

@Component({
  selector: 'app-cred-app-step-one',
  templateUrl: './cred-app-step-one.component.html',
  styleUrls: ['./cred-app-step-one.component.scss']
})
export class CredAppFormOneComponent implements OnInit {
  @Input() customerStatus: any;
  @Input() revisionData: any;
  @Input() states: any;
  @Input() creditAppStatus: string;
  @Input() projectId: number;
  @Input() startApplicationData: any;
  @Input() caSeqId: number;
  @Input() caNumber: number;
  @Output() nextStepEvent = new EventEmitter<void>();
  @Output() stepCompleted = new EventEmitter<number>();
  @Output() saveAndCloseEvent = new EventEmitter<void>();
  @ViewChild('fileInput') fileInput!: ElementRef;  // Reference to the file input element
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('successUploadFileTpl', { static: false }) successUploadFileTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  isFileOver = false;
  files: File[] = [];
  uploadedFiles: File[] = [];
  form: FormGroup;
  public successMsg: any;
  public errorMsg: any;
  toastMessages = CREDIT_APP_TOAST_MESSAGES;
  public helpSliderVisible: boolean = false;
  credFormOne = CRED_FORM_ONE_CONSTANT
  tr1fileName: string | null = null;
  tr1ErrorMessage: string | null = null;
  tr2fileName: string | null = null;
  tr2ErrorMessage: string | null = null;
  tr3fileName: string | null = null;
  tr3ErrorMessage: string | null = null; 
  tradeRefferenc1Data: any;
  tradeRefferenc2Data: any;
  tradeRefferenc3Data: any;
  credFormOneConstant = CRED_STEP_ONE_CONSTANT;
  letterOfCreditPDFDocument: any;
  userProfileId: any;
  applicationData;
  logResponseDetails;
  showBankReference: any;
  lineOfcredit: any;
  letterOfGuarantee: any;
  productionCompany: any;
  federalTax: any;
  accountPayable: any;
  companyProfile: any;
  principalsAndPartners: any;
  tradeReference1: any;
  tradeReference2: any;
  tradeReference3: any;
  isRevision:boolean = false;

  constructor(
    private fb: FormBuilder,
    private formValidationService: FormValidationService,
    private loaderService: LoaderService,
    private creditApplicationService: CreditApplicationsService,
    private toastService: ToastService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router,
    private sharedViewCreditAppService: ViewCreditAppServiceService) { }

  ngOnInit(): void {
    this.initializeForm();
    // this.checkCreditAppStatus();
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.userProfileId = userProfile.userProfileId;
    this.applicationData = this.startApplicationData;
    this.patchValueOnCustomerStatus();
    this.revisionRequiredStatus()
  }

  patchValueOnCustomerStatus(){
    if( this.customerStatus === this.credFormOneConstant.startApplication){
      this.initializeForm()
      }
      else if(this.customerStatus === this.credFormOneConstant.underRevision){
        this.disableFormControls();
        this.patchRevision()
      }
      else{
        this.disableFormControls();
         this.pathchValue()
      }
  }

  ngOnChanges() {
    this.patchValueOnCustomerStatus()
    this.revisionRequiredStatus()
    if(this.customerStatus === this.credFormOneConstant.underRevision){
       this.isRevision = true
    }
    this.applicationData = this.startApplicationData;
    this.caSeqId = this.caSeqId;
    this.caNumber = this.caNumber;
  }

  /**
   * revisionRequiredStatus() - to enable and disable based on review required status
   */
  revisionRequiredStatus(): void{
    if(this.customerStatus === this.credFormOneConstant.underRevision){
      const data = this.revisionData?.caRevisionDetails?.applicationFormDetail      
      this.showBankReference = data?.bankingReferencesDetails?.reviewRequired
      this.lineOfcredit = data?.lineOfCreditDetails?.reviewRequired
      this.letterOfGuarantee = data?.letterOfGuaranteeDetails?.reviewRequired;
      this.productionCompany = data?.productionCompanyAddressDetails[0]?.reviewRequired;
      this.federalTax = data?.productionCompanyAddressDetails[0]?.reviewRequired
      this.accountPayable = data?.accountsPayableContactsDetails[0]?.reviewRequired;
      this.companyProfile = data?.caCompanyProfileDetails?.reviewRequired;
      this.principalsAndPartners = data?.partnersDetails[0]?.reviewRequired;
      this.tradeReference1 = data?.tradeReference1Details?.reviewRequired;
      this.tradeReference2 = data?.tradeReference2Details?.reviewRequired;
      this.tradeReference3 = data?.tradeReference3Details?.reviewRequired;
      this.updateFormControls();
    }
  }

  /**
   * updateFormControls() - to update form controls based on review required
   */
  updateFormControls(): void {
    this.form
      .get('bankingReference')
      ?.[this.showBankReference ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();

      this.form
      .get('lineOfCredit')
      ?.[this.lineOfcredit ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();

      this.form
      .get('letterOfGuarantee')
      ?.[this.letterOfGuarantee ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();

      this.form
      .get('productionCompany')
      ?.[this.productionCompany ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();


      this.form
      .get('federalTax')
      ?.[this.federalTax ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();

      this.form
      .get('accountPayable')
      ?.[this.accountPayable ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form
      .get('companyProfile')
      ?.[this.companyProfile ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form
      .get('principalsAndPartners')
      ?.[this.principalsAndPartners ?this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
    
      this.form
      .get('tradeReference1')
      ?.[this.tradeReference1 ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form
      .get('tradeReference2')
      ?.[this.tradeReference2 ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form
      .get('tradeReference3')
      ?.[this.tradeReference3 ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
  }


   /**
   * patchRevision() - To patch revision details for step one
   */
   patchRevision(): void {
    const data = this.revisionData?.caRevisionDetails?.applicationFormDetails;
    const lineOfCreditGroup = this.form?.get('lineOfCredit') as FormGroup;
    lineOfCreditGroup?.patchValue({
      anticipatedJobTotal: data?.lineOfCreditDetails?.locJobTotal,
      lineOfCreditRequested: data?.lineOfCreditDetails?.locRequested,
      totalNoOfMonths: data?.locNumOfMonths,
    });
    const productionCompanyDetails = this.form?.get(
      'productionCompany'
    ) as FormGroup;
    productionCompanyDetails?.patchValue({
      name: this.revisionData?.caRevisionDetails?.companyDetails?.companyName,
      street: data?.productionCompanyAddressDetails[0]?.street,
      city: data?.productionCompanyAddressDetails[0]?.city,
      state: data?.productionCompanyAddressDetails[0]?.state?.stateId,
      zipcode: data?.productionCompanyAddressDetails[0]?.zip,
      phone: data?.productionCompanyAddressDetails[0]?.phone,
    });
    const federalTaxIn = this.form?.get('federalTax');
    federalTaxIn?.patchValue({
      federalTaxTin: data?.productionCompanyAddressDetails[0]?.fedralTaxNum,
    });
    const accountPayable = this.form?.get('accountPayable');
    accountPayable?.patchValue({
      apFirstName: data?.accountsPayableContactsDetails[0]?.firstName,
      apLastName: data?.accountsPayableContactsDetails[0]?.lastName,
      apEmail: data?.accountsPayableContactsDetails[0]?.emailAddress,
      apPhone: data?.accountsPayableContactsDetails[0]?.telephone,
    });
    const companyProfile = this.form?.get('companyProfile');
    companyProfile?.patchValue({
      cpProfile: data?.caCompanyProfileDetails?.caCpType,
      //  cpDateStarted: data?.caCompanyProfileDetails?.createdDate,
      cpTypeOfBusiness: data?.caCompanyProfileDetails?.caCpType,
      cpTotalAnnualsale: data?.caCompanyProfileDetails?.totalAnnualSales,
      cpNetworth: data?.caCompanyProfileDetails?.networth,
      cpNumOfEmployees: data?.caCompanyProfileDetails?.noOfEmployees,
    });
    const principalsAndPartners = this.form?.get('principalsAndPartners');
    principalsAndPartners?.patchValue({
      ppName: data?.partnersDetails[0]?.partnerName,
      ppTitle: data?.partnersDetails[0]?.partnerTitle,
    });
    const bankingReference = this.form?.get('bankingReference');
    bankingReference?.patchValue({
      brbankName: data?.bankingReferencesDetails?.bankName,
      brAccountNumber: data?.bankingReferencesDetails?.accountNumber,
      brContactName: data?.bankingReferencesDetails?.contactFullName,
      brTitle: data?.bankingReferencesDetails?.title,
      brEmail: data?.bankingReferencesDetails?.emailAddress,
      brPhone: data?.bankingReferencesDetails?.phoneNumber,
    });
    const tradeReference1 = this.form?.get('tradeReference1');
    tradeReference1?.patchValue({
      tr1CompanyName: data?.tradeReference1Details?.trCompanyName,
      tr1ContactFirstName: data?.tradeReference1Details?.trFirstName,
      tr1ContactLastName: data?.tradeReference1Details?.trLastName,
      tr1Email: data?.tradeReference1Details?.trEmail,
      tr1Phone: data?.tradeReference1Details?.trPhone,
      tr1Street: data?.tradeReference1Details?.trStreet,
      tr1City: data?.tradeReference1Details?.trCity,
      tr1state: data?.tradeReference1Details?.trStateId,
      tr1ZipCode: data?.tradeReference1Details?.trZipCode,
    });
    const tradeReference2 = this.form?.get('tradeReference2');
    tradeReference2?.patchValue({
      tr2CompanyName: data?.tradeReference2Details?.trCompanyName,
      tr2ContactFirstName: data?.tradeReference2Details?.trFirstName,
      tr2ContactLastName: data?.tradeReference2Details?.trLastName,
      tr2Email: data?.tradeReference2Details?.trEmail,
      tr2Phone: data?.tradeReference2Details?.trPhone,
      tr2Street: data?.tradeReference2Details?.trStreet,
      tr2City: data?.tradeReference2Details?.trCity,
      tr2state: data?.tradeReference2Details?.trStateId,
      tr2ZipCode: data?.tradeReference2Details?.trZipCode,
    });
    const tradeReference3 = this.form?.get('tradeReference3');
    tradeReference3?.patchValue({
      tr3CompanyName: data?.tradeReference3Details?.trCompanyName,
      tr3ContactFirstName: data?.tradeReference3Details?.trFirstName,
      tr3ContactLastName: data?.tradeReference3Details?.trLastName,
      tr3Email: data?.tradeReference3Details?.trEmail,
      tr3Phone: data?.tradeReference3Details?.trPhone,
      tr3Street: data?.tradeReference3Details?.trStreet,
      tr3City: data?.tradeReference3Details?.trCity,
      tr3state: data?.tradeReference3Details?.trStateId,
      tr3ZipCode: data?.tradeReference3Details?.trZipCode,
    });
  }

  /**
   * initializeForm() - method to initialize the step one form
   */
  initializeForm(): void {
    this.form = this.fb.group({
      lineOfCredit: this.fb.group({
        lineOfCreditRequested: [
          '',
          [
            Validators.required,
            Validators.maxLength(14),
            this.formValidationService.validateNo,
          ],
        ],
        anticipatedJobTotal: [
          '',
          [
            Validators.required,
            Validators.maxLength(14),
            this.formValidationService.validateNo,
          ],
        ],
        totalNoOfMonths: [
          '',
          [
            Validators.required,
            Validators.maxLength(3),
            this.formValidationService.validateNo,
          ],
        ],
      }),

      letterOfGuarantee: this.fb.group({
        logComment: [
          '',
          [
            Validators.required,
          ],
        ],
      }),

      productionCompany: this.fb.group({
        name: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphanumericSpecialChars,
          ],
        ],
        street: [
          '',
          [
            Validators.required,
            this.formValidationService.streetValidator,
          ],
        ],
        city: ['',
          [
            Validators.required,
            this.formValidationService.cityValidator,
          ]
        ],
        state: [
          '',
          Validators.required
        ],
        zipcode: ['',
          [
            Validators.required,
            this.formValidationService.zipCodeValidator
          ]
        ],
        phone: ['',
          [
            Validators.required,
            this.formValidationService.validatePhoneNo
          ]
        ],
      }),

      federalTax: this.fb.group({
        federalTaxTin: ['',
          [
            Validators.required,
            Validators.minLength(15),
            this.formValidationService.validateFederalTaxTin
          ]
        ],
      }),

      accountPayable: this.fb.group({
        apFirstName: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        apLastName: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        apEmail: [
          '',
          [
            Validators.required,
            this.formValidationService.validateEmail,
          ],
        ],
        apPhone: [
          '',
          [
            Validators.required,
            this.formValidationService.validatePhoneNo,
          ],
        ],
      }),

      companyProfile: this.fb.group({
        cpProfile: [
          '',
          // Validators.required
        ],
        cpDateStarted: [
          '',
          [
            //   Validators.required,
          ],
        ],
        cpTypeOfBusiness: [
          '',
          [
            // Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphanumeric,
          ],
        ],
        cpTotalAnnualsale: [
          '',
          [
            //  Validators.required,
            // Validators.maxLength(50),
            this.formValidationService.validateNo,
          ],
        ],
        cpNetworth: [
          '',
          [
            //  Validators.required,
            this.formValidationService.validateNo,
          ],
        ],
        cpNumOfEmployees: [
          '',
          [
            // Validators.required,
            this.formValidationService.validateNo,
          ],
        ],
      }),

      principalsAndPartners: this.fb.group({
        ppName: [
          '',
          [
            //  Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        ppTitle: [
          '',
          [
            // Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphanumericSpecialChars,
          ],
        ],
      }),

      bankingReference: this.fb.group({
        brbankName: [
          '',
          [
            //  Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphanumericSpecialChars,
          ],
        ],
        brAccountNumber: [
          '',
          [
            //  Validators.required,
            Validators.maxLength(20),
            this.formValidationService.validateNo,
          ],
        ],
        brContactName: [
          '',
          [
            // Validators.required,
            Validators.maxLength(20),
            this.formValidationService.validateAlphabets,
          ],
        ],
        brTitle: [
          '',
          [
            // Validators.required,
            this.formValidationService.validateAlphanumericSpecialChars,
          ],
        ],
        brEmail: [
          '',
          [
            //  Validators.required,
            this.formValidationService.validateEmail,
          ],
        ],
        brPhone: [
          '',
          [
            //  Validators.required,
            this.formValidationService.validatePhoneNo,
          ],
        ],
      }),

      tradeReference1: this.fb.group({
        tr1CompanyName: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphanumericSpecialChars,
          ],
        ],
        tr1ContactFirstName: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        tr1ContactLastName: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        tr1Email: [
          '',
          [
            Validators.required,
            this.formValidationService.validateEmail,
          ],
        ],
        tr1Phone: [
          '',
          [
            Validators.required,
            this.formValidationService.validatePhoneNo,
          ],
        ],
        tr1Street: [
          '',
          [
            Validators.required,
            this.formValidationService.streetValidator,
          ],
        ],
        tr1City: [
          '',
          [
            Validators.required,
            this.formValidationService.cityValidator,
          ],
        ],
        tr1state: [
          '',
          Validators.required
        ],
        tr1ZipCode: [
          '',
          [
            Validators.required,
            this.formValidationService.zipCodeValidator,
          ],
        ],
      }),

      tradeReference2: this.fb.group({
        tr2CompanyName: [
          '',
          [
            //  Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphanumericSpecialChars,
          ],
        ],
        tr2ContactFirstName: [
          '',
          [
            //  Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        tr2ContactLastName: [
          '',
          [
            //  Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        tr2Email: [
          '',
          [
            // Validators.required,
            this.formValidationService.validateEmail,
          ],
        ],
        tr2Phone: [
          '',
          [
            //  Validators.required,
            this.formValidationService.validatePhoneNo,
          ],
        ],
        tr2Street: [
          '',
          [
            // Validators.required,
            this.formValidationService.streetValidator,
          ],
        ],
        tr2City: [
          '',
          [
            //  Validators.required,
            this.formValidationService.cityValidator,
          ],
        ],
        tr2state: [
          '',
          //  Validators.required
        ],
        tr2ZipCode: [
          '',
          [
            // Validators.required,
            this.formValidationService.zipCodeValidator,
          ],
        ],
      }),

      tradeReference3: this.fb.group({
        tr3CompanyName: [
          '',
          [
            // Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphanumericSpecialChars,
          ],
        ],
        tr3ContactFirstName: [
          '',
          [
            //  Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        tr3ContactLastName: [
          '',
          [
            // Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        tr3Email: [
          '',
          [
            // Validators.required,
            this.formValidationService.validateEmail,
          ],
        ],
        tr3Phone: [
          '',
          [
            // Validators.required,
            this.formValidationService.validatePhoneNo,
          ],
        ],
        tr3Street: [
          '',
          [
            // Validators.required,
            this.formValidationService.streetValidator,
          ],
        ],
        tr3City: [
          '',
          [
            // Validators.required,
            this.formValidationService.cityValidator,
          ],
        ],
        tr3state: [
          '',
          //Validators.required
        ],
        tr3ZipCode: [
          '',
          [
            // Validators.required,
            this.formValidationService.zipCodeValidator,
          ],
        ],
      }),
    })
  }

  /**
  * onFileRemoved() - method toremove the file
  * @param file - file to be removed
  */
  onFileRemoved(removedFiles: File[]): void {
    if (removedFiles) {
      this.removeLogFile();
    }
  }

  /**
   * onFilesUpdated() - to upload the file
   * @param files - the files that needed to be uploaded
   */
  onFilesUpdated(files: File[]): void {
    this.loaderService.setLoadingState(true);
    this.uploadedFiles = files;
    const formData = new FormData();
    _.forEach(this.uploadedFiles, (file) => {
      formData.append('file', file);
    });

    const logId = this.applicationData?.creditApplicationData?.letterOfGuarantee?.logId;
    const data = {
      caSeqId: this.caSeqId,
      caNumber: this.caNumber,
      logId: logId,
      sectionName: "letterofguarantee",
      isRemove: false
    };
    formData.append('data', JSON.stringify(data));
    this.creditApplicationService.uploadLogFile(formData).subscribe({
      next: (response) => {
        this.loaderService.setLoadingState(false);
        this.logResponseDetails = response;
        this.successMsg = this.toastMessages?.logUploadSuccess;
        this.showSuccess(this.successUploadFileTpl);
      },
      error: (error) => {
        this.loaderService.setLoadingState(false);
        console.error("error uploading the log file", error);
        this.errorMsg = error.errorMessage;
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
   * removeLogFile() - method to remove the uploaded log file
   */
  removeLogFile() {
    this.loaderService.setLoadingState(true);
    const logDetails = this.logResponseDetails?.responseDTO;
    const formData = new FormData();
    const data = {
      caSeqId: logDetails?.caSeqId,
      caNumber: logDetails?.caNumber,
      logId: logDetails?.logId,
      sectionName: "letterofguarantee",
      isRemove: true,
      filePath: logDetails?.logDocUrl
    };
    formData.append('data', JSON.stringify(data));
    this.creditApplicationService.uploadLogFile(formData).subscribe({
      next: (response) => {
        this.loaderService.setLoadingState(false);
        this.successMsg = this.toastMessages?.logDeleteSuccess;
        this.showSuccess(this.successUploadFileTpl);
      },
      error: (error) => {
        this.loaderService.setLoadingState(false);
        console.error("error uploading the log file", error);
        this.errorMsg = error.errorMessage;
        this.showDanger(this.dangerTpl);
      }
    });
  }


  /**
   * disableFormControls() - method to disable the form controls
   */
  disableFormControls(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key)?.disable(); // Disable top-level controls
      const nestedGroup = this.form.get(key) as FormGroup;
      if (nestedGroup) {
        Object.keys(nestedGroup.controls).forEach((nestedKey) => {
          nestedGroup.get(nestedKey)?.disable(); // Disable nested controls
        });
      }
    });
  }

  /**
   * createPayload() - creating payload for submitting credit application
   * @returns - a payload for submitting credit application
   */
  createPayload(): any {  
    const formValue = this.form.value;
    let caSeqId = this.applicationData?.caSeqId;
    let caNumber = this.applicationData?.caNumber;
    let payload =  {
      caSeqId: caSeqId,  
      caNumber: caNumber,
      projectId: this.projectId,
      companyId: 2,
      locId:  this.applicationData?.lineOfCredit?.locId,
      logId: this.applicationData?.letterOfGuarantee?.logId,
      caCompanyProfileId: this.applicationData?.caCompanyProfile?.caCompanyProfileId,
      brId: this.applicationData?.bankingReferences?.brId,
      lineOfCredit: {
        locRequested: formValue.lineOfCredit.lineOfCreditRequested,
        locJobTotal: formValue.lineOfCredit.anticipatedJobTotal,
        locNumOfMonths: formValue.lineOfCredit.totalNoOfMonths,
        locId: this.applicationData?.lineOfCredit?.locId,
      },
      letterOfGuarantee: {
        logId: this.applicationData?.letterOfGuarantee?.logId,
        logComments: formValue.letterOfGuarantee.logComment,
        logDocUrl: "test/url/doc12"
      },
      caProdCompany: [{
        caProdComAddrId: 1,
        companyAddressType: {
          companyAddressTypeId: 1,
          StringaddressType: "billing Address"
        },
        countryId: 2,
        state: {
          stateId: 1,
          stateCode: "us"
        },
        companyName: formValue.productionCompany.name,
        // state: formValue.productionCompany.state,
        street: formValue.productionCompany.street,
        city: formValue.productionCompany.city,
        zip: formValue.productionCompany.zipcode,
        phone: formValue.productionCompany.phone,
        billingAddressSameInd: 1,
        fedralTaxNum: formValue.federalTax.federalTaxTin,
      }],
      accountsPayableContacts: [
        {
          accountsPayableContactId: this.applicationData,
          firstName: formValue.accountPayable.apFirstName,
          lastName: formValue.accountPayable.apLastName,
          emailAddress: formValue.accountPayable.apEmail,
          telephone: formValue.accountPayable.apPhone,
        }
      ],

      caCompanyProfile: {
        dateStarted: formValue.companyProfile.cpDateStarted,
        typeOfBusiness: formValue.companyProfile.cpTypeOfBusiness,
        totalAnnualSales: formValue.companyProfile.cpTotalAnnualsale,
        networth: formValue.companyProfile.cpNetworth,
        noOfEmployees: formValue.companyProfile.cpNumOfEmployees,
        caCpType: formValue.companyProfile.cpProfile,
        companyProfileId: this.applicationData?.caCompanyProfile?.caCompanyProfileId,
      },
      partners: [
        {
          partnersId: 1,
          partnerName: formValue.principalsAndPartners.ppName,
          partnerTitle: formValue.principalsAndPartners.ppTitle
        },
      ],
      bankingReferences: {
        brId: this.applicationData?.bankingReferences?.brId,
        bankName: formValue.bankingReference.brbankName,
        accountNumber: formValue.bankingReference.brAccountNumber,
        contactFullName: formValue.bankingReference.brContactName,
        title: formValue.bankingReference.brTitle,
        emailAddress: formValue.bankingReference.brEmail,
        phoneNumber: formValue.bankingReference.brPhone,
      },
      tradeReferences: [
        {
          trId: this.applicationData?.tradeReferences1?.trId,
          trCompanyName: formValue.tradeReference1.tr1CompanyName,
          trFirstName: formValue.tradeReference1.tr1ContactFirstName,
          trLastName: formValue.tradeReference1.tr1ContactLastName,
          trEmail: formValue.tradeReference1.tr1Email,
          trPhone: formValue.tradeReference1.tr1Phone,
          trCity: formValue.tradeReference1.tr1City,
          trStateId: 1,
          trStreet: formValue.tradeReference1.tr1Street,
          trZipCode: formValue.tradeReference1.tr1ZipCode,
          trDocumentUrl: "url.com",
        },
        {
          trId: this.applicationData?.tradeReferences2?.trId,
          trCompanyName: formValue.tradeReference2.tr2CompanyName,
          trFirstName: formValue.tradeReference2.tr2ContactFirstName,
          trLastName: formValue.tradeReference2.tr2ContactLastName,
          trEmail: formValue.tradeReference2.tr2Email,
          trPhone: formValue.tradeReference2.tr2Phone,
          trCity: formValue.tradeReference2.tr2City,
          trStateId: 1,
          trStreet: formValue.tradeReference2.tr2Street,
          trZipCode: formValue.tradeReference2.tr2ZipCode,
          trDocumentUrl: "url.com",
        },
        {
          trId: this.applicationData?.tradeReferences3?.trId,
          trCompanyName: formValue.tradeReference3.tr3CompanyName,
          trFirstName: formValue.tradeReference3.tr3ContactFirstName,
          trLastName: formValue.tradeReference3.tr3ContactLastName,
          trEmail: formValue.tradeReference3.tr3Email,
          trPhone: formValue.tradeReference3.tr3Phone,
          trCity: formValue.tradeReference3.tr3City,
          trStateId: 1,
          trStreet: formValue.tradeReference3.tr3Street,
          trZipCode: formValue.tradeReference3.tr3ZipCode,
          trDocumentUrl: "url.com",
        },
      ]

    };

    return payload;
  }

  /**
   * submitCreditform() - method to submit the credit application form data
   */
  submitCreditform() {
    //this.loaderService.setLoadingState(true);
    //if (this.form.valid) {

    let payload = this.createPayload();

    this.creditApplicationService.saveCreditAppForm(payload).subscribe({
      next: (response: any) => {
        this.loaderService.setLoadingState(false);
        this.showSuccess(this.successTpl);
      },
      error: (error) => {
        console.error(error);
        this.errorMsg = error.errormMessage;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      }
    });
    // } 

  }

  /**
   * updateCreditform() - to update the credit application 
   */
  updateCreditform(): void {
    //this.loaderService.setLoadingState(true);
    //if (this.form.valid) {
    let payload = this.createPayload();
    this.creditApplicationService.updateCreditAppForm(payload).subscribe({
      next: (response: any) => {
        this.loaderService.setLoadingState(false);
        this.showSuccess(this.successTpl);
        this.stepCompleted.emit(2);
      },
      error: (error) => {
        console.error(error);
        this.errorMsg = error.message;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      }
    });
    // } 

  }

  /**
   * submitCreditAppication() - Method to submit the form data to the API
   */
  submitCreditAppication(): void {
    this.loaderService.setLoadingState(true);
    if (this.customerStatus !== this.credFormOneConstant.underRevision) {
      this.submitCreditform();
    } else {
      this.updateCreditform();
    }
  }

  /**
   * pathchValue() - to patch the value other than revision
   */
  pathchValue(): void {
    const data = this.applicationData?.creditApplicationList;
    const dateParts = data?.caCompanyProfile?.dateStarted?.split('/');
    const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    this.form.patchValue({
      // this.form.get('lineOfCredit')?.patchValue({
      lineOfCredit: {
        lineOfCreditRequested: data.lineOfCredit?.locRequested,
        anticipatedJobTotal: data.lineOfCredit?.locJobTotal,
        totalNoOfMonths: data.lineOfCredit?.locNumOfMonths
      },
      letterOfGuarantee: {
        logComment: data.letterOfGuarantee.logComments,
        logDocUrl: data.letterOfGuarantee.logDocUrl
      },

      productionCompany: {
        name: data.companyName,
        street: data.caProdCompany[0].street,
        city: data.caProdCompany[0].city,
        state: data.caProdCompany[0].state.stateId,
        zipcode: data.caProdCompany[0].zip,
        phone: data.caProdCompany[0].phone

      },

      federalTax: {
        federalTaxTin: data.caProdCompany[0].fedralTaxNum

      },

      accountPayable: {
        apFirstName: data.accountPayableContact[0].firstName,
        apLastName: data.accountPayableContact[0].lastName,
        apEmail: data.accountPayableContact[0].emailAddress,
        apPhone: data.accountPayableContact[0].telephone,
      },

      companyProfile: {
        cpProfile: data.caCompanyProfile.caCpType,
        cpDateStarted: formattedDate,
        cpTypeOfBusiness: data.caCompanyProfile.typeOfBusiness,
        cpTotalAnnualsale: data.caCompanyProfile.totalAnnualSales,
        cpNetworth: data.caCompanyProfile.networth,
        cpNumOfEmployees: data.caCompanyProfile.noOfEmployees,

      },
      principalsAndPartners: {
        ppName: data.partners[0].partnerName,
        ppTitle: data.partners[0].partnerTitle
      },

      bankingReference: {
        brbankName: data.bankingReferences.bankName,
        brAccountNumber: data.bankingReferences.accountNumber,
        brContactName: data.bankingReferences.contactFullName,
        brTitle: data.bankingReferences.title,
        brEmail: data.bankingReferences.emailAddress,
        brPhone: data.bankingReferences.phoneNumber
      },

      tradeReference1: {
        tr1CompanyName: data.tradeReferences1.trCompanyName,
        tr1ContactFirstName: data.tradeReferences1.trFirstName,
        tr1ContactLastName: data.tradeReferences1.trLastName,
        tr1Email: data.tradeReferences1.trEmail,
        tr1Phone: data.tradeReferences1.trPhone,
        tr1Street: data.tradeReferences1.trStreet,
        tr1City: data.tradeReferences1.trCity,
        tr1state: data.tradeReferences1.trStateId,
        tr1ZipCode: data.tradeReferences1.trZipCode
      },
      tradeReference2: {
        tr2CompanyName: data.tradeReferences2.trCompanyName,
        tr2ContactFirstName: data.tradeReferences2.trFirstName,
        tr2ContactLastName: data.tradeReferences2.trLastName,
        tr2Email: data.tradeReferences2.trEmail,
        tr2Phone: data.tradeReferences2.trPhone,
        tr2Street: data.tradeReferences2.trStreet,
        tr2City: data.tradeReferences2.trCity,
        tr2state: data.tradeReferences2.trStateId,
        tr2ZipCode: data.tradeReferences2.trZipCode
      },
      tradeReference3: {
        tr3CompanyName: data.tradeReferences3.trCompanyName,
        tr3ContactFirstName: data.tradeReferences3.trFirstName,
        tr3ContactLastName: data.tradeReferences3.trLastName,
        tr3Email: data.tradeReferences3.trEmail,
        tr3Phone: data.tradeReferences3.trPhone,
        tr3Street: data.tradeReferences3.trStreet,
        tr3City: data.tradeReferences3.trCity,
        tr3state: data.tradeReferences3.trStateId,
        tr3ZipCode: data.tradeReferences3.trZipCode
      }
      // });
    })
  }

  /**
 * convertStringToNgbDate() - converts a date string to NgbDateStruct
 */
  convertStringToNgbDate(dateString: string): NgbDateStruct {
    if (!dateString) return null;

    const parts = dateString.split('/');
    return {
      year: parseInt(parts[2], 10), // Assuming the format is dd/MM/yyyy
      month: parseInt(parts[1], 10),
      day: parseInt(parts[0], 10),
    };
  }

  /**
   * nextPage() - Method to handle the action of proceeding to the next page.
   * It calls the submitCreditform1 method to handle form submission.
   */
  nextPage() {
    this.submitCreditform();
    this.nextStepEvent.emit();
  }

  /**
   * saveAndClose() - Method to handle the action of saving and closing the form.
   * It calls the submitCredit form1 method to handle form submission.
   */
  saveAndClose() {
    this.submitCreditform();
    // this.saveAndCloseEvent.emit();
  }
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = true;
  }
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = false;
  }
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = false;

    if (event.dataTransfer?.files) {
      this.addFiles(event.dataTransfer.files);
    }
  }
  onFileSelect(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.addFiles(input.files);
    }
    this.resetFileInput();  // Reset input after handling the selection
  }

  addFiles(files: FileList) {
    for (let i = 0; i < files?.length; i++) {
      this.files.push(files[i]);
    }
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  // Reset the file input to allow re-uploading of the same files
  resetFileInput() {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

/**
* onTrFileChange() - method to upload the Trade Reference file
*/
onTrFileChange(event: any, trOrder: number): void {
  const file = event.target.files[0];
  if (file) {
   // Validate file size (<= 100MB)
   if (file.size > 100 * 1024 * 1024) {
     
     if(trOrder === INDEX.one){
      this.tr1fileName = null;
      this.tr1ErrorMessage = this.toastMessages?.fileMoreThan100MBErrMsg;
     }
     else if(trOrder === INDEX.two){
      this.tr2fileName = null;
      this.tr2ErrorMessage = this.toastMessages?.fileMoreThan100MBErrMsg;
    }
     else if(trOrder === INDEX.three){
      this.tr3fileName = null;
      this.tr3ErrorMessage = this.toastMessages?.fileMoreThan100MBErrMsg;
    }
     return;
   }
   else{
     this.loaderService.setLoadingState(true);
     const formData = new FormData();  
     formData.append('file', file); 
     const data = {
      caSeqId: '67',
      trId: null,
      trOrder: trOrder,
      sectionName: CREDIT_APP_REPORT_SECTION.trSection,
      isRemove:false
   };
   formData.append('data', JSON.stringify(data));
   this.creditApplicationService.uploadTradeRefference(formData).subscribe({
    next: (response: any) => {
      if(trOrder === INDEX.one){
        this.tradeRefferenc1Data = response?.responseDTO;
        this.tr1fileName = file.name;
        this.tr1ErrorMessage = null;
      }
      else if(trOrder === INDEX.two){
        this.tradeRefferenc2Data = response?.responseDTO;
        this.tr2fileName = file.name;
        this.tr2ErrorMessage = null;
      }
      else if(trOrder === INDEX.three){
        this.tradeRefferenc3Data = response?.responseDTO;
        this.tr3fileName = file.name;
        this.tr3ErrorMessage = null;
      }
      this.loaderService.setLoadingState(false);
      this.successMsg = this.toastMessages?.tradeReferenceSuccess
      this.showSuccessFileUpload(this.successUploadFileTpl);
    },
    error: (error) => {
      this.loaderService.setLoadingState(false);
      console.error('Error uploading the file:', error);
      this.errorMsg = error;
      this.showDanger(this.dangerTpl);
     }
    });
  }
 }
}

/**
* viewTradeRefferenceReport() - method to call  the uploadTradeReferenceFile method
*/
viewTradeRefferenceReport(tradeReferenceDetails): void {
  const section = CREDIT_APP_REPORT_SECTION.trSection;
  this.getTradeRefferenceReport(tradeReferenceDetails, section);
}

/**
* removeTradeRefferenceReport() - method to remove the uploaded Trade Reference File
*/
removeTradeRefferenceReport(event: Event, tradeData:any, trOrder:number) {
  this.loaderService.setLoadingState(true);
  const formData = new FormData();  
  const data = {
    caSeqId: '67',
    trId: tradeData?.trId,
    trOrder: trOrder,
    sectionName: CREDIT_APP_REPORT_SECTION.trSection,
    isRemove:true,
    filePath:tradeData?.trDocumentUrl
  };
  formData.append('data', JSON.stringify(data));
  this.creditApplicationService.uploadTradeRefference(formData).subscribe({
    next: (response: any) => {
      if(trOrder === INDEX.one){this.tr1fileName = '';}
      else if(trOrder === INDEX.two){this.tr2fileName = '';}
      else if(trOrder === INDEX.three){this.tr3fileName = '';}
      this.loaderService.setLoadingState(false);
      this.successMsg = this.toastMessages?.tradeReferenceRemoveSuccess
      this.showSuccessFileUpload(this.successUploadFileTpl);
    },
    error: (error) => {
      this.loaderService.setLoadingState(false);
      console.error('Error uploading the file:', error);
      this.errorMsg = error;
      this.showDanger(this.dangerTpl);
     }
    });
}

  /**
  * getTradeRefferenceReport() - method to get the Certificate of Insurance through API call
  */
  getTradeRefferenceReport(url: any, section: any): void {
    this.loaderService.setLoadingState(true);
    this.sharedViewCreditAppService.getCreditAppReport(url, section)
      .pipe()
      .subscribe({
        next: (response: any) => {
          const docUrl = response?.url;
          this.loaderService.setLoadingState(false);
          window.open(docUrl, '_blank');
        },
        error: (error) => {
          console.error(error);
          this.errorMsg = error;
          this.loaderService.setLoadingState(false);
          this.showDanger(this.dangerTpl);
        }
      })
  }

  /**
  * showSuccess() - Method to display a success toast notification.
  * @param successTpl - The template reference for the success message
  */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
  * showSuccessFileUpload() - Method to display a success toast notification.
  * @param successUploadFileTpl - The template reference for the upload file success message
  */
  showSuccessFileUpload(successUploadFileTpl: TemplateRef<any>) {
    this.toastService.show(successUploadFileTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * toggleHelpSlider() - Method to toggle the visibility of the help slider
   * 
   * This method switches the `helpSliderVisible` boolean state between `true` and `false`,
   * effectively showing or hiding the help slider.
   *  @returns {void} This method does not return any value.
   */
  toggleHelpSlider(): void {
    this.helpSliderVisible = !this.helpSliderVisible;
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: any) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
  * viewLetterOfCreditPDFDocument() - method to call the getLetterOfCreditPDFDocument
  */
  viewLetterOfCreditPDFDocument(): void {
    const section = CREDIT_APP_REPORT_SECTION.locSection;
    this.getLetterOfCreditPDFDocument(this.letterOfCreditPDFDocument?.documentUrl, section);
  }

  /**
  * getLetterOfCreditPDFDocument() - method to get the Letter of Credit PDF document through API call
  */
  getLetterOfCreditPDFDocument(url: any, section: any): void {
    this.loaderService.setLoadingState(true);
    this.sharedViewCreditAppService.getCreditAppReport(url, section)
      .pipe()
      .subscribe({
        next: (response: any) => {
          const docUrl = response?.url;
          this.loaderService.setLoadingState(false);
          window.open(docUrl, '_blank');
        },
        error: (error) => {
          console.error(error);
          this.errorMsg = error;
          this.loaderService.setLoadingState(false);
          this.showDanger(this.dangerTpl);
        }
      });
  }

  /**
 * revisionPayload() - method to post the data after revision
 * @returns - returns payload to be sent to api
 */
  revisionPayload(){
    const data = this.revisionData?.caRevisionDetails?.applicationFormDetails
    console.log(data)
    const formValue = this.form.getRawValue();
    let payload = {
      caSeqNumber: 61,
      caNumber: 61,
      lineOfCreditRequest: {
        locRequested: formValue.lineOfCredit.lineOfCreditRequested,
        locJobTotal: formValue.lineOfCredit.anticipatedJobTotal,
        locNumOfMonths: formValue.lineOfCredit.totalNoOfMonths,
        reviewRequired:  this.lineOfcredit,
      },
      letterOfGuaranteeRequest: {
        logId: data?.letterOfGuaranteeDetails?.logId,
        logComments: formValue.letterOfGuarantee.logComment,
        logDocUrl: 'test/url/doc12',
        reviewRequired: this.letterOfGuarantee ,
      },
      productionCompanyRequest: [
        {
          caProdComAddrId: 1,
          street: formValue.productionCompany.street,
          city: formValue.productionCompany.city,
          zip: formValue.productionCompany.zipcode,
          phone: formValue.productionCompany.phone,
          reviewRequired: this.productionCompany,
        },
      ],
      accountPayableRequest: [
        {
          accountsPayableContactId: data?.accountsPayableContactsDetails?.accountsPayableContactId,
          firstName: formValue.accountPayable.apFirstName,
          lastName: formValue.accountPayable.apLastName,
          emailAddress: formValue.accountPayable.apEmail,
          telephone: formValue.accountPayable.apPhone,
          reviewRequired: this.accountPayable,
        },
      ],
      companyProfileRequest: {
        companyProfileId: data?.caCompanyProfileDetails?.caCompanyProfileId,
        caCpType: formValue.companyProfile.cpProfile,
        dateStarted: formValue.companyProfile.cpDateStarted,
        typeOfBusiness: formValue.companyProfile.cpTypeOfBusiness,
        totalAnnualSales: formValue.companyProfile.cpTotalAnnualsale,
        networth: formValue.companyProfile.cpNetworth,
        noOfEmployees: formValue.companyProfile.cpNumOfEmployees,
        reviewRequired: this.companyProfile,
      },
      partnersRequest: [
        {
          partnersId: data?.partnersDetails[0]?.partnersId,
          partnerName: formValue.principalsAndPartners.ppName,
          partnerTitle: formValue.principalsAndPartners.ppTitle,
          reviewRequired: this.principalsAndPartners,
        },
      ],
      bankReferenceRequest: {
        brId: data?.bankingReferencesDetails?.brId,
        bankName: formValue.bankingReference.brbankName,
        accountNumber: formValue.bankingReference.brAccountNumber,
        contactFullName: formValue.bankingReference.brContactName,
        title: formValue.bankingReference.brTitle,
        emailAddress: formValue.bankingReference.brEmail,
        phoneNumber: formValue.bankingReference.brPhone,
        reviewRequired:  this.showBankReference,
      },
      tradeReferencesRequest: [
        {
          trId: data?.tradeReference1Details?.trId,
          trCompanyName: formValue.tradeReference1.tr1CompanyName,
          trFirstName: formValue.tradeReference1.tr1ContactFirstName,
          trLastName: formValue.tradeReference1.tr1ContactLastName,
          trEmail: formValue.tradeReference1.tr1Email,
          trPhone: formValue.tradeReference1.tr1Phone,
          trCity: formValue.tradeReference1.tr1City,
          trStreet: formValue.tradeReference1.tr1Street,
          trZipCode: formValue.tradeReference1.tr1ZipCode,
          trDocumentUrl: 'url.com',
          reviewRequired: this.tradeReference1,
        },
        {
          trId: data?.tradeReference2Details?.trId,
          trCompanyName: formValue.tradeReference1.tr1CompanyName,
          trFirstName: formValue.tradeReference1.tr1ContactFirstName,
          trLastName: formValue.tradeReference1.tr1ContactLastName,
          trEmail: formValue.tradeReference1.tr1Email,
          trPhone: formValue.tradeReference1.tr1Phone,
          trCity: formValue.tradeReference1.tr1City,
          trStreet: formValue.tradeReference1.tr1Street,
          trZipCode: formValue.tradeReference1.tr1ZipCode,
          trDocumentUrl: 'url.com',
          reviewRequired:  this.tradeReference2,
        },
        {
          trId: data?.tradeReference3Details?.trId,
          trCompanyName: formValue.tradeReference1.tr1CompanyName,
          trFirstName: formValue.tradeReference1.tr1ContactFirstName,
          trLastName: formValue.tradeReference1.tr1ContactLastName,
          trEmail: formValue.tradeReference1.tr1Email,
          trPhone: formValue.tradeReference1.tr1Phone,
          trCity: formValue.tradeReference1.tr1City,
          trStreet: formValue.tradeReference1.tr1Street,
          trZipCode: formValue.tradeReference1.tr1ZipCode,
          trDocumentUrl: 'url.com',
          reviewRequired: this.tradeReference3,
        },
      ],
    };

    return payload;
  }


  /**
   * postCreditApplication() - method to submit the credit application step1
   */
  postCreditApplication(): void {
    let revisionPayload = this.revisionPayload();
    this.creditApplicationService
      .postCreditApplication(revisionPayload)
      .subscribe({
        next: (response) => {

        },
        error: (error) => {
          console.error(error);
        },
      });
  }
}