<div *ngIf="isAuthenticated">
  <app-menu [portalType]="portalType"></app-menu>
</div>
<div class="container-fluid page-wrapper">
	<div class="container">
		<div class="row">
			<div class="col-12">
                <h1 class="page-title px-0 mb-0">{{constants.directory}}</h1>
                <div class="d-flex align-items-center view-text ">
                    <div>
                        <p class="me-3 mb-0 change-weather-title-block">You are viewing the Directory for <span class="change-weather-title">Burbank</span> <span class="change-weather-toggle"> <span>Change</span> <i class="bi bi-geo-alt-fill"></i>
                        </span></p>
                    </div>
                  
                </div>
            </div>
            <div class="col-md-4 col-lg-4 d-none d-md-block">
                <ol class="handbook-list" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <li
                    class="nav-link"
                    id="office-production-services-tab"
                    role="tab"
                    aria-controls="office-production-services-content"
                    aria-selected="true"
                    (click)="selectTab(constants.office_production_services_tab)"
                    [ngClass]="{'show active': selectedTab === 'office-production-services-tab'}"
                  > {{constants.office_and_production_services}}
                  </li>
                  <li
                    class="nav-link"
                    id="safety-environmental-affairs-tab"
                    role="tab"
                    aria-controls="safety-environmental-affairs-content"
                    aria-selected="false"
                    (click)="selectTab(constants.safety_environmental_affairs_tab)"
                    [ngClass]="{'show active': selectedTab === 'safety-environmental-affairs-tab'}"
                  > {{constants.safety_and_environment_affairs}}
                  </li>
                  <li
                    class="nav-link"
                    id="studio-protection-group-tab"
                    role="tab"
                    aria-controls="studio-protection-group-content"
                    aria-selected="false"
                    (click)="selectTab(constants.studio_protection_group_tab)"
                    [ngClass]="{'show active': selectedTab === 'studio-protection-group-tab'}"
                  > {{constants.studio_protection_group}}
                  </li>
                  <li
                    class="nav-link"
                    id="construction-services-tab"
                    role="tab"
                    aria-controls="construction-services-content"
                    aria-selected="false"
                    (click)="selectTab(constants.construction_services_tab)"
                    [ngClass]="{'show active': selectedTab === 'construction-services-tab'}"
                  > {{constants.construction_services}}
                  </li>
                  <li
                    class="nav-link"
                    id="costume-tab"
                    role="tab"
                    aria-controls="costume-content"
                    aria-selected="false"
                    (click)="selectTab(constants.costume_tab)"
                    [ngClass]="{'show active': selectedTab === 'costume-tab'}"
                  > {{constants.costume}}
                  </li>
                  <li
                    class="nav-link"
                    id="design-studio-tab"
                    role="tab"
                    aria-controls="design-studio-content"
                    aria-selected="false"
                    (click)="selectTab(constants.design_studio_tab)"
                    [ngClass]="{'show active': selectedTab === 'design-studio-tab'}"
                  > {{constants.design_studio}}
                  </li>
                  <li
                    class="nav-link"
                    id="post-production-creative-services-tab"
                    role="tab"
                    aria-controls="post-production-creative-services-content"
                    aria-selected="false"
                    (click)="selectTab(constants.post_production_creative_services_tab)"
                    [ngClass]="{'show active': selectedTab === 'post-production-creative-services-tab'}"
                  > {{constants.post_production_creative_services}}
                  </li>
                  <li
                    class="nav-link"
                    id="production-sound-video-tab"
                    role="tab"
                    aria-controls="production-sound-video-content"
                    aria-selected="false"
                    (click)="selectTab(constants.production_sound_video_tab)"
                    [ngClass]="{'show active': selectedTab === 'production-sound-video-tab'}"
                  > {{constants.production_sound_and_video}}
                  </li>
                  <li
                    class="nav-link"
                    id="property-drapery-floor-covering-tab"
                    role="tab"
                    aria-controls="property-drapery-floor-covering-content"
                    aria-selected="false"
                    (click)="selectTab(constants.property_drapery_floor_covering_tab)"
                    [ngClass]="{'show active': selectedTab === 'property-drapery-floor-covering-tab'}"
                  > {{constants.property_drapery_floor_covering}}
                  </li>
                  <li
                    class="nav-link"
                    id="food-services-special-events-tab"
                    role="tab"
                    aria-controls="food-services-special-events-content"
                    aria-selected="false"
                    (click)="selectTab(constants.food_services_special_events_tab)"
                    [ngClass]="{'show active': selectedTab === 'food-services-special-events-tab'}"
                  > {{constants.food_services_special_events}}
                  </li>
                  <li
                    class="nav-link"
                    id="grip-tab"
                    role="tab"
                    aria-controls="grip-content"
                    aria-selected="false"
                    (click)="selectTab(constants.grip_tab)"
                    [ngClass]="{'show active': selectedTab === 'grip-tab'}"
                  > {{constants.grip}}
                  </li>
                  <li
                    class="nav-link"
                    id="set-lighting-tab"
                    role="tab"
                    aria-controls="set-lighting-content"
                    aria-selected="false"
                    (click)="selectTab(constants.set_lighting_tab)"
                    [ngClass]="{'show active': selectedTab === 'set-lighting-tab'}"
                  > {{constants.set_lighting}}
                  </li>
                  <li
                    class="nav-link"
                    id="special-effects-tab"
                    role="tab"
                    aria-controls="special-effects-content"
                    aria-selected="false"
                    (click)="selectTab(constants.special_effects_tab)"
                    [ngClass]="{'show active': selectedTab === 'special-effects-tab'}"
                  > {{constants.special_effects}}
                  </li>
                  <li
                    class="nav-link"
                    id="transportation-tab"
                    role="tab"
                    aria-controls="transportation-content"
                    aria-selected="false"
                    (click)="selectTab(constants.transportation_tab)"
                    [ngClass]="{'show active': selectedTab === 'transportation-tab'}"
                  > {{constants.transportation}}
                  </li>
                  <li
                    class="nav-link"
                    id="labor-tab"
                    role="tab"
                    aria-controls="labor-content"
                    aria-selected="false"
                    (click)="selectTab(constants.labor_tab)"
                    [ngClass]="{'show active': selectedTab === 'labor-tab'}"
                  > {{constants.labor}}
                  </li>
                  <li
                    class="nav-link"
                    id="utilities-tab"
                    role="tab"
                    aria-controls="utilities-content"
                    aria-selected="false"
                    (click)="selectTab(constants.utilities_tab)"
                    [ngClass]="{'show active': selectedTab === 'utilities-tab'}"
                  > {{constants.utilites}}
                  </li>
                  <li
                    class="nav-link"
                    id="studio-tours-tab"
                    role="tab"
                    aria-controls="studio-tours-content"
                    aria-selected="false"
                    (click)="selectTab(constants.studio_tours_tab)"
                    [ngClass]="{'show active': selectedTab === 'studio-tours-tab'}"
                  > {{constants.studio_tours}}
                  </li>
                  <li
                    class="nav-link"
                    id="additional-services-tab"
                    role="tab"
                    aria-controls="additional-services-content"
                    aria-selected="false"
                    (click)="selectTab(constants.additional_services_tab)"
                    [ngClass]="{'show active': selectedTab === 'additional-services-tab'}"
                  > {{constants.additional_on_lot_client_employee_services}}
                  </li>
                </ol>
              </div>
              
              <div class="col-md-4 col-lg-4 col-sm-12 d-block d-md-none mobile-select-block">
                <select class="form-select" id="mobileSelect" [(ngModel)]="selectedTab" (change)="onSelectChange($event)">
                  <option value="office-production-services-tab">{{constants.office_and_production_services}}
                  </option>
                  <option value="safety-environmental-affairs-tab">{{constants.safety_and_environment_affairs}}
                  </option>
                  <option value="studio-protection-group-tab">{{constants.studio_protection_group}}
                  </option>
                  <option value="construction-services-tab">{{constants.construction_services}}
                  </option>
                  <option value="costume-tab">{{constants.costume}}
                  </option>
                  <option value="design-studio-tab">{{constants.design_studio}}
                  </option>
                  <option value="post-production-creative-services-tab">{{constants.post_production_creative_services}}
                  </option>
                  <option value="production-sound-video-tab">{{constants.production_sound_and_video}}
                  </option>
                  <option value="property-drapery-floor-covering-tab">{{constants.property_drapery_floor_covering}}
                  </option>
                  <option value="food-services-special-events-tab">{{constants.food_services_special_events}}
                  </option>
                  <option value="grip-tab">{{constants.grip}}
                  </option>
                  <option value="set-lighting-tab">{{constants.set_lighting}}
                  </option>
                  <option value="special-effects-tab">{{constants.special_effects}}
                  </option>
                  <option value="transportation-tab">{{constants.transportation}}
                  </option>
                  <option value="labor-tab">{{constants.labor}}
                  </option>
                  <option value="utilities-tab">{{constants.utilites}}
                  </option>
                  <option value="studio-tours-tab">{{constants.studio_tours}}
                  </option>
                  <option value="additional-services-tab">
                    {{constants.additional_on_lot_client_employee_services}}
                  </option>
                </select>
              </div>
              
              <div class="col-md-8 col-lg-8 col-sm-12">
                <!-- Tab Content -->
                <div class="tab-content" id="v-pills-tabContent">
                  <!-- OFFICE & PRODUCTION SERVICES (NEW) -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-services-tab'}"
                  id="office-production-services-tab" role="tabpanel" aria-labelledby="office-production-services-tab">
                  <div class="page-details-section" [innerHtml]="directoryContent">
                     
                  </div>  
              </div>
              
              
                  <!-- SAFETY & ENVIRONMENTAL AFFAIRS -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environmental-affairs-tab'}"
                    id="safety-environmental-affairs-tab" role="tabpanel" aria-labelledby="safety-environmental-affairs-tab">
                    <div class="page-details-section"  [innerHtml]="directoryContent">
                        
                    </div>
                    
                  </div>
              
                  <!-- STUDIO PROTECTION GROUP -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-group-tab'}"
                  id="studio-protection-group-tab" role="tabpanel" aria-labelledby="studio-protection-group-tab">
                  <div class="page-details-section"  [innerHtml]="directoryContent">
                      
                  </div>
              </div>
              
              
                  <!-- CONSTRUCTION SERVICES -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'construction-services-tab'}"
                  id="construction-services-tab" role="tabpanel" aria-labelledby="construction-services-tab">
                 <div class="page-details-section"  [innerHtml]="directoryContent">
                     
                 </div>
             </div>
             
            
             
             <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'costume-tab'}"
                  id="costume-tab" role="tabpanel" aria-labelledby="costume-tab">
                 <div class="page-details-section"  [innerHtml]="directoryContent">
                    
                 </div>
             </div>
             
              
                  <!-- DESIGN STUDIO -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'design-studio-tab'}"
                  id="design-studio-tab" role="tabpanel" aria-labelledby="design-studio-tab">
                 <div class="page-details-section"  [innerHtml]="directoryContent">
                    
                 </div>
             </div>
             
             
              
                  <!-- POST PRODUCTION CREATIVE SERVICES -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'post-production-creative-services-tab'}"
                  id="post-production-creative-services-tab" role="tabpanel" aria-labelledby="post-production-creative-services-tab">
                 <div class="page-details-section"  [innerHtml]="directoryContent">
                     
                 </div>
             </div>
             
              
                  <!-- PRODUCTION SOUND & VIDEO -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'production-sound-video-tab'}"
                  id="production-sound-video-tab" role="tabpanel" aria-labelledby="production-sound-video-tab">
                 <div class="page-details-section"  [innerHtml]="directoryContent">
                     
                 </div>
             </div>
             
              
                  <!-- PROPERTY | DRAPERY | FLOOR COVERING -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'property-drapery-floor-covering-tab'}"
                  id="property-drapery-floor-covering-tab" role="tabpanel" aria-labelledby="property-drapery-floor-covering-tab">
                 <div class="page-details-section"  [innerHtml]="directoryContent">
                    
                 </div>
             </div>
             
              
                  <!-- FOOD SERVICES | SPECIAL EVENTS -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'food-services-special-events-tab'}"
                  id="food-services-special-events-tab" role="tabpanel" aria-labelledby="food-services-special-events-tab">
                 <div class="page-details-section"  [innerHtml]="directoryContent">
                    
                 </div>
             </div>
             
              
                  <!-- GRIP -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'grip-tab'}"
                  id="grip-tab" role="tabpanel" aria-labelledby="grip-tab">
                 <div class="page-details-section"  [innerHtml]="directoryContent">

                 </div>
             </div>
             
              
                  <!-- SET LIGHTING -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'set-lighting-tab'}"
                  id="set-lighting-tab" role="tabpanel" aria-labelledby="set-lighting-tab">
                 <div class="page-details-section"  [innerHtml]="directoryContent">
                     
                 </div>
             </div>
             
              
                  <!-- SPECIAL EFFECTS -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'special-effects-tab'}"
                  id="special-effects-tab" role="tabpanel" aria-labelledby="special-effects-tab">
                 <div class="page-details-section"  [innerHtml]="directoryContent">

                 </div>
             </div>
             
              
                  <!-- TRANSPORTATION -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'transportation-tab'}"
                  id="transportation-tab" role="tabpanel" aria-labelledby="transportation-tab">
                 <div class="page-details-section"  [innerHtml]="directoryContent">

                 </div>
             </div>
             
              
                  <!-- LABOR -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'labor-tab'}"
                  id="labor-tab" role="tabpanel" aria-labelledby="labor-tab">
                 <div class="page-details-section"  [innerHtml]="directoryContent">

                 </div>
             </div>
             
              
                  <!-- UTILITIES -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'utilities-tab'}"
                  id="utilities-tab" role="tabpanel" aria-labelledby="utilities-tab">
                 <div class="page-details-section"  [innerHtml]="directoryContent">

                 </div>
             </div>
             
             <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-tours-tab'}"
             id="studio-tours-tab" role="tabpanel" aria-labelledby="studio-tours-tab">
            <div class="page-details-section"  [innerHtml]="directoryContent">

            </div>
        </div>
        
              
                  <!-- ADDITIONAL ON-LOT CLIENT & EMPLOYEE SERVICES -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'additional-services-tab'}"
                  id="additional-services-tab" role="tabpanel" aria-labelledby="additional-services-tab">
                 <div class="page-details-section"  [innerHtml]="directoryContent">
                     
                 </div>
             </div>
             
             
                </div>
              </div>
              

            </div>
            </div>
</div>
