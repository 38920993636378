import { Component, inject, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-faq-edit',
  templateUrl: './faq-edit.component.html',
  styleUrls: ['./faq-edit.component.scss']
})
export class FaqEditComponent implements OnInit {
  private modalService = inject(NgbModal);
  selectedTab: string = 'overview-tab';  // Default tab is 'overview'
  isEditable: boolean = false;           // Controls whether the form is editable
  formGroups: { [key: string]: FormGroup } = {};  // Object to hold form groups for each tab
  sectionToDelete: { tab: string, index: number } | null = null;  // Holds the section to be deleted

  constructor() { }

  ngOnInit(): void {
    this.initializeForms();
  }

  // Initialize form groups for all tabs
  private initializeForms(): void {
    this.formGroups['overview'] = new FormGroup({
      sections: new FormArray([])
    });
    this.formGroups['details'] = new FormGroup({
      sections: new FormArray([])
    });
    this.formGroups['account'] = new FormGroup({
      sections: new FormArray([])
    })
    this.formGroups['projects'] = new FormGroup({
      sections: new FormArray([])
    });
    this.formGroups['requests'] = new FormGroup({
      sections: new FormArray([])
    });
    this.formGroups['users'] = new FormGroup({
      sections: new FormArray([])
    });
    this.formGroups['contactUs'] = new FormGroup({
      sections: new FormArray([])
    });
  }

  // Getters for sections in each tab
  getOverviewSections(): FormArray {
    return this.formGroups['overview'].get('sections') as FormArray;
  }

  getDetailsSections(): FormArray {
    return this.formGroups['details'].get('sections') as FormArray;
  }
  getAccountSections(){
    return this.formGroups['account'].get('sections') as FormArray;
  }

  getProjectsSections(): FormArray {
    return this.formGroups['projects'].get('sections') as FormArray;
  }

  getRequestsSections(): FormArray {
    return this.formGroups['requests'].get('sections') as FormArray;
  }

  getUsersSections(): FormArray {
    return this.formGroups['users'].get('sections') as FormArray;
  }

  getContactUsSections(): FormArray {
    return this.formGroups['contactUs'].get('sections') as FormArray;
  }

  // Add sections to each tab
  addOverviewSection(): void {
    const section = new FormGroup({
      title: new FormControl(null, Validators.required),
      details: new FormControl(null, Validators.required)
    });
    this.getOverviewSections().push(section);
  }

  addDetailsSection(): void {
    const section = new FormGroup({
      summary: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required)
    });
    this.getDetailsSections().push(section);
  }
  addAccountSection(): void {
    const section = new FormGroup({
      summary: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required)
    });
    this.getAccountSections().push(section);
  }
  addProjectsSection(): void {
    const section = new FormGroup({
      projectTitle: new FormControl(null, Validators.required),
      projectDetails: new FormControl(null, Validators.required)
    });
    this.getProjectsSections().push(section);
  }

  addRequestsSection(): void {
    const section = new FormGroup({
      requestTitle: new FormControl(null, Validators.required),
      requestDetails: new FormControl(null, Validators.required)
    });
    this.getRequestsSections().push(section);
  }

  addUsersSection(): void {
    const section = new FormGroup({
      userName: new FormControl(null, Validators.required),
      userEmail: new FormControl(null, Validators.required)
    });
    this.getUsersSections().push(section);
  }

  addContactUsSection(): void {
    const section = new FormGroup({
      contactTitle: new FormControl(null, Validators.required),
      contactDetails: new FormControl(null, Validators.required)
    });
    this.getContactUsSections().push(section);
  }

  // Remove sections from each tab
  removeOverviewSection(index: number): void {
    this.getOverviewSections().removeAt(index);
  }

  removeDetailsSection(index: number): void {
    this.getDetailsSections().removeAt(index);
  }

  removeAccountSection(index: number): void {
    this.getAccountSections().removeAt(index);
  }

  removeProjectsSection(index: number): void {
    this.getProjectsSections().removeAt(index);
  }

  removeRequestsSection(index: number): void {
    this.getRequestsSections().removeAt(index);
  }

  removeUsersSection(index: number): void {
    this.getUsersSections().removeAt(index);
  }

  removeContactUsSection(index: number): void {
    this.getContactUsSections().removeAt(index);
  }

  // Save changes for each tab
  saveChanges(tab: string): void {
    const formGroup = this.formGroups[tab];
    if (formGroup.valid) {
      console.log(`Saved form data for ${tab}:`, formGroup.value);
    } else {
      console.log(`Form for ${tab} is invalid!`);
    }
  }

  // Discard changes for each tab (reinitialize forms)
  discardChanges(tab: string): void {
    this.initializeForms();
  }

  // Handle tab change
  selectTab(tab: string): void {
    this.selectedTab = tab;
  }

  onSelectChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.selectedTab = target.value;
  }

  // Open delete confirmation modal
  openDeleteConfirmationModal(content: TemplateRef<any>, tab: string, index: number): void {
    this.sectionToDelete = { tab, index };
    this.modalService.open(content, { windowClass: 'common-modal-center', centered: true });
  }

  // Confirm delete action
  confirmDelete(): void {
    if (this.sectionToDelete) {
      const { tab, index } = this.sectionToDelete;
      if (tab === 'overview') {
        this.removeOverviewSection(index);
      } else if (tab === 'details') {
        this.removeDetailsSection(index);
      } else if (tab === 'account'){
        this.removeAccountSection(index);
      }else if (tab === 'projects') {
        this.removeProjectsSection(index);
      } else if (tab === 'requests') {
        this.removeRequestsSection(index);
      } else if (tab === 'users') {
        this.removeUsersSection(index);
      } else if (tab === 'contactUs') {
        this.removeContactUsSection(index);
      }
      this.sectionToDelete = null;
    }
    this.modalService.dismissAll();
  }
}
