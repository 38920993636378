import { ChangeDetectorRef, Component, ViewChild, AfterViewInit } from '@angular/core';
import tippy, { hideAll } from 'tippy.js';
import { POP_UP_CELL_RENDERED } from '../constants/common';

@Component({
  selector: 'app-pop-up-cell-renderer',
  templateUrl: './pop-up-cell-renderer.component.html',
  styleUrls: ['./pop-up-cell-renderer.component.scss']
})
export class PopUpCellRendererComponent implements AfterViewInit {
  private params; 
  isOpen: boolean = false;  
  private tippyInstance;  // Variable to store the Tippy.js instance

  popUpDropdown = POP_UP_CELL_RENDERED;

  @ViewChild('content') container;  // Reference to the popup content container
  @ViewChild('trigger') button;  // Reference to the button that triggers the popup

  constructor(private changeDetector: ChangeDetectorRef) {}

  /**
   * ngAfterViewInit() - Lifecycle hook to initialize the Tippy instance after view is initialized
   */
  ngAfterViewInit(): void {
    this.tippyInstance = tippy(this.button.nativeElement, {
      trigger: 'manual',  // Use manual trigger
      placement: 'right',
      arrow: false,
      interactive: true,  // Allow interaction with the popup content
      appendTo: document.body,
      hideOnClick: false,  // Prevent popup from hiding on clicking inside it
      onShow: (instance) => {
        hideAll({ exclude: instance });  // Hide other Tippy popups except the current one
      },
      onClickOutside: (instance) => {
        this.isOpen = false;  // Close the popup when clicking outside
        instance.hide();  // Hide the popup
      },
    });
    this.tippyInstance.disable();  // Disable the Tippy instance initially
  }

  /**
   * agInit() - Method to initialize ag-Grid cell renderer with parameters
   * @param params - The params passed by ag-Grid to initialize the component
   */
  agInit(params) {
    this.params = params; 
  }

  /**
   * configureTippyInstance() - Method to configure and display the Tippy instance
   */
  configureTippyInstance() {
    this.tippyInstance.enable();  // Enable the Tippy instance
    this.tippyInstance.setContent(this.container.nativeElement);  // Set content of the popup
    this.tippyInstance.show();  // Show the Tippy popup
  }

  /**
   * togglePopup() - Method to toggle the popup's visibility
   */
  togglePopup() {
    this.isOpen = !this.isOpen; 
    this.changeDetector.detectChanges(); 

    if (this.isOpen) {
      this.configureTippyInstance();  // Configure and display the popup
    } else {
      this.tippyInstance.hide();  // Hide the popup
    }
  }
}
