import { Component, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-approve-cred-review-app',
  templateUrl: './approve-cred-review-app.component.html',
  styleUrls: ['./approve-cred-review-app.component.scss']
})
export class ApproveCredReviewAppComponent {
  constructor(private modalService:NgbModal){}
  openDeleteConfirmationModal(content: TemplateRef<any>): void {
    this.modalService.open(content, { size: 'lg' , centered: true });
  }
  openDeleteConfirmationModal1(content1: TemplateRef<any>): void {
    this.modalService.open(content1, { size: 'lg' , centered: true });
  }
}
