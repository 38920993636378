<div class="container-fluid handbook-wrapper">
    <div class="container">
        <div class="row" *ngIf="false">
            <div>
                <h1 class="page-title">Hi, <span class="user-name">Laura</span></h1>
                <p class="text-danger">The account “The Movies Company” has been canceled.</p>
                <p class="text-contact">Please contact your account administrator.</p>
            </div>
        </div>
        <div class="row" *ngIf="true">
            <div>
                <h1 class="page-title">Hi, <span class="user-name">Laura</span></h1>
                <p class="text-danger">The account “The Movies Company” has been canceled.</p>
                <button class="btn common-btn-secondary">I want to reactivate my account</button>
            </div>
        </div>
    </div>
</div>