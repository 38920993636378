<button data-bs-toggle="modal" data-bs-target="#addUserCustPortal">Add A New Project</button>
<!-- common modal section-->
<div class="modal fade" id="addUserCustPortal" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
          <div class="cm-header-title text-center mb-5">
            <h5 class="modal-title mb-2" id="commonModalLabel">Terms & Conditions</h5>
            <p class="modal-title-desc">Warner Bros. Studio Facilities</p>
          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
              class="cm-close-text">Close</span></button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
              <div class="modal-left-section me-4">
                <h4 class="mt-4">Credit Application</h4>
                <div class="company-meta-data">
                  <p class="title"><strong><span class="label-title">PROJECT</span></strong>: The new show <span></span></p>
                  <p class="title mb-0"><strong><span class="label-title">Departments:</span></strong></p>
                  <p class="pb-0 pt-0 mb-0">Studio Operations; Transportation; Design Studio; Post Production; Creative Services; Property; Production Catering</p>
                </div>             
                <div class="mb-4 mt-3">
                  <div class="list-group list-group-flush mb-2">
                    <ul class="step-list">
                      <li>
                        <span class="step-icon step-check"></span>
                        <span class="step-text"> Application Form</span>
                      </li>
                      <li>
                        <span class="step-icon step-check"></span>
                        <span class="step-text"> Certification of Insurance</span>
                      </li>
                      <li>
                        <span class="step-icon"></span>
                        <span class="step-text current-step">Terms & Conditions</span>
                      </li>
                      <li>
                        <span class="step-icon"></span>
                        <span class="step-text">Review Application</span>
                      </li>
                    </ul>
                  </div>
                  <p class="required-text">(<span class="star-red">*</span>)Required</p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="container cm-main-container px-0 mx-0">
                <div class="common-modal-input-section grey-bg">
                  <div class="term-condition-desc">
                    <div class="water-mark">
                        <h4>PLACEHOLDER TEXT</h4>
                    </div>
                    <ul class="term-condition-list">
                    <li class="mb-3"><b>TERMS:</b> Net 30 days from date of invoice. Customer agrees to make payments in full to 
                        Warner Bros. Studio Operations  (“WBSO”),  a  division  of  WB  Studio  Enterprises  Inc.,  for  
                        all  amounts  due  according  to  WBSO invoice(s).  Should  Customer  default  in  any  such  
                        payment(s),  WBSO  shall  have  the  right,  without  notice  to  Customer,  to  declare  all  
                        invoice  amounts  immediately  due  and  payable.  In  the  event  WBSO  should  commence  
                        any  action  or  otherwise  seek  to  enforce  these  terms  and  conditions  against  
                        Customer,  Customer  agrees  to  pay reasonable collection and attorney(s) fees, costs and 
                        other related expenses incurred by WBSO, whether or not an action is filed. This agreement 
                        is not transferable or assignable by Customer without the prior written consent of WBSO. 
                        Any sums payable to WBSO shall be paid within the terms stated above or shall bear interest 
                        from the due  date,  at  the  then  prime  rate  or  the  rate  at  which  WBSO  is  then  able  to  
                        borrow  funds,  whichever  is  greater.  This  Application  may  be  used  for  any  and  all  
                        WBSO  departments  regardless  of  those  specifically  identified  above. Venue for all 
                        disputes shall be in the County of Los Angeles.</li>

                        <li class="mb-3">Payments are to be mailed to: WBSO - P.O. Box 847250 - Los Angeles, CA 90084-7250</li>
                        <li class="mb-3">The WBSO terms and condition of sales, shall govern all transactions between WBSO and 
                            Customer, including any additional terms and conditions that may be provided by any WBSO department.</li>
                        <li class="mb-3">WBSO reserves the right to decline credit to Customer at WBSO's sole discretion, and, in the 
                            event credit is extended to Customer, WBSO reserves the right to change or revoke 
                            Customer's credit line on the basis of changes in WBSO's credit policies or Customer's 
                            financial condition and/or payment record.</li>
                        <li class="mb-3">Customer hereby requests WBSO to consider this Application for the purpose of extending 
                            credit and authorizes (i) the references listed on this Application to provide all information 
                            requested in conjunction with this Application; and (ii) WBSO to investigate Customer's credit 
                            information and history as WBSO may deem necessary.</li>
                        <li class="mb-3">The undersigned hereby certifies that he or she is duly authorized to sign this Application on 
                            behalf of Customer, that the information given in this Application is true and correct to the 
                            best of his or her knowledge and that the Customer hereby agrees to the foregoing terms 
                            and conditions.</li>
                    </ul>
                  </div>
                  <div class="mt-5">
                    <form>
                        <div class="row">
                            <div class="col-md-6 custom-floating-label">
                              <label for="termCondiName" class="form-label"> Name</label>
                              <input type="text" class="form-control" placeholder="Name" id="termCondiName">
                            </div>
                            <div class="col-md-6 custom-floating-label">
                                <label for="termCondiTitle" class="form-label"> Title</label>
                                <input type="text" class="form-control" placeholder="Title" id="termCondiTitle">
                              </div>
                              <div class="col-md-6 custom-floating-label">
                                <label for="termCondiSignature" class="form-label"> Signature</label>
                                <input type="text" class="form-control" placeholder="Signature" id="termCondiSignature">
                              </div>
                              <div class="col-md-4 custom-floating-label">
                                <label for="termCondiDate" class="form-label"> Date</label>
                                <input type="text" class="form-control" placeholder="Date" id="termCondiDate">
                              </div>
                          </div>
                    </form>
                  </div>
                </div>

                <div class="cm-btn-section d-flex align-items-center">
                  <div class="left-btn me-auto">
                    <a class="text-link"><img class="back-img" src="../../assets/images/blue-left-icon.svg" alt="Back" />back </a>
                  </div>  
                  <button type="button" class="btn common-btn-secondary" data-bs-dismiss="modal">Next: Terms & Conditions</button>
                  <button type="button" class="btn common-btn-light ms-2" data-bs-dismiss="modal">Save & Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
    <!---Added modals on click of Select Company-->
<ng-template #company let-modal>
  <div class="modal-body">
    <h2 class="section-main-header">Search </h2>
    <div class="">
      <div class="mt-56 custom-floating-label">
        <label for="input1" class="form-label">Search</label>
        <input type="text" class="pe-4 form-control" placeholder="Search company name or company ID #"
          id="input1">
        <img class="search-icon" src="../../../../../../assets/images/search-icon.svg" alt="Search Icon">
      </div>
    </div>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="btn common-btn-secondary" (click)="modal.close('Close click')">Cancel</button>
      <button type="button" class="btn common-btn-secondary" >Select</button>
    </div>
  </div>
</ng-template>