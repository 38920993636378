import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CRED_DEPARTMENT_CONSTANT, CREDIT_APPLICATION_CONSTANTS } from 'src/app/feature/customer-portal/constants/customer-portal.constant';
import { CreditApplicationsService } from 'src/app/feature/customer-portal/services/credit-applications/credit-applications.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-credit-app-sidebar',
  templateUrl: './credit-app-sidebar.component.html',
  styleUrls: ['./credit-app-sidebar.component.scss']
})
export class CreditAppSidebarComponent {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @Input() creditAppStatus: string;
  @Input() projectId: number;
  @Input() projectName: string;
  @Input() caSeqId: number;
  @Input() caNumber?: number;
  @Input() customerStatus;
  startApplicationData;
  completedSteps: boolean[] = [false, false, false, false];
  currentStep: number = 1; // Default to the first step
  departments: any;
  modalReference!: NgbModalRef;
  credFormDepartmentConstant = CRED_DEPARTMENT_CONSTANT;
  credAppConstants = CREDIT_APPLICATION_CONSTANTS;
  departmentForm: FormGroup;
  editDepartmentsResponse: any;
  deptError: any;
  deptEditError: any;
  states: any;
  creditMetaData: any;
  departmentsData: any[] = [];
  revisionData: any;

  constructor(
    private creditApplicationService: CreditApplicationsService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    // Initialize the form selected departments
    this.departmentForm = this.fb.group({
      selectedDepartments: this.fb.array([]),
    });
    this.fetchStateMetaData();
    this.startApplication(this.caSeqId, this.caNumber);
    this.getRevisionForm();

  }


  /**
   * startApplication() - method to get the credit application data
   * @param sequenceId 
   * @param creditNumber 
   */
  startApplication(sequenceId, creditNumber?) {
    this.creditApplicationService.startCreditApplication(sequenceId, creditNumber).subscribe({
      next: (response: any) => {
        this.startApplicationData = response;
        this.departmentsData = this.startApplicationData?.creditApplicationList?.caDepartments;
      },
      error: (error) => {
      }
    })
  }

  /**
   * selectedDepartments() - get selected departments
   */
  get selectedDepartments() {
    return (this.departmentForm.get('selectedDepartments') as FormArray);
  }

  /**
   * selectStep() - method to change the step 
   * @param step - the current step/form
   */
  selectStep(step: number) {
    this.currentStep = step;
  }

  /**
     * fetchStateMetaData() - API call to fetch the data for states
     */
  fetchStateMetaData() {
    this.creditApplicationService.getCreditApplicationMetaData().subscribe({
      next: (response: any) => {
        if (response) {
          const res = response;
          this.creditMetaData = response?.pageData?.termConditionUrlData;
          this.departments = response?.pageData?.departments;
          this.states = response?.pageData?.certOfInsuranceStates?.countryWithStates?.states;
        } else {
          console.warn('Profile data could not be retrieved.');
        }
      },
      error: (error) => {
        console.error('Error fetching user profile:', error);
      },
    });
  }

  /**
   * openEditDepartmentsModal() - Method to open the edit deaprtments modal.
   * @param editDepartmentsModal - Template reference for the edit deaprtments modal.
   */
  openEditDepartmentsModal(editDepartmentsModal: TemplateRef<any>) {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(editDepartmentsModal, { size: 'lg', centered: true });
  }

  /**
   * toggleDepartment() - Method to handle toggle checkbox
   */
  toggleDepartment(departmentId: number, event: any) {
    const selectedDepartments = this.selectedDepartments;
    if (event.target.checked) {
      selectedDepartments.push(this.fb.control(departmentId));
    } else {
      const index = selectedDepartments.controls.findIndex(
        (control) => control.value === departmentId
      );
      if (index >= 0) {
        selectedDepartments.removeAt(index);
      }
    }
  }

  /**
   * isSelected() - Method to check whether checkbox is selected 
   */
  isSelected(departmentId: number): boolean {
    return this.selectedDepartments.controls.some(control => control.value === departmentId);
  }

  /**
   * saveChanges() - Method to save departments 
   */
  saveChanges() {
    this.loaderService.setLoadingState(true);
    const selectedDeptIds = this.selectedDepartments.value;
    const payload = {
      companyId: "1",
      projectId: "1",
      caSeqId: "39",
      departmentsIds: selectedDeptIds
    }
    if (payload) {
      this.editDepartmentsCallAPI(payload);
    }
  }
  /**
   * editDepartmentsCallAPI() - Method to edit departments 
   */
  editDepartmentsCallAPI(payload: any): void {
    this.creditApplicationService.editDepartments(payload).subscribe({
      next: (response: any) => {
        this.editDepartmentsResponse = response;
        this.loaderService.setLoadingState(false);
        this.showSuccess(this.successTpl);
        this.modalService.dismissAll();
      },
      error: (error) => {
        console.error('Error', error);
        this.deptEditError = error.message;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
   * cancel() - Method to reset department form
   */
  cancel(): void {
    this.departmentForm.reset();
    this.modalService.dismissAll();
  }

  /**
 * showSuccess() - Displays a success toast notification with custom content and options.
 * @param successTpl - The template reference containing the toast's content.
 */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
  * showDanger() - Method to display a danger (error) toast message
  * @param dangerTpl - The template reference for the danger message to display
  */
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }
  /**
 * closeModal() - method to close the modal when called.
 */
  closeModal(): void {
    this.activeModal.close();
  }

  /**
 * nextStep() - method to go to the next step
 */
  nextStep() {
    this.currentStep++;
  }

  /**
   * previousStep() - method to go to the previous step
   */
  previousStep() {
    this.currentStep--;
  }

  /**
   * onStepCompleted() - method to check if the step is completed
   * @param step - the completed step
   */
  onStepCompleted(step: number) {
    this.completedSteps[step - 1] = true; // Mark the current step as completed
  }

   /**
   * getRevisionForm() - the get the revision form data
   * @param caSeqId - the caseq need to be passed
   * @param caNumber - the caNumber need to be passed
   */
   getRevisionForm(): void {
    this.creditApplicationService.getRevisionForm(61, 61).subscribe({
      next: (response) => {
        this.revisionData = response
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
}