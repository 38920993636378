export const FAQ = {
  faq: 'FAQs',
  overview: 'Overview',
  account: 'Company',
  your_projects: 'Your Projects',
  requests: 'Requests',
  users: 'Users',
  contact_us: 'Contact Us',
  client_resource: 'Client Resources',
  overview_tab: 'overview-tab',
  account_tab: 'account-tab',
  your_projects_tab: 'projects-tab',
  requests_tab: 'requests-tab',
  users_tab: 'users-tab',
  contact_us_tab: 'contact-us-tab',
  client_resource_tab: 'client-resources-tab',
  dot: '.',
  edit_mode: 'Edit Mode',
  view_mode: 'View Mode',
  edit: 'Edit',
  view: 'View'
}

export const CUSTOMER_MENU_LABELS = {
  overview: 'Overview',
  projects: 'Projects',
  requests: 'Requests',
  company: 'Company'
};

export const STUDIO_MENU_LABELS = {
  myDashboard: 'My Dashboard',
  projects: 'Projects',
  users: 'Users',
  company: 'Company',
  companies: 'Companies',
  creditAplication: 'Credit Applications',
  request: 'Requests'
};
export const AR_MENU_LABELS = {
  myDashboard: 'My Dashboard',
  projects: 'Projects',
  users: 'Users',
  company: 'Company',
  companies: 'Companies',
  creditAplication: 'Credit Applications',
  request: 'Requests'
};

export const WBSO = {
  handbook: 'Handbook',
  office_production_services: 'Office & Production Services (OPS)',
  purchasing_and_billing: 'Purchasing & Billing',
  studio_protection_group: 'Studio Protection Group / Emergency Response',
  safety_and_enivironmental_affairs: 'Safety & Environmental Affairs',
  paragraph_text: 'The Handbook is designed to introduce you and the members of your production team to our studios and the operational procedures and policies that exist for all users of the Studio. We hope that you will find this manual to be a useful reference tool during your production here at Warner Bros. Studios.'
}
export const WBSO_page = {
  studio_protection_0_tab: 'studio-protection-0-tab',
  handbook: 'Handbook',
  burbank: 'Burbank',
  action_1: 'Action 1',
  action_2: 'Action 2',
  action_3: 'Action 3',
  dot: '.',
  hyphen: '-',
  office_and_production_services: 'Office & Production Services (OPS)',
  overview: '1.0 – Overview',
  sound_stage_chart: '1.1 – Sound Stage Chart',
  stage_check_in_and_check_out: "1.2 – Stage Check-In and Check-Out",
  general_stage_housekeeping: 'General Stage Housekeeping',
  hot_lock_and_stage_card_access_procedures: 'Hot Lock & Stage Card Access Procedures',
  stage_specs: 'Stage Specs',
  overhead_loading_roof_trusses_and_permanent_beams: 'Overhead Loading – Roof Trusses & Permanent Beams',
  permanent_construction_requests: 'Permanent Construction Requests',
  stage_lighting_guidelines: 'Stage Lighting Guidelines',
  stage_dressing_rooms: 'Stage Dressing Rooms',
  production_guidelines_exterior_sets: 'Production Guidelines – Exterior Sets',
  notification_policy_for_surrounding_neighborhoods: 'Notification Policy For Surrounding Neighborhoods',
  notification_policy_for_surrounding_neighborhoods_main_lot_and_ranch: 'Notification Policy For Surrounding Neighborhoods (Main Lot And Ranch)',
  daily_call_sheets_prod_schedules_staff_crew: 'Daily Call Sheets | Prod. Schedules | Staff & Crew',
  daily_call_sheets: 'Daily Call Sheets',
  radio_frequency_equipment_prod_sound_video: 'Radio Frequency Equipment – Prod. Sound & Video',
  radio_frequency_equipment_wbso_production_sound_video: 'Radio Frequency Equipment - WBSO Production Sound & Video',
  camera_policy_press_publicity_access_procedures: 'Camera Policy & Press/Publicity Access Procedures',
  production_guidelines_water_sets: 'Production Guidelines - Water Sets',
  office_guidelines: 'Office Guidelines',
  furniture: 'Furniture',
  phones_wifi_studionet: 'Phones / Wi-Fi / StudioNet',
  custodial_service: 'Custodial Service',
  signage_exterior_display: 'Signage/Exterior Display',
  conference_room_bookings: 'Conference Room Bookings',
  key_shop: 'Key Shop',
  parking_services: 'Parking Services',
  warner_bros_labor_dept: 'Warner Bros. Labor Dept.',
  recycling_guidelines_social_vocational_services: 'Recycling Guidelines / Social Vocational Services',
  smoking_policy: 'Smoking Policy',
  definition_of_smoking: 'Definition of Smoking',
  smoking_in_prohibited_areas: 'Smoking In Prohibited Areas',
  office_production_tab: 'office-production-tab',
  office_production_0_tab: 'office-production-0-tab',
  office_production_1_tab: 'office-production-1-tab',
  office_production_2_tab: 'office-production-2-tab',
  office_production_3_tab: 'office-production-3-tab',
  office_production_4_tab: 'office-production-4-tab',
  office_production_5_tab: 'office-production-5-tab',
  office_production_6_tab: 'office-production-6-tab',
  office_production_7_tab: 'office-production-7-tab',
  office_production_8_tab: 'office-production-8-tab',
  office_production_9_tab: 'office-production-9-tab',
  office_production_10_tab: 'office-production-10-tab',
  office_production_11_tab: 'office-production-11-tab',
  office_production_12_tab: 'office-production-12-tab',
  office_production_13_tab: 'office-production-13-tab',
  office_production_14_tab: 'office-production-14-tab',
  office_production_15_tab: 'office-production-15-tab',
  office_production_16_tab: 'office-production-16-tab',
  office_production_17_tab: 'office-production-17-tab',
  office_production_18_tab: 'office-production-18-tab',
  office_production_19_tab: 'office-production-19-tab',
  office_production_20_tab: 'office-production-20-tab',
  office_production_21_tab: 'office-production-21-tab',
  office_production_22_tab: 'office-production-22-tab',
  office_production_23_tab: 'office-production-23-tab',
  office_production_24_tab: 'office-production-24-tab',
  office_production_25_tab: 'office-production-25-tab',
  office_production_26_tab: 'office-production-26-tab',
  purchase_billing_tab: 'purchase-billing-tab',
  purchase_billing_0_tab: 'purchase-billing-0-tab',
  purchase_billing_1_tab: 'purchase-billing-1-tab',
  purchase_billing_2_tab: 'purchase-billing-2-tab',
  purchase_billing_3_tab: 'purchase-billing-3-tab',
  purchase_billing_4_tab: 'purchase-billing-4-tab',
  purchase_billing_5_tab: 'purchase-billing-5-tab',
  purchasing_and_billing: 'Purchasing and Billing',
  accounts_receivable_numbers: 'Accounts Receivable Numbers (AR Number)',
  studio_work_authorizations: 'Studio Work Authorizations (SWA/Internal POs)',
  studio_work_authorization: 'Studio Work Authorizations (SWA/Internal PO’s)',
  quick_reference_guide_to_swa_creation: 'A Quick Reference Guide To SWA Creation',
  studio_work_authorization_form: 'Studio Work Authorization Form',
  construction_flat_bids: 'Construction Flat Bids',
  invoices: 'Invoices',
  studio_protection_tab: 'studio-protection-tab',
  studio_protection_1_tab: 'studio-protection-1-tab',
  studio_protection_2_tab: 'studio-protection-2-tab',
  studio_protection_3_tab: 'studio-protection-3-tab',
  studio_protection_4_tab: 'studio-protection-4-tab',
  studio_protection_5_tab: 'studio-protection-5-tab',
  studio_protection_6_tab: 'studio-protection-6-tab',
  studio_protection_7_tab: 'studio-protection-7-tab',
  studio_protection_8_tab: 'studio-protection-8-tab',
  studio_protection_9_tab: 'studio-protection-9-tab',
  studio_protection_10_tab: 'studio-protection-10-tab',
  studio_protection_11_tab: 'studio-protection-11-tab',
  studio_protection_12_tab: 'studio-protection-12-tab',
  studio_protection_13_tab: 'studio-protection-13-tab',
  studio_protection_14_tab: 'studio-protection-14-tab',
  studio_protection_group_emergency_response: 'Studio Protection Group / Emergency Response',
  studio_protection_group: 'Studio Protection Group (SPG)',
  wb_security_primary_contacts: 'WB Security - Primary Contacts',
  warner_bros_main_lot_gate_hours_info: 'Warner Bros.Main Lot Gate Hours & Info',
  gate_hours: 'Gate Hours',
  studio_access_policies_and_procedures: 'Studio Access Policies & Procedures',
  access_categories_and_pass_types: 'Access Categories & Pass Types.',
  wbso_pass_request_form: 'WBSO Pass Request Form',
  wbso_call_sheet: 'WBSO Call Sheet.',
  wbso_security: 'WBSO Security - Traffic Department: Rules & Enforcement',
  wbso_security_production_services: 'WBSO Security - Production Services',
  wbso_security_vendor_delivery_policies: 'WBSO Security - Vendor Delivery Policies',
  warner_bros_entertainment_inc_firearms_weapons_policy: 'Warner Bros. Entertainment Inc. Firearms-Weapons Policy',
  spg_warner_bros_first_aid_dept_information_and_regulations: 'SPG: Warner Bros. First Aid Dept. - Information & Regulations',
  wbso_first_aid_health_and_wellness_services: 'WBSO First Aid / Health & Wellness Services',
  spg_warner_bros_fire_dept_information_and_regulations: 'SPG: Warner Bros. Fire Dept. - Information & Regulations',
  general_requirements: 'General Requirements',
  evacuation_check_list: 'Evacuation Check List',
  safety_environment_tab: 'safety-environment-tab',
  safety_environment_0_tab: 'safety-environment-0-tab',
  safety_environment_1_tab: 'safety-environment-1-tab',
  safety_environment_2_tab: 'safety-environment-2-tab',
  safety_environment_3_tab: 'safety-environment-3-tab',
  safety_environment_4_tab: 'safety-environment-4-tab',
  safety_environment_5_tab: 'safety-environment-5-tab',
  safety_environment_6_tab: 'safety-environment-6-tab',
  safety_environment_7_tab: 'safety-environment-7-tab',
  safety_environment_8_tab: 'safety-environment-8-tab',
  safety_environment_9_tab: 'safety-environment-9-tab',
  safety_environment_10_tab: 'safety-environment-10-tab',
  safety_environment_11_tab: 'safety-environment-11-tab',
  safety_environment_12_tab: 'safety-environment-12-tab',
  safety_environment_13_tab: 'safety-environment-13-tab',
  safety_environment_14_tab: 'safety-environment-14-tab',
  safety_environment_15_tab: 'safety-environment-15-tab',
  safety_and_environmental_affairs: 'Safety & Environmental Affairs',
  primary_contacts: 'Primary Contacts: On-lot Fire/Medical/Safety Emergency Line and S&EA Team',
  iipp: 'Injury, Illness Prevention Program (IIPP)',
  important_points_to_help_keep_your_employees_safe: 'Important Points To Help Keep Your Employees Safe',
  ten_important_points_to_help_keep_your_employees_safe: 'Ten Important Points to Help Keep Your Employees Safe',
  staff_and_crew_training: 'Staff & Crew Training',
  production_start_up_guidelines_for_warner_bros_productions: 'Production Start-Up Guidelines For Warner Bros. Productions',
  productions_not_affiliated_with_warner_bros: 'Productions Not Affiliated with Warner Bros.',
  inspection_procedures_for_osha_and_other_regulatory_agencies: 'Inspection Procedures For OSHA And Other Regulatory Agencies',
  craft_service: 'Craft Service: Food Preparation On Sets And Mobile Food Vehicles',
  air_quality: 'Air Quality: Production Considerations',
  genral_warning_and_notifications_set_construction_safety: `Genral Warning's and Notifications/ Set Construction Safety`,
  managing_chemical_containing_products: 'Managing Chemical-Containing Products',
  wbso_sewer_and_storm_drain_policies: 'WBSO Sewer and Storm Drain Policies',
  additional_storm_drain_policy_guidelines_by_department: 'Additional Storm Drain Policy Guidelines – By Department',
  guidelines_for_shipping_dangerous_goods: 'Guidelines for Shipping Dangerous Goods',
  unmanned_aerial_vehicles_unmanned_aerial_camera_platforms_drones: 'Unmanned Aerial Vehicles / Unmanned Aerial Camera Platforms / Drones',
  primary_contact: 'Primary Contacts',
  hot_lock_access: 'Hot Lock Access',
  card_swipe_access: 'Card Swipe Access',
  temporary_access_memos: 'Temporary Access Memos',
}

export const header = {
  wbso_handbook: 'WBSO Handbook',
  maps: 'Maps',
  directory: 'Directory',
  covid: 'COVID-19',
  faq: 'FAQs',
  client_resource: 'Client Resources',
  studios_portal: 'Studios Portal',
  accounts: 'Accounts',
  overview: 'Overview',
  myDashboard: 'My Dashboard',
  companies: 'Companies',
  requests: 'Requests',
  company: 'Company',
  sign_in_to_wb_studios_portal: 'Sign In to WB Studios Portal',
  email_address: 'Email address',
  password: 'Password',
  forgot_password: 'Forgot Password',
  sign_in: 'Sign in',
  logout: 'Logout',
  not_a_client_yet: 'Not a client yet?',
  contact_us: 'contact us',
  modal_title: 'Modal Title',
  close: 'Close',
  subtitle_description_meta_data: 'Subtitle / Description / Meta Data',
  section_header: 'Section header',
  group_name: 'Group Name',
  label: 'label',
  option: 'Option',
  all: 'All',
  section_header_header: 'Section Header',
  sections_and_permissions: 'SECTIONS & PERMISSIONS',
  access: 'Access',
  accordion_item: 'Accordion Item #1',
  placeholder_content_for_this_accordion: 'Placeholder content for this accordion.',
  secondary_cta: 'Secondary CTA',
  primary_cta: 'Primary CTA',
  projects: 'Projects',
  users: 'Users',
  build_version: 'V2.0.0'
}

export const login = {
  myprofile: 'My Profile',
  logout: 'Logout',
  signin: 'Sign In'
}

export const editfaq = {
  overview: 'Overview',
  add_faq: '+ Add FAQ',
  title: 'Title',
  details: 'Details',
  save_changes: 'Save Changes',
  discard: 'Discard',
  add_detail: '+ Add Detail',
  summary: 'Summary',
  description: 'Description',
  account: 'Company',
  add_account_info: '+ Add Account Info',
  account_info_title: 'Account Info Title',
  account_info_details: 'Account Info Details',
  projects: 'Your Project',
  add_project: '+ Add Project',
  project_title: 'Project Title',
  project_details: 'Project Details',
  requests: 'Request',
  add_request: '+ Add Request',
  request_title: 'Request Title',
  request_details: 'Request Details',
  users: 'Users',
  add_user: '+ Add User',
  user_name: 'User Title',
  user_details: 'User Details',
  contact_us: 'Contact Us',
  add_contact_info: '+ Add Contact Info',
  contact_info_title: 'Contact Info Title',
  contact_info_details: 'Contact Info Details',
  are_you_sure_you_want_to_delete: 'Are you sure you want to delete?',
  confirm_save_changes: 'Would you like to save changes?',
  ok: 'Ok',
  cancel: 'Cancel',
  dot: '.',

  faq: 'FAQs',
  client_resource: 'Client Resource',
  overview_tab: 'overview-tab',
  details_tab: 'details-tab',
  account_tab: 'account-tab',
  your_projects_tab: 'projects-tab',
  requests_tab: 'requests-tab',
  users_tab: 'users-tab',
  contact_us_tab: 'contact-us-tab',
  client_resource_tab: 'client-resources-tab',
  edit_mode: 'Edit Mode',
  view_mode: 'View Mode',
  edit: 'Edit',
  view: 'View',

  overview_faq: 'overview',
  details_faq: 'details',
  account_faq: 'account',
  projects_faq: 'projects',
  requests_faq: 'requests',
  users_faq: 'users',
  contactus_faq: 'contactUs',
  client_resource_faq: 'clientResources',
  sections: 'sections',
}
export const core_directory = {
  directory: 'Directory',
  you_are_viewing_the_directory_for: 'You are viewing the Directory for',
  burbank: 'Burbank',
  action_1: 'Action 1',
  action_2: 'Action 2',
  action_3: 'Action 3',
  office_and_production_services: 'Office And Production Services',
  safety_and_environment_affairs: 'Safety And Environmental Affairs',
  studio_protection_group: 'Studio Protection Group',
  construction_services: 'Construction Services',
  costume: 'Costume',
  design_studio: 'Design Studio',
  post_production_creative_services: 'Post Production Creative Services',
  production_sound_and_video: 'Production Sound And Video',
  property_drapery_floor_covering: 'Property Drapery Floor Covering',
  food_services_special_events: 'Food Services Special Events',
  grip: 'Grip',
  set_lighting: 'Set Lighting',
  special_effects: 'Special Effects',
  transportation: 'Transportation',
  labor: 'Labor',
  utilites: 'Utilities',
  studio_tours: 'Studio Tours',
  additional_on_lot_client_employee_services: 'Additional On Lot Client Employee Services',
  dot: '.',
  office_production_services_tab: 'office-production-services-tab',
  safety_environmental_affairs_tab: 'safety-environmental-affairs-tab',
  studio_protection_group_tab: 'studio-protection-group-tab',
  construction_services_tab: 'construction-services-tab',
  costume_tab: 'costume-tab',
  design_studio_tab: 'design-studio-tab',
  post_production_creative_services_tab: 'post-production-creative-services-tab',
  production_sound_video_tab: 'production-sound-video-tab',
  property_drapery_floor_covering_tab: 'property-drapery-floor-covering-tab',
  food_services_special_events_tab: 'food-services-special-events-tab',
  grip_tab: 'grip-tab',
  set_lighting_tab: 'set-lighting-tab',
  special_effects_tab: 'special-effects-tab',
  transportation_tab: 'transportation-tab',
  labor_tab: 'labor-tab',
  utilities_tab: 'utilities-tab',
  studio_tours_tab: 'studio-tours-tab',
  additional_services_tab: 'additional-services-tab',

}
export const maps = {
  maps: 'Maps',
  main_lot: 'Main Lot',
  ranch: 'Ranch',
  ev_chargers: 'EV Chargers',
  conference_rooms: 'Conference Rooms',
  studio_protection_group: '3.0 Studio Protection Group (SPG)',
  dot: '.',
  map_1: 'Map 1',
  map_2: 'Map 2',
  map_3: 'Map 3',
  map_4: 'Map 4',
  map_5: 'Map 5',
  you_are_viewing_the_directory_for: 'You are viewing the directory for',
  burbank: 'Burbank',
  leavesden: 'Leavesden',
  main_lot_tab: 'main-lot-tab',
  ev_chargers_tab: 'ev-chargers-tab',
  conference_rooms_tab: 'conference-rooms-tab',
  ranch_tab: 'ranch-tab'
}
export const footer = {
  studios_portal: 'Studios Portal',
  portal: 'Portal:',
  overview: 'Overview',
  projects: 'Projects',
  requests: 'Requests',
  company: 'Company',
  myDashboard: 'Mydashboard',
  users: 'Users',
  companies: 'Companies',
  creditApplications: 'Credit Applications',
  client_resources: 'Client Resources:',
  handbook: 'Handbook',
  maps: 'Maps',
  directory: 'Directory',
  faqs: 'FAQs',
  wb_burbank: 'WB Burbank',
  food_services: 'Food Services',
  safety_on_the_set: 'Safety On The Set',
  wb_leavesden: 'WB Leavesden',
  privacy_policy: 'Privacy Policy',
  terms_conditions: 'Terms & Conditions',
  para_text: '© 2024 Warner Bros. Discovery, Inc. or its subsidiaries and affiliates.',
  paragraph_text: 'All trademarks are the property of their respective owners. All rights reserved.'
}

export const EDIT_FAQ_SUCCESS_MESSAGES = {
  addSuccess: 'FAQ(s) Added Successfully',
  editSuccess: 'FAQ(s) Data Updated Successfully',
  deleteSuccess: 'FAQ(s) Deleted Successfully'
}

export const handbookTab = [
  '1.0 – Overview',
  '1.1 – Sound Stage Chart',
  '1.2 – Stage Check-In and Check-Out',
  '1.3 – General Stage Housekeeping',
  '1.4 – Hot Lock & Stage Card Access Procedures',
  '1.5 – Stage Specs',
  '1.6 – Overhead Loading – Roof Trusses & Permanent Beams',
  '1.7 – Permanent Construction Requests',
  '1.8 – Stage Lighting Guidelines',
  '1.9 – Stage Dressing Rooms',
  '1.10 – Production Guidelines – Exterior Sets',
  '1.11 – Notification Policy For Surrounding Neighborhoods',
  '1.12 – Daily Call Sheets',
  '1.13 – Radio Frequency Equipment – WBSO Production Sound & Video',
  '1.14 – Camera Policy & Press/Publicity Access Procedures',
  '1.15 – Production Guidelines – Water Sets',
  '1.16 – Office Guidelines',
  '1.17 – Furniture',
  '1.18 – Phones / Wi-Fi / StudioNet',
  '1.19 – Custodial Service',
  '1.20 – Signage/Exterior Display',
  '1.21 – Conference Room Bookings',
  '1.22 – Key Shop',
  '1.23 – Parking Services',
  '1.24 – Warner Bros. Labor Dept.',
  '1.25 – Recycling Guidelines / Social Vocational Services',
  '1.26 – Smoking Policy'
];

export const handbookTab1 = [
  "2.0 – Accounts Receivable Numbers (AR Number)",
  "2.1 – Studio Work Authorizations (Swa/Internal Po’s)",
  "2.2 – A Quick Reference Guide To SWA Creation",
  "2.3 – Studio Work Authorization Form",
  "2.4 – Construction Flat Bids",
  "2.5 – Invoices"
];
export const handbookTab2 = [
  "3.0 – Studio Protection Group (SPG) ",
  "3.1 – WB Security – Primary Contacts",
  "3.2 – Warner Bros. Main Lot Gate Hours & Info",
  "3.3 – Studio Access Policies & Procedures",
  "3.4 –  Access Categories & Pass Types",
  "3.5 – WBSO Pass Request Form",
  "3.6 – WBSO Security – Traffic Department: Rules & Enforcement",
  "3.7 – WBSO Security – Production Services",
  "3.8 – WBSO Security – Vendor Delivery Policies",
  "3.9 – Warner Bros. Entertainment Inc. Firearms-Weapons Policy ",
  "3.10 – SPG: Warner Bros. First Aid Dept. – Information & Regulations",
  "3.11 – WBSO First Aid / Health & Wellness Services",
  "3.12 – SPG: Warner Bros. Fire Dept. – Information & Regulations",
  "3.13 – General Requirements",
  "3.14 – Evacuation Check List"
];