import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, Renderer2, HostListener } from '@angular/core';

@Component({
  selector: 'app-cred-app-form-one',
  templateUrl: './cred-app-form-one.component.html',
  styleUrls: ['./cred-app-form-one.component.scss']
})
export class CredAppFormOneComponent {
  constructor(private renderer: Renderer2, private elRef: ElementRef) { }
  @ViewChild('fileInput') fileInput!: ElementRef;  // Reference to the file input element
  isFileOver = false;
  files: File[] = [];
  sidebarVisible: boolean=false;
  noPaddingTop :boolean= false;
  helpSliderVisible :boolean=false;
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = false;

    if (event.dataTransfer?.files) {
      this.addFiles(event.dataTransfer.files);
    }
  }

  onFileSelect(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.addFiles(input.files);
    }
    this.resetFileInput();  // Reset input after handling the selection
  }

  addFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files[i]);
    }
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  // Reset the file input to allow re-uploading of the same files
  resetFileInput() {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }
  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
    this.helpSliderVisible=!this.helpSliderVisible;
    console.log("hi help",this.sidebarVisible);
  }
  sticky: boolean = false;
stickyPoint: number = 100;
@HostListener('window:scroll', ['$event'])
handleScroll() {
  const windowScroll = window.pageYOffset;
  this.sticky = windowScroll >= this.stickyPoint;
  this.noPaddingTop = windowScroll >=  this.stickyPoint;
}
ngAfterViewInit() {
  this.renderer.listen('document', 'click', (event: Event) => {
    const target = event.target as HTMLElement;
    if (this.helpSliderVisible && !this.elRef.nativeElement.contains(target)) {
      this.closeHelpSlider();
    }
  });
}

closeHelpSlider() {
  this.helpSliderVisible = false;
}
}
