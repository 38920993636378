import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-review-step-two',
  templateUrl: './credit-review-step-two.component.html',
  styleUrls: ['./credit-review-step-two.component.scss']
})
export class CreditReviewStepTwoComponent {

}
