<button data-bs-toggle="modal" data-bs-target="#addUserCustPortal">Add A Company</button>
<!-- common modal section-->
<div class="modal fade" id="addUserCustPortal" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content common-modal-xl">
            <div class="modal-body justify-content-center align-items-center">
                <div class="cm-header-section">
                    <h5 class="modal-title" id="commonModalLabel">Add A Company</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                            class="cm-close-text">Close</span></button>
                </div>
                <div class="container cm-main-container">
                
                <div class="common-modal-input-section custom-internal-use-bg-shadow">
                  <h2 class="section-main-header">Company Type</h2>
                  <div class="mt-5">
                    <p class="text-color-red">For Internal use only</p>
                    <form>
                      <div class="row">
                    <div class="col-md-5 custom-dropdown">
                      <select class="custom-select">
                        <option class="dropdown-option" value="" disabled selected hidden>Select</option>
                        <option class="dropdown-option" value="1">Projects</option>
                        <option class="dropdown-option" value="2">Users</option>
                        <option class="dropdown-option" value="3">Spaces & Resources</option>
                        <option class="dropdown-option" value="3">Company</option>
                      </select>  
                    </div>
                  </div>
                </form>
                </div>
                </div>
                    <div class="common-modal-input-section">
                      <h2 class="section-main-header">Production Company Name</h2>
                        <form>
                            <div class="row">
                                <div class="col-md-12 custom-floating-label">
                                    <label for="input1" class="form-label"> Name</label>
                                    <input type="text" class="form-control" placeholder="Name" id="input1">
                                </div>
                                <p>Production Legal Name</p>
                                
                            </div>
                            
                        </form>
                    </div>
                    <div class="common-modal-input-section">
                      <h2 class="section-main-header">Production Company Address</h2>
                        <form>
                            <div class="row">
                              <div class="col-md-4 mb-5 custom-dropdown custom-floating-label">
                                <label for="input1" class="form-label">Country</label>
                                <select class="custom-select">
                                  <option class="dropdown-item" value="" disabled selected hidden>United States</option>
                                  <option class="dropdown-item" value="1">Projects</option>
                                  <option class="dropdown-item" value="2">Users</option>
                                  <option class="dropdown-item" value="3">Spaces & Resources</option>
                                  <option class="dropdown-item" value="3">Company</option>
                                </select>  
                              </div>
                                <div class="col-md-12 custom-floating-label">
                                    <label for="input1" class="form-label">Street</label>
                                    <input type="text" class="form-control" placeholder="Street" id="input1">
                                </div>
                                <div class="col-md-5 custom-floating-label">
                                  <label for="input1" class="form-label">City</label>
                                  <input type="text" class="form-control" placeholder="City" id="input1">
                              </div>
                              <div class="col-md-3 custom-dropdown custom-floating-label">
                                <label for="input1" class="form-label">State</label>
                                <select class="custom-select">
                                  <option class="dropdown-option" value="" disabled selected hidden>State</option>
                                  <option class="dropdown-option" value="1">Projects</option>
                                  <option class="dropdown-option" value="2">Users</option>
                                  <option class="dropdown-option" value="3">Spaces & Resources</option>
                                  <option class="dropdown-option" value="3">Company</option>
                                </select>  
                              </div>
                            <div class="col-md-3 custom-floating-label">
                              <label for="input1" class="form-label">Zip Code</label>
                              <input type="text" class="form-control" placeholder="Zip Code" id="input1">
                          </div>
                          <div class="col-md-5 custom-floating-label">
                            <label for="input1" class="form-label">Phone</label>
                            <input type="text" class="form-control" placeholder="Phone" id="input1">
                        </div>
                        <div >
                          <label class="custom-checkbox">
                            <input type="checkbox">
                            <span class="checkmark"></span>
                            Use for Billing Address
                          </label>
                        </div>
                            </div>
                            
                        </form>
                    </div>
                    <div class="common-modal-input-section">
                      <h2 class="section-main-header">Billing Address</h2>
                        <form>
                            <div class="row">
                              <div class="col-md-6 mb-5 custom-dropdown custom-floating-label">
                                <label for="input1" class="form-label">Country</label>
                                <select class="custom-select">
                                  <option class="dropdown-item" value="" disabled selected hidden>United States</option>
                                  <option class="dropdown-item" value="1">Projects</option>
                                  <option class="dropdown-item" value="2">Users</option>
                                  <option class="dropdown-item" value="3">Spaces & Resources</option>
                                  <option class="dropdown-item" value="3">Company</option>
                                </select>  
                              </div>
                                <div class="col-md-12 custom-floating-label">
                                    <label for="input1" class="form-label">Street</label>
                                    <input type="text" class="form-control" placeholder="Street" id="input1">
                                </div>

                                <div class="col-md-5 custom-floating-label">
                                  <label for="input1" class="form-label">City</label>
                                  <input type="text" class="form-control" placeholder="City" id="input1">
                              </div>
                              <div class="col-md-3 custom-dropdown custom-floating-label">
                                <label for="input1" class="form-label">State</label>
                                <select class="custom-select">
                                  <option class="dropdown-option" value="" disabled selected hidden>State</option>
                                  <option class="dropdown-option" value="1">Projects</option>
                                  <option class="dropdown-option" value="2">Users</option>
                                  <option class="dropdown-option" value="3">Spaces & Resources</option>
                                  <option class="dropdown-option" value="3">Company</option>
                                </select>  
                              </div>
                            <div class="col-md-3 custom-floating-label">
                              <label for="input1" class="form-label">Zip Code</label>
                              <input type="text" class="form-control" placeholder="Zip Code" id="input1">
                          </div>
                          <div class="col-md-5 custom-floating-label">
                            <label for="input1" class="form-label">Phone</label>
                            <input type="text" class="form-control" placeholder="Phone" id="input1">
                        </div>
                            </div>
                            
                        </form>
                    </div>
                    <div class="common-modal-input-section">
                      <h2 class="section-main-header">Federal Tax ID #</h2>
                        <form>
                            <div class="row">
                                <div class="col-md-12 custom-floating-label">
                                    <label for="input1" class="form-label">TN</label>
                                    <input type="text" class="form-control" placeholder="TN" id="input1">
                                </div>
                                
                            </div>
                            
                        </form>
                    </div>
                    <div class="common-modal-input-section">
                      <h2 class="section-main-header">Contacts</h2>
                        <form>
                            <div class="row">
                              <div class="col-md-6 custom-floating-label">
                                <label for="input1" class="form-label">First Name</label>
                                <input type="text" class="form-control" placeholder="First Name" id="input1">
                            </div>
                                <div class="col-md-6 custom-floating-label">
                                    <label for="input1" class="form-label">Last Name</label>
                                    <input type="text" class="form-control" placeholder="Last Name" id="input1">
                                </div>
                          
                            <div class="col-md-6 custom-floating-label">
                              <label for="input1" class="form-label">Email</label>
                              <input type="text" class="form-control" placeholder="Email" id="input1">
                          </div>
                          <div class="col-md-6 custom-floating-label">
                            <label for="input1" class="form-label">JobTitle</label>
                            <input type="text" class="form-control" placeholder="JobTitle" id="input1">
                        </div>
                        <div class="col-md-6 custom-floating-label">
                          <label for="input1" class="form-label">Phone</label>
                          <input type="text" class="form-control" placeholder="Phone" id="input1">
                      </div>
                      <div class="col-md-6 text-end">
                        <p class="remove-contact remove-contact-disabled "> Remove Contact</p>

                      </div>
                          </div>
                            <span class="add-contact text-link" data-bs-toggle="modal" data-bs-target="#modal-existing-company">+ Add a Contact</span>
                        </form>
                    </div>
                    <div class="cm-btn-section">
                        <button type="button" class="btn common-btn-light disabled me-2" data-bs-dismiss="modal">Add
                          a Company</button>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
</div>
<!--common modal section ends-->
<div class="modal fade" id="modal-existing-company" tabindex="-1" aria-labelledby="existingCompanyModalLabel" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered common-modal-center" >
  <div class="modal-content">
   
    <div class="modal-body">
      <p>We found an existing company with a similar name:</p>
      <div>
        <label>Exsisting:</label>
        <p class="title-bold">The Comapny Name</p>
        <p>ID: 4308</p>
        <label>New Company :</label>
        <p class="title-bold">New Company Name</p>
      </div>
      
   <div class="existing-company  justify-content-between">
      <button type="btn" class="btn common-btn-secondary" data-bs-dismiss="modal">Add New</button>
      <button type="button" class="btn common-btn-secondary">Cancel and go to Existing</button>
    </div>
  </div>
</div>
</div>

