import { Injectable } from '@angular/core';
import { CUSTOMER_PORTAL_API_PATHS } from '../../../../../assets/constants/apiPaths';
import { BaseApiService } from '../../../../shared/services/base-api/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(public baseApiService: BaseApiService) { }

/**
* getCompanyDetail - Invoke the data search for the company details for the logged in user.
* @param companyId - companyId input is expected to search the company details.
* @returns An Observable that emits a user information as response.
*/

  getCompanyDetail(companyId) {
    let endpoint = CUSTOMER_PORTAL_API_PATHS.company_detail + '?' + 'companyId=' + companyId;
    return this.baseApiService.get(endpoint);
  }

/**
* getCompanyUser - Invoke the data search for the company user details for the logged in user.
* @param companyId - companyId input is expected to search the company details.
* @returns An Observable that emits a user information as response.
*/

  getCompanyUser(companyId) {
    let endpoint = CUSTOMER_PORTAL_API_PATHS.company_user + '?' + 'companyId=' + companyId;
    return this.baseApiService.get(endpoint);
  }

/**
* getCompanyUserMetaData - Invoke the data search for the add user metadata.
* @param companyId - companyId input is expected to search the company details.
* @returns An Observable that emits a user information as response.
*/

  getCompanyUserMetaData(companyId) {
    let endpoint = CUSTOMER_PORTAL_API_PATHS.company_user_metadata + '?' + 'companyId=' + companyId;
    return this.baseApiService.get(endpoint);
  }

/**
* getUserSearch - Invoke the data search for the user details.
* @param userProfileId - userProfileId input is expected to search the user details.
* @returns An Observable that emits a user information as response.
*/
  getUserSearch(userProfileId) {
    let endpoint = CUSTOMER_PORTAL_API_PATHS.company_user_search + '?' + 'userProfileId=' +  userProfileId;
    return this.baseApiService.get(endpoint);
  }

/**
* getCountryMetaData - Invoke the country search for the edit company details.
* @param companyId - companyId input is expected to search the company details.
* @returns An Observable that emits a user information as response.
*/

  getCountryMetaData(companyId) {
    let endpoint = CUSTOMER_PORTAL_API_PATHS.company_country + '?' + 'companyId=' + companyId;
    return this.baseApiService.get(endpoint);
  }

  /**
* updatedUserStatus - Invoke the data search for the add user metadata.
* @param companyId - companyId input is expected to search the company details.
* @returns An Observable that emits a user information as response.
*/

  updatedUserStatus(userProfileId, statusActiveInd, loggedInId) {
    let endpoint = CUSTOMER_PORTAL_API_PATHS.company_user_updatestatus + '?' + 'userProfileId=' + userProfileId + '&' + 'status=' + statusActiveInd + '&' + 'loggedInId=' + loggedInId;
    return this.baseApiService.put(endpoint, '');
  }

  
}
