import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { INDEX } from '../../enum/shared.enum';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/'; 
  preferredDateFormat: number;
  userProfile: any;

  constructor() {
    super(); 
    this.changeDateFormat()
  }

  /**
   * changeDateFormat() - to chnage the date fomrat according to user preference
   */
  changeDateFormat(): void{
    this.userProfile = JSON.parse(localStorage.getItem('user-profile'));
    const updatedData = JSON.parse(localStorage.getItem("updated-profile"))
    if(updatedData){
      this.preferredDateFormat = updatedData?.userPreferences?.dateFormatSettings?.dateFormatId;
    }
    else{
      this.preferredDateFormat= this.userProfile.userPreferences.dateFormatSettings.dateFormatId;
  }
}

/**
 * parse() - to get the parsed value
 * @param value - value to parse
 * @returns - parsed value
 */
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = _.split(value, this.DELIMITER);
      if (this.preferredDateFormat === INDEX.one) {
        return {
          month: parseInt(date[0], 10),
          day: parseInt(date[1], 10),
          year: parseInt(date[2], 10),
        };
      }  if (this.preferredDateFormat === INDEX.two) {
        
        return {
          day: parseInt(date[0], 10),
          month: parseInt(date[1], 10),
          year: parseInt(date[2], 10),
        };
      }
    }
    return null;
  }

  /**
   * format() - to get the formatted adte accordin to user entered date
   * @param date - to format the date
   * @returns - the formatted date
   */
  format(date: NgbDateStruct | null): string {
    if (date) {
      if (this.preferredDateFormat === INDEX.one) {
        return `${date.month}${this.DELIMITER}${date.day}${this.DELIMITER}${date.year}`;
      } else if (this.preferredDateFormat === INDEX.two) {
        return `${date.day}${this.DELIMITER}${date.month}${this.DELIMITER}${date.year}`;
      }
    }
    return '';
  }
}