<div tabindex="0" (blur)="onTouched()" class="multiselect-dropdown" (clickOutside)="closeDropdown()">
  <div>
    <span tabindex="-1" [ngClass]="{ 'active-project': _placeholder === 'Active Project' }" class="dropdown-btn" (click)="toggleDropdown($event)">
      <span>{{_placeholder}}</span>
      <span [ngClass]="{ 'dropdown-multiselect--active': _settings.defaultOpen }" style="float:right !important;padding-right:4px">
        <span class="dropdown-multiselect__caret"></span>
      </span>

    </span>
  </div>
  <div class="dropdown-list p-3 pt-0" [ngClass]="_type == 'typeahead' ? 'typeahead-container' : ''" [hidden]="!_settings.defaultOpen">
    <ul class="item1">
      <li class="filter-textbox" *ngIf="(_data?.length>0 || _settings.allowRemoteDataSearch) && _settings.allowSearchFilter">
        <div class="d-flex">
          <img class="search-icon" src="../../../../../../assets/images/search-icon.svg" alt="Search Icon">
          <input type="text" *ngIf="_type == ''" class="ms-2" aria-label="multiselect-search" [placeholder]="_settings.searchPlaceholderText" [(ngModel)]="filter.text" (ngModelChange)="onFilterTextChange($event)">
          <input type="text" *ngIf="_type == 'typeahead'" class="ms-2 typeahead-search-input" [placeholder]="_searchtextplaceholder" [(ngModel)]="inputTypeahead"   [formControl]="searchControl">
        </div>
      </li>
    </ul>
    <ul class="item2 mt-3" *ngIf="_type == ''" [style.maxHeight]="_settings.maxHeight+'px'">
    <li *ngFor="let item of _data | multiSelectFilter:filter; let i = index;" (click)="onItemClick($event,item)" class="multiselect-item-checkbox ps-0">
    <input type="checkbox" [attr.aria-label]="item.text" [checked]="isSelected(item) " />
    <div>{{item.text}}</div>
  </li>
    <li class='no-filtered-data' *ngIf="_data?.length != 0 && (_data | multiSelectFilter:filter).length == 0 && !_settings.allowRemoteDataSearch">
      <h5>{{_settings.noFilteredDataAvailablePlaceholderText}}</h5>
    </li>
    <li class='no-data' *ngIf="_data?.length == 0 && !_settings.allowRemoteDataSearch">
      <h5>{{_settings.noDataAvailablePlaceholderText}}</h5>
    </li>
    </ul>
    <ul class="item2 mt-3" *ngIf="_type == 'typeahead'" [style.maxHeight]="_settings.maxHeight+'px'">
      <ng-container *ngIf="_data[0].text == 'userIdTypeahead'">
        <ng-container *ngIf="userId$ | async as results">
          <li *ngFor="let result of results" (click)="onItemClick($event,result)" class="multiselect-item-checkbox ps-0">
            <input type="checkbox" [checked]="isSelected(result)" />
            <div>{{result.id}}</div>
          </li>
          <li class='no-data' *ngIf="noTypeaheadData">
            <h5>{{_settings.noDataAvailablePlaceholderText}}</h5>
          </li>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="_data[0].text == 'projectIdTypeahead'">
        <ng-container *ngIf="projectId$ | async as results">
          <li *ngFor="let result of results" (click)="onItemClick($event,result)" class="multiselect-item-checkbox ps-0">
            <input type="checkbox" [checked]="isSelected(result)" />
            <div>{{result.projectId}}</div>
          </li>
          <li class='no-data' *ngIf="noTypeaheadData">
            <h5>{{_settings.noDataAvailablePlaceholderText}}</h5>
          </li>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="_data[0].text == 'userTypeahead'">
        <ng-container *ngIf="users$ | async as results">
          <li *ngFor="let result of results" (click)="onItemClick($event,result)" class="multiselect-item-checkbox ps-0">
            <input type="checkbox" [checked]="isSelected(result)" />
            <div>{{result.firstname}} {{result.lastname}}</div>
            <div class="typeahead">{{result.email}}</div>
          </li>
          <li class='no-data' *ngIf="noTypeaheadData">
            <h5>{{_settings.noDataAvailablePlaceholderText}}</h5>
          </li>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="_data[0].text == 'jobtitleTypeahead'">
        <ng-container *ngIf="jobTitle$ | async as results">
          <li *ngFor="let result of results" (click)="onItemClick($event,result)" class="multiselect-item-checkbox ps-0">
            <input type="checkbox" [checked]="isSelected(result)" />
            <div>{{result.jobtitle}}</div>
          </li>
          <li class='no-data' *ngIf="noTypeaheadData">
            <h5>{{_settings.noDataAvailablePlaceholderText}}</h5>
          </li>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="_data[0].text == 'activeProjectsTypeahead'">
        <ng-container *ngIf="activeProjects$ | async as results">
          <li *ngFor="let result of results" (click)="onItemClick($event,result)" class="multiselect-item-checkbox ps-0">
            <input type="checkbox" [checked]="isSelected(result)" />
            <div>{{result.text}}</div>
          </li>
          <li class='no-data' *ngIf="noTypeaheadData">
            <h5>{{_settings.noDataAvailablePlaceholderText}}</h5>
          </li>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="_data[0].text == 'companiesTypeahead'">
        <ng-container *ngIf="activeProjects$ | async as results">
          <li *ngFor="let result of results" (click)="onItemClick($event,result)" class="multiselect-item-checkbox ps-0">
            <input type="checkbox" [checked]="isSelected(result)" />
            <div>{{result.text}}</div>
          </li>
          <li class='no-data' *ngIf="noTypeaheadData">
            <h5>{{_settings.noDataAvailablePlaceholderText}}</h5>
          </li>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="_data[0].text == 'departmentsTypeahead'">
        <ng-container *ngIf="activeProjects$ | async as results">
          <li *ngFor="let result of results" (click)="onItemClick($event,result)" class="multiselect-item-checkbox ps-0">
            <input type="checkbox" [checked]="isSelected(result)" />
            <div>{{result.text}}</div>
          </li>
          <li class='no-data' *ngIf="noTypeaheadData">
            <h5>{{_settings.noDataAvailablePlaceholderText}}</h5>
          </li>
        </ng-container>
      </ng-container>
      
      <ng-container *ngIf="_data[0].text == 'companyTypeahead'">
        <ng-container *ngIf="company$ | async as results">
          <li *ngFor="let result of results" (click)="onItemClick($event,result)" class="multiselect-item-checkbox ps-0">
            <input type="checkbox" [checked]="isSelected(result)" />
            <div>{{result.id}}</div>
          </li>
          <li class='no-data' *ngIf="noTypeaheadData">
            <h5>{{_settings.noDataAvailablePlaceholderText}}</h5>
          </li>
        </ng-container>
      </ng-container>
    </ul>
    <div class="d-flex mt-3">
      <button class="btn common-btn-secondary w-100" *ngIf="selectedItems?.length > 0 && (_type =='' || applyTypeahead) || (selectedItems?.length > 0 && inputTypeahead !== '')" (click)="applyFilter()">{{multiselect.apply}}</button>
      <button class="btn common-btn-secondary w-100" *ngIf="selectedItems?.length == 0 || (_type =='typeahead' && !applyTypeahead ) && (selectedItems?.length > 0 && inputTypeahead == '') " (click)="closeDropdown()">{{multiselect.close}}</button>
    </div>
  </div>
  </div>
