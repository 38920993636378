<app-menu [portalType]='portalType'></app-menu>
<div class='container-fluid'>
  <div class="error-msg-block">
      <h2 class='error-message'>{{errorHeader}}</h2>
      <p class="error-para">{{errorMessage}}</p>
       <div class="button-wrapper">
          <button class="common-btn-primary btn" routerLink="/homepage">{{errorBtnTitle}}</button>
      </div> 
  </div>
</div>
