import { Component, Input, SimpleChanges } from '@angular/core';
import { ACTIVE_PROJECTS } from '../../../constants/customer-portal.constant';
import { ACTIVE_PROJECTS_CONSTANTS } from '../../../enum/customer-portal.enum';
import { activeProjectData } from 'mockData/development/dashboards-active-projects';
import { Router } from '@angular/router';
import { Project, Resources } from 'src/app/shared/models/active-projects';

@Component({
  selector: 'app-active-projects',
  templateUrl: './active-projects.component.html',
  styleUrls: ['./active-projects.component.scss'],
})
export class ActiveProjectsComponent {
  public _projectList: Project[] = [];
  @Input() resources: Resources[] = [];

  @Input()
  set projectList(value: Project[]) {
    this._projectList = value;
    console.log('Project List set:', this._projectList);
  }

  get projectList(): Project[] {
    return this._projectList;
  }

  activeProjects = ACTIVE_PROJECTS;
  activeProjectsConstants = ACTIVE_PROJECTS_CONSTANTS;
  activeProjectsData = activeProjectData;

  constructor(private router: Router) {}

  /**
   * navigateToProjectDetails() - It navigates to the project detail screen
   * @param projectId - the projects Id id passed as parameter to redirect to that specific project
   */
  navigateToProjectDetails(projectId: number) {
    this.router.navigate([
      '/feature/customer-dashboard/project-details',
      projectId,
    ]);
  }
}
