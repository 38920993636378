 import { Injectable, Inject } from '@angular/core';
 import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
 import { catchError } from 'rxjs/operators';
 import { Observable, throwError, of } from 'rxjs';
 import { OKTA_AUTH } from '@okta/okta-angular';
 import OktaAuth from '@okta/okta-auth-js';
 import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

 @Injectable()
 export class TokenInterceptors implements HttpInterceptor {
   sessionExpired:boolean = false;
   timerInterval: any;
   constructor(@Inject(OKTA_AUTH) private _oktaAuth: OktaAuth, private router: Router) { }

   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     return next.handle(this.addAuthHeaderToAllowedOrigins(req))
   }


   /**
  * addAuthHeaderToAllowedOrigins() - Performs interceptor to pass the okta token in each API endpoint.
  * @param req - req parameter is expecting the HTTP call from the service.
  */

   addAuthHeaderToAllowedOrigins(req: HttpRequest<any>): HttpRequest<any> {
     let request = req
     //let isTokenRequired = this.getAuthHeaderNotRequiredAPI(request)
     const allowedOrigins = [environment.apiGateWayBaseURL];
     if (allowedOrigins.find((origin) => request.url.includes(origin))) {
       if (true) {
         const authToken = this._oktaAuth.getAccessToken();
         if (authToken) {
           request = request.clone({
             headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': authToken })
           })
         }
       }
     }
     return request
   }


/**
* getAuthHeaderNotRequiredAPI() - Performs interceptor which doesnt need okta token in application.
* @param request - request parameter is expecting the HTTP call from the service.
*/

   //getAuthHeaderNotRequiredAPI(request: HttpRequest<any>): boolean {
   //  if (request.url.endsWith(API_PATHS.LOGIN) || request.url.endsWith(API_PATHS.EMPLOYEE_REGISTRATION)) {
   //    return false
   //  }
   //  return true
   //}
 }
