export enum PASSES_NUMBER {
  allPassesCount = 36,
  driveOnCount = 15,
  walkOnCount = 21
}

export enum INDEX {
  minusTwo = -2,
  one = 1,
  two = 2,
  three = 3,
}

export enum COMPANY_NUMBER {
  companyid = 12345,
  projectcount = 2,
  companyusercount = 74
}

export enum PAGES {
  zero = 0,
  one = 1,
  ten = 10
}

export enum SORT_ID {
  default_id_sort_function=1
}

export enum BOOKMARK {
  zero = 0,
  one = 1
}

export enum ADD_PROJECT {
  zero = 0,
  one = 1,
  seven = 7
}

export enum PROJECT_DETAILS {
  zero = 0,
  one = 1,
  two = 2,
  six = 6,
  seven = 7,
  ten = 10
}