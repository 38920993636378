import { Component } from '@angular/core';

@Component({
  selector: 'app-denied-cod-with-risk',
  templateUrl: './denied-cod-with-risk.component.html',
  styleUrls: ['./denied-cod-with-risk.component.scss']
})
export class DeniedCodWithRiskComponent {

}
