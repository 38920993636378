import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { IconsComponent } from 'src/app/shared/components/ag-grid-icons/icons/icons.component';

@Component({
  selector: 'app-project-user',
  templateUrl: './project-user.component.html',
  styleUrls: ['./project-user.component.scss']
})
export class ProjectUserComponent {

  @Output() paginationChanged = new EventEmitter<any>();
  
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  public dropdownSettings: IDropdownSettings = {};

  ngOnInit() {
    this.dropdownList = [
      { item_id: 1, item_text: 'Project Name' },
      { item_id: 2, item_text: 'Project Name' },
      { item_id: 3, item_text: 'Project Name' },
      { item_id: 4, item_text: 'Project Name' },
      { item_id: 5, item_text: 'Project Name' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      showSelectedItemsAtTop: false
    };
  }
  

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }
  colsForSmallScreen=['user', 'status','icons'];
  colsForMediumScreen=['user', 'status', 'icons'];
  colsForLargeScreen=['user', 'status', 'activity', 'jobTitle','icons']
  colsForExtraLargeScreen=['id', 'user', 'status', 'activity', 'jobTitle','accessdept','sections','icons'];

  rowData = [
    {id:12345, fullName: 'Dan Acorn', email: 'colettealfaro@gmail.com',requestType:"", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', accessdept: '1',sections:'Project'},
    { id:12345,fullName: 'Lexi Allan', email: 'eryamille@sunshineproductions.com',requestType:"", status: 'DEACTIVATED', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', accessdept: '1',sections:'All' },
    { id:12345,fullName: 'Maggie Bash', email: 'colettealfaro@gmail.com',requestType:"", status: 'DEACTIVATED', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', accessdept: '5',sections:'Project:Manage Users' },
    {id:12345, fullName: 'Nuha Hodgson', email: 'colettealfaro@gmail.com',requestType:"company admin", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', accessdept: '5',sections:'' },
    {id:12345, fullName: 'Farhan Blevins', email: 'colettealfaro@gmail.com',requestType:"", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', accessdept: '5',sections:'All' },
    {id:12345, fullName: 'Lin ANg', email: 'colettealfaro@gmail.com',requestType:"", status: 'DEACTIVATED', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', accessdept: '5',sections:'Project' },
    { id:12345,fullName: 'Erya Milnex`', email: 'colettealfaro@gmail.com',requestType:"", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', accessdept: '1',sections:'Project' },
    {id:12345, fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', accessdept: 'All',sections:'All' },
    {id:12345, fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"company admin", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', accessdept: 'All',sections:'Project' },
    {id:12345, fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"", status: 'DEACTIVATED', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', accessdept: 'All',sections:'Project' },
    { id:12345,fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"company admin", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', accessdept: '5',sections:'Project' },
    { id:12345,fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', accessdept: '3',sections:'All' },
    { id:12345,fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com',requestType:"", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', accessdept: '3',sections:'Project' },
  ];

  colDefs: ColDef[] = [
    {
      headerName:'ID#',
      field:'id',cellClass:'common-cell-data-bold',minWidth:93,flex:1.8
    },
    {
      headerName: 'User',
      field: 'user',
      filter: 'agTextColumnFilter',
      filterParams: { alwaysShowFilter: true },
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const fullName = params.data.fullName;
        const email = params.data.email;
        const requestType = params.data.requestType;
      
        let requestTypeClass = '';
        if (requestType === 'system admin') {
          requestTypeClass = 'system-admin';
        } else if (requestType === 'account payable') {
          requestTypeClass = 'account-payable';
        } else if (requestType === 'company admin') {
          requestTypeClass = 'company-admin';
        }
      
        return `
          <span class="common-split-cell-section">
            <span class="common-split-cell-data-1">${fullName}</span>
            <span class="common-split-cell-data-2">${email}</span>
            <span class="common-studio-user-request-label ${requestTypeClass}">${requestType}</span>
          </span>
        `;
      },
      minWidth:256,
      flex: 5.2
    },
    {
      headerName: 'STATUS',
      field: 'status',
      cellRenderer: this.statusCellRenderer.bind(this),
      minWidth: 150,
      flex: 1.8,
    },
    {
      headerName: 'Last Login',
      field: 'activity',
      filter: false,
      cellRenderer: (params: ICellRendererParams) => {
        const date = params.data.updatedDate;
        const additionalTime = params.data.additionalTime;
        return `<span class="common-split-cell-section">
                  <span class="common-split-cell-data-default-font common-split-cell-data-1">last:${date}</span>
                  <span class="common-split-cell-data-2">${additionalTime}</span>
                </span>`;
      }, minWidth: 140, flex: 1.7,
    },
    { headerName: 'JOB TITLE', field: 'jobTitle', flex: 3, cellClass:'common-cell-data-bold',minWidth:150 },
    { headerName: 'ACCESS TO DPT', field: 'accessdept', cellClass:'common-cell-data-mt account-user-active-project-cell',minWidth:120,flex:1.5,filter:false },
    {headerName:'SECTIONS',field:'sections',cellClass:'common-cell-data-mt account-user-active-project-cell',flex:1.5,minWidth:110,filter:false},
    {
      field: 'icons',
      headerName: '',
      cellRenderer: IconsComponent,
      filter: false,
      minWidth: 120,
    }
  ];
  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.value);
    return `
      <div class="status-container">
        <div class="request-status-tag ${statusClass}">${params.value}</div>
      </div>
    `;
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'ACTIVE':
        return 'user-status-tag active account-user-common-status-cell mt-common-ag-grid';
      case 'DEACTIVATED':
        return 'user-status-tag deactivated account-user-common-status-cell mt-common-ag-grid';
      default:
        return '';
    }
  }
}
