<button data-bs-toggle="modal" data-bs-target="#addUserCustPortal">Open add user customer portal modal</button>
<!-- common modal section-->
<div class="modal fade" id="addUserCustPortal" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content common-modal-xl">
            <div class="modal-body justify-content-center align-items-center">
                <div class="cm-header-section">
                    <h5 class="modal-title" id="commonModalLabel">Add A User</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                            class="cm-close-text">Close</span></button>
                </div>
                <div class="container cm-main-container">
                    <div class="common-modal-input-section">
                        <!-- <h2 class="section-main-header">Section header</h2> -->
                        <form>
                            <!-- <h3 class="form-section-header">Group Name</h3> -->
                            <div class="row">
                                <div class="col-md-6 custom-floating-label">
                                    <label for="input1" class="form-label">First Name</label>
                                    <input type="text" class="form-control" placeholder="First Name" id="input1">
                                </div>
                                <div class="col-md-6 custom-floating-label">
                                    <label for="input2" class="form-label">Last Name</label>
                                    <input type="text" class="form-control" placeholder="Last Name" id="input2">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 custom-floating-label mb-0">
                                    <label for="input3" class="form-label">Email</label>
                                    <input required type="email" class="form-control" placeholder="Email" id="input3">
                                </div>
                                <div class="col-md-6 custom-floating-label mb-0">
                                    <label for="input4" class="form-label">Job Title</label>
                                    <input type="text" class="form-control" placeholder="Job Title" id="input4">
                                </div>
                            </div>
                            <div class="row">
                                <h2 class="form-section-header custom-margin">Optional Settings</h2>
                                <div class="col-md-6">
                                    <label class="custom-checkbox">
                                        <input type="checkbox">
                                        <span class="checkmark"></span>
                                        Make the user a company admin
                                    </label>
                                </div>
                                <div class="col-md-6">
                                    <label class="custom-checkbox">
                                        <input type="checkbox">
                                        <span class="checkmark"></span>
                                        User is an Accounts Payable user
                                    </label>
                                </div>
                                <p class="verification-note">A verification email will be sent to the new user after
                                    approval from WBSO.</p>
                            </div>
                        </form>
                    </div>
                    <div class="common-modal-input-section">
                        <h2 class="section-main-header">Projects</h2>
                        <div class="comman-tabs-block mt-5">
                            <ul class="nav" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link" aria-current="page" data-bs-toggle="tab" href="#active"
                                        role="tab" aria-selected="true">Active <span>(3)</span></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#closed" role="tab"
                                        aria-controls="fill-tabpanel-1">Closed <span>(43)</span></a>
                                </li>
                            </ul>
                            <div class="tab-content" id="tab-content">
                                <div class="tab-pane space-project-tab" id="active" role="tabpanel"
                                    aria-labelledby="fill-tab-0">
                                    <div class="common-checkbox-img-list">
                                        <ul class="list-group">
                                            <li class="list-group-item">
                                                <label class="custom-checkbox">
                                                    <input type="checkbox">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <img src="../../../../../../assets/images/project-image.png" alt="">
                                                <span class="season-name">The Court - Season 1</span>
                                            </li>
                                        </ul>
                                        <div class="alert alert-danger common-alert-danger" role="alert">
                                            <p>Select department(s)</p>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-10">
                                                    <div class="dept-permission-info">
                                                        <div>
                                                            <span>Departments:</span>
                                                            <span>...</span>
                                                        </div>
                                                        <div>
                                                            <span>Permissions:</span>
                                                            <span>Project (View)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-2 text-end">
                                                    <div class="edit-icon">
                                                        <a class="text-link">Edit <img
                                                                src="../../../../assets/images/edit-icon.svg"
                                                                alt="edit icon"></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane space-project-tab active" id="closed" role="tabpanel"
                                    aria-labelledby="fill-tab-0">
                                    <div class="common-checkbox-img-list">
                                        <ul class="list-group">
                                            <li class="list-group-item">
                                                <label class="custom-checkbox">
                                                    <input type="checkbox">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <img src="../../../../../../assets/images/projects-small-img.svg"
                                                    alt="">
                                                <span class="season-name">Mom - Season 8</span>
                                            </li>
                                            <li class="list-group-item">
                                                <label class="custom-checkbox">
                                                    <input type="checkbox">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <img src="../../../../../../assets/images/projects-small-img.svg"
                                                    alt="">
                                                <span class="season-name">Yound Sheldon - Season 4</span>
                                            </li>
                                            <li class="list-group-item">
                                                <label class="custom-checkbox">
                                                    <input type="checkbox">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <img src="../../../../../../assets/images/projects-small-img.svg"
                                                    alt="">
                                                <span class="season-name">Yound Sheldon - Season 4</span>
                                            </li>
                                            <li class="list-group-item">
                                                <label class="custom-checkbox">
                                                    <input type="checkbox">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <img src="../../../../../../assets/images/projects-small-img.svg"
                                                    alt="">
                                                <span class="season-name">Yound Sheldon - Season 4</span>
                                            </li>
                                            <li class="list-group-item">
                                                <label class="custom-checkbox">
                                                    <input type="checkbox">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <img src="../../../../../../assets/images/projects-small-img.svg"
                                                    alt="">
                                                <span class="season-name">Yound Sheldon - Season 4</span>
                                            </li>
                                            <li class="list-group-item">
                                                <label class="custom-checkbox">
                                                    <input type="checkbox">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <img src="../../../../../../assets/images/projects-small-img.svg"
                                                    alt="">
                                                <span class="season-name">Yound Sheldon - Season 4</span>
                                            </li>
                                            <li class="list-group-item">
                                                <label class="custom-checkbox">
                                                    <input type="checkbox">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <img src="../../../../../../assets/images/projects-small-img.svg"
                                                    alt="">
                                                <span class="season-name">Yound Sheldon - Season 4</span>
                                            </li>
                                            <li class="list-group-item">
                                                <label class="custom-checkbox">
                                                    <input type="checkbox">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <img src="../../../../../../assets/images/projects-small-img.svg"
                                                    alt="">
                                                <span class="season-name">Yound Sheldon - Season 4</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cm-btn-section">
                        <button type="button" class="btn common-btn-light me-2" data-bs-dismiss="modal">Add
                            user</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--common modal section ends-->