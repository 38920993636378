import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ACTIVE_PROJECTS } from '../../../constants/customer-portal.constant';
import { ACTIVE_PROJECTS_CONSTANTS } from '../../../enum/customer-portal.enum';
import { activeProjectData } from 'mockData/development/dashboards-active-projects';
import { Router } from '@angular/router';
import { Project, Resources } from 'src/app/shared/models/active-projects';

@Component({
  selector: 'app-active-projects',
  templateUrl: './active-projects.component.html',
  styleUrls: ['./active-projects.component.scss'],
})
export class ActiveProjectsComponent implements OnInit{
  public _projectList: Project[] = [];
  @Input() resources: Resources[] = [];
  permissionsData: any;

  @Input()
  set projectList(value: Project[]) {
    this._projectList = value;
    console.log('Project List set:', this._projectList);
  }

  get projectList(): Project[] {
    return this._projectList;
  }

  activeProjects = ACTIVE_PROJECTS;
  activeProjectsConstants = ACTIVE_PROJECTS_CONSTANTS;
  activeProjectsData = activeProjectData;

  constructor(private router: Router) {}

  ngOnInit(): void {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
      this.permissionsData = { ...userProfile.roleAssigned };
  }

  /**
   * navigateToProjectDetails() - It navigates to the project detail screen
   * @param projectId - the projects Id id passed as parameter to redirect to that specific project
   */
  navigateToProjectDetails(projectId: number) {
    this.router.navigate([
      '/feature/customer-dashboard/project-details',
      projectId,
    ]);
  }

   /**
   * formatDate() - method to format the date
   * @param dateString - the date to be formatted
   * @returns - returns the formatted date
   */
   formatDate(dateString: string): string {
    if (!dateString) return '';
    const parts = dateString.split('/');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2].slice(-2);
    return `${day}/${month}/${year}`;
  }
}
