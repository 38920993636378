import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  GridApi,
  GridOptions,
  ICellRendererParams,
} from 'ag-grid-community';
import { PASSES_EXPECTED_TODAY } from '../../../constants/customer-portal.constant';
import { PASSES_NUMBER } from '../../../enum/customer-portal.enum';

@Component({
  selector: 'app-pass-expected-today',
  templateUrl: './pass-expected-today.component.html',
  styleUrls: ['./pass-expected-today.component.scss'],
})
export class PassExpectedTodayComponent implements OnInit {
  @ViewChild('passesGrid') passesGrid!: AgGridAngular;
  gridApi!: GridApi;
  gridOptions: GridOptions = {};
  rowDataPasses: any;
  colDefsPasses: ColDef[] = [];
  passesExpectedToday = PASSES_EXPECTED_TODAY;
  passesNumber = PASSES_NUMBER

  constructor() {}

  ngOnInit(): void {
    this.generateRequestAgGrid();
  }

  /**
   * generateRequestAgGrid() - configuration for Ag grid
   */
  generateRequestAgGrid() {
    this.gridOptions = {
      domLayout: 'autoHeight',
      defaultColDef: {
        flex: 1,
        minWidth: 100,
        filter: true,
      },
    };

    this.rowDataPasses = [
      {
        
        username: 'Mark Wells',
        walkon: 'Walk-On',
        gate: 'Gate-3',
        status: 'CHECKED-IN'
      },
      {
        
        username: 'Cathy Aframian',
        walkon: 'Walk-On',
        gate: 'Gate-3', 
        status: 'CHECKED-OUT'

      },
      {
   
        username: 'Desmond Loza',
        walkon: 'Walk-On',
        gate: 'Gate-3',
         status: 'CHECKED-IN'
      },
      {
      
        username: 'Emily Riddle',
        walkon: 'Walk-On',
        gate: 'Gate-3',
         status: 'CHECKED-IN'
      },
      
    ];

    this.colDefsPasses = [
      {
        headerName: '',
        field: 'merged-field',
        wrapText: true,
        autoHeight: true,
       
        cellRenderer: (params: ICellRendererParams) => {
          const username = params.data.username;
          const gate = params.data.gate;
          const imageUrl = '../../assets/images/man.svg';
          return `<div class="common-split-cell-with-image">
                 <div class="image-section-on-grid">
                  <span class="image-block"><img src="${imageUrl}" alt="man-icon" class="man-icon-img"/><span>
            </div> 
            <div class="split-column-data-on-grid">
                    <span title="${username}" class="common-cell-data-bold ellipsis-email-pass-expected">${username}</span>
                    <span class="move-content-on-top">${gate}</span>
                  </div></div>`;
        }
      },
      {
        headerName: '',
        field: 'status',
        cellRenderer: this.statusCellRenderer.bind(this),
        cellClass: 'account-user-common-status-cell cell-right-align',
     
       
      },
    ];
  }

  /**
   * statusCellRenderer() - Renders a cell with the appropriate status class and value.
   * @param params - Parameters passed by ag-Grid to the cell renderer. Includes data and context information.
   */
  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.value);
    return `
         <div class="status-container passes-status-block">
        <div class="request-status-tag ${statusClass}">${params.value}</div>
        </div>
    `;
  }
  
  /**
   * getStatusClass() - Returns the appropriate class for each status value
   * @param status - The status string to get class for
   */
  getStatusClass(status: string): string {
    switch (status) {
      case 'CHECKED-IN':
        return 'checked-in account-user-common-status-cell mt-pass-expexted';
      case 'CHECKED-OUT' :
        return 'checked-out account-user-common-status-cell mt-pass-expexted'
      default:
        return '';
    }
  }

  /**
   * HostListener() - listen for window resize events
   * @param event - The 'event' parameter is the native resize event object
   */
 

  /**
   * onGridReady() - Event handler for when the grid is ready
   * @param params - it is the object provided by AG Grid when the grid is ready
   */
  onGridReady(params: any) {
    this.gridApi = params.api;
    //this.adjustColumns(window.innerWidth);
  }

}
