import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { BaseApiService } from 'src/app/shared/services/base-api/base-api.service';
import {
  GLOBAL_API_PATHS,
  STUDIO_PORTAL_API_PATHS,
} from 'src/assets/constants/apiPaths';
import { end } from '@popperjs/core';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  private refreshSubject = new BehaviorSubject<boolean>(false);
    refresh$ = this.refreshSubject.asObservable();
  constructor(
    private http: HttpClient,
    private baseApiService: BaseApiService
  ) {}

  /**
   * getProfileId() - Method to get the profile id from the local
   */
  getProfileId(): number {
    const user = JSON.parse(localStorage.getItem('user-profile'));
    return user?.userProfileId;
  }

  /**
   * getProjectsByUserProfileID() - method to get project list by passing profileId
   * @param profileId - profile id to pass in params
   * @returns - returns the projects list
   */

  getProjectsByUserProfileID(profileId: number): Observable<any> {
    const endpoint = `${STUDIO_PORTAL_API_PATHS.projects_project_list}?userProfileId=${profileId}`;
    return this.baseApiService.get(endpoint);
  }

  /**
   * getProjectDetailsByUserAndProjectID() - method to get project details by passing profileId and projectId
   * @param profileId - profile id to pass in params
   * @param projectId - project id to pass in params
   * @returns - returns the respective projects details records
   */
  getProjectDetailsByUserAndProjectID(
    profileId: number,
    projectId: number
  ): Observable<any> {
    const endpoint = `${STUDIO_PORTAL_API_PATHS.project_details_list}?userProfileId=${profileId}&projectId=${projectId}`;
    return this.baseApiService.get(endpoint);
  }

  /**
   * addBookmarkByUserAndProjectID() - method to add the bookmark using payload data
   * @param bookmarkPayload - payload includes profileid, projectid, status and projectBookmarked flag
   * @returns - returns the status of the added bookmark status
   */
  addBookmarkByUserAndProjectID(bookmarkPayload: any): Observable<any> {
    const endpoint = `${STUDIO_PORTAL_API_PATHS.project_details_add_bookmark}`;
    return this.baseApiService.post(endpoint, bookmarkPayload);
  }

  /**
   * removeBookmarkByUserAndProjectID() - method to remove the bookmark using payload data
   * @param bookmarkPayload - payload includes profileid, projectid, status and projectBookmarked flag
   * @returns - returns the status of the removed bookmark status
   */
  removeBookmarkByUserAndProjectID(bookmarkPayload: any): Observable<any> {
    const endpoint = `${STUDIO_PORTAL_API_PATHS.project_details_remove_bookmark}`;
    return this.baseApiService.post(endpoint, bookmarkPayload);
  }

  /**
   * updateProjectStatusByUserAndProjectID() - method to update the project status using payload data
   * @param statusPayload - payload includes profileid, projectid and projectstatusid
   * @returns - returns the confirmation of project update status
   */
  updateProjectStatusByUserAndProjectID(statusPayload: any): Observable<any> {
    const endpoint = `${STUDIO_PORTAL_API_PATHS.project_status_update}`;
    return this.baseApiService.put(endpoint, statusPayload);
  }

  /**
   * getProjectCompanyAndFormList() - method to get company details list and project form data
   * @returns - returns the company and project form list
   */
  getProjectCompanyAndFormList(): Observable<any> {
    const endpoint = `${STUDIO_PORTAL_API_PATHS.project_company_list}`;
    return this.baseApiService.get(endpoint);
  }

  /**
   * addNewProjectByUser() - method to create the new project
   * @param projectPayload - payload includes project specific required details
   * @returns - returns the status of the created project
   */
  addNewProjectByUser(projectPayload: any): Observable<any> {
    const endpoint = `${STUDIO_PORTAL_API_PATHS.project_add_project}`;
    return this.baseApiService.post(endpoint, projectPayload);
  }

  /**
   * updateExistingProjectByUser() - method to update the existing project
   * @param projectPayload - payload includes project specific required details
   * @returns - returns the status of the modified project
   */
  updateExistingProjectByUser(projectPayload: any): Observable<any> {
    const endpoint = `${STUDIO_PORTAL_API_PATHS.project_update_project}`;
    return this.baseApiService.put(endpoint, projectPayload);
  }

  /**
   * fetchMetaData() - Method to fetch metadata related to project users.
   * @returns - The API response containing the metadata.
   */
  fetchMetaData(): Observable<any>  {
    let endpoint = `${STUDIO_PORTAL_API_PATHS.project_user_metadata}`;
    return this.baseApiService.get(endpoint);
  }

  /**
   * getProjectUser() - Method to call get Project User details by projectId and company id.
   * @returns - The API response containing user details.
   */
  getProjectUser(projectId: number, companyId: number): any {
    const url = `${STUDIO_PORTAL_API_PATHS.project_users}?projectId=${projectId}&companyId=${companyId}`;
    return this.baseApiService.get(url);
  }

  /**
   * getProjectUserById() - Fetches the details of a specific project user by company ID, project ID, and user profile ID.
   * @returns - The API response containing the specific project user's details.
   */
  getProjectUserById(companyId, projectId, userProfileId) {
    let endpoint = `${STUDIO_PORTAL_API_PATHS.project_users_search}?companyId=${companyId}&projectId=${projectId}&userProfileId=${userProfileId}`;
    return this.baseApiService.get(endpoint);
  }

  /**
   * addImage() - to upload image to database
   * @param data - the data that need to be send to api
   * @returns - an observable with a success message
   */
  addImage(data: any) {
    const endpoint = `${STUDIO_PORTAL_API_PATHS.upload_image}`;
    return this.baseApiService.put(endpoint, data);
  }
  resetImage(data:any){
    const endpoint =`${STUDIO_PORTAL_API_PATHS.resetPhoto}`;
    return this.baseApiService.put(endpoint,data);
  }
  /**
 *  triggerRefresh() - Call this method to trigger a refresh in other components
 */
  triggerRefresh() {
    this.refreshSubject.next(true);
  }
}
