export const projectSendCreditApplications = {
    companyName: 'A Brand New Production Company',
    projectName: 'The New Show - Pilot',
    textboxName: 'Nullam id dolor id nibh ultricies vehicula ut id elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor.',
    accountPayableContacts: 'Catherine Decker (catherinedecker@abrandnewproductioncompany.com)'
}

export const projectCreditApplications = {
    totalNumber: '1',
    arNumber: '84745',
    departmentList: [
        {departmentName:'Studio Operations'},
        {departmentName:'Transportation'},
        {departmentName:'Design Studio'},
        {departmentName:'Post Production Creative Services'},
        {departmentName:'Property'},
        {departmentName:'Production Catering'}
    ]
}

export const ATTACH_QUOTES_CA = {
    setLighting: 'Set Lighting',
    setLighting1: 'Set Lighting 1',
    setLighting2: 'Set Lighting 2',
}

export const VIEW_QUOTES_CA = {
    uploadDate: 'Upload from 3/17/2024 at 11:12am',
    name: 'By Ian Corrigan',
    soundStages: 'Sound Stages',
    price: '$47,320.00',
    exteriorSet: 'Exterior Set',
    gripDept: 'Grip Department',
    setLighting: 'Set Lighting',
}
