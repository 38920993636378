export enum NUMBER {
    one = 1,
    two = 2,
    three = 3,
    four = 4,
    five = 5,
    six = 6,
    seven = 7,
    eight = 8,
    nine = 9,
    ten = 10,
    eleven = 11,
    twelve = 12,
    thirteen = 13,
    fourteen = 14,
    fifteen = 15,
    sixteen = 16,
    seventeen = 17,
    eighteen = 18,
    one_point_zero='1.0',
    one_point_one='1.1',
    one_point_two='1.2',
    one_point_three='1.3',
    one_point_four='1.4',
    one_point_five='1.5',
    one_point_six='1.6',
    one_point_seven='1.7',
    one_point_eight='1.8',
    one_point_nine='1.9',
    one_point_ten='1.10',
    one_point_eleven='1.11',
    one_point_twelve='1.12',
    one_point_thirteen='1.13',
    one_point_fourteen='1.14',
    one_point_fifteen='1.15',
    one_point_sixteen='1.16',
    one_point_seventeen='1.17',
    one_point_eighteen='1.18',
    one_point_nineteen='1.19',
    one_point_twenty='1.20',
    one_point_twentyone='1.21',
    one_point_twentytwo='1.22',
    one_point_twentythree='1.23',
    one_point_twentyfour='1.24',
    one_point_twentyfive='1.25',
    one_point_twentysix='1.26',
    two_point_zero='2.0',
    two_point_one='2.1',
    two_point_two='2.2',
    two_point_three='2.3',
    two_point_four='2.4',
    two_point_five='2.5',
    three_point_one='3.1',
    three_point_zero='3.0',
    three_point_two='3.2',
    three_point_three='3.3',
    three_point_four='3.4',
    three_point_five='3.5',
    three_point_six='3.6',
    three_point_seven='3.7',
    three_point_eight='3.8',
    three_point_nine='3.9',
    three_point_ten='3.10',
    three_point_eleven='3.11',
    three_point_twelve='3.12',
    three_point_thirteen='3.13',
    three_point_fourteen='3.14',
    three_point_fifteen='3.15',
    four_point_one='4.1',
    four_point_zero='4.0',
    four_point_two='4.2',
    four_point_three='4.3',
    four_point_four='4.4',
    four_point_five='4.5',
    four_point_six='4.6',
    four_point_seven='4.7',
    four_point_eight='4.8',
    four_point_nine='4.9',
    four_point_ten='4.10',
    four_point_eleven='4.11',
    four_point_twelve='4.12',
    four_point_thirteen='4.13',
    four_point_fourteen='4.14',
    four_point_fifteen='4.15',



}