import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  GridApi,
  GridOptions,
  ICellRendererParams,
} from 'ag-grid-community';
import { REQUEST } from '../../../constants/customer-portal.constant';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
})
export class RequestsComponent implements OnInit {
  @ViewChild('requestGrid') requestGrid!: AgGridAngular;
  gridApi!: GridApi;
  gridOptions: GridOptions = {
    defaultColDef:{
      cellStyle:{marginTop:'1rem'}
    }
  };
  rowData: any;
  colDefsRequest: ColDef[] = [];
  Requests = REQUEST;

  constructor() {
    this.statusCellRenderer = this.statusCellRenderer.bind(this);
  }

  ngOnInit() {
    this.generateRequestAgGrid();
  }

  /**
   * generateRequestAgGrid() - configuration for Ag grid
   */
  generateRequestAgGrid() {
    this.gridOptions = {
      domLayout: 'autoHeight',
      defaultColDef: {
        flex: 1,
        minWidth: 100,
        filter: true,
      },
    };
    this.rowData = [
      { date: '1/11/24', request: 'Publicity Clearance', status: 'NEW REQUEST' },
      { date: '1/11/24 ', request: 'WBSO Pass Request', status: 'IN PROGRESS' },
      { date: '1/11/24 ', request: 'Publicity Clearance', status: 'RESOLVED' },
    ];
    this.colDefsRequest = [
      {
        headerName: '',
        field: 'date',
        wrapText: true,
        autoHeight: true,
        width: 100,
        hide: true,
        maxWidth:110,
        cellClass:'date-request'
      },
      {
        headerName: '',
        field: 'merged-field',
        wrapText: true,
        autoHeight: true,
        cellRenderer: (params: ICellRendererParams) => {
          const request = params.data.request;
          const date = params.data.date;
          return `<span class="common-split-cell-section">
                    <span class="common-split-cell-data-1">${request}</span>
                    <span class="common-split-cell-data-2 pb-common">${date}</span>
                  </span>`;
        },
        minWidth: 150,
        flex: 2,
        
      },
      {
        headerName: '',
        field: 'request',
        minWidth: 150,      
        hide: true,
        flex:2
      },
      {
        headerName: '',
        field: 'status',
        cellRenderer: this.statusCellRenderer.bind(this),
        cellClass: 'account-user-common-status-cell cell-right-align ',
        minWidth: 150,
        hide: true,
      },
    ];
  }

  /**
   * statusCellRenderer() - Renders a cell with the appropriate status class and value.
   * @param params - Parameters passed by ag-Grid to the cell renderer. Includes data and context information.
   */
  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.value);
    return `
         <div class="status-container move-right">
        <div class="request-status-tag  ${statusClass}">${params.value}</div>
        </div>
    `;
  }
  
  /**
   * getStatusClass() - Returns the appropriate class for each status value
   * @param status - The status string to get class for
   */
  getStatusClass(status: string): string {
    switch (status) {
      case 'NEW REQUEST':
        return 'new account-user-common-status-cell cell-right-align';
      case 'IN PROGRESS':
        return 'inprogress-color account-user-common-status-cell';
      case 'CLOSED':
        return 'closed account-user-common-status-cell';
      case 'ASSIGNED':
        return 'assigned-color account-user-common-status-cell';
      case 'RESOLVED':
        return 'resolved-color account-user-common-status-cell';
      default:
        return '';
    }
  }

  /**
   * onGridReady() - Called when the grid is ready
   * @param params - Grid parameters
   */
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.adjustColumns(window.innerWidth);
  }

  /**
   * HostListener() - listen for window resize events
   * @param event - The 'event' parameter is the native resize event object
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustColumns(event.target.innerWidth);
  }

  /**
   * adjustColumns() - Adjusts column visibility based on the screen width
   * @param screenWidth - The current width of the screen/window
   */
 
  adjustColumns(screenWidth: number) {
    if (this.gridApi) {
      const allColumnIds: string[] = [];
      this.gridApi.getAllGridColumns().forEach((column) => {
        allColumnIds.push(column.getColId());
      });
      if (screenWidth <= 375) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['merged-field',  'status'], true);
      } else if (screenWidth > 375 && screenWidth <= 510) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['merged-field',  'status'], true);
      } else if (screenWidth > 510 && screenWidth <= 992) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['date', 'request','status'], true);
      } else {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['date', 'request','status'], true);
      }
    }
  }
}
