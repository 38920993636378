import { Component, inject, TemplateRef, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']  ,
  encapsulation:ViewEncapsulation.None
})
export class ProjectDetailComponent {

  constructor(private modalService: NgbModal) {}

  openFullscreen(content: TemplateRef<any>) {
    // this.modalService.open(content, { fullscreen: true });
  this.modalService.open(content, { windowClass: 'common-modal-xl-1',fullscreen: true  })
  }
}
