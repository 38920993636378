import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, } from 'rxjs/operators';
import { SearchDataService } from '../../services/search.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MESSAGES, SEARCH_DATA, SEARCH_PLACEHOLDER } from '../../constants/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  public model: any;
  companies = [];
  @Input() placeholder: string = SEARCH_PLACEHOLDER;
  @Input() disabled: boolean = false; // New input for disabled state
  @Input() searchTerm: string = null; // Bindable searchTerm property
  @Input() userType;
  @Input() searchGroupType: any;
  @Input() portalType:any
  @Input() usersList;
  @Output() searchTermChange = new EventEmitter<string>(); // Emit when searchTerm changes
  @Output() searchComplete = new EventEmitter<any>();
  url: string;
  usersListData: any;
  searchData = SEARCH_DATA;
  message = MESSAGES;
  invalidUser: boolean = false;
  requestData: any;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.performSearch();
   }

  /**
   * performSearch() - Performs a search based on the user type and search term.
   * Sets the correct API endpoint and retrieves search results.
   */
  performSearch() {
    if (this.userType == 'wbuser') {
      this.usersListData = this.usersList;
    } else {
      this.companies = this.usersList;
    } 
  }

  /**
   * search() - Creates a typeahead observable for searching.
   * @param text$ - RxJS observable of search input text.
   * @returns - An array of filtered search results.
   */
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        if (term?.length < 1) {
          this.invalidUser = false;
          return [];
        }
        const searchTerm = term.toLowerCase();
        let result = [];
        if (this.userType === 'wbuser') {
          result = this.usersListData.filter(user => {
            const fullName = `${user.firstName} ${user.lastName}`;
            return fullName?.toLowerCase().includes(searchTerm) || user.emailAddress?.toLowerCase().includes(searchTerm)}
          ).slice(0, 10);
        }
         else {
          // Search in companies
          result = this.companies.filter(company =>
            company.companyName.toLowerCase().includes(term.toLowerCase()) ||
            company.companyId.toString().toLowerCase().includes(term.toLowerCase())
          ).slice(0, 10);
        }
        this.invalidUser = result.length === 0;
        return result;
      })
    );

  /**
   * formatter - Formats a chosen search result for display.
   * @param x - Result object containing user or company information.
   * @returns - A formatted string combining key result details.
   */
  formatter = (x: { firstName?: string, jobTitle?: string, companyName?: string, companyId?: string , company?:any }) => {
    if (this.userType === 'wbuser') {
      return ``;
    }
    else {
      if (x.companyName && x.companyId) {
        return `${x.companyName}`;        
      }
      return ``;
    }
  };

  /**
   * selectedItem - Handles the selection of an item from the typeahead results.
   * @param event - The selection event containing the chosen item.
   */
  selectedItem(event: any): void {
    const selectedItem = event.item;
    this.invalidUser = false; // Reset invalid user flag on selection
    this.searchComplete.emit([selectedItem]);
    
  }

  /**
   * onInputChange - Updates the search term and emits changes.
   * @param newValue - The new value entered in the search input.
   */
  onInputChange(newValue: any): void {
    this.searchTerm = newValue || ''; // Fallback to empty string if undefined or null
    this.searchTermChange.emit(this.searchTerm);
  }
}
