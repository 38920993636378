<div class="user-project-wrapper">
	<div class="container px-0">
		<div class="row">
			<div class="comman-title-block">
				<div class="d-flex mb-3">
					<img src="../../assets/images/keyboard_arrow_left.svg" alt="keyboard-arrow-left" />
					<p class="back-link mb-0">
					 The Court Season - 1
					</p>
				  </div>
				  <div class="common-title-section d-flex justify-content-between mb-4">
					<div class="heading-content">
						<h1 class="heading">Project Users</h1>
						<span class="title-users">COMPANY:</span><span class="title-user-content"> A Brand New Production Company</span>
					</div>
					<div class="button">
						<button class="common-btn-primary btn">+ Add a  User</button>
					</div>
				  </div>
				<div class="comman-filter-block">
					<div class="container">
						<div class="row">
							<div class="col-lg-9 col-12 px-0">
								<div class="comman-filter-title-section">
									<p>Filters</p>
								</div>
								<div class="comman-filter-section d-flex flex-wrap align-items-center gap-3">
									<!-- Multiselect Dropdowns -->
									<ng-multiselect-dropdown class="custom-multiselect" [placeholder]="'Projects'"
										[settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
										(onSelect)="onItemSelect($event)">
									</ng-multiselect-dropdown>
									<ng-multiselect-dropdown class="custom-multiselect" [placeholder]="'Types'"
										[settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
										(onSelect)="onItemSelect($event)">
									</ng-multiselect-dropdown>
									<ng-multiselect-dropdown class="custom-multiselect" [placeholder]="'Status'"
										[settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
										(onSelect)="onItemSelect($event)">
									</ng-multiselect-dropdown>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="common-grid-block">
				<div class="mt-4">
					<ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-custom" [gridOptions]="gridOptions"
						[columnDefs]="colDefs" [rowData]="rowData" (gridReady)="onGridReady($event)"
						(paginationChanged)="updatePagination()">
					</ag-grid-angular>
				</div>
			</div>
		</div>
		<div class="common-pagination-section">
			<div class="common-pagination-controls">
				<div class="items-per-page">
					<label for="page-size" class="per-page">Items per page:</label>
					<select id="page-size" (change)="onPageSizeChanged($event)">
						<option *ngFor="let size of pageSizeOptions" [value]="size" class="size-per-page">{{size}}</option>
					</select>
				</div>
				<div class="common-pagination-navigation-block ">
					<div class="pagination-no align-self-center">{{startRow}} - {{endRow}} of {{totalRows}}
						<span class="paginator-arrow-icons align-self-center">
							<a class="arrow-1" (click)="onBtPrevious()"> <img src="../../assets/images/arrow-left.svg"
									alt="icon" /></a>
							<a class="arrow-2" (click)="onBtNext()"><img src="../../assets/images/arrow-right.svg" alt="icon" /></a>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>