import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import {
  STUDIO_CREDIT_APPLICATION,
  USER,
} from 'src/app/feature/studio-portal/constants/studio-portal.constant';
import { ProjectsService } from 'src/app/feature/studio-portal/services/projects.service';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ProjectsCreditApplicationService } from 'src/app/feature/studio-portal/services/projects-credit-application.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { AddProjectUserComponent } from 'src/app/shared/components/add-project-user/add-project-user.component';
import { PORTAL_TYPE } from 'src/app/shared/constants/common';
import * as _ from 'lodash';

@Component({
  selector: 'app-send-credit-application',
  templateUrl: './send-credit-application.component.html',
  styleUrls: ['./send-credit-application.component.scss'],
})
export class SendCreditApplicationComponent {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @Input() public projectName: any;
  @Input() public companyName: any;
  @Input() public projectId: any;
  @Input() public projectCompanyId: any;
  creditApplicationConstants = STUDIO_CREDIT_APPLICATION;
  user = USER;
  modalReference: NgbModalRef;
  form: FormGroup;
  departmentDetails;
  userRoleDetails;
  errorResponse;
  selectedDepartmentIds = [];
  departmentControls = [];
  displaySuccessMsg: string;
  accountPayableUsers: any;
  caAccountPayableUserControls = [];

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public projectsService: ProjectsService,
    private fb: FormBuilder,
    public projectsCreditApplicationService: ProjectsCreditApplicationService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private accountService: ProjectsCreditApplicationService
  ) {}

  ngOnInit() {
    this.getDepartmentDetails();
    this.getAddProjectUserMetadata();
  }

  /**
   * initializeForm() - method to initialize the form group
   */
  initializeForm(): void {
    this.form = this.fb.group({
      caTextbox: [this.creditApplicationConstants.sendCreditApplicationDataTextboxName, [Validators.required]],
      ...this.departmentDetails.reduce((acc, dept) => {
        acc[`department${dept.departmentId}`] = [false]; // default unchecked
        return acc;
      }, {}),
      ...this.accountPayableUsers.reduce((acc, user) => {
        acc[`caAccountPayableUser${user.userProfileId}`] = [false]; // default unchecked
        return acc;
      }, {})
    });
    this.form.addValidators([
      this.atLeastOneDepartmentSelected(),
      this.atLeastOneAccountPayableUserSelected()
    ]);
  }

  /**
  * atLeastOneDepartmentSelected() - Custom validator for at least one department selected
  */
  atLeastOneDepartmentSelected() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const selectedDepartments = this.departmentDetails.map(dept => control.get(`department${dept.departmentId}`).value);
      return selectedDepartments.includes(true) ? null : { 'atLeastOneDepartmentRequired': true };
    };
  }

  /**
  * atLeastOneAccountPayableUserSelected() - Custom validator for at least one account payable user selected
  */
  atLeastOneAccountPayableUserSelected() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const selectedUsers = this.accountPayableUsers.map(user => control.get(`caAccountPayableUser${user.userProfileId}`).value);
      return selectedUsers.includes(true) ? null : { 'atLeastOneAccountPayableUserRequired': true };
    };
  }

  /**
   * getAccountPayableUsers() - to get the account payable users
   */
  getAccountPayableUsers(): void {
    this.loaderService.setLoadingState(true);
    this.accountService.getAccountPayableUsers(this.projectCompanyId).subscribe({
      next: (response: any) => {
        this.loaderService.setLoadingState(false);
        this.accountPayableUsers = response.accPayUsers;
        if (this.departmentDetails && this.accountPayableUsers) {
          this.initializeForm();
        }
      },
      error: (error: any) => {
        this.loaderService.setLoadingState(false);
        console.error(this.creditApplicationConstants.accountPayable, error);
      },
    });
  }

  /**
   * getDepartmentDetails() - Method to fetch the department details
  */
  getDepartmentDetails(): void {
    this.accountService.fetchCAPageData().subscribe((response: any) => {
      this.departmentDetails = response?.departments;
      this.getAccountPayableUsers();
    });
  }

  /**
   * getAddProjectUserMetadata() - Method to fetch the metadata for add project user screen
   */
  getAddProjectUserMetadata(): void {
    this.projectsService.fetchMetaData().subscribe((response: any) => {
      this.userRoleDetails = response?.getProjectUserMetaDataDTO?.userRoleDetails;
    });
  }

  
  /**
   * openAddUserModal() - method to open the add a user modal
   */
  openAddUserModal(): void {
    window.scrollTo(0,0);
    this.modalReference = this.modalService.open(AddProjectUserComponent, {
      windowClass: 'common-modal-xl common-modal-adjust',
      fullscreen: true,
    });
    this.modalReference.componentInstance.modalTitle =
      this.creditApplicationConstants?.addUserTitle;
    this.modalReference.componentInstance.projectName = this.projectName;
    this.modalReference.componentInstance.departmentDetails =
      this.departmentDetails;
    this.modalReference.componentInstance.userRoleDetails =
      this.userRoleDetails;
    this.modalReference.componentInstance.portalType = PORTAL_TYPE.studioPortal;
    this.modalReference.componentInstance.projectId = this.projectId;
    this.modalReference.componentInstance.projectName = this.projectName;
    this.modalReference.componentInstance.companyName = this.companyName;
    this.modalReference.componentInstance.componentType =
      PORTAL_TYPE.studioTypeAdd;
    this.modalReference.componentInstance.projectCompanyId =
      this.projectCompanyId;
    this.modalReference.componentInstance.isAccountsPayableUser =
      PORTAL_TYPE.accountsPayableUser;
    this.modalReference.result.then(
      (data) => {
        if (data) {
          if (data.status == this.creditApplicationConstants.statusSuccess) {
            this.getAccountPayableUsers();
            this.displaySuccessMsg = this.user.addUserSuccessMsg;
            this.showSuccess(this.successTpl);
          } else {
            this.errorResponse = data;
            this.showDanger(this.dangerTpl);
          }
        }
        // on close
      },
      (reason) => {
        // on dismiss
      }
    );
  }

  /**
   * onSubmit() - method to submit the Send Credit Application Packet.
   */
  onSubmit(): void {
    if (this.form.valid) {
      const selectedDepartmentIds = this.departmentDetails
        .filter(dept => this.form.value[`department${dept.departmentId}`])
        .map(dept => dept.departmentId);
      // Check if departmentId 1 is selected
      const departmentOneSelected = selectedDepartmentIds.includes(1);
      // If departmentId 1 is selected, send only departmentId 1, otherwise send all selected departments
      const finalDepartmentIds = departmentOneSelected ? [1] : selectedDepartmentIds;
      const selectedAccountPayableUserIds = this.accountPayableUsers
        .filter(user => this.form.value[`caAccountPayableUser${user.userProfileId}`])
        .map(user => user.userProfileId);
      const payload = {
        companyId: this.projectCompanyId,
        projectId: this.projectId,
        caPacketText: this.form.value.caTextbox,
        caDepartmentIds: finalDepartmentIds,
        caAccPayUsrIds: selectedAccountPayableUserIds,
        portalType:'studio'
      };
      this.loaderService.setLoadingState(true);
      this.accountService.sendToCustomer(payload).subscribe({
        next: (response: any) => {
          let message = { status: 'success', message: response.message };
          this.activeModal.close(message);
          this.loaderService.setLoadingState(false);
        },
        error: (error) => {
          let errorMessage = { status: 'error', message: error };
          this.activeModal.close(error);
          this.loaderService.setLoadingState(false);
        },
      });
    } else {
      console.error('Form is invalid:', this.form.errors);
    }
  }

  /**
   * onDepartmentCheckboxChange() - method to handle the event when a department checkbox is checked or unchecked.
   */
  onDepartmentCheckboxChange(event, dept): void {
    const checkbox = event.target as HTMLInputElement;
    const departmentId = Number(checkbox.value);
    switch (checkbox.checked) {
      case true:
        {
          if (departmentId == 1) {
            this.selectedDepartmentIds = [];
            _.forEach(this.departmentDetails, (dept) => {
              this.form
                .get(`department${dept.departmentId}`)
                .setValue(checkbox.checked);
            });
            this.selectedDepartmentIds.push(dept);
          } else {
            this.selectedDepartmentIds.push(dept);
            if (
              this.departmentDetails?.length-1 == this.selectedDepartmentIds?.length
            ) {
              this.selectedDepartmentIds = [];
              _.forEach(this.departmentDetails, (dept) => {
                this.form
                  .get(`department${dept.departmentId}`)
                  .setValue(checkbox.checked);
                dept.departmentId == 1
                  ? this.selectedDepartmentIds.push(dept)
                  : '';
              });
            }
          }
        }
        break;
      case false:
        {
          if (departmentId == 1) {
            this.selectedDepartmentIds = [];
            _.forEach(this.departmentDetails, (dept) => {
              this.form
                .get(`department${dept.departmentId}`)
                .setValue(checkbox.checked);
            });
          } else {
            if (this.departmentDetails?.length == this.selectedDepartmentIds?.length || this.selectedDepartmentIds?.length == 1) {
              this.selectedDepartmentIds = this.departmentDetails;
              this.selectedDepartmentIds = _.filter(this.selectedDepartmentIds,
                (id) => id.departmentId !== departmentId && id.departmentId !== 1
              );
              _.forEach(this.departmentDetails, (dept) => {
                dept.departmentId == 1 ? this.form.get(`department${dept.departmentId}`).setValue(checkbox.checked) : '';
              });
              this.form.get(`department1`).setValue(checkbox.checked);

            } else {
              this.selectedDepartmentIds = _.filter(this.selectedDepartmentIds,
                (id) => id.departmentId !== departmentId
              );
            }
          }
        }
        break;
    }
    this.form.get('departmentsIds')?.setValue(this.selectedDepartmentIds);
    const allChecked = _.every(this.departmentDetails,
      (dept) => this.form.get(`department${dept.departmentId}`)?.value
    );
    this.form.get('custom-checkbox-all')?.setValue(allChecked);
  }
  
  /**
   * closeModal() - Method to close the modal
   */
  closeModal(): void {
    this.activeModal.close();
  }

  /**
   * showSuccess() - Displays a success toast notification with custom content and options.
   * @param successTpl - The template reference containing the toast's content.
   */
  showSuccess(successTpl: TemplateRef<any>): void {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: TemplateRef<any>): void {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }
}
