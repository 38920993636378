<!-- Navbar with fixed positioning -->
<div class="container-fluid">
  <div class="row">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container">
        <!-- Hamburger Menu for Mobile View on the left -->
        <button class="navbar-toggler d-block d-md-none" type="button" (click)="toggleSidebar()" aria-controls="sidebar"
          aria-expanded="false" aria-label="Toggle sidebar">
          <!-- <span class="navbar-toggler-icon"></span> -->
          <img class="pdf-icon" src="../../../../../assets/images/hamburger-menu.svg" alt="menu">
        </button>

        <!-- Profile Dropdown on the left for desktop view -->
        <ul class="navbar-nav d-none d-md-flex">
          <li class="nav-item dropdown">
            <a class="nav-link nav-client-resource" href="#" id="profileDropdown" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              {{constants.client_resource}}
              <span class="header_dropdown-multiselect__caret"></span>
            </a>
            <ul class="dropdown-menu client-res-dropdown" aria-labelledby="profileDropdown">
              <li><a class="dropdown-item" routerLink="/core/wbso-handbook">{{constants.wbso_handbook}}</a></li>
              <li><a class="dropdown-item" routerLink="/core/maps">{{constants.maps}}</a></li>
              <li><a class="dropdown-item" routerLink="/core/core-directory">{{constants.directory}}</a></li>
              <li *ngIf="isLoggedIn"><a class="dropdown-item" routerLink="/core/faqs">{{constants.faq}}</a></li>
            </ul>
          </li>
        </ul>

        <!-- Center Brand Logo -->
        <a class="navbar-brand" (click)="navigateOnLogoClick()">
          <div class="text-center logo-wrapper">
            <h3 class="text-primary" style="margin-bottom: 0;">
              <img [src]="logoPath" class="wb-logo" alt="Logo">
              <span class="logo-title">{{constants.studios_portal}}</span>
              <span class="logo-version-number">V1.0.0</span>
            </h3>
          </div>
        </a>

        <!-- Logged-in User Dropdown on the right -->
        <app-login></app-login>

      </div>
    </nav>
  </div>
  <!-- Sidebar -->
  <div [ngClass]="{'show': sidebarVisible}" [class.noPaddingTop]="noPaddingTop" class="sidebar bg-light">
    <div class="sidebar-section sidebar-bg-black" style="background-color: black;">
      <ul class="navbar-nav">
        <!-- Sidebar menu for mobile view, includes Profile dropdown items -->
        <li class="nav-item">
          <a class="nav-link" href="/core/wbso-handbook">{{constants.wbso_handbook}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/core/maps">{{constants.maps}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/core/core-directory">{{constants.directory}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/core/faqs">{{constants.faq}}</a>
        </li>
      </ul>
    </div>
    <div [ngClass]="{'show': sidebarVisible}" class="bg-light">
      <div *ngIf="isLoggedIn" class="sidebar-section">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" (click)="navigateToDashboard()">{{constants.overview}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="navigateToProjects()">{{constants.projects}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">{{constants.requests}}</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" (click)="navigateToCompany()">{{constants.company}}</a>
          </li>

          <li *ngIf="userTypeId === 2" class="nav-item">
            <a class="nav-link" href="/feature/studio-dashboard/users">{{constants.users}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- sidebar ends here -->

  <!-- login modal section starts -->
  <div class="modal fade login-modal" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title login-modal-title" id="loginModalLabel">{{constants.sign_in_to_wb_studios_portal}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-4 custom-floating-label">
              <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Email Address" />
              <label for="exampleFormControlInput1" class="form-label">{{constants.email_address}}</label>
            </div>
            <div class="mb-4 custom-floating-label">
              <input type="password" class="form-control" id="exampleFormControlInput2" placeholder="Password" />
              <label for="exampleFormControlInput2" class="form-label">{{constants.password}}</label>
            </div>
          </form>
          <div class="d-flex justify-content-between align-items-center">
            <a routerLink="/account-reset-password" class="forgot-pass-link">{{constants.forgot_password}}</a>
            <button type="button" class="btn btn-primary common-btn-secondary"
              (click)="loginUser()">{{constants.sign_in}}</button>
            <button type="button" class="btn btn-primary common-btn-secondary"
              (click)="logout()">{{constants.logout}}</button>
          </div>
        </div>
        <div class="modal-footer  d-flex justify-content-between">
          <p class="login-help-link">{{constants.not_a_client_yet}} <a href=""
              class="contact-us-link">{{constants.contact_us}}</a></p>
        </div>
      </div>
    </div>
  </div>
  <!-- login modal section ends -->

  <!-- common modal section-->
  <div class="modal fade" id="commonModal" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content common-modal-xl">
        <div class="modal-body justify-content-center align-items-center">
          <div class="cm-header-section">
            <h5 class="modal-title" id="commonModalLabel">{{constants.modal_title}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span class="cm-close-text">{{constants.close}}</span>
            </button>
            <p class="cm-subtitle mb-5">{{constants.subtitle_description_meta_data}}</p>
          </div>
          <div class="container cm-main-container">
            <div class="common-modal-input-section">
              <h2 class="section-main-header">{{constants.section_header}}</h2>
              <form>
                <h3 class="form-section-header">{{constants.group_name}}</h3>
                <div class="row">
                  <div class="col-md-6 custom-floating-label">
                    <label for="input1" class="form-label">{{constants.label}}</label>
                    <input type="text" class="form-control" placeholder="Placeholder" id="input1">
                  </div>
                  <div class="col-md-6 custom-floating-label">
                    <label for="input2" class="form-label">{{constants.label}}</label>
                    <input type="text" class="form-control" placeholder="Placeholder" id="input2">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 custom-floating-label">
                    <label for="input3" class="form-label">{{constants.label}}</label>
                    <input type="text" class="form-control" placeholder="Placeholder" id="input3">
                  </div>
                  <div class="col-md-3 custom-floating-label">
                    <label for="input4" class="form-label">{{constants.label}}</label>
                    <input type="text" class="form-control" placeholder="Placeholder" id="input4">
                  </div>
                </div>
                <div class="col-md-4">
                  <label class="custom-checkbox">
                    <input type="checkbox">
                    <span class="checkmark"></span>
                    {{constants.option}}
                  </label>
                </div>
              </form>
            </div>
            <div class="common-modal-input-section">
              <form action="">
                <h3 class="form-section-header mt-0">{{constants.section_header_header}}</h3>
                <div class="row">
                  <div class="col-md-12">
                    <label class="custom-checkbox">
                      <input type="checkbox">
                      <span class="checkmark"></span>
                      {{constants.all}}
                    </label>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-6 col-6">
                    <label class="custom-checkbox">
                      <input type="checkbox">
                      <span class="checkmark"></span>
                      {{constants.option}}
                    </label>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-6 col-6">
                    <label class="custom-checkbox">
                      <input type="checkbox">
                      <span class="checkmark"></span>
                      {{constants.option}}
                    </label>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-6 col-6">
                    <label class="custom-checkbox">
                      <input type="checkbox">
                      <span class="checkmark"></span>
                      {{constants.option}}
                    </label>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-6 col-6">
                    <label class="custom-checkbox">
                      <input type="checkbox">
                      <span class="checkmark"></span>
                      {{constants.option}}
                    </label>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-6 col-6">
                    <label class="custom-checkbox">
                      <input type="checkbox">
                      <span class="checkmark"></span>
                      {{constants.option}}
                    </label>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-6 col-6">
                    <label class="custom-checkbox">
                      <input type="checkbox">
                      <span class="checkmark"></span>
                      {{constants.option}}
                    </label>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-6 col-6">
                    <label class="custom-checkbox">
                      <input type="checkbox">
                      <span class="checkmark"></span>
                      {{constants.option}}
                    </label>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-6 col-6">
                    <label class="custom-checkbox">
                      <input type="checkbox">
                      <span class="checkmark"></span>
                      {{constants.option}}
                    </label>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-6 col-6">
                    <label class="custom-checkbox">
                      <input type="checkbox">
                      <span class="checkmark"></span>
                      {{constants.option}}
                    </label>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-6 col-6">
                    <label class="custom-checkbox">
                      <input type="checkbox">
                      <span class="checkmark"></span>
                      {{constants.option}}
                    </label>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-6 col-6">
                    <label class="custom-checkbox">
                      <input type="checkbox">
                      <span class="checkmark"></span>
                      {{constants.option}}
                    </label>
                  </div>
                </div>
              </form>
            </div>
            <div class="common-modal-input-section">
              <div class="d-flex justify-content-between align-items-center">
                <h3 class="form-section-header mt-0">{{constants.sections_and_permissions}}</h3>
                <h3 class="form-section-header mt-0">{{constants.access}}</h3>
              </div>
              <form action="">
                <div class="main-acc-wrapper">
                  <div class="d-flex justify-content-between align-items-center">
                    <h3 class="acc-sec-header">{{constants.section_header}}</h3>
                    <div class="form-check form-switch">
                      <input class="form-check-input switch" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                        checked>
                    </div>
                  </div>
                  <div class="col-12 col-sm-8 col-md-6 col-lg-4">
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            <img src="../../../../assets/images/eye.svg" class="eye" alt="eye icon">
                            {{constants.accordion_item}}
                          </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample">
                          <div class="accordion-body p-0">{{constants.placeholder_content_for_this_accordion}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="cm-btn-section">
              <button type="button" class="btn common-btn-light me-2"
                data-bs-dismiss="modal">{{constants.secondary_cta}}</button>
              <button type="button" class="btn common-btn-secondary">{{constants.primary_cta}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #successTpl>
    <div class="success">{{addUserSuccess}}</div>
  </ng-template>

  <ng-template #dangerTpl>
    <div class="danger">{{addUserError}}</div>
  </ng-template>
  <app-toast aria-live="polite" aria-atomic="true"></app-toast>
  <!--common modal section ends-->