<div class="container-fluid handbook-wrapper">
  <div class="container">
    <div class="row">
      <div>
        <h1 class="page-title">Handbook</h1>
        <p class="para-text">The Handbook is designed to introduce you and the members of your
          production team to our studios and the operational procedures and policies that exist for all users of the
          Studio. We hope that you will find this manual to be a
          useful reference tool during your production here at Warner Bros. Studios.</p>
      </div>
      <div routerLink="/handbook-page"
        class="card-wrapper col-md-6 col-sm-6 mb-md-3 mb-sm-3 mb-3 col-lg-6 col-xl-3 justify-contents-center d-flex align-items-center">
        <div class="card">
          <div class="card-body">
            <h1 class="card-title">1</h1>
            <p class="card-text">Office & Production Services (OPS)</p>
          </div>
        </div>
      </div>
      <div routerLink="/handbook-page"
        class="card-wrapper col-md-6 col-sm-6 mb-md-3 mb-sm-3 mb-3 col-lg-6 col-xl-3 justify-contents-center d-flex align-items-center">
        <div class="card">
          <div class="card-body">
            <h1 class="card-title">2</h1>
            <p class="card-text">Purchasing & Billing</p>
          </div>
        </div>
      </div>
      <div routerLink="/handbook-page"
        class="card-wrapper col-md-6 col-sm-6 mb-md-3 mb-sm-3 mb-3 col-lg-6 col-xl-3 justify-contents-center d-flex align-items-center">
        <div class="card">
          <div class="card-body">
            <h1 class="card-title">3</h1>
            <p class="card-text">Studio Protection Group / Emergency Response</p>
          </div>
        </div>
      </div>
      <div routerLink="/handbook-page"
        class="card-wrapper col-md-6 col-sm-6 mb-md-3 mb-sm-3 mb-3 col-lg-6 col-xl-3 justify-contents-center d-flex align-items-center">
        <div class="card">
          <div class="card-body">
            <h1 class="card-title">4</h1>
            <p class="card-text">Safety & Environmental Affairs</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>