<button data-bs-toggle="modal" data-bs-target="#content" (click)="openFullscreen(content)">Add A Quote</button>
<!-- common modal section-->
<ng-template #content let-modal>
    <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
            <h5 class="modal-title" id="commonModalLabel">Quotes For Credit Application</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                    class="cm-close-text">Close</span></button>
        </div>
        <div class="container cm-main-container">
            <div class="common-modal-input-section grey-bg">
                <div class="quote-card">
                    <div class="upload-info">
                        <div class="uploaded-date"> Upload from 3/17/2024 at 11:12am</div>
                        <div class="uploaded-by">By Ian Corrigan</div>
                    </div>
                    <div class="row quote-row">
                        <div class="col-lg-6 col-md-4 col-sm-4 col-4">
                            <div>Sound Stages</div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-4 col-4">
                            <a href="#" class="view-quote">View Quote</a>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-4 col-4">
                            <span class="price">$47,320.00</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="common-modal-input-section lower-section">
                <div class="quote-card">
                    <div class="upload-info">
                        <div class="uploaded-date">Upload from 3/15/2024 at 3:48m</div>
                        <div class="uploaded-by"> By Ian Corrigan</div>
                    </div>
                    <div class="status-message">
                        Waiting for a few more quotes from PPCS and Sound Stages
                    </div>
                    <div class="list-div">
                    <ul class="quote-list">
                        <li class="quote-row">
                            <div class="row">
                                <div class="col-lg-6 col-md-4 col-sm-4 col-4">
                                    <div>Exterior Set</div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-4 col-4">
                                    <a href="#" class="view-quote">View Quote</a>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-4 col-4">
                                    <span class="price">$47,320.00</span>
                                </div>
                            </div>
                        </li>
                        <li class="quote-row ">
                            <div class="row">
                                <div class="col-lg-6 col-md-4 col-sm-4 col-4">
                                    <div>Grip Department</div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-4 col-4">
                                    <a href="#" class="view-quote">View Quote</a>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-4 col-4">
                                    <span class="price">$47,320.00</span>
                                </div>
                            </div>
                        </li>
                        <li class="quote-row">
                            <div class="row">
                                <div class="col-lg-6 col-md-4 col-sm-4 col-4">
                                    <div>Set Lighting</div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-4 col-4">
                                    <a href="#" class="view-quote">View Quote</a>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-4 col-4">
                                    <span class="price">$47,320.00</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                </div>
            </div>
        </div>
    
    </div>
</ng-template>