import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  NAME_PATTERN,
  PHONE_PATTERN,
  PROFILE,
} from '../../../../feature/customer-portal/constants/customer-portal.constant';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserProfileSearch } from 'src/app/shared/models/profileModel';
import { ADD_PROJECT_USER, USER } from 'src/app/shared/constants/common';
import { FormValidationService } from 'src/app/shared/services/form-validations/form-validation.service';
import { ToastService } from '../../toast.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @Input() profileInfo: UserProfileSearch;
  @Output() profileData = new EventEmitter<any>();
  profileForm: FormGroup;
  isFormChanged: boolean = false;
  profile = PROFILE;
  user = USER;
  validations = ADD_PROJECT_USER;
  initialFormValues: any;
  disableSaveButton: boolean = true;
  Wbuser: boolean = false;
  userDeatils = JSON.parse(localStorage.getItem('user-profile'))
  userId = this.userDeatils?.userTypeId;
  tooltipMessage = this.profile.tooltip;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private toastService: ToastService,
    private validatorService: FormValidationService
  ) 
  {
    this.Wbuser = this.userId === 2; 
  
    this.profileForm = this.fb.group({
      firstName: [{ value: this.Wbuser ? '' : '', disabled: this.Wbuser },
        [Validators.required,this.validatorService.validateAlphabets]],
      lastName: [{ value: this.Wbuser ? '' : '', disabled: this.Wbuser },
         [Validators.required, this.validatorService.validateAlphabets]],
      email: [
        { value: '', disabled: true },
        [Validators.required, Validators.email, this.validatorService.validateEmail],
      ],
      phone: 
        [,
        [this.validatorService.phoneValidator()],
      ],
      jobTitle: [{ value: this.Wbuser ? '' : '', disabled: this.Wbuser }, [Validators.required, Validators.maxLength(50), this.validatorService.JobTitleValidator]],
    });
  }

  ngOnInit(): void {
    this.profileForm.valueChanges.subscribe(() => {
      this.checkFormChanges();
    });
    this.initialFormValues = this.profileForm?.value;
  }

  /**
   * checkFormChanges() - checks if form values has any changes
   */
  checkFormChanges(): void {
    this.isFormChanged = this.profileForm.dirty;
    this.disableSaveButton = this.isInitialState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['profileInfo']) {
      this.profileForm.patchValue({
        firstName: this.profileInfo?.firstName,
        lastName: this.profileInfo?.lastName,
        email: this.profileInfo?.emailAddress,
        phone: this.profileInfo?.contactNo,
        jobTitle: this.profileInfo?.jobTitle,
      });
    }
    this.initialFormValues = this.profileForm?.value; 
    this.checkFormChanges();
  }

  isInitialState(): boolean {
    return JSON.stringify(this.profileForm?.value) === JSON.stringify(this.initialFormValues);
  }

  /**
   * onSaveSettings() - to perform actions after submitting the form with error handling
   */
  onSave(): void {
    if (this.profileForm.valid) {
      const data = {
        userProfileId: this.profileInfo.userProfileId,
        firstName: this.profileForm.get('firstName')?.value.trim(),
        lastName: this.profileForm.get('lastName')?.value.trim(),
        contactNo: this.profileForm.get('phone')?.value,
        jobTitle: this.profileForm.get('jobTitle')?.value.trim(),
      };
      this.profileData.emit(data);
      this.initialFormValues = this.profileForm?.value
      this.disableSaveButton = true;
    } else {
      this.showDanger(this.dangerTpl);
    }
  }

  /**
   * openDeleteConfirmationModal() - Opens a confirmation modal for deleting an item.
   * @param {TemplateRef<any>} content - The template reference for the modal content to be displayed.
   * @returns {void}
   */
  openDeleteConfirmationModal(content: TemplateRef<any>): void {
    this.modalService.open(content, {
      windowClass: 'common-modal-center',
      centered: true,
    });
  }

  /**
   * navigateToDashboard() - Closes the open modal and navigates to the customer dashboard overview.
   * @param {any} modal - The reference to the currently open modal instance.
   * @returns {void}
   */
  navigateToDashboard(modal: any): void {
    modal.close();
    this.resetChanges();
  }
  
  /**
   * to reset changes after clicking discard
   */
  resetChanges(): void {
    const currentValues = this.profileForm.value;
    Object.keys(currentValues).forEach(key => {
      if (this.profileForm.get(key)?.dirty) {
        this.profileForm.get(key)?.setValue(this.initialFormValues[key]);
      }
    });
    this.checkFormChanges();
  }
  

  /**
   * phoneValidator() - Custom validator to validate phone numbers.
   * It enforces a 15-character limit and only allows numbers, '-', '+', and '()'.
   */
  phoneValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!PHONE_PATTERN.test(value)) {
      return { invalidPhone: true };
    }
    return null;
  }

  /**
   * nameValidator() - Custom validator to validate names
   * @param control - The form control to validate
   * @returns ValidationErrors | null - Returns an error object if validation fails, or null if it passes
   */
  nameValidator(control: AbstractControl): ValidationErrors | null {
    const isValid = NAME_PATTERN.test(control.value);
    if (!isValid) {
      return { invalidName: true };
    }
    return null;
  }

  /**
   * Checks if a form control has a minimum length error
   * @param controlName the name of the form control
   * @param minLength the minimum length required for the control
   * @returns true if the control has a minimum length error, false otherwise
   */
  hasMinLengthError(controlName: string, minLength: number): boolean {
    const control = this.profileForm.get(controlName);
    return control?.hasError('minlength') && control.value.length < minLength;
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }
}
