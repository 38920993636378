import { Component, HostListener, forwardRef, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges } from "@angular/core";
import * as _ from 'lodash';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { ListItem, IDropdownSettings } from "../../models/multiselect.model";
import { ListFilterPipe } from "../../pipes/list-filter.pipe";
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { TypeaheadService } from "../../services/typeahead/typeahead.service";
import { TYPEAHEAD } from "../../enum/shared.enum";
import { MULTISELECT } from "../../constants/common";
export const DROPDOWN_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiSelectComponent),
  multi: true
};
const noop = () => {};

@Component({
  selector: "app-ng-multiselect-dropdown",
  templateUrl: "./multi-select.component.html",
  styleUrls: ["./multi-select.component.scss"],
  providers: [DROPDOWN_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectComponent implements ControlValueAccessor {
  @Input() companyId:number;
  @Input() typeaheadId:any;
  @Input() activestatus;
  public _settings!: IDropdownSettings;
  public _data: Array<ListItem> = [];
  public selectedItems: Array<ListItem> = [];
  public isDropdownOpen = true;
  _type: string = "";
  _filterData: any[] = [];
  public _sourceDataType: any = null; // to keep note of the source data type. could be array of string/number/object
  private _sourceDataFields: Array<String> = []; // store source data fields names
  filter: ListItem = new ListItem(this.data);
  searchControl = new FormControl();
  users$: Observable<any[]>;
  jobTitle$: Observable<any[]>;
  company$:Observable<any[]>;
  userId$: Observable<any[]>;
  projectId$: Observable<any[]>;
  activeProjects$: Observable<any[]>;
  noTypeaheadData = false;
  inputTypeahead = '';
  searchedTypeahead = false;
  applyTypeahead = false;
  typeahead = TYPEAHEAD;
  multiselect = MULTISELECT;
  _placeholder = this.multiselect.placeholder;
  _searchtextplaceholder = this.multiselect.typeaheadplaceholder;
  


/**
* defaultSettings - variable holds the default setting value if there is not input value from parent component
*/

  defaultSettings: IDropdownSettings = {
    idField: "id",
    textField: "text",
    enableCheckAll: true,
    allowSearchFilter: true,
    limitSelection: -1,
    clearSearchFilter: true,
    maxHeight: 197,
    itemsShowLimit: 999999999999,
    searchPlaceholderText: "Search",
    noDataAvailablePlaceholderText: "No data available",
    noFilteredDataAvailablePlaceholderText: "No filtered data available",
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
    allowRemoteDataSearch: false
  };
    clonedData: any;
    @Input() defaultSelectedStatus;
  /**
  * @Input() type - value will be shown up in the select input box,
   * @param value - value will be getting from the parent component
  */

  @Input()
  public set type(value: string) {
    if (value) {
      this._type = value;
    } else {
      this._type = this._type;
    }
  }

  /**
 * @Input() type - value will be shown up in the select input box,
  * @param value - value will be getting from the parent component
 */

  @Input()
  public set filterData(value: any[]) {
    if (value) {
      this._filterData = value;
    } else {
      this._filterData = [];
    }
  }


  /**
  * @Input() searchtextplaceholder - value will be shown up in the select input box,
   * @param value - value will be getting from the parent component
  */

  @Input()
  public set searchtextplaceholder(value: string) {
    if (value) {
      this._searchtextplaceholder = value;
    } else {
      this._searchtextplaceholder = this._searchtextplaceholder;
    }
  }

/**
* @Input() placeholder - value will be shown up in the select input box,
 * @param value - value will be getting from the parent component
*/

  @Input()
  public set placeholder(value: string) {
    if (value) {
      this._placeholder = value;
    } else {
      this._placeholder = "Select";
    }
  }

/**
* @Input() settings - settings value will be the rules/commands for multiselect
* @param value - value hold the property of the multiselect which comes from the parent component
*/

  @Input()
  public set settings(value: IDropdownSettings) {
    if (value) {
      this._settings = Object.assign(this.defaultSettings, value);
    } else {
      this._settings = Object.assign(this.defaultSettings);
    }
  }

/**
* @Input() data - data which is the source of the component from parent component
* @param value - value will be an array of object to generate the data for multiselct options
*/

  @Input()
  public set  data(value: Array<any>) {
    if (!value) {
      this._data = [];
    } else {
      const firstItem = value[0];
      this._sourceDataType = typeof firstItem;
      this._sourceDataFields = this.getFields(firstItem);
      this._data = value.map((item: any) =>
        typeof item === "string" || typeof item === "number"
          ? new ListItem(item)
          : new ListItem({
              id: item[this._settings.idField],
              text: item[this._settings.textField],
            })
      );
    }
  }

/**
* @Output() onFilterChange - perform the operation based on input search filter
*/

  @Output("onFilterChange")
  onFilterChange: EventEmitter<ListItem> = new EventEmitter<any>();

/**
* @Output() onDropDownClose - perform the operation based on dropdown close
*/

  @Output("onDropDownClose")
  onDropDownClose: EventEmitter<ListItem> = new EventEmitter<any>();

/**
* @Output() onSelect - selected value will emit the data to parent using EventEmiiter function
*/

  @Output("onSelect")
  onSelect = new EventEmitter<any>();

/**
* @Output() onDeSelect - deselected value will emit the data to parent using EventEmiiter function
*/

  @Output("onDeSelect")
  onDeSelect: EventEmitter<ListItem> = new EventEmitter<any>();


/**
* onTouchedCallback - default multiselect callback methods
* onChangeCallback - default multiselect callback methods
*/

  private onTouchedCallback: () => void = noop;
  public onChangeCallback: (_: any) => void = noop;

/**
* onFilterTextChange - Function which is used to search the value in the select options 
* @param  $event - input value which is typed by the user on the search box
*/

  onFilterTextChange($event: any) : any {
    this.onFilterChange.emit($event);
  }

  constructor(
    private listFilterPipe:ListFilterPipe,
    public cdr: ChangeDetectorRef,
    private typeaheadService: TypeaheadService
  ) {}

  /**
  * ngOnChanges - method for updating the multiselct value.
  * @param  changes - once the input value is changes in parent component, this changes value will trigger.
  */
  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {
      switch (propName) {
        case 'filterData':
          this._filterData = changes[propName].currentValue;
          break
        case 'data':
          _.isNil(changes[propName].previousValue) ? this.clonedData = _.cloneDeep(this._data) : this.clonedData = this.clonedData;
          this._data = this.clonedData;
          const isChanged = changes[propName].currentValue?.length - changes[propName].previousValue?.length;
          if (changes[propName].currentValue?.length && changes[propName].previousValue?.length && (isChanged === 1 || isChanged > 1)) {
            this.clonedData = _.cloneDeep(changes[propName].currentValue);
            this._data = this.clonedData;
          }
          break
      }
      let change = changes[propName];
      let curVal = change.currentValue;
      let prevVal = change.previousValue;
      if (!_.isNil(curVal) && curVal?.length > 0) {
        this.removeSelected(curVal[0]);
      } else {
        this.selectedItems = [];
      }
    }
  }

  /**
* ngOnInit - initializing the component.
*/
  ngOnInit(): void {

    if (this.activestatus == 'ACTIVE') {
      let data = _.filter(this._data, i => i.text == this.activestatus);

      this.clonedData = _.cloneDeep(data);
    }
    this.userTypeahead();
    this.jobtitleTypeahead();
    this.companyTypeahead();
    this.userIdTypeahead();
    this.projectIdTypeahead();
    this.activeProjectsTypeahead();
    this.companiesTypeahead();
    this.departmentsTypeahead();
  }

/**
* onItemClick - Function which is used to track the selected option and create an array
* @param  item - passing the selected json object as params
*/

  onItemClick($event: any, item: any): any {
    const found = this.isSelected(item);
    const allowAdd = this._settings?.limitSelection === -1 || (this._settings?.limitSelection > 0 && this.selectedItems?.length < this._settings?.limitSelection);
    if (!found) {
      if (allowAdd) {
        this.addSelected(item);
      }
    } else {
      this.removeSelected(item);
    }
    if (this._settings.closeDropDownOnSelection) {
      this.closeDropdown();
      }
  }

/**
* writeValue - default function loads the inital value in dropdown by the ng multiselect module
* @param  value - passing an array from the parent component as input that iterate the select dropdown options
*/
  writeValue(value: any): any{
    if (value !== undefined && value !== null && value?.length > 0) {
      const _data = value.map((item: any) =>
        typeof item === "string" || typeof item === "number"
          ? new ListItem(item)
          : new ListItem({
            id: item[this._settings.idField],
            text: item[this._settings.textField],
          })
      );
      if (this._settings?.limitSelection > 0) {
        this.selectedItems = _data.splice(0, this._settings?.limitSelection);
      } else {
        this.selectedItems = _data;
      }
    } else {
      this.selectedItems = [];
    }
    this.onChangeCallback(value);

    this.cdr.markForCheck();
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): any {
    this.onChangeCallback = fn;
  }
  // From ControlValueAccessor interface
  registerOnTouched(fn: any): any{
    this.onTouchedCallback = fn;
  }
  // Set touched on blur
  @HostListener("blur")
  public onTouched() {
    this.onTouchedCallback();
  }

/**
* trackByFn - to get the unique value for the multiselct option
* @param  item - return the unique value after selction of record
*/

  trackByFn(index: any, item: any) {
    return item.id;
  }

/**
* isSelected - getting the selected option vai checkbox selection
* @param  clickedItem - return the selected json value from the array of option
*/

  isSelected(clickedItem: ListItem) {
    let found = false;
    this.selectedItems.forEach(item => {
      if (clickedItem.id === item.id) {
        found = true;
      }
    });
    return found;
  }


/**
* addSelected - this function called inside the "onItemClick" to create an array of selected item
* @param  item - selected option in the format of json value will be the input for this method and creates an array
*/

  addSelected(item: any) {
    if(!this.defaultSelectedStatus){
      let selected: any[] = [...this.selectedItems];
      selected.push(item);
      this.selectedItems = [...selected];
      if (this._type == 'typeahead' && this.selectedItems?.length > 0) {
        this.applyTypeahead = true;
      }
    }else if(this.defaultSelectedStatus){
      let selected: any[] = [...this.defaultSelectedStatus];
      selected.push(item);
      this.selectedItems = [...selected];
    }
    
  }

/**
* removeSelected - this function called inside the "onItemClick" to remove the object from an array which selected eariler.
* @param  itemSel - deselected option in the format of json value will be the input for this method and value will be reomved from an array
*/

  removeSelected(itemSel: any) {
    let selected: any[] = [...this.selectedItems];
    selected = _.filter(selected , item => item.id !== itemSel.id);
    this.selectedItems = [...selected];
  }

/**
* emittedValue - function will emit the value on each option selection in the multiselect dropdown, which calls inside the "addSelected" and "removeSelected"
* @param  val - expecting the json value as input to emit the value to parent component
*/
  emittedValue(val: any): any {
    const selected :any [] = [];
    if (Array.isArray(val)) {
      val.map(item => {
        selected.push(this.objectify(item));
      });
    } else {
      if (val) {
        return this.objectify(val);
      }
    }
    return selected;
  }

/**
* objectify - function will emit the value in json format with required key,value pair, this method calls inside the "emittedValue"
* @param  val - returned value from the "emittedValue" method as input to this method for object manipulation
*/

  objectify(val: ListItem) {
    if (this._sourceDataType === 'object') {
      const obj = { 'id': val.id, 'text': val.text };
      return obj;
    }
    if (this._sourceDataType === 'number') {
      return Number(val.id);
    } else {
      return val.text;
    }
  }

/**
* toggleDropdown - function for opening and closing the multiselect
* @param  evt - preventing the event and hadlning the open and close using boolean value.
*/

  toggleDropdown(evt: any): any  {
    evt.preventDefault();
    this._settings.defaultOpen = !this._settings.defaultOpen;
    if (!this._settings.defaultOpen) {
      this.onDropDownClose.emit();
    }
    if (this.inputTypeahead == '') {
      this.noTypeaheadData = false;
    }
    if (this._type == 'typeahead' && this.selectedItems?.length > 0) {
      this.applyTypeahead = false;
    }
  }

/**
* closeDropdown - method for closing the multiselect
*/

  closeDropdown() : any {
    this._settings.defaultOpen = false;
    //  this.onSelect.emit(this.selectedItems);
    if (this._settings.clearSearchFilter) {
      this.filter.text = "";
    }
    this.onDropDownClose.emit();
    this.inputTypeahead = '';
    if (this._type == 'typeahead' && this.selectedItems?.length > 0) {
      this.applyTypeahead = false;
    }
  }

/**
* getFields - creating the array of object for the source data and called inside the "@Input data"
* @param  inputData - inputData getting from the parent component
*/

  getFields(inputData : any) {
    const fields : any[] = [];
    if (typeof inputData !== "object") {
      return fields;
    }
    for (const prop in inputData) {
      fields.push(prop);
    }
    return fields;
  }

/**
* applyFilter - method for applying the filter once the options are selected and emitting the data to parent component
*/

  applyFilter():void {
    this.onSelect.emit(this.selectedItems);
    this.closeDropdown();
  }



  /**
  * userTypeahead - function to invoke the API call for user typeahead
  */

  userTypeahead(): any {
   this.users$ = this.searchControl.valueChanges.pipe(
  switchMap(searchTerm => {
    if (!searchTerm) {
      return of([]); // Return an observable of an empty array if no search term
    }

    // Filter data based on search term
    const results = this._filterData.filter(item => {
      if (item?.isServiceRequest) {
        // If isServiceRequest is true, filter by email only
        return item.email.toLowerCase().includes(searchTerm.toLowerCase());
      } else {
        // Default behavior: filter by both fullName and email
        return item.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
               item.email.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });

    // Conditionally sort based on whether any item has `isServiceRequest` true
    let sortedResult;
    if (results.some(item => item.isServiceRequest)) {
      // If any item has isServiceRequest = true, sort by email
      sortedResult = _.sortBy(results, ({ email }) => email.toLowerCase());
    } else {
      // Default sorting by fullName for other components
      sortedResult = _.sortBy(results, ({ fullName }) => fullName.toLowerCase());
    }

    return of(sortedResult);
  }),
  tap((results) => {
    this.noTypeaheadData = results?.length > 0 ? false : true; // Handle empty results
  })
);
  }

  /**
  * jobtitleTypeahead - function to invoke the API call for jobtitle typeahead
  */

  jobtitleTypeahead(): any {
    this.jobTitle$ = this.searchControl.valueChanges.pipe(
      switchMap(searchTerm => {
        if (!searchTerm) {
          return of([]); // Return an observable of an empty array
        }
        const results = this._filterData.filter(item => item.jobtitle.toLowerCase().includes(searchTerm.toLowerCase()));
        let sortedResult = _.sortBy(results, ({ jobtitle }) => jobtitle.toLowerCase())
        return of(sortedResult);
      }),
      tap((results) => {
        this.noTypeaheadData = results?.length > 0 ? false : true;
      })
    );
  }
   /**
  * activeProjectsTypeahead - function to invoke the API call for active projects typeahead
  */

  activeProjectsTypeahead(): any {
    this.activeProjects$ = this.searchControl.valueChanges.pipe(
      switchMap(searchTerm => {
        if (!searchTerm) {
          return of([]); // Return an observable of an empty array
        }
        const results = this._filterData.filter(item => item.text.toLowerCase().includes(searchTerm.toLowerCase()));
        return of(results);
      }),
      tap((results) => {
        this.noTypeaheadData = results?.length > 0 ? false : true;
      })
    );
  }

    /**
  * companiesTypeahead - function to invoke the API call for companies typeahead
  */
    companiesTypeahead(): any {
      this.activeProjects$ = this.searchControl.valueChanges.pipe(
        debounceTime(this.typeahead?.debounceTime),
        distinctUntilChanged(),
        switchMap(searchTerm => {
          if (!searchTerm) {
            return of([]); // Return an observable of an empty array
          }
          const results = this._filterData.filter(item => item.text.toLowerCase().includes(searchTerm.toLowerCase()));
          return of(results);
        }),
        tap((results) => {
          this.noTypeaheadData = results?.length > 0 ? false : true;
        })
      );
    }

  /**
  * departmentsTypeahead - function to invoke the API call for department typeahead
  */
  departmentsTypeahead(): any {
      this.activeProjects$ = this.searchControl.valueChanges.pipe(
        debounceTime(this.typeahead?.debounceTime),
        distinctUntilChanged(),
        switchMap(searchTerm => {
          if (!searchTerm) {
            return of([]); // Return an observable of an empty array
          }
          const results = this._filterData.filter(item => item.text.toLowerCase().includes(searchTerm.toLowerCase()));
          return of(results);
        }),
        tap((results) => {
          this.noTypeaheadData = results?.length > 0 ? false : true;
        })
      );
    }

  /**
  * userIdTypeahead - function to invoke the API call for userId typeahead
  */

  userIdTypeahead(): any{
    this.userId$ = this.searchControl.valueChanges.pipe(
      switchMap(searchTerm => {
        if (!searchTerm) {
          return of([]); 
        }
        const results = this._filterData.filter(item => item.id.toString().includes(searchTerm));
        
        return of(results);
      }),
      tap((results) => {
        this.noTypeaheadData = results?.length > 0 ? false : true;
      })
    );
  }
/**
  * companyTypeahead - function to invoke the API call for company user id typeahead
  */
  companyTypeahead(): any {
    this.company$ = this.searchControl.valueChanges.pipe(
      switchMap(searchTerm => {
        return this.typeaheadService.searchCompanyOrID(searchTerm)
      }),
      tap((results) => {
        this.noTypeaheadData = results?.length > 0 ? false : true;
      })
    );
  }
  /**
  * projectIdTypeahead - function to invoke the API call for userId typeahead
  */

  projectIdTypeahead(): any{
    this.projectId$ = this.searchControl.valueChanges.pipe(
      switchMap(searchTerm => {
        if (!searchTerm) {
          return of([]); // Return an observable of an empty array
        }
        const results = this._filterData.filter(item =>
          item.projectId.toString().includes(searchTerm)
        );
        return of(results);
      }),
      tap((results) => {
        this.noTypeaheadData = results?.length > 0 ? false : true;
      })
    );
  }


}
