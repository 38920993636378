import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/shared/services/base-api/base-api.service';
import { STUDIO_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';

@Injectable({
  providedIn: 'root'
})
export class StudioDashboardService {

  constructor(private baseApiService: BaseApiService) { }

  /**
   * getDashboardCreditApplication() - Method to call get Credit Application details.
   * @returns - The API response containing credit application details.
  */
  getDashboardCreditApplication(): any {
    const url = `${STUDIO_PORTAL_API_PATHS.dashboard_ca}`;
    return this.baseApiService.get(url);
  }
  
  /**
   * getDashboardBookmarked() - Method to call get Bookmarked data.
   * @returns - The API response containing bookmarked details.
  */
  getDashboardBookmarked(): any {
    const url = `${STUDIO_PORTAL_API_PATHS.dashboard_bookmarked}`;
    return this.baseApiService.get(url);
  }
}
