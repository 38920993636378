<app-menu [portalType]="portalType"></app-menu>
<div class="container-fluid page-wrapper">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<h1 class="page-title px-0 mb-0">{{constants.handbook}}</h1>
				<div class="d-flex align-items-center view-text ">
					<div>
						<p class="me-3 mb-0 change-weather-title-block">You are viewing the Handbook for <span
								class="change-weather-title">{{onTheLotCountryName}}</span> <span class="change-weather-toggle"> <span>Change</span> <i
									class="bi bi-geo-alt-fill"></i>
							</span></p>
					</div>
				</div>
			</div>
			<!-- <div class="d-flex flex-row select-block px-0"> -->
			<div class="col-md-4 col-lg-4  d-none d-md-block ">
				<ol class="handbook-list" id="v-pills-tab" role="tablist" aria-orientation="vertical">
					<li
					  class="nav-link"
					  id="office-production-tab"
					  role="tab"
					  aria-controls="office-production-content"
					  aria-selected="true"
					  (click)="selectTab(constants.office_production_tab)"
					  data-bs-toggle="collapse"
					  href="#collapseOne"
					  role="button"
					  aria-expanded="false"
					  aria-controls="collapseOne"
					>
					  <span class="list-number">{{number.one}}{{constants.dot}}</span> {{constants.office_and_production_services}}
					</li>
					<div class="collapse" id="collapseOne">
					  <ul class="handbook-sub-list scrollable-container">
							<li *ngFor="let listItem of handbookTab"
							class="nav-link"
							id="listItem"
							data-bs-toggle="pill"
							href="listItem"
							role="tab"
							aria-controls="1.0 – Overview"
							aria-selected="false"
							(click)="selectTab(listItem)"
							>
							{{listItem}}

							</li>
					</ul>
				  </div>
				  <li
				  class="nav-link"
				  id="purchase-billing-tab"
				  role="tab"
				  aria-controls="purchase-billing-content"
				  aria-selected="false"
				  (click)="selectTab(constants.purchase_billing_tab)"
				  data-bs-toggle="collapse"
				  href="#collapseTwo"
				  role="button"
				  aria-expanded="false"
				  aria-controls="collapseTwo"
				>
				
				  <span class="list-number">{{number.two}}{{constants.dot}}</span> {{constants.purchasing_and_billing}}
				</li>
				<div class="collapse" id="collapseTwo">
				  <ul class="handbook-sub-list scrollable-container">
					<li *ngFor="let listItem of handbookTab1"
						class="nav-link"
						id="listItem"
						data-bs-toggle="pill"
						role="tab"
						aria-controls="purchase-billing-0-tab"
						aria-selected="false"
						(click)="selectTab(listItem)"
						href="#collapseTwo"
						role="button"
						>
						{{listItem}}
					</li>
					</ul>
				  </div>
				  
				  <li
				  class="nav-link"
				  id="studio-protection-tab"
				  role="tab"
				  aria-controls="studio-protection-content"
				  aria-selected="false"
				  (click)="selectTab(constants.studio_protection_tab)"
				  data-bs-toggle="collapse"
				  href="#collapseThree"
				  role="button"
				  aria-expanded="false"
				  aria-controls="collapseThree"
				>
				  <span class="list-number">{{number.three}}{{constants.dot}}</span> {{constants.studio_protection_group_emergency_response}}
				</li>
				<div class="collapse" id="collapseThree">
				  <ul class="handbook-sub-list scrollable-container">
					<li *ngFor="let listItem of handbookTab2"
					  class="nav-link"
					  id="listItem"
					  data-bs-toggle="pill"
					  href="#studio-protection-0-tab"
					  role="tab"
					  aria-controls="studio-protection-0-tab"
					  aria-selected="false"
					  (click)="selectTab(listItem)"
					>
					{{listItem}}
					</li>
					</ul>
				  </div>
				  <li
				  class="nav-link"
				  id="safety-environment-tab"
				  role="tab"
				  aria-controls="safety-environment-content"
				  aria-selected="false"
				  (click)="selectTab(constants.safety_environment_tab)"
				  data-bs-toggle="collapse"
				  href="#collapseFour"
				  role="button"
				  aria-expanded="false"
				  aria-controls="collapseFour"
				>
				  <span class="list-number">{{number.four}}{{constants.dot}}</span> {{constants.safety_and_environmental_affairs}}
				</li>
				<div class="collapse" id="collapseFour">
				  <ul class="handbook-sub-list scrollable-container">
					<li
					  class="nav-link"
					  id="safety-environment-0-tab"
					  data-bs-toggle="pill"
					  href="#safety-environment-0-tab"
					  role="tab"
					  aria-controls="safety-environment-0-tab"
					  aria-selected="false"
					  (click)="selectTab(constants.safety_environment_0_tab)"
					>
					  <span class="list-number">{{number.four_point_zero}}</span> {{constants.overview}}
					</li>
					<li
					  class="nav-link"
					  id="safety-environment-1-tab"
					  data-bs-toggle="pill"
					  href="#safety-environment-1-tab"
					  role="tab"
					  aria-controls="safety-environment-1-tab"
					  aria-selected="false"
					  (click)="selectTab(constants.safety_environment_1_tab)"
					>
					  <span class="list-number">{{number.four_point_one}}</span> {{constants.primary_contacts}}
					</li>
					  <li
						class="nav-link"
						id="safety-environment-2-tab"
						data-bs-toggle="pill"
						href="#safety-environment-2-tab"
						role="tab"
						aria-controls="safety-environment-2-tab"
						aria-selected="false"
						(click)="selectTab(constants.safety_environment_2_tab)"
					  >
						<span class="list-number">{{number.four_point_two}}</span> {{constants.iipp}}
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-3-tab"
						data-bs-toggle="pill"
						href="#safety-environment-3-tab"
						role="tab"
						aria-controls="safety-environment-3-tab"
						aria-selected="false"
						(click)="selectTab(constants.safety_environment_3_tab)"
					  >
						<span class="list-number">{{number.four_point_three}}</span> {{constants.important_points_to_help_keep_your_employees_safe}}
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-4-tab"
						data-bs-toggle="pill"
						href="#safety-environment-4-tab"
						role="tab"
						aria-controls="safety-environment-4-tab"
						aria-selected="false"
						(click)="selectTab(constants.safety_environment_4_tab)"
					  >
						<span class="list-number">{{number.four_point_four}}</span> {{constants.staff_and_crew_training}}
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-5-tab"
						data-bs-toggle="pill"
						href="#safety-environment-5-tab"
						role="tab"
						aria-controls="safety-environment-5-tab"
						aria-selected="false"
						(click)="selectTab(constants.safety_environment_5_tab)"
					  >
						<span class="list-number">{{number.four_point_five}}</span> {{constants.production_start_up_guidelines_for_warner_bros_productions}}
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-6-tab"
						data-bs-toggle="pill"
						href="#safety-environment-6-tab"
						role="tab"
						aria-controls="safety-environment-6-tab"
						aria-selected="false"
						(click)="selectTab(constants.safety_environment_6_tab)"
					  >
						<span class="list-number">{{number.four_point_six}}</span> {{constants.productions_not_affiliated_with_warner_bros}}
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-7-tab"
						data-bs-toggle="pill"
						href="#safety-environment-7-tab"
						role="tab"
						aria-controls="safety-environment-7-tab"
						aria-selected="false"
						(click)="selectTab(constants.safety_environment_7_tab)"
					  >
						<span class="list-number">{{number.four_point_seven}}</span> {{constants.inspection_procedures_for_osha_and_other_regulatory_agencies}}
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-8-tab"
						data-bs-toggle="pill"
						href="#safety-environment-8-tab"
						role="tab"
						aria-controls="safety-environment-8-tab"
						aria-selected="false"
						(click)="selectTab(constants.safety_environment_8_tab)"
					  >
						<span class="list-number">{{number.four_point_eight}}</span> {{constants.craft_service}}
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-9-tab"
						data-bs-toggle="pill"
						href="#safety-environment-9-tab"
						role="tab"
						aria-controls="safety-environment-9-tab"
						aria-selected="false"
						(click)="selectTab(constants.safety_environment_9_tab)"
					  >
						<span class="list-number">{{number.four_point_nine}}</span> {{constants.air_quality}}
					  </li>
					  <li
					  class="nav-link"
					  id="safety-environment-10-tab"
					  data-bs-toggle="pill"
					  href="#safety-environment-10-tab"
					  role="tab"
					  aria-controls="safety-environment-10-tab"
					  aria-selected="false"
					  (click)="selectTab(constants.safety_environment_10_tab)"
					>
					  <span class="list-number">{{number.four_point_ten}}</span>{{constants.genral_warning_and_notifications_set_construction_safety}}
					</li>
					  <li
						class="nav-link"
						id="safety-environment-11-tab"
						data-bs-toggle="pill"
						href="#safety-environment-11-tab"
						role="tab"
						aria-controls="safety-environment-11-tab"
						aria-selected="false"
						(click)="selectTab(constants.safety_environment_11_tab)"
					  >
						<span class="list-number">{{number.four_point_eleven}}</span> {{constants.managing_chemical_containing_products}}
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-12-tab"
						data-bs-toggle="pill"
						href="#safety-environment-12-tab"
						role="tab"
						aria-controls="safety-environment-12-tab"
						aria-selected="false"
						(click)="selectTab(constants.safety_environment_12_tab)"
					  >
						<span class="list-number">{{number.four_point_twelve}}</span> {{constants.wbso_sewer_and_storm_drain_policies}}
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-13-tab"
						data-bs-toggle="pill"
						href="#safety-environment-13-tab"
						role="tab"
						aria-controls="safety-environment-13-tab"
						aria-selected="false"
						(click)="selectTab(constants.safety_environment_13_tab)"
					  >
						<span class="list-number">{{number.four_point_thirteen}}</span> {{constants.additional_storm_drain_policy_guidelines_by_department}}
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-14-tab"
						data-bs-toggle="pill"
						href="#safety-environment-14-tab"
						role="tab"
						aria-controls="safety-environment-14-tab"
						aria-selected="false"
						(click)="selectTab(constants.safety_environment_14_tab)"
					  >
						<span class="list-number">{{number.four_point_fourteen}}</span> {{constants.guidelines_for_shipping_dangerous_goods}}
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-15-tab"
						data-bs-toggle="pill"
						href="#safety-environment-15-tab"
						role="tab"
						aria-controls="safety-environment-15-tab"
						aria-selected="false"
						(click)="selectTab(constants.safety_environment_15_tab)"
					  >
						<span class="list-number">{{number.four_point_fifteen}}</span> {{constants.unmanned_aerial_vehicles_unmanned_aerial_camera_platforms_drones}}
					  </li>
					</ul>
				  </div>
				  
				</ol>
			</div>
			<div class="col-md-4 col-lg-4 col-sm-12 d-block d-md-none mobile-select-block">
				<select class="form-select" id="mobileSelect" [(ngModel)]="selectedTab" (change)="onSelectChange($event)">
					<option value="office-production-tab">
						<span class="list-number">{{number.one}}{{constants.dot}}</span> {{constants.office_and_production_services}}
					</option>
					<option value="1.0 – Overview">
						<span class="list-number">{{number.one_point_zero}}</span> {{constants.overview}}
					</option>
					<option value="office-production-1-tab">
						<span class="list-number">{{number.one_point_one}}</span> {{constants.sound_stage_chart}}
					</option>
					<option value="office-production-2-tab">
						<span class="list-number">{{number.one_point_two}}</span> {{constants.stage_check_in_and_check_out}}
					</option>
					<option value="office-production-3-tab">
						<span class="list-number">{{number.one_point_three}}</span> {{constants.general_stage_housekeeping}}
					</option>
					<option value="office-production-4-tab">
						<span class="list-number">{{number.one_point_four}}</span> {{constants.hot_lock_and_stage_card_access_procedures}}
					</option>
					<option value="office-production-5-tab">
						<span class="list-number">{{number.one_point_five}}</span> {{constants.stage_specs}}
					</option>
					<option value="office-production-6-tab">
						<span class="list-number">{{number.one_point_six}}</span> {{constants.overhead_loading_roof_trusses_and_permanent_beams}}
					</option>
					<option value="office-production-7-tab">
						<span class="list-number">{{number.one_point_seven}}</span> {{constants.permanent_construction_requests}}
					</option>
					<option value="office-production-8-tab">
						<span class="list-number">{{number.one_point_eight}}</span> {{constants.stage_lighting_guidelines}}
					</option>
					<option value="office-production-9-tab">
						<span class="list-number">{{number.one_point_nine}}</span> {{constants.stage_dressing_rooms}}
					</option>
					<option value="office-production-10-tab">
						<span class="list-number">{{number.one_point_ten}}</span> {{constants.production_guidelines_exterior_sets}}
					</option>
					<option value="office-production-11-tab">
						<span class="list-number">{{number.one_point_eleven}}</span> {{constants.notification_policy_for_surrounding_neighborhoods}}
					</option>
					<option value="office-production-12-tab">
						<span class="list-number">{{number.one_point_twelve}}</span> {{constants.daily_call_sheets_prod_schedules_staff_crew}}
					</option>
					<option value="office-production-13-tab">
						<span class="list-number">{{number.one_point_thirteen}}</span> {{constants.radio_frequency_equipment_prod_sound_video}}
					</option>
					<option value="office-production-14-tab">
						<span class="list-number">{{number.one_point_fourteen}}</span> {{constants.camera_policy_press_publicity_access_procedures}}
					</option>
					<option value="office-production-15-tab">
						<span class="list-number">{{number.one_point_fifteen}}</span> {{constants.production_guidelines_water_sets}}
					</option>
					<option value="office-production-16-tab">
						<span class="list-number">{{number.one_point_sixteen}}</span> {{constants.office_guidelines}}
					</option>
					<option value="office-production-17-tab">
						<span class="list-number">{{number.one_point_seventeen}}</span> {{constants.furniture}}
					</option>
					<option value="office-production-18-tab">
						<span class="list-number">{{number.one_point_eighteen}}</span> {{constants.phones_wifi_studionet}}
					</option>
					<option value="office-production-19-tab">
						<span class="list-number">{{number.one_point_nineteen}}</span> {{constants.custodial_service}}
					</option>
					<option value="office-production-20-tab">
						<span class="list-number">{{number.one_point_twenty}}</span> {{constants.signage_exterior_display}}
					</option>
					<option value="office-production-21-tab">
						<span class="list-number">{{number.one_point_twentyone}}</span> {{constants.conference_room_bookings}}
					</option>
					<option value="office-production-22-tab">
						<span class="list-number">{{number.one_point_twentytwo}}</span> {{constants.key_shop}}
					</option>
					<option value="office-production-23-tab">
						<span class="list-number">{{number.one_point_twentythree}}</span> {{constants.parking_services}}
					</option>
					<option value="office-production-24-tab">
						<span class="list-number">{{number.one_point_twentyfour}}</span> {{constants.warner_bros_labor_dept}}
					</option>
					<option value="office-production-25-tab">
						<span class="list-number">{{number.one_point_twentyfive}}</span> {{constants.recycling_guidelines_social_vocational_services}}
					</option>
					<option value="office-production-26-tab">
						<span class="list-number">{{number.one_point_twentysix}}</span> {{constants.smoking_policy}}
					</option>
					<option value="purchase-billing-tab">
						<span class="list-number">{{number.two}}{{constants.dot}}</span> {{constants.purchasing_and_billing}}
					</option>
					<option value="purchase-billing-0-tab">
						<span class="list-number">{{number.two_point_zero}}</span>  {{constants.accounts_receivable_numbers}}
					</option>
					<option value="purchase-billing-1-tab">
						<span class="list-number">{{number.two_point_one}}</span> {{constants.studio_work_authorizations}}
					</option>
					<option value="purchase-billing-2-tab">
						<span class="list-number">{{number.two_point_two}}</span> {{constants.quick_reference_guide_to_swa_creation}}
					</option>
					<option value="purchase-billing-3-tab">
						<span class="list-number">{{number.two_point_three}}</span>  {{constants.studio_work_authorization_form}}
					</option>
					<option value="purchase-billing-4-tab">
						<span class="list-number">{{number.two_point_four}}</span> {{constants.construction_flat_bids}}
					</option>
					<option value="purchase-billing-5-tab">
						<span class="list-number">{{number.two_point_five}}</span>{{constants.invoices}}
					</option>
					<option value="studio-protection-tab">
						<span class="list-number">{{number.three}}{{constants.dot}}</span> {{constants.studio_protection_group_emergency_response}}
					</option>
					<option value="studio-protection-0-tab">
						<span class="list-number" selected>{{number.three_point_zero}}</span> {{constants.studio_protection_group}}
					</option>
					<option value="studio-protection-1-tab">
						<span class="list-number">{{number.three_point_one}}</span> {{constants.wb_security_primary_contacts}}
					</option>
					<option value="studio-protection-2-tab">
						<span class="list-number">{{number.three_point_two}}</span> {{constants.gate_hours}}
					</option>
					<option value="studio-protection-3-tab">
						<span class="list-number">{{number.three_point_three}}</span> {{constants.studio_access_policies_and_procedures}}
					</option>
					<option value="studio-protection-4-tab">
						<span class="list-number">{{number.three_point_four}}</span> {{constants.access_categories_and_pass_types}}
					</option>
					<option value="studio-protection-5-tab">
						<span class="list-number">{{number.three_point_five}}</span> {{constants.wbso_call_sheet}}
					</option>
					<option value="studio-protection-6-tab">
						<span class="list-number">{{number.three_point_six}}</span> {{constants.wbso_security}}
					</option>
					<option value="studio-protection-7-tab">
						<span class="list-number">{{number.three_point_seven}}</span>  {{constants.wbso_security_production_services}}
					</option>
					<option value="studio-protection-8-tab">
						<span class="list-number">{{number.three_point_eight}}</span>  {{constants.wbso_security_vendor_delivery_policies}}
					</option>
					<option value="studio-protection-9-tab">
						<span class="list-number">{{number.three_point_nine}}</span> {{constants.warner_bros_entertainment_inc_firearms_weapons_policy}}
					</option>
					<option value="studio-protection-10-tab">
						<span class="list-number">{{number.three_point_ten}}</span> {{constants.spg_warner_bros_first_aid_dept_information_and_regulations}}
					</option>
					<option value="studio-protection-11-tab">
						<span class="list-number">{{number.three_point_eleven}}</span>  {{constants.wbso_first_aid_health_and_wellness_services}}
					</option>
					<option value="studio-protection-12-tab">
						<span class="list-number">{{number.three_point_twelve}}</span> {{constants.spg_warner_bros_fire_dept_information_and_regulations}}
					</option>
					<option value="studio-protection-13-tab">
						<span class="list-number">{{number.three_point_thirteen}}</span> {{constants.general_requirements}}
					</option>
					<option value="studio-protection-14-tab">
						<span class="list-number">{{number.three_point_fourteen}}</span> {{constants.evacuation_check_list}}
					</option>
					<option value="safety-environment-tab">
						<span class="list-number">{{number.four}}{{constants.dot}}</span> {{constants.safety_and_environmental_affairs}}
					</option>
					<option value="safety-environment-0-tab">
						<span class="list-number">{{number.four_point_zero}}</span> {{constants.overview}}
					</option>
					<option value="safety-environment-1-tab">
						<span class="list-number">{{number.four_point_one}}</span> {{constants.primary_contacts}}
					</option>
					<option value="safety-environment-2-tab">
						<span class="list-number">{{number.four_point_two}}</span> {{constants.iipp}}
					</option>
					<option value="safety-environment-3-tab">
						<span class="list-number">{{number.four_point_three}}</span> {{constants.important_points_to_help_keep_your_employees_safe}}
					</option>
					<option value="safety-environment-4-tab">
						<span class="list-number">{{number.four_point_four}}</span> {{constants.staff_and_crew_training}}
					</option>
					<option value="safety-environment-5-tab">
						<span class="list-number">{{number.four_point_five}}</span> {{constants.production_start_up_guidelines_for_warner_bros_productions}}
					</option>
					<option value="safety-environment-6-tab">
						<span class="list-number">{{number.four_point_six}}</span>  {{constants.productions_not_affiliated_with_warner_bros}}
					</option>
					<option value="safety-environment-7-tab">
						<span class="list-number">{{number.four_point_seven}}</span> I{{constants.inspection_procedures_for_osha_and_other_regulatory_agencies}}
					</option>
					<option value="safety-environment-8-tab">
						<span class="list-number">{{number.four_point_eight}}</span> {{constants.craft_service}}
					</option>
					<option value="safety-environment-9-tab">
						<span class="list-number">{{number.four_point_nine}}</span> {{constants.air_quality}}
					</option>
					<option value="safety-environment-10-tab">
						<span class="list-number">{{number.four_point_ten}}</span> {{constants.genral_warning_and_notifications_set_construction_safety}}
					</option>
					<option value="safety-environment-11-tab">
						<span class="list-number">{{number.four_point_eleven}}</span>  {{constants.managing_chemical_containing_products}}
					</option>
					<option value="safety-environment-12-tab">
						<span class="list-number">{{number.four_point_twelve}}</span>  {{constants.wbso_sewer_and_storm_drain_policies}}
					</option>
					<option value="safety-environment-13-tab">
						<span class="list-number">{{number.four_point_thirteen}}</span> {{constants.additional_storm_drain_policy_guidelines_by_department}}
					</option>
					<option value="safety-environment-14-tab">
						<span class="list-number">{{number.four_point_fourteen}}</span> {{constants.guidelines_for_shipping_dangerous_goods}}
					</option>
					<option value="safety-environment-15-tab">
						<span class="list-number">{{number.four_point_fifteen}}</span>  {{constants.unmanned_aerial_vehicles_unmanned_aerial_camera_platforms_drones}}
					</option>
				</select>
			</div>
			<div class="col-md-8 col-lg-8 col-sm-12">
				<!-- Tab Content -->
				<div class="tab-content" id="v-pills-tabContent">
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-tab'}"
						id="office-production-tab" role="tabpanel" aria-labelledby="office-production-tab">

					</div>
					<ng-container *ngFor="let listContent of handbookTab" >
						<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === listContent}"
						id="listContent" role="tabpanel" aria-labelledby="listContent">
						<div class="page-details-section" [innerHtml]="handbookContent">

						</div>

						</div>
					</ng-container>
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'purchase-billing-tab'}"
					id="purchase-billing-tab" role="tabpanel" aria-labelledby="purchase-billing-tab">

				</div>
				<ng-container *ngFor="let listContent of handbookTab1" >
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === listContent}"
					id="listContent" role="tabpanel" aria-labelledby="listContent">
					<div class="page-details-section" [innerHtml]="handbookContent1">

					</div>

					</div>
				</ng-container>
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-tab'}"
						id="studio-protection-tab" role="tabpanel" aria-labelledby="studio-protection-tab">

					</div>
					<ng-container *ngFor="let listContent of handbookTab2" >
						<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === listContent}"
						id="listContent" role="tabpanel" aria-labelledby="listContent">
						<div class="page-details-section" [innerHtml]="handbookContent2">
	
						</div>
	
						</div>
					</ng-container>        

					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-tab'}"
						id="safety-environment-tab" role="tabpanel" aria-labelledby="safety-environment-tab">

					</div>
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-0-tab'}"
					id="safety-environment-0-tab" role="tabpanel" aria-labelledby="safety-environment-0-tab">
					<div class="page-details-section">
						<h2 class="page-sub-heading">{{number.four_point_zero}} {{constants.hyphen}} {{constants.overview}}</h2>
						<br>
						<p class="para-text-light">Warner Bros. Safety & Environmental Affairs provides support in three major areas: Occupational Health and Safety, Environmental Affairs, and Feature/TV Production Safety.</p>
						<p class="para-text-light">S&EA works with in-house employees and production to create production safety guidelines and monitors all Warner Bros. productions worldwide, including: WBSO, WB Pictures, WBTV, WB Animation, Warner Unscripted Television, Bonanza Productions, Telepictures, SHED Media, and New Line Cinema, LLC.</p>
						<p class="para-text-light">S&EA also acts as the interface between the studio and government agencies representing the enforcement of local, state, and federal codes regulating employee occupational and environmental health and safety. (<strong>e.g. CAL/OSHA</strong>) and the environmental health of Warner Bros. Studio (<strong>EPA, SCAQMD, CITY OF BURBANK & LAC DEPT. OF HEALTH</strong>). Warner Bros. expects all its employees to perform their work in a safe manner and report any safety concerns or unsafe practices they observe by calling the <strong>Anonymous Safety Hotline at <span class="contact-number-blue">818-954-2800</span></strong>. If you have any questions or concerns regarding environmental regulations, chemical containing products, ergonomic safety, or food safety in your work area, please contact the Safety office at <strong><span class="contact-number-blue">818-954-2890</span></strong>.</p>
						<p class="para-text-light">For the purposes of this WBSO Operational Handbook, we have provided the following brief overview of some of the guidelines for the safe use of the WB facility. This overview contains general safety guidelines for employees and supervisors, safety training information for employees, and examples of the types of events that would trigger WB S&EA involvement. We also have provided some technical information regarding our Soundstage Floors and Trusses; Set Construction Safety; how to manage chemical-containing products; and environmental regulations covering air and water quality.</p>
						<p class="para-text-light"><strong>“SAFETY ON THE SET” WEBSITE</strong></p>
						<p class="para-text-light">Additional safety information can be found on our S&EA website: <a href="http://www.safetyontheset.com" target="_blank">www.safetyontheset.com</a> and is available to all productions. There you will find a printable version of the Production Safety Manual for Warner Bros. and its affiliates, Codes of Safe Practices covering employee safety for both construction and production personnel, Tool Box Talks, updated Safety Bulletins from the Alliance of Motion Picture and Television Producers (AMPTP), and the Safety Forms you will need to complete regularly during the construction, production and strike phases of your show.</p>
						<p class="para-text-light">The website is regularly updated to include new Occupational Safety & Health Administration (OSHA) regulations and information that will be helpful to your production in achieving compliance. Every employee has a role and responsibility in safety.</p>
						<p class="para-text-light">All employees, regardless of position, are required by law to notify management of any existing potential safety hazards of which they are aware. Use the <strong>Anonymous Safety Hotline <span class="contact-number-blue	">(818) 954-2800</span></strong>.</p>
						<p class="para-text-light"><strong>HAVING A WELL-DEFINED AND DOCUMENTED SAFETY PROGRAM WILL HELP REDUCE ACCIDENTS AND INJURIES AND PROVIDE YOUR PRODUCTION WITH A SAFER WORK ENVIRONMENT.</strong></p>
					</div>
					
					
				</div>
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-1-tab'}"
					id="safety-environment-1-tab" role="tabpanel" aria-labelledby="safety-environment-1-tab">
					<div class="page-details-section">
						<h2 class="page-sub-heading">{{number.four_point_one}} {{constants.hyphen}} {{constants.primary_contacts}}</h2>
						<p class="para-text-light studios-para">ON-LOT EMERGENCY LINE FIRE/MEDICAL/SAFETY<br>
							<strong class="contact-number-blue">818-954-3333</strong>
						</p>
						<p class="para-text-light">Please call the WB on-lot emergency line for all fire, medical, safety, and security emergencies. Calling <span class="contact-number-blue">(818) 954-3333</span> (or extension 4-3333 from any WB phone) will direct the call through WB Security Dispatch, which in turn has a direct line to the City of Burbank emergency dispatch system. This will allow gate officers to direct any incoming ambulances, fire engines, police, or paramedics to the proper location. This will also trigger an immediate response from WB Fire, Security, and First Aid—as appropriate. Using the 911 emergency system will only delay the arrival of emergency responders from the Studio and City of Burbank.</p>
						<p class="para-text-light ">ON-LOT SAFETY & ENVIRONMENTAL AFFAIRS</p>
						<p class="para-text-light ">Main Office<br>
							<span >Office: <strong class="contact-number-blue">818-954-2890</strong></span><br>
						<span>Fax:<strong class="contact-number-blue bullet-list-pb">818-954-2805</strong></span>
						</p>
						<p class="para-text-light">Anonymous Safety Hotline<br>
							<strong class="contact-number-blue">818-954-2800</strong><br>
							<strong class="contact-number-blue">877-566-8001 (Toll-Free)</strong>
						</p>
						<p class="para-text-light">S&EA On-Lot Team</p>
						<ul class="bullet-list">
							<li>
								<span class="">John Clements, Vice President Safety & Environmental Affairs</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 954-4248</strong></div>
								<div class="bullet-list-pb">Cell: <strong class="contact-number-blue">(310) 628-7364</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:john.clements@warnerbros.com">john.clements@warnerbros.com</a></div>
							</li>
							<li>
								<span class="">Corina Zurcher, Executive Director, Safety & Environmental Affairs</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 954-2890</strong></div>
								<div class="bullet-list-pb">Cell: <strong class="contact-number-blue">(818) 628-7364</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:corina.zurcher@warnerbros.com">corina.zurcher@warnerbros.com</a></div>
							</li>
							<li>
								<span class="">Richard J. Levin, Director Feature Production Safety</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 977-3438</strong></div>
								<div class="bullet-list-pb">Cell: <strong class="contact-number-blue">(310) 430-3692</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:richard.j.levin@warnerbros.com">richard.j.levin@warnerbros.com</a></div>
							</li>
							<li>
								<span class="">Brent Gale, Manager, Environmental Affairs</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 954-3880</strong></div>
								<div class="bullet-list-pb">Cell: <strong class="contact-number-blue">(818) 974-6569</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:brent.gale@warnerbros.com">brent.gale@warnerbros.com</a></div>
							</li>
							<li>
								<span class="">Eddie Tang, Director, Production Safety</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 954-1533</strong></div>
								<div class="bullet-list-pb">Cell: <strong class="contact-number-blue">(818) 281-4328</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:eddie.tang@warnerbros.com">eddie.tang@warnerbros.com</a></div>
							</li>
							<li>
								<span class="">Vincent Chin, Manager, Production Safety</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 954-5568</strong></div>
								<div class="bullet-list-pb">Cell: <strong class="contact-number-blue">(818) 281-9248</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:vincent.chin@warnerbros.com">vincent.chin@warnerbros.com</a></div>
							</li>
							<li>
								<span class="">Gloria Gama, Manager, Ergonomics & Safety Resources</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 954-2961</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:gloria.gama@warnerbros.com">gloria.gama@warnerbros.com</a></div>
							</li>
							<li>
								<span class="">Robert Contreraz, Hazardous Waste Facility</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 954-1271</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:robert.contreraz@warnerbros.com">robert.contreraz@warnerbros.com</a></div>
							</li>
						</ul>
						<p class="para-text-light">Hours: 6:30AM-6PM, Monday through Friday (or by arrangement)<br>
							Location: Safety & Environmental Affairs (S&EA) is located on the North side of Building 44 (The Mill), 5th Street and Avenue C on the Second Floor, above the Canvas Room
						</p>
					</div>
					
					
					
				</div>
				<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-2-tab'}"
				id="safety-environment-2-tab" role="tabpanel" aria-labelledby="safety-environment-2-tab">
				<div class="page-details-section">
					<h2 class="page-sub-heading">{{number.four_point_two}} {{constants.hyphen}} {{constants.iipp}}</h2>
					<p class="para-text-light studios-para">INTRODUCTION Warner Bros. Studios Operations believes that everyone benefits from a safe and healthful work environment. We are committed to maintaining an injury-free and illness-free workplace in compliance with applicable laws and regulations governing workplace safety. To achieve this goal, the Company has adopted an Injury and Illness Prevention Program (IIPP). This program applies only to employees of Warner Bros. Studios. Any direct production hires should consult the Production Safety Manuals at <a href="http://www.safetyontheset.com" target="_blank">www.safetyontheset.com</a>. This program is everyone’s responsibility as we work together to identify and eliminate conditions and practices to ensure a safe and healthful work environment.</p>
					<p class="para-text-light">Download Warner Bros. Discovery (IIPP)</p>
				</div>
				
			</div>
			<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-3-tab'}"
			id="safety-environment-3-tab" role="tabpanel" aria-labelledby="safety-environment-3-tab">
			<div class="page-details-section">
				<h2 class="page-sub-heading">{{number.four_point_three}} {{constants.hyphen}} {{constants.ten_important_points_to_help_keep_your_employees_safe}}</h2>
				<br>
				<ul class="bullet-list">
					<li><span class="">HIRE CSATF SAFETY PASS TRAINED EMPLOYEES.</span> Only those who have completed Safety Pass Training are eligible for employment at Warner Bros.</li>
					<li><span class="">FALL PROTECTION IS MANDATORY WHEN WORKING AT HEIGHTS</span> Fall protection is required when working outside of the catwalks in the permanents of a soundstage, on unprotected roofs and elevated platforms, all aerial lifts, and in other locations and circumstances.</li>
					<li><span class="">ALWAYS PROPERLY DISPOSE OF PAINT OR CHEMICAL-CONTAINING PRODUCTS</span> It is much more cost effective to recycle your waste than to dispose of it. If waste materials cannot be recycled, then proper disposal procedures must be followed. Improper disposal of waste can result in substantial regulatory fines to your production. Avoid extra costs by recycling or handling paint waste carefully. For proper disposal instructions contact, Brent Gale (818-954-3880).</li>
					<li><span class="">DO NOT OVERLOAD THE STAGE FLOORS OR TRUSSES</span> Contact Office & Production Services (818-954-6777) or S&EA (818-954-2890) for help in determining truss and soundstage loading allowances.</li>
					<li><span class="">DO NOT PUT ANYTHING DOWN THE STORM DRAINS</span> The storm drains at Warner Bros. Studio lead directly to the L.A. River, which in turn drains directly into the Pacific Ocean. Anything that goes into the storm drains is ultimately discharged untreated to the ocean. “Anything” includes soapy water, paint, oil, grease, ice drippings, dirt, sawdust, paint chips, tap water, soda, etc. The only substance that may legally enter the storm drains is rainwater. Fines totaling hundreds of thousands of dollars are levied against companies that discharge into the storm drain system illegally, and these fines will be passed on to production companies if resulting from their production’s activities.</li>
					<li><span class="">DO NOT SHIP DANGEROUS GOODS VIA AIR</span> Dangerous goods are any chemical-containing products that are shipped by mail. One studio was recently fined $47,000 by the FAA for shipping two undeclared and improperly packaged cans of Streaks N’ Tips via air.</li>
					<li><span class="">DO NOT USE AN AERIAL / SCISSOR LIFT OR CONDOR WITHOUT A SAFETY HARNESS</span> The California Occupational Safety & Health Administration (Cal/OSHA) and WBSO both require harnesses when using any type of lift.</li>
					<li><span class="">ALL WATER DISCHARGE MUST MEET BURBANK SEWER DISCHARGE REQUIREMENTS</span> Water used in stage tanks, the Lagoon, and on exterior sets (e.g. rain bars, wet downs, etc.) must be discharged to the sanitary sewer. Production needs to meet with Office & Production Services, Plumbing and S&EA to discuss water disposal procedures. There are restrictions on the use of dyes, oils and other chemicals added to the water. There may also be discharge timing and flow rate limitations.</li>
					<li><span class="">ROUGH TERRAIN FORKLIFTS (GRADALLS) REQUIRE PRIOR APPROVAL</span> Rough terrain forklifts (e.g., Gradalls) are a very specialized piece of lifting equipment and should only be operated by personnel that has been properly trained on the equipment and has been cleared to use it.</li>
					<li><span class="">IF YOU HAVE ANY SAFETY CONCERNS OR QUESTIONS, CALL S&EA AT (818-954-2890) or the ANONYMOUS SAFETY HOTLINE (818-954-2800).</span> REMINDER: California Law states Smoking is prohibited in all buildings, soundstages and within 20 feet of building entrances. This applies to all smoking products, including e-cigarettes. Violation of this policy will result in discipline, up to and including termination.</li>
				</ul>
			</div>
			
		</div>
		<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-4-tab'}"
		id="safety-environment-4-tab" role="tabpanel" aria-labelledby="safety-environment-4-tab">
		<div class="page-details-section">
			<h2 class="page-sub-heading">{{number.four_point_four}} {{constants.hyphen}} {{constants.staff_and_crew_training}}</h2>
			<p class="para-text-light studios-para">
				In order to be eligible for employment with any Warner Bros. related or affiliated entity, employees working in IATSE and Basic Crafts represented classifications must be current in the completion of their respective Safety Pass Courses. An employee’s Safety Pass status may be verified by reviewing his/her/their Contract Services Safety Pass Card. For information on Safety Pass, please refer to <a href="http://www.CSATF.org">www.CSATF.org</a> or contact Safety Pass at 818-847-0040. It is located at: 2710 Winona Ave., Burbank, CA 91504.
			</p>
			<p class="para-text-light">
				DGA members are required to attend and pass a two-hour DGA Safety Training. Upon completion of the class, members are eligible to monitor any of the twenty-six Safety Pass classes that they would like to take to better understand IATSE safety requirements. Please contact CSATF to schedule.
			</p>
			<p class="para-text-light">
				Supervisory employees who direct the work of other employees are to actively participate in scheduling, conducting safety meetings and training classes and to ensure the attendance of all employees. Supervisors are to also provide site-specific safety information to all employees on their first day on the job, including the following:
			</p>
			<ul class="bullet-list">
				<li><span class="">Emergency Procedures:</span> emergency phone numbers, emergency exit locations, evacuation training and emergency relocation area, any special shut-down procedures, etc. Please spread the on-lot Warner Bros. emergency number (818-954-3333) to all staff and crew.</li>
				<li><span class="">The location and operation of safety equipment:</span> fire extinguishers, eye wash station & first aid department, etc.</li>
				<li><span class="">Ensure that each employee has received:</span> the appropriate General Safety Guidelines for Production (Form 1) and any specific Codes of Safe Practices for the use of tools, equipment, and materials that they encounter while performing their work. Special attention should be given to the handling and storage of potentially hazardous material.</li>
				<li><span class="">Be aware that employees new to the industry:</span> (i.e. Permits) should not be assigned work that requires special training, such as the use of respirators or areas requiring fall protection equipment, operating forklifts/condors/scissors lifts, using high-reach forklifts or other heavy equipment, working in potential confined spaces, etc. During “Permit” status, assign an experienced employee to monitor their work.</li>
				<li><span class="">Consult the Alliance of Motion Picture and Television Producers (AMPTP) Safety Bulletins:</span> if potentially hazardous activities are scheduled, such as pyrotechnic events, stunts, use of firearms, etc. A walk-through of the event should be conducted with all involved cast and crew members. A copy of the applicable AMPTP Safety Bulletin is available at <a href="http://www.safetyontheset.com">www.safetyontheset.com</a> and should be attached to that day’s call sheet, made available or conspicuously posted.</li>
				<li><span class="">See to it that employees are provided with Personal Protective Equipment (PPE):</span> and understand the selection, use, care, limitations and location of any PPE that is needed for their job assignment.</li>
			</ul>
			<p class="para-text-light">
				Provide additional worksite-specific safety training when:
			</p>
			<ul class="bullet-list">
				<li>A new employee is hired.</li>
				<li>Any employee is given a new job assignment for which they have not been trained.</li>
				<li>New substances, processes, procedures, or equipment are introduced into the workplace.</li>
				<li>The employer/manager is made aware of a new or previously unrecognized hazard.</li>
				<li>Utilizing unfamiliar locations at WBSO.</li>
			</ul>
			<p class="para-text-light">
				Any questions or guidance to the above can be addressed by calling S&EA at 818-954-2890. Warner Bros. employees can also receive site-specific safety training, including fall protection or guidance on machinery, respirator fit testing or chemical products.
			</p>
		</div>
		
	</div>
	<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-5-tab'}"
	id="safety-environment-5-tab" role="tabpanel" aria-labelledby="safety-environment-5-tab">
	<div class="page-details-section">
		<h2 class="page-sub-heading">{{number.four_point_five}} {{constants.hyphen}} {{constants.production_start_up_guidelines_for_warner_bros_productions}}</h2>
		<p class="para-text-light studios-para">To help you take the first steps toward keeping your production in compliance with safety regulations, we have provided the following “Warner Bros. Production Start-Up Guidelines.”</p>
		<ol class="bullet-list">
			<li><span class="">Have your UPM or Production Office Coordinator call your Production Safety Representative to set up your Pre-Production Safety Orientation Meeting.</span> All Warner Bros.-affiliated productions are to meet with a Production Safety Representative prior to the start of production. All key department heads should attend the meeting, including but not limited to: Unit Production Manager (UPM), First Assistant Director, Second Assistant Director, Construction Coordinator, Location Manager, Key Grip, Gaffer, Best Boys, and Transportation Coordinator.</li>
			<li><span class="">As required by Warner Bros. policy, make sure that the General Safety Guidelines for Production (Form 1) is attached to the production’s deal memo prior to hiring, and that each employee’s signed Safety Guidelines Acknowledgement Form is on file before they start work.</span> These documents are available at www.safetyontheset.com. Keep the original signature sheets on file and send copies to your Production Office Coordinator.</li>
			<li><span class="">Ensure your production hires only CSATF Safety Pass trained crew members</span> by checking the CSATF Experience Roster at csatf.org.</li>
			<li><span class="">Have your Production Coordinator fill out Form 2 (IIPP Contact List) and Form 4 (Serious Accident or Set Emergency Notification Procedures).</span> Keep a readily accessible copy for production. The State of California (Cal/OSHA) requires every employer to have its own Injury & Illness Prevention Program (IIPP). For Warner Bros. productions, Safety & Environmental Affairs has already written your productions IIPP. It is available for download on S&EA’s website: www.safetyontheset.com. This site provides access to Form 2 and Form 4.</li>
			<li><span class="">Review the Position Safety Responsibilities section</span> on the safetyontheset.com website. Have applicable members of the production also review this document, which outlines the safety responsibilities of every position on production. This is required by Cal/OSHA, which mandates that the IIPP assign safety duties appropriate to employees’ positions. Cal/OSHA requires every IIPP to assign safety duties. Also available at www.safetyontheset.com are the following:
				<ul>
					<li>Safety Forms</li>
					<li>Tool Box Talks</li>
					<li>Codes of Safe Practices</li>
					<li>Links to AMPTP Safety Bulletins</li>
					<li>Links to check with CSATF that employees are fully trained</li>
					<li>Links to other helpful safety information and safety resource website</li>
				</ul>
			</li>
			<li><span class="">Productions working on the WB Main Lot or WB Ranch</span> should review the Safety Manual Supplement for Productions found on the safetyontheset.com website. This portion of the website contains information on environmental regulations impacting productions—and other information.</li>
		</ol>
		<p class="para-text-light">CALL WB SAFETY <span class="contact-number-blue">(818-954-2890) </span>WHEN:</p>
		<ol class="bullet-list">
			<li>Planning your first Production Meeting of the season so that your Production Safety Representative can arrange a Production Safety Orientation. You may also contact your Production Safety Representative directly.</li>
			<li>Planning a major stunt, car chase, or special effect.</li>
			<li>Flying rigs are used.</li>
			<li>Helicopters, aircraft, watercraft, motorcycles, all-terrain vehicles (ATV’s) are used.</li>
			<li>Prior to any use of Unmanned Aerial Vehicles or Unmanned Aerial Camera Platforms (i.e. drones).</li>
			<li>Planning to film in, on, or around water.</li>
			<li>Intending to drain the Lagoon, Stage 15 or Stage 16 water tanks, or Ranch pool, or any other filming pool.</li>
			<li>Production may impact the public at large, public waterways, streams, lakes, reservoirs, or public lands.</li>
			<li>Using a warehouse as a soundstage.</li>
			<li>Contemplating demolition of any major set or structure.</li>
			<li>Drums of hazardous materials not associated with your production are present upon arrival.</li>
			<li>Production employees may be exposed to safety hazards or hazardous materials that are present as a result of the proximity to another employee, production company, or vendor.</li>
			<li>Scaffolds three stories (36 feet) or higher are being constructed.</li>
			<li>Cranes and equipment exceeding 10,000 lb. are used on a soundstage.</li>
			<li>You have questions about the load-bearing capacity of a stage floor, truss, or backlot platform (roof, fire escape, etc.)</li>
			<li>You suspect the presence of asbestos, lead paint, or another environmental hazard on location.</li>
			<li>You need safety equipment, such as fall protection.</li>
			<li>An employee lacks safety training to operate equipment or perform a task.</li>
			<li>You need assistance with ergonomics issues relating to your workspace.</li>
			<li>You have food safety concerns.</li>
			<li>You have questions about the “Safety Pass Program.”</li>
			<li>A Cal-OSHA or other governmental agency inspector visits your stage or location. The inspector should be asked to wait, if possible, until a representative of S&EA is on site. (See Inspection Procedures for OSHA and Other Regulatory Agencies on the following page.)</li>
		</ol>
		<p class="para-text-light">Please fax or email script pages and complete appropriate Production Safety Forms to S&EA for review.</p>
	</div>
	
</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-6-tab'}"
id="safety-environment-6-tab" role="tabpanel" aria-labelledby="safety-environment-6-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">{{number.four_point_six}} {{constants.hyphen}} {{constants.productions_not_affiliated_with_warner_bros}}</h2>
    <p class="para-text-light studios-para">CALL WB SAFETY <span class="contact-number-blue"> (818-954-2890)</span> WHEN:</p>
    <ul class="bullet-list">
        <li>Opening your production office or scheduling your first tech scout. We would like to discuss your production’s safety program and obtain information regarding safety while at WBSO.</li>
        <li>Planning a major stunt, car chase, or special effect.</li>
        <li>Helicopters, aircraft, watercraft, motorcycles, all-terrain vehicles (ATV’s) are used.</li>
        <li>Prior to any use of Unmanned Aerial Vehicles or Unmanned Aerial Camera Platforms (i.e. drones).</li>
        <li>Planning to film in, on, or around water.</li>
        <li>Intending to drain the Lagoon, Stage 15 or Stage 16 water tanks, or Ranch pool, or any other filming pool.</li>
        <li>Contemplating demolition of any major set or structure.</li>
        <li>Drums of hazardous materials not associated with your production are present upon arrival.</li>
        <li>Production employees may be exposed to safety hazards or hazardous materials that are present as a result of the proximity to another employee, production company, or vendor.</li>
        <li>Scaffolds three stories (36 feet) or higher are being constructed.</li>
        <li>Cranes and equipment exceeding 10,000 lb. are used on a soundstage.</li>
        <li>You have questions about the load-bearing capacity of a stage floor, truss, or backlot platform (roof, fire escape, etc.)</li>
        <li>A Cal-OSHA or other governmental agency inspector visits your stage or location. The inspector should be asked to wait, if possible, until a representative of S&EA is on site. (See Inspection Procedures for OSHA and Other Regulatory Agencies on the following page.)</li>
    </ul>
    <div class="last-para-section">
        <p class="para-text-light">Please follow these guidelines to ensure the safety and compliance of your production while on Warner Bros. property. For any questions or clarifications, do not hesitate to contact WB Safety at the provided number.</p>
    </div>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-7-tab'}"
id="safety-environment-7-tab" role="tabpanel" aria-labelledby="safety-environment-7-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">{{number.four_point_seven}} {{constants.hyphen}} {{constants.inspection_procedures_for_osha_and_other_regulatory_agencies}}</h2>
    <p class="para-text-light studios-para">Several types of events or scenarios may cause an inspector from the California Occupational Safety Health Administration (Cal/OSHA) or other California State regulatory agency to visit the set. Some examples: a serious accident has occurred; an employee complaint has been registered with a regulatory agency, the inspector was driving by the production and noticed areas of non-compliance.</p>
    <p class="para-text-light">If an inspector arrives, follow the procedures below:</p>
    <ol class="bullet-list">
        <li>Notify the Department of Safety & Environmental Affairs (S&EA) immediately at 818-954-2890, as well as your physical production executive. Inform the inspector that you cannot proceed with the inspection until those notifications have been made.</li>
        <li>If requested by S&EA or the Production Attorney, ask the OSHA inspector to wait for S&EA’s arrival. If authorized by S&EA, accompany the inspector directly to the site in question. If possible, do not let the inspector wander into other areas.</li>
        <li>The inspector’s credentials should be requested and examined, and the nature of the visit should be determined. Be courteous, but cautious.</li>
        <li>Do not sign anything or provide any written documentation. Ask that their request for any written material be put in writing so that we may respond in writing, as this is company policy.</li>
        <li>Under OSHA regulations, an inspector has the right to privately interview crew members. However, the crew members should be informed by the Production or S&EA that they have the right to have either a union representative or attorney present during the interview.</li>
        <li>The inspector has the right to take photographs. However, it is important that the production company takes duplicate photographs of those taken by the inspector.</li>
        <li>DO NOT volunteer information or speculation. Provide short, direct, concise answers to questions posed by the inspector. If you truly don’t know the answers, simply tell the inspector that you “do not know”.</li>
        <li>Take detailed notes during the inspection. Immediately after the inspection, forward a copy of the notes to the VP S&EA as well as your production’s management.</li>
        <li>Depending upon the severity of the incident, the results of the inspection may determine whether there is a basis for the State to pursue criminal action, and an inspector may not inform you of this. However, you have the right to remain silent; have your business agent in attendance and/or the right to have legal counsel present when making statements to inspectors.</li>
    </ol>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-8-tab'}"
id="safety-environment-8-tab" role="tabpanel" aria-labelledby="safety-environment-8-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">{{number.four_point_eight}} {{constants.hyphen}} {{constants.craft_service}}</h2>
    <p class="para-text-light studios-para">
        All productions working at Warner Bros. Studios are expected to comply with LA County Department of Public Health guidelines regarding service of food and refreshments. Please see the following bulletins from LAC DPH, which addresses food that can be served by craft service without a permit, protocols for receipt of food delivery, and information about mobile food facilities and catering operations.
    </p>
    <div class="pdf-links">
        <p class="para-text-light">
            <i class="bi bi-file-earmark-pdf-fill"></i>
            <a href="#" class="text-primary" target="_blank">Approved Film Production Food Services</a>
        </p>
        <p class="para-text-light">
            <i class="bi bi-file-earmark-pdf-fill"></i>
            <a href="#" class="text-primary" target="_blank">Permit and Operational Requirements for Motion Picture Catering Operations</a>
        </p>
        <p class="para-text-light">
            <i class="bi bi-file-earmark-pdf-fill"></i>
            <a href="#" class="text-primary" target="_blank">General Requirements Checklist For Mobile Storage Vehicles</a>
        </p>
        <p class="para-text-light">
            <i class="bi bi-file-earmark-pdf-fill"></i>
            <a href="#" class="text-primary" target="_blank">Mobile Food Facility Vehicles</a>
        </p>
    </div>
    <div class="last-para-section">
        <p class="para-text-light">
            Food served by an employee of a Certified Food Facility who possesses a Food Manager Card or Handling Card for Catering is permissible; however, the kitchens in the WBSO sound stages are not permitted and cannot be used for any food preparation.
        </p>
        <p class="para-text-light">
            If you intend to use a Mobile Food Preparation Truck (Hot Truck); a Mobile Food Facility (Cold Truck); Trailer, Cart, or any of the other mobile food facility vehicles, the LA County Department of Public Health requires that the person cooking in the mobile food facility must have a current “Manager’s Food Handling Certificate”, and the vehicle(s) must be permitted by the LAC DPH Vehicle Inspection Program (V.I.P.). Permit requirements to operate a food truck or any food service are complex, and the process to obtain the agency’s permit can be slow and laborious.
        </p>
        <p class="para-text-light">
            The following documents, stickers, etc. are necessary to know that the truck/trailer is in compliance:
            <ol>
                <li>County of Los Angeles “Public Health Operating Permit” (Annual)</li>
                <li>Initial Inspection Sticker (Issued when truck passed VIP inspection.)</li>
            </ol>
        <!-- </p> -->
        <p class="para-text-light">
            If you have any questions, please call Gloria Gama-Tafoya (818-954-2890), Manager Ergonomics and Safety Resources. You may also reach LAC DHS Vehicle Inspection Program (V.I.P.) at 626-430-5500, or the Food and Milk Program at (626) 430-5400.
        </p>
    </div>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-9-tab'}"
id="safety-environment-9-tab" role="tabpanel" aria-labelledby="safety-environment-9-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">{{number.four_point_nine}} {{constants.hyphen}} {{constants.air_quality}}</h2>
    <p class="para-text-light studios-para">In Southern California, the oversight of air quality is the responsibility of the South Coast Air Quality Management District (SCAQMD). The SCAQMD has enacted rules that regulate the use and operation of many materials, processes and equipment that may be used by your production company. The following guidelines and regulations have been developed to help you comply with these regulatory requirements.</p>
    <ul class="bullet-list">
        <li><span class="">Visible Emissions –</span> If your production company will be using fires, explosions or large-scale effects involving airborne smoke or dust you will need to notify Office & Production Services and S&EA prior to the effect. Regulations enforced by the South Coast Air Quality Management District (SCAQMD) forbid the airborne release of smoke, dust or other visible emissions that last more than three minutes in duration within any one-hour period. S&EA can assist you in complying with this regulation.</li>
        <li><span class="">Portable Generators –</span> While WBSO has many power supplies available across our facilities and generally wishes to avoid the use of portable generators, portable generators may be approved for usage in conjunction with certain production equipment, or in locations where a power supply is unavailable. Internal combustion engine-driven portable generators are regulated by the SCAQMD because of the exhaust gases that are generated and released into the atmosphere. To comply with the applicable SCAQMD regulations, daily and hourly use records must be kept by the portable generator operator. The SCAQMD regulations governing the use of generators are extensive, but most of them will not impact your production company if you rent the generator from an equipment rental company. When deciding on the placement of generators, consideration must be given to the effect of exhaust gases on employees working downwind or in adjacent occupied buildings. This will help to avoid re-location of the generators during production, which may be required due to errant diesel fumes or other health concerns. Please address this with Office & Production Services and S&EA on your location tech scouts.</li>
        <li><span class="">Coatings, Paint, and Other Paint-Related Materials –</span> Nearly all production companies use paints, stains, primers, etc., and all of these materials are regulated by the SCAQMD. Your paint foremen should be aware of the limitations imposed by these regulations because they govern the chemical content and use of all coatings and paints. All coatings sold through the Warner Bros. Studio Paint Department are SCAQMD compliant for their intended use. The equipment used to apply coatings is also regulated by the SCAQMD. Certain limitations apply to spray guns, which may need an SCAQMD Permit to operate. Other limitations apply to the construction and operation of spray booths. A permit must be obtained prior to the construction of any spray booth, even if it’s temporary. Finally, remind all workers working with coatings or other chemical-based products to keep the lids tightly on cans when not in use, and to store all rags in closed containers. If you need additional information or guidance on what products can be legally or used selected, please contact Brent Gale at (818) 954-3880. Purchasing non-compliant paint and paint-related materials off-lot for use on the lot is a violation of SCAQMD Regulations and Warner Bros. policy. Violators and their Supervisors will be brought to the attention of Labor Relations.</li>
    </ul>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-10-tab'}"
id="safety-environment-10-tab" role="tabpanel" aria-labelledby="safety-environment-10-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">{{number.four_point_ten}} {{constants.hyphen}} {{constants.genral_warning_and_notifications_set_construction_safety}}</h2>
    <p class="para-text-light studios-para">
        <strong>General Warnings and Notifications</strong>
        <div class="spaced"></div>
        <strong>PROP 65 WARNING</strong>
        <div class="spaced"></div>
        <p class="para-text-light">California Prop 65 requires businesses to give a “clear and reasonable warning” prior to exposing any person to a detectable amount of a chemical listed by the state as covered by the Prop 65 Statute. The list contains a wide range of chemicals. Many of them are ingredients or components of common household products, such as ceramic ware, alcoholic beverages and aspirin. Others may be byproducts of certain combustion processes, such as motor vehicle exhaust, tobacco smoke and burning natural gas. Still others may be chemical-containing products such as dyes, or specialty pigments.
        Therefore, as required by the CA Prop 65 Statute, the following Prop 65 Warning is provided:
        <div class="spaced">
        “WARNING: This facility contains chemicals known to the State of California to cause cancer and birth defects or other reproductive harm.”
    <!-- </p> --></div>
    <p class="para-text-light">
        <strong>NOTIFICATION TO EMPLOYEES: ASBESTOS-CONTAINING BUILDING MATERIALS</strong>
        <div class="spaced">
        <p class="para-text-light">Both state and federal law require building owners to notify occupants of the presence of any asbestos-containing materials (ACM) in buildings in which they work. We are making this notification to comply with these laws, and because it is Warner Bros. Studios policy to make such information available.</p>
	</div>
        <strong>BACKGROUND</strong>
        <div class="spaced">
        <p class="para-text-light">Asbestos-containing materials were commonly used in the construction of buildings and homes prior to 1979. ACM were once used as part of certain building materials, such as insulation, roofing materials, floor tiles, and spray-on insulation.
        Warner Bros. Studio has a comprehensive Operation and Maintenance Plan for Asbestos Containing Materials. Under this plan, we use licensed ACM abatement firms to remove ACM from mechanical rooms and exposed pipes and ducts; and to remove any ACM present prior to or discovered while doing Studio remodeling and renovation work. In addition, we have conducted air sampling in all WB buildings known to contain ACM. The Environmental Protection Agency (EPA) and The Occupational Safety & Health Administration (OSHA) set standards for building occupancy, and we are pleased to say that all WBSO buildings meet these standards.
        The following WBSO buildings are known to contain ACM and are subject to this notification: 4, 5, 8, 10, 11, 12, 13, 15, 18, 19, 22, 27, 31, 34, 35, 37, 41, 44, 51, 52, 53, 54, 55, 56, 58, 59, 66, 67, 68, 69, 71, 76, 81, 90, 95, 131, 133, 135, 137, 138, 139, 140, 142, 143, 146, 151, 154, 155 and 3R.
        The survey reports and bulk sample and air sample test reports for particular building locations are available for inspection by appointment. Because inhalation of unprotected airborne asbestos fibers may pose serious potential health risks, it is important that you not disturb potential ACM (i.e., by drilling, sanding or hammering) without first calling S&EA at <strong class="contact-number-blue">818-954-2890</strong> to receive clearance.
        </p></div>
    <!-- </p> -->
    <p class="para-text-light">
        <strong>WB Productions – Specialized Safety Equipment / Personal Protective Equipment (PPE)</strong>
        <div class="spaced">
       <p class="para-text-light"> Safety & Environmental Affairs (S&EA) maintains a variety of specialized safety equipment that is available for use by WB employees. S&EA’s safety-related equipment inventory includes items like face shields, hearing protection, fall protection equipment, horizontal safety lines, vertical rope grabs, Nomex clothing (coveralls, gloves, and hoods), airline respirators, potential confined space ventilators, and high-velocity ducted air machines. Disposable personal protective equipment (PPE) is provided to individual employees free-of-charge to protect them in a variety of work conditions. Other more expensive and specialized equipment is provided for your departments and production companies free of charge; however, lost or abused equipment will be charged to the production.
        Fall protection is mandatory when working in situations that expose employees to potential falls greater than 4 feet in height and where guardrails are absent. Some examples: outside of the catwalks in the permanents of a soundstage; on roofs, shooting platforms or decks built for set construction.
        When larger quantities of items are required, such as for an entire crew, disposable items such as safety glasses, dust masks, earplugs, gloves, and goggles, and other PPE items are available for purchase through the WBSO Paint Department at <strong class="contact-number-blue">(818) 954-4444</strong>, or VER Sales at <strong class="contact-number-blue">(818) 567-3000</strong>. In addition, S&EA maintains a limited supply of expendable safety equipment to be used in emergency scenarios.
        Please call S&EA with any questions or concerns at <strong class="contact-number-blue">(818) 954-2890</strong>.
    </p></div>
    <p class="para-text-light">
        <strong>Third Party Productions – Personal Protective Equipment</strong>
        <div class="spaced">
      <p class="para-text-light">  Productions unaffiliated with WB or its production entities who are leasing production space from WBSO are responsible to supply their employees with Personal Protective Equipment as required by Cal/OSHA. Please call S&EA at <strong class="contact-number-blue">(818) 954-2890</strong> for specific requirements. This equipment can be purchased or rented from VER Sales in Burbank at <strong class="contact-number-blue">(818) 567-3000</strong>.
        Fall protection is mandatory when working in situations that expose employees to potential falls greater than 4 feet in height and where guardrails are absent. Some examples: outside of the catwalks in the permanents of a soundstage; on roofs, shooting platforms or decks built for set construction.
    </p>
</div>
</div>


</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-11-tab'}"
id="safety-environment-11-tab" role="tabpanel" aria-labelledby="safety-environment-11-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">{{number.four_point_eleven}} {{constants.hyphen}} {{constants.managing_chemical_containing_products}}</h2>
    <p class="para-text-light studios-para">General Purchasing Practices Purchase materials, especially chemical products, in the smallest quantity possible.</p>
    
    <h3 class="page-sub-heading">Paint and Painting Materials</h3>
    <p class="para-text-light studios-para">Purchase paint and paint-related products that comply with all local or state environmental regulatory agency requirements. The WBSO Paint Dept. sells compliant painting materials. Paint rags should be used sparingly. Air drying rags or throwing used rags, brushes, rollers, stir sticks, etc. in the trash is illegal.</p>
    
    <h3 class="page-sub-heading">Compressed Gases</h3>
    <p class="para-text-light studios-para">Have all compressed gases delivered by the vendor to your location. Never throw compressed gas cylinders in the trash. Chain all cylinders in an upright position with valve covers tightened down. Segregate incompatible gases such as flammables (propane, acetylene etc.) from oxidizers (oxygen, etc.).</p>
    
    <h3 class="page-sub-heading">Construction Subcontractors</h3>
    <p class="para-text-light studios-para">Inform all subcontractors you employ that the disposal of any waste generated by the subcontractor is the responsibility of the subcontractor. All waste must be taken by the subcontractor once the job is complete. Inspect their worksite frequently and watch for any dumping of waste materials into the waste hopper, storm drains, toilets, or sinks.</p>
    
    <h3 class="page-sub-heading">Special Effects and Environmental Considerations</h3>
    <p class="para-text-light studios-para">Any form of artificial snow cannot be washed down storm drains. SnoFoam should be allowed to dry into a solid and then swept up and disposed of as trash. Special Effects materials should be evaluated and disposed of appropriately by the FX person in charge.</p>
    
    <h3 class="page-sub-heading">Cleaning Up Spills of Hazardous Material</h3>
    <ul>
        <li class="para-text-light bullet-list-pb">All spills should be cleaned up promptly. The first priority after a spill is the safety of all personnel. Once personnel are safe, and if a qualified person is present, the environment should be protected by stopping the spill from reaching storm drains, sumps, gutters, soil, or bodies of water.</li>
        <li class="para-text-light">If a spill occurs, avoid touching it, walking in it or breathing it, and immediately take these steps:
            <ul>
                <li class="para-text-light">Use sand or spill absorption materials to build a dike well ahead of the course of the spill.</li>
                <li class="para-text-light">Once the flow of material has been stopped, absorb the spill using absorbent or sand.</li>
            </ul>
        </li>
        <li class="para-text-light">For large spills, or small spills of flammable materials, immediately call the WBSO emergency hotline: <span class="contact-number-blue">(818) 954-3333</span>.</li>
        <li class="para-text-light">Once the spill is absorbed, safely sweep or shovel up the spill-contaminated material into a labeled container. Do not throw spill debris into the trash; it must be disposed of as hazardous waste.</li>
        <li class="para-text-light">When time permits, or if you are unable to clean up the spill, please report the spill and any containment efforts to S&EA at <span class="contact-number-blue">(818) 954-2890</span> or call the Hazardous Waste Facility at <span class="contact-number-blue">(818) 954-1271</span>. The WBSO Fire Department can also help clean up a spill—especially during after-hours or on weekends. They can be reached at <span class="contact-number-blue">(818) 954-1261</span>.</li>
    </ul>
    
    <h3 class="page-sub-heading">Painting Operations / Waste Paint Consolidation and Labeling Guidelines</h3>
    <ul>
        <li class="para-text-light bullet-list-pb">These guidelines are to be followed by all employees working with paint and paint-related materials while at Warner Bros. Studio or on location for a Warner Bros. production.</li>
        <li class="para-text-light">Productions working on the WBSO Main Lot should take all waste described below to the Hazardous Waste Facility (Avenue D and Eighth Street). For waste at the Ranch or on location, contact Brent Gale at <span class="contact-number-blue">(818) 954-3880</span>.</li>
        <li class="para-text-light">Painting operations on soundstages or in the Mill (Bldg. 44) that generate irritating odors should be scheduled during off hours whenever possible. If other productions working in the Mill complain – off hour scheduling is mandatory. Ventilation (i.e., stage fans and floor fans) should be used to dissipate odors.</li>
        <li class="para-text-light">Water-based paint waste and solvent-based paint waste are to be kept separate from one another and as clean of debris as possible. Water-based paint that is free of debris and not contaminated with any solvent-based paint can be recycled. Since recyclable paint is less expensive to dispose of than contaminated paint (which must be disposed as hazardous waste), you can save your production money by recycling as much of your water-based paint as possible.</li>
        <li class="para-text-light">Store any flammable waste paints in 5 gal. buckets. (e.g., acetone, “Japans”). Label them “Flammable Paint”.</li>
        <li class="para-text-light">Paint-related materials such as brushes, rollers, and stir sticks are to be isolated in a separate bucket that does not contain paint. Label them “Brushes/Rollers”.</li>
        <li class="para-text-light">Waste latex, vinyl, and other water-based paints should also be stored in 5 gal. buckets. Label them “Water-Based Paint”.</li>
        <li class="para-text-light">Sawdust, sand or other absorbents used in spill cleanup should be isolated and sealed in a separate bucket that does not contain paint. Label them “Used Spill Cleanup of (insert name of material)”.</li>
        <li class="para-text-light">Used plastic bucket liners should be wiped as clean as possible while the paint is still wet, and then crushed and packaged in a separate 5 gal. bucket and labeled “Bucket Liners”. All aerosol cans are to be disposed of separately from other waste paint-related material, preferably in a 5-gallon bucket, sealed and labeled “Empty Spray Cans”. Do not store aerosol cans in the sunlight.</li>
        <li class="para-text-light">Empty waste paint buckets should be wiped clean while still wet and then sealed in plastic garbage bags (tied shut) and labeled “Empty Paint Cans”.</li>
        <li class="para-text-light">Used paint rags should be packed in a 5-gallon bucket and labeled “Used Paint Rags”.</li>
        <li class="para-text-light">When filling buckets with waste paint, do not fill the bucket to the rim. Leave 2-3 inches headspace to allow for expansion.</li>
        <li class="para-text-light">All wash water from washing brushes, rollers, buckets, etc. is to be saved and handled in the same manner as water-based paint waste. Buckets should be labeled “Brush Water”.</li>
    </ul>
    
    <h3 class="page-sub-heading">Waste Paint Drop-Off Procedures When at Warner Bros. Studios</h3>
    <ul>
        <li class="para-text-light bullet-list-pb">All studio departments and production companies are required to drop off their hazardous waste at the WBSO Hazardous Waste Facility Bldg. 78, located South of Bldg. 47 at the intersection of Avenue D and 8th Street. Note: Non-WB Productions purchasing paint from WBSO are eligible for hazardous waste services.</li>
        <li class="para-text-light">When the maximum allowable fifty-five-gallon storage limit has been reached (any combination of the above waste categories equaling a total of 11 five-gallon buckets), the following procedures shall be followed:
            <ul>
                <li class="para-text-light">All containers shall have listed on them; 1 The production or department name, 2 The container contents, labeled as described above, and 3) The stage number or set where the waste was generated.</li>
                <li class="para-text-light">The Hazardous Waste Facility must be called in advance to make sure the facility is open to accept the waste. To schedule a drop-off, call <span class="contact-number-blue">(818) 954-1271</span> or <span class="contact-number-blue">(818) 954-2890</span>.</li>
                <li class="para-text-light">Waste drop-offs will only be scheduled to be received between the hours of 6:15 a.m. and 2:15 p.m., unless special arrangements are made.</li>
                <li class="para-text-light">Waste shall not be allowed to accumulate into large quantities on stage or in departments. Frequent, smaller waste drop-offs should be made. Plan for chemical inventory and disposal when hiatus periods are approaching.</li>
                <li class="para-text-light">Waste shall not be left at the Hazardous Waste Facility if there is no one there to accept it, especially if after hours.</li>
            </ul>
        </li>
    </ul>
    
    <p class="para-text-light studios-para">Please call Brent Gale at <span class="contact-number-blue">(818) 954-3880</span> or Robert Contreraz at <span class="contact-number-blue">(818) 954-1271</span> if you have any questions.</p>
    
    <h3 class="page-sub-heading">Waste Paint Disposal Procedures When on Local Location</h3>
    <p class="para-text-light bullet-list-pb">When returning paint-related materials to WBSO (including brush water), attach a label to the bucket that exactly describes the contents of the bucket, as described above in “Waste Paint Consolidation and Labeling.”</p>
    <p class="para-text-light">Do not use terms such as “Sludge” or “Waste” to describe the contents of a bucket! In the event of a spill or mishap, police or fire departments may call for the Fire Department Hazardous Materials Response Team if all containers of chemical-containing buckets are not properly labeled.</p>
    <p class="para-text-light">Leftover paint and brush water that can still be used can be transported back to the Main Lot. Materials that are considered “waste” cannot be transported by production and will need to be picked up by a licensed hauler. Contact Brent Gale for assistance at <span class="contact-number-blue">(818) 954-3880</span>.</p>
    <p class="para-text-light">Used materials that cannot be reused are considered waste and cannot be transported back to the main lot. This waste will need to be picked up by a licensed hazardous waste hauler; S&EA has a list of pre-approved haulers. The pickup will require the acquisition of an EPA ID Number, which may take in excess of two weeks. Waste cannot be picked up or shipped until the number has been issued by the EPA, as it will be required on the federal hazardous waste manifests. Disposal in any other manner is against WB Policy.</p>
    <p class="para-text-light">If your production company anticipates that it will be generating any used chemical-containing materials off-lot that will be considered “waste,” contact Brent Gale at <span class="contact-number-blue">(818) 954-3880</span>.</p>
</div>


</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-12-tab'}"
id="safety-environment-12-tab" role="tabpanel" aria-labelledby="safety-environment-12-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">{{number.four_point_twelve}} {{constants.hyphen}} {{constants.wbso_sewer_and_storm_drain_policies}}</h2>
    <p class="para-text-light studios-para">
        The types of materials discharged to the sewer or storm drain systems are highly regulated by local, state, and federal law. The chart below reflects Warner Bros. policy as to what can be discharged into each system. <strong>Employees violating the policies listed below will be subject to discipline, up to and including referral to Labor Relations and possibly termination.</strong>
    </p>
	<body>
		<table class="policy-table">
			<thead>
				<tr>
					<th colspan="2">SEWER (Sinks, Toilets, Mop Closets)</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Allowed</td>
					<td>Prohibited</td>
				</tr>
				<tr>
					<td>
						<ul>
							<li>Tap or bottled water - Ice - Soft drinks or other beverages - Some food (in garbage disposal only) - Mop water</li>
						</ul>
					</td>
					<td>
						<ul>
							<li><strong>Everything Else - Including:</strong> Paint / brush water - Solid foods - Car or cart wash water / soap - Cleaning supplies, solvents, or other chemicals - Gum, cigarette butts, or other trash</li>
						</ul>
					</td>
				</tr>
			</tbody>
			<thead>
				<tr>
					<th colspan="2">STORM DRAIN (All open drains on WBSO streets - untreated directly to L.A. River)</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Allowed</td>
					<td>Prohibited</td>
				</tr>
				<tr>
					<td>
						<ul>
							<li>Rainwater</li>
						</ul>
					</td>
					<td>
						<ul>
							<li><strong>Everything Else - Including:</strong> Tap water (!) - Bottled water - Ice or melted ice runoff - Soft drinks or other beverages - Paint/brush water - Solid foods - Car or cart wash water/soap - Cleaning supplies, solvents, or other chemicals - Gum, cigarette butts, or other trash</li>
						</ul>
					</td>
				</tr>
			</tbody>
			<thead>
				<tr>
					<th colspan="2">COSTS AND PENALTIES</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td colspan="2">
						<ul>
								<li>WBSO Fees for Labor and Costs of Clean-Up: Depends on extent of discharge.</li>
								<li>EPA Clean-Up Costs: Depends on extent of discharge. Could be $100,000 or more.</li>
								<li>Regional Water Quality Control Board: Fines of up to $10,000 per day per discharge, plus $10 per gallon of material discharged.</li>
								<li>Criminal Penalties: Fines for the Studio and/or the Production. Criminal charges can be brought against the responsible person(s).</li>
								<li>Damage to Environment: River, Ocean, Beach, Sewage Treatment Plant.</li>
								<li>Bad Publicity: For the Studio and the Production.</li>
								<li>Loss of Discharge Permits and/or Exemptions.</li>
								<li>Disciplinary Action: Violation of Studio Policy could result in Discipline, up to and including Termination.</li>
						</ul>
					</td>
				   
				</tr>
			</tbody>
			<thead>
				<tr>
					<th colspan="2">Please spread the message: Protect the Sewers and Storm Drains!</th>
				</tr>
			</thead>
		   
		</table>
	   
	</body>
</div>
</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-13-tab'}"
id="safety-environment-13-tab" role="tabpanel" aria-labelledby="safety-environment-13-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">{{number.four_point_thirteen}} {{constants.hyphen}} {{constants.additional_storm_drain_policy_guidelines_by_department}}</h2>
    <p class="para-text-light studios-para">The State of California, the Regional Water Quality Control Board and Warner Bros. Studios strictly forbid all employees, clients and tenants from intentionally dumping or allowing chemicals—including those labeled “environmentally friendly” or “biodegradable”—into the storm drain system.</p>
    <p class="para-text-light">Please review the WBSO storm drain policy guidelines below with your department heads and their crew as applicable. For any questions on wastewater, contact S&EA at <span class="contact-number-blue">(818) 954-2890</span> or the WB Plumbing at <span class="contact-number-blue">(818) 954-1700</span>.</p>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">Craft Services</h3>
    <ul class="bullet-list">
        <li>Ice, melted ice, drinking water and all other forms of liquid cannot be allowed to enter the storm drain system. Do not pour any of these liquids (or, in the case of ice, leave it to melt) on the ground in the vicinity of a storm drain catch basin.</li>
        <li>Regularly check the level of the waste tanks for Craft Service trailers, as well as on-stage or portable sinks, to make sure they do not overflow. Notify your Transportation Department when waste tanks on your trailers need to be emptied.</li>
        <li>Waste mop water and other forms of residential-type cleaning products must be deposited into mop sinks, toilets, or other locations which drain to the sanitary sewer.</li>
    </ul>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">Transportation</h3>
    <ul class="bullet-list">
        <li>Check the level of liquid in waste tanks on all trailers to make sure they are emptied before they overflow.</li>
        <li>Do not over-fill fuel tanks on vehicles. When done filling tanks, make sure that the filler cap is replaced before moving the vehicle.</li>
        <li>Report on-lot spills of fuel, motor oil, hydraulic oil and other vehicular fluids to S&EA at <span class="contact-number-blue">(818) 954-2890</span>. These spills must be cleaned up by a trained and qualified representative of the Hazardous Waste Facility or Fire Department, and the spill debris must be disposed of as hazardous waste.</li>
        <li>Car washing or detailing activities may not take place except in designated areas where containment is possible.</li>
    </ul>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">Make-Up & Costume Departments</h3>
    <ul class="bullet-list">
        <li>Do not allow water used in cleaning any equipment or materials (such as laundry) to run into the storm drain system. Please have production contact OPS <span class="contact-number-blue">(818) 954-6777</span> if you need assistance with disposal.</li>
    </ul>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">Props Department</h3>
    <ul class="bullet-list">
        <li>Check the level of liquid in waste tanks on all trailers to make sure they are emptied before they overflow. Notify your Transportation Department when waste tanks on your trailers need to be emptied.</li>
    </ul>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">Greens Department</h3>
    <ul class="bullet-list">
        <li>Do not allow water from outdoor plant watering to run into the storm drain system.</li>
    </ul>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">Special Effects</h3>
    <ul class="bullet-list">
        <li>Contact Office & Production Services whenever on-lot water effects are being planned on exterior sets (e.g., rain bars, wet downs, fire hydrant props, etc.) or on stage (e.g., showers, sinks, bathtubs, etc.). Advance coordination with S&EA and WB Plumbing is required to discuss plans for the required water capturing/disposal efforts.</li>
    </ul>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">Production / Construction – General Waste Disposal</h3>
    <ul class="bullet-list">
        <li>Make sure the area surrounding your sound stage(s) is kept free of construction or production related waste. This includes items like cigarette butts, wood, rope, empty plastic cups or bottles, nails, screws, paper, cardboard, candy wrappers, saw dust and metal filings, and other general forms of trash. Have your laborers police these areas to ensure that they are kept clean.</li>
    </ul>
</div>


</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-14-tab'}"
id="safety-environment-14-tab" role="tabpanel" aria-labelledby="safety-environment-14-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">{{number.four_point_fourteen}} {{constants.hyphen}} {{constants.guidelines_for_shipping_dangerous_goods}}</h2>
    <p class="para-text-light studios-para">READ THIS PRIOR TO SHIPPING ANYTHING BY AIR</p>
    <p class="para-text-light">When chemicals or chemical-containing products are shipped by air, they are called “Dangerous Goods.” Dangerous Goods are regulated by the International Air Transport Association (IATA) and these regulations are enforced by the Federal Aviation Administration (FAA) and the Department of Homeland Security.</p>
    <p class="para-text-light">Many of the products used by consumers in their households would be considered dangerous goods. The same could be said of many of the products shipped by offices and production companies. Some examples of dangerous goods include household products such as hair spray, glass cleaner, aerosol cans (regardless of content), paints, lubricating oils, lithium batteries, etc.</p>
    <p class="para-text-light">Shippers of dangerous goods should keep in mind that certain materials, like pressurized cylinders or aerosol cans, could represent a significant risk to an aircraft and its passengers if shipped illegally. A mishap involving such dangerous goods could impart significant liability to the shipper.</p>
    <p class="para-text-light">The process by which products or materials are identified, classified, packaged, marked and labeled in accordance with these regulations is rather complicated. Unless you have been trained to ship dangerous goods, it is NOT recommended that you do so. Instead of shipping common, readily available products, consider having productions on location instead buy the products locally.</p>
    <h3 class="page-sub-heading studios-para-no-paddding-upside">Shipping Dangerous Goods</h3>
    <p class="para-text-light">Before shipping packages, identify the contents of the package and check for possible dangerous goods. If you are given a package to ship, ask for a detailed list of the contents. If you have products or materials that you think might be dangerous goods, you should contact the mail carrier that you plan to ship the product or material with (i.e., Airborne, FedEx, etc.). They can tell you if it is a “dangerous good” and, for a small fee, most shipping companies can package and label your shipment for you.</p>
    <p class="para-text-light">Dangerous Goods Packers/Shippers:</p>
    <ul class="bullet-list">
        <li><span class="">DHL – Dangerous Goods:</span><span class="contact-number-blue"> 1-800-225-5345</span> or <a href="http://bit.ly/1tFDo3S" target="_blank">http://bit.ly/1tFDo3S</a></li>
        <li><span class="">FedEx – Dangerous Goods:</span><span class="contact-number-blue"> 1-800-463-3339</span> and say “dangerous goods” or <a href="http://bit.ly/S4YBrL" target="_blank">http://bit.ly/S4YBrL</a></li>
    </ul>
    <p class="para-text-light">Remember: The person who signs the shipping document is the responsible and legally liable party.</p>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-15-tab'}"
id="safety-environment-15-tab" role="tabpanel" aria-labelledby="safety-environment-15-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">{{number.four_point_fifteen}} {{constants.hyphen}} {{constants.unmanned_aerial_vehicles_unmanned_aerial_camera_platforms_drones}}</h2>
    <p class="para-text-light studios-para">On-Lot Productions</p>
    <ul class="bullet-list">
        <li class="para-text-light">Small, unmanned aircraft must weigh less than 55 pounds— including payload—at takeoff (unless the vendor has a waiver provided to Risk Management and Production Safety)</li>
        <li class="para-text-light">UAS vendor must utilize the Low Altitude Authorization & Notification Capability (LAANC) automated airspace approval system</li>
        <li class="para-text-light">When utilizing First Person View (FPV), the vendor must have a visual observer keeping line of sight on the drone at all times</li>
        <li class="para-text-light">Do not fly above 400ft above ground level (AGL)</li>
        <li class="para-text-light">Do not fly within 5 miles of an airport without approval and notification to the control tower</li>
        <li class="para-text-light">Do not fly a drone from a moving vehicle unless in a sparsely populated area</li>
        <li class="para-text-light">Flying over people is not permitted unless satisfying one of the categories in Subpart D for operations over humans.</li>
        <li class="para-text-light">Night operations are permitted under Part 107.29.</li>
    </ul>
    <div class="last-para-section">
        <p class="para-text-light studios-para-no-paddding-upside">What Safety & Environmental Affairs Needs:</p>
        <ul class="bullet-list">
            <li class="">In addition to the insurance documentation required by Risk Management, Safety will need the following information from the drone vendor:</li>
            <li class="">Pre-flight inspection</li>
            <li class="">Written safety guidelines for crew</li>
            <li class="">Emergency landing procedures</li>
            <li class="">Use of a Visual Observer, assigned to no other duties during drone flight(s)</li>
            <li class="">Plan of activity (POA) with a map including:
                <ul class="bullet-list">
                    <li class="">Takeoff/Landing zones (LZ)</li>
                    <li class="">Flight path/altitude</li>
                    <li class="">Safe zone for crew members not directly involved in flight operations</li>
                    <li class="">Emergency landing zone(s) (ELZ)</li>
                    <li class="">Obstructions to be avoided</li>
                </ul>
            </li>
            <li class="para-text-light">If you are planning on using the drone less than 50 ft clearance from cast or crew while in flight (or less than 30 ft. from stunt performers), contact S&EA to discuss prior to scheduled activity.</li>
        </ul>
        <p class="para-text-light studios-para-no-paddding-upside">My Pilot is a Crew Member!</p>
        <ul class="bullet-list">
            <li class="">If the drone pilot is a crew member with other responsibilities, they may not assume those responsibilities while participating in any activities related to the drone flight (i.e., pre-flight inspection, flying, etc.). Note that all safety and insurance requirements are the same as for any third party vendor.</li>
        </ul>
        <p class="para-text-light studios-para-no-paddding-upside">Additional Safety Protocols:</p>
        <ul class="bullet-list">
            <li class="">Attach Safety Bulletin #36 – Recommended Guidelines for Safely Working Around UAS to the Call Sheet</li>
            <li class="">Hold a pre-flight safety brief (review safety guidelines, flight plan and emergency procedures)</li>
            <li class="">Hold a safety meeting prior to flight with all pertinent cast and crew.</li>
            <li class="">Follow all protocols as outlined in the POA.</li>
            <li class="">Limit radio frequency (RF) use during flight operations to reduce risk of drone control failure</li>
            <li class="">(For Los Angeles and Downtown L.A. locations only) When working in downtown Los Angeles, notify Hooper LAPD flight operations of the planned drone use location.</li>
        </ul>
    </div>
</div>

</div>
				</div>
			</div>
		</div>
		<!-- </div> -->
	</div>
<!-- </div> -->
