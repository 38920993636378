import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth-service/auth.service';


export const AuthGuardFn: CanActivateFn = async (route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const isAuth = await authService.isAuthenticated();
  if (!isAuth) {
    localStorage.setItem('email-link', window.location.pathname)
    router.navigate(['/homepage']);
    return false;
  }
   // Retrieve the required roles from route data
   const expectedRoles = route.data['roles'] as Array<string>;
   const userRole = authService.getUserRole();
   // Check if the user's role is allowed for this route
   if (expectedRoles && !expectedRoles.includes(userRole)) {
     router.navigate(['/page-not-found']); // Redirect if the role is unauthorized
     return false;
   }
  return isAuth;

};

