import { Component, inject, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-attach-quotes',
  templateUrl: './view-attach-quotes.component.html',
  styleUrls: ['./view-attach-quotes.component.scss']
})
export class ViewAttachQuotesComponent {
  private modalService = inject(NgbModal);
  openFullscreen(content: TemplateRef<any>) {
    this.modalService.open(content, { windowClass: 'common-modal-xl', fullscreen: true });
  }
}

