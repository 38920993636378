<button data-bs-toggle="modal" data-bs-target="#addWBUser">Open add customer user modal</button>
<!-- common modal section-->
<div class="modal fade" id="addWBUser" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-fullscreen">
		<div class="modal-content common-modal-xl">
			<div class="modal-body justify-content-center align-items-center">
				<div class="cm-header-section">
					<h5 class="modal-title" id="commonModalLabel">Add A WB User</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
						<span   class="cm-close-text">Close</span></button>
				</div>
				<div>
					
				</div>
				<div class="container cm-main-container">
					<div class="common-modal-input-section">
						<h2 class="section-main-header">Search User</h2>
						<div class="">
							<div class="mt-56 custom-floating-label">
								<label for="input1" class="form-label">Search</label>
								<input type="text" class="pe-4 form-control" placeholder="Search user name or email address"
									id="input1">
								<img class="search-icon" src="../../../../../../assets/images/search-icon.svg" alt="Search Icon">
							</div>
							<div class="user-details">
								<ul class="list-group">
									<li class="list-group-item">
										<span class="username">Andrew Adams</span>
									</li>
									<li class="list-group-item">
										<span class="user-designation">Studio Operations - Sr. Technical Producer</span>
									</li>
									<li class="list-group-item">
										<span class="user-email">andrew.adams@wbd.com</span>
									</li>
								</ul>
								<a href="" class="text-link reset-link">Reset</a>
							</div>
						</div>
					</div>
					<div class="common-modal-input-section">
						<form>
							<h3 class="form-section-header">Group Name <img src="../../../../../../assets/images/question-mark.svg" alt=""></h3>
							<div class="">
								<div class="row">
									<div class="col-12 col-md-4 col-sm-6 mb-sm-3 mb-0 radio-block">
										<div class="common-radio-btn me-3">
											<input class="common-radio-input mb-0" type="radio" name="user-role" id="deptUser"
												formControlName="deptUser" value="" />
											<label class="common-radio-label" for="deptUser">
												Department User
											</label>
										</div>
									</div>
									<div class="col-12 col-md-4 col-sm-6 mb-sm-3 mb-0 radio-block">
										<div class="common-radio-btn">
											<input class="common-radio-input mb-0" type="radio" name="user-role" id="deptHead"
												formControlName="deptHead" value="" />
											<label class="common-radio-label" for="deptHead">
												Department Head
											</label>
										</div>
									</div>
									<div class="col-12 col-md-4 col-sm-6 mb-sm-3 mb-0 radio-block">
										<div class="common-radio-btn">
											<input class="common-radio-input mb-0" type="radio" name="user-role" id="csr" formControlName="csr"
												value="" />
											<label class="common-radio-label" for="csr">
												CSR
											</label>
										</div>
									</div>
									<div class="col-12 col-md-4 col-sm-6 mb-sm-3 mb-0 radio-block">
										<div class="common-radio-btn">
											<input class="common-radio-input mb-0" type="radio" name="user-role" id="ar" formControlName="ar"
												value="" />
											<label class="common-radio-label" for="ar">
												A/R
											</label>
										</div>
									</div>
									<div class="col-12 col-md-4 col-sm-6 mb-sm-3 mb-0 radio-block">
										<div class="common-radio-btn">
											<input class="common-radio-input mb-0" type="radio" name="user-role" id="systemAdmin" formControlName="systemAdmin"
												value="" />
											<label class="common-radio-label" for="systemAdmin">
												System Admin
											</label>
										</div>
									</div>
								</div>
							</div>
					</form>
					</div>

					<div class="cm-btn-section">
						<button type="button" class="btn common-btn-light me-2" data-bs-dismiss="modal">Add
							User</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--common modal section ends-->