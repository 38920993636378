import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { editfaq } from 'src/app/core/constants/core.constant';
import { NUMBER } from 'src/app/core/enum/core.enum';
import { FaqServiceService } from 'src/app/core/services/faq-service.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';

@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html',
  styleUrls: ['./edit-faq.component.scss']
})
export class EditFaqComponent implements OnInit {
  @ViewChild('unsavedChangesModalContent') unsavedChangesModalContent!: TemplateRef<any>;
  @Input() mode: boolean;
  @Output() modeChange = new EventEmitter<string>();
  @Output() toastMessage = new EventEmitter<{ type: string; message: string }>();

  constants = editfaq;
  number = NUMBER;
  portalType: string;
  private modalService = inject(NgbModal);
  selectedTab: string = this.constants.overview_tab;
  previousTab: string;
  isEditable: boolean;
  formGroups: { [key: string]: FormGroup[] } = {};
  sectionToDelete: { tab: string, index: number, faqId: number } | null = null;
  faqData;
  overview;
  account;
  your_project;
  request;
  users;
  contact_us;
  client_resource: any;
  your_projects;
  requests;
  projects;
  overviewCategoryId: any;
  accountCategoryId: any;
  projectsCategoryId: any;
  requestsCategoryId: any;
  usersCategoryId: any;
  contactCategoryId: any;
  clientresourceCategoryId: any;
  isEditFaq: boolean = true;
  hasUnsavedChanges: boolean = false;
  currentTab: string;
  prevtabArr: string[] = [];
  isUnsavedChangesOpened: boolean = false;
  deletedFaqIds: number[] = [];
  tabToDelete: string | null = null;
  indexToDelete: number | null = null;

  constructor(
    private FaqServiceService: FaqServiceService,
    private fb: FormBuilder,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.initializeForms();
    this.isEditable = this.mode;
    this.selectedTab = this.constants.overview_tab;
    this.selectTab(this.selectedTab);
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.getFaqData();
    if (userProfile?.userTypeId == 1) {
      this.portalType = "customer";
    }
    else if (userProfile?.userTypeId == 2) {
      this.portalType = "studio";
    }
  }

  /**
   * initializeForms() - method to initialize the forms
   */
  private initializeForms(): void {
    this.formGroups[editfaq.overview_faq] = [];
    this.formGroups[editfaq.details_faq] = [];
    this.formGroups[editfaq.account_faq] = [];
    this.formGroups[editfaq.projects_faq] = [];
    this.formGroups[editfaq.requests_faq] = [];
    this.formGroups[editfaq.users_faq] = [];
    this.formGroups[editfaq.contactus_faq] = [];
    this.formGroups[editfaq.client_resource_faq] = [];
  }

  /**
 * getFaqData() - method to fetch the faq details
 */
  getFaqData(): void {
    this.loaderService.setLoadingState(true);
    this.FaqServiceService.getFaqdata().subscribe({
      next: (response: any) => {
        if (response) {
          this.faqData = response?.faqSearch;

          // Initialize categories
          const categories = {
            overview: null,
            account: null,
            projects: null,
            requests: null,
            users: null,
            contact_us: null,
            client_resource: null
          };

          // Populate categories based on faqCategoryId
          this.faqData.forEach(category => {
            switch (category.faqCategoryId) {
              case 1:
                categories.overview = {
                  ...category,
                  faq: _.uniqBy(category.faq, item => `${item.faqQuestions}&&${item.faqAnswers}`)
                };
                break;
              case 2:
                categories.account = {
                  ...category,
                  faq: _.uniqBy(category.faq, item => `${item.faqQuestions}&&${item.faqAnswers}`)
                };
                break;
              case 3:
                categories.projects = {
                  ...category,
                  faq: _.uniqBy(category.faq, item => `${item.faqQuestions}&&${item.faqAnswers}`)
                };
                break;
              case 4:
                categories.requests = {
                  ...category,
                  faq: _.uniqBy(category.faq, item => `${item.faqQuestions}&&${item.faqAnswers}`)
                };
                break;
              case 5:
                categories.users = {
                  ...category,
                  faq: _.uniqBy(category.faq, item => `${item.faqQuestions}&&${item.faqAnswers}`)
                };
                break;
              case 6:
                categories.contact_us = {
                  ...category,
                  faq: _.uniqBy(category.faq, item => `${item.faqQuestions}&&${item.faqAnswers}`)
                };
                break;
              case 7:
                categories.client_resource = {
                  ...category,
                  faq: _.uniqBy(category.faq, item => `${item.faqQuestions}&&${item.faqAnswers}`)
                };
                break;
              default:
                break;
            }
          });

          // Assign the categories to the component properties
          this.overview = categories.overview;
          this.account = categories.account;
          this.projects = categories.projects;
          this.requests = categories.requests;
          this.users = categories.users;
          this.contact_us = categories.contact_us;
          this.client_resource = categories.client_resource;

          // Populate the sections
          this.populateOverviewSections(this.overview?.faq);
          this.populateAccountsSection(this.account?.faq);
          this.populateProjectsSection(this.projects?.faq);
          this.populateRequestSection(this.requests?.faq);
          this.populateUsersSection(this.users?.faq);
          this.populateContactUsSection(this.contact_us?.faq);
          this.populateClientResourcesSection(this.client_resource?.faq);
          this.setupFormListeners();
          this.loaderService.setLoadingState(false);
        } else {
          console.warn('FAQ data could not be retrieved.');
        }
      },
      error: (error) => {
        this.loaderService.setLoadingState(false);
        console.error('Error fetching FAQ details:', error);
      },
    });
  }

  /**
 * setupFormListeners() - method to setup the form listeners to detect the changes in the faq's
 */
  setupFormListeners(): void {
    Object.keys(this.formGroups).forEach(tab => {
      const formGroupArray = this.formGroups[tab];

      if (Array.isArray(formGroupArray)) {
        formGroupArray.forEach(formGroup => {
          if (formGroup instanceof FormGroup) {
            Object.keys(formGroup.controls).forEach(controlName => {
              formGroup.get(controlName).valueChanges.subscribe(() => {
                this.hasUnsavedChanges = true;
              });
            });
          } else {
            console.error('Expected FormGroup but got:', formGroup);
          }
        });
      } else {
        console.error('Expected an array of FormGroup for tab:', tab);
      }
    });
  }

  /**
   * populateOverviewSections() - method to get the exisiting details to display in form
   * @param faqItems - the faq data
   */
  populateOverviewSections(faqItems: any[]): void {
    if (faqItems && faqItems.length > 0) {
      faqItems.forEach(item => {
        const section = this.fb.group({
          faqId: [item.faqId || null],
          title: [item.faqQuestions || '', Validators.required],
          details: [item.faqAnswers || '', Validators.required]
        });
        this.formGroups[editfaq.overview_faq].push(section);
      });
    }
  }

  /**
  * populateAccountsSection() - method to get the exisiting details to display in form
  * @param faqItems - the faq data
  */
  populateAccountsSection(faqItems: any[]): void {
    if (faqItems && faqItems.length > 0) {
      faqItems.forEach(item => {
        const section = this.fb.group({
          faqId: [item.faqId || null],
          accountTitle: [item.faqQuestions || '', Validators.required],
          accountDetails: [item.faqAnswers || '', Validators.required]
        });
        this.formGroups[editfaq.account_faq].push(section);
      });
    }
  }

  /**
  * populateProjectsSection() - method to get the exisiting details to display in form
  * @param faqItems - the faq data
  */
  populateProjectsSection(faqItems: any[]): void {
    if (faqItems && faqItems.length > 0) {
      faqItems.forEach(item => {
        const section = this.fb.group({
          faqId: [item.faqId || null],
          projectTitle: [item.faqQuestions || '', Validators.required],
          projectDetails: [item.faqAnswers || '', Validators.required]
        });
        this.formGroups[editfaq.projects_faq].push(section);
      });
    }
  }

  /**
  * populateRequestSection() - method to get the exisiting details to display in form
  * @param faqItems - the faq data
  */
  populateRequestSection(faqItems: any[]): void {
    if (faqItems && faqItems.length > 0) {
      faqItems.forEach(item => {
        const section = this.fb.group({
          faqId: [item.faqId || null],
          requestTitle: [item.faqQuestions || '', Validators.required],
          requestDetails: [item.faqAnswers || '', Validators.required]
        });
        this.formGroups[editfaq.requests_faq].push(section);
      });
    }
  }

  /**
 * populateUsersSection() - method to get the exisiting details to display in form
 * @param faqItems - the faq data
 */
  populateUsersSection(faqItems: any[]): void {
    if (faqItems && faqItems.length > 0) {
      faqItems.forEach(item => {
        const section = this.fb.group({
          faqId: [item.faqId || null],
          userName: [item.faqQuestions || '', Validators.required],
          userDetails: [item.faqAnswers || '', Validators.required]
        });
        this.formGroups[editfaq.requests_faq].push(section);
      });
    }
  }

  /**
 * populateContactUsSection() - method to get the exisiting details to display in form
 * @param faqItems - the faq data
 */
  populateContactUsSection(faqItems: any[]): void {
    if (faqItems && faqItems.length > 0) {
      faqItems.forEach(item => {
        const section = this.fb.group({
          faqId: [item.faqId || null],
          contactTitle: [item.faqQuestions || '', Validators.required],
          contactDetails: [item.faqAnswers || '', Validators.required]
        });
        this.formGroups[editfaq.requests_faq].push(section);
      });
    }
  }

  /**
 * populateClientResourcesSection() - method to get the exisiting details to display in form
 * @param faqItems - the faq data
 */
  populateClientResourcesSection(faqItems: any[]): void {
    if (faqItems && faqItems.length > 0) {
      faqItems.forEach(item => {
        const section = this.fb.group({
          faqId: [item.faqId || null],
          clientTitle: [item.faqQuestions || '', Validators.required],
          clientDetails: [item.faqAnswers || '', Validators.required]
        });
        this.formGroups[editfaq.requests_faq].push(section);
      });
    }
  }

  /**
   * getOverviewSections() - method to return the formGroup
   * @returns - the form group
   */
  getOverviewSections(): FormGroup[] {
    return this.formGroups[editfaq.overview_faq];
  }

  /**
   * getAccountSections() - method to return the formGroup
   * @returns - the form group
   */
  getAccountSections(): FormGroup[] {
    return this.formGroups[editfaq.account_faq];
  }

  /**
   * getProjectsSections() - method to return the formGroup
   * @returns - the form group
   */
  getProjectsSections(): FormGroup[] {
    return this.formGroups[editfaq.projects_faq];
  }

  /**
   * getRequestsSections() - method to return the formGroup
   * @returns - the form group
   */
  getRequestsSections(): FormGroup[] {
    return this.formGroups[editfaq.requests_faq];
  }

  /**
   * getUsersSections() - method to return the formGroup
   * @returns - the form group
   */
  getUsersSections(): FormGroup[] {
    return this.formGroups[editfaq.users_faq];
  }

  /**
   * getContactUsSections() - method to return the formGroup
   * @returns - the form group
   */
  getContactUsSections(): FormGroup[] {
    return this.formGroups[editfaq.contactus_faq];
  }

  /**
   * getClientResourcesSection() - method to return the formGroup
   * @returns - the form group
   */
  getClientResourcesSection(): FormGroup[] {
    return this.formGroups[editfaq.client_resource_faq]
  }

  /**
   * addOverviewSection() - method to add the new form data
   */
  addOverviewSection(): void {
    const section = this.fb.group({
      title: [null, Validators.required],
      details: [null, Validators.required]
    });
    this.getOverviewSections().push(section);
    this.setupFormListeners();
  }

  /**
   * addAccountSection() - method to add the new form data
   */
  addAccountSection(): void {
    const section = this.fb.group({
      accountTitle: [null, Validators.required],
      accountDetails: [null, Validators.required]
    });
    this.getAccountSections().push(section);
    this.setupFormListeners();
  }

  /**
   * addProjectsSection() - method to add the new form data
   */
  addProjectsSection(): void {
    const section = this.fb.group({
      projectTitle: [null, Validators.required],
      projectDetails: [null, Validators.required]
    });
    this.getProjectsSections().push(section);
    this.setupFormListeners();
  }

  /**
   * addRequestsSection() - method to add the new form data
   */
  addRequestsSection(): void {
    const section = this.fb.group({
      requestTitle: [null, Validators.required],
      requestDetails: [null, Validators.required]
    });
    this.getRequestsSections().push(section);
    this.setupFormListeners();
  }

  /**
   * addUsersSection() - method to add the new form data
   */
  addUsersSection(): void {
    const section = this.fb.group({
      userName: [null, Validators.required],
      userDetails: [null, Validators.required]
    });
    this.getUsersSections().push(section);
    this.setupFormListeners();
  }

  /**
   * addContactUsSection() - method to add the new form data
   */
  addContactUsSection(): void {
    const section = this.fb.group({
      contactTitle: [null, Validators.required],
      contactDetails: [null, Validators.required]
    });
    this.getContactUsSections().push(section);
    this.setupFormListeners();
  }

  /**
   * removeOverviewSection() - method to delte the faq
   * @param index - index of the faq to be deleted
   */
  removeOverviewSection(index: number): void {
    this.getOverviewSections().splice(index, 1);
  }

  /**
   * removeAccountSection() - method to delte the faq
   * @param index - index of the faq to be deleted
   */
  removeAccountSection(index: number): void {
    this.getAccountSections().splice(index, 1);
  }

  /**
   * removeProjectsSection() - method to delte the faq
   * @param index - index of the faq to be deleted
   */
  removeProjectsSection(index: number): void {
    this.getProjectsSections().splice(index, 1);
  }

  /**
   * removeRequestsSection() - method to delte the faq
   * @param index - index of the faq to be deleted
   */
  removeRequestsSection(index: number): void {
    this.getRequestsSections().splice(index, 1);
  }

  /**
   * removeUsersSection() - method to delte the faq
   * @param index - index of the faq to be deleted
   */
  removeUsersSection(index: number): void {
    this.getUsersSections().splice(index, 1);
  }

  /**
   * removeContactUsSection() - method to delte the faq
   * @param index - index of the faq to be deleted
   */
  removeContactUsSection(index: number): void {
    this.getContactUsSections().splice(index, 1);
  }

  /**
   * saveChanges() - method to save the added or edited faq data
   * @param tab - the section in which the faq is present
   */
  saveChanges(tab: string): void {
    this.hasUnsavedChanges = false;
    this.loaderService.setLoadingState(true);

    const newFaqDetails = [];
    const updatedFaqDetails = [];

    this.formGroups[tab]?.forEach(section => {
      const faqId = section.get('faqId')?.value;
      const faqData = {
        faqQuestions: section.get('title')?.value || section.get('accountTitle')?.value || section.get('projectTitle')?.value || section.get('requestTitle')?.value || section.get('userName')?.value || section.get('contactTitle')?.value || section.get('clientTitle')?.value,
        faqAnswers: section.get('details')?.value || section.get('accountDetails')?.value || section.get('projectDetails')?.value || section.get('requestDetails')?.value || section.get('userDetails')?.value || section.get('contactDetails')?.value || section.get('clientDetails')?.value
      };

      if (!faqId) {
        newFaqDetails.push(faqData);
      } else {
        updatedFaqDetails.push({ faqId, ...faqData });
      }
    });

    const selectedFaqCategory = this.getSelectedFaqCategory(tab);

    if (updatedFaqDetails?.length > 0 && !this.deletedFaqIds?.length) {
      this.editFaqDetails(updatedFaqDetails);
    }

    if (newFaqDetails?.length > 0 && !this.deletedFaqIds?.length) {
      this.addFaqDetails(newFaqDetails, selectedFaqCategory);
    }

    if (this.deletedFaqIds?.length > 0) {
      this.deletedFaqIds.forEach(faqId => {
        this.deleteFaqDetails([faqId]); // Call deleteFaqDetails for each ID
      });
    }

    this.loaderService.setLoadingState(false);
  }

  /**
   * getSelectedFaqCategory() - method to get the category id of the selected tab
   * @param tab - the selected tab (overview, account, your projects etc)
   */
  getSelectedFaqCategory(tab: string) {
    return this.faqData?.find(category => {
      switch (tab) {
        case this.constants.overview_faq:
          return category.faqCategoryId === 1;
        case this.constants.account_faq:
          return category.faqCategoryId === 2;
        case this.constants.projects_faq:
          return category.faqCategoryId === 3;
        case this.constants.requests_faq:
          return category.faqCategoryId === 4;
        case this.constants.users_faq:
          return category.faqCategoryId === 5;
        case this.constants.contactus_faq:
          return category.faqCategoryId === 6;
        default:
          return null;
      }
    });
  }

  /**
   * addFaqDetails() - method to subscribe to the post api call
   * @param newFaqDetails - new faq details to be added
   * @param selectedFaqCategory - the faq category where the faq is to be added
   */
  addFaqDetails(newFaqDetails: any[], selectedFaqCategory: any): void {
    const addPayload = {
      faqCategoryId: selectedFaqCategory?.faqCategoryId,
      loginUserId: JSON.parse(localStorage.getItem('user-profile')).userProfileId,
      faq: newFaqDetails
    };

    this.FaqServiceService.addFaqData(addPayload).subscribe({
      next: (response) => {
        if (response) {
          this.loaderService.setLoadingState(false);
          if (!this.isUnsavedChangesOpened) {
            this.modeChange.emit('View');
          }
          this.toastMessage.emit({ type: 'success', message: 'FAQ(s) Added Successfully' });
        }
      },
      error: (error) => {
        console.error(`Error saving form data FAQ data:`, error);
        this.loaderService.setLoadingState(false);
        this.toastMessage.emit({ type: 'danger', message: 'Error in Adding FAQ' });
      }
    });
  }

  /**
   * editFaqDetails() - method to subscribe to edit faq api call
   * @param updatedFaqDetails - the updated faq details
   */
  editFaqDetails(updatedFaqDetails: any[]): void {
    const updatePayload = {
      faq: updatedFaqDetails,
      loginUserId: JSON.parse(localStorage.getItem('user-profile')).userProfileId
    };

    this.FaqServiceService.updateFaqData(updatePayload).subscribe({
      next: (response) => {
        if (response) {
          this.loaderService.setLoadingState(false);
          if (!this.isUnsavedChangesOpened) {
            this.modeChange.emit('View');
          }
          this.toastMessage.emit({ type: 'success', message: 'FAQ(s) Data Updated Successfully' });
        }
      },
      error: (error) => {
        console.error(`Error editing FAQ:`, error);
        this.loaderService.setLoadingState(false);
        this.toastMessage.emit({ type: 'danger', message: 'Error in Updating FAQ(s) Data' });
      }
    });
  }

  /**
   * discardChanges() - method to discard the changes made in faq
   * @param tab - the section in which the faq is present
   */
  discardChanges(tab: string): void {
    this.selectedTab = tab;
    this.setupFormListeners();
    this.getFaqData();
    this.initializeForms();
    this.hasUnsavedChanges = false;
    this.deletedFaqIds = null;
  }

  /**
   * selectTab() - method to select the tab/section
   * @param tab - the section for ex: overview/account/your projects etc;
   */
  selectTab(tab: string): void {
    const trimmedTab = tab.replace('-tab', '');
    this.selectedTab = trimmedTab;
    if (this.selectedTab) {
      this.prevtabArr.push(this.selectedTab);
      if (this.prevtabArr?.length <= 1) {
        this.previousTab = this.prevtabArr[this.prevtabArr?.length - 1];
      }
      else {
        this.previousTab = this.prevtabArr[this.prevtabArr?.length - 2];
      }
    }
    this.setupFormListeners();
    if (this.hasUnsavedChanges) {
      this.openUnsavedChangesModal(this.previousTab);
    } else {
      this.selectedTab = tab;
    }
  }

  /**
   * openDeleteConfirmationModal() - method to open the confirmation modal for faq delete
   * @param content - the modal template
   * @param tab - the tab in which faq to be deleted is present
   * @param index - index of the faq to be deleted
   */
  openDeleteConfirmationModal(content: TemplateRef<any>, tab: string, index: number): void {
    const faqId = this.formGroups[tab][index].get('faqId')?.value;
    if (faqId && !this.deletedFaqIds.includes(faqId)) {
      this.deletedFaqIds.push(faqId);
    }
    this.tabToDelete = tab;
    this.indexToDelete = index;
  
    this.modalService.open(content, { windowClass: 'common-modal-center', centered: true, backdrop: 'static' });
  }

  /**
   * confirmDelete() - method to subscribe to delete faq api call
   */
  confirmDelete(): void {
    if (this.tabToDelete && this.indexToDelete !== null) {
      this.removeSection(this.tabToDelete, this.indexToDelete);
    }
    this.modalService.dismissAll();
  
    this.tabToDelete = null;
    this.indexToDelete = null;
  }

  /**
   * deleteFaqDetails() - method to subscribe to the delete api call
   * @param faqIds - Id's of the faq's to be deleted 
   */
  deleteFaqDetails(faqIds: number[]): void {
    this.loaderService.setLoadingState(true);
  
    this.FaqServiceService.deleteFaqData(faqIds).subscribe({
      next: (response) => {
        if (response) {
          this.deletedFaqIds.forEach(id => {
            Object.keys(this.formGroups).forEach(tab => {
              this.formGroups[tab] = this.formGroups[tab].filter(section => section.get('faqId')?.value !== id);
            });
          });
  
          // Reset the deletedFaqIds array after deletion
          this.deletedFaqIds = [];
  
          if (!this.isUnsavedChangesOpened) {
            this.modeChange.emit('View');
          }
  
          this.toastMessage.emit({ type: 'success', message: 'FAQ(s) Deleted Successfully' });
        }
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        console.error('Error deleting FAQ:', error);
        this.loaderService.setLoadingState(false);
        this.toastMessage.emit({ type: 'danger', message: 'Error in deleting FAQ' });
      }
    });
  }


  /**
   * removeSection() - method to remove the deleted faq
   * @param tab - the section in which deleted faq is present earlier
   * @param index - index of the deleted faq
   */
  removeSection(tab: string, index: number): void {
    switch (tab) {
      case editfaq.overview_faq:
        this.removeOverviewSection(index);
        break;
      case editfaq.account_faq:
        this.removeAccountSection(index);
        break;
      case editfaq.projects_faq:
        this.removeProjectsSection(index);
        break;
      case editfaq.requests_faq:
        this.removeRequestsSection(index);
        break;
      case editfaq.users_faq:
        this.removeUsersSection(index);
        break;
      case editfaq.contactus_faq:
        this.removeContactUsSection(index);
        break;
      default:
        break;
    }
  }

  /**
   * openUnsavedChangesModal() - method to open the unsaved changes modal
   * @param tab - current section
   */
  openUnsavedChangesModal(tab: string): void {
    this.isUnsavedChangesOpened = true;
    const modalRef = this.modalService.open(this.unsavedChangesModalContent,
      {
        windowClass: 'common-modal-center',
        centered: true,
        backdrop: 'static'
      });
    modalRef.result.then(
      (result) => {
        this.selectedTab = `${this.prevtabArr[this.prevtabArr?.length - 1]}-tab`;
        if (result) {
          this.saveChanges(tab);
          this.hasUnsavedChanges = false;
        } else {
          this.discardChanges(this.selectedTab);
          this.hasUnsavedChanges = false;
          this.isUnsavedChangesOpened = false;
        }
      },
      (reason) => {
        console.log('Modal dismissed:', reason);
      }
    );
  }
}