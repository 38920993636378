import { Component, HostListener } from '@angular/core';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-user-project',
  templateUrl: './user-project.component.html',
  styleUrls: ['./user-project.component.scss']
})
export class UserProjectComponent {
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  public dropdownSettings: IDropdownSettings = {};
  ngOnInit() {
    this.dropdownList = [
      { item_id: 1, item_text: 'Project Name' },
      { item_id: 2, item_text: 'Project Name' },
      { item_id: 3, item_text: 'Project Name' },
      { item_id: 4, item_text: 'Project Name' },
      { item_id: 5, item_text: 'Project Name' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      showSelectedItemsAtTop: false
    };
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  gridOptions: GridOptions = {
    pagination: true,
    paginationPageSize: 10,
    domLayout: 'autoHeight',
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      filter: true
    },
  };
  rowData = [{ user: "Colette Alfaro", email: "col2@wbd.com", accountType: "", status: "ACTIVE", date: "1/11/24", time: "10:00AM", jobTitle: "Custom Assistant", access: "1", sections: "Project" },
  { user: "Lexi Ajian ", email: "lexi@sunshineproductions.com", accountType: "", status: "ACTIVE", date: "1/11/24 ", time: "10:00AM", jobTitle: "Executive Producer", access: "All", sections: "All" },
  { user: "Lin Ang ", email: "linang@sunshineproductions.com", accountType: "", status: "DEACTIVATED", date: "1/11/24 ", time: "10:00PM", jobTitle: "Assistant", access: "2", sections: "Project ; Manage Users" },
  { user: "Maggle Bash", email: "mbash@sunshineproductions.com", accountType: "", status: "ACTIVE", date: "1/11/24 ", time: "10:00PM", jobTitle: "Producer", access: "All", sections: "All" },
  { user: "Farhan Blevins", email: "fblevins@sunshineproductions.com", accountType: "", status: "ACTIVE", date: "1/11/24 ", time: " 10:00PM", jobTitle: "Accountant", access: "2", sections: "Project" },
  { user: "Nuha Hodgson", email: "nuhahodgson@gmail.com", accountType: "", status: "DEACTIVATED", date: "1/11/24 ", time: " 10:00PM", jobTitle: "Sound Director", access: "2", sections: "Project" },
  { user: "Eryn Mline", email: "eryn.milhe@sunshineproductions.com", accountType: "", status: "ACTIVE", date: "1/11/24 ", time: "10:00PM", jobTitle: "Costume Director", access: "2", sections: "Project" },
  { user: "Risa Miyu ", email: "rlsamiyu@sunshineproductions.com", accountType: "", status: "ACTIVE", date: "1/11/24 ", time: "10:00PM", jobTitle: "Assistant", access: "1", sections: "Requests" },
  { user: "Jodie Smith", email: "jodie@sunshineproductions.com", accountType: "account admin", status: "ACTIVE", date: "1/11/24 ", time: "10:00PM", jobTitle: "Executive Producer", access: "4", sections: "All" },
  { user: "Kendell Turner", email: "kendell.turner@sunshineproductions.com", accountType: "", status: "DEACTIVATED", date: "1/11/24 ", time: "10:00PM", jobTitle: "Executive Producer", access: "3", sections: "All" }
  ];
  colDefs: ColDef[] = [
    {field:'id',
      headerName:'ID#',
      minWidth:95
    },

    {
      headerName: 'User',
      field: 'user',
      filter: 'agTextColumnFilter',
      filterParams: { alwaysShowFilter: true },
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const user = params.data.user;
        const email = params.data.email;
        const type = params.data.accountType;
        return `<span class="common-split-cell-section">
                  <span class="common-split-cell-data-1">${user}</span>
                  <span class="common-split-cell-data-2">${email}</span>
									<span class="common-split-cell-data-3">${type}</span>
                </span>`;
      },
      minWidth: 185
    },

    {
      headerName: 'STATUS',
      field: 'status',
      cellRenderer: this.statusCellRenderer.bind(this),
      minWidth: 140,
    },
    {
      headerName: 'Last Login',
      field: 'activity',
      filter: false,
      cellRenderer: (params: ICellRendererParams) => {
        const date = params.data.date;
        const time = params.data.time;

        return `<span class="common-split-cell-section">
                  <span class="common-split-cell-data-default-font common-split-cell-data-1">last:${date}</span>
                  <span class="common-split-cell-data-2">${time}</span>
                </span>`;
      }, minWidth: 120
    },
    { field: "jobTitle", filter: false, minWidth: 160, cellClass: "common-cell-data-bold" },
    { headerName: ' Access to DPT.', filter: false, field: "access", cellClass: "user-project-acces-cell", minWidth: 120,flex:1.2 },
    { field: "sections", filter: false, minWidth: 150 },
    {
      headerName: 'USER',
      field: 'merged-field',
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const user = params.data.user;
        const email = params.data.email;
        const status = params.data.status;
        const statusClass = this.getStatusClass(status);
        
        return `
          <span class="common-split-cell-section">
            <span class="common-split-cell-data-1">${user}</span>
            <span title="${email}" class="common-split-cell-data-2 ellipsis-email">${email}</span>
            <span class="common-split-cell-data-4">
              <span class="request-status-tag ${statusClass} mt-common-ag-grid-contant">${status}</span>
            </span>
          </span>
        `;
      },
      minWidth: 160,
      hide:true
    },
    {
      field: "icons",
      cellRenderer: () => {
        return `<div class="icon-container">
          <span class="icon-hover">
            <img src="/assets/images/account-freezed-icon.svg"/>
          </span>
          <span class="icon-hover">
            <img src="/assets/images/user-deactivate-icon.svg"/>
          </span>
          <span class="icon-hover">
            <img src="/assets/images/user-remove-icon.svg"/>
          </span>
        </div>`;

      },
      cellClass:'user-project-icons',
      headerName: '',
      filter: false,
      minWidth: 113,
      maxWidth:140
    }
  ];
  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.value);
    return `
      <div class="status-container">
        <div class="request-status-tag ${statusClass}">${params.value}</div>
      </div>
    `;
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'ACTIVE':
        return 'user-status-tag active account-user-common-status-cell mt-common-ag-grid';
      case 'DEACTIVATED':
        return 'user-status-tag deactivated account-user-common-status-cell mt-common-ag-grid';
      default:
        return '';
    }
  }
  gridApi!: GridApi;
  pageSizeOptions: number[] = [10, 20, 50];
  startRow: number = 1;
  endRow: number = 10;
  totalRows: number = this.rowData.length;
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.updatePagination();
  }
  onPageSizeChanged(event: any) {
    const value = event.target.value;
    this.gridApi.paginationSetPageSize(Number(value));
    this.updatePagination();
  }
  onBtNext() {
    this.gridApi.paginationGoToNextPage();
    this.updatePagination();
  }
  onBtPrevious() {
    this.gridApi.paginationGoToPreviousPage();
    this.updatePagination();
  }
  updatePagination() {
    if (this.gridApi) {
      const currentPage = this.gridApi.paginationGetCurrentPage();
      const pageSize = this.gridApi.paginationGetPageSize();
      this.startRow = currentPage * pageSize + 1;
      this.endRow = Math.min((currentPage + 1) * pageSize, this.totalRows);
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustColumns(event.target.innerWidth);
  }
  adjustColumns(screenWidth: number) {
    if (this.gridApi) {
      const allColumnIds: string[] = [];
      this.gridApi.getAllGridColumns().forEach((column) => {
        allColumnIds.push(column.getColId());
      });

      if (screenWidth <= 375) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['merged-field','icons'], true);
      } else if (screenWidth > 375 && screenWidth <= 510) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['merged-field', 'icons'], true);
      } else if (screenWidth > 511 && screenWidth <= 992) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['user', 'status','activity', 'jobTitle', 'icons'], true);
      } else {
        this.gridApi.setColumnsVisible(allColumnIds, true);
      }
    }
  }
}
