import { Component, Inject, OnInit } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { FormControl, FormGroup } from '@angular/forms';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { Router } from '@angular/router';
import { filter, forkJoin, map, Observable, switchMap } from 'rxjs';
import { AuthState } from '@okta/okta-auth-js';
import { UserProfileService } from '../../services/user-profileinfo/user-profile.service';
import { login } from '../../constants/core.constant';
import * as _ from 'lodash';
import { HeaderService } from '../../services/header/header.service';
import { UserService } from '../../services/user-service/user-service.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public isAuthenticated$!: Observable<boolean>;
  public name$!: Observable<string>;
  public token$!: Observable<string>;
  logininfo = login;
  clearCache: any;
  userDetails = JSON.parse(localStorage.getItem('user-profile'))


  constructor(@Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private _oktaStateService: OktaAuthStateService,
    private router: Router, private userProfile: UserProfileService, private userService: UserService,
    private headerService: HeaderService) { }

  userInfo: any;

  ngOnInit(): void {
    this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
    this.name$ = this._oktaStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
      map((authState: AuthState) => authState.idToken?.claims.name ?? '')
    );
    this.userDetails = JSON.parse(localStorage.getItem('user-profile'))
    this.getUserInfo()
  }

  /**
  *logout() - after successfull logout this method will redirect to signed out dashboard
  */

  async logout() {
    await this.headerService.clearCache().subscribe({
      next: (data: any) => {
        this.clearCache = data.message;
      },
      error: (error: any) => {
      }
    });
    await this._oktaAuth.signOut();
    localStorage.clear();
  }

  /**
  *oktaAuthentication() - after successfull login this method will redirect to respective dashboard
  */

  async oktaAuthentication() {
    await this._oktaAuth
      .signInWithRedirect()
      .then((_) => this.router.navigate(['/homepage']))
      .catch((Error) => {
        console.log(Error);
      });

  }

  /**
  *loginUser() - invoke the OKTA login method
  */

  loginUser(): void {
    this.oktaAuthentication();
  }

  /**
  *getuserProfileinfo() - Method to get user information via API
  */

  getuserProfileinfo() {
    this.userProfile.getUsers().subscribe((posts) => {
      localStorage.setItem('user-profile', JSON.stringify(posts))
    });
    this.userInfo = JSON.parse(localStorage.getItem('user-profile'))
  }

  /**
   * getUserInfo() - used to subscribe userInfo that is set from the service
   */
  getUserInfo(): void {
    this.userService.userInfo$.subscribe((userInfo) => {
      if (userInfo) {
        this.userDetails = userInfo?.userProfileDTO;
      }
    });
  }

  /**
 * redirectToProfile() - Redirects the user to the appropriate profile page based on their user type.
 * 
*/

  redirectToProfile() {
    if (this.userDetails?.userTypeId === 1) {
      this.router.navigate(['feature/customer-dashboard/user-dropdown'])
    }
    else {
      this.router.navigate(['feature/studio-dashboard/user-dropdown'])

    }
  }
}