export const VIEW_ALL_USERS_DATA = [
    {
      id: '12341',
      user: 'Colette Alfaro',
      email: 'col2@wbd.com',
      accountType: '',
      status: 'ACTIVE',
      date: '01/11/24',
      time: '10:00AM',
      jobTitle: 'Custom Assistant',
      access: '1',
      sections: 'Project',
    },
    {
      id: '12345',
      user: 'Lexi Ajian',
      email: 'lexi@sunshineproductions.com',
      accountType: '',
      status: 'ACTIVE',
      date: '02/21/24',
      time: '10:00AM',
      jobTitle: 'Executive Producer',
      access: 'All',
      sections: 'All',
    },
    {
      id: '12348',
      user: 'Lin Ang',
      email: 'linang@sunshineproductions.com',
      accountType: '',
      status: 'DEACTIVATED',
      date: '03/04/24',
      time: '10:00PM',
      jobTitle: 'Assistant',
      access: '2',
      sections: 'Project ; Manage Users',
    },
    {
      id: '12349',
      user: 'Maggle Bash',
      email: 'mbash@sunshineproductions.com',
      accountType: '',
      status: 'ACTIVE',
      date: '04/27/24',
      time: '10:00PM',
      jobTitle: 'Producer',
      access: 'All',
      sections: 'All',
    },
    {
      id: '12351',
      user: 'Farhan Blevins',
      email: 'fblevins@sunshineproductions.com',
      accountType: '',
      status: 'ACTIVE',
      date: '05/05/24',
      time: ' 10:00PM',
      jobTitle: 'Accountant',
      access: '2',
      sections: 'Project',
    },
    {
      id: '12356',
      user: 'Nuha Hodgson',
      email: 'nuhahodgson@gmail.com',
      accountType: '',
      status: 'DEACTIVATED',
      date: '06/14/24',
      time: ' 10:00PM',
      jobTitle: 'Sound Director',
      access: '2',
      sections: 'Project',
    },
    {
      id: '12367',
      user: 'Eryn Mline',
      email: 'eryn.milhe@sunshineproductions.com',
      accountType: '',
      status: 'ACTIVE',
      date: '07/19/24',
      time: '10:00PM',
      jobTitle: 'Costume Director',
      access: '2',
      sections: 'Project',
    },
    {
      id: '12368',
      user: 'Risa Miyu',
      email: 'rlsamiyu@sunshineproductions.com',
      accountType: '',
      status: 'ACTIVE',
      date: '08/09/24',
      time: '10:00PM',
      jobTitle: 'Assistant',
      access: '1',
      sections: 'Requests',
    },
    {
      id: '12369',
      user: 'Jodie Smith',
      email: 'jodie@sunshineproductions.com',
      accountType: 'account admin',
      status: 'ACTIVE',
      date: '09/30/24',
      time: '10:00PM',
      jobTitle: 'Executive Producer',
      access: '4',
      sections: 'All',
    },
    {
      id: '12380',
      user: 'Kendell Turner',
      email: 'kendell.turner@sunshineproductions.com',
      accountType: '',
      status: 'DEACTIVATED',
      date: '10/24/24',
      time: '10:00PM',
      jobTitle: 'Executive Producer',
      access: '3',
      sections: 'All',
    },
  ];

export const companyUserTypeahead = [
    { id: 1, text: 'userTypeahead' },
];

export const companyJobTitleTypeahead = [
    { id: 1, text: 'jobtitleTypeahead' },
];

export const companyUserIdTypeahead = [
    { id: 1, text: 'userIdTypeahead' },
];

export const companyProjectIdTypeahead = [
    { id: 1, text: 'projectIdTypeahead' },
];