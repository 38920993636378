<button data-bs-toggle="modal" data-bs-target="#addUserCustPortal">Add A New Project</button>
<!-- common modal section-->
<div class="modal fade" id="addUserCustPortal" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
          <div class="cm-header-title text-center mb-5">
            <h5 class="modal-title mb-2" id="commonModalLabel">Certificate of Insurance</h5>
            <p class="modal-title-desc">This certificate is issued as a matter of information only and confers no rights upon the certificate holder. <br>
                This certificate does not amend, extend or alter the coverage afforded by the policies below.</p>
          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
              class="cm-close-text">Close</span></button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
                <div class="modal-left-section me-4">
                    <h4 class="mt-4">Credit Application</h4>
                    <div class="company-meta-data">
                      <p class="title"><strong><span class="label-title">PROJECT</span></strong>: The new show <span></span></p>
                      <p class="title mb-0"><strong><span class="label-title">Departments:</span></strong></p>
                      <p class="pb-0 pt-0 mb-0">Studio Operations; Transportation; Design Studio; Post Production; Creative Services; Property; Production Catering</p>
                    </div>             
                    <div class="mb-4 mt-3">
                      <div class="list-group list-group-flush mb-2">
                        <ul class="step-list">
                          <li>
                            <span class="step-icon step-check"></span>
                            <span class="step-text"> Application Form</span>
                          </li>
                          <li>
                            <span class="step-icon"></span>
                            <span class="step-text current-step"> Certification of Insurance</span>
                          </li>
                          <li>
                            <span class="step-icon"></span>
                            <span class="step-text">Terms & Conditions</span>
                          </li>
                          <li>
                            <span class="step-icon"></span>
                            <span class="step-text">Review Application</span>
                          </li>
                        </ul>
                      </div>
                      <p class="required-text">(<span class="star-red">*</span>)Required</p>
                    </div>
                  </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="container cm-main-container px-0 mx-0">
                <div class="common-modal-input-section grey-bg">
                  <h2 class="section-main-header">Insurance Information</h2>
                  <div class="mt-5">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                              <p class="form-section-header mt-0 mb-4">NAMES:</p>
                            </div>
                            <div class="col-md-6 custom-floating-label">
                              <label for="insurInfoProducer" class="form-label"> Producer</label>
                              <input type="text" class="form-control" placeholder="Producer" id="insurInfoProducer">
                              <span class="span-info ps-2">Your insurance broker's name</span>
                            </div>
                            <div class="col-md-6 custom-floating-label">
                                <label for="insurInfoInsured" class="form-label"> Insured</label>
                                <input type="text" class="form-control" placeholder="Producer" id="insurInfoInsured">
                                <span class="span-info ps-2">Your full name</span>
                              </div>
      
                            <div class="col-md-12 mt-3">
                              <p class="form-section-header mt-0 mb-4">Address:</p>
                            </div>
                            <div class="col-md-12 custom-floating-label">
                              <label for="insurInfoStreet" class="form-label"> Street</label>
                              <input type="text" class="form-control" placeholder="Street" id="insurInfoStreet">
                            </div>
                            <div class="col-md-6 custom-floating-label">
                              <label for="insurInfoCity" class="form-label"> City</label>
                              <input type="text" class="form-control" placeholder="City" id="insurInfoCity">
                            </div>
                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-5 custom-dropdown custom-floating-label">
                                  <label for="prodComState" class="form-label"> State</label>
                                  <select class="custom-select" id="prodComState">
                                    <option class="dropdown-option" value="" disabled selected hidden>Select</option>
                                    <option class="dropdown-option" value="1">Projects</option>
                                    <option class="dropdown-option" value="2">Users</option>
                                    <option class="dropdown-option" value="3">Spaces & Resources</option>
                                    <option class="dropdown-option" value="3">Company</option>
                                  </select>  
                                </div>
                                <div class="col-md-7 custom-floating-label">
                                  <label for="insurInfoZipcode" class="form-label"> ZIP Code</label>
                                  <input type="text" class="form-control" placeholder="ZIP Code" id="insurInfoZipcode">
                                </div>
                              </div>
                            </div>
                          </div>
                    </form>
                  </div>
                </div>

                <div class="common-modal-input-section">
                  <h2 class="section-main-header">Upload your Form</h2>
                  <form>
                    <div class="row">
                      <div class="col-md-12 custom-floating-label my-4">
                            <div class="file-drop-area mt-3" [ngClass]="{ 'file-over': isFileOver }"
                                (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
                                <img class="folder-icon" (click)="fileInput.click()"
                                    src="../../../../../assets/images/folder-icon.svg" alt="folder icon">
                                <h3 class="mb-2 drag-n-drop">Drag and Drop your file here, or <a class="browse-file-link"
                                        (click)="fileInput.click()">browse</a></h3>
                                <input type="file" multiple class="d-none" (change)="onFileSelect($event)" #fileInput>
                            </div>
                            <!-- Display list of selected files -->
                            <ul *ngIf="files.length > 0" class="list-group mt-3 uploaded-files-section">
                                <li *ngFor="let file of files; let i = index" class="list-group-item px-0">
                                    <div class="d-flex align-items-center">
                                        <div class="col-auto icon-col">
                                            <img src="../../../../../assets/images/pdf-icon.svg" alt="pdf icon"
                                                class="file-icon">
                                        </div>
                                        <div class="col ps-3 pe-2">
                                            <span [title]="file.name" class="file-name">{{ file.name }}</span>
                                            <!--<ngb-progressbar class="file-progress-bar" type="success" textType="white"
                                                [value]="100" [showValue]="false"></ngb-progressbar>-->
                                        </div>
                                        <div class="col-auto close-icon-col px-0">
                                            <i class="bi bi-x remove-btn" (click)="removeFile(i)"></i>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="justify-content-end text-end px-0">
                                <div class="" *ngIf="files.length > 0">
                                    <div class="custom-floating-label px-0">
                                        <label for="details1" class="form-label">Add a Comment</label>
                                        <textarea formControlName="details" class="form-control common-text-area"
                                            placeholder="Waiting for a few more quotes from PPCS and Sound Stages"
                                            id="details1" rows="3"></textarea>
                                    </div>
                                </div>
                            </div>
                      </div>
                      <div class="col-md-12 custom-floating-label">
                        <label for="LtofCreditUploadDoc" class="d-inline-block">
                          <span class="fw-semibold text-link d-flex align-items-center">
                          View Sample</span>
                        </label>   
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-md-12 custom-floating-label my-4">
                        <div class="file-drop-area mt-3">
                          <img class="folder-icon"
                              src="../../../../../assets/images/folder-icon.svg" alt="folder icon">
                          <h3 class="mb-2 drag-n-drop">Drag and Drop your file here, or <a class="browse-file-link">browse</a></h3>
                          <input type="file" multiple class="d-none">
                      </div>
                   
                      </div>
                      <div class="col-md-12 custom-floating-label">
                        <label for="LtofCreditComment" class="form-label"> Comments</label>
                        <input type="text" class="form-control" placeholder="Comments" id="LtofCreditComment">
                      </div>
                    </div> -->
                  </form>
                </div>

                <div class="cm-btn-section d-flex align-items-center">
                  <div class="left-btn me-auto">
                    <a class="text-link"><img class="back-img" src="../../assets/images/blue-left-icon.svg" alt="Back" /> back </a>
                  </div>  
                  <button type="button" class="btn common-btn-secondary" data-bs-dismiss="modal">Next: Terms & Conditions</button>
                  <button type="button" class="btn common-btn-light ms-2" data-bs-dismiss="modal">Save & Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
    <!---Added modals on click of Select Company-->
<ng-template #company let-modal>
  <div class="modal-body">
    <h2 class="section-main-header">Search </h2>
    <div class="">
      <div class="mt-56 custom-floating-label">
        <label for="input1" class="form-label">Search</label>
        <input type="text" class="pe-4 form-control" placeholder="Search company name or company ID #"
          id="input1">
        <img class="search-icon" src="../../../../../../assets/images/search-icon.svg" alt="Search Icon">
      </div>
    </div>

    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="btn common-btn-secondary" (click)="modal.close('Close click')">Cancel</button>
      <button type="button" class="btn common-btn-secondary" >Select</button>
    </div>
  </div>
</ng-template>