<div>
  <span>
    <img
      src="assets/images/three-dots.svg"
      class="eye"
      alt="..."
      #trigger
      (click)="togglePopup()"
      data-action="toggle"
    />
  </span>
  <div #content *ngIf="isOpen" class="menu-container">
    <span class="more-action">{{popUpDropdown.moreActions}}</span>
    <div class="menu-item">
      <span
        class="icon-hover"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Add a User"
      >
        <img
          src="assets/images/person-add.svg"
          alt="Add a User"
        /><span class="img-content" (click)="openAddUserModal();isOpen=!isOpen">Add a User</span>
      </span>
    </div>
    <div class="menu-item">
      <span class="icon-hover">
        <img
          src="assets/images/house-icon.svg"
          alt="Send Credit Application"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Send Credit Application"
          />
          <span class="img-content" (click)="handleCreditStatus();isOpen=!isOpen">
            {{ projectData[0]?.company?.companyCreditStatus?.creditStatus === 'Current' ? 'Request AR' : 'Send Credit Application' }}
          </span>
          </span>
    </div>
    <div class="menu-item">
      <span
        class="icon-hover"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Remove Bookmark"
        (click)="removeBookmark(); isOpen=!isOpen"
      >
        <img src="assets/images/active-bookmark.svg" alt="Remove User" />
        <span class="img-content">Remove Bookmark</span>
      </span>
    </div>
  </div>
</div>

<ng-template #successTpl>
  <div class="success">{{requestResponse}}</div>
</ng-template>
<ng-template #dangerTpl>
  <div class="danger">{{requestResponse}}</div>
</ng-template>
