
     <!---Help Slidout Button-->
     <button class="" type="button" (click)="toggleHelpSlider()"
     aria-controls="sidebar" aria-expanded="false" aria-label="Toggle sidebar">
     Help Slider
     </button>
     <!-- Help Slider -->
     <div>
<div [ngClass]="{'show': helpSliderVisible}" [class.noPaddingTop]="noPaddingTop" class="sidebar helpsidebar bg-light">
  <div [ngClass]="{'show': helpSliderVisible}" class="help-sidebar-section">
    <div class="">
      <div class="d-flex justify-content-end mb-3"><span class="close-btn">close Help > </span></div>
    </div>
    <div class="helper-content-section">
      <h3>Title:</h3>
      <p>Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean lacinia bibendum nulla sed consectetur. Vestibulum id ligula porta felis euismod semper.</p>
    </div>
    <div class="helper-content-section">
      <h3>Title:</h3>
      <p>Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean lacinia bibendum nulla sed consectetur. Vestibulum id ligula porta felis euismod semper.</p>
    </div>
    <div class="helper-content-section">
      <h3>Need More Help?</h3>
      <p>Read our <span class="blue-text">FAQs </span></p>
    </div>
  </div>

  <!-- Overlay to detect clicks outside -->
  <div *ngIf="helpSliderVisible" class="overlay" (click)="closeHelpSlider()"></div>
</div>
</div>