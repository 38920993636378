<button data-bs-toggle="modal" data-bs-target="#addUserCustPortal">Add A New Project</button>
<!-- common modal section-->
<div class="modal fade" id="addUserCustPortal" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
          <h5 class="modal-title" id="commonModalLabel">Add A New Project</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
              class="cm-close-text">Close</span></button>
        </div>
        <div class="container px-0">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
              <div class="modal-left-section">
                <h4>Company</h4>
                <div class="company-meta-data">
                  <p class="title">Type: <span></span></p>
                  <p class="title mb-0">Comapny:</p>
                  <p class="pb-0 pt-0">A Brand New Production Company</p>
                <div class="d-flex flex-column">
                 <span class=" mb-2"><a class="text-link">Change Company</a></span>
                 <span class=" mb-2"><a class="text-link" >Open Company</a></span>
                </div>
                </div>
                <button class="btn common-btn-secondary" (click)="openSelectCompany(company)">Select Company</button>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="container cm-main-container px-0 mx-0">

                <div class="common-modal-input-section grey-bg">
                  <h2 class="section-main-header">Project Name</h2>
                  <div class="mt-5">
                    <form>
                      <div class="row">
                        <div class="col-md-12 custom-floating-label">
                          <label for="input1" class="form-label">Project Name</label>
                          <input type="text" class="form-control" placeholder="Project Name" id="input1">
                        </div>

                      </div>

                    </form>
                  </div>
                </div>

                <div class="common-modal-input-section">
                  <h2 class="section-main-header">Production Company Name</h2>
                  <form>
                    <div class="row">
                      <div class="col-md-12 custom-floating-label">
                        <label for="input1" class="form-label"> Name</label>
                        <input type="text" class="form-control" placeholder="Name" id="input1">
                      </div>

                    </div>

                  </form>
                </div>
                <div class="common-modal-input-section">
                  <h2 class="section-main-header">Project Details</h2>
                  <form>
                    <div class="row">
                      <div class="col-md-12 px-0">
                        <p class="form-section-header mt-0 mb-4">Project Type</p>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="feature"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="feature">
                            Feature
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="tele"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="tele">
                            Television
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="tele"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="tele">
                            Live Production/Rehearsal
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="feature"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="feature">
                            Commercial
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="tele"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="tele">
                            Still Shoot
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="tele"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="tele">
                            Special Event
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="tele"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="tele">
                            Other
                          </label>
                        </div>
                      </div>
                      <div class="col-md-5 custom-floating-label mt-4">
                        <label for="input1" class="form-label">Type</label>
                        <input type="text" class="form-control" placeholder="What is your project type?" id="input1">
                      </div>
                      <div class="col-md-12 mt-4 px-0">
                        <p class="form-section-header mt-0 mb-4">TELEVISION PROJECT CAMERA SETUP:</p>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="feature"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="feature">
                            Single Cam
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="tele"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="tele">
                            Multi Cam
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="tele"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="tele">
                            Variety
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-5 px-0">
                        <p class="form-section-header mt-0 mb-4">Programming schedule:</p>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="feature"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="feature">
                            Single Episode/Pilot 
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                        <div class="common-radio-btn">
                          <input class="common-radio-input mb-0" type="radio" name="project" id="tele"
                            value="d/m/yyyy" />
                          <label class="common-radio-label" for="tele">
                            Series/Multi Episodes
                          </label>
                        </div>
                        <div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-3">
                          <div class="custom-dropdown disabled px-0 w-100">
                            <select class="custom-select disabled ">
                              <option class="dropdown-option" value="" disabled selected hidden>Season #</option>
                              <option class="dropdown-option" value="1">1</option>
                              <option class="dropdown-option" value="2">2</option>
                              <option class="dropdown-option" value="3">3</option>
                              <option class="dropdown-option" value="3">4</option>
                            </select>  
                        <div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-5 px-0">
                      <p class="form-section-header mt-0 mb-4">Project Description:</p>
                    </div>
                    <div class="col-md-12 px-0 ">
                      <div class=" custom-floating-label">
                        <label for="input1" class="form-label">Project Description</label>

                        <textarea class="form-control form-control-lg common-text-area" id="project Description" rows="4">Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. </textarea>
                      </div>
                    </div>
                    </div>
                  </form>
                </div>
                <div class="common-modal-input-section">
                  <h2 class="section-main-header">Anticipated Project Dates:</h2>
                  <form>
                    <div class="row">
                        <div class=" col-md-6 custom-floating-label" id="datepicker">
                          <label for="input1" class="form-label">Start Date</label>
                          <input type="date" class="form-control d-block" placeholder="Start Date" id="date" />
                      </div>
                      <div class=" col-md-6 custom-floating-label" id="datepicker">
                        <label for="input1" class="form-label">End Date</label>
                        <input type="date" class="form-control d-block" placeholder="Start Date" id="date" />
                    </div>
                    <div class="col-md-12 px-0 ">
                      <div class=" custom-floating-label">
                        <label for="input1" class="form-label">Scdeduled Notes</label>
                        <textarea class="form-control form-control-lg common-text-area" id="project Description" rows="4">Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. Vestibulum id ligula porta felis euismod semper. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. </textarea>
                      </div>
                    </div>
                    </div>
                  </form>
                </div>
                    
                
                <div class="common-modal-input-section custom-internal-use-bg-shadow">
                  <h2 class="section-main-header">Company Type</h2>
                 
           
                    <p class="text-color-red">For Internal use only</p>
                    <form>
                      <div class="row">
                        <div class="col-md-6 custom-floating-label">
                          <label for="input1" class="form-label form-label-for-select">Customer Type</label>

                    <div class=" custom-dropdown w-100">
                      <select class="custom-select">
                        <option class="dropdown-option" value="" disabled selected hidden>Select</option>
                        <option class="dropdown-option" value="1">Projects</option>
                        <option class="dropdown-option" value="2">Users</option>
                        <option class="dropdown-option" value="3">Spaces & Resources</option>
                        <option class="dropdown-option" value="3">Company</option>
                      </select>  
                    </div>
                    </div>
                    <div class="col-md-6 custom-floating-label">
                      <label for="input1" class="form-label form-label-for-select">Primary Payment Type</label>
                      <div class=" custom-dropdown w-100">
                      <select class="custom-select">
                        <option class="dropdown-option" value="" disabled selected hidden>Select</option>
                        <option class="dropdown-option" value="1">Projects</option>
                        <option class="dropdown-option" value="2">Users</option>
                        <option class="dropdown-option" value="3">Spaces & Resources</option>
                        <option class="dropdown-option" value="3">Company</option>
                      </select>  
                    </div>
                    </div>
                    </div>
                    </form>
                    
                </div>
                
                <div class="cm-btn-section">
                  <button type="button" class="btn common-btn-secondary me-2 mb-2" data-bs-dismiss="modal">Add
                    Project</button>
                    <button type="button" class="btn common-btn-secondary me-2 mb-2" data-bs-dismiss="modal">Add
                      and go to Project</button>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
    <!---Added modals on click of Select Company-->
<ng-template #company let-modal>
  <div class="modal-body">
    <h2 class="section-main-header">Search </h2>
    <div class="">
      <div class="mt-56 custom-floating-label">
        <label for="input1" class="form-label">Search</label>
        <input type="text" class="pe-4 form-control" placeholder="Search company name or company ID #"
          id="input1">
        <img class="search-icon" src="../../../../../../assets/images/search-icon.svg" alt="Search Icon">
      </div>
    </div>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="btn common-btn-secondary" (click)="modal.close('Close click')">Cancel</button>
      <button type="button" class="btn common-btn-secondary" >Select</button>
    </div>
  </div>
</ng-template>