<div>
    <button
      class="btn btn-primary"
      (click)="openDeleteConfirmationModal(content)"
    >
      hello
    </button>
  </div>
  <ng-template #content let-modal>
    <div class="page-wrapper-modal">
      <div class="page-header">
        <h2 class="mb-0">Needs Revisions</h2>
      </div>
      <div class="title-company-project">
        <div class="company">
          <span class="company-heading">Company:</span>
          <span class="company-content">A Brand New Production Company</span>
        </div>
        <div class="project">
          <span class="project-heading">Project:</span>
          <span class="project-content">The New Show - Pilot</span>
        </div>
      </div>
      <div class="section-text-area">
        <h3 class="section-text">Section Name:</h3>
        <textarea class="text-area" id="credit-text" name="credit-text">Could you please let us know the contact’s full name?</textarea>
      </div>
    </div>
    <div class="end-wrapper">
      <div class="button">
        <button class="common-btn-light btn">Cancel</button>
        <button class="common-btn-secondary btn">Save Comment</button>
      </div>
    </div>
  </ng-template>
  