export const COMPANY_USERS_DATA = [
  { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "company admin", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Assistant', activeProjects: 'The Court', },
  { fullName: 'Colette Alfaro', email: 'eryamille@sunshineproductions.com', requestType: "", status: 'DEACTIVATED', updatedDate: '1/12/24', additionalTime: '10:00AM', jobTitle: 'Sound Director', activeProjects: 'The Court' },
  { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "company admin", status: 'DEACTIVATED', updatedDate: '1/14/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'Arrow' },
  { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '1/15/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'DEACTIVATED', updatedDate: '1/17/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '2/10/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Producer', activeProjects: 'The Court' },
  { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "account admin", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Director', activeProjects: 'The Court' },
  { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'DEACTIVATED', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Assistant', activeProjects: 'The Court' },
  { fullName: 'John Smith', email: 'jsmith@sunshineproductions.com', requestType: "", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { fullName: 'Dineshwaran Sivakumar', email: 'dineshwaran.sivakumar@wbdcontractor.com', requestType: "", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },

];

export const COMPANY_CONTACT_INFO = [
  { name: 'Jodie Smith', account: 'Production', email: 'jsmith@sunshineproductions.com', phone: '818-567-9245 (work)' },
  { name: 'Farhan Blevins', account: 'Production', email: 'lexi@sunshineproductions.com', phone: '818-567-9245 (work)' },
  { name: 'Lexi Allan', account: 'Accounting', email: 'flevins@sunshineproductions.com', phone: '818-567-9245 (work)' },
  { name: 'Farhan Blevins', account: 'Accounting', email: 'flevins@sunshineproductions.com', phone: '818-567-9245 (work)' }
];

export const COMPANY_VIEW_INFO = {
  "success": true,
  "message": "Data Retrieved Successfully",
  "company": {
    "companyName": "ABC Prod",
    "companyId": 15,
    "billinAddressSameInd": 0,
    "projectCount": 0,
    "userCount": 0,
    "validUntil": "2025-01-31T00:00:00.000+00:00",
    "federalTax": {
      "federalId": 15,
      "federalTaxId": 1500
    },
    "companyType": {
      "companyTypeId": 4,
      "companyType": "3rd Party Commercial House",
      "orderNo": 4
    },
    "companyCreditStatus": {
      "companyCreditStatusId": 1,
      "creditStatus": "Current",
      "orderNo": 1
    },
    "companyAddresses": [
      {
        "companyAddressId": 9,
        "street": "Street",
        "city": "City1",
        "zip": "287382",
        "phone": "6382781823",
        "statusActiveInd": "Y",
        "state": {
          "stateId": 4,
          "countryId": 3,
          "stateCode": "NB",
          "stateName": "New Brunswick",
          "orderNo": 4
        },
        "country": {
          "countryId": 3,
          "countryCode": "CA",
          "countryName": "Canada",
          "orderNo": 3
        },
        "companyAddressType": {
          "companyAddressTypeId": 2,
          "addressType": "Billing Address"
        }
      },
      {
        "companyAddressId": 10,
        "street": "Street",
        "city": "City1",
        "zip": "287382",
        "phone": "6382781823",
        "statusActiveInd": "Y",
        "state": {
          "stateId": 3,
          "countryId": 12,
          "stateCode": "MB",
          "stateName": "Manitoba",
          "orderNo": 3
        },
        "country": {
          "countryId": 12,
          "countryCode": "AQ",
          "countryName": "Antarctica",
          "orderNo": 12
        },
        "companyAddressType": {
          "companyAddressTypeId": 1,
          "addressType": "Production company Address"
        }
      }
    ],
    "companyContactInfos": [
      {
        "companyContactInfoId": 17,
        "firstName": "Sia",
        "lastName": "Dee",
        "email": "sia@email.com",
        "phone": "1983920102",
        "jobTitle": "Producer",
        "contactStatus": "Y"
      },
      {
        "companyContactInfoId": 18,
        "firstName": "Sam",
        "lastName": "John",
        "email": "sam@abc.com",
        "phone": "12302349",
        "jobTitle": "Assistant",
        "contactStatus": "Y"
      }
    ]
  }
}


export const COMPANY_DATA= [
  {compid:'123', companyName: 'Dan Acorn', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'',comapnyName:'Dan Acorn',ids:'12345'},
  { compid:'12345',companyName: 'Lexi Allan', status: 'ACTIVE',  projects: '#', active: '#',users:'#' ,goToPage:'',comapnyName:'Lexi Allan',ids:'12345'},
  { compid:'12345',companyName: 'Maggie Bash', status: 'ACTIVE',  projects: '#', active: '#',users:'#' ,goToPage:'',comapnyName:'Maggie Bash',ids:'12345'},
  {compid:'12345', companyName: 'Nuha Hodgson', status: 'DISABLED',  projects: '#', active: '#',users:'#' ,goToPage:'',comapnyName:'Nuha Hodgson',ids:'12345'},
  {compid:'12345', companyName: 'Farhan Blevins', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'' ,comapnyName:'Farhan Blevins',ids:'12345'},
  {compid:'12345', companyName: 'Lin ANg', status: '',  projects: '#', active: '#',users:'#' ,goToPage:'',comapnyName:'Lin ANg',ids:'12345'},
  { compid:'123',companyName: 'Erya Milnex', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'' ,comapnyName:'Erya Milnex',ids:'12345'},
  {compid:'12345', companyName: 'Colette Alfaro', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'' ,comapnyName:'Colette Alfaro',ids:''},
  {compid:'12345', companyName: 'Colette Alfaro', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'' ,comapnyName:'Colette Alfaro',ids:'12345'},
  {compid:'12345', companyName: 'Colette Alfaro', status: '',  projects: '#', active: '#',users:'#' ,goToPage:'',comapnyName:'Colette Alfaro',ids:'12345'},
  { compid:'12345',companyName: 'Colette Alfaro', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'',comapnyName:'Colette Alfaro',ids:'12345' },
  { compid:'12345',companyName: 'Colette Alfaro', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'' ,comapnyName:'Colette Alfaro',ids:'12345'},
  { compid:'12345',companyName: 'Colette Alfaro', status: 'DISABLED',  projects: '#', active: '#',users:'#',goToPage:'',comapnyName:'Colette Alfaro',ids:'12345' },
];
export const COMPANY_USERS_STUDIO= [
  { compid:'12345',fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "company admin", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court', },
  { compid:'123',fullName: 'Colette Alfaro', email: 'eryamille@sunshineproductions.com', requestType: "", status: 'DEACTIVATED', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { compid:'12345',fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "company admin", status: 'DEACTIVATED', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'Arrow' },
  { compid:'12345',fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { compid:'12',fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { compid:'1',fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'DEACTIVATED', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { compid:'12345',fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { compid:'4',fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { compid:'12345',fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "account admin", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { compid:'12345',fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'DEACTIVATED', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { compid:'12345',fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { compid:'12345',fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
  { compid:'12345',fullName: 'Colette Alfaro', email: 'colettealfaro@gmail.com', requestType: "", status: 'ACTIVE', updatedDate: '1/11/24', additionalTime: '10:00AM', jobTitle: 'Costume Assistant', activeProjects: 'The Court' },
]
