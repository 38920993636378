<h2 class="section-main-header" *ngIf="userType === 'wbuser'">{{searchData.searchUser}}</h2>
<h2 class="section-main-header" *ngIf="userType === 'customer'">{{searchData.search}}</h2>
<div class="mt-56 custom-floating-label common-search-input">
    <label for="input1" class="form-label">{{searchData.search}}</label>
    <input type="text" class="pe-4 form-control "  
         [(ngModel)]="searchTerm"
         (ngModelChange)="onInputChange($event)"
         [ngbTypeahead]="search"
         [inputFormatter]="formatter"
         [resultFormatter]="formatter"
         [resultTemplate]="rt"
         [editable]="true"    
         [disabled]="disabled"  
         [placeholder]="placeholder"
         (selectItem)="selectedItem($event)">
 
    <img class="search-icon" src="../../../../../../assets/images/search-icon.svg" alt="Search Icon">
 
    <ng-template #rt let-r="result">
      <div class="user-details" *ngIf="userType === 'wbuser'">
        <ul class="list-group">
          <li class="list-group-item">
            <span class="username">{{ r.firstName }} {{ r.lastName }}</span><br>
            <span class="user-designation">{{ r.jobTitle}}</span><br>
            <span class="user-designation">{{ r.emailAddress}}</span>
        </ul>
      </div>
      <!-- <div class="company-details" *ngIf="userType === 'customer' && portalType != 'studio'">
      <div class="user-details" *ngIf="userType === 'customer'">
        <ul class="list-group">
          <li class="list-group-item">
            <span class="username">{{ r.companyName }}</span>
          </li>
          <li class="list-group-item">
            <span class="company-id">{{ r.companyId }}</span>
          </li>
        </ul>
      </div> -->
      <div class="user-details" *ngIf="portalType === 'studio'">
        <ul class="list-group">
          <li class="list-group-item">
            <span class="username">{{ r.companyName }}</span>
          </li>
        </ul>

      </div>
    </ng-template>
</div>
 
 
 