<div class="page-wrapper">
    <div class="container px-0 ">
      <div class="top-nav-company d-flex align-items-center mb-4">
        <img src="assets/images/keyboard_arrow_left.svg" alt="Company Icon" class="icon">
        <p class="all-companies mb-0">All companies</p>
      </div>
      <div class="row mx-0">
        <div class="page-header col-12 px-3">

          <h1 class="mb-0">Chukk Lorre Productions</h1>
        </div>    
      </div>
      <div class="row account-project-block">
        <div class="col-6 account-section">
          <h5 class="mb-0">Company ID</h5>
          <p class="mb-0">12345</p>
        </div>
        <div class="col-6 project-section">
          <h5 class="mb-0">Projects (23)</h5>
          <span class="text-link view-sub-text mb-0">View All</span>
        </div>
      </div>
      <div class="row contact-info-block-1 ">
        <div class="col-12 d-flex justify-content-between align-items-center pe-0 ps-0 mb-4">
          <h3 class="title-contact mb-0">Contact Information</h3>
          <p class="text-link view-sub-text mb-0">Edit</p>
        </div>
        <div class="col-12 ps-0 pe-0">
          <span class="contact-inner">1399 W Olive Ave, Burbank, CA 91506</span>
        </div>
      </div>
      <div class="row contact-info-block-2">
        <div class=" px-0">
            <div class="additional-contact-information-section-1">
                <div class="col-12 d-flex justify-content-between align-items-center pe-0 ps-0">
                  <p class="title-contact mb-0">Contact Information</p>
                  <p class="text-link view-sub-text mb-0">Edit</p>  
                </div>
              </div>
          <div class="row additional-contact-information-section-2 mx-0 ">
            <div class="col-lg-4 col-sm-12 px-0 pt-4">
              <strong class="content-bold pb-1">Production </strong><p class="content-bold mb-0 pb-2">Jodie Smith</p>
              <p class="content-normal mb-0 pb-1">jsmith@sunshineproductions.com</p><p class="content-normal mb-0">818-567-9245 (work)</p>
            </div>
            <div class="col-sm-12 col-lg-4  px-0 pt-4">
              <strong class="content-bold  pb-1">Production</strong><p class="content-bold pb-2 mb-0">Lexi Allan</p>
              <p class="content-normal mb-0 pb-1">lexi@sunshineproductions.com</p><p class="content-normal mb-0">818-567-9245 (work)</p>
            </div>
            <div class="col-sm-12 col-lg-4  px-0 pt-4">
              <strong class="content-bold">Accounting</strong><p class="content-bold mb-0 pb-2">Farhan Blevins</p>
              <p class="content-normal mb-0 pb-1">flevins@sunshineproductions.com</p><p class="content-normal mb-0">818-567-9245 (work)</p>
            </div>
            <div class="col-sm-12 col-lg-12  px-0 pt-4">
              <strong class="content-bold">Accounting</strong><p class="content-bold mb-0 pb-2">Farhan Blevins</p>
              <p class="content-normal mb-0 pb-1">flevins@sunshineproductions.com</p><p class="content-normal mb-0">818-567-9245 (work)</p>
          </div>
          </div>
        </div> 
      </div>
      <div class="row account-user-block align-items-center">
        <div class="account-user col-12 d-flex justify-content-between ps-0 pe-0 ">
          <h3 class="title-contact mb-0">Account Users (74)</h3>
          <div class="view-div d-flex align-items-center">
            <p class="text-link view-sub-text mb-0 me-3">View All</p>
            <button class="btn btn-dark custom-button">+ Add a User</button>
          </div>
        </div>
      </div>
      <div class="row account-project-block">
        <div class="account-section  col-12 col-md-6 col-sm-6">
          <h5 class="mb-0 title-contact">Credit Status</h5>
          <div class="view-div d-flex align-items-center">
            <p class="credit-sub-text mb-0 me-3">Valid Until 12/12/24</p>
            <p class="mb-0 credit-status-tag current">Current</p>
          </div>
        </div>
        <div class="company-type col-12 col-md-6 col-sm-6">



            <div class="d-flex justify-content-between">
              <div>
                <p class="mb-0 additional-text">FOR INTERNAL USE ONLY</p>
                <h5 class="mb-0 title-contact">Company Type</h5>
              </div>
              <div class="align-self-end">
                <p class=" mb-0 additional-text-1">Major Studio</p>
              </div>
            </div>
        </div>
        </div>
      
      </div>
      <div class="last-section">
        <div class="last-section-edit">
          <img src="assets/images/edit.svg" alt="Edit Icon" class="icon">
          <p class="mb-0">Edit Company</p>
        </div>
        <div class="last-section-disable">
          <img src="assets/images/disable.svg" alt="Disable Icon" class="icon">
          <p class="mb-0">Disable Company</p>
        </div>
      </div>
      
    </div>

  